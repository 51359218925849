import React, { memo, useState } from 'react'

import { Dialog, Card } from 'components/newCommon'

const Delete = ({
  onClose = () => {},
  isOpen = false,
  onConfirm = () => {},
}) => {
  const [loading, setLoading] = useState(false)

  const deleteTask = async () => {
    setLoading(true)
    await onConfirm()
    setLoading(false)
    onClose()
  }

  return (
    <Dialog
      buttonUppercase
      isOpen={isOpen}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      confirmText={'Có'}
      loading={loading}
      onCancel={onClose}
      onConfirm={deleteTask}
      onClose={onClose}
      title={'Xóa mẫu'}
      textCenter
    >
      <Card className="mt20 mb20">{'Anh/Chị chắc chắn muốn xóa mẫu này?'}</Card>
    </Dialog>
  )
}

export default memo(Delete)
