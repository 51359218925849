import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { get, size } from 'lodash'

const ThongKeTiLeCongViecHalf = ({ dataDoughnut, colors }) => {
  if (!dataDoughnut) {
    return <></>
  }

  const checkData = get(dataDoughnut, 'datasets[0].data', []).reduce(
    (a, b) => a + b,
    0
  )
  let newData = dataDoughnut
  let isNotData = false
  if (checkData === 0) {
    // Nếu không có dữ liệu thì set giá trị biểu đồ thành 100% để hiển thị nền màu xám
    isNotData = true
    newData = {
      ...newData,
      datasets: [
        {
          ...newData.datasets[0],
          backgroundColor: [colors.grey, colors.grey],
          data: [100, 0],
        },
      ],
      labels: [],
    }
  }

  const drawLine = (ctx, cx, cy, angle, ch, color) => {
    ctx.translate(cx, cy)
    ctx.rotate(angle)
    ctx.beginPath()
    ctx.moveTo(0, -3)
    ctx.lineTo(ch / 2, 0) // chiều cao của kim
    ctx.lineTo(0, 3)
    ctx.fillStyle = color
    ctx.fill()
    ctx.rotate(-angle)
    ctx.translate(-cx, -cy)
    ctx.beginPath()
    ctx.arc(cx, cy, 3, 0, Math.PI * 2) // độ to của trung tâm
    ctx.closePath()
    ctx.fill()
  }
  const plugin = {
    beforeDraw: chart => {
      const chartConfig = chart.chart.config
      const chartNew = chart.chart
      const needleValue = chartConfig.data.datasets[0].needleValue
      const needleValue2 = chartConfig.data.datasets[0].needleValue2
      const dataTotal = chartConfig.data.datasets[0].data.reduce(
        (a, b) => a + b,
        0
      )
      const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI
      const ctx = chartNew.ctx
      const chartWidth = chartNew.canvas.offsetWidth
      const chartHeight = chartNew.canvas.offsetHeight
      const cw = chartWidth
      const ch = chartHeight / 2 + chartHeight / 4
      const cx = cw / 2
      const cy = ch - 0.88
      if (needleValue !== 0 && needleValue2 !== 0) {
        drawLine(ctx, cx, cy, angle, ch, '#01B051')
      }
    },
  }

  return (
    <div className="chart-doughnut-wrapper">
      <div className="chart-doughnut-block">
        <div style={{ marginTop: -30 }}>
          <Doughnut
            data={newData}
            height={300}
            options={{
              responsive: true,
              tooltips: {
                enabled: !isNotData,
                position: 'nearest',
                callbacks: {
                  title: function (tooltipItem, data) {
                    return data['labels'][tooltipItem[0]['index']]
                  },
                  label: function (tooltipItem, data) {
                    return (
                      data['datasets'][0]['data'][tooltipItem['index']] +
                      ' Công việc'
                    )
                  },
                },
                backgroundColor: colors.white,
                titleFontColor: colors.black,
                bodyFontColor: colors.black,
                borderColor: colors.black,
                borderWidth: 0,
                caretSize: 5,
              },
              legend: {
                display: false,
              },
              cutoutPercentage: 80,
              rotation: 1 * Math.PI,
              circumference: 1 * Math.PI,
              pieceLabel: {
                render: 'label',
                arc: true,
                fontColor: '#000',
                position: 'outside',
              },
            }}
            plugins={[plugin]}
          />
          <div className="chart-doughnut-parameter">
            <span style={{ color: isNotData ? colors.grey : '#09B424' }}>
              {get(dataDoughnut, 'datasets[0].data', []).reduce(
                (a, b) => a + b,
                0
              )}
              <i>công việc</i>
            </span>
          </div>
          <div className="chart-doughnut-percent">
            {get(dataDoughnut, 'datasets[0].data', []).map((elm, index) => {
              if (elm === 0) {
                return true
              }

              // Tính tổng các giá trị
              const total = get(dataDoughnut, 'datasets[0].data', []).reduce(
                (a, b) => a + b,
                0
              )
              const giaTriConLai = total - elm
              let phanTram = Math.round((elm / total) * 100)
              const phanTramGiaTriConLai = Math.round(
                (giaTriConLai / total) * 100
              )
              const tongPhanTram = phanTram + phanTramGiaTriConLai
              let phanTramDu = 0
              let phanTramThieu = 0
              if (tongPhanTram > 100) {
                phanTramDu = tongPhanTram - 100
              } else if (tongPhanTram < 100) {
                phanTramThieu = 100 - tongPhanTram
              }

              if (index === 1 && phanTramDu !== 0) {
                phanTram = phanTram - phanTramDu
              } else if (index === 1 && phanTramThieu !== 0) {
                phanTram = phanTram + phanTramThieu
              }

              let locationDefault = {
                top: 'auto',
                bottom: 'auto',
                left: 'auto',
                right: 'auto',
              }
              let location = null
              if (index === 0) {
                location = {
                  ...locationDefault,
                  top: '18%',
                  left: '-10px',
                }
              } else if (index === 1) {
                location = {
                  ...locationDefault,
                  top: '18%',
                  right: '-10px',
                }
              }

              return (
                <span
                  key={index}
                  style={{
                    color: get(
                      dataDoughnut,
                      `datasets[0].backgroundColor[${index}]`
                    ),
                    ...location,
                  }}
                >
                  {elm > 0 ? phanTram + '%' : '0%'}
                </span>
              )
            })}
          </div>
        </div>
      </div>
      {size(get(newData, 'labels')) > 0 && (
        <ul className="chart-legends mt50">
          {get(newData, 'labels', []).map((elm, index) => (
            <li key={index}>
              <span
                style={{
                  backgroundColor: get(
                    newData,
                    `datasets[0].backgroundColor[${index}]`
                  ),
                }}
              />
              {elm}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default ThongKeTiLeCongViecHalf
