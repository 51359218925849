import React, { Component } from 'react'
// import AssignmentInformation from './AssignmentInformation';
// import InformationSteering from '../common/InformationSteering';
import { LoadingItem, DetailFileDinhKem } from '../common/'
import classnames from 'classnames'
import { FileList } from '../../components/common'
import { Utils } from 'helpers'

class DetailBoxActionSeeToKnow extends Component {
  state = {
    showBoxAction: false,
  }

  showBoxAction(e) {
    e.preventDefault()
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  render() {
    return (
      <div className="list-action">
        {this.props.isLoadingData &&
        Object.values(this.props.isLoadingData).find(item => item === true) !==
          undefined ? (
          <LoadingItem />
        ) : (
          <div>
            <p className="title">Thực Hiện</p>
            <span
              className="icon-More show-th-mobile"
              onClick={this.showBoxAction.bind(this)}
            ></span>
            {this.props.seeToKnow &&
              this.props.seeToKnow.fileDinhKem &&
              this.props.seeToKnow.fileDinhKem.length > 0 && (
                <DetailFileDinhKem
                  receiveDocument={{
                    fileDinhKem: this.props.seeToKnow.fileDinhKem,
                  }}
                  auth={this.props.auth}
                />
              )}
            {this.props.seeToKnow &&
              this.props.seeToKnow.fileDinhKemEvn &&
              this.props.seeToKnow.fileDinhKemEvn.length > 0 && (
                <DetailFileDinhKem
                  receiveDocument={{
                    fileDinhKem: this.props.seeToKnow.fileDinhKemEvn,
                  }}
                  auth={this.props.auth}
                />
              )}
            {/* Files tham khảo từ hội đồng thành viên  */}
            {Utils.checkBanTongHop(this.props.auth.mainDepartmentId) &&
              this.props.filesThamKhao &&
              this.props.filesThamKhao.length !== 0 && (
                <div className="pl20 pr20">
                  <div className="row">
                    <label className="pt-label col-md-3 col-xs-12 label-text-detail">
                      File tham khảo ({this.props.filesThamKhao.length}):
                    </label>
                    <div className="pt-form-content col-md-9 col-xs-12">
                      <div className="pl10">
                        <FileList
                          auth={this.props.auth}
                          receiveDocument={{
                            fileThamKhao: this.props.filesThamKhao,
                          }}
                          field="fileThamKhao"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div
              className={classnames('box-action', {
                'box-action-open': this.state.showBoxAction,
              })}
            >
              <div className="close-mobile-menu-detail">
                <button
                  className="click-item"
                  onClick={this.showBoxAction.bind(this)}
                >
                  <span className="pt-icon pt-icon-cross"></span>
                </button>
              </div>

              <div className="row list-action-detail">
                <div className="col-xs-4 col-sm-6 col-md-6 item">
                  <button
                    className="click-item"
                    onClick={this.props.onClickExecutingView}
                  >
                    <i className="icon-Xemthuchien icon-cpc"></i>
                    <span className="action-name">Xem thực hiện</span>
                  </button>
                </div>
                {!(
                  this.props.isNewAssignDoc !== undefined &&
                  this.props.isNewAssignDoc === true
                ) && (
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickSaveDocument}
                    >
                      <i className="icon-Luututailieu icon-cpc"></i>
                      <span className="action-name">Lưu tủ tài liệu</span>
                    </button>
                  </div>
                )}
                {!(
                  this.props.isNewAssignDoc !== undefined &&
                  this.props.isNewAssignDoc === true
                ) && (
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickInformationAssign}
                    >
                      <i className="icon-Chidao icon-cpc"></i>
                      <span className="action-name">Thông tin chỉ đạo</span>
                    </button>
                  </div>
                )}
                {!(
                  this.props.isNewAssignDoc !== undefined &&
                  this.props.isNewAssignDoc === true
                ) && (
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickInformationWork}
                    >
                      <i className="icon-Thong_Tin_Giao_Viec icon-cpc"></i>
                      <span className="action-name">Thông tin giao việc</span>
                    </button>
                  </div>
                )}
                {!this.props.isNewAssignDoc && (
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.onClickSendMail}
                    >
                      <i className="icon-Gmail icon-cpc"></i>
                      <span className="action-name">Gửi email</span>
                    </button>
                  </div>
                )}
                {!this.props.isNewAssignDoc && (
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      className="click-item"
                      onClick={this.props.sendToChat}
                    >
                      <i className="icon-speech-bubble icon-cpc"></i>
                      <span className="action-name">Gửi Chat</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* this.props.isLoadingData &&
          Object.values(this.props.isLoadingData).find(item => item === true) === undefined &&
          this.props.assignmentInformation &&
          this.props.assignmentInformation.hasOwnProperty('phieuGiaoViecId') &&
          <AssignmentInformation
            assignmentInformation={this.props.assignmentInformation}
            onHandleSubmit={this.props.onHandleSubmit}
            isDepartment={this.props.isDerpartment}
            type={this.props.type}
            auth={this.props.auth}
          />
        }

        { this.props.isLoadingData &&
          Object.values(this.props.isLoadingData).find(item => item === true) === undefined &&
          this.props.informationSteering &&
          this.props.informationSteering.hasOwnProperty('chiDaoId') &&
          <InformationSteering
            closeModulesDetail={this.closeModulesDetail}
            informationSteering={this.props.informationSteering}
          />
        */}
      </div>
    )
  }
}

export default DetailBoxActionSeeToKnow
