import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import { withRouter } from 'react-router-dom'
import {
  Classes,
  Button,
  Intent,
  Radio,
  Checkbox,
  Position,
} from '@blueprintjs/core'
import {
  get,
  findLast,
  size,
  differenceBy,
  uniqBy,
  uniq,
  isEmpty,
} from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

import { Icon, Row, Col, Scrollbar } from 'components/newCommon'
import { Input, FormDatePicker, DateAdvanced } from 'components/newCommon2'
import {
  getMyFavoriteGroups,
  getCongViec,
  postGiaoViec,
  getThongKeLoaiGiaoViec,
  getThongKeTrangThaiCongViec,
  showToast,
  getPhongBanNhanVien,
  postFavoriteGroup,
} from 'actions/task'
import { Action, Toast } from 'constants/MessageForm'
import {
  RESPONSIBILITY_TYPE,
  ASSIGN_TYPE,
  WORK_STATUS,
  STATISTIC_WORK_RESPONSIBILITY,
  WORK_TYPE,
} from 'constants/Enum'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import { TaskContext } from '../AllTask'
import PhongBanVaDonViThucHien from './_components/PhongBanVaDonViThucHien'

const GiveTask = ({ onClose = () => {}, reloadList = () => {}, match }) => {
  const PHONG_BAN = 'DEPARTMENT'
  const DON_VI = 'ORGANIZATION'
  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const THEO_DOI = 'THEO_DOI'
  const FORM_TITLE = 'Giao việc'
  const dateRequest = 'YYYY-MM-DD'
  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const { id, type, action } = match.params
  const isGiveTaskEdit = action === 'sua-giao-viec'
  const showTitle = isGiveTaskEdit ? 'Sửa giao việc' : FORM_TITLE

  const {
    filter: { status = WORK_STATUS.CHUA_THUC_HIEN },
    dataTreeStore,
    roleId,
    mainDepartmentId,
    mainUnitId,
    hasShowAllDeparment,
  } = useContext(TaskContext)

  const [isLoadingTree, setIsLoadingTree] = useState(false)
  const [taskDetail, setTaskDetail] = useState({})
  const [taskParentDetail, setTaskParentDetail] = useState({})
  const [fetching, setFetching] = useState(false)
  const [formData, setFormData] = useState({})
  const [validateErrors, setValidateErrors] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [dataAutoToggle, setDataAutoToggle] = useState([])
  const [favoriteGroups, setFavoriteGroups] = useState([])
  const [isOutOfDate, setIsOutOfDate] = useState(false)
  const [dataTreeStoreNew, setDataTreeStoreNew] = useState([])

  const handleExecutionDateMin = useCallback(() => {
    return moment().toDate()
  }, [])

  const onChangeRadio = useCallback(
    values => {
      setIsDisabledButtonDefault(false)
      const isPhongBanDonVi =
        values?.type === PHONG_BAN || values?.type === DON_VI
      setFormData(prev => ({
        ...prev,
        [CHU_TRI]: values,
        [PHOI_HOP]: !isPhongBanDonVi
          ? prev?.[PHOI_HOP]?.filter(e => e.id !== values?.id)
          : isPhongBanDonVi
          ? prev?.[PHOI_HOP]?.filter(
              e =>
                (e.parentId !== values?.id && e.id !== values.id) ||
                (e.parentId === values?.id && !e.isLanhDaoPhongBan)
            )
          : prev?.[PHOI_HOP],
        [THEO_DOI]: !isPhongBanDonVi
          ? prev?.[THEO_DOI]?.filter(e => e.id !== values?.id)
          : isPhongBanDonVi
          ? prev?.[THEO_DOI]?.filter(
              e =>
                (e.parentId !== values?.id && e.id !== values.id) ||
                (e.parentId === values?.id && !e.isLanhDaoPhongBan)
            )
          : prev?.[THEO_DOI],
      }))
    },
    [setFormData]
  )

  const handleCheckActive = useCallback(
    (key, formData, record) => {
      const childrenItem =
        dataTreeStore?.find(e => e?.id === record?.id)?.children || []
      const dataKey = formData?.[key]?.findIndex(e => e?.id === record?.id)
      let isChecked = false
      if (size(formData?.[key]) !== 0) {
        const taskItemDataKey = formData?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        if (
          (size(childrenItem) !== 0 &&
            size(childrenItem) === size(taskItemDataKey)) ||
          dataKey === 0 ||
          (dataKey && dataKey !== -1)
        ) {
          isChecked = true
        }
      }

      return isChecked
    },
    [dataTreeStore]
  )

  const onChangeCheckbox = useCallback(
    async (name, values, event, isIndeterminate) => {
      setIsDisabledButtonDefault(false)
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      const isOtherWorkType =
        taskDetail?.workType === WORK_TYPE.MEETING || taskDetail?.referenceId
      const isPhongBanDonVi =
        values?.type === PHONG_BAN || values?.type === DON_VI
      let treeItem = []
      if (isPhongBanDonVi) {
        treeItem =
          dataTreeStore?.find(e => e?.id === values?.id)?.children || []
        setDataAutoToggle([values?.id])
      }

      setFormData(prev => {
        let newData = []
        const oldData = prev?.[name] || []
        newData = [...oldData, values]
        if (isPhongBanDonVi) {
          if (!isChecked) {
            newData = differenceBy(newData || [], [...treeItem, values], 'id')
          } else {
            if ((isOtherWorkType && !hasShowAllDeparment) || !isOtherWorkType) {
              newData = [...newData, ...(treeItem || [])]
              newData = differenceBy(newData || [], [values], 'id')
            }
          }

          const dataChuTri = prev?.[CHU_TRI] ? [prev?.[CHU_TRI]] : []
          const dataPhoiHop = prev?.[PHOI_HOP] || []
          const dataTheoDoi = prev?.[THEO_DOI] || []
          if (
            name === PHOI_HOP &&
            (!isOtherWorkType || (isOtherWorkType && !hasShowAllDeparment))
          ) {
            newData = differenceBy(
              newData,
              [...dataChuTri, ...dataTheoDoi],
              'id'
            )
          } else if (
            name === THEO_DOI &&
            (!isOtherWorkType || (isOtherWorkType && !hasShowAllDeparment))
          ) {
            newData = differenceBy(
              newData,
              [...dataChuTri, ...dataPhoiHop],
              'id'
            )
          }

          if (
            (name === PHOI_HOP || name === THEO_DOI) &&
            size(dataChuTri) !== 0 &&
            dataChuTri?.some(
              e =>
                e?.id === values?.id &&
                (e?.type === PHONG_BAN || e?.type === DON_VI)
            )
          ) {
            newData = newData?.filter(
              e =>
                e.parentId !== values?.id ||
                (e.parentId === values?.id && !e?.isLanhDaoPhongBan)
            )
          }
        } else {
          if (!isChecked) {
            newData = differenceBy(newData || [], [values], 'id')
          }
        }

        newData = differenceBy(newData, [{ id: roleId }], 'id')

        const chuTri =
          name !== CHU_TRI &&
          prev?.[CHU_TRI]?.id === values?.id &&
          ((prev?.[CHU_TRI]?.type !== PHONG_BAN &&
            prev?.[CHU_TRI]?.type !== DON_VI &&
            !isOtherWorkType) ||
            isOtherWorkType)
            ? null
            : prev?.[CHU_TRI]
        const phoiHop =
          name === PHOI_HOP
            ? uniqBy(newData, 'id')
            : prev?.[PHOI_HOP]?.filter(e => e.id !== values?.id) || []
        const theoDoi =
          name === THEO_DOI
            ? uniqBy(newData, 'id')
            : prev?.[THEO_DOI]?.filter(e => e.id !== values?.id) || []

        return {
          ...prev,
          [CHU_TRI]: chuTri,
          [PHOI_HOP]: phoiHop,
          [THEO_DOI]: theoDoi,
        }
      })
    },
    [
      setFormData,
      setDataAutoToggle,
      dataTreeStore,
      taskDetail,
      hasShowAllDeparment,
      roleId,
    ]
  )

  const handleCheckOutOfDate = useCallback((executionDate, deadline) => {
    let result = false
    const today = moment()
    const executionDateString = executionDate
      ? moment(executionDate).format(`${dateRequest} 00:00:00`)
      : null
    const startDate = executionDateString ? moment(executionDateString) : null
    const deadlineString = moment(deadline).format(`${dateRequest} 23:59:59`)
    const endDate = deadlineString ? moment(deadlineString) : null
    if (
      startDate &&
      endDate &&
      ((endDate.diff(startDate, 'seconds') &&
        endDate.diff(startDate, 'seconds') < 0) ||
        (endDate.diff(today, 'seconds') && endDate.diff(today, 'seconds') < 0))
    ) {
      result = true
    }

    return result
  }, [])

  const handleCheckIndeterminate = useCallback(
    (name, record) => {
      let isChecked = false
      const dataKey = size(formData?.[name]) !== 0
      const hasChecked = formData?.[name]?.some(e => e?.parentId === record?.id)
      if (
        dataKey &&
        (record?.type === PHONG_BAN || record?.type === DON_VI) &&
        hasChecked
      ) {
        isChecked = true
      }

      return isChecked
    },
    [formData]
  )

  const handleCheckDisable = useCallback(
    (key, record) => {
      let isDisable = false
      if ((key === PHOI_HOP || key === THEO_DOI) && roleId === record?.id) {
        isDisable = true
      }

      const dataChuTri = formData?.[CHU_TRI] || null
      if (
        (key === PHOI_HOP || key === THEO_DOI) &&
        !isEmpty(dataChuTri) &&
        record?.parentId === dataChuTri?.id &&
        record?.isLanhDaoPhongBan
      ) {
        isDisable = true
      }

      const isOutOfDate = handleCheckOutOfDate(
        formData?.executionDate,
        formData?.deadline
      )
      if (
        (key !== THEO_DOI && roleId !== record?.id && isOutOfDate) ||
        (key === THEO_DOI &&
          roleId !== record?.id &&
          isOutOfDate &&
          !record?.isCheckTheoDoi)
      ) {
        isDisable = true
      }

      return isDisable
    },
    [formData, roleId, handleCheckOutOfDate]
  )

  const column = [
    {
      title: 'Phòng ban/đơn vị',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: record => {
        const isDisable = handleCheckDisable(CHU_TRI, record)
        const formItem = formData?.[CHU_TRI]?.id === record?.id
        let isChecked = false
        if (formItem) {
          isChecked = true
        }

        if (
          (!hasShowAllDeparment && record?.id === mainDepartmentId) ||
          !record.type
        ) {
          return ''
        }

        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            disabled={isDisable}
            onChange={() => onChangeRadio(record)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: record => {
        const isDisable = handleCheckDisable(PHOI_HOP, record)
        const isChecked = handleCheckActive(PHOI_HOP, formData, record)
        const isIndeterminate = handleCheckIndeterminate(PHOI_HOP, record)
        if (!record.type) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable}
            onChange={e =>
              onChangeCheckbox(PHOI_HOP, record, e, isIndeterminate)
            }
          />
        )
      },
    },
    {
      title: 'TD',
      render: record => {
        const isDisable = handleCheckDisable(THEO_DOI, record)
        const isChecked = handleCheckActive(THEO_DOI, formData, record)
        const isIndeterminate = handleCheckIndeterminate(THEO_DOI, record)
        if (!record.type) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable}
            onChange={e =>
              onChangeCheckbox(THEO_DOI, record, e, isIndeterminate)
            }
          />
        )
      },
    },
  ]

  const changeFormValue = ({ name, value }) => {
    mountedSet(setFormData, {
      ...formData,
      [name]: value,
    })
  }

  const changeFormValidate = ({ name, value }) => {
    mountedSet(setValidateErrors, {
      ...validateErrors,
      [name]: value,
    })
  }

  const changeField = name => value => {
    setIsDisabledButtonDefault(false)
    changeFormValidate({ name })
    changeFormValue({ name, value })
  }

  const validateRequired = (name, key) => {
    if (!!get(formData, key ? `${name}.${key}` : name)) {
      changeFormValidate({ name, value: false })
      return true
    }

    changeFormValidate({ name, value: true })
    return false
  }

  const validateExecutor = () => {
    return validateRequired(CHU_TRI, 'id')
  }

  const validate = () => {
    const success = validateExecutor()
    if (!success) {
      throw new Error('warning')
    }
  }

  const getRequestData = () => {
    const description = get(formData, 'description') || undefined
    const deadline = get(formData, 'deadline')
      ? moment(get(formData, 'deadline')).startOf('day').format(dateRequest)
      : undefined
    const executionDate = moment(formData?.executionDate).isValid()
      ? moment(formData?.executionDate).format(dateRequest)
      : undefined
    const phoiHop = get(formData, PHOI_HOP, [])?.map(elm => ({
      responsibilityId: get(elm, 'id'),
      responsibilityType: RESPONSIBILITY_TYPE.PHOI_HOP,
      assignType: get(elm, 'type'),
      description,
      deadline,
      executionDate,
    }))
    let fTyle = 'PERSONAL'
    if (
      hasShowAllDeparment &&
      (taskDetail?.workType === WORK_TYPE.MEETING || taskDetail?.referenceId)
    ) {
      fTyle = 'DEPARTMENT'
    }

    const theoDoi = get(formData, THEO_DOI, [])
      ?.filter(f => f.type === fTyle)
      ?.map(elm => ({
        responsibilityId: get(elm, 'id'),
        responsibilityType: RESPONSIBILITY_TYPE.THEO_DOI,
        assignType: get(elm, 'type'),
        description,
        deadline,
        executionDate,
      }))

    return [
      {
        responsibilityId: get(formData, `${CHU_TRI}.id`),
        responsibilityType: RESPONSIBILITY_TYPE.CHU_TRI,
        assignType: get(formData, `${CHU_TRI}.type`),
        description,
        deadline,
        executionDate,
      },
      ...phoiHop,
      ...theoDoi,
    ].map(user => toDecamelizeKeys(user))
  }

  const handleFavoriteGroupSave = () => {
    if (formData?.isFavoriteGroupSave) {
      const pushData = (arr = []) => {
        if (!arr || arr?.length === 0) {
          return []
        }

        return arr.reduce((result, item) => {
          if (item?.type === 'PERSONAL') {
            return [...result, item]
          } else if (item?.type !== 'PERSONAL') {
            return [...result, ...item?.children]
          }

          return result
        }, [])
      }

      const convertData = (arr = []) => {
        if (!arr || arr?.length === 0) {
          return []
        }

        return uniqBy(
          arr?.map(item => ({
            avatar: item?.anhDaiDien ? item?.anhDaiDien : item?.avatar,
            department_id: item?.parentId,
            department_name: item?.tenPhongBan
              ? item?.tenPhongBan
              : item?.departmentName
              ? item?.departmentName
              : item?.information?.department?.name,
            department_shortname: item?.information?.department?.shortName
              ? item?.information?.department?.shortName
              : item?.departmentShortname
              ? item?.departmentShortname
              : item?.tenPhongBan,
            id: item?.chucDanhId ? item?.chucDanhId : item?.id,
            name: item?.tenNhanVien ? item?.tenNhanVien : item?.name,
          })),
          'id'
        )
      }

      const newTree =
        dataTreeStoreNew && size(dataTreeStoreNew) !== 0
          ? dataTreeStoreNew
          : dataTreeStore
      let chuTri = []
      if (formData?.CHU_TRI?.id) {
        if (formData?.CHU_TRI?.type !== 'PERSONAL') {
          chuTri =
            newTree?.find(f => f?.id === formData?.CHU_TRI?.id)?.children || []
        } else {
          chuTri = [formData?.CHU_TRI]
        }
      }

      const tempUsers = [
        ...chuTri,
        ...(formData?.PHOI_HOP && formData.PHOI_HOP?.length !== 0
          ? pushData(formData.PHOI_HOP)
          : []),
        ...(formData?.THEO_DOI && formData.THEO_DOI?.length !== 0
          ? pushData(formData.THEO_DOI)
          : []),
      ]?.filter(f => f?.type === 'PERSONAL')
      try {
        requestAction({
          action: () => {
            const params = {
              name: `Nhóm ${tempUsers?.length} thành viên`,
              members: convertData(tempUsers),
              isActive: true,
            }
            return postFavoriteGroup(toDecamelizeKeys(params))
          },
          showToast: false,
        })
        return true
      } catch (_) {
        return true
      }
    }

    return true
  }

  const submit = async () => {
    let requestData = []
    requestAction({
      codeCheck: false,
      getResult: false,
      showToast: false,
      beforeAction: () => {
        mountedSet(setSubmitLoading, true)
        validate()
      },
      action: async () => {
        requestData = await getRequestData()
        handleFavoriteGroupSave()
        return postGiaoViec(id, requestData)
      },
      afterResponse: res => {
        showToast({
          message: res?.message,
          intent: res?.result ? Intent.SUCCESS : Intent.DANGER,
        })

        if (!res?.result) {
          return
        }

        if (status === WORK_STATUS.CHUA_THUC_HIEN) {
          reloadList()
        }

        reloadThongKe()
        onClose()
      },
      afterAction: () => {
        mountedSet(setSubmitLoading, false)
        mountedSet(setIsDisabledButtonDefault, true)
      },
      errorMessage: Toast.FAIL(Action.ASSIGN),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const reloadThongKe = () => {
    getThongKeLoaiGiaoViec()
    if (type === 'duoc-giao') {
      getThongKeTrangThaiCongViec({
        responsibilityType: STATISTIC_WORK_RESPONSIBILITY.ASSIGN_TO_ME,
      })
    }
  }

  const convertToStateData = useCallback(
    data => {
      let execSrc = { ...data }
      let execKey = 'mainResponsibility'
      const mainRespon = get(data, 'responsibilities[0].workAssignments', [])
      const assigners = mainRespon.reduce(
        (result, assigner) => [...result, assigner],
        []
      )
      const currentUserAssigner = findLast(assigners, {
        assignerId: roleId,
      })
      if (currentUserAssigner) {
        execSrc = { ...currentUserAssigner }
        execKey = 'responsibility'
      }

      const deadline = get(execSrc, `deadline`)
      let executionDate = moment()
      if (isGiveTaskEdit && execSrc?.executionDate) {
        executionDate = moment(execSrc?.executionDate)
      }

      let exec = {
        deadline: deadline && moment(deadline),
        executionDate: executionDate,
        allowClearDeadline: !deadline,
      }

      if (currentUserAssigner || roleId === get(data, 'creatorId')) {
        exec = {
          ...exec,
          executor: {
            id: get(execSrc, `${execKey}.id`),
            type: get(execSrc, `${execKey}.type`),
          },
          executorLabel:
            get(
              execSrc,
              `${execKey}.${
                get(execSrc, `${execKey}.type`) === ASSIGN_TYPE.CA_NHAN
                  ? 'name'
                  : 'shortName'
              }`
            ) || '',
          description: get(execSrc, 'description') || '',
        }
      }

      const coordinations = get(data, 'responsibilities[1].workAssignments', [])
      const originCoordinations = coordinations?.map(coordination => ({
        ...coordination?.responsibility,
        id: get(coordination, 'responsibilityId'),
        type: get(coordination, 'assignType'),
        parentId: get(coordination, 'responsibility.information.department.id'),
        isLanhDaoPhongBan: coordination?.responsibility?.isLanhDaoPhongBan,
      }))
      const watchers = get(data, 'responsibilities[2].workAssignments', [])
      const originWatchers = watchers?.map(watcher => ({
        ...watcher?.responsibility,
        id: get(watcher, 'responsibilityId'),
        type: get(watcher, 'assignType'),
        parentId: get(watcher, 'responsibility.information.department.id'),
        isLanhDaoPhongBan: watcher?.responsibility?.isLanhDaoPhongBan,
      }))

      const isAssignEdit = isGiveTaskEdit ? true : false
      const main = get(data, 'responsibilities[0].workAssignments[0]')
      return {
        ...exec,
        ...(main && isAssignEdit
          ? {
              [CHU_TRI]: {
                ...main?.responsibility,
                id: main?.responsibilityId,
                type: main?.assignType,
                isLanhDaoPhongBan: main?.responsibility?.isLanhDaoPhongBan,
              },
            }
          : {}),
        [PHOI_HOP]: isAssignEdit ? originCoordinations : [],
        [THEO_DOI]: isAssignEdit ? originWatchers : [],
      }
    },
    [roleId, isGiveTaskEdit]
  )

  const handleDataParent = useCallback(
    data => {
      const newParent = []
      const mainResponsibility = data?.mainResponsibility?.information
      if (mainResponsibility) {
        const departmentId = mainResponsibility?.department?.id
        const organizationId = mainResponsibility?.organization?.id
        if (departmentId) {
          newParent.push(departmentId)
        }

        if (organizationId) {
          newParent.push(organizationId)
        }

        if (
          data?.mainResponsibility?.type === PHONG_BAN ||
          data?.mainResponsibility?.type === DON_VI
        ) {
          newParent.push(data?.mainResponsibility?.id)
        }
      }

      const coordinations = data?.responsibilities?.[1]?.workAssignments || []
      if (size(coordinations) !== 0) {
        coordinations.forEach(elm => {
          const departmentId = elm?.responsibility?.information?.department?.id
          const organizationId =
            elm?.responsibility?.information?.organization?.id
          if (departmentId) {
            newParent.push(departmentId)
          }

          if (organizationId) {
            newParent.push(organizationId)
          }
        })
      }

      const watchers = get(data, 'responsibilities[2].workAssignments', [])
      if (size(watchers) !== 0) {
        watchers.forEach(elm => {
          const departmentId = elm?.responsibility?.information?.department?.id
          const organizationId =
            elm?.responsibility?.information?.organization?.id
          if (departmentId) {
            newParent.push(departmentId)
          }

          if (organizationId) {
            newParent.push(organizationId)
          }
        })
      }

      const newParentConvert = uniq(newParent)
      if (size(newParentConvert) !== 0) {
        setDataAutoToggle([...newParentConvert, mainDepartmentId, mainUnitId])
      }
    },
    [setDataAutoToggle, mainDepartmentId, mainUnitId]
  )

  const convertUsers = useCallback(
    values => ({
      ...values,
      id: values?.chucDanhId,
      type: 'PERSONAL',
      name: values?.tenNhanVien,
      tenChucVu: values?.chucVu,
      maChucVu: values?.maChucVu,
      parentId: values?.parentId,
    }),
    []
  )

  const fetchPhongBanDonVi = useCallback(
    async ({ workType, assignType }) => {
      if (action !== 'giao-viec' && !isOutOfDate) {
        setDataTreeStoreNew([])
        return
      }

      let params = {
        donViId: mainUnitId,
        phongBanId: mainDepartmentId,
        workType,
        assignType,
        limit: 9999,
      }
      await requestAction({
        showToast: false,
        successCode: 200,
        beforeAction: () => {
          setIsLoadingTree(true)
        },
        action: () => getPhongBanNhanVien(params),
        afterResponse: response => {
          let newData = []
          let newWatchers = []
          if (response?.items) {
            newData = response?.items?.map(elm => {
              return {
                id: elm?.phongBanId,
                type: convertType('PB'),
                name: elm?.maPhongBan,
                code: elm?.maPhongBan,
                parentId: elm?.donViId,
                children:
                  elm?.nhanViens?.map(u =>
                    convertUsers({ ...u, parentId: elm?.phongBanId })
                  ) || [],
              }
            })
            newWatchers = newData.reduce((result, item) => {
              const newChil =
                item?.children?.filter(e => e?.isCheckTheoDoi) || []
              return [...result, ...newChil]
            }, [])
            if (size(newWatchers) !== 0) {
              newWatchers = differenceBy(newWatchers, [{ id: roleId }], 'id')
              setFormData(prev => ({
                ...prev,
                [THEO_DOI]: newWatchers,
              }))
            }
          }
          setDataTreeStoreNew(newData)
        },
        afterAction: () => {
          setIsLoadingTree(false)
        },
      })
    },
    [mainUnitId, mainDepartmentId, convertUsers, action, roleId, isOutOfDate]
  )

  const fetchDetail = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getCongViec(id),
      afterResponse: detail => {
        const isDepartment = detail?.mainResponsibility?.type === PHONG_BAN
        const isOrganization = detail?.mainResponsibility?.type === DON_VI
        if (isDepartment || isOrganization) {
          fetchPhongBanDonVi({
            workType: detail?.workType,
            assignType: detail?.mainResponsibility?.type,
          })
        }
        handleDataParent(detail)
        mountedSet(setTaskDetail, detail)
        setFormData(prev => ({
          ...prev,
          ...convertToStateData(detail),
        }))
      },
      afterAction: () => mountedSet(setFetching, false),
    })
    // eslint-disable-next-line
  }, [id, convertToStateData, fetchPhongBanDonVi])

  const fetchParentDetail = useCallback(parentId => {
    requestAction({
      showToast: false,
      action: () => getCongViec(parentId),
      afterResponse: parentDetail => {
        setTaskParentDetail(parentDetail)
      },
    })
  }, [])

  const convertType = type => {
    if (type === 'PB') {
      return 'DEPARTMENT'
    } else if (type === 'DV') {
      return 'ORGANIZATION'
    }

    return 'PERSONAL'
  }

  const _fetchFavoriteGroup = useCallback(() => {
    requestAction({
      showToast: false,
      action: () => getMyFavoriteGroups({ is_active: true }),
      afterResponse: ({ items }) => {
        mountedSet(
          setFavoriteGroups,
          items.map(({ id, name, members }) => ({
            id,
            name,
            children: members.map(({ id, name, avatar, departmentId }) => ({
              id,
              parentId: departmentId,
              name,
              type: convertType('PERSONAL'),
              avatar,
            })),
          }))
        )
      },
    })
  }, [])

  const kiemTraQuaHan = useCallback(() => {
    let isDisable = false
    const isCheckOutOfDate = handleCheckOutOfDate(
      formData?.executionDate,
      formData?.deadline
    )
    if (isCheckOutOfDate) {
      isDisable = true
    }

    setIsOutOfDate(isDisable)
  }, [formData, handleCheckOutOfDate])

  const setDefaultChuTri = useCallback(() => {
    if (isOutOfDate) {
      setFormData(prev => ({
        ...prev,
        [CHU_TRI]: {
          id: roleId,
          type: 'PERSONAL',
        },
        executionDate: moment(),
      }))
    }
  }, [isOutOfDate, roleId])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (taskDetail && taskDetail?.parentId) {
      fetchParentDetail(taskDetail?.parentId)
    }
  }, [taskDetail, fetchParentDetail])

  useEffect(() => {
    kiemTraQuaHan()
  }, [kiemTraQuaHan])

  useEffect(() => {
    setDefaultChuTri()
  }, [setDefaultChuTri])

  useEffect(() => {
    _fetchFavoriteGroup()
  }, [_fetchFavoriteGroup])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  useEffect(() => {
    mountedSet(setValidateErrors, {})
  }, [id])

  return (
    <div className={classNames('cpc-side-action', 'open', 'cpc-form')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>{showTitle}</span>
        <Icon
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          onClick={onClose}
        />
      </h1>
      <Scrollbar maxHeight={window.innerHeight - 162}>
        <div style={{ padding: 10 }}>
          <div className="tree-organization-task">
            <div className="save-favorite-group">
              <Checkbox
                className={classNames(Classes.SMALL, 'mb0')}
                onChange={e =>
                  changeField('isFavoriteGroupSave')(e?.target?.checked)
                }
                checked={formData?.isFavoriteGroupSave || false}
              >
                <span className="font-size-13 font-weight-300">
                  Lưu nhóm thường giao việc
                </span>
              </Checkbox>
            </div>
            <PhongBanVaDonViThucHien
              className="mb10"
              isRequired={true}
              isLoading={fetching || isLoadingTree}
              column={column}
              data={
                dataTreeStoreNew && size(dataTreeStoreNew) !== 0
                  ? dataTreeStoreNew
                  : dataTreeStore
              }
              dataFavorite={favoriteGroups}
              isDisplayFavoriteGroup={
                !isOutOfDate &&
                (!taskDetail?.workType ||
                  (taskDetail?.workType &&
                    (taskDetail?.workType === WORK_TYPE.NORMAL ||
                      (taskDetail?.workType === WORK_TYPE.EOFFICE &&
                        !hasShowAllDeparment) ||
                      (taskDetail?.workType === WORK_TYPE.MEETING &&
                        !hasShowAllDeparment) ||
                      (taskDetail?.workType === WORK_TYPE.DOFFICE &&
                        !hasShowAllDeparment))))
              }
              dataAutoToggle={dataAutoToggle}
              taskId={id}
              isHideChild={
                (hasShowAllDeparment &&
                  (taskDetail?.workType === WORK_TYPE.MEETING ||
                    taskDetail?.workType === WORK_TYPE.EOFFICE)) ||
                (hasShowAllDeparment &&
                  (taskDetail?.workType === WORK_TYPE.CHAT ||
                    taskDetail?.workType === WORK_TYPE.SERVICE_DESK)) ||
                false
              }
              dataDisplayChild={
                isOutOfDate && mainDepartmentId ? [mainDepartmentId] : []
              }
              warning={get(validateErrors, CHU_TRI)}
            />
          </div>
          <Row gutterVertical>
            <Col grid="half">
              <FormDatePicker
                textLabel="Ngày bắt đầu"
                disabled={fetching || isOutOfDate}
                inputClearable={!isOutOfDate}
                inputIconClassName="icon2-date-frames"
                inputWrapperClassName="mb10"
                onChange={value => {
                  changeField('executionDate')(value)
                }}
                minimumDate={handleExecutionDateMin()}
                maximumDate={
                  get(formData, 'deadline')
                    ? moment(get(formData, 'deadline')).toDate()
                    : undefined
                }
                placeholder="Chọn ngày bắt đầu"
                popoverStretch={false}
                popoverPosition={Position.TOP_LEFT}
                selected={get(formData, 'executionDate')}
              />
            </Col>
            <Col grid="half">
              <DateAdvanced
                textLabel="Hạn thực hiện"
                disabled={fetching || isOutOfDate}
                inputClearable={!isOutOfDate && !taskParentDetail?.deadline}
                inputIconClassName="icon2-date-frames"
                inputWrapperClassName="mb10"
                placeholder="Chọn hạn thực hiện"
                popoverStretch={false}
                selected={get(formData, 'deadline')}
                inputWarning={get(validateErrors, 'deadline')}
                textWarning={
                  isOutOfDate
                    ? 'Công việc này đã quá hạn giải quyết theo chỉ đạo! Anh/chị chỉ giao được công việc này cho chính mình'
                    : null
                }
                minimumDate={
                  get(formData, 'executionDate')
                    ? moment(get(formData, 'executionDate')).toDate()
                    : moment().toDate()
                }
                maximumDate={
                  get(taskDetail, 'deadline') &&
                  taskDetail?.creatorId !== roleId &&
                  taskDetail?.assigner?.id !== roleId
                    ? moment(get(taskDetail, 'deadline')).toDate()
                    : !isEmpty(taskParentDetail) && taskParentDetail?.deadline
                    ? moment(taskParentDetail?.deadline).toDate()
                    : null
                }
                popoverPosition={Position.TOP_RIGHT}
                onChange={value => {
                  changeField('deadline')(value)
                }}
              />
            </Col>
          </Row>
          <Input
            disabled={fetching}
            label="Mô tả"
            onChange={changeField('description')}
            placeholder="Nhập..."
            rows={2}
            maxLength={500}
            autoResize={true}
            style={{ maxHeight: 'unset' }}
            type="textarea"
            value={get(formData, 'description', '')}
            wrapperClassName="mb10"
          />
        </div>
      </Scrollbar>
      <div
        className={classNames('element-center', 'mb10', 'pt10', 'border-top')}
      >
        <Button
          className={classNames(
            'cpc-button',
            'btn-cancel',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-100',
            'mr15'
          )}
          disabled={fetching}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={classNames(
            'cpc-button',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-80'
          )}
          disabled={fetching || isDisabledButtonDefault}
          intent={Intent.PRIMARY}
          onClick={submit}
          loading={submitLoading}
        >
          Lưu
          <Icon classIcon="icon-save" className="ml5" />
        </Button>
      </div>
    </div>
  )
}

export default withRouter(memo(GiveTask))
