import React, { memo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from 'react-calendar-timeline'
import { Button } from '@blueprintjs/core'

import { WORK_STATUS, WORK_TYPE } from 'constants/Enum'
import { MESSAGE_NOT_FOUND } from 'constants/MessageForm'
import { Avatar, Empty, Loading } from 'components/newCommon'
const imageWorkEmpty = `${process.env.PUBLIC_URL}/images/empty.svg`

const Index = ({
  filterKeys,
  filterData,
  isFetching,
  dataSource,
  onItemClick,
  isDefault,
  onSetIsDefault,
  onChange: onChangeFilter,
}) => {
  const requestDateFormat = 'YYYY/MM/DD'
  let scrollRefs
  const [isVisible, setIsVisible] = useState(true)
  const [defaultTimeStart, setDefaultTimeStart] = useState(
    moment().startOf('month').toDate()
  )
  const [defaultTimeEnd, setDefaultTimeEnd] = useState(
    moment().startOf('month').add(1, 'month').toDate()
  )
  const [visibleTimeStart, setVisibleTimeStart] = useState(
    moment().startOf('month').valueOf()
  )
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    moment().startOf('month').add(1, 'month').valueOf()
  )

  const groupConvert = value => {
    const isPersonal = value?.mainResponsibility?.type === 'PERSONAL'
    return {
      ...value,
      key: value?.id,
      id: value?.mainResponsibilityId,
      title: (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {isPersonal ? (
            <Avatar
              src={value?.mainResponsibility?.avatar}
              className="mr10"
              tinytan
              needBaseUrl
            />
          ) : (
            ''
          )}
          <span
            style={{ whiteSpace: 'normal', lineHeight: '18px' }}
            className={classNames('text-color font-size-12')}
          >
            {isPersonal
              ? value?.mainResponsibility?.name
              : value?.mainResponsibility?.shortName}
          </span>
        </div>
      ),
      bgColor: '#000',
    }
  }

  const itemConvert = value => {
    let bg = 'rgba(229, 232, 237, 1)'
    let border = 'rgba(203, 203, 203, 0.3)'
    let color = 'rgba(203, 203, 203, 1)'
    let start = moment(value?.executionDate)
    let end = moment(value?.deadline)
    const today = moment().startOf('day')
    const diff = end.startOf('day').diff(today, 'days')
    if (diff < 0 && value?.status !== 'DONE') {
      bg = '#FFE7E7'
      color = 'rgba(238, 81, 81, 1)'
      border = 'rgba(238, 81, 81, 0.3)'
    } else if (value?.status === WORK_STATUS.CHUA_THUC_HIEN) {
      bg = '#FFF8EC'
      color = 'rgba(247, 144, 10, 1)'
      border = 'rgba(247, 144, 10, 0.3)'
    } else if (value?.status === WORK_STATUS.DANG_THUC_HIEN) {
      bg = '#F0F9FF'
      color = 'rgba(8, 79, 208, 1)'
      border = 'rgba(8, 79, 208, 0.3)'
    } else if (value?.status === WORK_STATUS.HOAN_THANH) {
      bg = '#ECFDF3'
      color = 'rgba(18, 183, 106, 1)'
      border = 'rgba(18, 183, 106, 0.3)'
    }

    if (!value?.executionDate && value?.deadline) {
      let stringDate = moment(value?.deadline).format('YYYY/MM/DD 00:00:00')
      if (value?.creationTime) {
        stringDate = moment(value.creationTime).format('YYYY/MM/DD 00:00:00')
      }

      start = moment(stringDate)
    }

    if (!value?.deadline && value?.executionDate) {
      const stringDate = moment(value?.executionDate)
        .add(20, 'years')
        .format('YYYY/MM/DD 23:59:59')
      end = moment(stringDate)
    }

    return {
      ...value,
      key: value?.id,
      id: value?.id,
      group: value?.mainResponsibilityId,
      title: value?.title,
      start: start,
      end: end,
      bgColor: bg,
      selectedBgColor: bg,
      borderColor: border,
      color: color,
    }
  }

  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
    const backgroundColor = item?.bgColor

    return (
      <div
        {...getItemProps({
          className: 'item-active',
          style: {
            backgroundColor,
            background: backgroundColor,
            color: item?.color,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 20,
            borderColor: item?.borderColor,
            borderLeftWidth: itemContext?.selected ? 3 : 1,
            borderRightWidth: itemContext?.selected ? 3 : 1,
          },
          onMouseDown: () => {
            return onItemClick(item)
          },
        })}
      >
        {itemContext?.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          className="timeline-item"
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
          }}
        >
          {item?.workType === WORK_TYPE.MEETING ? (
            <span className="icon2-clipboard"></span>
          ) : (
            ''
          )}
          {item?.workType === WORK_TYPE.EOFFICE ? (
            <span className="icon2-e-office-label"></span>
          ) : (
            ''
          )}
          {item?.workType === WORK_TYPE.DOFFICE ? (
            <span className="icon2-d-office"></span>
          ) : (
            ''
          )}
          {item?.workType === WORK_TYPE.CHAT ? (
            <span className="icon2-chat-type"></span>
          ) : (
            ''
          )}
          {item?.workType === WORK_TYPE.SERVICE_DESK ? (
            <span className="icon2-call-type"></span>
          ) : (
            ''
          )}
          <span className="name">{itemContext?.title}</span>
        </div>
        {itemContext?.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    )
  }

  const onTimeChange = (
    visibleTimeStart,
    visibleTimeEnd,
    updateScrollCanvas
  ) => {
    setIsVisible(false)
    const minTime = moment().add(-6, 'months').valueOf()
    const maxTime = moment().add(6, 'months').valueOf()
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime)
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
    }
  }

  const onPrevClick = () => {
    const values = {
      [filterKeys.from]: moment(filterData?.[filterKeys.from])
        .subtract(1, 'months')
        .format(requestDateFormat),
      [filterKeys.to]: filterData?.[filterKeys.from],
    }

    onChangeFilter({ isMultipleKey: true })(values)

    setDefaultTimeStart(
      moment(filterData?.[filterKeys.from]).startOf('month').toDate()
    )
    setDefaultTimeEnd(
      moment(filterData?.[filterKeys.from])
        .startOf('month')
        .add(1, 'month')
        .toDate()
    )
    setVisibleTimeStart(
      moment(filterData?.[filterKeys.from]).startOf('month').valueOf()
    )
    setVisibleTimeEnd(
      moment(filterData?.[filterKeys.from]).endOf('month').valueOf()
    )
  }

  const onNextClick = () => {
    const values = {
      [filterKeys.from]: filterData?.[filterKeys.to],
      [filterKeys.to]: moment(filterData?.[filterKeys.to])
        .subtract(-1, 'months')
        .format(requestDateFormat),
    }

    onChangeFilter({ isMultipleKey: true })(values)

    setDefaultTimeStart(
      moment(filterData?.[filterKeys.to]).endOf('month').toDate()
    )
    setDefaultTimeEnd(
      moment(filterData?.[filterKeys.to])
        .endOf('month')
        .add(1, 'month')
        .toDate()
    )
    setVisibleTimeStart(
      moment(filterData?.[filterKeys.to])
        .subtract(-1, 'months')
        .startOf('month')
        .valueOf()
    )
    setVisibleTimeEnd(
      moment(filterData?.[filterKeys.to])
        .subtract(-1, 'months')
        .endOf('month')
        .valueOf()
    )
  }

  const onZoomOutClick = () => {
    if (!isEmpty(scrollRefs)) {
      setIsVisible(false)
      scrollRefs.changeZoom(2)
    }
  }

  const onZoomInClick = () => {
    if (!isEmpty(scrollRefs)) {
      setIsVisible(false)
      scrollRefs.changeZoom(0.5)
    }
  }

  const onReset = useCallback(() => {
    if (!isDefault) {
      return
    }

    if (!isEmpty(scrollRefs)) {
      scrollRefs.changeZoom(1)
    }

    setDefaultTimeStart(moment().startOf('month').toDate())
    setDefaultTimeEnd(moment().startOf('month').add(1, 'month').toDate())
    setVisibleTimeStart(moment().startOf('month').valueOf())
    setVisibleTimeEnd(moment().startOf('month').add(1, 'month').valueOf())
    onSetIsDefault(false)
  }, [isDefault, scrollRefs, onSetIsDefault])

  const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start',
    itemTimeEndKey: 'end',
  }

  useEffect(() => {
    onReset()
  }, [onReset])

  return (
    <div className="timeline-calendar">
      <div className="timeline-calendar-header-right">
        <div className="timeline-calendar-arrow">
          <Button
            className="timeline-button"
            disabled={false}
            onClick={onPrevClick}
          >
            <span className="icon2-arrow-left font-size-10 icon-size-10 mr5"></span>
            <span className="font-size-12">Tháng trước</span>
          </Button>
          <Button
            className="timeline-button"
            disabled={false}
            onClick={onNextClick}
          >
            <span className="font-size-12">Tháng sau</span>
            <span className="icon2-arrow-right font-size-10 icon-size-10 ml5"></span>
          </Button>
        </div>
        <div className="timeline-calendar-arrow ml10">
          <Button
            className="timeline-button"
            disabled={false}
            onClick={onZoomOutClick}
          >
            <span className="icon-round-delete-button1 font-size-14 icon-size-14"></span>
          </Button>
          <Button
            className="timeline-button"
            disabled={false}
            onClick={onZoomInClick}
          >
            <span className="icon-rounded-add-button1 font-size-14 icon-size-14"></span>
          </Button>
        </div>
      </div>
      {!isFetching ? (
        <Timeline
          keys={keys}
          ref={el => (scrollRefs = el)}
          sidebarWidth={180}
          lineHeight={40}
          stackItems
          itemHeightRatio={0.75}
          traditionalZoom={true}
          buffer={1}
          minZoom={7 * 24 * 60 * 60 * 1000}
          maxZoom={5 * 365.24 * 86400 * 1000} // Thời gian lớn nhất lịch có thể zoom (5 năm)
          defaultTimeStart={defaultTimeStart}
          defaultTimeEnd={defaultTimeEnd}
          visibleTimeStart={isVisible ? visibleTimeStart : null}
          visibleTimeEnd={isVisible ? visibleTimeEnd : null}
          canMove={false}
          canResize={false}
          groups={
            dataSource
              ? uniqBy(dataSource, 'mainResponsibilityId')?.map(groupConvert)
              : []
          }
          items={dataSource?.map(itemConvert) ?? []}
          onTimeChange={onTimeChange}
          itemRenderer={itemRenderer}
        >
          <TimelineHeaders className="sticky">
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <div {...getRootProps()}>
                    <span>Chủ trì</span>
                  </div>
                )
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" height={50} />
            <DateHeader />
          </TimelineHeaders>
        </Timeline>
      ) : (
        ''
      )}
      {size(dataSource) === 0 && !isFetching ? (
        <div
          className="d-flex item-center justify-center"
          style={{ minHeight: 279, backgroundColor: '#fff' }}
        >
          <Empty
            image={imageWorkEmpty}
            size="SMALL"
            message={MESSAGE_NOT_FOUND}
          />
        </div>
      ) : (
        ''
      )}
      {isFetching && (
        <div style={{ backgroundColor: '#fff' }}>
          <Loading />
        </div>
      )}
    </div>
  )
}

Index.propTypes = {
  filterKeys: PropTypes.object,
  dataSource: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  onChange: PropTypes.func,
}

Index.defaultProps = {
  filterKeys: null,
  dataSource: [],
  onItemClick: () => {},
  onChange: () => {},
}

export default memo(Index)
