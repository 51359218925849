import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash'
import {
  Radio,
  Button,
  Intent,
  Popover,
  Position,
  RadioGroup,
  PopoverInteractionKind,
} from '@blueprintjs/core'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { patchWidgetHome, updateWidgetHome } from 'actions'
import { Utils } from 'helpers/index'
import { reorder } from 'helpers/dragdrop'
import { Icon } from 'components/newCommon'

const BACKGROUND_LIST = [
  {
    isLight: false,
    url: 'bg1.jpg',
  },
  {
    isLight: false,
    url: 'bg2.jpg',
  },
  {
    isLight: true,
    url: 'bg3.jpg',
  },
  {
    isLight: true,
    url: 'bg4.jpg',
  },
  {
    isLight: true,
    url: 'bg5.jpg',
  },
  {
    isLight: true,
    url: 'bg6.jpg',
  },
  {
    isLight: false,
    url: 'bg7.jpg',
  },
  {
    isLight: false,
    url: 'bg8.jpg',
  },
  {
    isLight: true,
    url: 'bg9.jpg',
  },
  {
    isLight: false,
    url: 'bg10.jpg',
  },
  {
    isLight: false,
    url: 'bg11.jpg',
  },
  {
    isLight: true,
    url: 'bg12.jpg',
  },
]

const Background = ({
  actions,
  nhanVienId,
  cauHinhDashboard,
  classNameIcon = '',
  isCloseBg = false,
}) => {
  const dashboardData = useMemo(() => {
    return cauHinhDashboard
  }, [cauHinhDashboard])
  const [cookies, setCookie] = useCookies(['home_background_image'])
  const [cookiesTheme, setCookiesTheme] = useCookies(['theme_menu'])
  const [isShowBackgroundBlock, setIsShowBackgroundBlock] = useState(false)
  const [smallBlockList, setSmallBlockList] = useState([])
  const [nguonCVBlockList, setNguonCVBlockList] = useState([])

  if (!get(cookies, 'home_background_image')) {
    setCookie('home_background_image', {
      url: 'home-bg.png',
      is_light: false,
    })
  }

  const changeBackground = (image, isLight) => {
    setCookie('home_background_image', {
      url: image,
      is_light: isLight,
    })
  }

  const onSaveData = useCallback(
    (name, data) => {
      const cauHinhDashboard = [
        ...dashboardData
          ?.filter(f => f?.block === 'BANLAMVIEC')
          ?.map(e => ({
            ...e,
            viTri: name === 'VITRIBANLAMVIEC' ? data : e?.viTri,
            subBlock: name === 'BANLAMVIECCON' ? data : e?.subBlock,
          })),
        ...(name === 'BIEUDO' ? data : smallBlockList),
      ]
      actions
        .patchWidgetHome(nhanVienId, {
          cau_hinh_dashboard: cauHinhDashboard?.map(e =>
            Utils.linkObjectKey(e)
          ),
        })
        .then(res => {
          actions.updateWidgetHome(cauHinhDashboard)
        })
    },
    [actions, nhanVienId, dashboardData, smallBlockList]
  )

  const onSwitchTheme = e => {
    const value = e?.target?.value
    setCookie('theme_menu', value)
  }

  const onSwitchBanLamViec = e => {
    const value = e?.target?.value
    onSaveData('VITRIBANLAMVIEC', Number(value))
  }

  const onDragEnd = useCallback(
    data => {
      const { source, destination } = data
      if (!destination) {
        return
      }

      let tempData = [...smallBlockList]
      if (source.droppableId === destination.droppableId) {
        tempData = reorder(tempData, source.index, destination.index)
      }
      setSmallBlockList(tempData?.map((e, i) => ({ ...e, stt: i + 1 })))
      onSaveData(
        'BIEUDO',
        tempData?.map((e, i) => ({ ...e, stt: i + 1 }))
      )
    },
    [smallBlockList, onSaveData]
  )

  const onDragEndNgonBLock = useCallback(
    data => {
      const { source, destination } = data
      if (!destination) {
        return
      }

      let tempData = [...nguonCVBlockList]
      if (source.droppableId === destination.droppableId) {
        tempData = reorder(tempData, source.index, destination.index)
      }
      setNguonCVBlockList(tempData?.map((e, i) => ({ ...e, stt: i })))
      onSaveData(
        'BANLAMVIECCON',
        tempData?.map((e, i) => ({ ...e, stt: i }))
      )
    },
    [nguonCVBlockList, onSaveData]
  )

  const renderTitle = keyName => {
    if (!keyName) {
      return ''
    }

    const dataName = {
      KYSO: 'Ký số',
      CONGVAN: 'Công văn',
      QUANLYCONGVIEC: 'Quản lý công việc',
      CONGVIEC: 'Công việc',
      CONGVIECKHAC: 'Công việc Khác',
      TBKLCH: 'Công việc Thông báo KLCH',
      KYSOCONGVAN: 'Công việc Ký số, Công văn',
      CHAT: 'Công việc Chat',
      DOFFICE: 'Công việc Doffice',
    }

    return dataName[`${keyName}`]
  }

  const renderItemStyle = (isDragging, draggableStyle) => {
    return {
      ...draggableStyle,
      marginTop: 10,
      position: isDragging ? 'absolute' : 'relative',
      left: 'auto',
      top: 'auto',
      color: isDragging ? '#195fff' : '#000',
    }
  }

  const renderIconStyle = isDragging => {
    return {
      color: isDragging ? '#195fff' : '#777777',
    }
  }

  useEffect(() => {
    setSmallBlockList(dashboardData?.filter(f => f?.block !== 'BANLAMVIEC'))
    setNguonCVBlockList(
      dashboardData?.find(f => f?.block === 'BANLAMVIEC')?.subBlock
    )
  }, [dashboardData])

  useEffect(() => {
    if (!cookiesTheme?.theme_menu) {
      setCookiesTheme('theme_menu', 'HORIZONTAL')
    }
  }, [setCookiesTheme, cookiesTheme])

  useEffect(() => {
    if (isCloseBg) {
      setIsShowBackgroundBlock(false)
    }
  }, [isCloseBg])

  return (
    <Popover
      popoverClassName="popover-background-list cpc-popover no-arrow mt5"
      position={Position.BOTTOM_RIGHT}
      interactionKind={PopoverInteractionKind.CLICK}
      onClose={() => setIsShowBackgroundBlock(false)}
      content={
        <div>
          <div className="p20">
            <div className="d-flex align-items-center justify-content-space-between gap-10">
              <span className="text-14-500">Kiểu trình đơn</span>
              <RadioGroup
                className="cpc-radio cpc-radio-group"
                name="theme_menu"
                onChange={onSwitchTheme}
                selectedValue={cookiesTheme?.theme_menu}
              >
                <Radio
                  className="pt-large pt-text font-size-14 mr15 mb0 width-70"
                  label={'Ngang'}
                  value={'HORIZONTAL'}
                />
                <Radio
                  className="pt-large pt-text font-size-14 mb0 width-60"
                  label={'Dọc'}
                  value={'VERTICAL'}
                />
              </RadioGroup>
            </div>
            <div className="d-flex align-items-center justify-content-space-between gap-10 mt20">
              <span className="text-14-500">Bàn làm việc</span>
              <div>
                <RadioGroup
                  className="cpc-radio cpc-radio-group"
                  name="banLamViec"
                  onChange={onSwitchBanLamViec}
                  selectedValue={
                    cauHinhDashboard?.find(e => e?.block === 'BANLAMVIEC')
                      ?.viTri
                  }
                >
                  <Radio
                    className="pt-large pt-text font-size-14 mb0 mr15 width-70"
                    label={'Trái'}
                    value={0}
                  />
                  <Radio
                    className="pt-large pt-text font-size-14 mb0 width-60"
                    label={'Phải'}
                    value={1}
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="mt18">
              <span className="text-14-500">Thứ tự biểu đồ</span>
              <div className="mt14">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="list"
                    type="COLUMN"
                    direction="vertical"
                  >
                    {(dropProvided, dropSnapshot) => (
                      <div ref={dropProvided.innerRef}>
                        {smallBlockList?.map((item, index) => {
                          return (
                            <Draggable
                              key={index}
                              draggableId={`${index}`}
                              index={index}
                              // isDragDisabled={isDragDisabled}
                            >
                              {(dragProvided, dragSnapshot) => (
                                <div
                                  key={`${index}`}
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.draggableProps}
                                  {...dragProvided.dragHandleProps}
                                  style={renderItemStyle(
                                    dragSnapshot.isDragging,
                                    dragProvided.draggableProps.style
                                  )}
                                >
                                  <div className="d-flex align-items-center gap-10 cursor-move">
                                    <span
                                      className="icon2-dragdrop color-gray font-size-10"
                                      style={renderIconStyle(
                                        dragSnapshot.isDragging
                                      )}
                                    ></span>
                                    <span className="font-size-14">
                                      {renderTitle(item?.block)}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        })}
                        {dropProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            <div className="mt18">
              <span className="text-14-500">Thứ tự nguồn công việc</span>
              <div className="mt14">
                <DragDropContext onDragEnd={onDragEndNgonBLock}>
                  <Droppable
                    droppableId="list"
                    type="COLUMN"
                    direction="vertical"
                  >
                    {(dropProvided, dropSnapshot) => (
                      <div {...dropProvided.droppableProps}>
                        <div ref={dropProvided.innerRef}>
                          {nguonCVBlockList?.map((item, index) => {
                            return (
                              <Draggable
                                key={index}
                                draggableId={`${index}`}
                                index={index}
                              >
                                {(dragProvided, dragSnapshot) => (
                                  <div
                                    key={`${index}`}
                                    ref={dragProvided.innerRef}
                                    {...dragProvided.draggableProps}
                                    {...dragProvided.dragHandleProps}
                                    style={renderItemStyle(
                                      dragSnapshot.isDragging,
                                      dragProvided.draggableProps.style
                                    )}
                                  >
                                    <div className="d-flex align-items-center gap-10 cursor-move">
                                      <span
                                        className="icon2-dragdrop color-gray font-size-10"
                                        style={renderIconStyle(
                                          dragSnapshot.isDragging
                                        )}
                                      ></span>
                                      <span className="font-size-14">
                                        {renderTitle(item?.block)}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            )
                          })}
                          {dropProvided.placeholder}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
          <div className="pv20 pb10">
            <p className="border-top m0"></p>
          </div>
          <div className="pb10">
            <div
              className={classNames(
                'config-background-title d-flex align-items-center justify-content-space-between gap-10 ph10 pv20',
                {
                  active: isShowBackgroundBlock,
                }
              )}
              onClick={() => setIsShowBackgroundBlock(prev => !prev)}
            >
              <span className="text-14-500">Hình nền</span>
              <span className="icon2-arrow-right font-size-9"></span>
            </div>
            <div
              className={classNames('background-block', {
                active: isShowBackgroundBlock,
              })}
            >
              <div className="background-list-block mt10">
                <span className="text-14-500 d-block">Hình nền</span>
                <div className="background-list mt5">
                  {BACKGROUND_LIST.map((elm, index) => {
                    let background = `/backgrounds_home/thumbnail-${elm.url}`
                    return (
                      <div key={index} className="background-list-item">
                        <span
                          style={{ backgroundImage: `url(${background})` }}
                          className={classNames({
                            active:
                              get(cookies, 'home_background_image.url', '') ===
                              elm.url,
                          })}
                          onClick={() => changeBackground(elm.url, elm.isLight)}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <Button
        className={classNames(
          'cpc-button ml15',
          'p0',
          'rounded',
          classNameIcon
        )}
        intent={Intent.PRIMARY}
      >
        <Icon classIcon="icon-settings" />
      </Button>
    </Popover>
  )
}

Background.propTypes = {
  cauHinhDashboard: PropTypes.array,
}

const mapStateToProps = state => ({
  cauHinhDashboard: state.dashboard?.cauHinhDashboard,
  nhanVienId: state.auth.user?.nhanVienId,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      patchWidgetHome,
      updateWidgetHome,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Background)
