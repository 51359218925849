import * as types from '../constants/ActionTypes'

const initialState = {
  list: [],
}

export default function assignmentHistory(state = initialState, action) {
  switch (action.type) {
    case types.DOCUMENT_RECEIVE_ASSIGNMENT_HISTORY:
      return {
        ...state,
        list: action.payload.data.result.items,
      }
    default:
      return state
  }
}
