import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const NOT_UNIT = '00000000-0000-0000-0000-000000000000'
class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  // handleClick = (id, type) => {
  //   if (id !== NOT_UNIT) {
  //     const date = this.props.filter;
  //     return this.props.history && this.props.history.push({
  //       pathname: `/tien-ich/tinh-hinh-xu-ly-cong-van/chi-tiet/phong-ban/${id}`,
  //       ngayChiDao: {
  //         from: date.start_date,
  //         to: date.end_date
  //       },
  //       type
  //     });
  //   }
  // }

  handleSubmit = (id, isDonViPc) => {
    if (id !== NOT_UNIT && isDonViPc) {
      this.props.actions.refreshStatistic(false)
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/thong-ke-thu-hoi-cong-van/danh-sach/${id}`,
          id,
        })
      )
    }
  }

  render() {
    const { item, number } = this.props
    return (
      <tr
        className={classnames({
          'text-bold':
            item.donViId === NOT_UNIT && item.tenDonVi === 'Tổng cộng',
        })}
      >
        <td className="gerenal-list-utilities">
          {item.donViId !== NOT_UNIT && item.tenDonVi !== 'Tổng cộng'
            ? number > 9
              ? number
              : '0' + number
            : null}
        </td>
        <td
          className={classnames('gerenal-list-utilities', {
            'over-time cursor-pointer': item.isDonViPc,
          })}
          onClick={this.handleSubmit.bind(this, item.donViId, item.isDonViPc)}
        >
          {item.tenDonVi}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongVanThuHoiToanBo}
        </td>
      </tr>
    )
  }
}

export default ListItem
