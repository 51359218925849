import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import { get, isEmpty, intersection, intersectionBy } from 'lodash'
import { Permission } from '../../components/auth'
import { TEXT_BUTTOM_CLOSE, TEXT_SUBMIT_BODY } from '../../constants/Popup'
import {
  DA_XAY_RA_LOI,
  DICHUYEN_THANHCONG,
  DICHUYEN_KHONG_THANHCONG,
  TOATS_MESSAGE_SUCCESS,
  TOATS_MESSAGE_FAIL,
  TEXT_MESSAGE_DEFAULT,
  UPDATE_lY_DO_THU_hOI,
} from '../../constants/MessageForm'
import { DiChuyen } from '../../components/tutailieu'
import { SelectCheckBox } from '../../components/common'

const typePopup = {
  THEM_MOI_THU_MUC_CON: 'THEM-MOI-THU-MUC-CON',
  THEM_MOI_HSCV_CON: 'THEM-MOI-HSCV-CON',
  THEM_MOI_THU_MUC: 'THEM-MOI-THU-MUC',
  THEM_MOI_HSCV: 'THEM-MOI-HSCV',
  CHINH_SUA: 'CHINH-SUA',
  CHINH_SUA_HSCV_CON: 'CHINH-SUA-HSCV-CON',
  CHINH_SUA_THU_MUC_CON: 'CHINH-SUA-THU-MUC-CON',
  XOA: 'XOA',
  DUYET_HOSO: 'DUYET-HOSO',
  DUYET_LAI: 'DUYET-LAI',
  TIEP_NHAN: 'TIEP-NHAN',
  DI_CHUYEN: 'DI-CHUYEN',
  HUY_NOP: 'HUY-NOP',
  TIEP_NHAN_LAI: 'TIEP-NHAN-LAI',
}
class CapNhatHoSoCongViec extends Component {
  constructor(props) {
    super(props)
    this.state = {
      keyDisabledRadio: '',
      dataSelectNguoiPhoiHop: [],
      isLoadingButton: false,
      params: {},
      errorData: {},
      loaiChinhSua: '',
      danhSachViTriDiChuyen: [],
      viTriDiChuyen: '',
      isCheckNguoiChiuTrachNhiem: false,
    }
  }

  async componentDidMount() {
    await this.getDataSelect()
    // Kiểm tra props và gọi api chi tiết khi chỉnh sửa
    if (
      this.props.dataPopup.isOpenPopup &&
      this.props.dataPopup.type === typePopup.CHINH_SUA
    ) {
      this.getChiTiet()
    }
  }

  componentWillReceiveProps(props) {
    const { params } = this.state
    let dataSelectNguoiPhoiHop = []
    // Reset dữ liệu form khi tắt/mở popup
    if (props.dataPopup.isOpenPopup) {
      params.id = undefined
      params.maKyHieu = ''
      params.tenHoSo = ''
      params.ten = ''
      params.nam = moment().format('YYYY')
      params.nguoiPhoiHopDs = []
      this.setState({
        errorData: {},
      })
    }
    // Set loại chỉnh sửa
    let loaiChinhSua = ''
    if (
      typePopup.CHINH_SUA === this.props.dataPopup.type &&
      props.dataPopup.data &&
      props.dataPopup.data.loai === 0
    ) {
      loaiChinhSua = typePopup.CHINH_SUA_THU_MUC_CON
    }
    if (
      typePopup.CHINH_SUA === this.props.dataPopup.type &&
      props.dataPopup.data &&
      props.dataPopup.data.loai === 1
    ) {
      loaiChinhSua = typePopup.CHINH_SUA_HSCV_CON
    }
    // định dạng dữ liệu select multi
    if (props.staffs && props.staffs.length !== 0) {
      // Set dữ liệu params mặt định cho select box
      params.nguoiChiuTrachNhiemId = props.staffs[0].chucDanhId
      props.staffs.map(elm => {
        return dataSelectNguoiPhoiHop.push({
          value: elm.chucDanhId,
          label: elm.tenNhanVien,
        })
      })
    }
    // Set dữ liệu params mặt định cho select box
    if (
      props.thoiHanBaoQuanDanhSach &&
      props.thoiHanBaoQuanDanhSach.length !== 0
    ) {
      params.thoiHanBaoQuanId = props.thoiHanBaoQuanDanhSach[0].thoiHanLuuId
    }
    if (
      !isEmpty(props.dataPopup.data) &&
      (props.dataPopup.type === typePopup.DUYET_HOSO ||
        props.dataPopup.type === typePopup.TIEP_NHAN ||
        props.dataPopup.type === typePopup.DUYET_LAI ||
        props.dataPopup.type === typePopup.TIEP_NHAN_LAI)
    ) {
      params.ten = get(props.dataPopup.data, 'ten', '')
    }
    // Get danh sách dùng để di chuyển
    if (
      props.dataPopup.isOpenPopup === true &&
      props.dataPopup.type === typePopup.DI_CHUYEN
    ) {
      this.getDanhSachViTriDiChuyen()
    }

    this.setState({
      dataSelectNguoiPhoiHop,
      params: {
        ...params,
      },
      loaiChinhSua,
    })
  }

  /**
   * Get chi tiết thư mục hoặc hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  getChiTiet = async () => {
    try {
      const loai = this.props.dataPopup.data.loai
      const id = this.props.dataPopup.data.id
      if (loai === 0) {
        await this.props.actions.getChiTietThuMucHoSoCongViec(id).then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            return this.setState({
              params: {
                ...this.state.params,
                id: get(res.payload.data.result, 'id'),
                ten: get(res.payload.data.result, 'ten', ''),
                nam: get(res.payload.data.result, 'nam', ''),
                thuMucChaId: get(res.payload.data.result, 'thuMucChaId'),
              },
            })
          }
        })
      } else {
        await this.props.actions.getChiTietHoSoCongViec(id).then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            let dsNguoiPhoiHopFormatValue = []
            res.payload.data.result.dsNguoiPhoiHop &&
              res.payload.data.result.dsNguoiPhoiHop.length !== 0 &&
              res.payload.data.result.dsNguoiPhoiHop.map(elm => {
                return dsNguoiPhoiHopFormatValue.push(elm.chucDanhId)
              })
            // Khi chỉnh sửa disabled người chịu trách nhiệm
            let isCheckNguoiChiuTrachNhiem = false
            if (
              intersection(this.props.auth.permission, [
                Permission.TUTAILIEUPHONGBAN_QUANLY,
              ]).length === 0 &&
              intersectionBy(
                [
                  {
                    id: get(
                      res.payload.data.result.nguoiChiuTrachNhiem,
                      'chucDanhId'
                    ),
                  },
                ],
                get(this.props.auth.user, 'dsChucDanh'),
                'id'
              ).length !== 0
            ) {
              isCheckNguoiChiuTrachNhiem = true
            }
            return this.setState({
              params: {
                ...this.state.params,
                id: get(res.payload.data.result, 'id'),
                thuMucChaId: get(res.payload.data.result, 'thuMucChaId'),
                tenHoSo: get(res.payload.data.result, 'ten', ''),
                maKyHieu: get(res.payload.data.result, 'maKyHieu', ''),
                nam: get(res.payload.data.result, 'nam', ''),
                nguoiChiuTrachNhiemId: get(
                  res.payload.data.result.nguoiChiuTrachNhiem,
                  'chucDanhId',
                  this.props.staffs[0].chucDanhId
                ),
                thoiHanBaoQuanId: get(
                  res.payload.data.result.thoiHanBaoQuan,
                  'thoiHanLuuId',
                  this.props.thoiHanBaoQuanDanhSach[0].thoiHanLuuId
                ),
                nguoiPhoiHopDs: dsNguoiPhoiHopFormatValue,
              },
              isCheckNguoiChiuTrachNhiem,
            })
          }
        })
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
      this.props && this.props.onClose()
    }
  }

  /**
   * Get danh sách vị trí di chuyển
   * @memberof CapNhatHoSoCongViec
   */
  getDanhSachViTriDiChuyen = async () => {
    const { actions } = this.props
    try {
      this.setState({ isLoadingButton: true })
      const params = {
        loai: 0,
        phanLoaiTu: 'PHONGBAN',
      }
      const response = await actions.getLoaiLuuTaiLieu(params)
      const list = get(response, 'payload.data.result.result.items', []).map(
        id =>
          get(response, 'payload.data.entities.documentCabinetCategories', {})[
            id
          ]
      )
      this.setState({ danhSachViTriDiChuyen: list })
    } catch (err) {
      throw err
    } finally {
      this.setState({ isLoadingButton: false })
    }
  }

  /**
   * Xử lý toggle check vị trí di chuyển
   * @memberof CapNhatHoSoCongViec
   */
  checkDiChuyen = list => {
    let viTriDiChuyen = undefined
    if (String(list) !== '') {
      viTriDiChuyen = String(list)
    }

    this.setState({ viTriDiChuyen: viTriDiChuyen })
  }

  /**
   * POST di chuyển hồ sơ công việc trong phòng ban
   * @memberof CapNhatHoSoCongViec
   */
  submitDiChuyenHSCV = async () => {
    const { dataPopup, actions } = this.props
    const { viTriDiChuyen } = this.state
    this.setState({ isLoadingButton: true })
    try {
      const id = get(dataPopup, 'data.id')
      const params = {
        newThuMucChaId: viTriDiChuyen,
      }
      const response = await actions.putDiChuyenThuMucHoacHoSoCongViec(
        id,
        params
      )
      if (get(response, 'error')) {
        return actions.commonAddToasterMessage({
          message: DICHUYEN_KHONG_THANHCONG,
          intent: Intent.DANGER,
        })
      }
      actions.commonAddToasterMessage({
        message: DICHUYEN_THANHCONG,
        intent: Intent.SUCCESS,
      })
    } catch (err) {
      actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props.onClose()
      this.props.onSuccess()
    }
  }

  /**
   * Reset params khi change radio
   * @memberof CapNhatHoSoCongViec
   */
  handleResetParams = () => {
    this.setState({
      params: {
        ...this.state.params,
        id: undefined,
        maKyHieu: '',
        tenHoSo: '',
        ten: '',
        nguoiPhoiHopDs: [],
      },
      errorData: {},
    })
  }

  /**
   * Get data select option
   * @memberof CapNhatHoSoCongViec
   */
  getDataSelect = async () => {
    const { auth } = this.props
    await this.props.actions.commonFetchStaffByDepartmentId(
      auth.mainDepartmentId
    )
    await this.props.actions.getThoiGianLuuDanhSach({ isAll: false })
  }

  /**
   * Change data input
   * @memberof CapNhatHoSoCongViec
   */
  handleInputChange = e => {
    const { target } = e
    this.setState({
      params: {
        ...this.state.params,
        [target.name]: target.value,
      },
    })
  }

  handleSelectMuti = val => {
    this.setState({
      params: {
        ...this.state.params,
        nguoiPhoiHopDs: val,
      },
    })
  }

  /**
   * Submit form data
   * @memberof CapNhatHoSoCongViec
   */
  handleSubmit = e => {
    e.preventDefault()
    const { dataPopup } = this.props
    switch (dataPopup.type) {
      case typePopup.THEM_MOI_HSCV_CON:
      case typePopup.THEM_MOI_HSCV:
        this.checkDataTaoHSCV()
        break
      case typePopup.THEM_MOI_THU_MUC_CON:
      case typePopup.THEM_MOI_THU_MUC:
        this.checkDataTaoThuMuc()
        break
      case typePopup.CHINH_SUA:
        if (this.state.loaiChinhSua === typePopup.CHINH_SUA_THU_MUC_CON) {
          this.checkDataTaoThuMuc()
        } else {
          this.checkDataTaoHSCV()
        }

        break
      case typePopup.DUYET_HOSO:
        this.submitDuyetHSCV(dataPopup.type)
        break
      case typePopup.DUYET_LAI:
        this.submitDuyetHSCV(dataPopup.type)
        break
      case typePopup.HUY_NOP:
        this.submitHuyNopHSCV()
        break
      case typePopup.TIEP_NHAN:
      case typePopup.TIEP_NHAN_LAI:
        this.submitTiepNhanHSCV()
        break
      case typePopup.DI_CHUYEN:
        this.submitDiChuyenHSCV()
        break
      default:
        break
    }
  }

  /**
   * Kiểm tra dữ liệu trước khi submit tạo thư mục hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  checkDataTaoThuMuc = () => {
    const { params } = this.state
    const { dataPopup } = this.props
    let errorData = {}
    let isValidateSucess = true
    if (get(params, 'ten', '').trim() === '') {
      errorData.ten = TEXT_MESSAGE_DEFAULT
      isValidateSucess = false
    }

    this.setState({
      errorData: {
        ...errorData,
      },
    })
    if (isValidateSucess && dataPopup.type === typePopup.THEM_MOI_THU_MUC_CON) {
      this.submitTaoThuMuc()
    }
    if (isValidateSucess && dataPopup.type === typePopup.THEM_MOI_THU_MUC) {
      this.submitTaoThuMucCha()
    }
    if (isValidateSucess && dataPopup.type === typePopup.CHINH_SUA) {
      this.submitCapNhatThuMuc()
    }
  }

  /**
   * Kiểm tra dữ liệu trước khi submit tạo hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  checkDataTaoHSCV = () => {
    const { params } = this.state
    const { dataPopup } = this.props
    let errorData = {}
    let isValidateSucess = true
    if (get(params, 'maKyHieu', '').trim() === '') {
      errorData.maKyHieu = TEXT_MESSAGE_DEFAULT
      isValidateSucess = false
    }
    if (get(params, 'tenHoSo', '').trim() === '') {
      errorData.tenHoSo = TEXT_MESSAGE_DEFAULT
      isValidateSucess = false
    }
    if (get(params, 'nguoiChiuTrachNhiemId', '') === '') {
      errorData.nguoiChiuTrachNhiemId = TEXT_MESSAGE_DEFAULT
      isValidateSucess = false
    }
    if (get(params, 'thoiHanBaoQuanId', '') === '') {
      errorData.thoiHanBaoQuanId = TEXT_MESSAGE_DEFAULT
      isValidateSucess = false
    }
    if (get(params, 'nam', '') === '') {
      errorData.nam = TEXT_MESSAGE_DEFAULT
      isValidateSucess = false
    }
    this.setState({
      errorData: {
        ...errorData,
      },
    })
    if (isValidateSucess && dataPopup.type === typePopup.THEM_MOI_HSCV_CON) {
      this.submitTaoHSCV()
    }
    if (isValidateSucess && dataPopup.type === typePopup.THEM_MOI_HSCV) {
      this.submitTaoHSCVCha()
    }
    if (isValidateSucess && dataPopup.type === typePopup.CHINH_SUA) {
      this.submitCapNhatHSCV()
    }
  }

  /**
   * POST Tạo thư mục hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  submitTaoThuMuc = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const { dataPopup } = this.props
      const data = {
        ten: params.ten,
        thu_muc_cha_id: dataPopup.idRow,
      }
      await this.props.actions.postTaoThuMucHSCV(data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  /**
   * POST Tạo thư mục cha hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  submitTaoThuMucCha = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const data = {
        ten: params.ten,
        thu_muc_cha_id: null,
      }
      await this.props.actions.postTaoThuMucHSCV(data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  /**
   * PUT cập nhật thư mục hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  submitCapNhatThuMuc = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const id = params.id
      const data = {
        ten: params.ten,
        thu_muc_cha_id: params.thuMucChaId,
      }
      await this.props.actions.putCapNhatThuMucHSCV(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  /**
   * POST Tạo hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  submitTaoHSCV = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state

      const { dataPopup } = this.props
      const data = {
        ten: params.tenHoSo,
        thu_muc_cha_id: dataPopup.idRow,
        ma_ky_hieu: params.maKyHieu,
        nguoi_chiu_trach_nhiem_id: params.nguoiChiuTrachNhiemId,
        ds_nguoi_phoi_hop: params.nguoiPhoiHopDs,
        thoi_han_bao_hanh_id: params.thoiHanBaoQuanId,
        nam: params.nam,
      }
      await this.props.actions.postTaoHSCV(data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  /**
   * POST Tạo hồ sơ công việc cha
   * @memberof CapNhatHoSoCongViec
   */
  submitTaoHSCVCha = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const data = {
        ten: params.tenHoSo,
        thu_muc_cha_id: null,
        ma_ky_hieu: params.maKyHieu,
        nguoi_chiu_trach_nhiem_id: params.nguoiChiuTrachNhiemId,
        ds_nguoi_phoi_hop: params.nguoiPhoiHopDs,
        thoi_han_bao_hanh_id: params.thoiHanBaoQuanId,
        nam: params.nam,
      }
      await this.props.actions.postTaoHSCV(data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  /**
   * PUT cập nhật hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  submitCapNhatHSCV = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const id = params.id
      const data = {
        thu_muc_cha_id: params.thuMucChaId,
        ten: params.tenHoSo,
        ma_ky_hieu: params.maKyHieu,
        nguoi_chiu_trach_nhiem_id: params.nguoiChiuTrachNhiemId,
        ds_nguoi_phoi_hop: params.nguoiPhoiHopDs,
        thoi_han_bao_hanh_id: params.thoiHanBaoQuanId,
        nam: params.nam,
      }
      await this.props.actions.putCapNhatHSCV(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  /**
   * PUT lãnh đạo phòng ban duyệt hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  submitDuyetHSCV = async type => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const { dataPopup, auth } = this.props
      const id = dataPopup.data.id
      let trangThai = 2
      if (type === typePopup.DUYET_HOSO) {
        if (dataPopup.data.trangThai === 3) {
          trangThai = 4
        }
      }
      if (type === typePopup.DUYET_LAI) {
        trangThai = dataPopup.data.trangThaiTruoc + 1
      }

      const data = {
        trang_thai: trangThai,
        nguoi_duyet_id: get(auth, 'roleId'),
        ghi_chu:
          params.ghiChu && params.ghiChu.length !== 0
            ? params.ghiChu.trim()
            : '',
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  /**
   * PUT hủy nộp hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  submitHuyNopHSCV = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { dataPopup, auth } = this.props
      const id = dataPopup.data.id
      const data = {
        trang_thai: 0,
        nguoi_duyet_id: get(auth, 'roleId'),
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  /**
   * PUT tiếp nhận hồ sơ công việc của văn thư
   * @memberof CapNhatHoSoCongViec
   */
  submitTiepNhanHSCV = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const { dataPopup, auth } = this.props
      const id = dataPopup.data.id
      const data = {
        trang_thai: 3,
        nguoi_duyet_id: get(auth, 'roleId'),
        ghi_chu:
          params.ghiChu && params.ghiChu.length !== 0
            ? params.ghiChu.trim()
            : '',
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  checkActionTraLai = e => {
    e.preventDefault()
    const { params } = this.state
    if (get(params, 'ghiChu', '').trim() === '') {
      return this.setState({
        errorData: {
          ghiChu: UPDATE_lY_DO_THU_hOI,
        },
      })
    }

    this.xuLyTraLaiHoSoCongViec()
  }

  /**
   * Xử lý trả lại hồ sơ công việc
   * @memberof CapNhatHoSoCongViec
   */
  xuLyTraLaiHoSoCongViec = async () => {
    this.setState({ isLoadingButton: true })
    try {
      const { params } = this.state
      const { dataPopup, auth } = this.props
      const id = dataPopup.data.id
      const data = {
        trang_thai: 5,
        nguoi_duyet_id: get(auth, 'roleId'),
        ghi_chu:
          params.ghiChu && params.ghiChu.length !== 0
            ? params.ghiChu.trim()
            : '',
      }
      await this.props.actions.putXuLyHoSoCongViec(id, data).then(res => {
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.getNumberHSCV()
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      })
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({ isLoadingButton: false, errorData: {} })
      this.props && this.props.onClose()
      this.props && this.props.onSuccess()
    }
  }

  expandYears = (start = '2020') => {
    const years = []
    const dateStart = moment(start, 'YYYY')
    const dateEnd = moment().startOf('years')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      years.push(dateStart.format('YYYY'))
      dateStart.add(1, 'year')
    }
    return years
  }

  render() {
    const { dataPopup, staffs, thoiHanBaoQuanDanhSach } = this.props
    const {
      dataSelectNguoiPhoiHop,
      danhSachViTriDiChuyen,
      params,
      isLoadingButton,
      isCheckNguoiChiuTrachNhiem,
      loaiChinhSua,
      errorData,
    } = this.state
    return (
      <Dialog
        isOpen={dataPopup.isOpenPopup}
        onClose={this.props.onClose}
        title={dataPopup.titlePopup}
        className={classnames('size-container-dialog pb10', {
          'size-container-dialog--width-auto':
            dataPopup.type === typePopup.HUY_NOP,
        })}
        canOutsideClickClose={false}
      >
        <form onSubmit={this.handleSubmit}>
          <div className="pt-dialog-body container-UpdateDrawers wrapper-content-dialog border-bottom-none m0 pb10">
            {(dataPopup.type === typePopup.THEM_MOI_HSCV_CON ||
              loaiChinhSua === typePopup.CHINH_SUA_HSCV_CON ||
              dataPopup.type === typePopup.THEM_MOI_HSCV) &&
              dataPopup.type !== typePopup.DUYET_HOSO &&
              dataPopup.type !== typePopup.TIEP_NHAN && (
                <div className="form-container form-container-popup">
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Mã ký hiệu
                          <span className="required-input">*</span>
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <input
                          name="maKyHieu"
                          className="pt-input"
                          disabled={isCheckNguoiChiuTrachNhiem}
                          placeholder="Nhập mã ký hiệu"
                          type="text"
                          dir="auto"
                          maxLength={255}
                          value={get(params, 'maKyHieu', '')}
                          onChange={this.handleInputChange}
                        />
                        {get(errorData, 'maKyHieu') !== undefined && (
                          <div className="pt-form-helper-text">
                            {errorData.maKyHieu}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Tên hồ sơ
                          <span className="required-input">*</span>
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <textarea
                          rows="3"
                          name="tenHoSo"
                          className="pt-input"
                          placeholder="Nhập tên hồ sơ công việc"
                          type="text"
                          dir="auto"
                          maxLength={500}
                          onChange={this.handleInputChange}
                          value={get(params, 'tenHoSo', '')}
                        />
                        {get(errorData, 'tenHoSo') !== undefined && (
                          <div className="pt-form-helper-text">
                            {errorData.tenHoSo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Chịu trách nhiệm
                          <span className="required-input">*</span>
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <div className="pt-select">
                          <select
                            className="content-select"
                            disabled={isCheckNguoiChiuTrachNhiem}
                            name="nguoiChiuTrachNhiemId"
                            onChange={this.handleInputChange}
                            value={get(params, 'nguoiChiuTrachNhiemId', '')}
                          >
                            {staffs &&
                              staffs.length !== 0 &&
                              staffs.map((item, k) => (
                                <option key={k} value={item.chucDanhId}>
                                  {item.tenNhanVien}
                                </option>
                              ))}
                          </select>
                        </div>
                        {get(errorData, 'nguoiChiuTrachNhiemId') !==
                          undefined && (
                          <div className="pt-form-helper-text">
                            {errorData.nguoiChiuTrachNhiemId}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Người phối hợp
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <SelectCheckBox
                          data={dataSelectNguoiPhoiHop}
                          selectedValue={get(params, 'nguoiPhoiHopDs', [])}
                          disabledList={[get(params, 'nguoiChiuTrachNhiemId')]}
                          onValue={this.handleSelectMuti}
                          placeholder="Chọn người phối hợp"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Thời hạn bảo quản
                          <span className="required-input">*</span>
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <div className="pt-select">
                          <select
                            className="content-select"
                            name="thoiHanBaoQuanId"
                            onChange={this.handleInputChange}
                            value={get(params, 'thoiHanBaoQuanId', '')}
                          >
                            {thoiHanBaoQuanDanhSach &&
                              thoiHanBaoQuanDanhSach.length !== 0 &&
                              thoiHanBaoQuanDanhSach.map((item, k) => (
                                <option key={k} value={item.thoiHanLuuId}>
                                  {item.tenThoiHanLuu}
                                </option>
                              ))}
                          </select>
                        </div>
                        {get(errorData, 'thoiHanBaoQuanId') !== undefined && (
                          <div className="pt-form-helper-text">
                            {errorData.thoiHanBaoQuanId}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Năm
                          <span className="required-input">*</span>
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <div className="pt-select">
                          <select
                            className="content-select"
                            name="nam"
                            onChange={this.handleInputChange}
                            value={get(params, 'nam', '')}
                          >
                            {this.expandYears().map((item, i) => (
                              <option key={i} value={item}>
                                Năm {item}
                              </option>
                            ))}
                          </select>
                        </div>
                        {get(errorData, 'nam') !== undefined && (
                          <div className="pt-form-helper-text">
                            {errorData.nam}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {(dataPopup.type === typePopup.THEM_MOI_THU_MUC_CON ||
              loaiChinhSua === typePopup.CHINH_SUA_THU_MUC_CON ||
              dataPopup.type === typePopup.THEM_MOI_THU_MUC) &&
              dataPopup.type !== typePopup.DUYET_HOSO &&
              dataPopup.type !== typePopup.TIEP_NHAN && (
                <div className="form-container">
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Tên thư mục
                          <span className="required-input">*</span>
                        </label>
                      </div>
                      <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                        <input
                          autoFocus
                          name="ten"
                          className="pt-input"
                          placeholder="Nhập tên thư mục"
                          type="text"
                          dir="auto"
                          onChange={this.handleInputChange}
                          value={get(params, 'ten', '')}
                        />
                        {get(errorData, 'ten') !== undefined && (
                          <div className="pt-form-helper-text">
                            {errorData.ten}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {(dataPopup.type === typePopup.DUYET_HOSO ||
              dataPopup.type === typePopup.DUYET_LAI ||
              dataPopup.type === typePopup.TIEP_NHAN_LAI) && (
              <div className="form-container">
                <div className="pt-form-group">
                  <div className="row item-row-input">
                    <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                      <label className="pt-label label-inline-input">
                        Tên hồ sơ
                      </label>
                    </div>
                    <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                      <span>{get(params, 'ten')}</span>
                    </div>
                  </div>
                </div>
                <div className="pt-form-group">
                  <div className="row item-row-input">
                    <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                      <label className="pt-label label-inline-input">
                        Ghi chú
                      </label>
                    </div>
                    <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                      <textarea
                        style={{ width: '100%' }}
                        name="ghiChu"
                        rows="4"
                        className="pt-input"
                        placeholder="Nhập ghi chú"
                        dir="auto"
                        maxLength={255}
                        onChange={this.handleInputChange}
                        value={get(params, 'ghiChu', '')}
                      />
                      {get(errorData, 'ghiChu') !== undefined && (
                        <div className="pt-form-helper-text">
                          {errorData.ghiChu}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {dataPopup.type === typePopup.TIEP_NHAN && (
              <div className="form-container">
                <div className="pt-form-group">
                  <div className="row item-row-input">
                    <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                      <label className="pt-label label-inline-input">
                        Tên hồ sơ
                      </label>
                    </div>
                    <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                      <span>{get(params, 'ten')}</span>
                    </div>
                  </div>
                </div>
                <div className="pt-form-group">
                  <div className="row item-row-input">
                    <div className="col-md-4 col-xs-4 col-sm-4 form-inline-group">
                      <label className="pt-label label-inline-input">
                        Ghi chú
                      </label>
                    </div>
                    <div className="pt-form-content col-md-8 col-xs-8 col-sm-8">
                      <textarea
                        style={{ width: '100%' }}
                        name="ghiChu"
                        rows="4"
                        className="pt-input"
                        placeholder="Nhập ghi chú"
                        dir="auto"
                        maxLength={255}
                        onChange={this.handleInputChange}
                        value={get(params, 'ghiChu', '')}
                      />
                      {get(errorData, 'ghiChu') !== undefined && (
                        <div className="pt-form-helper-text">
                          {errorData.ghiChu}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {dataPopup.type === typePopup.DI_CHUYEN && (
              <div className="document-cabinet-content">
                <DiChuyen
                  mutilSelect
                  disableIds={[
                    get(dataPopup, 'data.id', ''),
                    // get(dataPopup, 'data.thuMucChaId', ''), // Nếu muốn disable luôn thư mục cha và các thư mục con
                  ]}
                  categories={danhSachViTriDiChuyen}
                  onCheckCategory={this.checkDiChuyen}
                  isLoading={isLoadingButton}
                  data={dataPopup.data}
                />
              </div>
            )}
            {dataPopup.type === typePopup.HUY_NOP && (
              <div>{TEXT_SUBMIT_BODY}</div>
            )}
          </div>
          <div className="pt-dialog-footer pt-dialog-footer-group-button">
            <div className="group-button center">
              {dataPopup.type !== typePopup.DUYET_HOSO &&
                dataPopup.type !== typePopup.TIEP_NHAN && (
                  <div className="group-button--content">
                    <Button
                      loading={false}
                      disabled={isLoadingButton}
                      onClick={this.props.onClose}
                    >
                      {dataPopup.textClose || TEXT_BUTTOM_CLOSE}
                    </Button>
                  </div>
                )}
              {(dataPopup.type === typePopup.DUYET_HOSO ||
                dataPopup.type === typePopup.TIEP_NHAN) && (
                <div className="group-button--content">
                  <Button
                    className="btn-red-bg-while"
                    loading={isLoadingButton}
                    disabled={isLoadingButton}
                    onClick={this.checkActionTraLai}
                  >
                    Trả lại
                  </Button>
                </div>
              )}
              <div className="group-button--content">
                <Button
                  className="btn-blue"
                  loading={isLoadingButton}
                  disabled={isLoadingButton}
                  type="submit"
                >
                  {dataPopup.textSubmit}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    )
  }
}

CapNhatHoSoCongViec.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  dataPopup: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  staffs: state.common.staffs,
  thoiHanBaoQuanDanhSach: state.common.thoiHanBaoQuanDanhSach,
})

export default connect(mapStateToProps, null)(CapNhatHoSoCongViec)
