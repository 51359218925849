import React, { memo, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { deleteTag } from 'actions/task'
import { Dialog, Card } from 'components/newCommon/'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import {
  DELETE_TAG_TITLE,
  DELETE_TAG_CONTENT,
  TEXT_BUTTON_SUBMIT,
} from 'constants/Popup'

const DeleteTag = ({ dataSource, onClose, onSuccess }) => {
  const id = dataSource?.id
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const deleteTask = () => {
    requestAction({
      beforeAction: () => _mountedSet(setLoading, true),
      action: () => deleteTag(id),
      afterResponse: () => {
        _mountedSet(setShowDialog, false)
        onSuccess()
      },
      afterAction: () => _mountedSet(setLoading, false),
      successMessage: Toast.SUCCESS(Action.DELETE),
      errorMessage: Toast.FAIL(Action.DELETE),
    })
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Dialog
      buttonUppercase
      isOpen={showDialog}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      confirmText={TEXT_BUTTON_SUBMIT}
      loading={loading}
      onCancel={onClose}
      onConfirm={deleteTask}
      onClose={onClose}
      title={DELETE_TAG_TITLE}
      textCenter
    >
      <Card className="mt20 mb20">{DELETE_TAG_CONTENT}</Card>
    </Dialog>
  )
}

DeleteTag.defaultProps = {
  dataSource: null,
  onClose: () => {},
  onSuccess: () => {},
}

DeleteTag.propTypes = {
  dataSource: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default memo(DeleteTag)
