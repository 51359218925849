import * as types from '../constants/ActionTypes'

const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  detailId: '',
  listSeeToKnow: [],
  totalSeeToKnow: null,
  totalSeeToKnowNotRead: null,
  listPersonal: [],
}

export default function personalWorks(state = initialState, action) {
  switch (action.type) {
    case types.PERSONAL_WORK_REQUEST_SEND:
      return {
        ...state,
        list: {
          page: 1,
          total: 0,
          items: [],
        },
      }

    case types.PERSONAL_WORK:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.GET_WORK_DOCUMENT_OVER_TIME:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.PERSONAL_WORK_ITEM:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.WORK_SEE_TO_KNOW:
      return {
        ...state,
        listSeeToKnow: action.payload.data.result.items,
        totalSeeToKnow: action.payload.data.result.total,
      }

    case types.TOTAL_SEE_TO_KNOW:
      return {
        ...state,
        totalSeeToKnowNotRead: action.payload.data.result,
      }

    case types.GET_LIST_PERSONAL_ASSIGNMENT:
      return {
        ...state,
        listPersonal: action.payload.data.result,
      }

    case types.API_RESET_PERSONAL_WORK_ITEM:
      return {
        ...state,
        detailId: '',
      }

    default:
      return state
  }
}
