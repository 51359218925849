import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { Checkbox } from '@blueprintjs/core'
import classnames from 'classnames'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
  }

  state = {
    isEnabled: this.props.checked || false,
    isRedirect: false,
    congVanId: '',
  }

  componentWillReceiveProps(props) {
    if (!isNaN(props.checked)) {
      this.setState({
        isEnabled: props.checked,
      })
    }
  }

  handleEnabledChange = e => {
    this.setState({ isEnabled: !this.state.isEnabled }, () => {
      this.props.onCheck &&
        this.props.onCheck(this.props.item.id, this.state.isEnabled)
    })
  }

  handleClick = id => {
    this.setState({
      isRedirect: true,
      congVanId: id,
    })
  }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={`/tien-ich/tim-kiem-cong-van/chi-tiet/${this.state.congVanId}`}
        />
      )
    }
    return (
      <tr
        className={classnames({
          'status-urgency-nomarl':
            this.props.item.doKhan && this.props.item.doKhan.maDoKhan === 'BT',
          'status-urgency':
            this.props.item.doKhan && this.props.item.doKhan.maDoKhan === 'KH',
          'status-urgency-hight':
            this.props.item.doKhan && this.props.item.doKhan.maDoKhan === 'TK',
        })}
      >
        <td>
          <Checkbox
            checked={this.state.isEnabled}
            onChange={this.handleEnabledChange}
          />
        </td>
        <td onClick={this.handleClick.bind(this, this.props.item.id)}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick.bind(this, this.props.item.id)}>
          {this.props.item.ngayCongVan
            ? moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')
            : null}
        </td>
        <td onClick={this.handleClick.bind(this, this.props.item.id)}>
          {this.props.item.soCongVan}
        </td>
        <td onClick={this.handleClick.bind(this, this.props.item.id)}>
          {this.props.item.ngayDen
            ? moment(this.props.item.ngayDen).format('DD/MM/YYYY')
            : null}
        </td>
        <td onClick={this.handleClick.bind(this, this.props.item.id)}>
          {this.props.item.soDen}
        </td>
        <td
          onClick={this.handleClick.bind(this, this.props.item.id)}
          className="textarea-content"
        >
          {this.props.item.trichYeu}
        </td>
        <td onClick={this.handleClick.bind(this, this.props.item.id)}>
          {this.props.item.noiGui}
        </td>
      </tr>
    )
  }
}

export default ListItem
