import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LoginForm } from '../../components/auth'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { VERSION } from '../../constants/Api'
import * as AuthActions from '../../actions'
import { stopDataListener } from '../../middleware/firebase/firebase-functions'

class Login extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
  }

  componentDidMount() {
    stopDataListener()
  }

  render() {
    return (
      <div
        className="cpc-login-page"
        style={{ backgroundImage: `url('/images/background.png')` }}
      >
        {/* eslint-disable */}
        <h1
          className="cpc-logo-full-text"
          style={{ backgroundImage: `url('/images/logo.png')` }}
        ></h1>
        <LoginForm actions={this.props.actions} />
        <div className="cpc-footer">
          <div className="cpc-footer-version">
            PHIÊN BẢN CPC-eOffice 7.1 - Văn phòng điện tử
          </div>
          <div className="cpc-footer-copyright">
            &copy; {new Date().getFullYear()} TỔNG CÔNG TY ĐIỆN LỰC MIỀN TRUNG
          </div>
        </div>
        <div className="version">
          <p>Version: {VERSION}</p>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(AuthActions, dispatch),
})

export default connect(null, mapDispatchToProps)(Login)
