import { Permission } from 'components/auth'
import { isValidCondition, checkPermissionMenu } from 'helpers/Helper'
import { get, includes, size } from 'lodash'
import React, { Fragment } from 'react'
import classNames from 'classnames'
import { Cookies } from 'react-cookie'
import { StatisticItem } from 'components/home'

const cookies = new Cookies()

const HomePageOld = ({
  isLoading,
  dataStatistics,
  auth,
  history,
  quyensMenu,
  numberSideBar,
}) => {
  const blockName = {
    // Công văn
    DOCUMENTS: {
      name: 'Công văn',
      className: 'col-xs-6 col-sm-4 col-md-4 col-lg-4',
      permission: [Permission.CONGVANDEN, Permission.KYSO_KYDUYET_GOPY],
      permissionMenu: [
        Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
        Permission.MENU_SIDEBAR.SMENU_CVAN_CANXULY,
      ],
    },
    // Công việc
    WORKS: {
      name: 'Công việc',
      className: 'col-xs-6 col-sm-6 col-md-6 col-lg-6',
      permission: [
        Permission.CONGVIEC_GIAOVIEC,
        Permission.CONGVIEC_PHONGBAN,
        Permission.CONGVIEC_CANHAN,
      ],
      permissionMenu: [Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC],
    },
    // Quản lý công việc
    WORKS_MANAGE: {
      name: 'Quản lý công việc',
      className: 'col-xs-6 col-sm-6 col-md-6 col-lg-6',
      permission: [Permission.SUDUNG_QLCV],
    },
    // Ký số
    SIGNING: {
      name: 'Ký số',
      className: 'col-xs-6 col-sm-6 col-md-6 col-lg-6',
      permission: [Permission.KYSO_QUANLY],
    },
    // Yêu cầu góp ý cần xử lý
    FEEDBACK: {
      name: 'Yêu cầu góp ý cần xử lý',
      className: 'col-xs-6 col-sm-6 col-md-6 col-lg-6',
      permission: [
        Permission.LAYYKIENDONVI_PHIEUGUI,
        Permission.LAYYKIENDONVI_PHIEUNHAN,
      ],
    },
  }

  const modelStatistic = {
    // Công văn chưa xử lý
    REFERENCE_NOT_HANDLED: {
      icon: 'icon icon-time-divisin',
      background: '#FF4858',
      permission: [Permission.CONGVANDEN],
      url: '/cong-van/cong-van-den/danh-sach/chua-xu-ly',
    },
    // Công việc chưa giao
    WORK_NOT_ASSIGNED: {
      icon: 'icon icon-Cong_van_chua_xu_ly',
      background: '#FF4858',
      permission: [Permission.CONGVIEC_GIAOVIEC, Permission.CONGVIEC_PHONGBAN],
      url: '/cong-viec/phong-ban/danh-sach/chua-giao',
    },
    // Công việc chưa thực hiện
    WORK_NEW: {
      icon: 'icon icon-Cong_van_dang_xu_ly',
      background: '#FF4858',
      permission: [Permission.CONGVIEC_CANHAN],
      url: '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien',
    },
    // QLCV_Đang thực hiện
    WORK_MANAGE_DOING: {
      icon: 'icon icon-time-skip',
      background: '#3478F3',
      url: '/quan-ly-cong-viec/duoc-giao',
    },
    // QLCV_Chưa thực hiện
    WORK_MANAGE_NEW: {
      icon: 'icon icon-calendar-time',
      background: '#FF4858',
      url: '/quan-ly-cong-viec/duoc-giao',
    },
    // Công văn đi ký số
    REFERENCE_SIGNING: {
      icon: 'icon icon-pen',
      background: '#12AA97',
      permission: [Permission.KYSO_KYDUYET_GOPY],
      permissionMenu: [Permission.MENU_SIDEBAR.SMENU_KYSO_DSCVANDI],
      url: '/ky-so/cong-van-di/danh-sach',
    },
    // Công văn nội bộ ký số
    INTERNAL_DOCUMENT_SIGNING: {
      icon: 'icon icon-recycle',
      background: '#3478F3',
      permission: [Permission.KYSO_KYDUYET_GOPY],
      url: '/ky-so/cong-van-noi-bo/danh-sach',
    },
    // Ký số mở rộng
    EXPAND_SIGNING: {
      icon: 'icon icon-zoom-out-arrow',
      background: '#E8A60E',
      url: '/ky-so/du-an/danh-sach',
    },
    // Ký số tập trung
    CONCENTRATE_SIGNING: {
      icon: 'icon icon-file',
      background: '#3478F3',
      permission: [Permission.KYSO_KYDUYET_GOPY],
      url: '/ky-so-tap-trung/van-ban-ky-duyet/danh-sach',
    },
    // Gửi góp ý chưa xử lý
    SENT_FEEDBACK_NOT_HANDLED: {
      icon: 'icon icon2-file-send',
      background: '#FF4858',
      permission: [Permission.LAYYKIENDONVI_PHIEUGUI],
      url: '/tien-ich/lay-y-kien-don-vi/danh-sach-goi',
    },
    // Nhận góp ý chưa xử lý
    RECEIVE_FEEDBACK_NOT_HANDLED: {
      icon: 'icon icon-time-up',
      background: '#3478F3',
      permission: [Permission.LAYYKIENDONVI_PHIEUNHAN],
      url: '/tien-ich/lay-y-kien-don-vi/danh-sach-nhan',
    },
  }

  return (
    <div className="mt20">
      {!isLoading &&
        dataStatistics
          ?.filter(
            s =>
              includes(['DOCUMENTS', 'WORKS', 'WORKS_MANAGE'], s.feature) &&
              (!blockName[`${s.feature}`]?.permission ||
                (blockName[`${s.feature}`]?.permission &&
                  isValidCondition(
                    auth.permission,
                    blockName[`${s.feature}`]?.permission,
                    'OR'
                  ))) &&
              (!blockName[`${s.feature}`]?.permissionMenu ||
                (blockName[`${s.feature}`]?.permissionMenu &&
                  checkPermissionMenu(
                    quyensMenu,
                    blockName[`${s.feature}`]?.permissionMenu
                  )))
          )
          .map((elm, key) => {
            let statisticsItems = get(elm, 'data', [])?.filter(
              s =>
                (!modelStatistic[`${s.type}`]?.permission ||
                  (modelStatistic[`${s.type}`]?.permission &&
                    isValidCondition(
                      auth.permission,
                      modelStatistic[`${s.type}`]?.permission,
                      'AND'
                    ))) &&
                (!modelStatistic[`${s.type}`]?.permissionMenu ||
                  (modelStatistic[`${s.type}`]?.permissionMenu &&
                    checkPermissionMenu(
                      quyensMenu,
                      modelStatistic[`${s.type}`]?.permissionMenu
                    )))
            )
            if (size(statisticsItems)) {
              return (
                <Fragment key={key}>
                  <h2
                    className={classNames('home-row--title', {
                      'color-light': get(
                        cookies.get('home_background_image'),
                        'is_light',
                        false
                      ),
                    })}
                  >
                    {blockName[`${elm.feature}`].name}
                  </h2>
                  <div className="home-row--items">
                    {statisticsItems.map((item, index) => (
                      <div
                        key={index}
                        className={classNames(
                          'home-col--items mb25 col-mobile'
                        )}
                        style={{ flex: 1 }}
                      >
                        <StatisticItem
                          history={history}
                          data={item}
                          modelStyle={modelStatistic[item.type]}
                          url={modelStatistic[item.type].url}
                        />
                      </div>
                    ))}
                  </div>
                </Fragment>
              )
            }

            return false
          })}
      {!isLoading &&
        dataStatistics
          ?.filter(
            s =>
              includes(['SIGNING', 'FEEDBACK'], s.feature) &&
              (!blockName[`${s.feature}`]?.permission ||
                (blockName[`${s.feature}`]?.permission &&
                  isValidCondition(
                    auth.permission,
                    blockName[`${s.feature}`]?.permission,
                    'OR'
                  ))) &&
              (!blockName[`${s.feature}`]?.permissionMenu ||
                (blockName[`${s.feature}`]?.permissionMenu &&
                  checkPermissionMenu(
                    quyensMenu,
                    blockName[`${s.feature}`]?.permissionMenu
                  )))
          )
          .map((elm, key) => {
            let statisticsItems = get(elm, 'data', [])?.filter(
              s =>
                !includes(['CONCENTRATE_SIGNING'], s.type) &&
                (!modelStatistic[`${s.type}`]?.permission ||
                  (modelStatistic[`${s.type}`]?.permission &&
                    isValidCondition(
                      auth.permission,
                      modelStatistic[`${s.type}`]?.permission,
                      'AND'
                    ))) &&
                (!modelStatistic[`${s.type}`]?.permissionMenu ||
                  (modelStatistic[`${s.type}`]?.permissionMenu &&
                    checkPermissionMenu(
                      quyensMenu,
                      modelStatistic[`${s.type}`]?.permissionMenu
                    )))
            )
            if (size(statisticsItems)) {
              return (
                <Fragment key={key}>
                  <h2
                    className={classNames('home-row--title', {
                      'color-light': get(
                        cookies.get('home_background_image'),
                        'is_light',
                        false
                      ),
                    })}
                  >
                    {blockName[`${elm.feature}`].name}
                  </h2>
                  <div className="home-row--items">
                    {statisticsItems.map((item, index) => (
                      <div
                        key={index}
                        className={classNames(
                          'home-col--items mb25 col-mobile'
                        )}
                        style={{ flex: 1 }}
                      >
                        <StatisticItem
                          history={history}
                          data={item}
                          modelStyle={modelStatistic[item.type]}
                          url={modelStatistic[item.type].url}
                        />
                      </div>
                    ))}
                    {includes(['SIGNING'], elm.feature) && (
                      <div
                        className={classNames(
                          'home-col--items mb25 col-mobile'
                        )}
                        style={{ flex: 1 }}
                      >
                        <StatisticItem
                          history={history}
                          data={{
                            value: get(numberSideBar, 'kySoTapTrung'),
                            name: 'Ký số tập trung',
                            url: '/ky-so-tap-trung/van-ban-ky-duyet/danh-sach',
                          }}
                          modelStyle={modelStatistic.CONCENTRATE_SIGNING}
                          url={modelStatistic.CONCENTRATE_SIGNING.url}
                        />
                      </div>
                    )}
                  </div>
                </Fragment>
              )
            }
            return false
          })}
    </div>
  )
}

export default HomePageOld
