import React from 'react'
import async from 'async'
import { Checkbox, Intent } from '@blueprintjs/core'
import Authorization from '../auth/Authorization'

class ListItemRolePermissions extends React.Component {
  constructor(props) {
    super(props)

    this.handleCheck = this.handleCheck.bind(this)
  }

  state = {
    checkbox: {},
  }

  handleCheck(permission, e) {
    const { name, checked, value } = e.target

    this.setState(
      {
        checkbox: {
          ...this.state.checkbox,
          [`${name}-${value}`]: checked,
        },
      },
      () => {
        this.updateCheckBox(
          {
            isUpdate: checked,
            maQuyen: value,
            doiTuongId: name,
          },
          permission
        )
      }
    )
  }

  updateCheckBox(data, permission) {
    let request, handleElements
    let { isUpdate, doiTuongId, maQuyen } = data

    if (isUpdate) {
      handleElements = Authorization.checkParent(maQuyen)
      request = this.props.actions.postCategoryCommonFetchRoles(
        doiTuongId,
        handleElements.concat([maQuyen])
      )
    } else {
      handleElements = Authorization.checkChildren(maQuyen)
      request = this.props.actions.deleteCategoryCommonFetchRoles(
        doiTuongId,
        handleElements.concat([maQuyen])
      )
    }

    request.then(res => {
      if (
        res.payload &&
        res.payload.data.result &&
        res.payload.data.result === true
      ) {
        this.props.actions.getCategoryCommonFetchRole(this.props.donViId)
        return this.props.actions.commonAddToasterMessage({
          message: 'Lưu thành công',
          intent: Intent.SUCCESS,
        })
      }

      this.setState({
        checkbox: {
          ...this.state.checkbox,
          [`${doiTuongId}-${maQuyen}`]:
            !this.state.checkbox[`${doiTuongId}-${maQuyen}`],
        },
      })
      return this.props.actions.commonAddToasterMessage({
        message: 'Lưu không thành công',
        intent: Intent.DANGER,
      })
    })
  }

  componentWillReceiveProps(props) {
    const { categoryRole, maQuyen } = props

    if (
      maQuyen &&
      maQuyen.length > 0 &&
      this.state.maQuyen &&
      this.state.maQuyen.length > 0 &&
      categoryRole === this.state.categoryRole
    ) {
      return
    }

    this.updateCheckView(props)
  }

  componentDidMount() {
    this.updateCheckView(this.props)
  }

  updateCheckView(data) {
    const { maQuyen, categoryRole } = data
    let checkbox = {}

    if (!maQuyen || maQuyen.length === 0) {
      return
    }

    async.map(
      maQuyen,
      (i, n) => {
        return async.map(
          categoryRole,
          (item, next) => {
            const permission = item.dsQuyen.map(i => i.maQuyen)
            checkbox[`${item.doiTuongId}-${i}`] = permission.indexOf(i) >= 0
            return next()
          },
          n
        )
      },
      () => {
        this.setState({ checkbox, maQuyen, categoryRole })
      }
    )
  }

  render() {
    return (
      <tbody>
        {this.props.maQuyen &&
          this.props.maQuyen.length !== 0 &&
          this.props.maQuyen.map((maQuyen, i) => (
            <tr key={i}>
              <td
                style={{
                  minWidth: '60px',
                  Width: '60px',
                  textAlign: 'center',
                  paddingLeft: '10px',
                }}
                className="table-scroll-fixed--col"
              >
                <span className="order-content">
                  {i + 1 < 10 ? '0' + (i + 1) : i + 1}
                </span>
              </td>
              <td className="table-scroll-fixed--col table-scroll-fixed--col2">
                <span className="text-content">{maQuyen}</span>
              </td>
              {this.props.categoryRole.map((item, key) => {
                const permission = item.dsQuyen.map(i => i.maQuyen)
                return (
                  <td key={key} className="colum-role">
                    <span className="text-content">
                      <Checkbox
                        className="checkbox-item"
                        name={item.doiTuongId}
                        value={maQuyen}
                        onChange={this.handleCheck.bind(this, permission)}
                        checked={
                          this.state.checkbox[
                            `${item.doiTuongId}-${maQuyen}`
                          ] || false
                        }
                      />
                    </span>
                  </td>
                )
              })}
            </tr>
          ))}
      </tbody>
    )
  }
}

export default ListItemRolePermissions
