import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

const DEFAULT_ID = '00000000-0000-0000-0000-000000000000'
const TOTAL_NAME = 'Tổng cộng'

class ListItemMessenger extends Component {
  constructor(props) {
    super(props)

    this.convertNumber = this.convertNumber.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  convertNumber = number => {
    if (_.isNumber(number)) {
      return number
    }
    return 0
  }

  handleSubmit = item => {
    if (item.id !== DEFAULT_ID && item.loai === 1) {
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/thong-ke-tin-nhan/da-gui/chi-tiet/don-vi/${item.id}`,
          state: {
            isCheck: true,
          },
        })
      )
    }

    if (item.id !== DEFAULT_ID && item.loai === 2) {
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/thong-ke-tin-nhan/da-gui/chi-tiet/phong-ban/${item.id}`,
          state: {
            isCheck: true,
          },
        })
      )
    }
  }

  render() {
    const { item, number } = this.props
    if (!item) {
      return
    }

    const isTotalRaw = item.id === DEFAULT_ID && item.ten === TOTAL_NAME
    return (
      <tr
        className={classnames({
          'text-bold': isTotalRaw,
        })}
      >
        <td className="gerenal-list-utilities">
          {isTotalRaw ? '' : number > 9 ? number : '0' + number}
        </td>
        <td
          onClick={this.handleSubmit.bind(this, item)}
          className={classnames('gerenal-list-utilities', {
            'over-time': !isTotalRaw && (item.loai === 1 || item.loai === 2),
          })}
        >
          <span className="hover-over-time">{item.ten}</span>
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoTinNhan}
        </td>
      </tr>
    )
  }
}

export default ListItemMessenger
