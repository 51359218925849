import { schema } from 'normalizr'

const Notification = new schema.Entity(
  'notifications',
  {},
  {
    idAttribute: notification => notification.id,
  }
)

export default Notification
