import React, {
  memo,
  Fragment,
  useMemo,
  useCallback,
  useContext,
  useState,
} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import { get } from 'lodash'
import { TaskContext } from '../AllTask'

import { Card } from '../../../../components/newCommon'
import { CalendarFilter } from '../Filter'

import { calendarConfig } from '../../../../constants/config'

const requestDateFormat = 'YYYY/MM/DD'
const eventDateFormat = 'YYYY-MM-DD'

const filterKeys = {
  from: 'FromDate',
  to: 'ToDate',
  dateType: 'FilterWorkItem.DateType',
  status: 'Status',
  tag: 'TagIds',
  title: 'Title',
}

const keyMaps = {
  CREATION: 'creationTime',
  DEADLINE: 'deadline',
}

const colors = {
  NEW: '#FD9638',
  DOING: '#1EABF1',
  DONE: '#58C72D',
}

const Calendar = ({ match, history, currentUserId }) => {
  const [viewType, setViewType] = useState('dayGridMonth')

  const calendarRef = React.createRef()

  const { type: taskType, id: currentId, action: currentAction } = match.params

  const mainPathname = useMemo(
    () => `/quan-ly-cong-viec/${taskType}`,
    [taskType]
  )

  const goToAction = useCallback(
    (id, action) => {
      const url = `${mainPathname}/${action}/${id}`
      if (!currentAction) return history.push(url)
      if (action !== currentAction || id !== currentId)
        return history.replace(url)
    },
    [history, currentAction, mainPathname, currentId]
  )

  const {
    calendarData = [],
    changeFilter = () => {},
    filter = {},
  } = useContext(TaskContext)

  const changeFilterHandle = useCallback(
    ({ start: startProps, end: endProps } = {}) => {
      const start = calendarRef.current?.getApi().view.activeStart || startProps
      const end = calendarRef.current?.getApi().view.activeEnd || endProps

      const values = {
        [filterKeys.from]: moment(start).format(requestDateFormat),
        [filterKeys.to]: moment(end).format(requestDateFormat),
      }
      changeFilter({ multipleKey: true })(values)
    },
    [changeFilter, calendarRef]
  )

  const customButtons = useMemo(() => {
    return {
      prev: {
        click: () => {
          calendarRef.current.getApi().prev()
          changeFilterHandle()
        },
      },
      next: {
        click: () => {
          calendarRef.current.getApi().next()
          changeFilterHandle()
        },
      },
      dayGridWeek: {
        text: 'Tuần',
        click: () => {
          calendarRef.current.getApi().changeView('dayGridWeek')
          setViewType('dayGridWeek')
          changeFilterHandle()
        },
      },
      dayGridMonth: {
        text: 'Tháng',
        click: () => {
          calendarRef.current.getApi().changeView('dayGridMonth')
          setViewType('dayGridMonth')
          changeFilterHandle()
        },
      },
      today: {
        text: 'Hôm nay',
        click: () => {
          calendarRef.current.getApi().today()
          changeFilterHandle()
        },
      },
    }
  }, [changeFilterHandle, calendarRef])

  const eventDatas = useMemo(
    () =>
      calendarData.map(item => ({
        ...item,
        eventId: item.id,
        date: moment(
          item[keyMaps[get(filter, filterKeys.dateType, 'CREATION')]]
        ).format(eventDateFormat),
        title: item.title,
        color: colors[get(item, 'status', 'NEW')],
      })),
    [calendarData, filter]
  )

  const eventItemClick = useCallback(
    item => {
      goToAction(item.event.extendedProps.eventId, 'chi-tiet')
    },
    [goToAction]
  )

  const onReset = useCallback(() => {
    calendarRef.current.getApi().changeView('dayGridMonth')
    setViewType('dayGridMonth')
    calendarRef.current.getApi().today()

    const fromDate = calendarRef.current?.getApi().view.activeStart
    const toDate = calendarRef.current?.getApi().view.activeEnd

    const values = {
      [filterKeys.from]: moment(fromDate).format(requestDateFormat),
      [filterKeys.to]: moment(toDate).format(requestDateFormat),
      [filterKeys.dateType]: 'CREATION',
      [filterKeys.status]: undefined,
      [filterKeys.tag]: undefined,
      [filterKeys.title]: '',
    }

    changeFilter({ multipleKey: true })(values)
  }, [calendarRef, changeFilter])

  return (
    <Fragment>
      <div className="task-manager-filter">
        <CalendarFilter onReset={onReset} />
      </div>
      <div className="cpc-calendar">
        <Card bordered>
          <FullCalendar
            {...calendarConfig}
            dayMaxEvents={viewType === 'dayGridMonth' ? 3 : 10}
            dayHeaderFormat={
              viewType === 'dayGridMonth'
                ? { weekday: 'long' }
                : {
                    weekday: 'short',
                    month: 'numeric',
                    day: 'numeric',
                    omitCommas: true,
                  }
            }
            ref={calendarRef}
            plugins={[dayGridPlugin]}
            customButtons={customButtons}
            initialView="dayGridMonth"
            eventClick={eventItemClick}
            initialEvents={changeFilterHandle}
            events={eventDatas}
          />
        </Card>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  currentUserId: get(state, 'auth.user.nhanVienId'),
})

export default withRouter(connect(mapStateToProps)(memo(Calendar)))
