import React from 'react'

const HeadingTableSort = props => {
  return (
    <div className="table-display table-head-container">
      {props.data.map((item, i) => (
        <div key={i} className={`table-cols-content ${item.classNames}`}>
          <span className="head-label">{item.value}</span>
          {!!item.sort &&
            (!!props.itemSort &&
            item.sortName === props.itemSort.key &&
            props.itemSort.type === 'ASC' ? (
              <span
                className="pt-icon pt-icon-chevron-down"
                onClick={() =>
                  !!props.onSort &&
                  props.onSort({ key: item.sortName, type: 'DESC' })
                }
              />
            ) : (
              <span
                className="pt-icon pt-icon-chevron-up"
                onClick={() =>
                  !!props.onSort &&
                  props.onSort({ key: item.sortName, type: 'ASC' })
                }
              />
            ))}
        </div>
      ))}
    </div>
  )
}

export default HeadingTableSort
