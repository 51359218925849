import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { Collapse } from '@blueprintjs/core'

import { StatusTag } from 'components/newCommon'
import { ASSIGN_TYPE } from 'constants/Enum'
import { getTypeResponsipility } from 'helpers/Helper'
import { IMAGE_URL } from 'constants/Api'
import FlowDoc from './file'

const FlowTaskItem = ({ children, data }) => {
  const [dataAssigner, setDataAssigner] = useState({})
  const [dataSource, setDataSource] = useState({})
  const [dataApproval, setDataApproval] = useState({})
  const [isOpen, setIsOpen] = useState(true)
  const [isOpenGroup, setIsOpenGroup] = useState(true)

  const handleClick = (isGroup = false) => {
    if (isGroup) {
      setIsOpenGroup(prev => !prev)
      return
    }

    if (!isEmpty(data?.workItems)) {
      setIsOpen(prev => !prev)
    }
  }

  const renderUserAvatar = values => {
    let infos = data?.mainResponsibility
    if (size(values?.workItems || []) !== 0) {
      infos = data?.assigner
    }

    return (
      <span
        className="avatar-user"
        style={{
          backgroundImage: `url(${
            infos && infos.avatar
              ? IMAGE_URL + infos.avatar
              : '/images/default_avatar.png'
          })`,
        }}
      />
    )
  }

  const renderUserName = values => {
    let infos = data?.mainResponsibility
    if (size(values?.workItems || []) !== 0) {
      infos = data?.assigner
    }

    return infos?.type === ASSIGN_TYPE.CA_NHAN ? infos?.name : infos?.shortName
  }

  const renderDepartment = values => {
    return values?.type === ASSIGN_TYPE.CA_NHAN
      ? values?.name
      : values?.shortName
  }

  useEffect(() => {
    const hasWorkItems = size(data?.workItems || []) !== 0
    if (!isEmpty(data)) {
      setDataAssigner({})
      setDataApproval({})
      if (
        hasWorkItems ||
        (!hasWorkItems && data?.assigner?.id === data?.mainResponsibilityId)
      ) {
        setDataAssigner({
          labelDate: 'Ngày giao',
          dateValue: moment(data?.assignDate).isValid()
            ? moment(data?.assignDate).format('DD/MM/YYYY HH:mm')
            : '',
        })
      }

      if (!hasWorkItems) {
        switch (data?.status) {
          case 'DOING':
          case 'PENDING':
            setDataSource({
              status: data?.status,
              textStatus: 'Đang thực hiện',
              labelDate: null,
              dateValue: null,
            })
            break
          case 'DONE':
            setDataSource({
              status: data?.status,
              textStatus: 'Hoàn thành',
              labelDate: 'Ngày',
              dateValue: moment(data?.finishedDate).isValid()
                ? moment(data?.finishedDate).format('DD/MM/YYYY HH:mm')
                : '',
            })
            break
          case 'DONE_SLOW':
            setDataSource({
              status: data?.status,
              textStatus: 'Hoàn thành chậm',
              labelDate: 'Ngày',
              dateValue: moment(data?.finishedDate).isValid()
                ? moment(data?.finishedDate).format('DD/MM/YYYY HH:mm')
                : '',
            })
            break
          default:
            break
        }
      }

      if (data?.approval === 'WAITING_APPROVAL') {
        setDataApproval({
          status: 'WAITING_APPROVAL',
          textStatus: 'Chờ duyệt',
          labelDate: null,
          dateValue: null,
        })
      } else if (
        data?.approval === 'APPROVED' &&
        (size(data?.workItems || []) !== 0 ||
          (size(data?.workItems || []) === 0 &&
            data?.assigner?.id === data?.mainResponsibilityId))
      ) {
        setDataApproval({
          status: 'APPROVED',
          textStatus: 'Đã duyệt',
          labelDate: 'Ngày duyệt',
          dateValue: moment(data?.approvalDate).isValid()
            ? moment(data?.approvalDate).format('DD/MM/YYYY HH:mm')
            : '',
        })
      }
    }
  }, [data])

  let isPersonal = true
  let isGroupDepartment = false
  if (
    (data?.mainResponsibility?.type === ASSIGN_TYPE.DON_VI ||
      data?.mainResponsibility?.type === ASSIGN_TYPE.PHONG_BAN) &&
    size(data?.workItems || []) === 0
  ) {
    isPersonal = false
  }

  if (data?.mainResponsibilityId !== data?.oldMainResponsibilityId) {
    isGroupDepartment = true
  }

  return (
    <div className="item-flow mb15 pl40">
      {isGroupDepartment && (
        <div
          className="item-flow-department mb10"
          onClick={() => handleClick(true)}
        >
          <span
            className={classnames(
              'pt-icon font-size-13 wh-16 mr5 color-blue cursor-pointer',
              {
                'pt-icon-chevron-up': isOpenGroup,
                'pt-icon-chevron-down': !isOpenGroup,
              }
            )}
          />
          <div className={classnames('name-user')}>
            {renderDepartment(data?.oldMainResponsibility)}
            <span className="role">
              ({getTypeResponsipility(data?.responsibilityType)})
            </span>
          </div>
        </div>
      )}
      <Collapse isOpen={isOpenGroup}>
        <div
          className={classnames({
            pl20: isGroupDepartment,
          })}
        >
          <div
            className="click-avatar-work-flow mb5"
            onClick={() => handleClick(false)}
          >
            <span
              className={classnames(
                'pt-icon font-size-13 wh-16 color-blue cursor-pointer',
                {
                  'pt-icon-chevron-up': !isEmpty(data?.workItems) && isOpen,
                  'pt-icon-chevron-down': !isEmpty(data?.workItems) && !isOpen,
                }
              )}
            />
            {isPersonal && renderUserAvatar(data)}
            <span
              className={classnames('name-user', {
                pl10: !isPersonal,
                pl50: isEmpty(data?.workItems) && isPersonal,
              })}
            >
              {renderUserName(data)}
              {(!isGroupDepartment ||
                (isGroupDepartment && size(data?.workItems || []) === 0)) && (
                <span className="role">
                  ({getTypeResponsipility(data?.responsibilityType)})
                </span>
              )}
            </span>
          </div>
          <div
            className={classnames('info-user', {
              pl25: !isPersonal,
            })}
          >
            {dataAssigner?.labelDate && (
              <div className="mb15">
                <span>
                  <strong>{dataAssigner?.labelDate}</strong>:{' '}
                  {dataAssigner?.dateValue ? dataAssigner?.dateValue : ''}
                </span>
              </div>
            )}
            <div className="mb15">
              {dataSource?.status && isPersonal && (
                <StatusTag
                  type={dataSource?.status}
                  className="round mr10"
                  text={dataSource?.textStatus}
                />
              )}
              {dataSource?.dateValue && (
                <span>
                  <strong>{dataSource?.labelDate}</strong>:{' '}
                  {dataSource?.dateValue}
                </span>
              )}
            </div>
            {dataApproval?.status && (
              <div className="mb15">
                <StatusTag
                  type={dataApproval?.status}
                  className="round mr10"
                  text={dataApproval?.textStatus}
                />
                {dataApproval?.labelDate && (
                  <span>
                    <strong>{dataApproval?.labelDate}</strong>:{' '}
                    {dataApproval?.dateValue}
                  </span>
                )}
              </div>
            )}
          </div>
          {!isEmpty(data?.workReports) && (
            <FlowDoc
              data={data?.workReports}
              className={classnames('mb15', {
                pl25: !isPersonal,
              })}
            />
          )}
          <Collapse isOpen={isOpen}>{children}</Collapse>
        </div>
      </Collapse>
    </div>
  )
}

export default FlowTaskItem
