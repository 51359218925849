import { Collapse } from '@blueprintjs/core'
import {
  EmptyDataTable,
  ErrorLoadingTable,
  HeadingTableSort,
  LoadingTable,
} from '../../../components/common'
import React from 'react'
import _ from 'lodash'
import { headers } from '../../../constants/Table'

class StatisticalCollapseList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data || [],
      isDataEmpty: props.data && !props.data.length,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        data: nextProps.data,
        isDataEmpty: !nextProps.data.length,
      })
    }
  }

  render() {
    const { isLoading, isError, data } = this.props
    const { isDataEmpty } = this.state

    return (
      <div className="table-scroll">
        <div className="new-table-style">
          <header>
            <HeadingTableSort data={headers.statisticalServiceList} />
          </header>
          <main>
            {!!isLoading ? (
              <LoadingTable />
            ) : !!isError ? (
              <ErrorLoadingTable />
            ) : !!isDataEmpty ? (
              <EmptyDataTable />
            ) : (
              data.map((item, index) => (
                <ListItem key={index} i={index + 1} data={item} />
              ))
            )}
          </main>
        </div>
      </div>
    )
  }
}

class ListItem extends React.Component {
  constructor() {
    super()
    this.state = {
      isShowCollapse: false,
    }
  }
  toggleCollapse = () =>
    this.setState({ isShowCollapse: !this.state.isShowCollapse })

  render() {
    const { i, data } = this.props
    const { isShowCollapse } = this.state
    const hasList = !!_.get(data, 'dsService', []).length
    return (
      <section className="row">
        <main className="row parent">
          <span className="cell cell-width-5 center-text">
            {hasList ? (
              isShowCollapse ? (
                <i
                  className="icon-round-delete-button has-action"
                  onClick={this.toggleCollapse}
                />
              ) : (
                <i
                  className="icon-rounded-add-button has-action"
                  onClick={this.toggleCollapse}
                />
              )
            ) : (
              ''
            )}
          </span>
          <span className="cell cell-width-5">{i < 10 ? '0' + i : i}</span>
          <span className="cell cell-width-50 ">
            <b>{data.tenDonVi}</b>
          </span>
          <span className="cell cell-width-20 center text-link">
            {data.soLuongService}
          </span>
          <span className="cell cell-width-20 center">
            {_.sumBy(data.dsService, function (o) {
              return o.totalTinNhan
            })}
          </span>
        </main>
        <Collapse isOpen={isShowCollapse}>
          {data.dsService.map((childItem, index) => (
            <main
              className="row child has-action margin-collapse-10"
              key={index}
            >
              <div className="cell cell-width-50">{childItem.tenService}</div>
              <div className="cell cell-width-20 center">&nbsp;</div>
              <div className="cell cell-width-20 center">
                {childItem.totalTinNhan}
              </div>
            </main>
          ))}
        </Collapse>
      </section>
    )
  }
}

export default StatisticalCollapseList
