import { CHECKBOX_REQUIRED } from '../../constants/MessageForm'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import {
  EmptyDataTable as EmptyData,
  ErrorLoadingTable as ErrorLoading,
  LoadingLine,
} from './index'
import React, { Component } from 'react'

import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fecthUnitEmployee } from '../../actions/procedure'
import { getCommonFetchUnitItem, commonAddToasterMessage } from '../../actions'
import classnames from 'classnames'
import Tree, { TreeNode } from 'rc-tree'

class EmployeeSelectDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      employeeUnit: {},
      isLoading: true,
      isEmptyData: false,
      isError: false,
      selectList: [],
      defaultSelectKeyList: [],
    }
  }

  fetchUnit = async () => {
    const { fetchUnit, unitUserId } = this.props
    const { employeeUnit } = this.state
    !!unitUserId &&
      !employeeUnit.length &&
      (await fetchUnit(unitUserId).then(res => {
        const status = !!(_.get(res, 'payload.status') === 200)
        const data = _.get(res, 'payload.data.result', {})
        this.setState({
          isError: !status,
          employeeUnit: data,
        })
      }))
  }

  fetchEmployee = async () => {
    const { fetchEmployee, unitUserId } = this.props
    const { employeeUnit } = this.state
    if (!!unitUserId && !_.some(employeeUnit, _.isEmpty)) {
      await this.fetchUnit()
      await fetchEmployee(unitUserId).then(res => {
        const status = !!(_.get(res, 'payload.status') === 200)
        const list = _.get(res, 'payload.data.result.items', [])
        let convertList = list.filter(item => !item.phongBanCha)
        convertList = convertList.map(item => {
          return {
            ...item,
            dsPhongBanCon: list.filter(
              department =>
                !!department.phongBanCha &&
                item.phongBanId === department.phongBanCha
            ),
          }
        })
        this.setState({
          isError: !status,
          isEmptyData: !list.length,
          employeeUnit: { ...this.state.employeeUnit, dsPhongBan: convertList },
        })
      })
    }
    this.setState({ isLoading: false })
  }

  setSelectKey = (unitId, departmentId, employee) => {
    const value = {}
    !!unitId && (value.don_vi_id = unitId)
    !!departmentId && (value.phong_ban_id = departmentId)
    if (!!employee) {
      value.nhan_vien_id = employee.nhanVienId
      value.chuc_danh_id = _.find(employee.dsChucDanh, {
        phongBanId: departmentId,
      }).id
    }
    return JSON.stringify(value)
  }

  disableDepartment = department => {
    const { totalNhanVien = 0, soPhongBanCon = 0, phongBanId } = department
    const { defaultSelectKeyList } = this.state
    let childTotalSelect = 0
    const totalSelect = defaultSelectKeyList
      .map(i => JSON.parse(i))
      .filter(i => i.phong_ban_id === phongBanId).length
    if (soPhongBanCon > 0) {
      childTotalSelect = department.dsPhongBanCon
        .map(item => (this.disableDepartment(item) ? item.totalNhanVien : 0))
        .reduce((a, b) => a + b, 0)
    }
    return !!(totalNhanVien === totalSelect + childTotalSelect)
  }

  disableUnit = (unit, defaultSelect) => {
    const totalEmployee = _.get(unit, 'dsPhongBan', [])
      .map(i => i.totalNhanVien)
      .reduce((a, b) => a + b, 0)
    const totalSelect = defaultSelect.length
    return !!(totalEmployee === totalSelect)
  }

  onCheck = selectList => {
    const list = selectList
      .map(item => JSON.parse(item))
      .filter(item => !!item.nhan_vien_id)
    this.setState({
      selectList: list,
    })
  }

  onSubmit = () => {
    const { onSubmit, showToast } = this.props
    const { selectList } = this.state
    if (!!selectList.length) {
      return !!onSubmit && onSubmit(this.state.selectList)
    }
    showToast({
      message: CHECKBOX_REQUIRED('thành viên'),
      intent: Intent.DANGER,
    })
  }

  componentDidUpdate(prevProps) {
    const { isDialogOpen, unitUserId } = this.props
    if (isDialogOpen && isDialogOpen !== prevProps.isDialogOpen) {
      this.fetchEmployee()
    }
    if (unitUserId && unitUserId !== prevProps.unitUserId) {
      this.fetchEmployee()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultSelectList !== this.props.defaultSelectList) {
      const convertList = nextProps.defaultSelectList
        ? nextProps.defaultSelectList.map(item =>
            JSON.stringify({
              don_vi_id: item.donViId,
              phong_ban_id: item.phongBanId,
              nhan_vien_id: item.nhanVienId,
              chuc_danh_id: item.chucDanhId,
            })
          )
        : []
      this.setState({ defaultSelectKeyList: convertList })
    }
  }

  render() {
    const { isDialogOpen: isOpen = false, closeDialog, title } = this.props
    const {
      isLoading,
      isError,
      isEmptyUnitList,
      employeeUnit,
      defaultSelectKeyList,
    } = this.state
    const unitKey = this.setSelectKey(employeeUnit.donViId)
    const disableUnit = this.disableUnit(employeeUnit, defaultSelectKeyList)
    return (
      <Dialog
        isOpen={isOpen}
        onClose={closeDialog}
        title={title || ''}
        className="size-container-dialog employee-dialog"
      >
        <div
          className={classnames(
            'container-UpdateDrawers',
            'wrapper-content-dialog',
            'employee-dialog-content'
          )}
        >
          {isLoading ? (
            <LoadingLine />
          ) : isError ? (
            <ErrorLoading />
          ) : isEmptyUnitList ? (
            <EmptyData />
          ) : (
            !!employeeUnit && (
              <Tree
                className="employee-select-tree"
                checkable
                onSelect={this.onSelect}
                onCheck={this.onCheck}
                defaultCheckedKeys={defaultSelectKeyList}
                selectable={false}
              >
                <TreeNode
                  title={employeeUnit.tenDonVi}
                  key={unitKey}
                  className="employee-select-tree-item"
                  disableCheckbox={disableUnit}
                >
                  {!!employeeUnit.dsPhongBan &&
                    employeeUnit.dsPhongBan.map(department => {
                      const departmentKey = this.setSelectKey(
                        employeeUnit.donViId,
                        department.phongBanId
                      )
                      const disableDepartment =
                        this.disableDepartment(department)
                      return (
                        <TreeNode
                          title={department.tenPhongBan}
                          key={departmentKey}
                          className="employee-select-tree-item"
                          disableCheckbox={disableDepartment}
                        >
                          {!!department.dsNhanVien &&
                            department.dsNhanVien.map(employee => {
                              const employeeKey = this.setSelectKey(
                                employeeUnit.donViId,
                                department.phongBanId,
                                employee
                              )
                              return (
                                <TreeNode
                                  title={employee.tenNhanVien}
                                  key={employeeKey}
                                  className="employee-select-tree-item"
                                  disableCheckbox={defaultSelectKeyList.includes(
                                    employeeKey
                                  )}
                                />
                              )
                            })}
                          {!!department.dsPhongBanCon &&
                            department.dsPhongBanCon.map(childDepartment => {
                              const childDepartmentKey = this.setSelectKey(
                                employeeUnit.donViId,
                                childDepartment.phongBanId
                              )
                              const disableChildDepartment =
                                this.disableDepartment(childDepartment)
                              return (
                                <TreeNode
                                  title={childDepartment.tenPhongBan}
                                  key={childDepartmentKey}
                                  className="employee-select-tree-item"
                                  disableCheckbox={disableChildDepartment}
                                >
                                  {!!childDepartment.dsNhanVien &&
                                    childDepartment.dsNhanVien.map(
                                      childEmployee => {
                                        const childEmployeeKey =
                                          this.setSelectKey(
                                            employeeUnit.donViId,
                                            childDepartment.phongBanId,
                                            childEmployee
                                          )
                                        return (
                                          <TreeNode
                                            title={childEmployee.tenNhanVien}
                                            key={childEmployeeKey}
                                            className="employee-select-tree-item"
                                            disableCheckbox={defaultSelectKeyList.includes(
                                              childEmployeeKey
                                            )}
                                          />
                                        )
                                      }
                                    )}
                                </TreeNode>
                              )
                            })}
                        </TreeNode>
                      )
                    })}
                </TreeNode>
              </Tree>
            )
          )}
        </div>
        <div className="pt-dialog-footer dialog-footer-employee">
          <div className="pt-dialog-footer-actions">
            <div className="box-button">
              <Button
                className="button-blue btn-save-employee"
                text="Lưu"
                intent={Intent.PRIMARY}
                onClick={this.onSubmit}
                disabled={isLoading || isEmptyUnitList || isError}
              />
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  unitList: state.category.categoryUnitMultiLevel,
  unitUserId: _.get(state, 'auth.user.dsChucDanh[0].donViId', null),
})

const mapDispathToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
  fetchEmployee: bindActionCreators(fecthUnitEmployee, dispatch),
  fetchUnit: bindActionCreators(getCommonFetchUnitItem, dispatch),
})

export default connect(mapStateToProps, mapDispathToProps)(EmployeeSelectDialog)
