import React, { Component } from 'react'
import _ from 'lodash'
import * as Types from '../../constants/Api'

class TableDetailBox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dsThanhVien: _.get(props, 'detail.dsThanhVien', []),
    }
  }

  render() {
    const dsThanhVien = _.get(this.state, 'dsThanhVien', []) || []
    const token = _.get(this.props, 'auth.token.accessToken', '')
    return (
      <div className="pb10">
        <div className="table-responsive">
          <table className="table table-detail">
            <thead>
              <tr>
                <th>Stt</th>
                <th>Thành viên</th>
                <th>Phiếu</th>
              </tr>
            </thead>
            <tbody>
              {dsThanhVien.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {item.stt + 1 > 9 ? item.stt + 1 : `0${item.stt + 1}`}
                    </td>
                    <td>
                      <h4>{item.tenNhanVien}</h4>
                    </td>
                    <td>
                      <div className="file-item">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            _.get(item, 'tenNhanVien', false) &&
                            `${Types.FILE_URL}/api/file/get/${item.fileYKienId}?BearerToken=${token}`
                          }
                        >
                          <span className="icon icon-list-check color-blue"></span>
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default TableDetailBox
