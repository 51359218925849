import * as types from '../constants/ActionTypes'

const initialState = {
  detailId: null,
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  dataDetail: {},
}

export default function serviceDocuments(state = initialState, action) {
  switch (action.type) {
    case types.STATISTICAL_SERVICE_DOCUMENTS:
      return {
        ...state,
        list: {
          page:
            action.payload.config &&
            action.payload.config.params &&
            action.payload.config.params.page,
          total:
            action.payload.data &&
            action.payload.data.result &&
            action.payload.data.result.length,
          items: action.payload.data.result || [],
        },
      }
    case types.SERVICE_DOCUMENTS:
      return {
        ...state,
        list: {
          page: action.payload.data.result.page,
          total: action.payload.data.result.total,
          items:
            action.payload.data.result && action.payload.data.result.items
              ? action.payload.data.result.items
              : [],
        },
      }
    case types.SERVICE_DOCUMENTS_DETAIL:
      return {
        ...state,
        dataDetail: action.payload.data.result,
      }
    default:
      return state
  }
}
