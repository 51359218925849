import * as types from '../constants/ActionTypes'
const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  detailId: '',
}

export default function departmentWorks(state = initialState, action) {
  switch (action.type) {
    case types.DEPARTMENT_WORK_NOT_ASSIGN:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.GET_GERENAL_DOCUMENT_OVER_TIME:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.WORK_ITEM:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    default:
      return state
  }
}
