import React, { memo } from 'react'
import { Checkbox, Classes } from '@blueprintjs/core'
import csx from 'classnames'

const TaskCheckbox = ({
  children,
  className,
  onChange = () => {},
  ...props
}) => {
  const _onChange = e => {
    const { checked } = e.target
    onChange(checked)
  }

  return (
    <Checkbox
      {...props}
      className={csx(
        'cpc-checkbox',
        'cpc-checkbox-task',
        Classes.LARGE,
        className
      )}
      onChange={_onChange}
    >
      {children}
    </Checkbox>
  )
}

export default memo(TaskCheckbox)
