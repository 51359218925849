import React, { memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { deleteCongViec } from 'actions/task'
import { Dialog, Card } from 'components/newCommon/'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import {
  DELETE_WORK_MEETING_TITLE,
  DELETE_WORK_MEETING_CONTENT,
} from 'constants/Popup'

const DeleteThongBaoKLCHCongViec = ({ dataSource, onClose, onSuccess }) => {
  const id = dataSource?.id
  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const deleteWork = useCallback(() => {
    requestAction({
      beforeAction: () => setLoading(true),
      action: () => deleteCongViec(id),
      afterResponse: () => {
        setShowDialog(false)
        return onSuccess()
      },
      afterAction: () => setLoading(false),
      successMessage: Toast.SUCCESS(Action.DELETE),
      errorMessage: Toast.FAIL(Action.DELETE),
    })
  }, [id, onSuccess])

  return (
    <Dialog
      buttonUppercase
      isOpen={showDialog}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      confirmText="Xóa"
      loading={loading}
      onCancel={onClose}
      onConfirm={deleteWork}
      onClose={onClose}
      title={DELETE_WORK_MEETING_TITLE}
      textCenter
    >
      <Card className="mt20 mb20">{DELETE_WORK_MEETING_CONTENT}</Card>
    </Dialog>
  )
}

DeleteThongBaoKLCHCongViec.defaultProps = {
  dataSource: null,
  onClose: () => {},
  onSuccess: () => {},
}

DeleteThongBaoKLCHCongViec.propTypes = {
  dataSource: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default memo(DeleteThongBaoKLCHCongViec)
