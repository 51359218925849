import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Types from '../../constants/Api'

class InformationSteeringItem extends Component {
  static propTypes = {
    informationSteering: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div className="item-content-history">
        <div className="time-create">
          {this.props.informationSteering.ngayTao &&
            moment(this.props.informationSteering.ngayTao).format(
              'DD/MM/YYYY HH:mm'
            )}
        </div>
        <div className="avatar-user">
          <span
            className="avatar"
            style={{
              backgroundImage: `url(${
                this.props.informationSteering.nguoiChiDao &&
                this.props.informationSteering.nguoiChiDao.anhDaiDien
                  ? Types.IMAGE_URL +
                    this.props.informationSteering.nguoiChiDao.anhDaiDien
                  : '/images/default_avatar.png'
              })`,
            }}
          ></span>
          <span className="name_user">
            {' '}
            {this.props.informationSteering.nguoiChiDao
              ? this.props.informationSteering.nguoiChiDao.tenNhanVien
              : ''}
          </span>
        </div>
        <div className="info-content">
          <ul className="list-info">
            <li className="item-info">
              <span className="style-icon icon-Chidaobosung"></span>
              <div className="content-detail">
                <span className="title-bold">Chỉ đạo bổ sung: </span>
                <span className="detail">
                  {' '}
                  {this.props.informationSteering.noiDung}{' '}
                </span>
              </div>
            </li>
            <li className="item-info">
              <span className="style-icon icon-Phoihop"></span>
              <div className="content-detail">
                <span className="title-bold">Bổ sung phối hợp: </span>
                {this.props.informationSteering.phoiHop ? (
                  <span className="detail">
                    {this.props.informationSteering.phoiHop.map((item, i) => (
                      <span key={i}>
                        {' '}
                        {i === 0
                          ? item.maPhongBan
                          : ', ' + item.maPhongBan}{' '}
                      </span>
                    ))}
                  </span>
                ) : null}
              </div>
            </li>
            <li className="item-info">
              <span className="style-icon icon-Xemdebiet"></span>
              <div className="content-detail">
                <span className="title-bold">Xem để biết: </span>
                {this.props.informationSteering.xemDeBiet ? (
                  <span className="detail">
                    {this.props.informationSteering.xemDeBiet.map((item, i) => (
                      <span key={i}>
                        {' '}
                        {i === 0
                          ? item.maPhongBan
                          : ', ' + item.maPhongBan}{' '}
                      </span>
                    ))}
                  </span>
                ) : null}
              </div>
            </li>
            <li className="item-info">
              <span className="style-icon icon-Cacdonvitructhuoc"></span>
              <div className="content-detail">
                <span className="title-bold">Các đơn vị trực thuộc: </span>
                {this.props.informationSteering.donViTrucThuoc ? (
                  <span className="detail">
                    {this.props.informationSteering.donViTrucThuoc.map(
                      (item, i) => (
                        <span key={i}>
                          {' '}
                          {i === 0
                            ? item.tenVietTat
                            : ', ' + item.tenVietTat}{' '}
                        </span>
                      )
                    )}
                  </span>
                ) : null}
              </div>
            </li>

            <li className="item-info">
              <span className="style-icon icon-calendar"></span>
              <div className="content-detail">
                <span className="title-bold">Hạn giải quyết: </span>
                <span className="detail">
                  {' '}
                  {this.props.informationSteering.hanGiaiQuyet &&
                    moment(this.props.informationSteering.hanGiaiQuyet).format(
                      'DD/MM/YYYY'
                    )}{' '}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default InformationSteeringItem
