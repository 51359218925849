import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Intent, Classes, Checkbox } from '@blueprintjs/core'
import { get } from 'lodash'
import classNames from 'classnames'

import { Input, Icon, Tag, Loading } from 'components/newCommon'
import { Input as Input2, ColorSelect } from 'components/newCommon2'
import {
  getDsTag,
  postCreateTag,
  deleteTag,
  putEditTag,
  getCongViec,
  postTagCongViec,
} from 'actions/task'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import { checkSearchNoAccents } from 'helpers/string'
import { toDecamelizeKeys } from 'helpers/key'

const TagForm = ({ defaultFormValue, loading, onClose, onSubmit }) => {
  const [isDisabledDefault, setIsDisabledDefault] = useState(true)
  const [formData, setFormData] = useState(
    defaultFormValue || {
      backgroundColor: '#f1f1f1',
      name: '',
    }
  )

  const onChange = name => value => {
    setIsDisabledDefault(false)
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const onSave = () => {
    setIsDisabledDefault(true)
    onSubmit(formData)
  }

  return (
    <div className="align-center">
      <div style={{ width: 60 }}>
        <ColorSelect
          inputWrapperClassName="p5"
          onChange={onChange('backgroundColor')}
          value={get(formData, 'backgroundColor')}
          popoverClassName="ml15"
          sampleText={get(formData, 'name')}
        />
      </div>
      <Input2
        type="text"
        onChange={onChange('name')}
        value={get(formData, 'name', '')}
        placeholder="Nhập tên loại công việc"
        wrapperClassName={classNames('mr15', 'ph5 pv10')}
        wrapperStyle={{ flex: 1 }}
      />
      <Icon
        classIcon="icon-Huy"
        className={classNames('has-event', 'size-icon-10', 'mr15', 'd-flex')}
        onClick={onClose}
      />
      <Button
        className={classNames('cpc-button', 'text-uppercase', 'font-size-13')}
        intent={Intent.PRIMARY}
        onClick={onSave}
        loading={loading}
        disabled={!get(formData, 'name', '').trim() || isDisabledDefault}
      >
        <Icon classIcon="icon-save" />
      </Button>
    </div>
  )
}

const AddTags = ({ dataSource, onSuccess, onClose }) => {
  const id = dataSource?.id
  const [tags, setTags] = useState([])
  const [isDisabledDefault, setIsDisabledDefault] = useState(true)
  const [checkedFetching, setCheckedFetching] = useState(false)
  const [editId, setEditId] = useState(null)
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [selected, setSelected] = useState([])
  const [isFetch, setIsFetch] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const toggleEdit = useCallback(
    (id = null) =>
      () => {
        if (!loadingEdit) setEditId(id)
      },
    [loadingEdit]
  )

  const editProps = useMemo(
    () => ({
      onCancel: toggleEdit(),
      loading: loadingEdit,
    }),
    [toggleEdit, loadingEdit]
  )

  const fetchTag = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => setCheckedFetching(true),
      action: () => getCongViec(id),
      afterResponse: ({ workUserTags = [] }) => {
        setSelected(workUserTags.map(taskTag => taskTag.workTagId))
      },
      afterAction: () => setCheckedFetching(false),
    })
  }, [id])

  const changeSearch = text => {
    setTags(prev => prev.filter(({ name }) => checkSearchNoAccents(name, text)))
  }

  const onCreate = formValues => {
    requestAction({
      beforeAction: () => {
        setLoadingEdit(true)
      },
      action: () =>
        postCreateTag(toDecamelizeKeys({ ...formValues, isActive: true })),
      afterResponse: result => {
        setTags(prev => [...prev, result])
        setEditId(null)
      },
      afterAction: () => setLoadingEdit(false),
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const onEdit = tagId => formValues => {
    requestAction({
      beforeAction: () => {
        setLoadingEdit(true)
      },
      action: () =>
        putEditTag(
          toDecamelizeKeys({ ...formValues, id: tagId, isActive: true })
        ),
      afterResponse: res => {
        setTags(prev => prev.map(tag => (tag.id === tagId ? res : tag)))
        setEditId(null)
      },
      afterAction: () => setLoadingEdit(false),
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      warningMessage: Toast.INCOMPLETE,
      logError: true,
    })
  }

  const onDelete = tagId => e => {
    setTags(prev => prev.filter(tag => tag.id !== tagId))
    e.preventDefault()
    requestAction({
      action: () => deleteTag(tagId),
      afterResponse: () =>
        setTags(prev => prev.filter(tag => tag.id !== tagId)),
      successMessage: Toast.SUCCESS(Action.DELETE),
      errorMessage: Toast.FAIL(Action.DELETE),
    })
  }

  const toggleUse = tagId => e => {
    const { checked } = e.target
    const newValue = checked
      ? [...selected, tagId]
      : selected.filter(id => id !== tagId)
    setSelected(newValue)
    setIsDisabledDefault(false)
  }

  const onSave = useCallback(() => {
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => setIsSubmit(true),
      action: () =>
        postTagCongViec(
          id,
          selected.map(select => ({ work_tag_id: select }))
        ),
      afterResponse: () => {
        onSuccess()
        return onClose()
      },
      afterAction: () => setIsSubmit(false),
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }, [id, selected, onSuccess, onClose])

  const closeUpdateTag = () => {
    setEditId(null)
  }

  const fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      beforeAction: () => setIsFetch(true),
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => setTags(items),
      afterAction: () => setIsFetch(false),
    })
  }, [])

  useEffect(() => {
    fetchTag()
  }, [fetchTag])

  useEffect(() => {
    fetchDsTag()
  }, [fetchDsTag])

  return (
    <div className={'cpc-form'}>
      <h1 className="d-flex justify-space-between align-center border-bottom ph14 pv15">
        <span
          className="icon2-arrow-dart-left font-size-14 cursor-pointer"
          onClick={onClose}
        ></span>
        <span className="text-13-500 text-uppercase color-gray">
          Quản lý loại công việc
        </span>
        <span></span>
      </h1>
      <div style={{ padding: 10 }} className="border-bottom">
        <Input
          className="cpc-input-search mb5"
          leftIconName="search"
          onChange={changeSearch}
          placeholder="Tìm kiếm"
        />
        <div className="cpc-list">
          {!isFetch ? (
            tags.map(({ id, name, backgroundColor }) =>
              editId === id ? (
                <TagForm
                  {...editProps}
                  key="tag-edit"
                  onSubmit={onEdit(id)}
                  onClose={closeUpdateTag}
                  defaultFormValue={{
                    name,
                    backgroundColor,
                  }}
                />
              ) : (
                <div className={classNames('cpc-list-item', 'mh10')} key={id}>
                  <Checkbox
                    className="cpc-checkbox"
                    onChange={toggleUse(id)}
                    checked={selected.includes(id)}
                    disabled={checkedFetching}
                  >
                    <Tag name={name} color={backgroundColor} />
                    <span className="action">
                      <i
                        className="icon-but size-icon-12 mr10 icon-color-primary"
                        onClick={toggleEdit(id)}
                      />
                      <i
                        className="icon-bin size-icon-12 icon-color-danger"
                        onClick={onDelete(id)}
                      />
                    </span>
                  </Checkbox>
                </div>
              )
            )
          ) : (
            <Loading />
          )}
        </div>
      </div>
      <div style={{ padding: 10 }}>
        {editId === 'NEW' && (
          <TagForm
            {...editProps}
            onClose={closeUpdateTag}
            onSubmit={onCreate}
          />
        )}
        <Button
          className={classNames(
            Classes.MINIMAL,
            'cpc-form-button-left',
            'cpc-button'
          )}
          intent={Intent.PRIMARY}
          iconName="plus"
          text="Thêm"
          onClick={toggleEdit('NEW')}
        />
      </div>

      <div className="d-flex align-items-center justify-content-center gap-15 border-top ph15">
        <Button
          className={
            'cpc-button white-border-gray uppercase font-size-13 ph10 min-width-100'
          }
          disabled={isSubmit}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={'cpc-button blue uppercase font-size-13 ph10 min-width-80'}
          loading={isSubmit}
          disabled={isSubmit || isDisabledDefault}
          onClick={onSave}
        >
          Lưu
          <Icon classIcon="icon-save" className="ml5" />
        </Button>
      </div>
    </div>
  )
}

AddTags.defaultProps = {
  dataSource: null,
  onSuccess: () => {},
  onClose: () => {},
}

AddTags.propTypes = {
  dataSource: PropTypes.object,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
}

export default memo(AddTags)
