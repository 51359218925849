import * as types from '../constants/ActionTypes'

// Chức năng tạo việc từ CHAT và Hỗ trợ CNTT hoặc nguồn khác
export const setTokenWithQueryCreateWork = token => ({
  type: types.AUTH_SET_TOKEN_CREATE_WORK,
  payload: {
    data: token,
  },
})

export const getMeCreateWork =
  (roleId = null) =>
  (dispatch, getState) => {
    return dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.SET_ME_INFOS,
        types.AUTH_DISCARD_TOKEN,
      ],
      payload: {
        client: 'profile',
        request: {
          url: '/api/me',
          method: 'GET',
          headers: {
            Authorization:
              getState().createWork?.tokenCreateWork.tokenType +
              ' ' +
              getState().createWork?.tokenCreateWork.accessToken,
          },
        },
      },
      roleId,
    })
  }

export const getMessengeDetailCreateWork = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.AUTH_DISCARD_TOKEN,
    ],
    payload: {
      client: 'profile',
      request: {
        url: `/api/tinnhan/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().createWork?.tokenCreateWork.tokenType +
            ' ' +
            getState().createWork?.tokenCreateWork.accessToken,
          ChucDanhId: getState().createWork?.roleIdCreateWork,
        },
      },
    },
  })

export const getHoTroCNTTChiTietCreateWork = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.AUTH_DISCARD_TOKEN,
    ],
    payload: {
      client: 'profile',
      request: {
        url: `/api/remote/hotrocntt/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().createWork?.tokenCreateWork.tokenType +
            ' ' +
            getState().createWork?.tokenCreateWork.accessToken,
          ChucDanhId: getState().createWork?.roleIdCreateWork,
        },
      },
    },
  })

export const getUserPermissionCreateWork = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.USER_PERMISSION_CREATE_WORK,
      types.AUTH_DISCARD_TOKEN,
    ],
    payload: {
      request: {
        url: `/api/quyen/nhanvien/${id}`,
        method: 'GET',
        headers: {
          Authorization:
            getState().createWork?.tokenCreateWork.tokenType +
            ' ' +
            getState().createWork?.tokenCreateWork.accessToken,
          ChucDanhId: getState().createWork?.roleIdCreateWork,
        },
      },
    },
  })

export const getHoTroCNTTFilesCreateWork = id => (dispatch, getState) =>
  dispatch({
    types: [
      types.API_REQUEST_SEND,
      types.API_REQUEST_SUCCESS,
      types.AUTH_DISCARD_TOKEN,
    ],
    payload: {
      client: 'profile',
      request: {
        url: `/api/remote/hotrocntt/${id}/attachments`,
        method: 'GET',
        headers: {
          Authorization:
            getState().createWork?.tokenCreateWork.tokenType +
            ' ' +
            getState().createWork?.tokenCreateWork.accessToken,
          ChucDanhId: getState().createWork?.roleIdCreateWork,
        },
      },
    },
  })

export const getHoTroCNTTFileCreateWork =
  (idCNTT, id) => (dispatch, getState) =>
    dispatch({
      types: [
        types.API_REQUEST_SEND,
        types.API_REQUEST_SUCCESS,
        types.AUTH_DISCARD_TOKEN,
      ],
      payload: {
        client: 'downloadFromChat',
        request: {
          url: `/api/remote/hotrocntt/${idCNTT}/attachments/${id}/download`,
          method: 'GET',
          headers: {
            Authorization:
              getState().createWork?.tokenCreateWork.tokenType +
              ' ' +
              getState().createWork?.tokenCreateWork.accessToken,
            ChucDanhId: getState().createWork?.roleIdCreateWork,
          },
        },
      },
    })
