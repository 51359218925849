import React, { memo, useState } from 'react'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Scrollbars } from 'react-custom-scrollbars'
import size from 'lodash/size'

const ListTooltipShowMore = ({ data = [], dataKey = { name: 'name' } }) => {
  const [isOpen, setIsOpen] = useState(false)
  const newData = [...data]
  const sizeData = size(newData)
  if (sizeData === 0) {
    return <span></span>
  }

  if (sizeData <= 3) {
    return <div>{newData.map(e => e[dataKey.name]).join(', ')}</div>
  }

  const newDataSlice = newData.slice(0, 3)
  const newDataSplice = newData.splice(0, 3)
  return (
    <div className="list-tooltip-show-more">
      <div>{newDataSlice.map(e => e[dataKey.name]).join(', ')}</div>
      <div>
        <Popover
          // portalClassName="cpc-receivers-list-more"
          // popoverClassName="pt-minimal"
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.TOP}
          popoverWillOpen={true}
          isOpen={isOpen}
          openOnTargetFocus={true}
          content={
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={200}>
              <div className="list-tooltip-show-more__content">
                {newDataSplice.map((e, index) => {
                  return <p key={index}>{e[dataKey.name]}</p>
                })}
              </div>
            </Scrollbars>
          }
          tetherOptions={{
            constraints: [{ attachment: 'together', to: 'scrollParent' }],
          }}
        >
          <span
            className="icon-arrow-up font-size-13 ml5"
            onClick={() => setIsOpen(prev => !prev)}
          />
        </Popover>
      </div>
    </div>
  )
}

export default memo(ListTooltipShowMore)
