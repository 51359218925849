import * as Types from '../constants/ActionTypes'

import alertMistakes from './alertMistakes'
import appliedUnit from './appliedUnit'
import assignmentHistory from './assignmentHistory'
import assignmentInformation from './assignmentInformation'
import auth from './auth'
import authorityDocument from './authorityDocument'
import authorityWork from './authorityWork'
import category from './category'
import { combineReducers } from 'redux'
import common from './common'
import contact from './contact'
import departmentWorks from './departmentWorks'
import departmentWorksAssigned from './departmentWorksAssigned'
import digitalDocuments from './digitalDocuments'
import documentsCabinet from './documentsCabinet'
import entities from './entities'
import evnDocuments from './evnDocuments'
import evnDocumentsNoSchema from './evnDocumentsNoSchema'
import executingViews from './executingViews'
import listInternalDocuments from './listInternalDocuments'
import notifications from './notifications'
import personalWorks from './personalWorks'
import procedure from './procedure'
import procedureAuthority from './procedureAuthority'
import procedureCategory from './procedureCategory'
import projects from './projects'
import proposeAssignment from './proposeAssignment'
import receiveDocuments from './receiveDocuments'
import reportWork from './reportWork'
import { routerReducer as routing } from 'react-router-redux'
import sendDocuments from './sendDocuments'
import signNumberProjects from './signNumberProjects'
import signNumbers from './signNumbers'
import statisticAlertMistakes from './statisticAlertMistakes'
import statisticMessenger from './statisticMessenger'
import serviceDocuments from './serviceDocuments'
import statisticSignNumber from './statisticSignNumber'
import statisticUsers from './statisticUsers'
import system from './system'
import users from './users'
import workAssignInformations from './workAssignInformations'
// Hoi dong thanh vien
import opinions from './opinions'
import revokeDocuments from './revokeDocuments'
// Chat
import chats from './chats'
// Tìm kiếm công văn 6.1
import timKiemCongVan61 from './timKiemCongVan61'
// Chi tiết công văn nội bộ
import detailInternalDocuments from './detailInternalDocuments'

//Goi yeu cau gop y
import requestComments from './requestComments'
// Công văn đến thu hồi
import congVanDenThuHoi from './congVanDenThuHoi'
// QUẢN LÝ CÔNG VIÊC
import TaskManager from './TaskManager'
import counters from './counter'
import dashboard from './dashboard'
import doffice from './doffice'
import createWork from './createWork'

const appReducer = combineReducers({
  auth,
  contact,
  entities,
  receiveDocuments,
  sendDocuments,
  personalWorks,
  executingViews,
  assignmentInformation,
  reportWork,
  assignmentHistory,
  departmentWorks,
  departmentWorksAssigned,
  common,
  digitalDocuments,
  proposeAssignment,
  routing,
  signNumbers,
  signNumberProjects,
  projects,
  listInternalDocuments,
  workAssignInformations,
  documentsCabinet,
  category,
  users,
  authorityWork,
  alertMistakes,
  evnDocuments,
  evnDocumentsNoSchema,
  notifications,
  authorityDocument,
  system,
  statisticSignNumber,
  statisticUsers,
  statisticAlertMistakes,
  statisticMessenger,
  revokeDocuments,
  serviceDocuments,
  opinions,
  chats,
  requestComments,
  procedureCategory,
  procedure,
  appliedUnit,
  procedureAuthority,
  congVanDenThuHoi,
  timKiemCongVan61,
  TaskManager,
  counters,
  detailInternalDocuments,
  dashboard,
  doffice,
  createWork,
})

const rootReducer = (state, action) => {
  if (action.type === Types.AUTH_DISCARD_TOKEN) {
    const { routing } = state
    state = { routing }
  }

  return appReducer(state, action)
}

export default rootReducer
