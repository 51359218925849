import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import classnames from 'classnames'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class ListFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {
        textSearch: '',
        ngayHoanThanh: {},
      },
    }

    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentWillReceiveProps(props) {
    const { filter } = props

    if (filter !== this.props.filter) {
      const { ngayHoanThanh } = filter
      this.setState({
        filter: {
          textSearch: filter.textSearch || '',
          ngayHoanThanh: {
            from:
              ngayHoanThanh &&
              ngayHoanThanh.from &&
              ngayHoanThanh.from.trim().length > 0
                ? moment(ngayHoanThanh.from)
                : undefined,
            to:
              ngayHoanThanh &&
              ngayHoanThanh.to &&
              ngayHoanThanh.to.trim().length > 0
                ? moment(ngayHoanThanh.to)
                : undefined,
          },
        },
      })
    }
  }

  clearFilter(load = true) {
    if (
      this.props.status === 'chua-thuc-hien' ||
      this.props.status === 'dang-thuc-hien'
    ) {
      const filter = {
        textSearch: '',
        ngayHoanThanh: {
          from: undefined,
          to: undefined,
        },
      }
      return this.setState(
        {
          filter,
        },
        e => {
          if (e) {
            e.preventDefault()
          }
          if (load) {
            const filter = {
              ...this.state.filter,
              ngayHoanThanh: {
                from: this.state.filter.ngayHoanThanh.from
                  ? moment(this.state.filter.ngayHoanThanh.from).format(
                      'YYYY-MM-DD'
                    )
                  : '',
                to: this.state.filter.ngayHoanThanh.to
                  ? moment(this.state.filter.ngayHoanThanh.to).format(
                      'YYYY-MM-DD'
                    )
                  : '',
              },
            }
            this.props.handleResetCheck(filter)
            this.props.actions.getNumberWorkPage()
          }
        }
      )
    }

    const filter = {
      textSearch: '',
      ngayHoanThanh: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
    }
    this.setState(
      {
        filter,
      },
      e => {
        if (e) {
          e.preventDefault()
        }
        if (load) {
          const filter = {
            ...this.state.filter,
            ngayHoanThanh: {
              from: this.state.filter.ngayHoanThanh.from
                ? moment(this.state.filter.ngayHoanThanh.from).format(
                    'YYYY-MM-DD'
                  )
                : '',
              to: this.state.filter.ngayHoanThanh.to
                ? moment(this.state.filter.ngayHoanThanh.to).format(
                    'YYYY-MM-DD'
                  )
                : '',
            },
          }
          this.props.handleResetCheck(filter)
          this.props.actions.getNumberWorkPage()
        }
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
    })
    this.props.handleDateChange && this.props.handleDateChange(name, pos, e)
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayHoanThanh: {
        from: this.state.filter.ngayHoanThanh.from
          ? moment(this.state.filter.ngayHoanThanh.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayHoanThanh.to
          ? moment(this.state.filter.ngayHoanThanh.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.clearFilterColumn && this.props.clearFilterColumn(filter)
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
    this.props.removeTextSearch && this.props.removeTextSearch('textSearch')
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className={classnames(
          'search-container search-DocumentarySearch pb30 px-sm-15',
          {
            pt15: this.props.status === 'da-thuc-hien',
            pt30: this.props.status !== 'da-thuc-hien',
          }
        )}
      >
        <div className="row">
          {this.props.status === 'da-thuc-hien' && (
            <div className="col-xs-12 col-md-4">
              <div>
                <label className="search-label">
                  Thời gian (Ngày hoàn thành)
                </label>
                <div className="mt5 form-input-filter date-picker-inline border-radius-3">
                  <DatePicker
                    readOnly
                    isClearable
                    selected={this.state.filter.ngayHoanThanh.from}
                    selectsStart
                    startDate={this.state.filter.ngayHoanThanh.from}
                    endDate={this.state.filter.ngayHoanThanh.to}
                    onChange={this.handleDateChange.bind(
                      this,
                      'ngayHoanThanh',
                      'from'
                    )}
                    format="DD/MM/YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                    placeholderText="Từ ngày"
                    maxDate={this.state.filter.ngayHoanThanh.to}
                    popperPlacement="auto"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '5px, 10px',
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                      },
                    }}
                  />
                  <DatePicker
                    readOnly
                    isClearable
                    selected={this.state.filter.ngayHoanThanh.to}
                    selectsEnd
                    startDate={this.state.filter.ngayHoanThanh.from}
                    endDate={this.state.filter.ngayHoanThanh.to}
                    onChange={this.handleDateChange.bind(
                      this,
                      'ngayHoanThanh',
                      'to'
                    )}
                    format="DD/MM/YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                    placeholderText="Đến ngày"
                    minDate={this.state.filter.ngayHoanThanh.from}
                    popperPlacement="auto"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '5px, 10px',
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div
            className={classnames('col-xs-12 mt20 mt-md-0 align-items-end', {
              'col-md-8': this.props.status === 'da-thuc-hien',
            })}
          >
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                name="textSearch"
                className="input border-transparent pr-40 input-search-filter"
                type="text"
                onChange={this.handleInputChange}
                placeholder="Tìm kiếm Số CV, Trích yếu, Số hóa (hoặc Số CV:Trích yếu)"
                value={this.state.filter.textSearch}
                maxLength={255}
              />
              {this.state.filter.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'textSearch')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

export default ListFilter
