import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class ListFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {
        textSearch: '',
        ngayCongVan: {
          from: undefined,
          to: undefined,
        },
      },
    }
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentWillReceiveProps(props) {
    if (props.filter) {
      this.setState({
        filter: {
          textSearch: props.filter.textSearch || '',
          ngayCongVan: {
            from:
              props.filter.ngayCongVan &&
              props.filter.ngayCongVan.from &&
              props.filter.ngayCongVan.from.trim().length > 0
                ? moment(props.filter.ngayCongVan.from)
                : undefined,
            to:
              props.filter.ngayCongVan &&
              props.filter.ngayCongVan.to &&
              props.filter.ngayCongVan.to.trim().length > 0
                ? moment(props.filter.ngayCongVan.to)
                : undefined,
          },
        },
      })
    }
  }

  clearFilter(e) {
    this.setState(
      {
        filter: {
          textSearch: '',
          ngayCongVan: {
            from: undefined,
            to: undefined,
          },
        },
      },
      () => {
        this.props.clearFilterColumn &&
          this.props.clearFilterColumn(this.state.filter, true)
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
    })
    this.props.handleDateChange && this.props.handleDateChange(name, pos, e)
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayCongVan: {
        from: this.state.filter.ngayCongVan.from
          ? moment(this.state.filter.ngayCongVan.from).format('YYYY-MM-DD')
          : undefined,
        to: this.state.filter.ngayCongVan.to
          ? moment(this.state.filter.ngayCongVan.to).format('YYYY-MM-DD')
          : undefined,
      },
    }
    this.props.clearFilterColumn && this.props.clearFilterColumn(filter, false)
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
    this.props.removeTextSearch && this.props.removeTextSearch('textSearch')
  }
  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch pt15 pb20 px-sm-15"
      >
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <label className="search-label mb10">
              Thời gian (Ngày công văn)
            </label>
            <div className="row-picker-range">
              <div className="col-picker-range">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCongVan.from}
                  selectsStart
                  startDate={this.state.filter.ngayCongVan.from}
                  endDate={this.state.filter.ngayCongVan.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'from'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent"
                  placeholderText="Từ ngày"
                  maxDate={this.state.filter.ngayCongVan.to}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
              <div className="col-picker-range">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCongVan.to}
                  selectsEnd
                  startDate={this.state.filter.ngayCongVan.from}
                  endDate={this.state.filter.ngayCongVan.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'to'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent"
                  placeholderText="Đến ngày"
                  minDate={this.state.filter.ngayCongVan.from}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-8 mt20 mt-md-0 align-items-end">
            <div className="form-input-search flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                name="textSearch"
                className="input border-transparent pr-40 input-search-filter"
                type="text"
                placeholder="Tìm kiếm Số CV, Trích yếu, Số hóa (hoặc Số CV Trích yếu)"
                onChange={this.handleInputChange}
                value={this.state.filter.textSearch}
                maxLength={255}
              />
              {this.state.filter.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'textSearch')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              type="button"
              className="btn-icon flex-solid ml10 pt-intent-reload"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

ListFilter.propTypes = {
  units: PropTypes.array,
  onSubmit: PropTypes.func,
}

export default ListFilter
