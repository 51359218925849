import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withRouter from 'react-router/withRouter'

class HeaderMenuItem extends Component {
  constructor(props) {
    super(props)
    this.handleClickMenu = this.handleClickMenu.bind(this)
  }

  static propTypes = {
    item: PropTypes.shape({
      path: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }

  handleClickMenu = e => {
    e.preventDefault()
    if (this.props.item && this.props.item.typeSidebar) {
      this.props.actions.commonSaveTypeSidebar(this.props.item.typeSidebar)
    }
    this.props.hideWhenclickMenu && this.props.hideWhenclickMenu()
    if (this.props.item && this.props.item.path && this.props.history) {
      this.props.history.push(this.props.item.path)
    }
  }

  render() {
    const location = this.props.location.pathname
    let isActive = location === this.props.item.path

    if (this.props.item.links.length > 0) {
      for (let i = 0; i < this.props.item.links.length; i++) {
        let link =
          location[
            location.lastIndexOf(this.props.item.links[i]) +
              this.props.item.links[i].length
          ]
        if (
          location === this.props.item.links[i] ||
          (location.lastIndexOf(this.props.item.links[i]) === 0 && link === '/')
        ) {
          isActive = true
          break
        }
      }
    }

    return (
      <li
        className={classnames('item', {
          active: isActive,
        })}
      >
        <span onClick={this.handleClickMenu} className="menu-link">
          <span className={'top-icon ' + this.props.item.icon}>
            {this.props.item.newStatus ? (
              <span className="status-new"></span>
            ) : (
              ''
            )}
          </span>
          <span className="text-content">{this.props.item.name}</span>
        </span>
      </li>
    )
  }
}

export default withRouter(HeaderMenuItem)
