export const defaultLabel = '#E6E6E4'

export const qlcvLabel = [
  '#FFA332',
  '#FFBD6C',
  '#FFD384',
  '#FFD3B5',
  '#FFDFBA',
  '#FFFAE0',
  '#99D98C',
  '#AAF683',
  '#BFFFBA',
  '#CCFF33',
  '#EDFFBA',
  '#F5FFD8',
  '#88BCFF',
  '#A7C5EB',
  '#AFE7FF',
  '#A4EBF3',
  '#87FFE6',
  '#BAFFF1',
  '#D5B9B2',
  '#FFADAD',
  '#FFC6BA',
  '#FFDCDC',
  '#FFEAEA',
  '#FFF0F0',
  '#FCA3B9',
  '#E0B1CB',
  '#FFA1EC',
  '#CCBAFF',
  '#EFC3E6',
  '#FEEAFA',
  '#C3FFA7',
  '#CDF6BA',
  '#DBF0D2',
  '#C5D7BD',
  '#D6DFD2',
  '#E2E5E0',
  '#FFEF03',
  '#F6EC54',
  '#EFE994',
  '#E9E6C0',
  '#E6E5DC',
  defaultLabel,
]
