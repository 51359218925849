import React from 'react'
import _, { get, size } from 'lodash'
import classnames from 'classnames'
import arrayToTree from 'array-to-tree'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  Popover,
  Position,
  Checkbox,
  PopoverInteractionKind,
} from '@blueprintjs/core'
import { PopupConfirm, UpdateDrawers } from '../common/'
import {
  THEM_MOI_NGAN_TU,
  CHINH_SUA_NGAN_TU,
  TEXT_BUTTOM_ADD,
  TEXT_BUTTOM_EDIT,
} from '../../constants/Popup'
import { Authorization } from '../auth'
import { Redirect } from 'react-router-dom'
import { STATUS_HSCV } from '../../constants/Api'
import { Utils } from '../../helpers'

const DON_VI = '1'
const PHONG_BAN = '2'
const CA_NHAN = '3'
const HO_SO_CONG_VIEC = '4'

const LOAI = {
  THU_MUC: 0,
  HO_SO_CONG_VIEC: 1,
}
class sidebarCabinet extends React.Component {
  static defaultProps = {
    actionPermissions: [],
    staffs: [],
    categories: [],
    disableIds: [],
    mutilSelect: false,
    auth: {},
  }

  state = {
    item: {},
    checked: [],
    isOpen: {},
    isOpenPopup: false,
    isOpenPopupAddDrawers: false,
    active: this.props.nganTuId,
    type: 'Edit',
    titlePopup: '',
    isOpenPopover: false,
    data: null,
    isRedirect: false,
    categories: [],
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
    this.handleCheckCategories = this.handleCheckCategories.bind(this)
  }

  componentDidMount() {
    this.setPreData()
    if (!!this.props.pathDocumentCabinet) {
      this.openPathDocument(this.props.pathDocumentCabinet)
    }
  }

  componentWillReceiveProps(props) {
    if (props.categories !== this.props.categories) {
      this.setState({
        checked: [],
      })
    }

    if (props.nganTuId !== this.props.nganTuId) {
      this.setState({
        active: props.nganTuId,
      })
    }
  }

  setPreData = () => {
    const data = Utils.preData('danh-sach-tu-tai-lieu-sidebarcabinet')
    if (data) {
      this.setState(
        {
          isOpen: data,
        },
        () => {
          Utils.saveData('danh-sach-tu-tai-lieu-sidebarcabinet', data)
        }
      )
    }
  }

  nameTabToType = nameTab => {
    switch (nameTab) {
      case 'TTL ĐƠN VỊ':
        return '1'
      case 'TTL PHÒNG BAN':
        return '2'
      case 'HỒ SƠ CÔNG VIỆC':
        return '4'
      default:
        return '3'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.pathDocumentCabinet &&
      prevProps.pathDocumentCabinet !== this.props.pathDocumentCabinet &&
      this.props.type === this.nameTabToType(this.props.nameTab)
    ) {
      this.openPathDocument(this.props.pathDocumentCabinet)
    }

    if (prevProps.type !== this.props.type) {
      this.props.actions.setDocumentCabinetPath('')
      this.setState({
        isOpen: {},
      })
    }

    if (
      this.props.categories !== prevProps.categories &&
      this.props.categories.length > 0
    ) {
      this.setState({ categories: this.checkShow(this.props.categories) })
    }
  }

  openPathDocument = path => {
    let newIsOpen = { ...this.state.isOpen }
    let pathArr = path.split('/')
    pathArr.shift()
    let targetCategory
    if (pathArr.length === 1) {
      targetCategory = this.props.categories.find(
        i => i.tenNganTu === pathArr[0] && i.nganTuChaId === null
      )
    } else if (pathArr.length > 1) {
      let category = this.props.categories.find(
        i => i.tenNganTu === pathArr[0] && i.nganTuChaId === null
      )
      let idItem = category && category.id
      newIsOpen[idItem] = true
      for (let i = 1; i < pathArr.length; i++) {
        const item = pathArr[i]
        let dsNganTuCon =
          category && category.children && category.children.length > 0
            ? category.children
            : []
        if (i === pathArr.length - 1) {
          targetCategory = dsNganTuCon.find(i => i.tenNganTu === item)
        } else {
          category = dsNganTuCon.find(i => i.tenNganTu === item)
          idItem = category && category.id
          newIsOpen[idItem] = true
        }
      }
    }
    this.setState(
      {
        isOpen: newIsOpen,
      },
      () => {
        targetCategory && this.handleCategoryClick(targetCategory)
      }
    )
  }

  handleClick = (id, e) => {
    e.preventDefault()
    this.setState(
      {
        isOpen: {
          ...this.state.isOpen,
          [id]: !this.state.isOpen[id],
        },
      },
      () => {
        Utils.saveData(
          'danh-sach-tu-tai-lieu-sidebarcabinet',
          this.state.isOpen
        )
      }
    )
  }

  handleClickPopup = (type = 'Delete', item = {}) => {
    if (type === 'Delete') {
      this.setState({
        item,
        isOpenPopup: !this.state.isOpenPopup,
        error: '',
        isOpenPopover: {
          ...this.state.isOpenPopover,
          [item.id]: false,
        },
      })
    } else {
      this.setState({
        item,
        isOpenPopup: !this.state.isOpenPopup,
        error: '',
        isOpenPopover: false,
      })
    }
  }

  handleOpenPopover = (id, e) => {
    e.preventDefault()
    this.setState({
      isOpenPopover: {
        ...this.state.isOpenPopover,
        [id]: this.state.isOpenPopover[id],
      },
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.props.onSubmitRemoveItem &&
      this.props.onSubmitRemoveItem(this.state.item.id)
    this.setState({
      isOpenPopup: false,
    })
  }

  handleUpdateDrawersPopup = async (type = 'Edit', item, e) => {
    if (this.props.type === DON_VI && item && item.id) {
      await this.props.actions.getCabinetUnitItem(item && item.id).then(res => {
        if (res.error) {
          return
        }
        const data = res.payload && res.payload.data && res.payload.data.result
        this.setState({ data })
      })
    }

    if (this.props.type === PHONG_BAN && item && item.id) {
      await this.props.actions
        .getCabinetDepartmentItem(item && item.id)
        .then(res => {
          if (res.error) {
            return
          }
          const data =
            res.payload && res.payload.data && res.payload.data.result
          this.setState({ data })
        })
    }

    if (this.props.type === CA_NHAN && item && item.id) {
      await this.props.actions
        .getCabinetPersonalItem(item && item.id)
        .then(res => {
          if (res.error) {
            return
          }
          const data =
            res.payload && res.payload.data && res.payload.data.result
          this.setState({ data })
        })
    }

    let info = {
      item,
      titlePopup: CHINH_SUA_NGAN_TU,
      buttonAction: TEXT_BUTTOM_EDIT,
    }

    if (type === 'Add') {
      info = {
        item: {
          nganTuChaId: item.id,
        },
        titlePopup: THEM_MOI_NGAN_TU,
        buttonAction: TEXT_BUTTOM_ADD,
      }
      this.setState({ data: null })
    }

    if (item === undefined) {
      this.setState({
        ...info,
        type,
        isOpenPopupAddDrawers: !this.state.isOpenPopupAddDrawers,
        isOpenPopover: false,
      })
    } else {
      this.setState({
        ...info,
        type,
        isOpenPopupAddDrawers: !this.state.isOpenPopupAddDrawers,
        isOpenPopover: {
          ...this.state.isOpenPopover,
          [item.id]: false,
        },
      })
    }
  }

  handleCategoryClick(item, e) {
    e && e.preventDefault()

    this.setState({ active: item.id })

    this.props.onCategoryClick && this.props.onCategoryClick(item)
    this.props.showContentSideBar(item)
  }

  handleSubmitForm(data) {
    this.setState({ data: null }, () => {
      this.props.onSubmit && this.props.onSubmit(data, this.state.type)
      this.handleUpdateDrawersPopup()
    })
  }

  handleCheckCategories(e) {
    let { checked } = this.state

    if (e.target.checked) {
      checked.push(e.target.value)
    } else {
      if (checked.indexOf(e.target.value) !== -1) {
        delete checked[checked.indexOf(e.target.value)]
      }
    }

    this.setState(
      {
        checked: _.compact(checked),
      },
      () => {
        this.props.onCheckCategory &&
          this.props.onCheckCategory(this.state.checked)
      }
    )
  }

  handleMoveDrawer(item) {
    this.setState({
      data: item,
      isRedirect: true,
    })
  }

  checkShow = (list = [], disabled = []) => {
    const { auth } = this.props
    const roleUserId = get(auth, 'roleId')
    const newList = list.reduce((result, item) => {
      if (
        [
          get(item, 'nguoiChiuTrachNhiem') || {},
          ...(get(item, 'dsNguoiPhoiHop') || []),
        ]
          .map(v => get(v, 'chucDanhId'))
          .includes(roleUserId) === false &&
        item.loai === 1
      ) {
        return result
      }

      const child = this.checkShow(get(item, 'dsHoSoCongViecCon', []), disabled)
      if (!item.loai && !size(child)) {
        return result
      }

      return [
        ...result,
        {
          ...item,
          dsHoSoCongViecCon: child,
        },
      ]
    }, [])

    return newList
  }

  checkDisable = item => {
    const { auth, listDisable } = this.props
    const roleUserId = get(auth, 'roleId')
    if (this.props.typeName === HO_SO_CONG_VIEC) {
      if (listDisable.includes(item.id)) {
        return true
      } else if (
        ![STATUS_HSCV.CHUA_NOP, STATUS_HSCV.TRA_LAI].includes(
          get(item, 'trangThai', 0)
        )
      ) {
        return true
      } else if (
        [
          get(item, 'nguoiChiuTrachNhiem') || {},
          ...(get(item, 'dsNguoiPhoiHop') || []),
        ]
          .map(v => get(v, 'chucDanhId'))
          .includes(roleUserId) === false &&
        item.loai === 1
      ) {
        return true
      }
      return false
    }
    return false
  }

  renderSubItem(items) {
    return (
      <ul className="sublist">
        {items &&
          items.map(item => {
            if (item.children) {
              item.dsNganTuCon = item.children
            }

            return (
              <li className="subitem" key={item.id}>
                <div
                  className={classnames('main-content', {
                    active: this.state.active === item.id,
                  })}
                >
                  {item.dsNganTuCon && (
                    <span
                      className="dropdown-left"
                      onClick={this.handleClick.bind(this, item.id)}
                    >
                      <span
                        className={classnames(
                          'pt-icon pt-icon-chevron-up d-block',
                          {
                            flipY: this.state.isOpen[item.id],
                          }
                        )}
                      ></span>
                    </span>
                  )}
                  {this.props.typeName === HO_SO_CONG_VIEC && (
                    <>
                      {this.props.mutilSelect &&
                      item.loai === LOAI.HO_SO_CONG_VIEC ? (
                        <span className="text-link">
                          <Checkbox
                            label={item.tenNganTu || item.ten}
                            value={item.id}
                            onChange={this.handleCheckCategories}
                            checked={this.state.checked.indexOf(item.id) !== -1}
                            disabled={
                              this.props.disableIds.indexOf(item.id) !== -1 ||
                              this.checkDisable(item)
                            }
                          />
                        </span>
                      ) : (
                        <span
                          href="/#"
                          className="text-link"
                          onClick={this.handleCategoryClick.bind(this, item)}
                        >
                          <span className="text-content">
                            {item.tenNganTu || item.ten}
                          </span>
                        </span>
                      )}
                    </>
                  )}
                  {this.props.typeName !== HO_SO_CONG_VIEC && (
                    <>
                      {this.props.mutilSelect ? (
                        <span className="text-link">
                          <Checkbox
                            label={item.tenNganTu || item.ten}
                            value={item.id}
                            onChange={this.handleCheckCategories}
                            checked={this.state.checked.indexOf(item.id) !== -1}
                            disabled={
                              this.props.disableIds.indexOf(item.id) !== -1 ||
                              this.checkDisable(item)
                            }
                          />
                        </span>
                      ) : (
                        <span
                          href="/#"
                          className="text-link"
                          onClick={this.handleCategoryClick.bind(this, item)}
                        >
                          <span className="text-content">
                            {item.tenNganTu || item.ten}
                          </span>
                        </span>
                      )}
                    </>
                  )}
                  {/* {Authorization.vaild(
                    this.props.auth.permission,
                    this.props.actionPermissions
                  ) &&
                    type !== PHONG_BAN &&
                    !this.props.mutilSelect &&
                    this.props.phongBanId ===
                      this.props.auth.mainDepartmentId && ( */}
                  <div className="right-action-group">
                    <Popover
                      popoverClassName="pt-popover-content-sizing"
                      position={Position.BOTTOM_RIGHT}
                      interactionKind={PopoverInteractionKind.CLICK}
                      openOnTargetFocus
                      isOpen={this.state.isOpenPopover[item.id]}
                      content={
                        <div className="menu-popover">
                          <span
                            className="item"
                            onClick={this.handleUpdateDrawersPopup.bind(
                              this,
                              'Edit',
                              item
                            )}
                          >
                            <span className="pt-icon pt-icon-annotation"></span>
                            <span className="name_action">Chỉnh sửa</span>
                          </span>
                          <span
                            className="item"
                            onClick={this.handleClickPopup.bind(
                              this,
                              'Delete',
                              item
                            )}
                          >
                            <span className="pt-icon pt-icon-trash"></span>
                            <span className="name_action">Xóa</span>
                          </span>
                          <span
                            className="item"
                            onClick={this.handleUpdateDrawersPopup.bind(
                              this,
                              'Add',
                              item
                            )}
                          >
                            <span className="pt-icon pt-icon-add"></span>
                            <span className="name_action">Thêm mới</span>
                          </span>
                          <span
                            className="item"
                            onClick={this.handleMoveDrawer.bind(this, item)}
                          >
                            <span className="pt-icon pt-icon-move"></span>
                            <span className="name_action">Di chuyển</span>
                          </span>
                        </div>
                      }
                    >
                      <span
                        className="pt-popover-target"
                        onClick={this.handleOpenPopover.bind(this, item.id)}
                      >
                        <span className="icon-action" tabIndex="0">
                          <span className="icon-More hidden-icon-addDocumentsCabinet"></span>
                        </span>
                      </span>
                    </Popover>
                  </div>
                  {/* )} */}
                </div>
                {this.state.isOpen[item.id] &&
                  item.dsNganTuCon &&
                  this.renderSubItem(item.dsNganTuCon)}
              </li>
            )
          })}
      </ul>
    )
  }

  recursiveCategories = data =>
    data.map(item => ({
      ...item,
      children: !_.isEmpty(item.dsHoSoCongViecCon)
        ? this.recursiveCategories(item.dsHoSoCongViecCon)
        : undefined,
    }))

  render() {
    if (this.state.isRedirect) {
      let typeName
      switch (this.props.type) {
        case CA_NHAN:
          typeName = 'ca-nhan'
          break
        case PHONG_BAN:
          typeName = 'phong-ban'
          break
        default:
          typeName = 'don-vi'
      }
      return (
        <Redirect
          push
          to={{
            pathname: `/tu-tai-lieu/di-chuyen-ngan-tu/${typeName}`,
            state: {
              data: this.state.data,
            },
          }}
        />
      )
    }

    const categories =
      this.props.typeName === HO_SO_CONG_VIEC
        ? this.recursiveCategories(this.state.categories)
        : arrayToTree(this.props.categories, {
            parentProperty: 'nganTuChaId',
          })

    return (
      <section className="aside-nav">
        <div className="head-content-aside">
          <h3 className="main-heading heading-14">{this.props.nameTab}</h3>
          {Authorization.vaild(
            this.props.auth.permission,
            this.props.actionPermissions
          ) &&
            // type !== PHONG_BAN &&
            !this.props.mutilSelect &&
            this.props.phongBanId === this.props.auth.mainDepartmentId && (
              <button
                type="button"
                className="pt-button btn-add-small"
                onClick={this.handleUpdateDrawersPopup.bind(this, 'Add', {})}
              >
                <span className="text-content">Thêm {this.props.nameTab}</span>
              </button>
            )}
        </div>
        <div className="aside-main-content">
          <div className="aside-list-content">
            <Scrollbars
              // autoHide
              // autoHideTimeout={100}
              // autoHideDuration={300}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={398}
            >
              <ul className="departments-list departments-action-list">
                {categories.map((item, k) => {
                  if (item.children) {
                    item.dsNganTuCon = item.children
                  }

                  const child =
                    !!size(get(item, 'dsHoSoCongViecCon', [])) &&
                    get(item, 'dsHoSoCongViecCon', [])

                  return (
                    <li className="item-list" key={k}>
                      <div
                        className={classnames('main-content', {
                          active:
                            !this.props.mutilSelect &&
                            this.state.active === item.id,
                        })}
                      >
                        {item.dsNganTuCon && (
                          <span
                            className="dropdown-left"
                            onClick={this.handleClick.bind(this, item.id)}
                          >
                            <span
                              className={classnames(
                                'pt-icon pt-icon-chevron-up d-block',
                                {
                                  flipY: this.state.isOpen[item.id],
                                }
                              )}
                            ></span>
                          </span>
                        )}
                        {this.props.mutilSelect ? (
                          <span className="text-link">
                            {!!child ? (
                              <label className="pt-control">
                                {item.tenNganTu || item.ten}
                              </label>
                            ) : (
                              <Checkbox
                                label={item.tenNganTu || item.ten}
                                value={item.id}
                                onChange={this.handleCheckCategories}
                                checked={
                                  this.state.checked.indexOf(item.id) !== -1
                                }
                                disabled={
                                  this.props.disableIds.indexOf(item.id) !==
                                    -1 || this.checkDisable(item)
                                }
                              />
                            )}
                          </span>
                        ) : (
                          <span
                            href="/#"
                            className="text-link"
                            onClick={this.handleCategoryClick.bind(this, item)}
                          >
                            <span className="text-content">
                              {item.tenNganTu || item.ten}
                            </span>
                          </span>
                        )}
                        {/* {Authorization.vaild(
                          this.props.auth.permission,
                          this.props.actionPermissions
                        ) &&
                          type !== PHONG_BAN &&
                          !this.props.mutilSelect &&
                          this.props.phongBanId ===
                            this.props.auth.mainDepartmentId && ( */}
                        <div className="right-action-group">
                          <Popover
                            popoverClassName="pt-popover-content-sizing"
                            position={Position.BOTTOM_RIGHT}
                            openOnTargetFocus
                            interactionKind={PopoverInteractionKind.CLICK}
                            isOpen={this.state.isOpenPopover[item.id]}
                            content={
                              <div className="menu-popover">
                                <span
                                  className="item"
                                  onClick={this.handleUpdateDrawersPopup.bind(
                                    this,
                                    'Edit',
                                    item
                                  )}
                                >
                                  <span className="pt-icon pt-icon-annotation"></span>
                                  <span className="name_action">Chỉnh sửa</span>
                                </span>
                                <span
                                  className="item"
                                  onClick={this.handleClickPopup.bind(
                                    this,
                                    'Delete',
                                    item
                                  )}
                                >
                                  <span className="pt-icon pt-icon-trash"></span>
                                  <span className="name_action">Xóa</span>
                                </span>
                                <span
                                  className="item"
                                  onClick={this.handleUpdateDrawersPopup.bind(
                                    this,
                                    'Add',
                                    item
                                  )}
                                >
                                  <span className="pt-icon pt-icon-add"></span>
                                  <span className="name_action">Thêm mới</span>
                                </span>
                                <span
                                  className="item"
                                  onClick={this.handleMoveDrawer.bind(
                                    this,
                                    item
                                  )}
                                >
                                  <span className="pt-icon pt-icon-move"></span>
                                  <span className="name_action">Di chuyển</span>
                                </span>
                              </div>
                            }
                          >
                            <span
                              className="pt-popover-target"
                              onClick={this.handleOpenPopover.bind(
                                this,
                                item.id
                              )}
                            >
                              <span className="icon-action" tabIndex="0">
                                <span className="icon-More hidden-icon-addDocumentsCabinet"></span>
                              </span>
                            </span>
                          </Popover>
                        </div>
                        {/* )} */}
                      </div>
                      {this.state.isOpen[item.id] &&
                        item.dsNganTuCon &&
                        this.renderSubItem(item.dsNganTuCon)}
                    </li>
                  )
                })}
              </ul>
            </Scrollbars>
          </div>
          <div className="aside-collaspe-content">
            <PopupConfirm
              isOpen={this.state.isOpenPopup}
              onClose={this.handleClickPopup}
              title="Xóa tủ tài liệu"
              text="Xóa"
              onSubmit={this.handleSubmitRemoveItem}
            >
              Anh/chị có muốn xóa hồ sơ này?
            </PopupConfirm>
            {/* Component commmon add and edit UpdateDrawers*/}
            <UpdateDrawers
              type={this.props.type}
              staffs={this.props.staffs}
              nganTuChaId={
                this.state.item && this.state.item.nganTuChaId
                  ? this.state.item.nganTuChaId
                  : null
              }
              item={this.state.data}
              isOpen={this.state.isOpenPopupAddDrawers}
              onClose={this.handleUpdateDrawersPopup}
              title={this.state.titlePopup}
              buttonAction={this.state.buttonAction}
              onSubmit={this.handleSubmitForm}
              actions={this.props.actions}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default sidebarCabinet
