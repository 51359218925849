import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from 'actions'
import { MasterLayout } from 'components/layout'
import TableCollapse from './TableCollapse'
import { Button, Checkbox, Intent } from '@blueprintjs/core'
import { includes } from 'lodash'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import {
  DA_XAY_RA_LOI,
  TEXT_MESSAGE_DEFAULT,
  TOATS_MESSAGE_FAIL,
  TOATS_MESSAGE_SUCCESS,
  TOATS_MESSAGE_WARNING,
} from 'constants/MessageForm'
import { HeadingPage } from 'components/common'

class AddPhanQuyenChiaSe extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoadingData: false,
      isLoading: false,
      tenApi: '',
      error: {},
      isHoatDong: false,
      listApiId: [],
      ngayHetHan: null,
      shareApplicationId: '',
      isLoadingCategory: false,
    }

    this.handleChecked = this.handleChecked.bind(this)
    this.onCheckList = this.onCheckList.bind(this)
    this.handleCheckListAll = this.handleCheckListAll.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    this.setState(
      {
        isLoadingCategory: true,
      },
      () => {
        this.props.actions.getApiCategory().finally(() => {
          this.setState({ isLoadingCategory: false })
        })
      }
    )
    this.props.actions.shareAPI({ limit: 999 })
    const { id } = this.props.match.params
    if (id) {
      this.props.actions
        .getDetailAuthenToken(id)
        .then(res => {
          if (res.error) {
            this.props.actions.commonAddToasterMessage({
              message: DA_XAY_RA_LOI,
              intent: Intent.DANGER,
            })
          }
        })
        .catch(errors => {
          console.log(errors)
        })
    }
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.props.match.params
    if (nextProps.sharedApplication && !id) {
      this.setState({
        shareApplicationId: nextProps.sharedApplication[0]?.applicationId,
      })
    }
    if (id && nextProps.detailAuthenToken) {
      this.setState({
        tenApi: nextProps.detailAuthenToken.name,
        isHoatDong: nextProps.detailAuthenToken.isActive,
        listApiId:
          nextProps.detailAuthenToken?.permissions?.map(
            item => item.shareApiId
          ) || [],
        ngayHetHan: nextProps.detailAuthenToken.expiredTime
          ? moment(nextProps.detailAuthenToken.expiredTime)
          : null,
        shareApplicationId: nextProps.detailAuthenToken.applicationId,
      })
    }
  }

  // Check hoạt động
  handleChecked(e) {
    this.setState({
      isHoatDong: e.target.checked,
    })
  }

  // Chọn sử dụng
  onCheckList(e, id) {
    if (e.target.checked) {
      this.setState({
        listApiId: [...this.state.listApiId, id],
      })
    } else {
      this.setState({
        listApiId: this.state.listApiId.filter(item => item !== id),
      })
    }
  }

  // Chọn sử dụng tất cả
  handleCheckListAll(e, listId) {
    const result =
      this.state.listApiId.filter(item => !includes(listId, item)) || []
    if (e.target.checked) {
      this.setState({
        listApiId: [...result, ...listId],
      })
    } else {
      this.setState({
        listApiId: result,
      })
    }
  }

  handleChange(e) {
    this.setState({
      tenApi: e.target.value,
    })
  }

  // onChange chọn ngày hết hạn
  changeDate(date) {
    this.setState({
      ngayHetHan: date,
    })
  }

  // onChange select ứng dụng chia sẻ
  handleChangeSelect(e) {
    this.setState({
      shareApplicationId: e.target.value,
    })
  }

  // Lưu
  handleSubmit(e) {
    e.preventDefault()
    this.setState({ error: {} })
    const { id } = this.props.match.params
    const error = {}

    if (!this.state.shareApplicationId) {
      error.shareApplicationId = TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return this.props.actions.commonAddToasterMessage({
        message: TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }
    this.setState({ isLoading: true })
    if (!id) {
      this.props.actions
        .postAuthenToken({
          application_id: this.state.shareApplicationId,
          name: this.state.tenApi,
          expired_time: this.state.ngayHetHan
            ? moment(this.state.ngayHetHan).format('MM/DD/YYYY')
            : undefined,
          is_active: this.state.isHoatDong,
          permissions: this.state.listApiId.map(item => ({
            share_api_id: item,
          })),
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            this.props.history.goBack()
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    } else {
      this.props.actions
        .putAuthenToken(id, {
          application_id: this.state.shareApplicationId,
          name: this.state.tenApi,
          expired_time: this.state.ngayHetHan
            ? moment(this.state.ngayHetHan).format('MM/DD/YYYY')
            : undefined,
          is_active: this.state.isHoatDong,
          permissions: this.state.listApiId.map(item => ({
            share_api_id: item,
          })),
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            this.props.history.goBack()
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }
  }

  render() {
    const { id } = this.props.match.params
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <HeadingPage
          namePage={
            !id ? 'Thêm mới phân quyền chia sẻ' : 'Chỉnh sửa phân quyền chia sẻ'
          }
          iconPage="icon2-share"
        />
        <div className="systerm-main-content">
          <div className="form-container form-inline-container systerm-form-container">
            <form className="main-form systerm-form">
              <div className="form-block p15">
                <div className="form-row justify-center">
                  <div className="form-cols form-col4">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="tenApi"
                      >
                        Tên api
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          value={this.state.tenApi}
                          name="tenApi"
                          placeholder="Nhập tên api"
                          className="pt-input"
                          onChange={this.handleChange}
                          maxLength={250}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col4">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="ungDungChiaSe"
                      >
                        Ứng dụng chia sẻ
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <div className={'pt-select'}>
                          <select
                            name="ungDungChiaSe"
                            onChange={this.handleChangeSelect}
                            value={this?.state?.shareApplicationId}
                            className="pt-input"
                          >
                            {this?.props?.sharedApplication?.map(item => {
                              return (
                                <option
                                  key={item?.applicationId}
                                  value={item?.applicationId}
                                >
                                  {item?.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col4">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="ngayHetHan"
                      >
                        Ngày hết hạn
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <ReactDatePicker
                          name="ngayHetHan"
                          onChange={this.changeDate}
                          className="input-datepicker pointer"
                          selected={this.state.ngayHetHan}
                          readOnly
                          isClearable
                          placeholderText="Chọn ngày hết hạn"
                        />
                        {this.state.error?.tenUngDung !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error?.tenUngDung}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols" style={{ width: '20%' }}>
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="hoatDong"
                        style={{ width: '40%' }}
                      >
                        Hoạt động
                      </label>
                      <div
                        className="input-form-inline"
                        style={{ width: '60%' }}
                      >
                        <Checkbox
                          name="hoatDong"
                          checked={this.state.isHoatDong}
                          className="mt10"
                          onChange={this.handleChecked}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="table-container" style={{ paddingBottom: 0 }}>
          <TableCollapse
            listApiId={this.state.listApiId}
            onCheckList={this.onCheckList}
            handleCheckListAll={this.handleCheckListAll}
            dataSource={this?.props?.listApiCategory || []}
            isLoading={this.state.isLoadingCategory}
          />
          <div className="buttton-action-footer">
            <Button
              type="button"
              className="pt-button pt-button button-default"
              onClick={() => this.props.history.goBack()}
              disabled={this.state.isLoading === true ? true : false}
            >
              <span className="text-content">Hủy</span>
              <span className="pt-icon icon-Huy"></span>
            </Button>
            <Button
              type="button"
              className="pt-button pt-button btn-blue-color"
              loading={this.state.isLoading}
              onClick={this.handleSubmit}
              disabled={
                Object.values(this.state.isLoadingData).find(
                  item => item === true
                ) !== undefined
              }
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  listApiCategory: state.system.listApiCategory,
  sharedApplication: state?.system?.sharedApplications?.items,
  detailAuthenToken: state.system.detailAuthenToken,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPhanQuyenChiaSe)
