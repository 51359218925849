import { Action, Toast } from '../../../constants/MessageForm'
import { Button, Checkbox, Intent } from '@blueprintjs/core'
import React, { Component } from 'react'
import { maxLength, required, unique } from '../../../helpers/validation'

import GoBackButton from '../../../components/Button/GoBackButton'
import { ValidateForm } from '../../../constants/MessageForm'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { commonAddToasterMessage } from '../../../actions'
import { connect } from 'react-redux'
import { fetchProcedureCategory } from '../../../actions/procedure'
import { withRouter } from 'react-router-dom'

class ProcedureCategoryForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        id: null,
        code: '',
        name: '',
        isUsing: true,
        stepDefine: [],
      },
      error: {},
      isWaitingSubmit: false,
      isLoadingData: false,
    }
  }

  handleChange = e => {
    const { name, value, type, checked } = e.target
    this.state.error[name] && delete this.state.error[name]
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: type === 'checkbox' ? checked : value,
      },
    })
  }

  defineChange = (e, defineIndex) => {
    const { value } = e.target
    const { formData, error } = this.state
    const newStepDefine = formData.stepDefine.map((i, index) =>
      index === defineIndex ? value : i
    )
    if (!newStepDefine.filter(i => i === '').length && error.define) {
      delete error.define
    }
    this.setState({
      formData: {
        ...formData,
        stepDefine: newStepDefine,
      },
    })
  }

  addDefine = () => {
    this.setState(prev => ({
      formData: {
        ...prev.formData,
        stepDefine: [...prev.formData.stepDefine, ''],
      },
    }))
  }

  removeDefine = defineIndex => {
    const { formData, error } = this.state
    const newStepDefine = formData.stepDefine.filter(
      (i, index) => index !== defineIndex
    )
    if (!newStepDefine.length && error.define) {
      delete error.define
    }
    this.setState({
      formData: {
        ...formData,
        stepDefine: newStepDefine,
      },
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { toggleAllowGoback } = this.props
    this.setState(
      {
        isWaitingSubmit: true,
        error: {},
      },
      async () => {
        toggleAllowGoback()
        const { id, code, name, isUsing, stepDefine } = this.state.formData
        const { isEdit } = this.props
        let errorMsg = {}
        if (required(code)) errorMsg.code = ValidateForm.IS_REQUIRED
        if (required(name)) errorMsg.name = ValidateForm.IS_REQUIRED
        if (maxLength(code, 50)) errorMsg.code = ValidateForm.MAX_LENGTH(50)
        if (maxLength(name, 255)) errorMsg.name = ValidateForm.MAX_LENGTH(255)
        stepDefine.forEach(item => {
          if (unique(item, stepDefine)) {
            errorMsg.define = ValidateForm.UNIQUE('Định nghĩa bước ký duyệt')
          }
        })
        if (stepDefine.some(i => i === '')) {
          errorMsg.define = 'Nhập vào định nghĩa hoặc xóa bước định nghĩa!'
        }
        if (!!_.size(errorMsg)) {
          this.props.showToast({
            message: Toast.INCOMPLETE,
            intent: Intent.DANGER,
          })
          this.setState({ error: errorMsg })
        } else {
          await this.props
            .dispatch(
              // props action get from parent
              this.props.action({
                id,
                ma: code,
                ten: name,
                is_active: isUsing,
                ds_dinh_nghia_buoc: stepDefine,
              })
            )
            .then(res => {
              const status = !!_.get(res, 'payload.data.result')
              this.props.showToast({
                message: status
                  ? Toast.SUCCESS(isEdit ? Action.UPDATE : Action.CREATE)
                  : Toast.FAIL(isEdit ? Action.UPDATE : Action.CREATE),
                intent: status ? Intent.SUCCESS : Intent.DANGER,
              })
              return status && this.props.history.goBack()
            })
        }
        this.setState({ isWaitingSubmit: false }, () => toggleAllowGoback())
      }
    )
  }

  fetchData = async () => {
    const { fetch, showToast, match, history } = this.props
    const id = _.get(match, 'params.id')

    this.setState({ isLoadingData: true })
    fetch(id)
      .then(res => {
        const status = _.get(res, 'payload.status') && !_.get(res, 'error')
        const data = _.get(res, 'payload.data.result')

        if (!status) {
          showToast({
            message: Toast.FAIL(Action.FETCH),
            intent: Intent.DANGER,
          })
          return history.goBack()
        }
        this.setState({
          formData: {
            id: data.id,
            code: data.ma,
            name: data.ten,
            isUsing: data.isActive,
            stepDefine: data.dsDinhNghiaBuoc || [],
          },
        })
      })
      .finally(() => this.setState({ isLoadingData: false }))
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.fetchData()
    }
  }

  render() {
    const { formData, isLoadingData, isWaitingSubmit, error } = this.state
    return (
      <div className="systerm-main-content">
        <div className="form-container procedure-form">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label pt-label-inline">
                        Mã danh mục
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form pt-form-content">
                        <input
                          type="text"
                          name="code"
                          onChange={this.handleChange}
                          value={formData.code || ''}
                          className={`pt-input ${
                            isLoadingData && `loading-input`
                          }`}
                          placeholder="Nhập mã danh mục"
                          maxLength="50"
                          disabled={isLoadingData}
                          autoFocus
                        />
                        {!!error.code && (
                          <div className="pt-form-content pt-form-helper-text">
                            {error.code}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label className="pt-label pt-label-inline">
                        Tên danh mục
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form pt-form-content">
                        <input
                          type="text"
                          name="name"
                          onChange={this.handleChange}
                          value={formData.name || ''}
                          className={`pt-input ${
                            isLoadingData && `loading-input`
                          }`}
                          placeholder="Nhập tên danh mục"
                          maxLength="255"
                          disabled={isLoadingData}
                        />
                        {!!error.name && (
                          <div className="pt-form-content pt-form-helper-text">
                            {error.name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item form-group-inline">
                      <label className="pt-label pt-label-inline">
                        <Checkbox
                          name="isUsing"
                          onChange={this.handleChange}
                          checked={!!formData.isUsing}
                          disabled={isLoadingData}
                        />
                        Sử dụng
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="m0" />
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-row">
                  <div className="form-cols form-col-full">
                    <div className="form-group-item pt-form-group pt-intent-danger mb0">
                      <label className="pt-label mb5">
                        Danh sách định nghĩa các bước ký duyệt
                      </label>
                      <div className="new-table-style procedure-step-define-table">
                        <div className="row header-row">
                          <div className="cell cell-width-4 center-text">
                            STT
                          </div>
                          <div className="cell cell-width-96">
                            ĐỊNH NGHĨA CÁC BƯỚC KÝ DUYỆT
                          </div>
                        </div>
                        {formData.stepDefine.map((define, index) => (
                          <div className="row data-row" key={index}>
                            <div className="cell cell-width-4 center-text">
                              {index < 9 ? `0${index + 1}` : index + 1}
                            </div>
                            <div className="cell cell-width-92">
                              <input
                                type="text"
                                onChange={e => this.defineChange(e, index)}
                                value={define || ''}
                                className={`pt-input ${
                                  isLoadingData && `loading-input`
                                }`}
                                placeholder="Nhập định nghĩa bước ký duyệt"
                                maxLength="255"
                                disabled={isLoadingData}
                              />
                            </div>
                            <div className="cell cell-width-4 center-text">
                              <span
                                className="pt-icon pt-icon-trash default-color-icon has-event"
                                onClick={() => this.removeDefine(index)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      {!!error.define && (
                        <div className="pt-form-content pt-form-helper-text">
                          {error.define}
                        </div>
                      )}
                    </div>
                    <div className="form-group-item">
                      <button
                        type="button"
                        className="pt-button btn-add-more"
                        onClick={this.addDefine}
                      >
                        <span className="text-content">Thêm</span>
                        <span className="icon-bc icon-Plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <GoBackButton disabled={isWaitingSubmit} />
                <Button
                  type="button"
                  className="pt-button pt-button btn-blue-color"
                  loading={isWaitingSubmit}
                  onClick={this.handleSubmit}
                  disabled={isWaitingSubmit}
                >
                  <span className="text-content">Lưu</span>
                  <i className="pt-icon icon-save" />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
  fetch: bindActionCreators(fetchProcedureCategory, dispatch),
  dispatch,
})

export default connect(
  null,
  mapDispatchToProps
)(withRouter(ProcedureCategoryForm))
