import React from 'react'
import PropTypes from 'prop-types'
import { Position, Tooltip } from '@blueprintjs/core'
import classNames from 'classnames'
import { WORK_TYPE } from 'constants/Enum'
import { Icon } from 'components/newCommon'

const TypeIcons = ({ workType, isDaLuuTuPhongBan, isShowName = false }) => {
  const data = [
    ...(workType === WORK_TYPE.EOFFICE && isDaLuuTuPhongBan
      ? [
          {
            name: 'Công việc đã lưu tủ tài liệu',
            icon: 'icon-Save-DocumentCabinet',
            colorClassName: 'icon-color-primary',
            className: 'color-blue',
          },
        ]
      : []),
    ...(workType === WORK_TYPE.EOFFICE
      ? [
          {
            name: 'Công việc Ký số, Công văn',
            icon: 'icon2-e-office-label',
            colorClassName: 'icon-color-primary',
            className: 'color-blue',
          },
        ]
      : []),
    ...(workType === WORK_TYPE.MEETING
      ? [
          {
            name: 'Công việc Thông báo KLCH',
            icon: 'icon2-clipboard',
            colorClassName: 'icon-color-yellow',
            className: 'color-yellow',
          },
        ]
      : []),
    ...(workType === WORK_TYPE.DOFFICE
      ? [
          {
            name: 'Công việc D-Office',
            icon: 'icon2-d-office',
            colorClassName: 'icon-color-danger',
            className: 'color-danger',
          },
        ]
      : []),
    ...(workType === WORK_TYPE.CHAT
      ? [
          {
            name: 'Công việc CHAT',
            icon: 'icon2-chat-type',
            colorClassName: 'icon-color-primary',
            className: 'color-blue',
          },
        ]
      : []),
    ...(workType === WORK_TYPE.SERVICE_DESK
      ? [
          {
            name: 'Công việc từ Service Desk',
            icon: 'icon2-call-type',
            colorClassName: 'icon-color-primary',
            className: 'color-blue',
          },
        ]
      : []),
  ]

  return (
    <>
      {data?.length > 0 ?? false ? (
        <div className="type-icon-block">
          {data?.map((item, index) => (
            <Tooltip
              key={index}
              content={<span className="font-size-12">{item?.name}</span>}
              position={Position.BOTTOM}
              isDisabled={isShowName}
            >
              <div className="type-icon-box">
                <Icon
                  classIcon={item?.icon}
                  className={classNames('d-block size-icon-18', {
                    [`${item?.colorClassName}`]: item?.colorClassName,
                  })}
                />
                {isShowName ? (
                  <span
                    className={classNames('text-11-600 text-uppercase', {
                      [`${item?.className}`]: item?.className,
                    })}
                  >
                    {item?.name}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </Tooltip>
          ))}
        </div>
      ) : (
        ''
      )}
    </>
  )
}

TypeIcons.propTypes = {
  workType: PropTypes.string,
  isDaLuuTuPhongBan: PropTypes.bool,
}

TypeIcons.defaultProps = {
  workType: '',
  isDaLuuTuPhongBan: false,
}

export default TypeIcons
