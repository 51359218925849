import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Checkbox } from '@blueprintjs/core'
import { get, size } from 'lodash'
import csx from 'classnames'

import { Card, Input, Scrollbar } from 'components/newCommon'
import { checkSearchNoAccents } from 'helpers/string'
import DropdownWrapper from './DropdownWrapper'

const CheckboxSelect = ({
  dataSource,
  onChange = () => {},
  onClose = () => {},
  selectedAllText,
  shadow = false,
  value,
  ...props
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const [showList, setShowList] = useState([])
  const [selected, setSelected] = useState([])
  const [search, setSearch] = useState('')

  const toggleCheck = value => e => {
    const { checked } = e.target
    const newSelected = checked
      ? [...selected, value]
      : selected.filter(select => select !== value)
    mountedSet(setSelected, newSelected)
    onChange(newSelected)
  }

  const _onClose = useCallback(
    open => {
      if (!open) {
        mountedSet(setSearch, '')
        onClose(selected)
      }
    },
    [onClose, selected]
  )

  const changeSearch = (text = '') => {
    mountedSet(setSearch, text)
    mountedSet(
      setShowList,
      dataSource.filter(({ label }) => checkSearchNoAccents(label, text))
    )
  }

  const onClear = () => {
    mountedSet(setSelected, [])
    onChange([])
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    mountedSet(setShowList, dataSource || [])
  }, [dataSource])

  useEffect(() => {
    mountedSet(setSelected, value || [])
  }, [value])

  return (
    <DropdownWrapper
      {...props}
      value={
        !!size(dataSource)
          ? selectedAllText && size(selected) === size(dataSource)
            ? selectedAllText
            : dataSource
                .filter(({ value }) => selected.includes(value))
                .map(({ label }) => label)
                .join(', ')
          : undefined
      }
      className={csx('w-100', { 'cpc-box-shadow': shadow })}
      onDropdownVisibleChange={_onClose}
      onClear={onClear}
    >
      <Card className="popup pl10 pr10 pb5">
        <Card paddingVertical>
          <Input
            className="cpc-input-search"
            leftIconName="search"
            onChange={changeSearch}
            placeholder="Tìm kiếm"
            value={search}
          />
        </Card>
        <Card>
          <Scrollbar maxHeight={200}>
            {showList.map(({ label, value }) => (
              <Card key={value} className="mt5 mb5">
                <Checkbox
                  checked={selected.includes(value)}
                  className={csx('cpc-checkbox', 'font-size-13')}
                  label={label}
                  onChange={toggleCheck(value)}
                />
              </Card>
            ))}
          </Scrollbar>
        </Card>
      </Card>
    </DropdownWrapper>
  )
}

export default memo(CheckboxSelect)
