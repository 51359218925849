import React, { Component } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class ListFilter extends Component {
  static propTypes = {
    status: PropTypes.array,
    objects: PropTypes.array,
    saveCodes: PropTypes.array,
    units: PropTypes.array,
    departments: PropTypes.array,
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  state = {
    isOpen: false,
    filter: {
      textSearch: '',
      ngayCongVan: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      phongBanId: '',
      caNhanId: '',
      status: '',
      doiTuongId: '',
      soLuuId: '',
      isdisabled: true,
    },
  }

  clearFilter(e) {
    const filter = {
      textSearch: '',
      ngayCongVan: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      phongBanId: '',
      caNhanId: '',
      status: '',
      doiTuongId: '',
      soLuuId: '',
      isdisabled: true,
    }

    this.setState(
      {
        filter,
        staffByDepartment: [],
      },
      () => {
        this.handleSubmit()
      }
    )
  }

  componentWillReceiveProps(props) {
    const { filter } = props

    if (filter) {
      const { ngayCongVan } = filter
      this.setState({
        filter: {
          textSearch: filter.textSearch || '',
          ngayCongVan: {
            from:
              ngayCongVan &&
              ngayCongVan.from &&
              ngayCongVan.from.trim().length > 0
                ? moment(ngayCongVan.from)
                : '',
            to:
              ngayCongVan && ngayCongVan.to && ngayCongVan.to.trim().length > 0
                ? moment(ngayCongVan.to)
                : '',
          },
          phongBanId: filter.phongBanId || '',
          caNhanId: filter.caNhanId || '',
          status: filter.status || '',
          doiTuongId: filter.doiTuongId || '',
          soLuuId: filter.soLuuId || '',
        },
      })
    }
  }

  handleDateChange(name, pos, e, value) {
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
    })
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })

    if (name === 'phongBanId' && e.target.value !== '') {
      this.props.actions
        .commonFetchStaffByDepartmentId(e.target.value)
        .then(res => {
          if (res.payload.data) {
            this.setState({
              filter: {
                ...this.state.filter,
                [name]: value,
                isdisabled: false,
              },
              staffByDepartment: res.payload.data.result.items,
            })
          }
        })
    }

    if (name === 'phongBanId' && e.target.value === '') {
      this.setState({
        filter: {
          ...this.state.filter,
          [name]: value,
          isdisabled: true,
        },
      })
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayCongVan: {
        from: this.state.filter.ngayCongVan.from
          ? moment(this.state.filter.ngayCongVan.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayCongVan.to
          ? moment(this.state.filter.ngayCongVan.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <form
        className="search-container search-DocumentarySearch pt15 pb30 px-sm-15"
        onSubmit={this.handleSubmit}
      >
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <div>
              <label className="search-label">Thời gian (Ngày công văn)</label>
              <div className="mt5 form-input-filter date-picker-inline border-radius-3 ">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCongVan.from}
                  selectsStart
                  startDate={this.state.filter.ngayCongVan.from}
                  endDate={this.state.filter.ngayCongVan.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'from'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Từ ngày"
                  maxDate={this.state.filter.ngayCongVan.to}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayCongVan.to}
                  selectsEnd
                  startDate={this.state.filter.ngayCongVan.from}
                  endDate={this.state.filter.ngayCongVan.to}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'to'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Đến ngày"
                  minDate={this.state.filter.ngayCongVan.from}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-575-12 col-xs-6 col-md-2 mt10 mt-md-0">
            <div>
              <label className="search-label">Đối tượng</label>
              <div className="mt5 form-input-filter select-arrow border-radius-3 ">
                <select
                  className="input border-transparent appearance-none"
                  name="doiTuongId"
                  value={this.state.filter.doiTuongId}
                  onChange={this.handleInputChange}
                >
                  <option value="">Tất cả</option>
                  {this.props.objects.map((item, i) => (
                    <option key={i} value={item.doiTuongCongVanId}>
                      {item.tenDoiTuongCongVan}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-575-12 col-xs-6 col-md-2 mt10 mt-md-0">
            <div>
              <label className="search-label">Phòng ban</label>
              <div className="mt5 form-input-filter select-arrow border-radius-3 ">
                <select
                  className="input border-transparent appearance-none"
                  name="phongBanId"
                  value={this.state.filter.phongBanId}
                  onChange={this.handleInputChange}
                >
                  <option value="">Tất cả</option>
                  {this.props.departments.map((item, i) => (
                    <option key={i} value={item.phongBanId}>
                      {item.maPhongBan}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-5 mt20 mt-md-0 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 ">
              <input
                autoFocus={false}
                autoComplete="off"
                className="input border-transparent pr-40"
                placeholder="Tìm kiếm Số CV, Trích yếu, Số hóa (hoặc Số CV:Trích yếu)..."
                type="text"
                dir="auto"
                name="textSearch"
                onChange={this.handleInputChange}
                value={this.state.filter.textSearch}
              />
              <Button
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
                type="submit"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              className="btn-icon flex-solid ml10 mr10 pt-intent-btn-white"
              type="button"
              onClick={this.clearFilter}
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
            <Button
              type="button"
              onClick={this.handleClick.bind(this)}
              className="btn-icon flex-solid ml0 pt-intent-btn-white"
            >
              <span
                className={classnames('pt-icon mr0', {
                  'pt-icon-double-chevron-up': this.state.isOpen,
                  'pt-icon-double-chevron-down': !this.state.isOpen,
                })}
              ></span>
            </Button>
          </div>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <div className="row mt15 ml0 mr0">
            <div className="col-575-12 col-xs-6 col-sm-4 pr-sm-0 pl-sm-0 mt10 mt-576-0">
              <div>
                <label className="search-label">Cá nhân</label>
                <div className="mt5 form-input-filter select-arrow border-radius-lelf-3">
                  <select
                    className="input border-transparent appearance-none"
                    value={this.state.filter.caNhanId}
                    name="caNhanId"
                    disabled={this.state.filter.isdisabled}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Tất cả</option>
                    {this.state.staffByDepartment &&
                      this.state.staffByDepartment.map((item, i) => (
                        <option key={i} value={item.chucDanhId}>
                          {item.tenNhanVien}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-575-12 col-xs-6 col-sm-4 pr-sm-0 pl-sm-0 mt10 mt-576-0">
              <div>
                <label className="search-label">Tình trạng</label>
                <div className="mt5 form-input-filter select-arrow border-left-sm-transparent">
                  <select
                    className="input border-transparent appearance-none"
                    name="status"
                    value={this.state.filter.status}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Tất cả</option>
                    {this.props.status.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-575-12 col-xs-6 col-sm-4 pr-sm-0 pl-sm-0 mt10 mt-sm-0">
              <div>
                <label className="search-label">Số lưu công văn</label>
                <div className="mt5 form-input-filter select-arrow border-left-sm-transparent border-radius-right-3">
                  <select
                    className="input border-transparent appearance-none"
                    value={this.state.filter.soLuuId}
                    name="soLuuId"
                    onChange={this.handleInputChange}
                  >
                    <option value="">Tất cả</option>
                    {this.props.saveCodes.map((item, i) => (
                      <option key={i} value={item.soLuuId}>
                        {item.tenSoLuu}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </form>
    )
  }
}

export default ListFilter
