import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Radio, RadioGroup } from "@blueprintjs/core";
// import { PopupConfirm } from '../common/';

class EditDocumentsCabinet extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      form: {
        id: props.item.id,
        stt: props.item.stt,
        ghiChu: props.item.ghiChu,
        tenTaiLieu: props.item.tenTaiLieu || '',
      },
      valueRadio: 'one',
      isOpen: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    })
  }

  handleSubmit() {
    this.props.onSubmit && this.props.onSubmit(this.state.form)
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Sửa tài liệu</h4>
        </div>
        <div className="form-container form-EditDocumentsCabinet">
          <div className="pt-form-group">
            <div className="row item-form">
              <label className="col-md-3 col-xs-12 pt-label">Số thứ tự</label>
              <div className="pt-form-content col-md-6 col-xs-12">
                <div className="pt-form-content">
                  <input
                    className="pt-input"
                    name="stt"
                    maxLength={5}
                    placeholder="Nhập số"
                    type="text"
                    dir="auto"
                    value={this.state.form.stt}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group">
            <div className="row item-form">
              <label className="col-md-3 col-xs-12 pt-label">Ghi chú</label>
              <div className="pt-form-content col-md-9 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="2"
                    className="pt-input pt-fill"
                    name="ghiChu"
                    dir="auto"
                    value={this.state.form.ghiChu}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group">
            <div className="row item-form">
              <label className="col-md-3 col-xs-12 pt-label">Trích yếu</label>
              <div className="pt-form-content col-md-9 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="3"
                    className="pt-input pt-fill text-abridgment"
                    name="tenTaiLieu"
                    dir="auto"
                    value={this.state.form.tenTaiLieu}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*
          <div className="item-info-file pt-form-group">
            <span className="label-item">
              File nội dung
            </span>
            <span
              className="info"><img className="icon-pdf"
              src={process.env.PUBLIC_URL + '/images/pdf_file.svg'}
              alt=""
            />
              Chuong_Trinh_Lam_Viec_Cua_EVNCPC.pdf  -  15 MB
            </span>
          </div>
          <div className="pt-form-group">
            <div className="row item-form">
              <label className="col-md-3 col-xs-12 pt-label">
                Lưu vào
              </label>
              <div className="pt-form-content col-md-9 col-xs-12">
                <RadioGroup
                    className="radio-group"
                    onChange={this.handleRadio}
                    selectedValue={this.state.valueRadio}
                >
                  <Radio
                    className="pt-large item-radio"
                    label="TTL ĐƠN VỊ"
                    value="one"
                  />
                  <Radio
                    className="pt-large item-radio"
                    label="TTL PHÒNG BAN"
                    value="two"
                  />
                  <Radio
                    className="pt-large item-radio"
                    label="TTL CÁ NHÂN"
                    value="three"
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
          */}
        </div>
        <div className="buttton-action-footer">
          <button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </button>
          <button onClick={this.handleSubmit} className="pt-button btn-save">
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </button>
        </div>
        {/*<PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title="Hủy sửa tài liệu"
          text="Hủy"
          onSubmit={this.props.onClickGoBack}
        >
          Anh/chị có muốn hủy tài liệu này?
        </PopupConfirm>*/}
      </div>
    )
  }
}

export default EditDocumentsCabinet
