import React from 'react'
import { MasterLayout } from '../../../components/layout'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { headers } from '../../../constants/Table'
import { PAGINATION_LIMIT } from '../../../constants/Api'
import { get, keys, isEmpty } from 'lodash'

import {
  fetchAllProcedureCategory,
  fetchAllProcedure,
  timKiemVbks,
} from '../../../actions/procedure'
import { getCategoryUnitMultiLevel, getDepartment } from '../../../actions'

import SearchDispatchListFilter from './SearchDispatchListFilter'
import SearchDispatchListItem from './SearchDispatchListItem'
import SearchDispatchGridViewFilter from './SearchDispatchGridViewFilter'

import Pagination from 'components/Pagination'

class SearchDispatch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      resetFilter: false,
      tmpFilter: {},
      loading: {
        tableData: false,
      },
      error: {
        tableData: false,
      },
      data: {
        table: [],
        page: {
          current: 1,
          total: 1,
        },
        dropdown: {
          unit: [],
          department: [],
          procedureCategory: [],
          procedure: [],
        },
      },
    }
  }

  textFilterChangeCallback = (filter, filterType) => {
    if (filterType === 'filter') {
      this.setFilter(filter)
    } else if (filterType === 'column') {
      this.setFilter({ filterColumn: filter })
    }
  }

  goToDetails = id => {
    this.props.history.push(`/ky-so-tap-trung/tim-kiem-van-ban/chi-tiet/${id}`)
  }

  pageChange = async page => {
    await this.setState(prev => ({ tmpFilter: { ...prev.tmpFilter, page } }))
    this.fetchTableData()
  }

  setFilter = filter => {
    this.setState(prev => ({ tmpFilter: { ...prev.tmpFilter, ...filter } }))
  }

  preFilter = (filter = {}) => {
    const { text = '', select = {}, date = {} } = filter
    return {
      textSearch: !!text.trim() ? text : undefined,
      ngayLap: {
        from: date.from ? date.from.format('YYYY-MM-DD') : undefined,
        to: date.to ? date.to.format('YYYY-MM-DD') : undefined,
      },
      donVi: select.unit !== '-1' ? select.unit : undefined,
      phongBan: select.department !== '-1' ? select.department : undefined,
      danhMuc:
        select.procedureCategory !== '-1'
          ? select.procedureCategory
          : undefined,
      quyTrinh: select.procedure !== '-1' ? select.procedure : undefined,
    }
  }

  preFilterColumn = (filter = {}) => {
    const { text = {}, date = {} } = filter
    return {
      ...text,
      ngayLap: date.ngayLap ? date.ngayLap.format('YYYY-MM-DD') : undefined,
    }
  }

  preFilterSort = (filter = {}) => {
    return isEmpty(filter)
      ? undefined
      : `${keys(filter)[0]} ${filter[keys(filter)[0]]}`
  }

  fetchTableData = async (newFilter, type) => {
    if (type !== 'reset') {
      if (newFilter) {
        await this.setFilter(
          type !== 'column' ? newFilter : { filterColumn: newFilter }
        )
      }
    } else {
      await this.setState({ tmpFilter: {} })
    }
    const {
      tmpFilter: { page = 1, filterColumn = {}, ...filter },
    } = this.state

    const { fetchTableData } = this.props
    await this.setState(prev => ({
      loading: {
        ...prev.loading,
        tableData: true,
      },
    }))

    fetchTableData({
      filter: this.preFilter(filter),
      sort: this.preFilterSort(filterColumn.sort),
      filterColumn: this.preFilterColumn(filterColumn),
      page,
    })
      .then(res => {
        const fetchSuccess = get(res, 'payload.status') === 200 || !res.error
        if (fetchSuccess) {
          const result = get(res, 'payload.data.result')
          const table = result.items
          const page = {
            current: result.page,
            total: Math.ceil(result.total / PAGINATION_LIMIT),
          }
          this.setState(prev => ({
            data: {
              ...prev.data,
              table,
              page,
            },
          }))
        } else {
          this.setState({
            error: {
              tableData: true,
            },
          })
        }
      })
      .catch(() =>
        this.setState({
          error: {
            tableData: true,
          },
        })
      )
      .finally(() =>
        this.setState(prev => ({
          loading: {
            ...prev.loading,
            tableData: false,
          },
        }))
      )
  }

  setDropdownData = (name, data = []) => {
    this.setState(prev => ({
      data: {
        ...prev.data,
        dropdown: {
          ...prev.data.dropdown,
          [name]: data,
        },
      },
    }))
  }

  flatUnit = arrayUnit => {
    let tmpList = []
    arrayUnit.forEach(i => {
      tmpList = [...tmpList, i]
      if (i.dsDonViCon) {
        tmpList = [...tmpList, ...this.flatUnit(i.dsDonViCon)]
      }
    })
    return tmpList
  }

  setUnitDropdownData = () => {
    const unitId = get(this.props, 'auth.mainUnitId')
    this.props.fetchUnit(unitId).then(res => {
      let list = get(res, 'payload.data.result', [])
      list = this.flatUnit(list)
      this.setDropdownData('unit', list)
    })
  }

  setProcedureCategoryDropdownData = () => {
    this.props.fetchProcedureCategory({ activeFilter: true }).then(res => {
      const data = get(res, 'payload.data.result.items')
      this.setDropdownData('procedureCategory', data)
    })
  }

  dropSelectCallback = (name, id) => {
    if (name === 'unit') {
      if (id === '-1') {
        this.setDropdownData('department', [])
      } else {
        this.props.fetchDepartment(id).then(res => {
          const data = get(res, 'payload.data.result')
          this.setDropdownData('department', data)
        })
      }
    }
    if (name === 'procedureCategory') {
      if (id === '-1') {
        this.setDropdownData('procedure', [])
      } else {
        this.props
          .fetchProcedure({ activeFilter: true, selectFilter: id })
          .then(res => {
            const data = get(res, 'payload.data.result.items')
            this.setDropdownData('procedure', data)
          })
      }
    }
  }

  resetFilter = async () => {
    await this.setState({ resetFilter: true })
    this.fetchTableData()
    this.setState({ resetFilter: false })
  }

  async componentDidMount() {
    this.fetchTableData()
    this.setUnitDropdownData()
    this.setProcedureCategoryDropdownData()
  }

  render() {
    const {
      data: { table, page, dropdown },
      loading,
      error,
      resetFilter,
    } = this.state

    return (
      <MasterLayout typeSidebar="signNumber">
        <div>
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-danh-sach-tap-trung icon-heading" />
                  <span className="text-heading">Danh sách ký duyệt</span>
                </h3>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container list-sign-number signature-document-custom-list">
            <SearchDispatchListFilter
              submitFilter={this.fetchTableData}
              resetFilter={this.resetFilter}
              dropdownData={dropdown}
              dropSelectCallback={this.dropSelectCallback}
              textFilterChange={filter =>
                this.textFilterChangeCallback(filter, 'filter')
              }
            />

            <div
              className="table-container pb0"
              style={{
                border: 0,
                background: page.total > 1 ? '#fff' : '#eff3f5',
              }}
            >
              <SearchDispatchGridViewFilter
                data={table}
                resetFilter={resetFilter}
                classNames={'pt-table'}
                currentPage={page.current}
                header={headers.timKiemVanBanKySo}
                itemShape={SearchDispatchListItem}
                goToDetails={this.goToDetails}
                submitFilter={filter => this.fetchTableData(filter, 'column')}
                textFilterChange={filter =>
                  this.textFilterChangeCallback(filter, 'column')
                }
                loading={loading.tableData}
                error={error.tableData}
              />
              {!loading.tableData &&
                !error.tableData &&
                !!table.length &&
                page.total > 1 && (
                  <Pagination
                    current={page.current}
                    total={page.total}
                    onChange={this.pageChange}
                  />
                )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  fetchTableData: bindActionCreators(timKiemVbks, dispatch),
  fetchUnit: bindActionCreators(getCategoryUnitMultiLevel, dispatch),
  fetchProcedureCategory: bindActionCreators(
    fetchAllProcedureCategory,
    dispatch
  ),
  fetchDepartment: bindActionCreators(getDepartment, dispatch),
  fetchProcedure: bindActionCreators(fetchAllProcedure, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchDispatch)
