import * as types from '../constants/ActionTypes'

const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  detailId: '',
}

export default function listInternalDocuments(state = initialState, action) {
  switch (action.type) {
    case types.DOCUMENT_INTERNAL_GET:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.GET_DOCUMENT_INTERNAL_ITEM:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }
    default:
      return state
  }
}
