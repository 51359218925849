import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItem } from '../../components/System/service'
import { GridView } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import { Intent } from '@blueprintjs/core'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import moment from 'moment'

import Pagination from 'components/Pagination'

const REFRESH_FALSE = false
class ListService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {},
      filterColumn: {},
      sort: undefined,
      isLoading: false,
      isEmpty: false,
      page: 1,
      isError: false,
    }

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  static propTypes = {
    list: PropTypes.object.isRequired,
  }

  componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData('ListService')
    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
    } else {
      state.filter.ngayTao = {
        from: undefined,
        to: moment().format('YYYY-MM-DD'),
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props &&
      this.props.list &&
      this.props.list.total &&
      this.state.page >
        Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT)
    ) {
      this.setState(
        { page: Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT) },
        () => {
          this.clickPagination(this.state.page)
        }
      )
    }
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)

    this.setState({ filter, page, isLoading: true, sort }, () => {
      Tool.Utils.preData('ListService', { filter, page, sort })
      // tạm thời để refresh = false
      this.props.actions
        .serviceDocumentGetAPI({ filter }, page, sort, REFRESH_FALSE)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }
          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }

          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
          this.props.actions.refreshServiceDocument(false)
        })
    })
  }

  async handleSubmitRemoveItem(id) {
    let total = 0
    let page = this.state.page
    if (this.props && this.props.list && this.props.list.total) {
      total = this.props.list.total
    }
    await this.props.actions
      .serviceDeleteAPI(id)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.XOA_SERVICE_KHONGTHANHCONG,
            intent: Intent.DANGER,
          })
        }
        if (total > 0) {
          total -= 1
        }
        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }
        if (page === 0) {
          page = 1
        }
        // Refresh danh sach service
        this.props.actions.refreshServiceDocument(true)
        this.submitFilter(this.state.filter, page, this.state.sort)
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.XOA_SERVICE_THANHCONG,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.XOA_SERVICE_KHONGTHANHCONG,
          intent: Intent.DANGER,
        })
      })
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({
        filterColumn: {},
      })
    }
    await this.submitFilter(filter)
  }

  removeTextSearch(name) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: '',
        },
      },
      () => {
        this.submitFilter(this.state.filter)
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  render() {
    const { isError, isEmpty, isLoading, page } = this.state

    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-s-list icon-heading"></span>
                  <span className="text-heading">Danh sách Service</span>
                </h3>
              </div>
            </div>
          </div>
          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <ListFilter
              filter={this.state.filter}
              onSubmit={this.submitFilter}
              actions={this.props.actions}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
              handleDateChange={this.handleDateChange}
            />
            <div
              className="table-container table-container-full"
              style={{
                paddingBottom: 0,
                background:
                  Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridView
                isLoading={this.state.isLoading}
                classNames={'pt-table table-middle'}
                page={this.state.page}
                headers={headers.ListService}
                itemShape={ListItem}
                data={this.props.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                isError={this.state.isError}
                filterColumn={this.state.filterColumn}
                auth={this.props.auth}
                onSubmit={this.submitFilter}
                pageCount={Math.ceil(
                  this.props.list.total / Types.PAGINATION_LIMIT
                )}
                isType={false}
                history={this.props.history}
              />

              {!isLoading && !isEmpty && !isError && (
                <Pagination
                  current={page}
                  total={Math.ceil(
                    this.props.list.total / Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,
  list: state.serviceDocuments.list,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListService)
