import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import { Checkbox, Classes, Button, Intent, Tooltip } from '@blueprintjs/core'
import { get } from 'lodash'
import csx from 'classnames'
import { withCookies } from 'react-cookie'

import { toDecamelizeKeys } from 'helpers/key'
import requestAction from 'helpers/request'
import { MasterLayout } from 'components/layout'
import { Icon } from 'components/newCommon'
import { Table, Pagination } from 'components/newCommon2'
import { Action, Toast } from 'constants/MessageForm'
import { getDsTag, putEditTag } from 'actions/task'
import TagsForm from './Form/Tags'
import DeleteTagConfirm from './Confirm/DeleteTag'

const PAGE_SIZE = 10
const PAGE_NAME = 'Quản lý loại công việc'

const pageActions = {
  taoMoi: 'them-moi',
  chinhSua: 'chinh-sua',
  xoa: 'xoa',
}

// PAGE
const Tags = ({ history, match, allCookies = {} }) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const { action: currentAction, id: currentId } = match.params
  const mainPathname = useMemo(() => '/quan-ly-cong-viec/tags', [])

  const DEFAULT_FILTER = useMemo(
    () => ({
      page: 1,
    }),
    []
  )

  const [fetching, setFetching] = useState(true)
  const [fetchingFail, setFetchingFail] = useState(false)

  const [tags, setTags] = useState([])
  const [checking, setChecking] = useState(false)

  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [totalPage, setTotalPage] = useState(1)

  // Thao tác công việc
  const goToAction = useCallback(
    (id, action) => () => {
      const url = `${mainPathname}/${action}/${id}`
      if (!currentAction) return history.push(url)
      if (action !== currentAction || id !== currentId)
        return history.replace(url)
    },
    [history, currentAction, mainPathname, currentId]
  )

  const changeCheckBox = record => e => {
    const { checked } = e.target
    const newRecord = { ...record, isActive: checked }

    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => mountedSet(setChecking, true),
      action: () => putEditTag(toDecamelizeKeys(newRecord)),
      afterResponse: () =>
        mountedSet(setTags, (oldTags = []) =>
          oldTags.map(tag => (tag.id === newRecord.id ? newRecord : tag))
        ),
      afterAction: () => mountedSet(setChecking, false),
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }

  const _fetchList = useCallback(requestFilter => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getDsTag(requestFilter),
      afterResponse: ({ items = [], totalCount = 0 }) => {
        mountedSet(setTags, items)
        mountedSet(setTotalPage, totalCount)
      },
      afterAction: () => mountedSet(setFetching, false),
      afterError: () => mountedSet(setFetchingFail, true),
    })
  }, [])

  const changeFilter =
    (name, fetch = true) =>
    value => {
      const newFilter = { ...filter, [name]: value }

      mountedSet(setFilter, newFilter)
      if (fetch) {
        _fetchList(newFilter)
      }
    }

  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Stt',
      render: (v, r, index) => {
        const num = (get(filter, 'page', 1) - 1) * 10 + index + 1
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'name',
      width: 50,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Loại công việc',
      render: ({ name, backgroundColor = '#f1f1f1' }) => (
        <>
          <span
            style={{
              display: 'inline-block',
              width: 24,
              height: 24,
              background: backgroundColor,
              marginRight: 10,
            }}
          />
          {name}
        </>
      ),
    },
    {
      key: 'suDung',
      width: 10,
      headClass: 'uppercase',
      className: 'element-center align-center',
      title: 'Sử dụng',
      dataIndex: 'isActive',
      render: (value, record) => (
        <Checkbox
          checked={!!value}
          className={csx(Classes.MINIMAL, 'mb0 inline-block')}
          disabled={
            checking ||
            currentAction === pageActions.taoMoi ||
            currentAction === pageActions.chinhSua
          }
          onChange={changeCheckBox(record)}
        />
      ),
    },
    {
      key: 'thaoTac',
      className: 'element-center align-center',
      width: 5,
      dataIndex: 'id',
      render: value => (
        <>
          <Tooltip
            content={
              <span className="font-size-12">Chỉnh sửa loại công việc</span>
            }
          >
            <Icon
              classIcon="icon-but"
              className={csx(
                'size-icon-12',
                'mr10',
                'icon-color-primary',
                'has-event'
              )}
              onClick={goToAction(value, 'chinh-sua')}
            />
          </Tooltip>
          <Tooltip
            content={<span className="font-size-12">Xóa loại công việc</span>}
          >
            <Icon
              classIcon="icon-bin"
              className={csx('size-icon-12', 'icon-color-danger', 'has-event')}
              onClick={goToAction(value, 'xoa')}
            />
          </Tooltip>
        </>
      ),
    },
  ]

  const openCreate = useCallback(() => {
    const url = `/quan-ly-cong-viec/tags/${pageActions.taoMoi}`
    if (!currentAction) return history.push(url)
    if (currentAction) return history.replace(url)
  }, [currentAction, history])

  const actionBlock = useMemo(() => {
    const actionOverlays = {
      [pageActions.taoMoi]: TagsForm,
      [pageActions.chinhSua]: TagsForm,
      [pageActions.xoa]: DeleteTagConfirm,
    }
    const ActionOverlay = actionOverlays[currentAction]
    if (!ActionOverlay) return <></>
    return (
      <ActionOverlay
        onClose={history.goBack}
        reloadList={() => _fetchList(filter)}
      />
    )
  }, [currentAction, history, _fetchList, filter])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchList(filter)
  }, [_fetchList, filter])

  return (
    <MasterLayout typeSidebar="taskManager">
      <div style={{ flex: 1, position: 'relative' }}>
        <div className="task-manager-title">
          <h1 className="name">{PAGE_NAME}</h1>
          <Tooltip
            content={<span className="font-size-12">Tạo loại công việc</span>}
          >
            <Button
              className={csx('cpc-button theme-md')}
              intent={Intent.PRIMARY}
              onClick={openCreate}
            >
              <span>Tạo loại công việc</span>
              <Icon classIcon="icon-Plus" />
            </Button>
          </Tooltip>
        </div>

        <div className="task-manager-block">
          <Table
            className="cpc-table-giaodienmoi"
            autoHide={false}
            columns={columns}
            data={tags}
            loading={fetching}
            error={fetchingFail}
            tableMinWidth={400}
          />
          {totalPage > PAGE_SIZE && (
            <div className="text-center bg-white ph15 pv20 border-top">
              <Pagination
                total={totalPage}
                current={get(filter, 'page', 1)}
                pageSize={PAGE_SIZE}
                onChange={changeFilter('page')}
              />
            </div>
          )}
        </div>
      </div>

      {actionBlock}
    </MasterLayout>
  )
}

export default withCookies(memo(Tags))
