import React, { Component } from 'react'
import {
  Button,
  // Collapse
} from '@blueprintjs/core'
// import classnames from 'classnames';
// import PropTypes from 'prop-types';
// import moment from 'moment';
// import DatePicker from 'react-datepicker';

class ListFilterNotAssigned extends Component {
  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
  }

  state = {
    filter: {
      textSearch: '',
    },
  }

  clearFilter(e) {
    const filter = {
      textSearch: '',
    }
    this.setState(
      {
        filter,
      },
      () => {
        this.props.handleResetData(filter)
        this.props.actions.getNumberWorkPage()
      }
    )
  }

  componentWillReceiveProps(props) {
    const { filter } = props

    if (filter) {
      this.setState({
        filter: {
          textSearch: filter.textSearch || '',
        },
      })
    }
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    this.props.clearFilterColumn &&
      this.props.clearFilterColumn(this.state.filter)
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
    this.props.removeTextSearch && this.props.removeTextSearch('textSearch')
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch pt30 pb30 px-sm-15"
      >
        <div className="row">
          <div className="col-xs-12 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                className="input border-transparent pr-40 input-search-filter"
                name="textSearch"
                type="text"
                value={this.state.filter.textSearch}
                onChange={this.handleInputChange}
                placeholder="Tìm kiếm Sô CV, Trích yếu, Số hóa (hoặc Số CV Trích yếu)"
                maxLength={255}
              />
              {this.state.filter.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'textSearch')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

export default ListFilterNotAssigned
