import React, { memo, useContext } from 'react'
import { Icon, Radio } from '@blueprintjs/core'
import { isArray, get, some } from 'lodash'
import csx from 'classnames'

import { Card, Collapse, Avatar } from 'components/newCommon'
import { SelectContext } from '../UserTree'

const RadioItem = ({
  level = 0,
  avatar = null,
  showAvatar = false,
  label = '',
  departmentShortname = null,
  value: itemValue,
  children,
}) => {
  const {
    selected = [],
    onChange = () => {},
    showKeys = [],
    toggleShow = () => {},
  } = useContext(SelectContext)
  const isShow = showKeys.includes(itemValue)

  // change Radio
  const changeRadio = () => {
    onChange([itemValue], itemValue)
  }

  const content = (
    <>
      {showAvatar && <Avatar src={avatar} className="mr10" needBaseUrl />}
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
        }}
      >
        <span className={csx('font-size-13', 'text-color-333')}>{label}</span>
        {departmentShortname && (
          <span
            className={csx(
              'font-size-11',
              'text-color-333',
              'text-uppercase',
              'font-weight-600'
            )}
            style={{
              marginTop: 3,
            }}
          >
            {departmentShortname}
          </span>
        )}
      </div>
      {children && (
        <span className="action show">
          <Icon
            iconName={isShow ? 'chevron-up' : 'chevron-down'}
            className="m0"
            onClick={toggleShow(itemValue)}
          />
        </span>
      )}
    </>
  )

  return (
    <>
      <Card className="cpc-list-item" paddingHorizontal>
        {get(itemValue, 'type') === 'LABEL' ? (
          <div
            className={csx(
              `ml${level * 20}`,
              'pt10 pb10',
              'font-size-13',
              'd-flex'
            )}
          >
            {content}
          </div>
        ) : (
          <Radio
            className={csx('cpc-radio', `ml${level * 20}`, 'pt10 pb10')}
            onChange={changeRadio}
            checked={some(selected, itemValue)}
          >
            {content}
          </Radio>
        )}
      </Card>
      <Collapse isOpen={isShow} keepChildrenMounted>
        {isArray(children) &&
          children.map(item => (
            <RadioItem
              {...item}
              key={get(item, 'value.id')}
              level={level + 1}
            />
          ))}
      </Collapse>
    </>
  )
}

export default memo(RadioItem)
