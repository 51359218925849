import React from 'react'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import size from 'lodash/size'

import { Scrollbar } from 'components/newCommon'

const ViewMoreDots = ({
  isTotal = false,
  totalShow = 3,
  data = [],
  position = Position.TOP_LEFT,
  interactionKind = PopoverInteractionKind.HOVER,
}) => {
  const showData = data.slice(0, totalShow)
  const hideData = data.slice(totalShow)
  return (
    <div className="view-more-dots">
      <Popover
        position={position}
        interactionKind={interactionKind}
        content={
          <Scrollbar maxHeight={200}>
            <div className="view-more-dots__content p10">
              <ul>
                {hideData?.map((elm, index) => (
                  <li key={index} className="font-size-12">
                    {elm}
                  </li>
                ))}
              </ul>
            </div>
          </Scrollbar>
        }
        isDisabled={size(hideData) === 0}
      >
        <div className="view-more-dots__action">
          <span>{showData?.join(', ')}</span>
          {size(hideData) !== 0 && isTotal && (
            <span className="view-more-dots__total ml5">
              + {size(hideData)}
            </span>
          )}
          {size(hideData) !== 0 && !isTotal && (
            <span className="view-more-dots__icon ml5">...</span>
          )}
        </div>
      </Popover>
    </div>
  )
}

export default ViewMoreDots
