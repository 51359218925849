import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage, SelectTree } from '../../components/common'
import { DepartmentCategory } from '../../components/System'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { Utils } from '../../helpers'

const LINK = '/he-thong/phong-ban/them-moi'
const LINK_REDIRECT = '/he-thong/phong-ban/danh-muc'
class DepartmentCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitRemove = this.handleSubmitRemove.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveHeader = this.handleSaveHeader.bind(this)
  }

  state = {
    Departments: [],
    header: [{ id: '', tenVietTat: 'Danh mục' }],
    donViId: this.props.auth.mainUnitId,
    isRedirect: false,
    unitSelect: [],
    isLoading: true,
    isError: false,
  }

  componentDidMount() {
    let donViId = Utils.getData('DepartmentCategoryPage', {})
    let header = Utils.getData('DepartmentCategoryPage-Header', [])
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined

    if (donViId === undefined) {
      donViId = this.props.auth.mainUnitId
    }

    if (header === undefined) {
      header = this.state.header
    }

    this.setState({ donViId, header }, () => {
      if (permission) {
        this.props.actions.getCategoryUnitMultiLevel().then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
        })
      } else {
        this.props.actions
          .getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
          .then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true, isLoading: false })
            }
          })
      }

      if (this.props.match.params.id === undefined) {
        return this.props.actions
          .commonFetchDepartments(1, donViId, true)
          .then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true, isLoading: false })
            }
          })
      }
    })
    if (this.props.match.params.id !== undefined) {
      let id = header[header.length - 1].id

      if (!id) {
        id = this.props.match.params.id
      }
      this.props.actions.getCategoryDepartmentChild(id, true).then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          this.setState({ isError: true, isLoading: false })
        }
        let header = Utils.getData('DepartmentCategoryPage-Header', [])
        if (res.payload && res.payload.data.result.length === 0) {
          header.splice(header.length - 1)
          this.setState({ header })
          Utils.saveData('DepartmentCategoryPage-Header', header)
          if (header.length === 1) {
            this.props.history.push(`${LINK_REDIRECT}`)
            return this.props.actions
              .commonFetchDepartments(1, this.props.donViId, true)
              .then(res => {
                if (res.error || (res.payload && res.payload.status !== 200)) {
                  this.setState({ isError: true, isLoading: false })
                }
              })
          }
          this.props.history.push(
            `${LINK_REDIRECT}/${header[header.length - 1].id}`
          )
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.categoryDepartmentChild.items !==
      this.props.categoryDepartmentChild.items
    ) {
      if (this.props.categoryDepartmentChild.items.length === 0) {
        return
      }

      const header = this.state.header
      if (
        header.find(
          item =>
            item.id ===
              this.props.categoryDepartmentChild.items[0].phongBanCha &&
            item.tenVietTat ===
              this.props.categoryDepartmentChild.items[0].maPhongBanCha
        ) === undefined
      ) {
        header.push({
          id: this.props.categoryDepartmentChild.items[0].phongBanCha,
          tenVietTat: this.props.categoryDepartmentChild.items[0].maPhongBanCha,
        })
      }
      Utils.saveData('DepartmentCategoryPage-Header', header)
      this.setState({
        Departments: this.props.categoryDepartmentChild.items,
        header,
        isLoading: false,
      })
    }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.actions
        .getCategoryDepartmentChild(this.props.match.params.id, true)
        .then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
          if (res.payload && res.payload.data.result.length === 0) {
            return this.props.history.goBack()
          }
        })
    }

    if (
      prevState.donViId !== this.state.donViId &&
      prevState.isRedirect !== this.state.isRedirect
    ) {
      this.props.actions
        .commonFetchDepartments(1, this.state.donViId, true)
        .then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
          Utils.saveData('DepartmentCategoryPage', this.state.donViId)
          if (this.state.isRedirect) {
            const header = this.state.header
            header.splice(1)
            Utils.saveData('DepartmentCategoryPage-Header', header)
            this.setState({ isRedirect: false, header }, () => {
              this.props.history.push(`${LINK_REDIRECT}`)
            })
          }
        })
    }
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.departments.items !== this.props.departments.items) {
      this.setState(
        {
          Departments: nextProps.departments.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    }

    if (nextProps.unitSelect.items !== this.props.unitSelect.items) {
      this.setState({
        unitSelect: nextProps.unitSelect.items,
      })
    }

    if (nextProps.departments.items === this.props.departments.items) {
      this.setState({ isLoading: false })
    }
  }

  handleSubmit(id) {
    this.setState({ isLoading: true })
  }

  handleSaveHeader(header) {
    this.setState({ header })
  }

  handleRemoveHeader(i) {
    const header = this.state.header

    header.splice(i + 1)

    this.setState({ header, isLoading: true })
    Utils.saveData('DepartmentCategoryPage-Header', header)
  }

  handleSubmitRemove() {
    this.props.history.goBack()
  }

  handleChange(e) {
    if (e !== undefined) {
      this.setState({
        donViId: e,
        isRedirect: true,
        isLoading: true,
      })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <HeadingPage
          namePage="Danh mục phòng ban"
          iconPage="icon-departments"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <div className="listrole-container">
          <ul className="pt-breadcrumbs breadcrumb-path">
            {this.state.header.map((item, i) => (
              <li key={i}>
                <Link
                  className={classnames('pt-breadcrumb', {
                    'pt-breadcrumb-current': this.state.header.length === i + 1,
                  })}
                  to={`${LINK_REDIRECT}/${this.state.header[i].id}`}
                  onClick={this.handleRemoveHeader.bind(this, i)}
                >
                  {this.state.header[i].tenVietTat}
                </Link>
              </li>
            ))}
          </ul>
          <div className="search-container search-group-container search-system-module">
            <div className="row seach-form">
              <div className="col-xs-12 col-sm-12 col-sm-12 end-xs">
                <form
                  className="form-search-basic"
                  onSubmit={this.handleSubmit}
                >
                  <div className="search-header-group form-container">
                    <div className="search-system-tree">
                      <label className="pt-label pt-label-inline">
                        <span className="text-label">Đơn vị</span>
                      </label>
                      <SelectTree
                        data={this.state.unitSelect}
                        type="Unit"
                        permission={false}
                        value={
                          this.state.unitSelect.length > 0
                            ? this.state.donViId
                            : ''
                        }
                        handleChange={this.handleChange}
                        isLoading={
                          this.state.unitSelect.length > 0 ? false : true
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <DepartmentCategory
            items={this.state.Departments}
            actions={this.props.actions}
            onSubmit={this.handleSubmit}
            onRemove={this.handleSubmitRemove}
            match={this.props.match}
            auth={this.props.auth.mainUnitId}
            donViId={this.state.donViId}
            saveHeader={this.handleSaveHeader}
            history={this.props.history}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
          ></DepartmentCategory>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },

  departments: {
    ...state.common,
    items: state.common.differentDepartments,
  },

  categoryDepartmentChild: {
    ...state.categoryDepartmentChild,
    items: state.category.categoryDepartmentChild,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentCategoryPage)
