import React, { Component } from 'react'
import { ContentToggle } from '../../components/common'
import _ from 'lodash'
import classnames from 'classnames'
import moment from 'moment'

class DataTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dsThanhVien: [],
    }
  }

  componentDidMount() {
    const dsThanhVien = _.get(this.props, 'detail.dsThanhVien', [])
    const newDsThanhVien = []

    dsThanhVien.map((item, i) => {
      newDsThanhVien.push({ ...item, stt: i })
      if (item.noiDungYKien) {
        return newDsThanhVien.push({ noiDung: item.noiDungYKien })
      }

      return true
    })

    this.setState({ dsThanhVien: newDsThanhVien })
  }

  handleGetName = data => {
    let name = ''
    switch (data) {
      case 0:
        name = 'Chưa có ý kiến'
        break
      case 1:
        name = 'Không đồng ý'
        break
      case 2:
        name = 'Đồng ý'
        break
      case 3:
        name = 'Ý kiến khác'
        break
      default:
        name = ''
    }

    return name
  }

  render() {
    const dsThanhVien = _.get(this.state, 'dsThanhVien', [])
    return (
      <div className="pb10">
        <div className="table-responsive">
          <table className="table table-detail">
            <thead>
              <tr>
                <th>Stt</th>
                <th>Thành viên</th>
                <th>Trạng thái</th>
                <th>Thời gian</th>
              </tr>
            </thead>
            <tbody>
              {dsThanhVien.map((item, i) => {
                if (item.noiDung) {
                  return (
                    <tr className="sub" key={i}>
                      <td colSpan="4">
                        <ContentToggle data={item.noiDung} />
                      </td>
                    </tr>
                  )
                }
                return (
                  <tr key={i}>
                    <td>
                      {item.stt + 1 > 9 ? item.stt + 1 : `0${item.stt + 1}`}
                    </td>
                    <td>
                      <h4>{item.tenNhanVien}</h4>
                    </td>
                    <td>
                      <span
                        className={classnames('status', {
                          blue: item.trangThai === 2,
                          yellow: item.trangThai === 0,
                          black: item.trangThai === 3,
                        })}
                      >
                        {this.handleGetName(item.trangThai)}
                      </span>
                    </td>
                    <td>
                      {item.thoiGian &&
                        moment(item.thoiGian).format('DD/MM/YYYY HH:mm')}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default DataTable
