import { Action, Toast, ValidateForm } from '../../../constants/MessageForm'
import { Button, Checkbox, Intent } from '@blueprintjs/core'
import React, { PureComponent } from 'react'
import { commonAddToasterMessage, fileUpload } from '../../../actions'
import {
  fetchAllProcedureCategory,
  fetchProcedure,
  procedureNameCheck,
} from '../../../actions/procedure'
import {
  fileType,
  maxFileSize,
  maxLength,
  required,
  selectRequired,
  unique,
} from '../../../helpers/validation'

import ApprovalStepForm from './ApprovalStepForm'
import GoBackButton from '../../../components/Button/GoBackButton'
import { Utils } from '../../../helpers'
import _ from 'lodash'
import { arrayMove } from 'react-sortable-hoc'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const EMPTY_STEP = {
  name: '',
  quantity_signature: 1,
  signature_location_type: 0,
  signature_location_note: '',
  signature_location_page: null,
  signature_type: 0,
  sign_subject: 0,
  role: [],
}

const EMPTY_FILE_INFO = {
  id: null,
  name: '',
  size: 0,
  type: null,
  url: null,
  esixt: false,
  origin: null,
}

const DEFAULT_STATE = {
  formData: {
    id: null,
    category_id: '-1',
    procedure_code: '',
    procedure_name: '',
    guide_document_file_info: { ...EMPTY_FILE_INFO },
    is_using: true,
    approval_step: [EMPTY_STEP],
    xac_dinh_thanh_phan_ky_duyet: 0,
    suggestFileInfo: { ...EMPTY_FILE_INFO },
  },
  error: {},
  isWaitingSubmit: false,
  isLoadingData: false,
  isLoadingDataError: false,
  isLoadingCategory: false,
  isLoadingCategoryError: false,
  isUploadingFile: false,
  defineList: [],
  defaultDefineList: [],
  isUploadingSuggestFile: false,
}

class ProcedureForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      ...DEFAULT_STATE,
    }
  }

  setDefineList = (list = [], removeOld = true) => {
    // truyền vào một mảng kiểu chuỗi
    const { defineList } = this.state
    let selfDefineList = [...defineList]
    if (removeOld) {
      selfDefineList = defineList.filter(i => !!i.className)
    }
    const newDefineList = list
      .filter(i => selfDefineList.every(item => item.value !== i))
      .map(i => ({
        value: i,
        label: i,
      }))
    this.setState({ defineList: [...selfDefineList, ...newDefineList] })
  }

  setDefineListWithCategory = id => {
    const { procedureCategory: { list = [] } = {} } = this.props
    const step = list.find(i => i.id === id)
    if (step) {
      this.setDefineList(step.dsDinhNghiaBuoc || [])
    }
  }

  handleChange = async e => {
    const { name, value, type, checked } = e.target
    const { formData, error, defaultDefineList } = this.state
    let approval_step = formData.approval_step
    let newValue = value

    if (name === 'category_id' && value !== '-1') {
      await this.setDefineListWithCategory(value)
      this.setDefineList(defaultDefineList, false)
    }
    delete error[name]
    if (name === 'xac_dinh_thanh_phan_ky_duyet') {
      newValue = parseInt(value, 10)
      approval_step = approval_step.map(i => ({
        ...i,
        sign_subject: newValue,
        role: [],
      }))
      if (newValue === 1) {
        approval_step = approval_step.map(i => ({
          ...i,
          signature_location_type: 0,
        }))
      }
    }
    this.setState({
      formData: {
        ...formData,
        [name]: type === 'checkbox' ? checked : newValue,
        approval_step,
      },
    })
  }

  defineStepChange = (e, index) => {
    if (!e) {
      return
    }
    const { formData, error } = this.state
    const stepError = _.get(error, 'approval_step', [])
    if (stepError[index]) {
      delete stepError[index]
    }
    const value = _.get(e, 'value', '')
    this.setState({
      formData: {
        ...formData,
        approval_step: formData.approval_step.map((item, i) =>
          i === index
            ? {
                ...item,
                name: value,
              }
            : item
        ),
      },
    })
  }

  handleChangeStepData = (e, index) => {
    const { name, value, type, checked } = e.target
    const { formData, error } = this.state
    let newValue = value
    let resetNoteInput = {}
    !!error.approval_step &&
      !!error.approval_step[index] &&
      delete error.approval_step[index][name]
    if (
      formData.approval_step[index].signature_location_type === 1 &&
      name === 'signature_location_note'
    ) {
      newValue = _.replace(value, /[^0-9.;]/g, '')
    }

    if (
      (type === 'select-one' && name !== 'role') ||
      name === 'signature_location_page'
    ) {
      newValue = parseInt(value, 10)
    }

    if (
      name === 'signature_location_type' ||
      (formData.approval_step[index].signature_location_type === 0 &&
        name === 'quantity_signature' &&
        parseInt(newValue, 10) === 2)
    ) {
      resetNoteInput = {
        signature_location_note: '',
      }
    }

    if (name === 'signature_type' && newValue === 6) {
      resetNoteInput = {
        signature_location_type: 0,
      }
    }

    if (type === 'checkbox') {
      if (checked) {
        newValue = [...formData.approval_step[index][name], newValue]
      } else {
        newValue = formData.approval_step[index][name].filter(
          i => i !== newValue
        )
      }
    }

    if (name === 'sign_subject') {
      resetNoteInput = {
        role: [],
      }
    }

    this.setState({
      formData: {
        ...formData,
        approval_step: formData.approval_step.map((item, i) =>
          i === index
            ? {
                ...item,
                ...resetNoteInput,
                [name]: newValue,
              }
            : item
        ),
      },
    })
  }

  handleFileChange = e => {
    if (!e) {
      return
    }
    const { name, value, files } = e.target
    const { error } = this.state
    delete error[name]
    let errorMsg = {}
    const extension = Utils.getExtensionFile(value)
    const file = !!files && files[0]

    if (file) {
      const { size } = file
      if (name === 'guide_document_file_info' && fileType(extension, ['pdf'])) {
        errorMsg[name] = ValidateForm.FILE_TYPE(['pdf'])
      }
      if (
        name === 'suggestFileInfo' &&
        fileType(extension, ['pdf', 'doc', 'docx', 'xls', 'xlsx'])
      ) {
        errorMsg[name] = ValidateForm.FILE_TYPE([
          'pdf',
          'doc',
          'docx',
          'xls',
          'xlsx',
        ])
      }
      if (maxFileSize(size, 25)) {
        errorMsg[name] = ValidateForm.MAX_FILE_SIZE(25)
      }
      if (!!_.size(errorMsg)) {
        this.setState({ error: errorMsg })
      } else {
        const { formData } = this.state
        this.setState({
          formData: {
            ...formData,
            [name]: {
              ...EMPTY_FILE_INFO,
              name: file.name,
              size,
              origin: file,
            },
          },
        })
      }
    }
  }

  removeFile = name => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: EMPTY_FILE_INFO,
      },
    })
  }

  uploadFile = async (name, file) => {
    if (name === 'guide_document_file_info') {
      await this.setState({ isUploadingFile: true })
    }
    if (name === 'suggestFileInfo') {
      await this.setState({ isUploadingSuggestFile: true })
    }
    const { uploadFile, showToast } = this.props
    const { formData } = this.state
    return uploadFile(file)
      .then(res => {
        const status =
          !_.get(res, 'error') && _.get(res, 'payload.status') === 200
        const fileInfo = _.get(res, 'payload.data.result[0]', {})
        if (status) {
          this.setState({
            formData: {
              ...formData,
              [name]: {
                ...formData.guide_document_file_info,
                id: fileInfo.id,
                name: fileInfo.originalName,
                size: fileInfo.length,
                type: fileInfo.extension,
                url: fileInfo.path,
                esixt: false,
              },
            },
          })
        } else {
          this.setState({
            error: {
              [name]: Toast.FAIL('Tải file lên'),
            },
          })
          showToast({
            message: Toast.FAIL('Tải file lên'),
            intent: Intent.DANGER,
          })
        }
      })
      .catch(() => {
        this.setState({
          error: {
            [name]: Toast.FAIL('Tải file lên'),
          },
        })
        showToast({
          message: Toast.FAIL('Tải file lên'),
          intent: Intent.DANGER,
        })
      })
      .finally(() => {
        if (name === 'guide_document_file_info') {
          this.setState({ isUploadingFile: false })
        }
        if (name === 'suggestFileInfo') {
          this.setState({ isUploadingSuggestFile: false })
        }
      })
  }

  convertData = () => {
    const {
      id,
      category_id,
      procedure_code,
      procedure_name,
      approval_step,
      is_using,
      guide_document_file_info,
      xac_dinh_thanh_phan_ky_duyet,
      suggestFileInfo,
    } = this.state.formData

    return {
      id: id,
      danh_muc_id: category_id,
      ma_quy_trinh: procedure_code,
      ten_quy_trinh: procedure_name,
      is_active: is_using,
      file_huong_dan: this.convertFile(guide_document_file_info),
      ds_buoc: approval_step.map((item, index) =>
        this.convertStep(item, index)
      ),
      xac_dinh_thanh_phan_ky_duyet,
      file_de_xuat: this.convertFile(suggestFileInfo),
    }
  }

  convertFile = data => {
    if (data.id) {
      return {
        file_id: data.id,
        ten_file: data.name,
        url: data.url,
        kieu_file: data.type,
        kich_thuoc: data.size,
        is_existed: data.esixt,
      }
    }
    return null
  }

  convertStep = (data, index) => {
    if (data) {
      return {
        buoc: index + 1,
        dinh_nghia: data.name,
        so_luong_nguoi_ky: data.quantity_signature,
        xac_dinh_vung_ky: data.signature_location_type,
        thong_tin_vung_ky: data.signature_location_note,
        trang_ky: data.signature_location_page,
        loai_chu_ky: data.signature_type,
        doi_tuong_ky: data.sign_subject,
        ds_vai_tro: data.sign_subject !== 0 ? data.role : [],
      }
    }
  }

  handleSubmit = async e => {
    const { toggleAllowGoback, nameCheck } = this.props
    e.preventDefault()
    await this.setState({
      isWaitingSubmit: true,
      error: {},
    })
    !!toggleAllowGoback && toggleAllowGoback()
    const {
      id,
      category_id,
      procedure_code,
      procedure_name,
      approval_step,
      guide_document_file_info,
      suggestFileInfo,
    } = this.state.formData
    const { isEdit } = this.props
    let errorMsg = {}

    if (selectRequired(category_id)) {
      errorMsg.category_id = ValidateForm.IS_REQUIRED
    }
    if (required(procedure_code)) {
      errorMsg.procedure_code = ValidateForm.IS_REQUIRED
    }
    if (required(procedure_name)) {
      errorMsg.procedure_name = ValidateForm.IS_REQUIRED
    }
    if (maxLength(procedure_code, 50)) {
      errorMsg.procedure_code = ValidateForm.MAX_LENGTH(50)
    }
    if (maxLength(procedure_name, 255)) {
      errorMsg.procedure_name = ValidateForm.MAX_LENGTH(255)
    }

    if (!required(procedure_code) && !required(procedure_name)) {
      await nameCheck({
        ma_quy_trinh: procedure_code,
        ten_quy_trinh: procedure_name,
        id,
      }).then(res => {
        let uniqueCode = true,
          uniqueName = true
        const status = _.get(res, 'payload.data.code') === 200
        if (!status) {
          uniqueCode = _.get(res, 'payload.data.result.maQuyTrinh')
          uniqueName = _.get(res, 'payload.data.result.tenQuyTrinh')
        }
        if (!uniqueCode) {
          errorMsg.procedure_code = ValidateForm.UNIQUE()
        }
        if (!uniqueName) {
          errorMsg.procedure_name = ValidateForm.UNIQUE()
        }
      })
    }

    errorMsg.approval_step = []
    const check_list = approval_step.map(item => item.name.toLowerCase())
    approval_step.forEach(item => {
      let stepError = {}
      if (unique(item.name, check_list)) {
        stepError.name = ValidateForm.UNIQUE('Định nghĩa bước ký duyệt')
      }
      if (required(item.name)) {
        stepError.name = ValidateForm.IS_REQUIRED
      }
      if (maxLength(item.name, 1000)) {
        stepError.name = ValidateForm.MAX_LENGTH(1000)
      }
      if (
        item.signature_location_type === 1 &&
        required(item.signature_location_note)
      ) {
        stepError.signature_location_note = ValidateForm.IS_REQUIRED
      }
      if (maxLength(item.signature_location_note, 255)) {
        stepError.signature_location_note = ValidateForm.MAX_LENGTH(255)
      }
      if (item.sign_subject !== 0 && !item.role.length) {
        stepError.role = ValidateForm.IS_REQUIRED
      }
      errorMsg.approval_step.push(stepError)
    })
    if (guide_document_file_info.origin && !guide_document_file_info.id) {
      await this.uploadFile(
        'guide_document_file_info',
        guide_document_file_info.origin
      )
    }
    if (suggestFileInfo.origin && !suggestFileInfo.id) {
      await this.uploadFile('suggestFileInfo', suggestFileInfo.origin)
    }
    if (errorMsg.approval_step.every(item => _.isEmpty(item))) {
      delete errorMsg.approval_step
    }
    if (!!_.size(errorMsg)) {
      this.props.showToast({
        message: Toast.INCOMPLETE,
        intent: Intent.DANGER,
      })
      await this.setState({ error: { ...this.state.error, ...errorMsg } })
    } else {
      const { dispatch, action, showToast } = this.props
      const convertData = this.convertData()
      dispatch(action(convertData)).then(res => {
        const status = !!_.get(res, 'payload.data.result')
        showToast({
          message: status
            ? Toast.SUCCESS(isEdit ? Action.UPDATE : Action.CREATE)
            : Toast.FAIL(isEdit ? Action.UPDATE : Action.CREATE),
          intent: status ? Intent.SUCCESS : Intent.DANGER,
        })
        return status && this.props.history.goBack()
      })
    }
    this.setState({ isWaitingSubmit: false })
    !!toggleAllowGoback && toggleAllowGoback()
  }

  addStep = e => {
    e.preventDefault()
    const { formData } = this.state
    let newStep = EMPTY_STEP
    if (formData.xac_dinh_thanh_phan_ky_duyet === 1) {
      newStep = { ...newStep, sign_subject: 1 }
    }
    const newAprovalStep = formData.approval_step
    let lastStep = newAprovalStep.pop()
    if (lastStep.signature_type === 6) {
      lastStep.signature_type = 0
    }
    this.setState({
      formData: {
        ...formData,
        approval_step: [...newAprovalStep, lastStep, newStep],
      },
    })
  }

  removeStep = index => {
    const { formData, error } = this.state
    this.setState(() => {
      let state = {
        formData: {
          ...formData,
          approval_step: formData.approval_step.filter(
            (item, i) => i !== index
          ),
        },
      }
      if (error.approval_step) {
        state.error = {
          ...error,
          approval_step: error.approval_step.filter((item, i) => i !== index),
        }
      }
      return state
    })
  }

  moveStep = ({ oldIndex, newIndex }) => {
    const { formData, error } = this.state
    if (oldIndex !== newIndex) {
      this.setState({
        formData: {
          ...formData,
          approval_step: arrayMove(formData.approval_step, oldIndex, newIndex),
        },
        error: {
          ...error,
          approval_step: error.approval_step
            ? arrayMove(error.approval_step, oldIndex, newIndex)
            : [],
        },
      })
    }
  }

  async componentDidMount() {
    const { fetchCategory, isEdit, fetchData } = this.props
    await this.setState({ isLoadingCategory: true })
    fetchCategory({ activeFilter: true }).then(res => {
      const status = !!(res && res.payload && res.payload.status === 200)
      this.setState({
        isLoadingCategoryError: !status,
        isLoadingCategory: false,
      })
    })
    if (isEdit) {
      const { id } = this.props.match.params
      await this.setState({ isLoadingData: true })
      fetchData(id).then(res => {
        const status = !!(res && res.payload && res.payload.status === 200)
        this.setState({
          isLoadingDataError: !status,
          isLoadingData: false,
        })
      })
    }
  }

  componentDidUpdate(prevState) {
    if (
      this.state.isLoadingDataError &&
      this.state.isLoadingDataError !== prevState.isLoadingDataError
    ) {
      this.props.showToast({
        message: Toast.FAIL(Action.FETCH),
        intent: Intent.DANGER,
      })
      this.props.history.goBack()
    }
  }

  convertReverseData = data => {
    const {
      procedureCategory: { list = [] },
    } = this.props
    const {
      danhMucId,
      quyTrinhId,
      maQuyTrinh,
      tenQuyTrinh,
      fileHuongDan,
      dsBuocKyDuyet,
      isActive,
      xacDinhThanhPhanKyDuyet,
      fileDeXuat,
    } = data

    return {
      id: quyTrinhId || '',
      category_id: list.map(i => i.id).includes(danhMucId) ? danhMucId : '-1',
      procedure_code: maQuyTrinh || '',
      procedure_name: tenQuyTrinh || '',
      guide_document_file_info: this.convertReverseFile(fileHuongDan),
      is_using: !!isActive,
      approval_step: dsBuocKyDuyet
        ? dsBuocKyDuyet.map(item => this.convertReverseStep(item))
        : [...EMPTY_STEP],
      xac_dinh_thanh_phan_ky_duyet: xacDinhThanhPhanKyDuyet,
      suggestFileInfo: this.convertReverseFile(fileDeXuat),
    }
  }

  convertReverseFile = data => {
    if (data) {
      return {
        id: data.fileId || null,
        name: data.tenFile || '',
        size: data.kichThuoc || 0,
        type: data.kieuFile || null,
        url: data.url || null,
        esixt: true,
      }
    }
    return { ...EMPTY_FILE_INFO }
  }

  convertReverseStep = data => {
    if (data) {
      return {
        id: data.buocId || null,
        name: data.dinhNghia || '',
        quantity_signature: data.soLuongNguoiKy || 1,
        signature_location_type: data.xacDinhVungKy || 0,
        signature_location_note: data.thongTinVungKy || '',
        signature_location_page: data.trangKy || null,
        signature_type: data.loaiChuKy || 0,
        sign_subject: data.doiTuongKy || 0,
        role: data.dsVaiTro || [],
      }
    }
  }

  async componentWillReceiveProps(nextProps) {
    const { editData } = this.props
    if (editData !== nextProps.editData) {
      const { danhMucId, tenQuyTrinh, dsBuocKyDuyet } = nextProps.editData
      let defaultDefineList = []
      this.props.changePageName(tenQuyTrinh)
      await this.setDefineListWithCategory(danhMucId)
      if (dsBuocKyDuyet) {
        defaultDefineList = dsBuocKyDuyet.map(i => i.dinhNghia)
        await this.setDefineList(defaultDefineList, false)
      }
      const formData = this.convertReverseData(nextProps.editData)
      this.setState({
        formData,
        isLoadingData: false,
        defaultDefineList,
      })
    }
  }

  render() {
    const {
      formData,
      isLoadingData,
      isLoadingCategory,
      isWaitingSubmit,
      isUploadingFile,
      error,
      defineList,
      isUploadingSuggestFile,
    } = this.state
    const { procedureCategory, isEdit } = this.props

    return (
      <div className="systerm-main-content procedure-form-content">
        <div className="form-container">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock no-max-width">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="pt-form-group">
                      <label className="pt-label">
                        Danh mục
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div
                        className={`pt-select  pt-form-content ${
                          isLoadingData || isLoadingCategory
                            ? 'loading-input'
                            : ''
                        }`}
                      >
                        <select
                          name="category_id"
                          value={formData.category_id || ''}
                          className="pt-input"
                          disabled={isLoadingData || isLoadingCategory}
                          onChange={this.handleChange}
                        >
                          <option default value={'-1'}>
                            Chọn danh mục
                          </option>
                          {!!procedureCategory &&
                            !!procedureCategory.list &&
                            procedureCategory.list.map(item => (
                              <option value={item.id} key={item.id}>
                                {item.ten}
                              </option>
                            ))}
                        </select>
                      </div>
                      {!!error.category_id && (
                        <div className="pt-form-helper-text">
                          {error.category_id}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-cols form-col5 pl0">
                      <div className="pt-form-group">
                        <label className="pt-label">
                          Mã quy trình
                          <span className="pt-text-muted required-input">
                            *
                          </span>
                        </label>
                        <div className="input-form pt-form-content">
                          <input
                            type="text"
                            name="procedure_code"
                            onChange={this.handleChange}
                            value={formData.procedure_code || ''}
                            className={`pt-input ${
                              isLoadingData && 'loading-input'
                            }`}
                            placeholder="Nhập mã quy trình"
                            maxLength="50"
                            disabled={isLoadingData}
                          />
                          {!!error.procedure_code && (
                            <div className="pt-form-helper-text">
                              {error.procedure_code}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-cols form-col5 pr0">
                      <div className="pt-form-group">
                        <label className="pt-label">
                          Tên quy trình
                          <span className="pt-text-muted required-input">
                            *
                          </span>
                        </label>
                        <div className="input-form pt-form-content">
                          <input
                            type="text"
                            name="procedure_name"
                            onChange={this.handleChange}
                            value={formData.procedure_name || ''}
                            className={`pt-input ${
                              isLoadingData && 'loading-input'
                            }`}
                            placeholder="Nhập tên quy trình"
                            maxLength="255"
                            disabled={isLoadingData}
                          />
                          {!!error.procedure_name && (
                            <div className="pt-form-helper-text">
                              {error.procedure_name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="pt-form-group">
                      <label className="pt-label">
                        File tài liệu hướng dẫn
                      </label>
                      <div className="form-row">
                        <div className="form-cols form-col5 pl0">
                          <div
                            className="input-form pt-form-content"
                            style={{ position: 'relative' }}
                          >
                            <label className="pt-file-upload">
                              <input
                                type="file"
                                name="guide_document_file_info"
                                onChange={this.handleFileChange}
                                className="pt-input"
                                disabled={
                                  isUploadingFile ||
                                  isLoadingData ||
                                  isLoadingCategory
                                }
                              />
                              <span className="pt-file-upload-input">
                                {formData.guide_document_file_info.name ? (
                                  <span className="has-file-input">
                                    {formData.guide_document_file_info.name}
                                  </span>
                                ) : (
                                  'Chọn file đính kèm'
                                )}
                              </span>
                            </label>
                            {formData.guide_document_file_info.name &&
                              !isUploadingFile && (
                                <span
                                  className="icon-bc icon-close icon-remove-file"
                                  onClick={() =>
                                    this.removeFile('guide_document_file_info')
                                  }
                                />
                              )}
                            {!!isUploadingFile && (
                              <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                                <div className="pt-progress-meter" />
                              </div>
                            )}
                            {!isEdit &&
                              !!formData.guide_document_file_info.size && (
                                <p className="pt-form-helper-text pt-form-helper-infomation">
                                  Kích thước file:{' '}
                                  <span className="size-file">
                                    {Utils.getFileSize(
                                      formData.guide_document_file_info.size
                                    )}
                                  </span>
                                </p>
                              )}
                            {!!error.guide_document_file_info && (
                              <div className="pt-form-helper-text">
                                {error.guide_document_file_info}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-cols form-col5">
                          <div className="pt-form-group">
                            <label className="pt-label ">
                              <Checkbox
                                name="is_using"
                                onChange={this.handleChange}
                                checked={!!formData && !!formData.is_using}
                                disabled={isLoadingData}
                              >
                                Sử dụng
                              </Checkbox>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="pt-form-group">
                      <label className="pt-label">
                        Xác định thành phần ký duyệt
                      </label>
                      <div
                        className={`pt-select  pt-form-content ${
                          isLoadingData ? 'loading-input' : ''
                        }`}
                      >
                        <select
                          name="xac_dinh_thanh_phan_ky_duyet"
                          value={formData.xac_dinh_thanh_phan_ky_duyet}
                          className="pt-input"
                          disabled={isLoadingData}
                          onChange={this.handleChange}
                        >
                          <option value={0}>Theo danh sách thành viên</option>
                          <option value={1}>Theo phòng ban hoặc vai trò</option>
                          <option value={9}>
                            Theo danh sách thành viên hoặc đơn vị ngoài
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="pt-form-group">
                      <label className="pt-label">File đề xuất quy trình</label>
                      <div
                        className="input-form pt-form-content"
                        style={{ position: 'relative' }}
                      >
                        <label className="pt-file-upload">
                          <input
                            type="file"
                            name="suggestFileInfo"
                            onChange={this.handleFileChange}
                            className="pt-input"
                            disabled={
                              isUploadingSuggestFile ||
                              isLoadingData ||
                              isLoadingCategory
                            }
                          />
                          <span className="pt-file-upload-input">
                            {formData.suggestFileInfo.name ? (
                              <span className="has-file-input">
                                {formData.suggestFileInfo.name}
                              </span>
                            ) : (
                              'Chọn file đính kèm'
                            )}
                          </span>
                        </label>
                        {formData.suggestFileInfo.name &&
                          !isUploadingSuggestFile && (
                            <span
                              className="icon-bc icon-close icon-remove-file"
                              onClick={() => this.removeFile('suggestFileInfo')}
                            />
                          )}
                        {!!isUploadingSuggestFile && (
                          <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                            <div className="pt-progress-meter" />
                          </div>
                        )}
                        {!isEdit && !!formData.suggestFileInfo.size && (
                          <p className="pt-form-helper-text pt-form-helper-infomation">
                            Kích thước file:{' '}
                            <span className="size-file">
                              {Utils.getFileSize(formData.suggestFileInfo.size)}
                            </span>
                          </p>
                        )}
                        {!!error.suggestFileInfo && (
                          <div className="pt-form-helper-text">
                            {error.suggestFileInfo}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block">
              <div className="form-subblock no-max-width">
                <ApprovalStepForm
                  addStep={this.addStep}
                  removeStep={this.removeStep}
                  handleChange={this.handleChangeStepData}
                  data={formData.approval_step}
                  isLoading={isLoadingData}
                  error={error.approval_step || []}
                  moveStep={this.moveStep}
                  defineStepChange={this.defineStepChange}
                  defineList={defineList}
                  thanhPhanKy={formData.xac_dinh_thanh_phan_ky_duyet}
                />
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <GoBackButton disabled={isWaitingSubmit} />
                <Button
                  type="button"
                  className="pt-button pt-button btn-blue-color"
                  loading={isWaitingSubmit}
                  onClick={this.handleSubmit}
                  disabled={isUploadingFile || isWaitingSubmit}
                >
                  <span className="text-content">Lưu</span>
                  <i className="pt-icon icon-save" />
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  procedureCategory: state.procedureCategory,
  editData: state.procedure.currentItem,
})

const mapDispatchToProps = dispatch => ({
  fetchCategory: bindActionCreators(fetchAllProcedureCategory, dispatch),
  fetchData: bindActionCreators(fetchProcedure, dispatch),
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
  uploadFile: bindActionCreators(fileUpload, dispatch),
  nameCheck: bindActionCreators(procedureNameCheck, dispatch),
  dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProcedureForm))
