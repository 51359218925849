import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const check = '00000000-0000-0000-0000-000000000000'
class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  handleClick = (id, type) => {
    if (id !== check) {
      const filter = this.props.filter
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/thong-ke-cong-viec/chi-tiet/phong-ban/${filter.phong_ban_id}/nhan-vien/${id}/${type}`,
          state: {
            ngayGiaoViec: {
              from: filter.start_date,
              to: filter.end_date,
            },
            loaiCongViec: filter.loai_cong_viec,
          },
        })
      )
    }
  }

  handleSubmit = (id, isDonVi, nhanVienPhongBanId) => {
    if (id !== check && isDonVi && nhanVienPhongBanId === check) {
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/thong-ke-cong-viec/danh-sach/don-vi/${id}`,
          isDonVi,
          id,
          state: {
            isFromeDetailPage: true,
          },
        })
      )
    }
    if (id !== check && !isDonVi && nhanVienPhongBanId === check) {
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/thong-ke-cong-viec/danh-sach/phong-ban/${id}`,
          isDonVi,
          id,
          state: {
            isFromeDetailPage: true,
          },
        })
      )
    }
  }

  render() {
    const { item, number } = this.props
    return (
      <tr
        className={classnames({
          'text-bold': item.id === check && item.name === 'Tổng cộng',
        })}
      >
        <td className="gerenal-list-utilities">
          {item.id !== check && item.name !== 'Tổng cộng'
            ? number > 9
              ? number
              : '0' + number
            : null}
        </td>
        <td
          className={classnames('gerenal-list-utilities', {
            'over-time cursor-pointer':
              item.nhanVienPhongBanId === check &&
              item.tenNhanVien !== 'Tổng cộng',
          })}
          onClick={this.handleSubmit.bind(
            this,
            item.id,
            item.isDonVi,
            item.nhanVienPhongBanId
          )}
        >
          {item && item.tenNhanVien}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item && item.tongSoCongViec}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item && item.tongSoCongViecChuaGiaiQuyet}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item && item.tongSoCongViecDaGiaiQuyet}
        </td>
        <td
          className={classnames('text-center', {
            'over-time': item.nhanVienPhongBanId !== check,
          })}
          onClick={this.handleClick.bind(
            this,
            item.nhanVienPhongBanId,
            'qua-han'
          )}
        >
          <span className="hover-over-time">
            {item && item.tongSoCongViecQuaHanChuaGiaiQuyet}
          </span>
        </td>
        <td
          className={classnames('gerenal-list-utilities text-center', {
            'over-time': item.nhanVienPhongBanId !== check,
          })}
          onClick={this.handleClick.bind(
            this,
            item.nhanVienPhongBanId,
            'can-bao-cao'
          )}
        >
          <span className="hover-over-time">
            {item && item.tongSoCongViecCanBaoCao}
          </span>
        </td>
      </tr>
    )
  }
}

export default ListItem
