import React, { memo, Fragment, useState, useRef, useEffect } from 'react'
import { Icon } from '@blueprintjs/core'
import { size, get } from 'lodash'
import csx from 'classnames'

import { Pagination, Loading } from '../index'
import {
  MESSAGE_ERROR,
  MESSAGE_NOT_FOUND,
  MESSAGE_REQUIRED_STATISTICS,
} from 'constants/MessageForm'

/* VIẾT LẠI GRIDVIEWFILTER */

const publicPath = process.env.PUBLIC_URL
const imgPaths = {
  notFound: `${publicPath}/images/search-result-not-found.png`,
  error: `${publicPath}/images/Server.png`,
  statistic: `${publicPath}/images/calendar.png`,
}
const messages = {
  notFound: MESSAGE_NOT_FOUND,
  error: MESSAGE_ERROR,
  statistic: MESSAGE_REQUIRED_STATISTICS,
}

const Table = ({
  loading = true,
  error = false,
  statisticMessage = false,
  columns = [],
  onRowClick,
  onContextMenu = () => {},
  data = [],
  rowClassName = () => {},
  className,
  classNameWrapper,
  sortValue,
  onSort: tableSort = () => {},
  extraRecord = null,
  assistance = null,
  pagination = {},
  onChangePage = () => {},
  match = {},
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const fullRow = size(columns)
  const empty = !size(data)
  const showBody = !(loading || error || empty || statisticMessage)

  const [sorts, setSorts] = useState({})

  const onSort = key => () => {
    const value = get(sorts, key, 'ASC') === 'DESC' ? 'ASC' : 'DESC'
    mountedSet(setSorts, { [key]: value })
    tableSort(`${key} ${value}`)
  }

  const getMessageType = () => {
    if (error) {
      return 'error'
    }
    if (empty) {
      return 'notFound'
    }
    if (statisticMessage) {
      return 'statistic'
    }
    return ''
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (!sortValue) return mountedSet(setSorts, {})
    const [key, value] = sortValue.split(' ')
    mountedSet(setSorts, key && value ? { [key]: value } : {})
  }, [sortValue])

  return (
    <Fragment>
      <div className={csx('cpc-table-old-wrapper', classNameWrapper)}>
        <table className={csx('pt-table', 'cpc-table-old', className)}>
          <thead>
            <tr>
              {columns.map(
                ({ key, title, className: headClass, sortKey }, headIndex) => (
                  <th
                    key={key || headIndex}
                    className={csx('header-cell', headClass)}
                  >
                    {title}
                    {sortKey && (
                      <Icon
                        iconName={
                          get(sorts, sortKey, 'ASC') === 'DESC'
                            ? 'chevron-down'
                            : 'chevron-up'
                        }
                        className={csx('ml5', 'has-event')}
                        onClick={onSort(sortKey)}
                      />
                    )}
                  </th>
                )
              )}
            </tr>
            {columns.some(cell => cell.hasOwnProperty('filter')) && (
              <tr className="">
                {columns.map(({ filter }, index) => (
                  <th key={index}>{filter}</th>
                ))}
              </tr>
            )}
          </thead>

          <tbody>
            {showBody ? (
              <Fragment>
                {data.map((record, rowIndex) => {
                  const { key: rowKey } = record
                  return (
                    <tr
                      key={rowKey || rowIndex}
                      className={csx(
                        {
                          'has-event': onRowClick,
                        },
                        {
                          'row-active': get(match, 'params.id') === record.id,
                        },
                        rowClassName(record)
                      )}
                      onClick={() => onRowClick && onRowClick(record)}
                      onContextMenu={e => onContextMenu(record, e)}
                    >
                      {columns.map((column, columnIndex) => {
                        const {
                          key: columnKey,
                          render,
                          dataIndex,
                          align,
                          className: columnClass,
                        } = column
                        return (
                          <td
                            key={columnKey || columnIndex}
                            className={csx(
                              {
                                [`text-${align}`]: !!align,
                              },
                              columnClass
                            )}
                          >
                            {render
                              ? render(
                                  dataIndex ? get(record, dataIndex) : record,
                                  record,
                                  rowIndex
                                )
                              : get(record, dataIndex)}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
                {extraRecord}
              </Fragment>
            ) : (
              <tr className={csx({ 'table-loading': loading })}>
                <td
                  colSpan={fullRow}
                  className={csx({
                    'table-placeholder': !loading,
                  })}
                >
                  {loading ? (
                    <Loading column={3} />
                  ) : (
                    <Fragment>
                      <img
                        src={imgPaths[getMessageType()]}
                        alt="table-placeholder"
                        className="thumb"
                      />
                      <p className="description">
                        {messages[getMessageType()]}
                      </p>
                    </Fragment>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showBody && (
        <div
          className={csx('cpc-table-old-footer', {
            'no-border': true,
          })}
        >
          {assistance}
          {pagination && <Pagination {...pagination} onChange={onChangePage} />}
        </div>
      )}
    </Fragment>
  )
}

export default memo(Table)
