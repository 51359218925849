import React from 'react'
import { Checkbox } from '@blueprintjs/core'
import { PropTypes } from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import NotFound from './NotFound'
import LoadingItem from './LoadingItem'

class CheckboxGroupTwoColums extends React.Component {
  constructor(props) {
    super(props)
    this.handleEnabledChangeCheckbox =
      this.handleEnabledChangeCheckbox.bind(this)
    this.handleCheckAllCheckbox = this.handleCheckAllCheckbox.bind(this)
    this.state = {
      valueCheckbox1: props.listSelectedData1 || [],
      valueCheckbox2: props.listSelectedData2 || [],
      isCheckAllCheckBox1: false,
      isCheckAllCheckBox2: false,
      listDisabledOption1: [],
      listDisabledOption2: [],
    }
  }

  static propTypes = {
    options: PropTypes.array,
    listDistabledData1: PropTypes.array,
    listDistabledData2: PropTypes.array,
    titles: PropTypes.array,
    listSelectedData1: PropTypes.array,
    listSelectedData2: PropTypes.array,
    isDisabledAll: PropTypes.bool,
    isShowCheckAll: PropTypes.bool,
    isDisabledAllData1: PropTypes.bool,
    isDisabledAllData2: PropTypes.bool,
    isLoadingData: PropTypes.bool,
  }

  handleEnabledChangeCheckbox = (
    currentValueCheckboxName,
    anotherValueCheckboxName,
    e
  ) => {
    let currentValueCheckbox
    let anotherValueCheckbox = this.state[anotherValueCheckboxName]
    const newSelection = e.target.id
    if (
      this.state[currentValueCheckboxName].length > 0 &&
      this.state[currentValueCheckboxName].indexOf(newSelection) > -1
    ) {
      currentValueCheckbox = this.state[currentValueCheckboxName].filter(
        s => s !== newSelection
      )
    } else {
      currentValueCheckbox = [
        ...this.state[currentValueCheckboxName],
        newSelection,
      ]
      anotherValueCheckbox = this.state[anotherValueCheckboxName].filter(
        s => s !== newSelection
      )
    }
    this.setState({
      [currentValueCheckboxName]: currentValueCheckbox,
      [anotherValueCheckboxName]: anotherValueCheckbox,
    })
  }

  handleCheckAllCheckbox = (
    currentValueCheckboxName,
    currentIsCheckAllCheckboxName,
    currentListDisabledOptionName,
    anotherValueCheckboxName
  ) => {
    let currentValueCheckbox = this.state[currentValueCheckboxName]
    let currentIsCheckAllCheckbox = !this.state[currentIsCheckAllCheckboxName]
    if (this.state[currentIsCheckAllCheckboxName]) {
      currentValueCheckbox = []
    } else {
      this.props.options.forEach(item => {
        if (
          this.state[currentValueCheckboxName].indexOf(item.value) === -1 &&
          this.state[currentListDisabledOptionName].indexOf(item.value) === -1
        ) {
          currentValueCheckbox.push(item.value)
        }
      })
    }

    if (currentValueCheckbox.length === 0) {
      currentIsCheckAllCheckbox = false
    }

    this.setState({
      [currentIsCheckAllCheckboxName]: currentIsCheckAllCheckbox,
      [currentValueCheckboxName]: currentValueCheckbox,
      [anotherValueCheckboxName]: [],
    })
  }

  componentDidMount() {
    if (this.props.listSelectedData1) {
      this.setState({ valueCheckbox1: this.props.listSelectedData1 })
    }
    if (this.props.listSelectedData2) {
      this.setState({ valueCheckbox2: this.props.listSelectedData2 })
    }
    if (this.props.options && this.props.listDistabledData1) {
      let listDisabledOption1 = this.props.listDistabledData1.filter(
        s => this.props.options.find(item => item.value === s) !== undefined
      )
      this.setState({ listDisabledOption1 })
    }
    if (this.props.options && this.props.listDistabledData2) {
      let listDisabledOption2 = this.props.listDistabledData2.filter(
        s => this.props.options.find(item => item.value === s) !== undefined
      )
      this.setState({ listDisabledOption2 })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.listSelectedData1 &&
      !_.isEqual(nextProps.listSelectedData1, this.props.listSelectedData1)
    ) {
      this.setState({ valueCheckbox1: nextProps.listSelectedData1 })
    }
    if (
      nextProps.listSelectedData2 &&
      !_.isEqual(nextProps.listSelectedData2, this.props.listSelectedData2)
    ) {
      this.setState({ valueCheckbox2: nextProps.listSelectedData2 })
    }
    if (
      nextProps.options &&
      nextProps.listDistabledData1 &&
      !_.isEqual(nextProps.listDistabledData1, this.state.listDisabledOption1)
    ) {
      let listDisabledOption1 = nextProps.listDistabledData1.filter(
        s => nextProps.options.find(item => item.value === s) !== undefined
      )
      this.setState({ listDisabledOption1 })
    }
    if (
      nextProps.options &&
      nextProps.listDistabledData2 &&
      !_.isEqual(nextProps.listDistabledData2, this.state.listDisabledOption2)
    ) {
      let listDisabledOption2 = nextProps.listDistabledData2.filter(
        s => nextProps.options.find(item => item.value === s) !== undefined
      )
      this.setState({ listDisabledOption2 })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.valueCheckbox1, this.state.valueCheckbox1)) {
      let isCheckAllCheckBox1 = false
      if (
        this.state.valueCheckbox1.length +
          this.state.listDisabledOption1.length ===
          this.props.options.length &&
        this.props.options.length > 0 &&
        this.state.valueCheckbox1.length > 0
      ) {
        isCheckAllCheckBox1 = true
      }

      this.setState(
        { isCheckAllCheckBox1 },
        this.props.onSubmit &&
          this.props.onSubmit({
            listResultData1: this.state.valueCheckbox1,
            listResultData2: this.state.valueCheckbox2,
          })
      )
    }

    if (!_.isEqual(prevState.valueCheckbox2, this.state.valueCheckbox2)) {
      let isCheckAllCheckBox2 = false
      if (
        this.state.valueCheckbox2.length +
          this.state.listDisabledOption2.length ===
          this.props.options.length &&
        this.props.options.length > 0 &&
        this.state.valueCheckbox2.length > 0
      ) {
        isCheckAllCheckBox2 = true
      }

      this.setState(
        { isCheckAllCheckBox2 },
        this.props.onSubmit &&
          this.props.onSubmit({
            listResultData1: this.state.valueCheckbox1,
            listResultData2: this.state.valueCheckbox2,
          })
      )
    }
  }

  render() {
    return (
      <div
        className={classnames(
          'department-perform-table checkbox-group-two-columns',
          {
            'no-scroll-DepartmentPerform': this.props.classCustomStyle,
          }
        )}
      >
        {this.props.isLoadingData === true && (
          <div className="loading-container">
            <LoadingItem />
            <LoadingItem />
            <LoadingItem />
          </div>
        )}
        {!this.props.isLoadingData &&
          this.props.options &&
          this.props.options.length > 0 && (
            <table className="pt-table table-container">
              <thead className="heading-table">
                <tr>
                  <th>PHÒNG BAN</th>
                  <th className="head-table-checkall-content text-center">
                    {this.props.isShowCheckAll && (
                      <Checkbox
                        className="checkall"
                        name="allCompositive"
                        checked={this.state.isCheckAllCheckBox1}
                        onChange={this.handleCheckAllCheckbox.bind(
                          this,
                          'valueCheckbox1',
                          'isCheckAllCheckBox1',
                          'listDisabledOption1',
                          'valueCheckbox2'
                        )}
                        disabled={
                          !this.props.options.length ||
                          this.props.options.length ===
                            this.state.listDisabledOption1.length ||
                          this.props.isDisabledAll
                        }
                      ></Checkbox>
                    )}
                    <span className="text-content">
                      {this.props.titles && this.props.titles[0]
                        ? this.props.titles[0]
                        : 'KÝ PHỐI HỢP'}
                    </span>
                  </th>
                  <th className="head-table-checkall-content col-third text-center">
                    {this.props.isShowCheckAll && (
                      <Checkbox
                        className="checkall"
                        name="allCompositive-department"
                        checked={this.state.isCheckAllCheckBox2}
                        onChange={this.handleCheckAllCheckbox.bind(
                          this,
                          'valueCheckbox2',
                          'isCheckAllCheckBox2',
                          'listDisabledOption2',
                          'valueCheckbox1'
                        )}
                        disabled={
                          !this.props.options.length ||
                          this.props.options.length ===
                            this.state.listDisabledOption2.length ||
                          this.props.isDisabledAll
                        }
                      ></Checkbox>
                    )}
                    <span className="text-content">
                      {this.props.titles && this.props.titles[1]
                        ? this.props.titles[1]
                        : 'CHUYỂN ĐẾN'}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.options.map((item, i) => (
                  <tr key={i}>
                    <td className="cell-content">
                      {item.content}
                      {item.codeSignNumber && (
                        <span className="code-sign-number">{` (${item.codeSignNumber})`}</span>
                      )}
                    </td>
                    <td className="cell-content text-center">
                      <Checkbox
                        className="item-checkbox disabled-checkbox d-inline-block"
                        onChange={this.handleEnabledChangeCheckbox.bind(
                          this,
                          'valueCheckbox1',
                          'valueCheckbox2'
                        )}
                        name="checbox1-department"
                        id={item.value}
                        checked={
                          this.state.valueCheckbox1.indexOf(item.value) > -1 &&
                          this.state.listDisabledOption1.indexOf(item.value) ===
                            -1
                        }
                        disabled={
                          this.state.listDisabledOption1.indexOf(item.value) >
                            -1 ||
                          this.props.isDisabledAll ||
                          this.props.isDisabledAllData1 ||
                          item.isCanShowCompositive === false
                        }
                      />
                    </td>
                    <td className="cell-content text-center">
                      <Checkbox
                        className="item-checkbox disabled-checkbox d-inline-block"
                        onChange={this.handleEnabledChangeCheckbox.bind(
                          this,
                          'valueCheckbox2',
                          'valueCheckbox1'
                        )}
                        name="checbox2-department"
                        id={item.value}
                        checked={
                          (this.state.valueCheckbox2.indexOf(item.value) > -1 &&
                            this.state.listDisabledOption2.indexOf(
                              item.value
                            ) === -1) ||
                          (this.state.valueCheckbox1.indexOf(item.value) > -1 &&
                            this.state.listDisabledOption1.indexOf(
                              item.value
                            ) === -1)
                        }
                        disabled={
                          this.state.listDisabledOption2.indexOf(item.value) >
                            -1 ||
                          this.props.isDisabledAll ||
                          this.props.isDisabledAllData2
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        {!this.props.isLoadingData &&
          (!this.props.options || this.props.options.length === 0) && (
            <NotFound />
          )}
      </div>
    )
  }
}

export default CheckboxGroupTwoColums
