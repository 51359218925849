import { schema } from 'normalizr'

const AlertMistakeDocument = new schema.Entity(
  'alertMistakesDocument',
  {},
  {
    idAttribute: alertMistakesDocument =>
      alertMistakesDocument.congVanCongViecId,
  }
)

export default AlertMistakeDocument
