import React, { useState, useEffect, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import requestAction from 'helpers/request'
import Classnames from 'classnames'
import FileDownload from 'file-saver'
import print from 'print-js'
import moment from 'moment/moment'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { Tab2, Tabs2, Intent } from '@blueprintjs/core'

import { MasterLayout } from 'components/layout'
import { downloadFile } from 'helpers/Helper'
import { DO_DOWNLOAD_FILE, FE_CHAT_URL } from 'constants/Api'
import { DetailFileContentV3, NotFound } from 'components/common'
import { FileGroup, Loading, Icon, Scrollbar } from 'components/newCommon'
import * as Actions from 'actions'
import {
  getChiTietVanBanSoHoa,
  getListVanBan,
  getDOCongVan,
  getLoaiVanBanDO,
  getTenSoLoaiVanBan,
  getDuThaoId,
  getQuaTrinhXuLy,
  getDOPreviewFileCongVan,
  getDODowloadFileCongVan,
  resetDO,
  postUploadZipFromDO,
} from 'actions/doffice'
import { showToast } from 'actions/task'
import { Toast } from 'constants/MessageForm'
import QuaTrinhXuLy from './quatrinhxuly'
import QuanLyPheDuyet from './quanlypheduyet'
import MenuDetail from './components/menudetail'
import GiveTask from './components/givetask'
import SendMail from './components/sendmail'
import PerformTheWork from './components/perform'
import './index.scss'
import CryptoJS from 'crypto-js'

const ChiTietCongVan = ({
  auth,
  history,
  match,
  typeSidebar,
  tokenDO,
  chiTietVanBan,
  listVanBan,
}) => {
  const actionKeys = {
    FLOW_TASK: {
      key: 'FLOW_TASK',
      name: 'quá trình phân phối',
    },
    GIVE_TASK: {
      key: 'GIVE_TASK',
      name: 'Giao việc',
    },
    PERFORM_THE_WORK: {
      key: 'PERFORM_THE_WORK',
      name: 'Thực hiện công việc',
    },
    SEND_EMAIL: {
      key: 'SEND_EMAIL',
      name: 'Gửi email',
    },
    SEND_CHAT: {
      key: 'SEND_CHAT',
      name: 'Gửi chat',
    },
  }
  const deCodeId = CryptoJS.AES.decrypt(
    decodeURIComponent(match.params.id),
    'EVNCPC'
  )
  const id = deCodeId.toString(CryptoJS.enc.Utf8)
  const [isMenuFixTop, setIsMenuFixTop] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSendChat, setIsLoadingSendChat] = useState(false)
  const [documentDOInfo, setDocumentDOInfo] = useState(null)
  const [loaiVanBan, setLoaiVanBan] = useState(null)
  const [dataVanBan, setDataVanBan] = useState(null)
  const [filePhuLuc, setFilePhuLuc] = useState(null)
  const [quaTrinhXyLy, setDataQuaTrinhXyLy] = useState(null)
  const token = {
    tokenType: 'Bearer',
    accessToken: tokenDO?.token,
  }
  const [isLoadingFile, setIsLoadingFile] = useState(true)
  const [filePreview, setFilePreview] = useState(null)
  const [actionData, setActionData] = useState(null)
  const [fileDO, setFileDO] = useState([])
  const [tabActive, setTabActive] = useState('QuaTrinhXuLy')

  const goBack = () => {
    return history.push({
      pathname: '/quan-ly-cong-viec-do/tim-kiem-cong-van',
    })
  }

  const handleScroll = () => {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      setIsMenuFixTop(true)
    } else {
      setIsMenuFixTop(false)
    }
  }

  const getTextDoKhan = text => {
    if (!text) return ''
    switch (text) {
      case 'k':
        return 'Khẩn'
      case 't':
        return 'Thượng khẩn'
      case 'h':
        return 'Hỏa tốc'
      default:
        return 'Bình thường'
    }
  }

  const getDoiTuong = text => {
    if (!text) return ''
    switch (text) {
      case 'VBDI':
        return 'Văn bản đi'
      case 'VBDE':
        return 'Văn bản đến'
      case 'VBNB':
        return 'Văn bản nội bộ'
      default:
        return ''
    }
  }

  const handleDownload = useCallback(
    (values, name) => {
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDODowloadFileCongVan(values, token),
        afterResponse: async response => {
          if (response) {
            FileDownload.saveAs(response, name)
          }
        },
      })
    },
    [token]
  )

  const onFileDownload = useCallback(() => {
    if (filePreview?.iDFILE) {
      const params = {
        id: filePreview?.iDFILE,
        idDv: tokenDO?.nhanVien?.idDv,
        idNv: tokenDO?.nhanVien?.idNv,
      }
      handleDownload(params, filePreview?.tENFILE)
    }
  }, [filePreview, tokenDO, handleDownload])

  const onFilePrint = useCallback(() => {
    if (filePreview?.iDFILE) {
      print({
        printable: filePreview?.filePrint,
        type: filePreview?.lOAIFILE,
        base64: true,
        onError: () => {},
      })
    }
  }, [filePreview])

  const sendToChat = async () => {
    const accessToken = auth?.token?.accessToken
    const expiresIn = auth?.token?.expiresIn
    const tokenType = auth?.token?.tokenType
    const refreshToken = auth?.token?.refreshToken
    setIsLoadingSendChat(true)
    await requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () =>
        postUploadZipFromDO({
          id_vb: documentDOInfo?.idVb,
          id_loai_vb: dataVanBan?.iDLOAIVB,
          so_ky_hieu: documentDOInfo?.kyHieu,
        }),
      afterResponse: response => {
        setIsLoadingSendChat(false)
        const fileId = response?.result?.[0]?.id
        if (fileId) {
          const url =
            FE_CHAT_URL +
            `?accessToken=${accessToken}&expiresIn=${expiresIn}&tokenType=${tokenType}&refreshToken=${refreshToken}&fileId=${fileId}`
          return window.open(url, '_blank')
        } else {
          return showToast({
            message: Toast.NOT_FOUND('file nội dung'),
            intent: Intent.WARNING,
          })
        }
      },
      afterError: () => {
        setIsLoadingSendChat(false)
        showToast({
          message: Toast.NOT_FOUND('file nội dung'),
          intent: Intent.WARNING,
        })
      },
    })
  }

  const onChangeMenu = menuKey => () => {
    if (menuKey === actionKeys.SEND_CHAT.key) {
      return sendToChat()
    }

    return setActionData(actionKeys?.[menuKey])
  }

  const goBackAction = () => {
    setActionData(null)
  }

  const onChangeFile = file => {
    if (file?.id) {
      const paramsDownLoadFile = `ID_NV=${tokenDO?.nhanVien?.idNv}&ID_DV=${tokenDO?.nhanVien?.idDv}&ID_LOAI_VB=${id}`
      const url = `${DO_DOWNLOAD_FILE}?ID_FILE=${file.id}&${paramsDownLoadFile}`
      downloadFile(url, file?.name, token?.accessToken)
    }
  }

  useEffect(() => {
    if (id && tokenDO?.token) {
      const params = {
        nvid: tokenDO?.nhanVien?.idNv,
        madv: tokenDO?.nhanVien?.idDv,
        idvb: id,
        loai: 'VBDE',
      }
      // Chi tiết
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        beforeAction: () => {
          setIsLoading(true)
        },
        action: () => getChiTietVanBanSoHoa(params, token),
        afterResponse: response => {
          if (response?.data) {
            requestAction({
              codeCheck: false,
              getResult: false,
              showToast: false,
              action: () =>
                getLoaiVanBanDO(
                  {
                    ID_VB: id,
                    ID_DV: tokenDO?.nhanVien?.idDv,
                  },
                  token
                ),
              afterResponse: res => {
                if (!isEmpty(res?.data)) {
                  setDataVanBan(res.data)
                  requestAction({
                    getResult: false,
                    codeCheck: false,
                    showToast: false,
                    getResponse: true,
                    action: () =>
                      getTenSoLoaiVanBan(
                        {
                          idSoVb: res?.data?.iDSOVB,
                          idLoaiVb: res?.data?.iDLOAIVB,
                        },
                        token
                      ),
                    afterResponse: response => {
                      setLoaiVanBan(response?.data)
                    },
                  })
                }
              },
            })
          }
        },
        afterAction: () => {
          setIsLoading(false)
        },
      })

      // Danh sách văn bản
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getListVanBan({ id_vanban: id }, token),
        afterResponse: response => {
          if (response?.data) {
            setFileDO(response?.data || [])
          }

          if (response && response?.data?.some(e => e?.fILECHINH)) {
            const fileMain = response?.data?.find(
              e =>
                e?.fILECHINH &&
                e?.lOAIFILE &&
                e?.lOAIFILE?.toUpperCase() === 'PDF'
            )
            const params = {
              id: fileMain?.iDFILE,
              idDv: tokenDO?.nhanVien?.idDv,
              idNv: tokenDO?.nhanVien?.idNv,
              idLoaiVb: null,
            }
            requestAction({
              showToast: false,
              codeCheck: false,
              getResult: false,
              action: () => getDOPreviewFileCongVan(params, token),
              afterResponse: response => {
                const fileBase64 = `data:application/pdf;base64,${response}`
                setFilePreview({
                  ...fileMain,
                  filePrint: response,
                  fileBase64: fileBase64,
                })
              },
              afterAction: () => {
                setIsLoadingFile(false)
              },
            })
          } else {
            setIsLoadingFile(false)
          }
        },
        afterError: () => {
          setIsLoadingFile(false)
        },
      })

      // Danh sách phụ lục
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDOCongVan(id),
        afterResponse: response => {
          if (response?.data) {
            setDocumentDOInfo(response?.data)
            const fileVb = response?.data?.fileVb
            const resule = fileVb.filter(item => !item.fileChinh)
            setFilePhuLuc(resule)
          }
        },
      })

      // Lấy Id dự thảo
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDuThaoId({ ID_VB: id }, token),
        afterResponse: response => {
          if (response?.data) {
            requestAction({
              showToast: false,
              codeCheck: false,
              getResult: false,
              action: () => getQuaTrinhXuLy({ ID_DT: response?.data }, token),
              afterResponse: res => {
                if (res) {
                  setDataQuaTrinhXyLy(res?.data?.[0])
                }
              },
            })
          }
        },
      })
    }
    return () => {
      requestAction({
        showToast: false,
        action: () => resetDO(),
      })
    }
    // eslint-disable-next-line
  }, [id, tokenDO?.token])

  const changeTab = value => {
    setTabActive(value)
  }

  const tabs = [
    {
      id: 'QuaTrinhXuLy',
      key: 'QuaTrinhXuLy',
      name: 'Quá trình xử lý',
      content: <QuaTrinhXuLy id={id} loaiVanBan={loaiVanBan} />,
    },
    {
      id: 'QuanLyPheDuyet',
      key: 'QuanLyPheDuyet',
      name: 'Quá trình phê duyệt',
      content: <QuanLyPheDuyet data={quaTrinhXyLy} />,
    },
  ]

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <MasterLayout typeSidebar={typeSidebar}>
      <div className="dispatch-code">
        <button
          onClick={goBack}
          type="button"
          className={Classnames('pt-button pt-button-back button-back-fixed', {
            'fixed-button-back': isMenuFixTop && window.innerWidth >= 1024,
            'fixed-button-hasSidebar':
              isMenuFixTop && window.innerWidth >= 1024,
          })}
        >
          <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
          <span className="text-content">Quay lại</span>
        </button>
        {chiTietVanBan?.kYHIEU && !isLoading && (
          <span className="number-code">
            <span className="name">{getDoiTuong(loaiVanBan?.lOAISOVB)}:</span>
            <span className="code">{chiTietVanBan?.kYHIEU}</span>
          </span>
        )}
      </div>
      <div>
        <div className="row-detail">
          <div className="row-detail__col bg-white">
            {isLoadingFile && <Loading />}
            {!isLoadingFile && !filePreview?.fileBase64 && <NotFound />}
            {!isLoadingFile && filePreview && filePreview?.fileBase64 && (
              <DetailFileContentV3
                fileWidth={794}
                file={filePreview?.fileBase64}
                onFileDownload={onFileDownload}
                onFilePrint={onFilePrint}
                onFileOrigin={onFileDownload}
              />
            )}
          </div>
          <div
            className={Classnames(
              'row-detail__col row-detail__col-right bg-white'
            )}
          >
            <div className="box-content bg-white">
              <h1
                className="font-roboto text-uppercase font-size-13 p15 border-bottom"
                style={{ color: '#9498a2' }}
              >
                {actionData && (
                  <Icon
                    style={{ color: '#000' }}
                    classIcon="pt-icon pt-icon-arrow-left mr10"
                    className={'cursor-pointer'}
                    onClick={goBackAction}
                  />
                )}
                <span style={{ color: '#9498a2' }} className="font-weight-500">
                  {actionData ? actionData?.name : 'THÔNG TIN CÔNG VĂN'}
                </span>
              </h1>
              {isLoading ? (
                <div className="loading-container">
                  <Loading />
                </div>
              ) : (
                <div className="cpc-detail font-size-13">
                  {!actionData && (
                    <div className="p15">
                      <div style={{ marginTop: -10 }}>
                        <MenuDetail
                          actionKeys={actionKeys}
                          isLoadingSendChat={isLoadingSendChat}
                          onChange={onChangeMenu}
                        />
                      </div>
                      <div className="pt10 border-top">
                        <div className="row">
                          {chiTietVanBan?.sOVB !== 0 && (
                            <div className="col-xs-6 col-sm-6 col-md-6">
                              Số văn bản:{' '}
                              <span className="font-weight-600">
                                {chiTietVanBan?.sOVB}
                              </span>
                            </div>
                          )}
                          {chiTietVanBan?.kYHIEU && (
                            <div className="col-xs-6 col-sm-6 col-md-6">
                              Số ký hiệu:{' '}
                              <span className="font-weight-600">
                                {chiTietVanBan?.kYHIEU || ''}
                              </span>
                            </div>
                          )}
                        </div>
                        <p className="font-weight-600 mt10">
                          {chiTietVanBan?.tRICHYEU || ''}
                        </p>
                        {listVanBan?.some(item => item.fILECHINH) && (
                          <div className="mt15">
                            <label className="detail-label">File văn bản</label>
                            <FileGroup
                              heightMax={200}
                              list={listVanBan
                                ?.filter?.(item => item.fILECHINH)
                                .map?.(item => ({
                                  ...item,
                                  id: item.iDFILE,
                                  name: item.tENFILE,
                                }))}
                              isLink={false}
                              onChange={onChangeFile}
                            />
                          </div>
                        )}
                        {size(filePhuLuc) !== 0 && (
                          <div className="mt15">
                            <label className="detail-label">Phụ lục</label>
                            <FileGroup
                              heightMax={200}
                              isFileSizeDisplay={false}
                              list={filePhuLuc?.map?.(item => ({
                                ...item,
                                id: item.idFile,
                                name: item.tenFile,
                              }))}
                              isLink={false}
                              onChange={onChangeFile}
                            />
                          </div>
                        )}
                      </div>
                      <div className="border-top font-size-14 mt10">
                        <div className="row">
                          <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                            <label className="detail-label">
                              Ngày văn bản:
                            </label>
                            <p className="detail-text">
                              {chiTietVanBan?.nGAYVB
                                ? moment(chiTietVanBan?.nGAYVB)
                                    .subtract(7, 'hours')
                                    .format('DD/MM/YYYY')
                                : ''}
                            </p>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                            <label className="detail-label">Ngày nhận:</label>
                            <p className="detail-text">
                              {dataVanBan?.nGAYNHAN
                                ? moment(dataVanBan?.nGAYNHAN)
                                    .subtract(7, 'hours')
                                    .format('DD/MM/YYYY HH:mm')
                                : ''}
                            </p>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                            <label className="detail-label">Đối tượng:</label>
                            <p className="detail-text">
                              {getDoiTuong(loaiVanBan?.lOAISOVB)}
                            </p>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                            <label className="detail-label">
                              Cơ quan ban hành:
                            </label>
                            <p className="detail-text">
                              {chiTietVanBan?.nOIBANHANH || ''}
                            </p>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                            <label className="detail-label">Sổ văn bản:</label>
                            <p className="detail-text">
                              {loaiVanBan?.lOAISOVB || ''}
                            </p>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                            <label className="detail-label">
                              Loại văn bản:
                            </label>
                            <p className="detail-text">
                              {loaiVanBan?.tENLOAIVB || ''}
                            </p>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                            <label className="detail-label">Độ khẩn:</label>
                            <p className="detail-text">
                              {getTextDoKhan(chiTietVanBan?.mADK)}
                            </p>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 mt15">
                            <label className="detail-label">Độ mật:</label>
                            <p className="detail-text">
                              {getTextDoKhan(chiTietVanBan?.mADM)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {actionData && (
                    <Scrollbar maxHeight={window.innerHeight - 190}>
                      {actionData?.key === actionKeys.FLOW_TASK.key && (
                        <div className="p15">
                          <Tabs2
                            className="tabs-custom"
                            contentBordered
                            scrollable
                            selectedTabId={tabActive}
                            onChange={changeTab}
                          >
                            {tabs.map(tab => (
                              <Tab2
                                key={tab.id}
                                id={tab.id}
                                title={tab.name}
                                panel={tab.content}
                              />
                            ))}
                          </Tabs2>
                        </div>
                      )}
                      {actionData?.key === actionKeys.PERFORM_THE_WORK.key && (
                        <PerformTheWork
                          match={match}
                          fileDO={fileDO}
                          documentInfo={documentDOInfo}
                          onClose={goBackAction}
                        />
                      )}
                      {actionData?.key === actionKeys.GIVE_TASK.key && (
                        <GiveTask
                          match={match}
                          fileDO={fileDO}
                          documentInfo={documentDOInfo}
                          onClose={goBackAction}
                        />
                      )}
                      {actionData?.key === actionKeys.SEND_EMAIL.key && (
                        <SendMail
                          match={match}
                          documentInfo={
                            {
                              idVb: documentDOInfo?.idVb,
                              loaiVb: dataVanBan?.iDLOAIVB,
                              kyHieu: documentDOInfo?.kyHieu,
                              files: [],
                              fileDO,
                            } ?? null
                          }
                          onClose={goBackAction}
                        />
                      )}
                    </Scrollbar>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  auth: state?.auth,
  typeSidebar: state?.common?.typeSidebar,
  tokenDO: state?.doffice?.toKenDO,
  chiTietVanBan: state?.doffice?.chiTietVanBan,
  listVanBan: state?.doffice?.listVanBan,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChiTietCongVan)
