import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

class ListFilterStatistic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      tuNgay: undefined,
      denNgay: undefined,
    }
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  clearFilter(e) {
    this.setState(
      {
        searchText: '',
        tuNgay: undefined,
        denNgay: undefined,
      },
      () => {
        this.props.searchSubmit && this.props.searchSubmit(this.state)
      }
    )
  }

  handleDateChange(name, e) {
    this.setState(
      {
        ...this.state,
        [name]: e,
      },
      () => {
        this.props.searchSubmit && this.props.searchSubmit(this.state)
      }
    )
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({
      ...this.state,
      [name]: value,
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const params = {
      searchText: this.state.searchText,
      tuNgay: this.state.tuNgay || undefined,
      denNgay: this.state.denNgay || undefined,
    }
    this.props.searchSubmit && this.props.searchSubmit(params)
  }

  removeTextSearch(name) {
    this.setState({
      ...this.state,
      [name]: '',
    })
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch pt15 pb20 px-sm-15"
      >
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="row-picker-range">
              <div className="col-picker-range">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.tuNgay}
                  selectsStart
                  startDate={this.state.tuNgay}
                  endDate={this.state.denNgay}
                  onChange={this.handleDateChange.bind(this, 'tuNgay')}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent"
                  placeholderText="Từ ngày"
                  maxDate={this.state.denNgay}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
              <div className="col-picker-range">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.denNgay}
                  selectsEnd
                  startDate={this.state.tuNgay}
                  endDate={this.state.denNgay}
                  onChange={this.handleDateChange.bind(this, 'denNgay')}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent"
                  placeholderText="Đến ngày"
                  minDate={this.state.tuNgay}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-8 mt20 mt-md-0 align-items-end">
            <div className="form-input-search flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                name="searchText"
                className="input border-transparent pr-40 input-search-filter"
                type="text"
                placeholder="Tìm kiếm nội dung"
                onChange={this.handleInputChange}
                value={this.state.searchText}
                maxLength={255}
              />
              {this.state.searchText && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'searchText')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              type="button"
              className="btn-icon flex-solid ml10 pt-intent-reload"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

ListFilterStatistic.propTypes = {
  onSubmit: PropTypes.func,
}

export default ListFilterStatistic
