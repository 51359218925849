import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Authorization, Permission } from '../../auth'

import Pagination from 'components/Pagination'

class ListFooter extends Component {
  static propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
  }

  render() {
    const AuthElement = Authorization.Element

    const { current, total, onChange } = this.props

    return (
      <div className="footer-list">
        <AuthElement permission={[Permission.CONGVANDEN_QUANLY]}>
          <div
            className={classnames('note-issues', {
              'note-issues-none': !!total,
            })}
          >
            <span className="name-note">Chú Thích</span>
            <ul className="list-note">
              <li className="note-item">
                <span className="pt-icon-star pt-icon check-star"></span>Công
                văn mới
              </li>
            </ul>
          </div>
        </AuthElement>

        <Pagination current={current} total={total} onChange={onChange} />
      </div>
    )
  }
}

export default ListFooter
