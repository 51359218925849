import React, { memo, useEffect, useRef, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Classes, Checkbox, Button, Intent } from '@blueprintjs/core'
import { get, isEqual, size, unionBy } from 'lodash'
import csx from 'classnames'

import { Permission } from 'components/auth'
import { Icon } from 'components/newCommon'
import { Input, UserTree } from 'components/newCommon2'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import { ASSIGN_TYPE } from 'constants/Enum'
import {
  getCayPhongBan,
  getDsNhanVien,
  getFavoriteGroup,
  postFavoriteGroup,
  putFavoriteGroup,
} from 'actions/task'

const FavoriteGroupForm = ({
  match,
  hasShowAll,
  mainDepartmentId,
  mainDepartmentInfo,
  onClose = () => {},
  reloadList = () => {},
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const { action, id } = match.params
  const isUpdate = action === 'chinh-sua'
  const [validateErrors, setValidateErrors] = useState({})
  const [departments, setDepartments] = useState([])
  const [departmentFetched, setDepartmentFetched] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [formData, setFormData] = useState({})

  const changeCheckbox = e => {
    const { checked } = e.target
    _mountedSet(setFormData, { ...formData, isActive: checked })
  }

  const changeField = name => value => {
    _changeFormValue({ name, value })
  }

  const _changeFormValue = useCallback(({ name, value, error }) => {
    _mountedSet(setFormData, prevFormData => ({
      ...prevFormData,
      [name]: {
        value,
        error,
      },
    }))
  }, [])

  const _fetchDepartments = useCallback(() => {
    requestAction({
      showToast: false,
      successCode: 0,
      action: getCayPhongBan,
      afterResponse: (list = []) =>
        _mountedSet(
          setDepartments,
          list.map(phongBan => ({
            value: {
              id: get(phongBan, 'phongBanId'),
              type: ASSIGN_TYPE.PHONG_BAN,
              departmentId: get(phongBan, 'phongBanId'),
              departmentShortname: get(phongBan, 'maPhongBan'),
              departmentName: get(phongBan, 'tenPhongBan'),
            },
            label: get(phongBan, 'maPhongBan'),
            children: [],
          }))
        ),
    })
  }, [])

  const convertEmployee = useCallback(
    employee => ({
      showAvatar: true,
      value: {
        id: get(employee, 'chucDanhId'),
        type: ASSIGN_TYPE.CA_NHAN,
        label: get(employee, 'tenNhanVien'),
        avatar: get(employee, 'anhDaiDien'),
        departmentId: get(employee, 'departmentId'),
        departmentShortname: get(employee, 'departmentShortname'),
        departmentName: get(employee, 'departmentName'),
      },
      label: get(employee, 'tenNhanVien'),
      avatar: get(employee, 'anhDaiDien'),
    }),
    []
  )

  const _fetchUsers = useCallback(
    async ({ id: departmentId, type, departmentShortname, departmentName }) => {
      if (
        departmentFetched.includes(departmentId) ||
        type !== ASSIGN_TYPE.PHONG_BAN
      )
        return
      let users = []
      await requestAction({
        showToast: false,
        action: () => getDsNhanVien(departmentId),
        afterResponse: ({ items = [] }) => {
          _mountedSet(setDepartmentFetched, oldList => [
            ...oldList,
            departmentId,
          ])
          users = items
            .map(elm => ({
              ...elm,
              departmentId: departmentId,
              departmentShortname: departmentShortname,
              departmentName: departmentName,
            }))
            .map(convertEmployee)
          _mountedSet(setDepartments, oldList =>
            hasShowAll
              ? oldList.map(department =>
                  get(department, 'value.id') === departmentId
                    ? {
                        ...department,
                        children: users,
                      }
                    : department
                )
              : users
          )
        },
      })
      return users
    },
    [departmentFetched, convertEmployee, hasShowAll]
  )

  const taskFetchUsers = async department => {
    if (
      departmentFetched.includes(department.id) ||
      department.type !== ASSIGN_TYPE.PHONG_BAN
    )
      return

    const users = await _fetchUsers(department)
    _mountedSet(setFormData, prevForm => ({
      ...prevForm,
      members: {
        ...get(prevForm, 'members'),
        value: get(prevForm, 'members.value', []).reduce((result, member) => {
          if (isEqual(member, department)) {
            return [...result, ...(users || []).map(user => user.value)]
          }

          return [...result, member]
        }, []),
      },
    }))
    return users
  }

  const _fetchDetail = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => _mountedSet(setFetching, true),
      action: () => getFavoriteGroup(id),
      afterResponse: result => {
        _mountedSet(setFormData, {
          name: {
            value: get(result, 'name', ''),
          },
          members: {
            value: size(get(result, 'members', []))
              ? result.members.map(elm => {
                  return {
                    id: get(elm, 'id'),
                    type: ASSIGN_TYPE.CA_NHAN,
                    label: get(elm, 'name'),
                    avatar: get(elm, 'avatar'),
                    departmentId: get(elm, 'departmentId'),
                    departmentShortname: get(elm, 'departmentShortname'),
                    departmentName: get(elm, 'departmentName'),
                  }
                })
              : [],
          },
          isActive: get(result, 'isActive'),
          membersLabel: size(get(result, 'members', []))
            ? result.members.map(member => get(member, 'name')).join(', ')
            : '',
        })
      },
      afterAction: () => _mountedSet(setFetching, false),
    })
  }, [id])

  const _validateForm = () => {
    let newValidateErrors = {}
    let name = false
    let members = false
    if (!get(formData, 'name.value', '').trim()) {
      name = true
    }

    if (!size(get(formData, 'members.value', []))) {
      members = true
    }

    newValidateErrors = {
      name,
      members,
    }
    _mountedSet(setValidateErrors, newValidateErrors)

    if (name || members) {
      throw new Error('warning')
    }
  }

  const submitCreate = () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setSubmitLoading, true)
        _validateForm()
      },
      action: () => {
        const params = {
          name: get(formData, 'name.value').trim(),
          members: size(get(formData, 'members.value', []))
            ? unionBy(
                formData.members.value.map(elm => {
                  return {
                    id: elm.id,
                    name: elm.label,
                    avatar: elm.avatar,
                    department_id: elm.departmentId,
                    department_shortname: elm.departmentShortname,
                    department_name: elm.departmentName,
                  }
                }),
                'id'
              )
            : [],
          isActive: get(formData, 'isActive'),
        }
        return postFavoriteGroup(toDecamelizeKeys(params))
      },
      afterResponse: () => {
        reloadList()
        onClose()
      },
      afterAction: () => _mountedSet(setSubmitLoading, false),
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.CREATE_FAVORITE),
      errorMessage: Toast.FAIL(Action.CREATE_FAVORITE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const submitUpdate = () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setSubmitLoading, true)
        _validateForm()
      },
      action: () => {
        const params = {
          id: id,
          name: get(formData, 'name.value').trim(),
          members: size(get(formData, 'members.value', []))
            ? unionBy(
                formData.members.value.map(elm => {
                  return {
                    id: elm.id,
                    name: elm.label,
                    avatar: elm.avatar,
                    department_id: elm.departmentId,
                    department_shortname: elm.departmentShortname,
                    department_name: elm.departmentName,
                  }
                }),
                'id'
              )
            : [],
          isActive: get(formData, 'isActive'),
        }
        return putFavoriteGroup(toDecamelizeKeys(params))
      },
      afterResponse: () => {
        reloadList()
        onClose()
      },
      afterAction: () => _mountedSet(setSubmitLoading, false),
      successCode: 200,
      successMessage: Toast.SUCCESS(Action.UPDATE_FAVORITE),
      errorMessage: Toast.FAIL(Action.UPDATE_FAVORITE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  useEffect(() => {
    if (hasShowAll) {
      _fetchDepartments()
    }
  }, [_fetchDepartments, hasShowAll])

  useEffect(() => {
    if (!hasShowAll) {
      _fetchUsers({
        id: mainDepartmentId,
        type: ASSIGN_TYPE.PHONG_BAN,
        departmentId: get(mainDepartmentInfo, '[0].phongBanId'),
        departmentShortname: get(mainDepartmentInfo, '[0].maPhongBan'),
        departmentName: get(mainDepartmentInfo, '[0].tenPhongBan'),
      })
    }
  }, [_fetchUsers, hasShowAll, mainDepartmentId, mainDepartmentInfo])

  useEffect(() => {
    if (isUpdate) {
      _fetchDetail()
    }
  }, [_fetchDetail, isUpdate])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <div className={csx('cpc-side-action', 'open', 'cpc-form')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>
          {isUpdate
            ? 'Chỉnh sửa nhóm thường giao việc'
            : 'Tạo nhóm thường giao việc'}
        </span>
        <Icon
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          onClick={onClose}
        />
      </h1>
      <main style={{ padding: 10 }} className={csx('border-bottom', 'mb10')}>
        <Input
          disabled={false}
          label="Tên nhóm thường giao việc"
          maxLength={500}
          onChange={changeField('name')}
          placeholder="Nhập tên nhóm thường giao việc"
          required
          value={get(formData, 'name.value', '')}
          wrapperClassName="mb10"
          warning={get(validateErrors, 'name')}
        />
        <UserTree
          inputWrapperClassName="mb10"
          dataSource={departments}
          defaultLabel={get(formData, 'membersLabel')}
          onChange={(list, selectValue) => {
            changeField('members')(list)
            if (selectValue) {
              taskFetchUsers(selectValue)
            }
          }}
          onShowCollapse={taskFetchUsers}
          multiple
          mainOnly
          allowSelectAll={!hasShowAll}
          placeholder="Chọn người trong nhóm"
          value={get(formData, 'members.value', [])}
          inputWarning={get(validateErrors, 'members')}
        />
        <Checkbox
          className={Classes.SMALL}
          checked={get(formData, 'isActive', false)}
          disabled={false}
          onChange={changeCheckbox}
        >
          <span className="font-size-13">Sử dụng</span>
        </Checkbox>
      </main>
      <div className="element-center">
        <Button
          className={csx(
            'cpc-button',
            'btn-cancel',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-100',
            'mr15'
          )}
          disabled={submitLoading || fetching}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={csx('cpc-button', 'uppercase', 'font-size-13', 'ph10')}
          disabled={submitLoading || fetching}
          intent={Intent.PRIMARY}
          onClick={isUpdate ? submitUpdate : submitCreate}
          loading={submitLoading}
        >
          {'Lưu'}
          <Icon classIcon="icon-save" className="ml5" />
        </Button>
      </div>
    </div>
  )
}

FavoriteGroupForm.propTypes = {
  hasShowAll: PropTypes.bool,
  mainDepartmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const mapStateToProps = state => ({
  hasShowAll: get(state, 'auth.permission', []).includes(
    Permission.CONGVANDEN_CHIDAO
  ),
  mainDepartmentId: get(state, 'auth.mainDepartmentId'),
  mainDepartmentInfo: get(state, 'auth.user.dsChucDanh', [])
    .filter(elm => elm.phongBanChinh)
    .map(elm => elm.phongBan),
})

export default withRouter(connect(mapStateToProps)(memo(FavoriteGroupForm)))
