import React from 'react'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { InputForm } from '../../components/RequestComment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as Actions from '../../actions/'
import withRouter from 'react-router/withRouter'
import * as MESSAGE from '../../constants/MessageForm'
import * as Types from '../../constants/Api'
import _ from 'lodash'

class TaoPhieuYeuCauGopY extends React.Component {
  static propTypes = {
    departments: PropTypes.object.isRequired,
    attachedUnits: PropTypes.object.isRequired,
    signNumberDepartmentLeaders: PropTypes.object.isRequired,
    personalLeaderSignNumber: PropTypes.object.isRequired,
    signNumber: PropTypes.object,
    auth: PropTypes.object.isRequired,
    unitItem: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getUploadProgress = this.getUploadProgress.bind(this)

    this.state = {
      mainDepartmentId: this.props.auth
        ? this.props.auth.mainDepartmentId
        : null,
      parentUnitId: this.props.auth ? this.props.auth.parentUnitId : null,
      mainUnitId: this.props.auth ? this.props.auth.mainUnitId : null,
      signNumberId: '',
      isUpdate: false,
      isLoadingData: {
        departments: true,
        attachedUnits: true,
        parentDepartments: true,
        lanhDaoDonViId: true,
        lanhDaoPhongBanChuTriId: true,
        requestComments: true,
        // lanhDaoDonViCapTrenId: this.props.auth && this.props.auth.parentUnitId ? true : false,
        electricUnits: true,
      },
      isCanUpdate: true,
      isSubmitFail: false,
      requestComments: null,
      contentFileProgress: [0],
      attachFilesProgress: [0],
      dinhKemFilesProgress: [0],
      isCanChoseSignNumberRelativeUnit: false,
      isCanGetLeaderDepartment: false,
      isPc: false,
    }
  }

  componentWillMount() {
    let state = this.state
    if (this.props.match.path === '/tien-ich/lay-y-kien-don-vi/chinh-sua/:id') {
      state.signNumberId = this.props.match.params.id
      state.isUpdate = true
      state.isLoadingData = {
        ...state.isLoadingData,
      }
    } else {
      state.isLoadingData = {
        ...state.isLoadingData,
        requestComments: false,
      }
    }
    this.setState(state)
  }

  getUploadProgress = (name, i) => progress => {
    let state = this.state
    if (!isNaN(i) && name) {
      state[name][i] = Math.floor((progress.loaded * 100) / progress.total)
    }
    this.setState(state)
  }

  async componentDidMount() {
    // phong ban phoi hop cua don vi cap tren
    this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        parentDepartments: false,
      },
    })
    if (this.state.mainUnitId) {
      //get ds lanh dao
      await this.props.actions
        .commonSignNumberDepartmentLeader(this.state.mainUnitId)
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              lanhDaoDonViId: false,
            },
          })
        })
      await this.props.actions
        .getCommonFetchUnitItem(this.state.mainUnitId)
        .then(async res => {
          let isCanChoseSignNumberRelativeUnit =
            this.state.isCanChoseSignNumberRelativeUnit
          let isCanGetLeaderDepartment = Types.TYPE_DEPARTMENT
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            isCanChoseSignNumberRelativeUnit =
              res.payload.data.result.kySoLienDonVi
          }
          await this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              tenThanhPho: false,
              electricUnits: false,
            },
            isCanChoseSignNumberRelativeUnit,
            isCanGetLeaderDepartment,
          })
          // get ds phong ban gop y
          await this.props.actions
            .commonFetchDepartments(
              Types.PAGE,
              null,
              false,
              Types.TYPE_COMMON_DEPARTMENT
            )
            .finally(async () => {
              await this.setState({
                isLoadingData: {
                  ...this.state.isLoadingData,
                  departments: false,
                },
              })
            })
          // get ds don vi gop ys
          await this.props.actions
            .commonFetchAttachedUnits(this.props.auth.mainUnitId)
            .finally(async () => {
              await this.setState({
                isLoadingData: {
                  ...this.state.isLoadingData,
                  attachedUnits: false,
                },
              })
            })
        })
    }

    //get lanh dao phong ban
    if (this.state.mainDepartmentId) {
      await this.props.actions
        .commonPersonalLeaderSignNumber(this.state.mainDepartmentId)
        .then(async () => {
          await this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              lanhDaoPhongBanChuTriId: false,
            },
          })
        })
    }
    if (
      this.state.signNumberId &&
      this.props.match.path === '/tien-ich/lay-y-kien-don-vi/chinh-sua/:id'
    ) {
      return this.props.actions
        .requestCommentDetail(this.state.signNumberId)
        .then(() => {
          let state = this.state
          if (this.props.requestComments && this.props.requestComments.item) {
            state.requestComments = this.props.requestComments.item
            state.isLoadingData.requestComments = false
          }
          this.setState(state)
        })
    }
  }

  handleSubmit = async (data, isValidData, message, isUpdate) => {
    if (!isValidData) {
      if (message) {
        return this.props.actions.commonAddToasterMessage({
          message: message,
          intent: Intent.WARNING,
        })
      }
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    await this.setState({ isSubmitFail: false })
    let contentFile = data.fileNoiDung
    let attachFiles = data.fileThamKhao.filter(s => !!s)
    let dinhKemFiles = data.dsFileDinhKem.filter(s => !!s)
    let attachFilesProgress = []
    let dinhKemFilesProgress = []
    if (data.files.contentFiles.length) {
      try {
        await this.props.actions
          .fileUpload(
            data.files.contentFiles,
            this.getUploadProgress('contentFileProgress', 0)
          )
          .then(res => {
            contentFile = {
              file_id: res.payload.data.result[0].id,
              kieu_file: res.payload.data.result[0].extension,
              ten_file: res.payload.data.result[0].originalName,
              url: res.payload.data.result[0].path,
              kich_thuoc: res.payload.data.result[0].length,
            }
          })
      } catch (e) {
        this.setState({ isSubmitFail: true })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    }

    delete data.files.contentFile

    if (data.files.attachFiles.length) {
      attachFilesProgress = Array(data.files.attachFiles.length).fill(0)
      await this.setState({ attachFilesProgress })
      try {
        await Promise.all(
          data.files.attachFiles.map(async (item, i) => {
            if (!!item) {
              const data = await this.props.actions.fileUpload(
                item,
                this.getUploadProgress('attachFilesProgress', i)
              )
              return attachFiles.push({
                file_id: data.payload.data.result[0].id,
                kieu_file: data.payload.data.result[0].extension,
                ten_file: data.payload.data.result[0].originalName,
                url: data.payload.data.result[0].path,
                kich_thuoc: data.payload.data.result[0].length,
                is_existed: false,
              })
            }
          })
        )
      } catch (e) {
        this.setState({ isSubmitFail: true })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    }

    delete data.files.attachFiles
    if (data.files.dinhKemFiles.length) {
      dinhKemFilesProgress = Array(data.files.dinhKemFiles.length).fill(0)
      await this.setState({ dinhKemFilesProgress })
      try {
        await Promise.all(
          data.files.dinhKemFiles.map(async (item, i) => {
            if (!!item) {
              const data = await this.props.actions.fileUpload(
                item,
                this.getUploadProgress('dinhKemFilesProgress', i)
              )
              return dinhKemFiles.push({
                file_id: data.payload.data.result[0].id,
                kieu_file: data.payload.data.result[0].extension,
                ten_file: data.payload.data.result[0].originalName,
                url: data.payload.data.result[0].path,
                kich_thuoc: data.payload.data.result[0].length,
                is_existed: false,
              })
            }
          })
        )
      } catch (e) {
        this.setState({ isSubmitFail: true })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    }
    delete data.files.dinhKemFiles

    let dataRequest = {
      so_ky_hieu: data.soKyHieu,
      thoi_han: data.thoiHan,
      trich_yeu: data.trichYeu,
      noi_dung_yeu_cau: data.noiDungYeuCau,
      file_noi_dung: contentFile,
      ds_file_dinh_kem: dinhKemFiles,
      ds_file_tham_khao: attachFiles,
      lanh_dao_phong_ban_lap: data.lanhDaoPhongBanLap,
      lanh_dao_don_vi: _.isEmpty(data.lanhDaoDonVi) ? null : data.lanhDaoDonVi,
      phong_ban_nhan_ids: data.phongBanNhanIds,
      don_vi_nhan_ids: data.donviNhanIds,
    }

    if (!isUpdate) {
      if (this.props.match.path === '/tien-ich/lay-y-kien-don-vi/them-moi') {
        this.props.actions
          .requestCommentCreate(dataRequest)
          .then(res => {
            if (res.error) {
              this.setState({ isSubmitFail: true })
              let error = MESSAGE.TOATS_MESSAGE_FAIL
              if (
                res.error &&
                res.error.response &&
                res.error.response.data &&
                res.error.response.data.message
              ) {
                error = res.error.response.data.message
              }
              return this.props.actions.commonAddToasterMessage({
                message: error,
                intent: Intent.DANGER,
              })
            }
            this.setState({ signNumber: null })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            // Refresh danh sach ky so di
            this.props.actions.refreshSendSignNumber(true)
            this.props.history.push('/tien-ich/lay-y-kien-don-vi/danh-sach-goi')
          })
          .catch(err => {
            this.setState({ isSubmitFail: true })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          })
      }
    } else {
      if (
        this.props.match.path.indexOf(
          '/tien-ich/lay-y-kien-don-vi/chinh-sua'
        ) === 0
      ) {
        this.props.actions
          .requestCommentUpdate(this.state.signNumberId, dataRequest)
          .then(res => {
            if (res.error) {
              this.setState({ isSubmitFail: true })
              let error = MESSAGE.TOATS_MESSAGE_FAIL
              if (
                res.error &&
                res.error.response &&
                res.error.response.data &&
                res.error.response.data.message
              ) {
                error = res.error.response.data.message
              }
              return this.props.actions.commonAddToasterMessage({
                message: error,
                intent: Intent.DANGER,
              })
            }
            this.setState({ signNumber: null })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            this.props.history.goBack()
          })
          .catch(err => {
            this.setState({ isSubmitFail: true })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          })
      }
    }
  }

  render() {
    const location = this.props.location.pathname
    const { isCanUpdate, isUpdate } = this.state

    if (isCanUpdate === false && isUpdate === true) {
      return null
    }

    return (
      <MasterLayout typeSidebar="utilities" collapseSideBar={true}>
        <div className="row center-xs format-center">
          <div className="col-md-8 col-xs-12 col-sm-12 padding-0 form-width-90">
            <div className="row">
              <div className="col-xs-12">
                <div className="heading-page bottom-add-receive-document">
                  <h3 className="pull-left text-heading-page">
                    <span className="icon-rounded-add-button1 icon-heading"></span>
                    <span className="text-heading">
                      {location === '/tien-ich/lay-y-kien-don-vi/them-moi' &&
                        'Tạo phiếu yêu cầu góp ý'}
                      {location.indexOf(
                        '/tien-ich/lay-y-kien-don-vi/chinh-sua'
                      ) === 0 && 'Sửa phiếu yêu cầu góp ý'}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <InputForm
              departments={this.props.departments.items}
              attachedUnits={this.props.attachedUnits.items}
              departmentSigned={this.props.personalLeaderSignNumber.items}
              unitsLeader={this.props.signNumberDepartmentLeaders.items}
              onSubmit={this.handleSubmit}
              mainDepartmentId={this.state.mainDepartmentId}
              mainUnitId={this.state.mainUnitId}
              signNumber={this.state.signNumber}
              requestComments={this.state.requestComments}
              isUpdate={this.state.isUpdate}
              isLoadingData={this.state.isLoadingData}
              isSubmitFail={this.state.isSubmitFail}
              type={this.state.type}
              parentUnitId={this.state.parentUnitId}
              parentDepartments={this.props.parentDepartments.items}
              parentUnitsLeaders={
                this.props.parentSignNumberDepartmentLeaders.items
              }
              contentFileProgress={this.state.contentFileProgress}
              attachFilesProgress={this.state.attachFilesProgress}
              dinhKemFilesProgress={this.state.dinhKemFilesProgress}
              isCanChoseSignNumberRelativeUnit={
                this.state.isCanChoseSignNumberRelativeUnit
              }
              isCanGetLeaderDepartment={
                this.state.isCanGetLeaderDepartment !==
                Types.TYPE_COMMON_DEPARTMENT
              }
              isPc={this.state.isPc}
              electricUnits={this.props.electricUnits}
              actions={this.props.actions}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },

  departments: {
    items: state.common.departments,
  },
  attachedUnits: {
    items: state.common.attachedUnits,
  },

  parentDepartments: {
    items: state.common.parentDepartments,
  },

  signNumberDepartmentLeaders: {
    items: state.common.signNumberDepartmentLeaders,
  },

  parentSignNumberDepartmentLeaders: {
    items: state.common.differentSignNumberDepartmentLeaders,
  },

  personalLeaderSignNumber: {
    ...state.personalLeaderSignNumber,
    items: state.common.personalLeaderSignNumber,
  },

  requestComments: {
    ...state.requestComments,
    item: state.requestComments.detailId
      ? state.entities.requestComments[state.requestComments.detailId]
      : {},
    listFeedbacks: {
      ...state.requestComments.listFeedbacks,
    },
  },

  unitItem: state.common.unitItem,

  electricUnits: state.common.electricUnits,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaoPhieuYeuCauGopY)
)
