import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Intent } from '@blueprintjs/core'
import { Permission, Authorization } from '../../components/auth'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { SaveToDocumentCabinet } from '../../components/Utilities/DocumentarySearch'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import { get } from 'lodash'

const cabinetTypes = {
  [Types.CABINET_UNIT]: 'unit',
  [Types.CABINET_DEPARTMENT]: 'department',
  [Types.HOSO_CONGVIEC]: 'profile',
  [Types.CABINET_PERSONAL]: 'personal',
}
class SaveFileDocumentsCabinet extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    if (
      this.props.location &&
      this.props.location.state &&
      !this.props.location.state.dsFileCongVan
    ) {
      return this.props.history.push('/tien-ich/tim-kiem-cong-van/danh-sach')
    }

    this.state = {
      ids: this.props.location.state.dsFileCongVan.map(item => item.file_id),
      dsFileCongVan: this.props.location.state.dsFileCongVan,
      type: this.props.auth.defaultCabinetType,
      saveTo: [
        ...(Authorization.vaild(this.props.auth.permission, [
          Permission.TUTAILIEUDONVI_QUANLY,
        ])
          ? [
              {
                id: Types.CABINET_UNIT,
                name: 'Tủ đơn vị',
                disabled: Authorization.vaild(this.props.auth.permission, [
                  Permission.TUTAILIEUDONVI_QUANLY,
                ])
                  ? false
                  : true,
              },
            ]
          : []),
        {
          id: Types.CABINET_DEPARTMENT,
          name: 'Tủ phòng ban',
        },
        {
          id: Types.HOSO_CONGVIEC,
          name: 'Hồ sơ công việc',
          disabled: !Authorization.vaild(this.props.auth.permission, [
            Permission.TUTAILIEUPHONGBAN,
          ]),
        },
        {
          id: Types.CABINET_PERSONAL,
          name: 'Tủ cá nhân',
        },
      ],
    }

    this.handleChangeType = this.handleChangeType.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.updateItem()
    this.updateCategories()
  }

  handleGoBack() {
    if (this.props.location.isGoBack) {
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/thong-ke-tu-tai-lieu/chi-tiet/phong-ban/${this.props.location.phongBanId}`,
          state: {
            ngayGiaoViec: {
              from: this.props.location.start_date,
              to: this.props.location.end_date,
            },
          },
        })
      )
    }

    this.props.history.goBack()
  }

  updateItem() {
    this.props.actions.getListFileInfoOfDocuments(this.state.ids)
  }

  updateCategories() {
    this.props.actions.setCabinetType(this.state.type)
    switch (this.state.type) {
      case Types.CABINET_PERSONAL:
        this.props.actions.documentsCabinetGetListCategoriesPersonal()
        break
      case Types.CABINET_DEPARTMENT:
        this.props.actions.documentsCabinetGetListCategoriesDepartment()
        break
      case Types.HOSO_CONGVIEC:
        const params = {
          loai: 1,
          phanLoaiTu: 'PHONGBAN',
        }
        this.props.actions.getLoaiLuuTaiLieu(params)
        break
      default:
        this.props.actions.documentsCabinetGetListCategoriesUnit()
    }
  }

  handleChangeType(type) {
    this.setState({ type }, () => {
      this.updateCategories()
    })
  }

  handleSubmit(err, data) {
    if (err) {
      return this.props.actions.commonAddToasterMessage({
        message: err,
        intent: Intent.DANGER,
      })
    }

    data = Object.assign(
      {},
      {
        ds_file_cong_van: this.state.dsFileCongVan,
      },
      Tool.Utils.linkObjectKey(data)
    )
    return this.props.actions
      .documentsCabinetSaveReceiveDocument(data)
      .then(res => {
        if (res && res.error) {
          const errorString = res.error.response.data.message
          return this.props.actions.commonAddToasterMessage({
            message: errorString || MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }
        if (res && res.payload && res.payload.status === 200) {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          this.props.actions.refreshPage(true)
          this.handleGoBack()
        }
      })
  }

  render() {
    const data =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.listCabinetOfDocument
        ? this.props.location.state.listCabinetOfDocument
        : []
    let listCabinetOfDocument = data.map(item => item.id)
    const { archives, auth } = this.props
    const cabinetCategories = get(
      archives,
      `type[${
        cabinetTypes[get(auth, 'defaultCabinetType', Types.HOSO_CONGVIEC)]
      }]`
    )

    return (
      <MasterLayout typeSidebar="documentsCabinet">
        <div className="detail-receive-document page-DetailDocumentsCabinet">
          <HeadingPage namePage="Tủ tài liệu" iconPage="icon-Tu_Tai_Lieu" />
          <div className="detail-container">
            <SaveToDocumentCabinet
              files={this.props.archives.fileItems}
              storage={this.props.archives.storage}
              onClickGoBack={this.handleGoBack}
              archives={cabinetCategories}
              saveTo={this.state.saveTo}
              onSelectSaveTo={this.handleChangeType}
              onSubmit={this.handleSubmit}
              receiveDocument={
                this.state.receiveDocument ? this.state.receiveDocument : {}
              }
              listCabinetOfDocument={listCabinetOfDocument}
              defaultType={this.state.type}
              auth={this.props.auth}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  archives: {
    ...state.documentsCabinet,
    categories: state.documentsCabinet.categories.map(
      id => state.entities.documentCabinetCategories[id]
    ),
    type: {
      unit: state.documentsCabinet.type.unit.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      department: state.documentsCabinet.type.department.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      personal: state.documentsCabinet.type.personal.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      profile: state.documentsCabinet.type.profile.map(
        id => state.entities.documentCabinetCategories[id]
      ),
    },
    fileItems: state.documentsCabinet.fileItems.map(id => {
      let file = state.entities.files[id]

      if (file) {
        file.kichThuocStr = Tool.Utils.getFileSize(file.kichThuoc)
        file.icon = Tool.Utils.getFileLogo(file)
      }

      return file
    }),
    storage: {
      ...state.documentsCabinet.storage,
      used: Tool.Utils.getFileSize(state.documentsCabinet.storage.used),
    },
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveFileDocumentsCabinet)
