import React, { memo, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Classes, Button } from '@blueprintjs/core'
import size from 'lodash/size'
import uniq from 'lodash/uniq'

import Fragment from 'components/common/Fragment'
import { Collapse, Icon, Scrollbar, Loading } from 'components/newCommon'

const warningStyles = {
  borderColor: '#c02e2e',
}

const PhongBanVaDonViThucHien = ({
  headStyle = null,
  label = 'Phòng ban/đơn vị thực hiện',
  column,
  data,
  dataFavorite,
  isDisplayDefault,
  className,
  taskId,
  dataAutoToggle,
  warning,
  isNoTree,
  isDisplayFavoriteGroup = false,
  isRequired = false,
  isHideChild = false,
  isLoading = false,
  dataDisplayChild = [],
  onClick,
}) => {
  const ALL = 'ALL'
  const PHONG_BAN = 'DEPARTMENT'
  const DON_VI = 'ORGANIZATION'
  const [isCollapse, setIsCollapse] = useState(true)
  const [activeTabKey, setActiveTabKey] = useState('DEPARTMENT_ORGANIZATION')
  const [dataToggle, setDataToggle] = useState([])
  const toggleItem = (id, isCheckShow) => {
    const isHasItem = dataToggle?.findIndex(elm => elm === id)
    if (isHasItem === -1) {
      onClick && onClick(id)
    }

    setDataToggle(prev => [
      ...(prev?.filter(elm => elm !== id) || []),
      ...(!isCheckShow ? [id] : []),
    ])
  }

  useEffect(() => {
    if (size(dataAutoToggle) !== 0) {
      setDataToggle(prev => uniq([...prev, ...dataAutoToggle]))
    }
  }, [dataAutoToggle, setDataToggle])

  const renderItem = (data, index, indexChild, level) => {
    const type = data?.type
    const isDisplayIcon =
      level === 0 &&
      (type === DON_VI ||
        type === PHONG_BAN ||
        type === ALL ||
        isDisplayFavoriteGroup)
    const isCheckShow = dataToggle?.includes(data.id)
    const isDisplayChild = dataDisplayChild.includes(data.id)

    return (
      <Fragment key={index + indexChild}>
        <tr
          key={index}
          className={classNames(`row-level-${level}`)}
          // style={type === ALL ? { backgroundColor: '#E8E8E8' } : null}
        >
          {column?.map((cel, celIndex) => {
            return (
              <td key={celIndex}>
                <div className="cel">
                  {celIndex === 0 &&
                    (!isNoTree || isDisplayFavoriteGroup) &&
                    (!isHideChild || (isHideChild && isDisplayChild)) && (
                      <span
                        className={classNames('mr10 font-9 pointer', {
                          'icon2-arrow-up': isCheckShow && isDisplayIcon,
                          'icon2-arrow-down': !isCheckShow && isDisplayIcon,
                        })}
                        onClick={() => toggleItem(data?.id, isCheckShow)}
                      />
                    )}
                  <div
                    className={classNames('cel-value', {
                      'cel-value-first': celIndex === 0,
                      'text-uppercase':
                        type === PHONG_BAN || type === DON_VI || type === ALL,
                      'font-weight-700': type === ALL,
                    })}
                  >
                    {cel?.render
                      ? cel?.render(
                          cel?.dataIndex ? data?.[cel.dataIndex] : data,
                          index,
                          indexChild,
                          taskId
                        )
                      : data?.[cel.dataIndex]}
                  </div>
                </div>
              </td>
            )
          })}
        </tr>
        {(isDisplayDefault || isCheckShow) &&
          (!isHideChild || (isHideChild && isDisplayChild)) &&
          data?.children?.map((item, childIndex) =>
            renderItem(item, index, childIndex, level + 1)
          )}
      </Fragment>
    )
  }

  return (
    <div className={classNames(className)}>
      <Button
        className={classNames(
          Classes.MINIMAL,
          'cpc-button',
          'cpc-button-link',
          'mb10'
        )}
        onClick={() => setIsCollapse(prev => !prev)}
      >
        <label
          className={classNames('font-size-13 font-weight-600 color-black', {
            asterisk: isRequired,
          })}
        >
          {label}
        </label>
        <Icon
          classIcon={isCollapse ? 'icon2-arrow-up' : 'icon2-arrow-down'}
          className={classNames('ml5', 'size-icon-10', 'd-flex')}
        />
      </Button>
      <Collapse isOpen={isCollapse}>
        {!isLoading && isDisplayFavoriteGroup && (
          <ul className={classNames('table-phongban-donvi__tabs')}>
            <li className="mb10">
              <span
                className={classNames(
                  'font-size-13 font-weight-500 pointer pb5',
                  {
                    active: activeTabKey === 'DEPARTMENT_ORGANIZATION',
                  }
                )}
                onClick={() => setActiveTabKey('DEPARTMENT_ORGANIZATION')}
              >
                Phòng ban/đơn vị
              </span>
            </li>
            <li className="mb10">
              <span
                className={classNames(
                  'font-size-13 font-weight-500 pointer pb5',
                  {
                    active: activeTabKey === 'FAVORITE_GROUP',
                  }
                )}
                onClick={() => setActiveTabKey('FAVORITE_GROUP')}
              >
                Nhóm thường giao việc
              </span>
            </li>
          </ul>
        )}
        <div
          style={{
            ...(warning ? warningStyles : {}),
          }}
          className={classNames('table-phongban-donvi')}
        >
          {isLoading ? (
            <Loading column={3} />
          ) : (
            <Scrollbar maxHeight={600}>
              <table className="table-phongban-donvi__table">
                <thead>
                  <tr>
                    {column?.map((elm, index) => {
                      return (
                        <th
                          key={index}
                          className={classNames(elm?.headClass)}
                          style={headStyle}
                        >
                          <span
                            className={classNames({
                              asterisk: elm?.isRequired,
                            })}
                          >
                            {elm?.renderTitle
                              ? elm?.renderTitle(index, taskId)
                              : elm?.title}
                          </span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {activeTabKey === 'DEPARTMENT_ORGANIZATION' &&
                    data?.map((item, index) => renderItem(item, index, 0, 0))}
                  {activeTabKey === 'FAVORITE_GROUP' &&
                    dataFavorite?.map((item, index) =>
                      renderItem(item, index, 0, 0)
                    )}
                </tbody>
              </table>
            </Scrollbar>
          )}
        </div>
      </Collapse>
    </div>
  )
}

export default memo(PhongBanVaDonViThucHien)
