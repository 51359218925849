import React, { memo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import withRouter from 'react-router/withRouter'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { isValidMenu } from './helper'
import Item from './item'

const Sidebar = memo(
  ({
    auth,
    match,
    dataSource = [],
    isMobileMenuShown,
    counterCongViecPhongBanCaNhan,
    onClose,
    isMenuShown,
    onToggleMenu,
    counters,
  }) => {
    return (
      <div
        className={classNames('sidebar-menu', {
          'sidebar-mobile-open': isMobileMenuShown,
        })}
      >
        <Scrollbars autoHide autoHideTimeout={100} autoHideDuration={200}>
          <div className="sidebar-block">
            <div className="sidebar-block__arrow">
              <div className="sidebar-arrow-close">
                <span className="icon-Huy" onClick={onClose}></span>
              </div>
              <div className="sidebar__arrow">
                <span className={'icon icon2-left'} onClick={onToggleMenu} />
              </div>
            </div>
            <ul className="menu">
              {dataSource
                ?.filter(f => isValidMenu(auth?.permission, f))
                ?.map((data, index) => (
                  <Item
                    key={index}
                    auth={auth}
                    dataSource={data}
                    counters={{
                      ...counters,
                      counterCongViecPhongBanCaNhan,
                    }}
                    isDisabled={isMenuShown}
                    match={match}
                  />
                ))}
            </ul>
          </div>
        </Scrollbars>
      </div>
    )
  }
)

const mapStateToProps = state => ({
  counters: state?.counters,
  auth: state.auth,
  counterCongViecPhongBanCaNhan:
    state.TaskManager.counterCongViecPhongBanCaNhan,
})

export default withRouter(connect(mapStateToProps)(Sidebar))
