import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'

export default class ToMessages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: [],
      items: [],
    }
  }

  componentDidMount() {
    this.getDataTo()
  }

  getDataTo() {
    if (this.props.receivers && this.props.receivers.length === 0) {
      return false
    }
    let item = this.props.receivers && this.props.receivers.slice(0, 5)
    this.setState({
      item,
      items: this.props.receivers,
    })
  }

  render() {
    let { item, items } = this.state
    return (
      <div className="statistic-to">
        <span className="name-default" onClick={this.props.onClick}>
          {item &&
            item.length !== 0 &&
            item.map((item, key) => {
              if (key === 0) {
                return `${item.tenNguoiNhan}`
              }

              return `, ${item.tenNguoiNhan}`
            })}
        </span>
        {items && items.length >= 6 ? (
          <Popover
            popoverClassName="pt-popover-content-sizing"
            openOnTargetFocus={true}
            interactionKind={PopoverInteractionKind.HOVER}
            content={
              <div className="menu-popover menu-popover-box">
                {items &&
                  items.length > 0 &&
                  items.map((item, key) => {
                    return (
                      <span className="popover-chat-item" key={key}>
                        {item.tenNguoiNhan}
                      </span>
                    )
                  })}
              </div>
            }
            position={Position.BOTTOM}
          >
            <span className="icon-to-down icon-arrow-down"></span>
          </Popover>
        ) : null}
      </div>
    )
  }
}

ToMessages.propTypes = {
  receivers: PropTypes.array.isRequired,
}
