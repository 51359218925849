import React from 'react'
import { bindActionCreators } from 'redux'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { PAGINATION_LIMIT } from 'constants/Api'
import {
  TOATS_MESSAGE_DELETE_FAIL,
  TOATS_MESSAGE_DELETE_SUCCESS,
} from 'constants/MessageForm'
import { MasterLayout } from 'components/layout'
import { GridView } from 'components/common'
import { headers } from 'constants/Table'
import * as Actions from 'actions'
import ListItem from './ListItem'
import { isEmpty } from 'lodash'
import CustomPagination from '../UngDungChiaSe/CustomPagination'

class ListPhanQuyenChiaSe extends React.Component {
  constructor(props) {
    super(props)

    let state = {
      isLoading: false,
      page: 1,
      isError: false,
    }
    this.state = state

    this.clickPagination = this.clickPagination.bind(this)
  }

  getListApplication = params => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.actions
          .getAuthenToken(params)
          .then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true, isLoading: false })
            } else {
              this.setState({ isLoading: false })
            }
          })
          .catch(error => {
            this.setState({ isLoading: false })
          })
      }
    )
  }

  clickPagination(page) {
    this.setState({ page: page })
    this.getListApplication({
      page,
    })
  }

  componentDidMount() {
    this.getListApplication()
  }

  handleSubmitRemoveItem = id => {
    if (id) {
      this.props.actions.deleteAuthToken(id).then(res => {
        if (res.error || (res.payload && res.payload.status !== 200)) {
          return this.props.actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
        this.getListApplication()
        this.props.actions.commonAddToasterMessage({
          message: TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        {/* Heading */}
        <div className="heading-page">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="text-heading-page">
                <span className="icon2-share icon-heading"></span>
                Phân quyền chia sẻ API
              </h3>
              <Link
                to="/he-thong/chia-se-api/phan-quyen-chia-se-api/them-moi"
                className="btn-action pull-right hidden-btn btn-minwidth-auto"
              >
                <span className="name-btn">Thêm mới</span>
                <span className="pt-icon pt-icon-small-plus"></span>
              </Link>
            </div>
          </div>
        </div>

        {/* Table List */}
        <div className="page-list-container">
          <div
            className="table-container"
            style={{
              paddingBottom: 0,
              border: 0,
              background:
                this.props.listAuthenToken.total > 1 ? '#fff' : '#eff3f5',
            }}
          >
            <GridView
              isLoading={this.state.isLoading}
              classNames={'pt-table'}
              page={this.state.page}
              headers={headers.phanQuyenChiaSe}
              itemShape={ListItem}
              data={this.props.listAuthenToken.list}
              isBordered
              onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
              isEmpty={isEmpty(this.props.listAuthenToken.list)}
              isError={this.state.isError}
            />
            <CustomPagination
              current={this.state.page}
              total={this.props.listAuthenToken.total}
              onChange={this.clickPagination}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => {
  const total = state.system.listAuthenToken?.total
    ? Math.ceil(state.system.listAuthenToken.total / PAGINATION_LIMIT)
    : 0
  return {
    listAuthenToken: {
      ...state.system.listAuthenToken,
      list: state.system.listAuthenToken?.items || [],
      total: total,
    },
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListPhanQuyenChiaSe)
