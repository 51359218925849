import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  Fragment,
} from 'react'
import {
  Checkbox,
  Classes,
  Button,
  Intent,
  Popover,
  Menu,
  MenuDivider,
  Position,
  MenuItem,
  PopoverInteractionKind,
  Tooltip,
} from '@blueprintjs/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FileDownload from 'file-saver'
import { connect } from 'react-redux'
import moment from 'moment'
import get from 'lodash/get'
import size from 'lodash/size'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import difference from 'lodash/difference'
import { Action, Toast } from 'constants/MessageForm'
import { withCookies } from 'react-cookie'

import { Utils } from 'helpers'
import { ASSIGN_TYPE } from 'constants/Enum'
import requestAction from 'helpers/request'
import { MasterLayout } from 'components/layout'
import { Table, Pagination } from 'components/newCommon2'
import { TruncateTextTooltip } from 'components/common'
import { Permission } from 'components/auth'
import {
  showToast,
  saveTree2,
  getMyFavoriteGroups,
  getDsTag,
  getPhongBanDonVi,
  getThongTinDonVi,
  getMeetings,
  getLanhDaoChuTri,
  getMeetingsExport,
} from 'actions/task'
import MeetingForm from './Form/MeetingForm'
import DeleteMeeting from './Confirm/DeleteMeeting'
// import BackgroundSetting from './Settings/Background'
import { MeetingFilter } from './Filter'
import { Input, Select, Icon, Avatar } from 'components/newCommon'

export const MeetingContext = React.createContext({})

const Meeting = ({
  history,
  location,
  match,
  allCookies = {},
  roleId,
  mainUnitId,
  mainDepartmentId,
  hasShowAllDeparment,
  dataLanhDaoDonVi,
  dataThongTinDonVi,
  dataTreeOrganizationDepartmentStore,
}) => {
  const DATA_FORMAT = 'DD/MM/YYYY'
  const PAGE_SIZE = 10
  const PAGE_NAME = 'Thông báo kết luận cuộc họp'
  const filterKeys = {
    from: 'fromDate',
    to: 'toDate',
    textSearch: 'textSearch',
    meetingType: 'meetingType',
    textNumber: 'textNumber',
    title: 'title',
    mainResponsibilityId: 'mainResponsibilityId',
    creationTime: 'creationTime',
    fromExecutionDate: 'fromExecutionDate',
    toExecutionDate: 'toExecutionDate',
  }
  const pageActions = {
    taoMoi: 'them-moi',
    chinhSua: 'chinh-sua',
    xoa: 'xoa',
    chiTiet: 'chi-tiet',
  }
  const dsLoaiCuocHop = [
    {
      label: 'Giao ban tuần',
      value: 'WEEKLY_DELIVERY',
    },
    {
      label: 'Sơ kết/Tổng kết',
      value: 'SUMMARY',
    },
    {
      label: 'Họp tại đơn vị',
      value: 'MEETING_AT_ORGANIZATION',
    },
    {
      label: 'Khác',
      value: 'OTHER',
    },
  ]

  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const { action: currentAction, id: currentId } = match.params
  const mainPathname = useMemo(
    () => '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop',
    []
  )
  const DEFAULT_FILTER = useMemo(
    () => ({
      page: 1,
    }),
    []
  )

  const [tags, setTags] = useState([])
  const [favoriteGroups, setFavoriteGroups] = useState([])
  const [fetching, setFetching] = useState(true)
  const [isLoadingExcel, setIsLoadingExcel] = useState(false)
  const [fetchingFail, setFetchingFail] = useState(false)
  const [dataMettings, setDataMettings] = useState([])
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [totalData, setTotalData] = useState(1)
  const [selectItem, setSelectItem] = useState([])

  const goToAction = useCallback(
    (id, action) => () => {
      const { chinhSua, chiTiet } = pageActions
      if (action === chinhSua) {
        const url = `/thong-bao-ket-luan-cuoc-hop/chi-tiet/${id}/${chinhSua}/${id}`
        return history.push(url)
      }

      if (action === chiTiet) {
        const url = `/thong-bao-ket-luan-cuoc-hop/chi-tiet/${id}`
        return history.push(url)
      }

      const url = `${mainPathname}/${action}/${id}`
      if (!currentAction) return history.push(url)
      if (action !== currentAction || id !== currentId)
        return history.replace(url)
    },
    [history, pageActions, currentAction, mainPathname, currentId]
  )

  const _fetchList = useCallback(requestFilter => {
    const newFilter = {
      ...requestFilter,
      textSearch: isString(requestFilter?.textSearch)
        ? requestFilter?.textSearch?.trim()
        : requestFilter?.textSearch,
      textNumber: isString(requestFilter?.textNumber)
        ? requestFilter?.textNumber?.trim()
        : requestFilter?.textNumber,
      title: isString(requestFilter?.title)
        ? requestFilter?.title?.trim()
        : requestFilter?.title,
    }

    Utils.preData('meetingStore', newFilter)
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getMeetings(newFilter),
      afterResponse: response => {
        mountedSet(setFilter, newFilter)
        mountedSet(setDataMettings, response?.items || [])
        mountedSet(setTotalData, response?.totalCount || 0)
      },
      afterAction: () => mountedSet(setFetching, false),
      afterError: () => mountedSet(setFetchingFail, true),
    })
  }, [])

  const changeFilter =
    ({ name, reFetch = true, multipleKey = false }) =>
    value => {
      let newFilter = { ...filter, page: 1 }
      if (name === 'RESET') {
        newFilter = DEFAULT_FILTER
      } else if (multipleKey) {
        newFilter = { ...newFilter, ...value }
      } else {
        newFilter = {
          ...newFilter,
          [name]: value,
        }
      }

      setFilter(newFilter)
      if (reFetch) {
        _fetchList(newFilter)
      }
    }

  const onSort = values => {
    changeFilter({
      name: 'sort',
      reFetch: true,
    })(values)
  }

  const getMenuAction = useCallback(
    ({ id, creatorProfileId } = {}) => {
      const allowAction = creatorProfileId === roleId
      return [
        ...(allowAction
          ? [
              {
                icon: 'icon-but',
                text: 'Sửa',
                onClick: goToAction(id, pageActions.chinhSua),
              },
            ]
          : []),
        ...(allowAction
          ? [
              {
                icon: 'icon-bin',
                text: 'Xóa',
                onClick: goToAction(id, pageActions.xoa),
              },
            ]
          : []),
      ]
    },

    [roleId, goToAction, pageActions.chinhSua, pageActions.xoa]
  )

  const handleSelectRecord = (e, id) => {
    if (e.target.checked) {
      setSelectItem([...selectItem, id])
    } else {
      setSelectItem(selectItem.filter(item => item !== id))
    }
  }

  const columns = [
    {
      key: 'textNumber',
      width: 10,
      headClass: 'uppercase',
      className: 'align-center min-width-160',
      title: (
        <div
          className="d-flex align-items-center"
          onClick={e => e.stopPropagation()}
        >
          <Checkbox
            className={classNames(Classes.SMALL, 'mb0')}
            checked={
              size(dataMettings) ===
              size(dataMettings.filter(e => selectItem.includes(e?.id)))
            }
            onChange={e => onSelectAll(e.target.checked)}
          />
          Số hiệu văn bản
        </div>
      ),
      filter: (
        <Input
          clearable
          onChange={changeFilter({
            name: filterKeys.textNumber,
            reFetch: false,
          })}
          onClear={changeFilter({ name: filterKeys.textNumber })}
          onSend={changeFilter({ name: filterKeys.textNumber })}
          placeholder="Nhập"
          value={filter?.textNumber || ''}
        />
      ),
      render: ({ textNumber }, record) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={e => e.stopPropagation()}
          >
            <Checkbox
              className={classNames(Classes.SMALL, 'mb0')}
              checked={selectItem.includes(record.id)}
              onChange={e => handleSelectRecord(e, record.id)}
            />
            <span className="white-space-break-spaces">{textNumber}</span>
          </div>
        )
      },
    },
    {
      key: 'title',
      width: 20,
      headClass: 'uppercase',
      className: 'min-width-200 align-center min-width-100',
      title: 'Tên cuộc họp',
      filter: (
        <Input
          clearable
          onChange={changeFilter({
            name: filterKeys.title,
            reFetch: false,
          })}
          onClear={changeFilter({ name: filterKeys.title })}
          onSend={changeFilter({ name: filterKeys.title })}
          placeholder="Nhập"
          value={filter?.title || ''}
        />
      ),
      render: record => {
        return (
          <span className="white-space-break-spaces">
            {record?.title?.length > 20 ? (
              <TruncateTextTooltip content={record?.title} />
            ) : (
              record?.title
            )}
          </span>
        )
      },
    },
    {
      key: 'executionDate',
      width: 5,
      headClass: 'uppercase',
      className: 'min-width-160 align-center',
      title: 'Ngày ban hành',
      sortKey: 'executionDate',
      render: ({ executionDate }) => {
        return moment(executionDate).isValid()
          ? moment(executionDate).format(DATA_FORMAT)
          : ''
      },
    },
    {
      key: 'mainResponsibility',
      width: 15,
      headClass: 'uppercase',
      className: 'min-width-240 align-center',
      title: 'Lãnh đạo chủ trì',
      dataIndex: 'mainResponsibility',
      filter: (
        <Select
          dataSource={dataLanhDaoDonVi?.map(elm => ({
            label: elm?.tenNhanVien,
            value: elm?.chucDanhId,
          }))}
          disabled={fetching}
          inputIconClassName="icon-flag"
          onChange={changeFilter({ name: filterKeys.mainResponsibilityId })}
          placeholder="Chọn"
          value={get(filter, 'mainResponsibilityId')}
        />
      ),
      render: value => {
        if (get(value, 'type') === ASSIGN_TYPE.CA_NHAN) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={get(value, 'avatar')}
                className="mr10 tiny"
                needBaseUrl
              />
              <span>{get(value, 'name')}</span>
            </div>
          )
        }

        return get(value, 'shortName')
      },
    },
    {
      key: 'creationTime',
      width: 3,
      headClass: 'uppercase',
      className: 'min-width-120 align-center',
      title: 'Ngày tạo',
      sortKey: 'creationTime',
      render: ({ creationTime }) => {
        return moment(creationTime).isValid()
          ? moment(creationTime).format(DATA_FORMAT)
          : ''
      },
    },
    {
      key: 'organizationApproved',
      width: 10,
      headClass: 'uppercase text-center',
      className: 'min-width-100 align-center justify-center',
      title: 'Ban/Đơn vị duyệt',
      render: ({ organizationApproved }) => {
        return <span className="font-weight-600">{organizationApproved}</span>
      },
    },
    {
      key: 'secretaryApproved',
      width: 10,
      headClass: 'uppercase text-center',
      className: 'min-width-100 align-center justify-center',
      title: 'Thư ký duyệt',
      render: ({ secretaryApproved }) => {
        return <span className="font-weight-600">{secretaryApproved}</span>
      },
    },
    {
      key: 'thaoTac',
      className: 'element-center max-width-60 min-width-60 align-center',
      width: 5,
      render: record => {
        const menu = getMenuAction(record) || []
        if (!size(menu)) return null
        return (
          <Popover
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            content={
              <Menu className="cpc-context-menu border-none">
                {menu.map(({ icon, text, onClick = () => {} }, index) => (
                  <Fragment key={index}>
                    {!!index && <MenuDivider />}
                    <MenuItem
                      text={
                        <span className="cpc-context-menu-item">
                          <i className={classNames('size-icon-15', icon)} />{' '}
                          {text}
                        </span>
                      }
                      onClick={onClick}
                    />
                  </Fragment>
                ))}
              </Menu>
            }
          >
            <span
              className="icon-More size-icon-24"
              onClick={e => e.stopPropagation()}
            />
          </Popover>
        )
      },
    },
  ]

  const handleExport = useCallback(() => {
    requestAction({
      showToast: false,
      getResult: false,
      codeCheck: false,
      beforeAction: () => mountedSet(setIsLoadingExcel, true),
      action: () =>
        getMeetingsExport({
          ...filter,
          page: 1,
          limit: 9999,
        }),
      afterResponse: response => {
        FileDownload.saveAs(response, 'thong-bao-ket-luan-cuoc-hop.xls')
      },
      afterAction: () => mountedSet(setIsLoadingExcel, false),
      afterError: () => {
        showToast({
          message: Toast.FAIL(Action.EXPORT_EXCEL),
          intent: Intent.DANGER,
        })
      },
    })
  }, [filter])

  const convertType = type => {
    if (type === 'PB') {
      return 'DEPARTMENT'
    } else if (type === 'DV') {
      return 'ORGANIZATION'
    }

    return 'PERSONAL'
  }

  const fetchDepartmentsUnit = useCallback(() => {
    requestAction({
      showToast: false,
      successCode: 201 || 200,
      action: () =>
        getPhongBanDonVi(mainUnitId, { phongBanLanhDao: false, limit: 9999 }),
      afterResponse: response => {
        const departmentData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'PB')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -1,
              children: [],
            })) || []
        const organizationData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'DV')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -2,
              children: [],
            })) || []
        const newData = [
          ...(size(departmentData) !== 0
            ? [
                {
                  id: -1,
                  type: 'ALL',
                  name: 'Phòng ban',
                  code: 'Phòng ban',
                  parentId: null,
                  children: departmentData,
                },
              ]
            : []),
          ...(size(organizationData) !== 0
            ? [
                {
                  id: -2,
                  type: 'ALL',
                  name: 'PC/ Đơn vị',
                  code: 'PC/ Đơn vị',
                  parentId: null,
                  children: organizationData,
                },
              ]
            : []),
        ]
        requestAction({
          showToast: false,
          successCode: 0,
          action: () => saveTree2(newData),
        })
      },
    })
  }, [mainUnitId])

  const _fetchFavoriteGroup = useCallback(() => {
    requestAction({
      showToast: false,
      action: () => getMyFavoriteGroups({ is_active: true }),
      afterResponse: ({ items }) => {
        mountedSet(
          setFavoriteGroups,
          items.map(({ id, name, members }) => ({
            id,
            name,
            children: members.map(({ id, name, avatar, departmentId }) => ({
              id,
              parentId: departmentId,
              name,
              type: convertType('PERSONAL'),
              avatar,
            })),
          }))
        )
      },
    })
  }, [])

  const _fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => mountedSet(setTags, items),
    })
  }, [])

  const handleRedirect = useCallback(() => {
    const hasGoBack = get(location, 'state.showGoBack', false)
    hasGoBack
      ? history.goBack()
      : history.push(`/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop`)
  }, [location, history])

  const openCreate = useCallback(() => {
    const url = `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/${pageActions.taoMoi}`
    if (!currentAction) return history.push(url)
    if (currentAction) return history.replace(url)
  }, [currentAction, history, pageActions.taoMoi])

  const actionBlock = useMemo(() => {
    const { taoMoi, chinhSua, xoa } = pageActions
    const actionOverlays = {
      [taoMoi]: MeetingForm,
      [chinhSua]: MeetingForm,
      [xoa]: DeleteMeeting,
    }
    const ActionOverlay = actionOverlays[currentAction]
    if (!ActionOverlay) return <></>
    return (
      <ActionOverlay
        onClose={handleRedirect}
        reloadList={() => _fetchList(filter)}
      />
    )
  }, [pageActions, currentAction, _fetchList, handleRedirect, filter])

  const checkLayout = keyName => {
    const data = [pageActions.taoMoi, pageActions.chinhSua, pageActions.xoa]
    if (data.includes(keyName)) {
      return true
    }

    return false
  }

  const onSelectAll = useCallback(
    isChecked => {
      const ids = dataMettings?.map(elm => elm?.id) || []
      if (isChecked) {
        return setSelectItem(prev => uniq([...prev, ...ids]))
      }

      setSelectItem(prev => uniq(difference(prev, ids)))
    },
    [dataMettings]
  )

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchFavoriteGroup()
  }, [_fetchFavoriteGroup])

  useEffect(() => {
    _fetchDsTag()
  }, [_fetchDsTag])

  useEffect(() => {
    const filterStoreData = Utils.preData('meetingStore')
    let params = DEFAULT_FILTER
    if (!isEmpty(filterStoreData)) {
      params = filterStoreData
    }

    _fetchList(params)
  }, [_fetchList, DEFAULT_FILTER])

  useEffect(() => {
    if (hasShowAllDeparment) {
      fetchDepartmentsUnit()
    }
  }, [fetchDepartmentsUnit, hasShowAllDeparment])

  useEffect(() => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getThongTinDonVi({ donViId: mainUnitId }),
    })
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getLanhDaoChuTri(mainUnitId),
    })
  }, [mainUnitId])

  const contextValue = {
    favoriteGroups,
    tags,
    dataTreeOrganizationDepartmentStore,
    mainDepartmentId,
    hasShowAllDeparment,
    dataThongTinDonVi,
    dsLoaiCuocHop,
    filter,
    totalData,
    changeFilter,
  }

  const openStatistical = useCallback(() => {
    const url = `/tien-ich/thong-bao-ket-luan-cuoc-hop/thong-ke`
    if (!currentAction)
      return history.push({
        pathname: url,
        state: {
          ids: selectItem,
        },
      })
    if (currentAction) return history.replace(url)
  }, [currentAction, history, selectItem])

  return (
    <MasterLayout typeSidebar="taskManager">
      <MeetingContext.Provider value={contextValue}>
        <div style={{ flex: 1, position: 'relative' }}>
          <div className="task-manager-title">
            <h1 className="name">{PAGE_NAME}</h1>
            <div className="d-flex gap-10">
              <Tooltip
                content={<span className="font-size-12">Xuất excel</span>}
              >
                <Button
                  className={classNames(
                    'cpc-button theme-md',
                    'cpc-button-green'
                  )}
                  loading={isLoadingExcel}
                  disabled={size(dataMettings) === 0 || fetching}
                  onClick={handleExport}
                >
                  <span>Xuất excel</span>
                  <Icon classIcon="icon2-export" />
                </Button>
              </Tooltip>
              <Tooltip content={<span className="font-size-12">Thống kê</span>}>
                <Button
                  className={classNames('cpc-button theme-md')}
                  intent={Intent.PRIMARY}
                  onClick={openStatistical}
                  disabled={size(dataMettings) === 0 || fetching}
                >
                  <span>Thống kê</span>
                  <Icon classIcon="icon2-chart-histogram" />
                </Button>
              </Tooltip>
              <Tooltip
                position={Position.TOP_RIGHT}
                content={
                  <span className="font-size-12">
                    Tạo thông báo kết luận cuộc họp
                  </span>
                }
              >
                <Button
                  className={classNames('cpc-button theme-md')}
                  intent={Intent.PRIMARY}
                  disabled={fetching}
                  onClick={openCreate}
                >
                  <span>Tạo thông báo kết luận cuộc họp</span>
                  <Icon classIcon="icon-Plus" />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="border mt10">
            <div className="task-manager-filter">
              <MeetingFilter />
            </div>
            <Table
              className="cpc-table-giaodienmoi"
              autoHide={false}
              columns={columns}
              data={dataMettings}
              loading={fetching}
              error={fetchingFail}
              tableMinWidth={1200}
              onSort={onSort}
              onRowClick={({ id }) => goToAction(id, pageActions.chiTiet)()}
            />
            {totalData > 10 && (
              <div className="text-center bg-white ph15 pv20 border-top">
                <Pagination
                  total={totalData}
                  current={get(filter, 'page', 1)}
                  pageSize={PAGE_SIZE}
                  onChange={changeFilter({ name: 'page' })}
                />
              </div>
            )}
          </div>
        </div>
        {checkLayout(currentAction) && <div className="mask-layout"></div>}
        {actionBlock}
      </MeetingContext.Provider>
    </MasterLayout>
  )
}

Meeting.propTypes = {
  mainUnitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasShowAllDeparment: PropTypes.bool,
  mainDepartmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTreeOrganizationDepartmentStore: PropTypes.array,
}

const mapStateToProps = state => {
  const roleId = state?.auth?.roleId
  const hasShowAllDeparment = get(state, 'auth.permission', []).includes(
    Permission.THONGBAOKETLUANHDTV
  )

  return {
    hasShowAllDeparment,
    roleId,
    mainUnitId: get(state, 'auth.mainUnitId'),
    mainDepartmentId: get(state, 'auth.mainDepartmentId'),
    dataLanhDaoDonVi: get(state, 'common.dataLanhDaoDonVi'),
    dataThongTinDonVi: get(state, 'common.dataThongTinDonVi'),
    dataTreeOrganizationDepartmentStore: get(
      state,
      'common.dataTreeOrganizationDepartmentStore'
    ),
  }
}

export default withCookies(connect(mapStateToProps)(memo(Meeting)))
