import React from 'react'
import classnames from 'classnames'
import {
  LoadingTable,
  ErrorLoadingTable,
  EmptyDataTable,
} from '../../../components/common'

class StatisticList extends React.PureComponent {
  render() {
    const {
      classNames,
      header,
      itemShape: ItemShape,
      data,
      loading,
      error,
    } = this.props
    const empty = !data.length
    const columnAmount = header.length

    return (
      <div
        className={classnames('table-scroll', {
          'table-scroll-hidden': empty || error,
        })}
      >
        <table className={classNames}>
          {!!header.length && !(empty || error) && (
            <thead>
              <tr>
                {header.map((col, i) => (
                  <th
                    key={i}
                    className={!!col.classNames.length && col.classNames}
                  >
                    <span className="sort-colum">
                      <span className="text-sort">{col.value}</span>
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {loading ? (
              <tr className="table-loading">
                <td
                  className="colum-loading"
                  colSpan={this.props.header.length}
                >
                  <LoadingTable />
                </td>
              </tr>
            ) : empty || error ? (
              <tr className="no-border-bt">
                <td colSpan={columnAmount} className="error-table-hover">
                  <div className="error-page-container">
                    <div className="error-content">
                      {error ? (
                        <ErrorLoadingTable />
                      ) : (
                        empty && <EmptyDataTable />
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              data.map((item, i) => (
                <ItemShape
                  key={i}
                  index={i + 1}
                  type={this.props.type ? this.props.type : ''}
                  data={item}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default StatisticList
