import React, { memo, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { get } from 'lodash'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import { calendarConfig } from 'constants/config'

const Calendar = ({
  calendarRef,
  filterKeys,
  dataSource,
  viewType,
  setViewType,
  onChange: changeFilter,
  onEventItemClick,
}) => {
  const eventDateFormat = 'YYYY-MM-DD'
  const colors = {
    NEW: '#FD9638',
    DOING: '#1EABF1',
    DONE: '#58C72D',
  }

  const changeFilterHandle = useCallback(
    ({ start: startProps, end: endProps } = {}) => {
      const start = calendarRef.current?.getApi().view.activeStart || startProps
      const end = calendarRef.current?.getApi().view.activeEnd || endProps
      const values = {
        [filterKeys.from]: moment(start).format(eventDateFormat),
        [filterKeys.to]: moment(end).format(eventDateFormat),
      }
      changeFilter({ isMultipleKey: true })(values)
    },
    [calendarRef, filterKeys, changeFilter, eventDateFormat]
  )

  const customButtons = useMemo(() => {
    return {
      prev: {
        click: () => {
          calendarRef.current.getApi().prev()
          changeFilterHandle()
        },
      },
      next: {
        click: () => {
          calendarRef.current.getApi().next()
          changeFilterHandle()
        },
      },
      dayGridWeek: {
        text: 'Tuần',
        click: () => {
          calendarRef.current.getApi().changeView('dayGridWeek')
          setViewType('dayGridWeek')
          changeFilterHandle()
        },
      },
      dayGridMonth: {
        text: 'Tháng',
        click: () => {
          calendarRef.current.getApi().changeView('dayGridMonth')
          setViewType('dayGridMonth')
          changeFilterHandle()
        },
      },
      today: {
        text: 'Hôm nay',
        click: () => {
          calendarRef.current.getApi().today()
          changeFilterHandle()
        },
      },
    }
  }, [calendarRef, setViewType, changeFilterHandle])

  const eventDatas = useMemo(
    () =>
      dataSource.map(item => ({
        ...item,
        eventId: item.id,
        date: item.creationTime
          ? moment(item.creationTime).format(eventDateFormat)
          : undefined,
        title: item.title,
        color: colors[get(item, 'status', 'NEW')],
      })),
    [dataSource, eventDateFormat, colors]
  )

  return (
    <div className="cpc-calendar bg-white">
      <FullCalendar
        {...calendarConfig}
        dayMaxEvents={viewType === 'dayGridMonth' ? 3 : 10}
        dayHeaderFormat={
          viewType === 'dayGridMonth'
            ? { weekday: 'long' }
            : {
                weekday: 'short',
                month: 'numeric',
                day: 'numeric',
                omitCommas: true,
              }
        }
        ref={calendarRef}
        plugins={[dayGridPlugin]}
        customButtons={customButtons}
        initialView="dayGridMonth"
        eventClick={item =>
          onEventItemClick({
            id: item.event?.extendedProps?.eventId,
          })
        }
        initialEvents={changeFilterHandle}
        events={eventDatas}
      />
    </div>
  )
}

Calendar.propTypes = {
  calendarRef: PropTypes.object,
  filterKeys: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  dataSource: PropTypes.array.isRequired,
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  changeFilter: PropTypes.func,
  onEventItemClick: PropTypes.func,
}

Calendar.defaultProps = {
  calendarRef: null,
  filterKeys: null,
  match: null,
  history: null,
  dataSource: [],
  viewType: 'dayGridMonth',
  setViewType: () => {},
  changeFilter: () => {},
  onEventItemClick: () => {},
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(memo(Calendar))
