import React, { Component } from 'react'
import {
  CANH_BAO_XOA_VAN_BAN_KY_SO,
  TEXT_BUTTON_SUBMIT,
  TEXT_SUBMIT_BODY,
  XOA_KY_SO_CONG_VAN_DI,
  XOA_VAN_BAN_KY_SO,
} from '../../constants/Popup'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { PopupConfirm } from '../common/'
import PropTypes from 'prop-types'
import { Utils } from '../../helpers'
import moment from 'moment'
import _ from 'lodash'

const CHUA_DUYET = 0
const KHONG_DUYET = 1
const DANG_DUYET = 2
class ListConvergeItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      chucDanhId: props.auth ? props.auth.roleId : null,
      isOpenPopup: false,
      allowDelete: false,
      allowUpdate: false,
      allowManage: false,
    }
  }

  handleClick = () => {
    const { item, onClickDetail } = this.props

    onClickDetail &&
      item &&
      onClickDetail({
        vanBanKySoId: item.vanBanKySoId,
        soKyHieu: item.soKyHieu,
        fileId: item.fileId,
      })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
    })
  }

  handleSubmitRemoveItem = lydo => {
    const { onHandleSubmitRemoveItem, item } = this.props
    this.setState({
      isOpenPopup: false,
    })
    onHandleSubmitRemoveItem &&
      onHandleSubmitRemoveItem(lydo, item.vanBanKySoId)
  }

  componentDidMount() {
    const { chucDanhId } = this.state
    let { allowManage, allowUpdate, allowDelete } = this.state
    const { item } = this.props

    if (item) {
      if (_.get(item, 'nguoiLap.chucDanhId', '') === chucDanhId) {
        allowManage = true
        allowUpdate = true
        allowDelete = true
      }

      if (item.tinhTrang) {
        const tinhTrang = item.tinhTrang
        if (![CHUA_DUYET, KHONG_DUYET, DANG_DUYET].includes(tinhTrang)) {
          allowManage = false
          allowUpdate = false
          allowDelete = false
        }
      }
      this.setState({
        allowManage,
        allowDelete,
        allowUpdate,
      })
    }
  }

  prepareProperty = () => {
    const { item } = this.props

    if (item) {
      const tinhTrang = Utils.getLabelStatus(
        item,
        'ky-so-tap-trung',
        'tinhTrang'
      )

      let soKyHieu = ''
      if (item.soKyHieu) {
        soKyHieu = item.soKyHieu
        if (item.soKyHieu.indexOf('/') === 0) {
          soKyHieu = item.soKyHieu.substring(1)
        }
      }

      if (item.soVanBan && !isNaN(item.soVanBan) && Number(item.soVanBan) > 0) {
        soKyHieu = `${item.soVanBan}/${soKyHieu}`
      }

      return {
        tinhTrang,
        soKyHieu,
      }
    }
  }

  render() {
    const { number, type, item, isLoadingXoaKySo } = this.props
    const { allowDelete, allowManage, allowUpdate, isOpenPopup } = this.state
    const { tinhTrang, soKyHieu } = this.prepareProperty()
    return (
      <tr onClick={this.handleClick}>
        <td>{number > 9 ? number : '0' + number}</td>
        {/* Cong van di */}
        {type === 'cong-van-di' && <td>{soKyHieu}</td>}
        <td>{item.ngayLap && moment(item.ngayLap).format('DD/MM/YYYY')}</td>
        <td>{item.nguoiLap && item.nguoiLap.tenNhanVien}</td>
        <td>{this.props.item.tenQuyTrinh}</td>
        <td className="textarea-content">{this.props.item.trichYeu}</td>
        <td>{tinhTrang}</td>
        {(!!allowDelete || !!allowUpdate) && !!allowManage ? (
          <td onClick={e => e.stopPropagation()}>
            <Popover
              popoverClassName="pt-popover-content-sizing"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus
              content={
                <div className="menu-popover">
                  {allowUpdate && (
                    <Link
                      className="item"
                      to={`/ky-so-tap-trung/van-ban-ky-duyet/chinh-sua/${item.vanBanKySoId}`}
                    >
                      <span className="pt-icon pt-icon-annotation" />
                      <span className="name_action">Chỉnh sửa</span>
                    </Link>
                  )}
                  {allowDelete && (
                    <span className="item" onClick={this.handleClickPopup}>
                      <span className="pt-icon pt-icon-trash" />
                      <span className="name_action">Xóa</span>
                    </span>
                  )}
                </div>
              }
            >
              <span className="icon-More" />
            </Popover>
            <PopupConfirm
              isOpen={isOpenPopup}
              textarea
              actionPage={XOA_KY_SO_CONG_VAN_DI}
              onClose={this.handleClickPopup}
              title={XOA_VAN_BAN_KY_SO}
              text={TEXT_BUTTON_SUBMIT}
              isWarning={item && item.tinhTrang > 1}
              onSubmit={this.handleSubmitRemoveItem}
              isLoading={isLoadingXoaKySo}
            >
              {item && item.tinhTrang > 1
                ? CANH_BAO_XOA_VAN_BAN_KY_SO
                : TEXT_SUBMIT_BODY}
            </PopupConfirm>
          </td>
        ) : (
          <td>&nbsp;</td>
        )}
      </tr>
    )
  }
}

ListConvergeItem.propTypes = {
  item: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  match: PropTypes.object,
}

export default ListConvergeItem
