import React, { memo } from 'react'
import { Button, Tooltip } from '@blueprintjs/core'
import {
  ResetButton,
  SearchInput,
  Select,
  SelectSearch,
  DatePicker,
} from 'components/newCommon'
import { Input } from 'components/newCommon2'
import { useState } from 'react'
import moment from 'moment/moment'
import requestAction from 'helpers/request'
import { withCookies } from 'react-cookie'
import { connect } from 'react-redux'
import { getDonViTrongNganh } from 'actions/doffice'

const optionLoaiTimKiem = [
  {
    label: 'Văn bản đến',
    value: 'vbde',
  },
  {
    label: 'Văn bản đi',
    value: 'vbdi',
  },
  {
    label: 'Văn bản nội bộ',
    value: 'vbnb',
  },
]

const TimKiemCongViecFilter = ({
  tokenDO,
  filterKeys,
  filter,
  changeFilter = () => {},
  ...props
}) => {
  const [openFilter, setOpenFilter] = useState(false)
  const [hasOrganization, setHasOrganization] = useState(false)
  const [receiveOrganization, setReceiveOrganization] = useState([])

  const {
    listCongVan,
    listPhongBan,
    listSoVanBan,
    listPhongBanXuLy,
    listLanhDao,
    listChuyenVienXuLy,
  } = props

  const handleShowSearch = () => {
    setOpenFilter(!openFilter)
    if (!hasOrganization && tokenDO?.token) {
      const token = {
        tokenType: 'Bearer',
        accessToken: tokenDO?.token,
      }
      requestAction({
        codeCheck: false,
        showToast: false,
        getResult: false,
        beforeAction: () => {},
        action: () => getDonViTrongNganh(token),
        afterResponse: res => {
          setReceiveOrganization(
            res?.data
              ?.filter(e => e?.mADV !== null && e?.iDDV < 20000)
              ?.map(elm => ({
                label: elm?.tENDV,
                value: elm?.mADV,
              }))
          )
        },
        afterAction: () => {
          setHasOrganization(true)
        },
      })
    }
  }

  const {
    [filterKeys.loaiCongVan]: LoaiVB = 'all',
    [filterKeys.mainKeyWord]: MainKeyWord = '',
    [filterKeys.idLanhDaoChiDao]: IDLanhDaoChiDao = '0',
    [filterKeys.idPhongBanChuTri]: IDPhongBanChuTri = 0,
    [filterKeys.nguoiKyVB]: NguoiKyVB = '',
    [filterKeys.noiNhan]: NoiNhan = '',
    [filterKeys.idSoVB]: ID_SO_VB = 0,
    [filterKeys.idLoaiVB]: ID_LOAI_VB = 0,
    [filterKeys.noiGui]: NoiGui = '',
    [filterKeys.idPhongBanBanHanh]: IDPhongBanBanHanh = 0,
    [filterKeys.idChuyenVienXL]: IDChuyenVienXL = 0,
    [filterKeys.dateFrom]: DateFrom = undefined,
    [filterKeys.dateTo]: DateTo = undefined,
  } = filter

  return (
    <div className="layout-filter layout-filter--bg ph20 pv15 font-roboto">
      <div className="flex-basis-20 layout-filter-col min-width-280 max-width-280">
        <div className="d-flex cpc-input-v2-filter">
          <DatePicker
            placeholder="Từ ngày"
            isClearable={true}
            selectsStart
            startDate={DateFrom ? moment(DateFrom) : undefined}
            endDate={DateTo ? moment(DateTo) : undefined}
            selected={DateFrom ? moment(DateFrom) : undefined}
            maxDate={moment(DateTo) || undefined}
            onChange={value => {
              const newValue = value ? moment(value).utc().format() : undefined
              changeFilter({ name: filterKeys.dateFrom, reFetch: false })(
                newValue
              )
            }}
          />
          <DatePicker
            placeholder="Đến ngày"
            isClearable={false}
            selectsEnd
            startDate={DateFrom ? moment(DateFrom) : undefined}
            endDate={DateTo ? moment(DateTo) : undefined}
            selected={DateTo ? moment(DateTo) : undefined}
            minDate={moment(DateFrom) || undefined}
            onChange={value => {
              const newValue = value ? moment(value).utc().format() : undefined
              changeFilter({ name: filterKeys.dateTo, reFetch: false })(
                newValue
              )
            }}
          />
        </div>
      </div>
      <div className="flex-basis-20 layout-filter-col width-180 max-width-180">
        <div className="cpc-input-v2-filter">
          <Select
            value={LoaiVB}
            clearable={false}
            placeholder="Loại tìm kiếm"
            dataSource={[
              { label: 'Tất cả loại văn bản', value: 'all' },
              ...optionLoaiTimKiem,
            ]}
            dropdownClassName="dropdown-300"
            onChange={changeFilter({
              name: filterKeys.loaiCongVan,
              reFetch: true,
            })}
          />
        </div>
      </div>
      <div className="layout-filter-col">
        <div className="cpc-input-v2-filter-button">
          <SearchInput
            placeholder="Nhập trích yếu / số ký hiệu"
            leftIcon="icon2-search"
            onChange={changeFilter({
              name: filterKeys.mainKeyWord,
              reFetch: false,
            })}
            value={MainKeyWord}
            onSearch={changeFilter({ name: 'SUBMIT', reFetch: true })}
          />
          <Tooltip content={<span className="font-size-12">Làm mới</span>}>
            <ResetButton
              className="ml10"
              onClick={changeFilter({ name: 'RESET' })}
            />
          </Tooltip>
          <Tooltip content={<span className="font-size-12">Tìm kiếm</span>}>
            <Button
              className="cpc-button-filter-clear pt-intent-btn-white ml10 mr5"
              onClick={() => handleShowSearch()}
            >
              <i className="icon2-filter icon-Loading mr0" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div>
        <div className={`box-filter mt10 ${openFilter ? 'open' : 'close'}`}>
          <div className="row">
            <div className="col-xs-4 col-sm-4 col-md-4 mb20">
              <div className="cpc-input-label">Lãnh đạo chỉ đạo</div>
              <Select
                placeholder="Chọn"
                clearable={false}
                dataSource={[
                  { label: 'Tất cả', value: '0' },
                  ...(listLanhDao?.map?.(e => ({
                    label: e?.tENPB,
                    value: e?.iDPB,
                  })) || []),
                ]}
                value={IDLanhDaoChiDao}
                dropdownClassName="dropdown-300"
                onChange={changeFilter({
                  name: filterKeys.idLanhDaoChiDao,
                  reFetch: false,
                })}
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 mb20">
              <div className="cpc-input-label">Ban phòng ban hành</div>
              <Select
                placeholder="Chọn"
                clearable={false}
                dataSource={[
                  { label: 'Tất cả', value: 0 },
                  ...(listPhongBan?.map?.(e => ({
                    label: e?.tENPB,
                    value: e?.iDPB,
                  })) || []),
                ]}
                value={IDPhongBanBanHanh}
                dropdownClassName="dropdown-300"
                onChange={changeFilter({
                  name: filterKeys.idPhongBanBanHanh,
                  reFetch: false,
                })}
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 mb20">
              <Input
                label="Người ký ban hành"
                isDOInputNumber
                disableHeightLabel
                labelClassname="cpc-input-label"
                wrapperClassName="cpc-input-filter"
                placeholder="Nhập"
                value={NguoiKyVB}
                allowEmptyEnter={false}
                onEnter={changeFilter({
                  name: filterKeys.nguoiKyVB,
                  reFetch: true,
                })}
                onChange={changeFilter({
                  name: filterKeys.nguoiKyVB,
                  reFetch: false,
                })}
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 mb20">
              <div className="cpc-input-label">Đơn vị nhận</div>
              <SelectSearch
                isMutiple={false}
                dataSource={[
                  { label: 'Tất cả', value: 'ALL' },
                  ...receiveOrganization,
                ]}
                onClear={changeFilter({
                  name: filterKeys.noiNhan,
                  reFetch: false,
                })}
                onChange={changeFilter({
                  name: filterKeys.noiNhan,
                  reFetch: false,
                })}
                onClose={changeFilter({
                  name: filterKeys.noiNhan,
                  reFetch: false,
                })}
                placeholder="Chọn"
                selectedAllText="Tất cả"
                value={NoiNhan}
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 mb20">
              <div className="cpc-input-label">Sổ văn bản</div>
              <Select
                dropdownClassName="dropdown-300"
                clearable={false}
                placeholder="Chọn"
                value={ID_SO_VB}
                dataSource={[
                  { label: 'Tất cả', value: 0 },
                  ...(listSoVanBan?.map?.(e => ({
                    label: e?.tENSOVB,
                    value: e?.iDSOVB,
                  })) || []),
                ]}
                onChange={changeFilter({
                  name: filterKeys.idSoVB,
                  reFetch: false,
                })}
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 mb20">
              <div className="cpc-input-label">Loại văn bản</div>
              <Select
                placeholder="Chọn"
                clearable={false}
                value={ID_LOAI_VB}
                dataSource={[
                  { label: 'Tất cả', value: 0 },
                  ...(listCongVan?.map?.(e => ({
                    label: e?.tENLOAIVB,
                    value: e?.iDLOAIVB,
                  })) || []),
                ]}
                onChange={changeFilter({
                  name: filterKeys.idLoaiVB,
                  reFetch: false,
                })}
                dropdownClassName="dropdown-300"
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 mb20">
              <Input
                label="Đơn vị ban hành"
                isDOInputNumber
                value={NoiGui}
                disableHeightLabel
                labelClassname="cpc-input-label"
                wrapperClassName="cpc-input-filter"
                placeholder="Nhập"
                allowEmptyEnter={false}
                onEnter={changeFilter({
                  name: filterKeys.noiGui,
                  reFetch: true,
                })}
                onChange={changeFilter({
                  name: filterKeys.noiGui,
                  reFetch: false,
                })}
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 mb20">
              <div className="cpc-input-label">Phòng ban xử lý</div>
              <Select
                dropdownClassName="dropdown-300"
                clearable={false}
                placeholder="Chọn"
                value={IDPhongBanChuTri}
                dataSource={[
                  { label: 'Tất cả', value: 0 },
                  ...(listPhongBanXuLy?.map?.(e => ({
                    label: e?.tENPB,
                    value: e?.iDPB,
                  })) || []),
                ]}
                onChange={changeFilter({
                  name: filterKeys.idPhongBanChuTri,
                  reFetch: false,
                })}
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 mb20">
              <div className="cpc-input-label">Chuyên viên xử lý</div>
              <Select
                dropdownClassName="dropdown-300"
                clearable={false}
                placeholder="Chọn"
                value={IDChuyenVienXL}
                dataSource={[
                  { label: 'Tất cả', value: 0 },
                  ...(listChuyenVienXuLy?.map?.(e => ({
                    label: e?.nAME,
                    value: e?.iDNV,
                  })) || []),
                ]}
                onChange={changeFilter({
                  name: filterKeys.idChuyenVienXL,
                  reFetch: false,
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  tokenDO: state?.doffice?.toKenDO,
  listCongVan: state?.doffice?.listCongVan,
  listPhongBan: state?.doffice?.listPhongBan,
  listSoVanBan: state?.doffice?.listSoVanBan,
  listPhongBanXuLy: state?.doffice?.listPhongBanXuLy,
  listLanhDao: state?.doffice?.listLanhDao,
  listChuyenVienXuLy: state?.doffice?.listChuyenVienXuLy,
})

export default withCookies(
  connect(mapStateToProps)(memo(TimKiemCongViecFilter))
)
