import { Action, Toast } from '../../../constants/MessageForm'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import React, { Component } from 'react'
import {
  addUnits,
  deleteUnit,
  fetchAppliedUnits,
  fetchProcedure,
} from '../../../actions/procedure'
import {
  commonAddToasterMessage,
  getCategoryUnitMultiLevel,
} from '../../../actions'

import AppliedUnitList from './AppliedUnitList'
import { ContentHeader } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import UnitRecursive from './UnitRecursive'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { PAGINATION_LIMIT } from '../../../constants/Api'

class AppliedUnitPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      list: [],
      isLoading: true,
      isError: false,
      isOpen: false,
      onClose: true,
      selectedUnits: [],
      defaultSelected: [],
      isDelete: false,
      page: 1,
      totalPage: 1,
    }
  }

  onAddUnit = async e => {
    const { match, unit, fetchAllUnits, fetchAll } = this.props
    const { isOpen } = this.state
    e && e.preventDefault()
    if (!unit.items.length) {
      await fetchAllUnits().then(res => {
        if (_.get(res, 'error') || _.get(res, 'payload.status') !== 200) {
          this.setState({ isError: true, isLoading: false })
        }
      })
    }
    await fetchAll(match.params.id, 1, true).then(res => {
      const data = _.get(res, 'payload.data.result.items', [])
      this.setState({
        defaultSelected: data.map(i => ({
          don_vi_id: i.donViId,
          ten_viet_tat: i.tenVietTat,
          ten_don_vi: i.tenDonVi,
        })),
      })
    })
    this.setState({ isOpen: !isOpen })
  }

  onClosePopup = () => {
    this.setState({ isOpen: !this.state.isOpen, selectedUnits: [] })
  }

  componentDidMount() {
    const { fetchProcedure, match } = this.props
    const { params } = match
    if (params && params.id) {
      this.fetchAll({ page: 1 })
      fetchProcedure(params.id)
    }
  }

  onChangeUnit = unit => {
    unit = JSON.parse(unit)
    let selectedUnits = this.state.selectedUnits

    const selectedUnit = _.find(selectedUnits, { don_vi_id: unit.don_vi_id })

    if (!selectedUnit) {
      selectedUnits = _.concat(selectedUnits, unit)
    } else {
      selectedUnits = selectedUnits.filter(
        item => item.don_vi_id !== unit.don_vi_id
      )
    }

    this.setState({
      selectedUnits: selectedUnits,
    })
  }

  onSubmit = e => {
    e.preventDefault()

    if (!this.state.selectedUnits.length) {
      return this.onClosePopup()
    }

    const { params } = this.props.match
    this.props.addUnits(params.id, this.state.selectedUnits).then(res => {
      const status = !!_.get(res, 'payload.data.result')
      this.props.showToast({
        message: status
          ? Toast.SUCCESS(Action.CREATE)
          : Toast.FAIL(Action.CREATE),
        intent: status ? Intent.SUCCESS : Intent.DANGER,
      })
      this.fetchAll({ page: this.state.page })
      this.onClosePopup()
    })
  }

  fetchAll = (options = {}) => {
    const { match, fetchAll } = this.props
    fetchAll(match.params.id, options.page).then(res => {
      const strStatus = !!(_.get(res, 'payload.status', null) === 200)
      const totalPage = Math.ceil(
        _.get(res, 'payload.data.result.total', 0) / PAGINATION_LIMIT
      )
      this.setState({ isError: !strStatus, isLoading: false, totalPage })
    })
  }

  pageChange = async page => {
    await this.setState({ isLoading: true })
    await this.fetchAll({ page })
    this.setState({ page, isLoading: false })
  }

  render() {
    const { unit, procedure } = this.props
    const { page, totalPage } = this.state

    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <ContentHeader
          pageName={`Đơn vị áp dụng quy trình ${procedure.tenQuyTrinh || ''}`}
          pageIcon="icon-Ket_Qua_Bao_Cao"
          showGoBackButton
          actionsButton={[
            {
              name: 'Thêm mới',
              icon: 'pt-icon pt-icon-small-plus',
              onClick: this.onAddUnit,
            },
          ]}
        />
        <div className="custom-table-container applied-unit">
          <AppliedUnitList
            data={this.props.appliedUnit.list}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
            deleteUnit={this.props.deleteUnit}
            showToast={this.props.showToast}
            match={this.props.match}
            fetchAll={this.fetchAll}
            totalPage={totalPage}
            page={page}
            pageChange={this.pageChange}
          />
          <Dialog
            isOpen={this.state.isOpen}
            onClose={this.onClosePopup}
            title={'Thêm mới đơn vị'}
            className="size-container-dialog"
          >
            <div className="pt-dialog-body container-UpdateDrawers wrapper-content-dialog">
              {unit.items &&
                unit.items.length > 0 &&
                unit.items.map(item => (
                  <UnitRecursive
                    onChange={this.onChangeUnit}
                    key={item.donViId}
                    data={item.dsDonViCon}
                    unit={item}
                    selected={this.state.selectedUnits}
                    defaultSelected={this.state.defaultSelected}
                  />
                ))}
            </div>
            <div className="pt-dialog-footer dialog-footer-unit">
              <div className="pt-dialog-footer-actions">
                <div className="box-button">
                  <Button
                    className="button-blue btn-save-unit"
                    text={'Lưu'}
                    intent={Intent.PRIMARY}
                    type="button"
                    onClick={this.onSubmit}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  appliedUnit: state.appliedUnit,
  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },
  procedure: state.procedure.currentItem,
})

const mapDispathToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
  fetchAll: bindActionCreators(fetchAppliedUnits, dispatch),
  addUnits: bindActionCreators(addUnits, dispatch),
  deleteUnit: bindActionCreators(deleteUnit, dispatch),
  fetchAllUnits: bindActionCreators(getCategoryUnitMultiLevel, dispatch),
  fetchProcedure: bindActionCreators(fetchProcedure, dispatch),
})

export default connect(mapStateToProps, mapDispathToProps)(AppliedUnitPage)
