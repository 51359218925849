import React from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

const BarChartVertical = ({
  history,
  data = [],
  className,
  width = '100%',
  height = 198,
}) => {
  const goPath = path => {
    return history.push(path)
  }

  const onGoDetail = (value, index) => {
    const res = data?.find((_, key) => index === key)
    return goPath(res?.path)
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white border-secondary p10">
          <p className="label no-wrap">{`${payload?.[0]?.payload?.name} : ${payload?.[0]?.value}`}</p>
        </div>
      )
    }

    return null
  }

  const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={15}
          textAnchor="middle"
          fill="rgba(0,0,0,0.4)"
          strokeWidth="0"
          stroke="transparent"
          // transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    )
  }

  return (
    <ResponsiveContainer className={className} width={width} height={height}>
      <BarChart
        layout="vertical"
        width={500}
        height={height}
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <XAxis
          type="number"
          axisLine={false}
          tickLine={false}
          width={85}
          allowDecimals={false}
          tick={CustomizedAxisTick}
        />
        <YAxis
          cursor="pointer"
          type="category"
          dataKey="name"
          width={85}
          allowDecimals={false}
          tick={{
            fill: 'rgba(0,0,0,0.7)',
            strokeWidth: 0,
            stroke: 'transparent',
          }}
          onClick={onGoDetail}
        />
        <CartesianGrid horizontal={false} strokeDasharray="1 1" />
        <Tooltip cursor={false} content={<CustomTooltip />} />
        <Bar
          cursor="pointer"
          dataKey="value"
          fill="#FF951A"
          minPointSize={0}
          maxBarSize={45}
          onClick={onGoDetail}
        >
          {data.map((elm, index) => (
            <Cell key={index} fill={elm?.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartVertical
