import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import { Button, Intent } from '@blueprintjs/core'
import { connect } from 'react-redux'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { getDsBinhLuan, postBinhLuan, putBinhLuan } from 'actions/task'
import {
  Card,
  CollapseMore,
  Loading,
  Comment,
  Dialog,
  UserCard,
  Mention,
} from 'components/newCommon'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import { Action, Toast, CONFIRM_THUHOI_BINHLUAN } from 'constants/MessageForm'
import { WORK_COMMENT_STATUS } from 'constants/Enum'

const dialogProps = {
  buttonUppercase: true,
  confirmDanger: true,
  textCenter: true,
  cancelClassName: 'pl20 pr20',
  confirmClassName: 'pl20 pr20',
}

const CommentBlock = ({ className, match, currentUserId, users = [] }) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const { id, actionId } = match.params
  const taskId = actionId || id

  const [fetching, setFetching] = useState(false)
  const [comments, setComments] = useState([])
  const [newText, setNewText] = useState('')
  const [usersMention, setUsersMention] = useState([])
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [editId, setEditId] = useState()
  const [editText, setEditText] = useState('')
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [evictionId, setEvictionId] = useState()
  const [loadingEviction, setLoadingEviction] = useState(false)

  const fetchComments = useCallback(() => {
    if (taskId) {
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        beforeAction: () => _mountedSet(setFetching, true),
        action: () =>
          getDsBinhLuan(taskId, {
            limit: -1,
          }),
        afterResponse: ({ items = [] }) => {
          _mountedSet(setComments, items)
        },
        afterAction: () => _mountedSet(setFetching, false),
      })
    }
  }, [taskId])

  const onCreate = () => {
    if (!newText.trim()) return
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => _mountedSet(setLoadingCreate, true),
      action: () =>
        postBinhLuan(
          toDecamelizeKeys({
            workItemId: taskId,
            comment: newText,
            userIds: usersMention,
          })
        ),
      afterResponse: result => {
        _mountedSet(setNewText, '')
        _mountedSet(setComments, [...comments, result])
      },
      afterAction: () => _mountedSet(setLoadingCreate, false),
      successMessage: Toast.SUCCESS(Action.COMMENT),
      errorMessage: Toast.FAIL(Action.COMMENT),
    })
  }

  const onEdit = () => {
    if (!editText.trim()) return
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => _mountedSet(setLoadingEdit, true),
      action: () =>
        putBinhLuan(
          editId,
          toDecamelizeKeys({
            workItemId: taskId,
            comment: editText,
            status: WORK_COMMENT_STATUS.CHINH_SUA,
          })
        ),
      afterResponse: result => {
        _mountedSet(
          setComments,
          comments.map(comment => (comment.id === editId ? result : comment))
        )
        _mountedSet(setEditText, '')
        _mountedSet(setEditId)
      },
      afterAction: () => _mountedSet(setLoadingEdit, false),
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }

  const onEviction = () => {
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => _mountedSet(setLoadingEviction, true),
      action: () =>
        putBinhLuan(
          evictionId,
          toDecamelizeKeys({
            workItemId: taskId,
            status: WORK_COMMENT_STATUS.THU_HOI,
          })
        ),
      afterResponse: result => {
        _mountedSet(
          setComments,
          comments.map(comment =>
            comment.id === evictionId ? result : comment
          )
        )
        _mountedSet(setEvictionId)
      },
      afterAction: () => _mountedSet(setLoadingEviction, false),
      successMessage: Toast.SUCCESS(Action.EVICTION_COMMENT),
      errorMessage: Toast.FAIL(Action.EVICTION_COMMENT),
    })
  }

  const mentionsData = (key, text, mentions) => {
    if (key === 'EDIT') {
      _mountedSet(setEditText, text)
    } else {
      _mountedSet(setNewText, text)
    }

    if (mentions && mentions.length !== 0) {
      let userIds = []
      mentions.map(elm => {
        return userIds.push(elm.id)
      })
      _mountedSet(setUsersMention, uniq(userIds))
    } else {
      _mountedSet(setUsersMention, [])
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    fetchComments()
  }, [fetchComments])

  return (
    <div
      className={classNames('cpc-detail-block', {
        [`${className}`]: className,
      })}
    >
      <div className="pt15 pr15 pl15">
        <label
          className={classNames('font-size-13', 'font-weight-600', 'uppercase')}
        >
          Ý kiến
        </label>
        <div className="mt10">
          {fetching ? (
            <Loading />
          ) : (
            <CollapseMore
              textShow="Xem tất cả"
              textHide="Thu gọn"
              limitDisplay={2}
              className={'border-top'}
            >
              {(comments || []).map(
                ({ id, creator, status, comment, creationTime }) => {
                  if (editId === id) {
                    return (
                      <Card
                        key="EDIT"
                        className={classNames('comment-card border-top pt10', {
                          mb10: comments?.length === 1 ?? false,
                        })}
                        padding={false}
                      >
                        <UserCard
                          needBaseUrl
                          src={get(creator, 'avatar')}
                          name={get(creator, 'fullName')}
                          department={get(creator, 'department.shortName')}
                          unit={get(creator, 'organization.shortName')}
                          className="mb10"
                        />
                        <div
                          className="p5 mb5 border-radius-3 border-color-blue"
                          style={{
                            border: '1px solid #DFE6EA',
                            backgroundColor: '#fff',
                          }}
                        >
                          <Mention
                            className="task-mention"
                            users={users}
                            dataValue={editText}
                            onChange={(text, mentions) =>
                              mentionsData('EDIT', text, mentions)
                            }
                          />
                        </div>
                        <div className="align-center">
                          <div style={{ marginRight: 'auto' }}>
                            <Button
                              intent={Intent.PRIMARY}
                              className={classNames(
                                'cpc-button',
                                'uppercase',
                                'font-size-13',
                                'ph10',
                                'mr10'
                              )}
                              disabled={!editText.trim()}
                              loading={loadingEdit}
                              onClick={() =>
                                onEdit(WORK_COMMENT_STATUS.CHINH_SUA)
                              }
                            >
                              Lưu
                            </Button>
                            <Button
                              disabled={loadingEdit}
                              className={classNames(
                                'cpc-button',
                                'default',
                                'no-border',
                                'uppercase',
                                'font-size-13',
                                'ph10'
                              )}
                              onClick={() => _mountedSet(setEditId)}
                            >
                              Hủy
                            </Button>
                          </div>
                        </div>
                      </Card>
                    )
                  }
                  return (
                    <Comment
                      key={id}
                      className={classNames('border-top mt10 pt10', {
                        mb10: comments?.length === 1 ?? false,
                      })}
                      user={creator}
                      noiDung={comment}
                      thoiGian={creationTime}
                      onDelete={() => _mountedSet(setEvictionId, id)}
                      onEdit={() => {
                        _mountedSet(setEditId, id)
                        _mountedSet(setEditText, comment)
                      }}
                      allowAction={
                        status !== WORK_COMMENT_STATUS.THU_HOI &&
                        currentUserId === creator.id
                      }
                      isEdited={status === WORK_COMMENT_STATUS.CHINH_SUA}
                      isRetrieval={status === WORK_COMMENT_STATUS.THU_HOI}
                    />
                  )
                }
              )}
            </CollapseMore>
          )}
        </div>
      </div>
      <div
        className="p10"
        style={{ backgroundColor: '#F8F9FB', borderTop: '1px solid #DFE6EA' }}
      >
        <div
          className="p5 border-radius-3"
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid #DFE6EA',
            backgroundColor: '#fff',
          }}
        >
          <Mention
            className="task-mention"
            users={users}
            dataValue={newText}
            onChange={(text, mentions) => mentionsData('NEW', text, mentions)}
          />
          <Button
            className={classNames(
              'cpc-button',
              'text-uppercase',
              'font-size-13',
              'comment-button'
            )}
            intent={Intent.PRIMARY}
            disabled={loadingCreate || (!newText.trim() ? true : false)}
            loading={loadingCreate}
            onClick={onCreate}
          >
            <i className={classNames('icon-paper-plane')} />
          </Button>
        </div>
      </div>
      <Dialog
        {...dialogProps}
        isOpen={!!evictionId}
        cancelText="Hủy"
        confirmText={Action.EVICTION_COMMENT}
        loading={loadingEviction}
        onCancel={() => _mountedSet(setEvictionId)}
        onClose={() => _mountedSet(setEvictionId)}
        onConfirm={onEviction}
        title={Action.EVICTION_COMMENT}
      >
        <Card className="mt20 mb20">{CONFIRM_THUHOI_BINHLUAN}</Card>
      </Dialog>
    </div>
  )
}

const mapStateToProps = state => ({
  currentUserId: get(state, 'auth.user.nhanVienId'),
})

export default memo(withRouter(connect(mapStateToProps)(CommentBlock)))
