import React, { memo, useMemo } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'
import { Position } from '@blueprintjs/core'
import get from 'lodash/get'
import size from 'lodash/size'

import { MasterLayout } from 'components/layout'
import GoBackButton from 'components/Button/GoBackButton'
import {
  StatusDate,
  StatusTag,
  PrioritizeFlag,
  UserCard,
  CollapseMore,
  FileGroup,
  TagGroup,
  Loading,
  Icon as Icon2,
} from 'components/newCommon'
import { WORK_STATUS, WORK_APPROVED } from 'constants/Enum'
import { ViewMoreDots } from 'components/common'
import { Progress } from 'components/newCommon2'
import Comment from './Details/Comment'
import Menu from './components/menu'

import AcceptHistory from './components/detail/accept-history'
import FlowTask from './components/detail/flow-task/index'
import ConfirmSuaHuy from './components/confirm/confirm-sua-huy'
import CancelApproval from './components/confirm/cancel-approval'
import DeleteThongBaoKLCHCongViec from './components/confirm/delete-thongbao-klch-congviec'
import AcceptTaskKLCH from './components/form/accept-task-klch'
import Reminder from './components/form/reminder'
import AddTags from './components/form/add-tags'
import useTaskThongBaoKLCHTaskDetail from './hooks/use-task-thongbao-klch-task-detail'

const TaskThongBaoKLCHDetail = ({ match, history, roleId }) => {
  const id = match.params?.id
  const {
    dateFormat,
    fetching,
    detail,
    userMention,
    attachmentFilesNotFromDocument,
    coordinations,
    executor,
    menuActive,
    informationWorkItemAction,
    showDeadline,
    renderStatus,
    renderApproval,
    setMenuActive,
    fetchDetail,
    onMenu,
  } = useTaskThongBaoKLCHTaskDetail({
    match,
    history,
  })

  const leftMenuData = useMemo(() => {
    const isAllowAction = detail?.creatorProfileId === roleId
    let isApprove = false
    let isDelete = false
    if (detail?.approval === WORK_APPROVED.APPROVED && isAllowAction) {
      isApprove = true
    }

    if (
      isAllowAction &&
      !isApprove &&
      detail?.status === WORK_STATUS.CHUA_THUC_HIEN
    ) {
      isDelete = true
    }

    return [
      {
        keyName: 'LUONG_CONG_VIEC',
        name: 'Luồng công việc',
        icon: 'icon-organization font-size-13',
      },
      ...(detail?.creatorProfileId === roleId && !isApprove
        ? [
            {
              keyName:
                detail?.status !== WORK_STATUS.HOAN_THANH &&
                detail?.status !== WORK_STATUS.HOAN_THANH_CHAM
                  ? 'SUA_HUY_KET_LUAN'
                  : 'CONFIRM_SUA_HUY',
              name: 'Sửa/Hủy giao việc',
              icon: 'icon-but font-size-12',
            },
          ]
        : []),
      ...(informationWorkItemAction?.isAccept
        ? [
            {
              keyName: 'XAC_NHAN',
              name: 'Xác nhận',
              icon: 'icon-Bao_Cao font-size-11',
            },
          ]
        : []),
      {
        keyName: 'LICH_SU_XAC_NHAN',
        name: 'Lịch sử xác nhận',
        icon: 'icon-thoigianluu font-size-15',
      },
      ...(isAllowAction
        ? [
            {
              keyName: 'NHAC_VIEC',
              name: 'Nhắc việc',
              icon: 'icon2-bell size-icon-15',
            },
            {
              keyName: 'GAN_TAGS',
              name: 'Loại công việc (tags)',
              icon: 'icon2-tag font-size-15',
            },
          ]
        : []),
      ...(isApprove
        ? [
            {
              keyName: 'HUY_DUYET',
              name: 'Hủy duyệt',
              icon: 'icon-Huy font-size-12',
            },
          ]
        : []),
      ...(isDelete
        ? [
            {
              keyName: 'XOA',
              name: 'Xóa',
              icon: 'icon-Huy font-size-12',
              className: 'color-red-important',
            },
          ]
        : []),
    ]
  }, [detail, roleId, informationWorkItemAction])

  const actionBlock = useMemo(() => {
    const actionOverlays = {
      XAC_NHAN: AcceptTaskKLCH,
      LICH_SU_XAC_NHAN: AcceptHistory,
      LUONG_CONG_VIEC: FlowTask,
      NHAC_VIEC: Reminder,
      GAN_TAGS: AddTags,
    }
    const ActionOverlay = actionOverlays[menuActive?.keyName]
    if (!ActionOverlay) return <></>
    return (
      <ActionOverlay
        actionKey={menuActive?.keyName}
        dataSource={detail}
        onClose={() => setMenuActive(null)}
        onSuccess={() => fetchDetail()}
      />
    )
  }, [detail, menuActive, setMenuActive, fetchDetail])

  const actionDialogBlock = useMemo(() => {
    const actionOverlays = {
      CONFIRM_SUA_HUY: ConfirmSuaHuy,
      HUY_DUYET: CancelApproval,
      XOA: DeleteThongBaoKLCHCongViec,
    }

    const ActionOverlay = actionOverlays[menuActive?.keyName]
    if (!ActionOverlay) return <></>
    return (
      <ActionOverlay
        actionKey={menuActive?.keyName}
        dataSource={detail}
        onClose={() => setMenuActive(null)}
        onSuccess={() => {
          if (menuActive?.keyName === 'CONFIRM_SUA_HUY') {
            return history.push(
              `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/ket-luan/chinh-sua/${detail?.parentId}/${id}`
            )
          }

          return fetchDetail()
        }}
      />
    )
  }, [history, detail, id, menuActive, setMenuActive, fetchDetail])

  const isShowMenu = useMemo(() => {
    if (
      !menuActive ||
      (menuActive &&
        ![
          'CONFIRM_SUA_HUY',
          'XAC_NHAN',
          'LICH_SU_XAC_NHAN',
          'LUONG_CONG_VIEC',
          'NHAC_VIEC',
          'GAN_TAGS',
        ].some(s => s === menuActive?.keyName))
    ) {
      return true
    }

    return false
  }, [menuActive])

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="task-manager-detail-layout">
        <GoBackButton />
        <div className="task-manager-detail-block mt15">
          <div className="left p15">
            {fetching ? (
              <Loading />
            ) : (
              <div>
                <div className="cpc-detail-block p10">
                  <div className={classNames('pb10', 'border-bottom', 'mb10')}>
                    {get(detail, 'workType') === 'MEETING' && (
                      <span
                        className={classNames(
                          'uppercase',
                          'font-size-10',
                          'font-weight-600',
                          'text-yellow'
                        )}
                      >
                        Từ thông báo kết luận cuộc họp
                      </span>
                    )}
                    <h3
                      className={classNames(
                        'font-size-13',
                        'font-weight-600',
                        'pr20'
                      )}
                    >
                      {get(detail, 'title')}
                    </h3>
                    <TagGroup
                      className="gap-5 mt5"
                      tags={get(detail, 'workUserTags', []).map(
                        ({ workTagName, backgroundColor }) => ({
                          name: workTagName,
                          color: backgroundColor,
                        })
                      )}
                      popoverPosition={Position.BOTTOM_RIGHT}
                    />
                    {detail?.requireReport && (
                      <p className="font-size-13">
                        <Icon2
                          classIcon="icon-Bao_Cao"
                          className="font-size-10 mr5"
                        />
                        Yêu cầu báo cáo Hoàn thành
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames(
                      'd-flex',
                      'pb10',
                      'border-bottom',
                      'mb10'
                    )}
                  >
                    <div>
                      {(showDeadline ||
                        (get(detail, 'finishedDate') &&
                          get(detail, 'status') === 'DONE')) && (
                        <span className="cpc-detail-left" style={{ flex: 1 }}>
                          {showDeadline && (
                            <div>
                              <label className="cpc-detail-label">
                                Hạn thực hiện:
                              </label>
                              <StatusDate
                                className="cpc-detail-text"
                                date={showDeadline}
                                descriptionInline
                                showDescription={false}
                                isCompleted={get(detail, 'status') === 'DONE'}
                              />
                            </div>
                          )}
                        </span>
                      )}
                      <div>
                        {(size(coordinations || []) !== 0 || executor) && (
                          <div>
                            <ul className="d-flex">
                              {executor && (
                                <li className="pb10">
                                  <div>
                                    <label
                                      className={classNames(
                                        'cpc-detail-label',
                                        'mb5'
                                      )}
                                    >
                                      Chủ trì
                                    </label>
                                    <div className="font-size-13">
                                      {executor?.responsibility?.shortName}
                                    </div>
                                  </div>
                                </li>
                              )}
                              {size(coordinations || []) !== 0 && (
                                <li className="pb10 ml40">
                                  <div>
                                    <label
                                      className={classNames(
                                        'cpc-detail-label',
                                        'mb5'
                                      )}
                                    >
                                      Phối hợp
                                    </label>
                                    <div className="font-size-13">
                                      {
                                        <ViewMoreDots
                                          data={coordinations}
                                          isTotal={true}
                                        />
                                      }
                                    </div>
                                  </div>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <span className="cpc-detail-right text-right">
                      <div className="mb10">
                        <StatusTag type={renderStatus(detail)} />
                        {(detail?.approval === 'WAITING_APPROVAL' ||
                          detail?.approval === 'APPROVED') && (
                          <StatusTag
                            {...renderApproval(detail)}
                            className="ml25"
                          />
                        )}
                        <PrioritizeFlag
                          type={get(detail, 'priority')}
                          className="ml10"
                        />
                      </div>
                      <div
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                      >
                        <label className={classNames('cpc-detail-label')}>
                          Tiến độ
                        </label>
                        <div style={{ width: 32 }}>
                          <Progress progress={get(detail, 'progress', 0)} />
                        </div>
                      </div>
                    </span>
                  </div>
                  <CollapseMore
                    textShow="Xem thêm"
                    textHide="Thu gọn"
                    limitDisplay={0}
                    buttonSpace={false}
                    defaultOpen
                  >
                    <div className={classNames('border-bottom', 'mb10')}>
                      <div className="mb10">
                        <label
                          className={classNames('cpc-detail-label', 'mb5')}
                        >
                          Người giao
                        </label>
                        <UserCard
                          needBaseUrl
                          src={detail?.assigner?.avatar}
                          name={
                            detail?.assigner?.type === 'PERSONAL'
                              ? detail?.assigner?.name
                              : detail?.assigner?.shortName
                          }
                          department={
                            detail?.assigner?.information?.department?.shortName
                          }
                          unit={
                            detail?.assigner?.information?.organization
                              ?.shortName
                          }
                        />
                      </div>
                      <div className={classNames('d-flex', 'mb10')}>
                        {moment(get(detail, 'assignDate')).isValid() && (
                          <span style={{ minWidth: '33.33%' }}>
                            <label className="cpc-detail-label">
                              Ngày giao:
                            </label>
                            <span className="cpc-detail-text">
                              {moment(get(detail, 'assignDate')).format(
                                dateFormat
                              )}
                            </span>
                          </span>
                        )}
                        {moment(get(detail, 'executionDate')).isValid() && (
                          <span style={{ minWidth: '33.33%' }}>
                            <label className="cpc-detail-label">
                              Ngày bẳt đầu:
                            </label>
                            <span className="cpc-detail-text">
                              {moment(get(detail, 'executionDate')).format(
                                dateFormat
                              )}
                            </span>
                          </span>
                        )}
                        {moment(get(detail, 'originalDeadline')).isValid() && (
                          <span style={{ minWidth: '33.33%' }}>
                            <label className="cpc-detail-label">
                              Hạn thực hiện:
                            </label>
                            <span className="cpc-detail-text">
                              {moment(get(detail, 'originalDeadline')).format(
                                dateFormat
                              )}
                            </span>
                          </span>
                        )}
                      </div>
                      {!!get(detail, 'description') && (
                        <div className="mb10">
                          <label className="cpc-detail-label">Mô tả</label>
                          <p className="cpc-detail-norm">
                            {get(detail, 'description')}
                          </p>
                        </div>
                      )}
                    </div>
                    {(!!size(get(detail, 'files', [])) ||
                      !!size(get(detail, 'referenceFile', []))) && (
                      <div
                        className={classNames('pb10', 'border-bottom', 'mb10')}
                      >
                        {!!size(attachmentFilesNotFromDocument) && (
                          <div>
                            <label className="cpc-detail-label">
                              File đính kèm
                            </label>
                            <FileGroup list={attachmentFilesNotFromDocument} />
                          </div>
                        )}
                      </div>
                    )}
                  </CollapseMore>
                </div>
                <Comment className="mt10" users={userMention} />
              </div>
            )}
          </div>
          <div className="right">
            {isShowMenu && (
              <h2 className="text-uppercase text-13-500 color-gray p15">
                THỰC HIỆN
              </h2>
            )}
            {isShowMenu && (
              <Menu
                history={history}
                dataSource={leftMenuData}
                onClick={onMenu}
              />
            )}
            {actionBlock}
          </div>
        </div>
        {actionDialogBlock}
      </div>
    </MasterLayout>
  )
}

TaskThongBaoKLCHDetail.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  roleId: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    roleId: state?.auth?.roleId,
  }
}

export default withRouter(
  connect(mapStateToProps)(memo(TaskThongBaoKLCHDetail))
)
