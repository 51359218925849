import * as types from '../constants/ActionTypes'

const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  users: [],
  sharedApplications: {},
  detailApplications: {},
  listApiCategory: [],
  detailAuthenToken: {},
}

export default function systemProject(state = initialState, action) {
  switch (action.type) {
    case types.GET_LIST_PROJECT:
      return {
        ...state,
        list: {
          page: action.payload.data.result.page,
          total: action.payload.data.result.total,
          items:
            action.payload.data.result && action.payload.data.result.items
              ? action.payload.data.result.items
              : [],
        },
      }

    case types.GET_LIST_STAFFS_IN_DEPARTMENT:
      return {
        ...state,
        users: action.payload.data.result ? action.payload.data.result : [],
      }
    case types.API_REQUEST_SHARED_API:
      return {
        ...state,
        sharedApplications: action.payload.data.result || {},
      }
    case types.API_REQUEST_DETAIL_APPLICATION:
      return {
        ...state,
        detailApplications: action.payload.data.result || {},
      }
    case types.API_REQUEST_AUTHENTOKEN:
      return {
        ...state,
        listAuthenToken: action.payload.data.result || {},
      }
    case types.API_REQUEST_APICATEGORY:
      return {
        ...state,
        listApiCategory: action.payload.data.result || {},
      }
    case types.API_REQUEST_AUTHENTOKEN_DETAILS:
      return {
        ...state,
        detailAuthenToken: action.payload.data.result || {},
      }
    default:
      return state
  }
}
