import React, { Component } from 'react'
import classnames from 'classnames'
import { Spinner, Intent } from '@blueprintjs/core'
import { Scrollbars } from 'react-custom-scrollbars'
import moment from 'moment'
import { Authorization, Permission } from '../../components/auth/'
import { isEmpty } from 'lodash'
import { TruncateText, NotFound } from '../common'

export default class NotificationTabs extends Component {
  state = {
    tab: 'corporations',
    scrollTop: {
      corporations: 0,
      unit: 0,
    },
  }
  async componentDidMount() {
    let type = this.state.tab
    await this.fetchDataNoti(type)
    this.handleReadNoti(type)
  }

  handleReadNoti = async type => {
    const { notifications } = this.props
    const newestData =
      notifications.list[
        `${type === 'corporations' ? 'unreadCorporations' : 'unreadUnit'}`
      ]
    if (!newestData.daDoc) {
      await this.props.actions.notificationMarkAsReaded({
        thongBaoChungId: newestData.id,
        isTinTongCongTy: newestData.tinTongCongTy,
      })
      this.props.actions.notificationGetLastest()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.tab !== prevState.tab) {
      let type = this.state.tab
      this.Scrollbars && this.Scrollbars.scrollToTop()
      await this.fetchDataNoti(type)
      this.handleReadNoti(type)
    }
  }
  fetchDataNoti = async type => {
    const { notifications } = this.props
    const newestData =
      notifications.list[
        `${type === 'corporations' ? 'unreadCorporations' : 'unreadUnit'}`
      ]
    if (isEmpty(this.props[type]) || !newestData.daDoc) {
      return this.props.submitFilter(1, type)
    }
  }

  handleLoadMore = type => onScroll => {
    if (onScroll.top >= 1 && !this.props.isLoading[type]) {
      this.props.onClickNextPage(type)
    }
  }

  showListNoti = type => {
    return (
      <div className="fade-in">
        <Scrollbars
          ref={el => (this.Scrollbars = el)}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={window.innerHeight - 250}
          onScrollFrame={this.handleLoadMore(type)}
        >
          <ul className="notification-tabs__list-noti">
            {this.props[type].length === 0 &&
              !(
                this.props.isLoading.corporations || this.props.isLoading.unit
              ) && <NotFound />}
            {this.props[type] &&
              this.props[type].map(item => (
                <li key={item.id} className="notification-tabs__list-noti-item">
                  <div>
                    <img src="/images/CPC-icon.svg" alt="CPC ICON" />
                  </div>
                  <ul className="noti-content-list ql-snow">
                    <li className="noti-content-list__title">{item.tieuDe}</li>
                    <li className="ql-editor noti-content-list__decription">
                      {<TruncateText content={item.noiDung} />}
                    </li>
                    <li className="noti-content-list__time">
                      <span className="icon-time"></span>
                      {moment(item.ngayTao).format('DD/MM/YYYY HH:mm')}
                    </li>
                  </ul>
                </li>
              ))}
          </ul>
          {this.props.isLoading[type] && (
            <Spinner
              intent={Intent.PRIMARY}
              className="notification-tabs__loading"
            />
          )}
        </Scrollbars>
      </div>
    )
  }

  changeTab = type => {
    this.setState({ tab: type })
  }

  render() {
    const AuthElement = Authorization.Element
    const { notifications } = this.props
    const { unreadCorporations, unreadUnit } = notifications.list
    return (
      <div
        style={{ width: this.props.width + 'px' }}
        className="notification-tabs"
      >
        <AuthElement orPermission={[Permission.SUPERADMIN, Permission.ADMIN]}>
          <div
            onClick={() => this.props.history.push('/thong-bao/danh-sach')}
            className="notification-tabs__content-manage"
          >
            <div className="d-flex middle-xs">
              <span className="icon-XemDSVBKyso notification-tabs__content-manage-icon"></span>
              <span className="notification-tabs__content-manage-text">
                Quản lý nội dung
              </span>
            </div>
            <span className="icon-arrow-next notification-tabs__content-manage-arrow-icon"></span>
          </div>
        </AuthElement>
        <div className="notification-tabs__header">
          <div
            onClick={() => this.changeTab('corporations')}
            className={classnames('notification-tabs__header-item', {
              'notification-tabs__header-item--active':
                this.state.tab === 'corporations',
            })}
          >
            <div className="notification-tabs__header-item-text">
              TỔNG CÔNG TY
              {!unreadCorporations.daDoc && (
                <span className="notification-title__dot-noti notification-title__dot-noti--header-title"></span>
              )}
            </div>
          </div>
          <div
            onClick={() => this.changeTab('unit')}
            className={classnames('notification-tabs__header-item', {
              'notification-tabs__header-item--active':
                this.state.tab === 'unit',
            })}
          >
            <div className="notification-tabs__header-item-text">
              ĐƠN VỊ
              {!unreadUnit.daDoc && (
                <span className="notification-title__dot-noti notification-title__dot-noti--header-title"></span>
              )}
            </div>
          </div>
          <div
            style={{ left: this.state.tab === 'corporations' ? '0' : '50%' }}
            className="notification-tabs__header-underline"
          ></div>
        </div>

        <div
          className={classnames('notification-tabs__body', {
            'notification-tabs__body--is-loading':
              (this.props.isLoading.corporations &&
                isEmpty(this.props.corporations)) ||
              (this.props.isLoading.unit && isEmpty(this.props.unit)),
          })}
        >
          {this.showListNoti(this.state.tab)}
        </div>
      </div>
    )
  }
}
