import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExecutingViewBoxItems from './ExecutingViewBoxItems'
import { Collapse } from '@blueprintjs/core'
import classnames from 'classnames'

class ExecutingViewBoxItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  state = {
    isOpen: false,
    data: [],
    isLoading: false,
  }

  componentDidMount() {
    if (
      this.props.item &&
      this.props.item.phieuGiaoViecId &&
      this.props.item.chuTri
    ) {
      const id = this.props.item.phieuGiaoViecId
      this.handleClick(id)
    }
  }

  handleClick = (id, isOpen) => {
    if (this.props.item && !this.props.item.phieuGiaoViecId) {
      return
    }
    if (id && !isOpen) {
      this.setState({ isLoading: true }, () => {
        if (this.state.data && !this.state.data[id]) {
          this.props.actions &&
            this.props.actions.getExecutingViewAssign(id).then(res => {
              if (
                res &&
                res.payload &&
                res.payload.data &&
                res.payload.data.result
              ) {
                this.setState({
                  isOpen: true,
                  data: {
                    [id]: [res.payload.data.result],
                  },
                  isLoading: false,
                })
              }
            })
        } else {
          this.setState({ isLoading: false, isOpen: true })
        }
      })
    }
    if (isOpen) {
      this.setState({ isOpen: false })
    }
  }

  render() {
    const { item, auth } = this.props
    return (
      <div className="box-level-sub item-box">
        <div className="drop-down-sub">
          <div
            className="click-avatar"
            onClick={this.handleClick.bind(
              this,
              item.phieuGiaoViecId,
              this.state.isOpen
            )}
          >
            <span
              className={classnames('pt-icon click-dropdown', {
                'pt-icon-chevron-up': item.phieuGiaoViecId && this.state.isOpen,
                'pt-icon-chevron-down':
                  item.phieuGiaoViecId && !this.state.isOpen,
              })}
            ></span>
            <span className="name-department">
              {item.phongBanThucHien && item.phongBanThucHien.maPhongBan + '  '}
            </span>
            {item.chuTri ? (
              item.chuTri === true && <span className="role">(Chủ trì)</span>
            ) : (
              <span className="role">(Phối hợp)</span>
            )}
          </div>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <div
            className={classnames({
              'loading-view': this.state.isLoading,
              'scroll-ExecutingViewBox': this.props.isInternalDoc !== undefined,
            })}
          >
            {this.state.isLoading && <span>&nbsp;</span>}
            {!this.state.isLoading &&
              this.state.data[item.phieuGiaoViecId] &&
              this.state.data[item.phieuGiaoViecId].map((item, i) => (
                <ExecutingViewBoxItems
                  item={item}
                  key={i}
                  auth={auth}
                  actions={this.props.actions}
                />
              ))}
          </div>
        </Collapse>
      </div>
    )
  }
}

export default ExecutingViewBoxItem
