import { DANH_SACH_CONG_VAN_61 } from '../constants/ActionTypes'

const initialState = {
  danhSachTimKiemCongVan61: {
    list: [],
    total: 0,
  },
}

export default function timKiemCongVan61(state = initialState, action) {
  switch (action.type) {
    case DANH_SACH_CONG_VAN_61:
      return {
        ...state,
        danhSachTimKiemCongVan61: {
          list: action.payload.data.result.items || [],
          total: action.payload.data.result.total || 0,
        },
      }
    default:
      return state
  }
}
