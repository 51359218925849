import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Intent, Button, Radio } from '@blueprintjs/core'
import * as MESSAGE from '../../constants/MessageForm'

class ForwardAssignment extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    receiveDocument: PropTypes.object.isRequired,
    action: PropTypes.object.isRequired,
    isLoadingData: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeRadio = this.handleChangeRadio.bind(this)
  }

  state = {
    congVanDenId: this.props.receiveDocument.congVanDenId,
    phongBanId: '',
    noiDung: '',
    isOpenPopup: false,
    isLoading: false,
    departments: [],
  }

  componentDidMount() {
    let departments = this.props.departments.filter(
      item =>
        item.phongBanId !== this.props.receiveDocument.trinhLanhDao.phongBanId
    )
    if (departments.length > 0) {
      this.setState({ departments, phongBanId: departments[0].phongBanId })
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleChangeRadio = (phongBanId, e) => {
    this.setState({ [e.target.name]: phongBanId })
  }

  handleSubmit = async () => {
    await this.setState({ isLoading: true })
    if (this.props.receiveDocument && this.props.receiveDocument.congVanDenId) {
      this.props.action
        .postForwardAssignment(this.props.receiveDocument.congVanDenId, {
          cong_van_den_id: this.state.congVanDenId,
          phong_ban_chuyen_den: this.state.phongBanId,
          noi_dung: this.state.noiDung,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.result === true &&
            this.state.congVanDenId
          ) {
            this.setState({ isLoading: false })
            this.props.action.getReceiveDocumentItem(
              this.props.receiveDocument.congVanDenId
            )
            this.props.onSubmit(
              'ChuyenChiDao',
              MESSAGE.TEXT_CHUYENCHIDAOTHANHCONG,
              Intent.SUCCESS,
              true,
              null
            )
          } else {
            this.setState({ isLoading: false })
            this.props.action.getReceiveDocumentItem(
              this.props.receiveDocument.congVanDenId
            )
            this.props.onSubmit(
              'ChuyenChiDao',
              MESSAGE.TEXT_CHUYENCHIDAOTHATBAI,
              Intent.DANGER,
              false,
              null
            )
          }
        })
    }
  }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>Chuyển chỉ đạo</h4>
        </div>
        <div className="form-container container-detail">
          <div className="pt-form-group">
            <div className="row full-row">
              <div className="col-md-3 col-xs-12">
                <label className="pt-label">Nội dung</label>
              </div>
              <div className="col-md-9 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="4"
                    className="pt-input pt-fill"
                    placeholder="Nhập nội dung"
                    dir="auto"
                    name="noiDung"
                    value={this.state.noiDung}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group">
            <div className="row full-row">
              <div className="col-md-12 col-xs-12">
                <label className="pt-label">Phòng ban thực hiện</label>
              </div>
              <div className="col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <table className="table-forward">
                    <thead className="thead-forward">
                      <tr className="tr-forward">
                        <th className="th-forward">Phòng ban</th>
                        <th className="th-forward">Chuyển chỉ đạo</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-forward">
                      {this.state.departments &&
                        this.state.departments.map((item, i) => (
                          <tr className="tr-forward" key={i}>
                            <td className="td-forward">{item.maPhongBan}</td>
                            <td className="td-forward">
                              <Radio
                                checked={
                                  this.state.phongBanId === item.phongBanId
                                    ? true
                                    : false
                                }
                                className="radio-forward"
                                name="phongBanId"
                                onChange={this.handleChangeRadio.bind(
                                  this,
                                  item.phongBanId
                                )}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-blue-color"
            loading={this.state.isLoading}
            disabled={
              Object.values(this.props.isLoadingData).find(
                item => item === true
              ) !== undefined
            }
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
      </div>
    )
  }
}

export default ForwardAssignment
