import React, { memo, useState, useEffect } from 'react'
import csx from 'classnames'

const Truncate = ({ limit = 180, text = '' }) => {
  const [show, setShow] = useState(false)
  const [showToggle, setShowToggle] = useState(false)
  const [showText, setShowText] = useState(text)

  const toggleShow = () => {
    if (show) {
      setShowText(text.slice(0, limit))
    } else {
      setShowText(text)
    }
    setShow(!show)
  }

  useEffect(() => {
    if (text.length > limit) {
      setShowText(text.slice(0, limit))
      setShowToggle(true)
    }
  }, [text, limit])

  return (
    <>
      <span className="mr5">
        {showText}
        {showToggle && !show && '...'}
      </span>
      {showToggle && (
        <span
          className={csx('text-primary', 'has-event', 'd-inline-block')}
          onClick={toggleShow}
        >
          {show ? 'Ẩn bớt' : 'Xem thêm'}
        </span>
      )}
    </>
  )
}

export default memo(Truncate)
