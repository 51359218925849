import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    disableAction: PropTypes.bool,
  }

  state = {
    isOpenPopup: false,
  }

  render() {
    const { item, number } = this.props
    return (
      <tr className="gerenal-list-utilities">
        <td className="gerenal-list-utilities">
          {number && number > 9 ? number : '0' + number}
        </td>
        <td className="gerenal-list-utilities">{item && item.soCongVan}</td>
        <td className="textarea-content gerenal-list-utilities">
          {item && item.trichYeu}
        </td>
        <td className="gerenal-list-utilities">
          <span className="container-text-content">
            {item &&
              item.giaoViec &&
              item.giaoViec.nguoiGiaoViec &&
              item.giaoViec.nguoiGiaoViec.tenNhanVien}
          </span>
          {item && item.giaoViec && <span> - </span>}
          <span className="container-text-content">
            {item && item.giaoViec && item.giaoViec.ngayGiaoViec
              ? moment(item.giaoViec.ngayGiaoViec).format('DD/MM/YYYY HH:mm')
              : ''}
          </span>
          <div className="assign-content textarea-content">
            {item && item.giaoViec && item.giaoViec.noiDung}
          </div>
        </td>
        <td className="gerenal-list-utilities">
          {item && item.hanGiaiQuyet
            ? moment(item.hanGiaiQuyet).format('DD/MM/YYYY')
            : ''}
        </td>
        <td className="text-nowrap gerenal-list-utilities">
          {item && item.chuTri === false ? 'Phối hợp' : 'Chủ trì'}
        </td>
      </tr>
    )
  }
}

export default ListItem
