import React, { Component } from 'react'
import moment from 'moment'
class DetailInfo extends Component {
  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Thông tin công văn</h4>
        </div>
        <div className="form-container section-box-info">
          <div className="detail-info-container">
            <div className="list-items">
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Số công văn:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {this.props.listInternalDocument &&
                    this.props.listInternalDocument.soCongVan
                      ? this.props.listInternalDocument.soCongVan
                      : ''}
                  </span>
                </div>
              </div>

              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Trích yếu:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content textarea-content">
                    {this.props.listInternalDocument &&
                    this.props.listInternalDocument.trichYeu
                      ? this.props.listInternalDocument.trichYeu
                      : ''}
                  </span>
                </div>
              </div>

              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Ngày công văn:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {this.props.listInternalDocument &&
                    this.props.listInternalDocument.ngayCongVan
                      ? moment(
                          this.props.listInternalDocument.ngayCongVan
                        ).format('DD/MM/YYYY')
                      : ''}
                  </span>
                </div>
              </div>

              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Người ký:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {this.props.listInternalDocument.nguoiKy &&
                    this.props.listInternalDocument.nguoiKy.tenNhanVien
                      ? this.props.listInternalDocument.nguoiKy.tenNhanVien
                      : ''}
                  </span>
                </div>
              </div>

              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Chuyển đến phòng ban:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {this.props.listInternalDocument &&
                      this.props.listInternalDocument.phongBanNhan &&
                      this.props.listInternalDocument.phongBanNhan.length > 0 &&
                      this.props.listInternalDocument.phongBanNhan.map(
                        (item, i) => (
                          <span key={i}>
                            {' '}
                            {i === 0
                              ? item.maPhongBan
                              : ', ' + item.maPhongBan}{' '}
                          </span>
                        )
                      )}
                  </span>
                </div>
              </div>
              <div className="row item-value">
                <div className="col-xs-3 left-content">
                  <span className="label-text">Chuyển đến tổ/nhóm:</span>
                </div>
                <div className="col-xs-9 right-content">
                  <span className="content">
                    {this.props.listInternalDocument &&
                      this.props.listInternalDocument.toNhomNhan &&
                      this.props.listInternalDocument.toNhomNhan.length > 0 &&
                      this.props.listInternalDocument.toNhomNhan.map(
                        (item, i) => (
                          <span key={i}>
                            {' '}
                            {i === 0
                              ? item.maPhongBan
                              : ', ' + item.maPhongBan}{' '}
                          </span>
                        )
                      )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DetailInfo
