import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { UpdateDecentralizationUser } from '../../components/System'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'
import { Utils } from '../../helpers'

const PATH = '/he-thong/nguoi-dung/chinh-sua/:id'
const PUSH = '/he-thong/nguoi-dung/phan-quyen'
const SUA_PHAN_QUYEN_NGUOI_DUNG = 'Sửa người dùng'
const PHAN_QUYEN_NGUOI_DUNG = 'Tạo người dùng'
class UpdateDecentralizationUserPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    staff: null,
    donViId: '',
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.staff.item !== this.props.staff.item) {
      this.setState({ staff: nextProps.staff.item })
    }
  }

  componentDidMount() {
    let donViId = Utils.getData('Permission-Unit', {})
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined

    if (donViId === undefined) {
      donViId = this.props.auth.mainUnitId
    }

    this.setState({ donViId }, () => {
      if (permission) {
        this.props.actions.getCategoryUnitMultiLevel()
      } else {
        this.props.actions.getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
      }
      if (this.props.match.path !== PATH) {
        this.props.actions.getCategoryDepartmentMultiLevel(donViId)
        this.props.actions.commonFetchPosition(donViId)
        this.props.actions.getCommonFetchRole(donViId, 1)
      }
    })

    if (this.props.match.params && this.props.match.params.id) {
      return this.props.actions.getStaffItem(this.props.match.params.id)
    }
  }

  handleSubmit(isSuccess = false) {
    if (isSuccess === true) {
      this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    if (this.props.match.path === PATH) {
      return this.props.history.goBack()
    }

    this.props.history.push(PUSH)
  }

  handleResetSignNumberToken = () => {
    this.setState({ resetLoading: true })
    this.props.actions
      .resetSignNumberToken(this.state.staff.nhanVienId)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGER.SIGN_NUMBER_RESET_FAILED,
            intent: Intent.DANGER,
          })
        }

        this.props.actions.commonAddToasterMessage({
          message: MESSAGER.SIGN_NUMBER_RESET_SUCCESS,
          intent: Intent.SUCCESS,
        })
      })
      .then(() => {
        this.setState({ resetLoading: false })
      })
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={
                this.state.staff
                  ? SUA_PHAN_QUYEN_NGUOI_DUNG
                  : PHAN_QUYEN_NGUOI_DUNG
              }
              iconPage="icon-decentralization-users"
            />
            <UpdateDecentralizationUser
              unit={this.props.unit.items}
              position={this.props.position.items}
              departments={this.props.departments.items}
              role={this.props.role.items}
              actions={this.props.actions}
              auth={this.props.auth}
              staff={this.state.staff}
              onSubmit={this.handleSubmit}
              match={this.props.match}
              onResetSignNumberToken={this.handleResetSignNumberToken}
              resetLoading={this.state.resetLoading}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },

  unit: {
    ...state.category,
    items: state.category.categoryUnitMultiLevel,
  },

  position: {
    ...state.common,
    items: state.common.position,
  },

  departments: {
    ...state.category,
    items: state.category.categoryDepartmentMultiLevel,
  },

  role: {
    ...state.common,
    items: state.common.role,
  },

  staff: {
    ...state.common,
    item: state.common.staffItem,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateDecentralizationUserPage)
