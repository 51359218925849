import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  Button,
  Popover,
  PopoverInteractionKind,
  Position,
  Menu,
  MenuItem,
  Icon,
  Intent,
} from '@blueprintjs/core'
import csx from 'classnames'
import { get, size } from 'lodash'
import { withRouter } from 'react-router-dom'

import { Card } from '../index'

const CustomOverlay = ({
  actionDisabled = false,
  isOpen = false,
  title = '',
  onClose = () => {},
  onSubmit = () => {},
  submitText = 'Lưu',
  submitIcon = 'icon-save',
  children = null,
  mainClassName = '',
  contentPadding = true,
  showFooter = true,
  menu = [],
  submitLoading = false,
  location,
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const iconGoBack = get(location, 'state.showGoBack', false)

  const [isStretch, setIsStrech] = useState(false)

  const close = () => {
    if (!submitLoading && !actionDisabled) {
      onClose()
    }
  }

  const toggleStretch = useCallback(() => {
    const pos = window.pageYOffset
    _mountedSet(setIsStrech, pos >= 25)
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', toggleStretch)
    return () => window.removeEventListener('scroll', toggleStretch)
  }, [toggleStretch])

  if (!isOpen) {
    return null
  }

  return createPortal(
    <div className="cpc-overlay">
      <div
        className={csx('cpc-overlay-popup', {
          stretch: isStretch,
        })}
      >
        {/* Header */}
        <Card bordered padding className="popup-title">
          <Icon
            className={csx('back-button', {
              'cursor-not-allowed': submitLoading || actionDisabled,
            })}
            iconName={iconGoBack ? 'arrow-left' : 'cross'}
            onClick={close}
          />
          {title}
          {!!size(menu) && (
            <Popover
              popoverClassName="cpc-popover no-arrow  mt0"
              position={Position.LEFT_TOP}
              interactionKind={PopoverInteractionKind.CLICK}
              content={
                <Menu className="cpc-context-menu no-border">
                  {menu.map(({ text = '', onClick = () => {} }, index) => (
                    <MenuItem
                      key={index}
                      onClick={onClick}
                      text={
                        <span className="cpc-context-menu-item">{text}</span>
                      }
                    />
                  ))}
                </Menu>
              }
            >
              <Icon className="menu-button" iconName="more" />
            </Popover>
          )}
        </Card>

        {/* Main */}
        <Card
          bordered
          padding={contentPadding}
          className={csx('popup-content', mainClassName)}
        >
          {children}
        </Card>

        {/* Footer */}
        {showFooter && (
          <Card bordered padding className="cpc-footer-button-group">
            <Button
              className={csx('cpc-button default', 'uppercase')}
              disabled={submitLoading || actionDisabled}
              onClick={close}
            >
              <i className="icon-back mr5" />
              Đóng
            </Button>
            <Button
              className={csx('cpc-button', 'uppercase')}
              intent={Intent.PRIMARY}
              disabled={actionDisabled}
              loading={submitLoading}
              onClick={onSubmit}
            >
              {submitText}
              <i className={csx('ml5', submitIcon)} />
            </Button>
          </Card>
        )}
      </div>
    </div>,
    document.querySelector('body')
  )
}

export default withRouter(memo(CustomOverlay))
