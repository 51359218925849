import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Intent } from '@blueprintjs/core'

import { Card, Icon, SliderBar } from 'components/newCommon'
import { Input } from 'components/newCommon2'
import requestAction from 'helpers/request'
import { Action, Toast } from 'constants/MessageForm'
import { showToast, getCongViec, postAccept } from 'actions/task'

const AcceptTaskKLCH = ({ dataSource, onClose, onSuccess }) => {
  const id = dataSource?.id
  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!mounted?.current && setFunction(state)
  const [fetching, setFetching] = useState(false)
  const [formData, setFormData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const changeField = name => value => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleAccept = useCallback(() => {
    const data = {
      work_item_id: formData?.id,
      description: formData?.description,
      progress: formData?.progress,
    }

    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      beforeAction: () => mountedSet(setIsLoading, true),
      action: () => postAccept(data),
      afterResponse: response => {
        if (response?.code === 200 && !response?.result) {
          return showToast({
            message: response?.message,
            intent: Intent.WARNING,
          })
        } else if (response?.code === 201) {
          showToast({
            message: Toast.SUCCESS(Action.ACCEPT),
            intent: Intent.SUCCESS,
          })
          return onSuccess()
        } else {
          return showToast({
            message: Toast.FAIL(Action.ACCEPT),
            intent: Intent.DANGER,
          })
        }
      },
      afterAction: () => {
        mountedSet(setIsLoading, false)
        return onClose()
      },
    })
  }, [formData, onClose, onSuccess])

  const fetchDetail = useCallback(() => {
    if (id) {
      requestAction({
        showToast: false,
        beforeAction: () => mountedSet(setFetching, true),
        action: () => getCongViec(id),
        afterResponse: (result = {}) => {
          setFormData(prev => ({
            ...prev,
            id: result?.id,
            progress: result?.progress,
          }))
        },
        afterAction: () => mountedSet(setFetching, false),
        afterError: () => {
          showToast({
            message: Toast.NOT_FOUND('công việc'),
            intent: Intent.WARNING,
          })
          return onClose()
        },
      })
    }
  }, [id, onClose])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  return (
    <div className={'cpc-form'}>
      <h1 className="d-flex justify-space-between align-center border-bottom ph14 pv15">
        <span
          className="icon2-arrow-dart-left font-size-14 cursor-pointer"
          onClick={onClose}
        ></span>
        <span className="text-13-500 text-uppercase color-gray">
          {'Xác nhận công việc'}
        </span>
        <span></span>
      </h1>
      <div className="p15">
        <Card className={'cpc-detail-block p10'}>
          <div className="align-center" style={{ height: '30px' }}>
            <label className="">Tiến độ</label>
          </div>
          <div className="pl5">
            <SliderBar
              onRelease={changeField('progress')}
              value={formData?.progress || 0}
            />
          </div>
          <Input
            disabled={fetching || isLoading}
            label="Mô tả"
            onChange={changeField('description')}
            placeholder="Nhập..."
            rows={4}
            maxLength={500}
            autoResize={true}
            style={{ maxHeight: 'unset' }}
            type="textarea"
            value={formData?.description}
            wrapperClassName="mt10"
          />
        </Card>
      </div>
      <div
        className={
          'd-flex align-items-center justify-content-center gap-15 bg-white border-radius-4 border-top ph15'
        }
      >
        <Button
          className={
            'cpc-button white-border-gray uppercase font-size-13 ph10 min-width-100'
          }
          disabled={fetching}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={'cpc-button blue uppercase font-size-13 ph10 min-width-80'}
          disabled={isLoading || fetching}
          loading={isLoading}
          onClick={handleAccept}
        >
          Lưu
          <Icon classIcon="icon-save" className="ml5" />
        </Button>
      </div>
    </div>
  )
}

AcceptTaskKLCH.defaultProps = {
  dataSource: null,
  onClose: () => {},
  onSuccess: () => {},
}

AcceptTaskKLCH.propTypes = {
  dataSource: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default memo(AcceptTaskKLCH)
