import { Cookies } from 'react-cookie'
import {
  AUTH_SET_TOKEN,
  AUTH_DISCARD_TOKEN,
  AUTH_SET_USER,
  AUTH_GET_PERMISSION,
  CONFIG_CABINET_TYPE,
  AUTH_SWITCH_ROLE,
} from '../constants/ActionTypes'
import * as Types from '../constants/Api'

const cookies = new Cookies()
let initialState = cookies.get('authState')
  ? cookies.get('authState')
  : {
      user: {
        dsChucDanh: [],
      },
    }

export default function auth(state = initialState, action) {
  let authState = state

  switch (action.type) {
    case AUTH_SET_TOKEN:
      authState = {
        ...state,
        token: action.payload.data,
      }
      break

    case AUTH_DISCARD_TOKEN:
      cookies.remove('Permission-Unit', { path: '/' })
      cookies.remove('Permission-Unit-filter', { path: '/' })
      cookies.remove('Permission-Department', { path: '/' })
      cookies.remove('Permission-Department-filter', { path: '/' })
      cookies.remove('UnitCategoryPage-Header', { path: '/' })
      cookies.remove('UnitCategoryPage-Header-filter', { path: '/' })
      cookies.remove('DepartmentCategoryPage', { path: '/' })
      cookies.remove('DepartmentCategoryPage-filter', { path: '/' })
      cookies.remove('DepartmentCategoryPage-Header', { path: '/' })
      cookies.remove('DepartmentCategoryPage-Header-filter', { path: '/' })
      cookies.remove('dsChucDanh', { path: '/' })
      cookies.remove('authState', { path: '/' })
      return (authState = {})

    case AUTH_SET_USER:
      let donViChinh = null
      let parentUnitId = null
      const idUser =
        action.payload.data &&
        action.payload.data.result &&
        action.payload.data.result.result
          ? action.payload.data.result.result
          : null
      let user
      if (idUser) {
        user =
          action.payload &&
          action.payload.data &&
          action.payload.data.entities &&
          action.payload.data.entities.users[idUser]
      }
      if (user && user.dsChucDanh) {
        if (
          action.meta &&
          action.meta.previousAction &&
          action.meta.previousAction.roleId
        ) {
          donViChinh = user.dsChucDanh.find(
            role => role.id === action.meta.previousAction.roleId
          )
        } else {
          donViChinh = user.dsChucDanh.find(role => role.phongBanChinh)
        }
      }
      if (donViChinh && donViChinh.donVi && donViChinh.donVi.donViCha) {
        parentUnitId = donViChinh.donVi.donViCha
      }

      authState = {
        ...state,
        user,
        mainUnitId:
          donViChinh && donViChinh.donViId ? donViChinh.donViId : null,
        mainDepartmentId:
          donViChinh && donViChinh.phongBanId ? donViChinh.phongBanId : null,
        defaultCabinetType: Types.CABINET_PERSONAL,
        roleId: donViChinh && donViChinh.id ? donViChinh.id : null,
        parentUnitId,
      }
      break

    case AUTH_GET_PERMISSION:
      authState = {
        ...state,
        permission: action.payload.data.result.items.map(i => i.maQuyen) || [],
      }
      break

    case CONFIG_CABINET_TYPE:
      authState = {
        ...state,
        defaultCabinetType: action.cabinetType,
      }
      break

    case AUTH_SWITCH_ROLE:
      let donViHienTai = null
      let parentCurrentUnitId = null
      let roleId = action.data.roleId
      const currentUser = state.user
      if (currentUser && currentUser.dsChucDanh) {
        donViHienTai = currentUser.dsChucDanh.find(role => role.id === roleId)
      }
      if (donViHienTai && donViHienTai.donVi && donViHienTai.donVi.donViCha) {
        parentCurrentUnitId = donViHienTai.donVi.donViCha
      }

      authState = {
        ...state,
        user: currentUser,
        mainUnitId:
          donViHienTai && donViHienTai.donViId ? donViHienTai.donViId : null,
        mainDepartmentId:
          donViHienTai && donViHienTai.phongBanId
            ? donViHienTai.phongBanId
            : null,
        defaultCabinetType: Types.CABINET_PERSONAL,
        roleId,
        parentUnitId: parentCurrentUnitId,
      }
      break
    default:
      authState = state
  }

  cookies.set(
    'authState',
    {
      ...authState,
      user: {
        ...authState.user,
        dsChucDanh: [],
      },
    },
    {
      path: '/',
    }
  )

  return authState
}
