import React, { memo, useCallback, useState, Fragment } from 'react'
import {
  Checkbox,
  Classes,
  Button,
  Intent,
  Popover,
  Menu,
  MenuDivider,
  Position,
  MenuItem,
  PopoverInteractionKind,
  Tooltip,
} from '@blueprintjs/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FileDownload from 'file-saver'
import { connect } from 'react-redux'
import moment from 'moment'
import get from 'lodash/get'
import size from 'lodash/size'
import uniq from 'lodash/uniq'
import difference from 'lodash/difference'
import { Action, Toast } from 'constants/MessageForm'
import { withCookies } from 'react-cookie'

import { ASSIGN_TYPE } from 'constants/Enum'
import requestAction from 'helpers/request'
import { MasterLayout } from 'components/layout'
import Title from 'components/title'
import { Table, Pagination } from 'components/newCommon2'
import { TruncateTextTooltip } from 'components/common'
import { showToast, getMeetingsExport } from 'actions/task'
import FilterThongbaoKLCH from './components/filter-thongbao-klch'
import DeleteThongbaoKLCH from './components/confirm/delete-thongbao-klch'
import { Input, Select, Icon, Avatar } from 'components/newCommon'
import useTaskThongbaoKLCH from './hooks/use-task-thongbao-klch'

const TaskThongBaoKLCH = ({
  history,
  roleId,
  mainUnitId,
  dataLanhDaoDonVi,
}) => {
  const DATA_FORMAT = 'DD/MM/YYYY'
  const PAGE_SIZE = 10

  const {
    filterKeys,
    filter,
    isFetching,
    fetchingFail,
    thongBaoKLCHData,
    page,
    deleteData,
    setDeleteData,
    onChangeFilter,
  } = useTaskThongbaoKLCH({ mainUnitId })

  const [isLoadingExcel, setIsLoadingExcel] = useState(false)
  const [selectItem, setSelectItem] = useState([])

  const getMenuAction = useCallback(
    ({ id, creatorProfileId } = {}) => {
      const allowAction = creatorProfileId === roleId
      return [
        ...(allowAction
          ? [
              {
                icon: 'icon-but',
                text: 'Sửa',
                onClick: () =>
                  history.push(
                    `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/chinh-sua/${id}`
                  ),
              },
            ]
          : []),
        ...(allowAction
          ? [
              {
                icon: 'icon-bin',
                text: 'Xóa',
                onClick: () => setDeleteData({ id: id }),
              },
            ]
          : []),
      ]
    },

    [roleId, history, setDeleteData]
  )

  const handleSelectRecord = (e, id) => {
    if (e.target.checked) {
      setSelectItem([...selectItem, id])
    } else {
      setSelectItem(selectItem.filter(item => item !== id))
    }
  }

  const columns = [
    {
      key: 'textNumber',
      width: 10,
      headClass: 'uppercase',
      className: 'align-center min-width-160',
      title: (
        <div
          className="d-flex align-items-center"
          onClick={e => e.stopPropagation()}
        >
          <Checkbox
            className={classNames(Classes.SMALL, 'mb0')}
            checked={
              thongBaoKLCHData?.items &&
              size(thongBaoKLCHData?.items) ===
                size(
                  thongBaoKLCHData?.items.filter(e =>
                    selectItem.includes(e?.id)
                  )
                )
            }
            onChange={e => onSelectAll(e.target.checked)}
          />
          Số hiệu văn bản
        </div>
      ),
      filter: (
        <Input
          clearable
          onChange={onChangeFilter({
            name: filterKeys.textNumber,
            isReFetch: false,
          })}
          onClear={onChangeFilter({ name: filterKeys.textNumber })}
          onSend={onChangeFilter({ name: filterKeys.textNumber })}
          placeholder="Nhập"
          value={filter?.textNumber || ''}
        />
      ),
      render: ({ textNumber }, record) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={e => e.stopPropagation()}
          >
            <Checkbox
              className={classNames(Classes.SMALL, 'mb0')}
              checked={selectItem.includes(record.id)}
              onChange={e => handleSelectRecord(e, record.id)}
            />
            <span className="white-space-break-spaces">{textNumber}</span>
          </div>
        )
      },
    },
    {
      key: 'title',
      width: 20,
      headClass: 'uppercase',
      className: 'min-width-200 align-center min-width-100',
      title: 'Tên cuộc họp',
      filter: (
        <Input
          clearable
          onChange={onChangeFilter({
            name: filterKeys.title,
            isReFetch: false,
          })}
          onClear={onChangeFilter({ name: filterKeys.title })}
          onSend={onChangeFilter({ name: filterKeys.title })}
          placeholder="Nhập"
          value={filter?.title || ''}
        />
      ),
      render: record => {
        return (
          <span className="white-space-break-spaces">
            {record?.title?.length > 20 ? (
              <TruncateTextTooltip content={record?.title} />
            ) : (
              record?.title
            )}
          </span>
        )
      },
    },
    {
      key: 'executionDate',
      width: 5,
      headClass: 'uppercase',
      className: 'min-width-160 align-center',
      title: 'Ngày ban hành',
      sortKey: 'executionDate',
      render: ({ executionDate }) => {
        return moment(executionDate).isValid()
          ? moment(executionDate).format(DATA_FORMAT)
          : ''
      },
    },
    {
      key: 'mainResponsibility',
      width: 15,
      headClass: 'uppercase',
      className: 'min-width-240 align-center',
      title: 'Lãnh đạo chủ trì',
      dataIndex: 'mainResponsibility',
      filter: (
        <Select
          dataSource={dataLanhDaoDonVi?.map(elm => ({
            label: elm?.tenNhanVien,
            value: elm?.chucDanhId,
          }))}
          disabled={isFetching}
          inputIconClassName="icon-flag"
          onChange={onChangeFilter({ name: filterKeys.mainResponsibilityId })}
          placeholder="Chọn"
          value={get(filter, 'mainResponsibilityId')}
        />
      ),
      render: value => {
        if (get(value, 'type') === ASSIGN_TYPE.CA_NHAN) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={get(value, 'avatar')}
                className="mr10 tiny"
                needBaseUrl
              />
              <span>{get(value, 'name')}</span>
            </div>
          )
        }

        return get(value, 'shortName')
      },
    },
    {
      key: 'creationTime',
      width: 3,
      headClass: 'uppercase',
      className: 'min-width-120 align-center',
      title: 'Ngày tạo',
      sortKey: 'creationTime',
      render: ({ creationTime }) => {
        return moment(creationTime).isValid()
          ? moment(creationTime).format(DATA_FORMAT)
          : ''
      },
    },
    {
      key: 'organizationApproved',
      width: 10,
      headClass: 'uppercase text-center',
      className: 'min-width-100 align-center justify-center',
      title: 'Ban/Đơn vị duyệt',
      render: ({ organizationApproved }) => {
        return <span className="font-weight-600">{organizationApproved}</span>
      },
    },
    {
      key: 'secretaryApproved',
      width: 10,
      headClass: 'uppercase text-center',
      className: 'min-width-100 align-center justify-center',
      title: 'Thư ký duyệt',
      render: ({ secretaryApproved }) => {
        return <span className="font-weight-600">{secretaryApproved}</span>
      },
    },
    {
      key: 'thaoTac',
      className: 'element-center max-width-60 min-width-60 align-center',
      width: 5,
      render: record => {
        const menu = getMenuAction(record) || []
        if (!size(menu)) return null
        return (
          <Popover
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            content={
              <Menu className="cpc-context-menu border-none">
                {menu.map(({ icon, text, onClick = () => {} }, index) => (
                  <Fragment key={index}>
                    {!!index && <MenuDivider />}
                    <MenuItem
                      text={
                        <span className="cpc-context-menu-item">
                          <i className={classNames('size-icon-15', icon)} />{' '}
                          {text}
                        </span>
                      }
                      onClick={onClick}
                    />
                  </Fragment>
                ))}
              </Menu>
            }
          >
            <span
              className="icon-More size-icon-24"
              onClick={e => e.stopPropagation()}
            />
          </Popover>
        )
      },
    },
  ]

  const handleExport = useCallback(() => {
    requestAction({
      showToast: false,
      getResult: false,
      codeCheck: false,
      beforeAction: () => setIsLoadingExcel(true),
      action: () =>
        getMeetingsExport({
          ...filter,
          page: 1,
          limit: 9999,
        }),
      afterResponse: response => {
        FileDownload.saveAs(response, 'thong-bao-ket-luan-cuoc-hop.xls')
      },
      afterAction: () => setIsLoadingExcel(false),
      afterError: () => {
        showToast({
          message: Toast.FAIL(Action.EXPORT_EXCEL),
          intent: Intent.DANGER,
        })
      },
    })
  }, [filter])

  const onSelectAll = useCallback(
    isChecked => {
      const ids = thongBaoKLCHData?.items?.map(elm => elm?.id) || []
      if (isChecked) {
        return setSelectItem(prev => uniq([...prev, ...ids]))
      }

      setSelectItem(prev => uniq(difference(prev, ids)))
    },
    [thongBaoKLCHData]
  )

  const openStatistical = useCallback(() => {
    const url = `/tien-ich/thong-bao-ket-luan-cuoc-hop/thong-ke`
    return history.push({
      pathname: url,
      state: {
        ids: selectItem,
      },
    })
  }, [history, selectItem])

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="d-flex align-items-center justify-content-space-between mt10">
        <Title name={'Thông báo kết luận cuộc họp'} icon="icon2-clipboard" />
        <div className="d-flex gap-10">
          <Tooltip content={<span className="font-size-12">Xuất excel</span>}>
            <Button
              className={'cpc-button cpc-button-green'}
              loading={isLoadingExcel}
              disabled={size(thongBaoKLCHData?.items) === 0 || isFetching}
              onClick={handleExport}
            >
              <span className="mr5 color-white">Xuất excel</span>
              <Icon classIcon="icon2-export color-white" />
            </Button>
          </Tooltip>
          <Tooltip content={<span className="font-size-12">Thống kê</span>}>
            <Button
              className={'cpc-button blue'}
              onClick={openStatistical}
              disabled={size(thongBaoKLCHData?.items) === 0 || isFetching}
            >
              <span className="mr5">Thống kê</span>
              <Icon classIcon="icon2-chart-histogram" />
            </Button>
          </Tooltip>
          <Tooltip
            position={Position.TOP_RIGHT}
            content={
              <span className="font-size-12">
                Tạo thông báo kết luận cuộc họp
              </span>
            }
          >
            <Button
              className={'cpc-button blue'}
              disabled={isFetching}
              onClick={() =>
                history.push(
                  `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/them-moi`
                )
              }
            >
              <span className="mr5">Tạo mới</span>
              <Icon classIcon="icon-Plus" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="border mt10">
        <FilterThongbaoKLCH
          filterKeys={filterKeys}
          dataSource={null}
          dataValue={filter}
          onChangeFilter={onChangeFilter}
        />
        <Table
          className="cpc-table-giaodienmoi"
          autoHide={false}
          columns={columns}
          data={thongBaoKLCHData?.items}
          loading={isFetching}
          error={fetchingFail}
          tableMinWidth={1200}
          onSort={onChangeFilter({ name: 'sort', isReFetch: true })}
          onRowClick={({ id }) =>
            history.push(
              `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/chi-tiet/${id}`
            )
          }
        />
        {thongBaoKLCHData?.totalCount > PAGE_SIZE && (
          <div className="text-center bg-white ph15 pv20 border-top">
            <Pagination
              total={thongBaoKLCHData?.totalCount}
              current={page}
              pageSize={PAGE_SIZE}
              onChange={onChangeFilter({ name: 'PAGE' })}
            />
          </div>
        )}
        {deleteData ? (
          <DeleteThongbaoKLCH
            dataSource={deleteData}
            onClose={() => setDeleteData(null)}
            onSuccess={() => {
              setDeleteData(null)
              onChangeFilter({ name: 'PAGE' })(1)
            }}
          />
        ) : (
          ''
        )}
      </div>
    </MasterLayout>
  )
}

TaskThongBaoKLCH.propTypes = {
  mainUnitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mainDepartmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const mapStateToProps = state => {
  const roleId = state?.auth?.roleId

  return {
    roleId,
    mainUnitId: get(state, 'auth.mainUnitId'),
    mainDepartmentId: get(state, 'auth.mainDepartmentId'),
    dataLanhDaoDonVi: get(state, 'common.dataLanhDaoDonVi'),
  }
}

export default withCookies(connect(mapStateToProps)(memo(TaskThongBaoKLCH)))
