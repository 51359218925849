import { schema } from 'normalizr'

const Project = new schema.Entity(
  'projects',
  {},
  {
    idAttribute: project => project.duAnId,
  }
)

export default Project
