import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NotFound } from '../common'
import ExecutingViewBox from '../common/ExecutingViewBox'
import moment from 'moment'
import * as Types from '../../constants/Api'

class Flowdocumentary extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    flowDocument: PropTypes.object.isRequired,
    hiddenName: PropTypes.bool,
    onClickDepartment: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isOpen: true,
    openSub: {},
  }

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { flowDocument } = this.props
    return (
      <div className="container-form-action">
        {!this.props.hiddenName && (
          <div className="heading">
            <span className="click-go-back" onClick={this.props.onClickGoBack}>
              <span className="pt-icon pt-icon-arrow-left pull-left"></span>
            </span>
            <h4 className="name-heading">Luồng công văn</h4>
          </div>
        )}
        <div className="form-container container-detail">
          {!this.props.flowDocument.nguoiTaoCongVan ? (
            <NotFound />
          ) : (
            <div className="view-job-done">
              {flowDocument.nguoiTaoCongVan && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            flowDocument.nguoiTaoCongVan &&
                            flowDocument.nguoiTaoCongVan.anhDaiDien
                              ? Types.IMAGE_URL +
                                flowDocument.nguoiTaoCongVan.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">
                        {flowDocument.nguoiTaoCongVan &&
                          flowDocument.nguoiTaoCongVan.tenNhanVien}
                      </span>
                    </div>
                  </div>
                  <div className="info-job">
                    {flowDocument.nguoiTaoCongVan.ngayTao && (
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {flowDocument.nguoiTaoCongVan.ngayTao &&
                            ' ' +
                              moment(
                                flowDocument.nguoiTaoCongVan.ngayTao
                              ).format('DD/MM/YYYY HH:mm')}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {flowDocument.chuyenChiDao &&
                flowDocument.chuyenChiDao.length > 0 &&
                flowDocument.chuyenChiDao.map((item, i) => (
                  <div className="box-level-primary item-box" key={i}>
                    <div className="avatar-user">
                      <div className="click-avatar">
                        <span
                          className="avatar"
                          style={{
                            backgroundImage: `url(${
                              item.nguoiChuyenChiDao &&
                              item.nguoiChuyenChiDao.anhDaiDien
                                ? Types.IMAGE_URL +
                                  item.nguoiChuyenChiDao.anhDaiDien
                                : '/images/default_avatar.png'
                            })`,
                          }}
                        ></span>
                        <span className="name_user">
                          {item.nguoiChuyenChiDao &&
                            item.nguoiChuyenChiDao.tenNhanVien}
                        </span>
                      </div>
                    </div>
                    <div className="info-job">
                      {item.nguoiChuyenChiDao.ngayTao && (
                        <span className="info-job-item">
                          <span className="title">Ngày:</span>
                          <span className="content">
                            {item.nguoiChuyenChiDao.ngayTao &&
                              ' ' +
                                moment(item.nguoiChuyenChiDao.ngayTao).format(
                                  'DD/MM/YYYY HH:mm'
                                )}
                          </span>
                          <span className="title">
                            &nbsp; - &nbsp; Chuyển chỉ đạo:
                          </span>
                          <span className="content">
                            {item.phongBanChuyenDen &&
                              item.phongBanChuyenDen.tenPhongBan}
                          </span>
                          <span className="title">
                            &nbsp; - &nbsp; Nội dung:
                          </span>
                          <span className="content">
                            {item.noiDung ? item.noiDung : ''}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              {flowDocument.chiDao && flowDocument.chiDao.chiDaoId && (
                <ExecutingViewBox
                  item={flowDocument.chiDao}
                  {...this.props}
                  auth={this.props.auth}
                />
              )}
              {(!flowDocument.chiDao ||
                (flowDocument.chiDao && !flowDocument.chiDao.chiDaoId)) &&
                flowDocument.phongBanTrinh &&
                flowDocument.phongBanTrinh.maPhongBan && (
                  <div className="box-level-sub item-box">
                    <div className="drop-down-sub">
                      <div className="click-avatar">
                        <span className="name-department">
                          {flowDocument.phongBanTrinh.maPhongBan}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Flowdocumentary
