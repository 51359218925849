import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  render() {
    const { item, number } = this.props
    return (
      <tr className="gerenal-list-utilities">
        <td className="gerenal-list-utilities">
          {number && number > 9 ? number : '0' + number}
        </td>
        <td className="gerenal-list-utilities">{item && item.soCongVan}</td>
        <td className="gerenal-list-utilities">
          {item && item.ngayCongVan
            ? moment(item.ngayCongVan).format('DD/MM/YYYY')
            : ''}
        </td>
        <td className="textarea-content gerenal-list-utilities">
          {item && item.trichYeu}
        </td>
      </tr>
    )
  }
}

export default ListItem
