import React from 'react'
import { MasterLayout } from '../../components/layout'
import { InputForm } from '../../components/AdminNotification'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class AddAdminNotificationPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickCancel = this.handleClickCancel.bind(this)

    this.state = {
      donViId: this.props.auth.mainUnitId,
      unitSelect: [],
      notification: null,
      notificationId: null,
      isLoadingData: {
        units: true,
        notification: true,
      },
      isError: {
        units: true,
        notification: true,
      },
      isUpdate: false,
    }
  }

  static propTypes = {
    notifications: PropTypes.object,
  }

  handleChange(e) {
    if (e !== undefined) {
      this.setState({
        donViId: e,
      })
    }
  }

  handleSubmit = params => {
    if (params && params.isSuccess === true) {
      this.setState({ notification: null })
      this.props.actions.commonAddToasterMessage({
        message: params.message,
        intent: params.intent,
      })
      Promise.all([
        this.props.actions.notificationFetchList(1, false),
        this.props.actions.notificationFetchList(1, true),
      ]).then(() => this.props.actions.notificationGetLastest())
      return this.props.history.goBack()
    }
    return this.props.actions.commonAddToasterMessage({
      message: params.message,
      intent: params.intent,
    })
  }

  handleClickCancel = () => {
    this.props.history && this.props.history.goBack()
  }

  componentWillMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      this.setState({
        notificationId: this.props.match.params.id,
        isUpdate: true,
      })
    } else {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          notification: false,
        },
        isError: {
          ...this.state.isError,
          notification: false,
        },
      })
    }
  }

  componentDidMount() {
    // Get api don vi
    let permission =
      this.props.auth &&
      this.props.auth.permission &&
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
        undefined
    let request = this.props.actions.getCategoryUnitMultiLevel(
      this.props.auth.mainUnitId
    )
    if (permission) {
      request = this.props.actions.getCategoryUnitMultiLevel()
    }
    request
      .then(res => {
        let state = this.state
        if (
          res.payload &&
          res.payload.status === 200 &&
          this.props.unitSelect &&
          this.props.unitSelect.items
        ) {
          state.unitSelect = this.props.unitSelect.items
        }

        if (res.error || !res.payload || res.payload.status !== 200) {
          state.isError.units = true
        }
        state.isLoadingData.units = false
        this.setState(state)
      })
      .catch(err => {
        this.setState({
          isError: {
            ...this.state.isError,
            units: true,
          },
          isLoadingData: {
            ...this.state.isLoadingData,
            units: false,
          },
        })
      })

    if (this.state.notificationId) {
      this.props.actions
        .notificationFetchItem(this.state.notificationId)
        .then(res => {
          if (
            res.payload &&
            res.payload.status === 200 &&
            this.props.notifications &&
            this.props.notifications.item
          ) {
            let donViId = this.state.donViId
            if (this.props.notifications.item.donViId) {
              donViId = this.props.notifications.item.donViId
            }
            this.setState(
              { notification: this.props.notifications.item, donViId },
              () => {
                this.setState({
                  isLoadingData: {
                    ...this.state.isLoadingData,
                    notification: false,
                  },
                })
              }
            )
          }
          if (res.error || !res.payload || res.payload.status !== 200) {
            this.setState({
              isError: {
                ...this.state.isError,
                notification: true,
              },
              isLoadingData: {
                ...this.state.isLoadingData,
                notification: false,
              },
            })
          }
        })
        .catch(e => {
          this.setState({
            isError: {
              ...this.state.isError,
              notification: true,
            },
            isLoadingData: {
              ...this.state.isLoadingData,
              notification: false,
            },
          })
        })
    }
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.unitSelect.items !== this.props.unitSelect.items) {
      this.setState({
        unitSelect: nextProps.unitSelect.items,
      })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="HomePage">
        <div className="row center-xs form-AddAdminNotificationPage">
          <div className="col-md-7 col-xs-12 col-sm-12 padding-0 size-width-AdminNotification">
            <div className="heading-page bottom-add-receive-document">
              <h3 className="pull-left text-heading-page">
                <span className="pt-icon pt-icon-annotation"></span>
                <span className="text-heading">
                  {this.state.notificationId ? 'Sửa bài viết' : 'Tạo bài viết'}
                </span>
              </h3>
            </div>
            <InputForm
              data={this.state.unitSelect}
              type="Unit"
              permission={false}
              donViId={
                this.state.unitSelect.length > 0 ? this.state.donViId : ''
              }
              onHandleChange={this.handleChange}
              onSubmit={this.handleSubmit}
              actions={this.props.actions}
              auth={this.props.auth}
              onClickCancel={this.handleClickCancel}
              notification={this.state.notification}
              isUpdate={this.state.isUpdate}
              isLoadingData={this.state.isLoadingData}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },

  notifications: {
    ...state.notifications,
    item: state.entities.notifications[state.notifications.detailId],
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAdminNotificationPage)
