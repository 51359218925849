import React, { memo } from 'react'
import csx from 'classnames'

import { IMAGE_URL } from 'constants/Api'

const Avatar = ({
  src,
  className,
  small = false,
  tinytan = false,
  needBaseUrl = false,
  notRadius = false,
  bgSize = 'cover',
  ...props
}) => {
  let newSrc = needBaseUrl ? `${IMAGE_URL}${src}` : src
  if (src === null || !src) {
    newSrc = '/images/default_avatar.jpeg'
  }

  return (
    <span
      className={csx(
        'cpc-avatar',
        {
          small,
        },
        {
          tinytan,
        },
        className
      )}
    >
      <span
        className={csx('image', {
          contain: bgSize === 'contain',
          'not-radius': notRadius,
        })}
        style={{ backgroundImage: `url(${newSrc})` }}
      >
        <img {...props} src={newSrc} alt={src} />
      </span>
    </span>
  )
}

export default memo(Avatar)
