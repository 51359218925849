import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import Feedback from './Feedback'
import PropTypes from 'prop-types'
import { Intent, Button } from '@blueprintjs/core'
import 'moment/locale/vi'
import moment from 'moment'
import { LoadingItem, PopupConfirm } from '../../components/common/'
import * as MESSAGE from '../../constants/MessageForm'
import DetailStatusProject from './DetailStatusProject'
import UpdateHistory from './UpdateHistory'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import * as POPUP from '../../constants/Popup'

const VNPT = 1
const VIETTEL = 0
class DetailBoxActionKSTT extends Component {
  constructor(props) {
    super(props)
    this.sendFeedback = this.sendFeedback.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleClickAgreeBySIM = this.handleClickAgreeBySIM.bind(this)
    this.handleClickAgreeByTOKEN = this.handleClickAgreeByTOKEN.bind(this)
    this.handleClickDisagree = this.handleClickDisagree.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleClickReserve = this.handleClickReserve.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.sendProgression = this.sendProgression.bind(this)
    this.handleUpdateFeedback = this.handleUpdateFeedback.bind(this)
    this.updateFeedback = this.updateFeedback.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    feedbacks: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    isLoadingData: PropTypes.object.isRequired,
  }

  state = {
    isOpenPopup: false,
    error: {},
    feedbackContent: '',
    chucDanhId: null,
    signNumberId: null,
    isLoading: {
      agreeBySIM: false,
      agreeBySIMVNPT: false,
      agreeByTOKEN: false,
      disagree: false,
      sendFeedback: false,
      progression: false,
      checkProgression: false,
      reverse: false,
    },
    isShowButton: true,
    soCongVan: '',
    ngayCongVan: moment(),
    truongKy: null,
    loaiKy: null,
    feedbackId: null,
    hrefToken: null,
    isDisagree: false,
    isAgree: false,
    isNotAgree: false,
    isReverse: false,
    isProgression: false,
    isButtonConfirm: false,
    isSubmitFeedbackSuccess: null,
    ngayCongVanToString: moment()
      .format('YYYY-MM-DD')
      .toString()
      .replace(/-/g, ''),
    dateToToString: moment().format('DD/MM/YYYY'),
    buoc: null,
    isCreator: false,
    thanhVien: {},
  }

  componentDidMount() {
    let {
      chucDanhId,
      ngayCongVan,
      ngayCongVanToString,
      isCreator,
      signNumberId,
      isShowButton,
      soCongVan,
      dateToToString,
      loaiKy,
      buoc,
      truongKy,
      hrefToken,
      isNotAgree,
      isDisagree,
      isAgree,
      isReverse,
      isProgression,
      hrefProgression,
      thanhVien,
      isButtonConfirm,
    } = this.state
    chucDanhId = _.get(this.props, 'auth.roleId', null)
    const phongBanId = _.get(this.props, 'auth.mainDepartmentId', null)
    let maDoiTuong = null
    if (phongBanId) {
      const chucDanh = _.get(this.props, 'auth.user.dsChucDanh', []).find(
        i => i.phongBanId === phongBanId
      )
      maDoiTuong = _.get(chucDanh, 'doiTuong.maDoiTuong', null)
    }
    ngayCongVan = moment()
    ngayCongVanToString = moment()
      .format('YYYY-MM-DD')
      .toString()
      .replace(/-/g, '')
    const { ctKySoTapTrung } = this.props

    if (ctKySoTapTrung) {
      if (_.get(ctKySoTapTrung, 'nguoiLap.chucDanhId', false)) {
        isCreator =
          _.get(ctKySoTapTrung, 'nguoiLap.chucDanhId', '') === chucDanhId
      }
      signNumberId = _.get(ctKySoTapTrung, 'vanBanKySoId', '')
      isShowButton = false
      // let { buoc, tinhTrang, soVanBan, ngayCongVan, dsThanhVien } = this.props.ctKySoTapTrung;
      if (
        ctKySoTapTrung.tinhTrang === 6 &&
        ctKySoTapTrung.soVanBan &&
        !isNaN(Number(ctKySoTapTrung.soVanBan)) &&
        Number(ctKySoTapTrung.soVanBan) > 0
      ) {
        soCongVan = ctKySoTapTrung.soVanBan
      }
      if (
        ctKySoTapTrung.tinhTrang === 6 &&
        ctKySoTapTrung.ngayCongVan &&
        Tool.Utils.isValidDate(
          moment(ctKySoTapTrung.ngayCongVan).format('DD/MM/YYYY')
        )
      ) {
        ngayCongVan = moment(ctKySoTapTrung.ngayCongVan)
        ngayCongVanToString = moment(ctKySoTapTrung.ngayCongVan)
          .format('YYYY-MM-DD')
          .toString()
          .replace(/-/g, '')
        dateToToString = moment(ctKySoTapTrung.ngayCongVan).format('DD/MM/YYYY')
      }

      if (ctKySoTapTrung.dsThanhVien) {
        ctKySoTapTrung.dsThanhVien.forEach(item => {
          if (
            item.chucDanhId === chucDanhId &&
            item.buoc < ctKySoTapTrung.buoc
          ) {
            return (thanhVien = item)
          }
          if (
            item.chucDanhId === chucDanhId &&
            item.buoc === ctKySoTapTrung.buoc
          ) {
            return (thanhVien = item)
          }
          if (
            item.phongBanId === phongBanId &&
            item.dsVaiTro &&
            item.dsVaiTro.includes(maDoiTuong) &&
            item.buoc <= ctKySoTapTrung.buoc &&
            item.trangThai === 0
          ) {
            return (thanhVien = item)
          }
        })
      }
      if (thanhVien) {
        switch (thanhVien.hinhThuc) {
          case Types.KSTT.XAC_NHAN:
            loaiKy = 'xacnhan'
            break

          case Types.KSTT.KHONG_HIEN_THI:
            loaiKy = 'khonghienthi'
            break

          case Types.KSTT.KY_KHONG_DAU:
            loaiKy = 'kykhongdau'
            break

          case Types.KSTT.KY_CO_DAU:
            loaiKy = 'kycodau'
            break

          case Types.KSTT.KY_NHAY:
            loaiKy = 'kynhay'
            break

          case Types.KSTT.CAP_SO:
            loaiKy = 'capso'
            break

          case Types.KSTT.DTXD:
            loaiKy = 'dtxd'
            break
          default:
        }
        buoc = thanhVien.buoc
        truongKy = thanhVien.truongKy
        hrefToken = `eofficecpc:id_vb=${signNumberId};id_nv=${chucDanhId};loaiky=${loaiKy};app=kstt`

        // Nêú là thành viên trong bước
        // + hoặc là tinhTrang !== 1
        // hoặc là chính là người không đồng ý
        // được ký duyệt thì hiển thị button
        if (
          ctKySoTapTrung.tinhTrang !== Types.DISAGREE ||
          (ctKySoTapTrung.tinhTrang === Types.DISAGREE &&
            thanhVien.trangThai === Types.DISAGREE)
        ) {
          isShowButton = true
        }
        // Nếu trạng thái ký của user === 0 thì enabled 3 button/cấp số
        if (thanhVien.trangThai === Types.NOT_YET_AGREE) {
          isNotAgree = true
        }
        // Nếu trạng thái ký của user === 1 thì enabled 2 button ký duyệt
        if (thanhVien.trangThai === Types.DISAGREE) {
          isDisagree = true
        }
        // Nếu trạng thái ký của user === 2 thì disabled 3 button
        if (thanhVien.trangThai === Types.AGREED) {
          isAgree = true
        }
        // Nếu trạng thái ký của user === 3 thì disabled 3 button
        if (thanhVien.trangThai === Types.REVERSE) {
          isReverse = true
        }
        // Nếu hình thức === 0 thì chỉ hiển thị button xác nhận
        if (thanhVien.hinhThuc === 0) {
          isButtonConfirm = true
        }
      }
      this.setState({
        chucDanhId,
        ngayCongVan,
        ngayCongVanToString,
        isCreator,
        signNumberId,
        isShowButton,
        soCongVan,
        dateToToString,
        loaiKy,
        buoc,
        truongKy,
        hrefToken,
        isNotAgree,
        isDisagree,
        isAgree,
        isReverse,
        isProgression,
        hrefProgression,
        thanhVien,
        isButtonConfirm,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.ctKySoTapTrung !== this.props.ctKySoTapTrung &&
      this.props.ctKySoTapTrung
    ) {
      let {
        chucDanhId,
        ngayCongVan,
        ngayCongVanToString,
        isCreator,
        signNumberId,
        isShowButton,
        soCongVan,
        dateToToString,
        loaiKy,
        buoc,
        truongKy,
        hrefToken,
        isNotAgree,
        isDisagree,
        isAgree,
        isReverse,
        isProgression,
        hrefProgression,
        thanhVien,
        isButtonConfirm,
      } = this.state
      chucDanhId = _.get(this.props, 'auth.roleId', null)
      const phongBanId = _.get(this.props, 'auth.mainDepartmentId', null)
      let maDoiTuong = null
      if (phongBanId) {
        const chucDanh = _.get(this.props, 'auth.user.dsChucDanh', []).find(
          i => i.phongBanId === phongBanId
        )
        maDoiTuong = _.get(chucDanh, 'doiTuong.maDoiTuong', null)
      }
      ngayCongVan = moment()
      ngayCongVanToString = moment()
        .format('YYYY-MM-DD')
        .toString()
        .replace(/-/g, '')
      const { ctKySoTapTrung } = this.props

      if (ctKySoTapTrung) {
        if (_.get(ctKySoTapTrung, 'nguoiLap.chucDanhId', false)) {
          isCreator =
            _.get(ctKySoTapTrung, 'nguoiLap.chucDanhId', '') === chucDanhId
        }
        signNumberId = _.get(ctKySoTapTrung, 'vanBanKySoId', '')
        isShowButton = false
        // let { buoc, tinhTrang, soVanBan, ngayCongVan, dsThanhVien } = this.props.ctKySoTapTrung;
        if (
          ctKySoTapTrung.tinhTrang === 6 &&
          ctKySoTapTrung.soVanBan &&
          !isNaN(Number(ctKySoTapTrung.soVanBan)) &&
          Number(ctKySoTapTrung.soVanBan) > 0
        ) {
          soCongVan = ctKySoTapTrung.soVanBan
        }
        if (
          ctKySoTapTrung.tinhTrang === 6 &&
          ctKySoTapTrung.ngayCongVan &&
          Tool.Utils.isValidDate(
            moment(ctKySoTapTrung.ngayCongVan).format('DD/MM/YYYY')
          )
        ) {
          ngayCongVan = moment(ctKySoTapTrung.ngayCongVan)
          ngayCongVanToString = moment(ctKySoTapTrung.ngayCongVan)
            .format('YYYY-MM-DD')
            .toString()
            .replace(/-/g, '')
          dateToToString = moment(ctKySoTapTrung.ngayCongVan).format(
            'DD/MM/YYYY'
          )
        }

        if (ctKySoTapTrung.dsThanhVien) {
          ctKySoTapTrung.dsThanhVien.forEach(item => {
            if (
              item.chucDanhId === chucDanhId &&
              item.buoc < ctKySoTapTrung.buoc
            ) {
              return (thanhVien = item)
            }
            if (
              item.chucDanhId === chucDanhId &&
              item.buoc === ctKySoTapTrung.buoc
            ) {
              return (thanhVien = item)
            }
            if (
              item.phongBanId === phongBanId &&
              item.dsVaiTro &&
              item.dsVaiTro.includes(maDoiTuong) &&
              item.buoc <= ctKySoTapTrung.buoc &&
              item.trangThai === 0
            ) {
              return (thanhVien = item)
            }
          })
        }
        if (thanhVien) {
          switch (thanhVien.hinhThuc) {
            case Types.KSTT.XAC_NHAN:
              loaiKy = 'xacnhan'
              break

            case Types.KSTT.KHONG_HIEN_THI:
              loaiKy = 'khonghienthi'
              break

            case Types.KSTT.KY_KHONG_DAU:
              loaiKy = 'kykhongdau'
              break

            case Types.KSTT.KY_CO_DAU:
              loaiKy = 'kycodau'
              break

            case Types.KSTT.KY_NHAY:
              loaiKy = 'kynhay'
              break

            case Types.KSTT.CAP_SO:
              loaiKy = 'capso'
              break

            case Types.KSTT.DTXD:
              loaiKy = 'dtxd'
              break

            default:
          }
          buoc = thanhVien.buoc
          truongKy = thanhVien.truongKy
          hrefToken = `eofficecpc:id_vb=${signNumberId};id_nv=${chucDanhId};loaiky=${loaiKy};app=kstt`

          // Nêú là thành viên trong bước
          // + hoặc là tinhTrang !== 1
          // hoặc là chính là người không đồng ý
          // được ký duyệt thì hiển thị button
          if (
            ctKySoTapTrung.tinhTrang !== Types.DISAGREE ||
            (ctKySoTapTrung.tinhTrang === Types.DISAGREE &&
              thanhVien.trangThai === Types.DISAGREE)
          ) {
            isShowButton = true
          }
          // Nếu trạng thái ký của user === 0 thì enabled 3 button/cấp số
          if (thanhVien.trangThai === Types.NOT_YET_AGREE) {
            isNotAgree = true
          }
          // Nếu trạng thái ký của user === 1 thì enabled 2 button ký duyệt
          if (thanhVien.trangThai === Types.DISAGREE) {
            isDisagree = true
          }
          // Nếu trạng thái ký của user === 2 thì disabled 3 button
          if (thanhVien.trangThai === Types.AGREED) {
            isAgree = true
          }
          // Nếu trạng thái ký của user === 3 thì disabled 3 button
          if (thanhVien.trangThai === Types.REVERSE) {
            isReverse = true
          }
          // Nếu hình thức === 0 thì chỉ hiển thị button xác nhận
          if (thanhVien.hinhThuc === 0) {
            isButtonConfirm = true
          }
        }
        this.setState({
          chucDanhId,
          ngayCongVan,
          ngayCongVanToString,
          isCreator,
          signNumberId,
          isShowButton,
          soCongVan,
          dateToToString,
          loaiKy,
          buoc,
          truongKy,
          hrefToken,
          isNotAgree,
          isDisagree,
          isAgree,
          isReverse,
          isProgression,
          hrefProgression,
          thanhVien,
          isButtonConfirm,
        })
      }
    }
  }

  handleDateChange = e => {
    const error = this.state.error
    delete error.ngayCongVan

    let ngayCongVan = undefined
    let ngayCongVanToString = ''
    let dateToToString = ''
    let hrefProgression = this.state.hrefProgression

    if (e) {
      ngayCongVan = e
      ngayCongVanToString = moment(e)
        .format('YYYY-MM-DD')
        .toString()
        .replace(/-/g, '')
      dateToToString = moment(e).format('DD/MM/YYYY')
      hrefProgression = `eofficecpc:id_vb=${this.state.signNumberId};id_nv=${this.state.chucDanhId};loaiky=${this.state.loaiKy};socv=${this.state.soCongVan};ngaycv=${ngayCongVanToString};app=kstt`
    }

    this.setState({
      ngayCongVan,
      ngayCongVanToString,
      error,
      dateToToString,
      hrefProgression,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (Tool.Utils.isValidDate(value)) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleInputChange = e => {
    const name = e.target.name
    let value = e.target.value
    const error = this.state.error
    let hrefProgression = this.state.hrefProgression
    delete error[name]

    if (name === 'soCongVan') {
      hrefProgression = `eofficecpc:id_vb=${this.state.signNumberId};id_nv=${this.state.chucDanhId};loaiky=${this.state.loaiKy};socv=${value};ngaycv=${this.state.ngayCongVanToString};app=kstt`
    }

    this.setState({
      [e.target.name]: value,
      hrefProgression,
      error,
    })
  }

  updateFeedback = params => {
    if (!params) {
      return
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        sendFeedback: true,
      },
    })

    this.props.actions
      .ctKySoTapTrungFeedbackUpdate(this.state.signNumberId, params.gopYId, {
        gop_y_id: params.gopYId,
        noi_dung: params.noiDung,
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            feedbackId: null,
            isLoading: {
              ...this.state.isLoading,
              sendFeedback: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GopY',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }

        this.setState({
          feedbackId: null,
          isLoading: {
            ...this.state.isLoading,
            sendFeedback: false,
          },
        })
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'GopY',
            isSuccess: true,
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        )
      })
  }

  sendFeedback(type = null) {
    this.setState({
      isLoading: {
        ...this.state.isLoading,
        sendFeedback: true,
      },
    })

    if (this.state.feedbackContent.length === 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          sendFeedback: false,
        },
        isSubmitFeedbackSuccess: false,
      })
      if (type !== 'NotShowMessage') {
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            title: 'GopY',
            isSuccess: false,
            message: MESSAGE.TEXT_MESSAGE_GOPY,
            intent: Intent.WARNING,
          })
        )
      }
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        sendFeedback: true,
      },
    })

    this.props.actions
      .ctKySoTapTrungFeedbackCreate(this.state.signNumberId, {
        buoc: this.state.buoc,
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: '',
      })
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          (res.payload && res.payload.data && res.payload.data.result === false)
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              sendFeedback: false,
            },
            isSubmitFeedbackSuccess: false,
          })
          if (type !== 'NotShowMessage') {
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                title: 'GopY',
                isSuccess: false,
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            )
          }
        }

        this.setState({
          isReverse: false,
          feedbackContent: '',
          isLoading: {
            ...this.state.isLoading,
            sendFeedback: false,
          },
          isSubmitFeedbackSuccess: true,
        })

        if (type !== 'NotShowMessage') {
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'GopY',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          )
        }
        return (
          this.props.onSubmit &&
          this.props.onSubmit({ title: 'GopY', isSuccess: true })
        )
      })
  }

  sendProgression = async e => {
    let error = {}
    this.setState({
      isLoading: {
        ...this.state.isLoading,
        progression: true,
      },
    })

    if (
      !this.state.soCongVan ||
      this.state.soCongVan.toString().trim().length === 0
    ) {
      error.soCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      this.state.soCongVan &&
      (isNaN(Number(this.state.soCongVan)) || Number(this.state.soCongVan) <= 0)
    ) {
      error.soCongVan = MESSAGE.TEXT_GIATRI_LONHON_0
    }
    if (!this.state.ngayCongVan) {
      error.ngayCongVan = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error })
    if (Object.keys(error).length !== 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          progression: false,
        },
      })
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          title: 'CapSo',
          isSuccess: false,
          message: MESSAGE.TOATS_MESSAGE_WARNING,
          intent: Intent.WARNING,
        })
      )
    }

    await this.setState(
      {
        isLoading: {
          ...this.state.isLoading,
          progression: true,
        },
      },
      () => {
        this.props.onRemoveFileInfo && this.props.onRemoveFileInfo()
      }
    )
    return (window.location.href = this.state.hrefProgression)
  }

  handleClickAgreeBySIM = async (type = VIETTEL) => {
    if (type === VIETTEL) {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          agreeBySIM: true,
        },
      })
    } else {
      await this.setState({
        isLoading: {
          ...this.state.isLoading,
          agreeBySIMVNPT: true,
        },
      })
    }
    this.props.actions
      .ctKySoTapTrungAgreeBySIM(this.state.signNumberId, type, {
        buoc: this.state.buoc,
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: '',
      })
      .then(res => {
        const error = _.get(res, 'error')
        const result = _.get(res, 'payload')
        if (!_.isEmpty(result)) {
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
        } else {
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: false,
              message:
                _.get(error, 'response.data.message') ||
                MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
        }

        if (type === VIETTEL) {
          this.setState({
            isReverse: false,
            feedbackContent: '',
            isLoading: {
              ...this.state.isLoading,
              agreeBySIM: false,
            },
          })
        } else {
          this.setState({
            isReverse: false,
            feedbackContent: '',
            isLoading: {
              ...this.state.isLoading,
              agreeBySIMVNPT: false,
            },
          })
        }
      })
  }

  handleClickAgreeByTOKEN = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        agreeByTOKEN: true,
      },
    })

    // Gui gop y:
    if (this.state.feedbackContent.length > 0) {
      this.sendFeedback('NotShowMessage', () => {
        if (
          !this.state.isSubmitFeedbackSuccess ||
          this.state.isSubmitFeedbackSuccess === false
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              agreeByTOKEN: false,
            },
          })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangTOKEN',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          )
        }
      })
    }

    this.setState(
      {
        isLoading: {
          ...this.state.isLoading,
          agreeByTOKEN: true,
        },
      },
      () => {
        this.props.onRemoveFileInfo && this.props.onRemoveFileInfo()
      }
    )
    window.location.href = this.state.hrefToken
  }

  handleClickDisagree = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        disagree: true,
      },
    })

    // Validate
    if (this.state.feedbackContent.length === 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          disagree: false,
        },
      })
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          title: 'KhongDongY',
          isSuccess: false,
          message: MESSAGE.TEXT_MESSAGE_DISSAGREE_SIGNNUMBER,
          intent: Intent.WARNING,
        })
      )
    }

    this.props.actions
      .kySoTapTrungKhongDongY(this.state.signNumberId, {
        buoc: this.state.buoc,
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: '',
      })
      .then(res => {
        const result = _.get(res, 'payload')
        if (!_.isEmpty(result)) {
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'KhongDongY',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
        } else {
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'KhongDongY',
              isSuccess: false,
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
        }
        this.setState({
          feedbackContent: '',
          isLoading: {
            ...this.state.isLoading,
            disagree: false,
          },
        })
      })
  }

  handleClickReserve = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        reverse: true,
      },
    })

    this.props.actions
      .ctKySoTapTrungReverse(this.state.signNumberId, {
        buoc: this.state.buoc,
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: '',
      })
      .then(res => {
        const error = _.get(res, 'error')
        const result = _.get(res, 'payload')
        if (!_.isEmpty(result)) {
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKySoBangSIM',
              isSuccess: true,
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
        } else {
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'BaoLuu',
              isSuccess: false,
              message:
                _.get(error, 'response.data.message') ||
                MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
        }
        this.setState({
          feedbackContent: '',
          isLoading: {
            ...this.state.isLoading,
            reverse: false,
          },
        })
      })
  }

  handleUpdateFeedback = (feedbackId = null) => {
    this.setState({ feedbackId: feedbackId })
  }

  handleClickPopup = () => {
    let isLoading = this.state.isLoading
    if (this.state.isOpenPopup) {
      isLoading = {
        ...isLoading,
        agree: false,
      }
    }
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      isLoading,
    })
  }

  handleClickAgree = async () => {
    await this.setState({
      isLoading: {
        ...this.state.isLoading,
        agree: true,
      },
      isOpenPopup: false,
    })

    await this.props
      .confirmDispatch(this.state.signNumberId, {
        buoc: this.state.buoc,
        gop_y_chung: this.state.feedbackContent,
        gop_y_lanh_dao: '',
      })
      .then(res => {
        const error = _.get(res, 'error.response.data.message')
        if (error) {
          this.props.onSubmit &&
            this.props.onSubmit({
              title: 'DongYKhongCanKySo',
              isSuccess: false,
              message: error,
              intent: Intent.DANGER,
            })
        } else {
          const result = _.get(res, 'payload')
          if (!_.isEmpty(result)) {
            this.props.onSubmit &&
              this.props.onSubmit({
                title: 'DongYKhongCanKySo',
                isSuccess: true,
                message: MESSAGE.TOATS_MESSAGE_SUCCESS,
                intent: Intent.SUCCESS,
              })
          } else {
            this.props.onSubmit &&
              this.props.onSubmit({
                title: 'DongYKhongCanKySo',
                isSuccess: false,
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
          }
        }
      })

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        agree: false,
      },
    })
  }

  sendToChat = e => {
    e.preventDefault()
    let { ctKySoTapTrung } = this.props
    if (ctKySoTapTrung && ctKySoTapTrung.fileNoiDung) {
      const { auth } = this.props
      let fileNoiDung = ctKySoTapTrung.fileNoiDung
        ? ctKySoTapTrung.fileNoiDung.fileId
        : ''
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = ctKySoTapTrung.kySoId
      let infoGetFileDinhKemType = 'KY_SO_TAP_TRUNG'
      const url =
        Types.FE_CHAT_URL +
        `?accessToken=${auth.token.accessToken}&expiresIn=${
          auth.token.expiresIn
        }
        &tokenType=${auth.token.tokenType}&refreshToken=${
          auth.token.refreshToken
        }
        &fileId=${fileNoiDung.toUpperCase()}&infoGetFileDinhKem=${infoGetFileDinhKem.toUpperCase()}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      window.open(url, '_blank')
    }
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    const { feedbacks } = this.props
    const hrefProgression = `eofficecpc:id_vb=${this.state.signNumberId};socv=${this.state.soCongVan};ngaycv=${this.state.ngayCongVanToString};id_nv=${this.state.chucDanhId};app=kstt`
    let extension = null
    const fileNoiDungKhongDau = _.get(
      this.props,
      'ctKySoTapTrung.fileNoiDungKhongDau'
    )
    if (_.get(fileNoiDungKhongDau, 'kieuFile')) {
      extension = _.get(fileNoiDungKhongDau, 'kieuFile').slice(1).toLowerCase()
    }
    return this.props.isLoadingData &&
      this.props.isLoadingData.ctKySoTapTrung === true ? (
      <LoadingItem />
    ) : (
      <div className="list-action list-action-sign-number kstt-container">
        <p className="title">Thực Hiện</p>
        <span className="icon-More show-th-mobile" tabIndex="0"></span>
        <div className="detail-info-container section-box-info">
          {/* <div className="gui-chat">
              <div className="box-action">
                <div className="row list-action-detail">
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button className="click-item" onClick={this.sendToChat}>
                      <i className="icon-speech-bubble icon-cpc"></i>
                      <span className="action-name">Gửi Chat</span>
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          <div className="list-items">
            {this.props.ctKySoTapTrung &&
              this.props.ctKySoTapTrung.trichYeu && (
                <div className="row item-value">
                  <div className="col-xs-4 left-content">
                    <span className="label-text">Trích yếu:</span>
                  </div>
                  <div className="col-xs-8 right-content">
                    <span className="content textarea-content">
                      {this.props.ctKySoTapTrung &&
                        this.props.ctKySoTapTrung.trichYeu}
                    </span>
                  </div>
                </div>
              )}

            {this.props.ctKySoTapTrung &&
              this.props.ctKySoTapTrung.noiDungTrinh && (
                <div className="row item-value">
                  <div className="col-xs-4 left-content">
                    <span className="label-text">Nội dung trình:</span>
                  </div>
                  <div className="col-xs-8 right-content">
                    <span className="content textarea-content">
                      {this.props.ctKySoTapTrung &&
                        this.props.ctKySoTapTrung.noiDungTrinh}
                    </span>
                  </div>
                </div>
              )}

            {this.props.ctKySoTapTrung &&
              this.props.ctKySoTapTrung.dsFileDinhKem &&
              this.props.ctKySoTapTrung.dsFileDinhKem.length > 0 && (
                <div className="row item-value">
                  <div className="col-xs-4 left-content">
                    <span className="label-text">
                      File đính kèm (
                      {this.props.ctKySoTapTrung.dsFileDinhKem.length}):
                    </span>
                  </div>
                  <div className="col-xs-8 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      <span className="detail">
                        {this.props.ctKySoTapTrung.dsFileDinhKem.map(
                          (value, item) => {
                            const path = value.migrated
                              ? `${Types.OLD_DOCUMENT_URL}${value.url}&BearerToken=${token}`
                              : `${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`
                            return (
                              <div className="info-file" key={item}>
                                <span className="info">
                                  <a
                                    style={{
                                      backgroundImage: `url(${Tool.Utils.typeFile(
                                        value.kieuFile.slice(1)
                                      )})`,
                                    }}
                                    className="link-file"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={path}
                                  >
                                    {value.tenFile} -{' '}
                                    {Tool.Utils.getFileSize(value.kichThuoc)}
                                  </a>
                                </span>
                              </div>
                            )
                          }
                        )}
                      </span>
                    </Scrollbars>
                  </div>
                </div>
              )}

            {this.props.ctKySoTapTrung &&
              this.props.ctKySoTapTrung.dsFileThamKhao &&
              this.props.ctKySoTapTrung.dsFileThamKhao.length > 0 && (
                <div className="row item-value">
                  <div className="col-xs-4 left-content">
                    <span className="label-text">
                      File tham khảo (
                      {this.props.ctKySoTapTrung.dsFileThamKhao.length}):
                    </span>
                  </div>
                  <div className="col-xs-8 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      <span className="detail">
                        {this.props.ctKySoTapTrung.dsFileThamKhao.map(
                          (value, item) => {
                            const path = value.migrated
                              ? `${Types.OLD_DOCUMENT_URL}${value.url}&BearerToken=${token}`
                              : `${Types.FILE_URL}/api/file/get/${value.fileId}?BearerToken=${token}`
                            return (
                              <div className="info-file" key={item}>
                                <span className="info">
                                  <a
                                    style={{
                                      backgroundImage: `url(${Tool.Utils.typeFile(
                                        value.kieuFile.slice(1)
                                      )})`,
                                    }}
                                    className="link-file"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={path}
                                  >
                                    {value.tenFile} -{' '}
                                    {Tool.Utils.getFileSize(value.kichThuoc)}
                                  </a>
                                </span>
                              </div>
                            )
                          }
                        )}
                      </span>
                    </Scrollbars>
                  </div>
                </div>
              )}
          </div>
        </div>
        {/* Cap so */}
        {this.props.ctKySoTapTrung && this.state.loaiKy === 'capso' && (
          <div className="form-signnumber-submit">
            <div className="form-container box-signnumber">
              <div className="form-input">
                <div className="pt-form-group pt-intent-danger">
                  <div className="row">
                    <label className="pt-label col-md-3 col-xs-12 col-sm-12">
                      Số công văn
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content col-md-4 col-sm-12 col-xs-12">
                      <input
                        name="soCongVan"
                        value={this.state.soCongVan}
                        className="pt-input"
                        placeholder="Nhập số công văn"
                        type="text"
                        dir="auto"
                        onChange={this.handleInputChange}
                        autoFocus
                      />
                      {this.state.error.soCongVan !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.error.soCongVan}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pt-form-group pt-intent-danger">
                  <div className="row">
                    <label className="pt-label col-md-3 col-xs-12 col-sm-12">
                      Ngày công văn
                      <span className="pt-text-muted required-input">*</span>
                    </label>
                    <div className="pt-form-content col-md-4 col-sm-12 col-xs-12">
                      <div className="pt-input-group">
                        <DatePicker
                          readOnly={this.props.readOnly}
                          selected={this.state.ngayCongVan}
                          onChange={this.handleDateChange}
                          value={this.state.dateToToString}
                          onChangeRaw={e =>
                            this.handleChangeRaw(
                              'dateToToString',
                              e.target.value
                            )
                          }
                          onBlur={e =>
                            this.focousOut(
                              'ngayCongVan',
                              'dateToToString',
                              e.target.value
                            )
                          }
                          format="DD/MM/YYYY"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="input-datepicker input-icon-datepicker"
                          placeholderText="Chọn ngày..."
                          popperPlacement="auto"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: '5px, 10px',
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: 'viewport',
                            },
                          }}
                        />
                      </div>
                    </div>
                    {this.state.error.ngayCongVan !== undefined && (
                      <div className="col-xs-12 col-md-offset-3 col-md-9 pt-form-helper-content">
                        <p className="pt-form-helper-text">
                          {this.state.error.ngayCongVan}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="box-action">
              <div className="group-button-action">
                <div className="button-content">
                  <Button
                    disabled={this.state.isLoading.progression}
                    loading={
                      this.state.isLoading.checkProgression &&
                      !this.state.isLoading.progressionByVNPT &&
                      !this.state.isLoading.progressionByVIETTEL
                    }
                    className="pt-button btn-action-sign-number btn-action-blue display-flex"
                    onClick={this.sendProgression}
                  >
                    <span style={{ display: 'none' }}>{hrefProgression}</span>
                    <span className="text-content">Cấp số bằng Token</span>
                    <span className="pt-icon icon-DongYKysoToken"></span>
                  </Button>
                </div>
                {/* Cấp số bằng sim, tạm thời chưa cần */}
                {/* { this.props.auth && this.props.auth.user && this.props.auth.user.soDienThoaiKySo &&
                    <div className="button-content">
                      <Button
                        disabled={this.state.isLoading.progression}
                        loading={this.state.isLoading.progressionByVIETTEL}
                        className="pt-button btn-action-sign-number btn-action-blue display-flex"
                        onClick={this.sendProgressionBySIM.bind(this, VIETTEL)}
                      >
                        { this.props.auth.user.soDienThoaiKySoVnpt
                        ?
                          <span className="text-content">Cấp số SIM VIETTEL</span>
                        :
                          <span className="text-content">Cấp số bằng SIM</span>
                        }
                      </Button>
                    </div>
                  }
                  { this.props.auth && this.props.auth.user && this.props.auth.user.soDienThoaiKySoVnpt &&
                    <div className="button-content">
                      <Button
                        disabled={this.state.isLoading.progression}
                        loading={this.state.isLoading.progressionByVNPT}
                        className="pt-button btn-action-sign-number btn-action-blue display-flex"
                        onClick={this.sendProgressionBySIM.bind(this, VNPT)}
                      >
                        { this.props.auth.user.soDienThoaiKySo
                        ?
                          <span className="text-content">Cấp số SIM VNPT</span>
                        :
                          <span className="text-content">Cấp số bằng SIM</span>
                        }
                      </Button>
                    </div>
                  } */}
              </div>
            </div>
          </div>
        )}
        {/* DTXD */}
        {this.props.ctKySoTapTrung &&
          this.state.loaiKy === 'dtxd' &&
          this.state.isNotAgree && (
            <div className="form-signnumber-submit">
              <div className="form-container box-signnumber">
                <div className="form-input">
                  <div className="pt-form-group pt-intent-danger">
                    <div className="row">
                      <label className="pt-label col-md-3 col-xs-12 col-sm-12">
                        Số công văn
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="pt-form-content col-md-4 col-sm-12 col-xs-12">
                        <input
                          name="soCongVan"
                          value={this.state.soCongVan}
                          className="pt-input"
                          placeholder="Nhập số công văn"
                          type="text"
                          dir="auto"
                          onChange={this.handleInputChange}
                          autoFocus
                        />
                        {this.state.error.soCongVan !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.soCongVan}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="pt-form-group pt-intent-danger">
                    <div className="row">
                      <label className="pt-label col-md-3 col-xs-12 col-sm-12">
                        Ngày công văn
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="pt-form-content col-md-4 col-sm-12 col-xs-12">
                        <div className="pt-input-group">
                          <DatePicker
                            readOnly={this.props.readOnly}
                            selected={this.state.ngayCongVan}
                            onChange={this.handleDateChange}
                            value={this.state.dateToToString}
                            onChangeRaw={e =>
                              this.handleChangeRaw(
                                'dateToToString',
                                e.target.value
                              )
                            }
                            onBlur={e =>
                              this.focousOut(
                                'ngayCongVan',
                                'dateToToString',
                                e.target.value
                              )
                            }
                            format="DD/MM/YYYY"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="input-datepicker input-icon-datepicker"
                            placeholderText="Chọn ngày..."
                            popperPlacement="auto"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: '5px, 10px',
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                              },
                            }}
                          />
                        </div>
                      </div>
                      {this.state.error.ngayCongVan !== undefined && (
                        <div className="col-xs-12 col-md-offset-3 col-md-9 pt-form-helper-content">
                          <p className="pt-form-helper-text">
                            {this.state.error.ngayCongVan}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-action">
                <div className="group-button-action">
                  <div className="button-content">
                    <Button
                      disabled={this.state.isLoading.progression}
                      loading={
                        this.state.isLoading.checkProgression &&
                        !this.state.isLoading.progressionByVNPT &&
                        !this.state.isLoading.progressionByVIETTEL
                      }
                      className="pt-button btn-action-sign-number btn-action-blue display-flex"
                      onClick={this.sendProgression}
                    >
                      <span style={{ display: 'none' }}>{hrefProgression}</span>
                      <span className="text-content">Đồng ý ký số Token</span>
                      <span className="pt-icon icon-DongYKysoToken"></span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        {/* Form gop y*/}
        {this.props.ctKySoTapTrung &&
          this.state.loaiKy !== 'dtxd' &&
          this.props.ctKySoTapTrung.tinhTrang !== Types.PASSED &&
          this.state.isShowButton &&
          !this.state.isAgree &&
          !this.state.isProgression &&
          this.state.buoc === this.props.ctKySoTapTrung.buoc &&
          !this.props.searchReadOnly && (
            // !this.state.isReverse &&
            <div className="form-container">
              <div className="box-signnumber">
                <div className="form-input border-top">
                  <div className="pt-form-group">
                    <div className="row">
                      <label className="pt-label col-xs-12 pt10 mb10">
                        Góp ý/Ghi chú
                      </label>
                      <div className="pt-form-content col-xs-12">
                        <textarea
                          rows="4"
                          className="pt-input pt-fill"
                          placeholder="Nhập nội dung..."
                          dir="auto"
                          name="feedbackContent"
                          onChange={this.handleInputChange}
                          value={this.state.feedbackContent}
                          disabled={
                            this.state.isAgree === true ||
                            this.state.buoc !== this.props.ctKySoTapTrung.buoc
                            // this.state.isReverse === true
                          }
                          maxLength="2000"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/*Button ky duyet*/}
        {this.props.ctKySoTapTrung &&
          this.state.loaiKy !== 'dtxd' &&
          this.props.ctKySoTapTrung.tinhTrang !== Types.PASSED &&
          this.state.isShowButton &&
          !this.state.isAgree &&
          !this.state.isProgression &&
          this.state.buoc === this.props.ctKySoTapTrung.buoc &&
          !this.props.searchReadOnly && (
            <div className="box-actions-right">
              <div className="box-action">
                <div className="group-button-action">
                  {!this.state.isButtonConfirm &&
                    this.state.loaiKy !== 'capso' &&
                    this.props.auth.user.suDungUsbToken && (
                      <div className="button-content">
                        <Button
                          className="pt-button btn-action-sign-number btn-action-blue display-flex"
                          onClick={this.handleClickAgreeByTOKEN}
                          disabled={
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined ||
                            Object.values(this.state.isLoading).find(
                              item => item === true
                            ) !== undefined ||
                            this.state.isAgree === true ||
                            // this.state.isReverse === true ||
                            (this.state.buoc !==
                              this.props.ctKySoTapTrung.buoc &&
                              this.props.ctKySoTapTrung.tinhTrang === 6)
                          }
                        >
                          <span style={{ display: 'none' }}>
                            {this.state.hrefToken}
                          </span>
                          Đồng ý ký số Token
                          {/*<span className="pt-icon icon-DongYKysoToken"></span>*/}
                        </Button>
                      </div>
                    )}
                  {!this.state.isButtonConfirm && (
                    <div className="button-content">
                      {this.props.auth &&
                        this.props.auth.user &&
                        this.props.auth.user.soDienThoaiKySo && (
                          <div className="button-content-2">
                            <Button
                              className="pt-button btn-action-sign-number btn-action-blue display-flex"
                              onClick={this.handleClickAgreeBySIM.bind(
                                this,
                                VIETTEL
                              )}
                              loading={this.state.isLoading.agreeBySIM}
                              disabled={
                                Object.values(this.props.isLoadingData).find(
                                  item => item === true
                                ) !== undefined ||
                                Object.values(this.state.isLoading).find(
                                  item => item === true
                                ) !== undefined ||
                                this.state.isAgree ||
                                (this.state.buoc !==
                                  this.props.ctKySoTapTrung.buoc &&
                                  this.props.ctKySoTapTrung.tinhTrang === 6)
                              }
                            >
                              {this.props.auth.user.soDienThoaiKySoVnpt ? (
                                <p> Đồng ý ký số VIETTEL</p>
                              ) : (
                                <p>
                                  Đồng ý ký số SIM
                                  {/*<span className="pt-icon icon-DongYKysoSim"></span>*/}
                                </p>
                              )}
                            </Button>
                          </div>
                        )}
                      {this.props.auth &&
                        this.props.auth.user &&
                        this.props.auth.user.soDienThoaiKySoVnpt && (
                          <div className="button-content-2">
                            <Button
                              className="pt-button btn-action-sign-number btn-action-blue display-flex"
                              onClick={this.handleClickAgreeBySIM.bind(
                                this,
                                VNPT
                              )}
                              loading={this.state.isLoading.agreeBySIMVNPT}
                              disabled={
                                Object.values(this.props.isLoadingData).find(
                                  item => item === true
                                ) !== undefined ||
                                Object.values(this.state.isLoading).find(
                                  item => item === true
                                ) !== undefined ||
                                this.state.isAgree === true ||
                                // this.state.isReverse === true ||
                                (this.state.buoc !==
                                  this.props.ctKySoTapTrung.buoc &&
                                  this.props.ctKySoTapTrung.tinhTrang === 6)
                              }
                            >
                              {this.props.auth.user.soDienThoaiKySo ? (
                                <p>Đồng ý ký số VNPT</p>
                              ) : (
                                <p>
                                  Đồng ý ký số SIM
                                  {/*<span className="pt-icon icon-DongYKysoSim"></span>*/}
                                </p>
                              )}
                            </Button>
                          </div>
                        )}
                    </div>
                  )}

                  {this.state.isButtonConfirm &&
                    this.props.isAgreeNotNeedSignNumber && (
                      <div className="button-content">
                        <Button
                          className="pt-button btn-action-sign-number btn-action-blue display-flex"
                          onClick={this.handleClickPopup}
                          loading={this.state.isLoading.agree}
                          disabled={
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined ||
                            Object.values(this.state.isLoading).find(
                              item => item === true
                            ) !== undefined ||
                            this.state.isAgree === true ||
                            (this.state.buoc !==
                              this.props.ctKySoTapTrung.buoc &&
                              this.props.ctKySoTapTrung.tinhTrang === 6)
                          }
                        >
                          Đồng ý ký số EVNCA
                          <span className="pt-icon icon-Bao_Cao"></span>
                        </Button>
                      </div>
                    )}

                  <div className="button-content">
                    <Button
                      className="pt-button btn-action-sign-number btn-action-red display-flex"
                      onClick={this.handleClickDisagree}
                      loading={this.state.isLoading.disagree}
                      disabled={
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined ||
                        Object.values(this.state.isLoading).find(
                          item => item === true
                        ) !== undefined ||
                        this.state.isDisagree === true ||
                        this.state.isAgree === true ||
                        this.state.isReverse === true ||
                        this.state.buoc !== this.props.ctKySoTapTrung.buoc
                      }
                    >
                      Không đồng ý
                      <span className="pt-icon pt-icon-large pt-icon-cross"></span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Download file khong dau */}
        {this.props.ctKySoTapTrung &&
          [Types.PASSED, Types.RELEASED].includes(
            this.props.ctKySoTapTrung.tinhTrang
          ) &&
          this.state.loaiKy !== 'dtxd' &&
          fileNoiDungKhongDau && (
            <div className="detail-history-signnumber">
              <div className="row info-file">
                <div className="col-md-12 col-xs-12">
                  <span className="info">
                    <a
                      className="link-file"
                      style={{
                        backgroundImage: `url(${Tool.Utils.typeFile(
                          extension
                        )})`,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        fileNoiDungKhongDau.migrated
                          ? `${Types.OLD_DOCUMENT_URL}${fileNoiDungKhongDau.url}&BearerToken=${token}`
                          : `${Types.FILE_URL}/api/file/get/${fileNoiDungKhongDau.fileId}?BearerToken=${token}`
                      }
                    >
                      File không dấu dùng để in và đóng dấu gửi ra bên ngoài -{' '}
                      {Tool.Utils.getFileSize(fileNoiDungKhongDau.kichThuoc)}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          )}

        {/* Download file chuyen doi */}
        {this.props.ctKySoTapTrung &&
          this.state.loaiKy === 'dtxd' &&
          fileNoiDungKhongDau && (
            <div className="detail-history-signnumber">
              <div className="row info-file">
                <div className="col-md-12 col-xs-12">
                  <span className="info">
                    <a
                      className="link-file"
                      style={{
                        backgroundImage: `url(${Tool.Utils.typeFile(
                          extension
                        )})`,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        fileNoiDungKhongDau.migrated
                          ? `${Types.OLD_DOCUMENT_URL}${fileNoiDungKhongDau.url}&BearerToken=${token}`
                          : `${Types.FILE_URL}/api/file/get/${fileNoiDungKhongDau.fileId}?BearerToken=${token}`
                      }
                    >
                      File chuyển đổi dùng để in -{' '}
                      {Tool.Utils.getFileSize(fileNoiDungKhongDau.kichThuoc)}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          )}

        {/* Trang thai */}
        {this.props.ctKySoTapTrung && (
          <DetailStatusProject
            item={this.props.ctKySoTapTrung}
            auth={this.props.auth}
            isKstt={this.props.isKstt}
          />
        )}

        {/* Danh sach gop y */}
        {this.props.isLoadingData &&
          !this.props.isLoadingData.feedbacks &&
          feedbacks &&
          feedbacks.length > 0 && (
            <Feedback
              title="Góp ý/Ghi chú"
              list={feedbacks}
              auth={this.state.chucDanhId}
              isCanUpdate={
                this.props.ctKySoTapTrung &&
                this.props.ctKySoTapTrung.tinhTrang !== Types.PASSED &&
                this.props.ctKySoTapTrung.tinhTrang !== Types.RELEASED
              }
              onSubmit={this.updateFeedback}
              loading={this.state.isLoading.sendFeedback}
              onChangeFeedback={this.handleUpdateFeedback}
              feedbackId={this.state.feedbackId}
              isLoading={this.state.isLoading}
              isLoadingData={this.props.isLoadingData}
              maxLengthFeedback={2000}
            />
          )}

        {/* Lich su gop y*/}
        {this.props.isLoadingData &&
          !this.props.isLoadingData.ctKySoTapTrung &&
          !this.props.isLoadingData.feedbacks &&
          !this.props.isShowHistoryDetail &&
          !this.props.searchReadOnly && (
            <UpdateHistory
              list={this.props.listHistoryUpdate}
              onClickShowHistoryDetail={this.props.onClickShowHistoryDetail}
              onGetHistoryList={this.props.onGetHistoryList}
              isLoadingData={
                this.props.isLoadingData && this.props.isLoadingData.historyList
              }
              isOpenHistoryList={this.props.isOpenHistoryList}
              type={this.props.type}
            />
          )}

        {/* Popup Confirm ký số */}
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.KY_SO}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleClickAgree}
        >
          {POPUP.DONG_Y_KY_SO_EVNCA}
        </PopupConfirm>
      </div>
    )
  }
}

export default DetailBoxActionKSTT
