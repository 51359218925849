import React from 'react'
import { MasterLayout } from '../../components/layout'
//import { SimpleLineChart, CircleChart, SlickSlider, ListNotification } from '../../components/home';
import * as Actions from '../../actions/'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Authorization, Permission } from '../../components/auth'
import { Link } from 'react-router-dom'
import * as Types from '../../constants/Api'
import PropTypes from 'prop-types'
import * as Tool from '../../helpers/'
import { LoadingItem } from '../../components/common'

class HomePage extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      // lineChart: [
      //   {name: 'Ngày 1',  'Công văn đi': 42, 'Công văn đến': 22, 'Công văn nội bộ': 55},
      //   {name: 'Ngày 5',  'Công văn đi': 24, 'Công văn đến': 43, 'Công văn nội bộ': 60},
      //   {name: 'Ngày 10', 'Công văn đi': 16, 'Công văn đến': 37, 'Công văn nội bộ': 40},
      //   {name: 'Ngày 15', 'Công văn đi': 36, 'Công văn đến': 21, 'Công văn nội bộ': 40},
      //   {name: 'Ngày 20', 'Công văn đi': 45, 'Công văn đến': 39, 'Công văn nội bộ': 22},
      //   {name: 'Ngày 25', 'Công văn đi': 42, 'Công văn đến': 45, 'Công văn nội bộ': 20},
      //   {name: 'Ngày 30', 'Công văn đi': 28, 'Công văn đến': 27, 'Công văn nội bộ': 40}
      // ],
      // pieChartData1: [
      //   {name: 'Công việc chưa xử lý', value: 100, color: '#e74c3b'},
      //   {name: 'Công việc đang xử lý', value: 300, color: '#19bc9c'},
      //   {name: 'Công việc đã xử lý', value: 600, color: '#0085ec'}
      // ],
      // pieChartData2: [
      //   {name: 'Công việc quá hạn', value: 100, color: '#fad232'},
      //   {name: 'Công việc đúng hạn', value: 900, color: '#0085ec'}
      // ]
      isLoading: {
        corporations: false,
        unit: false,
        numberSideBar: true,
      },
      isEmpty: {
        corporations: false,
        unit: false,
      },
      page: {
        corporations: Types.PAGE,
        unit: Types.PAGE,
      },
      pageCount: {
        corporations: Math.ceil(
          this.props.notifications.list.totalCorporations /
            Types.PAGINATION_LIMIT
        ),
        unit: Math.ceil(
          this.props.notifications.list.totalUnit / Types.PAGINATION_LIMIT
        ),
      },
      isNoMoreItem: {
        corporations: false,
        unit: false,
      },
    }
  }

  static propTypes = {
    auth: PropTypes.object,
    statistics: PropTypes.object,
    notifications: PropTypes.object,
  }

  async componentDidMount() {
    await this.props.actions.getNumberHomePage()
    let state = this.state
    let preDataCorporations = Tool.Utils.preData('corporationsNotificationList')
    let preDataUnit = Tool.Utils.preData('unitNotificationList')

    if (preDataCorporations) {
      state.page.corporations = preDataCorporations.page
    }

    if (preDataUnit) {
      state.page.unit = preDataUnit.page
    }

    await this.setState({
      state,
      isLoading: {
        ...this.state.isLoading,
        numberSideBar: false,
      },
    })
    await this.submitFilter(this.state.page.corporations, 'corporations')
    await this.submitFilter(this.state.page.unit, 'unit')
  }

  handleClickNextPage = (type = 'corporations') => {
    if (this.state.page[type] >= this.state.pageCount[type]) {
      return
    }
    let page = {
      ...this.state.page,
      [type]: this.state.page[type] + 1,
    }
    this.setState({ page }, () => {
      this.submitFilter(this.state.page[type], type)
    })
  }

  handleClickPreviousPage = (type = 'corporations') => {
    if (this.state.page[type] <= 1) {
      return
    }
    let page = {
      ...this.state.page,
      [type]: this.state.page[type] - 1,
    }
    this.setState({ page }, () => {
      this.submitFilter(this.state.page[type], type)
    })
  }

  submitFilter(page = 1, type = 'corporations') {
    const isLoading = {
      ...this.state.isLoading,
      [type]: true,
    }
    const pageList = {
      ...this.state.page,
      [type]: page,
    }
    const isCorporations = type === 'corporations' ? true : false
    this.setState({ page: pageList, isLoading }, () => {
      Tool.Utils.preData(`${type}NotificationList`, { page })
      this.props.actions
        .notificationFetchList(page, isCorporations)
        .then(res => {
          if (
            res &&
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result
          ) {
            let state = this.state
            if (
              res.payload.data.result.result.total &&
              !isNaN(res.payload.data.result.result.total)
            ) {
              state.total = {
                ...this.state.total,
                [type]: res.payload.data.result.result.total,
              }
              state.pageCount = {
                ...this.state.pageCount,
                [type]: Math.ceil(
                  res.payload.data.result.result.total / Types.PAGINATION_LIMIT
                ),
              }
            }
            if (res.payload.data.result.result.total === 0) {
              state.isEmpty = {
                ...this.state.isEmpty,
                [type]: true,
              }
            }
            this.setState(state)
          } else {
            this.setState({
              isEmpty: {
                ...this.state.isEmpty,
                [type]: false,
              },
            })
          }
        })
        .then(() => {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              [type]: false,
            },
          })
        })
    })
  }

  render() {
    // const location = this.props.location.pathname;
    const AuthElement = Authorization.Element
    return (
      <MasterLayout typeSidebar="HomePage">
        <div className="dashboard-container">
          <section
            className="section-block-heading"
            ref={el => (this.blockHeading = el)}
          >
            <h2 className="heading-icon-20 main-heading-page d-flex between-xs middle-xs">
              <div>
                <span className="icon-left icon-Trang_Chu"></span>
                <span className="heading-text">Trang chủ</span>
              </div>
            </h2>
          </section>
          {(!this.props.auth ||
            !this.props.auth.permission ||
            this.props.auth.permission.length === 0) && (
            <div className="section-container">
              <div className="section-block">
                <p className="text-no-permission">
                  Anh/ chị chưa được phân quyền, vui lòng liên hệ Admin/CPCIT
                </p>
              </div>
            </div>
          )}
          <AuthElement
            orPermission={[Permission.CONGVANDEN, Permission.KYSO_KYDUYET_GOPY]}
          >
            <div className="section-container">
              <section className="section-block">
                {this.state.isLoading && this.state.isLoading.numberSideBar && (
                  <div className="loading-container">
                    <LoadingItem />
                    <LoadingItem />
                  </div>
                )}
                {this.state.isLoading &&
                  !this.state.isLoading.numberSideBar && (
                    <div className="heading-content">
                      <h2 className="heading-icon-17 main-heading">
                        <span className="icon-left icon-double-file"></span>
                        <span className="heading-text">Công văn cần xử lý</span>
                      </h2>
                    </div>
                  )}
                {this.state.isLoading &&
                  !this.state.isLoading.numberSideBar && (
                    <div className="section-content">
                      <ul className="row border-top list-task-content">
                        <AuthElement permission={[Permission.CONGVANDEN]}>
                          <li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                            <Link
                              to="/cong-van/cong-van-den/danh-sach/chua-xu-ly"
                              className="link-block-content red-content"
                            >
                              <span className="left-icon-content cicle-icon-content">
                                <span className="icon-thumb icon-Cong_van_chua_xu_ly"></span>
                              </span>
                              <span className="text-content">
                                <span className="label-text">
                                  Công văn chưa xử lý
                                </span>
                                <span className="number-text">
                                  {this.props.numberSideBar &&
                                  !isNaN(
                                    this.props.numberSideBar.congVanChuaXuLy
                                  )
                                    ? this.props.numberSideBar.congVanChuaXuLy
                                    : 0}
                                </span>
                              </span>
                            </Link>
                          </li>
                        </AuthElement>
                        <AuthElement
                          permission={[Permission.KYSO_KYDUYET_GOPY]}
                        >
                          <li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                            <Link
                              to="/ky-so/cong-van-di/danh-sach"
                              className="link-block-content chrysoprase-content"
                            >
                              <span className="left-icon-content cicle-icon-content">
                                <span className="icon-thumb icon-Kyso_Congvandi"></span>
                              </span>
                              <span className="text-content">
                                <span className="label-text">
                                  Công văn đi ký số
                                </span>
                                <span className="number-text">
                                  {this.props.numberSideBar &&
                                  !isNaN(
                                    this.props.numberSideBar.congVanDiCanKySo
                                  )
                                    ? this.props.numberSideBar.congVanDiCanKySo
                                    : 0}
                                </span>
                              </span>
                            </Link>
                          </li>
                        </AuthElement>
                        <AuthElement
                          permission={[Permission.KYSO_KYDUYET_GOPY]}
                        >
                          <li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                            <Link
                              to="/ky-so/cong-van-noi-bo/danh-sach"
                              className="link-block-content lightblue-content"
                            >
                              <span className="left-icon-content cicle-icon-content">
                                <span className="icon-thumb icon-CVNBKyso"></span>
                              </span>
                              <span className="text-content">
                                <span className="label-text">
                                  Công văn nội bộ ký số
                                </span>
                                <span className="number-text">
                                  {this.props.numberSideBar &&
                                  !isNaN(
                                    this.props.numberSideBar.congVanNoiBoCanKySo
                                  )
                                    ? this.props.numberSideBar
                                        .congVanNoiBoCanKySo
                                    : 0}
                                </span>
                              </span>
                            </Link>
                          </li>
                        </AuthElement>
                        {/* <li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                        <Link to="#/" className="link-block-content orange-content">
                          <span className="left-icon-content cicle-icon-content">
                            <span className="icon-thumb icon-CVDTKySo"></span>
                          </span>
                          <span className="text-content">
                            <span className="label-text">Công văn đấu thầu cần ký số</span>
                            <span className="number-text">
                              {this.props.numberSideBar  && !isNaN(this.props.numberSideBar.congVanDauThauCanKySo) ? this.props.numberSideBar.congVanDauThauCanKySo : 0}
                            </span>
                          </span>
                        </Link>
                      </li> */}
                      </ul>
                    </div>
                  )}
              </section>
            </div>
          </AuthElement>
          <AuthElement permission={[Permission.CONGVIEC]}>
            <div className="section-container">
              <section className="section-block">
                {this.state.isLoading && this.state.isLoading.numberSideBar && (
                  <div className="loading-container">
                    <LoadingItem />
                    <LoadingItem />
                  </div>
                )}
                {this.state.isLoading &&
                  !this.state.isLoading.numberSideBar && (
                    <div className="heading-content">
                      <h2 className="heading-icon-17 main-heading">
                        <span className="icon-left icon-Cong_viec"></span>
                        <span className="heading-text">
                          Công việc cần xử lý
                        </span>
                      </h2>
                    </div>
                  )}
                {this.state.isLoading &&
                  !this.state.isLoading.numberSideBar && (
                    <div className="section-content">
                      <ul className="row border-top list-task-content">
                        <AuthElement
                          permission={[
                            Permission.CONGVIEC_GIAOVIEC,
                            Permission.CONGVIEC_PHONGBAN,
                          ]}
                        >
                          <li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                            <Link
                              to="/cong-viec/phong-ban/danh-sach/chua-giao"
                              className="link-block-content red-content"
                            >
                              <span className="left-icon-content cicle-icon-content">
                                <span className="icon-thumb icon-Chua_Giao"></span>
                              </span>
                              <span className="text-content">
                                <span className="label-text">
                                  Công việc chưa giao
                                </span>
                                <span className="number-text">
                                  {this.props.numberSideBar &&
                                  !isNaN(
                                    this.props.numberSideBar.congViecChuaGiao
                                  )
                                    ? this.props.numberSideBar.congViecChuaGiao
                                    : 0}
                                </span>
                              </span>
                            </Link>
                          </li>
                        </AuthElement>
                        <AuthElement permission={[Permission.CONGVIEC_CANHAN]}>
                          <li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                            <Link
                              to="/cong-viec/ca-nhan/danh-sach/chua-thuc-hien"
                              className="link-block-content red-content"
                            >
                              <span className="left-icon-content cicle-icon-content">
                                <span className="icon-thumb icon-Dang_Thuc_Hien"></span>
                              </span>
                              <span className="text-content">
                                <span className="label-text">
                                  Công việc chưa thực hiện
                                </span>
                                <span className="number-text">
                                  {this.props.numberSideBar &&
                                  !isNaN(
                                    this.props.numberSideBar
                                      .congViecChuaThucHien
                                  )
                                    ? this.props.numberSideBar
                                        .congViecChuaThucHien
                                    : 0}
                                </span>
                              </span>
                            </Link>
                          </li>
                        </AuthElement>
                        {/*<li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                        <Link to="#/" className="link-block-content grey-content">
                          <span className="left-icon-content cicle-icon-content">
                            <span className="icon-thumb icon-note-date-center"></span>
                          </span>
                          <span className="text-content">
                            <span className="label-text">công việc trễ hạn</span>
                            <span className="number-text">6</span>
                          </span>
                        </Link>
                      </li>*/}
                      </ul>
                    </div>
                  )}
              </section>
            </div>
          </AuthElement>

          {/*yêu cầu góp ý*/}
          <AuthElement permission={[Permission.CONGVIEC]}>
            <div className="section-container">
              <section className="section-block">
                {this.state.isLoading && this.state.isLoading.numberSideBar && (
                  <div className="loading-container">
                    <LoadingItem />
                    <LoadingItem />
                  </div>
                )}
                <div className="heading-content">
                  <h2 className="heading-icon-17 main-heading">
                    <span className="icon-left icon-Cong_viec"></span>
                    <span className="heading-text">
                      Yêu cầu góp ý cần xử lý
                    </span>
                  </h2>
                </div>
                <div className="section-content">
                  <ul className="row border-top list-task-content">
                    <AuthElement
                      permission={[Permission.LAYYKIENDONVI_PHIEUGUI]}
                    >
                      <li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                        <Link
                          to="/tien-ich/lay-y-kien-don-vi/danh-sach-goi"
                          className="link-block-content red-content"
                        >
                          <span className="left-icon-content cicle-icon-content">
                            <span className="icon-thumb icon-Chua_Giao"></span>
                          </span>
                          <span className="text-content">
                            <span className="label-text">
                              Gửi góp ý chưa xử lý
                            </span>
                            <span className="number-text">
                              {this.props.numberSideBar &&
                              !isNaN(
                                this.props.numberSideBar
                                  .phieuGuiYeuCauGopYCanXyLy
                              )
                                ? this.props.numberSideBar
                                    .phieuGuiYeuCauGopYCanXyLy
                                : 0}
                            </span>
                          </span>
                        </Link>
                      </li>
                    </AuthElement>
                    <AuthElement
                      permission={[Permission.LAYYKIENDONVI_PHIEUNHAN]}
                    >
                      <li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                        <Link
                          to="/tien-ich/lay-y-kien-don-vi/danh-sach-nhan"
                          className="link-block-content red-content"
                        >
                          <span className="left-icon-content cicle-icon-content">
                            <span className="icon-thumb icon-Dang_Thuc_Hien"></span>
                          </span>
                          <span className="text-content">
                            <span className="label-text">
                              Nhận góp ý chưa xử lý
                            </span>
                            <span className="number-text">
                              {this.props.numberSideBar &&
                              !isNaN(
                                this.props.numberSideBar
                                  .phieuNhanYeuCauGopYCanXyLy
                              )
                                ? this.props.numberSideBar
                                    .phieuNhanYeuCauGopYCanXyLy
                                : 0}
                            </span>
                          </span>
                        </Link>
                      </li>
                    </AuthElement>
                  </ul>
                </div>
              </section>
            </div>
          </AuthElement>
          <AuthElement orPermission={[Permission.KYSO_KYDUYET_GOPY]}>
            <div className="section-container">
              <section className="section-block">
                {this.state.isLoading && this.state.isLoading.numberSideBar && (
                  <div className="loading-container">
                    <LoadingItem />
                    <LoadingItem />
                  </div>
                )}
                {this.state.isLoading &&
                  !this.state.isLoading.numberSideBar && (
                    <div className="heading-content">
                      <h2 className="heading-icon-17 main-heading">
                        <span className="icon-left icon-double-file"></span>
                        <span className="heading-text">Ký số tập trung</span>
                      </h2>
                    </div>
                  )}
                {this.state.isLoading &&
                  !this.state.isLoading.numberSideBar && (
                    <div className="section-content">
                      <ul className="row border-top list-task-content">
                        <AuthElement
                          permission={[Permission.KYSO_KYDUYET_GOPY]}
                        >
                          <li className="col-xs-12 col-sm-6 col-md-6 col-lg-3 item">
                            <Link
                              to="/ky-so-tap-trung/van-ban-ky-duyet/danh-sach"
                              className="link-block-content lightblue-content"
                            >
                              <span className="left-icon-content cicle-icon-content">
                                <span className="icon-thumb icon-Ky_so"></span>
                              </span>
                              <span className="text-content">
                                <span className="label-text">
                                  Văn bản cần ký số
                                </span>
                                <span className="number-text">
                                  {this.props.numberSideBar &&
                                  !isNaN(this.props.numberSideBar.kySoTapTrung)
                                    ? this.props.numberSideBar.kySoTapTrung
                                    : 0}
                                </span>
                              </span>
                            </Link>
                          </li>
                        </AuthElement>
                      </ul>
                    </div>
                  )}
              </section>
            </div>
          </AuthElement>
          {/* <ListNotification
            corporations={this.props.notifications && this.props.notifications.list && this.props.notifications.list.corporations}
            unit={this.props.notifications && this.props.notifications.list && this.props.notifications.list.unit}
            pageCount={this.state.pageCount}
            page={this.state.page}
            isLoading={this.state.isLoading}
            isEmpty={this.state.isEmpty}
            onClickPreviousPage={this.handleClickPreviousPage}
            onClickNextPage={this.handleClickNextPage}
          /> */}
          {/*
          <div className="section-container">
            <section className="section-block">
              <div className="heading-content">
                <h2 className="heading-icon-17 main-heading">
                  <span className="icon-left icon-chart"></span>
                  <span className="heading-text">Tình hình xử lý công văn</span>
                </h2>
                <div className="action-right-content selectbox-inline">
                  <div className="filter-chart">
                    <div className="item-filter">
                      <label className="pt-label pt-inline">
                        Đơn vị:
                        <div className="pt-select">
                          <select>
                            <option value="1">EVNCPC</option>
                            <option value="2">EVNCPC</option>
                            <option value="3">EVNCPC</option>
                          </select>
                        </div>
                      </label>
                    </div>
                    <div className="item-filter">
                      <label className="pt-label pt-inline">
                        Thời Gian:
                        <div className="pt-select">
                          <select>
                            <option value="1">Tháng 1</option>
                            <option value="2">Tháng 2</option>
                            <option value="3">Tháng 3</option>
                            <option value="4">Tháng 4</option>
                            <option value="5">Tháng 5</option>
                          </select>
                        </div>
                        <div className="pt-select">
                            <select>
                              <option value="1">2014</option>
                              <option value="2">2015</option>
                              <option value="3">2016</option>
                              <option value="4">2017</option>
                            </select>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-content">
                <div className="row statistical-container">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-item statistical-item-left">
                    <div className="chart-content">
                      <SimpleLineChart data={this.state.lineChart}/>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-item statistical-item-right">
                    <div className="statistical-detail-content">
                      <h3 className="heading-14 main-heading">
                        <span className="heading-text">TỔNG SỐ CÔNG VĂN</span>
                        <span className="right-number">240</span>
                      </h3>
                      <ul className="row statistical-list">
                        <li className="col-xs-4 col-sm-4 col-md-4 col-lg-4 item">
                          <div className="progressbar-content">
                            <div className="progress-circle percent10 progress-orange">
                              <span className="number-content">
                                <span className="number-top">12</span>
                                <span className="number-bottom">10%</span>
                              </span>
                              <div className="left-half-clipper">
                                <div className="first50-bar"></div>
                                <div className="value-bar"></div>
                              </div>
                            </div>
                            <p className="description">Chưa phân</p>
                          </div>
                        </li>
                        <li className="col-xs-4 col-sm-4 col-md-4 col-lg-4 item">
                          <div className="progressbar-content">
                            <div className="progress-circle percent15 progress-chrysoprase">
                              <span className="number-content">
                                <span className="number-top">12</span>
                                <span className="number-bottom">15%</span>
                              </span>
                              <div className="left-half-clipper">
                                <div className="first50-bar"></div>
                                <div className="value-bar"></div>
                              </div>
                            </div>
                            <p className="description">Đang giải quyết</p>
                          </div>
                        </li>
                        <li className="col-xs-4 col-sm-4 col-md-4 col-lg-4 item">
                          <div className="progressbar-content">
                            <div className="progress-circle percent15 progress-lightblue">
                              <span className="number-content">
                                <span className="number-top">12</span>
                                <span className="number-bottom">15%</span>
                              </span>
                              <div className="left-half-clipper">
                                <div className="first50-bar"></div>
                                <div className="value-bar"></div>
                              </div>
                            </div>
                            <p className="description">Đã giải quyết</p>
                          </div>
                        </li>
                        <li className="col-xs-4 col-sm-4 col-md-4 col-lg-4 item">
                          <div className="progressbar-content">
                            <div className="progress-circle over50 percent65 progress-red">
                              <span className="number-content">
                                <span className="number-top">12</span>
                                <span className="number-bottom">65%</span>
                              </span>
                              <div className="left-half-clipper">
                                <div className="first50-bar"></div>
                                <div className="value-bar"></div>
                              </div>
                            </div>
                            <p className="description">Quá hạn</p>
                          </div>
                        </li>
                        <li className="col-xs-4 col-sm-4 col-md-4 col-lg-4 item">
                          <div className="progressbar-content">
                            <div className="progress-circle percent5 progress-red">
                              <span className="number-content">
                                <span className="number-top">12</span>
                                <span className="number-bottom">5%</span>
                              </span>
                              <div className="left-half-clipper">
                                <div className="first50-bar"></div>
                                <div className="value-bar"></div>
                              </div>
                            </div>
                            <p className="description">Quá hạn chưa giải quyết</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="row row-eq-height section-container home-section-container-last">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-left-content">
              <section className="section-block">
                <div className="heading-content">
                  <h2 className="heading-icon-17 main-heading">
                    <span className="icon-left icon-chart"></span>
                    <span className="heading-text">Thống kê công việc</span>
                  </h2>
                  <div className="action-right-content selectbox-inline">
                    <div className="filter-chart">
                      <div className="item-filter">
                        <label className="pt-label pt-inline">
                          Thời Gian:
                          <div className="pt-select">
                            <select>
                              <option value="1">Tháng 1</option>
                              <option value="2">Tháng 2</option>
                              <option value="3">Tháng 3</option>
                              <option value="4">Tháng 4</option>
                              <option value="5">Tháng 5</option>
                            </select>
                          </div>
                          <div className="pt-select">
                              <select>
                                <option value="1">2014</option>
                                <option value="2">2015</option>
                                <option value="3">2016</option>
                                <option value="4">2017</option>
                              </select>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-content">
                    <div className="row">
                      <div className="chart-content col-md-6 col-xs-12 col-sm-12">
                        <CircleChart data={this.state.pieChartData1}/>
                      </div>
                      <div className="chart-content col-md-6 col-xs-12 col-sm-12">
                        <CircleChart data={this.state.pieChartData2}/>
                      </div>
                    </div>
                </div>
              </section>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-right-content">
              <section className="section-block public-infor-section-block">
                <div className="heading-content">
                  <h2 className="heading-icon-17 main-heading">
                    <span className="icon-left icon-Thong_Bao_Chung"></span>
                    <span className="heading-text">Thông báo chung</span>
                  </h2>
                </div>
                <div className="section-content public-infor-content">
                  <div className="slider-container">
                      <SlickSlider />
                  </div>
                  <div className="public-infor-list-content">
                    <h3 className="title">Giới thiệu những tính năng mới trong eOffice phiên bản 2017</h3>
                    <ul className="list-dot-12 public-infor-list">
                      <li className="item">
                        <a className="text-link">Hướng dẫn sử dụng</a>
                      </li>
                      <li className="item">
                        <a className="text-link">Thông báo bảo trì hệ thống vào Tháng 6/2017</a>
                      </li>
                      <li className="item">
                        <a className="text-link">Chương trình quản lý văn bản và nhắn tin nhắc việc</a>
                      </li>
                    </ul>
                  </div>
                  </div>
                </section>
            </div>
          </div>
          */}
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  // statistics: state.common.statistics,

  numberSideBar: state.common.numberSideBar,

  notifications: state.notifications,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
