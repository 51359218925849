import React from 'react'
import csx from 'classnames'

const Tag = ({ name = '', color = '', className, onClick, active = true }) => {
  return (
    <span
      className={csx('cpc-label', className)}
      style={{
        backgroundColor: color,
        cursor: onClick ? 'pointer' : 'default',
        opacity: active ? 1 : 0.6,
      }}
      onClick={e => {
        e.stopPropagation()
        onClick && onClick()
      }}
    >
      {name}
    </span>
  )
}

export default Tag
