import React, { Component } from 'react'
import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
} from 'recharts'
import { Empty } from 'components/newCommon'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill="white">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border-secondary p10">
        <p className="label no-wrap">{`${payload?.[0]?.payload.name} : ${payload?.[0]?.value}`}</p>
      </div>
    )
  }

  return null
}

class CircleChart extends Component {
  goPath = path => {
    return this.props.history.push(path)
  }

  onClickPieChart = () => {
    return this.goPath('/quan-ly-cong-viec/ca-nhan/can-thuc-hien')
  }

  render() {
    const props = this.props
    const maxSize = 140
    return (
      <div>
        {(props?.data ?? []) !== 0 && props?.data?.some(e => e?.value !== 0) ? (
          <ResponsiveContainer
            className={props?.className}
            width={props?.width ?? '100%'}
            height={props?.height ?? 140}
          >
            <PieChart>
              <Tooltip
                // allowEscapeViewBox={{ x: true, y: true }}
                content={<CustomTooltip />}
              />
              <Pie
                cursor="pointer"
                data={props?.data}
                cx="50%"
                cy="50%"
                innerRadius={props?.innerRadius ?? 50}
                outerRadius={props?.outerRadius ?? maxSize / 2}
                labelLine={false}
                label={props?.label ? renderCustomizedLabel : false}
                dataKey="value"
                onClick={this.onClickPieChart}
              >
                {props?.total && (
                  <Label
                    className="chart__total"
                    value={props?.total}
                    position="center"
                  />
                )}
                {props?.data.map((entry, index) => (
                  <Cell key={index} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <Empty message="Chưa có dữ liệu" type="ROUND_CHART" />
        )}
      </div>
    )
  }
}

export default CircleChart
