import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const NOT_UNIT = '00000000-0000-0000-0000-000000000000'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  handleSubmit = (id, isDonViPc) => {
    if (id !== NOT_UNIT && isDonViPc) {
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/thong-ke-cong-van/danh-sach/${id}`,
          id,
        })
      )
    }
  }

  render() {
    const { item, number } = this.props
    return (
      <tr
        className={classnames({
          'text-bold':
            item.donViId === NOT_UNIT && item.tenDonVi === 'Tổng cộng',
        })}
      >
        <td className="gerenal-list-utilities">
          {item.donViId !== NOT_UNIT && item.tenDonVi !== 'Tổng cộng'
            ? number > 9
              ? number
              : '0' + number
            : null}
        </td>
        <td
          className={classnames('gerenal-list-utilities', {
            'over-time cursor-pointer': item.isDonViPc,
          })}
          onClick={this.handleSubmit.bind(this, item.donViId, item.isDonViPc)}
        >
          {item.tenDonVi}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongVanDi}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongVanDen}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongVanNoiBo}
        </td>
        <td className="gerenal-list-utilities text-center">{item.tongSo}</td>
      </tr>
    )
  }
}

export default ListItem
