import React, {
  memo,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { Classes, Button, Intent, Radio, Checkbox } from '@blueprintjs/core'
import {
  get,
  findLast,
  size,
  differenceBy,
  uniqBy,
  uniq,
  isEmpty,
} from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

import {
  getMyFavoriteGroups,
  getCongViec,
  postGiaoViec,
  showToast,
  getPhongBanNhanVien,
  postFavoriteGroup,
} from 'actions/task'
import { Permission } from 'components/auth'
import { Icon, Row, Col, DatePicker } from 'components/newCommon'
import { Input } from 'components/newCommon2'
import { Action, Toast } from 'constants/MessageForm'
import { RESPONSIBILITY_TYPE, ASSIGN_TYPE, WORK_TYPE } from 'constants/Enum'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import PhongBanVaDonViThucHien from '../phong-ban-don-vi-thuc-hien'

const GiveTask = ({
  history,
  actionKey,
  dataSource,
  auth,
  dataTree,
  onSuccess,
  onClose,
}) => {
  const roleId = auth?.roleId
  const mainDepartmentId = auth?.mainDepartmentId
  const mainUnitId = auth?.mainUnitId
  const hasShowAll = (auth?.permission || []).includes(
    Permission.CONGVANDEN_CHIDAO
  )
  const id = dataSource?.id
  const PHONG_BAN = 'DEPARTMENT'
  const DON_VI = 'ORGANIZATION'
  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const THEO_DOI = 'THEO_DOI'
  const FORM_TITLE = 'Giao việc'
  const dateRequest = 'YYYY-MM-DD'
  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const isGiveTaskEdit = actionKey === 'SUA_GIAO_VIEC'
  const showTitle = isGiveTaskEdit ? 'Sửa giao việc' : FORM_TITLE

  const [isLoadingTree, setIsLoadingTree] = useState(false)
  const [taskDetail, setTaskDetail] = useState({})
  const [taskParentDetail, setTaskParentDetail] = useState({})
  const [fetching, setFetching] = useState(false)
  const [formData, setFormData] = useState({})
  const [validateErrors, setValidateErrors] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [dataAutoToggle, setDataAutoToggle] = useState([])
  const [favoriteGroups, setFavoriteGroups] = useState([])
  const [isOutOfDate, setIsOutOfDate] = useState(false)
  const [dataTreeStoreNew, setDataTreeStoreNew] = useState([])

  const onChangeRadio = useCallback(
    values => {
      setIsDisabledButtonDefault(false)
      const isPhongBanDonVi =
        values?.type === PHONG_BAN || values?.type === DON_VI
      setFormData(prev => ({
        ...prev,
        [CHU_TRI]: values,
        [PHOI_HOP]: !isPhongBanDonVi
          ? prev?.[PHOI_HOP]?.filter(e => e.id !== values?.id)
          : isPhongBanDonVi
          ? prev?.[PHOI_HOP]?.filter(
              e =>
                (e.parentId !== values?.id && e.id !== values.id) ||
                (e.parentId === values?.id && !e.isLanhDaoPhongBan)
            )
          : prev?.[PHOI_HOP],
        [THEO_DOI]: !isPhongBanDonVi
          ? prev?.[THEO_DOI]?.filter(e => e.id !== values?.id)
          : isPhongBanDonVi
          ? prev?.[THEO_DOI]?.filter(
              e =>
                (e.parentId !== values?.id && e.id !== values.id) ||
                (e.parentId === values?.id && !e.isLanhDaoPhongBan)
            )
          : prev?.[THEO_DOI],
      }))
    },
    [setFormData]
  )

  const handleCheckActive = useCallback(
    (key, formData, record) => {
      const childrenItem =
        dataTree?.find(e => e?.id === record?.id)?.children || []
      const dataKey = formData?.[key]?.findIndex(e => e?.id === record?.id)
      let isChecked = false
      if (size(formData?.[key]) !== 0) {
        const taskItemDataKey = formData?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        if (
          (size(childrenItem) !== 0 &&
            size(childrenItem) === size(taskItemDataKey)) ||
          dataKey === 0 ||
          (dataKey && dataKey !== -1)
        ) {
          isChecked = true
        }
      }

      return isChecked
    },
    [dataTree]
  )

  const onChangeCheckbox = useCallback(
    async (name, values, event, isIndeterminate) => {
      setIsDisabledButtonDefault(false)
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      const isOtherWorkType =
        taskDetail?.workType === WORK_TYPE.MEETING || taskDetail?.referenceId
      const isPhongBanDonVi =
        values?.type === PHONG_BAN || values?.type === DON_VI
      let treeItem = []
      if (isPhongBanDonVi) {
        treeItem = dataTree?.find(e => e?.id === values?.id)?.children || []
        setDataAutoToggle([values?.id])
      }

      setFormData(prev => {
        let newData = []
        const oldData = prev?.[name] || []
        newData = [...oldData, values]
        if (isPhongBanDonVi) {
          if (!isChecked) {
            newData = differenceBy(newData || [], [...treeItem, values], 'id')
          } else {
            if ((isOtherWorkType && !hasShowAll) || !isOtherWorkType) {
              newData = [...newData, ...(treeItem || [])]
              newData = differenceBy(newData || [], [values], 'id')
            }
          }

          const dataChuTri = prev?.[CHU_TRI] ? [prev?.[CHU_TRI]] : []
          const dataPhoiHop = prev?.[PHOI_HOP] || []
          const dataTheoDoi = prev?.[THEO_DOI] || []
          if (
            name === PHOI_HOP &&
            (!isOtherWorkType || (isOtherWorkType && !hasShowAll))
          ) {
            newData = differenceBy(
              newData,
              [...dataChuTri, ...dataTheoDoi],
              'id'
            )
          } else if (
            name === THEO_DOI &&
            (!isOtherWorkType || (isOtherWorkType && !hasShowAll))
          ) {
            newData = differenceBy(
              newData,
              [...dataChuTri, ...dataPhoiHop],
              'id'
            )
          }

          if (
            (name === PHOI_HOP || name === THEO_DOI) &&
            size(dataChuTri) !== 0 &&
            dataChuTri?.some(
              e =>
                e?.id === values?.id &&
                (e?.type === PHONG_BAN || e?.type === DON_VI)
            )
          ) {
            newData = newData?.filter(
              e =>
                e.parentId !== values?.id ||
                (e.parentId === values?.id && !e?.isLanhDaoPhongBan)
            )
          }
        } else {
          if (!isChecked) {
            newData = differenceBy(newData || [], [values], 'id')
          }
        }

        newData = differenceBy(newData, [{ id: roleId }], 'id')

        const chuTri =
          name !== CHU_TRI &&
          prev?.[CHU_TRI]?.id === values?.id &&
          ((prev?.[CHU_TRI]?.type !== PHONG_BAN &&
            prev?.[CHU_TRI]?.type !== DON_VI &&
            !isOtherWorkType) ||
            isOtherWorkType)
            ? null
            : prev?.[CHU_TRI]
        const phoiHop =
          name === PHOI_HOP
            ? uniqBy(newData, 'id')
            : prev?.[PHOI_HOP]?.filter(e => e.id !== values?.id) || []
        const theoDoi =
          name === THEO_DOI
            ? uniqBy(newData, 'id')
            : prev?.[THEO_DOI]?.filter(e => e.id !== values?.id) || []

        return {
          ...prev,
          [CHU_TRI]: chuTri,
          [PHOI_HOP]: phoiHop,
          [THEO_DOI]: theoDoi,
        }
      })
    },
    [setFormData, setDataAutoToggle, dataTree, taskDetail, hasShowAll, roleId]
  )

  const handleCheckOutOfDate = useCallback((executionDate, deadline) => {
    let result = false
    const today = moment()
    const executionDateString = executionDate
      ? moment(executionDate).format(`${dateRequest} 00:00:00`)
      : null
    const startDate = executionDateString ? moment(executionDateString) : null
    const deadlineString = moment(deadline).format(`${dateRequest} 23:59:59`)
    const endDate = deadlineString ? moment(deadlineString) : null
    if (
      startDate &&
      endDate &&
      ((endDate.diff(startDate, 'seconds') &&
        endDate.diff(startDate, 'seconds') < 0) ||
        (endDate.diff(today, 'seconds') && endDate.diff(today, 'seconds') < 0))
    ) {
      result = true
    }

    return result
  }, [])

  const handleCheckIndeterminate = useCallback(
    (name, record) => {
      let isChecked = false
      const dataKey = size(formData?.[name]) !== 0
      const hasChecked = formData?.[name]?.some(e => e?.parentId === record?.id)
      if (
        dataKey &&
        (record?.type === PHONG_BAN || record?.type === DON_VI) &&
        hasChecked
      ) {
        isChecked = true
      }

      return isChecked
    },
    [formData]
  )

  const handleCheckDisable = useCallback(
    (key, record) => {
      let isDisable = false
      if ((key === PHOI_HOP || key === THEO_DOI) && roleId === record?.id) {
        isDisable = true
      }

      const dataChuTri = formData?.[CHU_TRI] || null
      if (
        (key === PHOI_HOP || key === THEO_DOI) &&
        !isEmpty(dataChuTri) &&
        record?.parentId === dataChuTri?.id &&
        record?.isLanhDaoPhongBan
      ) {
        isDisable = true
      }

      const isOutOfDate = handleCheckOutOfDate(
        formData?.executionDate,
        formData?.deadline
      )
      if (
        (key !== THEO_DOI && roleId !== record?.id && isOutOfDate) ||
        (key === THEO_DOI &&
          roleId !== record?.id &&
          isOutOfDate &&
          !record?.isCheckTheoDoi)
      ) {
        isDisable = true
      }

      return isDisable
    },
    [formData, roleId, handleCheckOutOfDate]
  )

  const column = [
    {
      title: 'Phòng ban/đơn vị',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: record => {
        const isDisable = handleCheckDisable(CHU_TRI, record)
        const formItem = formData?.[CHU_TRI]?.id === record?.id
        let isChecked = false
        if (formItem) {
          isChecked = true
        }

        if ((!hasShowAll && record?.id === mainDepartmentId) || !record.type) {
          return ''
        }

        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            disabled={isDisable}
            onChange={() => onChangeRadio(record)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: record => {
        const isDisable = handleCheckDisable(PHOI_HOP, record)
        const isChecked = handleCheckActive(PHOI_HOP, formData, record)
        const isIndeterminate = handleCheckIndeterminate(PHOI_HOP, record)
        if (!record.type) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable}
            onChange={e =>
              onChangeCheckbox(PHOI_HOP, record, e, isIndeterminate)
            }
          />
        )
      },
    },
    {
      title: 'TD',
      render: record => {
        const isDisable = handleCheckDisable(THEO_DOI, record)
        const isChecked = handleCheckActive(THEO_DOI, formData, record)
        const isIndeterminate = handleCheckIndeterminate(THEO_DOI, record)
        if (!record.type) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable}
            onChange={e =>
              onChangeCheckbox(THEO_DOI, record, e, isIndeterminate)
            }
          />
        )
      },
    },
  ]

  const changeFormValue = ({ name, value }) => {
    mountedSet(setFormData, {
      ...formData,
      [name]: value,
    })
  }

  const changeFormValidate = ({ name, value }) => {
    mountedSet(setValidateErrors, {
      ...validateErrors,
      [name]: value,
    })
  }

  const changeField = name => value => {
    setIsDisabledButtonDefault(false)
    changeFormValidate({ name })
    changeFormValue({ name, value })
  }

  const validateRequired = (name, key) => {
    if (!!get(formData, key ? `${name}.${key}` : name)) {
      changeFormValidate({ name, value: false })
      return true
    }

    changeFormValidate({ name, value: true })
    return false
  }

  const validateExecutor = () => {
    return validateRequired(CHU_TRI, 'id')
  }

  const validate = () => {
    const success = validateExecutor()
    if (!success) {
      throw new Error('warning')
    }
  }

  const getRequestData = () => {
    const description = get(formData, 'description') || undefined
    const deadline = get(formData, 'deadline')
      ? moment(get(formData, 'deadline')).startOf('day').format(dateRequest)
      : undefined
    const executionDate = moment(formData?.executionDate).isValid()
      ? moment(formData?.executionDate).format(dateRequest)
      : undefined
    const phoiHop = get(formData, PHOI_HOP, [])?.map(elm => ({
      responsibilityId: get(elm, 'id'),
      responsibilityType: RESPONSIBILITY_TYPE.PHOI_HOP,
      assignType: get(elm, 'type'),
      description,
      deadline,
      executionDate,
    }))
    let fTyle = 'PERSONAL'
    if (
      hasShowAll &&
      (taskDetail?.workType === WORK_TYPE.MEETING || taskDetail?.referenceId)
    ) {
      fTyle = 'DEPARTMENT'
    }

    const theoDoi = get(formData, THEO_DOI, [])
      ?.filter(f => f.type === fTyle)
      ?.map(elm => ({
        responsibilityId: get(elm, 'id'),
        responsibilityType: RESPONSIBILITY_TYPE.THEO_DOI,
        assignType: get(elm, 'type'),
        description,
        deadline,
        executionDate,
      }))

    return [
      {
        responsibilityId: get(formData, `${CHU_TRI}.id`),
        responsibilityType: RESPONSIBILITY_TYPE.CHU_TRI,
        assignType: get(formData, `${CHU_TRI}.type`),
        description,
        deadline,
        executionDate,
      },
      ...phoiHop,
      ...theoDoi,
    ].map(user => toDecamelizeKeys(user))
  }

  const handleFavoriteGroupSave = () => {
    if (formData?.isFavoriteGroupSave) {
      const pushData = (arr = []) => {
        if (!arr || arr?.length === 0) {
          return []
        }

        return arr.reduce((result, item) => {
          if (item?.type === 'PERSONAL') {
            return [...result, item]
          } else if (item?.type !== 'PERSONAL') {
            return [...result, ...item?.children]
          }

          return result
        }, [])
      }

      const convertData = (arr = []) => {
        if (!arr || arr?.length === 0) {
          return []
        }

        return uniqBy(
          arr?.map(item => ({
            avatar: item?.anhDaiDien ? item?.anhDaiDien : item?.avatar,
            department_id: item?.parentId,
            department_name: item?.tenPhongBan
              ? item?.tenPhongBan
              : item?.departmentName
              ? item?.departmentName
              : item?.information?.department?.name,
            department_shortname: item?.information?.department?.shortName
              ? item?.information?.department?.shortName
              : item?.departmentShortname
              ? item?.departmentShortname
              : item?.tenPhongBan,
            id: item?.chucDanhId ? item?.chucDanhId : item?.id,
            name: item?.tenNhanVien ? item?.tenNhanVien : item?.name,
          })),
          'id'
        )
      }

      const newTree =
        dataTreeStoreNew && size(dataTreeStoreNew) !== 0
          ? dataTreeStoreNew
          : dataTree
      let chuTri = []
      if (formData?.CHU_TRI?.id) {
        if (formData?.CHU_TRI?.type !== 'PERSONAL') {
          chuTri =
            newTree?.find(f => f?.id === formData?.CHU_TRI?.id)?.children || []
        } else {
          chuTri = [formData?.CHU_TRI]
        }
      }

      const tempUsers = [
        ...chuTri,
        ...(formData?.PHOI_HOP && formData.PHOI_HOP?.length !== 0
          ? pushData(formData.PHOI_HOP)
          : []),
        ...(formData?.THEO_DOI && formData.THEO_DOI?.length !== 0
          ? pushData(formData.THEO_DOI)
          : []),
      ]?.filter(f => f?.type === 'PERSONAL')
      try {
        requestAction({
          action: () => {
            const params = {
              name: `Nhóm ${tempUsers?.length} thành viên`,
              members: convertData(tempUsers),
              isActive: true,
            }
            return postFavoriteGroup(toDecamelizeKeys(params))
          },
          showToast: false,
        })
        return true
      } catch (_) {
        return true
      }
    }

    return true
  }

  const submit = async () => {
    let requestData = []
    requestAction({
      codeCheck: false,
      getResult: false,
      showToast: false,
      beforeAction: () => {
        mountedSet(setSubmitLoading, true)
        validate()
      },
      action: async () => {
        requestData = await getRequestData()
        handleFavoriteGroupSave()
        return postGiaoViec(id, requestData)
      },
      afterResponse: res => {
        showToast({
          message: res?.message,
          intent: res?.result ? Intent.SUCCESS : Intent.DANGER,
        })

        if (!res?.result) {
          return
        }

        onSuccess()
        onClose()
        return history.goBack()
      },
      afterAction: () => {
        mountedSet(setSubmitLoading, false)
        mountedSet(setIsDisabledButtonDefault, true)
      },
      errorMessage: Toast.FAIL(Action.ASSIGN),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const handleDataParent = useCallback(
    data => {
      const newParent = []
      const mainResponsibility = data?.mainResponsibility?.information
      if (mainResponsibility) {
        const departmentId = mainResponsibility?.department?.id
        const organizationId = mainResponsibility?.organization?.id
        if (departmentId) {
          newParent.push(departmentId)
        }

        if (organizationId) {
          newParent.push(organizationId)
        }

        if (
          data?.mainResponsibility?.type === PHONG_BAN ||
          data?.mainResponsibility?.type === DON_VI
        ) {
          newParent.push(data?.mainResponsibility?.id)
        }
      }

      const coordinations = data?.responsibilities?.[1]?.workAssignments || []
      if (size(coordinations) !== 0) {
        coordinations.forEach(elm => {
          const departmentId = elm?.responsibility?.information?.department?.id
          const organizationId =
            elm?.responsibility?.information?.organization?.id
          if (departmentId) {
            newParent.push(departmentId)
          }

          if (organizationId) {
            newParent.push(organizationId)
          }
        })
      }

      const watchers = get(data, 'responsibilities[2].workAssignments', [])
      if (size(watchers) !== 0) {
        watchers.forEach(elm => {
          const departmentId = elm?.responsibility?.information?.department?.id
          const organizationId =
            elm?.responsibility?.information?.organization?.id
          if (departmentId) {
            newParent.push(departmentId)
          }

          if (organizationId) {
            newParent.push(organizationId)
          }
        })
      }

      const newParentConvert = uniq(newParent)
      if (size(newParentConvert) !== 0) {
        setDataAutoToggle([...newParentConvert, mainDepartmentId, mainUnitId])
      }
    },
    [setDataAutoToggle, mainDepartmentId, mainUnitId]
  )

  const fetchPhongBanDonVi = useCallback(async () => {
    const isDepartment = taskDetail?.mainResponsibility?.type === PHONG_BAN
    const isOrganization = taskDetail?.mainResponsibility?.type === DON_VI
    if (!isDepartment && !isOrganization) {
      return
    }

    if (actionKey !== 'GIAO_VIEC' && !isOutOfDate) {
      setDataTreeStoreNew([])
      return
    }

    const convertUsers = values => ({
      ...values,
      id: values?.chucDanhId,
      type: 'PERSONAL',
      name: values?.tenNhanVien,
      tenChucVu: values?.chucVu,
      maChucVu: values?.maChucVu,
      parentId: values?.parentId,
    })

    let params = {
      donViId: mainUnitId,
      phongBanId: mainDepartmentId,
      workType: taskDetail?.workType,
      assignType: taskDetail?.mainResponsibility?.type,
      limit: 9999,
    }
    await requestAction({
      showToast: false,
      successCode: 200,
      beforeAction: () => {
        setIsLoadingTree(true)
      },
      action: () => getPhongBanNhanVien(params),
      afterResponse: response => {
        let newData = []
        let newWatchers = []
        if (response?.items) {
          newData = response?.items?.map(elm => {
            return {
              id: elm?.phongBanId,
              type: convertType('PB'),
              name: elm?.maPhongBan,
              code: elm?.maPhongBan,
              parentId: elm?.donViId,
              children:
                elm?.nhanViens?.map(u =>
                  convertUsers({ ...u, parentId: elm?.phongBanId })
                ) || [],
            }
          })
          newWatchers = newData.reduce((result, item) => {
            const newChil = item?.children?.filter(e => e?.isCheckTheoDoi) || []
            return [...result, ...newChil]
          }, [])
          if (size(newWatchers) !== 0) {
            newWatchers = differenceBy(newWatchers, [{ id: roleId }], 'id')
            setFormData(prev => ({
              ...prev,
              [THEO_DOI]: newWatchers,
            }))
          }
        }
        setDataTreeStoreNew(newData)
      },
      afterAction: () => {
        setIsLoadingTree(false)
      },
    })
  }, [taskDetail, mainUnitId, mainDepartmentId, actionKey, roleId, isOutOfDate])

  const fetchDetail = useCallback(() => {
    const convertToStateData = data => {
      let execSrc = { ...data }
      let execKey = 'mainResponsibility'
      const mainRespon = get(data, 'responsibilities[0].workAssignments', [])
      const assigners = mainRespon.reduce(
        (result, assigner) => [...result, assigner],
        []
      )
      const currentUserAssigner = findLast(assigners, {
        assignerId: roleId,
      })
      if (currentUserAssigner) {
        execSrc = { ...currentUserAssigner }
        execKey = 'responsibility'
      }

      const deadline = get(execSrc, `deadline`)
      let executionDate = moment()
      if (isGiveTaskEdit && execSrc?.executionDate) {
        executionDate = moment(execSrc?.executionDate)
      }

      let exec = {
        deadline: deadline && moment(deadline),
        executionDate: executionDate,
        allowClearDeadline: !deadline,
      }

      if (currentUserAssigner || roleId === get(data, 'creatorId')) {
        exec = {
          ...exec,
          executor: {
            id: get(execSrc, `${execKey}.id`),
            type: get(execSrc, `${execKey}.type`),
          },
          executorLabel:
            get(
              execSrc,
              `${execKey}.${
                get(execSrc, `${execKey}.type`) === ASSIGN_TYPE.CA_NHAN
                  ? 'name'
                  : 'shortName'
              }`
            ) || '',
          description: get(execSrc, 'description') || '',
        }
      }

      const coordinations = get(data, 'responsibilities[1].workAssignments', [])
      const originCoordinations = coordinations?.map(coordination => ({
        ...coordination?.responsibility,
        id: get(coordination, 'responsibilityId'),
        type: get(coordination, 'assignType'),
        parentId: get(coordination, 'responsibility.information.department.id'),
        isLanhDaoPhongBan: coordination?.responsibility?.isLanhDaoPhongBan,
      }))
      const watchers = get(data, 'responsibilities[2].workAssignments', [])
      const originWatchers = watchers?.map(watcher => ({
        ...watcher?.responsibility,
        id: get(watcher, 'responsibilityId'),
        type: get(watcher, 'assignType'),
        parentId: get(watcher, 'responsibility.information.department.id'),
        isLanhDaoPhongBan: watcher?.responsibility?.isLanhDaoPhongBan,
      }))

      const isAssignEdit = isGiveTaskEdit ? true : false
      const main = get(data, 'responsibilities[0].workAssignments[0]')
      return {
        ...exec,
        ...(main && isAssignEdit
          ? {
              [CHU_TRI]: {
                ...main?.responsibility,
                id: main?.responsibilityId,
                type: main?.assignType,
                isLanhDaoPhongBan: main?.responsibility?.isLanhDaoPhongBan,
              },
            }
          : {}),
        [PHOI_HOP]: isAssignEdit ? originCoordinations : [],
        [THEO_DOI]: isAssignEdit ? originWatchers : [],
      }
    }

    requestAction({
      showToast: false,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getCongViec(id),
      afterResponse: detail => {
        handleDataParent(detail)
        mountedSet(setTaskDetail, detail)
        setFormData(prev => ({
          ...prev,
          ...convertToStateData(detail),
        }))
      },
      afterAction: () => mountedSet(setFetching, false),
    })
  }, [id, roleId, isGiveTaskEdit, handleDataParent])

  const fetchParentDetail = useCallback(parentId => {
    requestAction({
      showToast: false,
      action: () => getCongViec(parentId),
      afterResponse: parentDetail => {
        setTaskParentDetail(parentDetail)
      },
    })
  }, [])

  const convertType = type => {
    if (type === 'PB') {
      return 'DEPARTMENT'
    } else if (type === 'DV') {
      return 'ORGANIZATION'
    }

    return 'PERSONAL'
  }

  const fetchFavoriteGroup = useCallback(() => {
    requestAction({
      showToast: false,
      action: () => getMyFavoriteGroups({ is_active: true }),
      afterResponse: ({ items }) => {
        mountedSet(
          setFavoriteGroups,
          items.map(({ id, name, members }) => ({
            id,
            name,
            children: members.map(({ id, name, avatar, departmentId }) => ({
              id,
              parentId: departmentId,
              name,
              type: convertType('PERSONAL'),
              avatar,
            })),
          }))
        )
      },
    })
  }, [])

  const kiemTraQuaHan = useCallback(() => {
    let isDisable = false
    const isCheckOutOfDate = handleCheckOutOfDate(
      formData?.executionDate,
      formData?.deadline
    )
    if (isCheckOutOfDate) {
      isDisable = true
    }

    setIsOutOfDate(isDisable)
  }, [formData, handleCheckOutOfDate])

  const setDefaultChuTri = useCallback(() => {
    if (isOutOfDate) {
      setFormData(prev => ({
        ...prev,
        [CHU_TRI]: {
          id: roleId,
          type: 'PERSONAL',
        },
        executionDate: moment(),
      }))
    }
  }, [isOutOfDate, roleId])

  useEffect(() => {
    fetchPhongBanDonVi()
  }, [fetchPhongBanDonVi])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (taskDetail && taskDetail?.parentId) {
      fetchParentDetail(taskDetail?.parentId)
    }
  }, [taskDetail, fetchParentDetail])

  useEffect(() => {
    kiemTraQuaHan()
  }, [kiemTraQuaHan])

  useEffect(() => {
    setDefaultChuTri()
  }, [setDefaultChuTri])

  useEffect(() => {
    fetchFavoriteGroup()
  }, [fetchFavoriteGroup])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  useEffect(() => {
    mountedSet(setValidateErrors, {})
  }, [id])

  const isDisplayFavoriteGroup = useMemo(() => {
    return (
      !isOutOfDate &&
      (!taskDetail?.workType ||
        (taskDetail?.workType &&
          (taskDetail?.workType === WORK_TYPE.NORMAL ||
            (taskDetail?.workType === WORK_TYPE.EOFFICE && !hasShowAll) ||
            (taskDetail?.workType === WORK_TYPE.MEETING && !hasShowAll) ||
            (taskDetail?.workType === WORK_TYPE.DOFFICE && !hasShowAll))))
    )
  }, [isOutOfDate, taskDetail, hasShowAll])

  const isHideChild = useMemo(() => {
    return (
      (hasShowAll &&
        (taskDetail?.workType === WORK_TYPE.MEETING ||
          taskDetail?.workType === WORK_TYPE.EOFFICE)) ||
      (hasShowAll &&
        (taskDetail?.workType === WORK_TYPE.CHAT ||
          taskDetail?.workType === WORK_TYPE.SERVICE_DESK)) ||
      false
    )
  }, [taskDetail, hasShowAll])

  return (
    <div className={'cpc-form'}>
      <h1 className="d-flex justify-space-between align-center border-bottom ph14 pv15">
        <span
          className="icon2-arrow-dart-left font-size-14 cursor-pointer"
          onClick={onClose}
        ></span>
        <span className="text-13-500 text-uppercase color-gray">
          {showTitle}
        </span>
        <span></span>
      </h1>
      <div className="p15">
        <div className="tree-organization-task">
          <div className="save-favorite-group">
            <Checkbox
              className={classNames(Classes.SMALL, 'mb0')}
              onChange={e =>
                changeField('isFavoriteGroupSave')(e?.target?.checked)
              }
              checked={formData?.isFavoriteGroupSave || false}
            >
              <span className="font-size-13 font-weight-300">
                Lưu nhóm thường giao việc
              </span>
            </Checkbox>
          </div>
          <PhongBanVaDonViThucHien
            className="mb10"
            isRequired={true}
            isLoading={fetching || isLoadingTree}
            column={column}
            data={
              dataTreeStoreNew && size(dataTreeStoreNew) !== 0
                ? dataTreeStoreNew
                : dataTree
            }
            dataFavorite={favoriteGroups}
            isDisplayFavoriteGroup={isDisplayFavoriteGroup}
            dataAutoToggle={dataAutoToggle}
            taskId={id}
            isHideChild={isHideChild}
            dataDisplayChild={
              isOutOfDate && mainDepartmentId ? [mainDepartmentId] : []
            }
            warning={get(validateErrors, CHU_TRI)}
          />
        </div>
        <Row gutterVertical>
          <Col grid="half">
            <label className="d-block m0 mb5 text-13-600">Ngày bắt đầu</label>
            <DatePicker
              placeholder="Chọn ngày bắt đầu"
              popperPlacement={'top-start'}
              isClearable={!isOutOfDate}
              selected={
                formData?.executionDate
                  ? moment(formData?.executionDate)
                  : undefined
              }
              minDate={moment().toDate()}
              maxDate={
                get(formData, 'deadline')
                  ? moment(get(formData, 'deadline')).toDate()
                  : undefined
              }
              disabled={fetching || isOutOfDate}
              onChange={value => {
                changeField('executionDate')(value)
              }}
            />
          </Col>
          <Col grid="half">
            <label className="d-block m0 mb5 text-13-600">Hạn thực hiện</label>
            <DatePicker
              placeholder="Chọn hạn thực hiện"
              popperPlacement={'top-end'}
              isClearable={!isOutOfDate && !taskParentDetail?.deadline}
              selected={
                formData?.deadline ? moment(formData?.deadline) : undefined
              }
              minDate={
                formData?.executionDate
                  ? moment(formData?.executionDate).toDate()
                  : moment().toDate()
              }
              maxDate={
                taskDetail?.deadline &&
                taskDetail?.creatorId !== roleId &&
                taskDetail?.assigner?.id !== roleId
                  ? moment(taskDetail.deadline).toDate()
                  : !isEmpty(taskParentDetail) && taskParentDetail?.deadline
                  ? moment(taskParentDetail?.deadline).toDate()
                  : null
              }
              disabled={fetching || isOutOfDate}
              onChange={value => {
                changeField('deadline')(value)
              }}
            />
            {/* get(validateErrors, 'deadline') */}
            {isOutOfDate ? (
              <span className="text-warning">
                Công việc này đã quá hạn giải quyết theo chỉ đạo! Anh/chị chỉ
                giao được công việc này cho chính mình
              </span>
            ) : null}
          </Col>
        </Row>
        <Input
          disabled={fetching}
          label="Mô tả"
          onChange={changeField('description')}
          placeholder="Nhập..."
          rows={2}
          maxLength={500}
          autoResize={true}
          style={{ maxHeight: 'unset' }}
          type="textarea"
          value={get(formData, 'description', '')}
          wrapperClassName="mb10"
        />
      </div>
      <div className="d-flex align-items-center justify-content-center gap-15 border-top ph15">
        <Button
          className={
            'cpc-button white-border-gray uppercase font-size-13 ph10 min-width-100'
          }
          disabled={fetching}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={'cpc-button blue uppercase font-size-13 ph10 min-width-80'}
          loading={submitLoading}
          disabled={fetching || isDisabledButtonDefault}
          onClick={submit}
        >
          Lưu
          <Icon classIcon="icon-save" className="ml5" />
        </Button>
      </div>
    </div>
  )
}

GiveTask.defaultProps = {
  dataSource: null,
  onSuccess: () => {},
  onClose: () => {},
}

GiveTask.propTypes = {
  dataSource: PropTypes.object,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
}

export default memo(GiveTask)
