import { schema } from 'normalizr'

const SignNumberProjectMember = new schema.Entity(
  'signNumberProjectMembers',
  {},
  {
    idAttribute: member => member.thanhVienId,
  }
)

export default SignNumberProjectMember
