import {
  SET_ME_INFOS,
  AUTH_SET_TOKEN_CREATE_WORK,
  USER_PERMISSION_CREATE_WORK,
} from '../constants/ActionTypes'

const initialState = {
  meCreateWork: null,
  isCreateWork: false,
  tokenCreateWork: null,
  roleIdCreateWork: null,
  permissionCreateWork: [],
}

export default function createWork(state = initialState, action) {
  switch (action.type) {
    case SET_ME_INFOS:
      const mainRole = action?.payload?.data?.result?.dsChucDanh?.find(
        role => role?.phongBanChinh
      )
      return {
        ...state,
        meCreateWork: action?.payload?.data?.result,
        roleIdCreateWork: mainRole?.id,
      }
    case AUTH_SET_TOKEN_CREATE_WORK:
      return {
        ...state,
        tokenCreateWork: action.payload.data,
        isCreateWork: true,
      }
    case USER_PERMISSION_CREATE_WORK:
      return {
        ...state,
        permissionCreateWork: action.payload?.data?.result?.items,
      }
    default:
      return state
  }
}
