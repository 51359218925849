import React, { memo, useMemo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import File from './File'

import { size } from 'lodash'

const FileGroup = ({
  list = [],
  listUpload = [],
  heightMax = '100%',
  ...props
}) => {
  const filesDisplay = useMemo(() => {
    return list?.filter(e => e?.uid || e?.id) || []
  }, [list])

  return (
    <Scrollbars
      className="cpc-scrollbar"
      renderThumbVertical={elm => <div {...elm} className="scroll-thumb" />}
      renderThumbHorizontal={elm => <div {...elm} />}
      autoHide={false}
      autoHeight
      autoHeightMax={heightMax}
    >
      <span className="cpc-file-group">
        {!!size(filesDisplay) &&
          filesDisplay.map((item, index) => (
            <File
              {...props}
              key={index || item.uid || item.id}
              displayBlock
              file={item}
              showClose={
                item.showClose !== undefined ? item.showClose : props.showClose
              }
            />
          ))}
        {!!size(listUpload) &&
          listUpload.map((item, index) => (
            <File
              {...props}
              key={index || item.uid || item.id}
              displayBlock
              file={item}
              showClose={
                item.showClose !== undefined ? item.showClose : props.showClose
              }
            />
          ))}
      </span>
    </Scrollbars>
  )
}

export default memo(FileGroup)
