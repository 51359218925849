import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { LoadingItem, DetailFileDinhKem } from '../../common/'

class DetailBoxAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chucDanhId: this.props.auth && this.props.auth.roleId,
      showBoxAction: false,
      receiveDocument: null,
    }
  }
  static propTypes = {
    onClickExecutingView: PropTypes.func.isRequired,
    receiveDocument: PropTypes.object.isRequired,
    onClickFlowdocumentary: PropTypes.func.isRequired,
  }

  showBoxAction(e) {
    e.preventDefault()
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.receiveDocument) {
      this.setState({ receiveDocument: nextProps.receiveDocument })
    }
  }

  componentDidMount() {
    if (this.props.receiveDocument) {
      this.setState({ receiveDocument: this.props.receiveDocument })
    }
  }

  componentWillUnmount() {
    this.setState({
      showBoxAction: false,
      receiveDocument: null,
    })
  }

  render() {
    return this.props.isLoadingData &&
      Object.values(this.props.isLoadingData).find(item => item === true) !==
        undefined ? (
      <LoadingItem />
    ) : (
      <div className="list-action">
        <p className="title">Thực Hiện</p>
        <span
          className="icon-More show-th-mobile"
          onClick={this.showBoxAction.bind(this)}
        ></span>
        {this.state.receiveDocument &&
          this.state.receiveDocument.fileDinhKem &&
          this.state.receiveDocument.fileDinhKem.length > 0 && (
            <DetailFileDinhKem {...this.props} />
          )}
        <div
          className={classnames('box-action', {
            'box-action-open': this.state.showBoxAction,
          })}
        >
          <div className="close-mobile-menu-detail">
            <button onClick={this.showBoxAction.bind(this)}>
              <span className="pt-icon pt-icon-cross"></span>
            </button>
          </div>
          <div className="row list-action-detail">
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickExecutingView}
              >
                <i className="icon-Xemthuchien icon-cpc"></i>
                <span className="action-name">Xem thực hiện</span>
              </button>
            </div>

            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <a
                href="#/"
                className="click-item"
                onClick={this.props.onClickFlowdocumentary}
              >
                <i className="icon-organization icon-cpc"></i>
                <span className="action-name">Luồng công văn</span>
              </a>
            </div>

            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickDetailInfo}
              >
                <i className="icon-Thong_Tin_Giao_Viec icon-cpc"></i>
                <span className="action-name">Thông tin công văn</span>
              </button>
            </div>

            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickInformationSteering}
              >
                <i className="icon-Chidao icon-cpc"></i>
                <span className="action-name">Thông tin chỉ đạo</span>
              </button>
            </div>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button
                className="click-item"
                onClick={this.props.onClickSendMail}
              >
                <i className="icon-Gmail icon-cpc"></i>
                <span className="action-name">Gửi email</span>
              </button>
            </div>
            <div className="col-xs-4 col-sm-6 col-md-6 item">
              <button className="click-item" onClick={this.props.sendToChat}>
                <i className="icon-speech-bubble icon-cpc"></i>
                <span className="action-name">Gửi Chat</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DetailBoxAction
