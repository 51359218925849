import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Intent, Button, Collapse } from '@blueprintjs/core'
import {
  // PopupConfirm,
  DepartmentPerform,
} from '../common/'
// import * as POPUP from '../../constants/Popup';
import * as MESSAGE from '../../constants/MessageForm'
import moment from 'moment'
import { Authorization, Permission } from '../auth'
import DatePicker from 'react-datepicker'
import * as Tool from '../../helpers'

class AdditionalAssignment extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    units: PropTypes.array.isRequired,
    informationSteering: PropTypes.object,
    actions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickArrow = this.handleClickArrow.bind(this)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this)
    // Change checkbox group to grid
    // this.handleCompositiveDepartmentSelection = this.handleCompositiveDepartmentSelection.bind(this);
    // this.handleCheckAllCompositive = this.handleCheckAllCompositive.bind(this);
    // this.handleCheckAllOnlySee = this.handleCheckAllOnlySee.bind(this);
    // this.handleOnlySeeDepartmentSelection = this.handleOnlySeeDepartmentSelection.bind(this);
    this.handleGetValueGridCheckBox = this.handleGetValueGridCheckBox.bind(this)
    //this.handleScroll = this.handleScroll.bind(this);
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    isOpen: false,
    isOpenPopup: false,
    congVanDenId: '',
    noiDung: '',
    dsPhoiHop: [],
    dsXemDeBiet: [],
    dsDonViPhoiHop: [],
    dsDonViXemDeBiet: [],
    hanGiaiQuyet: undefined,
    departmentSelections: [],
    attachedUnitSelections: [],
    // Phòng ban
    disabledListCompositiveDepartments: [],
    disabledListOnlySeeDepartments: [],
    selectedCompositiveDepartments: [],
    selectedOnlySeeDepartments: [],
    // Đơn vị
    disabledListCompositiveUnits: [],
    disabledListOnlySeeUnits: [],
    selectedCompositiveUnits: [],
    selectedOnlySeeUnits: [],
    // isCheckedAllCompositive: false,
    // isCheckedAllOnlySee: false,
    chiDaoId: '',
    isLoading: false,
    //isHideButton: false,
    dealineToString: '',
  }

  handleClickArrow() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  // handleClickPopup = () => {
  //   this.setState({
  //     isOpenPopup: !this.state.isOpenPopup
  //   });
  // }

  handleDateChange = (name, nameToString, e) => {
    let value = e ? e : undefined
    let valueToString = this.state[nameToString]
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    } else {
      valueToString = ''
    }
    this.setState({
      [name]: value,
      [nameToString]: valueToString,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment().format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleSubmit = () => {
    this.setState({ isLoading: true })
    if (
      (!this.state.noiDung || this.state.noiDung.trim().length === 0) &&
      this.state.dsPhoiHop.length === 0 &&
      this.state.dsXemDeBiet.length === 0 &&
      this.state.dsDonViPhoiHop.length === 0 &&
      this.state.dsDonViXemDeBiet.length === 0
    ) {
      this.setState({ isLoading: false })
      return this.props.onSubmit(
        'ChiDaoBoSung',
        MESSAGE.TEXT_MESSAGE_MIN_INPUT,
        Intent.WARNING,
        false,
        null
      )
    }
    let hanGiaiQuyet = null
    if (
      this.state.hanGiaiQuyet &&
      this.state.hanGiaiQuyet.toString().length > 0
    ) {
      hanGiaiQuyet = moment(this.state.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }
    const dataRequest = {
      cong_van_den_id: this.state.congVanDenId,
      noi_dung: this.state.noiDung,
      ds_phoi_hop: this.state.dsPhoiHop,
      ds_xem_de_biet: this.state.dsXemDeBiet,
      ds_don_vi_phoi_hop: this.state.dsDonViPhoiHop,
      ds_don_vi_xem_de_biet: this.state.dsDonViXemDeBiet,
      han_giai_quyet: hanGiaiQuyet,
    }
    this.props.actions
      .receiveDocumentSendAdditionalAssignment(
        this.props.congVanDenId,
        dataRequest
      )
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          this.props.onSubmit(
            'ChiDaoBoSung',
            MESSAGE.TOATS_MESSAGE_SUCCESS,
            Intent.SUCCESS,
            true,
            null
          )
        } else {
          this.setState({ isLoading: false })
          this.props.onSubmit(
            'ChiDaoBoSung',
            MESSAGE.TOATS_MESSAGE_FAIL,
            Intent.DANGER,
            false,
            null
          )
        }
      })
  }

  handleInputChange = e => {
    if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  // handleCheckAllCompositive = () => {
  //   this.setState({isCheckedAllCompositive: !this.state.isCheckedAllCompositive});
  //   let dsPhoiHop = this.state.dsPhoiHop;
  //   if (!this.state.isCheckedAllCompositive) {
  //     this.state.departmentSelections.forEach(item => {
  //       if (dsPhoiHop.indexOf(item.value) === -1 && this.state.dsXemDeBiet.indexOf(item.value) === -1 && this.state.disabledDepartmentList.indexOf(item.value) === -1) {
  //         dsPhoiHop.push(item.value);
  //       }
  //     });
  //   } else {
  //     dsPhoiHop = [];
  //   }
  //   this.setState({dsPhoiHop});
  // }

  // handleCompositiveDepartmentSelection(e) {
  //   const newSelection = e.target.value;
  //   let dsPhoiHop;
  //   if (this.state.dsPhoiHop.length > 0 && this.state.dsPhoiHop.indexOf(newSelection) > -1) {
  //     dsPhoiHop = this.state.dsPhoiHop.filter(s => s !== newSelection);
  //   } else {
  //     dsPhoiHop = [...this.state.dsPhoiHop, newSelection];
  //   }
  //   this.setState({dsPhoiHop});
  // }

  // handleCheckAllOnlySee = () => {
  //   this.setState({isCheckedAllOnlySee: !this.state.isCheckedAllOnlySee});
  //   let dsXemDeBiet = this.state.dsXemDeBiet;
  //   if (!this.state.isCheckedAllOnlySee) {
  //     this.state.departmentSelections.forEach(item => {
  //       if (dsXemDeBiet.indexOf(item.value) === -1 && this.state.dsPhoiHop.indexOf(item.value) === -1 && this.state.disabledDepartmentList.indexOf(item.value) === -1) {
  //         dsXemDeBiet.push(item.value);
  //       }
  //     });
  //   } else {
  //     dsXemDeBiet = [];
  //   }
  //   this.setState({dsXemDeBiet});
  // }

  // handleOnlySeeDepartmentSelection = (e) => {
  //   const newSelection = e.target.value;
  //   let onlySeeSelection;
  //   if (this.state.dsXemDeBiet.length > 0 && this.state.dsXemDeBiet.indexOf(newSelection) > -1) {
  //     onlySeeSelection = this.state.dsXemDeBiet.filter(s => s !== newSelection);
  //   } else {
  //     onlySeeSelection = [...this.state.dsXemDeBiet, newSelection];
  //   }
  //   this.setState({dsXemDeBiet: onlySeeSelection});
  // }

  handleGetValueGridCheckBox = (type, data) => {
    if (!data || !Object.keys(data).length) {
      return
    }

    switch (type) {
      case 'units':
        return this.setState({
          dsDonViPhoiHop: data.dsPhoiHop,
          dsDonViXemDeBiet: data.dsXemDeBiet,
        })

      default:
        return this.setState({
          dsPhoiHop: data.dsPhoiHop,
          dsXemDeBiet: data.dsXemDeBiet,
        })
    }
  }

  componentDidMount() {
    let dsPhongBanXemDeBiet = [] // Danh sach phong ban disbled cot xem de biet
    let dsPhongBanPhoiHop = [] // Danh sach phong ban disbled cot phoi hop
    let selectedCompositiveDepartments = []
    let selectedOnlySeeDepartments = []

    let dsDonViXemDeBiet = [] // Danh sach don vi disbled cot xem de biet
    let dsDonViPhoiHop = [] // Danh sach don vi disbled cot phoi hop
    let selectedCompositiveUnits = []
    let selectedOnlySeeUnits = []
    let isOpen = false
    if (this.props.informationSteering) {
      // Phòng ban
      if (
        this.props.informationSteering.chuTri &&
        this.props.informationSteering.chuTri.phongBanId
      ) {
        dsPhongBanXemDeBiet.push(
          this.props.informationSteering.chuTri.phongBanId
        )
        dsPhongBanPhoiHop.push(this.props.informationSteering.chuTri.phongBanId)
      }

      if (this.props.informationSteering.phoiHop) {
        this.props.informationSteering.phoiHop.forEach(item => {
          dsPhongBanXemDeBiet.push(item.phongBanId)
          dsPhongBanPhoiHop.push(item.phongBanId)
          selectedCompositiveDepartments.push(item.phongBanId)
        })
      }

      if (this.props.informationSteering.xemDeBiet) {
        this.props.informationSteering.xemDeBiet.forEach(item => {
          dsPhongBanXemDeBiet.push(item.phongBanId)
          selectedOnlySeeDepartments.push(item.phongBanId)
        })
      }

      // Đơn vị
      if (
        this.props.informationSteering.donViChuTri &&
        this.props.informationSteering.donViChuTri.donViId
      ) {
        dsDonViXemDeBiet.push(
          this.props.informationSteering.donViChuTri.donViId
        )
        dsDonViPhoiHop.push(this.props.informationSteering.donViChuTri.donViId)
        isOpen = true
      }

      if (this.props.informationSteering.donViPhoiHop) {
        this.props.informationSteering.donViPhoiHop.forEach(item => {
          dsDonViXemDeBiet.push(item.donViId)
          dsDonViPhoiHop.push(item.donViId)
          selectedCompositiveUnits.push(item.donViId)
        })
        if (this.props.informationSteering.donViPhoiHop.length > 0) {
          isOpen = true
        }
      }

      if (this.props.informationSteering.donViXemDeBiet) {
        this.props.informationSteering.donViXemDeBiet.forEach(item => {
          dsDonViXemDeBiet.push(item.donViId)
          selectedOnlySeeUnits.push(item.donViId)
        })
        if (this.props.informationSteering.donViXemDeBiet.length > 0) {
          isOpen = true
        }
      }
    }

    // TODO: Hot fix: show tat ca phong ban
    // const { mainDepartmentId } = this.props;
    // const currentDepartment = this.props.departments.find(item => item.phongBanId === mainDepartmentId);
    // const levelDepartment = currentDepartment && currentDepartment.loai;
    // const departmentSelections = [];
    // levelDepartment && this.props.departments.forEach((item,i) => {
    //   if (item.phongBanId === mainDepartmentId || item.loai > levelDepartment) {
    //     departmentSelections.push({content: item.maPhongBan, value: item.phongBanId});
    //   }
    // });

    const departmentSelections = []
    this.props.departments.forEach((item, i) => {
      departmentSelections.push({
        content: item.maPhongBan,
        value: item.phongBanId,
      })
    })

    const attachedUnitSelections = []
    this.props.units.forEach(item => {
      attachedUnitSelections.push({
        content: item.tenVietTat,
        value: item.donViId,
      })
    })

    this.setState({
      disabledListOnlySeeDepartments: dsPhongBanXemDeBiet,
      disabledListCompositiveDepartments: dsPhongBanPhoiHop,
      selectedCompositiveDepartments,
      selectedOnlySeeDepartments,
      disabledListOnlySeeUnits: dsDonViXemDeBiet,
      disabledListCompositiveUnits: dsDonViPhoiHop,
      selectedCompositiveUnits,
      selectedOnlySeeUnits,
      congVanDenId: this.props.congVanDenId,
      departmentSelections,
      attachedUnitSelections,
      isOpen,
    })

    // window.addEventListener('scroll', this.handleScroll);
    // let windowSize = window.innerHeight;
    // let gb = document.getElementById("groupButton");
    // let self = this;
    // setTimeout(function() {
    //   if (windowSize > gb.getBoundingClientRect().top) {
    //     self.setState({
    //       isHideButton: false
    //     });
    //   } else {
    //     self.setState({
    //       isHideButton: true
    //     });
    //   }
    // }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    // Change checkbox group to grid
    // if (prevState.dsPhoiHop !== this.state.dsPhoiHop) {
    //   if (this.state.dsPhoiHop.length + this.state.dsXemDeBiet.length + this.state.disabledDepartmentList.length === this.state.departmentSelections.length) {
    //     this.setState({isCheckedAllCompositive: true});
    //   } else {
    //     this.setState({isCheckedAllCompositive: false});
    //   }
    // }
    // if (prevState.dsXemDeBiet !== this.state.dsXemDeBiet) {
    //   if (this.state.dsPhoiHop.length + this.state.dsXemDeBiet.length + this.state.disabledDepartmentList.length === this.state.departmentSelections.length) {
    //     this.setState({isCheckedAllOnlySee: true});
    //   } else {
    //     this.setState({isCheckedAllOnlySee: false});
    //   }
    // }
    // if (prevState.isCheckedAllOnlySee !== this.state.isCheckedAllOnlySee) {
    //   if (this.state.dsPhoiHop.length) {
    //     this.setState({isCheckedAllCompositive: true});
    //   } else {
    //     this.setState({isCheckedAllCompositive: false});
    //   }
    //   if (this.state.dsXemDeBiet.length + this.state.dsPhoiHop.length + this.state.disabledDepartmentList.length !== this.state.departmentSelections.length) {
    //     this.setState({isCheckedAllCompositive: false});
    //   }
    // }
    // if (prevState.isCheckedAllCompositive !== this.state.isCheckedAllCompositive) {
    //   if (this.state.dsXemDeBiet.length) {
    //     this.setState({isCheckedAllOnlySee: true});
    //   } else {
    //     this.setState({isCheckedAllOnlySee: false});
    //   }
    //   if (this.state.dsXemDeBiet.length + this.state.dsPhoiHop.length + this.state.disabledDepartmentList.length !== this.state.departmentSelections.length) {
    //     this.setState({isCheckedAllOnlySee: false});
    //   }
    // }
  }

  // componentWillUnmount(){
  //   window.removeEventListener('scroll', this.handleScroll);
  // }
  //
  // handleScroll (event) {
  //   let windowSize = window.innerHeight;
  //   let gb = document.getElementById("groupButton");
  //   if (windowSize >= gb.getBoundingClientRect().top) {
  //     this.setState({
  //       isHideButton: false
  //     });
  //   }else{
  //     this.setState({
  //       isHideButton: true
  //     });
  //   }
  // }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Chỉ đạo bổ sung</h4>
        </div>

        <div className="form-container container-detail">
          <div className="pt-form-group">
            <div className="row full-row">
              <div className="col-xs-12 col-md-12 col-form-item">
                <label className="pt-label">Nội dung chỉ đạo bổ sung</label>
              </div>
              <div className="col-xs-12 col-md-12 col-form-item">
                <div className="pt-form-content">
                  <textarea
                    name="noiDung"
                    value={this.state.noiDung}
                    onChange={this.handleInputChange}
                    rows="1"
                    className="pt-input pt-fill"
                    dir="auto"
                    autoFocus={true}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          {/* Design Update*/}

          {/* Bổ sung phối hợp*/}
          {/*<div className="selectbox-group">
            <div className="heading-group-select disabled-checkbox">
              <span className="pull-left name_group">Bổ sung phối hợp</span>
              <Checkbox
                className="pull-right"
                name="allCompositive"
                checked={this.state.isCheckedAllCompositive}
                onChange={this.handleCheckAllCompositive}
                disabled={this.state.dsXemDeBiet.length + this.state.disabledDepartmentList.length === this.state.departmentSelections.length || !this.state.departmentSelections.length || this.state.disabledDepartmentList.length === this.state.departmentSelections.length}
              >
                <span className="name_checkbox">Chọn tất cả</span>
              </Checkbox>
            </div>
            <div className="container-checkbox">
              <CheckboxGroup
                name="phoiHop"
                options={this.state.departmentSelections}
                selectedOptions={this.state.dsPhoiHop}
                onChange={this.handleCompositiveDepartmentSelection}
                disabledList={this.state.dsXemDeBiet.concat(this.state.disabledDepartmentList)}
                colum="col-xs-6 col-sm-6 col-md-4"
              />
            </div>
          </div>*/}

          {/* Xem để biết*/}
          {/*<div className="selectbox-group">
            <div className="heading-group-select disabled-checkbox">
              <span className="pull-left name_group">Xem để biết</span>
              <Checkbox
                className="pull-right"
                name="allOnlySee"
                checked={this.state.isCheckedAllOnlySee}
                onChange={this.handleCheckAllOnlySee}
                disabled={this.state.dsPhoiHop.length + this.state.disabledDepartmentList.length === this.state.departmentSelections.length || !this.state.departmentSelections.length || this.state.disabledDepartmentList.length === this.state.departmentSelections.length}
              >
                <span className="name_checkbox">Chọn tất cả</span>
              </Checkbox>
            </div>
            <div className="container-checkbox">
              <CheckboxGroup
                name="xemDeBiet"
                options={this.state.departmentSelections}
                selectedOptions={this.state.dsXemDeBiet}
                onChange={this.handleOnlySeeDepartmentSelection}
                disabledList={this.state.dsPhoiHop.concat(this.state.disabledDepartmentList)}
                colum="col-xs-6 col-sm-6 col-md-4"
              />
            </div>
          </div>*/}

          <div className="pt-form-group">
            <label className="pt-label pt0">Phòng ban thực hiện</label>
            <DepartmentPerform
              options={this.state.departmentSelections}
              onSubmit={this.handleGetValueGridCheckBox.bind(
                this,
                'departments'
              )}
              dsPhoiHop={this.state.dsPhoiHop}
              dsXemDeBiet={this.state.dsXemDeBiet}
              chuTriId={this.state.chuTriId}
              disabledListCompositive={
                this.state.disabledListCompositiveDepartments
              }
              disabledListOnlySee={this.state.disabledListOnlySeeDepartments}
              selectedCompositiveDepartments={
                this.state.selectedCompositiveDepartments
              }
              selectedOnlySeeDepartments={this.state.selectedOnlySeeDepartments}
              titles={['CT', 'BSPH', 'XĐB']}
              isHasRadio={false}
              type="Phòng ban"
            />
          </div>

          {/* Các đơn vị trực thuộc */}
          {this.state.attachedUnitSelections &&
            this.state.attachedUnitSelections.length > 0 && (
              <Authorization.Element
                permission={[Permission.CONGVANDEN_CHIDAO_DVTRUCTHUOC]}
              >
                <div className="selectbox-group">
                  <div className="heading-group-select disabled-checkbox">
                    <label className="pt-label pt0">
                      <span
                        className="pull-left name_group name_group_unit"
                        onClick={this.handleClickArrow}
                      >
                        Các đơn vị trực thuộc
                      </span>
                    </label>
                    <span
                      onClick={this.handleClickArrow}
                      className={classnames('name_group_unit', {
                        'pt-icon pt-icon-chevron-up': this.state.isOpen,
                        'pt-icon pt-icon-chevron-down': !this.state.isOpen,
                      })}
                    ></span>
                  </div>
                  <Collapse isOpen={this.state.isOpen}>
                    <div className="pt-form-group">
                      <DepartmentPerform
                        options={this.state.attachedUnitSelections}
                        onSubmit={this.handleGetValueGridCheckBox.bind(
                          this,
                          'units'
                        )}
                        dsPhoiHop={this.state.dsDonViPhoiHop}
                        dsXemDeBiet={this.state.dsDonViXemDeBiet}
                        chuTriId={this.state.donViChuTriId}
                        disabledListCompositive={
                          this.state.disabledListCompositiveUnits
                        }
                        disabledListOnlySee={
                          this.state.disabledListOnlySeeUnits
                        }
                        selectedCompositiveDepartments={
                          this.state.selectedCompositiveUnits
                        }
                        selectedOnlySeeDepartments={
                          this.state.selectedOnlySeeUnits
                        }
                        titles={['CT', 'BSPH', 'XĐB']}
                        isHasRadio={false}
                        type="Đơn vị"
                      />
                    </div>
                  </Collapse>
                </div>
              </Authorization.Element>
            )}

          <div className="pt-form-group pt-inline resolve-date pt-intent-danger">
            <div className="row full-row">
              <div className="col-xs-12 col-sm-12">
                <label className="pt-label resolve-date-padding0">
                  Hạn giải quyết
                </label>
                <div className="pt-form-content">
                  <div className="pt-input-group pt-large">
                    <DatePicker
                      isClearable={this.state.dealineToString ? true : false}
                      readOnly={this.props.readOnly}
                      selected={this.state.hanGiaiQuyet}
                      onChange={this.handleDateChange.bind(
                        this,
                        'hanGiaiQuyet',
                        'dealineToString'
                      )}
                      value={this.state.dealineToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dealineToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'hanGiaiQuyet',
                          'dealineToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Chọn ngày..."
                      minDate={moment()}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div id="groupButton" className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>

        <div className="buttton-actionfooter-fixtop buttton-action-footer">
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
          >
            <span className="pt-icon icon-save"></span>
            <span className="text-content">Lưu</span>
          </Button>
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
        </div>

        {/*<PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.HUY_GUI_CHI_DAO}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.props.onClickGoBack}
        >
          {POPUP.TEXT_BODY}
        </PopupConfirm>*/}
      </div>
    )
  }
}

export default AdditionalAssignment
