import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { decamelizeKeys } from 'humps'
import { MasterLayout } from '../../components/layout'
import { TabItem } from '../../components/DocumentCabinet'
import { DanhSachTuTaiLieu } from '../../components/tutailieu'
import { HeadingPage, PopupConfirm } from '../../components/common'
import { Tab2, Tabs2, Intent } from '@blueprintjs/core'
import { Permission, Authorization } from '../../components/auth'
import * as Actions from '../../actions'
import * as MESSAGE from '../../constants/MessageForm'
import { Utils } from '../../helpers'
import * as Types from '../../constants/Api'
import moment from 'moment'
import csx from 'classnames'
import { get, intersection, isEmpty } from 'lodash'

import DocumentSearch from 'containers/pages/DocumentSearch'

const REFRESH = false
class ListDocumentsCabinet extends React.Component {
  constructor(props) {
    super(props)
    let state = {
      saveTo: Authorization.vaild(this.props.auth.permission, [
        Permission.TUTAILIEUPHONGBAN,
      ])
        ? Types.CABINET_DEPARTMENT
        : Authorization.vaild(this.props.auth.permission, [
            Permission.TUTAILIEUDONVI,
          ])
        ? Types.CABINET_UNIT
        : Types.CABINET_PERSONAL,
      filter: !isEmpty(get(props.location, 'state.filter'))
        ? get(props.location, 'state.filter')
        : {},
      filterColumn: {},
      listCheckIds: [],
      sort: undefined,
      nganTuId: undefined,
      phongBanId: this.props.auth.mainDepartmentId,
      isEmpty: false,
      isLoading: true,
      page: get(props.location, 'state.page') || 1,
      isShowTabItem: false,
      tenNganTu: '',
      isError: false,
      isShow: false,
      isCheckAll: false,
      keyTabTuTaiLieu: '3',
      phongBanLapDS: [],
      type: get(this.props.match.params, 'type', ''),
      isOpenPopup: false,
      isCheckAllData: false,
      showDocumentSearch: false,
    }
    if (props.location && props.location.state && props.location.state.type) {
      state.saveTo = props.location.state.type
      state.keyTabTuTaiLieu = props.location.state.keyTabTuTaiLieu
    }

    this.state = state
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleCategoryClick = this.handleCategoryClick.bind(this)
    this.switchTabs = this.switchTabs.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.updateCategories = this.updateCategories.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.updateListItem = this.updateListItem.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleMultiDelete = this.handleMultiDelete.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.handleChangeOwner = this.handleChangeOwner.bind(this)
    this.handleMoveFile = this.handleMoveFile.bind(this)
    this.showContentSideBar = this.showContentSideBar.bind(this)
    this.handlePage = this.handlePage.bind(this)
    this.onChangeGridViewFilter = this.onChangeGridViewFilter.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
  }

  componentDidMount() {
    this.props.actions.getNumberHSCV()
    this.props.actions.getThoiGianLuuDanhSach({ isAll: false })
    if (this.props.staffs && this.props.staffs.length === 0) {
      this.props.actions.getCategoryDepartmentMultiLevel(
        this.props.auth.mainUnitId
      )
    }

    if (this.props.units && this.props.units.length === 0) {
      this.props.actions.commonFetchUnitsRelative(this.props.auth.mainUnitId)
    }

    this.checkTab()
  }

  checkTab = async () => {
    let tmpState = { ...this.state }
    const { auth, counterHSCV, location } = this.props
    // Focus tủ tài liệu phòng ban khi ở role lđpb và chuyên viên
    if (
      Authorization.vaild(auth.permission, [
        Permission.TUTAILIEUPHONGBAN_QUANLY,
      ])
    ) {
      tmpState.saveTo = '2'
    }

    if (
      Authorization.vaild(auth.permission, [Permission.HSCV_PHONGBAN_DUYET])
    ) {
      tmpState.saveTo = '2'
      if (get(counterHSCV, 'phongBan[1]', 0) > 0) {
        tmpState.keyTabTuTaiLieu = get(location, 'state.keyTabTuTaiLieu') || '3'
      }
    }

    if (
      (Authorization.vaild(auth.permission, [Permission.HSCV_VANTHU]) &&
        get(counterHSCV, 'donVi[2]', 0) > 0) ||
      (Authorization.vaild(auth.permission, [Permission.HSCV_VANPHONG_DUYET]) &&
        get(counterHSCV, 'donVi[3]', 0) > 0)
    ) {
      tmpState.keyTabTuTaiLieu = get(location, 'state.keyTabTuTaiLieu') || '3'
      tmpState.saveTo = '1'
    }

    if (this.props.location.state && this.props.location.state.type) {
      tmpState.saveTo = this.props.location.state.type
    }

    const preData = Utils.preData(`List-CabinetDocuments-${tmpState.saveTo}`)
    const prevShowDocumentSearch = Utils.getData(
      `List-CabinetDocuments-showDocumentSearch`
    )

    if (preData) {
      tmpState = {
        ...preData,
        ...tmpState,
        nganTuId: get(preData, 'nganTuId', null),
        filterColumn: {
          ngayCongVan: get(preData, 'filterColumn.ngayCongVan')
            ? moment(get(preData, 'filterColumn.ngayCongVan'))
            : null,
          ngayLuu: get(preData, 'filterColumn.ngayLuu')
            ? moment(get(preData, 'filterColumn.ngayLuu'))
            : null,
          coQuanBanHanhId: get(preData, 'filterColumn.coQuanBanHanhId')
            ? get(preData, 'filterColumn.coQuanBanHanhId')
            : '',
          coQuanBanHanh: get(preData, 'filterColumn.coQuanBanHanh')
            ? get(preData, 'filterColumn.coQuanBanHanh')
            : null,
          tenTaiLieu: get(preData, 'filterColumn.tenTaiLieu')
            ? get(preData, 'filterColumn.tenTaiLieu')
            : null,
        },
      }
    }

    this.setState(tmpState)
    if (preData) {
      this.switchTabs2(tmpState.saveTo)

      if (prevShowDocumentSearch) {
        this.setState({
          saveTo: '2',
          showDocumentSearch: !!prevShowDocumentSearch,
        })
      }

      return this.handlePageClick({ selected: tmpState.page - 1 })
    }

    this.switchTabs(tmpState.saveTo)

    if (prevShowDocumentSearch) {
      this.setState({
        saveTo: '2',
        showDocumentSearch: !!prevShowDocumentSearch,
      })
    }
  }

  handlePageClick(e) {
    this.setState(
      {
        page: e.selected + 1,
      },
      () => {
        this.updateListItem(this.state.filter, this.state.page, this.state.sort)
      }
    )
  }

  switchTabs2 = e => {
    if (this.props.config && this.props.config.refresh) {
      this.props.actions.refreshPage(false)
      this.updateCategories(this.props.auth.mainDepartmentId, 'Update')
    } else {
      this.updateCategories()
    }
  }

  handleCategoryClick(e) {
    this.setState(
      {
        nganTuId: e.id,
      },
      this.updateListItem
    )
  }

  switchTabs(e) {
    this.setState(
      {
        nganTuId: undefined,
        filter: {},
        listCheckIds: [],
        sort: undefined,
        saveTo: e,
        isError: false,
        isShow: false,
        isLoading: true,
        filterColumn: {},
        showDocumentSearch: false,
      },
      () => {
        if (this.props.config && this.props.config.refresh) {
          this.props.actions.refreshPage(false)
          this.updateCategories(this.props.auth.mainDepartmentId, 'Update')
        } else {
          this.updateCategories()
        }

        // Check active tabs HSCV khi có chấm đỏ
        const { auth, counterHSCV } = this.props
        if (
          e === '1' &&
          ((Authorization.vaild(auth.permission, [Permission.HSCV_VANTHU]) &&
            get(counterHSCV, 'donVi[2]', 0) > 0) ||
            (Authorization.vaild(auth.permission, [
              Permission.HSCV_VANPHONG_DUYET,
            ]) &&
              get(counterHSCV, 'donVi[3]', 0) > 0))
        ) {
          this.setState({
            keyTabTuTaiLieu: '3',
          })
        }
        if (
          e === '1' &&
          intersection(this.props.auth.permission, [
            Permission.HSCV_VANTHU,
            Permission.HSCV_VANPHONG_DUYET,
          ]).length === 0
        ) {
          this.setState({
            keyTabTuTaiLieu: '1',
          })
        }
        if (
          e === '2' &&
          Authorization.vaild(auth.permission, [
            Permission.HSCV_PHONGBAN_DUYET,
          ]) &&
          get(counterHSCV, 'phongBan[1]', 0) > 0
        ) {
          this.setState({
            keyTabTuTaiLieu: '3',
          })
        }

        this.updateListItem()
      }
    )
  }

  submitFilter(e) {
    this.updateListItem(e)
  }

  handleSort(sort) {
    this.updateListItem(this.state.filter, this.state.page, sort)
  }

  setFilter = ({ filter, isSubmit }) => {
    this.setState({ filter }, () => {
      isSubmit && this.updateListItem()
    })
  }

  handleSubmit(data, type) {
    if (data.nganTuChaId === data.id) {
      delete data.nganTuChaId
    }

    data = decamelizeKeys(data)
    let request
    switch (type) {
      case 'Edit':
        switch (this.state.saveTo) {
          case Types.CABINET_PERSONAL:
            request =
              this.props.actions.documentsCabinetUpdatePersonalCategory(data)
            break
          case Types.CABINET_DEPARTMENT:
            request =
              this.props.actions.documentsCabinetUpdateDepartmentCategory(data)
            break
          default:
            request =
              this.props.actions.documentsCabinetUpdateUnitCategory(data)
        }
        break
      default:
        switch (this.state.saveTo) {
          case Types.CABINET_PERSONAL:
            request =
              this.props.actions.documentsCabinetSavePersonalCategory(data)
            break
          case Types.CABINET_DEPARTMENT:
            request =
              this.props.actions.documentsCabinetSaveDepartmentCategory(data)
            break
          default:
            request = this.props.actions.documentsCabinetSaveUnitCategory(data)
        }
    }

    request.then(async res => {
      if (res.error && res.error.response && res.error.response.data) {
        return this.props.actions.commonAddToasterMessage({
          message:
            res.error.response.data.message || MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }

      this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
      this.updateCategories(this.props.auth.mainDepartmentId, 'Update', REFRESH)
      if (data.id && data.id === this.state.nganTuId && type === 'Edit') {
        this.setState({ nganTuId: undefined }, () => this.updateListItem())
      } else {
        this.updateListItem()
      }
    })
  }

  handleSubmitRemoveItem(id) {
    let request
    switch (this.state.saveTo) {
      case Types.CABINET_PERSONAL:
        request = this.props.actions.documentsCabinetDeletePersonalCategory(id)
        break
      case Types.CABINET_DEPARTMENT:
        request =
          this.props.actions.documentsCabinetDeleteDepartmentCategory(id)
        break
      default:
        request = this.props.actions.documentsCabinetDeleteUnitCategory(id)
    }

    request.then(async res => {
      if (res.error) {
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
          intent: Intent.DANGER,
        })
      }

      this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
        intent: Intent.SUCCESS,
      })
      this.updateCategories(this.props.auth.mainDepartmentId, 'Update', REFRESH)
      await this.setState({ listCheckIds: [] })
      if (id === this.state.nganTuId) {
        this.setState({ nganTuId: undefined }, () => this.updateListItem())
      } else {
        this.updateListItem()
      }
    })
  }

  updateCategories(
    id = this.props.auth.mainDepartmentId,
    type = null,
    refresh
  ) {
    this.setState({ isLoading: true, phongBanId: id })
    let request
    switch (this.state.saveTo) {
      case Types.CABINET_PERSONAL:
        request = this.props.actions.documentsCabinetGetListCategoriesPersonal(
          type,
          refresh
        )
        break
      case Types.CABINET_DEPARTMENT:
        type !== 'Update' &&
          this.props.actions.commonFetchStaffByDepartmentId(id)
        request =
          this.props.actions.documentsCabinetGetListCategoriesDepartment(
            id,
            type,
            refresh
          )
        break
      default:
        type !== 'Update' &&
          this.props.actions.commonFetchStaffByUnitId(
            this.props.auth.mainUnitId
          )
        request = this.props.actions.documentsCabinetGetListCategoriesUnit(
          type,
          refresh
        )
    }

    return request.then(res => {
      // this.setState({ isLoading: false });
      return res
    })
  }

  updateListItem(e = {}, page = 1, sort) {
    e = {
      ...e,
      status: this.state.filter.status,
    }

    const filter = Object.assign({}, this.state.filter, e)
    const { nganTuId, phongBanId, saveTo } = this.state
    const refresh = this.props.config.refreshDocumentCabinet
    const filterColumn = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : null,
      ngayLuu: this.state.filterColumn.ngayLuu
        ? moment(this.state.filterColumn.ngayLuu).format('YYYY-MM-DD')
        : null,
      coQuanBanHanhId:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId !== '#'
          ? this.state.filterColumn.coQuanBanHanhId
          : null,
      coQuanBanHanh:
        this.state.filterColumn.coQuanBanHanhId &&
        this.state.filterColumn.coQuanBanHanhId === '#'
          ? !this.state.filterColumn.coQuanBanHanh
            ? ''
            : this.state.filterColumn.coQuanBanHanh
          : null,
      tenTaiLieu:
        this.state.filterColumn.tenTaiLieu &&
        this.state.filterColumn.tenTaiLieu !== ''
          ? this.state.filterColumn.tenTaiLieu
          : null,
      nguoiLuu:
        this.state.filterColumn.nguoiLuu &&
        this.state.filterColumn.nguoiLuu !== ''
          ? this.state.filterColumn.nguoiLuu
          : null,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
    }

    Utils.preData(`List-CabinetDocuments-${this.state.saveTo}`, {
      phongBanId,
      nganTuId,
      saveTo,
      filter,
      page,
      sort,
      filterColumn,
    })
    this.setState({ filter, page, isLoading: true, sort }, () => {
      let request
      let isEmpty = false
      let isError = false

      switch (this.state.saveTo) {
        case Types.CABINET_PERSONAL:
          request = this.props.actions.documentsCabinetGetByPersonal(
            { nganTuId, filter },
            page,
            sort,
            refresh,
            filterColumn
          )
          break
        case Types.CABINET_DEPARTMENT:
          request = this.props.actions.documentsCabinetGetByDepartment(
            { nganTuId, filter },
            page,
            sort,
            refresh,
            filterColumn
          )
          break
        default:
          request = this.props.actions.documentsCabinetGetByUnit(
            { nganTuId, filter },
            page,
            sort,
            refresh,
            filterColumn
          )
      }

      request
        .then(res => {
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
        })
        .then(res => {
          let object = false
          if (
            Object.values(filterColumn).find(item => item !== null) !==
            undefined
          ) {
            object = true
          }
          this.setState({
            isEmpty,
            isError,
            isLoading: false,
            isShow: object,
            isCheckAll: false,
          })
          this.props.actions.refreshDocumentCabinet(false)
        })
    })
  }

  handlePage(data, length) {
    let total = data || 0
    let page = this.state.page

    if (total > 0) {
      total -= length
    }
    if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
      page = Math.ceil(total / Types.PAGINATION_LIMIT)
    }
    if (page === 0) {
      page = 1
    }
    return page
  }

  handleMultiDelete() {
    this.setState({ isLoading: true }, () => {
      let request
      let total = 0
      let page = this.state.page
      switch (this.state.saveTo) {
        case Types.CABINET_PERSONAL:
          request = this.props.actions
            .documentsCabinetDeletePersonal(this.state.listCheckIds)
            .then(res => {
              if (res.error) {
                return this.props.actions.commonAddToasterMessage({
                  message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
                  intent: Intent.DANGER,
                })
              }

              total =
                this.props.documentsCabinet &&
                this.props.documentsCabinet.typeItems &&
                this.props.documentsCabinet.typeItems.personal &&
                this.props.documentsCabinet.typeItems.personal.total

              page = this.handlePage(total, this.state.listCheckIds.length)
            })
          break

        case Types.CABINET_DEPARTMENT:
          request = this.props.actions
            .documentsCabinetDeleteDepartment(this.state.listCheckIds)
            .then(res => {
              if (res.error) {
                return this.props.actions.commonAddToasterMessage({
                  message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
                  intent: Intent.DANGER,
                })
              }

              total =
                this.props.documentsCabinet &&
                this.props.documentsCabinet.typeItems &&
                this.props.documentsCabinet.typeItems.department &&
                this.props.documentsCabinet.typeItems.department.total

              page = this.handlePage(total, this.state.listCheckIds.length)
            })
          break

        default:
          request = this.props.actions
            .documentsCabinetDeleteUnit(this.state.listCheckIds)
            .then(res => {
              if (res.error) {
                return this.props.actions.commonAddToasterMessage({
                  message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
                  intent: Intent.DANGER,
                })
              }

              total =
                this.props.documentsCabinet &&
                this.props.documentsCabinet.typeItems &&
                this.props.documentsCabinet.typeItems.unit &&
                this.props.documentsCabinet.typeItems.unit.total

              page = this.handlePage(total, this.state.listCheckIds.length)
            })
      }

      request.then(() => {
        this.setState({ isLoading: false, listCheckIds: [] })
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
        // Refresh danh sach tai tieu
        this.props.actions.refreshDocumentCabinet(true)
        this.updateListItem(this.state.filter, page, this.state.sort)
      })
    })
  }

  handleCheck(e, item) {
    let { listCheckIds } = this.state
    if (e) {
      listCheckIds.findIndex(id => id === item.id) === -1 &&
        listCheckIds.push(item.id)
    } else {
      listCheckIds.splice(listCheckIds.indexOf(item.id), 1)
    }

    this.setState({
      listCheckIds,
    })
  }

  handleChangeOwner(e) {}

  handleMoveFile() {
    if (this.state.isCheckAllData && this.state.nganTuId) {
      this.setState({
        isOpenPopup: true,
      })
    } else {
      let typeName
      switch (this.state.saveTo) {
        case Types.CABINET_PERSONAL:
          typeName = 'ca-nhan'
          break
        case Types.CABINET_DEPARTMENT:
          typeName = 'phong-ban'
          break
        default:
          typeName = 'don-vi'
      }

      this.props.history.push({
        pathname: `/tu-tai-lieu/di-chuyen/${typeName}`,
        state: {
          listCheckIds: this.state.listCheckIds,
          nganTuId: this.state.nganTuId,
        },
      })
    }
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
    })
  }

  onSubmit = () => {
    if (this.state.isCheckAllData) {
      let typeName
      switch (this.state.saveTo) {
        case Types.CABINET_PERSONAL:
          typeName = 'ca-nhan'
          break
        case Types.CABINET_DEPARTMENT:
          typeName = 'phong-ban'
          break
        default:
          typeName = 'don-vi'
      }

      this.props.history.push({
        pathname: `/tu-tai-lieu/di-chuyen/${typeName}`,
        state: {
          listCheckIds: this.state.listCheckIds,
          isCheckAllData: true,
          nganTuId: this.state.nganTuId,
        },
      })
    }
  }

  //show mobile
  showContentSideBar(item) {
    this.setState({
      isShowTabItem: !this.state.isShowTabItem,
      tenNganTu: item.tenNganTu,
    })
  }

  onChangeGridViewFilter(e) {
    const value = e.target.value
    const name = e.target.name
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      },
      () => {
        if (name === 'coQuanBanHanhId') {
          this.updateListItem()
        }
      }
    )
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.updateListItem()
      }
    )
  }

  clearFilterColumn(filter) {
    this.setState(
      {
        filterColumn: {},
      },
      () => {
        this.updateListItem(filter)
      }
    )
  }

  onRemoveText(name) {
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.updateListItem()
      }
    )
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.documentsCabinet &&
      this.props.documentsCabinet.list &&
      this.props.documentsCabinet.list.items
    ) {
      if (this.state.isCheckAll && !this.isCheckAllIdsPage()) {
        this.setState({ isCheckAll: false })
      }
      if (!this.state.isCheckAll && this.isCheckAllIdsPage()) {
        this.setState({ isCheckAll: true })
      }
    }

    if (prevState.showDocumentSearch !== this.state.showDocumentSearch) {
      const key = `List-CabinetDocuments-showDocumentSearch`
      Utils.saveData(key, this.state.showDocumentSearch)
    }
  }

  componentWillUnmount() {
    Utils.removeData('List-CabinetDocuments-showDocumentSearch')
  }

  isCheckAllIdsPage = () => {
    let countCheck = 0
    this.props.documentsCabinet.list.items.forEach(item => {
      if (this.state.listCheckIds.findIndex(id => id === item.id) !== -1) {
        countCheck += 1
      }
    })
    return countCheck === this.props.documentsCabinet.list.items.length
  }

  removeCheckIdsPage = listCheckIds => {
    let newListCheckIds = [...listCheckIds]
    if (
      this.props.documentsCabinet.list &&
      this.props.documentsCabinet.list.items
    ) {
      this.props.documentsCabinet.list.items.forEach(item => {
        newListCheckIds = newListCheckIds.filter(id => id !== item.id)
      })
    }
    return newListCheckIds
  }

  addCheckIdsPage = listCheckIds => {
    let newListCheckIds = [...listCheckIds]
    newListCheckIds = this.removeCheckIdsPage(newListCheckIds)
    if (
      this.props.documentsCabinet.list &&
      this.props.documentsCabinet.list.items
    ) {
      newListCheckIds = [
        ...newListCheckIds,
        ...this.props.documentsCabinet.list.items.map(item => item.id),
      ]
    }
    return newListCheckIds
  }

  handleCheckAll = () => {
    if (this.state.isCheckAll) {
      this.setState({
        listCheckIds: this.removeCheckIdsPage(this.state.listCheckIds),
        isCheckAll: !this.state.isCheckAll,
      })
    } else {
      this.setState({
        listCheckIds: this.addCheckIdsPage(this.state.listCheckIds),
        isCheckAll: !this.state.isCheckAll,
      })
    }
  }

  hanldCheckAllData = () => {
    this.setState(
      {
        isCheckAllData: !this.state.isCheckAllData,
      },
      () => {
        if (this.state.isCheckAllData) {
          this.setState({
            listCheckIds: [],
          })
        }
      }
    )
  }

  handleChangeRadio = keyRadio => {
    this.setState({
      keyTabTuTaiLieu: keyRadio,
    })
  }

  moveToDetails = id => {
    const type = {
      1: 'don-vi',
      2: 'phong-ban',
      3: 'ca-nhan',
    }[this.state.saveTo]

    if (type) {
      this.props.history.push({
        pathname: `/ho-so-cong-viec/tai-lieu/chi-tiet/${type}/${id}`,
        state: {
          prevPath: this.props.location.pathname,
          store: {
            key: 'List-CabinetDocuments-showDocumentSearch',
            value: true,
          },
        },
      })
    }
  }

  toggleShowDocumentSearch = () => {
    localStorage.removeItem('TTL-HSCV-TimKiemCongVan')
    this.setState(prev => ({
      showDocumentSearch: !prev.showDocumentSearch,
    }))
  }

  renderTimKiemCongVan = () => (
    <DocumentSearch
      toggleShowDocumentSearch={this.toggleShowDocumentSearch}
      moveToDetails={this.moveToDetails}
    />
  )

  renderDSTuTaiLieu = props => (
    <DanhSachTuTaiLieu
      {...props}
      actions={this.props.actions}
      auth={this.props.auth}
      history={this.props.history}
      type={this.state.saveTo}
      thoiHanBaoQuanDanhSach={this.props.thoiHanBaoQuanDanhSach}
      handleChangeRadio={this.handleChangeRadio}
      keyTabTuTaiLieu={this.state.keyTabTuTaiLieu}
      typeApp={this.state.type}
      toggleShowDocumentSearch={this.toggleShowDocumentSearch}
    />
  )

  render() {
    const { keyTabTuTaiLieu } = this.state

    const { counterHSCV, auth } = this.props
    return (
      <MasterLayout typeSidebar="documentsCabinet">
        <div className="listcontact-container">
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title="Xác nhận di chuyển"
            text="Có"
            onSubmit={this.onSubmit}
          >
            Anh/chị có muốn di chuyển tất cả tài liệu?
          </PopupConfirm>
          <HeadingPage namePage="Tủ tài liệu" iconPage="icon-Tu_Tai_Lieu" />
          <div className="page-list-container page-list-document-cabinet-container">
            <Tabs2
              id="Tabs2DocumentsCabinet"
              className="pt-tabs tab-cus-container"
              onChange={this.switchTabs}
              selectedTabId={this.state.saveTo}
              renderActiveTabPanelOnly
            >
              <Tab2
                id="1"
                disabled={
                  !Authorization.vaild(this.props.auth.permission, [
                    Permission.TUTAILIEUDONVI,
                  ])
                }
                title={
                  <span
                    className={csx({
                      'has-new':
                        (Authorization.vaild(auth.permission, [
                          Permission.HSCV_VANTHU,
                        ]) &&
                          get(counterHSCV, 'donVi[2]', 0) > 0) ||
                        (Authorization.vaild(auth.permission, [
                          Permission.HSCV_VANPHONG_DUYET,
                        ]) &&
                          get(counterHSCV, 'donVi[3]', 0) > 0),
                    })}
                  >
                    TTL ĐƠN VỊ
                  </span>
                }
                panel={
                  keyTabTuTaiLieu === '1' ? (
                    <TabItem
                      nameTab="TTL ĐƠN VỊ"
                      type={this.state.saveTo}
                      units={this.props.units}
                      departmentSelect={this.props.departmentSelect.items}
                      categories={this.props.documentsCabinet.type.unit}
                      disableMove={
                        this.state.listCheckIds.length === 0 &&
                        !this.state.isCheckAllData
                      }
                      disableDelete={this.state.listCheckIds.length === 0}
                      list={this.props.documentsCabinet.typeItems.unit}
                      onPageClick={this.handlePageClick}
                      onCategoryClick={this.handleCategoryClick}
                      staffs={this.props.staffs}
                      onSubmitFilter={this.submitFilter}
                      onSubmit={this.handleSubmit}
                      onSort={this.handleSort}
                      onSubmitRemoveItem={this.handleSubmitRemoveItem}
                      onMultiDelete={this.handleMultiDelete}
                      onCheck={this.handleCheck}
                      onChangeOwner={this.handleChangeOwner}
                      onMoveFile={this.handleMoveFile}
                      isLoading={this.state.isLoading}
                      isEmpty={this.state.isEmpty}
                      auth={this.props.auth}
                      updateCategories={this.updateCategories}
                      phongBanId={this.state.phongBanId}
                      nganTuId={this.state.nganTuId}
                      filter={this.state.filter}
                      actionPermissions={[Permission.TUTAILIEUDONVI_QUANLY]}
                      forcePage={this.state.page - 1}
                      showContentSideBar={this.showContentSideBar}
                      isShowTabItem={this.state.isShowTabItem}
                      readOnly={this.props.readOnly}
                      tenNganTu={this.state.tenNganTu}
                      isError={this.state.isError}
                      actions={this.props.actions}
                      onChange={this.onChangeGridViewFilter}
                      onChangeDate={this.onChangeDateGridViewFilter}
                      isShow={this.state.isShow}
                      coQuanBanHanhId={this.props.relativeUnits}
                      filterColumn={this.state.filterColumn}
                      onRemoveText={this.onRemoveText}
                      pageCount={Math.ceil(
                        this.props.documentsCabinet.typeItems.unit.total /
                          Types.PAGINATION_LIMIT
                      )}
                      clearFilterColumn={this.clearFilterColumn}
                      match={this.props.match}
                      handleCheckAll={this.handleCheckAll}
                      listCheckIds={this.state.listCheckIds}
                      isCheckAll={this.state.isCheckAll}
                      setFilter={this.setFilter}
                      pathDocumentCabinet={this.props.documentsCabinet.path}
                      handleChangeRadio={this.handleChangeRadio}
                      keyTab="TTL_DV"
                      isCheckAllData={this.state.isCheckAllData}
                      hanldCheckAllData={this.hanldCheckAllData}
                      keyTabTuTaiLieu={this.state.keyTabTuTaiLieu}
                      page={this.state.page}
                    />
                  ) : (
                    this.renderDSTuTaiLieu({
                      keyTab: 'TTL_DV',
                      nameTab: 'TTL ĐƠN VỊ',
                    })
                  )
                }
              />
              <Tab2
                id="2"
                disabled={
                  !Authorization.vaild(this.props.auth.permission, [
                    Permission.TUTAILIEUPHONGBAN,
                  ])
                }
                title={
                  <span
                    className={csx({
                      'has-new':
                        Authorization.vaild(auth.permission, [
                          Permission.HSCV_PHONGBAN_DUYET,
                        ]) && get(counterHSCV, 'phongBan[1]', 0) > 0,
                    })}
                  >
                    TTL PHÒNG BAN
                  </span>
                }
                panel={
                  keyTabTuTaiLieu === '2' ? (
                    <TabItem
                      nameTab="TTL PHÒNG BAN"
                      type={this.state.saveTo}
                      units={this.props.units}
                      departmentSelect={this.props.departmentSelect.items}
                      categories={this.props.documentsCabinet.type.department}
                      disableMove={
                        this.state.listCheckIds.length === 0 &&
                        !this.state.isCheckAllData
                      }
                      disableDelete={this.state.listCheckIds.length === 0}
                      list={this.props.documentsCabinet.typeItems.department}
                      onPageClick={this.handlePageClick}
                      onCategoryClick={this.handleCategoryClick}
                      staffs={this.props.staffs}
                      onSubmitFilter={this.submitFilter}
                      onSubmit={this.handleSubmit}
                      onSort={this.handleSort}
                      onSubmitRemoveItem={this.handleSubmitRemoveItem}
                      onMultiDelete={this.handleMultiDelete}
                      onCheck={this.handleCheck}
                      onChangeOwner={this.handleChangeOwner}
                      onMoveFile={this.handleMoveFile}
                      isLoading={this.state.isLoading}
                      isEmpty={this.state.isEmpty}
                      auth={this.props.auth}
                      updateCategories={this.updateCategories}
                      phongBanId={this.state.phongBanId}
                      nganTuId={this.state.nganTuId}
                      filter={this.state.filter}
                      actionPermissions={[Permission.TUTAILIEUPHONGBAN]}
                      cabinetPermission={[Permission.TUTAILIEUPHONGBAN_QUANLY]}
                      forcePage={this.state.page - 1}
                      showContentSideBar={this.showContentSideBar}
                      isShowTabItem={this.state.isShowTabItem}
                      readOnly={this.props.readOnly}
                      tenNganTu={this.state.tenNganTu}
                      isError={this.state.isError}
                      actions={this.props.actions}
                      onChange={this.onChangeGridViewFilter}
                      onChangeDate={this.onChangeDateGridViewFilter}
                      isShow={this.state.isShow}
                      coQuanBanHanhId={this.props.relativeUnits}
                      filterColumn={this.state.filterColumn}
                      onRemoveText={this.onRemoveText}
                      pageCount={Math.ceil(
                        this.props.documentsCabinet.typeItems.department.total /
                          Types.PAGINATION_LIMIT
                      )}
                      clearFilterColumn={this.clearFilterColumn}
                      match={this.props.match}
                      handleCheckAll={this.handleCheckAll}
                      listCheckIds={this.state.listCheckIds}
                      isCheckAll={this.state.isCheckAll}
                      setFilter={this.setFilter}
                      pathDocumentCabinet={this.props.documentsCabinet.path}
                      handleChangeRadio={this.handleChangeRadio}
                      keyTab="TTL_PB"
                      isCheckAllData={this.state.isCheckAllData}
                      hanldCheckAllData={this.hanldCheckAllData}
                      keyTabTuTaiLieu={this.state.keyTabTuTaiLieu}
                      page={this.state.page}
                    />
                  ) : this.state.showDocumentSearch ? (
                    this.renderTimKiemCongVan()
                  ) : (
                    this.renderDSTuTaiLieu({
                      keyTab: 'TTL_PB',
                      nameTab: 'TTL PHÒNG BAN',
                    })
                  )
                }
              />
              <Tab2
                id="3"
                title="TTL CÁ NHÂN"
                panel={
                  <TabItem
                    nameTab="TTL CÁ NHÂN"
                    type={this.state.saveTo}
                    units={this.props.units}
                    departmentSelect={this.props.departmentSelect.items}
                    categories={this.props.documentsCabinet.type.personal}
                    disableMove={
                      this.state.listCheckIds.length === 0 &&
                      !this.state.isCheckAllData
                    }
                    disableDelete={this.state.listCheckIds.length === 0}
                    list={this.props.documentsCabinet.typeItems.personal}
                    onPageClick={this.handlePageClick}
                    onCategoryClick={this.handleCategoryClick}
                    staffs={this.props.staffs}
                    onSubmitFilter={this.submitFilter}
                    onSubmit={this.handleSubmit}
                    onSort={this.handleSort}
                    onSubmitRemoveItem={this.handleSubmitRemoveItem}
                    onMultiDelete={this.handleMultiDelete}
                    onCheck={this.handleCheck}
                    onChangeOwner={this.handleChangeOwner}
                    onMoveFile={this.handleMoveFile}
                    isLoading={this.state.isLoading}
                    isEmpty={this.state.isEmpty}
                    auth={this.props.auth}
                    updateCategories={this.updateCategories}
                    phongBanId={this.state.phongBanId}
                    nganTuId={this.state.nganTuId}
                    filter={this.state.filter}
                    forcePage={this.state.page - 1}
                    showContentSideBar={this.showContentSideBar}
                    isShowTabItem={this.state.isShowTabItem}
                    readOnly={this.props.readOnly}
                    tenNganTu={this.state.tenNganTu}
                    isError={this.state.isError}
                    actions={this.props.actions}
                    onChange={this.onChangeGridViewFilter}
                    onChangeDate={this.onChangeDateGridViewFilter}
                    isShow={this.state.isShow}
                    coQuanBanHanhId={this.props.relativeUnits}
                    filterColumn={this.state.filterColumn}
                    onRemoveText={this.onRemoveText}
                    pageCount={Math.ceil(
                      this.props.documentsCabinet.typeItems.personal.total /
                        Types.PAGINATION_LIMIT
                    )}
                    clearFilterColumn={this.clearFilterColumn}
                    match={this.props.match}
                    handleCheckAll={this.handleCheckAll}
                    listCheckIds={this.state.listCheckIds}
                    isCheckAll={this.state.isCheckAll}
                    setFilter={this.setFilter}
                    pathDocumentCabinet={this.props.documentsCabinet.path}
                    isCheckAllData={this.state.isCheckAllData}
                    hanldCheckAllData={this.hanldCheckAllData}
                    page={this.state.page}
                  />
                }
              />
            </Tabs2>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  config: state.common.config,
  documentsCabinet: {
    ...state.documentsCabinet,
    categories: state.documentsCabinet.categories.map(
      id => state.entities.documentCabinetCategories[id]
    ),
    type: {
      unit: state.documentsCabinet.type.unit.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      department: state.documentsCabinet.type.department.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      personal: state.documentsCabinet.type.personal.map(
        id => state.entities.documentCabinetCategories[id]
      ),
    },
    typeItems: {
      unit: {
        ...state.documentsCabinet.list,
        items: state.documentsCabinet.typeItems.unit.items.map(
          id => state.entities.documentsCabinet[id]
        ),
      },
      department: {
        ...state.documentsCabinet.list,
        items: state.documentsCabinet.typeItems.department.items.map(
          id => state.entities.documentsCabinet[id]
        ),
      },
      personal: {
        ...state.documentsCabinet.list,
        items: state.documentsCabinet.typeItems.personal.items.map(
          id => state.entities.documentsCabinet[id]
        ),
      },
    },
    list: {
      ...state.documentsCabinet.list,
      items: state.documentsCabinet.list.items.map(
        id => state.entities.documentsCabinet[id]
      ),
    },
    path: state.documentsCabinet.path,
  },
  departmentSelect: {
    ...state.category,
    items: state.category.categoryDepartmentMultiLevel,
  },
  units: state.common.relativeUnits,
  staffs: state.common.staffs,
  readOnly: state.common.enableReadOnly,
  thoiHanBaoQuanDanhSach: state.common.thoiHanBaoQuanDanhSach,
  counterHSCV: state.common.numberSideBar.hoSoCongViec,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDocumentsCabinet)
