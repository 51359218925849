import React, { Component } from 'react'
import { Button, Intent } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../../helpers'
import * as MESSAGER from '../../../constants/MessageForm'

class ListFilter extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)
    // this.clearFilter = this.clearFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    filter: PropTypes.object,
    isForChildUnit: PropTypes.bool,
  }

  state = {
    start_date: moment().subtract(1, 'months'),
    end_date: moment(),
    is_hoan_thanh: false,
    dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
    dateToToString: moment().format('DD/MM/YYYY'),
    // start_date: '',
    // end_date: '',
    // is_hoan_thanh: false,
    // dateFromToString: '',
    // dateToToString: ''
  }

  // componentDidMount() {
  //   this.handleSubmit();
  // }

  // clearFilter(e) {
  //   e.preventDefault();
  //   this.setState({
  //     start_date: moment().subtract(1, 'months'),
  //     end_date: moment(),
  //     is_hoan_thanh: false,
  //     dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
  //     dateToToString: moment().format('DD/MM/YYYY')
  //   }, () => {
  //     this.handleSubmit();
  //   });
  // }

  componentWillReceiveProps(props) {
    if (props.filter !== this.props.filter) {
      this.setState({
        start_date:
          props.filter.start_date && props.filter.start_date.trim().length > 0
            ? moment(props.filter.start_date)
            : undefined,
        end_date:
          props.filter.end_date && props.filter.end_date.trim().length > 0
            ? moment(props.filter.end_date)
            : undefined,
        dateFromToString:
          props.filter.start_date && props.filter.start_date.trim().length > 0
            ? moment(props.filter.start_date).format('DD/MM/YYYY')
            : '',
        dateToToString:
          props.filter.end_date && props.filter.end_date.trim().length > 0
            ? moment(props.filter.end_date).format('DD/MM/YYYY')
            : '',
      })
    }
  }

  handleChange(name, nameToString, e) {
    if (name === 'start_date' || name === 'end_date') {
      let value = ''
      if (e) {
        value = moment(e).format('DD/MM/YYYY')
      }

      this.setState({
        [name]: e,
        [nameToString]: value,
      })
    }
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      name === 'start_date' &&
      !this.state.end_date &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      name === 'start_date' &&
      this.state.end_date &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: moment(moment(this.state.end_date).format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    if (
      name === 'end_date' &&
      !this.state.start_date &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      name === 'end_date' &&
      this.state.start_date &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment(this.state.start_date).format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleChangeCheckbox(e) {
    if (e.target.type === 'checkbox') {
      this.setState({
        is_hoan_thanh: !this.state.is_hoan_thanh,
      })
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const start_date = this.state.start_date
      ? moment(this.state.start_date).format('YYYY-MM-DD')
      : null
    const end_date = this.state.end_date
      ? moment(this.state.end_date).format('YYYY-MM-DD')
      : null
    // const is_hoan_thanh = this.state.is_hoan_thanh;
    if (!start_date || !end_date) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TEXT_MESSAGE_TIME,
        intent: Intent.WARNING,
      })
    }
    this.props.onSubmit && this.props.onSubmit({ start_date, end_date })
  }

  render() {
    return (
      <div className="search-container search-statistic-documentCabinet">
        <form className="search-list-utilities search-utilities-nodropdown">
          <div className="search-advanced seach-form-container">
            <div className="row row-box-container">
              <div className="col-sm-8 col-xs-12 col-md-8 search-padding0">
                <div className="group-datetime pull-left">
                  <label className="pt-label label-datepicker">
                    Thời gian (ngày hoàn thành)
                  </label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      locale="vi"
                      isClearable={false}
                      readOnly={true}
                      disabled={this.props.isForChildUnit}
                      selected={this.state.start_date}
                      selectsStart
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleChange.bind(
                        this,
                        'start_date',
                        'dateFromToString'
                      )}
                      value={this.state.dateFromToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateFromToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'start_date',
                          'dateFromToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.end_date}
                      popperPlacement="bottom"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                    <DatePicker
                      locale="vi"
                      isClearable={false}
                      readOnly={true}
                      disabled={this.props.isForChildUnit}
                      selected={this.state.end_date}
                      selectsEnd
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleChange.bind(
                        this,
                        'end_date',
                        'dateToToString'
                      )}
                      value={this.state.dateToToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateToToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'end_date',
                          'dateToToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.start_date}
                      popperPlacement="bottom"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
                {/*
                  <div className="checkbox-active-work">
                    <Checkbox type="checkbox" name="is_hoan_thanh" checked={this.state.is_hoan_thanh} onChange={this.handleChangeCheckbox} label="Công việc đã thực hiện" className="checkbox-content"/>
                  </div>
                */}
              </div>
              {!this.props.isForChildUnit && (
                <div className="col-sm-4 col-xs-12 col-md-4 search-padding0">
                  <div className="group-btn-search">
                    <Button
                      rightIconName="pt-icon pt-icon-search"
                      className="btn-item btn-search"
                      text="Thống kê"
                      onClick={() => {
                        this.handleSubmit()
                        this.props.actions.refreshStatistic(true)
                      }}
                      loading={this.props.isLoading}
                    />
                    {/*<Button type="button" className="btn-item btn-reload" onClick={this.clearFilter}>
                      <span className="icon-Loading"></span>
                      </Button>*/}
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default ListFilter
