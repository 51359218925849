import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
// import { Checkbox } from '@blueprintjs/core';

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
  }

  state = {
    isEnabled: this.props.checked || false,
    isRedirect: false,
    congVanId: '',
  }

  componentWillReceiveProps(props) {
    if (!isNaN(props.checked)) {
      this.setState({
        isEnabled: props.checked,
      })
    }
  }

  handleEnabledChange = e => {
    this.setState({ isEnabled: !this.state.isEnabled }, () => {
      this.props.onCheck &&
        this.props.onCheck(this.props.item.congVanDenId, this.state.isEnabled)
    })
  }

  handleClick = id => {
    this.setState({
      isRedirect: true,
      congVanId: id,
    })
  }

  render() {
    const { item, number } = this.props
    return (
      <tr className="gerenal-list-utilities">
        <td className="gerenal-list-utilities">
          {number > 9 ? number : '0' + number}
        </td>
        <td className="gerenal-list-utilities">{item.soCongVan}</td>
        <td className="gerenal-list-utilities">
          {item.ngayCongVan && moment(item.ngayCongVan).format('DD/MM/YYYY')}
        </td>
        <td className="gerenal-list-utilities">{item.trichYeu}</td>
        <td className="gerenal-list-utilities">{item.coQuanBanHanh}</td>
        <td className="gerenal-list-utilities">{item.tenNguoiChuTri}</td>
      </tr>
    )
  }
}

export default ListItem
