import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import { NotFound } from '../common'

const LINE_HEIGHT = 22

class AssignmentInformation extends Component {
  static propTypes = {
    assignmentInformation: PropTypes.object,
    isDepartment: PropTypes.bool,
    type: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleReadMore = this.handleReadMore.bind(this)
  }

  state = {
    isOpenPopup: false,
    nguoiChuTri: '',
    phoiHop: '',
    xemDeBiet: '',
    readMore: false,
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })

    this.props.onHandleSubmit &&
      this.props.onHandleSubmit(
        'XoaGiaoViec',
        null,
        null,
        null,
        this.props.assignmentInformation.phieuGiaoViecId
      )
  }

  componentDidMount() {
    if (
      this.noiDung &&
      this.noiDung.offsetHeight &&
      this.noiDung.offsetHeight / LINE_HEIGHT > 3
    ) {
      this.setState({
        readMore: true,
      })
    }
    if (this.props.assignmentInformation) {
      let nguoiChuTri = []
      let phoiHop = []
      let xemDeBiet = []

      // Giao viec cho phong ban
      if (this.props.assignmentInformation.pbChuTri) {
        nguoiChuTri.push(this.props.assignmentInformation.pbChuTri.maPhongBan)
      }
      if (this.props.assignmentInformation.pbPhoiHops) {
        this.props.assignmentInformation.pbPhoiHops.forEach(item => {
          phoiHop.push(item.maPhongBan)
        })
      }
      if (
        this.props.assignmentInformation.pbXemDeBiets &&
        !this.props.isNewAssignDoc
      ) {
        this.props.assignmentInformation.pbXemDeBiets.forEach(item => {
          xemDeBiet.push(item.maPhongBan)
        })
      }

      // Lanh dao phong ban
      if (this.props.assignmentInformation.tenNguoiChuTri) {
        nguoiChuTri.push(this.props.assignmentInformation.tenNguoiChuTri)
      }
      if (this.props.assignmentInformation.dsPhoiHop) {
        let tmp = this.props.assignmentInformation.dsPhoiHop.split(',')
        phoiHop = phoiHop.concat(tmp)
      }
      if (this.props.assignmentInformation.dsXemDeBiet) {
        let tmp = this.props.assignmentInformation.dsXemDeBiet.split(',')
        xemDeBiet = xemDeBiet.concat(tmp)
      }

      // Giao viec cho ca nhan
      if (this.props.assignmentInformation.nguoiChuTri) {
        nguoiChuTri.push(
          this.props.assignmentInformation.nguoiChuTri.tenNhanVien
        )
      }
      if (this.props.assignmentInformation.phoiHop) {
        this.props.assignmentInformation.phoiHop.forEach(item => {
          phoiHop.push(item.tenNhanVien)
        })
      }
      if (this.props.assignmentInformation.xemDeBiet) {
        this.props.assignmentInformation.xemDeBiet.forEach(item => {
          xemDeBiet.push(item.tenNhanVien)
        })
      }

      this.setState({
        xemDeBiet: xemDeBiet.join(', '),
        phoiHop: phoiHop.join(', '),
        nguoiChuTri: nguoiChuTri.join(', '),
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.assignmentInformation) {
      let nguoiChuTri = []
      let phoiHop = []
      let xemDeBiet = []

      // Giao viec cho phong ban
      if (nextProps.assignmentInformation.pbChuTri) {
        nguoiChuTri.push(nextProps.assignmentInformation.pbChuTri.maPhongBan)
      }
      if (nextProps.assignmentInformation.pbPhoiHops) {
        nextProps.assignmentInformation.pbPhoiHops.forEach(item => {
          phoiHop.push(item.maPhongBan)
        })
      }
      if (
        nextProps.assignmentInformation.pbXemDeBiets &&
        !this.props.isNewAssignDoc
      ) {
        nextProps.assignmentInformation.pbXemDeBiets.forEach(item => {
          xemDeBiet.push(item.maPhongBan)
        })
      }

      // Lanh dao phong ban
      if (nextProps.assignmentInformation.tenNguoiChuTri) {
        nguoiChuTri.push(nextProps.assignmentInformation.tenNguoiChuTri)
      }
      if (nextProps.assignmentInformation.dsPhoiHop) {
        let tmp = nextProps.assignmentInformation.dsPhoiHop.split(',')
        phoiHop = phoiHop.concat(tmp)
      }
      if (nextProps.assignmentInformation.dsXemDeBiet) {
        let tmp = nextProps.assignmentInformation.dsXemDeBiet.split(',')
        xemDeBiet = xemDeBiet.concat(tmp)
      }

      // Giao viec cho ca nhan
      if (nextProps.assignmentInformation.nguoiChuTri) {
        nguoiChuTri.push(
          nextProps.assignmentInformation.nguoiChuTri.tenNhanVien
        )
      }
      if (nextProps.assignmentInformation.phoiHop) {
        nextProps.assignmentInformation.phoiHop.forEach(item => {
          phoiHop.push(item.tenNhanVien)
        })
      }
      if (nextProps.assignmentInformation.xemDeBiet) {
        nextProps.assignmentInformation.xemDeBiet.forEach(item => {
          xemDeBiet.push(item.tenNhanVien)
        })
      }

      this.setState({
        xemDeBiet: xemDeBiet.join(', '),
        phoiHop: phoiHop.join(', '),
        nguoiChuTri: nguoiChuTri.join(', '),
      })
    }
  }

  handleReadMore() {
    if (this.state.readMore) {
      this.setState({ readMore: false })
    }
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    const extension =
      this.props.assignmentInformation &&
      this.props.assignmentInformation.documentStore &&
      this.props.assignmentInformation.documentStore.tenFile &&
      this.props.assignmentInformation.documentStore.tenFile
        .slice(
          ((this.props.assignmentInformation.documentStore.tenFile.lastIndexOf(
            '.'
          ) -
            1) >>>
            0) +
            2
        )
        .toLowerCase()
    const { assignmentInformation } = this.props

    return (
      <div
        className={classnames(
          'history-steering-box content-AssignmentHistory mt0',
          {
            'history-steering-showmobile': this.props.isShowMobile,
          }
        )}
      >
        <div className="container-form-action container-form-action-sub">
          {!(
            this.props.isNewAssignDoc !== undefined &&
            this.props.isNewAssignDoc === true
          ) && (
            <div className="heading">
              <span
                className="click-go-back"
                onClick={this.props.onClickGoBack}
              >
                <span className="pt-icon pt-icon-arrow-left pull-left"></span>
              </span>
              <h4 className="name-heading">Thông tin giao việc</h4>
            </div>
          )}
          <div className="container-detail">
            {!assignmentInformation && <NotFound />}
            {assignmentInformation && (
              <div className="box-item">
                {(this.props.firstChild === true ||
                  !(
                    this.props.isNewAssignDoc !== undefined &&
                    this.props.isNewAssignDoc === true
                  )) && (
                  <div className="heading noborder-bottom">
                    <h3 className="heading-title">
                      <span className="icon-Thong_Tin_Giao_Viec"></span>
                      <span className="heading-title-text">
                        Thông tin giao việc
                      </span>
                    </h3>
                  </div>
                )}
                <div className="item-content-history job-information-form">
                  <div className="time-create">
                    {moment(assignmentInformation.ngayGiaoViec).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                  </div>
                  <div className="avatar-user">
                    <span
                      className="avatar"
                      style={{
                        backgroundImage: `url(${
                          assignmentInformation.nguoiGiaoViec &&
                          assignmentInformation.nguoiGiaoViec.anhDaiDien
                            ? Types.IMAGE_URL +
                              assignmentInformation.nguoiGiaoViec.anhDaiDien
                            : '/images/default_avatar.png'
                        })`,
                      }}
                    ></span>
                    <span className="name_user">
                      {assignmentInformation.nguoiGiaoViec
                        ? assignmentInformation.nguoiGiaoViec.tenNhanVien
                        : ''}
                    </span>
                  </div>
                  <div className="info-content">
                    <ul className="content-group">
                      {assignmentInformation.tieuDe &&
                        !(
                          this.props.isNewAssignDoc !== undefined &&
                          this.props.isNewAssignDoc === true
                        ) && (
                          <li className="content-item">
                            <span className="style-icon icon-Tieu_De"></span>
                            <div className="content-detail">
                              <span className="title-bold">Tiêu đề: </span>
                              <span className="detail">
                                {assignmentInformation.tieuDe}
                              </span>
                            </div>
                          </li>
                        )}

                      {assignmentInformation.noiDung && (
                        <li className="content-item">
                          <span className="style-icon icon-content"></span>
                          <div className="content-detail">
                            <span
                              // style={{ cursor: 'pointer' }}
                              className="detail textarea-content"
                              // onClick={this.handleReadMore}
                            >
                              <div ref={el => (this.noiDung = el)}>
                                <p
                                  className={classnames({
                                    // 'overflow-hidden-66px': this.state.readMore
                                  })}
                                >
                                  <span className="title-bold">Nội dung: </span>
                                  {assignmentInformation.noiDung
                                    ? assignmentInformation.noiDung
                                    : ''}
                                </p>
                                {/* {this.state.readMore && <i className="icon-Group-8"></i>} */}
                              </div>
                            </span>
                          </div>
                        </li>
                      )}

                      <li className="content-item">
                        <span className="style-icon icon-setting"></span>
                        <div className="content-detail">
                          <span className="title-bold">Chủ trì: </span>
                          <span className="detail">
                            {this.state.nguoiChuTri}
                          </span>
                        </div>
                      </li>

                      <li className="content-item">
                        <span className="style-icon icon-Phoihop"></span>
                        <div className="content-detail">
                          <span className="title-bold">Phối hợp: </span>
                          <span className="detail">{this.state.phoiHop}</span>
                        </div>
                      </li>

                      <li className="content-item">
                        <span className="style-icon icon-Xemdebiet"></span>
                        <div className="content-detail">
                          <span className="title-bold">Xem để biết: </span>
                          <span className="detail">{this.state.xemDeBiet}</span>
                        </div>
                      </li>

                      <li className="content-item">
                        <span className="style-icon icon-calendar"></span>
                        <div className="content-detail">
                          <span className="title-bold">Hạn giải quyết: </span>
                          <span className="detail light-text">
                            {assignmentInformation.hanGiaiQuyet &&
                              moment(assignmentInformation.hanGiaiQuyet).format(
                                'DD/MM/YYYY'
                              )}
                          </span>
                        </div>
                      </li>

                      <li className="content-item">
                        <span className="style-icon icon-Ket_Qua_Bao_Cao"></span>
                        <div className="content-detail">
                          <span className="title-bold">Báo cáo kết quả: </span>
                          <span className="detail light-text">
                            {assignmentInformation.baoCaoLai && 'Có'}
                            {!assignmentInformation.baoCaoLai && 'Không'}
                          </span>
                        </div>
                      </li>

                      {assignmentInformation.documentStore && (
                        <li className="content-item">
                          <span className="style-icon icon-File_Tham_Khao"></span>
                          <div className="content-detail">
                            <span className="title-bold">File tham khảo: </span>
                            <br />
                            {assignmentInformation.documentStore && (
                              <div className="detail-pdf">
                                <span className="detail-pdf-item">
                                  {assignmentInformation.documentStore
                                    .migrated && (
                                    <a
                                      style={{
                                        backgroundImage: `url(${Tool.Utils.typeFile(
                                          extension
                                        )})`,
                                      }}
                                      className="link-file"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`${Types.OLD_DOCUMENT_URL}${assignmentInformation.documentStore.url}&BearerToken=${token}`}
                                    >
                                      {
                                        assignmentInformation.documentStore
                                          .tenFile
                                      }{' '}
                                      -{' '}
                                      {Tool.Utils.getFileSize(
                                        assignmentInformation.documentStore
                                          .kichThuoc
                                      )}
                                    </a>
                                  )}
                                  {!assignmentInformation.documentStore
                                    .migrated && (
                                    <a
                                      style={{
                                        backgroundImage: `url(${Tool.Utils.typeFile(
                                          extension
                                        )})`,
                                      }}
                                      className="link-file"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`${Types.FILE_URL}/api/file/get/${assignmentInformation.documentStore.fileId}?BearerToken=${token}`}
                                    >
                                      {
                                        assignmentInformation.documentStore
                                          .tenFile
                                      }{' '}
                                      -{' '}
                                      {Tool.Utils.getFileSize(
                                        assignmentInformation.documentStore
                                          .kichThuoc
                                      )}
                                    </a>
                                  )}
                                </span>
                                <br />
                              </div>
                            )}
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default AssignmentInformation
