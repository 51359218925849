import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import {
  ListFilterEvn,
  ListFooter,
  ListEvnItem,
} from '../../components/ReceiveDocument/'
import { GridView } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'

class ListEvnDocumentPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isEmpty: false,
      page: 1,
      isError: false,
      status: 0,
      textSearch: '',
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
  }

  componentDidMount() {
    let preData = Tool.Utils.preData(
      'ListEvnDocumentPage',
      null,
      '/cong-van/cong-van-den/them-moi'
    )
    let preData2 = Tool.Utils.preData('ChiTietCongVanTuEVN', null)
    let state = this.state
    if (preData) {
      state.page =
        (preData?.page?.selected || preData?.page) > 0
          ? preData?.page?.selected || preData?.page
          : 1
      state.status = preData.status
      state.textSearch = preData.textSearch
    }

    if (preData2) {
      state.page =
        (preData2?.page?.selected || preData2?.page) > 0
          ? preData2?.page?.selected || preData2?.page
          : 1
      state.status = preData2.status
      state.textSearch = preData2.textSearch
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(
        this.state.page,
        this.state.status,
        this.state.textSearch
      )
    })
  }

  submitFilter(page = 1, status = null, textSearch = '') {
    this.setState({ page, isLoading: true, status, textSearch }, () => {
      Tool.Utils.preData(
        'ListEvnDocumentPage',
        { page, status, textSearch },
        '/cong-van/cong-van-den/them-moi'
      )
      Tool.Utils.preData('ChiTietCongVanTuEVN', { page, status, textSearch })
      this.props.actions
        .evnDocumentsFetchListNoSchema(
          page,
          Types.PAGINATION_LIMIT,
          status,
          textSearch
        )
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            res &&
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (
            res &&
            (res.error || (res.payload && res.payload.status !== 200))
          ) {
            isError = true
          }

          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  render() {
    return (
      <MasterLayout typeSidebar="documentary">
        <div className="heading-page">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="text-heading-page">
                <span className="icon-CV_EVN icon-heading"></span>
                <span className="text-heading">Công văn từ EVN</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="page-list-container">
          <ListFilterEvn
            actions={this.props.actions}
            submitFilter={this.submitFilter}
            status={this.state.status}
            textSearch={this.state.textSearch}
          />
          <div className="table-container">
            <GridView
              isLoading={this.state.isLoading}
              classNames={'pt-table'}
              history={this.props.history}
              page={this.state.page}
              headers={headers.listEvnDocument}
              itemShape={ListEvnItem}
              data={this.props.evnDocuments.list.items}
              isEmpty={this.state.isEmpty}
              isError={this.state.isError}
            />

            {!this.state.isLoading &&
              !this.state.isEmpty &&
              !this.state.isError && (
                <ListFooter
                  current={this.state.page}
                  total={Math.ceil(
                    this.props.evnDocuments.list.total / Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                  type="EVN"
                />
              )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

ListEvnDocumentPage.propTypes = {
  evnDocuments: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  evnDocuments: state.evnDocumentsNoSchema,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListEvnDocumentPage)
