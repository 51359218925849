import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { PopupConfirm } from '../common/'
import moment from 'moment'
import * as POPUP from '../../constants/Popup'
// import DetailStatus from './DetailStatus';
import * as Types from '../../constants/Api'
import { Authorization, Permission } from '../../components/auth'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)

    this.state = {
      chucDanhId: this.props.auth ? this.props.auth.roleId : null,
      isOpenPopup: false,
      isCanDelete: true,
      isCanUpdate: true,
      isCanManage: false,
    }
  }

  handleClick = () => {
    if (!this.props.item) {
      return
    }
    this.props.onClickDetail &&
      this.props.onClickDetail({
        kySoId: this.props.item.kySoId,
        soKyHieu: this.props.item.soKyHieu,
        fileId: this.props.item.fileId,
      })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = (lydo, e) => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })
    this.props.onHandleSubmitRemoveItem &&
      this.props.onHandleSubmitRemoveItem(lydo, this.props.item.kySoId)
  }

  componentDidMount() {
    let state = this.state
    if (
      this.props.item.lanhDaoPhongBanChuTri &&
      this.props.item.lanhDaoPhongBanChuTri.chucDanhId === state.chucDanhId
    ) {
      state.isCanManage = true
      state.isCanUpdate = false
    }
    if (
      this.props.item.nguoiLap &&
      this.props.item.nguoiLap.chucDanhId === state.chucDanhId
    ) {
      state.isCanManage = true
      state.isCanUpdate = true
    }
    if (this.props.item && this.props.item.tinhTrang) {
      let tinhTrang = this.props.item.tinhTrang
      // ky so di: nguoi lap và LD PB CT dc xoa van ban khong nam trong 3 tinh trang 5,6,7
      if (
        this.props.type === 'cong-van-di' &&
        [Types.PROGRESSION, Types.RELEASED, Types.LEADER_UNIT].indexOf(
          tinhTrang
        ) > -1
      ) {
        state.isCanDelete = false
      }

      // ky so noi bo: nguoi lap và LD PB CT duoc xoa van ban khi tinh trang khac 7
      if (
        this.props.type === 'cong-van-noi-bo' &&
        tinhTrang === Types.RELEASED
      ) {
        state.isCanDelete = false
      }

      if (
        tinhTrang >= Types.SECRETARY &&
        tinhTrang <= Types.RELEASED &&
        this.props.item.lanhDaoDonViPhoiHop
      ) {
        state.isCanUpdate = false
      }

      if (
        tinhTrang === Types.PROGRESSION ||
        tinhTrang === Types.RELEASED ||
        (this.props.type === 'cong-van-di' && tinhTrang === Types.LEADER_UNIT)
      ) {
        state.isCanUpdate = false
      }

      // Neu la van thu && (tinhTrang === 6 || tinhTrang === 5) && ky so di
      if (
        this.props.type === 'cong-van-di' &&
        (tinhTrang === Types.PROGRESSION || tinhTrang === Types.LEADER_UNIT) &&
        this.props.auth &&
        Authorization.vaild(this.props.auth.permission, [Permission.KYSO_CAPSO])
      ) {
        state.isCanManage = true
        state.isCanDelete = true
      }
    }
    this.setState(state)
  }

  render() {
    if (!this.props.item) {
      return
    }

    let tmp
    switch (this.props.item.tinhTrangHienThi) {
      case 0:
        tmp = <span className="status-job no-process">Chưa duyệt</span>
        break

      case 1:
        tmp = <span className="status-job no-approval">Không duyệt</span>
        break

      case 2:
        tmp = <span className="status-job approved">LĐPB CT đã duyệt</span>
        break

      case 20:
        tmp = <span className="status-job approved">LĐPB PH đang duyệt</span>
        break

      case 3:
        tmp = <span className="status-job approved">LĐPB PH đã duyệt</span>
        break

      case 30:
        if (this.props.type === 'cong-van-di') {
          tmp = <span className="status-job approved">PTGĐ PT đang duyệt</span>
        } else {
          tmp = <span className="status-job approved">LĐ ĐL PH đang duyệt</span>
        }
        break

      case 31:
        if (this.props.type === 'cong-van-di') {
          tmp = <span className="status-job approved">PTGĐ PT đã duyệt</span>
        } else {
          tmp = <span className="status-job approved">LĐ ĐL PH đã duyệt</span>
        }
        break

      case 10:
        tmp = <span className="status-job approved">LĐ PH đã duyệt</span>
        break

      case 4:
        tmp = <span className="status-job approved">TK LĐĐV đã duyệt</span>
        break

      case 5:
        tmp = <span className="status-job approved">LĐĐV đã duyệt</span>
        break

      case 6:
        tmp = <span className="status-job process">Đã cấp số</span>
        break

      case 7:
        tmp = <span className="status-job process">Đã phát hành</span>
        break

      case 21:
        tmp = (
          <span className="status-job approved">
            LĐPB PH Cấp trên đang duyệt
          </span>
        )
        break

      case 8:
        tmp = (
          <span className="status-job approved">
            LĐ PB PH ĐV Cấp trên đã duyệt
          </span>
        )
        break

      case 9:
        tmp = (
          <span className="status-job approved">
            Thư ký LĐ ĐV Cấp trên đã duyệt
          </span>
        )
        break

      default:
        tmp = <span>&nbsp;</span>
    }

    if (this.props.type === 'cong-van-di') {
      if (this.props.item.tinhTrang === 8) {
        tmp = <span className="status-job approved">TK LĐĐV PH đã duyệt</span>
      }
      if (this.props.item.tinhTrang === 9) {
        tmp = <span className="status-job approved">LĐ ĐV PH đã duyệt</span>
      }
    }

    const tinhTrang = tmp
    const location = this.props.match ? this.props.match.path : ''

    let soKyHieu = ''
    if (this.props.item.soKyHieu) {
      soKyHieu = this.props.item.soKyHieu
      if (this.props.item.soKyHieu.indexOf('/') === 0) {
        soKyHieu = this.props.item.soKyHieu.substring(1)
      }
    }

    if (
      this.props.item.soVanBan &&
      !isNaN(this.props.item.soVanBan) &&
      Number(this.props.item.soVanBan) > 0
    ) {
      soKyHieu = this.props.item.soVanBan + '/' + soKyHieu
    }

    return (
      <tr>
        <td onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        {/* Cong van di */}
        {this.props.type === 'cong-van-di' && (
          <td onClick={this.handleClick}>{soKyHieu}</td>
        )}
        {/* Cong van noi bo */}
        {this.props.type === 'cong-van-noi-bo' && (
          <td onClick={this.handleClick}>{this.props.item.soKyHieu}</td>
        )}
        <td onClick={this.handleClick} className="textarea-content">
          {this.props.item.trichYeu}
        </td>
        <td onClick={this.handleClick}>
          {this.props.item.ngayLap &&
            moment(this.props.item.ngayLap).format('DD/MM/YYYY')}
        </td>
        <td onClick={this.handleClick}>
          {this.props.item.phongBanLap &&
            this.props.item.phongBanLap.maPhongBan}
        </td>
        <td onClick={this.handleClick}>
          {this.props.item.nguoiLap && this.props.item.nguoiLap.tenNhanVien}
        </td>
        <td onClick={this.handleClick}>
          {/* <Popover
            popoverClassName="pt-popover-table"
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            openOnTargetFocus
            content={
              <DetailStatus item={this.props.item} auth={this.props.auth}/>
            }
          >
            {tinhTrang}
          </Popover> */}
          {tinhTrang}
        </td>
        {(this.state.isCanDelete === true || this.state.isCanUpdate === true) &&
        this.state.isCanManage === true ? (
          <td>
            <Popover
              popoverClassName="pt-popover-content-sizing"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  {this.state.isCanUpdate && (
                    <Link
                      className="item"
                      to={
                        location === '/ky-so/cong-van-di/danh-sach'
                          ? `/ky-so/cong-van-di/chinh-sua/${this.props.item.kySoId}`
                          : `/ky-so/cong-van-noi-bo/chinh-sua/${this.props.item.kySoId}`
                      }
                    >
                      <span className="pt-icon pt-icon-annotation"></span>
                      <span className="name_action">Chỉnh sửa</span>
                    </Link>
                  )}
                  {this.state.isCanDelete && (
                    <span className="item" onClick={this.handleClickPopup}>
                      <span className="pt-icon pt-icon-trash"></span>
                      <span className="name_action">Xóa</span>
                    </span>
                  )}
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
            <PopupConfirm
              isOpen={this.state.isOpenPopup}
              textarea={true}
              actionPage={POPUP.XOA_KY_SO_CONG_VAN_DI}
              onClose={this.handleClickPopup}
              title={POPUP.XOA_VAN_BAN_KY_SO}
              text={POPUP.TEXT_BUTTON_SUBMIT}
              isWarning={
                this.props.item && this.props.item.tinhTrang > 1 ? true : false
              }
              onSubmit={this.handleSubmitRemoveItem}
              isLoading={this.props.isLoadingXoaKySo}
            >
              {this.props.item && this.props.item.tinhTrang > 1
                ? POPUP.CANH_BAO_XOA_VAN_BAN_KY_SO
                : POPUP.TEXT_SUBMIT_BODY}
            </PopupConfirm>
          </td>
        ) : (
          <td>&nbsp;</td>
        )}
      </tr>
    )
  }
}

export default ListItem
