import React from 'react'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { ListItem } from '../../components/ListGerenal/StagisticalNumber'
import { GridView } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import moment from 'moment'
import HeadingPanel from 'components/common/HeadingPanel'

import Pagination from 'components/Pagination'

class ListStagisticalSignNumberOverTime extends React.Component {
  constructor(props) {
    super(props)

    const id =
      this.props.match && this.props.match.params && this.props.match.params.id
    const type =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.type
    this.state = {
      filter: {
        startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loaiCongVan: 1,
      },
      id,
      type,
      isEmpty: false,
      isLoading: false,
      page: 1,
      checkList: {},
      checkAll: false,
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
  }

  componentDidMount() {
    let state = this.state
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.ngayGiaoViec
    ) {
      state.filter.loaiCongVan =
        this.props.location.state.ngayGiaoViec.loaiCongVan
      state.filter.startDate = this.props.location.state.ngayGiaoViec.from
      state.filter.endDate = this.props.location.state.ngayGiaoViec.to
    }

    this.setState(state, () => {
      this.clickPagination(1)
    })
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match !== this.props.match
    ) {
      this.setState({
        id: nextProps.match.params.id,
        type: nextProps.match.params.type,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id || prevState.type !== this.state.type) {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    }
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const { type, id } = this.state
    const filter = Object.assign({}, this.state.filter, e)
    let filterSubmit = {
      ...filter,
    }
    if (type === 'don-vi') {
      filterSubmit = {
        ...filterSubmit,
        donViId: id,
      }
    }
    if (type === 'phong-ban') {
      filterSubmit = {
        ...filterSubmit,
        phongBanId: id,
      }
    }
    this.setState({ page, isLoading: true }, () => {
      this.props.actions
        .getStagisticalSignNumberOverTime(filterSubmit, page, sort)
        .then(res => {
          if (!res.error && res.payload.data.result.total === 0) {
            return this.setState({ isEmpty: true })
          }

          this.setState({ isEmpty: false })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  handleClickGoBack = async () => {
    await this.props.actions.refreshStatistic(false)
    this.props.history &&
      this.props.history.push({
        pathname: '/tien-ich/thong-ke-ky-so/danh-sach',
        state: {
          isFromeDetailPage: true,
          isCheck: true,
        },
      })
  }

  render() {
    const { isEmpty, isLoading, page } = this.state

    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div>
          <HeadingPanel
            className="mb10"
            goBack={this.handleClickGoBack}
            leftTitle="Tổng hợp văn bản ký tay"
            leftIcon="icon-Chua_Thuc_Hien"
          />
          {/* Table List */}
          <div className="page-list-container">
            <div className="table-container">
              <GridView
                isLoading={this.state.isLoading}
                classNames={'pt-table'}
                page={this.state.page ? this.state.page : 1}
                headers={headers.stagisticalNumberOverTime}
                itemShape={ListItem}
                data={this.props.stagitiscalNumberOverTime.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onHandleClickItem={this.HandleClickItem}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                onCheck={this.handleCheck}
                onCheckAll={this.handleCheckAll}
                isEnabledAll={this.state.checkAll}
                checkList={this.state.checkList}
              />
              {!isLoading && !isEmpty && (
                <Pagination
                  current={page}
                  total={Math.ceil(
                    this.props.stagitiscalNumberOverTime.list.total /
                      Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  typeSidebar: state.common.typeSidebar,

  stagitiscalNumberOverTime: {
    ...state.statisticSignNumber.stagitiscalNumberOverTime,
    list: {
      ...state.statisticSignNumber.stagitiscalNumberOverTime.list,
      items: state.statisticSignNumber.stagitiscalNumberOverTime.list.items,
    },
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListStagisticalSignNumberOverTime)
