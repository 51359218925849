import React from 'react'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'

const NOT_UNIT = '00000000-0000-0000-0000-000000000000'
class StatisticItem extends React.PureComponent {
  goToDetails = (id, isDonViPc) => {
    if (id !== NOT_UNIT && isDonViPc) {
      this.props.history.push(
        `/he-thong/thong-ke-ky-so-tap-trung/danh-sach/${id}`
      )
    }
  }

  render() {
    const { data, index } = this.props
    const isTotal = data.donViId === NOT_UNIT && data.tenDonVi === 'Tổng cộng'

    return (
      <tr
        className={classnames({
          'text-bold': isTotal,
        })}
      >
        <td className="gerenal-list-utilities">
          {!isTotal ? (index > 9 ? index : `0${index}`) : null}
        </td>
        <td
          className={classnames('gerenal-list-utilities', {
            'over-time cursor-pointer': data.isDonViPc,
          })}
          onClick={this.goToDetails.bind(null, data.donViId, data.isDonViPc)}
        >
          {data.tenDonVi}
        </td>
        <td className="gerenal-list-utilities text-center">
          {data.tongSoVanBan}
        </td>
      </tr>
    )
  }
}

export default withRouter(StatisticItem)
