import { schema } from 'normalizr'

const SaveCode = new schema.Entity(
  'saveCode',
  {},
  {
    idAttribute: saveCode => saveCode.soLuuId,
  }
)

export default SaveCode
