import { schema } from 'normalizr'

const SendDocuments = new schema.Entity(
  'sendDocuments',
  {},
  {
    idAttribute: sendDocuments => sendDocuments.id || sendDocuments.congVanDiId,
  }
)

export default SendDocuments
