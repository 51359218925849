import React from 'react'
import { MasterLayout } from '../../components/layout'

class ErrorPages extends React.Component {
  render() {
    return (
      <MasterLayout typeSidebar="HomePage">
        <div className="error-page-container">
          <div className="error-content">
            <img
              src={process.env.PUBLIC_URL + '/images/404.png'}
              alt="404"
              className="img-thumb-error"
            />
            <h2 className="title-error">404</h2>
            <p className="description">
              Không tìm thấy trang này, vui lòng{' '}
              <a href="/" className="text-link">
                quay lại trang trước
              </a>
            </p>
          </div>
        </div>
      </MasterLayout>
    )
  }
}
export default ErrorPages
