import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import { Position } from '@blueprintjs/core'
import ReactDatePicker from 'react-daterange-picker'
import { get } from 'lodash'
import csx from 'classnames'
import moment from 'moment'

import DropdownWrapper from './DropdownWrapper'
import Select from './Select'

const datetimeFormat = 'DD/MM/YYYY HH:mm'

const numberDisplay = n => `0${n}`.slice(-2)

const getHours = () => {
  return new Array(24).fill(null).map((v, i) => i)
}
const getMinutes = () => {
  return new Array(2).fill(null).map((v, i) => i * 30)
}
const getTimeDataSource = () => {
  const minutes = getMinutes()

  return getHours().reduce((result, hour) => {
    const time = minutes.map(minute => ({
      value: [hour, minute],
      label: `${numberDisplay(hour)}:${numberDisplay(minute)}`,
    }))
    return [...result, ...time]
  }, [])
}

const DatePicker = ({
  dataSource = [],
  onChange: selectChange = () => {},
  onAfterChangeTime = () => {},
  selected: selectValue,
  minimumDate = null,
  maximumDate = null,
  defaultTime = [0, 0],
  ...props
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [selected, _setSelected] = useState()
  const [dropdownOpen, _setDropdownOpen] = useState(false)

  const _toggleDropdown = useCallback(open => {
    _mountedSet(_setDropdownOpen, open)
  }, [])

  const _onChangeDate = useCallback(
    value => {
      const newValue = value
        ? value.set('hour', defaultTime[0]).set('minute', defaultTime[1])
        : null

      _mountedSet(_setSelected, newValue)
      selectChange(newValue)
    },
    [selectChange, defaultTime]
  )

  const _onChangeTime = ([hour, minute]) => {
    const preDateSelect = selected || undefined
    let newDate = moment(preDateSelect).set('hour', hour).set('minute', minute)
    if (newDate < moment(minimumDate)) {
      newDate = null
    }

    _mountedSet(_setSelected, newDate)
    _mountedSet(_setDropdownOpen, false)
    selectChange(newDate)
    onAfterChangeTime()
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(_setSelected, selectValue)
  }, [selectValue])

  return (
    <DropdownWrapper
      {...props}
      onClear={() => _onChangeDate(null)}
      value={selected ? moment(selected).format(datetimeFormat) : ''}
      open={dropdownOpen}
      onDropdownVisibleChange={_toggleDropdown}
    >
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        className="custom-datetime-picker"
      >
        <ReactDatePicker
          className={csx('cpc-datepicker-range')}
          value={selected}
          onSelect={_onChangeDate}
          selectionType="single"
          minimumDate={minimumDate}
          maximumDate={maximumDate}
        />
        <div style={{ margin: '0 20px 10px' }}>
          <Select
            dataSource={getTimeDataSource()}
            inputIconClassName="icon-time"
            onChange={_onChangeTime}
            placeholder="HH:mm"
            popoverPosition={Position.TOP}
            popoverHeight={225}
            value={selected ? [selected.hour(), selected.minute()] : null}
          />
        </div>
      </div>
    </DropdownWrapper>
  )
}

export default memo(DatePicker)
