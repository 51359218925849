import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import {
  putApprovalStatusWork,
  putCancelApprovalStatusWork,
} from 'actions/task'
import { Dialog, Card } from 'components/newCommon/'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import {
  APPROVE_SECRETARY_TITLE,
  NOT_APPROVE_TITLE,
  APPROVE_SECRETARY_CONTENT,
  NOT_APPROVE_CONTENT,
  TEXT_BUTTON_SUBMIT,
} from 'constants/Popup'

const Approve = ({ actionKey, dataSource, onClose, onSuccess }) => {
  const id = dataSource?.id
  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const onApprove = () => {
    let isHuyDuyet = false
    if (actionKey === 'HUY_DUYET') {
      isHuyDuyet = true
    }

    requestAction({
      beforeAction: () => setLoading(true),
      action: () =>
        isHuyDuyet
          ? putCancelApprovalStatusWork(id)
          : putApprovalStatusWork(id, { approval: 'APPROVED' }),
      afterResponse: () => {
        setShowDialog(false)
        onSuccess()
        onClose()
      },
      afterAction: () => setLoading(false),
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }

  const renderTitle = useCallback(() => {
    if (actionKey === 'HUY_DUYET') {
      return NOT_APPROVE_TITLE
    }

    return APPROVE_SECRETARY_TITLE
  }, [actionKey])

  const renderContent = useCallback(() => {
    if (actionKey === 'HUY_DUYET') {
      return NOT_APPROVE_CONTENT
    }

    return APPROVE_SECRETARY_CONTENT
  }, [actionKey])

  return (
    <Dialog
      buttonUppercase
      isOpen={showDialog}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      confirmText={TEXT_BUTTON_SUBMIT}
      loading={loading}
      onCancel={onClose}
      onConfirm={onApprove}
      onClose={onClose}
      title={renderTitle()}
      textCenter
    >
      <Card className="mt20 mb20">{renderContent()}</Card>
    </Dialog>
  )
}

Approve.defaultProps = {
  dataSource: null,
  onClose: () => {},
  onSuccess: () => {},
}

Approve.propTypes = {
  dataSource: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default memo(Approve)
