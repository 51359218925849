import { schema } from 'normalizr'

const flowDocumentReceiveDocument = new schema.Entity(
  'flowDocumentReceiveDocument',
  {},
  {
    idAttribute: flowDocumentReceiveDocument =>
      flowDocumentReceiveDocument.nguoiTaoCongVan &&
      flowDocumentReceiveDocument.nguoiTaoCongVan.chucDanhId,
  }
)

export default flowDocumentReceiveDocument
