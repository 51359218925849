import {
  API_REQUEST_ERROR,
  API_REQUEST_SEND,
  API_REQUEST_SUCCESS,
} from '../constants/ActionTypes'
import { KSTT_API_URL } from '../constants/Api'

export const fetchTemplate = procedureId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/nhanvien/${getState().auth.roleId}/quytrinh/${procedureId}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const deleteTemplate = templateId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/template/${templateId}`,
        method: 'DELETE',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const applyTemplate = templateId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/template/${templateId}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const fetchConvergeSignatureDocument = unitId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvi/${unitId}/vanbankysodaduyet`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })
export const fetchSignatureDocumentByProcedure =
  (procedureId, textSearch) => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/quytrinh/${procedureId}/vanbankyso`,
          method: 'GET',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
          params: {
            text_search: textSearch,
          },
        },
      },
    })
export const postFileTrinhKySoTapTrung = data => (dispatch, getState) => {
  return dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      client: 'formDataGetFileBinary',
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/vanbankyso/preview',
        method: 'POST',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data,
      },
    },
  })
}
