import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Button } from '@blueprintjs/core'
import Permission from './Permission'
import Recaptcha from 'react-recaptcha'
import CryptoAES from 'crypto-js/aes'
import { Cookies } from 'react-cookie'

import {
  API_XAC_THUC_DANG_NHAP,
  GOOGLE_DOMAIN,
  SECRET_KEY,
  SITE_KEY,
  CABINET_DEPARTMENT,
} from 'constants/Api'
import { checkPermissionMenu } from 'helpers/Helper'

// KEY Nhận thông tin từ app desktop theo từng feature
const CONGVANDEN = '1'
const CONGVIEC_PHONGBAN = '2'
const CONGVIEC_CANHAN = '3'
const KYSO = '4'
const KYSO_NOIBO = '5'
const CONGVANDI_THUHOI = '10'
const KYSO_TAPTRUNG = '6'
const HO_SO_CONG_VIEC = '11'
const QUANLY_CONGVIEC = '100'
class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      isLoading: false,
      countError: 0,
      isVerified: undefined,
      reReCaptcha: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAuth = this.handleAuth.bind(this)
    this.handlePermission = this.handlePermission.bind(this)
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }

  onDefaultPageView = (actionType, permission = []) => {
    const workid = this.props.location.state?.workid
    let url = '/'
    let type = 'HomePage'

    if (actionType === QUANLY_CONGVIEC) {
      url = '/quan-ly-cong-viec/ca-nhan/can-thuc-hien'
      if (workid) {
        url = `/quan-ly-cong-viec/ca-nhan/can-thuc-hien/chi-tiet/${workid}`
      } else if (permission?.some(f => f === 'CONGVIEC_PHONGBAN')) {
        url = '/quan-ly-cong-viec/phong-ban/chua-giao'
      }

      type = 'taskManager'
    }

    return {
      url: url,
      type: type,
    }
  }

  componentDidMount = () => {
    this.getMyLocation()
  }

  componentWillMount = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.token
    ) {
      const { token, type, roleId } = this.props.location.state
      this.props.actions.authLogout()
      this.props.actions.setTokenWithQuery(token)

      this.handleAuth({})
        .then(async res => {
          if (!roleId) {
            return this.handlePermission(res, type)
          }

          await this.props.actions.switchRoleUser({ roleId })
          await this.props.actions.userPermission(roleId)
        })
        .then(() => {
          let loginDirect = '/'
          let typeSidebar = 'utilities'
          switch (type) {
            case CONGVANDEN:
              loginDirect = '/cong-van/cong-van-den/danh-sach/chua-xu-ly'
              typeSidebar = 'documentary'
              break
            case CONGVIEC_PHONGBAN:
              loginDirect = '/cong-viec/phong-ban/danh-sach/chua-giao'
              typeSidebar = 'work'
              break
            case CONGVIEC_CANHAN:
              loginDirect = '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien'
              typeSidebar = 'work'
              break
            case KYSO:
              loginDirect = '/ky-so/cong-van-di/danh-sach'
              typeSidebar = 'signNumber'
              break
            case KYSO_NOIBO:
              loginDirect = '/ky-so/cong-van-noi-bo/danh-sach'
              typeSidebar = 'signNumber'
              break
            case CONGVANDI_THUHOI:
              loginDirect = '/cong-van/cong-van-di-thu-hoi/danh-sach'
              typeSidebar = 'documentary'
              break
            case KYSO_TAPTRUNG:
              loginDirect = '/ky-so-tap-trung/van-ban-ky-duyet/danh-sach'
              typeSidebar = 'signNumber'
              break
            case HO_SO_CONG_VIEC:
              loginDirect = '/ho-so-cong-viec/danh-sach/cho-xu-ly'
              typeSidebar = 'documentsCabinet'
              break
            case QUANLY_CONGVIEC:
              loginDirect = this.onDefaultPageView(QUANLY_CONGVIEC)?.url
              typeSidebar = 'taskManager'
              break
            default:
          }
          this.props.actions.commonSaveTypeSidebar(typeSidebar)
          return this.props.history.push(loginDirect)
        })
    }
  }

  getMyLocation() {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition(
        position => {
          localStorage['latitude'] = position.coords.latitude
          localStorage['longitude'] = position.coords.longitude
        },
        error => {
          this.setState({ latitude: '', longitude: '' })
          localStorage['latitude'] = ''
          localStorage['longitude'] = ''
        }
      )
    }
  }

  generateUUID() {
    let d = new Date().getTime()
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = ~~((d + Math.random() * 16) % 16)
        d = ~~(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
    return uuid
  }

  get_device() {
    var module = {
      options: [],
      header: [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor,
        window.opera,
      ],
      dataos: [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
      ],
      databrowser: [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
      ],

      init: function () {
        var agent = this.header.join(' '),
          os = this.matchItem(agent, this.dataos),
          browser = this.matchItem(agent, this.databrowser)
        return { os: os, browser: browser }
      },
      matchItem: function (string, data) {
        var i = 0,
          j = 0,
          regex,
          regexv,
          match,
          matches,
          version
        for (i = 0; i < data.length; i += 1) {
          regex = new RegExp(data[i].value, 'i')
          match = regex.test(string)
          if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
            matches = string.match(regexv)
            version = ''
            if (matches) {
              if (matches[1]) {
                matches = matches[1]
              }
            }
            if (matches) {
              matches = matches.split(/[._]+/)
              for (j = 0; j < matches.length; j += 1) {
                if (j === 0) {
                  version += matches[j] + '.'
                } else {
                  version += matches[j]
                }
              }
            } else {
              version = '0'
            }
            return {
              name: data[i].name,
              version: parseFloat(version),
            }
          }
        }
        return { name: 'unknown', version: 0 }
      },
    }
    var e = module.init(),
      debug = ''
    debug += '' + e.os.name + ' '
    debug += '' + e.os.version + ', '
    debug += '' + e.browser.name + ''
    return debug
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      error: '',
    })
  }

  handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }

    const reReCaptcha = this.state?.reReCaptcha
    this.setState({ isLoading: true }, () => {
      this.props.actions
        .authLogin({
          username: this.state.username,
          password: this.state.password,
          r: reReCaptcha ? reReCaptcha : null,
        })
        .then(this.handleAuth)
    })
  }

  handleAuth = async res => {
    let countError = this.state.countError
    if (res && res.error) {
      let msg = 'Đăng nhập không thành công, vui lòng thử lại'
      if (
        res &&
        res.error.response &&
        res.error.response.data &&
        res.error.response.data.error === 'invalid_grant'
      ) {
        if (this.state.isVerified) {
          this.resetRecaptcha()
        }

        countError++
        if (countError === 5) {
          msg = res.error.response.data?.errorDescription
        } else {
          msg = 'Tài khoản đăng nhập hoặc mật khẩu không đúng'
        }
      }

      if (res && res.error && res.error.data === 'Network Error') {
        msg = 'Mất kết nối internet, vui lòng thử lại'
      }

      return this.setState({ error: msg, isLoading: false, countError })
    }

    return await this.props.actions.userAuthenticated().then(res => {
      if (res.error || !res.payload.data.result.result) {
        return this.setState({
          isLoading: false,
          error: 'Đăng nhập không thành công, vui lòng thử lại',
        })
      }

      this.handlePermission(res)
    })
  }

  checkLoginLanDau(username) {
    // check login lan dau
    const cookies = new Cookies()
    const checkin_maxacnhan_cookies = cookies.get('checkin_maxacnhan_cookies')
      ? cookies.get('checkin_maxacnhan_cookies')
      : ''
    const isNewLogin = username !== localStorage['user_login']
    localStorage['user_login'] = username
    let MA_XACNHAN = localStorage['checkin_maxacnhan']
    if (
      checkin_maxacnhan_cookies === undefined ||
      checkin_maxacnhan_cookies === '' ||
      checkin_maxacnhan_cookies === null ||
      checkin_maxacnhan_cookies === 'null'
    ) {
      cookies.set('checkin_maxacnhan_cookies', this.generateUUID(), {
        path: '/',
      })
    }

    if (
      MA_XACNHAN === undefined ||
      MA_XACNHAN === '' ||
      MA_XACNHAN === null ||
      MA_XACNHAN === 'null'
    ) {
      localStorage['checkin_maxacnhan'] = this.generateUUID()
      MA_XACNHAN = localStorage['checkin_maxacnhan']
    }
    let login = {
      TAIKHOAN: CryptoAES.encrypt(username, 'hoa3004'),
      MA_UNGDUNG: 'EO_QLCV',
      MA_XACNHAN_STORAGE: MA_XACNHAN,
      MA_XACNHAN_COOKIE: cookies.get('checkin_maxacnhan_cookies'),
      TEN_THIETBI: this.get_device(),
      HOST_NAME: window.location.href,
      IP: '',
      LAT: localStorage['latitude'],
      LONG: localStorage['longitude'],
      KEY: isNewLogin ? '/VqC9jG0xNs=' : 'Iw87fGcomEZcl2AvIfdKhg==',
    }

    fetch(
      API_XAC_THUC_DANG_NHAP +
        `?TAIKHOAN=${login.TAIKHOAN}&MA_UNGDUNG=${login.MA_UNGDUNG}&MA_XACNHAN_STORAGE=${login.MA_XACNHAN_STORAGE}&MA_XACNHAN_COOKIE=${login.MA_XACNHAN_COOKIE}&TEN_THIETBI=${login.TEN_THIETBI}&HOST_NAME=${login.HOST_NAME}&IP=${login.IP}&LAT=${login.LAT}&LONG=${login.LONG}&KEY=${login.KEY}`,
      {
        mode: 'no-cors',
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'length',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: -1,
        },
      }
    )
  }

  async handlePermission(res, type) {
    if (
      !res ||
      res.error ||
      !res.payload.data ||
      !res.payload.data.result ||
      !res.payload.data.entities.users
    ) {
      return this.setState({
        isLoading: false,
        error: 'Đăng nhập không thành công, vui lòng thử lại',
      })
    }

    const user = res.payload.data.entities.users[res.payload.data.result.result]
    const defaultApartmend = user.dsChucDanh.find(
      item => item.phongBanChinh === true
    )
    this.checkLoginLanDau(user.username)
    const actions = this.props.actions
    actions.commonFetchPriorities()
    actions.commonFetchSecurities()
    actions.commonFetchObjects()
    actions.commonFetchDepartmentsLeader()
    await actions.userPermission(defaultApartmend.id).then(async resPers => {
      if (resPers.error) {
        return this.setState({
          isLoading: false,
          error: 'Đăng nhập không thành công, vui lòng thử lại',
        })
      }

      let loginDirect = '/'
      let typeSidebar = 'HomePage'
      // const fetchQuyensMenu = await actions.getQuyenChucNang()
      // const quyensMenuData = fetchQuyensMenu?.payload?.data?.result?.items || []
      // const quyensMenu = quyensMenuData
      //   ?.filter(f => f?.isActive)
      //   ?.map(item => item?.maChucNang)
      if (resPers.payload.data.result.items) {
        const permission = resPers.payload.data.result.items.map(
          item => item.maQuyen
        )
        // Redirect to after login
        if (checkPermissionMenu(permission, [Permission.SUDUNG_QLCV])) {
          loginDirect = this.onDefaultPageView(QUANLY_CONGVIEC, permission)?.url
          typeSidebar = this.onDefaultPageView(
            QUANLY_CONGVIEC,
            permission
          )?.type
        }

        if (permission.indexOf(Permission.TUTAILIEUPHONGBAN) !== -1) {
          actions.setCabinetType(CABINET_DEPARTMENT)
        }
      }

      !type && actions.commonSaveTypeSidebar(typeSidebar)
      !type && this.props.history.push(loginDirect)
    })
  }

  resetRecaptcha() {
    this.recaptcha.reset()
  }

  recaptchaLoaded() {}

  verifyCallback = response => {
    if (response) {
      try {
        const formData = `secret=${SECRET_KEY}&response=${response}`
        return fetch(GOOGLE_DOMAIN, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
          },
          body: formData,
        })
          .then(response => response.json())
          .then(res => {
            if (res && res.success) {
              this.setState(
                {
                  isVerified: true,
                  reReCaptcha: formData,
                },
                () => {
                  this.handleSubmit()
                }
              )
            } else {
              this.resetRecaptcha()
            }
          })
      } catch (error) {
        console.error(error)
      }
    }
  }

  render() {
    return (
      <form className="cpc-login-form" onSubmit={this.handleSubmit}>
        <h2 className="main-title">Thông tin đăng nhập</h2>
        <div className="pt-input-group pt-large">
          <span className="pt-icon icon-profile"></span>
          <div className="cpc-control-group">
            <label htmlFor="username">Tài khoản đăng nhập</label>
            <input
              type="text"
              className="pt-input cpc-input-username cpc-form-control"
              id="username"
              name="username"
              onChange={this.handleChange}
              placeholder="Nhập tên đăng nhập"
              autoFocus={true}
            />
          </div>
        </div>
        <div className="pt-input-group pt-large">
          <span className="pt-icon icon-lock"></span>
          <div className="cpc-control-group">
            <label htmlFor="password">Mật khẩu</label>
            <input
              type="password"
              className="pt-input cpc-input-password cpc-form-control"
              id="password"
              name="password"
              onChange={this.handleChange}
              placeholder="Nhập mật khẩu"
            />
            {this.state.error && (
              <div className="pt-input-helper-text error">
                {this.state.error}
              </div>
            )}
          </div>
        </div>
        {this.state.countError >= 5 && SITE_KEY && (
          <div className="recaptcha">
            <Recaptcha
              ref={e => (this.recaptcha = e)}
              sitekey={SITE_KEY}
              render="explicit"
              size={document.body.offsetWidth >= 360 ? 'normal' : 'compact'}
              onloadCallback={this.recaptchaLoaded}
              verifyCallback={this.verifyCallback}
              hl="vi"
            />
          </div>
        )}
        <div className="container-remember-login">
          <Button
            type="submit"
            className="pt-button pt-large pt-intent-primary"
            disabled={
              !this.state.username.trim() ||
              !this.state.password.trim() ||
              this.state.countError >= 5
            }
            loading={this.state.isLoading}
          >
            Đăng nhập
          </Button>
        </div>
      </form>
    )
  }
}

LoginForm.propTypes = {
  actions: PropTypes.object.isRequired,
}

export default withRouter(LoginForm)
