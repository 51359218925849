import React from 'react'
import PropTypes from 'prop-types'
import { Intent, Position, Toaster } from '@blueprintjs/core'
import { ListConvergeItem, ListFilter } from '../../../components/SignNumber'
import {
  TOATS_MESSAGE_DELETE_FAIL,
  TOATS_MESSAGE_DELETE_SUCCESS,
} from '../../../constants/MessageForm'
import { GridViewFilter } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { headers } from '../../../constants/Table'
import * as Actions from '../../../actions'
import * as Actions2 from '../../../actions/procedure'
import * as Tool from '../../../helpers'
import * as Types from '../../../constants/Api'
import _ from 'lodash'
import moment from 'moment'

import Pagination from 'components/Pagination'

const REFRESH_FALSE = false

class SignatureDocumentList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      filterColumn: {},
      sort: undefined,
      listStatus: [
        {
          name: 'Chưa duyệt',
          value: 0,
        },
        {
          name: 'Đang duyệt',
          value: 2,
        },
        {
          name: 'Đã duyệt',
          value: 6,
        },
        {
          name: 'Không duyệt',
          value: 1,
        },
        {
          name: 'Đã phát hành',
          value: 7,
        },
      ],
      isEmpty: false,
      page: 1,
      isLoading: false,
      isNeedSignNumber: true,
      soKyHieu: null,
      isError: false,
      isLoadingXoaKySo: false,
      departments: [],
      dsKyDuyet: {
        list: {
          page: 1,
          total: 0,
          items: [],
        },
      },
    }
  }

  refHandlers = {
    toaster: ref => (this.toaster = ref),
  }

  async componentDidMount() {
    const { auth, actions, location } = this.props
    await this.setState({ isLoading: true })
    await actions.getDepartment(auth.mainUnitId).then(res => {
      const result = _.get(res, 'payload.data.result')
      if (result) {
        const departments = [
          ...result.filter(i => !!i.phongBanId),
          {
            donViId: null,
            level: 0,
            maPhongBan: null,
            stt: 0,
            tenPhongBan: null,
            tenVietTat: 'Tất cả',
          },
        ]
        this.setState({ departments })
      }
    })

    if (location.state && location.state.saved) {
      this.toaster.show({
        message: 'Lưu thành công!',
        intent: Intent.SUCCESS,
        autoFocus: true,
        timeout: 2000,
      })
    }

    const preData = Tool.Utils.preData('SignatureDocumentList')
    let data = { ...this.state }

    if (preData) {
      data = {
        filter: preData.filter,
        page: preData.page,
        sort: preData.sort,
        isNeedSignNumber: preData.canKySo,
        filterColumn: {
          ...preData.filterColumn,
          ngayLap:
            preData.filterColumn && preData.filterColumn.ngayLap
              ? moment(preData.filterColumn.ngayLap)
              : null,
        },
      }
    }

    this.setState(data, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination = page => {
    this.submitFilter({}, page, this.state.sort, this.state.isNeedSignNumber)
    this.setState({ page })
  }

  handleClickNeedSignNumber = async () => {
    const { isNeedSignNumber, filter } = this.state
    await this.setState({
      isNeedSignNumber: !isNeedSignNumber,
      filterColumn: {},
      filter: {
        ...filter,
        textSearch: '',
        ngayLap: {
          from: isNeedSignNumber
            ? moment().subtract(1, 'months').format('YYYY-MM-DD')
            : undefined,
          to: isNeedSignNumber ? moment().format('YYYY-MM-DD') : undefined,
        },
      },
    })

    this.submitFilter(
      this.state.filter,
      Types.PAGE,
      this.state.sort,
      this.state.isNeedSignNumber
    )
  }

  submitFilter = (
    e = {},
    page = 1,
    sort,
    canKySo = this.state.isNeedSignNumber
  ) => {
    const { filter: stateFilter, filterColumn: stateFilterColumn } = this.state
    const filter = { ...stateFilter, ...e }
    const filterColumn = {
      ...stateFilterColumn,
      soKyHieu: stateFilterColumn.soKyHieu || null,
      trichYeu: stateFilterColumn.trichYeu || null,
      nguoiLap: stateFilterColumn.nguoiLap || null,
      tinhTrang: stateFilterColumn.tinhTrang || null,
      ngayLap: stateFilterColumn.ngayLap
        ? moment(stateFilterColumn.ngayLap).format('YYYY-MM-DD')
        : null,
    }

    let isCallRequestAgain = false
    this.setState({ filter, page, isLoading: true, sort }, () => {
      const { actions } = this.props
      Tool.Utils.preData('SignatureDocumentList', {
        filter,
        page,
        sort,
        canKySo,
        filterColumn,
      })
      // tạm thời để refresh bang false
      actions
        .getDsKyDuyetKSTT(
          { filter },
          page,
          sort,
          canKySo,
          Types.PAGINATION_LIMIT,
          REFRESH_FALSE,
          filterColumn
        )
        .then(res => {
          const total = _.get(res, 'payload.data.result.total')
          const status =
            _.get(res, 'payload.status') === 200 || (res && !res.error)
          const result = _.get(res, 'payload.data.result')
          const items = _.get(result, 'items', []).map(i => {
            if (
              i.tinhTrang === 2 &&
              i.khongDuyet &&
              i.nguoiLapId === this.props.auth.roleId
            ) {
              return { ...i, tinhTrang: 1 }
            }
            return i
          })
          const convertList = { ...result, items }
          if (!status) {
            return this.setState({ isError: !status })
          }
          if (page > 1 && total === 0) {
            isCallRequestAgain = true
            return this.submitFilter(e, page - 1, sort, canKySo)
          }
          this.setState({
            isEmpty: status && total === 0,
            isError: !status,
            dsKyDuyet: { list: convertList },
          })
        })
        .then(() => {
          if (!isCallRequestAgain) {
            this.setState({ isLoading: false, isNeedSignNumber: canKySo })
          }
        })
    })
  }

  handleSubmitRemoveItem = (lydo, id) => {
    const { actions } = this.props
    const { dsKyDuyet } = this.state
    let total = _.get(dsKyDuyet, 'list.total', 0)
    this.setState({ isLoadingXoaKySo: true }, () => {
      let { page } = this.state
      const { filter, sort, isNeedSignNumber } = this.state
      actions
        .deleteItemKyDuyetKSTT(lydo, id)
        .then(res => {
          if (res.error) {
            return actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_DELETE_FAIL,
              intent: Intent.DANGER,
            })
          }
          if (total > 0) {
            total -= 1
          }
          if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
            page = Math.ceil(total / Types.PAGINATION_LIMIT)
          }
          if (page === 0) {
            page = 1
          }

          this.props.actions.getNumberKSTTPage()
          this.submitFilter(filter, page, sort, isNeedSignNumber)
          actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_DELETE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        })
        .catch(() => {
          actions.commonAddToasterMessage({
            message: TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        })
        .finally(() => {
          this.setState({ isLoadingXoaKySo: false })
        })
    })
  }

  handleSort = sort => {
    const { filter, page, isNeedSignNumber } = this.state
    this.setState({ sort })
    this.submitFilter(filter, page, sort, isNeedSignNumber)
  }

  handleClickDetail = data => {
    const { history, location } = this.props
    if (data) {
      this.setState({ soKyHieu: data.soKyHieu }, () =>
        history.push({
          pathname: `/ky-so-tap-trung/van-ban-ky-duyet/chi-tiet/${data.vanBanKySoId}`,
          state: {
            fileId: data.fileId,
            soKyHieu: data.soKyHieu,
            from: location.pathname,
          },
        })
      )
    }
  }

  onChangeGridViewFilter = async e => {
    const { value, name } = e.target
    const { filterColumn } = this.state
    await this.setState({
      filterColumn: {
        ...filterColumn,
        [name]: value,
      },
    })
    const { filter, page, sort, isNeedSignNumber } = this.state
    if (name === 'tinhTrang') {
      this.submitFilter(filter, page, sort, isNeedSignNumber)
    }
  }

  onChangeDateGridViewFilter = async (name, e) => {
    const { filterColumn } = this.state
    await this.setState({
      filterColumn: {
        ...filterColumn,
        [name]: e,
      },
    })
    const { filter, page, sort, isNeedSignNumber } = this.state
    this.submitFilter(filter, page, sort, isNeedSignNumber)
  }

  clearFilterColumn = async (filter, type = null) => {
    const { isNeedSignNumber, page, sort } = this.state
    if (type === 'reset') {
      this.props.actions.getNumberKSTTPage()
      await this.setState({ filterColumn: {} })
      return this.submitFilter(filter, page, sort, true)
    }
    this.submitFilter(filter, page, sort, isNeedSignNumber)
  }

  onRemoveText = async name => {
    const { filterColumn, filter, page, sort, isNeedSignNumber } = this.state
    await this.setState({
      filterColumn: {
        ...filterColumn,
        [name]: '',
      },
    })
    this.submitFilter(filter, page, sort, isNeedSignNumber)
  }

  removeTextSearch = name => {
    const { filter } = this.state
    this.setState({
      filter: {
        ...filter,
        [name]: '',
      },
    })
  }

  render() {
    const {
      filter,
      listStatus,
      isNeedSignNumber,
      isLoading,
      page,
      isEmpty,
      isError,
      filterColumn,
      isLoadingXoaKySo,
      dsKyDuyet,
    } = this.state
    const { staffs, readOnly, actions, match, auth } = this.props
    return (
      <MasterLayout typeSidebar="signNumber">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-danh-sach-tap-trung icon-heading" />
                  <span className="text-heading">Danh sách ký duyệt</span>
                </h3>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container list-sign-number signature-document-custom-list">
            <ListFilter
              filter={filter}
              staffs={staffs}
              status={listStatus}
              onSubmit={this.handleSubmit}
              isNeedSignNumber={isNeedSignNumber}
              onClickNeddSignNumber={this.handleClickNeedSignNumber}
              readOnly={readOnly}
              actions={actions}
              clearFilterColumn={this.clearFilterColumn}
              removeTextSearch={this.removeTextSearch}
              type="kySoKyDuyet"
            />

            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(dsKyDuyet.list.total / Types.PAGINATION_LIMIT) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridViewFilter
                isLoading={isLoading}
                classNames={'pt-table fix-table-before'}
                type="cong-van-di"
                page={page}
                headers={headers.listSignatureConvergePage}
                itemShape={ListConvergeItem}
                data={dsKyDuyet.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onClickDetail={this.handleClickDetail}
                match={match}
                onFilter={this.handleSort}
                isEmpty={isEmpty}
                auth={auth}
                isError={isError}
                onChange={this.onChangeGridViewFilter}
                onChangeDate={this.onChangeDateGridViewFilter}
                tinhTrang={listStatus}
                filterColumn={filterColumn}
                onSubmit={this.submitFilter}
                onRemoveText={this.onRemoveText}
                isLoadingXoaKySo={isLoadingXoaKySo}
              />
              {!isEmpty && !isError && !isLoading && (
                <Pagination
                  current={page}
                  total={Math.ceil(
                    dsKyDuyet.list.total / Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                />
              )}
            </div>
          </div>
          <Toaster
            className="message-popup-delete"
            position={Position.TOP_RIGHT}
            ref={this.refHandlers.toaster}
          />
        </div>
      </MasterLayout>
    )
  }
}

SignatureDocumentList.propTypes = {
  match: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  config: state.common.config,
  auth: state.auth,
  staffs: state.common.staffs,
  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...Actions, ...Actions2 }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignatureDocumentList)
