import React from 'react'
import { get, size, isEmpty } from 'lodash'
import { MasterLayout } from '../../components/layout'
import {
  SaveDocument,
  SendMail,
  Report,
  AssignmentInformation,
  DetailBoxActionPersonal,
  ExecutingViewInternal,
  AlertMistake,
  Assign,
  LayYKien,
} from '../../components/Work'

import {
  DetailFileContent,
  ExecutingView,
  InformationSteering,
  LoadingLine,
  LoadingItem,
  DetailFileContentV2,
} from 'components/common'
import { FlowInternalDocumentary } from '../../components/InternalDocument'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { Permission, Authorization } from '../../components/auth'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { Flowdocumentary } from '../../components/ReceiveDocument'
import { FlowSendDocumentary } from '../../components/SendDocument'

const CONGVAN_NOIBO = 2
const DOCUMENT_MISTAKE = 1
const WORK_MISTAKE = 2
const cabinetTypes = {
  [Types.CABINET_UNIT]: 'unit',
  [Types.CABINET_DEPARTMENT]: 'department',
  [Types.HOSO_CONGVIEC]: 'profile',
  [Types.CABINET_PERSONAL]: 'personal',
}

class DetailPersonalWorkPage extends React.Component {
  static propTypes = {
    // TODO: Thay Thế: Sử dụng field dsNguoiDaBaoCao để kiểm tra user đã báo cáo chưa
    // reportWork: PropTypes.object.isRequired,
    archives: PropTypes.object.isRequired,
    staffs: PropTypes.array,
  }

  refHandlers = {
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  constructor(props) {
    super(props)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.handleClickReport = this.handleClickReport.bind(this)
    this.handleClickSaveDocument = this.handleClickSaveDocument.bind(this)
    this.handleClickAssignmentHistory =
      this.handleClickAssignmentHistory.bind(this)
    this.handleClickSendMail = this.handleClickSendMail.bind(this)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.closeModulesDetail = this.closeModulesDetail.bind(this)
    this.handleReportWork = this.handleReportWork.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSelectSaveTo = this.handleSelectSaveTo.bind(this)
    this.handleSaveArchive = this.handleSaveArchive.bind(this)
    this.handleSendMistake = this.handleSendMistake.bind(this)
    this.handleClickAssign = this.handleClickAssign.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleClickInformationWork = this.handleClickInformationWork.bind(this)
    this.handleClickInformationAssign =
      this.handleClickInformationAssign.bind(this)
    this.handleClickComplete = this.handleClickComplete.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleClickFlowDocument = this.handleClickFlowDocument.bind(this)
    this.handleClickShowSaveDocument =
      this.handleClickShowSaveDocument.bind(this)
    this.handleCancelAlertMistake = this.handleCancelAlertMistake.bind(this)
    this.handleClickMoreDepartmentExecutingView =
      this.handleClickMoreDepartmentExecutingView.bind(this)
  }

  state = {
    showResults: false,
    showSection: 'noContent',
    showListAction: true,
    isShowMobileAssignmentHistory: false,
    reportWorkPersonal: null,
    isUpdateAssignment: false,
    showInformationSteering: false,
    saveTo: [
      ...(Authorization.vaild(this.props.auth.permission, [
        Permission.TUTAILIEUDONVI_QUANLY,
      ])
        ? [
            {
              id: Types.CABINET_UNIT,
              name: 'Tủ đơn vị',
              disabled: Authorization.vaild(this.props.auth.permission, [
                Permission.TUTAILIEUDONVI_QUANLY,
              ])
                ? false
                : true,
            },
          ]
        : []),
      {
        id: Types.CABINET_DEPARTMENT,
        name: 'Tủ phòng ban',
        disabled: Authorization.vaild(this.props.auth.permission, [
          Permission.TUTAILIEUPHONGBAN,
        ])
          ? false
          : true,
      },
      {
        id: Types.HOSO_CONGVIEC,
        name: 'Hồ sơ công việc',
        disabled: !Authorization.vaild(this.props.auth.permission, [
          Permission.TUTAILIEUPHONGBAN,
        ]),
      },
      {
        id: Types.CABINET_PERSONAL,
        name: 'Tủ cá nhân',
      },
    ],
    showAssignmentInformation: false,
    showSendEmail: false,
    showLayYKien: false,
    isShowMobile: false,
    isLoading: false,
    isLoadingSubData: false,
    workId: this.props.match.params.id,
    personalWork: null,
    file: null,
    informationSteeringPersonal: null,
    isLoadingData: {
      work: true,
      file: true,
      documentNumber: true,
    },
    seeToKnow: null,
    isHasReceiveDocumentId: undefined,
    isLeaderUnit: false,
    isLoadingMigratedFile: true,
    fileFromList: null, // File từ danh sách
    documentNumberFromList: null, // Số công văn từ danh sách
    isLoadedApi: {
      departments: false,
      staffs: false,
      reportWork: false,
      steeringInformation: false,
      executingView: false,
      documentCabinetList: false,
      flowDocument: false,
    },
    infoAssign: null,
    isSubmit: false,
    boxMenuFixTop: false,
    isLoadingSaveDocument: false,
    isCheck: false,
    anLayYKien: true,
    filesThamKhao: [],
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  handleClickGoBack = e => {
    e && e.preventDefault()
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
    })
  }

  handleClickFlowDocument = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'LuongCongVan',
    })

    let { isCheck, isLoadedApi } = this.state

    if (
      this.props.personalWorks &&
      this.props.personalWorks.item &&
      this.props.personalWorks.item.congVanDiId &&
      isLoadedApi &&
      isLoadedApi.flowDocument === false
    ) {
      await this.props.actions
        .getFlowDocumentarySendDocument(
          this.props.personalWorks.item.congVanDiId
        )
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              flowDocument: true,
            }
          }
          isCheck = true
        })
    }

    if (
      this.props.personalWorks &&
      this.props.personalWorks.item &&
      !this.props.personalWorks.item.congVanDiId &&
      isLoadedApi &&
      isLoadedApi.flowDocument === false
    ) {
      await this.props.actions
        .getFlowDocumentaryReceiveDocument(
          this.props.personalWorks.item.congVanDenId
        )
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              flowDocument: true,
            }
          }
        })
    }

    await this.setState({
      isCheck,
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  getListDisable = async type => {
    const {
      actions,
      personalWorks: { item },
    } = this.props
    const id = item.congVanId || item.congVanNoiBoId
    const docType = item.congVanNoiBoId ? 2 : 1
    actions.getListCabinetOfDocument(id, docType)
  }

  handleClickSaveDocument = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'LuuTuTaiLieu',
    })

    let isLoadedApi = this.state.isLoadedApi
    let { personalWorks } = this.props
    let request

    if (
      personalWorks &&
      personalWorks.item &&
      personalWorks.item.congVanDenId &&
      isLoadedApi &&
      isLoadedApi.documentCabinetList === false
    ) {
      request = this.props.actions.getListCabinetOfDocument(
        personalWorks.item.congVanDenId
      )
    }

    if (
      personalWorks &&
      personalWorks.item &&
      personalWorks.item.congVanNoiBoId &&
      isLoadedApi &&
      isLoadedApi.documentCabinetList === false
    ) {
      request = this.props.actions.getListCabinetOfDocument(
        personalWorks.item.congVanNoiBoId,
        CONGVAN_NOIBO
      )
    }

    if (request) {
      request.then(res => {
        if (res.payload && res.payload.status === 200) {
          isLoadedApi = {
            ...isLoadedApi,
            documentCabinetList: true,
          }
        }
        this.setState({
          showResults: true,
          showListAction: false,
          isLoadingSubData: false,
          isLoadedApi,
        })
      })
    } else {
      this.setState({
        showResults: true,
        showListAction: false,
        isLoadingSubData: false,
        isLoadedApi,
      })
    }
  }

  handleClickShowSaveDocument = async () => {
    let { isLoadedApi } = this.state
    let { personalWorks } = this.props
    let request

    if (
      personalWorks &&
      personalWorks.item &&
      personalWorks.item.congVanDenId &&
      isLoadedApi &&
      isLoadedApi.documentCabinetList === false
    ) {
      request = this.props.actions.getListCabinetOfDocument(
        personalWorks.item.congVanDenId
      )
    }

    if (
      personalWorks &&
      personalWorks.item &&
      personalWorks.item.congVanNoiBoId &&
      isLoadedApi &&
      isLoadedApi.documentCabinetList === false
    ) {
      request = this.props.actions.getListCabinetOfDocument(
        personalWorks.item.congVanNoiBoId,
        CONGVAN_NOIBO
      )
    }

    if (request) {
      await this.setState({ isLoadingSaveDocument: true })
      request
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              documentCabinetList: true,
            }
          }
          this.setState({
            isLoadedApi,
            isLoadingSaveDocument: false,
          })
        })
        .catch(err => {
          this.setState({ isLoadingSaveDocument: false })
        })
    }
  }

  handleClickAssignmentHistory = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'LichSuGiaoViec',
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
      showSendEmail: !this.state.showSendEmail,
    })
  }

  handleClickAlertMistake = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'AlertMistake',
    })
  }

  handleClickReport = e => {
    e && e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'BaoCao',
    })
  }

  handleClickAssign = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    await this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'GiaoViec',
    })
    let isLoadedApi = this.state.isLoadedApi
    if (this.state.isLeaderUnit) {
      // Lanh dao don vi
      if (isLoadedApi && isLoadedApi.departments === false) {
        await this.props.actions.commonFetchDepartments().then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              departments: true,
            }
          }
        })
      }
      if (
        this.props.auth.mainDepartmentId &&
        isLoadedApi &&
        isLoadedApi.staffs === false
      ) {
        await this.props.actions
          .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              isLoadedApi = {
                ...isLoadedApi,
                staffs: true,
              }
            }
          })
      }
    } else {
      if (
        this.props.auth.mainDepartmentId &&
        isLoadedApi &&
        isLoadedApi.staffs === false
      ) {
        await this.props.actions
          .commonFetchStaffByDepartmentId(this.props.auth.mainDepartmentId)
          .then(res => {
            isLoadedApi = {
              ...isLoadedApi,
              staffs: true,
            }
          })
      }
    }
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GiaoViec',
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickExecutingView = () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'XemThucHien',
    })

    let isLoadedApi = this.state.isLoadedApi
    let { personalWorks } = this.props
    let request
    let executingViewAssign = null
    let isNewAssignDoc = false

    if (
      personalWorks &&
      personalWorks.item &&
      personalWorks.item.dsPhieuGiaoViec &&
      isLoadedApi &&
      isLoadedApi.executingView === false
    ) {
      request = this.props.actions.getExecutingViewAssign(
        personalWorks.item.phieuGiaoViecId,
        true
      )
      isNewAssignDoc = true
    }

    if (
      personalWorks &&
      personalWorks.item &&
      personalWorks.item.congVanDenId &&
      isLoadedApi &&
      isLoadedApi.executingView === false
    ) {
      request = this.props.actions.getExecutingView(
        personalWorks.item.congVanDenId
      )
    }

    if (
      personalWorks &&
      personalWorks.item &&
      personalWorks.item.congVanNoiBoId &&
      isLoadedApi &&
      isLoadedApi.executingView === false
    ) {
      request = this.props.actions.getExecutingViewInternalDocument(
        personalWorks.item.congVanNoiBoId
      )
    }

    if (request) {
      request.then(res => {
        if (res.payload && res.payload.status === 200) {
          isLoadedApi = {
            ...isLoadedApi,
            executingView: true,
          }
        }
        if (
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          isNewAssignDoc
        ) {
          executingViewAssign = [res.payload.data.result]
        }
        this.setState({
          showResults: true,
          showListAction: false,
          isLoadingSubData: false,
          isLoadedApi,
          executingViewAssign,
        })
      })
    } else {
      this.setState({
        showResults: true,
        showListAction: false,
        isLoadingSubData: false,
        isLoadedApi,
      })
    }
  }

  handleReportWork = () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'BanBaoCaoCongViec',
      showReportWork: !this.state.showReportWork,
    })
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  showDetailInfo = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeShowDetailInfo = () => {
    this.setState({
      showDetailInfo: false,
    })
  }

  closeModulesDetail = () => {
    this.setState({
      showListAction: true,
      showAssignmentInformation: false,
      showReportWork: false,
      showInformationSteering: false,
      showSendEmail: false,
      showLayYKien: false,
    })
  }

  handleClickInformationAssign = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinChiDao',
    })

    let isLoadedApi = this.state.isLoadedApi
    if (
      this.state.personalWork &&
      this.state.personalWork.congVanDenId &&
      isLoadedApi &&
      isLoadedApi.steeringInformation === false
    ) {
      await this.props.actions
        .InformationSteering(this.state.personalWork.congVanDenId)
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            isLoadedApi = {
              ...isLoadedApi,
              steeringInformation: true,
            }
            this.setState({
              informationSteeringPersonal: res.payload.data.result,
            })
          }
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickInformationWork = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinGiaoViec',
    })
    if (this.state.infoAssign) {
      return this.setState({
        isLoadingSubData: false,
        showResults: true,
      })
    }
    this.props.actions
      .getInformationAssign(this.props.match.params.id)
      .then(res => {
        this.setState({
          infoAssign: res.payload.data.result ? res.payload.data.result : null,
          isLoadingSubData: false,
          showResults: true,
        })
      })
  }

  handleSubmit = (title, message, intent, isSuccess, id = 0) => {
    switch (title) {
      case 'BaoCao':
      case 'GuiEmail':
      case 'GiaoViec':
      case 'XoaBaoCao':
      case 'XoaGiaoViec':
      case 'LayYKien':
        this.props.actions.commonAddToasterMessage({
          message: message,
          intent: intent,
        })
        if (isSuccess) {
          this.props.actions.refreshPage(true)
          this.props.history.goBack()
        }
        break

      case 'SuaBaoCao':
        let isLoadedApi = this.state.isLoadedApi
        this.setState(
          {
            showResults: false,
            showListAction: false,
            isLoadingSubData: true,
            showSection: title.slice(3),
          },
          () => {
            if (isLoadedApi && isLoadedApi.reportWork === false) {
              this.props.actions
                .getReportWorkPersonal(this.state.workId)
                .then(res => {
                  if (
                    res.payload &&
                    res.payload.data &&
                    res.payload.data.result
                  ) {
                    isLoadedApi = {
                      ...isLoadedApi,
                      reportWork: true,
                    }
                    this.setState({
                      reportWorkPersonal: res.payload.data.result,
                      showResults: true,
                      showListAction: false,
                      isLoadingSubData: false,
                      isLoadedApi,
                    })
                  } else {
                    this.setState({
                      showResults: true,
                      showListAction: false,
                      isLoadingSubData: false,
                      isLoadedApi,
                    })
                  }
                })
                .catch(err => {
                  this.setState({
                    showResults: true,
                    showListAction: false,
                    isLoadingSubData: false,
                    isLoadedApi,
                  })
                })
            } else {
              this.setState({
                showResults: true,
                showListAction: false,
                isLoadingSubData: false,
                isLoadedApi,
              })
            }
          }
        )
        break

      default:
        this.props.actions.commonAddToasterMessage({ message, intent })
    }
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    // Nếu có fileId, soCongVan lấy từ danh sách
    if (this.props.location && this.props.location.state) {
      // Gọi api get thông tin file => state.fileFromList
      let fileFromList = null
      let documentNumberFromList = null
      let isLoadingData = this.state.isLoadingData

      if (this.props.location.state.fileId) {
        await this.props.actions
          .getFileInformation(this.props.location.state.fileId)
          .then(res => {
            if (
              res &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result
            ) {
              fileFromList = res.payload.data.result
              isLoadingData = {
                ...isLoadingData,
                file: false,
              }
            }
          })
      }

      // Lấy số công văn từ danh sách
      if (this.props.location.state.soCongVan) {
        documentNumberFromList = this.props.location.state.soCongVan
        isLoadingData = {
          ...isLoadingData,
          documentNumber: false,
        }
      }

      if (fileFromList || documentNumberFromList) {
        await this.setState({
          fileFromList,
          documentNumberFromList,
          isLoadingData,
        })
      }
    }

    // TODO: Kiem tra truong hop LD DV hay LD PB
    if (this.props.auth && this.props.auth.permission) {
      this.setState({
        isLeaderUnit: Authorization.vaild(this.props.auth.permission, [
          Permission.CONGVANDEN_CHIDAO,
        ]),
      })
    }

    if (this.state.workId) {
      // Phieu giao viec === Thong tin giao viec
      await this.props.actions
        .getPersonalWorkItem(this.state.workId)
        .then(() => {
          if (this.props.personalWorks && this.props.personalWorks.item) {
            let isHasReceiveDocumentId = false
            if (this.props.personalWorks.item.congVanDenId) {
              isHasReceiveDocumentId = true
            }

            let anLayYKien = true
            if (
              this.props.personalWorks.item.congVanId === null &&
              this.props.personalWorks.item.congVanNoiBoId === null
            ) {
              anLayYKien = false
            }

            this.setState({
              file: this.props.personalWorks.item.fileCongVan,
              personalWork: this.props.personalWorks.item,
              isLoadingData: {
                ...this.state.isLoadingData,
                work: false,
                file: false,
                documentNumber: false,
              },
              isHasReceiveDocumentId,
              anLayYKien,
            })
            // Gọi api get file tham khảo
            this.getFileThamKhao(this.props.personalWorks.item).then(res => {
              if (res && res.length !== 0) {
                this.setState({
                  filesThamKhao: res,
                })
              }
            })
          }
        })
        .catch(err => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              work: false,
              file: false,
              documentNumber: false,
            },
          })
        })

      // TODO: Thay Thế: Sử dụng field dsNguoiDaBaoCao để kiểm tra user đã báo cáo chưa
      // Bao cao
      // this.props.actions.getReportWork(this.state.workId);
    }
  }

  // Get danh sách files tham khảo
  getFileThamKhao = async data => {
    let params = {
      loai: 1,
    }
    let id = -1
    if (data.congVanId) {
      params = {
        ...params,
        type: 'CONG_VAN',
      }
      id = data.congVanId
    } else {
      return []
    }

    let filesThamKhao = []
    await this.props.actions.getDanhSachFiles(id, params).then(res => {
      if (
        res &&
        res.payload &&
        res.payload.data &&
        res.payload.data.result &&
        res.payload.data.result.items &&
        res.payload.data.result.items.length !== 0
      ) {
        res.payload.data.result.items.map(elm =>
          filesThamKhao.push({
            ...elm,
            is_existed: true,
          })
        )
      }
    })

    return filesThamKhao
  }

  goBack = () => {
    this.props.history.goBack()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isLoadingData.work === true &&
      this.state.isLoadingData.work === false &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.showSection &&
      this.props.location.state.showSection === 'baoCao'
    ) {
      this.handleSubmit(
        'SuaBaoCao',
        null,
        null,
        null,
        this.props.location.state.phieuGiaoViecId
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    this.setState({
      showListAction: true,
      isShowMobileAssignmentHistory: false,
      reportWorkPersonal: null,
      isUpdateAssignment: false,
      workDepartmentAssignItem: null,
      showInformationSteering: false,
      saveTo: [
        ...(Authorization.vaild(this.props.auth.permission, [
          Permission.TUTAILIEUDONVI_QUANLY,
        ])
          ? [
              {
                id: Types.CABINET_UNIT,
                name: 'Tủ đơn vị',
                disabled: !Authorization.vaild(this.props.auth.permission, [
                  Permission.TUTAILIEUDONVI_QUANLY,
                ]),
              },
            ]
          : []),
        {
          id: Types.CABINET_DEPARTMENT,
          name: 'Tủ phòng ban',
        },
        {
          id: Types.HOSO_CONGVIEC,
          name: 'Hồ sơ công việc',
          disabled: !Authorization.vaild(this.props.auth.permission, [
            Permission.TUTAILIEUPHONGBAN,
          ]),
        },
        {
          id: Types.CABINET_PERSONAL,
          name: 'Tủ cá nhân',
        },
      ],
      showAssignmentInformation: false,
      showSendEmail: false,
      showLayYKien: false,
      isShowMobile: false,
      workId: this.props.match.params.id,
      personalWork: null,
      file: null,
      informationSteeringPersonal: null,
      isLoadingData: {
        work: true,
        fileFromList: true,
        documentNumber: true,
      },
      executingViews: [],
      infoAssign: null,
    })
  }

  showValidateMessage = (message, danger = false, success = false) => {
    return this.props.actions.commonAddToasterMessage({
      message: message,
      inten: success ? Intent.SUCCESS : danger ? Intent.DANGER : Intent.WARNING,
    })
  }

  changeUploadProgress = name => progress => {
    this.setState(prev => ({
      uploadProgress: {
        ...prev.uploadProgress,
        [name]: ~~((progress.loaded * 100) / progress.total),
      },
    }))
  }

  // Upload file đính kèm
  uploadFile = async files => {
    const { fileUpload } = this.props.actions
    let response = {}
    if (size(files)) {
      response = await fileUpload(files, this.changeUploadProgress)
    }
    if (response.error) {
      return this.showValidateMessage(MESSAGE.TEXT_MESSAGE_UPLOAD_FAIL, true)
    }

    return get(response, 'payload.data.result', []).map(file =>
      Tool.Utils.convertFileResponse(file)
    )
  }

  // Lưu hồ sơ công việc
  handleSaveJobProject = async (err, params) => {
    const {
      actions,
      personalWorks: { item },
    } = this.props
    const { commonAddToasterMessage, postTaiLieuHoSoCongViec } = actions
    if (err) {
      return commonAddToasterMessage({
        message: err,
        inten: Intent.DANGER,
      })
    }

    const files = await this.uploadFile(get(params, 'files', []))
    let fileDinhKemEvn = []
    if (item && item.fileDinhKemEvn && item.fileDinhKemEvn.length !== 0) {
      fileDinhKemEvn = item.fileDinhKemEvn.map(i => ({
        ...i,
        is_existed: true,
      }))
    }

    const data = Tool.Utils.toDecamelizeKeys({
      tenTaiLieu: item.trichYeu,
      ghiChu: get(params, 'ghiChu'),
      congVanId: item.congVanId,
      congVanNoiBoId: item.congVanNoiBoId,
      dsHoSoCongViecId: get(params, 'nganTuIds', []),
      soCongVan: item.soCongVan,
      ngayCongVan: item.ngayCongVan,
      fileNoiDung: item.fileNoiDung
        ? item.fileNoiDung
        : get(item, 'fileCongVan', {}),
      fileDinhKem: [...fileDinhKemEvn],
      fileThamKhao: [...this.state.filesThamKhao],
      fileDinhKemBenNgoai: [...files],
    })

    try {
      const response = await postTaiLieuHoSoCongViec(data)
      if (response.error) {
        return commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          inten: Intent.DANGER,
        })
      }

      if (get(response, 'payload.status') === 200) {
        commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_SUCCESS,
          intent: Intent.SUCCESS,
        })
        ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
        this.setState({
          showResults: false,
          showListAction: true,
          showSection: 'noContent',
          isLoadedApi: {
            ...this.state.isLoadedApi,
            documentCabinetList: false,
          },
        })
      }
    } catch (error) {
      return commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_FAIL,
        inten: Intent.DANGER,
      })
    }
  }

  handleSaveArchive = (err, params) => {
    if (err) {
      return this.props.actions.commonAddToasterMessage({
        message: err,
        intent: Intent.DANGER,
      })
    }

    if (params.categoryType === Types.HOSO_CONGVIEC) {
      return this.handleSaveJobProject(err, params)
    }

    const item = this.props.personalWorks.item

    params = {
      ds_ngan_tu: params.nganTuIds,
      loai_tu_tai_lieu: parseInt(params.categoryType, 10),
      ghi_chu: params.ghiChu,
      ds_file_cong_van: [
        {
          file_id: item.fileThamKhao
            ? item.fileThamKhao.fileId
            : item.fileCongVan.fileId,
          cong_van_den_id: item.congVanDenId,
          cong_van_noi_bo_id: item.congVanNoiBoId,
          ten_tai_lieu: params.tenTaiLieu,
        },
      ],
    }

    return this.props.actions
      .documentsCabinetSaveReceiveDocument(params)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }

        if (res.payload && res.payload.status === 200) {
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
          ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
          this.setState({
            showResults: false,
            showListAction: true,
            showSection: 'noContent',
            isLoadedApi: {
              ...this.state.isLoadedApi,
              documentCabinetList: false,
            },
          })
        }
      })
  }

  handleSelectSaveTo(v, isFromAssignmentForm = true) {
    this.props.actions.setCabinetType(v)
    this.getListDisable(v)
    switch (v) {
      case Types.CABINET_UNIT:
        this.props.actions.documentsCabinetGetListCategoriesUnit()
        break
      case Types.CABINET_PERSONAL:
        this.props.actions.documentsCabinetGetListCategoriesPersonal()
        break
      case Types.CABINET_DEPARTMENT:
        this.props.actions.documentsCabinetGetListCategoriesDepartment()
        break
      case Types.HOSO_CONGVIEC:
        const params = {
          loai: 1,
          phanLoaiTu: 'PHONGBAN',
        }
        this.props.actions.getLoaiLuuTaiLieu(params)
        break
      default:
    }
  }

  handleClickDepartmentExecutingView = id => {
    this.props.actions.getMoreExecutingView(id)
  }

  handleClickMoreDepartmentExecutingView = id => {
    this.props.actions.getMoreFlowDocumentaryReceiveDocument(id)
  }

  handleSendMistake(content) {
    if (!content) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_ALERT_MISTAKE_REQUIRE_WARNING,
        intent: Intent.WARNING,
      })
    }
    this.setState({ isLoading: true })
    let item = this.props.personalWorks.item
    const loai = item.phieuGiaoViecId ? WORK_MISTAKE : DOCUMENT_MISTAKE
    return this.props.actions
      .postAlertMistake({
        noi_dung: content,
        cong_van_cong_viec_id: item.phieuGiaoViecId || item.congVanDenId,
        cong_viec_phong_ban_id:
          loai === DOCUMENT_MISTAKE ? item.congViecPhongBanId : null,
        loai: loai,
      })
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          this.props.actions.getPersonalWorkItem(this.state.workId).then(() => {
            if (this.props.personalWorks && this.props.personalWorks.item) {
              let isHasReceiveDocumentId = false
              if (this.props.personalWorks.item.congVanDenId) {
                isHasReceiveDocumentId = true
              }
              this.setState({
                file: this.props.personalWorks.item.fileCongVan,
                personalWork: this.props.personalWorks.item,
                isHasReceiveDocumentId,
              })
            }
          })
          this.handleClickGoBack()
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_ALERT_MISTAKE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_MESSAGE_ALERT_MISTAKE_FAILED,
          intent: Intent.DANGER,
        })
      })
      .then(() => {
        this.setState({ isLoading: false })
      })
  }

  handleCancelAlertMistake() {
    this.setState({ isLoading: true })
    let item = this.props.personalWorks.item
    const loai = item.phieuGiaoViecId ? WORK_MISTAKE : DOCUMENT_MISTAKE
    return this.props.actions
      .cancelAlertMistake(item.baoNhamId, loai)
      .then(res => {
        if (res.payload && res.payload.status === 200) {
          this.props.actions.getPersonalWorkItem(this.state.workId).then(() => {
            if (this.props.personalWorks && this.props.personalWorks.item) {
              let isHasReceiveDocumentId = false
              if (this.props.personalWorks.item.congVanDenId) {
                isHasReceiveDocumentId = true
              }
              this.setState({
                file: this.props.personalWorks.item.fileCongVan,
                personalWork: this.props.personalWorks.item,
                isHasReceiveDocumentId,
              })
            }
          })
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_CANCEL_ALERT_MISTAKE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        }

        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_MESSAGE_CANCEL_ALERT_MISTAKE_FAILED,
          intent: Intent.DANGER,
        })
      })
      .then(() => {
        this.setState({ isLoading: false })
      })
  }

  handleClickComplete = id => {
    this.setState({ isSubmit: true }, () => {
      this.props.actions.workCompletePersonal(id).then(res => {
        if (res.error || res.payload.status !== 200) {
          this.setState({ isSubmit: false })
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TEXT_MESSAGE_KHONGHOANTHANHCONGVIEC,
            intent: Intent.DANGER,
          })
        }
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_MESSAGE_HOANTHANHCONGVIEC,
          intent: Intent.SUCCESS,
        })
        this.props.actions.refreshPage(true)
        this.props.history.goBack()
      })
    })
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  sendToChat = e => {
    e.preventDefault()
    if (
      this.props.personalWorks &&
      this.props.personalWorks.item &&
      this.props.personalWorks.item.fileCongVan
    ) {
      const { auth } = this.props
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = this.props.personalWorks.item.congVanId
      let infoGetFileDinhKemType = 'CONG_VAN'
      if (!infoGetFileDinhKem) {
        infoGetFileDinhKem = this.props.personalWorks.item.congVanNoiBoId
        infoGetFileDinhKemType = 'CONG_VAN_NOI_BO'
      }
      const url =
        Types.FE_CHAT_URL +
        `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}
      &tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}
      &fileId=${this.props.personalWorks.item.fileCongVan.fileId}&infoGetFileDinhKem=${infoGetFileDinhKem}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      window.open(url, '_blank')
    }
  }

  handleClickLayYKien = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'LayYKien',
      showLayYKien: !this.state.showLayYKien,
    })
  }

  handleClickFlowdocumentary = async () => {
    const { personalWorks } = this.props
    this.setState({
      showResults: false,
      showListAction: false,
      showSection: 'LuongCongVanNoiBo',
    })
    if (
      personalWorks &&
      personalWorks.item &&
      personalWorks.item.congVanNoiBoId
    ) {
      await this.props.actions.getLuongCongVanNoiBo(
        personalWorks.item.congVanNoiBoId
      )
      this.setState({
        showResults: true,
      })
    }
  }

  render() {
    let isNewAssignDoc =
      this.state.personalWork &&
      this.state.personalWork.dsPhieuGiaoViec &&
      this.state.personalWork.dsPhieuGiaoViec.length > 0
        ? true
        : false
    let isLoadingAll = !Object.values(this.state.isLoadingData).find(
      item => item === true
    )
    const fileContent = this.props.personalWorks.item
    let fileThamKhao = fileContent.fileThamKhao
      ? fileContent.fileThamKhao
      : fileContent.fileCongVan || {}
    fileThamKhao.kichThuocStr = Tool.Utils.getFileSize(fileThamKhao.kichThuoc)

    // Nếu có file từ danh sách thì dùng file, soCongVan từ danh sách,
    // ngược lại dùng file, soCongVan get từ api chi tiết
    const fileDetail = this.state.fileFromList || this.state.file
    let documentNumberDetail = this.state.documentNumberFromList

    if (
      !this.state.documentNumberFromList &&
      this.state.personalWork &&
      this.state.personalWork.soCongVan
    ) {
      documentNumberDetail = this.state.personalWork.soCongVan
    }

    if (isNewAssignDoc) {
      documentNumberDetail = this.state.personalWork.tieuDe
    }

    const { archives, auth } = this.props
    const cabinetCategories = get(
      archives,
      `type[${
        cabinetTypes[get(auth, 'defaultCabinetType', Types.CABINET_PERSONAL)]
      }]`
    )

    let disableGoBack = false
    disableGoBack = Object.values(this.state.isLoadingData).find(
      item => item === true
    )
    return (
      <MasterLayout typeSidebar="work" collapseSideBar={true}>
        <div className="detail-receive-document">
          {this.state.isLoadingData &&
          this.state.isLoadingData.documentNumber === true ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
                disabled={disableGoBack}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              <span className="number-code">
                <span className="name">
                  {isNewAssignDoc ? 'Tiêu đề:' : 'Công văn:'}
                </span>
                <span className="code">{documentNumberDetail}</span>
              </span>

              {/* this.state.isLoadingData && Object.values(this.state.isLoadingData).find(item => item === true) === undefined &&
                this.props.match.params.status === 'chua-thuc-hien' &&
                this.props.personalWorks &&
                this.props.personalWorks.item &&
                !this.props.personalWorks.item.baoCaoLai &&
                <button disabled={this.state.isSubmit} onClick={this.handleClickComplete.bind(this, this.props.personalWorks.item.phieuGiaoViecId)} type="button" className="btn-edit-blue">
                  <span className="cpc-icon-left icon-edit-line"></span>
                  <span className="text-content">Hoàn tất công việc</span>
                </button>
              */}
            </div>
          )}
          <div className="detail-container">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {isNewAssignDoc &&
                  this.state.personalWork.dsPhieuGiaoViec.map((item, index) => (
                    <div className="assign-info" key={index}>
                      <AssignmentInformation
                        firstChild={index === 0 ? true : false}
                        auth={this.props.auth}
                        assignmentInformation={item}
                        isNewAssignDoc={isNewAssignDoc}
                      />
                    </div>
                  ))}
                {!isNewAssignDoc && !isEmpty(fileDetail) && (
                  <DetailFileContentV2
                    auth={this.props.auth}
                    actions={this.props.actions}
                    file={fileDetail}
                  />
                )}
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
              >
                <div className="full-box-content">
                  {this.state.isLoadingData && !isLoadingAll && (
                    <div className="loading-container">
                      <LoadingItem />
                    </div>
                  )}
                  {this.state.showListAction && isLoadingAll && (
                    <DetailBoxActionPersonal
                      isNewAssignDoc={isNewAssignDoc}
                      work={this.state.personalWork}
                      assignmentInformation={
                        this.state.personalWork ? this.state.personalWork : null
                      }
                      // TODO: Thay Thế: Sử dụng field dsNguoiDaBaoCao để kiểm tra user đã báo cáo chưa
                      // reportWork={this.props.reportWork.items ? this.props.reportWork.items : []}
                      onClickSaveDocument={this.handleClickSaveDocument}
                      archives={this.props.archives.items}
                      onClickSendMail={this.handleClickSendMail}
                      onClickAlertMistake={this.handleClickAlertMistake}
                      onClickReport={this.handleClickReport}
                      onClickReportWork={this.handleReportWork}
                      onClickAssign={this.handleClickAssign}
                      actions={this.props.actions}
                      onHandleSubmit={this.handleSubmit}
                      onClickExecutingView={this.handleClickExecutingView}
                      path={this.props.match.path}
                      informationSteering={
                        typeof this.state.informationSteeringPersonal ===
                        'object'
                          ? this.state.informationSteeringPersonal
                          : null
                      }
                      type="personal"
                      phieuGiaoViecId={this.state.workId}
                      isLoadingData={this.state.isLoadingData}
                      auth={this.props.auth}
                      isLeaderUnit={this.state.isLeaderUnit}
                      onClickInformationAssign={
                        this.handleClickInformationAssign
                      }
                      onClickInformationWork={this.handleClickInformationWork}
                      personalWorks={this.props.personalWorks}
                      match={this.props.match}
                      handleClickComplete={this.handleClickComplete}
                      disableAlertMistake={
                        this.props.personalWorks.item.baoNham || false
                      }
                      onClickFlowDocument={this.handleClickFlowDocument}
                      onClickCancelAlertMistake={this.handleCancelAlertMistake}
                      sendToChat={this.sendToChat}
                      onClickLayYKien={this.handleClickLayYKien}
                      anLayYKien={this.state.anLayYKien}
                      filesThamKhao={this.state.filesThamKhao}
                      onClickFlowdocumentary={this.handleClickFlowdocumentary}
                    />
                  )}

                  {this.state.isLoadingSubData && <LoadingItem />}

                  {this.state.showResults &&
                    this.state.showSection === 'GiaoViec' &&
                    this.state.isLoadingData &&
                    isLoadingAll && (
                      <Assign
                        auth={this.props.auth}
                        isNewAssignDoc={isNewAssignDoc}
                        onClickGoBack={this.handleClickGoBack}
                        onSubmit={this.handleSubmit}
                        actions={this.props.actions}
                        staffs={this.props.staffs}
                        departmentWork={null}
                        personalWork={this.state.personalWork} // thong tin giao viec
                        workAssignInformations={null}
                        readOnly={this.props.readOnly}
                        departments={this.props.departments}
                        isLeaderUnit={this.state.isLeaderUnit}
                        mainDepartmentId={
                          this.props.auth && this.props.auth.mainDepartmentId
                        }
                        // Props lưu tủ tài liệu
                        archives={cabinetCategories}
                        saveTo={this.state.saveTo}
                        onSelectSaveTo={this.handleSelectSaveTo}
                        defaultType={this.props.auth.defaultCabinetType}
                        listCabinetOfDocument={this.props.listCabinetOfDocument}
                        onShowSaveDocument={this.handleClickShowSaveDocument}
                        isLoadingSaveDocument={this.state.isLoadingSaveDocument}
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'LuuTuTaiLieu' && (
                      <SaveDocument
                        auth={this.props.auth}
                        work={this.state.personalWork}
                        file={fileThamKhao}
                        storage={this.props.archives.storage}
                        onClickGoBack={this.handleClickGoBack}
                        archives={cabinetCategories}
                        saveTo={this.state.saveTo}
                        onSelectSaveTo={this.handleSelectSaveTo}
                        onSubmit={this.handleSaveArchive}
                        defaultType={this.props.auth.defaultCabinetType}
                        listCabinetOfDocument={this.props.listCabinetOfDocument}
                        filesThamKhao={this.state.filesThamKhao}
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'GuiEmail' && (
                      <SendMail
                        work={this.state.personalWork}
                        onClickGoBack={this.handleClickGoBack}
                        onSubmit={this.handleSubmit}
                        action={this.props.actions}
                        isShowMobile={this.state.showSendEmail}
                        closeModulesDetail={this.closeModulesDetail}
                        auth={this.props.auth}
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'BaoCao' && (
                      <Report
                        onClickGoBack={this.handleClickGoBack}
                        onSubmit={this.handleSubmit}
                        actions={this.props.actions}
                        work={this.state.personalWork}
                        reportWorkPersonal={
                          this.state.reportWorkPersonal || null
                        }
                      />
                    )}

                  {/*this.state.showResults && this.state.showAssignmentInformation && this.state.showSection === 'ThongTinGiaoViec' &&
                    <AssignmentInformation
                      isShowMobile={this.state.showAssignmentInformation}
                      closeModulesDetail={this.closeModulesDetail}
                      assignmentInformation={this.state.personalWork}
                      onHandleSubmit={this.handleSubmit}
                      type="personal"
                      isLeaderUnit={this.state.isLeaderUnit}
                    />
                  */}

                  {this.state.showResults &&
                    this.state.showSection === 'XemThucHien' && (
                      <div>
                        {this.state.isHasReceiveDocumentId === true &&
                          !isNewAssignDoc && (
                            <ExecutingView
                              onClickGoBack={this.handleClickGoBack}
                              onClickDepartment={
                                this.handleClickDepartmentExecutingView
                              }
                              executingViews={
                                this.props.executingViews.items || []
                              }
                              auth={this.props.auth}
                              actions={this.props.actions}
                            />
                          )}
                        {this.state.isHasReceiveDocumentId === false &&
                          !isNewAssignDoc && (
                            <ExecutingViewInternal
                              onClickGoBack={this.handleClickGoBack}
                              executingViews={
                                this.props.executingViewInternal
                                  .items_internalDocument || []
                              }
                              auth={this.props.auth}
                              actions={this.props.actions}
                            />
                          )}
                        {isNewAssignDoc && (
                          <ExecutingView
                            onClickGoBack={this.handleClickGoBack}
                            executingViews={
                              this.state.executingViewAssign || []
                            }
                            auth={this.props.auth}
                            actions={this.props.actions}
                            isNewAssignDoc={true}
                          />
                        )}
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'AlertMistake' && (
                      <div>
                        <AlertMistake
                          isLoading={this.state.isLoading}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSendMistake}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'ThongTinChiDao' && (
                      <InformationSteering
                        isShowMobile={true}
                        onClickGoBack={this.handleClickGoBack}
                        closeModulesDetail={this.closeModulesDetail}
                        informationSteering={
                          this.state.informationSteeringPersonal
                        }
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'ThongTinGiaoViec' && (
                      <AssignmentInformation
                        assignmentInformation={this.state.infoAssign}
                        type="personal"
                        isShowMobile={true}
                        auth={this.props.auth}
                        isLeaderUnit={this.state.isLeaderUnit}
                        onClickGoBack={this.handleClickGoBack}
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'LuongCongVan' && (
                      <div className="box-action-item">
                        {!this.state.isCheck && (
                          <Flowdocumentary
                            auth={this.props.auth}
                            onClickGoBack={this.handleClickGoBack}
                            flowDocument={
                              this.props.executingViews
                                .flowDocument_ReceiveDocument
                            }
                            onClickDepartment={
                              this.handleClickMoreDepartmentExecutingView
                            }
                            actions={this.props.actions}
                          />
                        )}
                        {this.state.isCheck && (
                          <FlowSendDocumentary
                            auth={this.props.auth}
                            onClickGoBack={this.handleClickGoBack}
                            flowDocument={
                              this.props.executingViews.flowSendDocument
                            }
                            onClickDepartment={
                              this.handleClickMoreDepartmentExecutingView
                            }
                            flowReceiveDocument={
                              this.props.executingViews.flowDocuments
                            }
                            actions={this.props.actions}
                          />
                        )}
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'LayYKien' && (
                      <LayYKien
                        onClickGoBack={this.handleClickGoBack}
                        onSubmit={this.handleSubmit}
                        actions={this.props.actions}
                        auth={this.props.auth}
                        ctCongViec={this.state.personalWork}
                      />
                    )}
                  {this.state.showResults &&
                    this.state.showSection === 'LuongCongVanNoiBo' && (
                      <div className="box-action-item">
                        <FlowInternalDocumentary
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          flowDocument={this.props.luongCongVanNoiBo}
                          flowReceiveDocument={
                            this.props.executingViews.flowDocuments
                          }
                          actions={this.props.actions}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  personalWorks: {
    ...state.personalWorks,
    item: state.personalWorks.detailId
      ? state.entities.personalWorks[state.personalWorks.detailId]
      : {},
    informationSteering: state.receiveDocuments.item,
  },

  auth: {
    ...state.auth,
    user: state.auth,
  },

  archives: {
    ...state.documentsCabinet,
    categories: state.documentsCabinet.categories.map(
      id => state.entities.documentCabinetCategories[id]
    ),
    type: {
      unit: state.documentsCabinet.type.unit.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      department: state.documentsCabinet.type.department.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      personal: state.documentsCabinet.type.personal.map(
        id => state.entities.documentCabinetCategories[id]
      ),
      profile: state.documentsCabinet.type.profile.map(
        id => state.entities.documentCabinetCategories[id]
      ),
    },
    storage: {
      ...state.documentsCabinet.storage,
      used: Tool.Utils.getFileSize(state.documentsCabinet.storage.used),
    },
  },

  // TODO: Thay Thế: Sử dụng field dsNguoiDaBaoCao để kiểm tra user đã báo cáo chưa
  // reportWork: {
  //   ...state.reportWork,
  //   items: state.reportWork.items.map((id) => state.entities.reportWork[id])
  // },

  executingViews: {
    ...state.executingViews,
    items:
      state.executingViews.items &&
      state.executingViews.items.map(id => {
        let data = state.entities.executingViews[id]
        const getDSDonVi = dsDonViTrucThuocResponse => {
          return dsDonViTrucThuocResponse.map(item => {
            let subItem = state.entities.executingViews[item.chiDaoId]

            return {
              ...item,
              executingViews: [
                subItem
                  ? {
                      ...subItem,
                      dsDonViTrucThuocResponse: getDSDonVi(
                        subItem.dsDonViTrucThuocResponse
                      ),
                    }
                  : undefined,
              ],
            }
          })
        }

        return {
          ...data,
          dsDonViTrucThuocResponse: getDSDonVi(data.dsDonViTrucThuocResponse),
        }
      }),

    flowDocuments: Object.keys(state.executingViews.flowDocuments).map(key => {
      const item = state.executingViews.flowDocuments[key]
      return {
        ...item,
        nguoiTaoCongVan: state.entities.users[item.nguoiTaoCongVan],
        chiDao: {
          ...state.entities.executingViews[item.chiDao],
          dsDonViTrucThuocResponse: (() => {
            if (!state.entities.executingViews[item.chiDao]) {
              return []
            }
            const getDSDonVi = dsDonViTrucThuocResponse => {
              return dsDonViTrucThuocResponse.map(item => {
                let subItem = state.entities.executingViews[item.chiDaoId]
                let flowDocument =
                  state.executingViews.flowDocuments[item.chiDaoId]
                return {
                  ...item,
                  executingViews: [
                    subItem
                      ? {
                          ...subItem,
                          dsDonViTrucThuocResponse: getDSDonVi(
                            subItem.dsDonViTrucThuocResponse
                          ),
                        }
                      : undefined,
                  ],
                  flowDocument: {
                    ...flowDocument,
                    nguoiTaoCongVan: flowDocument
                      ? state.entities.users[flowDocument.nguoiTaoCongVan]
                      : undefined,
                  },
                }
              })
            }

            return getDSDonVi(
              state.entities.executingViews[item.chiDao]
                .dsDonViTrucThuocResponse
            )
          })(),
        },
      }
    }),

    flowDocument_ReceiveDocument: {
      ...state.executingViews.flowDocument_ReceiveDocument,
      nguoiTaoCongVan:
        state.entities.users[
          state.executingViews.flowDocument_ReceiveDocument.nguoiTaoCongVan
        ],
      chiDao: {
        ...state.entities.executingViews[
          state.executingViews.flowDocument_ReceiveDocument.chiDao
        ],
        dsDonViTrucThuocResponse: (() => {
          if (
            !state.entities.executingViews[
              state.executingViews.flowDocument_ReceiveDocument.chiDao
            ]
          ) {
            return []
          }
          const getDSDonVi = dsDonViTrucThuocResponse => {
            return dsDonViTrucThuocResponse.map(item => {
              let subItem = state.entities.executingViews[item.chiDaoId]
              let flowDocument =
                state.executingViews.flowDocuments[item.chiDaoId]
              return {
                ...item,
                executingViews: [
                  subItem
                    ? {
                        ...subItem,
                        dsDonViTrucThuocResponse: getDSDonVi(
                          subItem.dsDonViTrucThuocResponse
                        ),
                      }
                    : undefined,
                ],
                flowDocument: {
                  ...flowDocument,
                  nguoiTaoCongVan: flowDocument
                    ? state.entities.users[flowDocument.nguoiTaoCongVan]
                    : undefined,
                },
              }
            })
          }

          return getDSDonVi(
            state.entities.executingViews[
              state.executingViews.flowDocument_ReceiveDocument.chiDao
            ].dsDonViTrucThuocResponse
          )
        })(),
      },
    },
  },

  executingViewInternal: {
    ...state.executingViews,
    items_internalDocument:
      state.executingViews.items_internalDocument !== undefined &&
      state.executingViews.items_internalDocument.map(
        id => state.entities.executingViews[id]
      ),
  },

  staffs: state.common.staffs,

  listCabinetOfDocument: state.common.listCabinetOfDocument.map(
    item => item.id
  ),

  departments: state.common.departments,

  readOnly: state.common.enableReadOnly,

  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  luongCongVanNoiBo: state.detailInternalDocuments.luongCongVanNoiBo,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailPersonalWorkPage)
