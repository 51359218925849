import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Intent, Button, Tooltip, Position } from '@blueprintjs/core'

const ActionFixed = ({ isLoading, isDisabled, onClose, onSubmit }) => {
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      if (scrollTop > 50) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={classNames('comp-action-fixed', { fixed: isFixed })}>
      <Tooltip
        tooltipClassName={'comp-action-fixed-tooltip'}
        content={'Quay lại'}
        position={Position.LEFT}
        intent={Intent.NONE}
      >
        <Button disabled={isLoading} onClick={onClose}>
          <span className="icon-back"></span>
        </Button>
      </Tooltip>
      <Tooltip
        isDisabled={isDisabled}
        tooltipClassName={'comp-action-fixed-tooltip'}
        content={'Lưu'}
        position={Position.LEFT}
        intent={Intent.NONE}
      >
        <Button
          className="blue"
          disabled={isDisabled}
          loading={isLoading}
          onClick={onSubmit}
        >
          <span className="icon-save"></span>
        </Button>
      </Tooltip>
    </div>
  )
}

ActionFixed.defaultProps = {
  isLoading: false,
  isDisabled: false,
  onClose: () => {},
  onSubmit: () => {},
}

ActionFixed.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default memo(ActionFixed)
