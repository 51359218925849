// trang thai phieu goi
export const LDPB_CHUA_DUYET = 0
export const KHONG_DUYET = 1
export const LDDV_CHUA_DUYET = 2
export const THU_KY_CHUA_DUYET = 4
export const DANG_GOP_Y = 3
export const DA_HOAN_THANH = 9

// trang thai phieu nhan
export const LDDV_CHUA_GIAO = 0
export const LDPB_CHUA_GIAO = 1
export const CHUA_GOP_Y = 2
export const LDPB_CHUA_XAC_NHAN = 3
export const LDPB_TU_CHOI_XAC_NHAN = 31
export const LDDV_CHUA_XAC_NHAN = 4
export const LDDV_TU_CHOI_XAC_NHAN = 41
export const TU_CHOI_XAC_NHAN = 8
export const DA_GOP_Y = 9

//Loai phieu
export const PHIEU_GOI_PHONG_BAN = 1
export const PHIEU_GOI_DON_VI = 2

//trang thai y kien
export const STATUS_LAYYKIEN_CHUTRI_CHUACO_YKIEN = 0
export const STATUS_LAYYKIEN_CHUTRI_THONGNHAT = 2
export const STATUS_LAYYKIEN_CHUTRI_KHONG_THONGNHAT = 1
export const STATUS_LAYYKIEN_CHUTRI_KHONG_YKIEN = 3

//trang thai duyet gop y
export const LAYYKIEN_DONGY = 2
export const LAYYKIEN_KHONG_DONGY = 1

// trạng thái đơn vị liên thông
export const DONVI_LIENTHONG_STATUS = {
  0: 'Đã gửi',
  1: 'Đã đến',
  3: 'Đã tiếp nhận',
  4: 'Đã phân công',
  5: 'Đang xử lý',
  6: 'Hoàn thành',
  99: 'Gửi thất bại',
}
