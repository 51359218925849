import { Authority } from '../constants/ActionTypes'
import _ from 'lodash'

const initialState = {
  list: [],
  groupList: [],
  title: '',
}

const ProcedureAuthorityReducer = (state = initialState, action) => {
  const result = _.get(action, 'payload.data.result', null)
  switch (action.type) {
    case Authority.COLLECTION:
      return {
        ...state,
        list: result.items || [],
      }
    case Authority.ITEM:
      return {
        ...state,
        groupList: result.dsNhom || [],
        title: result.tenQuyTrinh || '',
      }
    case Authority.CREATE_GROUP:
      return {
        ...state,
        groupList: [
          ...state.groupList,
          {
            nhomId: result,
            dsThanhVien: null,
            stt: 0,
          },
        ],
      }
    case Authority.DELETE_GROUP:
      const deleteId = _.get(action, 'meta.previousAction.id', null)
      return {
        ...state,
        groupList: state.groupList.filter(item => item.nhomId !== deleteId),
      }
    case Authority.REMOVE_MEMBER:
      const groupId = _.get(action, 'meta.previousAction.groupId', null)
      const memberId = _.get(action, 'meta.previousAction.memberId', null)
      return {
        ...state,
        groupList: state.groupList.map(group =>
          group.nhomId === groupId
            ? {
                ...group,
                dsThanhVien: group.dsThanhVien.filter(
                  item => item.id !== memberId
                ),
              }
            : group
        ),
      }
    default:
      return state
  }
}

export default ProcedureAuthorityReducer
