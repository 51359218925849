import { schema } from 'normalizr'

const DepartmentWorkAssigned = new schema.Entity(
  'departmentWorksAssigned',
  {},
  {
    idAttribute: departmentWorkAssigned =>
      departmentWorkAssigned.phieuGiaoViecId,
  }
)

export default DepartmentWorkAssigned
