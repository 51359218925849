import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Intent } from '@blueprintjs/core'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import { MoveDrawer } from '../../components/DocumentCabinet'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import classnames from 'classnames'

class MoveDrawerDocumentCabinet extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    let type
    switch (this.props.match.params.type) {
      case 'ca-nhan':
        type = Types.CABINET_PERSONAL
        break
      case 'phong-ban':
        type = Types.CABINET_DEPARTMENT
        break
      default:
        type = Types.CABINET_UNIT
    }

    this.state = {
      ids: this.props.location.state.listCheckIds || [],
      type,
      defaultType: type,
      isRedirect: false,
      redirectTo: '/tu-tai-lieu/danh-sach',
      isLoading: false,
    }

    this.handleChangeType = this.handleChangeType.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount() {
    this.updateCategories()
  }

  handleGoBack() {
    if (this.state.isLoading) {
      this.props.actions.refreshPage(true)
    }
    this.props.history.push({
      pathname: this.state.redirectTo,
      state: {
        type: this.state.type,
      },
    })
  }

  updateCategories() {
    switch (this.state.type) {
      case Types.CABINET_PERSONAL:
        this.props.actions.documentsCabinetGetListCategoriesPersonal()
        break

      case Types.CABINET_DEPARTMENT:
        this.props.actions.documentsCabinetGetListCategoriesDepartment()
        break

      default:
        this.props.actions.documentsCabinetGetListCategoriesUnit()
    }
  }

  handleChangeType(type) {
    this.setState({ type }, () => {
      this.updateCategories()
    })
  }

  handleSubmit(data) {
    this.setState({ isLoading: true }, () => {
      if (!data.loai_tu_tai_lieu) {
        this.setState({ isLoading: false })
        return this.props.actions.commonAddToasterMessage({
          message: 'Vui lòng chọn loại ngăn tủ',
          intent: Intent.WARNING,
        })
      }

      if (!data.tu_tai_lieu_src_id) {
        this.setState({ isLoading: false })
        return this.props.actions.commonAddToasterMessage({
          message: 'Vui lòng chọn ngăn tủ để di chuyển',
          intent: Intent.WARNING,
        })
      }

      if (!data.tu_tai_lieu_des_id) {
        data.tu_tai_lieu_des_id = null
      }

      if (data && data.loai_tu_tai_lieu && data.tu_tai_lieu_src_id) {
        this.props.actions
          .postMoveDrawer(data)
          .then(res => {
            if (res.error) {
              this.setState({ isLoading: false })
              return this.props.actions.commonAddToasterMessage({
                message: 'Di chuyển tài liệu không thành công',
                intent: Intent.DANGER,
              })
            }
            return this.props.actions.commonAddToasterMessage({
              message: 'Di chuyển tài liệu thành công',
              intent: Intent.SUCCESS,
            })
          })
          .then(this.handleGoBack)
      }
    })
  }

  render() {
    let { type, defaultType } = this.state
    let { items, categories } = this.props.documentsCabinet

    if (items) {
      items.map(item => {
        let fileNoiDung = item.fileNoiDung || {}
        item.fileNoiDung.kichThuocStr = Tool.Utils.getFileSize(
          fileNoiDung.kichThuoc
        )
        return item
      })
    }
    return (
      <MasterLayout typeSidebar="documentsCabinet">
        <div className="detail-receive-document page-DetailDocumentsCabinet">
          <HeadingPage namePage="Tủ tài liệu" iconPage="icon-Tu_Tai_Lieu" />
          <div className="detail-container">
            <div className="tabs-detail-page">
              <div className="pt-tabs tab-cus-container pd-none">
                <ul className="pt-tab-list" role="tablist">
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_UNIT,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_UNIT}
                  >
                    TTL ĐƠN VỊ
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_DEPARTMENT,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_DEPARTMENT}
                  >
                    TTL PHÒNG BAN
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_PERSONAL,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_PERSONAL}
                  >
                    TTL CÁ NHÂN
                  </li>
                </ul>
              </div>
            </div>
            <MoveDrawer
              auth={this.props.auth}
              goBack={this.handleGoBack}
              type={type}
              items={items}
              categories={categories}
              onChangeType={this.handleChangeType}
              onSubmit={this.handleSubmit}
              defaultType={defaultType}
              drawerId={
                this.props.location && this.props.location.state
                  ? this.props.location.state.data
                  : {}
              }
              isLoading={this.state.isLoading}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  documentsCabinet: {
    ...state.documentsCabinet,
    categories: state.documentsCabinet.categories.map(
      id => state.entities.documentCabinetCategories[id]
    ),
    items: state.documentsCabinet.items.map(
      id => state.entities.documentsCabinet[id]
    ),
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveDrawerDocumentCabinet)
