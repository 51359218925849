import React, { memo, useEffect, useRef, useState, useCallback } from 'react'
import { Checkbox, Button } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import { MasterLayout } from 'components/layout'
import Title from 'components/title'
import { Icon } from 'components/newCommon'
import { Input, ColorSelect } from 'components/newCommon2'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import { postCreateTag, putEditTag, getTag } from 'actions/task'

const TaskTagsCreate = ({ history, match }) => {
  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const { action, id } = match.params
  const isUpdate = action === 'chinh-sua'
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    backgroundColor: '#f1f1f1',
  })
  const [validateErrors, setValidateErrors] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)

  const _changeValidate = (name, value) => {
    _mountedSet(setValidateErrors, {
      ...validateErrors,
      [name]: value,
    })
  }

  const changeFormValue = name => value => {
    _mountedSet(setIsDisabledButtonDefault, false)
    _changeValidate(name)
    _mountedSet(setFormData, { ...formData, [name]: value })
  }

  const changeCheckbox = e => {
    _mountedSet(setIsDisabledButtonDefault, false)
    const { checked } = e.target
    _mountedSet(setFormData, { ...formData, isActive: checked })
  }

  const _validateName = () => {
    if (!get(formData, 'name', '').trim()) {
      _changeValidate('name', true)
      throw new Error('warning')
    }
  }

  const submitCreate = async () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setSubmitLoading, true)
        _validateName()
      },
      action: () => postCreateTag(toDecamelizeKeys(formData)),
      afterResponse: () => {
        return history.push('/quan-ly-cong-viec/tags')
      },
      afterAction: () => {
        _mountedSet(setSubmitLoading, false)
        _mountedSet(setIsDisabledButtonDefault, true)
      },
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.CREATE_TAGS),
      errorMessage: Toast.FAIL(Action.CREATE_TAGS),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const submitUpdate = async () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setSubmitLoading, true)
        _validateName()
      },
      action: () => putEditTag(toDecamelizeKeys(formData)),
      afterResponse: () => {
        return history.push('/quan-ly-cong-viec/tags')
      },
      afterAction: () => {
        _mountedSet(setSubmitLoading, false)
        _mountedSet(setIsDisabledButtonDefault, true)
      },
      successCode: 200,
      successMessage: Toast.SUCCESS(Action.UPDATE_TAGS),
      errorMessage: Toast.FAIL(Action.UPDATE_TAGS),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const fetchDetail = useCallback(() => {
    if (!isUpdate) {
      return
    }

    requestAction({
      showToast: false,
      beforeAction: () => _mountedSet(setFetching, true),
      action: () => getTag(id),
      afterResponse: result => _mountedSet(setFormData, result),
      afterAction: () => _mountedSet(setFetching, false),
    })
  }, [isUpdate, id])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  const disableAction = fetching || submitLoading

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className={'cpc-form mt10'}>
        <Title
          name={isUpdate ? 'Chỉnh sửa loại công viêc' : 'Tạo loại công việc'}
          icon="icon icon2-tag"
        />
        <main className="bg-white mt10 p15">
          <Input
            disabled={disableAction}
            label="Tên loại công việc"
            maxLength={500}
            onChange={changeFormValue('name')}
            placeholder="Nhập tên loại công việc"
            required
            value={get(formData, 'name', '')}
            wrapperClassName="mb10"
            warning={get(validateErrors, `name`)}
          />
          <div className="d-flex align-items-center flex-wrap gap-20">
            <div className="flex-grow-1">
              <ColorSelect
                inputIconClassName="icon2-color-blob"
                disabled={disableAction}
                onChange={changeFormValue('backgroundColor')}
                value={get(formData, 'backgroundColor')}
                sampleText={get(formData, 'name')}
              />
            </div>
            <div className="flex-grow-1">
              <Checkbox
                className={'mb0'}
                checked={get(formData, 'isActive', false)}
                disabled={disableAction}
                onChange={changeCheckbox}
              >
                <span className="font-size-13">Sử dụng</span>
              </Checkbox>
            </div>
          </div>
        </main>
        <div className="d-flex align-items-center justify-content-center gap-15 bg-white border-top ph15">
          <Button
            className={
              'cpc-button white-border-gray uppercase font-size-13 ph10 min-width-100'
            }
            disabled={disableAction}
            onClick={() => history.push('/quan-ly-cong-viec/tags')}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={
              'cpc-button blue uppercase font-size-13 ph10 min-width-80'
            }
            loading={submitLoading}
            disabled={disableAction || isDisabledButtonDefault}
            onClick={isUpdate ? submitUpdate : submitCreate}
          >
            Lưu
            <Icon classIcon="icon-save" className="ml5" />
          </Button>
        </div>
      </div>
    </MasterLayout>
  )
}

export default memo(withRouter(TaskTagsCreate))
