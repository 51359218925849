import React, { memo, useState } from 'react'
import { Classes, Button, Intent, Radio, RadioGroup } from '@blueprintjs/core'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { find } from 'lodash'
import csx from 'classnames'

import { Dialog, Loading, Empty } from 'components/newCommon'
import { getDsMau, deleteMau } from 'actions/task'
import requestAction from 'helpers/request'
import Delete from './delete'
import { Toast, Action } from 'constants/MessageForm'

const Template = ({ isOpen, onClose, onConfirm }) => {
  const [templates, setTemplates] = useState([])
  const [selected, setSelected] = useState()
  const [deleteId, setDeleteId] = useState()

  const [fetching, setFetching] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [isEmpty, setIsEmpty] = useState(false)
  const [firstLoading, setFirstLoading] = useState(true)

  const fetchTemplates = () => {
    requestAction({
      showToast: false,
      beforeAction: () => setFetching(true),
      action: () => getDsMau({ is_active: true, page: currentPage }),
      afterResponse: ({ items = [], totalCount = 0 }) => {
        setTemplates(prev => [...prev, ...items])
        setHasNextPage(currentPage * 10 < totalCount)
        setCurrentPage(prev => prev + 1)
        setIsEmpty(!totalCount)
        setFirstLoading(false)
      },
      afterAction: () => setFetching(false),
    })
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: fetching,
    hasNextPage: isOpen && hasNextPage,
    onLoadMore: fetchTemplates,
  })

  const _onConfirm = () => {
    const template = find(templates, { id: selected })
    onConfirm(template)
  }

  const onDeleteTemplate = () => {
    requestAction({
      action: () => deleteMau(deleteId),
      afterResponse: () => {
        setCurrentPage(1)
        setTemplates([])
        setIsEmpty(false)
        setFirstLoading(true)
        setHasNextPage(true)
      },
      successMessage: Toast.SUCCESS(Action.DELETE),
      warningMessage: Toast.SUCCESS(Action.DELETE),
    })
  }

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title={'Chọn từ mẫu có sẵn'}
        onClose={onClose}
        onCancel={onClose}
        onConfirm={_onConfirm}
        width={600}
        contentMaxHeight={300}
        cancelLeftIcon={<i className="icon-back mr5" />}
        cancelText="Đóng"
        confirmDisabled={!selected}
        confirmRightIcon={<i className="icon-save ml5" />}
        confirmText="Lưu"
      >
        <div ref={rootRef}>
          <RadioGroup
            onChange={({ currentTarget: { value } }) => setSelected(value)}
            selectedValue={selected}
            className="cpc-radio-group"
          >
            {firstLoading && <Loading />}
            {isEmpty && <Empty />}
            {templates.map(({ id, title }) => (
              <Radio className="cpc-radio" value={id} key={id}>
                <div className="d-flex align-item-center w-100">
                  <span className="flex-auto font-size-13">{title}</span>
                  <Button
                    className={csx(Classes.MINIMAL, 'cpc-button')}
                    intent={Intent.DANGER}
                    iconName="trash"
                    onClick={e => {
                      e.stopPropagation()
                      setDeleteId(id)
                    }}
                  />
                </div>
              </Radio>
            ))}
            {hasNextPage && <span ref={sentryRef} />}
          </RadioGroup>
        </div>
      </Dialog>
      <Delete
        isOpen={!!deleteId}
        onClose={() => setDeleteId()}
        onConfirm={() => onDeleteTemplate()}
      />
    </>
  )
}

export default memo(Template)
