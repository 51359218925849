import React, { memo, useCallback, useMemo } from 'react'
import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core'
import { get } from 'lodash'
import { createPortal } from 'react-dom'
import { withRouter } from 'react-router'

import Tags from './Tags'

const TaskContextMenu = ({
  isEditGiveTask = false,
  hasGiveTaskAction = false,
  hasSaveDocument = false,
  data: recordData = {},
  history,
  isOpen = false,
  match,
  hideMenu = () => {},
  position: { top = 0, left = 0 } = {},
}) => {
  const {
    type: taskType,
    id: currentId,
    action: taskAction = '',
  } = match.params
  const mainPathname = useMemo(
    () => `/quan-ly-cong-viec/${taskType}`,
    [taskType]
  )

  const goToAction = useCallback(
    menuAction => () => {
      hideMenu()
      const { id: recordId } = recordData
      const url = `${mainPathname}/${menuAction}/${recordId}`

      if (!taskAction) return history.push(url)
      if (!(currentId === recordId && menuAction === taskAction))
        history.replace(url)
    },
    [hideMenu, currentId, recordData, taskAction, history, mainPathname]
  )

  if (!isOpen) {
    return null
  }

  return createPortal(
    <div id="context-menu-portal">
      <div
        style={{ position: 'absolute', top, left }}
        onClick={e => e.stopPropagation()}
      >
        <Menu className="cpc-context-menu">
          <MenuItem
            text={
              <span className="cpc-context-menu-item">
                <i className="icon-eye size-icon-10" /> Xem chi tiết
              </span>
            }
            onClick={goToAction('chi-tiet')}
          />
          {hasGiveTaskAction && (
            <>
              <MenuDivider />
              <MenuItem
                text={
                  <span className="cpc-context-menu-item">
                    <i className="icon-give-task size-icon-15" />{' '}
                    {isEditGiveTask ? 'Sửa giao việc' : 'Giao việc'}
                  </span>
                }
                onClick={goToAction(
                  isEditGiveTask
                    ? recordData?.workType === 'DOFFICE'
                      ? 'sua-giao-viec-d-office'
                      : 'sua-giao-viec'
                    : recordData?.workType === 'DOFFICE'
                    ? 'giao-viec-d-office'
                    : 'giao-viec'
                )}
              />
            </>
          )}
          <MenuDivider />
          <MenuItem
            text={
              <span className="cpc-context-menu-item">
                <i className="icon2-bell size-icon-15" /> Nhắc việc
              </span>
            }
            onClick={goToAction('nhac-viec')}
          />
          <MenuDivider />
          <MenuItem
            text={
              <span className="cpc-context-menu-item">
                <i className="icon2-tag size-icon-15" /> Loại công việc (tags)
              </span>
            }
          >
            <Tags
              goToAction={goToAction}
              taskId={get(recordData, 'id')}
              defaultSelected={recordData?.workItemTag?.map(tag => tag.id)}
              hideMenu={hideMenu}
            />
          </MenuItem>
          {hasSaveDocument && (
            <>
              <MenuDivider />
              <MenuItem
                text={
                  <span className="cpc-context-menu-item">
                    <i className="icon-Tu_Tai_Lieu size-icon-13" />
                    Lưu tủ tài liệu
                  </span>
                }
                onClick={goToAction('luu-tu-tai-lieu')}
              />
            </>
          )}
        </Menu>
      </div>
    </div>,
    document.querySelector('body')
  )
}

export default memo(withRouter(TaskContextMenu))
