import React, { Component } from 'react'
import { Dialog, Intent } from '@blueprintjs/core'
import moment from 'moment'
import { LoadingLine, EmptyDataTable } from '../../../components/common'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchTemplate,
  deleteTemplate,
} from '../../../actions/signatureDocument'
import { get } from 'lodash'
import { Action, Toast } from '../../../constants/MessageForm'

class ApplyDraftDialog extends Component {
  constructor() {
    super()
    this.state = {
      templateList: [],
      isLoading: true,
    }
  }

  fetchTemplate = async () => {
    const { fetchAllTemplate, procedureId } = this.props
    await this.setState({ isLoading: true })
    fetchAllTemplate(procedureId)
      .then(res => {
        const templateList = get(res, 'payload.data.result.items', []).map(
          i => ({
            id: i.id,
            name: i.tenTemplate,
            createDate: i.ngayLuu,
          })
        )
        this.setState({ templateList })
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteTemplate = id => {
    const { deleteTemplate, showToast } = this.props
    deleteTemplate(id)
      .then(res => {
        const status = !!get(res, 'payload.data.result', false)
        showToast({
          message: status
            ? Toast.SUCCESS(Action.DELETE)
            : Toast.FAIL(Action.DELETE),
          intent: status ? Intent.SUCCESS : Intent.DANGER,
        })
      })
      .finally(() => this.fetchTemplate())
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    if (prevProps.isOpen !== isOpen && isOpen) {
      this.fetchTemplate()
    }
  }

  render() {
    const { isOpen, onClose, onClick } = this.props
    const { templateList, isLoading } = this.state
    return (
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        title="Tải từ mẫu có sẵn"
        className="apply-draft-dialog"
      >
        {isLoading ? (
          <div style={{ width: '300px' }}>
            <LoadingLine />
          </div>
        ) : !templateList.length ? (
          <div style={{ width: '300px' }}>
            <EmptyDataTable className="p10" />
          </div>
        ) : (
          <main>
            <table>
              <thead>
                <tr>
                  <th>STT</th>
                  <th>TÊN MẪU</th>
                  <th>NGÀY LƯU</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {templateList.map((item, index) => (
                  <tr key={index}>
                    <td>{index < 10 ? `0${index + 1}` : index + 1}</td>
                    <td onClick={() => onClick(item.id)}>{item.name}</td>
                    <td>
                      {moment(item.createDate).format('DD/MM/YYYY HH:mm')}
                    </td>
                    <td>
                      <i
                        className="icon-trash"
                        onClick={() => this.deleteTemplate(item.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        )}
      </Dialog>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAllTemplate: bindActionCreators(fetchTemplate, dispatch),
  deleteTemplate: bindActionCreators(deleteTemplate, dispatch),
})

export default connect(null, mapDispatchToProps)(ApplyDraftDialog)
