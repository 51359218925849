import React, { memo } from 'react'
import { connect } from 'react-redux'
import csx from 'classnames'
import { Utils } from 'helpers'
import { Icon } from 'components/newCommon/Icon'

const File = ({
  file = {},
  className = '',
  onClose = () => {},
  onClick = () => {},
}) => {
  const { name, size } = file

  const getFileExtension = fileName => {
    if (!fileName) {
      return null
    }

    const fileExtension = fileName.split('.').pop()
    return fileExtension
  }

  return (
    <span className="align-center">
      <div
        style={{
          backgroundImage: `url(${Utils.typeFile(getFileExtension(name))})`,
        }}
        className={csx(
          'd-flex',
          'd-flex-wrap',
          'align-center',
          'link-file',
          'font-size-13',
          'small-file-icon',
          'cursor-pointer',
          className
        )}
        onClick={() => onClick(file)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div>{name}</div>
        {size > 0 && <span className="ml5">- {Utils.getFileSize(size)}</span>}
      </div>
      <Icon
        classIcon="icon-close"
        className="has-event ml10"
        style={{ color: '#999999', fontSize: '14px' }}
        onClick={() => onClose(file)}
      />
    </span>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(memo(File))
