import React from 'react'
import { MasterLayout } from '../../components/layout'
import PropTypes from 'prop-types'
import * as Actions from '../../actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import * as Types from '../../constants/Api'
import { LoadingItem } from '../../components/common/'

class DetailNotificationPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
  }

  state = {
    notificationId: null,
    notication: null,
    isLoadingData: true,
  }
  static propTypes = {
    notifications: PropTypes.object,
  }

  componentWillMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      this.setState({ notificationId: this.props.match.params.id })
    }
  }

  componentDidMount() {
    if (this.state.notificationId) {
      this.props.actions
        .notificationFetchItem(this.state.notificationId)
        .then(() => {
          if (this.props.notifications && this.props.notifications.item) {
            this.setState(
              { notification: this.props.notifications.item },
              () => {
                this.setState({ isLoadingData: false })
              }
            )
          } else {
            this.setState({ isLoadingData: false })
          }
        })
        .catch(e => {
          this.setState({ isLoadingData: false })
        })
    }
  }

  handleClickGoBack = () => {
    this.props.history && this.props.history.goBack()
  }

  render() {
    const { notification } = this.state
    return (
      <MasterLayout typeSidebar="HomePage">
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-12 col-md-8">
            {/* Heading */}
            <div className="heading-page">
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <h3 className="text-heading-page heading-inline">
                    <span className="icon-notification icon-heading"></span>
                    <span className="text-heading">Thông báo</span>
                  </h3>
                  <div className="pull-right">
                    <button
                      className="pt-button pt-button-back"
                      type="button"
                      onClick={this.handleClickGoBack}
                      disabled={this.state.isLoadingData === true}
                    >
                      <span className="pt-icon pt-icon-chevron-left pt-align-light"></span>
                      <span className="text-content">Quay lại</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {this.state.isLoadingData === true ? (
              <div className="row-detail-container clearfix loading-line-detail">
                <div className="col-md-12 col-xs-12">
                  <div className="loading-container">
                    <LoadingItem />
                    <LoadingItem />
                    <LoadingItem />
                    <LoadingItem />
                  </div>
                </div>
              </div>
            ) : (
              <div className="container-detail-notification ql-snow">
                <h1 className="title">{notification && notification.tieuDe}</h1>
                <div className="info-post">
                  <span className="box-user pull-left">
                    <span
                      className="avatar"
                      style={{
                        backgroundImage: `url(${
                          notification && notification.anhDaiDien
                            ? Types.IMAGE_URL + notification.anhDaiDien
                            : '/images/default_avatar.png'
                        })`,
                      }}
                    ></span>
                    <span className="name_user">
                      {notification && notification.nguoiTao}
                    </span>
                  </span>
                  <span className="date-update pull-right">
                    <span className="date">
                      {notification &&
                        notification.ngayTao &&
                        moment(notification.ngayTao).format('DD/MM/YYYY')}
                    </span>
                    <span className="time">
                      {notification &&
                        notification.ngayTao &&
                        moment(notification.ngayTao).format('HH:mm')}
                    </span>
                  </span>
                  <div className="clearfix"></div>
                </div>
                {notification && (
                  <div
                    className="content-wrapper-news ql-editor"
                    dangerouslySetInnerHTML={{ __html: notification.noiDung }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  notifications: {
    ...state.notifications,
    item: state.entities.notifications[state.notifications.detailId],
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailNotificationPage)
