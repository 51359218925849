import { DOCUMENT_AUTHORITY_FETCH } from '../constants/ActionTypes'

const initialState = {
  item: null,
}

export default function authorityDocument(state = initialState, action) {
  switch (action.type) {
    case DOCUMENT_AUTHORITY_FETCH:
      return {
        ...state,
        item: action.payload.data.result,
      }

    default:
      return state
  }
}
