import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Paginate } from '../../../components/common'

class ListFooter extends Component {
  static propTypes = {
    initialPage: PropTypes.number,
    pageCount: PropTypes.number,
    pageRange: PropTypes.number,
    marginPage: PropTypes.number,
    onPageClick: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      initialPage: props.initialPage || 0,
      forcePage: props.forcePage || 0,
      pageCount: props.pageCount || 1,
      pageRange: props.pageRange || 2,
      marginPage: props.marginPage || 1,
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      initialPage: props.initialPage || 0,
      forcePage: props.forcePage || 0,
      pageCount: props.pageCount || 1,
      pageRange: props.pageRange || 2,
      marginPage: props.marginPage || 1,
    })
  }

  handlePageClick(e) {
    this.props.onPageClick && this.props.onPageClick(e)
  }

  render() {
    if (this.props.isEmpty === true) {
      return null
    }
    return (
      <div className="footer-list note-list-workPage">
        {this.state.pageCount > 1 && (
          <div>
            <div
              className={classnames('note-issues note-work', {
                'note-issues-none': this.state.pageCount,
              })}
            >
              <span className="name-note">Chú Thích</span>
              <ul className="list-note">
                <li className="note-item">
                  <span className="pt-icon pt-icon-symbol-square style-pbct"></span>
                  <span className="text-qh">Công việc phòng ban chủ trì</span>
                </li>
                <li className="note-item">
                  <span className="pt-icon pt-icon-symbol-square style-qh"></span>
                  <span className="text-qh">Công việc quá hạn</span>
                </li>
                <li className="note-item">
                  <span className="pt-icon pt-icon-symbol-square style-gqh"></span>
                  <span className="text-qh">Công việc gần quá hạn</span>
                </li>
                <li className="note-item">
                  <span className="icon-Save-DocumentCabinet style-icon-DocumentCabinet"></span>
                  <span className="text-qh">Đã lưu TTL</span>
                </li>
                <li className="note-item">
                  <span className="icon-attention-warning style-icon-DocumentCabinet"></span>
                  <span className="text-qh">Đã báo nhầm</span>
                </li>
              </ul>
            </div>
            <Paginate
              forcePage={this.state.forcePage}
              initialPage={this.state.initialPage}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={this.state.marginPage}
              pageRangeDisplayed={this.state.pageRange}
              onPageChange={this.handlePageClick.bind(this)}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ListFooter
