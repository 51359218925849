import React, { Component } from 'react'

import { ContentHeader } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import ProcedureCategoryForm from './ProcedureCategoryForm'
import { updateProcedureCategory } from '../../../actions/procedure'

const PAGE_NAME = 'Chỉnh sửa danh mục quy trình'
class AddProcedureCategoryPage extends Component {
  constructor() {
    super()
    this.state = {
      isWaitingSubmit: false,
    }
  }

  toggleAllowGoback = () => {
    const { isWaitingSubmit } = this.state
    this.setState({ isWaitingSubmit: !isWaitingSubmit })
  }

  render() {
    const { isWaitingSubmit } = this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <ContentHeader
              pageIcon="icon-danh-muc-quy-trinh"
              pageName={PAGE_NAME}
              showGoBackButton
              disabledGoBack={isWaitingSubmit}
            />
            <ProcedureCategoryForm
              action={updateProcedureCategory}
              isEdit
              toggleAllowGoback={this.toggleAllowGoback}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

export default AddProcedureCategoryPage
