import { schema } from 'normalizr'

const AlertMistake = new schema.Entity(
  'alertMistakes',
  {},
  {
    idAttribute: alertMistake => alertMistake.congVanCongViecId,
  }
)

export default AlertMistake
