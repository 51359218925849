import React, { memo } from 'react'
import { ProgressBar as PBar, Intent } from '@blueprintjs/core'
import { isNumber } from 'lodash'
import csx from 'classnames'

const ProgressBar = ({ percent = 0, large = false }) => {
  if (!isNumber(percent)) {
    return null
  }

  return (
    <span className="cpc-progress-bar-wrapper">
      <PBar
        className={csx('cpc-progress', 'pt-no-animation', 'pt-no-stripes', {
          large,
        })}
        value={percent / 100}
        intent={Intent.SUCCESS}
      />{' '}
      <span className="text-success font-size-13">{percent}%</span>
    </span>
  )
}

export default memo(ProgressBar)
