import { Permission } from 'components/auth'

const workManager = allPermissions => {
  return [
    {
      nameMenu: 'Tạo công việc',
      orPermission: allPermissions,
      subMenu: [
        {
          nameMenu: 'Tạo công việc mới',
          icon: 'icon-Nhap_cong_van_di size-icon-18',
          path: '/quan-ly-cong-viec/tao-cong-viec',
          links: ['/quan-ly-cong-viec/tao-cong-viec'],
          type: 'QLCV_TAO_VIEC',
        },
        {
          nameMenu: 'Loại công việc',
          icon: 'icon2-tag size-icon-16',
          path: '/quan-ly-cong-viec/tags',
          links: ['/quan-ly-cong-viec/tags'],
          type: 'QLCV_LOAI_CONGVIEC',
        },
        {
          nameMenu: 'Nhóm thường giao việc',
          icon: 'icon2-nhom-thuong-giao-viec size-icon-16',
          path: '/quan-ly-cong-viec/nhom-thuong-giao-viec',
          links: [
            '/quan-ly-cong-viec/nhom-thuong-giao-viec',
            '/quan-ly-cong-viec/nhom-thuong-giao-viec/them-moi',
            '/quan-ly-cong-viec/nhom-thuong-giao-viec/chinh-sua/',
          ],
        },
      ],
    },
    {
      nameMenu: 'Công việc phòng ban',
      permission: [Permission.CONGVIEC_PHONGBAN],
      subMenu: [
        {
          nameMenu: 'Chưa giao',
          icon: 'icon-Chua_Giao font-size-18',
          path: '/quan-ly-cong-viec/phong-ban/chua-giao',
          links: [
            '/quan-ly-cong-viec/phong-ban/chua-giao',
            '/quan-ly-cong-viec/phong-ban/chua-giao/chi-tiet',
          ],
          type: 'QLCV_PHONGBAN_CHUA_GIAO',
          badgeKey: 'counterCongViecPhongBanCaNhan.congViecPhongBanChuaGiao',
        },
        {
          nameMenu: 'Đã giao',
          icon: 'icon-Da_Giao font-size-18',
          path: '/quan-ly-cong-viec/phong-ban/da-giao',
          links: [
            '/quan-ly-cong-viec/phong-ban/da-giao',
            '/quan-ly-cong-viec/phong-ban/da-giao/chi-tiet',
            '/quan-ly-cong-viec/sua-cong-viec/phong-ban',
          ],
          type: 'QLCV_PHONGBAN_DA_GIAO',
          badgeKey: 'task.pbDaGiao',
        },
      ],
    },
    {
      nameMenu: 'Công việc cá nhân',
      orPermission: allPermissions,
      subMenu: [
        {
          nameMenu: 'Cần thực hiện',
          icon: 'icon-Chua_Thuc_Hien font-size-18',
          path: '/quan-ly-cong-viec/ca-nhan/can-thuc-hien',
          links: [
            '/quan-ly-cong-viec/ca-nhan/can-thuc-hien',
            '/quan-ly-cong-viec/ca-nhan/can-thuc-hien/chi-tiet',
          ],
          type: 'QLCV_CANHAN_CAN_THUC_HIEN',
          badgeKey: 'counterCongViecPhongBanCaNhan.congViecCaNhanCanThucHien',
        },
        {
          nameMenu: 'Đã giao',
          icon: 'icon-Da_Giao font-size-18',
          path: '/quan-ly-cong-viec/ca-nhan/da-giao',
          links: [
            '/quan-ly-cong-viec/ca-nhan/da-giao',
            '/quan-ly-cong-viec/ca-nhan/da-giao/chi-tiet',
            '/quan-ly-cong-viec/sua-cong-viec/ca-nhan',
          ],
          type: 'QLCV_CANHAN_DA_GIAO',
          badgeKey: 'task.cnDaGiao',
        },
        {
          nameMenu: 'Theo dõi',
          icon: 'icon-Xemdebiet font-size-18',
          path: '/quan-ly-cong-viec/ca-nhan/theo-doi',
          links: [
            '/quan-ly-cong-viec/ca-nhan/theo-doi',
            '/quan-ly-cong-viec/ca-nhan/theo-doi/chi-tiet',
          ],
          type: 'QLCV_CANHAN_THEO_DOI',
          badgeKey: 'counterCongViecPhongBanCaNhan.congViecTheoDoiChuaDoc',
        },
      ],
    },
    {
      nameMenu: 'Thông báo kết luận cuộc họp',
      permission: [Permission.THONGBAOKETLUANHDTV],
      subMenu: [
        {
          nameMenu: 'Danh sách thông báo',
          icon: 'icon2-clipboard size-icon-16',
          path: '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop',
          links: [
            '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop',
            '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/them-moi',
            '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/chinh-sua/',
            '/thong-bao-ket-luan-cuoc-hop/chi-tiet/',
            '/thong-bao-ket-luan-cuoc-hop/thong-ke',
          ],
          type: 'QLCV_THONGBAO_KLCH',
          badgeType: 'dot',
          permission: [Permission.THONGBAOKETLUANHDTV],
        },
      ],
    },
    {
      nameMenu: 'Chức năng khác',
      orPermission: allPermissions,
      subMenu: [
        {
          nameMenu: 'Thông báo',
          icon: 'icon-bell size-icon-16',
          path: '/quan-ly-cong-viec/thong-bao/',
          links: ['/quan-ly-cong-viec/thong-bao/'],
          type: 'QLCV_THONGBAO',
          badgeType: 'dot',
          badgeKey: 'task.notification',
        },
        {
          nameMenu: 'Tìm kiếm công văn D-Office',
          icon: 'icon2-d-office size-icon-16',
          path: '/quan-ly-cong-viec-do/tim-kiem-cong-van',
          links: ['/quan-ly-cong-viec-do/tim-kiem-cong-van'],
        },
        {
          nameMenu: 'Thống kê công việc',
          icon: 'icon2-pie-chart size-icon-16',
          path: '/quan-ly-cong-viec/thong-ke',
          links: ['/quan-ly-cong-viec/thong-ke'],
        },
        // {
        //   nameMenu: 'Công việc D-Office',
        //   icon: 'icon2-d-office size-icon-16',
        //   path: '/quan-ly-cong-viec/cong-viec-d-office',
        //   links: [
        //     '/quan-ly-cong-viec/cong-viec-d-office',
        //     '/quan-ly-cong-viec/cong-viec-d-office/chi-tiet'
        //   ],
        //   type: 'QLCV_D_OFFICE',
        // },
      ],
    },
  ]
}

export default workManager
