import * as types from '../constants/ActionTypes'

const initialState = {
  items: [],
  leaderCanAssignList: [],
}

export default function users(state = initialState, action) {
  switch (action.type) {
    case types.GET_STAFFS_IN_DEPARTMENT:
      return {
        ...state,
        items: action.payload.data.result.result,
      }

    case types.GET_LEADER_STAFF_CAN_ASSIGN:
      return {
        ...state,
        leaderCanAssignList: action.payload.data.result.result,
      }

    case types.CLEAR_USERS:
      return initialState

    default:
      return state
  }
}
