import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import { Intent, Button, Checkbox } from '@blueprintjs/core'
import { SelectTree, LoadingItem } from '../../components/common'
import * as MESSAGE from '../../constants/MessageForm'
import { Authorization, Permission } from '../../components/auth'
import classnames from 'classnames'

class InputForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      tieuDe: '',
      tinTongCongTy: false,
      isActive: true,
      isLoading: false,
      noiDung: '',
      _quillModules: {
        toolbar: [
          [{ header: 1 }, { header: 2 }],
          // heading
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          // font size
          [{ size: ['small', false, 'large', 'huge'] }],
          // toggled buttons
          ['bold', 'italic', 'underline'],
          // link
          ['link', 'video'],
          // dropdown with defaults from theme
          [{ color: [] }, { background: [] }],
          //clear format code
          ['clean'],
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
      },
      _quillFormats: [
        'header',
        'size',
        'bold',
        'italic',
        'underline',
        'link',
        'image',
        'color',
        'background',
        'video',
      ],
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeContent = this.handleChangeContent.bind(this)
  }

  handleChange = e => {
    if (e.target.type === 'checkbox') {
      return this.setState({ [e.target.name]: e.target.checked })
    }

    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = () => {
    if (!this.state.tieuDe || this.state.tieuDe.trim().length === 0) {
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          message: MESSAGE.TEXT_REQUIRE_TITILE_NOTIFICATION,
          isSuccess: false,
          intent: Intent.DANGER,
        })
      )
    }

    if (!this.state.noiDung || this.state.noiDung.trim().length === 0) {
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          message: MESSAGE.TEXT_REQUIRE_CONTENT_NOTIFICATION,
          isSuccess: false,
          intent: Intent.DANGER,
        })
      )
    }

    let donViId = this.props.donViId
    if (
      this.state.tinTongCongTy &&
      this.props.auth &&
      this.props.auth.mainUnitId
    ) {
      donViId = this.props.auth.mainUnitId
    }
    if (!donViId || donViId.trim().length === 0) {
      return (
        this.props.onSubmit &&
        this.props.onSubmit({
          message: MESSAGE.TEXT_REQUIRE_UNIT_NOTIFICATION,
          isSuccess: false,
          intent: Intent.DANGER,
        })
      )
    }

    const dataRequest = {
      id: this.state.id,
      don_vi_id: donViId,
      tieu_de: this.state.tieuDe,
      noi_dung: this.state.noiDung,
      tin_tong_cong_ty: this.state.tinTongCongTy,
      is_active: this.state.isActive,
    }

    this.setState({ isLoading: true })
    if (!this.props.isUpdate) {
      return this.props.actions
        .notificationCreate(dataRequest)
        .then(res => {
          if (
            res.error ||
            !res.payload ||
            !res.payload.data ||
            res.payload.data.result === false
          ) {
            this.setState({ isLoading: false })
            return (
              this.props.onSubmit &&
              this.props.onSubmit({
                message: MESSAGE.TOATS_MESSAGE_FAIL,
                isSuccess: false,
                intent: Intent.DANGER,
              })
            )
          }
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              isSuccess: true,
              intent: Intent.SUCCESS,
            })
          )
        })
        .catch(e => {
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              isSuccess: false,
              intent: Intent.DANGER,
            })
          )
        })
    }

    return this.props.actions
      .notificationUpdate(dataRequest)
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          !res.payload.data ||
          res.payload.data.result === false
        ) {
          this.setState({ isLoading: false })
          return (
            this.props.onSubmit &&
            this.props.onSubmit({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              isSuccess: false,
              intent: Intent.DANGER,
            })
          )
        }
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            isSuccess: true,
            intent: Intent.SUCCESS,
          })
        )
      })
      .catch(e => {
        return (
          this.props.onSubmit &&
          this.props.onSubmit({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            isSuccess: false,
            intent: Intent.DANGER,
          })
        )
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.notification &&
      prevProps.notification !== this.props.notification &&
      this.props.isUpdate
    ) {
      const tieuDe = this.props.notification.tieuDe
        ? this.props.notification.tieuDe
        : ''
      const noiDung = this.props.notification.noiDung
        ? this.props.notification.noiDung
        : ''
      const tinTongCongTy =
        typeof this.props.notification.tinTongCongTy === 'boolean'
          ? this.props.notification.tinTongCongTy
          : false
      const isActive =
        typeof this.props.notification.isActive === 'boolean'
          ? this.props.notification.isActive
          : false
      this.setState({
        id: this.props.notification.id,
        tieuDe,
        noiDung,
        tinTongCongTy,
        isActive,
      })
    }
  }

  handleChangeContent = value => {
    this.setState({ noiDung: value })
  }

  render() {
    const AuthElement = Authorization.Element
    const isLoadingData =
      this.props.isLoadingData &&
      Object.values(this.props.isLoadingData).find(item => item === true) !==
        undefined
    return (
      <div className="form-container form-container-AddAdminNotificationPage">
        <div className="form-padding-box">
          <div className="pt-form-group">
            <div className="row item-row-input">
              <div className="col-md-12 col-xs-12 col-sm-12 form-inline-group">
                <label className="pt-label label-inline-input">Tiêu đề</label>
                <div className="pt-form-content">
                  <input
                    name="tieuDe"
                    className={classnames('pt-input', {
                      'loading-input':
                        this.props.isLoadingData && isLoadingData,
                    })}
                    placeholder="Nhập tiêu đề..."
                    type="text"
                    dir="auto"
                    value={this.state.tieuDe}
                    onChange={this.handleChange}
                    disabled={
                      this.state.isLoading ||
                      (this.props.isLoadingData && isLoadingData)
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="pt-form-group">
            <div className="row item-row-input">
              <div className="col-md-12 col-xs-12 col-sm-12 form-inline-group">
                <label className="pt-label label-inline-input">Nội dung</label>
                <div className="pt-form-content">
                  <div className="react-quill-custom" id="quill-container">
                    {isLoadingData && this.props.isUpdate && (
                      <div className="loading-container">
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                      </div>
                    )}
                    {((!isLoadingData && this.props.isUpdate) ||
                      !this.props.isUpdate) && (
                      <ReactQuill
                        value={this.state.noiDung}
                        onChange={this.handleChangeContent}
                        modules={this.state._quillModules}
                        formats={this.state._quillFormats}
                        placeholder={'Nhập nội dung...'}
                        theme="snow"
                        bounds={'#quill-container'}
                        readOnly={isLoadingData}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AuthElement permission={[Permission.SUPERADMIN]}>
            <div className="pt-form-group">
              <div className="row item-row-input">
                <div className="col-md-12 col-xs-12 col-sm-12 form-inline-group">
                  <label className="pt-label label-inline-input">
                    Tổng Công Ty
                  </label>
                  <div className="pt-form-content">
                    <label className="pt-control pt-checkbox pt-inline">
                      <Checkbox
                        name="tinTongCongTy"
                        checked={this.state.tinTongCongTy}
                        onChange={this.handleChange}
                        disabled={
                          this.state.isLoading ||
                          (this.props.isLoadingData && isLoadingData)
                        }
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </AuthElement>

          <div className="pt-form-group">
            <div className="row item-row-input">
              <div className="col-md-12 col-xs-12 col-sm-12 form-inline-group">
                <label className="pt-label label-inline-input">Đơn Vị</label>
                <div className="pt-form-content">
                  <SelectTree
                    data={this.props.data}
                    type={this.props.type}
                    permission={this.props.permission}
                    value={this.props.donViId}
                    handleChange={this.props.onHandleChange}
                    isLoading={this.props.isLoadingUnit}
                    isDisabled={
                      this.state.tinTongCongTy ||
                      (this.props.isLoadingData && isLoadingData)
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="pt-form-group">
            <div className="row item-row-input">
              <div className="col-md-12 col-xs-12 col-sm-12 form-inline-group">
                <label className="pt-label label-inline-input">Hiển thị</label>
                <div className="pt-form-content">
                  <label className="pt-control pt-checkbox pt-inline checkbox-notification">
                    <Checkbox
                      name="isActive"
                      checked={this.state.isActive}
                      onChange={this.handleChange}
                      disabled={
                        this.state.isLoading ||
                        (this.props.isLoadingData && isLoadingData)
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="buttton-action-footer">
          <Button
            className="pt-button btn-cancel"
            disabled={
              this.state.isLoading ||
              (this.props.isLoadingData && isLoadingData)
            }
            onClick={this.props.onClickCancel}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
            disabled={
              this.state.isLoading ||
              (this.props.isLoadingData && isLoadingData)
            }
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
      </div>
    )
  }
}

export default InputForm
