import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import classnames from 'classnames'

// trang thai phieu nhan
const LDDV_CHUA_GIAO = 0
const LDPB_CHUA_GIAO = 1
const CHUA_GOP_Y = 2
const LDPB_CHUA_XAC_NHAN = 3
const LDPB_TU_CHOI_XAC_NHAN = 31
const LDDV_CHUA_XAC_NHAN = 4
const LDDV_TU_CHOI_XAC_NHAN = 41
const TU_CHOI_XAC_NHAN = 8
const DA_GOP_Y = 9

class ListItemPhieuNhanYeuCau extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  state = {
    isRedirect: false,
    isOpenPopup: false,
  }

  handleClick = () => {
    this.setState({
      isRedirect: true,
    })
  }

  render() {
    const { item, number } = this.props
    if (!item || !number) {
      return
    }
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={`/tien-ich/lay-y-kien-don-vi/chi-tiet-nhan/${item.id}`}
        />
      )
    }
    let trangThai = ''
    switch (item.tinhTrang) {
      case LDDV_CHUA_GIAO:
        trangThai = 'LĐĐV chưa  chỉ đạo'
        break
      case LDPB_CHUA_GIAO:
        trangThai = 'LĐPB chưa giao'
        break
      case CHUA_GOP_Y:
        trangThai = 'chưa góp ý'
        break
      case LDPB_CHUA_XAC_NHAN:
        trangThai = 'LĐPB chưa xác nhận'
        break
      case LDPB_TU_CHOI_XAC_NHAN:
        trangThai = 'LĐPB từ chối xác nhận'
        break
      case LDDV_CHUA_XAC_NHAN:
        trangThai = 'LĐĐV chưa xác nhận'
        break
      case LDDV_TU_CHOI_XAC_NHAN:
        trangThai = 'LĐĐV từ chối xác nhận'
        break
      case TU_CHOI_XAC_NHAN:
        trangThai = 'từ chối xác nhận'
        break
      case DA_GOP_Y:
        trangThai = 'đã góp ý'
        break
      default:
        trangThai = ''
    }
    return (
      <tr
        className={classnames({
          'status-urgency-hight': item.tinhTrang !== DA_GOP_Y && item.quaHan,
        })}
      >
        <td onClick={this.handleClick}>{number > 9 ? number : '0' + number}</td>
        <td onClick={this.handleClick}>{item.soKyHieu ? item.soKyHieu : ''}</td>
        <td onClick={this.handleClick}>{item.trichYeu ? item.trichYeu : ''}</td>
        <td onClick={this.handleClick}>
          {item.ngayLap ? moment(item.ngayLap).format('DD/MM/YYYY') : ''}
        </td>
        <td onClick={this.handleClick}>
          {item.thoiHan ? moment(item.thoiHan).format('DD/MM/YYYY') : ''}
        </td>
        <td onClick={this.handleClick}>
          {item.tinhTrang < LDPB_CHUA_XAC_NHAN
            ? item.nguoiLap && item.nguoiLap.tenNhanVien
            : item.caNhanChuTri && item.caNhanChuTri.tenNhanVien}
        </td>
        <td onClick={this.handleClick} className="textarea-content">
          <span
            className={classnames('status-job position-relative', {
              'lddv-chua-chi-dao': item.tinhTrang === LDDV_CHUA_GIAO,
              'lddv-chua-giao':
                item.tinhTrang === LDPB_CHUA_GIAO ||
                item.tinhTrang === CHUA_GOP_Y,
              'ldpb-chuaduyet':
                item.tinhTrang === LDDV_CHUA_XAC_NHAN ||
                item.tinhTrang === LDPB_CHUA_XAC_NHAN,
              'khong-duyet':
                item.tinhTrang === LDPB_TU_CHOI_XAC_NHAN ||
                item.tinhTrang === LDDV_TU_CHOI_XAC_NHAN ||
                item.tinhTrang === TU_CHOI_XAC_NHAN,
              'da-gop-y': item.tinhTrang === DA_GOP_Y,
            })}
          >
            {trangThai}
          </span>
        </td>
        <td className="none-status">&nbsp;</td>
      </tr>
    )
  }
}

export default ListItemPhieuNhanYeuCau
