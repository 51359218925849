import { useMemo, useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import get from 'lodash/get'
import size from 'lodash/size'
import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

import { RESPONSIBILITY_TYPE, UNSET, FILE_TYPE } from 'constants/Enum'
import { randomId } from 'helpers/string'
import { Action, Toast } from 'constants/MessageForm'
import {
  getCongViec,
  saveTree2,
  getDsTag,
  postUpload,
  postWorksMeeting,
  getPhongBanDonVi,
  patchWorksMeeting,
} from 'actions/task'
import requestAction from 'helpers/request'

const useTaskThongbaoKLCHTaskCreate = ({
  history,
  match,
  hasShowAllDeparment,
  mainUnitId,
  dataTreeOrganizationDepartmentStore,
}) => {
  const id = match.params?.id
  const taskId = match.params?.taskId
  const treeKeys = useMemo(() => {
    return {
      CHU_TRI: 'CHU_TRI',
      PHOI_HOP: 'PHOI_HOP',
      THEO_DOI: 'THEO_DOI',
    }
  }, [])
  const dateRequest = 'YYYY-MM-DD'
  const dateTimeRequest = 'YYYY-MM-DDTHH:mm:ss'

  const DEFAULT_TASK = useMemo(
    () => ({
      showTask: true,
      showExtra: false,
      data: {
        executionDate: moment(),
      },
      validate: {},
    }),
    []
  )

  const [tags, setTags] = useState([])
  const [fetching, setFetching] = useState(false)
  const [tasks, setTasks] = useState([{ ...DEFAULT_TASK, id: randomId() }])
  const [validateErrors, setValidateErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [dataAutoToggle] = useState([-1])
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [files, setFiles] = useState({})
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [idTempDialog, setIdTempDialog] = useState(null)

  const toggleCollapseExtra = id => () => {
    setTasks(
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showExtra: !task.showExtra,
            }
          : task
      )
    )
  }

  const _changeFormValue = ({ id, name, value }) => {
    setTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === id
          ? {
              ...task,
              data: {
                ...task.data,
                [name]: value,
                ...(name === 'deadline' ? { workReminder: null } : {}),
              },
            }
          : task
      )
    )
  }

  const addMeeting = () => {
    setTasks([
      ...tasks?.map(t => ({ ...t, showTask: false, showExtra: false })),
      { ...DEFAULT_TASK, id: randomId() },
    ])
  }

  const toggleCollapseTask = id => () => {
    setTasks(
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showTask: !task.showTask,
            }
          : task
      )
    )
  }

  const onChangeFormValidate = ({ id, name, value }) => {
    setValidateErrors(prev => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [name]: value,
      },
    }))
  }

  const removeTask = id => () => {
    setIdTempDialog(id)
    setIsShowDialog(true)
  }

  const changeField = (id, name) => value => {
    setIsDisableButton(false)
    onChangeFormValidate({ id, name, value: false })
    _changeFormValue({
      id,
      name,
      value,
    })
  }

  const onChangeRadio = useCallback(
    (name, values, taskId) => {
      setIsDisableButton(false)
      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            return {
              ...elm,
              data: {
                ...elm?.data,
                [treeKeys.CHU_TRI]: name === treeKeys.CHU_TRI ? values : null,
                [treeKeys.PHOI_HOP]:
                  name === treeKeys.PHOI_HOP
                    ? elm?.data?.[treeKeys.PHOI_HOP]
                    : elm?.data?.[treeKeys.PHOI_HOP]?.filter(
                        e => e.id !== values?.id
                      ),
                [treeKeys.THEO_DOI]:
                  name === treeKeys.THEO_DOI
                    ? elm?.data?.[treeKeys.THEO_DOI]
                    : elm?.data?.[treeKeys.THEO_DOI]?.filter(
                        e => e.id !== values?.id
                      ),
              },
            }
          }

          return elm
        })
      )
    },
    [treeKeys, setTasks]
  )

  const onChangeCheckbox = useCallback(
    async (name, values, taskId, event, isIndeterminate) => {
      setIsDisableButton(false)
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      let treeItem = []
      const isAll = values?.type === 'ALL'
      if (isAll) {
        treeItem =
          dataTreeOrganizationDepartmentStore?.find(e => e?.id === values?.id)
            ?.children || []
      }

      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            let newData = []
            const oldData =
              elm?.data?.[name]?.filter(e => e?.id !== values?.id) || []
            newData = [
              ...oldData,
              ...(isChecked && values?.type !== 'ALL' ? [values] : []),
            ]
            if (isAll) {
              if (isChecked) {
                newData = [...newData, ...treeItem]?.filter(
                  e => e.id !== values?.parentId
                )
                const dataChuTri = elm?.data?.[treeKeys.CHU_TRI]
                if (dataChuTri?.id) {
                  newData = newData?.filter(e => e.id !== dataChuTri?.id)
                }
              } else {
                newData = differenceBy(newData || [], treeItem || [], 'id')
              }
            } else {
              newData = newData?.filter(e => e.id !== values?.parentId)
            }

            return {
              ...elm,
              data: {
                ...elm?.data,
                [treeKeys.CHU_TRI]:
                  name !== treeKeys.CHU_TRI &&
                  elm?.data?.[treeKeys.CHU_TRI]?.id === values?.id
                    ? null
                    : elm?.data?.[treeKeys.CHU_TRI],
                [treeKeys.PHOI_HOP]:
                  name === treeKeys.PHOI_HOP
                    ? uniqBy(newData, 'id')
                    : elm?.data?.[treeKeys.PHOI_HOP]?.filter(
                        e => e.id !== values?.id
                      ) || [],
                [treeKeys.THEO_DOI]:
                  name === treeKeys.THEO_DOI
                    ? uniqBy(newData, 'id')
                    : elm?.data?.[treeKeys.THEO_DOI]?.filter(
                        e => e.id !== values?.id
                      ) || [],
              },
            }
          }

          return elm
        })
      )
    },
    [setTasks, treeKeys, dataTreeOrganizationDepartmentStore]
  )

  const changeFile = name => fileList => {
    setIsDisableButton(false)
    setFiles({
      ...files,
      [name]: fileList,
    })
  }

  const removeAttachmentFiles = id => removeFile => {
    if (removeFile.id) {
      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === id
            ? {
                ...task,
                data: {
                  ...get(task, 'data', {}),
                  attachmentFiles: get(task, 'data.attachmentFiles', []).filter(
                    file => !isEqual(file, removeFile)
                  ),
                },
              }
            : task
        )
      )
    } else {
      setFiles({
        ...files,
        [id]: files[id].filter(elm => elm.uid !== removeFile.uid),
      })
    }
  }

  const attachmentFilesFromDocument = (files, isEoffice) => {
    let filesResult = []
    if (!size(files)) {
      return []
    }

    if (isEoffice) {
      filesResult = files.filter(elm => elm.application === 'eOffice')
    } else {
      filesResult = files.filter(elm => elm.application !== 'eOffice')
    }

    return filesResult
  }

  const handleCheckActive = useCallback(
    (key, tasks, taskId, record) => {
      const parentItem =
        dataTreeOrganizationDepartmentStore?.find(e => e?.id === record?.id)
          ?.children || []
      const taskItem = tasks?.find(elm => elm?.id === taskId)
      let isChecked = false
      if (
        !isEmpty(taskItem) !== 0 &&
        !isEmpty(taskItem?.data) &&
        size(taskItem?.data?.[key]) !== 0
      ) {
        const taskItemDataKey = taskItem?.data?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        if (
          record?.type === 'ALL' &&
          size(parentItem) !== 0 &&
          size(parentItem) === size(taskItemDataKey)
        ) {
          isChecked = true
        } else {
          const dataKey = taskItem?.data?.[key]?.findIndex(
            e => e?.id === record?.id
          )
          if (dataKey === 0 || (dataKey && dataKey !== -1)) {
            isChecked = true
          }
        }
      }

      return isChecked
    },
    [dataTreeOrganizationDepartmentStore]
  )

  const handleCheckIndeterminate = useCallback(
    (name, tasks, taskId, record) => {
      const taskItem = tasks?.find(e => e.id === taskId)
      let isChecked = false
      const dataKey = size(taskItem?.data?.[name]) !== 0
      const hasChecked = taskItem?.data?.[name]?.some(
        e => e?.parentId === record?.id
      )
      if (dataKey && record?.type === 'ALL' && hasChecked) {
        isChecked = true
      }

      return isChecked
    },
    []
  )

  const _convertDataType = (values, type) => ({
    responsibility_id: get(values, 'id'),
    assign_type: get(values, 'type'),
    responsibility_type: type,
  })

  const _getConvertData = () => {
    const convertType = type => {
      if (type === 'DEPARTMENT') {
        return 'PB'
      } else if (type === 'ORGANIZATION') {
        return 'DV'
      }

      return 'PERSONAL'
    }

    return (tasks || []).map(task => {
      const originData = get(task, 'data', {})
      const newData = {
        title: get(originData, 'title', '').trim(),
        require_report: originData?.requireReport || false,
        description: get(originData, 'description', '').trim(),
        priority:
          get(originData, 'priority') !== UNSET
            ? get(originData, 'priority')
            : undefined,
        work_user_tags: get(originData, 'tags', []).map(item => ({
          work_tag_id: item,
        })),
        attachment_file_ids: get(originData, 'attachmentFiles', []).map(
          file => file.id
        ),
        reference_file_ids: get(originData, 'referenceFiles', []).map(
          file => file.id
        ),
        is_save_template: get(originData, 'isSaveTemplate'),
      }

      const deadline = get(originData, 'deadline')
      if (deadline) {
        newData.deadline = moment(deadline).startOf('day').format(dateRequest)
      }

      const executionDate = get(originData, 'executionDate')
      if (executionDate) {
        newData.execution_date = moment(executionDate).format(dateRequest)
      }

      const workReminder = get(originData, 'workReminder')
      if (workReminder) {
        newData.work_reminder = {
          type: workReminder.type,
          reminder_time: workReminder.date.format(dateTimeRequest),
        }
      }

      let executor = []
      if (get(originData, treeKeys.CHU_TRI)) {
        executor = [
          {
            responsibility_id: get(originData, `${treeKeys.CHU_TRI}.id`),
            assign_type: get(originData, `${treeKeys.CHU_TRI}.type`),
            responsibility_type: RESPONSIBILITY_TYPE.CHU_TRI,
          },
        ]
        newData.presiding_id = {
          [convertType(get(originData, `${treeKeys.CHU_TRI}.type`))]: get(
            originData,
            `${treeKeys.CHU_TRI}.id`
          ),
        }
      }

      let coordination = []
      if (get(originData, treeKeys.PHOI_HOP)) {
        coordination = get(originData, treeKeys.PHOI_HOP, [])?.map(elm =>
          _convertDataType(elm, RESPONSIBILITY_TYPE.PHOI_HOP)
        )
        newData.coordination_id = get(originData, treeKeys.PHOI_HOP, [])?.map(
          elm => ({
            [convertType(elm?.type)]: elm?.id,
          })
        )
      }

      let watcher = []
      if (get(originData, treeKeys.THEO_DOI)) {
        watcher = get(originData, treeKeys.THEO_DOI, [])?.map(elm =>
          _convertDataType(elm, RESPONSIBILITY_TYPE.THEO_DOI)
        )
      }

      newData.work_assignments = [...executor, ...coordination, ...watcher]

      return {
        ...task,
        data: newData,
      }
    })
  }

  const _upload = async filesData => {
    const responseFilesObject = {}
    await Promise.all(
      Object.entries(filesData).map(async ([key, files]) => {
        if (isEmpty(files)) {
          responseFilesObject[key] = []
          return
        }

        await requestAction({
          action: () => postUpload(files),
          afterResponse: (result = []) => {
            responseFilesObject[key] = result
          },
          showToastSucess: false,
          codeCheck: false,
        })

        return
      })
    )

    return responseFilesObject
  }

  const _getDataWithUpload = async () => {
    const cloneTasks = _getConvertData()
    const responseFilesObject = await _upload(files)
    return (cloneTasks || []).map(task => ({
      ...(task?.data || {}),
      work_type: 'MEETING',
      attachment_file_ids: [
        ...(task?.data?.attachment_file_ids || []),
        ...(responseFilesObject[task.id] || []).map(file => file.id),
      ],
    }))
  }

  const validateRequired = name => {
    let success = true
    const tasksNew = [...tasks]
    tasksNew.forEach(({ id, data }) => {
      if (isEmpty(data[name]) || (isString(data[name]) && !data[name].trim())) {
        success = false
        onChangeFormValidate({ id, name, value: true })
      } else {
        success = true
        onChangeFormValidate({ id, name, value: false })
      }
    })

    return success
  }

  const onValidate = () => {
    const isCheckTaskTitle = validateRequired('title')
    const isCheckTaskChuTri = validateRequired(treeKeys.CHU_TRI)
    const isCheckTask = !isCheckTaskTitle || !isCheckTaskChuTri
    if (isCheckTask) {
      throw new Error('warning')
    }
  }

  const submitCreate = async () => {
    await requestAction({
      successCode: 201,
      logError: true,
      beforeAction: () => {
        setIsLoading(true)
        onValidate()
      },
      action: async () => {
        const dataCongViec = await _getDataWithUpload()
        const fullData = dataCongViec?.map(e => ({
          ...e,
          parent_id: id,
        }))
        return postWorksMeeting(fullData)
      },
      afterResponse: () => {},
      afterAction: () => {
        history.goBack()
        setIsLoading(false)
        setIsDisableButton(true)
      },
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const submitUpdate = async () => {
    requestAction({
      beforeAction: () => {
        setIsLoading(true)
        onValidate()
      },
      action: async () => {
        const dataCongViec = await _getDataWithUpload()
        const fullData = {
          ...dataCongViec?.[0],
          parent_id: id,
        }
        return patchWorksMeeting(taskId, fullData)
      },
      afterResponse: () => {},
      afterAction: () => {
        history.goBack()
        setIsLoading(false)
      },
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const onCloseDialog = useCallback(() => {
    setIsShowDialog(false)
    setIdTempDialog(null)
  }, [])

  const onDeleteTask = useCallback(() => {
    const id = idTempDialog
    const newTasks = tasks.filter(task => task.id !== id)
    setTasks(
      size(newTasks) === 1
        ? newTasks.map(t => ({ ...t, showTask: true }))
        : newTasks
    )
    setFiles(prevFiles => {
      delete prevFiles[id]
      return prevFiles
    })

    onCloseDialog()
  }, [idTempDialog, tasks, onCloseDialog])

  const fetchDepartmentsUnit = useCallback(() => {
    const convertType = type => {
      if (type === 'PB') {
        return 'DEPARTMENT'
      } else if (type === 'DV') {
        return 'ORGANIZATION'
      }

      return 'PERSONAL'
    }

    requestAction({
      showToast: false,
      successCode: 201 || 200,
      action: () =>
        getPhongBanDonVi(mainUnitId, { phongBanLanhDao: false, limit: 9999 }),
      afterResponse: response => {
        const departmentData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'PB')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -1,
              children: [],
            })) || []
        const organizationData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'DV')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -2,
              children: [],
            })) || []
        const newData = [
          ...(size(departmentData) !== 0
            ? [
                {
                  id: -1,
                  type: 'ALL',
                  name: 'Phòng ban',
                  code: 'Phòng ban',
                  parentId: null,
                  children: departmentData,
                },
              ]
            : []),
          ...(size(organizationData) !== 0
            ? [
                {
                  id: -2,
                  type: 'ALL',
                  name: 'PC/ Đơn vị',
                  code: 'PC/ Đơn vị',
                  parentId: null,
                  children: organizationData,
                },
              ]
            : []),
        ]
        requestAction({
          showToast: false,
          successCode: 0,
          action: () => saveTree2(newData),
        })
      },
    })
  }, [mainUnitId])

  const convertToStateData = useCallback(
    data => {
      const preside = get(data, 'responsibilities[0].workAssignments', [])
      const coordinations = get(data, 'responsibilities[1].workAssignments', [])
      const originCoordinations = coordinations?.map(coordination => ({
        id: get(coordination, 'responsibilityId'),
        type: get(coordination, 'assignType'),
        parentId:
          coordination?.responsibility?.type === 'ORGANIZATION' ? -2 : -1,
      }))
      const watchers = get(data, 'responsibilities[2].workAssignments', [])
      const originWatchers = watchers?.map(watcher => ({
        id: get(watcher, 'responsibilityId'),
        type: get(watcher, 'assignType'),
        parentId: watcher?.responsibility?.type === 'ORGANIZATION' ? -2 : -1,
      }))

      const referenceFiles = (data?.files || []).filter(
        file => file?.type === FILE_TYPE.FILE_THAM_KHAO
      )
      const attachmentFiles = (data?.files || [])
        .filter(file => file?.type === FILE_TYPE.FILE_DINH_KEM)
        .map(file => ({ ...file, showClose: file.application !== 'eOffice' }))
      return {
        title: get(data, 'title') || '',
        requireReport: get(data, 'requireReport') || false,
        deadline:
          get(data, 'deadline') && moment(get(data, 'deadline')).isValid()
            ? moment(get(data, 'deadline'))
            : moment(get(data, 'originalDeadline')).isValid()
            ? moment(get(data, 'originalDeadline'))
            : null,
        executionDate:
          get(data, 'executionDate') &&
          moment(get(data, 'executionDate')).isValid()
            ? moment(get(data, 'executionDate'))
            : null,
        description: get(data, 'description') || '',
        workReminder: get(data, 'workReminder')
          ? {
              type: get(data, 'workReminder.type'),
              date: moment(get(data, 'workReminder.reminderTime')),
            }
          : undefined,
        priority: get(data, 'priority'),
        tags: get(data, 'workUserTags', []).map(tag => tag.workTagId),
        [treeKeys.CHU_TRI]: preside?.[0]
          ? {
              id: preside?.[0]?.responsibilityId,
              type: preside?.[0]?.assignType,
            }
          : null,
        [treeKeys.PHOI_HOP]: originCoordinations,
        [treeKeys.THEO_DOI]: originWatchers,
        referenceFiles,
        attachmentFiles,
      }
    },
    [treeKeys]
  )

  const fetchDetail = useCallback(() => {
    if (!taskId) {
      return
    }

    requestAction({
      showToast: false,
      beforeAction: () => setFetching(true),
      action: () => getCongViec(taskId),
      afterResponse: data => {
        setTasks([
          {
            ...DEFAULT_TASK,
            id: taskId,
            data: convertToStateData(data),
          },
        ])
      },
      afterAction: () => setFetching(false),
    })
  }, [taskId, DEFAULT_TASK, convertToStateData])

  const _fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => setTags(items),
    })
  }, [])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  useEffect(() => {
    if (hasShowAllDeparment) {
      fetchDepartmentsUnit()
    }
  }, [fetchDepartmentsUnit, hasShowAllDeparment])

  useEffect(() => {
    _fetchDsTag()
  }, [_fetchDsTag])

  return {
    treeKeys,
    tags,
    fetching,
    tasks,
    validateErrors,
    dataAutoToggle,
    isLoading,
    isDisableButton,
    files,
    isShowDialog,
    onCloseDialog,
    onDeleteTask,
    toggleCollapseExtra,
    addMeeting,
    removeTask,
    changeField,
    toggleCollapseTask,
    onChangeRadio,
    onChangeCheckbox,
    handleCheckActive,
    handleCheckIndeterminate,
    attachmentFilesFromDocument,
    changeFile,
    removeAttachmentFiles,
    submitUpdate,
    submitCreate,
  }
}

export default useTaskThongbaoKLCHTaskCreate
