import React from 'react'

const ItemLoading = () => (
  <div className="loading-content">
    <span className="shape1"></span>
    <span className="shape2"></span>
    <span className="shape3"></span>
    <span className="shape4"></span>
    <span className="shape5"></span>
    <span className="shape6"></span>
    <span className="shape7"></span>
    <span className="shape8"></span>
    <span className="shape9"></span>
    <span className="shape10"></span>
    <span className="shape11"></span>
    <span className="shape12"></span>
    <span className="shape13"></span>
  </div>
)

const LoadingTable = props => (
  <div className="table-loading-system" style={props.style}>
    <ItemLoading />
    <ItemLoading />
    <ItemLoading />
  </div>
)

export default LoadingTable
