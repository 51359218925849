import {
  WORK_DEPARTMENT_ASSIGN_INFORMATIONS,
  WORK_PERSONAL_ASSIGN_INFORMATIONS,
} from '../constants/ActionTypes'

const initialState = {
  department: null,
  personal: null,
}

export default function workAssignInformations(state = initialState, action) {
  switch (action.type) {
    case WORK_DEPARTMENT_ASSIGN_INFORMATIONS:
      return {
        ...state,
        department: action.payload.data.result,
      }

    case WORK_PERSONAL_ASSIGN_INFORMATIONS:
      return {
        ...state,
        personal: action.payload.data.result,
      }

    default:
      return state
  }
}
