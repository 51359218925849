import { Permission } from '../components/auth'
export const headers = {
  listReceiveDocument: [
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-3',
      style: [],
      value: 'STT',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số đến',
      sort: 'SoDen',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soDen',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày đến',
      sort: 'NgayCapNhat',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'ngayCapNhat',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-30',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'CQBH',
      sort: 'CoQuanBanHanh',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'coQuanBanHanhId',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Trình lãnh đạo',
      sort: 'PhongBanTrinh',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'phongBanTrinhId',
    },
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [Permission.CONGVANDEN_QUANLY],
      typeGridView: null,
      nameGridView: '',
    },
  ],
  listReceiveDocument2: [
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-3',
      style: [],
      value: 'STT',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số đến',
      sort: 'SoDen',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soDen',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày công văn',
      sort: 'NgayCongVan',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'ngayCongVan',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-30',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'CQBH',
      sort: 'CoQuanBanHanh',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'coQuanBanHanhId',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Trình lãnh đạo',
      sort: 'PhongBanTrinh',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'phongBanTrinhId',
    },
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [Permission.CONGVANDEN_QUANLY],
      typeGridView: null,
      nameGridView: '',
    },
  ],
  listInternalDocument: [
    {
      classNames: 'colum-3',
      style: [],
      value: 'STT',
      sort: false,
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày gửi',
      sort: 'ngayGui',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'ngayGui',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày công văn',
      sort: 'ngayCongVan',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'ngayCongVan',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      permission: [],
      nameGridView: 'soCongVan',
      typeGridView: 'input',
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Trích yếu',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Phòng ban lập',
      sort: 'PhongBanLap',
      permission: [],
      nameGridView: 'phongBanLapId',
      typeGridView: 'select',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Phòng ban nhận',
      permission: [],
      nameGridView: 'phongBanNhanId',
      typeGridView: 'select',
    },
    {
      classNames: 'colum-action',
      style: [],
      value: '',
      sort: false,
      permission: [Permission.CONGVANNOIBO_QUANLY],
    },
  ],
  listSendDocumentPage: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày công văn',
      sort: 'NgayCongVan',
      permission: [],
      nameGridView: 'ngayCongVan',
      typeGridView: 'date',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Số Công Văn',
      sort: 'SoCongVan',
      permission: [],
      nameGridView: 'soCongVan',
      typeGridView: 'input',
    },
    {
      classNames: 'column-32',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      nameGridView: 'trichYeu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Phòng ban lập',
      sort: 'MaPhongBan',
      permission: [],
      nameGridView: 'phongBanLapId',
      typeGridView: 'select',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Người ký',
      sort: 'TenNhanVien',
      permission: [],
      nameGridView: 'nguoiKyId',
      typeGridView: 'select',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Đơn vị nhận',
      permission: [],
      nameGridView: 'dsDonViTrucThuoc',
    },
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
  ],
  listSendSignNumberPage: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số ký hiệu',
      sort: 'KyHieu',
      permission: [],
      nameGridView: 'soKyHieu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-30',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      nameGridView: 'trichYeu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày lập',
      sort: 'NgayLap',
      permission: [],
      nameGridView: 'ngayLap',
      typeGridView: 'date',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Phòng ban lập',
      sort: 'PhongBanLap',
      permission: [],
      nameGridView: 'phongBanLapId',
      typeGridView: 'select',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Người lập',
      sort: 'NguoiLap',
      permission: [],
      nameGridView: 'nguoiLap',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Tình trạng',
      sort: null,
      permission: [],
      nameGridView: 'tinhTrang',
      typeGridView: 'select',
    },
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
  ],
  listSignNumberProjectPage: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-number-symbol',
      style: [],
      value: 'Tên dự án',
      permission: [],
    },
    {
      classNames: 'colum-number-symbol',
      style: [],
      value: 'Số ký hiệu',
      // sort: 'KyHieu',
      permission: [],
    },
    {
      classNames: 'colum-date',
      style: [],
      value: 'Ngày lập',
      // sort: 'NgayTao',
      permission: [],
    },
    {
      classNames: 'colum-establishment',
      style: [],
      value: 'Người lập',
      // sort: 'NguoiTao',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Trích yếu',
      // sort: 'TrichYeu',
      permission: [],
    },
    {
      classNames: 'colum-status',
      style: [],
      value: 'Tình trạng',
      // sort: 'TinhTrang',
      permission: [],
    },
    {
      classNames: 'colum-action',
      style: [],
      value: '',
      permission: [],
    },
  ],
  listPersonalWorkPageUnfulfilled: [
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      checkAll: false,
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: 'Stt',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-10 fix-padding',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [{ type: 'width', value: '25%' }],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-27 fix-padding',
      style: [],
      value: 'Giao việc',
      sort: 'GiaoViec',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'giaoViec',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [],
      value: 'Hạn giải quyết',
      sort: 'HanGiaiQuyet',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'hanGiaiQuyet',
    },
    {
      classNames: 'colum-days-remaining fix-padding',
      style: [{ type: 'minWidth', value: '58px' }],
      value: 'Số ngày còn lại',
      sort: null,
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '80px' }],
      value: 'CQBH',
      sort: 'CoQuanBanHanh',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'coQuanBanHanhId',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '80px' }],
      value: 'Vai trò',
      sort: null,
      permission: [],
      typeGridView: 'select',
      dataSelect: [
        { value: '', name: 'Tất cả', color: '#000000' },
        { value: '1', name: 'Chủ trì', color: '#000000' },
        { value: '2', name: 'Chủ trì', color: '#195FFF' },
        { value: '0', name: 'Phối hợp', color: '#000000' },
      ],
      nameGridView: 'vaiTro',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '80px' }],
      value: 'Cần BC',
      sort: null,
      permission: [],
      typeGridView: 'select',
      nameGridView: 'canBaoCao',
    },
  ],
  listPersonalWorkPage: [
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: 'Stt',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-10 fix-padding',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [{ type: 'width', value: '25%' }],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-27 fix-padding',
      style: [],
      value: 'Giao việc',
      sort: 'GiaoViec',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'giaoViec',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [],
      value: 'Hạn giải quyết',
      sort: 'HanGiaiQuyet',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'hanGiaiQuyet',
    },
    {
      classNames: 'colum-days-remaining fix-padding',
      style: [{ type: 'minWidth', value: '58px' }],
      value: 'Số ngày còn lại',
      sort: null,
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '80px' }],
      value: 'CQBH',
      sort: 'CoQuanBanHanh',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'coQuanBanHanhId',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '80px' }],
      value: 'Vai trò',
      sort: null,
      permission: [],
      typeGridView: 'select',
      dataSelect: [
        { value: '', name: 'Tất cả', color: '#000000' },
        { value: '1', name: 'Chủ trì', color: '#000000' },
        { value: '2', name: 'Chủ trì', color: '#195FFF' },
        { value: '0', name: 'Phối hợp', color: '#000000' },
      ],
      nameGridView: 'vaiTro',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '80px' }],
      value: 'Cần BC',
      sort: null,
      permission: [],
      typeGridView: 'select',
      nameGridView: 'canBaoCao',
    },
  ],
  listPersonalWorkPageDone: [
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: 'Stt',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-10 fix-padding',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [{ type: 'width', value: '25%' }],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-27 fix-padding',
      style: [],
      value: 'Giao việc',
      sort: 'GiaoViec',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'giaoViec',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [],
      value: 'Hạn giải quyết',
      sort: 'HanGiaiQuyet',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'hanGiaiQuyet',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '80px' }],
      value: 'CQBH',
      sort: 'CoQuanBanHanh',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'coQuanBanHanhId',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '80px' }],
      value: 'Vai trò',
      sort: null,
      permission: [],
      typeGridView: 'select',
      dataSelect: [
        { value: '', name: 'Tất cả', color: '#000000' },
        { value: '1', name: 'Chủ trì', color: '#000000' },
        { value: '2', name: 'Chủ trì', color: '#195FFF' },
        { value: '0', name: 'Phối hợp', color: '#000000' },
      ],
      nameGridView: 'vaiTro',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '80px' }],
      value: 'Cần BC',
      sort: null,
      permission: [],
      typeGridView: 'select',
      nameGridView: 'canBaoCao',
    },
  ],
  listSeeToKnowWorkPage: [
    {
      classNames: 'column-2',
      style: [],
      value: 'Stt',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-27',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày giao',
      sort: 'NgayGiao',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'ngayGiao',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Chủ trì',
      sort: 'NguoiChuTri',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'nguoiChuTri',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Người giao',
      sort: 'NguoiGiao',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'nguoiGiaoId',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Trạng thái',
      sort: null,
      permission: [],
      typeGridView: 'select',
      nameGridView: 'trangThaiXDB',
    },
  ],
  listDepartmentWorkAssigned: [
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: 'Stt',
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-10 fix-padding',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [{ type: 'width', value: '30%' }],
      value: 'Trích yếu',
      sort: null,
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-27 fix-padding',
      style: [],
      value: 'Giao việc',
      sort: null,
      typeGridView: 'input',
      nameGridView: 'giaoViec',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [],
      value: 'Hạn giải quyết',
      sort: 'HanGiaiQuyet',
      typeGridView: 'date',
      nameGridView: 'hanGiaiQuyet',
    },
    {
      classNames: 'colum-days-remaining fix-padding',
      style: [],
      value: 'Số ngày còn lại',
      sort: null,
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '70px' }],
      value: 'Trạng thái',
      sort: 'TrangThai',
      typeGridView: 'select',
      nameGridView: 'trangThai',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '70px' }],
      value: 'Vai trò',
      sort: null,
      typeGridView: 'select',
      nameGridView: 'vaiTro',
      dataSelect: [
        { value: '', name: 'Tất cả', color: '#000000' },
        { value: '1', name: 'Chủ trì', color: '#195FFF' },
        { value: '0', name: 'Phối hợp', color: '#000000' },
      ],
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '85px' }],
      value: 'Chủ trì',
      sort: 'ChuTri',
      typeGridView: 'select',
      nameGridView: 'chuTriId',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '70px' }],
      value: 'Cần BC',
      sort: null,
      typeGridView: 'select',
      nameGridView: 'canBaoCao',
    },
  ],
  listPersonalWorkAssigned: [
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: 'Stt',
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2 fix-padding',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-10 fix-padding',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [{ type: 'width', value: '30%' }],
      value: 'Trích yếu',
      sort: null,
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-27 fix-padding',
      style: [],
      value: 'Giao việc',
      sort: null,
      typeGridView: 'input',
      nameGridView: 'giaoViec',
    },
    {
      classNames: 'column-13 fix-padding',
      style: [],
      value: 'Hạn giải quyết',
      sort: 'HanGiaiQuyet',
      typeGridView: 'date',
      nameGridView: 'hanGiaiQuyet',
    },
    {
      classNames: 'colum-days-remaining fix-padding',
      style: [],
      value: 'Số ngày còn lại',
      sort: null,
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '70px' }],
      value: 'Trạng thái',
      sort: 'TrangThai',
      typeGridView: 'select',
      nameGridView: 'trangThai',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '85px' }],
      value: 'Chủ trì',
      sort: 'ChuTri',
      typeGridView: 'select',
      nameGridView: 'chuTriId',
    },
    {
      classNames: 'column-9 fix-padding',
      style: [{ type: 'minWidth', value: '70px' }],
      value: 'Cần BC',
      sort: null,
      typeGridView: 'select',
      nameGridView: 'canBaoCao',
    },
  ],
  listDepartmentWorkNotAssigned: [
    {
      classNames: 'column-2',
      style: [],
      value: '',
      checkAll: false,
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2',
      style: [],
      value: 'Stt',
      checkAll: false,
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'ccolumn-2',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-2',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    // Remove button check complete work
    // {
    //   classNames: '',
    //   style: [
    //     {
    //       type: 'width',
    //       value: 2.5 + '%'
    //     }
    //   ],
    //   value: '',
    //   checkAll: false
    // },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      checkAll: false,
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-20',
      style: [],
      value: 'Trích yếu',
      sort: null,
      checkAll: false,
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    // {
    //   classNames: 'colum-file',
    //   style: [],
    //   value: 'File',
    //   checkAll: false
    // },
    {
      classNames: 'column-27',
      style: [],
      value: 'Chỉ Đạo',
      checkAll: false,
      sort: 'ChiDao',
      typeGridView: 'input',
      nameGridView: 'chiDao',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Hạn giải quyết',
      sort: 'HanGiaiQuyet',
      checkAll: false,
      typeGridView: 'date',
      nameGridView: 'hanGiaiQuyet',
    },
    {
      classNames: 'colum-days-remaining column-2',
      style: [],
      value: 'Số ngày còn lại',
      sort: null,
      checkAll: false,
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-9',
      style: [],
      value: 'Vai trò',
      sort: null,
      checkAll: false,
      typeGridView: 'select',
      nameGridView: 'vaiTro',
      dataSelect: [
        { value: '', name: 'Tất cả', color: '#000000' },
        { value: '1', name: 'Chủ trì', color: '#195FFF' },
        { value: '0', name: 'Phối hợp', color: '#000000' },
      ],
    },
    {
      classNames: 'column-9',
      style: [],
      value: 'CQBH',
      sort: 'NoiGui',
      checkAll: false,
      typeGridView: 'select',
      nameGridView: 'coQuanBanHanhId',
    },
  ],
  listGerenalDocument: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      checkAll: false,
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Số công văn',
      sort: '',
      checkAll: false,
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Trích yếu',
      sort: '',
      checkAll: false,
    },
    {
      classNames: 'colum-command',
      style: [],
      value: 'Ngày Chỉ Đạo',
      checkAll: false,
    },
    {
      classNames: 'colum-date',
      style: [],
      value: 'Hạn giải quyết',
      sort: '',
      checkAll: false,
    },
    {
      classNames: 'colum-date',
      style: [],
      value: 'Ngày giao việc',
      sort: '',
      checkAll: false,
    },
    {
      classNames: 'colum-date',
      style: [],
      value: 'Ngày hoàn thành',
      sort: '',
      checkAll: false,
    },
    {
      classNames: 'colum-date',
      style: [],
      value: 'Tình trạng',
      sort: '',
      checkAll: false,
    },
    {
      classNames: 'colum-role',
      style: [],
      value: 'Chủ trì',
      checkAll: false,
    },
  ],
  listRoleCategory: [
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Mã vai trò',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-50',
      value: 'Tên vai trò',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-15',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  departmentCategory: [
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-30',
      value: 'Tên viết tắt',
      sort: false,
    },
    {
      classNames: 'width-col-30',
      value: 'Tên phòng ban',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Sử dụng',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  unitCategory: [
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-30',
      value: 'Tên viết tắt',
      sort: false,
    },
    {
      classNames: 'width-col-30',
      value: 'Tên đơn vị',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Sử dụng',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  subjectsCategory: [
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-30',
      value: 'Loại đối tượng',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-30',
      value: 'Tên loại',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Sử dụng',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  urgencyCategory: [
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-15',
      value: 'Mã số',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-45',
      value: 'Mô tả',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Sử dụng',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  securityLevelCategory: [
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-15',
      value: 'Mã số',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-45',
      value: 'Mô tả',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Sử dụng',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  regencyCategory: [
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-30',
      value: 'Mã chức vụ',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-30',
      value: 'Tên chức vụ',
      sort: false,
    },
    {
      classNames: 'width-col-20',
      value: 'Sử dụng',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  rolePermissions: [
    {
      classNames: 'width-col-5',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'MÃ QUYỀN',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'VĂN THƯ',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'GIÁM ĐỐC',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'PHÓ GIÁM ĐỐC',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'THƯ KÝ',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'LÃNH ĐẠO PHÒNG BAN',
      sort: true,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'CHUYÊN VIÊN',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'ADMIN',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'SUPPER ADMIN',
      sort: false,
      permission: [],
    },
  ],
  ListProjectPage: [
    {
      classNames: 'width-col-10',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Mã dự án',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-25',
      value: 'Tên dự án',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-40',
      value: 'Sử dụng',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  SaveNoteCategory: [
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Mã sổ',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-30',
      value: 'Tên sổ',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Loại sổ',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-15',
      value: 'Sử dụng',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  decentralizationUser: [
    {
      classNames: 'width-col-10',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'Mã Nhân Viên',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Tên Nhân Viên',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Phòng Ban',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'Chức Vụ',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'User',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'Quyền',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  ListPermissionRole: [
    {
      classNames: 'width-col-5',
      value: '',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-5',
      value: 'STT',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'Mã Nhân Viên',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Tên Nhân Viên',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-20',
      value: 'Phòng Ban',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'Chức Vụ',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'User',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: 'Quyền',
      sort: false,
      permission: [],
    },
    {
      classNames: 'width-col-10',
      value: '',
      sort: false,
      permission: [],
    },
  ],
  documentarySearch: [
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      typeGridView: null,
      nameGridView: '',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày công văn',
      sort: 'NgayCongVan',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'ngayCongVan',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số Công Văn',
      sort: 'SoCongVan',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soCongVan',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày đến',
      sort: 'ngayDen',
      permission: [],
      typeGridView: 'date',
      nameGridView: 'ngayDen',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số đến',
      sort: 'soDen',
      permission: [],
      typeGridView: 'input',
      nameGridView: 'soDen',
    },
    {
      classNames: 'column-35',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      typeGridView: 'input',
      nameGridView: 'trichYeu',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'CQBH',
      sort: 'CoQuanBanHanh',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'coQuanBanHanhId',
    },
  ],
  printSendDocument: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-date',
      style: [],
      value: 'Ngày công văn',
      sort: 'NgayCongVan',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Số Công Văn',
      sort: 'SoCongVan',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Trích yếu',
      sort: 'TrichYeu',
      permission: [],
    },
    {
      classNames: 'colum-number-copies',
      style: [],
      value: 'Số bản',
      sort: 'soBan',
      permission: [],
    },
    {
      classNames: 'colum-adress-send',
      style: [],
      value: 'Nơi nhận',
      sort: 'noiNhan',
      permission: [],
    },
    {
      classNames: 'colum-the-signer',
      style: [],
      value: 'Người ký',
      sort: 'nguoiKy',
      permission: [],
    },
  ],
  printReceiveDocument: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-date',
      style: [],
      value: 'Ngày công văn',
      sort: 'NgayCongVan',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Số Công Văn',
      sort: 'SoCongVan',
      permission: [],
    },
    {
      classNames: 'colum-date-documentary',
      style: [],
      value: 'Ngày đến',
      sort: 'NgayCapNhat',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Số Đến',
      sort: 'SoDen',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Trích Yếu',
      sort: 'TrichYeu',
      permission: [],
    },
    {
      classNames: 'colum-adress-send',
      style: [],
      value: 'Cơ quan ban hành',
      sort: 'CoQuanBanHanh',
      permission: [],
    },
  ],
  handleDocumentary: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị/Phòng ban',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tổng công văn',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Chưa XL',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Đang XL',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Đã XL',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Quá hạn chưa XL',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Quá hạn đã XL',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tổng quá hạn',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tỷ lệ (%)',
      sort: '',
      permission: [],
    },
  ],
  generalSignNumberUnit: [
    [
      {
        classNames: 'colum-stt-first',
        style: [],
        value: 'Stt',
        permission: [],
        rowSpan: 2,
      },
      {
        classNames: 'colum-default-width',
        style: [],
        value: 'Đơn vị',
        sort: '',
        permission: [],
        rowSpan: 2,
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Công văn đi',
        sort: '',
        permission: [],
        colSpan: 4,
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Công văn nội bộ',
        sort: '',
        permission: [],
        colSpan: 4,
      },
    ],
    [
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký tay',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký số',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tổng cộng',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tỷ lệ(%)',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký tay',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký số',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tổng cộng',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tỷ lệ(%)',
        sort: '',
        permission: [],
      },
    ],
  ],
  generalSignNumberDepartment: [
    [
      {
        classNames: 'colum-stt-first',
        style: [],
        value: 'Stt',
        permission: [],
        rowSpan: 2,
      },
      {
        classNames: 'colum-default-width',
        style: [],
        value: 'Phòng ban',
        sort: '',
        permission: [],
        rowSpan: 2,
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Công văn đi',
        sort: '',
        permission: [],
        colSpan: 4,
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Công văn nội bộ',
        sort: '',
        permission: [],
        colSpan: 4,
      },
    ],
    [
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký tay',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký số',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tổng cộng',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tỉ lệ (%)',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký tay',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký số',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tổng cộng',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tỉ lệ (%)',
        sort: '',
        permission: [],
      },
    ],
  ],
  generalSignNumberPersonal: [
    [
      {
        classNames: 'colum-stt-first',
        style: [],
        value: 'Stt',
        permission: [],
        rowSpan: 2,
      },
      {
        classNames: 'colum-default-width',
        style: [],
        value: 'Phòng ban',
        sort: '',
        permission: [],
        rowSpan: 2,
      },
      {
        classNames: 'colum-default-width',
        style: [],
        value: 'Nhân viên',
        sort: '',
        permission: [],
        rowSpan: 2,
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Công văn đi',
        sort: '',
        permission: [],
        colSpan: 4,
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Công văn nội bộ',
        sort: '',
        permission: [],
        colSpan: 4,
      },
    ],
    [
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký sim',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký token',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký tay',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tổng cộng',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký sim',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký token',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Ký tay',
        sort: '',
        permission: [],
      },
      {
        classNames: 'colum-count-deparkment',
        style: [],
        value: 'Tổng cộng',
        sort: '',
        permission: [],
      },
    ],
  ],
  generalWork: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Cá nhân',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Công việc trong kỳ',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Chưa giải quyết',
      sort: '',
      permission: [],
    },
    // {
    //   classNames: 'colum-count-deparkment',
    //   style: [],
    //   value: 'Đang giải quyết',
    //   sort: 'TongSoCongViecDangGiaiQuyet',
    //   permission: []
    // },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Đã giải quyết',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Quá hạn giải quyết',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Cần báo cáo',
      sort: '',
      permission: [],
    },
  ],
  generalWorkUnit: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Công việc trong kỳ',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Chưa giải quyết',
      sort: '',
      permission: [],
    },
    // {
    //   classNames: 'colum-count-deparkment',
    //   style: [],
    //   value: 'Đang giải quyết',
    //   sort: 'TongSoCongViecDangGiaiQuyet',
    //   permission: []
    // },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Đã giải quyết',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Quá hạn giải quyết',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Cần báo cáo',
      sort: '',
      permission: [],
    },
  ],
  generalWorkDepartment: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Phòng ban',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Công việc trong kỳ',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Chưa giải quyết',
      sort: '',
      permission: [],
    },
    // {
    //   classNames: 'colum-count-deparkment',
    //   style: [],
    //   value: 'Đang giải quyết',
    //   sort: 'TongSoCongViecDangGiaiQuyet',
    //   permission: []
    // },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Đã giải quyết',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Quá hạn giải quyết',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Cần báo cáo',
      sort: '',
      permission: [],
    },
  ],
  generalMessenger: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị/ Phòng ban',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tổng số tin nhắn',
      sort: '',
      permission: [],
    },
  ],
  generalMessengerUser: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Nhân viên',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tổng số tin nhắn',
      sort: '',
      permission: [],
    },
  ],
  generalNotMessenger: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị/ Phòng ban',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Nhân viên chưa nhắn tin',
      sort: '',
      permission: [],
    },
  ],
  statisticDocumentCabinetDepartment: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Phòng ban',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tổng số công việc',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Chưa lưu tủ',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Đã lưu tủ',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tỉ lệ (%)',
      // sort: 'tyLe',
      permission: [],
    },
  ],
  statisticDocumentCabinet: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị/Phòng ban',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tổng số công việc',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Chưa lưu tủ',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Đã lưu tủ',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tỉ lệ (%)',
      // sort: 'tyLe',
      permission: [],
    },
  ],
  listAlertMistakeDocument: [
    {
      classNames: 'column-3',
      style: [],
      value: 'STT',
      permission: [],
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Ngày báo nhầm',
      sort: 'NgayBaoNham',
      permission: [],
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      permission: [],
    },
    {
      classNames: 'column-30',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
    },
    {
      classNames: 'column-130px',
      style: [],
      value: 'CQBH',
      sort: 'CoQuanBanHanh',
      permission: [],
    },
    {
      classNames: 'colum-Leadership column-content-alert-mistake column-13',
      style: [],
      value: 'Thông tin báo nhầm',
      sort: null,
      permission: [],
    },
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [],
    },
  ],
  listAlertMistakeWork: [
    {
      classNames: 'column-3',
      style: [],
      value: 'STT',
      permission: [],
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Ngày báo nhầm',
      sort: 'NgayBaoNham',
      permission: [],
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số công văn',
      sort: 'SoCongVan',
      permission: [],
    },
    {
      classNames: 'column-30',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Hạn giải quyết',
      sort: 'HanGiaiQuyet',
      permission: [],
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Chủ trì',
      sort: 'NguoiChuTri',
      permission: [],
    },
    {
      classNames: 'column-content-alert-mistake',
      style: [],
      value: 'Thông tin báo nhầm',
      sort: null,
      permission: [],
    },
    {
      classNames: 'colum-action',
      style: [],
      value: '',
      permission: [],
    },
  ],
  listAdminNotification: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'STT',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Tiêu đề',
      permission: [],
    },
    {
      classNames: 'colum-action',
      style: [],
      value: 'Hiển thị',
      permission: [],
    },
    {
      classNames: 'colum-datetime',
      style: [],
      value: 'Ngày tạo',
      permission: [],
    },
    {
      classNames: 'colum-establishment',
      style: [],
      value: 'Người tạo',
      permission: [],
    },
    {
      classNames: 'colum-organ-enact',
      style: [],
      value: 'Nơi đăng',
      permission: [],
    },
    {
      classNames: 'colum-action',
      style: [],
      value: '',
      permission: [],
    },
  ],
  listEvnDocument: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'STT',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Số công văn',
      permission: [],
    },
    {
      classNames: 'colum-date',
      style: [],
      value: 'Ngày công văn',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Trích yếu',
      permission: [],
    },
    {
      classNames: 'colum-the-signer',
      style: [],
      value: 'Người ký',
      permission: [],
    },
  ],
  documentCabinetOverTime: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: '',
      style: [],
      value: 'Số Công Văn',
      sort: '',
      permission: [],
    },
    {
      classNames: '',
      style: [],
      value: 'Ngày công văn',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Trích yếu',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Cơ quan ban hành',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Người chủ trì',
      sort: '',
      permission: [],
    },
  ],
  stagisticalNumberOverTime: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Số Công Văn',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-date',
      style: [],
      value: 'Ngày công văn',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Trích yếu',
      sort: '',
      permission: [],
    },
  ],
  generalDocument: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Công văn đi',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Công văn đến',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Công văn nội bộ',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Tổng số',
      sort: '',
      permission: [],
    },
  ],
  statisticUser: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị/Phòng ban',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tài khoản eOffice',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tổng số nhân viên',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Tỉ lệ sử dụng (%)',
      sort: '',
      permission: [],
    },
  ],
  listDocumentDigitize: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'VB Đến nhận dạng',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'VB Đến nhập tay',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'VB Đi Nhận dạng',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'VB Đi nhập tay',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Tổng số VB',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Tỉ lệ (%)',
      sort: '',
      permission: [],
    },
  ],
  documentRecovery: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: 'Thu hồi',
      sort: '',
      permission: [],
    },
  ],
  statisticAlertMistakes: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Đơn vị/Phòng ban',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Số lần chỉ đạo nhầm',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Số lần giao việc nhầm',
      sort: '',
      permission: [],
    },
  ],
  statisticAlertMistakesPersonal: [
    {
      classNames: 'colum-stt-first',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'colum-default-width',
      style: [],
      value: 'Cá nhân',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Số lần chỉ đạo nhầm',
      sort: '',
      permission: [],
    },
    {
      classNames: 'colum-count-deparkment',
      style: [],
      value: 'Số lần giao việc nhầm',
      sort: '',
      permission: [],
    },
  ],
  listGerenalPersonalWorkPage: [
    {
      classNames: 'column-2',
      style: [],
      value: 'Stt',
      permission: [],
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số công văn',
      sort: null,
      permission: [],
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
    },
    {
      classNames: 'column-27',
      style: [],
      value: 'Giao việc',
      sort: null,
      permission: [],
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Hạn giải quyết',
      sort: null,
      permission: [],
    },
    {
      classNames: 'column-9',
      style: [],
      value: 'Vai trò',
      sort: null,
      permission: [],
    },
  ],
  procedureCategory: [
    {
      classNames: 'width-col-5',
    },
    {
      classNames: 'width-col-5',
      value: 'Stt',
    },
    {
      classNames: 'width-col-15',
      value: 'Mã danh mục',
    },
    {
      classNames: 'width-col-45',
      value: 'Tên danh mục',
    },
    {
      classNames: 'width-col-20',
      value: 'Sử dụng',
    },
    {
      classNames: 'width-col-10',
    },
  ],
  procedure: [
    {
      classNames: 'width-col-5',
    },
    {
      classNames: 'width-col-5',
      value: 'Stt',
    },
    {
      classNames: 'width-col-25',
      value: 'Mã quy trình',
    },
    {
      classNames: 'width-col-50',
      value: 'Tên quy trình',
    },
    {
      classNames: 'width-col-15',
    },
  ],
  appliedUnits: [
    {
      classNames: 'width-col-5',
      value: 'Stt',
    },
    {
      classNames: 'width-col-15',
      value: 'Tên viết tắt',
    },
    {
      classNames: 'width-col-55',
      value: 'Tên đơn vị',
    },
    {
      classNames: 'width-col-15',
      value: '',
    },
  ],
  procedureUsingGroup: [
    {
      classNames: 'width-col-5 p10',
    },
    {
      classNames: 'width-col-5 p10',
      value: 'stt',
    },
    {
      classNames: 'width-col-65 p10',
      value: 'tên danh mục/quy trình',
    },
    {
      classNames: 'width-col-20 center-text p10',
      value: 'số lượng nhóm',
    },
    {
      classNames: 'width-col-5 p10',
    },
  ],
  procedureUsingGroupDetail: [
    {
      classNames: 'width-col-5',
      value: 'stt',
    },
    {
      classNames: 'width-col-20',
      value: 'Tên thành viên',
    },
    {
      classNames: 'width-col-30',
      value: 'Đơn vị',
    },
    {
      classNames: 'width-col-40',
      value: 'Phòng ban',
    },
    {
      classNames: 'width-col-5',
    },
  ],
  procedureDTXDDetail: [
    {
      classNames: 'width-col-5',
      value: 'stt',
    },
    {
      classNames: 'width-col-20',
      value: 'Tên đơn vị',
    },
    {
      classNames: 'width-col-30',
      value: 'Email',
    },
    {
      classNames: 'width-col-30',
      value: 'Số điện thoại ký số Viettel',
    },
    {
      classNames: 'width-col-30',
      value: 'Số điện thoại ký số VNPT',
    },
    {
      classNames: 'width-col-5',
    },
  ],
  listSignatureConvergePage: [
    {
      classNames: 'column-3',
      value: 'Stt',
    },
    {
      classNames: 'column-10',
      value: 'Số ký hiệu',
      sort: 'SoKyHieu',
      nameGridView: 'soKyHieu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      value: 'Ngày lập',
      sort: 'NgayLap',
      nameGridView: 'ngayLap',
      typeGridView: 'date',
    },
    {
      classNames: 'column-13',
      value: 'Người lập',
      sort: 'NguoiLap',
      nameGridView: 'nguoiLap',
      typeGridView: 'input',
    },
    {
      classNames: 'column-20',
      value: 'Quy Trình',
      nameGridView: 'quyTrinh',
      typeGridView: 'input',
    },
    {
      classNames: 'column-25',
      value: 'Trích yếu',
      nameGridView: 'trichYeu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      value: 'Trạng Thái',
      nameGridView: 'tinhTrang',
      typeGridView: 'select',
    },
    {
      classNames: 'column-3',
    },
  ],
  procedureSelect: [
    {
      classNames: 'width-col-5',
    },
    {
      classNames: 'width-col-5',
      value: 'Stt',
    },
    {
      classNames: 'width-col-90',
      value: 'Tên danh mục/Quy trình',
    },
  ],
  thongKeKstt: [
    {
      classNames: 'colum-stt-first',
      value: 'Stt',
    },
    {
      classNames: 'colum-default-width',
      value: 'Đơn vị',
    },
    {
      classNames: 'colum-number-documentary',
      value: 'Tổng số văn bản',
    },
  ],
  timKiemVanBanKySo: [
    {
      classNames: 'column-3',
      value: 'Stt',
    },
    {
      classNames: 'column-20',
      value: 'Quy Trình',
      nameGridView: 'quyTrinh',
      typeGridView: 'input',
    },
    {
      classNames: 'column-10',
      value: 'Số ký hiệu',
      sort: 'SoKyHieu',
      nameGridView: 'soKyHieu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-15',
      value: 'Ngày lập',
      sort: 'NgayLap',
      nameGridView: 'ngayLap',
      typeGridView: 'date',
    },
    {
      classNames: 'column-15',
      value: 'Người lập',
      sort: 'NguoiLap',
      nameGridView: 'nguoiLap',
      typeGridView: 'input',
    },
    {
      classNames: 'column-30',
      value: 'Trích yếu',
      nameGridView: 'trichYeu',
      typeGridView: 'input',
    },
  ],
  OpinionList: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      // typeGridView: null
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Số ký hiệu',
      sort: 'SoKyHieu',
      permission: [],
      nameGridView: 'soKyHieu',
      // typeGridView: 'input'
    },
    {
      classNames: 'column-32',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      nameGridView: 'trichYeu',
      // typeGridView: 'input'
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày tạo',
      sort: 'NgayLap',
      permission: [],
      nameGridView: 'ngayLap',
      // typeGridView: 'date'
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Người tạo',
      sort: 'NguoiLap',
      permission: [],
      nameGridView: 'nguoiLap',
      // typeGridView: 'input'
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Trạng thái',
      sort: 'TinhTrang',
      permission: [],
      nameGridView: 'tinhTrang',
      // typeGridView: 'select'
    },
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [],
      nameGridView: '',
      // typeGridView: null
    },
  ],
  OpinionSynthesis: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      sort: null,
      value: 'Người cho ý kiến',
      permission: [],
      nameGridView: 'nguoiChoYKien',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      sort: null,
      value: 'Trạng thái',
      permission: [],
      nameGridView: 'trangThai',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      sort: null,
      value: 'Ý kiến',
      permission: [],
      nameGridView: 'yKien',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      sort: null,
      value: 'Thời gian ý kiến',
      permission: [],
      nameGridView: 'thoiGianYKien',
      typeGridView: null,
    },
    {
      classNames: 'column-3',
      style: [],
      sort: null,
      value: '',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
  ],
  listPhieuYeuCauGopY: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Số/ Ký hiệu',
      sort: 'soKyHieu',
      permission: [],
      nameGridView: 'soKyHieu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-32',
      style: [],
      value: 'Tiêu đề',
      sort: null,
      permission: [],
      nameGridView: 'trichYeu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày lập',
      sort: 'ngayLap',
      permision: [],
      nameGridView: 'ngayLap',
      typeGridView: 'date',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Thời hạn',
      sort: 'thoiHan',
      permission: [],
      nameGridView: 'thoiHan',
      typeGridView: 'date',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Người lập',
      sort: 'nguoiTao',
      permission: [],
      nameGridView: 'nguoiLap',
      typeGridView: 'input',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Trạng thái',
      sort: 'tinhTrang',
      permission: [],
      typeGridView: 'select',
      nameGridView: 'tinhTrang',
    },
    {
      classNames: 'colum-number-documentary',
      style: [],
      value: '',
      sort: '',
      permission: [],
    },
  ],
  listThongKeGopY: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Đơn vị',
      sort: null,
      permission: [],
      nameGridView: 'donViGopY',
      typeGridView: 'input',
    },
    {
      classNames: 'column-32',
      style: [],
      value: 'Nội dung góp ý',
      sort: null,
      permission: [],
      nameGridView: 'noiDungGopY',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Trạng thái',
      sort: null,
      permission: [],
      typeGridView: 'select',
      nameGridView: 'tinhTrang',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày góp ý',
      sort: null,
      permission: [],
      nameGridView: 'thoiGianHoanThanh',
      typeGridView: 'date',
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'File góp ý',
      sort: '',
      permission: [],
    },
  ],
  ListService: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'column-7 center',
      style: [],
      value: null,
      permission: [],
      nameGridView: 'hinhAnh',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Tên',
      sort: 'tenNhanVien',
      permission: [],
      nameGridView: 'tenNhanVien',
      typeGridView: null,
    },
    {
      classNames: 'column-32',
      style: [],
      value: 'Mô tả',
      sort: null,
      permission: [],
      nameGridView: 'moTa',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Username',
      sort: null,
      permission: [],
      nameGridView: 'username',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Người được cấp',
      sort: 'tenNguoiDuocCap',
      permission: [],
      nameGridView: 'tenNguoiDuocCap',
      typeGridView: null,
    },
    {
      classNames: 'column-7 center',
      style: [],
      value: 'Sử dụng',
      permission: [],
      nameGridView: 'suDung',
      typeGridView: null,
    },
    {
      classNames: 'column-3',
      style: [],
      value: '',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
  ],
  statisticalServiceList: [
    {
      classNames: 'width-col-5',
    },
    {
      classNames: 'width-col-5',
      value: 'Stt',
    },
    {
      classNames: 'width-col-50',
      value: 'Đơn vị',
      sort: '',
    },
    {
      classNames: 'width-col-20 center',
      value: 'Số lượng',
    },
    {
      classNames: 'width-col-20 center',
      value: 'Tổng số tin nhắn',
    },
  ],
  ListRevokePage: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày công văn',
      sort: 'NgayCongVan',
      permission: [],
      nameGridView: 'ngayCongVan',
      typeGridView: 'date',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Số Công Văn',
      sort: 'soCongVan',
      permission: [],
      nameGridView: 'soCongVan',
      typeGridView: 'input',
    },
    {
      classNames: 'column-32',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      nameGridView: 'trichYeu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-32',
      style: [],
      value: 'Thông tin thu hồi',
      sort: null,
      permission: [],
      nameGridView: 'thongTinThuHoi',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Phòng ban lập',
      sort: 'MaPhongBan',
      permission: [],
      nameGridView: 'phongBanLapId',
      typeGridView: 'select',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Người ký',
      sort: 'TenNhanVien',
      permission: [],
      nameGridView: 'nguoiKyId',
      typeGridView: 'select',
    },
    {
      classNames: 'column-9',
      style: [],
      value: 'Trạng thái',
      permission: [],
      nameGridView: 'trangThai',
      typeGridView: null,
    },
    {
      classNames: 'column-5',
      style: [],
      value: '',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
  ],
  CongVanDenThuHoiDanhDach: [
    {
      classNames: 'column-3',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'column-10',
      style: [],
      value: 'Số đến',
      sort: 'SoDen',
      permission: [],
      nameGridView: 'SoDen',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Ngày công văn',
      sort: 'NgayCongVan',
      permission: [],
      nameGridView: 'ngayCongVan',
      typeGridView: 'date',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'Số Công Văn',
      sort: 'SoCongVan',
      permission: [],
      nameGridView: 'SoCongVan',
      typeGridView: 'input',
    },
    {
      classNames: 'column-32',
      style: [],
      value: 'Trích yếu',
      sort: null,
      permission: [],
      nameGridView: 'trichYeu',
      typeGridView: 'input',
    },
    {
      classNames: 'column-13',
      style: [],
      value: 'CQBH',
      sort: 'CoQuanBanHanh',
      permission: [],
      nameGridView: 'coQuanBanHanhId',
      typeGridView: 'select',
    },
    {
      classNames: 'column-32',
      style: [],
      value: 'Thông tin thu hồi',
      sort: null,
      permission: [],
      nameGridView: 'thongTinThuHoi',
      typeGridView: 'input',
    },
    {},
  ],
  ThoiGianLuuDanhSach: [
    {
      classNames: 'width-col-5 center',
      style: [],
      value: '',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'width-col-5 center',
      style: [],
      value: 'Stt',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'width-col-45',
      style: [],
      value: 'Thời hạn bảo quản',
      permission: [],
      nameGridView: 'tenThoiHanLuu',
      typeGridView: null,
    },
    {
      classNames: 'width-col-20 center',
      style: [],
      value: 'Sử dụng',
      permission: [],
      nameGridView: 'isActive',
      typeGridView: null,
    },
    {
      classNames: 'width-col-5 center',
      style: [],
      value: '',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
  ],
  TuTaiLieuDanhSach: [
    {
      classNames: 'flex-grow-6 pl20',
      style: [],
      sort: false,
      value: 'Thư mục / Hồ sơ công việc',
      permission: [],
      nameGridView: 'ten',
      typeGridView: 'input',
    },
    {
      classNames: 'flex-grow-2',
      style: [],
      sort: false,
      value: 'Mã hồ sơ',
      permission: [],
      nameGridView: 'maKyHieu',
      typeGridView: 'input',
    },
    {
      classNames: 'flex-grow-2',
      style: [],
      value: 'Người chịu trách nhiệm',
      permission: [],
      nameGridView: 'nguoiChiuTrachNhiem',
      typeGridView: 'input',
    },
    {
      classNames: 'flex-grow-2',
      style: [],
      sort: false,
      value: 'Phòng ban lập',
      permission: [],
      nameGridView: '',
      // nameGridView: 'phongBanLapId',
      nameHide: 'phongBanLapHide',
      nameDisabledFilter: 'phongBanLapIdDisabledFilter',
      typeGridView: 'select',
    },
    {
      classNames: 'flex-grow-2',
      style: [],
      sort: false,
      value: 'Thời hạn bảo quản',
      permission: [],
      nameGridView: 'thoiHanBaoQuanId',
      typeGridView: 'select',
    },
    {
      classNames: 'flex-grow-2',
      style: [],
      sort: false,
      value: 'Năm',
      permission: [],
      nameGridView: 'nam',
      typeGridView: 'select',
    },
    {
      classNames: 'flex-grow-2',
      style: [],
      value: 'Trạng thái',
      permission: [],
      nameGridView: 'trangThaiId',
      nameHide: 'trangThaiIdHide',
      nameDisabledFilter: 'trangThaiIdDisabledFilter',
      nameFilterHide: 'trangThaiIdFilterHide',
      typeGridView: 'select',
    },
    {
      classNames: 'center',
      style: [],
      value: '',
      permission: [],
      nameGridView: '',
      nameHide: 'controlHide',
      typeGridView: null,
    },
  ],
  TaiLieuDanhSach: [
    {
      classNames: 'cell-border-right center',
      style: [],
      sort: null,
      value: '',
      permission: [],
      nameGridView: 'sapXep',
      nameHide: 'sapXep',
      typeGridView: null,
    },
    {
      classNames: 'center',
      style: [],
      sort: null,
      value: '',
      permission: [],
      nameGridView: '',
      typeGridView: null,
    },
    {
      classNames: 'flex-grow-3',
      style: [],
      sort: 'soCongVan',
      value: 'Số công văn',
      permission: [],
      nameGridView: 'soCongVan',
      typeGridView: 'input',
    },
    {
      classNames: 'flex-grow-3',
      style: [],
      sort: 'ngayCongVan',
      value: 'Ngày công văn',
      permission: [],
      nameGridView: 'ngayCongVan',
      typeGridView: 'date',
    },
    {
      classNames: 'flex-grow-7',
      style: [],
      sort: null,
      value: 'Tên tài liệu/Trích yếu',
      permission: [],
      nameGridView: 'tenTaiLieu',
      typeGridView: 'input',
    },
    {
      classNames: 'flex-grow-3',
      style: [],
      sort: 'coQuanBanHanh',
      value: 'Cơ quan ban hành',
      permission: [],
      nameGridView: 'coQuanBanHanh',
      typeGridView: 'input',
    },
    {
      classNames: 'flex-grow-3',
      style: [],
      sort: 'tenNhanVien',
      value: 'Người lưu',
      permission: [],
      nameGridView: 'nguoiLuu',
      typeGridView: 'input',
    },
  ],
  thongKeTuTaiLieu: [
    {
      classNames: 'colum-stt-first',
      value: 'Stt',
    },
    {
      classNames: 'colum-default-width',
      value: 'Đơn vị/Phòng ban',
      valueCustom: 'name',
    },
    {
      classNames: 'colum-count-deparkment',
      value: 'Tổng số công việc',
    },
    {
      classNames: 'colum-count-deparkment',
      value: 'Chưa lưu',
    },
    {
      classNames: 'colum-count-deparkment',
      value: 'Đã lưu tủ/hscv',
    },
    {
      classNames: 'colum-count-deparkment',
      value: 'Tỷ lệ (%)',
    },
  ],
  congVanChuaLuu: [
    {
      classNames: 'colum-stt-first',
      value: 'Stt',
    },
    {
      classNames: '',
      value: 'Số công văn',
      sort: 'soCongVan',
    },
    {
      classNames: '',
      value: 'Ngày công văn',
      sort: 'ngayCongVan',
    },
    {
      classNames: '',
      value: 'Tên tài liệu/Trích yếu',
      sort: 'trichYeu',
    },
    {
      classNames: '',
      value: 'Cơ quan ban hành',
      sort: 'coQuanBanHanh',
    },
    {
      classNames: '',
      value: 'Người chủ trì',
      sort: 'tenNhanVien',
    },
  ],
  ungDungChiaSe: [
    {
      classNames: 'column-3',
      value: 'Stt',
    },
    {
      classNames: '',
      value: 'Tên ứng dụng',
    },
    {
      classNames: '',
      value: 'Key',
    },
    {
      classNames: '',
      value: 'Ngày tạo',
    },
    {
      classNames: 'column-3',
      value: '',
    },
  ],
  phanQuyenChiaSe: [
    {
      classNames: 'column-3',
      value: 'Stt',
    },
    {
      classNames: '',
      value: 'Tên api',
    },
    {
      classNames: '',
      value: 'Authen token',
    },
    {
      classNames: '',
      value: 'Ngày tạo',
    },
    {
      classNames: '',
      value: 'Ngày hết hạn',
    },
    {
      classNames: 'center',
      value: 'Sử dụng',
    },
    {
      classNames: 'column-3',
      value: '',
    },
  ],
}
