import React, { useEffect, useRef, useCallback } from 'react'
import csx from 'classnames'
import { debounce } from 'lodash'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import KanbanItem from './item'

const KanbanList = ({
  list,
  listKey: key,
  index,
  title,
  classTitle,
  onItemClick,
  loadFunc,
  total,
}) => {
  const scrollableRef = useRef()

  const hanldeScroll = useCallback(
    debounce(e => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5
      const limit = parseInt(e.target.dataset['limit'], 10)
      const t = parseInt(e.target.dataset['total'], 10)
      if (bottom && limit < t) {
        loadFunc(key, limit + 10)
      }
    }, 300),
    [loadFunc]
  )

  useEffect(() => {
    const sRef = scrollableRef.current
    sRef.addEventListener('scroll', hanldeScroll)
    return () => sRef.removeEventListener('scroll', hanldeScroll)
  }, [hanldeScroll])

  return (
    <Draggable draggableId={key} key={key} index={index}>
      {(provided, snapshot) => (
        <div
          className={csx('cpc-kanban-column')}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <h3
            {...provided.dragHandleProps}
            aria-label={`quote list`}
            className={csx(
              'cpc-kanban-card',
              'cpc-kanban-title',
              `title-${classTitle}`
            )}
          >
            <span className="title-name">{title}</span>
          </h3>
          <div>
            <Droppable droppableId={key}>
              {(dropProvided, dropSnapshot) => (
                <div
                  ref={scrollableRef}
                  data-total={total}
                  data-limit={list.length}
                  className={csx('cpc-kanban-block', {
                    'cpc-kanban-column-scroll': list.length > 2,
                  })}
                  {...dropProvided.droppableProps}
                >
                  <div
                    className="cpc-kanban-dropzone"
                    ref={dropProvided.innerRef}
                  >
                    {list?.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={`${item.id}`}
                          index={index}
                        >
                          {(dragProvided, dragSnapshot) => (
                            <div
                              key={`${item.id}`}
                              className={csx(
                                'cpc-kanban-card',
                                'cpc-kanban-card-children'
                              )}
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                            >
                              <KanbanItem
                                onItemClick={onItemClick}
                                item={item}
                                isProcessing={key === 'DOING'}
                                isCompleted={key === 'DONE'}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {dropProvided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default KanbanList
