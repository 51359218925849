import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import KanbanList from './list'
import useKanban from './use-kanban'

const Kanban = ({
  dataSource = [],
  dataAfterSortOrUpdate,
  currentUserId,
  onUpdateData,
  onSortWotkItem,
  onUpdateDataAfterSortOrUpdate,
  onItemClick,
}) => {
  const { classes, titles, loadMore, onDragEnd } = useKanban({
    dataSource,
    dataAfterSortOrUpdate,
    currentUserId,
    onUpdateDataAfterSortOrUpdate,
    onUpdateData,
    onSortWotkItem,
  })

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="board" type="COLUMN" direction="horizontal">
          {provided => (
            <div
              className="cpc-kanban-wrapper"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {dataSource?.map((item, index) => (
                <KanbanList
                  key={item.status}
                  onItemClick={onItemClick}
                  list={item.items}
                  classTitle={classes[item.status]}
                  currentUserId={currentUserId}
                  title={titles[item.status]}
                  listKey={item.status}
                  index={index}
                  total={item.totalCount}
                  loadFunc={loadMore}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

Kanban.propTypes = {
  dataSource: PropTypes.array.isRequired,
  dataAfterSortOrUpdate: PropTypes.object,
  currentUserId: PropTypes.string,
  onUpdateData: PropTypes.func,
  onSortWotkItem: PropTypes.func,
  onItemClick: PropTypes.func,
}

Kanban.defaultProps = {
  dataSource: [],
  dataAfterSortOrUpdate: null,
  currentUserId: '',
  onUpdateData: () => {},
  onSortWotkItem: () => {},
  onItemClick: () => {},
}

export default Kanban
