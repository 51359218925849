import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Radio, RadioGroup } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'
import { get, isEmpty, size } from 'lodash'
import moment from 'moment'
import arrayToTree from 'array-to-tree'

import { Dialog, Upload, FileGroup, Scrollbar } from 'components/newCommon'
import JobProjectTree from './JobProjectTree'
import { Input, Select } from 'components/newCommon2'
import { documentTypes } from 'constants/fileTypes'
import { Permission } from 'components/auth'
import {
  documentsCabinetGetListCategoriesUnit,
  documentsCabinetGetListCategoriesPersonal,
  documentsCabinetGetListCategoriesDepartment,
  getLoaiLuuTaiLieu,
  postTaiLieuHoSoCongViec,
  documentsCabinetSaveReceiveDocument,
  getListCabinetOfDocument,
} from 'actions'
import {
  CABINET_UNIT,
  CABINET_DEPARTMENT,
  CABINET_PERSONAL,
  HOSO_CONGVIEC,
} from 'constants/Api'
import { getCongViec, postUpload } from 'actions/task'
import requestAction from 'helpers/request'
import { Action, Toast, CHON_NGAN_TU } from 'constants/MessageForm'
import { FILE_TYPE } from 'constants/Enum'
import { toDecamelizeKeys } from 'helpers/key'
import { checkSearchNoAccents } from 'helpers/string'
import { TaskContext } from '../AllTask'

const emptyReferenceFileMessage = `Công việc này không có công văn đính kèm, không thể lưu tủ tài liệu.
Anh/Chị vui lòng kiểm tra lại!`

const cabinetTypes = {
  [CABINET_UNIT]: 'unit',
  [CABINET_DEPARTMENT]: 'department',
  [HOSO_CONGVIEC]: 'profile',
  [CABINET_PERSONAL]: 'personal',
}

const getFilterYears = () => {
  const START_YEAR = '2020'
  const YEAR_FORMAT = 'YYYY'
  const years = []
  const start = moment(START_YEAR, YEAR_FORMAT)
  const end = moment().startOf('year')
  while (end.diff(start, 'years') >= 0) {
    years.push(start.format(YEAR_FORMAT))
    start.add(1, 'year')
  }
  return years
}

const SaveDocumentCabinet = ({
  onClose = () => {},
  actions: {
    unitFolders,
    departmentFolders,
    personalFolders,
    profileFolders,
    postHSCV,
    postTTL,
    getListCabinetOfDocument,
  },
  currentUserPermissions,
  roleId,
  listCabinetOfDocument,
  cabinets,
  match,
}) => {
  const dataLoaiTu = useMemo(
    () => [
      ...(currentUserPermissions.includes(Permission.TUTAILIEUDONVI_QUANLY)
        ? [
            {
              id: CABINET_UNIT,
              name: 'Tủ đơn vị',
              disabled: !currentUserPermissions.includes(
                Permission.TUTAILIEUDONVI_QUANLY
              ),
            },
          ]
        : []),
      {
        id: CABINET_DEPARTMENT,
        name: 'Tủ phòng ban',
      },
      {
        id: HOSO_CONGVIEC,
        name: 'Hồ sơ công việc',
        disabled: !currentUserPermissions.includes(
          Permission.TUTAILIEUPHONGBAN
        ),
      },
      {
        id: CABINET_PERSONAL,
        name: 'Tủ cá nhân',
      },
    ],
    [currentUserPermissions]
  )

  const { onSaveDocumentSuccess } = useContext(TaskContext)

  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const { id } = match.params

  const [cabinet, setCabinet] = useState(CABINET_PERSONAL)
  const [taskDetail, setTaskDetail] = useState({})
  const [foldersFetching, setFoldersFetching] = useState(true)
  const [disableFetching, setDisableFetching] = useState(true)
  const [detailFetching, setDetailFetching] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [filter, setFilter] = useState({
    year: moment().year(),
  })
  const [formData, setFormData] = useState({})
  const [validates, setValidates] = useState({})
  const [cabinetCategoriesFilter, setCabinetCategoriesFilter] = useState([])

  // Lưu file tạm trước khi upload
  const [files, setFiles] = useState([])

  const cabinetCategories = get(cabinets, cabinetTypes[cabinet])
  // Set dữ liệu tủ tài liệu vào model Filter
  useEffect(() => {
    _mountedSet(setCabinetCategoriesFilter, cabinetCategories)
  }, [cabinetCategories])

  const checkShow = (list = [], disabled = []) => {
    const newList = list.reduce((result, item) => {
      if (
        [
          get(item, 'nguoiChiuTrachNhiem') || {},
          ...(get(item, 'dsNguoiPhoiHop') || []),
        ]
          .map(v => get(v, 'chucDanhId'))
          .includes(roleId) === false &&
        item.loai === 1
      ) {
        return result
      }

      const child = checkShow(get(item, 'dsHoSoCongViecCon', []), disabled)
      if (!item.loai && !size(child)) {
        return result
      }

      return [
        ...result,
        {
          ...item,
          dsHoSoCongViecCon: child,
        },
      ]
    }, [])

    return newList
  }

  const fetchingActions = useMemo(
    () => ({
      [CABINET_UNIT]: unitFolders,
      [CABINET_DEPARTMENT]: departmentFolders,
      [CABINET_PERSONAL]: personalFolders,
      [HOSO_CONGVIEC]: () =>
        profileFolders({ loai: 1, phanLoaiTu: 'PHONGBAN' }),
    }),
    [unitFolders, departmentFolders, personalFolders, profileFolders]
  )

  const fetchFolders = useCallback(async () => {
    try {
      _mountedSet(setFoldersFetching, true)
      await fetchingActions[cabinet]()
    } catch (err) {
      throw err
    } finally {
      _mountedSet(setFoldersFetching, false)
    }
  }, [cabinet, fetchingActions])

  const changeCabinetType = e => {
    const { value } = e.target
    _mountedSet(setCabinet, value)
    _mountedSet(setFiles, [])
  }

  const selectCabinet = folderIds => {
    _mountedSet(setFormData, prevData => ({
      ...prevData,
      folderIds,
    }))
  }

  // Chọn files
  const changeFile = fileList => {
    _mountedSet(setFiles, fileList)
  }

  // Upload file
  const _upload = async () => {
    const uploadList = Object.values(files).flat()
    if (!size(uploadList)) {
      return []
    }

    let responseFiles = []
    const success = await requestAction({
      action: () => postUpload(uploadList),
      afterResponse: (result = []) => {
        responseFiles = result
      },
      showToastSucess: false,
      codeCheck: false,
      errorMessage: Toast.FAIL(Action.UPLOAD),
    })
    if (!success) {
      throw new Error()
    }

    return responseFiles
  }

  // Xóa file
  const removeFile = removeFile => {
    if (removeFile.id) {
      // Xóa file đã upload lên server
      _mountedSet(setTaskDetail, prevTasks => ({
        ...prevTasks,
        attachmentFiles: prevTasks.attachmentFiles.filter(
          file => file.id !== removeFile.id
        ),
      }))
    } else {
      // Xóa file chọn từ file upload
      _mountedSet(
        setFiles,
        files.filter(elm => elm.uid !== removeFile.uid)
      )
    }
  }

  const changeNote = text => {
    _mountedSet(setFormData, prev => ({
      ...prev,
      note: text,
    }))
  }

  const changeFilter = name => value => {
    _mountedSet(setFilter, prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const validateFolder = () => {
    const pass = !!size(formData?.folderIds)
    if (!pass) {
      _mountedSet(setValidates, prev => ({
        ...prev,
        folder: CHON_NGAN_TU,
      }))
    }
    return pass
  }

  const validate = () => {
    const success = validateFolder()
    if (!success) {
      throw new Error(`[warning]${CHON_NGAN_TU}`)
    }
  }

  const validateReferenceFiles = () => {
    const success = !!size(taskDetail?.referenceFiles)
    if (!success) {
      throw new Error(`[warning]${emptyReferenceFileMessage}`)
    }
  }

  const fileFormat = file =>
    !file
      ? undefined
      : {
          file_id: file?.id,
          ten_file: file?.name,
          url: file?.url,
          kieu_file: file?.mime,
          kich_thuoc: file?.size,
          is_existed: file?.application === 'eOffice',
        }

  const convertToRequestData = async () => {
    const responseFiles = await _upload()
    const { title, reference, referenceFiles, attachmentFiles } = taskDetail
    const { note, folderIds = [] } = formData
    if (cabinet === HOSO_CONGVIEC) {
      return {
        tenTaiLieu: title,
        ghiChu: note,
        congVanId: reference?.congVanId,
        congVanNoiBoId: reference?.congVanNoiBoId,
        dsHoSoCongViecId: folderIds,
        soCongVan: reference?.soCongVan,
        fileNoiDung: fileFormat(referenceFiles[0]),
        fileDinhKem: [...attachmentFiles]
          .filter(elm => elm.application === 'eOffice')
          .map(fileFormat),
        fileDinhKemBenNgoai: [...attachmentFiles, ...responseFiles]
          .filter(elm => elm.application !== 'eOffice')
          .map(fileFormat),
      }
    }

    return {
      dsNganTu: folderIds || [],
      loaiTuTaiLieu: +cabinet,
      ghiChu: note,
      dsFileCongVan: referenceFiles.map(file => ({
        fileId: file.id,
        congVanDenId: reference?.congVanDenId,
        congVanNoiBoId: reference?.congVanNoiBoId,
        tenTaiLieu: title,
      })),
    }
  }

  const submit = () => {
    requestAction({
      codeCheck: false,
      beforeAction: () => {
        _mountedSet(setSubmiting, true)
        validateReferenceFiles()
        validate()
      },
      action: async () => {
        const requestData = toDecamelizeKeys(await convertToRequestData())
        if (cabinet === HOSO_CONGVIEC) {
          return postHSCV(requestData)
        }
        return postTTL(requestData)
      },
      afterResponse: () => {
        if ([HOSO_CONGVIEC, CABINET_DEPARTMENT].includes(cabinet)) {
          onSaveDocumentSuccess(id)
        }
        onClose()
      },
      afterAction: () => _mountedSet(setSubmiting, false),
      successMessage: Toast.SUCCESS(Action.SAVE),
      errorMessage: Toast.FAIL(Action.SAVE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const _fetchDetail = useCallback(() => {
    requestAction({
      showToast: false,
      codeCheck: false,
      beforeAction: () => _mountedSet(setDetailFetching, true),
      action: () => getCongViec(id),
      afterResponse: ({ title, files, referenceId, reference }) => {
        if (!referenceId) {
          return onClose()
        }
        _mountedSet(setTaskDetail, {
          title,
          referenceFiles: (files || []).filter(
            file => file.type === FILE_TYPE.FILE_THAM_KHAO
          ),
          attachmentFiles: (files || [])
            .filter(file => file.type === FILE_TYPE.FILE_DINH_KEM)
            .map(file => ({
              ...file,
              showClose: file?.application !== 'eOffice',
              is_existed: file?.application === 'eOffice',
            })),
          reference,
        })
      },
      afterAction: () => _mountedSet(setDetailFetching, false),
    })
  }, [id, onClose])

  // Trải phẳng dữ liệu tủ tài liệu trước khi filter dữ liệu
  const _flatData = useCallback((array = []) => {
    return array.reduce(
      (result, item) => [
        ...result,
        {
          id: item.id,
          level: item.level,
          loai: item.loai,
          maKyHieu: item.maKyHieu,
          ten: item.ten,
          tenNganTu: item.tenNganTu,
          thuMucChaId: item.thuMucChaId,
          trangThai: item.trangThai,
          dsNguoiPhoiHop: item.dsNguoiPhoiHop,
          nguoiChiuTrachNhiem: item.nguoiChiuTrachNhiem,
          nam: item.nam,
        },
        ..._flatData(item.dsHoSoCongViecCon),
      ],
      []
    )
  }, [])

  // Without Flat
  // Điều kiện kiểm tra: Nếu là hồ sơ công việc có trạng thái chưa nộp và nhỏ hơn hoặc bằng năm hiện tại
  const _treeData = useCallback(
    (array = []) => {
      return array.reduce((result, item) => {
        if (
          filter.year &&
          item.loai === 1 &&
          item.nam &&
          (item.nam === Number(get(filter, 'year', 0)) ||
            (item.nam < Number(get(filter, 'year', 0)) && item.trangThai === 0))
        ) {
          return [...result, item]
        }

        if (item.dsHoSoCongViecCon && item.dsHoSoCongViecCon.length) {
          return [
            ...result,
            { ...item, dsHoSoCongViecCon: _treeData(item.dsHoSoCongViecCon) },
          ]
        }

        return result
      }, [])
    },
    [filter]
  )

  // Func lọc dữ liệu lưu tủ tài liệu
  const _filterData = useCallback(() => {
    let newResult = [...cabinetCategories]
    if (cabinet === HOSO_CONGVIEC) {
      newResult = _treeData(newResult)
    }

    if (!isEmpty(filter) && filter.title) {
      newResult = _flatData(newResult)
      newResult = newResult.filter(
        elm =>
          ((elm.loai === 1 && cabinet === HOSO_CONGVIEC) ||
            cabinet !== HOSO_CONGVIEC) &&
          filter.title &&
          (checkSearchNoAccents(
            get(elm, cabinet === HOSO_CONGVIEC ? 'ten' : 'tenNganTu', ''),
            get(filter, 'title', '')
          ) ||
            (elm.maKyHieu &&
              checkSearchNoAccents(
                get(elm, 'maKyHieu', ''),
                get(filter, 'title', '')
              )))
      )
    }

    _mountedSet(setCabinetCategoriesFilter, newResult)
  }, [filter, cabinetCategories, cabinet, _flatData, _treeData])

  // Gọi lại dữ liệu mới khi xóa tìm kiếm trong input search
  const filterClear = name => () => {
    _mountedSet(setFilter, prev => ({
      ...prev,
      [name]: undefined,
    }))
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    fetchFolders()
  }, [fetchFolders])

  useEffect(() => {
    _fetchDetail()
  }, [_fetchDetail])

  useEffect(() => {
    _filterData()
  }, [_filterData])

  const getListDisable = useCallback(async () => {
    const id =
      get(taskDetail, 'reference.congVanId') ||
      get(taskDetail, 'reference.congVanNoiBoId')

    if (!id) return _mountedSet(setDisableFetching, false)
    try {
      _mountedSet(setDisableFetching, true)
      const docType = get(taskDetail, 'reference.congVanNoiBoId') ? 2 : 1
      await getListCabinetOfDocument(id, docType)
    } catch (err) {
      throw err
    } finally {
      _mountedSet(setDisableFetching, false)
    }
  }, [taskDetail, getListCabinetOfDocument])

  useEffect(() => {
    getListDisable()
  }, [getListDisable])

  const isHSCVCabinet = cabinet === HOSO_CONGVIEC
  const attachmentFilesFromDocument = !!size(taskDetail?.attachmentFiles)
    ? taskDetail.attachmentFiles.filter(elm => elm.application === 'eOffice')
    : []
  const attachmentFilesNotFromDocument = !!size(taskDetail?.attachmentFiles)
    ? taskDetail.attachmentFiles.filter(elm => elm.application !== 'eOffice')
    : []
  return (
    <Dialog
      isOpen
      width={843}
      title="Lưu tủ tài liệu"
      onClose={onClose}
      onCancel={onClose}
      onConfirm={submit}
      cancelClassName="font-size-12 p10"
      cancelRightIcon={<i className="icon-Huy ml5 size-icon-10" />}
      cancelText="Hủy"
      confirmClassName="font-size-12 p10"
      confirmRightIcon={<i className="icon-save ml5 size-icon-10" />}
      confirmText="Lưu"
      confirmDisabled={detailFetching || disableFetching}
      buttonUppercase
      contentPadding={false}
      footerPaddingClass="p15"
      loading={submiting}
    >
      <div className="task-document-cabinet">
        <div className="task-document-cabinet__row">
          <div className="task-document-cabinet__row__left">
            <div className="p20">
              <RadioGroup
                name="loaiNganTu"
                className="task-document-cabinet__radio--group"
                onChange={changeCabinetType}
                selectedValue={cabinet}
              >
                {dataLoaiTu.map(({ id, name, disabled }) => (
                  <Radio key={id} value={id} label={name} disabled={disabled} />
                ))}
              </RadioGroup>
            </div>
          </div>
          <div className="task-document-cabinet__row__right">
            <div className="pt15 pr20 pb5 pl20 cpc-form">
              <h2 className="task-document-cabinet__title">
                {taskDetail?.title}
              </h2>
              <div className="task-document-cabinet__filter mb10">
                <Input
                  disabled={false}
                  maxLength={500}
                  placeholder="Nhập mã hồ sơ hoặc tên"
                  value={filter?.title}
                  iconClassName="icon2-search"
                  clearable
                  onClear={filterClear('title')}
                  onEnter={changeFilter('title')}
                />

                {isHSCVCabinet && (
                  <span style={{ width: 160, marginLeft: 10 }}>
                    <Select
                      dataSource={getFilterYears().map(year => ({
                        value: year,
                        label: year,
                      }))}
                      value={get(filter, 'year').toString()}
                      disabled={false}
                      onChange={changeFilter('year')}
                      placeholder="Chọn năm"
                      // inputClearable
                      onClear={filterClear('year')}
                      isShowArrowDropdown
                    />
                  </span>
                )}
              </div>

              <div
                className="task-document-cabinet__card mb10"
                style={{
                  padding: 15,
                  paddingBottom: 5,
                  marginBottom: 10,
                  borderColor: validates?.folder ? '#c02e2e' : '#dfe6ea',
                }}
              >
                <Scrollbar maxHeight="476px">
                  <JobProjectTree
                    loadingTree={foldersFetching || disableFetching}
                    data={
                      isHSCVCabinet
                        ? checkShow(cabinetCategoriesFilter)
                        : arrayToTree(cabinetCategoriesFilter, {
                            parentProperty: 'nganTuChaId',
                            childrenProperty: 'dsNganTuCon',
                          })
                    }
                    listDisable={listCabinetOfDocument}
                    onCheck={selectCabinet}
                    modelTree={
                      isHSCVCabinet ? 'dsHoSoCongViecCon' : 'dsNganTuCon'
                    }
                    modelTreeName={isHSCVCabinet ? 'ten' : 'tenNganTu'}
                    modelCodeName={isHSCVCabinet ? 'maKyHieu' : 'kyMaHieu'}
                    typeCabinet={cabinet}
                    allowParentChecked={!isHSCVCabinet}
                  />
                </Scrollbar>
              </div>

              <Input
                disabled={false}
                label="Ghi chú"
                maxLength={500}
                onChange={changeNote}
                value={formData?.note}
                placeholder="Nhập ghi chú..."
                rows={1}
                type="textarea"
                wrapperClassName="mb10"
              />

              {!!size(taskDetail?.referenceFiles) && (
                <div className="task-document-cabinet__card mb10 ph10 pv15">
                  <label className="cpc-detail-label">File công văn</label>
                  <FileGroup list={taskDetail.referenceFiles} />
                </div>
              )}

              {/* Danh sách file từ công văn */}
              {!!size(attachmentFilesFromDocument) && (
                <div className="task-document-cabinet__card mb10 ph10 pv15">
                  <label className="cpc-detail-label">
                    File đính kèm từ công văn
                  </label>
                  <FileGroup
                    list={attachmentFilesFromDocument}
                    showClose
                    onClose={removeFile}
                  />
                </div>
              )}
              {/* Danh sách file từ bên ngoài */}
              {(!!size(attachmentFilesNotFromDocument) || !!size(files)) &&
                isHSCVCabinet && (
                  <div className="task-document-cabinet__card mb10 ph10 pv15">
                    <label className="cpc-detail-label">
                      File đính kèm từ bên ngoài
                    </label>
                    <FileGroup
                      list={attachmentFilesNotFromDocument}
                      listUpload={files}
                      showClose
                      onClose={removeFile}
                    />
                  </div>
                )}
              {isHSCVCabinet && (
                <Upload
                  disabled={false}
                  accept={documentTypes.toString()}
                  fileList={files || []}
                  onChange={changeFile}
                  wrapperClassName="mb10"
                  textInfo="File đính kèm"
                  isShowList={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const mapStateToProps = state => {
  const folderTypes = state.documentsCabinet.type
  const folderDetails = state.entities.documentCabinetCategories
  const getDetails = folderIds => folderIds.map(id => folderDetails[id])

  return {
    currentUserPermissions: get(state, 'auth.permission'),
    roleId: get(state, 'auth.roleId'),
    cabinets: {
      unit: getDetails(folderTypes.unit),
      department: getDetails(folderTypes.department),
      personal: getDetails(folderTypes.personal),
      profile: getDetails(folderTypes.profile),
    },
    listCabinetOfDocument: get(state, 'common.listCabinetOfDocument').map(
      item => item.id
    ),
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      unitFolders: documentsCabinetGetListCategoriesUnit,
      departmentFolders: documentsCabinetGetListCategoriesDepartment,
      personalFolders: documentsCabinetGetListCategoriesPersonal,
      profileFolders: getLoaiLuuTaiLieu,
      postHSCV: postTaiLieuHoSoCongViec,
      postTTL: documentsCabinetSaveReceiveDocument,
      getListCabinetOfDocument,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(SaveDocumentCabinet))
)
