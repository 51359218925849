import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import { Button, Intent } from '@blueprintjs/core'
import FileDownload from 'file-saver'

import { MasterLayout } from '../../components/layout'
import {
  ListFilter,
  ListItemMessenger,
  TableView,
} from '../../components/Utilities/StatisticMessenger'
import * as Tool from '../../helpers'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import HeadingPanel from 'components/common/HeadingPanel'

class ListStatisticMessengerUnit extends React.Component {
  constructor(props) {
    super(props)
    const donViId =
      this.props.match && this.props.match.params && this.props.match.params.id
    this.state = {
      isShowMessengStatistics: true,
      isLoading: false,
      isEmpty: false,
      isError: false,
      isExporting: false,
      filter: {
        startDate: moment().subtract(1, 'months').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
      },
      page: 1,
      donViId,
    }

    this.submitFilter = this.submitFilter.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
  }

  async componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData(
      'ListStatisticMessenger',
      null,
      'thong-ke-tin-nhan'
    )

    if (preData) {
      state.filter = preData.filter
      await this.setState(state)
    }

    if (this.props.location && this.props.location.state) {
      let state = this.state
      const { isCheck } = this.props.location.state
      if (isCheck) {
        this.submitFilter(state.filter, this.state.page)
      }
    }

    let isStatisticSubmit = Tool.Utils.getData('isStatisticMessenger')
    if (isStatisticSubmit) {
      Tool.Utils.saveData('isStatisticMessenger', false)
      this.props.history.replace({
        state: {
          isCheck: true,
        },
      })
      this.submitFilter(this.state.filter, this.state.page)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match !== this.props.match
    ) {
      this.setState({ donViId: nextProps.match.params.id })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.donViId !== this.state.donViId) {
      this.submitFilter(this.state.filter, this.state.page)
    }
  }

  handleExport() {
    let { filter, donViId } = this.state
    if (donViId) {
      filter.don_vi_id = donViId
    }
    this.setState({ isExporting: true }, () => {
      this.props.actions
        .exportStatisticMessenger(filter)
        .then(res => {
          if (res.error || !res.payload || !res.payload.data) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          let preData = Tool.Utils.getData('ListStatisticMessenger')
          FileDownload.saveAs(
            res.payload.data,
            `thong-ke-tin-nhan-${moment(preData.filter.startDate).format(
              'L'
            )}-${moment(preData.filter.endDate).format('L')}.xls`
          )
        })
        .finally(() => {
          this.setState({ isExporting: false })
        })
    })
  }

  submitFilter(e = {}) {
    const filter = Object.assign({}, this.state.filter, e)
    const filterSubmit = {
      ...filter,
      don_vi_id: this.state.donViId,
    }
    this.setState(
      { filter, isLoading: true, isShowMessengStatistics: false },
      () => {
        Tool.Utils.preData(
          'ListStatisticMessenger',
          { filter },
          'thong-ke-tin-nhan'
        )
        this.props.actions
          .getStatisticMessenger(
            filterSubmit,
            this.props.config.refreshStatistic
          )
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true
            }
            if (res.error || !res.payload || res.payload.status !== 200) {
              isError = false
            }
            this.setState({ isEmpty, isError })
          })
          .finally(() => {
            this.props.actions.refreshStatistic(true)
            this.setState({ isLoading: false })
          })
      }
    )
  }

  handleClickGoBack = async () => {
    await this.props.actions.refreshStatistic(false)
    Tool.Utils.saveData('isStatisticMessenger', true)
    this.props.history && this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container page-statistic-signnumber">
          <HeadingPanel
            goBack={this.state.donViId ? this.handleClickGoBack : null}
            leftTitle="Thống kê tin nhắn"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading mb10"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.isExporting}
                disabled={
                  this.state.isShowMessengStatistics ||
                  this.state.isLoading ||
                  this.state.isEmpty
                }
              />
            }
          />
          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              onSubmit={this.submitFilter}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              isLoading={this.state.isLoading}
              filter={this.state.filter}
              isForChildUnit={typeof this.state.donViId === 'string'}
            />

            <TableView
              heading="Thống kê tổng số tin nhắn"
              page={this.state.page}
              header={headers.generalMessenger}
              itemShape={ListItemMessenger}
              data={this.props.statisticMessenger.messenger.list || []}
              isLoading={this.state.isLoading}
              isEmpty={this.state.isEmpty}
              filter={this.state.filter}
              history={this.props.history}
              isError={this.state.isError}
              type={Types.TYPE_STATISTIC_MESSENGER}
              isExporting={this.state.isExporting}
              onExport={this.handleExport}
              isForChildUnit={typeof this.state.donViId === 'string'}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  statisticMessenger: state.statisticMessenger,

  auth: state.auth,

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListStatisticMessengerUnit)
