import React, { memo } from 'react'
import File from './File'

const FileCreateWork = ({ dataSource = [], onClose, onClick }) => {
  return (
    <span className="cpc-file-group">
      {dataSource.map((item, index) => (
        <File key={index} file={item} onClose={onClose} onClick={onClick} />
      ))}
    </span>
  )
}

export default memo(FileCreateWork)
