import React, { memo, useCallback, useEffect, useState, useRef } from 'react'
import { Button, Intent, Radio } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'
import csx from 'classnames'
import { get } from 'lodash'
import moment from 'moment'

import { Action, Toast } from 'constants/MessageForm'
import { Icon } from 'components/newCommon'
import { FormDatetimePicker } from 'components/newCommon2'
import { UNSET, WORK_REMINDER } from 'constants/Enum'
import requestAction from 'helpers/request'
import { getCongViec, patchReminder } from 'actions/task'

const FORM_TITLE = 'Nhắc việc'
const DEFAULT_SELECTED = {
  type: UNSET,
  date: null,
}
const selectData = [
  { value: UNSET, label: 'Không nhắc' },
  { value: WORK_REMINDER.TRUOC_MOT_NGAY, label: 'Trước 1 ngày' },
  { value: WORK_REMINDER.TRUOC_HAI_NGAY, label: 'Trước 2 ngày' },
  { value: WORK_REMINDER.TRUOC_BA_NGAY, label: 'Trước 3 ngày' },
]
const typeToNumber = {
  [WORK_REMINDER.TRUOC_MOT_NGAY]: 1,
  [WORK_REMINDER.TRUOC_HAI_NGAY]: 2,
  [WORK_REMINDER.TRUOC_BA_NGAY]: 3,
}
const dateTimeRequest = 'YYYY-MM-DDTHH:mm:ss'

const Reminder = ({ onClose = () => {}, match }) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const { id, actionId } = match.params
  const taskId = actionId || id
  const [fetching, setFetching] = useState(false)
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [deadline, setDeadline] = useState()
  const [selected, setSelected] = useState(DEFAULT_SELECTED)

  const onSelect = value => () => {
    mountedSet(setIsDisabledButtonDefault, false)
    const newSelected = {
      type: value,
      date: null,
    }
    if (value !== UNSET) {
      newSelected.date = moment(deadline)
        .subtract(typeToNumber[value], 'days')
        .set('hour', 7)
        .set('minute', 0)
    }
    mountedSet(setSelected, newSelected)
  }

  const changeDate = date => {
    mountedSet(setIsDisabledButtonDefault, false)
    mountedSet(setSelected, prev => ({
      type: date ? WORK_REMINDER.TUY_CHINH : prev.type,
      date,
    }))
  }

  const getDeadline = detail => {
    return (
      get(
        get(detail, 'responsibilities[0].workAssignments', [])
          .filter(i => i.deadline)
          .slice(-1)[0],
        'deadline'
      ) || detail.deadline
    )
  }

  const _fetchDetail = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => mountedSet(setFetching, true),
      action: () => getCongViec(taskId),
      afterResponse: detail => {
        mountedSet(setDeadline, getDeadline(detail))
        mountedSet(
          setSelected,
          get(detail, 'workReminder')
            ? {
                type:
                  get(detail, 'workReminder.type') === WORK_REMINDER.KHONG_NHAC
                    ? UNSET
                    : get(detail, 'workReminder.type'),
                date: moment(get(detail, 'workReminder.reminderTime')),
              }
            : DEFAULT_SELECTED
        )
      },
      afterAction: () => mountedSet(setFetching, false),
    })
  }, [taskId])

  const submit = () => {
    let params =
      selected.type !== UNSET
        ? {
            type: selected.type,
            reminder_time: moment(selected.date).format(dateTimeRequest),
          }
        : {
            type: WORK_REMINDER.KHONG_NHAC,
          }
    requestAction({
      beforeAction: () => mountedSet(setSubmitLoading, true),
      action: () => patchReminder(taskId, params),
      afterResponse: () => onClose(),
      afterAction: () => {
        mountedSet(setSubmitLoading, false)
        mountedSet(setIsDisabledButtonDefault, true)
      },
      successMessage: Toast.SUCCESS(Action.SAVE),
      errorMessage: Toast.FAIL(Action.SAVE),
    })
  }

  const selectDisabled = value => {
    if (value === UNSET) {
      return false
    }
    if (!deadline) {
      return true
    }
    if (
      moment(deadline).subtract(typeToNumber[value], 'days') <
      moment().endOf('d')
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchDetail()
  }, [_fetchDetail])

  return (
    <div className={csx('cpc-side-action', 'open', 'cpc-form')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>{FORM_TITLE}</span>
        <Icon
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          onClick={onClose}
        />
      </h1>
      <div style={{ padding: 10 }} className={csx('cpc-list-item')}>
        {selectData.map(({ value, label }) => (
          <Radio
            key={value}
            className={csx('cpc-radio', 'ph10')}
            disabled={selectDisabled(value)}
            checked={get(selected, 'type') === value}
            onChange={onSelect(value)}
            label={label}
          />
        ))}
      </div>
      <div style={{ padding: 10 }} className="border-top">
        <FormDatetimePicker
          disabled={fetching}
          inputIconClassName="icon2-date-frames"
          onChange={changeDate}
          minimumDate={moment().toDate()}
          maximumDate={deadline && moment(deadline).toDate()}
          placeholder="Tùy chỉnh"
          popoverStretch={false}
          selected={
            get(selected, 'type') === WORK_REMINDER.TUY_CHINH
              ? get(selected, 'date')
              : null
          }
          defaultTime={[7, 0]}
        />
      </div>
      <div className={csx('element-center', 'mb10', 'pt10', 'border-top')}>
        <Button
          className={csx(
            'cpc-button',
            'btn-cancel',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-100',
            'mr15'
          )}
          disabled={fetching}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={csx(
            'cpc-button',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-80'
          )}
          disabled={fetching || isDisabledButtonDefault}
          intent={Intent.PRIMARY}
          onClick={submit}
          loading={submitLoading}
        >
          Lưu
        </Button>
      </div>
    </div>
  )
}

export default withRouter(memo(Reminder))
