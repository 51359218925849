import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

const DEFAULT_ID = '00000000-0000-0000-0000-000000000000'
const TOTAL_NAME = 'Tổng cộng'
const TYPE_UNIT = 1

class ListItem extends Component {
  constructor(props) {
    super(props)

    this.convertNumber = this.convertNumber.bind(this)
    this.redirectToStatisticByUnit = this.redirectToStatisticByUnit.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  convertNumber = number => {
    if (_.isNumber(number)) {
      return number
    }
    return 0
  }

  redirectToStatisticByUnit = item => {
    if (!item || item.loai !== TYPE_UNIT || !item.id) {
      return
    }
    this.props.actions.refreshStatistic(false)
    return (
      this.props.history &&
      this.props.history.push({
        pathname: `/tien-ich/thong-ke-nhan-vien/chi-tiet/don-vi/${item.id}`,
        state: {
          isCheck: true,
        },
      })
    )
  }

  render() {
    let { item, number } = this.props
    if (!item) {
      return null
    }

    const isTotalRaw = item.id === DEFAULT_ID && item.ten === TOTAL_NAME
    return (
      <tr
        className={classnames({
          'text-bold': isTotalRaw,
        })}
      >
        <td className="gerenal-list-utilities">
          {isTotalRaw ? '' : number > 9 ? number : '0' + number}
        </td>
        <td
          onClick={this.redirectToStatisticByUnit.bind(this, item)}
          className={classnames('gerenal-list-utilities', {
            'over-time': item.loai === TYPE_UNIT && !isTotalRaw,
          })}
        >
          <span className="hover-over-time">{item.ten}</span>
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoEoffice)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoHrms)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tiLeSuDung)}
        </td>
      </tr>
    )
  }
}

export default ListItem
