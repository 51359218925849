import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const NOT_DEPARTMENT = '00000000-0000-0000-0000-000000000000'
const CHUA_GIAI_QUYET = false
const DA_GIAI_QUYET = true
class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  handleClick = (id, type) => {
    if (id !== NOT_DEPARTMENT) {
      const date = this.props.filter
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/tinh-hinh-xu-ly-cong-van/chi-tiet/phong-ban/${id}`,
          ngayChiDao: {
            from: date.start_date,
            to: date.end_date,
          },
          type,
        })
      )
    }
  }

  handleSubmit = (id, isDonVi) => {
    if (id !== NOT_DEPARTMENT && isDonVi) {
      return (
        this.props.history &&
        this.props.history.push({
          pathname: `/tien-ich/tinh-hinh-xu-ly-cong-van/danh-sach/${id}`,
          id,
        })
      )
    }
  }

  render() {
    const { item, number } = this.props
    return (
      <tr
        className={classnames({
          'text-bold':
            item.phongBanId === NOT_DEPARTMENT &&
            item.tenPhongBan === 'Tổng cộng',
        })}
      >
        <td className="gerenal-list-utilities">
          {item.phongBanId !== NOT_DEPARTMENT &&
          item.tenPhongBan !== 'Tổng cộng'
            ? number > 9
              ? number
              : '0' + number
            : null}
        </td>
        <td
          className={classnames('gerenal-list-utilities', {
            'over-time cursor-pointer': item.isDonVi,
          })}
          onClick={this.handleSubmit.bind(this, item.phongBanId, item.isDonVi)}
        >
          {item.tenPhongBan}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongViec}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongViecChuaGiao}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongViecDangGiaiQuyet}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongViecDaGiaiQuyet}
        </td>
        <td
          className={classnames('text-center', {
            'over-time': item.phongBanId !== NOT_DEPARTMENT,
          })}
          onClick={this.handleClick.bind(
            this,
            item.phongBanId,
            CHUA_GIAI_QUYET
          )}
        >
          <span className="hover-over-time">
            {' '}
            {item.tongSoCongViecQuaHanChuaGiaiQuyet}{' '}
          </span>
        </td>
        <td
          className={classnames('text-center', {
            'over-time': item.phongBanId !== NOT_DEPARTMENT,
          })}
          onClick={this.handleClick.bind(this, item.phongBanId, DA_GIAI_QUYET)}
        >
          <span className="hover-over-time">
            {' '}
            {item.tongSoCongViecQuaHanDaGiaiQuyet}{' '}
          </span>
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongViecQuaHan}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tyLeCongViecQuaHan}
        </td>
      </tr>
    )
  }
}

export default ListItem
