export const isValidMenu = (originValue = [], menuData = null) => {
  const orPermission = menuData?.orPermission
  const permission = menuData?.permission
  if (!orPermission && !permission) {
    return true
  }

  if (orPermission && orPermission?.length !== 0) {
    const hasData = orPermission?.findIndex(f =>
      originValue?.some(o => o === f)
    )
    return hasData !== -1
  }

  if (permission && permission?.length !== 0) {
    const hasData = permission?.filter(f => originValue?.some(o => o === f))
    return hasData?.length === permission?.length
  }

  return true
}
