import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import get from 'lodash/get'

import { MasterLayout } from '../../components/layout'
import {
  DetailInfo,
  DetailBoxAction,
  ExecutingView,
  FlowInternalDocumentary,
} from '../../components/InternalDocument'
import {
  LoadingLine,
  // LoadingItem,
  DetailFileContentV2,
} from 'components/common'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import { SendMail } from '../../components/ReceiveDocument'

class DetailInternalDocument extends React.Component {
  static propTypes = {
    listInternalDocuments: PropTypes.object.isRequired,
    executingViews: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.closeShowDetailInfo = this.closeShowDetailInfo.bind(this)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.closeModulesDetail = this.closeModulesDetail.bind(this)
    this.handleClickExecutingView = this.handleClickExecutingView.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  state = {
    showListAction: true,
    showDetailInfo: false,
    internalDocumentId: this.props.match.params.id,
    internalDocument: null,
    file: null,
    executingViews: null,
    isLoadingData: {
      internalDocument: true,
    },
    boxMenuFixTop: false,
  }

  handleClickGoBack = () => {
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
    })
  }

  handleClickExecutingView = () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'XemThucHien',
    })
  }

  handleClickDetailInfo = () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'ThongTinCongVan',
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
    })
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  showDetailInfo = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeShowDetailInfo = () => {
    this.setState({
      showDetailInfo: false,
    })
  }

  closeModulesDetail = () => {
    this.setState({
      showListAction: true,
      showReportWork: !this.state.showReportWork,
    })
  }

  goBack() {
    this.props.history.goBack()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.props.actions
      .getInternalDocumentItem(this.state.internalDocumentId)
      .then(() => {
        if (
          this.props.listInternalDocuments &&
          this.props.listInternalDocuments.item
        ) {
          this.setState({
            internalDocument: this.props.listInternalDocuments.item,
            file: this.props.listInternalDocuments.item.fileNoiDung,
            isLoadingData: {
              ...this.state.isLoadingData,
              internalDocument: false,
            },
          })
        }
      })
    this.props.actions
      .getExecutingViewInternalDocument(this.state.internalDocumentId)
      .then(() => {
        if (this.props.executingViews && this.props.executingViews.items) {
          this.setState({ executingViews: this.props.executingViews.items })
        }
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.listInternalDocuments.item !==
        this.props.listInternalDocuments.item &&
      this.props.listInternalDocuments &&
      this.props.listInternalDocuments.item
    ) {
      this.setState({ internalDocument: this.props.listInternalDocuments.item })
    }

    if (
      prevProps.executingViews.items !== this.props.executingViews.items &&
      this.props.executingViews &&
      this.props.executingViews.items
    ) {
      this.setState({ executingViews: this.props.executingViews.items })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  sendToChat = e => {
    e.preventDefault()
    let { listInternalDocuments } = this.props
    if (listInternalDocuments && listInternalDocuments.item) {
      const { auth } = this.props
      let fileNoiDung = listInternalDocuments.item.fileNoiDung
        ? listInternalDocuments.item.fileNoiDung.fileId
        : ''
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = listInternalDocuments.item.congVanNoiBoId
      let infoGetFileDinhKemType = 'CONG_VAN_NOI_BO'
      if (!infoGetFileDinhKem) {
        infoGetFileDinhKem = listInternalDocuments.item.congVanId
        infoGetFileDinhKemType = 'CONG_VAN'
      }
      const url =
        Types.FE_CHAT_URL +
        `?accessToken=${auth.token.accessToken}&expiresIn=${
          auth.token.expiresIn
        }
      &tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}
      &fileId=${fileNoiDung.toUpperCase()}&infoGetFileDinhKem=${infoGetFileDinhKem.toUpperCase()}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      window.open(url, '_blank')
    }
  }

  handleSubmit = (title, msg, intent, isSuccess, id = 0) => {
    this.props.actions.commonAddToasterMessage({ message: msg, intent: intent })
    if (isSuccess) {
      this.props.actions.refreshPage(true)
      this.goBack()
    }
  }

  handleClickFlowdocumentary = async e => {
    this.setState({
      showResults: false,
      showListAction: false,
      showSection: 'LuongCongVan',
    })
    if (
      this.props.listInternalDocuments &&
      this.props.listInternalDocuments.item &&
      this.props.listInternalDocuments.item.congVanNoiBoId
    ) {
      await this.props.actions.getLuongCongVanNoiBo(
        this.props.listInternalDocuments.item.congVanNoiBoId
      )
      this.setState({
        showResults: true,
      })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="documentary" collapseSideBar={true}>
        <div className="detail-receive-document detail-send-document">
          {/* <DetailInfo
            listInternalDocument = {this.props.listInternalDocument.item}
            showDetailInfo = {this.state.showDetailInfo}
            closeShowDetailInfo={this.closeShowDetailInfo}
          /> */}
          {this.state.isLoadingData &&
          Object.values(this.state.isLoadingData).find(
            item => item === true
          ) !== undefined ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              <span className="number-code">
                <span className="name">Công văn nội bộ:</span>
                <span className="code">
                  {this.state.internalDocument
                    ? this.state.internalDocument.soCongVan
                    : ''}
                </span>
              </span>
            </div>
          )}
          <div className="detail-container">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {get(this.props.listInternalDocuments, 'item.fileNoiDung') &&
                  this.state.isLoadingData &&
                  Object.values(this.state.isLoadingData).find(
                    item => item === true
                  ) === undefined && (
                    <DetailFileContentV2
                      auth={this.props.auth}
                      actions={this.props.actions}
                      file={this.props.listInternalDocuments.item.fileNoiDung}
                    />
                  )}
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
              >
                <div className="full-box-content">
                  {this.state.showListAction && (
                    <DetailBoxAction
                      auth={this.props.auth}
                      onClickExecutingView={this.handleClickExecutingView}
                      onClickDetailInfo={this.handleClickDetailInfo}
                      sendToChat={this.sendToChat}
                      isLoadingData={this.state.isLoadingData}
                      onClickSendMail={this.handleClickSendMail}
                      listInternalDocuments={
                        this.props.listInternalDocuments &&
                        this.props.listInternalDocuments.item
                          ? this.props.listInternalDocuments.item
                          : {}
                      }
                      onClickFlowdocumentary={this.handleClickFlowdocumentary}
                    />
                  )}
                  {this.state.showResults &&
                    this.state.showSection === 'XemThucHien' && (
                      <ExecutingView
                        onClickGoBack={this.handleClickGoBack}
                        executingViews={this.state.executingViews}
                        auth={this.props.auth}
                        actions={this.props.actions}
                      />
                    )}
                  {this.state.showResults &&
                    this.state.showSection === 'ThongTinCongVan' && (
                      <DetailInfo
                        onClickGoBack={this.handleClickGoBack}
                        listInternalDocument={this.state.internalDocument}
                      />
                    )}
                  {this.state.showResults &&
                    this.state.showSection === 'GuiEmail' && (
                      <div className="box-action-item">
                        <SendMail
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSubmit}
                          action={this.props.actions}
                          receiveDocument={
                            this.props.listInternalDocuments &&
                            this.props.listInternalDocuments.item
                              ? this.props.listInternalDocuments.item
                              : {}
                          }
                        />
                      </div>
                    )}
                  {this.state.showResults &&
                    this.state.showSection === 'LuongCongVan' && (
                      <div className="box-action-item">
                        <FlowInternalDocumentary
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          flowDocument={this.props.luongCongVanNoiBo}
                          onClickDepartment={
                            this.handleClickMoreDepartmentExecutingView
                          }
                          flowReceiveDocument={
                            this.props.executingViews.flowDocuments
                          }
                          actions={this.props.actions}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },
  listInternalDocuments: {
    ...state.listInternalDocuments,
    item: state.entities.internalDocuments[
      state.listInternalDocuments.detailId
    ],
  },
  executingViews: {
    ...state.executingViews,
    items:
      state.executingViews.items_internalDocument !== undefined &&
      state.executingViews.items_internalDocument.map(
        id => state.entities.executingViews[id]
      ),
  },
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  luongCongVanNoiBo: state.detailInternalDocuments.luongCongVanNoiBo,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailInternalDocument)
