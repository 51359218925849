import React, { Component } from 'react'
import PropTypes from 'prop-types'
import XemThucHien from './XemThucHien'
import { Collapse } from '@blueprintjs/core'
import classnames from 'classnames'

import * as Types from '../../constants/Api'

class ExecutingViewBox extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isOpen: true,
    openSub: {},
  }

  handleClick = () => {
    if (
      this.props.item &&
      this.props.item.dsCongViecPhongBan &&
      this.props.item.dsCongViecPhongBan.length > 0
    ) {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  handleClickDepartment(item) {
    if (!item.chiDaoId && !item.phongBanTrinhId) {
      return
    }
    this.setState({
      openSub: {
        ...this.state.openSub,
        [item.congVanDenId]: !this.state.openSub[item.congVanDenId],
      },
    })
    if (!this.state.openSub[item.congVanDenId]) {
      return (
        this.props.onClickDepartment &&
        this.props.onClickDepartment(item.congVanDenId)
      )
    }
  }

  render() {
    const { item } = this.props
    return (
      <div className="view-job-done">
        <div className="box-level-primary item-box">
          <div className="avatar-user">
            <div className="click-avatar" onClick={this.handleClick}>
              <span
                className={classnames(
                  'pt-icon click-dropdown drop-down-items',
                  {
                    'pt-icon-chevron-up':
                      item.dsCongViecPhongBan &&
                      item.dsCongViecPhongBan.length > 0 &&
                      this.state.isOpen,
                    'pt-icon-chevron-down':
                      item.dsCongViecPhongBan &&
                      item.dsCongViecPhongBan.length > 0 &&
                      !this.state.isOpen,
                  }
                )}
              />
              <span
                className="avatar"
                style={{
                  backgroundImage: `url(${
                    item.nguoiChiDao && item.nguoiChiDao.anhDaiDien
                      ? Types.IMAGE_URL + item.nguoiChiDao.anhDaiDien
                      : '/images/default_avatar.png'
                  })`,
                }}
              />
              <span className="name_user">
                {item.nguoiChiDao && item.nguoiChiDao.tenNhanVien}
              </span>
            </div>
          </div>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <XemThucHien
            executingViews={item.dsCongViecPhongBan}
            auth={this.props.auth}
            actions={this.props.actions}
          />
        </Collapse>
      </div>
    )
  }
}

export default ExecutingViewBox
