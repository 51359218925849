import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import QueryString from 'query-string'
import {
  Classes,
  Button,
  Intent,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'
import classNames from 'classnames'
import FileDownload from 'file-saver'
import print from 'print-js'
import toPairs from 'lodash/toPairs'
import uniqBy from 'lodash/uniqBy'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { toDecamelizeKeys } from 'helpers/key'

import { NotFound } from 'components/common'
import { Action, Toast } from 'constants/MessageForm'
import { DetailFileContentV3 } from 'components/common'
import { priorities } from 'constants/task'
import { Row, Col, Avatar, FileGroup, Icon } from 'components/newCommon'
import {
  Input,
  Select,
  DateAdvanced,
  FormDatePicker,
} from 'components/newCommon2'
import PhongBanVaDonViThucHien from './TaskManager/Form/_components/PhongBanVaDonViThucHien'
import { contactFetchDepartmentByUnit } from '../../actions'
import requestAction from 'helpers/request'
import { showToast } from 'actions/task'
import {
  getDOCongViec,
  getDOToken,
  getDOCongVan,
  getDOPreviewFileCongVan,
  getDODowloadFileCongVan,
  getPhongBanThucHienNotToken,
  getDonViTrucThuocNotToken,
  putDOGiaoViec,
  getDODonViIdByUsername,
} from 'actions/doffice'

const DofficeCongViec = ({ location }) => {
  const query = QueryString.parse(location?.search)
  const { userName, workItemId } = query
  const PHOI_HOP = 'PHOI_HOP'
  const CHU_TRI_DO = 'CHU_TRI_DO'
  const PHOI_HOP_DO = 'PHOI_HOP_DO'
  const THEO_DOI_DO = 'THEO_DOI_DO'
  const [dataDepartment, setDataDepartment] = useState([])
  const [dataAutoToggle] = useState([-2])
  const [dataOrganization, setDataOrganization] = useState([])
  const [dataToken, setDataToken] = useState(null)
  const [dataCongVan, setDataCongVan] = useState(null)
  const [dataFileReview, setDataFileReview] = useState(null)
  const [fileBase64, setFileBase64] = useState(null)
  const [documentId, setDocumentId] = useState(null)
  const [dataForm, setDataForm] = useState({})
  const [validateErrors, setValidateErrors] = useState({})
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [donViId, setDonViId] = useState(null)

  const validate = useCallback(() => {
    setValidateErrors({})
    const success = size(dataForm?.[PHOI_HOP]) !== 0
    if (!success) {
      setValidateErrors({
        [PHOI_HOP]: true,
      })
      throw new Error('warning')
    }
  }, [dataForm])

  const getRequestData = useCallback(() => {
    const phoiHop =
      dataForm?.[PHOI_HOP]?.map(elm => ({
        responsibilityId: elm?.id,
        deadline: dataForm?.deadline,
        executionDate: dataForm?.executionDate,
      })) || []
    return phoiHop.map(user => toDecamelizeKeys(user))
  }, [dataForm])

  const submit = useCallback(async () => {
    let requestData = []
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      beforeAction: () => {
        setIsActionLoading(true)
        validate()
      },
      action: async () => {
        requestData = await getRequestData()
        return putDOGiaoViec(workItemId, userName, requestData)
      },
      afterResponse: res => {
        if (res?.code === 200) {
          return showToast({
            message: Toast.SUCCESS(Action.ASSIGN),
            intent: Intent.SUCCESS,
          })
        }

        return showToast({
          message: Toast.FAIL(Action.ASSIGN),
          intent: Intent.WARNING,
        })
      },
      afterAction: () => {
        setIsActionLoading(false)
      },
    })
  }, [workItemId, userName, getRequestData, validate])

  const changeField = name => value => {
    setDataForm(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const onChangeAll = useCallback(
    (name, isIndeterminate, event) => {
      const isChecked = event?.target?.checked
      setDataForm(prev => ({
        ...prev,
        [name]:
          isChecked && (isIndeterminate || !isIndeterminate)
            ? dataOrganization
            : [],
      }))
    },
    [dataOrganization]
  )

  const onChangeCheckbox = useCallback((name, values, event) => {
    const isChecked = event?.target?.checked
    setDataForm(prev => ({
      ...prev,
      [name]: uniqBy(
        [
          ...(prev?.[name]
            ? isChecked
              ? [...prev?.[name], values]
              : prev?.[name]?.filter(e => e.id !== values?.id)
            : []),
          ...(!prev?.[name] ? [values] : []),
        ],
        'id'
      ),
    }))
  }, [])

  const handleCheckAllActive = useCallback(
    name => {
      const sizeCheck = size(dataForm?.[name])
      const sizeOrigin = size(dataOrganization)
      if (sizeCheck === sizeOrigin) {
        return true
      }

      return false
    },
    [dataForm, dataOrganization]
  )

  const handleCheckIndeterminate = useCallback(
    name => {
      let isChecked = false
      const dataKey = size(dataForm?.[name]) !== 0
      if (dataKey) {
        isChecked = true
      }

      return isChecked
    },
    [dataForm]
  )

  const handleCheckActive = useCallback(
    (name, record) => {
      let isChecked = false
      const dataKey = dataForm?.[name]?.findIndex(e => e?.id === record?.id)
      if (dataKey === 0 || (dataKey && dataKey !== -1)) {
        isChecked = true
      }

      return isChecked
    },
    [dataForm]
  )

  const column = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: record => {
        const isChecked = handleCheckActive(CHU_TRI_DO, record)
        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            disabled={true}
          />
        )
      },
    },
    {
      title: 'PH',
      render: record => {
        const isChecked = handleCheckActive(PHOI_HOP_DO, record)
        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked}
            disabled={true}
          />
        )
      },
    },
    {
      title: 'TD',
      render: record => {
        const isChecked = handleCheckActive(THEO_DOI_DO, record)
        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked}
            disabled={true}
          />
        )
      },
    },
  ]

  const columnDonVi = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'PH',
      renderTitle: () => {
        const isChecked = handleCheckAllActive(PHOI_HOP)
        const isIndeterminate = handleCheckIndeterminate(PHOI_HOP)
        return (
          <div className="reset-margin">
            <Checkbox
              className={Classes.SMALL}
              label="PH"
              checked={isChecked}
              indeterminate={isIndeterminate && !isChecked}
              onChange={e => onChangeAll(PHOI_HOP, isIndeterminate, e)}
            />
          </div>
        )
      },
      render: record => {
        const isChecked = handleCheckActive(PHOI_HOP, record)
        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked}
            onChange={e => onChangeCheckbox(PHOI_HOP, record, e)}
          />
        )
      },
    },
  ]

  const handleDownload = useCallback(
    (values, name) => {
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDODowloadFileCongVan(values, dataToken),
        afterResponse: async response => {
          if (response) {
            FileDownload.saveAs(response, name)
          }
        },
      })
    },
    [dataToken]
  )

  const onClickFile = useCallback(
    e => {
      const params = {
        id: e?.fileId,
        idDv: dataToken?.user?.idDv,
        idNv: dataToken?.user?.idNv,
      }
      handleDownload(params, e?.name)
    },
    [dataToken, handleDownload]
  )

  const onFilePrint = useCallback(() => {
    if (dataFileReview?.iDFILE) {
      print({
        printable: dataFileReview?.fileBase64,
        type: dataFileReview?.lOAIFILE,
        base64: true,
        onError: () => {},
      })
    }
  }, [dataFileReview])

  const onFileDownload = useCallback(() => {
    if (dataFileReview?.iDFILE) {
      const params = {
        id: dataFileReview?.iDFILE,
        idDv: dataToken?.user?.idDv,
        idNv: dataToken?.user?.idNv,
      }
      handleDownload(params, dataFileReview?.tENFILE)
    }
  }, [dataFileReview, dataToken, handleDownload])

  const getPreviewFileCongVan = useCallback(
    (idFile, loaiFile) => {
      if (!idFile && isEmpty(dataToken)) {
        return
      }

      const params = {
        id: idFile,
        idDv: dataToken?.user?.idDv,
        idNv: dataToken?.user?.idNv,
      }
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDOPreviewFileCongVan(params, dataToken),
        afterResponse: response => {
          if (response) {
            if (loaiFile === 'pdf') {
              setDataFileReview(prev => ({
                ...prev,
                fileBase64: response,
              }))
              const fileBase64 = `data:application/pdf;base64,${response}`
              setFileBase64(fileBase64)
            }
          }
        },
      })
    },
    [dataToken]
  )

  const getThongTinCongViec = useCallback(() => {
    if (!workItemId) {
      return
    }

    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDOCongViec(workItemId),
      afterResponse: response => {
        const newData = response?.result || null
        setDocumentId(newData?.documentId || null)
        setDataForm(
          newData
            ? {
                description: newData?.description,
                executionDate: newData?.executionDate
                  ? moment(newData?.executionDate)
                  : moment(),
                deadline: newData?.deadline ? moment(newData?.deadline) : null,
                priority: newData?.priority,
                files: newData?.files,
                [CHU_TRI_DO]:
                  newData?.responsibilities?.[0]?.workAssignments?.map(e => ({
                    ...e,
                    id: e?.responsibilityId,
                    type: e?.assignType,
                  })) || [],
                [PHOI_HOP_DO]:
                  newData?.responsibilities?.[1]?.workAssignments?.map(e => ({
                    ...e,
                    id: e?.responsibilityId,
                    type: e?.assignType,
                  })),
                [THEO_DOI_DO]:
                  newData?.responsibilities?.[2]?.workAssignments?.map(e => ({
                    ...e,
                    id: e?.responsibilityId,
                    type: e?.assignType,
                  })),
                documentId: newData?.documentId,
              }
            : null
        )
      },
    })
  }, [workItemId])

  const getThongTinCongVan = useCallback(() => {
    if (documentId) {
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDOCongVan(documentId),
        afterResponse: response => {
          setDataCongVan(response?.data || null)
          const fileVb = response?.data?.fileVb?.find(e => e?.fileChinh) || null
          setDataFileReview(fileVb)
          if (fileVb?.idFile) {
            getPreviewFileCongVan(fileVb?.idFile, fileVb?.loaiFile)
          }
        },
      })
    }
  }, [documentId, getPreviewFileCongVan])

  const getPhongBan = useCallback(() => {
    if (!donViId) {
      return
    }

    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getPhongBanThucHienNotToken(donViId),
      afterResponse: response => {
        if (size(response?.result) !== 0) {
          const khoiLanhDao =
            response?.result
              ?.filter(f => f?.phongBanLanhDao)
              ?.map(elm => ({
                id: elm?.phongBanId,
                type: 'DEPARTMENT',
                name: elm?.maPhongBan,
                code: elm?.maPhongBan,
                parentId: -1,
              })) || []
          const khoiPhongBan =
            response?.result
              ?.filter(f => !f?.phongBanLanhDao)
              ?.map(elm => ({
                id: elm?.phongBanId,
                type: 'DEPARTMENT',
                name: elm?.maPhongBan,
                code: elm?.maPhongBan,
                parentId: -2,
              })) || []
          const newData = [
            {
              id: -1,
              type: 'ALL',
              name: 'Khối lãnh đạo',
              code: 'Khối lãnh đạo',
              parentId: null,
              children: khoiLanhDao,
            },
            {
              id: -2,
              type: 'ALL',
              name: 'Khối phòng ban',
              code: 'Khối phòng ban',
              parentId: null,
              children: khoiPhongBan,
            },
          ]
          setDataDepartment(newData)
        }
      },
    })
  }, [donViId])

  const getDonVi = useCallback(() => {
    if (!donViId) {
      return
    }

    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDonViTrucThuocNotToken(donViId),
      afterResponse: response => {
        if (size(response?.result) !== 0) {
          setDataOrganization(
            response?.result?.map(elm => {
              return {
                ...elm,
                id: elm?.donViId,
                type: 'ORGANIZATION',
                name: elm?.tenVietTat,
                code: elm?.tenVietTat,
                parentId: elm?.donViCha,
              }
            })
          )
        }
      },
    })
  }, [donViId])

  useEffect(() => {
    getThongTinCongViec()
  }, [getThongTinCongViec])

  useEffect(() => {
    getThongTinCongVan()
  }, [getThongTinCongVan])

  useEffect(() => {
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDOToken(userName.toLowerCase()),
      afterResponse: response => {
        const newResponse = response?.result
        if (newResponse) {
          setDataToken({
            tokenType: 'Bearer',
            accessToken: newResponse?.token,
            user: newResponse?.nhanVien,
          })
        }
      },
    })
  }, [userName])

  useEffect(() => {
    getPhongBan()
  }, [getPhongBan])

  useEffect(() => {
    getDonVi()
  }, [getDonVi])

  useEffect(() => {
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDODonViIdByUsername(userName),
      afterResponse: response => {
        if (response?.result) {
          setDonViId(response?.result)
        }
      },
    })
  }, [userName])

  return (
    <div className="public-layout">
      <header>
        <img src="/images/EVNCPC_Logo.png" alt="logo eoffice" />
        <div style={{ lineHeight: 0 }}>
          <Avatar src={'/images/default_avatar.png'} />
        </div>
      </header>
      <div className="content">
        <h1 className="heading mt15 mh10">
          Công văn: <span>{dataCongVan?.kYHIEU}</span>
        </h1>
        <div className="panel-layout">
          <div className="row">
            <div className="col-xs-12 col-md-7">
              {fileBase64 ? (
                <DetailFileContentV3
                  file={fileBase64}
                  onFileDownload={onFileDownload}
                  onFilePrint={onFilePrint}
                />
              ) : (
                <NotFound />
              )}
            </div>
            <div className="col-xs-12 col-md-5">
              <h2 className="text-center font-weight-400 font-size-17 ph10">
                Giao việc
              </h2>
              <div
                style={{ border: '0.5px solid #E2E2E2' }}
                className="cpc-form p10"
              >
                <Input
                  label="Nội dung chỉ đạo"
                  required
                  placeholder="Nhập nội dung chỉ đạo..."
                  autoResize={true}
                  type="textarea"
                  maxLength={500}
                  wrapperClassName="mb10"
                  readOnly={true}
                  value={dataForm?.description}
                  onChange={changeField('description')}
                  warning={validateErrors?.description}
                />
                <Row gutterVertical>
                  <Col grid="half">
                    <DateAdvanced
                      textLabel="Hạn giải quyết"
                      readOnly={true}
                      inputIconClassName="icon2-date-frames"
                      inputWrapperClassName="mb10"
                      placeholder="Chọn hạn giải quyết"
                      popoverStretch={false}
                      selected={dataForm?.deadline}
                      inputWarning={validateErrors?.deadline}
                      popoverPosition={Position.BOTTOM_RIGHT}
                      onChange={value => {
                        changeField('deadline')(value)
                      }}
                    />
                  </Col>
                  <Col grid="half">
                    <Select
                      textLabel="Độ khẩn"
                      isShowArrowDropdown={true}
                      dataSource={toPairs(priorities).map(([value, label]) => ({
                        value,
                        label,
                      }))}
                      readOnly={true}
                      inputIconClassName="icon-flag"
                      onChange={changeField('priority')}
                      placeholder="Chọn độ khẩn"
                      value={dataForm?.priority}
                    />
                  </Col>
                </Row>
                <div className="mb15">
                  <label className="cpc-detail-label">File công văn</label>
                  <FileGroup
                    list={dataForm?.files || []}
                    isLink={false}
                    onChange={onClickFile}
                  />
                </div>
                <PhongBanVaDonViThucHien
                  className="mb15"
                  headStyle={{
                    backgroundColor: '#E8E8E8',
                    color: '#31434B',
                  }}
                  label="Phòng ban thực hiện"
                  isDisplayFavoriteGroup={false}
                  isRequired={true}
                  column={column}
                  data={dataDepartment}
                  dataAutoToggle={dataAutoToggle}
                />
                <PhongBanVaDonViThucHien
                  className="mb15"
                  headStyle={{
                    backgroundColor: '#E8E8E8',
                    color: '#31434B',
                  }}
                  label="Đơn vị trực thuộc"
                  isDisplayFavoriteGroup={false}
                  isNoTree={true}
                  isRequired={true}
                  column={columnDonVi}
                  data={dataOrganization}
                  dataAutoToggle={[]}
                  warning={validateErrors?.[PHOI_HOP]}
                />
                <Row gutterVertical>
                  <Col grid="half">
                    <FormDatePicker
                      textLabel="Ngày bắt đầu"
                      disabled={false}
                      inputClearable
                      inputIconClassName="icon2-date-frames"
                      inputWrapperClassName="mb10"
                      popoverPosition={Position.TOP_LEFT}
                      onChange={value => {
                        changeField('executionDate')(value)
                      }}
                      minimumDate={moment().toDate()}
                      maximumDate={
                        dataForm?.deadline
                          ? moment(dataForm?.deadline).toDate()
                          : undefined
                      }
                      placeholder="Chọn ngày bắt đầu"
                      popoverStretch={false}
                      selected={dataForm?.executionDate}
                    />
                  </Col>
                  <Col grid="half">
                    <DateAdvanced
                      textLabel="Hạn thực hiện"
                      disabled={false}
                      inputClearable
                      inputIconClassName="icon2-date-frames"
                      inputWrapperClassName="mb10"
                      placeholder="Chọn hạn thực hiện"
                      popoverStretch={false}
                      selected={dataForm?.deadline}
                      inputWarning={validateErrors?.deadline}
                      minimumDate={
                        dataForm?.executionDate
                          ? moment(dataForm?.executionDate).toDate()
                          : moment().toDate()
                      }
                      popoverPosition={Position.TOP_RIGHT}
                      onChange={value => {
                        changeField('deadline')(value)
                      }}
                    />
                  </Col>
                </Row>
                <p
                  className="font-size-13"
                  style={{ fontStyle: 'italic', color: '#738A95' }}
                >
                  Nếu không chọn LĐ PB chủ trì sẽ xác định
                </p>
              </div>
              <div className="border-left border-bottom border-right ph15 text-center">
                <Button
                  className={classNames(
                    'cpc-button',
                    'uppercase',
                    'font-size-13',
                    'min-width-100'
                  )}
                  intent={Intent.PRIMARY}
                  loading={isActionLoading}
                  disabled={isActionLoading}
                  onClick={submit}
                >
                  Giao việc
                  <Icon classIcon="icon-save" className="ml5" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      contactFetchDepartmentByUnit,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(DofficeCongViec)
