import React, { useMemo } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import Tag from 'components/common/Tag'

const SignNumberCard = ({ history, className, data }) => {
  const dateFormat = 'DD/MM/YYYY'

  const goDetail = data => {
    const type = data?.loaiVanBan ?? 0
    const id = data?.vanBanKySoId
    switch (type) {
      case 1:
        return history.push(`/ky-so/cong-van-noi-bo/chi-tiet/${id}`)
      case 2:
        return history.push(`/ky-so/du-an/chi-tiet/${id}`)
      case 3:
        return history.push(`/ky-so-tap-trung/van-ban-ky-duyet/chi-tiet/${id}`)
      default:
        return history.push(`/ky-so/cong-van-di/chi-tiet/${id}`)
    }
  }

  const signNumberType = useMemo(
    () => type => {
      let name = 'Đi ký số'
      let className = 'light-blue'
      switch (type) {
        case 1:
          name = 'Nội bộ ký số'
          className = 'green'
          break
        case 2:
          name = 'Ký số mở rộng'
          className = 'yellow'
          break
        case 3:
          name = 'Ký số tập trung'
          className = 'blue'
          break
        default:
          break
      }
      return <Tag name={name} className={className} />
    },
    []
  )

  return (
    <div
      className={classNames('cursor-pointer', className)}
      onClick={() => goDetail(data)}
    >
      <div className="d-flex d-flex-wrap align-items-center justify-content-space-between">
        <div className="d-flex d-flex-wrap align-items-center">
          <div className="mr5">{signNumberType(data?.loaiVanBan ?? 0)}</div>
          <p className="font-size-13">
            <span className="mr5" style={{ color: '#959595' }}>
              Ký hiệu:
            </span>
            {data?.soKyHieu}
          </p>
        </div>
        <p className="font-size-13">
          <span className="mr5" style={{ color: '#959595' }}>
            Ngày lập:
          </span>
          {data?.ngayLap ? moment(data?.ngayLap).format(dateFormat) : '-'}
        </p>
      </div>
      {data?.trichYeu && (
        <div className="font-size-13 mt5 line-clamp-2 font-weight-600 font-roboto">
          {data?.trichYeu}
        </div>
      )}
    </div>
  )
}

export default SignNumberCard
