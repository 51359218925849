import React, { memo, useCallback, useEffect, useState, useRef } from 'react'
import { Checkbox, Classes, Button, Tooltip } from '@blueprintjs/core'
import { get } from 'lodash'
import csx from 'classnames'
import { withCookies } from 'react-cookie'

import Title from 'components/title'
import { toDecamelizeKeys } from 'helpers/key'
import requestAction from 'helpers/request'
import { MasterLayout } from 'components/layout'
import { Icon } from 'components/newCommon'
import { Table, Pagination } from 'components/newCommon2'
import { Action, Toast } from 'constants/MessageForm'
import { getMyFavoriteGroups, putFavoriteGroup } from 'actions/task'
import DeleteFavorite from './components/confirm/delete-favorite-group'

const TaskFavoriteGroup = ({ history }) => {
  const PAGE_SIZE = 10
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const [fetching, setFetching] = useState(true)
  const [fetchingFail, setFetchingFail] = useState(false)
  const [favoriteGroup, setFavoriteGroup] = useState([])
  const [checking, setChecking] = useState(false)
  const [filter, setFilter] = useState()
  const [totalPage, setTotalPage] = useState(1)
  const [deleteData, setDeleteData] = useState(null)

  const changeCheckBox = record => e => {
    const { checked } = e.target
    const newRecord = { ...record, isActive: checked }
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => mountedSet(setChecking, true),
      action: () => putFavoriteGroup(toDecamelizeKeys(newRecord)),
      afterResponse: () =>
        mountedSet(setFavoriteGroup, (oldFavoriteGroup = []) =>
          oldFavoriteGroup.map(elm =>
            elm.id === newRecord.id ? newRecord : elm
          )
        ),
      afterAction: () => mountedSet(setChecking, false),
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }

  const fetchList = useCallback((page = 1, params = null) => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      beforeAction: () => mountedSet(setFetching, true),
      action: () =>
        getMyFavoriteGroups({
          ...params,
          page: page,
        }),
      afterResponse: ({ items = [], totalCount = 0 }) => {
        mountedSet(setFavoriteGroup, items)
        mountedSet(setTotalPage, totalCount)
      },
      afterAction: () => mountedSet(setFetching, false),
      afterError: () => mountedSet(setFetchingFail, true),
    })
  }, [])

  const changeFilter =
    (name, isFetch = true) =>
    value => {
      let newFilter = filter
      if (name === 'PAGE') {
        newFilter = { ...newFilter, page: value }
        setFilter(newFilter)
        return fetchList(value, newFilter)
      }

      newFilter = { ...newFilter, [name]: value }
      setFilter(newFilter)
      if (isFetch) {
        fetchList(1, newFilter)
      }
    }

  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Stt',
      render: (v, r, index) => {
        const num = (get(filter, 'page', 1) - 1) * 10 + index + 1
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'name',
      width: 50,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Nhóm thường giao việc',
      render: ({ name }) => name,
    },
    {
      key: 'suDung',
      width: 10,
      headClass: 'uppercase',
      className: 'element-center align-center',
      title: 'Sử dụng',
      dataIndex: 'isActive',
      render: (value, record) => (
        <Checkbox
          checked={!!value}
          className={csx(Classes.MINIMAL, 'mb0 inline-block')}
          disabled={checking}
          onChange={changeCheckBox(record)}
        />
      ),
    },
    {
      key: 'thaoTac',
      className: 'element-center align-center',
      width: 5,
      dataIndex: 'id',
      render: id => (
        <>
          <Tooltip
            content={
              <span className="font-size-12">
                Chỉnh sửa nhóm thường giao việc
              </span>
            }
          >
            <Icon
              classIcon="icon-but"
              className={csx(
                'size-icon-12',
                'mr10',
                'icon-color-primary',
                'has-event'
              )}
              onClick={() =>
                history.push(
                  `/quan-ly-cong-viec/nhom-thuong-giao-viec/chinh-sua/${id}`
                )
              }
            />
          </Tooltip>
          <Tooltip
            content={
              <span className="font-size-12">Xóa nhóm thường giao việc</span>
            }
          >
            <Icon
              classIcon="icon-bin"
              className={csx('size-icon-12', 'icon-color-danger', 'has-event')}
              onClick={() => setDeleteData({ id: id })}
            />
          </Tooltip>
        </>
      ),
    },
  ]

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="d-flex align-items-center justify-content-space-between mt10">
        <Title
          name={'Quản lý nhóm thường giao việc'}
          icon="icon icon-give-task"
        />
        <Tooltip
          content={
            <span className="font-size-12">Tạo nhóm thường giao việc</span>
          }
        >
          <Button
            className={csx('cpc-button blue')}
            onClick={() =>
              history.push('/quan-ly-cong-viec/nhom-thuong-giao-viec/them-moi')
            }
          >
            <span className="mr5">Tạo mới</span>
            <Icon classIcon="icon-Plus" />
          </Button>
        </Tooltip>
      </div>
      <div className="task-manager-block">
        <Table
          className="cpc-table-giaodienmoi"
          autoHide={false}
          columns={columns}
          data={favoriteGroup}
          loading={fetching}
          error={fetchingFail}
          tableMinWidth={400}
        />
        {totalPage > PAGE_SIZE && (
          <div className="text-center bg-white ph15 pv20 border-top">
            <Pagination
              total={totalPage}
              current={get(filter, 'page', 1)}
              pageSize={PAGE_SIZE}
              onChange={changeFilter('PAGE')}
            />
          </div>
        )}
        {deleteData ? (
          <DeleteFavorite
            dataSource={deleteData}
            onClose={() => setDeleteData(null)}
            onSuccess={() => {
              setDeleteData(null)
              changeFilter('PAGE')(1)
            }}
          />
        ) : (
          ''
        )}
      </div>
    </MasterLayout>
  )
}

export default withCookies(memo(TaskFavoriteGroup))
