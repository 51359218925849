import React, { memo, useState, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import moment from 'moment/moment'
import { Position, Tooltip } from '@blueprintjs/core'

import { Collapse, Icon } from 'components/newCommon'
import requestAction from 'helpers/request'

import { getCongViecXuLy, getLichSuChiDao } from 'actions/doffice'
import TieuDeXuLy from './tieudexuly'
import QuaTrinhXuLyItemChild from './quatrinhxulyitem'

const QuaTrinhXuLyItem = ({
  itemData,
  id,
  idDv,
  tokenDO,
  hasChild = false,
  isLeader = false,
}) => {
  const [isOpenGroup, setOpenGroup] = useState(false)
  const [childrenData, setChildrenData] = useState([])

  const handleToggle = useCallback(
    (values, hasChildrenData = false) => {
      setOpenGroup(prev => !prev)
      if (!hasChildrenData && !isEmpty(tokenDO)) {
        const token = {
          tokenType: 'Bearer',
          accessToken: tokenDO?.token,
        }
        const params = {
          ID_DV: idDv,
          ID_VB: id,
        }
        requestAction({
          getResult: false,
          codeCheck: false,
          showToast: false,
          action: () => {
            if (isLeader) {
              return getLichSuChiDao(
                {
                  ...params,
                  ID_CD: values?.iD,
                },
                token
              )
            }

            return getCongViecXuLy(
              {
                ...params,
                ID_XULY: values?.iD,
              },
              token
            )
          },
          afterResponse: response => {
            if (response?.data) {
              setChildrenData(response?.data)
            }
          },
        })
      }
    },
    [tokenDO, id, isLeader, idDv]
  )

  const processingStatus = () => {
    let content = ''
    switch (itemData?.tTXULY) {
      case 'Hoàn thành':
        content = (
          <div className="circle-border">
            <span className="checkmark"></span>
          </div>
        )
        break
      case 'Đã chuyển':
        content = <Icon classIcon="icon2-hand" />
        break
      case 'Đang thực hiện':
        content = <Icon classIcon="icon-s-edit" style={{ color: '#f8d46e' }} />
        break
      default:
        break
    }

    if (content) {
      return (
        <Tooltip
          className="mr10"
          content={<span className="font-size-13">{itemData?.tTXULY}</span>}
          position={Position.TOP}
        >
          {content}
        </Tooltip>
      )
    }
  }

  const renderChild = useMemo(
    () => (value, index) => {
      if (!value?.isChildren) {
        return <TieuDeXuLy key={index} className="mt10" item={value} />
      }

      return (
        <QuaTrinhXuLyItemChild
          key={index}
          hasChild={value.isChildren}
          isLeader={value?.isChidao ?? false}
          id={id}
          idDv={idDv}
          tokenDO={tokenDO}
          itemData={value}
        />
      )
    },
    [id, tokenDO, idDv]
  )

  return (
    <div className="mt10 font-roboto">
      <div className={classNames('do-collapse-title pl20')}>
        <span
          className={classNames('pt-icon font-size-13 wh-16 mr5', {
            'color-blue cursor-pointer': hasChild,
            'pt-icon-chevron-up': isOpenGroup && hasChild,
            'pt-icon-chevron-down': !isOpenGroup && hasChild,
          })}
          style={{ position: 'absolute', left: 0, top: '2px' }}
          onClick={() =>
            hasChild ? handleToggle(itemData, size(childrenData) !== 0) : null
          }
        />
        <p className="chuc_vu mr10">{itemData?.tENPB || ''}</p>
        <p
          className={classNames('vai_tro mr10', {
            red: itemData?.mAXULY === 'Chủ trì' && itemData?.kEYXULY,
          })}
        >
          ({itemData?.mAXULY || ''})
        </p>
        {processingStatus()}
        <p className="thoi_gian">
          {itemData?.nGAYTAO
            ? moment(itemData?.nGAYTAO)
                .subtract(7, 'hours')
                .format('DD/MM/YYYY HH:mm')
            : ''}
        </p>
        {itemData?.nDUNGXULY && (
          <div
            className={'mt5 font-roboto'}
            style={{ minWidth: '100%' }}
            dangerouslySetInnerHTML={{
              __html: itemData?.nDUNGXULY,
            }}
          />
        )}
      </div>
      {hasChild && isOpenGroup && (
        <Collapse isOpen={isOpenGroup}>
          <div className={'pl20'}>
            {childrenData?.map?.((item, index) => renderChild(item, index))}
          </div>
        </Collapse>
      )}
    </div>
  )
}

export default memo(QuaTrinhXuLyItem)
