import { useMemo, useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { Intent } from '@blueprintjs/core'
import xlsx from 'xlsx'
import uniqBy from 'lodash/uniqBy'
import differenceBy from 'lodash/differenceBy'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isString from 'lodash/isString'
import uniq from 'lodash/uniq'

import { Action, Toast } from 'constants/MessageForm'
import {
  RESPONSIBILITY_TYPE,
  UNSET,
  WORK_PRIORITY,
  FILE_TYPE,
  WORK_TYPE,
} from 'constants/Enum'
import {
  getPhongBanNhanVien,
  saveTree,
  getDsTag,
  getMyFavoriteGroups,
  postCongViec,
  postUpload,
  getCongViec,
  putCongViec,
  showToast,
  postFavoriteGroup,
} from 'actions/task'
import { randomId } from 'helpers/string'
import { toDecamelizeKeys } from 'helpers/key'
import requestAction from 'helpers/request'

const useTaskCreate = ({
  actions,
  history,
  match,
  mainUnitId,
  mainDepartmentId,
  roleId,
  hasShowAll,
  dataTreeStore,
}) => {
  const id = match.params?.id
  const DATA_FORMAT = 'YYYY-MM-DD'
  const DATA_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
  const dayToMiliseconds = 24 * 60 * 60 * 1000
  const typeTree = useMemo(
    () => ({
      CHU_TRI: 'CHU_TRI',
      PHOI_HOP: 'PHOI_HOP',
      THEO_DOI: 'THEO_DOI',
    }),
    []
  )
  const excelKeys = {
    title: 'Tiêu đề',
    deadline: 'Hạn thực hiện',
    description: 'Mô tả',
    priority: 'Ưu tiên',
  }
  const priorityKeys = {
    Không: UNSET,
    Thấp: WORK_PRIORITY.THAP,
    'Bình thường': WORK_PRIORITY.BINH_THUONG,
    Cao: WORK_PRIORITY.CAO,
  }
  const PHONG_BAN = 'DEPARTMENT'
  const DON_VI = 'ORGANIZATION'

  const DEFAULT_TASK = useMemo(
    () => ({
      showTask: true,
      showExtra: false,
      data: {
        executionDate: moment(),
      },
      validate: {},
    }),
    []
  )

  const [tasks, setTasks] = useState([{ ...DEFAULT_TASK, id: randomId() }])
  const [taskDetail, setTaskDetail] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [validateErrors, setValidateErrors] = useState({})
  const [showTemplateTaskId, setShowTemplateTaskId] = useState()
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [idTempDialog, setIdTempDialog] = useState(null)
  const [newDataTreeStore, setNewDataTreeStore] = useState([])
  const [isLoadingTree, setIsLoadingTree] = useState(false)
  const [favoriteGroups, setFavoriteGroups] = useState([])
  const [dataAutoToggle, setDataAutoToggle] = useState([])
  const [tags, setTags] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)
  const [fileDinhKem, setFileDinhKem] = useState([])

  const onSetFileDinhKem = useCallback((files, id) => {
    setFileDinhKem(prev => ({
      ...prev,
      [id]: files,
    }))
  }, [])

  const handleConvertToStateData = useCallback(
    data => {
      const coordinations = get(data, 'responsibilities[1].workAssignments', [])
      const originCoordinations = coordinations?.map(coordination => {
        const responsibility = coordination?.responsibility
        let parentId = responsibility?.information?.department?.id

        return {
          id: get(coordination, 'responsibilityId'),
          type: get(coordination, 'assignType'),
          parentId: parentId,
          isLanhDaoPhongBan: coordination?.responsibility?.isLanhDaoPhongBan,
        }
      })
      const watchers = get(data, 'responsibilities[2].workAssignments', [])
      const originWatchers = watchers?.map(watcher => ({
        id: get(watcher, 'responsibilityId'),
        type: get(watcher, 'assignType'),
        parentId: get(watcher, 'responsibility.information.department.id'),
        isLanhDaoPhongBan: watcher?.responsibility?.isLanhDaoPhongBan,
      }))

      const referenceFiles = (data?.files || []).filter(
        file => file?.type === FILE_TYPE.FILE_THAM_KHAO
      )
      const attachmentFiles = (data?.files || [])
        .filter(file => file?.type === FILE_TYPE.FILE_DINH_KEM)
        .map(file => ({ ...file, showClose: file.application !== 'eOffice' }))
      return {
        title: get(data, 'title') || '',
        deadline:
          get(data, 'deadline') && moment(get(data, 'deadline')).isValid()
            ? moment(get(data, 'deadline'))
            : moment(get(data, 'originalDeadline')).isValid()
            ? moment(get(data, 'originalDeadline'))
            : null,
        requireReport: data?.requireReport || false,
        executionDate:
          get(data, 'executionDate') &&
          moment(get(data, 'executionDate')).isValid()
            ? moment(get(data, 'executionDate'))
            : null,
        description: get(data, 'description') || '',
        workReminder: get(data, 'workReminder')
          ? {
              type: get(data, 'workReminder.type'),
              date: moment(get(data, 'workReminder.reminderTime')),
            }
          : undefined,
        priority: get(data, 'priority'),
        tags: get(data, 'workUserTags', []).map(tag => tag.workTagId),
        [typeTree.CHU_TRI]: get(data, 'mainResponsibilityId')
          ? {
              id: get(data, 'mainResponsibilityId'),
              type: get(data, 'mainResponsibility.type'),
            }
          : null,
        [typeTree.PHOI_HOP]: originCoordinations,
        [typeTree.THEO_DOI]: originWatchers,
        workType: data?.workType,
        referenceId: data?.referenceId,
        referenceFiles,
        attachmentFiles,
      }
    },
    [typeTree]
  )

  const fetchDetail = useCallback(() => {
    const handleDataParent = data => {
      const newParent = []
      const mainResponsibility = data?.mainResponsibility?.information
      if (mainResponsibility) {
        const departmentId = mainResponsibility?.department?.id
        const organizationId = mainResponsibility?.organization?.id
        if (departmentId) {
          newParent.push(departmentId)
        }

        if (organizationId) {
          newParent.push(organizationId)
        }
      }

      const coordinations = data?.responsibilities?.[1]?.workAssignments || []
      if (size(coordinations) !== 0) {
        coordinations.forEach(elm => {
          const departmentId = elm?.responsibility?.information?.department?.id
          const organizationId =
            elm?.responsibility?.information?.organization?.id
          if (departmentId) {
            newParent.push(departmentId)
          }

          if (organizationId) {
            newParent.push(organizationId)
          }
        })
      }

      const watchers = get(data, 'responsibilities[2].workAssignments', [])
      if (size(watchers) !== 0) {
        watchers.forEach(elm => {
          const departmentId = elm?.responsibility?.information?.department?.id
          const organizationId =
            elm?.responsibility?.information?.organization?.id
          if (departmentId) {
            newParent.push(departmentId)
          }

          if (organizationId) {
            newParent.push(organizationId)
          }
        })
      }
      const newParentConvert = uniq(newParent)
      if (size(newParentConvert) !== 0) {
        setDataAutoToggle(newParentConvert)
      }
    }

    requestAction({
      showToast: false,
      beforeAction: () => setFetching(true),
      action: () => getCongViec(id),
      afterResponse: data => {
        setTaskDetail(data)
        handleDataParent(data)
        setTasks([
          {
            ...DEFAULT_TASK,
            id,
            data: handleConvertToStateData(data),
          },
        ])
      },
      afterAction: () => setFetching(false),
    })
  }, [id, handleConvertToStateData, DEFAULT_TASK])

  const fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => setTags(items),
    })
  }, [])

  const fetchFavoriteGroup = useCallback(() => {
    const convertType = type => {
      if (type === 'PB') {
        return 'DEPARTMENT'
      } else if (type === 'DV') {
        return 'ORGANIZATION'
      }

      return 'PERSONAL'
    }

    requestAction({
      showToast: false,
      action: () => getMyFavoriteGroups({ is_active: true }),
      afterResponse: ({ items }) => {
        setFavoriteGroups(
          items.map(({ id, name, members }) => ({
            id,
            name,
            children: members.map(
              ({ id, name, avatar, departmentId, ...res }) => ({
                ...res,
                id,
                parentId: departmentId,
                name,
                type: convertType('PERSONAL'),
                avatar,
              })
            ),
          }))
        )
      },
    })
  }, [])

  const onToggleCollapseTask = id => () => {
    setTasks(prev =>
      prev.map(task =>
        task.id === id
          ? {
              ...task,
              showTask: !task.showTask,
              showExtra: false,
            }
          : {
              ...task,
              showTask: false,
            }
      )
    )
  }

  const _changeFormValidate = ({ id, name, value }) => {
    setValidateErrors(prev => ({
      ...prev,
      [id]: {
        ...(prev?.[id] || {}),
        [name]: value,
      },
    }))
  }

  const _changeFormValue = ({ id, name, value }) => {
    setTasks(prev =>
      prev.map(task =>
        task.id === id
          ? {
              ...task,
              data: {
                ...task.data,
                [name]: value,
                ...(name === 'deadline' ? { workReminder: null } : {}),
              },
            }
          : task
      )
    )
  }

  const onChangeField = (id, name) => value => {
    setIsDisabledButtonDefault(false)
    _changeFormValidate({
      id,
      name,
      value: false,
    })

    _changeFormValue({
      id,
      name,
      value,
    })
  }

  const onToggleShowTemplate = (id = null) => {
    setShowTemplateTaskId(id)
  }

  const onRemoveTask = id => () => {
    setIdTempDialog(id)
    setIsShowDialog(true)
  }

  const onChangeCheckbox = useCallback(
    async (name, values, taskId, event, isIndeterminate) => {
      setIsDisabledButtonDefault(false)
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      const isOtherWorkType =
        taskDetail?.workType === WORK_TYPE.MEETING ||
        taskDetail?.workType === WORK_TYPE.EOFFICE ||
        taskDetail?.workType === WORK_TYPE.DOFFICE
      const isPhongBanDonVi =
        values?.type === PHONG_BAN ||
        values?.type === DON_VI ||
        values?.type === 'ALL'
      let treeItem = []
      if (isPhongBanDonVi) {
        treeItem =
          newDataTreeStore?.find(e => e?.id === values?.id)?.children || []
        setDataAutoToggle([values?.id])
      }

      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            let newData = []
            const oldData = elm?.data?.[name] || []
            newData = [...oldData, values]
            if (isPhongBanDonVi) {
              if (!isChecked) {
                newData = differenceBy(
                  newData || [],
                  [...treeItem, values],
                  'id'
                )
              } else {
                if ((isOtherWorkType && !hasShowAll) || !isOtherWorkType) {
                  newData = [...newData, ...(treeItem || [])]
                  newData = differenceBy(newData || [], [values], 'id')
                }
              }

              const dataChuTri = elm?.data?.[typeTree.CHU_TRI]
                ? [elm?.data?.[typeTree.CHU_TRI]]
                : []
              const dataPhoiHop = elm?.data?.[typeTree.PHOI_HOP] || []
              const dataTheoDoi = elm?.data?.[typeTree.THEO_DOI] || []
              if (
                name === typeTree.PHOI_HOP &&
                (!isOtherWorkType || (isOtherWorkType && !hasShowAll))
              ) {
                newData = differenceBy(
                  newData,
                  [...dataChuTri, ...dataTheoDoi],
                  'id'
                )
              } else if (
                name === typeTree.THEO_DOI &&
                (!isOtherWorkType || (isOtherWorkType && !hasShowAll))
              ) {
                newData = differenceBy(
                  newData,
                  [...dataChuTri, ...dataPhoiHop],
                  'id'
                )
              }

              if (
                (name === typeTree.PHOI_HOP || name === typeTree.THEO_DOI) &&
                size(dataChuTri) !== 0 &&
                dataChuTri?.some(
                  e =>
                    e?.id === values?.id &&
                    (e?.type === PHONG_BAN || e?.type === DON_VI)
                )
              ) {
                newData = newData?.filter(
                  e =>
                    e.parentId !== values?.id ||
                    (e.parentId === values?.id && !e?.isLanhDaoPhongBan)
                )
              }
            } else {
              if (!isChecked) {
                newData = differenceBy(newData || [], [values], 'id')
              }
            }

            newData = differenceBy(newData, [{ id: roleId }], 'id')

            const chuTri =
              name !== typeTree.CHU_TRI &&
              elm?.data?.[typeTree.CHU_TRI]?.id === values?.id &&
              ((elm?.data?.[typeTree.CHU_TRI]?.type !== PHONG_BAN &&
                elm?.data?.[typeTree.CHU_TRI]?.type !== DON_VI &&
                !isOtherWorkType) ||
                isOtherWorkType)
                ? null
                : elm?.data?.[typeTree.CHU_TRI]
            const phoiHop =
              name === typeTree.PHOI_HOP
                ? uniqBy(newData, 'id')
                : elm?.data?.[typeTree.PHOI_HOP]?.filter(
                    e => e.id !== values?.id
                  ) || []
            const theoDoi =
              name === typeTree.THEO_DOI
                ? uniqBy(newData, 'id')
                : elm?.data?.[typeTree.THEO_DOI]?.filter(
                    e => e.id !== values?.id
                  ) || []

            return {
              ...elm,
              data: {
                ...elm?.data,
                [typeTree.CHU_TRI]: chuTri,
                [typeTree.PHOI_HOP]: phoiHop,
                [typeTree.THEO_DOI]: theoDoi,
              },
            }
          }

          return elm
        })
      )
    },
    [
      typeTree,
      newDataTreeStore,
      taskDetail,
      hasShowAll,
      roleId,
      setTasks,
      setDataAutoToggle,
    ]
  )

  const onChangeRadio = useCallback(
    (values, taskId) => {
      setIsDisabledButtonDefault(false)
      const isPhongBanDonVi =
        values?.type === PHONG_BAN || values?.type === DON_VI
      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            return {
              ...elm,
              data: {
                ...elm?.data,
                [typeTree.CHU_TRI]: values,
                [typeTree.PHOI_HOP]: !isPhongBanDonVi
                  ? elm?.data?.[typeTree.PHOI_HOP]?.filter(
                      e => e.id !== values?.id
                    )
                  : isPhongBanDonVi
                  ? elm?.data?.[typeTree.PHOI_HOP]?.filter(
                      e =>
                        (e.parentId !== values?.id && e.id !== values?.id) ||
                        (e.parentId === values?.id && !e.isLanhDaoPhongBan)
                    )
                  : elm?.data?.[typeTree.PHOI_HOP],
                [typeTree.THEO_DOI]: !isPhongBanDonVi
                  ? elm?.data?.[typeTree.THEO_DOI]?.filter(
                      e => e.id !== values?.id
                    )
                  : isPhongBanDonVi
                  ? elm?.data?.[typeTree.THEO_DOI]?.filter(
                      e =>
                        (e.parentId !== values?.id && e.id !== values?.id) ||
                        (e.parentId === values?.id && !e.isLanhDaoPhongBan)
                    )
                  : elm?.data?.[typeTree.THEO_DOI],
              },
            }
          }

          return elm
        })
      )
    },
    [typeTree, setTasks]
  )

  const handleCheckDisable = useCallback(
    (key, taskId, record) => {
      let isDisable = false
      if (
        (key === typeTree.PHOI_HOP || key === typeTree.THEO_DOI) &&
        roleId === record?.id
      ) {
        isDisable = true
      }

      const dataChuTri =
        tasks?.find(e => e?.id === taskId)?.data?.[typeTree.CHU_TRI] || null
      if (
        (key === typeTree.PHOI_HOP || key === typeTree.THEO_DOI) &&
        !isEmpty(dataChuTri) &&
        record?.parentId === dataChuTri?.id &&
        record?.isLanhDaoPhongBan
      ) {
        isDisable = true
      }

      return isDisable
    },
    [typeTree, tasks, roleId]
  )

  const handleCheckActive = useCallback(
    (key, tasks, taskId, record) => {
      const parentItem =
        newDataTreeStore?.find(e => e?.id === record?.id)?.children || []
      const taskItem = tasks?.find(elm => elm?.id === taskId)
      let isChecked = false
      if (
        !isEmpty(taskItem) !== 0 &&
        !isEmpty(taskItem?.data) &&
        size(taskItem?.data?.[key]) !== 0
      ) {
        const taskItemDataKey = taskItem?.data?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        const dataKey = taskItem?.data?.[key]?.findIndex(
          e => e?.id === record?.id
        )
        if (
          (size(parentItem) !== 0 &&
            size(parentItem) === size(taskItemDataKey)) ||
          dataKey === 0 ||
          (dataKey && dataKey !== -1)
        ) {
          isChecked = true
        }
      }

      return isChecked
    },
    [newDataTreeStore]
  )

  const handleCheckIndeterminate = useCallback(
    (name, tasks, taskId, record) => {
      const taskItem = tasks?.find(e => e.id === taskId)
      let isChecked = false
      const dataKey = size(taskItem?.data?.[name]) !== 0
      const hasChecked = taskItem?.data?.[name]?.some(
        e => e?.parentId === record?.id
      )
      if (
        dataKey &&
        (record?.type === PHONG_BAN ||
          record?.type === DON_VI ||
          record?.type === 'ALL') &&
        hasChecked
      ) {
        isChecked = true
      }

      return isChecked
    },
    []
  )

  const toggleCollapseExtra = id => () => {
    setTasks(
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showExtra: !task.showExtra,
            }
          : task
      )
    )
  }

  const addTask = () => {
    setTasks([
      ...tasks?.map(t => ({ ...t, showTask: false, showExtra: false })),
      { ...DEFAULT_TASK, id: randomId() },
    ])
  }

  const importExcel = e => {
    const { files } = e.target
    if (!files || !files[0]) {
      return
    }

    const reader = new FileReader()
    reader.onload = e => {
      const bstr = e.target.result
      const wb = xlsx.read(bstr, { type: 'binary' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = xlsx.utils.sheet_to_json(ws)
      setTasks(
        data.map(record => ({
          ...DEFAULT_TASK,
          id: randomId(),
          data: {
            title: record[excelKeys.title] || '',
            deadline:
              record[excelKeys.deadline] &&
              moment(record[excelKeys.deadline] * dayToMiliseconds),
            description: record[excelKeys.description] || '',
            priority: priorityKeys[record[excelKeys.priority] || 'Không'],
          },
        }))
      )
    }

    reader.readAsBinaryString(files[0])
  }

  const convertDataType = (values, type) => ({
    responsibilityId: get(values, 'id'),
    assignType: get(values, 'type'),
    responsibilityType: type,
  })

  const getConvertData = () => {
    return (tasks || []).map(task => {
      const originData = get(task, 'data', {})
      const newData = {
        title: get(originData, 'title', '').trim(),
        description: get(originData, 'description', '').trim(),
        priority:
          get(originData, 'priority') !== UNSET
            ? get(originData, 'priority')
            : undefined,
        workUserTags: get(originData, 'tags', []).map(item => ({
          workTagId: item,
        })),
        attachmentFileIds: get(originData, 'attachmentFiles', []).map(
          file => file.id
        ),
        referenceFileIds: get(originData, 'referenceFiles', []).map(
          file => file.id
        ),
        isSaveTemplate: get(originData, 'isSaveTemplate'),
        requireReport: originData?.requireReport || false,
      }

      const deadline = get(originData, 'deadline')
      if (deadline) {
        newData.deadline = moment(deadline).startOf('day').format(DATA_FORMAT)
      }

      const executionDate = get(originData, 'executionDate')
      if (executionDate) {
        newData.executionDate = moment(executionDate).format(DATA_FORMAT)
      }

      const workReminder = get(originData, 'workReminder')
      if (workReminder) {
        newData.workReminder = {
          type: workReminder.type,
          reminderTime: workReminder.date.format(DATA_TIME_FORMAT),
        }
      }

      const executor = get(originData, typeTree.CHU_TRI)
        ? [
            {
              responsibilityId: get(originData, `${typeTree.CHU_TRI}.id`),
              assignType: get(originData, `${typeTree.CHU_TRI}.type`),
              responsibilityType: RESPONSIBILITY_TYPE.CHU_TRI,
            },
          ]
        : []
      const coordination = get(originData, typeTree.PHOI_HOP, [])?.map(elm =>
        convertDataType(elm, RESPONSIBILITY_TYPE.PHOI_HOP)
      )

      const watcher = get(originData, typeTree.THEO_DOI, [])?.map(elm =>
        convertDataType(elm, RESPONSIBILITY_TYPE.THEO_DOI)
      )
      newData.workAssignments = [...executor, ...coordination, ...watcher]

      return {
        ...task,
        data: newData,
      }
    })
  }

  const handleUpload = async () => {
    const responseFilesObject = {}
    await Promise.all(
      Object.entries(fileDinhKem).map(async ([key, files]) => {
        responseFilesObject[key] = []
        const filesTemp = []
        files.forEach(e => {
          if (e?.file) {
            filesTemp.push(e?.file)
          } else {
            responseFilesObject[key] = [
              ...responseFilesObject[key],
              { id: e?.id },
            ]
          }
        })

        if (filesTemp?.length > 0 ?? false) {
          await requestAction({
            action: () => postUpload(filesTemp),
            afterResponse: (result = []) => {
              responseFilesObject[key] = [
                ...responseFilesObject[key],
                ...result,
              ]
            },
            showToastSucess: false,
            codeCheck: false,
            showToast: false,
            afterError: () => {
              showToast({
                message: 'Kiểu file không hợp lệ',
                intent: Intent.DANGER,
              })
            },
          })
        }

        return
      })
    )

    return responseFilesObject
  }

  const getDataWithUpload = async () => {
    const cloneTasks = getConvertData()
    const responseFilesObject = await handleUpload()
    return (cloneTasks || []).map(task =>
      toDecamelizeKeys({
        ...(task?.data || {}),
        attachmentFileIds: (responseFilesObject[task.id] || [])
          ?.filter(f => f?.id)
          .map(file => file.id),
      })
    )
  }

  const _validateRequired = (name, key) => {
    let success = true
    const tasksNew = [...tasks]
    tasksNew.forEach(({ id, data }) => {
      let isCheck = isString(data[name]) && !data[name].trim()
      if (key) {
        isCheck = isString(data?.[name]?.[key]) && !data?.[name]?.[key].trim()
      }

      if (isEmpty(data[name]) || isCheck) {
        success = false
        _changeFormValidate({ id, name, value: true })
      } else {
        _changeFormValidate({ id, name, value: false })
      }
    })

    return success
  }

  const _validateTitle = () => {
    return _validateRequired('title')
  }

  const _validate = () => {
    const success = _validateTitle()
    const isHasChuTri = _validateRequired(typeTree.CHU_TRI, 'id')
    if (!success || !isHasChuTri) {
      throw new Error('warning')
    }
  }

  const handleFavoriteGroupSave = () => {
    const pushData = (arr = []) => {
      if (!arr || arr?.length === 0) {
        return []
      }

      return arr.reduce((result, item) => {
        if (item?.type === 'PERSONAL') {
          return [...result, item]
        } else if (item?.type !== 'PERSONAL') {
          return [...result, ...item?.children]
        }

        return result
      }, [])
    }

    const convertData = (arr = []) => {
      if (!arr || arr?.length === 0) {
        return []
      }

      return uniqBy(
        arr?.map(item => ({
          avatar: item?.anhDaiDien ? item?.anhDaiDien : item?.avatar,
          department_id: item?.parentId,
          department_name: item?.tenPhongBan
            ? item?.tenPhongBan
            : item?.departmentName,
          department_shortname: item?.departmentShortname
            ? item?.departmentShortname
            : item?.tenPhongBan,
          id: item?.chucDanhId ? item?.chucDanhId : item?.id,
          name: item?.tenNhanVien ? item?.tenNhanVien : item?.name,
        })),
        'id'
      )
    }

    tasks.forEach(elm => {
      if (elm?.data?.isFavoriteGroupSave) {
        const tempUsers = [
          ...(elm?.data?.CHU_TRI?.id
            ? elm?.data?.CHU_TRI?.type !== 'PERSONAL'
              ? elm?.data?.CHU_TRI?.children
              : [elm?.data?.CHU_TRI]
            : []),
          ...(elm?.data?.PHOI_HOP && elm.data.PHOI_HOP?.length !== 0
            ? pushData(elm.data.PHOI_HOP)
            : []),
          ...(elm?.data?.THEO_DOI && elm.data.THEO_DOI?.length !== 0
            ? pushData(elm.data.THEO_DOI)
            : []),
        ]?.filter(f => f?.type === 'PERSONAL')
        if (tempUsers?.length !== 0) {
          try {
            requestAction({
              action: () => {
                const params = {
                  name: `Nhóm ${tempUsers?.length} thành viên`,
                  members: convertData(tempUsers),
                  isActive: true,
                }
                return postFavoriteGroup(toDecamelizeKeys(params))
              },
              showToast: false,
            })
            return true
          } catch (_) {
            return true
          }
        }
      }
    })

    return true
  }

  const submitCreate = async () => {
    let requestData = []
    requestAction({
      beforeAction: () => {
        setSubmitLoading(true)
        _validate()
      },
      action: async () => {
        requestData = await getDataWithUpload()
        handleFavoriteGroupSave()
        return postCongViec(requestData)
      },
      afterResponse: () => {
        actions.getCounterCongViecPhongBanCaNhan()

        return history.replace('/quan-ly-cong-viec/ca-nhan/da-giao')
      },
      afterAction: () => {
        setSubmitLoading(false)
      },
      successCode: 201 || 200,
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const submitUpdate = async () => {
    let requestData = []
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => {
        setSubmitLoading(true)
        _validate()
      },
      action: async () => {
        requestData = await getDataWithUpload()
        handleFavoriteGroupSave()
        return putCongViec(id, requestData[0])
      },
      afterResponse: res => {
        showToast({
          message: res?.message,
          intent: res?.result ? Intent.SUCCESS : Intent.DANGER,
        })

        if (!res?.result) {
          return
        }

        const mainAssign = requestData[0]?.work_assignments?.find(
          e => e?.responsibility_type === 'MAIN_RESPONSIBILITY'
        )
        if (mainAssign?.assign_type !== 'PERSONAL') {
          return history.replace('/quan-ly-cong-viec/phong-ban/da-giao')
        }

        return history.replace('/quan-ly-cong-viec/ca-nhan/da-giao')
      },
      afterAction: () => {
        setSubmitLoading(false)
        setIsDisabledButtonDefault(true)
      },
      errorMessage: Toast.FAIL(Action.UPDATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const _templateToFormData = useCallback(data => {
    return {
      title: get(data, 'title') || '',
      description: get(data, 'description') || '',
      priority: get(data, 'priority'),
      deadline:
        get(data, 'deadline') && moment(get(data, 'deadline')) > moment()
          ? moment(get(data, 'deadline'))
          : null,
      executionDate:
        get(data, 'executionDate') &&
        moment(get(data, 'executionDate')) > moment()
          ? moment(get(data, 'executionDate'))
          : null,
      tags: get(data, 'tags', []).map(tag => tag.id),
    }
  }, [])

  const applyTemplate = template => {
    setTasks(prevTask =>
      prevTask.map(task =>
        task.id === showTemplateTaskId
          ? {
              ...task,
              data: _templateToFormData(template),
            }
          : task
      )
    )
  }

  const onCloseDialog = useCallback(() => {
    setIsShowDialog(false)
    setIdTempDialog(null)
  }, [])

  const onDeleteTask = useCallback(() => {
    const id = idTempDialog
    const newTasks = tasks.filter(task => task.id !== id)
    setTasks(
      size(newTasks) === 1
        ? newTasks.map(t => ({ ...t, showTask: true }))
        : newTasks
    )
    // Xóa file lưu tạm của công việc đó
    setFileDinhKem(prev => {
      delete prev[id]
      return prev
    })

    onCloseDialog()
  }, [idTempDialog, tasks, onCloseDialog])

  const fetchPhongBanDonVi = useCallback(async () => {
    const convertType = type => {
      if (type === 'PB') {
        return 'DEPARTMENT'
      } else if (type === 'DV') {
        return 'ORGANIZATION'
      }

      return 'PERSONAL'
    }
    const convertUsers = values => {
      return {
        ...values,
        id: values?.chucDanhId,
        type: 'PERSONAL',
        name: values?.tenNhanVien,
        tenChucVu: values?.chucVu,
        maChucVu: values?.maChucVu,
        parentId: values?.parentId,
      }
    }

    let params = {
      donViId: mainUnitId,
      phongBanId: mainDepartmentId,
      limit: 9999,
    }
    await requestAction({
      showToast: false,
      successCode: 200,
      beforeAction: () => {
        setIsLoadingTree(true)
      },
      action: () => getPhongBanNhanVien(params),
      afterResponse: response => {
        let newData = []
        if (response?.items) {
          newData = response?.items?.map(elm => {
            return {
              id: elm?.phongBanId,
              type: convertType('PB'),
              name: elm?.maPhongBan,
              code: elm?.maPhongBan,
              parentId: elm?.donViId,
              children:
                elm?.nhanViens?.map(u =>
                  convertUsers({ ...u, parentId: elm?.phongBanId })
                ) || [],
            }
          })
        }

        requestAction({
          showToast: false,
          successCode: 0,
          action: () => saveTree(newData),
        })
      },
      afterAction: () => {
        setIsLoadingTree(false)
      },
    })
  }, [mainUnitId, mainDepartmentId])

  useEffect(() => {
    fetchFavoriteGroup()
  }, [fetchFavoriteGroup])

  useEffect(() => {
    fetchPhongBanDonVi()
  }, [fetchPhongBanDonVi])

  useEffect(() => {
    setNewDataTreeStore(dataTreeStore)
  }, [dataTreeStore])

  useEffect(() => {
    if (id) {
      fetchDetail()
    }
  }, [fetchDetail, id])

  useEffect(() => {
    fetchDsTag()
  }, [fetchDsTag])

  return {
    typeTree,
    tasks,
    fetching,
    taskDetail,
    validateErrors,
    newDataTreeStore,
    isLoadingTree,
    favoriteGroups,
    dataAutoToggle,
    tags,
    isDisabledButtonDefault,
    submitLoading,
    showTemplateTaskId,
    isShowDialog,
    fileDinhKem,
    onChangeRadio,
    handleCheckDisable,
    handleCheckActive,
    onToggleCollapseTask,
    onChangeField,
    onRemoveTask,
    onToggleShowTemplate,
    onChangeCheckbox,
    handleCheckIndeterminate,
    toggleCollapseExtra,
    addTask,
    importExcel,
    submitCreate,
    submitUpdate,
    applyTemplate,
    onCloseDialog,
    onDeleteTask,
    onSetFileDinhKem,
  }
}

export default useTaskCreate
