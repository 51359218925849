import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import { Tab2, Tabs2 } from '@blueprintjs/core'
import size from 'lodash/size'
import classNames from 'classnames'
import reverse from 'lodash/reverse'

import {
  CircleChart,
  BarChart,
  PieChart,
  BarChartVertical,
} from 'components/home'
import { Card, Icon, Scrollbar, Empty, Loading } from 'components/newCommon'
import { checkPermissionMenu, isValidCondition } from 'helpers/Helper'
import Utils from 'helpers/utils'
import { Permission } from 'components/auth'
import { WORK_TYPE } from 'constants/Enum'
import JobCard from './JobCard'
import SignNumberCard from './SignNumberCard'
import InfoCard from './infocard'
import HomeCard from './HomeCard'
const imageWorkEmpty = `${process.env.PUBLIC_URL}/images/to-do-list.svg`
const imageSignNumberEmpty = `${process.env.PUBLIC_URL}/images/signature.svg`

const HomePageNew = ({
  auth,
  history,
  statisticsDashboard,
  listLayYKienDonVi,
  listPhieuLayYKien,
  quyensMenu,
  numberSideBar,
  listDashboard,
  cauHinhDashboard,
}) => {
  const tabKey = {
    VIEC_MOI: 'VIEC_MOI',
    KY_SO_MOI: 'KY_SO_MOI',
    Y_KIEN_DONVI: 'Y_KIEN_DONVI',
    Y_KIEN_HDTV: 'Y_KIEN_HDTV',
  }
  const cardRefLeft = useRef()
  const [heightMasterCard, setHeightMasterCard] = useState(400)
  const [dataQuanLyCongViec, setQuanLyCongViecData] = useState([])
  const [dataCongVan, setCongVanData] = useState([])
  const [dataCongViec, setCongViecData] = useState([])
  const [dataKySo, setKySoData] = useState([])
  const [isTimeOut, setIsTimeOut] = useState(false)
  const [tagActive, setTagActive] = useState(tabKey.KY_SO_MOI)
  const [tabs, setTabs] = useState([])
  const [isLayoutDual, setIsLayoutDual] = useState(false)
  const [smallBlockList, setSmallBlockList] = useState([])
  const [nguonCVBlockList, setNguonCVBlockList] = useState([])
  const [bigBlockList, setBigBlockList] = useState(['BANLAMVIEC', 'OTHER'])
  const dashboardData = useMemo(() => {
    return cauHinhDashboard
  }, [cauHinhDashboard])

  const onWorkDetail = useCallback(
    (path = '/', type = null) => {
      let pathname = path
      if (!path) {
        pathname = '/quan-ly-cong-viec/ca-nhan/can-thuc-hien'
        if (isValidCondition(auth?.permission, ['CONGVIEC_PHONGBAN'])) {
          pathname = '/quan-ly-cong-viec/phong-ban/chua-giao'
        }
      }
      return history.push({
        pathname: pathname,
        ...(type && type !== 'CON_HAN'
          ? {
              state: {
                workDeadLine: type,
              },
            }
          : {}),
      })
    },
    [history, auth]
  )

  const renderNguonCongViec = useMemo(
    () => (value, index) => {
      if (!value) {
        return ''
      }
      const classStyle = workType => {
        let result = ''
        switch (workType) {
          case WORK_TYPE.MEETING:
            result = 'color-orange'
            break
          case WORK_TYPE.EOFFICE:
            result = 'color-blue'
            break
          case WORK_TYPE.DOFFICE:
            result = 'color-red'
            break
          case WORK_TYPE.CHAT:
            result = 'color-green'
            break
          default:
            break
        }

        return result
      }

      return (
        <div key={index} className="home-work-origin__item mt10">
          <HomeCard
            auth={auth}
            className={classStyle(value?.workType)}
            history={history}
            total={value?.total}
            workType={value?.workType}
            descriptions={value?.title}
          />
        </div>
      )
    },
    [history, auth]
  )

  const work = useMemo(
    () => (data, isTimeOut) => {
      return (
        <div>
          {isTimeOut && data && size(data) !== 0 && (
            <Scrollbar maxHeight={279} autoHeightMin={279}>
              <div className="scroll-inline">
                {data?.map((elm, index) => (
                  <JobCard
                    key={index}
                    className="mt10"
                    history={history}
                    data={elm}
                  />
                ))}
              </div>
            </Scrollbar>
          )}
          {isTimeOut && (!data || (data && size(data) === 0)) && (
            <div
              className="d-flex item-center justify-center"
              style={{ minHeight: 279 }}
            >
              <Empty
                image={imageWorkEmpty}
                size="SMALL"
                message="Chúc mừng anh/chị đã hoàn thành công việc"
              />
            </div>
          )}
          {!isTimeOut && <Loading />}
        </div>
      )
    },
    [history]
  )

  const signNumber = useMemo(
    () => (data, isTimeOut) => {
      return (
        <div>
          {isTimeOut && data && size(data) !== 0 && (
            <Scrollbar maxHeight={279} autoHeightMin={279}>
              <div className="scroll-inline">
                {data?.map((elm, index) => {
                  return (
                    <SignNumberCard
                      key={index}
                      className={classNames('mt10', {
                        'border-top pt15': index !== 0,
                      })}
                      history={history}
                      data={elm}
                    />
                  )
                })}
              </div>
            </Scrollbar>
          )}
          {isTimeOut && data && size(data) === 0 && (
            <div
              className="d-flex item-center justify-center"
              style={{ minHeight: 279 }}
            >
              <Empty
                image={imageSignNumberEmpty}
                size="SMALL"
                message="Chúc mừng anh/chị đã hoàn thành ký số"
              />
            </div>
          )}
          {!isTimeOut && <Loading />}
        </div>
      )
    },
    [history]
  )

  const feebackOrganization = useMemo(
    () => (data, isTimeOut) => {
      return (
        <div>
          {isTimeOut && data && size(data) !== 0 && (
            <Scrollbar maxHeight={279} autoHeightMin={279}>
              <div className="scroll-inline">
                {data?.map((elm, index) => {
                  return (
                    <InfoCard
                      key={index}
                      className={classNames('mt10', {
                        'border-top pt15': index !== 0,
                      })}
                      history={history}
                      types={[
                        {
                          type: 1,
                          name: 'Nhận góp ý',
                          className: 'light-blue',
                          path: '/tien-ich/lay-y-kien-don-vi/chi-tiet-nhan/:id',
                        },
                        {
                          type: 2,
                          name: 'Gửi góp ý',
                          className: 'yellow',
                          path: '/tien-ich/lay-y-kien-don-vi/chi-tiet-goi/:id',
                        },
                      ]}
                      labels={['Số/Ký hiệu', 'Thời hạn']}
                      data={{
                        id: elm?.id,
                        type: elm?.typeGopY,
                        kyHieu: elm?.soKyHieu,
                        ngay: elm?.thoiHan,
                        noiDung: elm?.trichYeu,
                      }}
                    />
                  )
                })}
              </div>
            </Scrollbar>
          )}
          {isTimeOut && data && size(data) === 0 && (
            <div
              className="d-flex item-center justify-center"
              style={{ minHeight: 279 }}
            >
              <Empty
                image={imageSignNumberEmpty}
                size="SMALL"
                message="Chúc mừng anh/chị đã hoàn thành ý kiến"
              />
            </div>
          )}
          {!isTimeOut && <Loading />}
        </div>
      )
    },
    [history]
  )

  const feebackHDTV = useMemo(
    () => (data, isTimeOut) => {
      return (
        <div>
          {isTimeOut && data && size(data) !== 0 && (
            <Scrollbar maxHeight={279} autoHeightMin={279}>
              <div className="scroll-inline">
                {data?.map((elm, index) => {
                  return (
                    <InfoCard
                      key={index}
                      className={classNames('mt10', {
                        'border-top pt15': index !== 0,
                      })}
                      history={history}
                      types={[
                        {
                          type: -1,
                          path: '/hoi-dong-thanh-vien/lay-y-kien/chi-tiet/:id',
                        },
                      ]}
                      labels={['Số/Ký hiệu', 'Ngày tạo']}
                      data={{
                        id: elm?.phieuLayYKienId,
                        type: -1,
                        kyHieu: elm?.soKyHieu,
                        ngay: elm?.ngayLap,
                        noiDung: elm?.trichYeu,
                      }}
                    />
                  )
                })}
              </div>
            </Scrollbar>
          )}
          {isTimeOut && data && size(data) === 0 && (
            <div
              className="d-flex item-center justify-center"
              style={{ minHeight: 279 }}
            >
              <Empty
                image={imageSignNumberEmpty}
                size="SMALL"
                message="Chúc mừng anh/chị đã hoàn thành ý kiến"
              />
            </div>
          )}
          {!isTimeOut && <Loading />}
        </div>
      )
    },
    [history]
  )

  const tabTitle = (tabId, name) => {
    let title = name
    switch (tabId) {
      case tabKey.VIEC_MOI:
        if (size(statisticsDashboard?.congViecMoi) !== 0) {
          title = `${name} (${size(statisticsDashboard?.congViecMoi)})`
        }

        break
      case tabKey.KY_SO_MOI:
        if (size(listDashboard) !== 0) {
          title = `${name} (${size(listDashboard)})`
        }

        break
      case tabKey.Y_KIEN_DONVI:
        if (size(listLayYKienDonVi?.items ?? 0) !== 0) {
          title = `${name} (${size(listLayYKienDonVi?.items)})`
        }

        break
      case tabKey.Y_KIEN_HDTV:
        if (size(listPhieuLayYKien?.items ?? 0) !== 0) {
          title = `${name} (${size(listPhieuLayYKien?.items)})`
        }

        break
      default:
        break
    }

    return title
  }

  const tabContent = tabId => {
    switch (tabId) {
      case tabKey.VIEC_MOI:
        return work(statisticsDashboard?.congViecMoi ?? null, isTimeOut)
      case tabKey.KY_SO_MOI:
        return signNumber(listDashboard, isTimeOut)
      case tabKey.Y_KIEN_DONVI:
        return feebackOrganization(listLayYKienDonVi?.items, isTimeOut)
      case tabKey.Y_KIEN_HDTV:
        return feebackHDTV(listPhieuLayYKien?.items, isTimeOut)
      default:
        break
    }
  }

  const handlePermission = useMemo(
    () => values => {
      if (
        values?.id === tabKey.Y_KIEN_DONVI &&
        size(listLayYKienDonVi?.items ?? []) === 0
      ) {
        return false
      }

      if (
        values?.id === tabKey.Y_KIEN_HDTV &&
        size(listPhieuLayYKien?.items ?? []) === 0
      ) {
        return false
      }

      if (
        (!values?.permission && !values?.orPermission) ||
        (values?.permission &&
          ((values?.permissionMenu &&
            isValidCondition(auth.permission, values?.permission, 'AND') &&
            checkPermissionMenu(quyensMenu, values?.permissionMenu)) ||
            (!values?.permissionMenu &&
              isValidCondition(auth.permission, values?.permission, 'AND')))) ||
        (values?.orPermission &&
          isValidCondition(auth.permission, values?.orPermission, 'OR'))
      ) {
        return true
      }

      return false
    },
    // eslint-disable-next-line
    [auth?.permission]
  )

  useEffect(() => {
    const value = statisticsDashboard?.congViecCanXuLy
    setQuanLyCongViecData([
      {
        type: 'CON_HAN',
        name: 'Còn hạn',
        value: value?.conHan || 0,
        color: '#1552DC',
        className: 'color-blue',
      },
      {
        type: 'ON_TIME',
        name: 'Gần đến hạn',
        value: value?.ganDenHan || 0,
        color: '#FF951A',
        className: 'color-orange',
      },
      {
        type: 'OVERDUE',
        name: 'Quá hạn',
        value: value?.quaHan || 0,
        color: '#CC1F1F',
        className: 'color-red',
      },
    ])
  }, [statisticsDashboard])

  useEffect(() => {
    setCongVanData([
      {
        type: 'CONGVANCHUAXULY',
        name: 'Công văn chưa xử lý',
        value: numberSideBar?.congVanChuaXuLy || 0,
        color: '#FF951A',
        path: '/cong-van/cong-van-den/danh-sach/chua-xu-ly',
        permission: [Permission.CONGVANDEN],
        permissionMenu: [Permission.MENU_SIDEBAR.SMENU_CVAN_CANXULY],
      },
      {
        type: 'CONGVIECPBCHUAGIAO',
        name: 'Công việc phòng ban chưa giao',
        value: numberSideBar?.congViecChuaGiao || 0,
        color: '#00AA48',
        path: '/cong-viec/phong-ban/danh-sach/chua-giao',
        permission: [
          Permission.CONGVIEC_GIAOVIEC,
          Permission.CONGVIEC_PHONGBAN,
        ],
        permissionMenu: [Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC],
      },
    ])
    setCongViecData([
      {
        type: 'CONGVIECCHUATHUCHIEN',
        name: 'Chưa thực hiện',
        value: numberSideBar?.congViecChuaThucHien || 0,
        color: '#FF951A',
        className: 'color-orange',
        path: '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien',
        permission: [Permission.CONGVIEC_CANHAN],
      },
      {
        type: 'CONGVIECDANGTHUCHIEN',
        name: 'Đang thực hiện',
        value: numberSideBar?.congViecDangThucHien || 0,
        color: '#1552DC',
        className: 'color-blue',
        path: '/cong-viec/ca-nhan/danh-sach/dang-thuc-hien',
      },
    ])
    setKySoData([
      {
        type: 'CONGVANDICANKYSO',
        name: 'KSD',
        value: numberSideBar?.congVanDiCanKySo || 0,
        backgroundColor: '#2FAFD0',
        permission: [Permission.KYSO_QUANLY],
        permissionMenu: [Permission.MENU_SIDEBAR.SMENU_KYSO_DSCVANDI],
        path: '/ky-so/cong-van-di/danh-sach',
      },
      {
        type: 'CONGVANNOIBOCANKYSO',
        name: 'KSNB',
        value: numberSideBar?.congVanNoiBoCanKySo || 0,
        backgroundColor: '#00AA48',
        path: '/ky-so/cong-van-noi-bo/danh-sach',
      },
      {
        type: 'KYSOMORONG',
        name: 'KSMR',
        value: numberSideBar?.kySoMoRong || 0,
        backgroundColor: '#FF951A',
        path: '/ky-so/du-an/danh-sach',
      },
      {
        type: 'KYSOTAPTRUNG',
        name: 'KSTT',
        value: numberSideBar?.kySoTapTrung || 0,
        backgroundColor: '#1552DC',
        path: '/ky-so-tap-trung/van-ban-ky-duyet/danh-sach',
      },
    ])
    if (numberSideBar) {
      setTimeout(() => {
        setIsTimeOut(true)
      }, 2000)
    }
  }, [numberSideBar])

  useEffect(() => {
    const isCheckWork =
      checkPermissionMenu(quyensMenu, [
        Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
        Permission.MENU_SIDEBAR.SMENU_CVAN_CANXULY,
      ]) &&
      isValidCondition(
        auth.permission,
        [Permission.CONGVANDEN, Permission.KYSO_QUANLY],
        'OR'
      ) &&
      isValidCondition(
        auth.permission,
        [
          Permission.CONGVIEC_GIAOVIEC,
          Permission.CONGVIEC_PHONGBAN,
          Permission.CONGVIEC_CANHAN,
        ],
        'OR'
      )
    setTabs([
      {
        id: tabKey.KY_SO_MOI,
        name: 'Ký số',
      },
      {
        id: tabKey.Y_KIEN_DONVI,
        name: 'Ý kiến Đơn vị',
        orPermission: [
          Permission.LAYYKIENDONVI_PHIEUGUI,
          Permission.LAYYKIENDONVI_PHIEUNHAN,
        ],
      },
      {
        id: tabKey.Y_KIEN_HDTV,
        name: 'Ý kiến HĐTV',
        permission: [Permission.HDTV_LAYYKIEN],
        permissionMenu: [Permission.MENU_SIDEBAR.SMENU_HDTV_LAYYKIEN],
      },
      ...(isCheckWork
        ? [
            {
              id: tabKey.VIEC_MOI,
              name: 'Công việc mới',
            },
          ]
        : []),
    ])
    if (!isCheckWork) {
      setIsLayoutDual(true)
    }
    // eslint-disable-next-line
  }, [quyensMenu])

  useEffect(() => {
    const offsetHeight = cardRefLeft?.current?.offsetHeight ?? 500
    setHeightMasterCard(offsetHeight)
    // eslint-disable-next-line
  }, [cardRefLeft?.current])

  useEffect(() => {
    setSmallBlockList(dashboardData?.filter(f => f?.block !== 'BANLAMVIEC'))
    setNguonCVBlockList(
      dashboardData?.find(f => f?.block === 'BANLAMVIEC')?.subBlock
    )
    setBigBlockList(
      dashboardData?.find(f => f?.block === 'BANLAMVIEC')?.viTri === 0
        ? ['BANLAMVIEC', 'OTHER']
        : reverse(['BANLAMVIEC', 'OTHER'])
    )
  }, [dashboardData])

  return (
    <div className="wrap-content__homepage font-roboto">
      <div className="row">
        {bigBlockList
          ?.filter(
            f =>
              !f !== 'BANLAMVIEC' ||
              (f === 'BANLAMVIEC' &&
                isValidCondition(
                  auth.permission,
                  [Permission.SUDUNG_QLCV],
                  'OR'
                ))
          )
          .map((bb, index) => {
            if (bb === 'BANLAMVIEC') {
              return (
                <div
                  key={index}
                  className={classNames({
                    'col-xs-12 col-sm-12 col-md-12 col-lg-6': !isLayoutDual,
                    'col-xs-12 col-md-12 col-lg-9': isLayoutDual,
                  })}
                >
                  <Card
                    className="mt20"
                    padding={10}
                    rounded
                    bordered
                    title={
                      <div>
                        <Icon
                          classIcon="icon2-ds-thong-ke"
                          className="color-blue mr10"
                        />
                        <span className="text-uppercase color-blue font-roboto font-weight-600">
                          Bàn làm việc
                        </span>
                      </div>
                    }
                  >
                    <div ref={cardRefLeft}>
                      <div style={{ minHeight: '309px' }}>
                        <div
                          className={classNames('home-layout-dual', {
                            dual: isLayoutDual,
                          })}
                        >
                          <div>
                            <Tabs2
                              className="home-tabs"
                              scrollable
                              defaultSelectedTabId={tagActive}
                              selectedTabId={tagActive}
                              onChange={tabId => setTagActive(tabId)}
                            >
                              {tabs?.filter(handlePermission).map(tab => (
                                <Tab2
                                  key={tab.id}
                                  id={tab.id}
                                  title={tabTitle(tab.id, tab.name)}
                                  panel={tabContent(tab.id)}
                                />
                              ))}
                            </Tabs2>
                          </div>
                          {isLayoutDual && (
                            <div>
                              <p
                                className="font-size-14 font-weight-600"
                                style={{ lineHeight: '30px' }}
                              >
                                {`Công việc mới${
                                  size(listDashboard) !== 0
                                    ? `(${size(listDashboard)})`
                                    : ''
                                }`}
                              </p>
                              {work(
                                statisticsDashboard?.congViecMoi ?? null,
                                isTimeOut
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="border-top">
                        <p className="font-size-14 font-weight-600 mt20">
                          Công việc theo nguồn
                        </p>
                        <div style={{ minHeight: '80px' }}>
                          <div
                            className={classNames('home-work-origin', {
                              dual: isLayoutDual,
                            })}
                          >
                            {nguonCVBlockList?.map((elm, index) => {
                              let data = null
                              if (elm?.block === 'CONGVIECKHAC') {
                                data =
                                  statisticsDashboard?.counterCongViec?.find(
                                    f => f.workType === 'NORMAL'
                                  )
                              } else if (elm?.block === 'TBKLCH') {
                                data =
                                  statisticsDashboard?.counterCongViec?.find(
                                    f => f.workType === 'MEETING'
                                  )
                              } else if (elm?.block === 'KYSOCONGVAN') {
                                data =
                                  statisticsDashboard?.counterCongViec?.find(
                                    f => f.workType === 'EOFFICE'
                                  )
                              } else if (elm?.block === 'CHAT') {
                                data =
                                  statisticsDashboard?.counterCongViec?.find(
                                    f => f.workType === 'CHAT'
                                  )
                              } else if (elm?.block === 'DOFFICE') {
                                data =
                                  statisticsDashboard?.counterCongViec?.find(
                                    f => f.workType === 'DOFFICE'
                                  )
                              }

                              return renderNguonCongViec(data, index)
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              )
            }

            return (
              <div
                key={index}
                className={classNames({
                  'col-xs-12 col-sm-12 col-md-12 col-lg-6': !isLayoutDual,
                  'col-xs-12 col-sm-12 col-md-12 col-lg-3': isLayoutDual,
                })}
              >
                <div className="row">
                  {smallBlockList
                    ?.filter(
                      f =>
                        (f?.block === 'CONGVAN' &&
                          checkPermissionMenu(quyensMenu, [
                            Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
                            Permission.MENU_SIDEBAR.SMENU_CVAN_CANXULY,
                          ]) &&
                          (isValidCondition(
                            auth.permission,
                            [Permission.CONGVANDEN],
                            'OR'
                          ) ||
                            isValidCondition(
                              auth.permission,
                              [
                                Permission.CONGVIEC_GIAOVIEC,
                                Permission.CONGVIEC_PHONGBAN,
                              ],
                              'AND'
                            ))) ||
                        (f?.block === 'QUANLYCONGVIEC' &&
                          isValidCondition(
                            auth.permission,
                            [Permission.SUDUNG_QLCV],
                            'OR'
                          )) ||
                        (f?.block === 'CONGVIEC' &&
                          checkPermissionMenu(quyensMenu, [
                            Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
                          ]) &&
                          ((Utils.checkTongCongTy(auth?.mainUnitId) &&
                            checkPermissionMenu(quyensMenu, [
                              Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC_QCTCT,
                            ])) ||
                            (!Utils.checkTongCongTy(auth?.mainUnitId) &&
                              isValidCondition(
                                auth.permission,
                                [
                                  Permission.CONGVIEC_GIAOVIEC,
                                  Permission.CONGVIEC_PHONGBAN,
                                  Permission.CONGVIEC_CANHAN,
                                ],
                                'OR'
                              )))) ||
                        (f?.block === 'KYSO' &&
                          isValidCondition(
                            auth.permission,
                            [Permission.KYSO_QUANLY],
                            'OR'
                          ))
                    )
                    ?.map((e, index) => {
                      if (e?.block === 'CONGVAN') {
                        return (
                          <div
                            key={index}
                            className={classNames({
                              'col-xs-12 col-sm-6 col-md-6 col-lg-6':
                                !isLayoutDual,
                              'col-xs-12 col-sm-6 col-md-6 col-lg-12':
                                isLayoutDual,
                            })}
                          >
                            <Card
                              className="mt20"
                              padding={10}
                              rounded
                              bordered
                              title={
                                <div>
                                  <Icon
                                    classIcon="icon2-ds-thong-ke"
                                    className="color-blue mr10"
                                  />
                                  <span className="text-uppercase color-blue font-roboto font-weight-600">
                                    Công văn
                                  </span>
                                </div>
                              }
                            >
                              <div
                                style={{ height: heightMasterCard / 2 - 37 }}
                              >
                                {isTimeOut && size(dataCongVan) !== 0 && (
                                  <BarChartVertical
                                    data={dataCongVan?.filter(
                                      elm =>
                                        (!elm?.permission ||
                                          isValidCondition(
                                            auth.permission,
                                            elm?.permission,
                                            'AND'
                                          )) &&
                                        (!elm.permissionMenu ||
                                          (elm.permissionMenu &&
                                            checkPermissionMenu(
                                              quyensMenu,
                                              elm.permissionMenu
                                            ) &&
                                            ((elm?.type ===
                                              'CONGVIECPBCHUAGIAO' &&
                                              ((Utils.checkTongCongTy(
                                                auth?.mainUnitId
                                              ) &&
                                                checkPermissionMenu(
                                                  quyensMenu,
                                                  [
                                                    Permission.MENU_SIDEBAR
                                                      .BMENU_CVAN_CVIEC_QCTCT,
                                                  ]
                                                )) ||
                                                !Utils.checkTongCongTy(
                                                  auth?.mainUnitId
                                                ))) ||
                                              elm?.type !==
                                                'CONGVIECPBCHUAGIAO')))
                                    )}
                                    history={history}
                                  />
                                )}
                                {!isTimeOut && <Loading />}
                              </div>
                            </Card>
                          </div>
                        )
                      } else if (e?.block === 'QUANLYCONGVIEC') {
                        return (
                          <div
                            key={index}
                            className={classNames({
                              'col-xs-12 col-sm-6 col-md-6 col-lg-6':
                                !isLayoutDual,
                              'col-xs-12 col-sm-6 col-md-6 col-lg-12':
                                isLayoutDual,
                            })}
                          >
                            <Card
                              className="mt20"
                              padding={10}
                              rounded
                              bordered
                              title={
                                <div>
                                  <Icon
                                    classIcon="icon2-ds-thong-ke"
                                    className="color-blue mr10"
                                  />
                                  <span className="text-uppercase color-blue font-roboto font-weight-600">
                                    Quản lý công việc
                                  </span>
                                </div>
                              }
                            >
                              <div
                                className="d-flex item-center"
                                style={{ height: heightMasterCard / 2 - 37 }}
                              >
                                <div style={{ minWidth: '140px' }}>
                                  {isTimeOut &&
                                    size(dataQuanLyCongViec) !== 0 && (
                                      <CircleChart
                                        history={history}
                                        total={dataQuanLyCongViec
                                          ?.filter(
                                            item =>
                                              item.value &&
                                              item.type !== 'ON_TIME'
                                          )
                                          ?.map(item => item.value)
                                          ?.reduce((a, b) => a + b, 0)}
                                        data={dataQuanLyCongViec}
                                        innerRadius={45}
                                      />
                                    )}
                                  {!isTimeOut && <Loading />}
                                </div>
                                <ul className="home-legend-chart ml15">
                                  {dataQuanLyCongViec?.map((elm, index) => (
                                    <li
                                      key={index}
                                      className={classNames(elm?.className)}
                                      onClick={() =>
                                        onWorkDetail(null, elm?.type)
                                      }
                                    >
                                      <span
                                        className={
                                          'font-size-18 font-weight-700 mr5'
                                        }
                                      >
                                        {elm?.value}
                                      </span>
                                      <span
                                        className="font-roboto font-size-14 font-weight-400"
                                        style={{ color: 'rgba(0,0,0,0.7)' }}
                                      >
                                        {elm?.name}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Card>
                          </div>
                        )
                      } else if (e?.block === 'CONGVIEC') {
                        return (
                          <div
                            key={index}
                            className={classNames({
                              'col-xs-12 col-sm-6 col-md-6 col-lg-6':
                                !isLayoutDual,
                              'col-xs-12 col-sm-6 col-md-6 col-lg-12':
                                isLayoutDual,
                            })}
                          >
                            <Card
                              className="mt20"
                              padding={10}
                              rounded
                              bordered
                              title={
                                <div>
                                  <Icon
                                    classIcon="icon2-ds-thong-ke"
                                    className="color-blue mr10"
                                  />
                                  <span className="text-uppercase color-blue font-roboto font-weight-600">
                                    Công việc
                                  </span>
                                </div>
                              }
                            >
                              <div
                                className="d-flex item-center"
                                style={{ height: heightMasterCard / 2 - 37 }}
                              >
                                <div style={{ minWidth: '140px' }}>
                                  {isTimeOut && size(dataCongViec) !== 0 && (
                                    <PieChart
                                      data={dataCongViec || []}
                                      history={history}
                                    />
                                  )}
                                  {!isTimeOut && <Loading />}
                                </div>
                                <ul className="home-legend-chart ml15">
                                  {dataCongViec
                                    ?.filter(
                                      elm =>
                                        (!elm?.permission ||
                                          isValidCondition(
                                            auth.permission,
                                            elm?.permission,
                                            'AND'
                                          )) &&
                                        (!elm.permissionMenu ||
                                          (elm.permissionMenu &&
                                            checkPermissionMenu(
                                              quyensMenu,
                                              elm.permissionMenu
                                            )))
                                    )
                                    ?.map((elm, index) => (
                                      <li
                                        key={index}
                                        className={elm?.className}
                                        onClick={() => onWorkDetail(elm?.path)}
                                      >
                                        <span className="font-size-18 font-weight-700 mr5">
                                          {elm?.value}
                                        </span>
                                        <span className="font-roboto font-size-14 font-weight-400">
                                          {elm?.name}
                                        </span>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </Card>
                          </div>
                        )
                      } else if (e?.block === 'KYSO') {
                        return (
                          <div
                            key={index}
                            className={classNames({
                              'col-xs-12 col-sm-6 col-md-6 col-lg-6':
                                !isLayoutDual,
                              'col-xs-12 col-sm-6 col-md-6 col-lg-12':
                                isLayoutDual,
                            })}
                          >
                            <Card
                              className="mt20"
                              padding={10}
                              rounded
                              bordered
                              title={
                                <div>
                                  <Icon
                                    classIcon="icon2-ds-thong-ke"
                                    className="color-blue mr10"
                                  />
                                  <span className="text-uppercase color-blue font-roboto font-weight-600">
                                    Ký số
                                  </span>
                                </div>
                              }
                            >
                              <div
                                style={{ height: heightMasterCard / 2 - 37 }}
                              >
                                <div style={{ marginLeft: '-40px' }}>
                                  {isTimeOut && size(dataKySo) !== 0 && (
                                    <BarChart
                                      data={dataKySo?.filter(
                                        elm =>
                                          (!elm?.permissionMenu ||
                                            (size(elm?.permissionMenu) !== 0 &&
                                              checkPermissionMenu(
                                                quyensMenu,
                                                elm?.permissionMenu
                                              ))) &&
                                          (!elm?.permission ||
                                            (size(elm?.permission) !== 0 &&
                                              isValidCondition(
                                                auth.permission,
                                                elm?.permission,
                                                'OR'
                                              )))
                                      )}
                                      history={history}
                                    />
                                  )}
                                </div>
                                {!isTimeOut && <Loading />}
                              </div>
                            </Card>
                          </div>
                        )
                      }
                      return <div key={index}></div>
                    })}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default HomePageNew
