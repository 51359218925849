import * as types from '../constants/ActionTypes'
import { uniq } from 'lodash'

const initialState = {
  list: {
    pageCorporations: 0,
    totalCorporations: 0,
    unreadCorporations: {
      daDoc: true,
    },
    unreadUnit: {
      daDoc: true,
    },
    pageUnit: 0,
    totalUnit: 0,
    pageAdmin: 0,
    totalAdmin: 0,
    corporations: [],
    unit: [],
    admin: [],
  },
  detailId: null,
}

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case types.NOTIFICATION_FETCH_LIST:
      const isEmpty =
        action.payload.data.result &&
        action.payload.data.result &&
        action.payload.data.result.items &&
        action.payload.data.result.items.length === 0
      const isCorporations =
        !isEmpty && action.payload.data.result.items[0].tinTongCongTy === true
      const isUnit =
        !isEmpty && action.payload.data.result.items[0].tinTongCongTy === false
      let corporations = state.list.corporations
      let pageCorporations = state.list.pageCorporations
      let totalCorporations = state.list.totalCorporations
      let unit = state.list.unit
      let pageUnit = state.list.pageUnit
      let totalUnit = state.list.totalUnit
      const uniqData = data => {
        let dataIdArr = data.map(item => item.id)
        let dataEntity = {}
        data.forEach(element => {
          dataEntity[element.id] = element
        })
        return uniq(dataIdArr).map(id => dataEntity[id])
      }

      if (isCorporations) {
        pageCorporations = action.payload.data.result.page
        totalCorporations = action.payload.data.result.total
        if (action.payload.data.result.page === 1) {
          corporations = action.payload.data.result.items
        } else {
          corporations = [...corporations, ...action.payload.data.result.items]
        }
      }
      if (isUnit) {
        pageUnit = action.payload.data.result.page
        totalUnit = action.payload.data.result.total
        if (action.payload.data.result.page === 1) {
          unit = action.payload.data.result.items
        } else {
          unit = [...unit, ...action.payload.data.result.items]
        }
      }
      return (state = {
        ...state,
        list: {
          ...state.list,
          pageCorporations,
          totalCorporations,
          pageUnit,
          totalUnit,
          corporations: uniqData(corporations),
          unit: uniqData(unit),
        },
      })

    case types.NOTIFICATION_FETCH_ITEM:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.NOTIFICATION_FETCH_LIST_ADMIN:
      return {
        ...state,
        list: {
          ...state.list,
          admin:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
          pageAdmin: action.payload.data.result.result.page,
          totalAdmin: action.payload.data.result.result.total,
        },
      }
    case types.NOTIFICATION_GET_LASTEST:
      const data = action.payload.data.result
      return {
        ...state,
        list: {
          ...state.list,
          unreadCorporations: data.find(item => item.tinTongCongTy) || {
            daDoc: true,
          },
          unreadUnit: data.find(item => !item.tinTongCongTy) || { daDoc: true },
        },
      }
    default:
      return state
  }
}
