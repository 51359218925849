import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-container">
        <div className="info-footer">
          <p className="version">
            PHIÊN BẢN CPC-eOffice 7.1 - Văn phòng điện tử
          </p>
          <p className="copyright">© 2017 TỔNG CÔNG TY ĐIỆN LỰC MIỀN TRUNG</p>
        </div>
      </footer>
    )
  }
}
export default Footer
