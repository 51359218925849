import React from 'react'
import ReactDOM from 'react-dom'
import { MasterLayout } from '../../components/layout'
import { Redirect } from 'react-router-dom'
import ShowMoreText from 'react-show-more-text'
import {
  DetailInfo,
  DuyetPhieuYeuCauGopY,
  ThongTinXacNhan,
  ChiDaoGopY,
  GiaoGopY,
  ThucHienGopY,
  DuyetGopY,
  DetailBoxAction,
  DetailStatus,
  DetailStatusPhieuNhanGopY,
  FlowRequestComments,
  SendMail,
} from '../../components/RequestComment'
import {
  DetailFileContent,
  LoadingLine,
  DetailFileContentV2,
} from 'components/common'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from '../../actions'
import classnames from 'classnames'
import * as MESSAGE from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'
import * as Types from '../../constants/Api'
import * as TRANGTHAI from '../../constants/RequestCommentStatus'
import isEmpty from 'lodash/isEmpty'

class DetailPhieuYeuCauGopY extends React.Component {
  static propTypes = {
    requestComments: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)
    let type = 'layYKienGoi'
    if (
      this.props.match.path === '/tien-ich/lay-y-kien-don-vi/chi-tiet-nhan/:id'
    ) {
      type = 'layYKienNhan'
    }
    this.state = {
      mainUnitId:
        this.props.auth && this.props.auth.user
          ? this.props.auth.user.mainUnitId
          : null,
      showSection: 'noContent',
      showListAction: true,
      showDetailInfo: false,
      isLoadingSubData: false,
      signNumberId: this.props.match.params.id,
      requestCommentId: this.props.match.params.id,
      requestComments: null,
      flowRequestComment: null,
      file: null,
      type,
      feedbacks: null,
      isLoadingData: {
        signNumber: true,
        feedbacks: true,
        historyItemSignNumber: false,
        historyItemFeedbacks: false,
        isAgreeNotNeedSignNumber: true,
        file: true,
        documentNumber: true,
        historyList: false,
        isLoadingDuyetGopYDongY: false,
        isLoadingDuyetGopYKhongDongY: false,
        isLoadingLuongGopY: false,
        isLoadingChiDaoGopY: false,
        isLoadingGiaoGopY: false,
        detailBoxAction: true,
      },
      listHistoryUpdate: null,
      isShowHistoryDetail: false,
      historyItem: {
        signNumber: null,
        feedbacks: null,
        time: null,
      },
      isAgreeNotNeedSignNumber: false,
      isLoadingMigratedFile: true,
      fileFromList: null, // File từ danh sách
      documentNumberFromList: null, // Số công văn từ danh sách
      isOpenHistoryList: false,
      isRedirect: false,
    }

    this.closeModulesDetail = this.closeModulesDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleRemoveFileInfo = this.handleRemoveFileInfo.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleClickDetailInfo = this.handleClickDetailInfo.bind(this)
    this.handleClickDuyetPhieuYeuCauGopY =
      this.handleClickDuyetPhieuYeuCauGopY.bind(this)
    this.handleClickTongHopGopY = this.handleClickTongHopGopY.bind(this)
    this.handleClickLuongGopY = this.handleClickLuongGopY.bind(this)
    this.handleClickMoreReceivedComment =
      this.handleClickMoreReceivedComment.bind(this)
    this.handleClickChiDaoGopY = this.handleClickChiDaoGopY.bind(this)
    this.handleClickGiaoGopY = this.handleClickGiaoGopY.bind(this)
    this.handleClickThucHienGopY = this.handleClickThucHienGopY.bind(this)
    this.handleClickDuyetGopY = this.handleClickDuyetGopY.bind(this)
    this.handleClickThongTinXacNhan = this.handleClickThongTinXacNhan.bind(this)
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  refHandlers = {
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  componentDidMount = async () => {
    window.addEventListener('scroll', this.handleScroll)
    if (this.props.location && this.props.location.state) {
      // Gọi api get thông tin file => state.fileFromList
      let isLoadingData = this.state.isLoadingData

      if (this.props.location.state.fileId) {
        await this.props.actions
          .getFileInformation(this.props.location.state.fileId)
          .then(res => {
            if (
              res &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result
            ) {
              isLoadingData = {
                ...isLoadingData,
                file: false,
              }
            }
          })
      }
    }

    // view detail phieu goi yeu cau
    if (this.state.type === 'layYKienGoi') {
      await this.props.actions
        .requestCommentDetail(this.state.requestCommentId)
        .then(() => {
          if (this.props.requestComments && this.props.requestComments.item) {
            this.setState({
              requestComments: this.props.requestComments.item,
              file: this.props.requestComments.item.fileNoiDung,
              isLoadingData: {
                ...this.state.isLoadingData,
                signNumber: true,
                file: false,
                documentNumber: false,
              },
            })
          }
        })
    } else {
      // view detail phieu nhan yeu cau
      await this.props.actions
        .receiveCommentDetail(this.state.requestCommentId)
        .then(() => {
          if (this.props.requestComments && this.props.requestComments.item) {
            this.setState({
              requestComments: this.props.requestComments.item,
              file: this.props.requestComments.item.fileNoiDung,
              isLoadingData: {
                ...this.state.isLoadingData,
                signNumber: true,
                file: false,
                documentNumber: false,
              },
            })
          }
        })

      // get ds phong ban gop y
      await this.props.actions
        .commonFetchDepartments(Types.PAGE, null, false, Types.TYPE_DEPARTMENT)
        .finally(async () => {
          await this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              departments: false,
            },
          })
        })

      // get ds nhan vien phong ban gop y
      await this.props.actions
        .commonFetchStaffByDepartmentId(
          this.props.requestComments.item.phongBanId
        )
        .finally(async () => {
          await this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              staffs: false,
            },
          })
        })
    }

    let showSection = 'noContent'

    // const nhanVienId = this.props.auth && this.props.auth.user && this.props.auth.user.user ? this.props.auth.user.user.nhanVienId : '';
    // const chucDanhId = this.props.auth && this.props.auth.auth && this.props.auth.auth.mainDepartmentId ? this.props.auth.auth.mainDepartmentId : '';
    // const tinhTrang = this.props.requestComments && this.props.requestComments.item ? this.props.requestComments.item.tinhTrang : 0 ;
    // const loaiPhieu = this.props.requestComments && this.props.requestComments.item ? this.props.requestComments.item.loai : 0 ;
    // const isLanhDaoPhongBanXacNhanPhieuGoi =  (nhanVienId ===  (this.props.requestComments.item&&this.props.requestComments.item.lanhDaoPhongBanLap ? this.props.requestComments.item.lanhDaoPhongBanLap.nhanVienId : ''));
    //
    // //nếu thu ky chua duyet thi tim trong danh sach thu ky duyet
    // const thuKyXacNhanPhieuGoi = this.props.requestComments && this.props.requestComments.item && this.props.requestComments.item.dsThuKy && this.props.requestComments.item.dsThuKy.filter(item => (item.nhanVienId === nhanVienId));
    // //nếu thu ky da duyet thì có trường thuKy
    // const isthuKyXacNhanPhieuGoi  = (nhanVienId ===  (this.props.requestComments.item && this.props.requestComments.item.thuKy ? this.props.requestComments.item.thuKy.nhanVienId : ''));
    //
    // const isLanhDaoDonViXacNhanPhieuGoi =  (nhanVienId ===  (this.props.requestComments.item&&this.props.requestComments.item.lanhDaoDonVi ? this.props.requestComments.item.lanhDaoDonVi.nhanVienId : ''));
    // const isLanhDaoPhongBanXacNhanPhieuNhan =  (nhanVienId ===  (this.props.requestComments.item&&this.props.requestComments.item.lanhDaoPhongBanXacNhan? this.props.requestComments.item.lanhDaoPhongBanXacNhan.nhanVienId : ''));
    // const isLanhDaoXacNhanPhieuNhan =  (nhanVienId ===  (this.props.requestComments.item&&this.props.requestComments.item.lanhDaoDonViXacNhan ? this.props.requestComments.item.lanhDaoDonViXacNhan.nhanVienId : ''));
    // const iscaNhanChuTriPhieuNhan =  (nhanVienId ===  (this.props.requestComments.item&&this.props.requestComments.item.caNhanChuTri ? this.props.requestComments.item.caNhanChuTri.nhanVienId : ''));
    // const isPhongBanGiaoGopY =  (chucDanhId === (this.props.requestComments.item&&this.props.requestComments.item.phongBanId ? this.props.requestComments.item.phongBanId : ''));
    // const tinhTrangLanhDaoPhongBanXacNhanPhieuGoi =  this.props.requestComments.item&&this.props.requestComments.item.lanhDaoPhongBanLap ? this.props.requestComments.item.lanhDaoPhongBanLap.trangThai : 0;
    // const tinhTrangThuKyXacNhanPhieuGoi =  this.props.requestComments.item&&this.props.requestComments.item.thuKy ? this.props.requestComments.item.thuKy.trangThai : 0;
    //
    // const tinhTrangLanhDaoDonViXacNhanPhieuGoi =  this.props.requestComments.item&&this.props.requestComments.item.lanhDaoDonVi ? this.props.requestComments.item.lanhDaoDonVi.trangThai : 0;
    // const tinhTrangLanhDaoPhongBanXacNhanPhieuNhan =  this.props.requestComments.item&&this.props.requestComments.item.lanhDaoPhongBanXacNhan ? this.props.requestComments.item.lanhDaoPhongBanXacNhan.trangThai : 0;
    // const tinhTrangLanhDaoDonViXacNhanPhieuNhan =  this.props.requestComments.item&&this.props.requestComments.item.lanhDaoDonViXacNhan ? this.props.requestComments.item.lanhDaoDonViXacNhan.trangThai : 0;

    // if(this.state.type === 'layYKienGoi'){
    //   if((isLanhDaoPhongBanXacNhanPhieuGoi && tinhTrang === TRANGTHAI.LDPB_CHUA_DUYET)
    //     || ((thuKyXacNhanPhieuGoi && thuKyXacNhanPhieuGoi.length > 0) && tinhTrang === TRANGTHAI.THU_KY_CHUA_DUYET)
    //     || (isthuKyXacNhanPhieuGoi && tinhTrang === TRANGTHAI.THU_KY_CHUA_DUYET)
    //     || (isLanhDaoDonViXacNhanPhieuGoi && tinhTrang === TRANGTHAI.LDDV_CHUA_DUYET)
    //     || (isLanhDaoPhongBanXacNhanPhieuGoi && tinhTrang === TRANGTHAI.KHONG_DUYET && tinhTrangLanhDaoPhongBanXacNhanPhieuGoi === TRANGTHAI.LAYYKIEN_KHONG_DONGY)
    //     || (isthuKyXacNhanPhieuGoi && tinhTrang === TRANGTHAI.KHONG_DUYET && tinhTrangThuKyXacNhanPhieuGoi === TRANGTHAI.LAYYKIEN_KHONG_DONGY)
    //     || (isLanhDaoDonViXacNhanPhieuGoi && tinhTrang === TRANGTHAI.KHONG_DUYET && tinhTrangLanhDaoDonViXacNhanPhieuGoi === TRANGTHAI.LAYYKIEN_KHONG_DONGY)
    //   )
    //   {
    //     showSection = 'DuyetPhieuYeuCauGopY';
    //   }
    // }
    //
    // if(this.state.type === 'layYKienNhan'){
    //   if(tinhTrang === TRANGTHAI.LDDV_CHUA_GIAO)
    //   {
    //     showSection = 'ChiDaoGopY';
    //   }
    //   if((tinhTrang === TRANGTHAI.LDPB_CHUA_GIAO && isPhongBanGiaoGopY && loaiPhieu === TRANGTHAI.PHIEU_GOI_DON_VI)
    //     || (tinhTrang === TRANGTHAI.LDPB_CHUA_GIAO && loaiPhieu === TRANGTHAI.PHIEU_GOI_PHONG_BAN))
    //   {
    //     showSection = 'GiaoGopY';
    //   }
    //   if(tinhTrang === TRANGTHAI.CHUA_GOP_Y && iscaNhanChuTriPhieuNhan) {
    //     showSection = "ThucHienGopY"
    //   }
    //   if((tinhTrang === TRANGTHAI.LDPB_CHUA_XAC_NHAN && isLanhDaoPhongBanXacNhanPhieuNhan)
    //     || (tinhTrang === TRANGTHAI.LDDV_CHUA_XAC_NHAN && isLanhDaoXacNhanPhieuNhan)
    //     || ((tinhTrang === TRANGTHAI.TU_CHOI_XAC_NHAN && isLanhDaoXacNhanPhieuNhan && tinhTrangLanhDaoDonViXacNhanPhieuNhan === TRANGTHAI.LAYYKIEN_KHONG_DONGY))
    //     || ((tinhTrang === TRANGTHAI.TU_CHOI_XAC_NHAN && isLanhDaoPhongBanXacNhanPhieuNhan && tinhTrangLanhDaoPhongBanXacNhanPhieuNhan === TRANGTHAI.LAYYKIEN_KHONG_DONGY))
    //   ) {
    //     showSection = "DuyetGopY"
    //   }
    // }
    this.setState({
      showSection: showSection,
      isLoadingData: {
        ...this.state.isLoadingData,
        detailBoxAction: false,
      },
    })
  }

  handleSubmit = async (typeSubmit, params, message, intent, isSuccess) => {
    if (
      typeSubmit &&
      (typeSubmit === 'DuyetPhieuYeuCauGopY' || typeSubmit === 'DuyetGopY')
    ) {
      if (
        params.y_kien.trim().length === 0 &&
        params.trang_thai === TRANGTHAI.LAYYKIEN_KHONG_DONGY
      ) {
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_MESSAGE_ALERT_DISSAGREE_REQUIRE_WARNING,
          intent: Intent.WARNING,
        })
      }
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          isLoadingDuyetGopYDongY:
            params.trang_thai !== TRANGTHAI.LAYYKIEN_KHONG_DONGY,
          isLoadingDuyetGopYKhongDongY:
            params.trang_thai === TRANGTHAI.LAYYKIEN_KHONG_DONGY,
        },
      })
      if (typeSubmit === 'DuyetPhieuYeuCauGopY') {
        return this.props.actions
          .requestCommentDuyet(this.state.requestCommentId, params)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message:
                  params.trang_thai === TRANGTHAI.LAYYKIEN_KHONG_DONGY
                    ? MESSAGE.TEXT_MESSAGE_ALERT_TU_CHOI_THANHCONG
                    : MESSAGE.TEXT_MESSAGE_ALERT_AGREE_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message:
                  params.trang_thai === TRANGTHAI.LAYYKIEN_KHONG_DONGY
                    ? MESSAGE.TEXT_MESSAGE_ALERT_TU_CHOI_KHONGTHANHCONG
                    : MESSAGE.TEXT_MESSAGE_ALERT_AGREE_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                isLoadingDuyetGopYDongY: false,
                isLoadingDuyetGopYKhongDongY: false,
              },
            })
          })
      }
      if (typeSubmit === 'DuyetGopY') {
        return this.props.actions
          .requestCommentDuyetPhieuNhan(this.state.requestCommentId, params)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message:
                  params.trang_thai === TRANGTHAI.LAYYKIEN_KHONG_DONGY
                    ? MESSAGE.TEXT_MESSAGE_ALERT_TU_CHOI_THANHCONG
                    : MESSAGE.TEXT_MESSAGE_ALERT_AGREE_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message:
                  params.trang_thai === TRANGTHAI.LAYYKIEN_KHONG_DONGY
                    ? MESSAGE.TEXT_MESSAGE_ALERT_TU_CHOI_KHONGTHANHCONG
                    : MESSAGE.TEXT_MESSAGE_ALERT_AGREE_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                isLoadingDuyetGopYDongY: false,
                isLoadingDuyetGopYKhongDongY: false,
              },
            })
          })
      }
    }

    if (typeSubmit && typeSubmit === 'ChiDaoGopY') {
      if (!params.phongBanId) {
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_MESSAGE_ALERT_DEPARTMENT_REQUIRE_WARNING,
          intent: Intent.WARNING,
        })
      }
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          isLoadingChiDaoGopY: true,
        },
      })
      if (params.isSuaChiDao) {
        return this.props.actions
          .suaChiDaoGopY(this.state.requestCommentId, params)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_SUA_CHI_DAO_GOP_Y_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_SUA_CHI_DAO_GOP_Y_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                isLoadingChiDaoGopY: false,
              },
            })
          })
      } else {
        return this.props.actions
          .chiDaoGopY(this.state.requestCommentId, params)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_CHI_DAO_GOP_Y_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_CHI_DAO_GOP_Y_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                isLoadingChiDaoGopY: false,
              },
            })
          })
      }
    }

    if (typeSubmit && typeSubmit === 'GiaoGopY') {
      if (!params.chucDanhId) {
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TEXT_MESSAGE_ALERT_PERSONAL_REQUIRE_WARNING,
          intent: Intent.WARNING,
        })
      }
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          isLoadingGiaoGopY: true,
        },
      })
      if (params.isSuaGiaoGopY) {
        return this.props.actions
          .suaGiaoViecGopY(this.state.requestCommentId, params)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_SUA_GIAO_GOP_Y_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_SUA_GIAO_GOP_Y_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                isLoadingGiaoGopY: false,
              },
            })
          })
      } else {
        return this.props.actions
          .giaoViecGopY(this.state.requestCommentId, params)
          .then(res => {
            if (res.payload && res.payload.status === 200) {
              this.props.history.goBack()
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_GIAO_GOP_Y_THANHCONG,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGE.TEXT_MESSAGE_GIAO_GOP_Y_KHONGTHANHCONG,
                intent: Intent.DANGER,
              })
            }
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                isLoadingGiaoGopY: false,
              },
            })
          })
      }
    }

    if (typeSubmit && (typeSubmit === 'GopY' || typeSubmit === 'GuiEmail')) {
      this.props.actions.commonAddToasterMessage({
        message: message,
        intent: intent,
      })
      if (isSuccess) {
        this.props.actions.refreshPage(true)
        this.props.history.goBack()
      }
    }
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeModulesDetail = () => {
    this.setState({
      showListAction: true,
      showReportWork: !this.state.showReportWork,
    })
  }

  goBack() {
    this.props.history.goBack()
  }

  handleRemoveFileInfo = () => {
    this.props.history.replace({
      state: {
        fileId: null,
      },
    })

    if (this.state.type === 'kySoDi') {
      // Refresh danh sach ky so di
      return this.props.actions.refreshSendSignNumber(true)
    }
    // Refresh danh sach ky so noi bo
    return this.props.actions.refreshInternalSignNumber(true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  handleClickDetailInfo = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinPhieuYeuCauGopY',
    })
  }

  handleClickThongTinXacNhan = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinXacNhan',
    })
  }

  handleClickDuyetPhieuYeuCauGopY = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'DuyetPhieuYeuCauGopY',
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
    })
  }

  sendToChat = e => {
    e.preventDefault()
    if (
      this.props.requestComments &&
      this.props.requestComments.item &&
      this.props.requestComments.item.fileNoiDung
    ) {
      const auth = this.props.auth && this.props.auth.auth
      const url =
        Types.FE_CHAT_URL +
        `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}
      &tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}
      &fileId=${this.props.requestComments.item.fileNoiDung.fileId}`
      window.open(url, '_blank')
    }
  }

  handleClickLuongGopY = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
    })

    let { isCheck, isLoadingData } = this.state
    let idPhieuNhan = this.props.requestComments.item.id
    if (
      this.props.match.path === '/tien-ich/lay-y-kien-don-vi/chi-tiet-nhan/:id'
    ) {
      idPhieuNhan = this.props.requestComments.item.phieuGuiYeuCauId
    }

    if (
      this.props.requestComments &&
      this.props.requestComments.item &&
      idPhieuNhan &&
      isLoadingData.isLoadingLuongGopY === false
    ) {
      //get Luồng góp ý của ban và don vi
      await this.props.actions
        .getFlowPhieuGoiYeuCauGopY(idPhieuNhan)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            this.setState({
              flowRequestComment: res.payload.data.result,
            })
          }
        })
    }
    await this.setState({
      isCheck,
      showResults: true,
      isLoadingSubData: false,
      isLoadingData,
      showSection: 'LuongGopY',
    })
  }

  handleClickMoreReceivedComment = id => {
    this.props.actions.getMoreReceivedComment(id)
  }

  handleClickChiDaoGopY = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ChiDaoGopY',
    })
  }

  handleClickGiaoGopY = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'GiaoGopY',
    })
  }

  handleClickThucHienGopY = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThucHienGopY',
    })
  }

  handleClickDuyetGopY = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'DuyetGopY',
    })
  }

  handleClickGoBack = () => {
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
      isUpdateAssignment: false,
    })
  }

  handleClickTongHopGopY() {
    this.setState({
      isRedirect: true,
    })
  }

  render() {
    // Nếu có file từ danh sách thì dùng file, soCongVan từ danh sách,
    // ngược lại dùng file, soCongVan get từ api chi tiết
    const fileDetail = this.state.fileFromList || this.state.file
    let documentNumberDetail = this.state.documentNumberFromList

    if (
      !this.state.documentNumberFromList &&
      this.props.requestComments &&
      this.props.requestComments.item &&
      this.props.requestComments.item.soKyHieu
    ) {
      documentNumberDetail = this.props.requestComments.item.soKyHieu
    }
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={`/tien-ich/thong-ke-gop-y/${this.props.requestComments.item.id}`}
        />
      )
    }

    return (
      <MasterLayout typeSidebar="utilities" collapseSideBar={true}>
        <div className="detail-receive-document" ref="historyNode">
          {this.state.isLoadingData &&
          this.state.isLoadingData.documentNumber === true ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code form-row">
              <div className="row col-md-12 col-xs-12">
                <div className="col-md-1">
                  <button
                    onClick={this.goBack.bind(this)}
                    type="button"
                    className={classnames(
                      'pt-button pt-button-back button-back-fixed',
                      {
                        'fixed-button-back':
                          this.state.boxMenuFixTop &&
                          !this.props.enableScrollFixedMenu &&
                          window.innerWidth >= 1024,
                        'fixed-button-hasSidebar':
                          this.state.boxMenuFixTop &&
                          this.props.enableScrollFixedMenu &&
                          window.innerWidth >= 1024,
                      }
                    )}
                  >
                    <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                    <span className="text-content">Quay lại</span>
                  </button>
                </div>
                <div className="col-md-11 ">
                  <div className="d-flex number-code-request-comment">
                    <div className="w-145px">
                      <span className="name-number-code">Số ký hiệu:</span>
                    </div>
                    <div className="">
                      <span className="code-detail">
                        {documentNumberDetail}
                      </span>
                    </div>
                  </div>
                  <div className="number-code-request-comment">
                    <div className="w-145px">
                      <span className="name-number-code">
                        Nội dung yêu cầu:
                      </span>
                    </div>
                    <div style={{ flex: '1' }}>
                      <div className="detail">
                        <ShowMoreText
                          lines={3}
                          more="Xem thêm"
                          less="Thu gọn"
                          expanded={false}
                        >
                          {this.props.requestComments.item.noiDungYeuCau}
                        </ShowMoreText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="detail-container detail-container-sign-number">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {!isEmpty(fileDetail) && (
                  <DetailFileContentV2
                    auth={this.props.auth.user}
                    actions={this.props.actions}
                    file={fileDetail}
                  />
                )}
              </div>
              <div className="box-right">
                {this.state.showSection === 'noContent' && (
                  <DetailBoxAction
                    onClickDetailInfo={this.handleClickDetailInfo}
                    onClickDuyetPhieuYeuCauGopY={
                      this.handleClickDuyetPhieuYeuCauGopY
                    }
                    onClickTongHopGopY={this.handleClickTongHopGopY}
                    onClickLuongGopY={this.handleClickLuongGopY}
                    onClickChiDaoGopY={this.handleClickChiDaoGopY}
                    onClickGiaoGopY={this.handleClickGiaoGopY}
                    onClickThucHienGopY={this.handleClickThucHienGopY}
                    onClickDuyetGopY={this.handleClickDuyetGopY}
                    onClickThongTinXacNhan={this.handleClickThongTinXacNhan}
                    onClickSendMail={this.handleClickSendMail}
                    sendToChat={this.sendToChat}
                    auth={this.props.auth.user}
                    feedbacks={this.state.feedbacks}
                    requestComments={this.props.requestComments}
                    onSubmit={this.handleSubmit}
                    actions={this.props.actions}
                    type={this.state.type}
                    isLoadingData={this.state.isLoadingData}
                    listHistoryUpdate={this.state.listHistoryUpdate}
                    isShowHistoryDetail={this.state.isShowHistoryDetail}
                    isAgreeNotNeedSignNumber={
                      this.state.isAgreeNotNeedSignNumber
                    }
                    isOpenHistoryList={this.state.isOpenHistoryList}
                    onRemoveFileInfo={this.handleRemoveFileInfo}
                    history={this.props.history}
                  />
                )}
                {this.state.showSection === 'ThongTinPhieuYeuCauGopY' && (
                  <div className="box-action-item">
                    <DetailInfo
                      type={this.state.type}
                      onClickGoBack={this.handleClickGoBack}
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                    />
                  </div>
                )}
                {this.state.showSection === 'DuyetPhieuYeuCauGopY' && (
                  <div className="box-action-item">
                    <DuyetPhieuYeuCauGopY
                      onClickGoBack={this.handleClickGoBack}
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                      onSubmit={this.handleSubmit}
                      isLoading={this.state.isLoadingData}
                      auth={this.props.auth.auth}
                      actions={this.props.actions}
                    />
                    <DetailStatus
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                      auth={this.props.auth}
                      isShowIconWarningForLeader={true}
                    />
                  </div>
                )}
                {this.state.showSection === 'ThongTinXacNhan' && (
                  <div className="box-action-item">
                    <ThongTinXacNhan
                      onClickGoBack={this.handleClickGoBack}
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                      onSubmit={this.handleSubmit}
                      isLoading={this.state.isLoadingData}
                      auth={this.props.auth.auth}
                    />
                    <DetailStatus
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                      auth={this.props.auth}
                      isShowIconWarningForLeader={true}
                    />
                  </div>
                )}
                {this.state.showResults &&
                  this.state.showSection === 'LuongGopY' && (
                    <div className="box-action-item">
                      <FlowRequestComments
                        auth={this.props.auth}
                        onClickGoBack={this.handleClickGoBack}
                        flowRequestComment={this.state.flowRequestComment}
                        flowReceiveComment={
                          this.props.requestComments.flowReceiveComment
                        }
                        onClickDepartment={this.handleClickMoreReceivedComment}
                        actions={this.props.actions}
                        type={this.state.type}
                      />
                    </div>
                  )}
                {this.state.showSection === 'ChiDaoGopY' && (
                  <div className="box-action-item">
                    <ChiDaoGopY
                      onClickGoBack={this.handleClickGoBack}
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                      onSubmit={this.handleSubmit}
                      isLoading={this.state.isLoadingData.isLoadingChiDaoGopY}
                      departments={this.props.departments}
                    />
                  </div>
                )}
                {this.state.showSection === 'GiaoGopY' && (
                  <div className="box-action-item">
                    <GiaoGopY
                      onClickGoBack={this.handleClickGoBack}
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                      onSubmit={this.handleSubmit}
                      isLoading={this.state.isLoadingData.isLoadingGiaoGopY}
                      staffs={this.props.staffs}
                    />
                  </div>
                )}
                {this.state.showSection === 'ThucHienGopY' && (
                  <div className="box-action-item">
                    <ThucHienGopY
                      onClickGoBack={this.handleClickGoBack}
                      auth={this.props.auth}
                      onSubmit={this.handleSubmit}
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                      actions={this.props.actions}
                    />
                  </div>
                )}
                {this.state.showSection === 'DuyetGopY' && (
                  <div className="box-action-item">
                    <DuyetGopY
                      onClickGoBack={this.handleClickGoBack}
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                      onSubmit={this.handleSubmit}
                      auth={this.props.auth}
                      isLoading={this.state.isLoadingData}
                      actions={this.props.actions}
                    />
                    <DetailStatusPhieuNhanGopY
                      requestComments={
                        this.props.requestComments
                          ? this.props.requestComments.item
                          : {}
                      }
                      auth={this.props.auth}
                      isShowIconWarningForLeader={true}
                    />
                  </div>
                )}
                {this.state.showResults &&
                  this.state.showSection === 'GuiEmail' && (
                    <div className="box-action-item">
                      <SendMail
                        auth={this.props.auth}
                        onClickGoBack={this.handleClickGoBack}
                        onSubmit={this.handleSubmit}
                        action={this.props.actions}
                        requestComments={
                          this.props.requestComments
                            ? this.props.requestComments.item
                            : {}
                        }
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  requestComments: {
    ...state.requestComments,
    item: state.requestComments.detailId
      ? state.entities.requestComments[state.requestComments.detailId]
      : {},
    listFeedbacks: {
      ...state.requestComments.listFeedbacks,
    },
  },

  auth: {
    ...state,
    user: state.auth,
  },
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  departments: {
    items: state.common.departments,
  },
  staffs: {
    items: state.common.staffs,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailPhieuYeuCauGopY)
