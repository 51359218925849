import { useState, useEffect, useCallback } from 'react'
import requestAction from 'helpers/request'
import FileDownload from 'file-saver'
import moment from 'moment'
import { Intent } from '@blueprintjs/core'
import uniqBy from 'lodash/uniqBy'
import { Action, Toast, TEXT_HOANTHANH_CONGVIEC } from 'constants/MessageForm'
import { WORK_STATUS, FILE_TYPE } from 'constants/Enum'
import {
  saveTree,
  getCongViec,
  patchTienDo,
  showToast,
  getInformationWorkItemAction,
  getDODowloadFile,
  getPhongBanNhanVien,
} from 'actions/task'

const useTaskDetail = ({
  actions,
  history,
  match,
  roleId,
  mainDepartmentId,
  mainUnitId,
}) => {
  const params = match?.params
  const action = params?.action
  const entity = params?.entity
  const id = params?.id
  const [detailData, setDetailData] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [informationWorkItemAction, setInformationWorkItemAction] = useState({
    allowApproval: false,
    approval: '',
    isLock: true,
    showCancelApprove: false,
    assigned: null,
    isAccept: false,
  })
  const [main, setMain] = useState([])
  const [executor, setExecutor] = useState(null)
  const [coordinations, setCoordinations] = useState([])
  const [followers, setFollowers] = useState([])
  const [deadline, setDeadline] = useState(null)
  const [percent, setPercent] = useState(0)
  const [mentionData, setMentionData] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [menuActive, setMenuActive] = useState(null)
  const [isDisabledDefault, setIsDisabledDefault] = useState(true)

  const onMenu = useCallback(
    (_, data) => {
      if (data.keyName === 'SUA_CONG_VIEC') {
        return history.push(`/quan-ly-cong-viec/sua-cong-viec/${entity}/${id}`)
      }

      setMenuActive(data)
    },
    [history, id, entity]
  )

  const getProgressRequestData = (percent = 0) => {
    return {
      progress: percent,
      status:
        percent === 100
          ? WORK_STATUS.HOAN_THANH
          : percent === 0
          ? WORK_STATUS.CHUA_THUC_HIEN
          : WORK_STATUS.DANG_THUC_HIEN,
    }
  }

  const fetchInformationWorkItemAction = useCallback(() => {
    setInformationWorkItemAction({
      allowApproval: false,
      approval: '',
      isLock: true,
      showCancelApprove: false,
      assigned: null,
      isAccept: false,
    })
    requestAction({
      showToast: false,
      action: () => getInformationWorkItemAction(id),
      afterResponse: res => {
        setInformationWorkItemAction(res)
      },
    })
  }, [id])

  const onProgress = useCallback(() => {
    if (percent === detailData?.progress) return
    const data = getProgressRequestData(percent)
    const finishedDate = moment().format()
    setIsDisabled(true)
    requestAction({
      codeCheck: false,
      getResult: false,
      action: () => {
        return patchTienDo(id, data)
      },
      afterResponse: () => {
        actions.getCounterCongViecPhongBanCaNhan()
        fetchInformationWorkItemAction()
        setDetailData({
          ...detailData,
          ...data,
          finishedDate,
        })
      },
      successMessage:
        percent === 100
          ? TEXT_HOANTHANH_CONGVIEC
          : Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      afterAction: () => {
        setIsDisabled(false)
        setIsDisabledDefault(true)
      },
    })
  }, [
    percent,
    detailData,
    id,
    actions,
    setDetailData,
    fetchInformationWorkItemAction,
  ])

  const onChangeFile = useCallback(file => {
    if (file?.application === 'DOFFICE') {
      const params = {
        fileDoId: file?.fileId,
        workType: 'DOFFICE',
      }

      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDODowloadFile(params),
        afterResponse: async response => {
          if (response) {
            FileDownload.saveAs(response, file?.name)
          }
        },
      })
    }
  }, [])

  const fetchDetail = useCallback(() => {
    if (!id) {
      return
    }

    const handleSplitDetailFiles = data => {
      return {
        ...data,
        referenceFile: (data?.files || []).filter(
          file => file.type === FILE_TYPE.FILE_THAM_KHAO
        ),
        files: (data?.files || []).filter(
          file => file.type === FILE_TYPE.FILE_DINH_KEM
        ),
      }
    }

    const initDeadline = values => {
      if (!values) {
        return
      }

      let masterDeadline = values?.deadline
      if (values?.assigner?.id !== values?.creatorProfileId) {
        const dataGet = values?.responsibilities?.find(
          elm =>
            elm?.workAssignments?.[0] && elm?.workAssignments?.[0]?.length !== 0
        )

        masterDeadline = dataGet?.workAssignments?.[0]?.deadline
      }

      setDeadline(masterDeadline)
    }

    requestAction({
      showToast: false,
      beforeAction: () => setIsFetching(true),
      action: () => getCongViec(id),
      afterResponse: (result = {}) => {
        const isAssignByMe = result?.assigner?.id === roleId
        initDeadline(result)
        setPercent(result?.progress)
        setDetailData(handleSplitDetailFiles(result))
        setExecutor(result?.mainResponsibility)
        setMain(
          isAssignByMe
            ? result?.responsibilities?.[0]?.workAssignments || []
            : result?.responsibilitiesDetails?.[0]?.workAssignments || []
        )
        setCoordinations(
          isAssignByMe
            ? result?.responsibilities?.[1]?.workAssignments || []
            : result?.responsibilitiesDetails?.[1]?.workAssignments || []
        )
        setFollowers(
          isAssignByMe
            ? result?.responsibilities?.[2]?.workAssignments || []
            : result?.responsibilitiesDetails?.[2]?.workAssignments || []
        )
        setMentionData(
          uniqBy(
            result?.relatedStaff?.map(e => ({
              id: e?.id,
              display: e?.name,
            })),
            'id'
          )
        )
      },
      afterAction: () => {
        setIsFetching(false)
      },
      afterError: () => {
        showToast({
          message: Toast.NOT_FOUND('công việc'),
          intent: Intent.WARNING,
        })
      },
    })
  }, [id, roleId])

  const fetchPhongBanDonVi = useCallback(async () => {
    const convertType = type => {
      if (type === 'PB') {
        return 'DEPARTMENT'
      } else if (type === 'DV') {
        return 'ORGANIZATION'
      }

      return 'PERSONAL'
    }

    const convertUsers = values => {
      return {
        ...values,
        id: values?.chucDanhId,
        type: 'PERSONAL',
        name: values?.tenNhanVien,
        tenChucVu: values?.chucVu,
        maChucVu: values?.maChucVu,
        parentId: values?.parentId,
      }
    }

    await requestAction({
      showToast: false,
      successCode: 200,
      action: () =>
        getPhongBanNhanVien({
          donViId: mainUnitId,
          phongBanId: mainDepartmentId,
          limit: 9999,
        }),
      afterResponse: response => {
        let newData = []
        if (response?.items) {
          newData = response?.items?.map(elm => {
            return {
              id: elm?.phongBanId,
              type: convertType('PB'),
              name: elm?.maPhongBan,
              code: elm?.maPhongBan,
              parentId: elm?.donViId,
              children:
                elm?.nhanViens?.map(u =>
                  convertUsers({ ...u, parentId: elm?.phongBanId })
                ) || [],
            }
          })
        }

        requestAction({
          showToast: false,
          successCode: 0,
          action: () => saveTree(newData),
        })
      },
    })
  }, [mainUnitId, mainDepartmentId])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  useEffect(() => {
    fetchPhongBanDonVi()
  }, [fetchPhongBanDonVi])

  useEffect(() => {
    fetchInformationWorkItemAction()
  }, [fetchInformationWorkItemAction])

  return {
    pathAction: action,
    main,
    isFetching,
    isDisabled,
    isDisabledDefault,
    detailData,
    deadline,
    executor,
    percent,
    followers,
    informationWorkItemAction,
    coordinations,
    mentionData,
    menuActive,
    fetchInformationWorkItemAction,
    fetchDetail,
    setPercent,
    setIsDisabled,
    setMenuActive,
    setIsDisabledDefault,
    onChangeFile,
    onProgress,
    onMenu,
  }
}

export default useTaskDetail
