import * as types from '../constants/ActionTypes'

const initialState = {
  detailId: null,
  list: {
    items: [],
  },
}

export default function projects(state = initialState, action) {
  switch (action.type) {
    case types.PROJECTS_GET_LIST_BY_MEMBER_ID:
      return {
        ...state,
        list: {
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    default:
      return state
  }
}
