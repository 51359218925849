import { ListFilter } from '../../../components/common'
import React from 'react'

const ProcedureCategoryListFilter = props => (
  <ListFilter
    inputPlaceholder="Nhập tên danh mục"
    onSearch={props.onSearch}
    onRemoveFilter={props.onSearch}
  />
)

export default ProcedureCategoryListFilter
