import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import { CategoryDrawer } from '../../components/DocumentCabinet'
import * as Types from '../../constants/Api'

class MoveDrawer extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
  }

  static defaultProps = {
    auth: {},
    items: [],
    categories: [],
    type: Types.CABINET_UNIT,
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCheckCategory = this.handleCheckCategory.bind(this)
  }

  state = {
    isLoading: false,
    form: {
      tu_tai_lieu_src_id: this.props.drawerId ? this.props.drawerId.id : '',
      tu_tai_lieu_des_id: '',
      loai_tu_tai_lieu: this.props.type || 1,
    },
  }

  handleCheckCategory(data) {
    this.setState({
      form: {
        ...this.state.form,
        tu_tai_lieu_des_id: String(data),
      },
    })
  }

  async handleSubmit() {
    this.setState({ isLoading: true })
    await this.props.onSubmit(this.state.form)
    this.setState({ isLoading: false })
  }

  getTypeName(type) {
    let typeName
    switch (type) {
      case Types.CABINET_PERSONAL:
        typeName = 'TTL CÁ NHÂN'
        break
      case Types.CABINET_DEPARTMENT:
        typeName = 'TTL PHÒNG BAN'
        break
      default:
        typeName = 'TTL ĐƠN VỊ'
    }
    return typeName
  }

  render() {
    let { type, drawerId } = this.props
    let typeName = this.getTypeName(type)
    let disableIds = drawerId.id
    return (
      <div className="wrapper-box-container addDocumentsCabinet">
        <div className="container-box-addfile">
          <div className="box-action-detail">
            <div className="container-form-action">
              <div className="row section-content">
                <div className="col-left-form form-container">
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Di chuyển đến
                        </label>
                        <div className="pt-form-content">
                          <div className="col-right-form document-cabinet-content">
                            <CategoryDrawer
                              disableIds={disableIds}
                              mutilSelect
                              nameTab={typeName}
                              categories={this.props.categories}
                              onCheckCategory={this.handleCheckCategory}
                              isLoading={this.props.isLoading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buttton-action-footer">
            <Button
              className="pt-button btn-cancel"
              onClick={this.props.goBack}
              disabled={this.props.isLoading}
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </Button>
            <Button
              className="pt-button btn-blue-color"
              onClick={this.handleSubmit}
              loading={this.props.isLoading}
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default MoveDrawer
