import React from 'react'
import _ from 'lodash'
import arrayToTree from 'array-to-tree'
import { Scrollbars } from 'react-custom-scrollbars'
import { Radio } from '@blueprintjs/core'

class DiChuyen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: [],
      isOpen: {},
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleCheckCategories = this.handleCheckCategories.bind(this)
    this.handleOpenDrawer = this.handleOpenDrawer.bind(this)
  }

  handleOpenDrawer(data) {
    data.forEach(async item => {
      if (
        item.dsHoSoCongViecCon &&
        this.props.disableIds.indexOf(item.id) === -1
      ) {
        await this.setState({
          isOpen: {
            ...this.state.isOpen,
            [item.id]: false,
          },
        })
        this.handleOpenDrawer(item.dsHoSoCongViecCon)
      }
    })
  }

  componentWillReceiveProps(props) {
    if (props.categories !== this.props.categories && !this.props.isLoading) {
      const categories = arrayToTree(props.categories, {
        parentProperty: 'thuMucChaId',
      })
      this.handleOpenDrawer(categories)
    }
  }

  handleClick = (id, e) => {
    e.preventDefault()
    this.setState({
      isOpen: {
        ...this.state.isOpen,
        [id]: !this.state.isOpen[id],
      },
    })
  }

  handleCheckCategories(e) {
    let { checked } = this.state
    if (e.target.checked) {
      this.setState({ checked: [e.target.value] }, () => {
        this.props.onCheckCategory &&
          this.props.onCheckCategory(this.state.checked)
      })
    } else {
      if (checked.indexOf(e.target.value) !== -1) {
        delete checked[checked.indexOf(e.target.value)]
        this.setState(
          {
            checked: _.compact(checked),
          },
          () => {
            this.props.onCheckCategory &&
              this.props.onCheckCategory(this.state.checked)
          }
        )
      }
    }
  }

  renderSubItem(items, idDisable) {
    return (
      <ul className="sublist">
        {items &&
          items.map(item => {
            let isCheckDisable = false
            let idCheckDisable = null
            if (
              this.props.disableIds.indexOf(item.id) !== -1 ||
              (item.thuMucChaId &&
                this.props.disableIds.indexOf(item.thuMucChaId) !== -1) ||
              (idDisable && item.thuMucChaId === idDisable)
            ) {
              isCheckDisable = true
              idCheckDisable = item.id
            }

            return (
              <li className="subitem" key={item.id}>
                <div className="main-content">
                  {this.props.mutilSelect && (
                    <Radio
                      value={item.id}
                      onChange={this.handleCheckCategories}
                      checked={this.state.checked.indexOf(item.id) !== -1}
                      disabled={isCheckDisable}
                    />
                  )}
                  <div className="dropdown-left">
                    {item.dsHoSoCongViecCon &&
                      item.dsHoSoCongViecCon.length !== 0 && (
                        <span onClick={this.handleClick.bind(this, item.id)}>
                          {this.state.isOpen[item.id] && (
                            <span className="pt-icon pt-icon-chevron-up" />
                          )}
                          {!this.state.isOpen[item.id] && (
                            <span className="pt-icon pt-icon-chevron-down" />
                          )}
                        </span>
                      )}
                  </div>
                  <span className="text">{item.ten}</span>
                </div>
                {this.state.isOpen[item.id] &&
                  item.dsHoSoCongViecCon &&
                  item.dsHoSoCongViecCon.length !== 0 &&
                  this.renderSubItem(item.dsHoSoCongViecCon, idCheckDisable)}
              </li>
            )
          })}
      </ul>
    )
  }

  render() {
    const categories = arrayToTree(this.props.categories, {
      parentProperty: 'thuMucChaId',
    })
    const { data } = this.props
    return (
      <section className="aside-nav">
        <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={600}>
          <ul className="move-hscv-list">
            {categories.length > 0 && (
              <li>
                <div className="main-content border-top-none">
                  <Radio
                    disabled={data.level === 1}
                    value=""
                    checked={this.state.checked.indexOf('') !== -1}
                    onChange={this.handleCheckCategories}
                  />
                  <span className="text text-link-drawer">
                    Chuyển đến ngăn ngoài cùng
                  </span>
                </div>
              </li>
            )}
            {categories.map((item, k) => {
              let isCheckDisable = false
              let idCheckDisable = null
              if (
                this.props.disableIds.indexOf(item.id) !== -1 ||
                (item.thuMucChaId &&
                  this.props.disableIds.indexOf(item.thuMucChaId) !== -1)
              ) {
                isCheckDisable = true
                idCheckDisable = item.id
              }

              return (
                <li key={k}>
                  <div className="main-content">
                    {this.props.mutilSelect && (
                      <Radio
                        value={item.id}
                        onChange={this.handleCheckCategories}
                        checked={this.state.checked.indexOf(item.id) !== -1}
                        disabled={isCheckDisable}
                      />
                    )}
                    <div className="dropdown-left">
                      {item.dsHoSoCongViecCon &&
                        item.dsHoSoCongViecCon.length !== 0 && (
                          <span onClick={this.handleClick.bind(this, item.id)}>
                            {this.state.isOpen[item.id] && (
                              <span className="pt-icon pt-icon-chevron-up" />
                            )}
                            {!this.state.isOpen[item.id] && (
                              <span className="pt-icon pt-icon-chevron-down" />
                            )}
                          </span>
                        )}
                    </div>
                    <span className="text">{item.ten}</span>
                  </div>
                  {this.state.isOpen[item.id] &&
                    item.dsHoSoCongViecCon &&
                    item.dsHoSoCongViecCon.length !== 0 &&
                    this.renderSubItem(item.dsHoSoCongViecCon, idCheckDisable)}
                </li>
              )
            })}
          </ul>
        </Scrollbars>
      </section>
    )
  }
}

DiChuyen.defaultProps = {
  categories: [],
  disableIds: [],
}

export default DiChuyen
