import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import {
  ListFooter,
  ListItemThongKeGopY,
  CircleChart,
  GridView,
} from '../../components/RequestComment'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import { Redirect } from 'react-router-dom'
import { Button, Intent } from '@blueprintjs/core'
import { HeadingPage } from '../../components/common'
import FileDownload from 'file-saver'
import _ from 'lodash'

const REFRESH_FALSE = false
class ThongKeGopY extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isRedirect: false,
      redirectTo: '',
      filter: {},
      filterColumn: {},
      sort: undefined,
      isLoading: false,
      isEmpty: false,
      page: 1,
      isError: false,
      isShow: false,
      listStatus: [
        {
          name: 'Chưa có ý kiên',
          value: 0,
        },
        {
          name: 'Không đồng ý',
          value: 1,
        },
        {
          name: 'Đồng ý',
          value: 2,
        },
        {
          name: 'Không có ý kiến',
          value: 3,
        },
      ],
      pieChartDataTrangThai: [
        { name: 'Thống nhất', value: 0, color: '#1B60FF' },
        { name: 'Không thống nhất', value: 0, color: '#FF5876' },
        { name: 'Không ý kiến', value: 0, color: '#9C9DA0' },
      ],
      pieChartDataQuaHan: [
        { name: 'Quá hạn', value: 0, color: '#FF5876' },
        { name: 'Đúng hạn', value: 0, color: '#1B60FF' },
        { name: 'Chưa hoàn thành', value: 0, color: '#9C9DA0' },
      ],
      pieChartDataHoanThanh: [
        { name: 'Hoàn thành', value: 0, color: '#1B60FF' },
        { name: 'Chưa hoàn thành', value: 0, color: '#9C9DA0' },
      ],
      countDonViHoanThanh: 0,
      countTotal: 0,
      exporting: false,
    }

    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.handleExport = this.handleExport.bind(this)
  }

  static propTypes = {
    tongHopGopY: PropTypes.object.isRequired,
  }

  componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData('ThongKeGopY')

    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
    }

    this.setState(state, () => {
      this.clickPagination({ selected: this.state.page - 1 })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.tongHopGopY &&
      this.props.tongHopGopY.list &&
      this.props.tongHopGopY.list.total &&
      this.state.page >
        Math.ceil(this.props.tongHopGopY.list.total / Types.PAGINATION_LIMIT)
    ) {
      this.setState(
        {
          page: Math.ceil(
            this.props.tongHopGopY.list.total / Types.PAGINATION_LIMIT
          ),
        },
        () => {
          this.clickPagination({ selected: this.state.page - 1 })
        }
      )
    }
  }

  clickPagination(e) {
    let state = {
      page: e.selected + 1,
    }

    this.setState(state, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    let id = this.props.match.params.id
    this.setState({ page, isLoading: true, sort }, () => {
      Tool.Utils.preData('ThongKeGopY', { id, page, sort })
      // tạm thời để refresh = false
      this.props.actions
        .tongHopGopY(id, page, sort, REFRESH_FALSE)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
      let result = {}
      this.props.actions
        .thongKeGopY(id)
        .then(res => {
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            result = res.payload.data.result
          }
          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isError })
        })
        .then(() => {
          this.setState({
            isLoading: false,
            pieChartDataTrangThai: [
              {
                name: `Thống nhất ${
                  result.hoanThanh === 0
                    ? '0'
                    : _.round((result.thongNhat / result.hoanThanh) * 100, 2)
                }%`,
                value: result.thongNhat,
                color: '#1B60FF',
              },
              {
                name: `Không thống nhất ${
                  result.hoanThanh === 0
                    ? '0'
                    : _.round(
                        (result.khongThongNhat / result.hoanThanh) * 100,
                        2
                      )
                }% `,
                value: result.khongThongNhat,
                color: '#FF5876',
              },
              {
                name: `Không ý kiến ${
                  result.hoanThanh === 0
                    ? '0'
                    : _.round((result.khongYKien / result.hoanThanh) * 100, 2)
                }% `,
                value: result.hoanThanh === 0 ? 1 : result.khongYKien,
                color: '#9C9DA0',
              },
            ],
            pieChartDataQuaHan: [
              {
                name: `Quá hạn  ${_.round(
                  (result.quaHan / result.total) * 100,
                  2
                )}%`,
                value: result.quaHan,
                color: '#FF5876',
              },
              {
                name: `Đúng hạn  ${_.round(
                  (result.dungHan / result.total) * 100,
                  2
                )}%`,
                value: result.dungHan,
                color: '#1B60FF',
              },
              {
                name: `Chưa hoàn thành ${_.round(
                  (result.chuaHoanThanh / result.total) * 100,
                  2
                )}%`,
                value: result.chuaHoanThanh,
                color: '#9C9DA0',
              },
            ],
            // pieChartDataHoanThanh: [
            //   {name: `Hoàn thành ${(result.hoanThanh/result.total)*100}%`, value: result.hoanThanh, color: '#1B60FF'},
            //   {name: `Chưa hoàn thành ${(result.chuaHoanThanh / result.total) * 100}%`, value: result.chuaHoanThanh, color: '#9C9DA0'}
            // ],
            countDonViHoanThanh: result.hoanThanh,
            countTotal: result.total,
          })
        })
    })
  }

  handleClickGoBack = () => {
    this.props.history.goBack()
  }

  handleExport() {
    let id = this.props.match.params.id
    this.setState({ exporting: true }, () => {
      this.props.actions
        .exportGopYDonVi(id)
        .then(res => {
          if (res.error || !res.payload || !res.payload.data) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          FileDownload.saveAs(
            res.payload.data,
            `thong-ke-gop-y-kien-don-vi.xls`
          )
        })
        .finally(() => {
          this.setState({
            exporting: false,
          })
        })
    })
  }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
          }}
        />
      )
    }
    return (
      <MasterLayout typeSidebar="utilities">
        <div className="page-utilities-container">
          {/* Heading */}
          <HeadingPage>
            <div className="button-heading-right pull-left">
              <button
                className="pt-button pt-button-back"
                type="button"
                onClick={this.handleClickGoBack}
                disabled={this.state.isLoading}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-light"></span>
                <span className="text-content">Quay lại</span>
              </button>
            </div>
            <div className="button-heading-right pull-right">
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.exporting}
                disabled={this.state.isEmpty}
              />
            </div>
          </HeadingPage>
          <div className="section-content">
            <div className="row">
              <div className="col-md-4 col-xs-12 col-sm-12">
                <div className="chart-content-thongke-gopy">
                  <div className="row">
                    <div className="col-xs-12">
                      <p className="text-center text-bold">
                        Thống kê hoàn thành
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12" style={{ height: '200px' }}>
                      <div
                        className="row align-items-center"
                        style={{ height: '100%' }}
                      >
                        <div className="col-xs-6 chart-thongke-gopy">
                          <label className="text-blue text-chart">
                            {this.state.countTotal
                              ? `${_.round(
                                  (this.state.countDonViHoanThanh /
                                    this.state.countTotal) *
                                    100,
                                  2
                                )}%`
                              : ''}
                          </label>
                        </div>
                        <div className="col-xs-6 chart-thongke-gopy">
                          <h2 className="name-chart">{`${this.state.countDonViHoanThanh} / ${this.state.countTotal}  đơn vị đã hoàn thành`}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xs-12 col-sm-12">
                <div className="chart-content-thongke-gopy">
                  <p className="text-center text-bold">Thống kê quá hạn</p>
                  <CircleChart data={this.state.pieChartDataQuaHan} />
                </div>
              </div>
              <div className=" col-md-4 col-xs-12 col-sm-12">
                <div className="chart-content-thongke-gopy">
                  <p className="text-center text-bold">Thống kê trạng thái</p>
                  <CircleChart data={this.state.pieChartDataTrangThai} />
                </div>
              </div>
            </div>
          </div>
          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <div
              className="table-container"
              style={{
                paddingBottom: 0,
                border: 0,
                background:
                  Math.ceil(
                    this.props.tongHopGopY.list.total / Types.PAGINATION_LIMIT
                  ) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridView
                classNames={'pt-table'}
                page={this.state.page}
                headers={headers.listThongKeGopY}
                itemShape={ListItemThongKeGopY}
                data={this.props.tongHopGopY.list.items}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                isError={this.state.isError}
                auth={this.props.auth}
                history={this.props.history}
                actions={this.props.actions}
              />
              {!this.state.isEmpty && !this.state.isError && (
                <ListFooter
                  forcePage={this.state.page - 1}
                  pageCount={Math.ceil(
                    this.props.tongHopGopY.list.total / Types.PAGINATION_LIMIT
                  )}
                  onPageClick={this.clickPagination}
                  isLoading={this.state.isLoading}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,
  tongHopGopY: {
    ...state.tongHopGopY,
    list: {
      ...state.requestComments.list,
      items: state.requestComments.list.items,
    },
  },
  auth: {
    ...state,
    user: state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThongKeGopY)
