import React from 'react'
import { Tooltip, Intent, Position } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import get from 'lodash/get'
import { Badge } from 'components/newCommon'
import { isValidMenu } from './helper'
import ItemChild from './item'

const SidebarItem = ({ auth, match, dataSource, isDisabled, counters }) => {
  const checkActive = (arrayPath, link) => {
    if (
      arrayPath?.length !== 0 &&
      !!arrayPath.find(elm => link.lastIndexOf(elm) === 0)
    ) {
      return true
    }
  }

  return (
    <li
      className={classNames('sidebar-item', {
        active:
          dataSource?.path &&
          match?.url &&
          dataSource?.links &&
          dataSource?.links?.length !== 0
            ? checkActive([dataSource.path, ...dataSource.links], match.url)
            : false,
      })}
    >
      <Tooltip
        tooltipClassName="tooltip-sidebar"
        intent={Intent.PRIMARY}
        position={Position.RIGHT}
        isDisabled={isDisabled}
        content={dataSource?.nameMenu}
      >
        <div className="sidebar-item-box">
          {dataSource?.path ? (
            <Link to={dataSource?.path}>
              <div className="name-menu">
                <span
                  className={classNames('icon-sidebar', {
                    [`${dataSource?.icon}`]: dataSource?.icon,
                  })}
                />
                <span className={'name'}>{dataSource?.nameMenu}</span>
                <Badge
                  className="cpc-badge-sidebar"
                  count={get(counters, dataSource?.badgeKey)}
                  type={dataSource?.badgeType}
                />
              </div>
            </Link>
          ) : (
            <div className="name-menu">
              <span className="name">{dataSource?.nameMenu}</span>
              <Badge
                className="cpc-badge-sidebar"
                count={get(counters, dataSource?.badgeKey)}
                type={dataSource?.badgeType}
              />
            </div>
          )}
        </div>
      </Tooltip>
      {dataSource?.subMenu && dataSource?.subMenu?.length !== 0 ? (
        <ul>
          {dataSource?.subMenu
            ?.filter(f => isValidMenu(auth?.permission, f))
            ?.map((elm, index) => (
              <ItemChild
                key={index}
                auth={auth}
                match={match}
                dataSource={elm}
                isDisabled={isDisabled}
                counters={counters}
              />
            ))}
        </ul>
      ) : (
        ''
      )}
    </li>
  )
}

export default SidebarItem
