import React, { memo } from 'react'
import csx from 'classnames'

import { Card, Avatar } from '../index'

const UserCard = ({
  className,
  needBaseUrl = false,
  src,
  name = '',
  department,
  unit,
  action = '',
  isShowAvatar = true,
}) => {
  const handleShowName = () => {
    return (
      <div className="avatar-name mr10">
        <span>{name}</span>
      </div>
    )
  }

  return (
    <Card className={csx('cpc-user-card', className)}>
      {isShowAvatar ? (
        <Avatar src={src} className="mr10" needBaseUrl={needBaseUrl} />
      ) : (
        handleShowName()
      )}
      <div className="flex-auto">
        <div className="cpc-user-title">
          <span className="cpc-user-title mr5 font-size-13">{name}</span>
          <span className="cpc-user-action">{action}</span>
        </div>
        <div style={{ lineHeight: '100%' }}>
          <span className="cpc-user-sub">
            {department}
            {department && unit && ' - '}
            {unit}
          </span>
        </div>
      </div>
    </Card>
  )
}

export default memo(UserCard)
