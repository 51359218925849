import React, { useContext } from 'react'
import { Button, Tooltip } from '@blueprintjs/core'
import classNames from 'classnames'
import moment from 'moment'

import { ResetButton, Icon, DatePicker } from 'components/newCommon'
import { Select, SelectTree } from 'components/newCommon2'
import variables from 'constants/variables'
import { ThongKeKetLuanCuocHopContext } from '../ThongKeKetLuanCuocHop'

const filterKeys = {
  from: 'fromDate',
  to: 'toDate',
  status: 'status',
  isHide: 'isHide',
  phongBanDonVi: 'phongBanDonVi',
}

const statusList = [
  {
    value: 'NEW',
    label: 'Chưa hoàn thành',
  },
  {
    value: 'DONE',
    label: 'Hoàn thành',
  },
  {
    value: 'DONE_SLOW',
    label: 'Hoàn thành chậm',
  },
]

const MeetingStatisticFilter = () => {
  const {
    treeData,
    filter: {
      [filterKeys.from]: from = null,
      [filterKeys.to]: to = null,
      [filterKeys.status]: status = 'ALL',
      [filterKeys.phongBanDonVi]: phongBanDonVi = {
        label: 'Phòng ban/Đơn vị có công việc',
        value: 'isHide',
      },
    } = {},
    changeFilter = () => {},
  } = useContext(ThongKeKetLuanCuocHopContext)

  return (
    <div className="d-flex wrap justify-content-space-between gap-10">
      <div>
        <div className="d-flex wrap gap-10">
          <div>
            <div className="d-flex">
              <DatePicker
                placeholder="Từ ngày"
                isClearable={false}
                selected={from ? moment(from) : undefined}
                maxDate={moment(to) || undefined}
                onChange={value => {
                  const newValue = value
                    ? value.format(variables.DATE_FORMAT.DATE)
                    : undefined
                  changeFilter({ name: [filterKeys.from] })(newValue)
                }}
              />
              <DatePicker
                placeholder="Đến ngày"
                isClearable={false}
                selected={to ? moment(to) : undefined}
                minDate={moment(from) || undefined}
                onChange={value => {
                  const newValue = value
                    ? value.format(variables.DATE_FORMAT.DATE)
                    : undefined
                  changeFilter({ name: [filterKeys.to] })(newValue)
                }}
              />
            </div>
          </div>
          <div>
            <Select
              inputWrapperClassName={'border-radius-3 input-not-ph'}
              dataSource={[
                { label: 'Tất cả trạng thái', value: 'ALL' },
                ...statusList,
              ]}
              inputIconClassName="icon2-documents"
              placeholder="Tất cả cả trạng thái"
              // inputClearable
              // onClear={onClear(filterKeys.donVi)}
              wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
              inputStyle={{ height: '35px', lineHeight: '35px' }}
              isShowArrowDropdown
              value={status}
              onChange={changeFilter({
                name: filterKeys.status,
                reFetch: true,
              })}
            />
          </div>
          <div style={{ minWidth: 250 }}>
            <SelectTree
              inputWrapperClassName={'border-radius-3 input-not-ph'}
              dataSource={[
                { label: 'Phòng ban/Đơn vị có công việc', value: 'isHide' },
                { label: 'Hiện tất cả Phòng ban/Đơn vị', value: 'ALL' },
                ...treeData,
              ]}
              inputIconClassName="icon2-documents"
              placeholder="Phòng ban/ Đơn vị"
              wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
              inputStyle={{ height: '35px', lineHeight: '35px' }}
              isShowArrowDropdown
              radioName={'phongBanDonVi'}
              keySource={['label', 'value']}
              value={phongBanDonVi}
              onChange={option => {
                const value = {
                  [filterKeys.isHide]: option.value === 'isHide',
                  [filterKeys.phongBanDonVi]: option,
                }
                changeFilter({ multipleKey: true, reFetch: true })(value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex" style={{ minWidth: 142 }}>
        <Button
          className={classNames('cpc-button theme-md green', 'color-white')}
          onClick={changeFilter({ name: 'SUBMIT', reFetch: true })}
        >
          <span>Thống kê</span>
          <Icon classIcon={'icon2-search'} />
        </Button>
        <Tooltip content={<span className="font-size-12">Làm mới</span>}>
          <ResetButton
            className="ml10"
            onClick={changeFilter({ name: 'RESET' })}
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default MeetingStatisticFilter
