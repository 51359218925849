import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classnames from 'classnames'
import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { Button } from '@blueprintjs/core'

import { MasterLayout } from '../../components/layout'
import {
  DetailBoxAction,
  DetailBoxAction2,
  DetailBox,
} from '../../components/Opinion'
import {
  DetailFileContent,
  NotFound,
  LoadingLine,
  LoadingItem,
  DetailFileContentV2,
} from 'components/common'
import * as Actions from '../../actions'

class OpinionDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      opinions: {
        dataDetail: {
          soKyHieu: _.get(props, 'location.state.soKyHieu', ''),
          fileNoiDung: null,
        },
        fileId: _.get(props, 'location.state.fileId', null),
        id: _.get(props, 'match.params.id', null),
      },
      mainUnitId: _.get(props, 'auth.user.mainUnitId', null),
      isLoadingData: {
        opinion: true,
        file: true,
        detail: true,
      },
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount = async () => {
    window.addEventListener('scroll', this.handleScroll)
    this.getInfoFile()
    this.getDetail()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getInfoFile = () => {
    if (!_.get(this.props, 'location.state.fileId', false)) {
      return
    }

    this.props.actions
      .getFileInformation(_.get(this.props, 'location.state.fileId'))
      .then(res => {
        if (_.get(res, 'payload.data.result', false)) {
          this.setState({
            opinions: {
              ...this.state.opinions,
              dataDetail: {
                ...this.state.opinions.dataDetail,
                fileNoiDung: _.get(res, 'payload.data.result'),
              },
            },
            isLoadingData: {
              ...this.state.isLoadingData,
              file: false,
            },
          })
        }
      })
  }

  /**
   * Gọi Api chi tiết
   * @param {number} id ý kiến id
   * @returns {void} get chi tiết ý kiến
   * @memberof OpinionDetail
   */
  getDetail = (id = this.state.opinions.id) => {
    if (_.get(this.props, 'location.state.soKyHieu', false)) {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          opinion: false,
        },
      })
    }

    if (_.isEmpty(id)) {
      return
    }

    this.props.actions.opinionDetail(id).then(res => {
      if (_.get(res, 'payload.data.result', false)) {
        this.setState({
          opinions: {
            ...this.state.opinions,
            dataDetail: _.get(res, 'payload.data.result'),
          },
          isLoadingData: {
            ...this.state.isLoadingData,
            opinion: false,
            detail: false,
            file: false,
          },
        })
      }
    })
  }

  refHandlers = {
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  handleSubmit = async params => {
    if (params.message && params.intent) {
      this.props.actions.commonAddToasterMessage({
        message: params.message,
        intent: params.intent,
      })
    }

    if (params.isSuccess) {
      if (
        (params.title === 'DongYKySoBangSIM' ||
          params.title === 'DongY' ||
          params.title === 'DongYKySoEVNCA') &&
        this.props.history
      ) {
        return this.props.history.push(
          '/hoi-dong-thanh-vien/lay-y-kien/danh-sach'
        )
      }
    }

    await this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        opinion: true,
        detail: true,
      },
    })

    this.getDetail()
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  goLinkOpinionSynthesis = () => {
    this.props.history.push(
      `/hoi-dong-thanh-vien/lay-y-kien/tong-hop/${this.state.opinions.id}`
    )
  }

  goBack() {
    this.props.history.goBack()
  }

  isCheckLDPB = () => {
    let isCheckTLPB
    const { opinions } = this.state
    if (
      _.get(opinions, 'dataDetail.lanhDaoPhongBanChuTri.chucDanhId', '') ===
      _.get(this.props, 'auth.user.roleId', '')
    ) {
      isCheckTLPB = true
    }

    return isCheckTLPB
  }

  isCheckHDTV = () => {
    let isCheckHDTV
    const { opinions } = this.state
    const dsThanhVien = _.get(opinions, 'dataDetail.dsThanhVien', []) || []
    if (
      dsThanhVien.find(
        item => item.chucDanhId === _.get(this.props, 'auth.user.roleId', '')
      )
    ) {
      isCheckHDTV = true
    }

    return isCheckHDTV
  }

  handleRemoveFileInfo = () => {
    if (this.props.location && this.props.location.state) {
      this.props.history.replace({
        state: {
          ...this.props.location.state,
          fileId: null,
        },
      })
    }
  }

  updateDetail = () => {
    this.getDetail()
  }

  render() {
    const { isLoadingData, opinions } = this.state
    const fileDetail = _.get(opinions, 'dataDetail.fileNoiDung', false)
    return (
      <MasterLayout typeSidebar="signNumber" collapseSideBar={true}>
        <div className="detail-receive-document" ref="historyNode">
          {isLoadingData.opinion ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              <span className="number-code">
                <span className="name">Số ký hiệu:</span>
                <span className="code">
                  {_.get(opinions, 'dataDetail.soKyHieu', '')}
                </span>
              </span>
              <div className="pull-right button-icon-left">
                <Button
                  rightIconName="pt-icon icon-menu-o-vuong"
                  className="btn-action btn-top-heading button-icon-left"
                  text="Tổng hợp"
                  onClick={this.goLinkOpinionSynthesis}
                />
              </div>
            </div>
          )}
          <div className="detail-container detail-container-sign-number">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {!isEmpty(fileDetail) && (
                  <DetailFileContentV2
                    auth={this.props.auth.user}
                    actions={this.props.actions}
                    file={fileDetail}
                  />
                )}
              </div>
              {isLoadingData.detail ? (
                <div className="box-right">
                  <div className="loading-container">
                    <LoadingItem />
                  </div>
                </div>
              ) : !opinions.dataDetail ? (
                <div className="box-right">
                  <NotFound />
                </div>
              ) : (
                <div>
                  {!this.isCheckHDTV() && this.isCheckLDPB() && (
                    <div className="box-right">
                      <DetailBoxAction2
                        auth={this.props.auth.user}
                        onSubmit={this.handleSubmit}
                        actions={this.props.actions}
                        isLoadingData={isLoadingData}
                        data={opinions.dataDetail}
                        onRemoveFileInfo={this.handleRemoveFileInfo}
                      />
                    </div>
                  )}

                  {this.isCheckHDTV() && !this.isCheckLDPB() && (
                    <div className="box-right">
                      <DetailBoxAction
                        auth={this.props.auth.user}
                        onSubmit={this.handleSubmit}
                        actions={this.props.actions}
                        isLoadingData={isLoadingData}
                        data={opinions.dataDetail}
                        onRemoveFileInfo={this.handleRemoveFileInfo}
                        updateDetail={this.updateDetail}
                      />
                    </div>
                  )}

                  {!this.isCheckHDTV() && !this.isCheckLDPB() && (
                    <div className="box-right">
                      <DetailBox
                        data={opinions.dataDetail}
                        auth={this.props.auth.user}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

OpinionDetail.propTypes = {
  dataDetail: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = state => ({
  dataDetail: state.opinions.dataDetail,
  auth: { user: state.auth },
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(OpinionDetail)
