import React from 'react'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as Actions from '../../actions/'
import { Checkbox, Button, Collapse } from '@blueprintjs/core'
import moment from 'moment'
import { DepartmentPerform } from '../../components/common/'
import 'moment/locale/vi'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import classnames from 'classnames'
import DatePicker from 'react-datepicker'

class AssignmentPage extends React.Component {
  static propTypes = {
    departments: PropTypes.array.isRequired,
    attachedUnits: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      chuTriId: null,
      dsPhoiHop: [],
      dsXemDeBiet: [],
      hanGiaiQuyet: undefined,
      departmentSelections: [],
      isLoading: false,
      dealineToString: '',
      luuBanGoc: false,
      attachedUnitSelections: [],
      congVanDen: [],
      error: {},
      isOpen: false,
      noiDung: '',
      donViChuTriId: null,
      dsDonViPhoiHop: [],
      dsDonViXemDeBiet: [],
    }
  }

  handleGetValueGridCheckBox = (type, data) => {
    if (!data || !Object.keys(data).length) {
      return
    }

    switch (type) {
      case 'units':
        let chuTriId = this.state.chuTriId
        if (!!this.state.donViChuTriId !== !!data.chuTriId) {
          chuTriId = null
        }
        return this.setState({
          chuTriId,
          donViChuTriId: data.chuTriId,
          dsDonViPhoiHop: data.dsPhoiHop,
          dsDonViXemDeBiet: data.dsXemDeBiet,
        })

      default:
        let donViChuTriId = this.state.donViChuTriId
        if (this.state.chuTriId !== data.chuTriId) {
          donViChuTriId = null
        }
        return this.setState({
          donViChuTriId,
          chuTriId: data.chuTriId,
          dsPhoiHop: data.dsPhoiHop,
          dsXemDeBiet: data.dsXemDeBiet,
        })
    }
  }

  handleClickArrow = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleDateChange = (name, nameToString, e) => {
    const error = this.state.error
    delete error[name]
    let value = e ? e : undefined
    let valueToString = ''
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: value,
      [nameToString]: valueToString,
      error,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment().format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    let oldValue = undefined
    let oldValueToString = ''
    if (
      this.props.informationSteering &&
      this.props.informationSteering.hanGiaiQuyet
    ) {
      oldValue = moment(this.props.informationSteering.hanGiaiQuyet)
      oldValueToString = moment(
        this.props.informationSteering.hanGiaiQuyet
      ).format('DD/MM/YYYY')
    }

    this.setState({
      [nameToString]: oldValueToString,
      [name]: oldValue,
    })
  }

  handleInputChange = e => {
    if (e.target.type === 'checkbox') {
      return this.setState({ [e.target.name]: e.target.checked })
    }
    const error = this.state.error
    delete error[e.target.name]
    this.setState({ [e.target.name]: e.target.value, error })
  }

  componentDidMount() {
    this.props.actions.commonFetchDepartments()
    this.props.actions.commonFetchAttachedUnits(this.props.auth.mainUnitId)
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.list
    ) {
      this.setState({
        congVanDen: this.props.location.state.list,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.departments &&
      nextProps.departments.length &&
      nextProps.departments !== this.props.departments
    ) {
      const departmentSelections = []
      nextProps.departments.forEach(item => {
        departmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ departmentSelections })
    }

    if (
      nextProps.attachedUnits &&
      nextProps.attachedUnits.length &&
      nextProps.attachedUnits !== this.props.attachedUnits
    ) {
      const attachedUnitSelections = []
      nextProps.attachedUnits.forEach(item => {
        attachedUnitSelections.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
      })
      this.setState({ attachedUnitSelections })
    }
  }

  handleSubmit = () => {
    if (
      (!this.state.chuTriId || !this.state.chuTriId.length) &&
      (!this.state.donViChuTriId || !this.state.donViChuTriId.length)
    ) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_PHONGBANCHUTRI,
        intent: Intent.WARNING,
      })
    }

    this.setState({
      isLoading: true,
    })
    let hanGiaiQuyet = null
    if (
      this.state.hanGiaiQuyet &&
      this.state.hanGiaiQuyet.toString().length > 0
    ) {
      hanGiaiQuyet = moment(this.state.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    let dataRequest = {
      cong_van_den_ids: this.state.congVanDen,
      noi_dung: this.state.noiDung,
      chu_tri_id: this.state.chuTriId,
      ds_phoi_hop: this.state.dsPhoiHop,
      ds_xem_de_biet: this.state.dsXemDeBiet,
      don_vi_chu_tri_id: this.state.donViChuTriId,
      ds_don_vi_phoi_hop: this.state.dsDonViPhoiHop,
      ds_don_vi_xem_de_biet: this.state.dsDonViXemDeBiet,
      han_giai_quyet: hanGiaiQuyet,
      luu_ban_goc: this.state.luuBanGoc,
    }

    this.props.actions
      .receiveDocumentSendAssignmentv2(dataRequest)
      .then(res => {
        if (
          res &&
          res.payload &&
          res.payload.status === 200 &&
          res.payload.data &&
          res.payload.data.result &&
          res.payload.data.result.length > 0
        ) {
          const chiDao = this.state.congVanDen.length
          const daChiDao =
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length
          this.props.actions.commonAddToasterMessage({
            message: `Anh/chị chỉ đạo thành công ${daChiDao}/${chiDao} công văn`,
            intent: chiDao === daChiDao ? Intent.SUCCESS : Intent.WARNING,
          })
          this.handleClickCancel()
        } else {
          this.setState({
            isLoading: false,
          })
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }
      })
  }

  handleClickCancel = () => {
    this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar="documentary">
        <div className="row center-xs format-center detail-receive-document ">
          <div className="col-md-8 col-xs-12 col-sm-12 padding-0 form-width-90">
            <div className="heading-page bottom-add-receive-document">
              <h3 className="pull-left text-heading-page">
                <span className="icon-Chidao icon-heading"></span>
                <span className="text-heading">
                  Chỉ đạo (
                  {this.state.congVanDen && this.state.congVanDen.length
                    ? this.state.congVanDen.length
                    : 0}
                  )
                </span>
              </h3>
            </div>

            <div className="add-receive-document">
              <div className="form-container container-detail form-assign-work">
                <div className="form-padding-box">
                  <p className="text-warning mb15">
                    * Lưu ý: Nội dung chỉ đạo này sẽ được áp dụng cho các công
                    văn mà Anh/chị đã chọn
                  </p>

                  <div className="pt-form-group">
                    <div className="row full-row">
                      <div className="col-xs-12 col-md-3 col-form-item">
                        <label className="pt-label">Nội dung chỉ đạo</label>
                      </div>
                      <div className="col-xs-12 col-md-9 col-form-item">
                        <div className="pt-form-content">
                          <textarea
                            name="noiDung"
                            value={this.state.noiDung}
                            onChange={this.handleInputChange}
                            rows="3"
                            className="pt-input pt-fill"
                            dir="auto"
                            autoFocus={true}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-form-group">
                    <label className="pt-label pt0">Phòng ban thực hiện</label>
                    <DepartmentPerform
                      options={this.state.departmentSelections}
                      onSubmit={this.handleGetValueGridCheckBox.bind(
                        this,
                        'departments'
                      )}
                      dsPhoiHop={this.state.pbPhoiHop}
                      dsXemDeBiet={this.state.pbXemDeBiet}
                      chuTriId={this.state.chuTriId}
                      selectedCompositiveDepartments={[]}
                      selectedOnlySeeDepartments={[]}
                      titles={['Chủ trì', 'Phối hợp', 'Xem để biết']}
                      isHasRadio={true}
                      type="Phòng ban"
                    />
                  </div>

                  <div className="heading-group-select disabled-checkbox">
                    <label className="pt-label pt0">
                      <span
                        className="pull-left name_group name_group_unit"
                        onClick={this.handleClickArrow}
                      >
                        Các đơn vị trực thuộc
                      </span>
                    </label>
                    <span
                      onClick={this.handleClickArrow}
                      className={classnames('name_group_unit', {
                        'pt-icon pt-icon-chevron-up': this.state.isOpen,
                        'pt-icon pt-icon-chevron-down': !this.state.isOpen,
                      })}
                    ></span>
                  </div>
                  <Collapse isOpen={this.state.isOpen}>
                    <div className="pt-form-group">
                      <DepartmentPerform
                        options={this.state.attachedUnitSelections}
                        onSubmit={this.handleGetValueGridCheckBox.bind(
                          this,
                          'units'
                        )}
                        dsPhoiHop={this.state.dsDonViPhoiHop}
                        dsXemDeBiet={this.state.dsDonViXemDeBiet}
                        chuTriId={this.state.donViChuTriId}
                        selectedCompositiveDepartments={[]}
                        selectedOnlySeeDepartments={[]}
                        titles={['Chủ trì', 'Phối hợp', 'Xem để biết']}
                        isHasRadio={true}
                        type="Đơn vị"
                      />
                    </div>
                  </Collapse>

                  <div className="pt-form-group resolve-date pt-intent-danger">
                    <div className="row full-row">
                      <label className="pt-label pt-inline resolve-date-padding0 col-md-3 col-xs-12">
                        Hạn giải quyết
                      </label>
                      <div className="pt-form-content pt-inline col-md-3 col-xs-4 col-sm-4">
                        <div className="pt-input-group pt-large">
                          <DatePicker
                            readOnly={this.props.readOnly}
                            selected={this.state.hanGiaiQuyet}
                            onChange={this.handleDateChange.bind(
                              this,
                              'hanGiaiQuyet',
                              'dealineToString'
                            )}
                            value={this.state.dealineToString}
                            onChangeRaw={e =>
                              this.handleChangeRaw(
                                'dealineToString',
                                e.target.value
                              )
                            }
                            onBlur={e =>
                              this.focousOut(
                                'hanGiaiQuyet',
                                'dealineToString',
                                e.target.value
                              )
                            }
                            format="DD/MM/YYYY"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="input-datepicker input-icon-datepicker"
                            placeholderText="Chọn ngày..."
                            minDate={moment()}
                            popperPlacement="auto"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: '5px, 10px',
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                              },
                            }}
                          />
                        </div>
                      </div>
                      {this.state.error.hanGiaiQuyet !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.error.hanGiaiQuyet}
                        </div>
                      )}
                    </div>
                    <div className="note-deadline">
                      <p className="text pl0">
                        Nếu không chọn LĐ Phòng ban chủ trì sẽ xác định
                      </p>
                    </div>
                  </div>
                  <div className="selectbox-group">
                    <div className="row full-row">
                      <div className="col-xs-12 col-md-9 col-form-item">
                        <Checkbox
                          className="pt-inline"
                          name="luuBanGoc"
                          checked={this.state.luuBanGoc}
                          onChange={this.handleInputChange}
                        >
                          <span className="name_checkbox">
                            Phòng ban chủ trì lưu bản giấy
                          </span>
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="groupButton" className="buttton-action-footer">
                  <Button
                    className="pt-button btn-cancel"
                    onClick={this.handleClickCancel}
                    disabled={this.state.isLoading}
                  >
                    <span className="pt-icon icon-back"></span>
                    Quay lại
                  </Button>
                  <Button
                    className="pt-button btn-blue-color"
                    onClick={this.handleSubmit}
                    loading={this.state.isLoading}
                    disabled={this.state.isLoading}
                  >
                    <span className="text-content">Lưu</span>
                    <span className="pt-icon icon-save"></span>
                  </Button>
                </div>

                <div className="buttton-actionfooter-fixtop buttton-action-footer">
                  <Button
                    className="pt-button btn-blue-color"
                    onClick={this.handleSubmit}
                    loading={this.state.isLoading}
                    disabled={this.state.isLoading}
                  >
                    <span className="pt-icon icon-save"></span>
                    <span className="text-content">Lưu</span>
                  </Button>
                  <Button
                    className="pt-button btn-cancel"
                    onClick={this.handleClickCancel}
                    disabled={this.state.isLoading}
                  >
                    <span className=" pt-icon icon-back"></span>
                    <span className="text-content">Quay lại</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  staffs: state.common.staffs,

  departments: state.common.departments,

  readOnly: state.common.enableReadOnly,

  attachedUnits: state.common.attachedUnits,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentPage)
