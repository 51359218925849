import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'

import { Progress } from 'components/newCommon2'
import {
  Col,
  Row,
  PrioritizeFlag,
  StatusDate,
  TagGroup,
} from 'components/newCommon'
import { CardProfile } from 'components/newCommon2'
import { ASSIGN_TYPE } from 'constants/Enum'
import TypeIcons from '../type-icon'

const KanbanItem = ({ item, onItemClick, isProcessing, isCompleted }) => {
  const getDeadline = () => {
    return item?.deadline
  }

  return (
    <div
      onClick={() => {
        onItemClick(item)
      }}
    >
      <Row>
        <Col grid="fiveSixth" className={'title-content'}>
          <span>{item?.title}</span>
        </Col>
        <Col grid="oneSixth" className={classNames('text-right', 'p0')}>
          <PrioritizeFlag type={item?.priority} />
        </Col>
      </Row>
      <Row>
        <TagGroup
          className="gap-5 mt5"
          tags={item?.workItemTag?.map(({ name, backgroundColor }) => ({
            name: name || '',
            color: backgroundColor || '',
          }))}
          preChildren={
            <TypeIcons
              isDaLuuTuPhongBan={item?.isDaLuuTuPhongBan}
              workType={item?.workType}
            />
          }
        />
      </Row>
      {isCompleted ? (
        <Row className={classNames('mt5', 'mt10')}>
          <Col className={classNames('p0', 'd-flex', 'align-item-center')}>
            <StatusDate
              date={getDeadline()}
              isCompleted={isCompleted}
              showIcon
              descriptionSize={11}
              descriptionInline
              descriptionFlex
              centerFlex
            />
          </Col>
        </Row>
      ) : null}
      <Row className="align-item-center mt5">
        <Col grid="threeQuarters" className={classNames('p0')}>
          {get(item, 'mainResponsibility.type') === ASSIGN_TYPE.CA_NHAN ? (
            <CardProfile profile={item?.mainResponsibility} />
          ) : (
            <span className="font-size-13">
              {item?.mainResponsibility?.shortName}
            </span>
          )}
        </Col>
        <Col
          grid="quarterSmFull"
          className={classNames('p0', 'd-flex', 'justify-end')}
        >
          {isProcessing && (
            <div style={{ width: 32 }}>
              <Progress progress={get(item, 'progress', 0)} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default KanbanItem
