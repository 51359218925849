import React from 'react'
import { SelectTree } from '../common/'
import { Checkbox } from '@blueprintjs/core'
import { Authorization, Permission } from '../../components/auth'
import classnames from 'classnames'

class ListFilter extends React.Component {
  render() {
    const AuthElement = Authorization.Element
    const isSuperAdmin =
      this.props.auth &&
      Authorization.vaild(this.props.auth.permission, [Permission.SUPERADMIN])
    return (
      <div className="search-container search-group-container admin-notification-search">
        <div className="row seach-form">
          <AuthElement permission={[Permission.SUPERADMIN]}>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 search-header-left-content start-xs">
              <Checkbox
                className="pt-large pt-inline name-radio checkbox-sign-number head-check-content"
                name="canKySo"
                checked={this.props.isCorporation}
                onChange={this.props.onClickCoporation}
                disabled={this.props.isLoadingData}
              >
                <span className="name_checkbox">Tổng công ty</span>
              </Checkbox>
            </div>
          </AuthElement>

          <div
            className={classnames(
              'col-xs-12 search-header-right-content end-xs',
              {
                'col-sm-9 col-md-9 col-lg-9': isSuperAdmin,
                'col-sm-12 col-md-12 col-lg-12': !isSuperAdmin,
              }
            )}
          >
            <div className="search-header-group form-container form-search-basic">
              <div className="select-filter-header">
                <label className="pt-label pt-label-inline">
                  <span className="text-label">Đơn vị</span>
                </label>
                <SelectTree
                  data={this.props.data}
                  type={this.props.type}
                  permission={this.props.permission}
                  value={this.props.donViId}
                  handleChange={this.props.onHandleChange}
                  isLoading={this.props.isLoadingData}
                  isDisabled={
                    this.props.isCorporation === true ||
                    this.props.isLoadingData
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ListFilter
