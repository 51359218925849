import React, { Component } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../helpers'

const PERSONAL = '3'

class ListFilterOld extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  static defaultProps = {
    units: [],
  }

  state = {
    isOpen: false,
    filter: {
      textSearch: '',
      ngayCongVan: {
        from: '',
        to: '',
      },
      ngayTao: {
        from: '',
        to: '',
      },
      noiGuiId: '',
      nguoiTaoId: undefined,
    },
    dateFromToString: '',
    dateToToString: '',
    saveFromToString: '',
    saveToToString: '',
    isShowInput: false,
  }

  componentWillReceiveProps(props) {
    if (props.filter) {
      this.setState({
        filter: {
          textSearch: props.filter.textSearch || '',
          ngayCongVan: {
            from:
              props.filter.ngayCongVan &&
              props.filter.ngayCongVan.from &&
              props.filter.ngayCongVan.from.trim().length > 0
                ? moment(props.filter.ngayCongVan.from)
                : '',
            to:
              props.filter.ngayCongVan &&
              props.filter.ngayCongVan.to &&
              props.filter.ngayCongVan.to.trim().length > 0
                ? moment(props.filter.ngayCongVan.to)
                : '',
          },
          ngayTao: {
            from:
              props.filter.ngayTao &&
              props.filter.ngayTao.from &&
              props.filter.ngayTao.from.trim().length > 0
                ? moment(props.filter.ngayTao.from)
                : '',
            to:
              props.filter.ngayTao &&
              props.filter.ngayTao.to &&
              props.filter.ngayTao.to.trim().length > 0
                ? moment(props.filter.ngayTao.to)
                : '',
          },
          noiGuiId: props.filter.noiGuiId || '',
          noiGui: props.filter.noiGui || '',
          nguoiTaoId: props.filter.nguoiTaoId || '',
        },
        dateFromToString:
          props.filter.ngayCongVan &&
          props.filter.ngayCongVan.from &&
          props.filter.ngayCongVan.from.trim().length > 0
            ? moment(props.filter.ngayCongVan.from)
            : '',
        dateToToString:
          props.filter.ngayCongVan &&
          props.filter.ngayCongVan.to &&
          props.filter.ngayCongVan.to.trim().length > 0
            ? moment(props.filter.ngayCongVan.to)
            : '',
        saveFromToString:
          props.filter.ngayTao &&
          props.filter.ngayTao.from &&
          props.filter.ngayTao.from.trim().length > 0
            ? moment(props.filter.ngayTao.from)
            : '',
        saveToToString:
          props.filter.ngayTao &&
          props.filter.ngayTao.to &&
          props.filter.ngayTao.to.trim().length > 0
            ? moment(props.filter.ngayTao.to)
            : '',
        isShowInput: props.filter.noiGuiId === '#' ? true : false,
      })
    }

    if (!isNaN(props.open)) {
      this.setState({
        isOpen: props.open,
      })
    }
  }

  clearFilter(e) {
    const filter = {
      textSearch: '',
      ngayCongVan: {
        from: '',
        to: '',
      },
      ngayTao: {
        from: '',
        to: '',
      },
      noiGuiId: '',
      nguoiTaoId: '',
    }
    this.setState({
      filter,
      dateFromToString: '',
      dateToToString: '',
      saveFromToString: '',
      saveToToString: '',
    })
    this.props.onSubmitFilter && this.props.onSubmitFilter(filter)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    let isShowInput = false
    let { noiGuiId, noiGui } = this.state.filter

    if (
      (name === 'noiGuiId' && value === '#') ||
      (noiGuiId === '#' && name !== 'noiGuiId')
    ) {
      isShowInput = true
    }

    if (name === 'noiGuiId' && value !== '#') {
      noiGui = ''
    }

    this.setState({
      isShowInput,
      filter: {
        ...this.state.filter,
        noiGui,
        [name]: value,
      },
    })
  }

  handleDateChange(name, pos, nameToString, e) {
    let value = ''
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
      [nameToString]: value,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, pos, nameToString, value) {
    if (
      pos === 'from' &&
      !this.state.filter[name].to &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'from' &&
      this.state.filter[name].to &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: this.state.filter[name].to,
        },
      })
    ) {
      return
    }

    if (
      pos === 'to' &&
      !this.state.filter[name].from &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'to' &&
      this.state.filter[name].from &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: this.state.filter[name].from,
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: undefined,
        },
      },
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    const filter = {
      ...this.state.filter,
      ngayCongVan: {
        from: this.state.filter.ngayCongVan.from
          ? moment(this.state.filter.ngayCongVan.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayCongVan.to
          ? moment(this.state.filter.ngayCongVan.to).format('YYYY-MM-DD')
          : '',
      },
      ngayTao: {
        from: this.state.filter.ngayTao.from
          ? moment(this.state.filter.ngayTao.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayTao.to
          ? moment(this.state.filter.ngayTao.to).format('YYYY-MM-DD')
          : '',
      },
    }

    if (filter.noiGuiId === '#') {
      this.setState({
        isShowInput: true,
      })
    }

    this.props.onSubmitFilter && this.props.onSubmitFilter(filter)
  }

  render() {
    return (
      <div
        className={classnames('search-container search-group-container', {
          'search-ReceiveDocument-other': this.state.isShowInput,
        })}
      >
        <form onSubmit={this.handleSubmit} className="form-search-basic">
          <div className="seach-form">
            <div className="row expanded end-lg">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 search-header-right-content end-xs">
                <div className="search-header-group">
                  <div className="search-keyword">
                    <input
                      autoFocus={false}
                      autoComplete="off"
                      name="textSearch"
                      className="search"
                      type="text"
                      placeholder="Tìm kiếm Số công văn, Tên tài liệu"
                      onChange={this.handleInputChange}
                      value={this.state.filter.textSearch}
                    />
                  </div>
                  <div className="btn-reload-group">
                    <button type="submit" className="pt-button btn-search">
                      <span className="pt-icon pt-icon-search"></span>
                    </button>
                    <Button
                      className="pt-button btn-reload"
                      onClick={this.clearFilter}
                    >
                      <span className="icon-Loading"></span>
                    </Button>
                    <Button
                      className="pt-button btn-toogle"
                      onClick={this.handleClick}
                    >
                      <span
                        className={classnames('pt-icon', {
                          'pt-icon-double-chevron-up': this.state.isOpen,
                          'pt-icon-double-chevron-down': !this.state.isOpen,
                        })}
                      ></span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <Collapse isOpen={this.state.isOpen}>
              <div className="search-advanced">
                <div className="row">
                  <div
                    className={classnames('group-datetime', {
                      'group-datetime-50': this.props.type === PERSONAL,
                    })}
                  >
                    <label className="pt-label label-datepicker">
                      Ngày công văn
                    </label>
                    <div className="daterangepicker-group">
                      <DatePicker
                        isClearable={this.state.dateFromToString ? true : false}
                        readOnly={this.props.readOnly}
                        selected={this.state.filter.ngayCongVan.from}
                        selectsStart
                        startDate={this.state.filter.ngayCongVan.from}
                        endDate={this.state.filter.ngayCongVan.to}
                        onChange={this.handleDateChange.bind(
                          this,
                          'ngayCongVan',
                          'from',
                          'dateFromToString'
                        )}
                        value={this.state.dateFromToString}
                        onChangeRaw={e =>
                          this.handleChangeRaw(
                            'dateFromToString',
                            e.target.value
                          )
                        }
                        onBlur={e =>
                          this.focousOut(
                            'ngayCongVan',
                            'from',
                            'dateFromToString',
                            e.target.value
                          )
                        }
                        format="DD/MM/YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="input-datepicker input-icon-datepicker"
                        placeholderText="Từ ngày"
                        maxDate={this.state.filter.ngayCongVan.to}
                        popperPlacement="auto"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                      <DatePicker
                        isClearable={this.state.dateToToString ? true : false}
                        readOnly={this.props.readOnly}
                        selected={this.state.filter.ngayCongVan.to}
                        selectsEnd
                        startDate={this.state.filter.ngayCongVan.from}
                        endDate={this.state.filter.ngayCongVan.to}
                        onChange={this.handleDateChange.bind(
                          this,
                          'ngayCongVan',
                          'to',
                          'dateToToString'
                        )}
                        value={this.state.dateToToString}
                        onChangeRaw={e =>
                          this.handleChangeRaw('dateToToString', e.target.value)
                        }
                        onBlur={e =>
                          this.focousOut(
                            'ngayCongVan',
                            'to',
                            'dateToToString',
                            e.target.value
                          )
                        }
                        format="DD/MM/YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="input-datepicker input-icon-datepicker"
                        placeholderText="Đến ngày"
                        minDate={this.state.filter.ngayCongVan.from}
                        popperPlacement="auto"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={classnames('group-datetime', {
                      'group-datetime-50': this.props.type === PERSONAL,
                    })}
                  >
                    <label className="pt-label label-datepicker">
                      Lưu công văn
                    </label>
                    <div className="daterangepicker-group">
                      <DatePicker
                        isClearable={this.state.saveFromToString ? true : false}
                        readOnly={this.props.readOnly}
                        selected={this.state.filter.ngayTao.from}
                        selectsStart
                        startDate={this.state.filter.ngayTao.from}
                        endDate={this.state.filter.ngayTao.to}
                        onChange={this.handleDateChange.bind(
                          this,
                          'ngayTao',
                          'from',
                          'saveFromToString'
                        )}
                        value={this.state.saveFromToString}
                        onChangeRaw={e =>
                          this.handleChangeRaw(
                            'saveFromToString',
                            e.target.value
                          )
                        }
                        onBlur={e =>
                          this.focousOut(
                            'ngayTao',
                            'from',
                            'saveFromToString',
                            e.target.value
                          )
                        }
                        format="DD/MM/YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="input-datepicker input-icon-datepicker"
                        placeholderText="Từ ngày"
                        maxDate={this.state.filter.ngayTao.to}
                        popperPlacement="auto"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                      <DatePicker
                        isClearable={this.state.saveToToString ? true : false}
                        readOnly={this.props.readOnly}
                        selected={this.state.filter.ngayTao.to}
                        selectsEnd
                        startDate={this.state.filter.ngayTao.from}
                        endDate={this.state.filter.ngayTao.to}
                        onChange={this.handleDateChange.bind(
                          this,
                          'ngayTao',
                          'to',
                          'saveToToString'
                        )}
                        value={this.state.saveToToString}
                        onChangeRaw={e =>
                          this.handleChangeRaw('saveToToString', e.target.value)
                        }
                        onBlur={e =>
                          this.focousOut(
                            'ngayTao',
                            'to',
                            'saveToToString',
                            e.target.value
                          )
                        }
                        format="DD/MM/YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="input-datepicker input-icon-datepicker"
                        placeholderText="Đến ngày"
                        minDate={this.state.filter.ngayTao.from}
                        popperPlacement="auto"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </div>
                  </div>
                  {this.props.type !== PERSONAL && (
                    <div className="group-select">
                      <label className="pt-label">
                        Cơ quan ban hành
                        <div className="pt-select">
                          <select
                            name="noiGuiId"
                            onChange={this.handleInputChange}
                            value={this.state.filter.noiGuiId}
                          >
                            <option value="">Tất cả</option>
                            {this.props.units.map((item, i) => (
                              <option key={i} value={item.donViId}>
                                {item.tenVietTat}
                              </option>
                            ))}
                            <option value="#">Khác</option>
                          </select>
                        </div>
                      </label>
                    </div>
                  )}
                  {this.state.isShowInput && (
                    <div className="input-element-other">
                      <label className="pt-label">
                        <span className="emty-nbsp">&nbsp;</span>
                        <div className="pt-form-content">
                          <input
                            name="noiGui"
                            className="pt-input"
                            placeholder="Nhập cơ quan ban hành khác..."
                            type="text"
                            dir="auto"
                            value={
                              this.state.filter && this.state.filter.noiGui
                                ? this.state.filter.noiGui
                                : ''
                            }
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </label>
                    </div>
                  )}
                  {this.props.type !== PERSONAL && (
                    <div className="group-select">
                      <label className="pt-label">
                        Người lưu
                        <div className="pt-select">
                          <select
                            name="nguoiTaoId"
                            onChange={this.handleInputChange}
                            value={this.state.filter.nguoiTaoId}
                          >
                            <option value="">Tất cả</option>
                            {this.props.staffs.map((item, i) => (
                              <option key={i} value={item.chucDanhId}>
                                {item.tenNhanVien}
                              </option>
                            ))}
                          </select>
                        </div>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </Collapse>
          </div>
        </form>
      </div>
    )
  }
}

export default ListFilterOld
