import { schema } from 'normalizr'

const InternalDocument = new schema.Entity(
  'internalDocuments',
  {},
  {
    idAttribute: internalDocument => internalDocument.congVanNoiBoId,
  }
)

export default InternalDocument
