import { useMemo, useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import size from 'lodash/size'

import requestAction from 'helpers/request'
import {
  saveTree2,
  getMeeting,
  getPhongBanDonVi,
  getWorksMeeting,
} from 'actions/task'

const useTaskThongbaoKLCHDetail = ({
  match,
  mainUnitId,
  hasShowAllDeparment,
}) => {
  const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
  const dsLoaiCuocHop = [
    {
      label: 'Giao ban tuần',
      value: 'WEEKLY_DELIVERY',
    },
    {
      label: 'Sơ kết/Tổng kết',
      value: 'SUMMARY',
    },
    {
      label: 'Họp tại đơn vị',
      value: 'MEETING_AT_ORGANIZATION',
    },
    {
      label: 'Khác',
      value: 'OTHER',
    },
  ]
  const filterKeys = useMemo(() => {
    return {
      title: 'Title',
      chuTri: 'MainResponsibilityId',
      phoiHop: 'CoordinationId',
      ngayBatDau: 'ExecutionDate',
      hanThucHien: 'Deadline',
      trangThai: 'Status',
      trangThaiDuyet: 'Approval',
    }
  }, [])
  const id = match.params?.id
  const [fetching, setFetching] = useState(true)
  const [fetchingFail, setFetchingFail] = useState(false)
  const [dataDetailMetting, setDataDetailMetting] = useState({})
  const [fetchingDetail, setFetchingDetail] = useState(true)
  const [filter, setFilter] = useState()
  const [dataWorksMetting, setDataWorksMetting] = useState(null)
  const [page, setPage] = useState(1)
  const [deleteData, setDeleteData] = useState(null)

  const fetchList = useCallback(
    (page = 1, params = null) => {
      const newParams = {
        [filterKeys.title]: params?.[filterKeys.title]
          ? params?.[filterKeys.title]?.trim()
          : undefined,
        [filterKeys.chuTri]:
          params?.[filterKeys.chuTri] &&
          params?.[filterKeys.chuTri]?.code !== 'ALL'
            ? params?.[filterKeys.chuTri]?.id
            : undefined,
        [filterKeys.phoiHop]:
          params?.[filterKeys.phoiHop] &&
          params?.[filterKeys.phoiHop]?.code !== 'ALL'
            ? params?.[filterKeys.phoiHop]?.id
            : undefined,
        [filterKeys.ngayBatDau]:
          params?.[filterKeys.ngayBatDau] &&
          moment(params?.[filterKeys.ngayBatDau]).isValid()
            ? moment(params?.[filterKeys.ngayBatDau]).format(DATETIME_FORMAT)
            : undefined,
        [filterKeys.hanThucHien]:
          params?.[filterKeys.hanThucHien] &&
          moment(params?.[filterKeys.hanThucHien]).isValid()
            ? moment(params?.[filterKeys.hanThucHien]).format(DATETIME_FORMAT)
            : undefined,
        [filterKeys.trangThai]:
          params?.[filterKeys.trangThai] &&
          params?.[filterKeys.trangThai] !== 'ALL'
            ? params?.[filterKeys.trangThai]
            : undefined,
        [filterKeys.trangThaiDuyet]:
          params?.[filterKeys.trangThaiDuyet] &&
          params?.[filterKeys.trangThaiDuyet] !== 'ALL'
            ? params?.[filterKeys.trangThaiDuyet]
            : undefined,
      }
      requestAction({
        codeCheck: false,
        showToast: false,
        getResult: true,
        beforeAction: () => {
          setFetchingFail(false)
          setFetching(true)
        },
        action: () =>
          getWorksMeeting(id, {
            ...newParams,
            page: page,
            limit: 10,
          }),
        afterResponse: response => {
          setPage(page)
          setFilter(params)
          setDataWorksMetting(response)
        },
        afterAction: () => setFetching(false),
        afterError: () => setFetchingFail(true),
      })
    },
    [id, filterKeys]
  )

  const onChangeFilter =
    ({ name, isReFetch = true, isMultipleKey = false }) =>
    value => {
      let newFilter = { ...filter }
      if (name === 'RESET') {
        newFilter = {}
      } else if (name === 'PAGE') {
        return fetchList(value, newFilter)
      } else if (isMultipleKey) {
        newFilter = { ...newFilter, ...value }
      } else {
        newFilter = { ...newFilter, [name]: value }
      }

      setFilter(newFilter)
      if (isReFetch) {
        fetchList(1, newFilter)
      }
    }

  const fetchDetail = useCallback(() => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      beforeAction: () => setFetchingDetail(true),
      action: () => getMeeting(id),
      afterResponse: response => {
        setDataDetailMetting(response || {})
      },
      afterAction: () => setFetchingDetail(false),
    })
  }, [id])

  const fetchDepartmentsUnit = useCallback(() => {
    const convertType = type => {
      if (type === 'PB') {
        return 'DEPARTMENT'
      } else if (type === 'DV') {
        return 'ORGANIZATION'
      }

      return 'PERSONAL'
    }

    requestAction({
      showToast: false,
      successCode: 201 || 200,
      action: () =>
        getPhongBanDonVi(mainUnitId, { phongBanLanhDao: false, limit: 9999 }),
      afterResponse: response => {
        const departmentData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'PB')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -1,
              children: [],
            })) || []
        const organizationData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'DV')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -2,
              children: [],
            })) || []
        const newData = [
          ...(size(departmentData) !== 0
            ? [
                {
                  id: -1,
                  type: 'ALL',
                  name: 'Phòng ban',
                  code: 'Phòng ban',
                  parentId: null,
                  children: departmentData,
                },
              ]
            : []),
          ...(size(organizationData) !== 0
            ? [
                {
                  id: -2,
                  type: 'ALL',
                  name: 'PC/ Đơn vị',
                  code: 'PC/ Đơn vị',
                  parentId: null,
                  children: organizationData,
                },
              ]
            : []),
        ]
        requestAction({
          showToast: false,
          successCode: 0,
          action: () => saveTree2(newData),
        })
      },
    })
  }, [mainUnitId])

  useEffect(() => {
    if (hasShowAllDeparment) {
      fetchDepartmentsUnit()
    }
  }, [fetchDepartmentsUnit, hasShowAllDeparment])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  return {
    filterKeys,
    dsLoaiCuocHop,
    fetchingDetail,
    dataDetailMetting,
    fetching,
    fetchingFail,
    filter,
    page,
    dataWorksMetting,
    deleteData,
    setDeleteData,
    onChangeFilter,
  }
}

export default useTaskThongbaoKLCHDetail
