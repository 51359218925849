import React, { Component } from 'react'
import classnames from 'classnames'
import * as Tool from '../../helpers/'

class DropdownEvn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowDropdown: false,
    }
  }

  showDropdown = e => {
    e.stopPropagation()
    this.setState({ isShowDropdown: !this.state.isShowDropdown })
  }

  componentDidMount() {
    this.dropdown.addEventListener('click', this.showDropdown)
    //click anywwhere close dropdown file
    document.addEventListener('click', () =>
      this.setState({ isShowDropdown: false })
    )
  }

  render() {
    let extension = this.props.selectedFileName
      .slice(((this.props.selectedFileName.lastIndexOf('.') - 1) >>> 0) + 2)
      .toLowerCase()
    return (
      <div className="pt-form-content">
        <div className="pt-select">
          <div
            ref={el => (this.dropdown = el)}
            className={classnames('select', {
              'loading-input disabled': this.props.isLoadingFileEvn,
              focus: this.state.isShowDropdown,
            })}
            onClick={this.showDropdown}
            style={{
              backgroundImage: `url(${Tool.Utils.typeFile(extension)})`,
            }}
          >
            <p className="file-name">
              <span className="name">
                {this.props.selectedFileName.slice(
                  0,
                  this.props.selectedFileName.lastIndexOf('.')
                )}
              </span>
              <span className="extention">
                .{Tool.Utils.getExtensionFile(this.props.selectedFileName)}
              </span>
            </p>
          </div>

          <div
            style={!this.state.isShowDropdown ? { display: 'none' } : null}
            className="custom-dropdown"
          >
            {this.props.listFile.map((item, i) => {
              let extensionTK = item.tenFile
                .slice(((item.tenFile.lastIndexOf('.') - 1) >>> 0) + 2)
                .toLowerCase()
              return (
                <div
                  style={{
                    backgroundImage: `url(${Tool.Utils.typeFile(extensionTK)})`,
                  }}
                  className={classnames('custom-dropdown-item', {
                    choosen:
                      item.fileId.toString() ===
                      this.props.selectedFileId.toString(),
                  })}
                  key={i}
                  file={item.fileId}
                  onClick={this.props.handleChangeFile}
                >
                  {item.tenFile}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default DropdownEvn
