import React from 'react'
import _ from 'lodash'
import arrayToTree from 'array-to-tree'
import { Scrollbars } from 'react-custom-scrollbars'
import { Radio } from '@blueprintjs/core'

class CategoryDrawer extends React.Component {
  static defaultProps = {
    categories: [],
    disableIds: [],
  }

  state = {
    checked: [],
    isOpen: {},
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleCheckCategories = this.handleCheckCategories.bind(this)
    this.handleOpenDrawer = this.handleOpenDrawer.bind(this)
  }

  handleOpenDrawer(data) {
    data.forEach(async item => {
      if (item.children && this.props.disableIds.indexOf(item.id) === -1) {
        await this.setState({
          isOpen: {
            ...this.state.isOpen,
            [item.id]: true,
          },
        })
        this.handleOpenDrawer(item.children)
      }
    })
  }

  componentWillReceiveProps(props) {
    if (props.categories !== this.props.categories && !this.props.isLoading) {
      const categories = arrayToTree(props.categories, {
        parentProperty: 'nganTuChaId',
      })
      this.handleOpenDrawer(categories)
    }
  }

  handleClick = (id, e) => {
    e.preventDefault()
    if (this.props.disableIds.indexOf(id) === -1) {
      this.setState({
        isOpen: {
          ...this.state.isOpen,
          [id]: !this.state.isOpen[id],
        },
      })
    }
  }

  handleCheckCategories(e) {
    let { checked } = this.state

    if (e.target.checked) {
      this.setState({ checked: [e.target.value] }, () => {
        this.props.onCheckCategory &&
          this.props.onCheckCategory(this.state.checked)
      })
    } else {
      if (checked.indexOf(e.target.value) !== -1) {
        delete checked[checked.indexOf(e.target.value)]
        this.setState(
          {
            checked: _.compact(checked),
          },
          () => {
            this.props.onCheckCategory &&
              this.props.onCheckCategory(this.state.checked)
          }
        )
      }
    }
  }

  renderSubItem(items) {
    return (
      <ul className="sublist">
        {items &&
          items.map(item => {
            if (item.children) {
              item.dsNganTuCon = item.children
            }
            return (
              <li className="subitem" key={item.id}>
                <div className="main-content">
                  {this.props.disableIds.indexOf(item.id) === -1 &&
                    item.dsNganTuCon && (
                      <span
                        className="dropdown-left"
                        onClick={this.handleClick.bind(this, item.id)}
                      >
                        {this.state.isOpen[item.id] && (
                          <span className="pt-icon pt-icon-chevron-up"></span>
                        )}
                        {!this.state.isOpen[item.id] && (
                          <span className="cpc-icon-left icon-arrow-dropdown"></span>
                        )}
                      </span>
                    )}
                  {this.props.mutilSelect && (
                    <span className="text-link">
                      <Radio
                        label={item.tenNganTu}
                        value={item.id}
                        onChange={this.handleCheckCategories}
                        checked={this.state.checked.indexOf(item.id) !== -1}
                        disabled={this.props.disableIds.indexOf(item.id) !== -1}
                      />
                    </span>
                  )}
                </div>
                {this.state.isOpen[item.id] &&
                  item.dsNganTuCon &&
                  this.renderSubItem(item.dsNganTuCon)}
              </li>
            )
          })}
      </ul>
    )
  }

  render() {
    const categories = arrayToTree(this.props.categories, {
      parentProperty: 'nganTuChaId',
    })
    return (
      <section className="aside-nav">
        <div className="aside-main-content">
          <div className="aside-list-content">
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={600}>
              <ul className="departments-list departments-action-list">
                {categories.map((item, k) => {
                  if (item.children) {
                    item.dsNganTuCon = item.children
                  }
                  return (
                    <li className="item-list" key={k}>
                      <div className="main-content">
                        {this.props.disableIds.indexOf(item.id) === -1 &&
                          item.dsNganTuCon && (
                            <span
                              className="dropdown-left"
                              onClick={this.handleClick.bind(this, item.id)}
                            >
                              {this.state.isOpen[item.id] && (
                                <span className="pt-icon pt-icon-chevron-up"></span>
                              )}
                              {!this.state.isOpen[item.id] && (
                                <span className="cpc-icon-left icon-arrow-dropdown"></span>
                              )}
                            </span>
                          )}
                        {this.props.mutilSelect && (
                          <span className="text-link">
                            <Radio
                              label={item.tenNganTu}
                              value={item.id}
                              onChange={this.handleCheckCategories}
                              checked={
                                this.state.checked.indexOf(item.id) !== -1
                              }
                              disabled={
                                this.props.disableIds.indexOf(item.id) !== -1
                              }
                            />
                          </span>
                        )}
                      </div>
                      {this.state.isOpen[item.id] &&
                        item.dsNganTuCon &&
                        this.renderSubItem(item.dsNganTuCon)}
                    </li>
                  )
                })}
                {categories.length > 0 && (
                  <li className="item-list">
                    <div className="main-content">
                      <span className="text-link">
                        <Radio
                          className="text-link-drawer"
                          label={'Chuyển đến ngăn ngoài cùng'}
                          value=""
                          checked={this.state.checked.indexOf('') !== -1}
                          onChange={this.handleCheckCategories}
                        />
                      </span>
                    </div>
                  </li>
                )}
              </ul>
            </Scrollbars>
          </div>
        </div>
      </section>
    )
  }
}

export default CategoryDrawer
