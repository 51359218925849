import React from 'react'
import {
  PieChart as PChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts'
import size from 'lodash/size'
import { Empty } from 'components/newCommon'

const PieChart = ({
  history,
  className = '',
  width = '100%',
  height = 140,
  data = [],
}) => {
  const goPath = path => {
    return history.push(path)
  }

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const percentNum = percent * 100
    const intNum = Math.floor(percentNum)
    const toFixedNum = percentNum.toFixed(1)
    const surplusNum = toFixedNum - intNum
    let dd = percentNum.toFixed(0)
    if (surplusNum < 1 && surplusNum !== 0.0) {
      dd = percentNum.toFixed(1)
    }

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={'middle'}
        dominantBaseline="central"
      >
        {`${dd}%`}
      </text>
    )
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white border-secondary p10">
          <p className="label no-wrap">{`${payload?.[0]?.payload.name} : ${payload?.[0]?.value}`}</p>
        </div>
      )
    }

    return null
  }

  const onClickPieChart = value => {
    return goPath(value?.path)
  }

  return (
    <div>
      {size(data) !== 0 && data?.some(e => e?.value !== 0) ? (
        <div className="pt5">
          <ResponsiveContainer
            className={className}
            width={width}
            height={height}
          >
            <PChart cursor="pointer">
              <Tooltip content={<CustomTooltip />} />
              <Pie
                data={data?.filter(e => e.value)}
                cx="50%"
                cy="50%"
                animationBegin={100}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={height / 2}
                fill="#8884d8"
                dataKey="value"
                onClick={onClickPieChart}
              >
                {data
                  ?.filter(e => e.value)
                  ?.map((entry, index) => (
                    <Cell key={index} fill={entry?.color} />
                  ))}
              </Pie>
            </PChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Empty message="Chưa có dữ liệu" type="ROUND" className="mt5" />
      )}
    </div>
  )
}

export default PieChart
