import {
  SET_DO_TOKEN,
  SET_DO_PHONGBAN_NOIBO,
  GET_DOCS_LIST_DO,
  GET_PHONGBAN_LIST_DO,
  GET_DONVI_LIST_DO,
  GET_SOVB_DO,
  GET_PHONGBANXULY_DO,
  GET_LANHDAOCHIDAO_DO,
  GET_CHUYENVIENXULY_DO,
  GET_DANHSACHCONGVAN_DO,
  GET_CHITIETVANBANSOHOA_DO,
  GET_CHITIETVANBANSOHOA_DO_RESET,
  GET_LISTVANBAN_DO,
  GET_LIST_DONVI_VANBAN_DO,
  RESET_DO,
  GET_LIST_QUAN_HE_DONVI_VANBAN_DO,
  GET_LIST_QUAN_HE_DONVI_VANBAN_DO_RESET,
} from 'constants/ActionTypes'

const initialState = {
  toKenDO: null,
  phongBanNoiBoDO: [],
  listCongVan: [],
  listPhongBan: [],
  listDonVi: [],
  listLanhDao: [],
  listPhongBanXuLy: [],
  listSoVanBan: [],
  listChuyenVienXuLy: [],
  danhSachCongVan: [],
  chiTietVanBan: null,
  listVanBan: null,
  listDonViVanBanDO: [],
}

export default function doffice(state = initialState, action) {
  const type = action.type
  switch (type) {
    case SET_DO_TOKEN:
      return {
        ...state,
        toKenDO: action?.payload?.data?.result || null,
      }
    case SET_DO_PHONGBAN_NOIBO:
      const result = action?.payload?.data?.result?.items || []
      return {
        ...state,
        phongBanNoiBoDO: result,
      }
    case GET_DOCS_LIST_DO:
      return {
        ...state,
        listCongVan: action?.payload?.data?.data || [],
      }
    case GET_PHONGBAN_LIST_DO:
      return {
        ...state,
        listPhongBan: action?.payload?.data?.data || [],
      }
    case GET_DONVI_LIST_DO:
      return {
        ...state,
        listDonVi: action?.payload?.data?.data || [],
      }
    case GET_SOVB_DO:
      return {
        ...state,
        listSoVanBan: action?.payload?.data?.data || [],
      }
    case GET_PHONGBANXULY_DO:
      return {
        ...state,
        listPhongBanXuLy: action?.payload?.data?.data || [],
      }
    case GET_LANHDAOCHIDAO_DO:
      return {
        ...state,
        listLanhDao: action?.payload?.data?.data || [],
      }
    case GET_CHUYENVIENXULY_DO:
      return {
        ...state,
        listChuyenVienXuLy: action?.payload?.data?.data || [],
      }
    case GET_DANHSACHCONGVAN_DO:
      return {
        ...state,
        danhSachCongVan: action?.payload?.data?.data || [],
      }
    case GET_CHITIETVANBANSOHOA_DO:
      return {
        ...state,
        chiTietVanBan: action?.payload?.data?.data || null,
      }
    case GET_CHITIETVANBANSOHOA_DO_RESET:
      return {
        ...state,
        chiTietVanBan: null,
      }
    case GET_LISTVANBAN_DO:
      return {
        ...state,
        listVanBan: action?.payload?.data?.data || [],
      }
    case GET_LIST_DONVI_VANBAN_DO:
      return {
        ...state,
        listDonViVanBanDO:
          action?.payload?.data?.data?.qHVANBANDONVIVIEWMODAL || [],
      }
    case GET_LIST_QUAN_HE_DONVI_VANBAN_DO:
      return {
        ...state,
        listDonViVanBanDO: action?.payload?.data?.result?.items || [],
      }
    case GET_LIST_QUAN_HE_DONVI_VANBAN_DO_RESET:
      return {
        ...state,
        listDonViVanBanDO: [],
      }
    case RESET_DO:
      return {
        ...state,
        listDonViVanBanDO: [],
      }
    default:
      return state
  }
}
