import React from 'react'
import { MasterLayout } from '../../components/layout'
import { FormAddSubjectsCategory } from '../../components/System'
import { HeadingPage } from '../../components/common'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'

const SUA_DOI_TUONG = 'Sửa đối tượng'
const THEM_MOI_DOI_TUONG = 'Thêm mới đối tượng'
class AddSubjectsCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    doiTuongCongVanId: this.props.match.params.id,
    objects: null,
    unitSelect: [],
  }

  componentDidMount() {
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined

    if (this.props.match.params && this.props.match.params.id) {
      this.props.actions.getCommonFetchObjectItem(this.state.doiTuongCongVanId)
    }

    if (permission) {
      this.props.actions.getCategoryUnitMultiLevel()
    } else {
      this.props.actions.getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.objects.item !== this.props.objects.item) {
      this.setState({ objects: nextProps.objects.item })
    }

    if (nextProps.unitSelect.items !== this.props.unitSelect.items) {
      this.setState({ unitSelect: nextProps.unitSelect.items })
    }
  }

  handleSubmit(isSuccess = false) {
    if (isSuccess === true) {
      this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    return this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={this.state.objects ? SUA_DOI_TUONG : THEM_MOI_DOI_TUONG}
              iconPage="icon-Van_Ban_Tra_Loi"
            />
            <FormAddSubjectsCategory
              object={this.state.objects}
              actions={this.props.actions}
              unitSelect={this.state.unitSelect}
              auth={this.props.auth}
              onSubmit={this.handleSubmit}
              match={this.props.match}
              history={this.props.history}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  objects: {
    ...state.objectItem,
    item: state.common.objectItem,
  },

  auth: {
    ...state.auth,
    user: state.auth,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSubjectsCategoryPage)
