import React, { memo } from 'react'
import { Tag, Intent } from '@blueprintjs/core'
import csx from 'classnames'

const Badge = ({
  count = 0,
  showZero = false,
  overflowCount = 99,
  className,
  type = 'number',
  small,
}) => {
  if (!showZero && count === 0) {
    return
  }

  const isDot = type === 'dot'

  return (
    <Tag
      className={csx(
        'cpc-badge',
        {
          dot: isDot,
          small: small,
        },
        className
      )}
      intent={Intent.DANGER}
    >
      {!isDot && (count > overflowCount ? `${overflowCount}+` : count)}
    </Tag>
  )
}

export default memo(Badge)
