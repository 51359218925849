import React, { memo, useCallback, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Radio } from '@blueprintjs/core'
import { get } from 'lodash'
import moment from 'moment'

import { UNSET, WORK_REMINDER } from 'constants/Enum'
import { getCongViec, patchReminder } from 'actions/task'
import { Icon, DatePicker } from 'components/newCommon'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'

const Reminder = ({ dataSource, onClose, onSuccess }) => {
  const id = dataSource?.id
  const dateTimeRequest = 'YYYY-MM-DDTHH:mm:ss'
  const DEFAULT_SELECTED = useMemo(() => {
    return {
      type: UNSET,
      date: null,
    }
  }, [])
  const selectData = [
    { value: UNSET, label: 'Không nhắc' },
    { value: WORK_REMINDER.TRUOC_MOT_NGAY, label: 'Trước 1 ngày' },
    { value: WORK_REMINDER.TRUOC_HAI_NGAY, label: 'Trước 2 ngày' },
    { value: WORK_REMINDER.TRUOC_BA_NGAY, label: 'Trước 3 ngày' },
  ]
  const typeToNumber = {
    [WORK_REMINDER.TRUOC_MOT_NGAY]: 1,
    [WORK_REMINDER.TRUOC_HAI_NGAY]: 2,
    [WORK_REMINDER.TRUOC_BA_NGAY]: 3,
  }

  const [isDisabledDefault, setIsDisabledDefault] = useState(true)
  const [isFetch, setIsFetch] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [selected, setSelected] = useState(DEFAULT_SELECTED)
  const [deadline, setDeadline] = useState()

  const selectDisabled = value => {
    if (value === UNSET) {
      return false
    }

    if (!deadline) {
      return true
    }

    if (
      moment(deadline).subtract(typeToNumber[value], 'days') <
      moment().endOf('d')
    ) {
      return true
    }

    return false
  }

  const onSelect = value => () => {
    setIsDisabledDefault(false)
    const newSelected = {
      type: value,
      date: null,
    }
    if (value !== UNSET) {
      newSelected.date = moment(deadline)
        .subtract(typeToNumber[value], 'days')
        .set('hour', 7)
        .set('minute', 0)
    }

    setSelected(newSelected)
  }

  const changeDate = date => {
    setIsDisabledDefault(false)
    setSelected(prev => ({
      type: date ? WORK_REMINDER.TUY_CHINH : prev.type,
      date,
    }))
  }

  const submit = useCallback(() => {
    let params =
      selected.type !== UNSET
        ? {
            type: selected.type,
            reminder_time: moment(selected.date).format(dateTimeRequest),
          }
        : {
            type: WORK_REMINDER.KHONG_NHAC,
          }
    requestAction({
      beforeAction: () => setIsSubmit(true),
      action: () => patchReminder(id, params),
      afterResponse: () => {
        onSuccess()
        return onClose()
      },
      afterAction: () => {
        setIsSubmit(false)
        setIsDisabledDefault(true)
      },
      successMessage: Toast.SUCCESS(Action.SAVE),
      errorMessage: Toast.FAIL(Action.SAVE),
    })
  }, [id, selected, onClose, onSuccess])

  const fetchDetail = useCallback(() => {
    const getDeadline = detail => {
      return (
        get(
          get(detail, 'responsibilities[0].workAssignments', [])
            .filter(i => i.deadline)
            .slice(-1)[0],
          'deadline'
        ) || detail.deadline
      )
    }

    requestAction({
      showToast: false,
      beforeAction: () => setIsFetch(true),
      action: () => getCongViec(id),
      afterResponse: detail => {
        setDeadline(getDeadline(detail))
        setSelected(
          detail?.workReminder
            ? {
                type:
                  detail?.workReminder?.type === WORK_REMINDER.KHONG_NHAC
                    ? UNSET
                    : detail?.workReminder?.type,
                date: detail?.workReminder?.reminderTime
                  ? moment(detail.workReminder.reminderTime)
                  : null,
              }
            : DEFAULT_SELECTED
        )
      },
      afterAction: () => setIsFetch(false),
    })
  }, [id, DEFAULT_SELECTED])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  return (
    <div className={'cpc-form'}>
      <h1 className="d-flex justify-space-between align-center border-bottom ph14 pv15">
        <span
          className="icon2-arrow-dart-left font-size-14 cursor-pointer"
          onClick={onClose}
        ></span>
        <span className="text-13-500 text-uppercase color-gray">Nhắc việc</span>
        <span></span>
      </h1>
      <div style={{ padding: 10 }} className={'cpc-list-item'}>
        {selectData.map(({ value, label }) => (
          <Radio
            key={value}
            className={'cpc-radio ph10'}
            disabled={selectDisabled(value)}
            checked={selected?.type === value}
            onChange={onSelect(value)}
            label={label}
          />
        ))}
      </div>
      <div style={{ padding: 10 }} className="border-top">
        <DatePicker
          placeholder="Tùy chỉnh"
          popperPlacement={'top-start'}
          dateFormat="DD/MM/YYYY H:mm"
          timeInputLabel="Giờ:"
          showTimeSelect={true}
          timeIntervals={30}
          timeFormat="HH:mm"
          isClearable={false}
          selected={
            selected?.type === WORK_REMINDER.TUY_CHINH
              ? selected?.date
              : undefined
          }
          minDate={moment()}
          maxDate={deadline ? moment(deadline) : undefined}
          disabled={isSubmit}
          onChange={changeDate}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center gap-15 border-top ph15">
        <Button
          className={
            'cpc-button white-border-gray uppercase font-size-13 ph10 min-width-100'
          }
          disabled={isSubmit}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={'cpc-button blue uppercase font-size-13 ph10 min-width-80'}
          loading={isSubmit}
          disabled={isFetch || isSubmit || isDisabledDefault}
          onClick={submit}
        >
          Lưu
          <Icon classIcon="icon-save" className="ml5" />
        </Button>
      </div>
    </div>
  )
}

Reminder.defaultProps = {
  dataSource: null,
  onClose: () => {},
  onSuccess: () => {},
}

Reminder.propTypes = {
  dataSource: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default memo(Reminder)
