import React from 'react'
class GroupMenuSideBar extends React.Component {
  render() {
    return (
      <li className={this.props.styleClass}>
        <span className="title">
          <span>{this.props.name}</span>
          {!!this.props.isNewFeature && (
            <span className="new-feature-badge">
              <i className="icon-new-feature" />
            </span>
          )}
        </span>
        {this.props.children}
      </li>
    )
  }
}
export default GroupMenuSideBar
