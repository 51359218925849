import React, { memo } from 'react'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import csx from 'classnames'
import { size } from 'lodash'

import Tag from './Tag'

const TagGroup = ({
  className = '',
  defaultActiveAll = true,
  activeTags = [],
  activeKey = 'id',
  tags = [],
  limit = 3,
  popoverPosition = Position.BOTTOM_LEFT,
  popoverClassName,
  preChildren,
  onClick,
}) => {
  const showTags = tags.slice(0, limit)
  const hideTags = tags.slice(limit)

  const checkActive = ({ [activeKey]: key }) => {
    return size(activeTags) ? activeTags.includes(key) : defaultActiveAll
  }

  return (
    <div className={csx('cpc-label-group', className)}>
      {preChildren ? preChildren : ''}
      {showTags.map((item, index) => (
        <Tag
          key={index}
          {...item}
          onClick={() => onClick && onClick(item)}
          active={checkActive(item)}
        />
      ))}

      {!!size(hideTags) && (
        <Popover
          content={
            <div
              style={{
                padding: 12,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {hideTags.map((item, index) => (
                <Tag
                  key={index}
                  {...item}
                  active={checkActive(item)}
                  onClick={() => onClick && onClick(item)}
                />
              ))}
            </div>
          }
          interactionKind={PopoverInteractionKind.HOVER}
          popoverClassName={csx(
            'cpc-popover',
            'no-arrow',
            'label-group-popover',
            popoverClassName
          )}
          position={popoverPosition}
        >
          <Tag name="..." />
        </Popover>
      )}
    </div>
  )
}

export default memo(TagGroup)
