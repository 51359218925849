import { Action, Toast, inProcess } from '../../../constants/MessageForm'
import { Checkbox, Intent } from '@blueprintjs/core'
import {
  EmptyDataTable,
  ErrorLoadingTable,
  HeadingTableSort,
  LoadingTable,
  PopupConfirm,
} from '../../../components/common'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc'
import {
  TEXT_BUTTON_SUBMIT,
  TEXT_SUBMIT_BODY,
  XOA_DANH_MUC,
} from '../../../constants/Popup'

import { Link } from 'react-router-dom'
import React from 'react'
import _ from 'lodash'
import { headers } from '../../../constants/Table'

const EDIT_PATH = '/he-thong/danh-muc-quy-trinh/chinh-sua'
class ProcedureCategoryList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      isDataEmpty: false,
      isOpenPopup: false,
      deleteItem: null,
      listDeleteId: [],
      listUpdateId: [],
    }
  }

  setDeleteItem = (item = null) => {
    this.setState({
      deleteItem: item,
    })
  }

  togglePopup = (item = null) => {
    const { showToast } = this.props
    const { isOpenPopup, listDeleteId } = this.state

    if (!isOpenPopup && !!item) {
      if (!listDeleteId.includes(item.id)) {
        this.setDeleteItem(item)
        return this.setState({
          isOpenPopup: !isOpenPopup,
        })
      } else {
        return showToast({
          message: inProcess,
          intent: Intent.WARNING,
        })
      }
    }
    this.setState({
      isOpenPopup: !isOpenPopup,
    })
  }

  toggleUsing = async item => {
    const { toggleUsing } = this.props
    const { listUpdateId } = this.state
    const isProcessing = listUpdateId.includes(item.id)
    if (!isProcessing) {
      await this.setState({
        listUpdateId: [...listUpdateId, item.id],
      })
      await toggleUsing(item)
      this.setState({
        listUpdateId: this.state.listUpdateId.filter(i => i !== item.id),
      })
    }
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { actionSort, showToast } = this.props
    const { data } = this.state
    if (oldIndex !== newIndex) {
      await this.setState({
        data: arrayMove(data, oldIndex, newIndex),
      })
      const sortList = this.state.data.map(item => item.id)
      actionSort(sortList).then(res => {
        const status = !!_.get(res, 'payload.data.result', false)
        showToast({
          message: status
            ? Toast.SUCCESS(Action.SORT)
            : Toast.FAIL(Action.SORT),
          intent: status ? Intent.SUCCESS : Intent.DANGER,
        })
      })
    }
  }

  handleDelete = async () => {
    const { actionDelete, showToast } = this.props
    const { deleteItem, listDeleteId } = this.state
    if (deleteItem) {
      this.setState({
        listDeleteId: [...listDeleteId, deleteItem.id],
      })
      await actionDelete(deleteItem.id)
        .then(res => {
          const status = !!_.get(res, 'payload.data.result', false)
          showToast({
            message: status
              ? Toast.SUCCESS(Action.DELETE)
              : Toast.FAIL(Action.DELETE),
            intent: status ? Intent.SUCCESS : Intent.DANGER,
          })
        })
        .finally(() => {
          const { listDeleteId } = this.state
          this.setState({
            deleteItem: null,
            listDeleteId: listDeleteId.filter(id => id !== deleteItem.id),
          })
        })
    }
    this.togglePopup()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        data: nextProps.data,
        isDataEmpty: !nextProps.data.length,
      })
    }
  }

  render() {
    const { isLoading, isError } = this.props
    const { isDataEmpty, data, isOpenPopup, deleteItem, listUpdateId } =
      this.state
    return (
      <div className="page-list-container page-list-role-container">
        <div className="table-container table-subcollap-container">
          <div className="table-scroll">
            <div className="collapse-panel-content">
              <div className="table-display">
                <div className="table-display-container">
                  <HeadingTableSort data={headers.procedureCategory} />
                  <div className="table-body-container">
                    {!!isLoading ? (
                      <LoadingTable />
                    ) : !!isError ? (
                      <ErrorLoadingTable />
                    ) : !!isDataEmpty ? (
                      <EmptyDataTable />
                    ) : (
                      <SortableList
                        helperClass="SortableHelper"
                        list={data}
                        onSortEnd={this.onSortEnd}
                        useDragHandle
                        lockAxis="y"
                        togglePopup={this.togglePopup}
                        toggleUsing={this.toggleUsing}
                        lockToContainerEdges
                        listUpdateId={listUpdateId}
                      />
                    )}
                    <PopupConfirm
                      isOpen={isOpenPopup}
                      onClose={() => this.togglePopup()}
                      title={`${XOA_DANH_MUC} ${
                        !!deleteItem ? deleteItem.ma : ''
                      }`}
                      text={TEXT_BUTTON_SUBMIT}
                      onSubmit={this.handleDelete}
                    >
                      {TEXT_SUBMIT_BODY}
                    </PopupConfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const DragHandle = SortableHandle(() => <span className="icon-move-drap" />)

const SortableList = SortableContainer(({ list, ...props }) => (
  <div className="sortableList">
    {!!list &&
      list.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          i={index + 1}
          index={index}
          item={item}
          {...props}
        />
      ))}
  </div>
))

//items move
const SortableItem = SortableElement(
  ({ i, item, togglePopup, toggleUsing, listUpdateId }) => (
    <div className="table-rows no-event">
      <div className="table-cols-content width-col-5">
        <DragHandle />
      </div>
      <div className="table-cols-content width-col-5">
        <span className="order-content">{i < 10 ? '0' + i : i}</span>
      </div>
      <div className="table-cols-content width-col-15">
        <span className="order-content">{item.ma}</span>
      </div>
      <div className="table-cols-content width-col-45">
        <span className="text-content">{item.ten}</span>
      </div>
      <div className="table-cols-content width-col-20">
        <span className="text-content">
          <Checkbox
            className="checkbox-item"
            checked={item.isActive}
            onChange={() => toggleUsing(item)}
            disabled={listUpdateId.includes(item.id)}
          />
        </span>
      </div>
      <div className="table-cols-content width-col-10">
        <Popover
          popoverClassName="pt-popover-content-sizing"
          position={Position.TOP_RIGHT}
          interactionKind={PopoverInteractionKind.HOVER}
          openOnTargetFocus
          content={
            <div className="menu-popover">
              <Link className="item" to={`${EDIT_PATH}/${item.id}`}>
                <span className="pt-icon pt-icon-annotation" />
                <span className="name_action">Chỉnh sửa</span>
              </Link>
              <span className="item" onClick={() => togglePopup(item)}>
                <span className="pt-icon pt-icon-trash" />
                <span className="name_action">Xóa</span>
              </span>
            </div>
          }
        >
          <span className="icon-More" />
        </Popover>
      </div>
    </div>
  )
)

export default ProcedureCategoryList
