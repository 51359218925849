import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { Button, Intent, Classes, Checkbox, Radio } from '@blueprintjs/core'
import classNames from 'classnames'
import size from 'lodash/size'
import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import QueryString from 'query-string'

import { Icon, Upload } from 'components/newCommon'
import { Input, Select, FormDatePicker } from 'components/newCommon2'
import requestAction from 'helpers/request'
import { ASSIGN_TYPE } from 'constants/Enum'
import { Avatar } from 'components/newCommon'
import PhongBanVaDonViThucHien from 'components/common/PhongBanVaDonViThucHien'
import {
  getDOToken,
  getDOPhongBanNoiBo,
  getDOWorkKey,
  postDOFiles,
  postDOWork,
} from 'actions/doffice'
import { documentTypes, allowDocumentFileTypes } from 'constants/fileTypes'
import { showToast } from 'actions/task'
import { Action, Toast } from 'constants/MessageForm'

const DofficeCongViecChiDao = ({
  location,
  userInfoDO,
  toKenDO,
  phongBanNoiBoDO,
}) => {
  const query = QueryString.parse(location?.search)
  const { userName } = query
  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const THEO_DOI = 'THEO_DOI'
  const [dataDepartment, setDataDepartment] = useState([])
  const [dataForm, setDataForm] = useState({})
  const [dataError, setDataError] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)

  const onUpload = useCallback(async (files, idDv, toKenDO) => {
    const result = []
    await requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => postDOFiles(files, idDv, toKenDO),
      afterResponse: res => {
        result.push(...res?.Data)
      },
    })

    return result
  }, [])

  const onIsValidate = useCallback(() => {
    setDataError(null)
    let newError = {}
    if (!dataForm?.idChiDao) {
      newError = { ...newError, idChiDao: true }
    }

    if (!dataForm?.idVanBan) {
      newError = { ...newError, idVanBan: true }
    }

    if (!dataForm?.title) {
      newError = { ...newError, title: true }
    }

    setDataError(newError)
    if (!isEmpty(newError)) {
      return false
    }

    if (
      !dataForm?.CHU_TRI ||
      (dataForm?.CHU_TRI && size(dataForm?.CHU_TRI) === 0)
    ) {
      showToast({
        message: 'Vui lòng chọn chủ trì',
        intent: Intent.WARNING,
      })

      return false
    }

    return true
  }, [dataForm])

  const onSave = useCallback(() => {
    if (!onIsValidate()) {
      return
    }

    const chuTri = dataForm?.CHU_TRI
      ? {
          ID_PB: dataForm?.CHU_TRI?.[0]?.id,
          MA_PB: dataForm?.CHU_TRI?.[0]?.maPb,
        }
      : undefined
    const phoiHop =
      dataForm?.PHOI_HOP?.map(e => ({
        ID_PB: e?.id,
        MA_PB: e?.maPb,
      })) || []
    const theoDoi =
      dataForm?.THEO_DOI?.map(e => ({
        ID_PB: e?.id,
        MA_PB: e?.maPb,
      })) || []
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: true,
      beforeAction: () => {
        setIsSubmit(true)
      },
      action: () =>
        getDOWorkKey({
          ID_CD: dataForm?.idChiDao,
        }),
      afterResponse: res => {
        if (res) {
          requestAction({
            showToast: false,
            codeCheck: false,
            getResult: false,
            beforeAction: () => {
              setIsSubmit(true)
            },
            action: async () => {
              let params = {
                KEY_API: res,
                USER_NAME: userName,
                ID_VB: dataForm?.idVanBan || undefined,
                ID_CD: dataForm?.idChiDao || undefined,
                TIEU_DE: dataForm?.title || undefined,
                NOI_DUNG: dataForm?.noiDungChiDao || undefined,
                DO_KHAN: dataForm?.doKhan || undefined,
                HAN_GQ: dataForm?.deadline || undefined,
                YCVB_TRALOI: dataForm?.yeuCauTraLoi || false,
                KHOI_PHONG_BAN: {
                  DS_CHU_TRI: chuTri,
                  DS_PHOI_HOP: phoiHop,
                  DS_XEM_DB: theoDoi,
                },
                FILES: [],
              }
              let responseFiles = []
              if (dataForm?.files) {
                responseFiles = await onUpload(
                  {
                    fileVanBan: dataForm?.files || [],
                  },
                  userInfoDO?.idDv,
                  toKenDO
                )
              }

              if (size(responseFiles) !== 0) {
                params = {
                  ...params,
                  FILES: responseFiles,
                }
              }

              return postDOWork(params)
            },
            afterResponse: res => {
              if (res?.code === 200 || res?.code === 201) {
                return showToast({
                  message: Toast.SUCCESS(Action.ASSIGN),
                  intent: Intent.SUCCESS,
                })
              }

              return showToast({
                message: Toast.FAIL(Action.ASSIGN),
                intent: Intent.WARNING,
              })
            },
            afterAction: () => {
              setIsSubmit(false)
            },
          })
        }
      },
      afterAction: () => {
        setIsSubmit(false)
      },
    })
    // eslint-disable-next-line
  }, [dataForm, userName, toKenDO, userInfoDO, onUpload])

  const changeField = name => val => {
    let value = val
    if (
      (name === 'idChiDao' || name === 'idVanBan') &&
      (isNaN(Number(value)) || Number(value) < 1)
    ) {
      value = ''
    }

    setDataForm(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const changeFile = files => {
    setDataForm(prev => ({
      ...prev,
      files: files,
    }))
  }

  const changeFieldInTable = useCallback((name, value, event) => {
    const isChecked = event?.target?.checked
    setDataForm(prev => {
      let newValue = []
      const origin = prev?.[name] ? prev?.[name] : []
      const chuTri = prev?.[CHU_TRI] ? prev?.[CHU_TRI] : []
      const phoiHop = prev?.[PHOI_HOP] ? prev?.[PHOI_HOP] : []
      const theoDoi = prev?.[THEO_DOI] ? prev?.[THEO_DOI] : []
      if (name === PHOI_HOP || name === THEO_DOI) {
        const treeItem = value?.children || []
        if (isChecked) {
          if (size(treeItem) === 0) {
            newValue = [...origin, value]
          } else {
            newValue = [...origin, ...treeItem]
            if (
              size(origin) !== 0 &&
              origin?.findIndex(e => e.parentId === value?.id) !== -1
            ) {
              newValue = differenceBy(newValue, treeItem, 'id')
            }
          }
        } else {
          if (size(treeItem) === 0) {
            newValue = differenceBy(origin, [value], 'id')
          } else {
            newValue = differenceBy(origin, treeItem, 'id')
          }
        }
        if (name === PHOI_HOP && size(treeItem) !== 0) {
          newValue = differenceBy(newValue, [...chuTri, ...theoDoi], 'id')
        }
        if (name === THEO_DOI && size(treeItem) !== 0) {
          newValue = differenceBy(newValue, [...chuTri, ...phoiHop], 'id')
        }
      }

      return {
        ...prev,
        ...(name !== CHU_TRI && name !== PHOI_HOP && name !== THEO_DOI
          ? { [name]: value }
          : {}),
        ...(name === CHU_TRI || name === PHOI_HOP || name === THEO_DOI
          ? {
              [CHU_TRI]:
                name === CHU_TRI
                  ? [value]
                  : chuTri?.filter(e => e.id !== value?.id) || [],
              [PHOI_HOP]:
                name === PHOI_HOP
                  ? uniqBy(newValue, 'id')
                  : phoiHop?.filter(e => e.id !== value?.id) || [],
              [THEO_DOI]:
                name === THEO_DOI
                  ? uniqBy(newValue, 'id')
                  : theoDoi?.filter(e => e.id !== value?.id) || [],
            }
          : {}),
      }
    })
  }, [])

  const handleCheckActive = useCallback(
    (name, record) => {
      let isChecked = false
      const sizeFormData = size(dataForm?.[name])
      const sizeChildren = size(record?.children)
      const dataKey = dataForm?.[name]?.findIndex(e => e?.id === record?.id)
      if (
        dataKey === 0 ||
        (dataKey && dataKey !== -1) ||
        (sizeFormData !== 0 &&
          sizeChildren !== 0 &&
          sizeFormData === sizeChildren)
      ) {
        isChecked = true
      }

      return isChecked
    },
    [dataForm]
  )

  const handleCheckDisabled = useCallback((name, record) => {
    let result = false
    if (name === CHU_TRI && (record.id === 1 || record.id === 2)) {
      result = true
    }

    return result
  }, [])

  const handleCheckIndeterminate = useCallback(
    (name, record) => {
      let isChecked = false
      const dataKey = dataForm?.[name]?.filter(e => e.parentId === record?.id)
      if (size(dataKey) !== 0) {
        isChecked = true
      }

      return isChecked
    },
    [dataForm]
  )

  const column = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: record => {
        const isChecked = handleCheckActive(CHU_TRI, record)
        const isDisabled = handleCheckDisabled(CHU_TRI, record)
        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            disabled={isDisabled}
            onChange={val => changeFieldInTable(CHU_TRI, record, val)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: record => {
        const isChecked = handleCheckActive(PHOI_HOP, record)
        const isIndeterminate = handleCheckIndeterminate(PHOI_HOP, record)
        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked}
            indeterminate={isIndeterminate && !isChecked}
            // disabled={true}
            onChange={val => changeFieldInTable(PHOI_HOP, record, val)}
          />
        )
      },
    },
    {
      title: 'TD',
      render: record => {
        const isChecked = handleCheckActive(THEO_DOI, record)
        const isIndeterminate = handleCheckIndeterminate(THEO_DOI, record)
        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked}
            indeterminate={isIndeterminate && !isChecked}
            // disabled={true}
            onChange={val => changeFieldInTable(THEO_DOI, record, val)}
          />
        )
      },
    },
  ]

  const onHandlePhongBanNoiBo = useCallback(() => {
    if (size(phongBanNoiBoDO) !== 0) {
      setDataDepartment(
        phongBanNoiBoDO?.map(elm => {
          return {
            ...elm,
            id: elm?.khoiLevel,
            name: elm?.tenKhoi,
            type: 'ALL',
            children:
              elm?.pbNbChild?.map(child => ({
                ...child,
                id: child?.idPb,
                name: child?.tenPb,
                type: ASSIGN_TYPE.PHONG_BAN,
                parentId: elm?.khoiLevel,
              })) || [],
          }
        })
      )
    }
  }, [phongBanNoiBoDO])

  useEffect(() => {
    onHandlePhongBanNoiBo()
  }, [onHandlePhongBanNoiBo])

  useEffect(() => {
    if (!userInfoDO) {
      return
    }

    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDOPhongBanNoiBo({ idDonVi: userInfoDO?.idDv }),
    })
  }, [userInfoDO])

  useEffect(() => {
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      action: () => getDOToken(userName.toLowerCase()),
    })
  }, [userName])

  return (
    <div className="public-layout">
      <header>
        <img src="/images/EVNCPC_Logo.png" alt="logo eoffice" />
        <div style={{ lineHeight: 0 }}>
          <Avatar src={'/images/default_avatar.png'} />
        </div>
      </header>
      <div className="content">
        <div className="panel-layout cpc-form mh20 p20">
          <div className="row">
            <div className="col-xs-12 col-sm-6 mt15">
              <Input
                required
                label="Id chỉ đạo"
                onChange={changeField('idChiDao')}
                placeholder="Nhập id chỉ đạo"
                type="number"
                autoResize={true}
                value={dataForm?.idChiDao || ''}
                warning={dataError?.idChiDao}
              />
            </div>
            <div className="col-xs-12 col-sm-6 mt15">
              <Input
                required
                label="Id văn bản"
                onChange={changeField('idVanBan')}
                placeholder="Nhập id văn bản"
                type="number"
                autoResize={true}
                value={dataForm?.idVanBan || ''}
                warning={dataError?.idVanBan}
              />
            </div>
          </div>
          <div className="mt15">
            <Input
              required
              label="Tiêu đề"
              onChange={changeField('title')}
              placeholder="Nhập tiêu đề"
              value={dataForm?.title || ''}
              warning={dataError?.title}
            />
          </div>
          <div className="mt15">
            <Input
              required
              label="Nội dung chỉ đạo"
              onChange={changeField('noiDungChiDao')}
              placeholder="Nhập nội dung chỉ đạo"
              type="textarea"
              rows={2}
              maxLength={500}
              autoResize={true}
              value={dataForm?.noiDungChiDao || ''}
              wrapperClassName="mb15"
            />
          </div>
          <div className="mt15">
            <PhongBanVaDonViThucHien
              headStyle={{
                backgroundColor: '#E8E8E8',
                color: '#31434B',
              }}
              label="Phòng ban thực hiện"
              isDisplayFavoriteGroup={false}
              column={column}
              data={dataDepartment}
              dataAutoToggle={[]}
            />
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 mt15">
              <FormDatePicker
                textLabel="Hạn giải quyết"
                inputClearable
                inputIconClassName="icon2-date-frames"
                onChange={value => {
                  changeField('deadline')(value)
                }}
                minimumDate={moment().toDate()}
                placeholder="Chọn hạn giải quyết"
                popoverStretch={false}
                selected={dataForm?.deadline}
              />
            </div>
            <div className="col-xs-12 col-sm-6 mt15">
              <Select
                textLabel="Độ khẩn"
                dataSource={[
                  {
                    label: 'Bình thương',
                    value: 1,
                  },
                  {
                    label: 'Khẩn',
                    value: 2,
                  },
                  {
                    label: 'Thượng khẩn',
                    value: 3,
                  },
                  {
                    label: 'Hỏa tốc',
                    value: 4,
                  },
                ]}
                inputIconClassName="icon-flag"
                onChange={changeField('doKhan')}
                placeholder="Chọn độ khẩn"
                value={dataForm?.doKhan}
              />
            </div>
          </div>
          <div className="mt15">
            <Checkbox
              className={classNames(Classes.SMALL, 'mt5')}
              checked={dataForm?.yeuCauTraLoi || false}
              onChange={({ target: { checked } }) =>
                changeField('yeuCauTraLoi')(checked)
              }
            >
              <span className="font-size-13 font-weight-300">
                Yêu cầu văn bản trả lời
              </span>
            </Checkbox>
            {/* <Checkbox
              className={classNames(Classes.SMALL, 'mt5')}
              checked={dataForm?.taoViecEOffice || false}
              onChange={({ target: { checked } }) =>
                changeField('taoViecEOffice')(checked)
              }
            >
              <span className="font-size-13 font-weight-300">
                Tạo công việc qua E-Office
              </span>
            </Checkbox>
            <Checkbox
              className={classNames(Classes.SMALL, 'mt5')}
              checked={dataForm?.chuyenThuKy || false}
              onChange={({ target: { checked } }) =>
                changeField('chuyenThuKy')(checked)
              }
            >
              <span className="font-size-13 font-weight-300">
                Chuyển thư ký
              </span>
            </Checkbox> */}
          </div>
          <div>
            <Upload
              textLabel="File đính kèm"
              accept={documentTypes.toString()}
              fileList={dataForm?.files || []}
              wrapperClassName="mb10"
              textSumary={
                <p className="mt5 font-style-italic font-size-12">
                  Tải file có dung lượng tối đa 100MB, định dạng .pdf, .docs,
                  .doc, .xls, .xlsx, .ppt, .pptx
                </p>
              }
              isShowList={true}
              allowFileTypes={allowDocumentFileTypes}
              onChange={changeFile}
            />
          </div>
          <div className="text-center mt15">
            <Button
              className={classNames(
                'cpc-button',
                'uppercase',
                'font-size-13',
                'ph10',
                'min-width-100'
              )}
              disabled={isSubmit}
              loading={isSubmit}
              intent={Intent.PRIMARY}
              onClick={onSave}
            >
              Lưu
              <Icon classIcon="icon-save" className="ml5" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

DofficeCongViecChiDao.propTypes = {
  phongBanNoiBoDO: PropTypes.any,
}

const mapStateToProps = state => ({
  userInfoDO: state?.doffice?.toKenDO?.nhanVien
    ? state?.doffice?.toKenDO?.nhanVien
    : null,
  toKenDO: state?.doffice?.toKenDO?.token
    ? {
        tokenType: 'Bearer',
        accessToken: state?.doffice?.toKenDO?.token,
      }
    : null,
  userName: state?.auth?.user?.username,
  phongBanNoiBoDO: state?.doffice?.phongBanNoiBoDO,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DofficeCongViecChiDao)
