import React from 'react'
import classNames from 'classnames'

const HomeTag = ({ className, backgroundColor, color, text }) => {
  return (
    <div
      className={classNames('home__tag', className)}
      style={{
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      <p className="home__tag__text">{text}</p>
    </div>
  )
}

export default HomeTag
