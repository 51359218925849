import React from 'react'
import { Cookies, withCookies } from 'react-cookie'
import withRouter from 'react-router/withRouter'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import get from 'lodash/get'

import { Header, Footer, Sidebar } from '../layout'
import { Permission, Authorization } from '../auth'
import menuSidebar from 'constants/sidebar/index'
import * as Actions from '../../actions'
import * as Actions2 from '../../actions/procedure'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import Utils from 'helpers/utils'
import { checkPermissionMenu, isValidCondition } from 'helpers/Helper'
import { getThongKeLoaiGiaoViec, getNotificationCounter } from 'actions/task'

import requestAction from 'helpers/request'
import { getDOToken } from 'actions/doffice'
import Navigation from './Navigation'
import SidebarV3 from 'components/layout/sidebar/index.jsx'

const cookies = new Cookies()

class MasterLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmit: true,
      isShowMenuMobile: false,
      hiddenMenuHome: false,
      enableReadOnly: this.props.readOnly,
      enableScrollFixedMenu: this.props.enableScrollFixedMenu,
      isLeftNav: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.clickSidebarMobile = this.clickSidebarMobile.bind(this)
    this.resize = this.resize.bind(this)
    this.logout = this.logout.bind(this)
    this.getMenuItems = this.getMenuItems.bind(this)
    this.handleOpenChat = this.handleOpenChat.bind(this)
  }

  checkCouterQuanLyCongViec = () => {
    const taskCounters = this.props?.counters.task
    const counterCongViecPhongBanCaNhan =
      this.props.counterCongViecPhongBanCaNhan
    return (
      !!taskCounters?.notification ||
      !!taskCounters?.assignToMe ||
      !!taskCounters?.watchedByMe ||
      !!taskCounters?.assignByMe ||
      counterCongViecPhongBanCaNhan?.congViecCaNhanCanThucHien ||
      counterCongViecPhongBanCaNhan?.congViecPhongBanChuaGiao ||
      counterCongViecPhongBanCaNhan?.congViecTheoDoiChuaDoc
    )
  }

  getMenuItems = () => {
    const path = type => {
      if (!type) {
        return '/'
      }

      const auth = this.props?.auth
      const quyensMenu = this.props?.quyensMenu
      let pathUrl = '/'
      switch (type) {
        case 'documentary':
          if (
            Authorization.vaild(auth.permission, [Permission.CONGVANDEN]) &&
            checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.SMENU_CVAN_CANXULY,
            ])
          ) {
            pathUrl = '/cong-van/cong-van-den/danh-sach/chua-xu-ly'
          } else if (
            Authorization.vaild(auth.permission, [Permission.CONGVANDI])
          ) {
            pathUrl = '/cong-van/cong-van-di/danh-sach'
          } else if (
            Authorization.vaild(auth.permission, [Permission.CONGVAN_XEMTATCA])
          ) {
            pathUrl = '/tien-ich/tim-kiem-cong-van/danh-sach'
          } else {
            pathUrl = '/cong-van/cong-van-noi-bo/danh-sach'
          }

          break
        case 'signNumber':
          if (
            checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.SMENU_KYSO_DSCVANDI,
            ])
          ) {
            pathUrl = '/ky-so/cong-van-di/danh-sach'
          } else {
            pathUrl = '/ky-so/cong-van-noi-bo/danh-sach'
          }

          break
        case 'work':
          if (
            checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
            ]) &&
            Authorization.vaild(auth.permission, [Permission.CONGVIEC_PHONGBAN])
          ) {
            pathUrl = '/cong-viec/phong-ban/danh-sach/chua-giao'
          } else if (
            checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
            ]) &&
            Authorization.vaild(auth.permission, [Permission.CONGVIEC_CANHAN])
          ) {
            pathUrl = '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien'
          } else {
            pathUrl = '/tien-ich/tim-kiem-cong-van/danh-sach'
          }

          break
        case 'utilities':
          if (
            this.props.numberSideBar &&
            this.props.numberSideBar.phieuGuiYeuCauGopYCanXyLy > 0 &&
            Authorization.vaild(auth.permission, [
              Permission.LAYYKIENDONVI_PHIEUGUI,
            ])
          ) {
            pathUrl = '/tien-ich/lay-y-kien-don-vi/danh-sach-goi'
          } else {
            pathUrl = '/tien-ich/lay-y-kien-don-vi/danh-sach-nhan'
          }

          break
        case 'sidebarSystem':
          if (
            Authorization.vaild(this.props.auth.permission, [Permission.ADMIN])
          ) {
            pathUrl = '/he-thong/nguoi-dung/phan-quyen'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.DOITUONGCONGVAN_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/doi-tuong/danh-muc'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.SOLUUTRU_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/so-luu/danh-muc'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.DOKHAN_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/do-khan/danh-muc'
          } else if (
            Authorization.vaild(this.props.auth.permission, [
              Permission.HETHONG,
              Permission.DOBAOMAT_QUANLY,
            ])
          ) {
            pathUrl = '/he-thong/do-bao-mat/danh-muc'
          }

          break
        default:
          break
      }

      return pathUrl
    }

    const status = type => {
      const numberSideBar = this.props?.numberSideBar
      const auth = this.props?.auth
      const quyensMenu = this.props?.quyensMenu
      if (!numberSideBar) {
        return false
      }

      let result = false
      switch (type) {
        case 'documentary':
          const congVanChuaXuLy = numberSideBar?.congVanChuaXuLy ?? 0
          const congVanEvn = numberSideBar?.congVanEvn ?? 0
          const congVanBaoNham = numberSideBar?.congVanBaoNham ?? 0
          const tongSoThuHoi = numberSideBar?.tongSoThuHoi ?? 0
          if (
            (checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.SMENU_CVAN_CANXULY,
            ]) &&
              (congVanChuaXuLy > 0 || congVanBaoNham > 0)) ||
            congVanEvn > 0 ||
            tongSoThuHoi > 0
          ) {
            result = true
          }

          break
        case 'signNumber':
          const congVanDiCanKySo = numberSideBar?.congVanDiCanKySo ?? 0
          const congVanNoiBoCanKySo = numberSideBar?.congVanNoiBoCanKySo ?? 0
          const kySoMoRong = numberSideBar?.kySoMoRong ?? 0
          const kySoTapTrung = numberSideBar?.kySoTapTrung ?? 0
          const countYKien = numberSideBar?.countYKien ?? 0
          if (
            (checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.SMENU_KYSO_DSCVANDI,
            ]) &&
              congVanDiCanKySo > 0) ||
            (checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.SMENU_HDTV_LAYYKIEN,
            ]) &&
              countYKien > 0) ||
            congVanNoiBoCanKySo > 0 ||
            kySoMoRong > 0 ||
            kySoTapTrung > 0
          ) {
            result = true
          }

          break
        case 'work':
          const congViecChuaGiao = numberSideBar?.congViecChuaGiao ?? 0
          const congViecChuaThucHien = numberSideBar?.congViecChuaThucHien ?? 0
          const congViecBaoNham = numberSideBar?.congViecBaoNham ?? 0
          if (
            checkPermissionMenu(quyensMenu || [], [
              Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
            ]) &&
            (congViecChuaGiao > 0 ||
              congViecChuaThucHien > 0 ||
              congViecBaoNham > 0)
          ) {
            result = true
          }

          break
        case 'documentsCabinet':
          const hscvPhongBan = numberSideBar?.hoSoCongViec?.phongBan?.[1] ?? 0
          const hscvDonVi = numberSideBar?.hoSoCongViec?.donVi?.[2] ?? 0
          const hscvDonViDuyet = numberSideBar?.hoSoCongViec?.donVi?.[3] ?? 0
          if (
            (Authorization.vaild(
              auth.permission,
              [Permission.HSCV_PHONGBAN_DUYET],
              true
            ) &&
              hscvPhongBan > 0) ||
            (Authorization.vaild(
              auth.permission,
              [Permission.CONGVANDEN_QUANLY],
              true
            ) &&
              hscvDonVi > 0) ||
            (Authorization.vaild(
              auth.permission,
              [Permission.HSCV_VANPHONG_DUYET],
              true
            ) &&
              hscvDonViDuyet > 0)
          ) {
            result = true
          }

          break
        case 'utilities':
          const phieuGuiYeuCauGopYCanXyLy =
            numberSideBar?.phieuGuiYeuCauGopYCanXyLy
          const phieuNhanYeuCauGopYCanXyLy =
            numberSideBar?.phieuNhanYeuCauGopYCanXyLy
          if (phieuGuiYeuCauGopYCanXyLy > 0 || phieuNhanYeuCauGopYCanXyLy > 0) {
            result = true
          }

          break
        default:
          result = false
          break
      }

      return result
    }

    const auth = this.props?.auth
    const mainUnitId = auth?.mainUnitId
    return [
      {
        path: '/',
        icon: 'icon-Trang_Chu',
        name: 'Trang Chủ',
        permission: [],
        links: [],
        typeSidebar: 'HomePage',
        newStatus: false,
      },
      {
        path: path('documentary'),
        icon: 'icon-Cong_van',
        name: 'Công Văn',
        permission: [Permission.CONGVAN],
        links: [
          '/cong-van/cong-van-den/them-moi',
          '/cong-van/cong-van-den/chinh-sua',
          '/cong-van/cong-van-den/danh-sach',
          '/cong-van/cong-van-den/danh-sach/dang-xu-ly',
          '/cong-van/cong-van-den/chi-tiet',
          '/cong-van/cong-van-di/them-moi',
          '/cong-van/cong-van-di/chinh-sua',
          '/cong-van/cong-van-di/danh-sach',
          '/cong-van/cong-van-di/chi-tiet',
          '/cong-van/cong-van-noi-bo/them-moi',
          '/cong-van/cong-van-noi-bo/chinh-sua',
          '/cong-van/cong-van-noi-bo/danh-sach',
          '/cong-van/cong-van-noi-bo/chi-tiet',
          '/cong-van/chuyen-nham/danh-sach',
          '/cong-van/cong-van-evn/danh-sach',
          '/cong-van/uy-quyen',
          '/cong-van-den/chi-dao',
          '/cong-van/cong-van-di-thu-hoi/danh-sach',
          '/cong-van/cong-van-di-thu-hoi/chi-tiet',
          '/cong-van/cong-van-den-thu-hoi/danh-sach',
          '/tien-ich/tim-kiem-cong-van-v61/danh-sach',
          '/tien-ich/tim-kiem-cong-van/danh-sach',
          '/quan-ly-cong-viec-do/tim-kiem-cong-van',
          '/quan-ly-cong-viec-do/tim-kiem-cong-van/chi-tiet',
        ],
        typeSidebar: 'documentary',
        newStatus: status('documentary'),
      },
      {
        path: path('signNumber'),
        icon: 'icon-Ky_so',
        name: 'Ký Số',
        permission: [Permission.KYSO_QUANLY],
        links: [
          '/ky-so/cong-van-di/them-moi',
          '/ky-so/cong-van-di/danh-sach',
          '/ky-so/cong-van-di/chi-tiet',
          '/ky-so/cong-van-di/chinh-sua',
          '/ky-so/cong-van-noi-bo/them-moi',
          '/ky-so/cong-van-noi-bo/danh-sach',
          '/ky-so/cong-van-noi-bo/chi-tiet',
          '/ky-so/cong-van-noi-bo/chinh-sua',
          '/ky-so/cong-van-dau-thau/them-moi',
          '/ky-so/du-an/them-moi',
          '/ky-so/du-an/chinh-sua',
          '/ky-so/du-an/danh-sach',
          '/ky-so/du-an/chi-tiet',
          '/ky-so-mo-rong/du-an/danh-sach',
          '/ky-so-mo-rong/du-an/them-moi',
          '/ky-so-mo-rong/du-an/chinh-sua',
          '/ky-so-mo-rong/du-an/thong-tin',
          '/ky-so-tap-trung/van-ban-ky-duyet',
          '/ky-so-tap-trung/tim-kiem-van-ban',
          '/hoi-dong-thanh-vien/lay-y-kien',
          '/hoi-dong-thanh-vien/lay-y-kien/danh-sach',
        ],
        typeSidebar: 'signNumber',
        newStatus: status('signNumber'),
      },
      ...((Utils.checkTongCongTy(mainUnitId) &&
        checkPermissionMenu(this.props?.quyensMenu || [], [
          Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC_QCTCT,
        ])) ||
      (!Utils.checkTongCongTy(mainUnitId) &&
        checkPermissionMenu(this.props?.quyensMenu || [], [
          Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC,
        ]))
        ? [
            {
              path: path('work'),
              icon: 'icon-Cong_viec',
              name: 'Công Việc',
              permission: [Permission.CONGVIEC],
              permissionMenu: [Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC],
              links: [
                '/cong-viec/giao-viec-moi',
                '/cong-viec/them-moi-giao-viec',
                '/cong-viec/phong-ban/danh-sach/da-giao',
                '/cong-viec/ca-nhan/danh-sach',
                '/cong-viec/ca-nhan/chi-tiet/chua-thuc-hien',
                '/cong-viec/ca-nhan/chi-tiet/dang-thuc-hien',
                '/cong-viec/ca-nhan/chi-tiet/da-thuc-hien',
                '/cong-viec/ca-nhan/xem-de-biet',
                '/cong-viec/phong-ban/chi-tiet/chua-giao',
                '/cong-viec/phong-ban/chi-tiet/da-giao',
                '/cong-viec/uy-quyen',
                '/cong-viec/chuyen-nham/danh-sach',
                '/cong-viec/ca-nhan/danh-sach/da-giao',
                '/cong-viec/ca-nhan/chi-tiet/da-giao',
              ],
              typeSidebar: 'work',
              newStatus: status('work'),
            },
          ]
        : []),
      {
        typeSidebar: 'taskManager',
        path: Authorization.vaild(this.props.auth.permission, [
          Permission.CONGVANDEN_CHIDAO,
        ])
          ? '/quan-ly-cong-viec/ca-nhan/da-giao'
          : '/quan-ly-cong-viec/ca-nhan/can-thuc-hien',
        icon: 'icon-case',
        name: 'Quản lý công việc',
        permission: [Permission.SUDUNG_QLCV],
        links: [
          '/quan-ly-cong-viec/tao-cong-viec',
          '/quan-ly-cong-viec/thong-bao/',
          '/quan-ly-cong-viec/tags',
          '/quan-ly-cong-viec/nhom-thuong-giao-viec',
          '/quan-ly-cong-viec/thong-ke',
          '/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop',
          '/thong-bao-ket-luan-cuoc-hop/chi-tiet',
          '/quan-ly-cong-viec/phong-ban/chua-giao',
          '/quan-ly-cong-viec/phong-ban/da-giao',
          '/quan-ly-cong-viec/ca-nhan/can-thuc-hien',
          '/quan-ly-cong-viec/ca-nhan/da-giao',
          '/quan-ly-cong-viec/ca-nhan/theo-doi',
        ],
        newStatus: this.checkCouterQuanLyCongViec(),
      },
      ...(checkPermissionMenu(this.props?.quyensMenu || [], [
        Permission.MENU_SIDEBAR.SMENU_TTL,
      ])
        ? [
            {
              path: '/tu-tai-lieu/danh-sach',
              icon: 'icon-Tu_Tai_Lieu',
              name: 'Tủ Tài Liệu',
              permission: [Permission.TUTAILIEU],
              links: [
                '/tu-tai-lieu/danh-sach',
                '/tu-tai-lieu/them-file',
                '/tu-tai-lieu/di-chuyen',
                '/tu-tai-lieu/di-chuyen-ngan-tu',
                '/tu-tai-lieu/chi-tiet',
                '/ho-so-cong-viec/chi-tiet',
              ],
              typeSidebar: 'documentsCabinet',
              newStatus: status('documentsCabinet'),
            },
          ]
        : []),
      {
        path: path('utilities'),
        icon: 'icon-wrench',
        name: 'Tiện ích',
        permission: [],
        links: [
          '/tien-ich/thong-ke-cong-van/danh-sach',
          '/tien-ich/tinh-hinh-xu-ly-cong-van/danh-sach',
          '/tien-ich/thong-ke-cong-viec/danh-sach',
          '/tien-ich/thong-ke-tu-tai-lieu/danh-sach',
          '/tien-ich/thong-ke-ho-so-cong-viec/danh-sach',
          '/tien-ich/thong-ke-ky-so/danh-sach',
          '/tien-ich/thong-ke-van-ban-nhan-dang/danh-sach',
          '/tien-ich/thong-ke-thu-hoi-cong-van/danh-sach',
          '/tien-ich/thong-ke-bao-nham/danh-sach',
          '/tien-ich/thong-ke-nhan-vien/danh-sach',
          '/tien-ich/thong-ke-tin-nhan/danh-sach',
          '/danh-ba',
          '/tien-ich/lay-y-kien-don-vi/danh-sach-goi',
          '/tien-ich/lay-y-kien-don-vi/danh-sach-nhan',
          '/tien-ich/lay-y-kien-don-vi/tao-phieu',
          '/tien-ich/thong-bao-ket-luan-cuoc-hop/thong-ke',
          ...(!checkPermissionMenu(this.props?.quyensMenu || [], [
            Permission.MENU_SIDEBAR.SMENU_TTL,
          ])
            ? ['/tu-tai-lieu/danh-sach']
            : []),
        ],
        typeSidebar: 'utilities',
        newStatus: status('utilities'),
      },
      {
        path: path('sidebarSystem'),
        icon: 'icon-Hethong',
        name: 'Hệ thống',
        orPermission: [
          Permission.ADMIN,
          Permission.SUPERADMIN,
          Permission.HETHONG,
        ],
        links: [
          '/he-thong/nguoi-dung/phong-ban',
          '/he-thong/nguoi-dung/phan-quyen',
          '/he-thong/nguoi-dung/them-moi',
          '/he-thong/nguoi-dung/chinh-sua',
          '/he-thong/phan-quyen-vai-tro',
          '/he-thong/vai-tro/danh-muc',
          '/he-thong/vai-tro/them-moi',
          '/he-thong/vai-tro/chinh-sua',
          '/he-thong/don-vi/danh-muc',
          '/he-thong/don-vi/them-moi',
          '/he-thong/don-vi/chinh-sua',
          '/he-thong/phong-ban/danh-muc',
          '/he-thong/phong-ban/them-moi',
          '/he-thong/phong-ban/chinh-sua',
          '/he-thong/chuc-vu/danh-muc',
          '/he-thong/chuc-vu/them-moi',
          '/he-thong/chuc-vu/chinh-sua',
          '/he-thong/so-luu/danh-muc',
          '/he-thong/so-luu/them-moi',
          '/he-thong/so-luu/chinh-sua',
          '/he-thong/doi-tuong/danh-muc',
          '/he-thong/doi-tuong/them-moi',
          '/he-thong/doi-tuong/chinh-sua',
          '/he-thong/do-khan/danh-muc',
          '/he-thong/do-khan/them-moi',
          '/he-thong/do-khan/chinh-sua',
          '/he-thong/do-bao-mat/danh-muc',
          '/he-thong/do-bao-mat/them-moi',
          '/he-thong/do-bao-mat/chinh-sua',
          '/he-thong/quan-ly-cong-viec/banner/danh-sach',
          '/he-thong/quan-ly-cong-viec/banner/them-moi',
          '/he-thong/quan-ly-cong-viec/banner/chinh-sua',
          '/he-thong/service/them-moi',
          '/he-thong/service/chinh-sua',
          '/he-thong/service/danh-sach',
          '/he-thong/service/thong-ke',
          '/he-thong/danh-muc-quy-trinh',
          '/he-thong/quy-trinh',
          '/he-thong/don-vi',
          '/he-thong/thong-ke-ky-so-tap-trung/danh-sach',
          '/he-thong/phuong-phap-luu/danh-sach',
          '/he-thong/phuong-phap-luu/them-moi',
          '/he-thong/phuong-phap-luu/chinh-sua',
          '/he-thong/thoi-gian-luu/danh-sach',
          '/he-thong/thoi-gian-luu/them-moi',
          '/he-thong/thoi-gian-luu/chinh-sua',
          '/he-thong/phien-ban/phan-quyen-chuc-nang',
          '/he-thong/chia-se-api/ung-dung-chia-se',
          '/he-thong/chia-se-api/phan-quyen-chia-se-api',
          '/he-thong/quan-ly-cong-viec/do-uu-tien/danh-sach',
        ],
        typeSidebar: 'sidebarSystem',
        newStatus: false,
      },
    ]
  }

  isNewNotifications = () => {
    let noti = this.props.notifications
    let corporations =
      noti && noti.list && noti.list.corporations ? noti.list.corporations : []
    let unit = noti && noti.list && noti.list.unit ? noti.list.unit : []
    let isNewDate = date => {
      if (moment(date).format('L') === moment().format('L')) {
        return true
      } else {
        return false
      }
    }
    if (
      (corporations &&
        corporations[0] &&
        corporations[0].ngayTao &&
        isNewDate(corporations[0].ngayTao)) ||
      (unit && unit[0] && unit[0].ngayTao && isNewDate(unit[0].ngayTao))
    ) {
      return true
    } else {
      return false
    }
  }

  handleClick(e) {
    this.setState(
      {
        isSubmit: !this.state.isSubmit,
      },
      () => {
        this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
      }
    )
  }

  clickSidebarMobile(e) {
    this.setState({
      isShowMenuMobile: !this.state.isShowMenuMobile,
    })
  }
  logout(e) {
    let checkin_maxacnhan = localStorage.getItem('checkin_maxacnhan')
    let user_login = localStorage.getItem('user_login')
    localStorage.clear()
    localStorage.setItem('user_login', user_login)
    localStorage.setItem('checkin_maxacnhan', checkin_maxacnhan)
    sessionStorage.clear()
    this.props.actions.authLogout()
  }

  componentWillMount() {
    window.addEventListener('resize', this.resize)
    window.addEventListener('checkReloadPage', this.checkReloadPage)
    this.resize()
    this.checkReloadPage()
  }

  checkReloadPage = () => {
    window.onbeforeunload = e => {
      Tool.Utils.saveData('isReloadPage', true)
    }

    window.onload = e => {
      Tool.Utils.saveData('isReloadPage', false)
    }
  }

  resize() {
    if (this.props.collapseSideBar && this.props.collapseSideBar === true) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (window.innerWidth <= 1024) {
      this.setState(
        {
          enableReadOnly: true,
        },
        () => {
          this.props.actions.commonReadOnlyDatePicker(this.state.enableReadOnly)
        }
      )
    }

    if (
      this.props.location.pathname.indexOf('them-file') > -1 ||
      this.props.location.pathname.indexOf('di-chuyen') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('danh-sach') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('chuyen-nham') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('tien-ich') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('danh-ba') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }

    if (
      window.innerWidth <= 1024 &&
      this.props.location.pathname.indexOf('he-thong') > -1
    ) {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }
  }

  componentDidMount() {
    const { auth, location } = this.props
    const currentUserPermission = auth.permission
    const { pathname } = location
    if (
      isValidCondition(currentUserPermission, [Permission.SUDUNG_QLCV], 'OR')
    ) {
      getThongKeLoaiGiaoViec()
      if (pathname.includes('/quan-ly-cong-viec/')) {
        getNotificationCounter()
      }
    }
    this.props.actions.getQuyenChucNang()
    this.props.actions.getNumberHomePage()
    if (this.props.typeSidebar === 'listContact') {
      this.setState(
        {
          isSubmit: false,
        },
        () => {
          this.props.actions.commonScrollFixtopMenu(this.state.isSubmit)
        }
      )
    }
    if (this.props.location.pathname === '/error-page') {
      document.body.style.backgroundColor = 'white'
    }
    const danhSach = this.props.match.path.indexOf('danh-sach') > -1
    if (this.props.typeSidebar === 'documentary' && danhSach) {
      this.props.actions.getNumberDocumentPage()
      this.props.actions.getNumberEVNDocument()
      this.props.actions.getTongSoCongVanThuHoi()
    }
    if (this.props.typeSidebar === 'signNumber' && danhSach) {
      this.props.actions.getNumberSignNumberPage()
      this.props.actions.getNumberProjectSignNumberPage()
      this.props.actions.getNumberKSTTPage()
      this.props.actions.getNumberPhieuYKien()
    }
    if (this.props.typeSidebar === 'work' && danhSach) {
      if (this.props.config.refresh) {
        return this.props.actions.getNumberWorkPage(this.props.config.refresh)
      }
      this.props.actions.getNumberWorkPage()
    }
    if (
      this.props.typeSidebar === 'documentsCabinet' ||
      danhSach ||
      this.props.location.pathname === '/'
    ) {
      this.props.actions.getNumberHSCV()
    }

    if (this.props.location.pathname === '/') {
      this.props.actions.getCounterCongViecPhongBanCaNhan()
    }

    if (this.props.userName) {
      // get DO token
      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () => getDOToken(this.props.userName.toLowerCase()),
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const danhSach = this.props.match.path.indexOf('danh-sach') > -1
    if (
      prevProps.typeSidebar === 'documentary' &&
      danhSach &&
      prevProps.match.url !== this.props.match.url
    ) {
      this.props.actions.getNumberDocumentPage()
      this.props.actions.getNumberEVNDocument()
    }
    if (
      prevProps.typeSidebar === 'work' &&
      danhSach &&
      prevProps.match.url !== this.props.match.url
    ) {
      if (this.props.config.refresh) {
        return this.props.actions.getNumberWorkPage(this.props.config.refresh)
      }
      this.props.actions.getNumberWorkPage()
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (cookies.get('theme_menu') && cookies.get('theme_menu') === 'VERTICAL') {
      this.setState({ isLeftNav: true })
    } else {
      this.setState({ isLeftNav: false })
    }
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('checkReloadPage', this.checkReloadPage)
  }

  handleOpenChat() {
    const { auth } = this.props

    const url =
      Types.FE_CHAT_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`
    window.open(url, 'eoffice-chat')
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {})
    )
    const isLeftNav = this.state?.isLeftNav
    const { auth, typeSidebar, numberSideBar, location, quyensMenu } =
      this.props

    return (
      <div>
        {isLeftNav && (
          <Navigation
            quyensMenu={quyensMenu}
            user={auth.user}
            menu={this.getMenuItems()}
          />
        )}
        <div
          className={classnames('page-container', {
            'is-navigation': isLeftNav,
            'page-container-dashboard': location.pathname === '/',
            'sidebar-collapsed': !this.state.isSubmit,
            'sidebar-collapsed-back': this.state.isSubmit,
          })}
        >
          {(!isLeftNav || (isLeftNav && location.pathname !== '/')) && (
            <Header
              quyensMenu={quyensMenu}
              user={this.props.auth.user}
              roleId={this.props.auth && this.props.auth.roleId}
              path={this.props.path}
              actions={this.props.actions}
              hiddenMenuHome={this.state.hiddenMenuHome}
              notifications={this.props.notifications}
              history={this.props.history}
              isNavigation={isLeftNav}
              menu={this.getMenuItems()}
              onHandleClick={this.clickSidebarMobile}
              onOpenChat={this.handleOpenChat}
              handleLogout={this.logout}
            />
          )}
          {typeSidebar && typeSidebar === 'taskManager' && (
            <SidebarV3
              dataSource={menuSidebar.taskManager}
              isMobileMenuShown={this.state.isShowMenuMobile}
              isMenuShown={this.state.isSubmit}
              isNavigation={isLeftNav}
              onToggleMenu={this.handleClick}
              onClose={this.clickSidebarMobile}
            />
          )}
          {typeSidebar &&
            typeSidebar !== 'taskManager' &&
            typeSidebar !== 'HomePage' && (
              <Sidebar
                auth={auth}
                quyensMenu={quyensMenu}
                data={
                  typeSidebar === 'documentary'
                    ? menuSidebar.documentary
                    : typeSidebar === 'work'
                    ? menuSidebar.work
                    : typeSidebar === 'signNumber'
                    ? menuSidebar.signNumber
                    : typeSidebar === 'documentsCabinet'
                    ? checkPermissionMenu(this.props?.quyensMenu || [], [
                        Permission.MENU_SIDEBAR.SMENU_TTL,
                      ])
                      ? menuSidebar.documentsCabinet
                      : menuSidebar.utilities
                    : typeSidebar === 'sidebarSystem'
                    ? menuSidebar.system
                    : typeSidebar === 'utilities'
                    ? menuSidebar.utilities
                    : null
                }
                isSubmit={this.state.isSubmit}
                isShowMenuMobile={this.state.isShowMenuMobile}
                notAssign={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.congViecChuaGiao
                      : null
                    : null
                }
                inProcess={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.congViecDangThucHien
                      : numberSideBar.congVanDangXuLy
                    : 0
                }
                noProcess={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.congViecChuaThucHien
                      : numberSideBar.congVanChuaXuLy
                    : 0
                }
                alertMistake={numberSideBar ? numberSideBar.congVanBaoNham : 0}
                seeToKnow={
                  numberSideBar
                    ? typeSidebar === 'work'
                      ? numberSideBar.xemDeBietChuaDoc
                      : null
                    : null
                }
                parentUnitId={this.props.auth && this.props.auth.parentUnitId}
                evnTotal={numberSideBar ? numberSideBar.congVanEvn : 0}
                tongSoThuHoi={numberSideBar ? numberSideBar.tongSoThuHoi : 0}
                sendTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar.congVanDiCanKySo
                    : null
                }
                internalTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar?.congVanNoiBoCanKySo || 0
                    : null
                }
                projectTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar?.kySoMoRong || 0
                    : null
                }
                kySoTapTrungTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar?.kySoTapTrung || 0
                    : null
                }
                yKienTotal={
                  numberSideBar && typeSidebar === 'signNumber'
                    ? numberSideBar?.countYKien || 0
                    : null
                }
                phieuGuiYeuCauGopYCanXyLy={
                  numberSideBar && typeSidebar === 'utilities'
                    ? numberSideBar?.phieuGuiYeuCauGopYCanXyLy || 0
                    : null
                }
                phieuNhanYeuCauGopYCanXyLy={
                  numberSideBar && typeSidebar === 'utilities'
                    ? numberSideBar?.phieuNhanYeuCauGopYCanXyLy || 0
                    : null
                }
                isNavigation={isLeftNav}
                onHandleClick={this.handleClick}
                clickSidebarMobile={this.clickSidebarMobile}
              />
            )}
          <main
            className={classnames('left-content', {
              'dashboard-page-wapper':
                location.pathname === '/' ||
                get(location, 'pathname', '').split('/')[1] === 'thong-bao', // giữ class với 1 số router '/thong-bao/*'
              'dashboard-page-error': location.pathname === '/error-page',
            })}
          >
            <div
              className={classnames({
                'content-wrapper padding-mobile-default':
                  location.pathname !== '/',
              })}
            >
              {childrenWithProps}
            </div>
            <Footer />
          </main>
        </div>
      </div>
    )
  }
}

MasterLayout.defaultProps = {
  typeSidebar: '',
  collapseSideBar: false,
}

const mapStateToProps = state => ({
  counters: state?.counters,
  config: state.common.config,
  userName: state.auth.user.username,
  auth: state.auth,
  receiveDocumentTotals: state.common.receiveDocumentTotals,
  departmentWorkTotals: state.common.departmentWorkTotals,
  personalWorkTotals: state.common.personalWorkTotals,
  numberSideBar: state.common.numberSideBar,
  totalSeeToKnow: state.personalWorks.totalSeeToKnowNotRead,
  alertMistakeTotals: state.common.alertMistakeTotals,
  readOnly: state.common.enableReadOnly,
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  notifications: state.notifications,
  quyensMenu: state.common.quyensMenu,
  counterCongViecPhongBanCaNhan:
    state.TaskManager.counterCongViecPhongBanCaNhan,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...Actions, ...Actions2 }, dispatch),
})

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MasterLayout))
)
