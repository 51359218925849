import { useCallback, useEffect } from 'react'
import { reorder, move } from 'helpers/dragdrop'
import { Intent } from '@blueprintjs/core'
import { ASSIGN_TYPE, WORK_STATUS } from 'constants/Enum'
import requestAction from 'helpers/request'
import {
  Action,
  Toast,
  DROP_DRAP_KANBAN_FAILED,
  TEXT_HOANTHANH_CONGVIEC,
} from 'constants/MessageForm'
import { showToast, patchTienDoKanban } from 'actions/task'

const useKanban = ({
  dataSource = [],
  dataAfterSortOrUpdate = null,
  currentUserId,
  onUpdateDataAfterSortOrUpdate,
  onUpdateData,
  onSortWotkItem,
}) => {
  const classes = {
    NEW: 'open',
    DOING: 'progress',
    DONE: 'done',
  }
  const titles = {
    NEW: 'chưa thực hiện',
    DOING: 'đang thực hiện',
    DONE: 'đã thực hiện',
  }
  const tienDoTheoTrangThai = {
    NEW: 0,
    DOING: 50,
    DONE: 100,
  }

  // Cập nhật lại orderIndex của workItem sau khi sort hoặc cập nhật tiến độ thành công
  const onUpdateAfterSortOrUpdateProgress = useCallback(
    workItem => {
      const tempData = [...dataSource]
      const columnIndex = tempData.findIndex(
        item => item.status === workItem.status
      )

      if (columnIndex < 0) {
        return
      }

      const columnData = tempData[columnIndex]
      let columnItems = columnData.items
      const workItemIndex = columnItems.findIndex(
        item => item.id === workItem.id
      )

      if (workItemIndex < 0) {
        return
      }

      columnItems[workItemIndex].orderIndex = workItem.orderIndex
      columnData.items = columnItems
      tempData[columnIndex] = columnData

      onUpdateData(tempData)
    },
    [dataSource, onUpdateData]
  )

  const handleUpdateProgress = useCallback(
    (id, data) => {
      requestAction({
        action: () => patchTienDoKanban(id, data),
        afterResponse: response => {
          onUpdateAfterSortOrUpdateProgress(response)
        },
        errorMessage: Toast.FAIL(Action.UPDATE),
        successMessage:
          data.status === WORK_STATUS.HOAN_THANH
            ? TEXT_HOANTHANH_CONGVIEC
            : Toast.SUCCESS(Action.UPDATE),
      })
    },
    [onUpdateAfterSortOrUpdateProgress]
  )

  const onDragEnd = result => {
    const { source, destination, draggableId } = result

    if (!destination) {
      return
    }

    if (result.type === 'COLUMN') {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const tempData = [...dataSource]
      const index = tempData.findIndex(
        item => item.status === source.droppableId
      )

      if (index < 0) {
        return
      }

      const columnData = tempData[index]
      let columnItems = columnData.items

      columnItems = reorder(columnItems, source.index, destination.index)

      // Get dữ liệu để sắp xếp
      const afterItem = columnItems[destination.index - 1]
      const beforeItem = columnItems[destination.index + 1]
      const sortData = {
        after_order_index: afterItem ? afterItem.orderIndex : null,
        before_order_index: beforeItem ? beforeItem.orderIndex : null,
      }

      onSortWotkItem(draggableId, sortData)

      columnData.items = columnItems
      tempData[index] = columnData

      return onUpdateData(tempData)
    }

    const tempData = [...dataSource]
    const sourceColumnIndex = tempData.findIndex(
      item => item.status === source.droppableId
    )
    const destColumnIndex = tempData.findIndex(
      item => item.status === destination.droppableId
    )

    if (sourceColumnIndex < 0 || destColumnIndex < 0) {
      return
    }

    const sourceColumnData = tempData[sourceColumnIndex]
    let sourceColumnItems = sourceColumnData.items
    const destColumnData = tempData[destColumnIndex]
    let destColumnItems = destColumnData.items

    // Kiểm tra xem người dùng có quyền kéo thả thay đổi trạng thái
    // (Chỉ được kéo thay đổi trạng thái nếu công việc đó giao cho chính mình)
    const currentItem = sourceColumnItems[source.index]
    if (
      !currentItem?.mainResponsibility ||
      currentItem?.mainResponsibility?.type !== ASSIGN_TYPE.CA_NHAN ||
      currentItem?.mainResponsibility?.refId !== currentUserId
    ) {
      return showToast({
        message: DROP_DRAP_KANBAN_FAILED,
        intent: Intent.WARNING,
      })
    }

    const [sourceList, destList] = move(
      sourceColumnItems,
      destColumnItems,
      source,
      destination
    )

    // Get dữ liệu để sắp xếp
    const afterItem = destList[destination.index - 1]
    const beforeItem = destList[destination.index + 1]
    const sortData =
      afterItem || beforeItem
        ? {
            after_order_index: afterItem ? afterItem.orderIndex : null,
            before_order_index: beforeItem ? beforeItem.orderIndex : null,
          }
        : null

    // Cập nhật tiến độ cho công việc hiện tại
    currentItem.progress = tienDoTheoTrangThai[destination.droppableId]
    currentItem.status = destination.droppableId
    const updateProgressData = {
      status: currentItem.status,
      progress: currentItem.progress,
      sort_item: sortData,
    }

    // Gọi cập nhật tiến độ và sort
    handleUpdateProgress(draggableId, updateProgressData)

    // Cập nhật lại state
    sourceColumnData.items = sourceList
    tempData[sourceColumnIndex] = sourceColumnData

    destList[destination.index] = currentItem
    destColumnData.items = destList
    tempData[destColumnIndex] = destColumnData

    onUpdateData(tempData)
  }

  useEffect(() => {
    if (dataAfterSortOrUpdate?.id) {
      onUpdateAfterSortOrUpdateProgress(dataAfterSortOrUpdate)
      onUpdateDataAfterSortOrUpdate()
    }
  }, [
    dataAfterSortOrUpdate,
    onUpdateAfterSortOrUpdateProgress,
    onUpdateDataAfterSortOrUpdate,
  ])

  return {
    classes,
    titles,
    onDragEnd,
  }
}

export default useKanban
