import {
  GET_STATISTIC_SIGN_NUMBER_UNITS,
  GET_STATISTIC_SIGN_NUMBER_DEPARTMENTS,
  GET_STATISTIC_SIGN_NUMBER_PERSONAL,
  GET_STATISTIC_SIGN_NUMBER_PERSONAL_IN_CHILD_UNITS,
  STAGISTICAL_SIGNNUMBER_OVERTIME,
} from '../constants/ActionTypes'

const initialState = {
  // Thống kê ký số
  units: {
    list: [],
    total: 0,
  },
  departments: {
    list: [],
    total: 0,
  },
  personal: {
    list: [],
    total: 0,
  },
  personalInChildUnits: {
    list: [],
    total: 0,
  },
  stagitiscalNumberOverTime: {
    list: {
      page: 1,
      total: 0,
      items: [],
    },
  },
}

export default function statisticSignNumber(state = initialState, action) {
  switch (action.type) {
    case GET_STATISTIC_SIGN_NUMBER_UNITS:
      return {
        ...state,
        units: {
          list: action.payload.data.result.thongKeKySoDonViChung || [],
          total: action.payload.data.result.thongKeKySoDonViChung
            ? action.payload.data.result.thongKeKySoDonViChung.length
            : 0,
        },
      }

    case GET_STATISTIC_SIGN_NUMBER_DEPARTMENTS:
      return {
        ...state,
        departments: {
          list: action.payload.data.result.thongKeKySoPhongBanChung || [],
          total: action.payload.data.result.thongKeKySoPhongBanChung
            ? action.payload.data.result.thongKeKySoPhongBanChung.length
            : 0,
        },
      }

    case GET_STATISTIC_SIGN_NUMBER_PERSONAL:
      return {
        ...state,
        personal: {
          list: action.payload.data.result.thongKeKySoCaNhanChung || [],
          total: action.payload.data.result.thongKeKySoCaNhanChung
            ? action.payload.data.result.thongKeKySoCaNhanChung.length
            : 0,
        },
      }

    case GET_STATISTIC_SIGN_NUMBER_PERSONAL_IN_CHILD_UNITS:
      return {
        ...state,
        personalInChildUnits: {
          list: action.payload.data.result.thongKeKySoCaNhanChung || [],
          total: action.payload.data.result.thongKeKySoCaNhanChung
            ? action.payload.data.result.thongKeKySoCaNhanChung.length
            : 0,
        },
      }

    case STAGISTICAL_SIGNNUMBER_OVERTIME:
      return {
        ...state,
        stagitiscalNumberOverTime: {
          ...state.stagitiscalNumberOverTime,
          list: {
            page: action.payload.data.result.page,
            total: action.payload.data.result.total,
            items: action.payload.data.result.items,
          },
        },
      }

    default:
      return state
  }
}
