import React, { Fragment, memo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'
import toPairs from 'lodash/toPairs'
import classNames from 'classnames'
import moment from 'moment'
import {
  Classes,
  Button,
  Intent,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'

import { MasterLayout } from 'components/layout'
import Title from 'components/title'
import { Permission } from 'components/auth'
import { priorities } from 'constants/task'
import {
  documentTypesLimit,
  allowDocumentFileTypesLimit,
  documentTypes,
  allowDocumentFileTypes,
} from 'constants/fileTypes'
import {
  Row,
  Col,
  Upload,
  Collapse,
  FileGroup,
  Icon,
  Dialog,
  Card,
} from 'components/newCommon'
import {
  Input,
  Select,
  RemindSelect,
  CheckboxSelect,
  FormDatePicker,
  DateAdvanced,
  InputSearch,
} from 'components/newCommon2'
import ActionFixed from 'components/action-fixed'
import { searchFileCongVanV2 } from 'actions'
import PhongBanVaDonViThucHien from './components/phong-ban-don-vi-thuc-hien'
import useTaskThongbaoKLCHCreate from './hooks/use-task-thongbao-klch-create'

const TaskThongBaoKLCHCreate = ({
  actions,
  match,
  history,
  mainUnitId,
  mainDepartmentId,
  hasShowAllDeparment,
  dataThongTinDonVi,
  dataLanhDaoDonVi,
  dataTreeOrganizationDepartmentStore,
}) => {
  const {
    dsLoaiCuocHop,
    tags,
    dataInputSearch,
    isOpen,
    tasks,
    dataCollapse,
    dataForm,
    validateErrors,
    fetching,
    dataAutoToggle,
    files,
    isLoading,
    isDisabledButtonDefault,
    isShowDialog,
    onCloseDialog,
    onCardChange,
    callApiSoCongVan,
    handleCheckIndeterminate,
    onChangeRadio,
    attachmentFilesFromDocument,
    handleCheckActive,
    onChangeCheckbox,
    removeAttachmentFiles,
    changeField,
    changeFile,
    toggleCollapseTask,
    addTask,
    removeTask,
    onDeleteTask,
    toggleCollapseExtra,
    toggleCollapse,
    submitUpdate,
    submitCreate,
  } = useTaskThongbaoKLCHCreate({
    match,
    history,
    actions,
    mainUnitId,
    hasShowAllDeparment,
    dataThongTinDonVi,
    dataTreeOrganizationDepartmentStore,
  })

  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const id = match.params?.id

  const column = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: (record, _, __, taskId) => {
        const taskItem = tasks?.find(
          elm => elm.id === taskId && elm?.data?.[CHU_TRI]?.id === record?.id
        )
        let isChecked = false
        if (!isEmpty(taskItem)) {
          isChecked = true
        }

        if (
          (!hasShowAllDeparment && record?.phongBanId === mainDepartmentId) ||
          record?.type === 'ALL'
        ) {
          return ''
        }

        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            onChange={() => onChangeRadio(CHU_TRI, record, taskId)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: (record, _, __, taskId) => {
        const isChecked = handleCheckActive(PHOI_HOP, tasks, taskId, record)
        const isIndeterminate = handleCheckIndeterminate(
          PHOI_HOP,
          tasks,
          taskId,
          record
        )
        if (!hasShowAllDeparment && record?.phongBanId === mainDepartmentId) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            onChange={e =>
              onChangeCheckbox(PHOI_HOP, record, taskId, e, isIndeterminate)
            }
          />
        )
      },
    },
  ]

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="mt10">
        <Title
          name={
            id
              ? 'Chỉnh sửa thông báo kết luận cuộc họp'
              : 'Tạo thông báo kết luận cuộc họp'
          }
          icon="icon2-clipboard"
        />
        <div
          className={'cpc-form bg-white pt30 pl30 pr30 border-radius-4 mt15'}
        >
          <Button
            className={classNames(
              Classes.MINIMAL,
              'cpc-button',
              'cpc-button-link',
              'mb10'
            )}
            intent={Intent.PRIMARY}
            onClick={() =>
              toggleCollapse('showMeeting', dataCollapse?.showMeeting)
            }
          >
            <span className="text-uppercase font-size-13 font-weight-600 color-blue">
              {'Thông tin cuộc họp'}
            </span>
            <Icon
              classIcon={
                dataCollapse?.showMeeting
                  ? 'icon2-arrow-up'
                  : 'icon2-arrow-down'
              }
              className={classNames('ml5', 'size-icon-10', 'd-flex')}
            />
          </Button>
          <Collapse isOpen={dataCollapse?.showMeeting}>
            <div
              className="d-flex align-item-center p10 mb10"
              style={{ backgroundColor: '#E8E8E8' }}
            >
              <Icon
                classIcon={'icon2-user'}
                className={classNames('mr5', 'd-flex')}
              />
              <span className="font-size-13">
                {dataThongTinDonVi?.tenDonVi}
              </span>
            </div>
            <Row gutterVertical>
              <Col grid="half">
                <Select
                  className="mb10"
                  isRequired={true}
                  textLabel="Loại cuộc họp"
                  isShowArrowDropdown={true}
                  dataSource={dsLoaiCuocHop}
                  inputIconClassName="icon2-tags-dup"
                  placeholder="Loại cuộc họp"
                  value={get(dataForm, 'meetingType')}
                  inputWarning={get(validateErrors, 'meetingType')}
                  onChange={changeField('MEETING', 'meetingType')}
                />
              </Col>
              <Col grid="half">
                <InputSearch
                  popoverPosition={Position.BOTTOM_RIGHT}
                  value={dataForm?.textNumber}
                  maxLength={500}
                  required
                  label="Số hiệu văn bản"
                  iconClassName="icon2-documents"
                  wrapperClassName="mb10"
                  dataSource={dataInputSearch}
                  warning={get(validateErrors, 'textNumber')}
                  open={isOpen}
                  onCardChange={onCardChange}
                  onLoad={callApiSoCongVan}
                  onChange={e => changeField('MEETING', 'textNumber')(e)}
                />
              </Col>
            </Row>
            <Upload
              isOnly
              isRequired
              textLabel="File văn bản"
              accept={documentTypesLimit.toString()}
              fileList={get(dataForm, 'meetingFiles') || []}
              wrapperClassName="mb10"
              warning={get(validateErrors, 'meetingFiles')}
              textSumary={
                <p className="mt5 font-style-italic font-size-12">
                  Chỉ tải lên 1 file có dung lượng tối đa 100MB, định dạng .pdf,
                  .docs, .doc, .xls, .xlsx
                </p>
              }
              allowFileTypes={allowDocumentFileTypesLimit}
              isShowList={true}
              onChange={changeFile('MEETING', 'meetingFiles')}
            />
            <Input
              label="Tên cuộc họp"
              required
              placeholder="Nhập tên cuộc họp..."
              autoResize={true}
              type="textarea"
              rows={2}
              maxLength={2000}
              wrapperClassName="mb10"
              value={get(dataForm, 'title')}
              onChange={changeField('MEETING', 'title')}
              warning={get(validateErrors, 'title')}
            />
            <Row gutterVertical>
              <Col grid="half">
                <FormDatePicker
                  isRequired={true}
                  textLabel="Ngày ban hành văn bản"
                  inputClearable
                  inputIconClassName="icon2-date-frames"
                  inputWrapperClassName="mb10"
                  placeholder="Ngày ban hành văn bản"
                  popoverStretch={false}
                  selected={get(dataForm, 'executionDate')}
                  inputWarning={get(validateErrors, 'executionDate')}
                  onChange={value => {
                    changeField('MEETING', 'executionDate')(value)
                  }}
                />
              </Col>
              <Col grid="half">
                <Select
                  isRequired={true}
                  textLabel="Lãnh đạo chủ trì"
                  placeholder="Chọn lãnh đạo chủ trì"
                  isShowArrowDropdown={true}
                  dataSource={dataLanhDaoDonVi?.map(elm => ({
                    label: elm?.tenNhanVien,
                    value: elm?.chucDanhId,
                  }))}
                  inputIconClassName="icon2-user"
                  value={get(dataForm, 'mainResponsibilityId')}
                  inputWarning={get(validateErrors, 'mainResponsibilityId')}
                  onChange={changeField('MEETING', 'mainResponsibilityId')}
                />
              </Col>
            </Row>
          </Collapse>
          {!id ? (
            <div className="pt10">
              {/* Kết luận, chỉ đạo */}
              <Button
                className={classNames(
                  Classes.MINIMAL,
                  'cpc-button',
                  'cpc-button-link',
                  'mb10'
                )}
                intent={Intent.PRIMARY}
                onClick={() =>
                  toggleCollapse('showConclusion', dataCollapse?.showConclusion)
                }
              >
                <span className="text-uppercase font-size-13 font-weight-600 color-blue">
                  {'Kết luận, chỉ đạo'}
                </span>
                <Icon
                  classIcon={
                    dataCollapse?.showConclusion
                      ? 'icon2-arrow-up'
                      : 'icon2-arrow-down'
                  }
                  className={classNames('ml5', 'size-icon-10', 'd-flex')}
                />
              </Button>
              <Collapse isOpen={dataCollapse?.showConclusion}>
                {tasks.map(({ id, showTask, showExtra, data }, index) => (
                  <Fragment key={`${id}${index}`}>
                    {!!index && (
                      <span
                        style={{
                          display: 'block',
                          height: 5,
                          background: '#DFE6EA',
                        }}
                      />
                    )}
                    {size(tasks) > 1 && (
                      <header className="d-flex">
                        <Button
                          className={
                            'cpc-button cpc-button-link no-border transparent blue-text ph5'
                          }
                          onClick={toggleCollapseTask(id)}
                        >
                          <span className="mr5">{`Kết luận ${index + 1}`}</span>
                          <span
                            className={classNames(
                              'icon2-left size-icon-9 d-flex align-items-center justify-content-center',
                              {
                                'rotate-270': !showTask,
                                'rotate-90': showTask,
                              }
                            )}
                          ></span>
                        </Button>
                        <Button
                          className={classNames(
                            Classes.MINIMAL,
                            'cpc-button',
                            'cpc-form-button-right'
                          )}
                          intent={Intent.DANGER}
                          onClick={removeTask(id)}
                        >
                          <Icon classIcon="icon-bin" />
                        </Button>
                      </header>
                    )}

                    <Collapse isOpen={showTask}>
                      <Input
                        required={true}
                        disabled={fetching}
                        label="Nội dung kết luận"
                        onChange={changeField('TASK', id, 'title')}
                        placeholder="Nhập nội dung kết luận"
                        type="textarea"
                        rows={2}
                        maxLength={2000}
                        autoResize={true}
                        value={get(data, 'title')}
                        wrapperClassName="mb15"
                        labelButton={
                          <div style={{ marginLeft: 'auto' }}>
                            <Checkbox
                              className={classNames(Classes.SMALL, 'mb0')}
                              checked={get(data, 'requireReport', false)}
                              onChange={({ target: { checked } }) =>
                                changeField(
                                  'TASK',
                                  id,
                                  'requireReport'
                                )(checked)
                              }
                            >
                              <span className="font-size-13 font-weight-300">
                                Yêu cầu báo cáo Hoàn thành
                              </span>
                            </Checkbox>
                          </div>
                        }
                        warning={get(validateErrors, `${id}.title`)}
                      />
                      <PhongBanVaDonViThucHien
                        className="mb10"
                        isRequired={true}
                        column={column}
                        data={dataTreeOrganizationDepartmentStore}
                        taskId={id}
                        warning={get(validateErrors, `${id}.${CHU_TRI}`)}
                        dataAutoToggle={dataAutoToggle}
                      />
                      <Row gutterVertical>
                        <Col grid="half">
                          <FormDatePicker
                            textLabel="Ngày bắt đầu"
                            disabled={fetching}
                            inputClearable
                            inputIconClassName="icon2-date-frames"
                            inputWrapperClassName="mb10"
                            onChange={value => {
                              changeField('TASK', id, 'executionDate')(value)
                            }}
                            maximumDate={
                              get(data, 'deadline')
                                ? moment(get(data, 'deadline')).toDate()
                                : undefined
                            }
                            placeholder="Chọn ngày bắt đầu"
                            popoverStretch={false}
                            popoverPosition={Position.TOP_LEFT}
                            selected={get(data, 'executionDate')}
                          />
                        </Col>
                        <Col grid="half">
                          <DateAdvanced
                            textLabel="Hạn thực hiện"
                            disabled={fetching}
                            inputClearable
                            inputIconClassName="icon2-date-frames"
                            inputWrapperClassName="mb10"
                            placeholder="Chọn hạn thực hiện"
                            popoverStretch={false}
                            selected={get(data, 'deadline')}
                            inputWarning={get(validateErrors, `${id}.deadline`)}
                            minimumDate={
                              get(data, 'executionDate')
                                ? moment(get(data, 'executionDate')).toDate()
                                : moment().toDate()
                            }
                            popoverPosition={Position.TOP_LEFT}
                            onChange={value => {
                              changeField('TASK', id, 'deadline')(value)
                            }}
                          />
                        </Col>
                      </Row>

                      {/* Thông tin bổ sung */}
                      <Collapse isOpen={showExtra}>
                        <Input
                          disabled={fetching}
                          label="Mô tả"
                          onChange={changeField('TASK', id, 'description')}
                          placeholder="Nhập..."
                          rows={2}
                          maxLength={500}
                          autoResize={true}
                          style={{ maxHeight: 'unset' }}
                          type="textarea"
                          value={get(data, 'description')}
                          wrapperClassName="mb10"
                        />
                        <Row gutterVertical className="mb10">
                          <Col grid="half">
                            <Select
                              textLabel="Mức độ ưu tiên"
                              isShowArrowDropdown={true}
                              dataSource={toPairs(priorities).map(
                                ([value, label]) => ({ value, label })
                              )}
                              disabled={fetching}
                              inputIconClassName="icon-flag"
                              onChange={changeField('TASK', id, 'priority')}
                              placeholder="Chọn mức độ ưu tiên"
                              value={get(data, 'priority')}
                            />
                          </Col>
                          <Col grid="half">
                            <RemindSelect
                              textLabel="Thời gian nhắc việc"
                              inputIconClassName="icon2-date-frames"
                              deadline={get(data, 'deadline')}
                              disabled={fetching}
                              onChange={changeField('TASK', id, 'workReminder')}
                              value={get(data, 'workReminder')}
                              placeholder="Thời gian nhắc việc"
                              popoverPosition={Position.BOTTOM_RIGHT}
                            />
                          </Col>
                        </Row>

                        <CheckboxSelect
                          textLabel="Loại công việc"
                          disabled={fetching}
                          inputClearable
                          inputIconClassName="icon2-tag"
                          inputWrapperClassName="mb10"
                          placeholder="Loại công việc"
                          popoverPosition={Position.TOP_LEFT}
                          onChange={changeField('TASK', id, 'tags')}
                          dataSource={tags.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))}
                          value={get(data, 'tags', [])}
                        />

                        {!!size(get(data, 'referenceFiles', [])) && (
                          <>
                            <label className="cpc-detail-label">
                              File công văn
                            </label>
                            <FileGroup list={get(data, 'referenceFiles')} />
                          </>
                        )}

                        {!!size(get(data, 'attachmentFiles', [])) &&
                          !!size(
                            attachmentFilesFromDocument(
                              get(data, 'attachmentFiles', []),
                              true
                            )
                          ) && (
                            <>
                              <label className="cpc-detail-label">
                                File đính kèm từ công văn
                              </label>
                              <FileGroup
                                list={attachmentFilesFromDocument(
                                  get(data, 'attachmentFiles', []),
                                  true
                                )}
                                onClose={removeAttachmentFiles(id)}
                              />
                            </>
                          )}

                        {((!!size(get(data, 'attachmentFiles', [])) &&
                          !!size(
                            attachmentFilesFromDocument(
                              get(data, 'attachmentFiles', []),
                              false
                            )
                          )) ||
                          !!size(files[id])) && (
                          <>
                            <label className="cpc-detail-label">
                              File đính kèm
                            </label>
                            <FileGroup
                              list={attachmentFilesFromDocument(
                                get(data, 'attachmentFiles', []),
                                false
                              )}
                              listUpload={files[id]}
                              onClose={removeAttachmentFiles(id)}
                              showClose
                            />
                          </>
                        )}

                        <Upload
                          textLabel="File đính kèm"
                          accept={documentTypes.toString()}
                          fileList={get(files, id, [])}
                          wrapperClassName="mb10"
                          textSumary={
                            <p className="mt5 font-style-italic font-size-12">
                              Tải file có dung lượng tối đa 100MB, định dạng
                              .pdf, .docs, .doc, .xls, .xlsx, .ppt, .pptx
                            </p>
                          }
                          isShowList={false}
                          disabled={fetching}
                          allowFileTypes={allowDocumentFileTypes}
                          onChange={changeFile('TASK', id)}
                        />
                      </Collapse>
                      <div className="d-flex align-items-center justify-content-center pt5 pb15">
                        <Button
                          className={
                            'cpc-button cpc-button-link no-border transparent blue-text ph5'
                          }
                          onClick={toggleCollapseExtra(id)}
                        >
                          <span className="mr5">
                            {showExtra ? 'Thu gọn' : 'Mở rộng'}
                          </span>
                          <span
                            className={classNames(
                              'icon2-left size-icon-9 d-flex align-items-center justify-content-center',
                              {
                                'rotate-270': !showExtra,
                                'rotate-90': showExtra,
                              }
                            )}
                          ></span>
                        </Button>
                      </div>
                    </Collapse>
                  </Fragment>
                ))}
              </Collapse>
            </div>
          ) : (
            ''
          )}
        </div>
        {!id && (
          <div className={'d-flex align-items-center bg-white border-top pv30'}>
            <div className="ph15">
              <Button className={'cpc-button green-no-bg'} onClick={addTask}>
                <Icon classIcon="icon-Plus" className="size-icon-12 mr5" />
                <span>Thêm nội dung kết luận</span>
              </Button>
            </div>
          </div>
        )}
        <div
          className={
            'd-flex align-items-center justify-content-center gap-15 bg-white border-radius-4 border-top ph15'
          }
        >
          <Button
            className={
              'cpc-button white-border-gray uppercase font-size-13 ph10 min-width-100'
            }
            disabled={isLoading}
            onClick={() => history.goBack()}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={
              'cpc-button blue uppercase font-size-13 ph10 min-width-80'
            }
            disabled={isLoading || isDisabledButtonDefault}
            onClick={id ? submitUpdate : submitCreate}
            loading={isLoading}
          >
            Lưu
            <Icon classIcon="icon-save" className="ml5" />
          </Button>
        </div>
        <ActionFixed
          isLoading={isLoading}
          isDisabled={isLoading || isDisabledButtonDefault}
          onClose={() => history.goBack()}
          onSubmit={id ? submitUpdate : submitCreate}
        />
        <Dialog
          buttonUppercase
          isOpen={isShowDialog}
          cancelClassName="pl20 pr20"
          cancelText="Hủy"
          confirmClassName="pl20 pr20"
          confirmText="Xóa"
          onCancel={onCloseDialog}
          onConfirm={onDeleteTask}
          onClose={onCloseDialog}
          title={'Xóa kết luận'}
          textCenter
        >
          <Card className="mt20 mb20">{'Anh/Chị muốn xóa kết luận này?'}</Card>
        </Dialog>
      </div>
    </MasterLayout>
  )
}

TaskThongBaoKLCHCreate.propTypes = {
  mainUnitId: PropTypes.string,
  mainDepartmentId: PropTypes.string,
  hasShowAllDeparment: PropTypes.bool,
  dataLanhDaoDonVi: PropTypes.array,
  dataThongTinDonVi: PropTypes.object,
  dataTreeOrganizationDepartmentStore: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    mainUnitId: get(state, 'auth.mainUnitId'),
    mainDepartmentId: get(state, 'auth.mainDepartmentId'),
    hasShowAllDeparment: get(state, 'auth.permission', []).includes(
      Permission.THONGBAOKETLUANHDTV
    ),
    dataLanhDaoDonVi: get(state, 'common.dataLanhDaoDonVi'),
    dataThongTinDonVi: get(state, 'common.dataThongTinDonVi'),
    dataTreeOrganizationDepartmentStore: get(
      state,
      'common.dataTreeOrganizationDepartmentStore'
    ),
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ searchFileCongVanV2 }, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(TaskThongBaoKLCHCreate))
)
