import * as POPUP from '../../../constants/Popup'

import { Action, Toast } from '../../../constants/MessageForm'
import {
  EmptyDataTable,
  ErrorLoadingTable,
  HeadingTableSort,
  LoadingTable,
  PopupConfirm,
  TablePagination,
} from '../../../components/common'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'

import { Intent } from '@blueprintjs/core'
import React from 'react'
import { headers } from '../../../constants/Table'
import { get } from 'lodash'
class AppliedUnitPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      isDataEmpty: false,
      isOpenPopup: false,
      isMove: false,
      deleteItem: null,
      loadingAfterDelete: false,
    }
  }

  togglePopup = (item = null) => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      deleteItem: item,
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.setState({
        data: arrayMove(this.state.data, oldIndex, newIndex),
        isMove: true,
      })
    }
  }

  handleDelete = () => {
    const { match, page, deleteUnit, pageChange, showToast } = this.props
    const { deleteItem } = this.state
    const id = get(match, 'params.id')

    if (!id) {
      return window.location.replace('/')
    }

    deleteUnit(id, deleteItem.donViId).then(async res => {
      const { data } = this.state
      const status = !!get(res, 'payload.data.result')
      showToast({
        message: status
          ? Toast.SUCCESS(Action.DELETE)
          : Toast.FAIL(Action.DELETE),
        intent: status ? Intent.SUCCESS : Intent.DANGER,
      })
      if (status) {
        await this.setState({ loadingAfterDelete: true })
        pageChange(data.length > 0 ? page : page - 1)
      }
    })
    this.togglePopup()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        data: nextProps.data,
        isDataEmpty: !nextProps.data.length,
        loadingAfterDelete: false,
      })
    }
  }

  render() {
    const { isLoading, isError, data, totalPage, pageChange, page } = this.props
    const { loadingAfterDelete } = this.state

    const SortableItem = SortableElement(({ i, item }) => (
      <div className="table-rows no-event">
        <div className="table-cols-content width-col-5">
          <span className="order-content">{i < 10 ? '0' + i : i}</span>
        </div>
        <div className="table-cols-content width-col-15">
          <span className="order-content">{item.tenVietTat}</span>
        </div>
        <div className="table-cols-content width-col-55">
          <span className="text-content">{item.tenDonVi}</span>
        </div>
        <div className="table-cols-content width-col-15">
          <span className="item" onClick={() => this.togglePopup(item)}>
            <span className="icon-trash"></span>
          </span>
        </div>
      </div>
    ))

    const SortableList = SortableContainer(({ list }) => (
      <div className="sortableList">
        {!!list &&
          list.map((item, index) => (
            <SortableItem
              key={`item-${index}`}
              i={(page - 1) * 10 + index + 1}
              index={index}
              item={item}
              disabled
            />
          ))}
      </div>
    ))

    return (
      <div className="page-list-container page-list-role-container">
        <div className="table-container table-subcollap-container">
          <div className="table-scroll">
            <div className="collapse-panel-content">
              <div className="table-display">
                <div className="table-display-container">
                  {!!data.length && !isError && (
                    <HeadingTableSort data={headers.appliedUnits} />
                  )}
                  <div className="table-body-container">
                    {!!isLoading || !!loadingAfterDelete ? (
                      <LoadingTable />
                    ) : !!isError ? (
                      <ErrorLoadingTable />
                    ) : !!this.state.isDataEmpty ? (
                      <EmptyDataTable />
                    ) : (
                      <SortableList
                        helperClass="SortableHelper"
                        list={this.state.data}
                        onSortEnd={this.onSortEnd}
                        useDragHandle
                        lockAxis="y"
                      />
                    )}
                    <PopupConfirm
                      isOpen={this.state.isOpenPopup}
                      onClose={() => this.togglePopup()}
                      title={`${POPUP.XOA_DON_VI} ${
                        this.state.deleteItem
                          ? this.state.deleteItem.tenVietTat
                          : ''
                      }`}
                      text={POPUP.TEXT_BUTTON_SUBMIT}
                      onSubmit={this.handleDelete}
                    >
                      {POPUP.TEXT_SUBMIT_BODY}
                    </PopupConfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-footer">
            {totalPage > 1 && (
              <TablePagination
                pageCount={totalPage}
                pageChange={pageChange}
                page={page}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default AppliedUnitPage
