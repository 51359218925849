import * as types from '../constants/ActionTypes'

const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
}

export default function congVanDenThuHoi(state = initialState, action) {
  switch (action.type) {
    case types.CONGVANDENTHUHOI_GET:
      return {
        ...state,
        list: {
          page: action.payload.data.result.page,
          total: action.payload.data.result.total,
          items: action.payload.data.result && action.payload.data.result.items,
        },
      }
    default:
      return state
  }
}
