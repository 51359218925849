import React from 'react'
import { ListFilter } from '../../../components/common'

const UsingGroupListFilter = props => (
  <ListFilter
    inputPlaceholder="Nhập tên quy trình"
    dropdownDefaultText="Tất cả"
    onRemoveFilter={props.onSearch}
    onSearch={props.onSearch}
    {...props}
  />
)

export default UsingGroupListFilter
