import React, { Fragment, memo, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import {
  Classes,
  Button,
  Intent,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'
import classNames from 'classnames'
import { get, size, toPairs, isEmpty } from 'lodash'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { getCounterCongViecPhongBanCaNhan } from 'actions/index'
import { MasterLayout } from 'components/layout'
import { Permission } from 'components/auth'
import Title from 'components/title'
import { Row, Col, Collapse, Icon, Dialog, Card } from 'components/newCommon'
import {
  Input,
  Select,
  RemindSelect,
  CheckboxSelect,
  FormDatePicker,
  DateAdvanced,
} from 'components/newCommon2'

import { ducumentsType } from 'helpers/file'
import { priorities } from 'constants/task'
import { WORK_TYPE } from 'constants/Enum'
import PhongBanVaDonViThucHien from './components/phong-ban-don-vi-thuc-hien'
import Template from './components/confirm/template'
import UploadFile from 'components/upload-file'
import useTaskCreate from './hooks/use-task-create'

import { connect } from 'react-redux'
const TaskCreate = ({
  actions,
  history,
  match,
  roleId,
  hasShowAll,
  mainDepartmentId,
  dataAutoToggleDefault,
  dataTreeStore,
  mainUnitId,
}) => {
  const taskId = match.params?.id
  const {
    typeTree,
    tasks,
    fetching,
    taskDetail,
    validateErrors,
    newDataTreeStore,
    isLoadingTree,
    favoriteGroups,
    dataAutoToggle,
    tags,
    isDisabledButtonDefault,
    submitLoading,
    showTemplateTaskId,
    isShowDialog,
    fileDinhKem,
    onChangeRadio,
    handleCheckDisable,
    onToggleCollapseTask,
    onRemoveTask,
    onChangeField,
    onToggleShowTemplate,
    onChangeCheckbox,
    handleCheckActive,
    handleCheckIndeterminate,
    toggleCollapseExtra,
    addTask,
    submitCreate,
    submitUpdate,
    applyTemplate,
    onCloseDialog,
    onDeleteTask,
    onSetFileDinhKem,
  } = useTaskCreate({
    actions,
    history,
    match,
    roleId,
    mainUnitId,
    mainDepartmentId,
    hasShowAll,
    dataTreeStore,
  })

  const column = useMemo(() => {
    return [
      {
        title: 'Phòng ban/đơn vị',
        dataIndex: 'name',
      },
      {
        title: 'CT',
        isRequired: true,
        render: (record, _, __, taskId) => {
          const taskItem = tasks?.find(
            elm =>
              elm.id === taskId &&
              elm?.data?.[typeTree.CHU_TRI]?.id === record?.id
          )
          let isChecked = false
          if (!isEmpty(taskItem)) {
            isChecked = true
          }

          if (
            (!hasShowAll && record?.id === mainDepartmentId) ||
            !record.type ||
            record.type === 'ALL'
          ) {
            return ''
          }

          return (
            <Radio
              className={Classes.SMALL}
              checked={isChecked}
              onChange={() => onChangeRadio(record, taskId)}
            />
          )
        },
      },
      {
        title: 'PH',
        render: (record, _, __, taskId) => {
          const isDisable = handleCheckDisable(
            typeTree.PHOI_HOP,
            taskId,
            record
          )
          const isChecked = handleCheckActive(
            typeTree.PHOI_HOP,
            tasks,
            taskId,
            record
          )
          const isIndeterminate = handleCheckIndeterminate(
            typeTree.PHOI_HOP,
            tasks,
            taskId,
            record
          )
          if (!record.type) {
            return ''
          }

          return (
            <Checkbox
              className={Classes.SMALL}
              checked={isChecked || false}
              indeterminate={isIndeterminate && !isChecked}
              disabled={isDisable || false}
              onChange={e =>
                onChangeCheckbox(
                  typeTree.PHOI_HOP,
                  record,
                  taskId,
                  e,
                  isIndeterminate
                )
              }
            />
          )
        },
      },
      {
        title: 'TD',
        render: (record, _, __, taskId) => {
          const isDisable = handleCheckDisable(
            typeTree.THEO_DOI,
            taskId,
            record
          )
          const isChecked = handleCheckActive(
            typeTree.THEO_DOI,
            tasks,
            taskId,
            record
          )
          const isIndeterminate = handleCheckIndeterminate(
            typeTree.THEO_DOI,
            tasks,
            taskId,
            record
          )
          if (!record.type) {
            return ''
          }

          return (
            <Checkbox
              className={Classes.SMALL}
              checked={isChecked || false}
              indeterminate={isIndeterminate && !isChecked}
              disabled={isDisable || false}
              onChange={e =>
                onChangeCheckbox(
                  typeTree.THEO_DOI,
                  record,
                  taskId,
                  e,
                  isIndeterminate
                )
              }
            />
          )
        },
      },
    ]
  }, [
    tasks,
    typeTree,
    hasShowAll,
    mainDepartmentId,
    onChangeRadio,
    onChangeCheckbox,
    handleCheckActive,
    handleCheckDisable,
    handleCheckIndeterminate,
  ])

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="mt10">
        <Title
          name={taskId ? 'Sửa công việc' : 'Tạo công việc'}
          icon="icon-Nhap_cong_van_di"
        />
        <div
          className={'cpc-form bg-white pt30 pl30 pr30 border-radius-4 mt15'}
        >
          {tasks.map(({ id, showTask, showExtra, data }, index) => (
            <Fragment key={id}>
              {!!index && (
                <span
                  style={{
                    display: 'block',
                    height: 5,
                    background: '#DFE6EA',
                  }}
                />
              )}
              <div>
                {size(tasks) > 1 && (
                  <header className="d-flex">
                    <Button
                      className={
                        'cpc-button cpc-button-link no-border transparent blue-text ph5'
                      }
                      onClick={onToggleCollapseTask(id)}
                    >
                      <span className="mr5">{`Công việc ${index + 1}`}</span>
                      <span
                        className={classNames(
                          'icon2-left size-icon-9 d-flex align-items-center justify-content-center',
                          {
                            'rotate-270': !showTask,
                            'rotate-90': showTask,
                          }
                        )}
                      ></span>
                    </Button>
                    <Button
                      className={classNames(
                        Classes.MINIMAL,
                        'cpc-button',
                        'cpc-form-button-right'
                      )}
                      intent={Intent.DANGER}
                      onClick={onRemoveTask(id)}
                    >
                      <Icon classIcon="icon-bin" />
                    </Button>
                  </header>
                )}

                <Collapse isOpen={showTask}>
                  <Input
                    required
                    disabled={fetching}
                    label="Tiêu đề"
                    labelButton={
                      taskDetail?.workType !== WORK_TYPE.DOFFICE ? (
                        <div className="cpc-form-button-right">
                          <div
                            className="mr10"
                            style={{ display: 'inline-block' }}
                          >
                            <Checkbox
                              className={classNames(Classes.SMALL, 'mb0')}
                              checked={get(data, 'requireReport', false)}
                              onChange={({ target: { checked } }) =>
                                onChangeField(id, 'requireReport')(checked)
                              }
                            >
                              <span className="font-size-13 font-weight-300">
                                Yêu cầu báo cáo Hoàn thành
                              </span>
                            </Checkbox>
                          </div>
                          <Button
                            className={classNames(
                              Classes.MINIMAL,
                              'cpc-button',
                              'font-size-13',
                              'qlcv-green-color'
                            )}
                            onClick={() => onToggleShowTemplate(id)}
                            rightIconName="chevron-down"
                          >
                            {'Chọn từ mẫu có sẵn'}
                          </Button>
                        </div>
                      ) : null
                    }
                    onChange={onChangeField(id, 'title')}
                    placeholder="Nhập tiêu đề/trích yếu"
                    type="textarea"
                    rows={2}
                    maxLength={500}
                    autoResize={true}
                    value={get(data, 'title')}
                    wrapperClassName="mb15"
                    warning={get(validateErrors, `${id}.title`)}
                  />
                  {id && taskDetail?.isDofficeSend ? (
                    <table className="table-perform mb10 font-size-13">
                      <tbody>
                        <tr>
                          <td>Thực hiện</td>
                          <td>CT</td>
                        </tr>
                        <tr>
                          <td>{taskDetail?.mainResponsibility?.name}</td>
                          <td>
                            <Radio
                              className={classNames(Classes.SMALL, 'p0 m0')}
                              style={{
                                display: 'inline-block',
                                width: '16px',
                              }}
                              checked={true}
                              onChange={() => {}}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <div className="tree-organization-task">
                      <div className="save-favorite-group">
                        <Checkbox
                          className={classNames(Classes.SMALL, 'mb0')}
                          onChange={e =>
                            onChangeField(
                              id,
                              'isFavoriteGroupSave'
                            )(e?.target?.checked)
                          }
                          checked={data?.isFavoriteGroupSave || false}
                        >
                          <span className="font-size-13 font-weight-300">
                            Lưu nhóm thường giao việc
                          </span>
                        </Checkbox>
                      </div>
                      <PhongBanVaDonViThucHien
                        className="mb10"
                        isRequired={true}
                        isLoading={fetching || isLoadingTree}
                        column={column}
                        data={newDataTreeStore}
                        dataFavorite={favoriteGroups}
                        isDisplayFavoriteGroup={
                          !taskDetail?.workType ||
                          (taskDetail?.workType &&
                            (taskDetail?.workType === WORK_TYPE.NORMAL ||
                              (taskDetail?.workType === WORK_TYPE.EOFFICE &&
                                !hasShowAll) ||
                              (taskDetail?.workType === WORK_TYPE.MEETING &&
                                !hasShowAll) ||
                              (taskDetail?.workType === WORK_TYPE.DOFFICE &&
                                !hasShowAll)))
                        }
                        dataAutoToggle={[
                          ...dataAutoToggle,
                          ...dataAutoToggleDefault,
                        ]}
                        taskId={id}
                        warning={get(
                          validateErrors,
                          `${id}.${typeTree.CHU_TRI}`
                        )}
                        isHideChild={
                          (hasShowAll &&
                            (taskDetail?.workType === WORK_TYPE.MEETING ||
                              taskDetail?.workType === WORK_TYPE.EOFFICE)) ||
                          (hasShowAll &&
                            taskDetail?.workType === WORK_TYPE.DOFFICE) ||
                          (hasShowAll &&
                            (taskDetail?.workType === WORK_TYPE.SERVICE_DESK ||
                              taskDetail?.workType === WORK_TYPE.CHAT))
                        }
                      />
                    </div>
                  )}
                  <Row gutterVertical>
                    <Col grid="quarter">
                      <FormDatePicker
                        textLabel="Ngày bắt đầu"
                        disabled={fetching}
                        inputClearable
                        inputIconClassName="icon2-date-frames"
                        inputWrapperClassName="mb10"
                        onChange={value => {
                          onChangeField(id, 'executionDate')(value)
                        }}
                        minimumDate={moment().toDate()}
                        maximumDate={
                          get(data, 'deadline')
                            ? moment(get(data, 'deadline')).toDate()
                            : undefined
                        }
                        placeholder="Chọn ngày bắt đầu"
                        popoverStretch={false}
                        popoverPosition={Position.TOP_LEFT}
                        selected={get(data, 'executionDate')}
                      />
                    </Col>
                    <Col grid="quarter">
                      <DateAdvanced
                        textLabel="Hạn thực hiện"
                        disabled={fetching}
                        inputClearable
                        inputIconClassName="icon2-date-frames"
                        inputWrapperClassName="mb10"
                        placeholder="Chọn hạn thực hiện"
                        popoverStretch={false}
                        selected={get(data, 'deadline')}
                        inputWarning={get(validateErrors, `${id}.deadline`)}
                        minimumDate={
                          get(data, 'executionDate')
                            ? moment(get(data, 'executionDate')).toDate()
                            : moment().toDate()
                        }
                        popoverPosition={Position.TOP_RIGHT}
                        onChange={value => {
                          onChangeField(id, 'deadline')(value)
                        }}
                      />
                    </Col>
                    <Col grid="quarter">
                      <Select
                        textLabel="Mức độ ưu tiên"
                        dataSource={toPairs(priorities).map(
                          ([value, label]) => ({ value, label })
                        )}
                        disabled={fetching}
                        inputIconClassName="icon-flag"
                        onChange={onChangeField(id, 'priority')}
                        placeholder="Chọn mức độ ưu tiên"
                        value={get(data, 'priority')}
                      />
                    </Col>
                    <Col grid="quarter">
                      <RemindSelect
                        textLabel="Thời gian nhắc việc"
                        inputIconClassName="icon2-date-frames"
                        deadline={data?.deadline}
                        disabled={fetching}
                        onChange={onChangeField(id, 'workReminder')}
                        value={get(data, 'workReminder')}
                        placeholder="Thời gian nhắc việc"
                        popoverPosition={Position.BOTTOM_RIGHT}
                      />
                    </Col>
                  </Row>

                  {/* Thông tin bổ sung */}
                  <Collapse isOpen={showExtra}>
                    <Input
                      disabled={fetching}
                      label="Mô tả"
                      onChange={onChangeField(id, 'description')}
                      placeholder="Nhập..."
                      rows={2}
                      maxLength={500}
                      autoResize={true}
                      style={{ maxHeight: 'unset' }}
                      type="textarea"
                      value={get(data, 'description')}
                      wrapperClassName="mb10"
                    />
                    <CheckboxSelect
                      textLabel="Loại công việc"
                      disabled={fetching}
                      inputClearable
                      inputIconClassName="icon2-tag"
                      inputWrapperClassName="mb10"
                      placeholder="Loại công việc"
                      onChange={onChangeField(id, 'tags')}
                      dataSource={tags.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      value={get(data, 'tags', [])}
                    />
                    {data?.referenceFiles?.length > 0 ?? false ? (
                      <div className="mb10">
                        <label className="text-13-600">File công văn</label>
                        <UploadFile
                          key={1}
                          className="mt5"
                          hasAddFile={false}
                          defaultFiles={data?.referenceFiles}
                          isDisabled
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <label className="text-13-600">File đính kèm</label>
                    <UploadFile
                      key={2}
                      className="mt5"
                      maxSize={100}
                      fileTypeAllow={ducumentsType()}
                      fileTypeAllowShortcut={[
                        '.pdf',
                        '.docs',
                        '.doc',
                        '.xls',
                        '.xlsx',
                        '.ppt',
                        '.pptx',
                      ]}
                      onLoadFile={files => onSetFileDinhKem(files, id)}
                      defaultFiles={data?.attachmentFiles}
                      isReset={fileDinhKem?.[id]?.length === 0}
                    />
                    {!taskId && (
                      <div className="mt15">
                        <Checkbox
                          className={Classes.SMALL}
                          onChange={({ target: { checked } }) =>
                            onChangeField(id, 'isSaveTemplate')(checked)
                          }
                          checked={get(data, 'isSaveTemplate', false)}
                        >
                          <span className="font-size-13">Lưu mẫu</span>
                        </Checkbox>
                      </div>
                    )}
                  </Collapse>
                  <div className="d-flex align-items-center justify-content-center pt5 pb15">
                    <Button
                      className={
                        'cpc-button cpc-button-link no-border transparent blue-text ph5'
                      }
                      onClick={toggleCollapseExtra(id)}
                    >
                      <span className="mr5">
                        {showExtra ? 'Thu gọn' : 'Mở rộng'}
                      </span>
                      <span
                        className={classNames(
                          'icon2-left size-icon-9 d-flex align-items-center justify-content-center',
                          {
                            'rotate-270': !showExtra,
                            'rotate-90': showExtra,
                          }
                        )}
                      ></span>
                    </Button>
                  </div>
                </Collapse>
              </div>
            </Fragment>
          ))}
        </div>
        {!taskId && (
          <div className={'d-flex align-items-center bg-white border-top pv30'}>
            <div className="ph15">
              <Button
                className={'cpc-button green-no-bg theme-sm'}
                onClick={addTask}
              >
                <span className="text-uppercase">Thêm công việc</span>
                <Icon classIcon="icon-Plus" className="size-icon-12 ml5" />
              </Button>
            </div>
          </div>
        )}
        <div
          className={
            'd-flex align-items-center justify-content-center gap-15 bg-white border-radius-4 border-top ph15'
          }
        >
          <Button
            className={
              'cpc-button white-border-gray uppercase font-size-13 ph10 min-width-100'
            }
            disabled={fetching}
            onClick={() => history.goBack()}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={
              'cpc-button blue uppercase font-size-13 ph10 min-width-80'
            }
            disabled={fetching || isDisabledButtonDefault}
            onClick={taskId ? submitUpdate : submitCreate}
            loading={submitLoading}
          >
            Lưu
            <Icon classIcon="icon-save" className="ml5" />
          </Button>
        </div>
        <Template
          isOpen={showTemplateTaskId}
          onClose={() => onToggleShowTemplate()}
          onConfirm={values => {
            applyTemplate(values)
            onToggleShowTemplate()
          }}
        />
        <Dialog
          buttonUppercase
          isOpen={isShowDialog}
          cancelClassName="pl20 pr20"
          cancelText="Hủy"
          confirmClassName="pl20 pr20"
          confirmText="Xóa"
          onCancel={onCloseDialog}
          onConfirm={onDeleteTask}
          onClose={onCloseDialog}
          title={'Xóa công việc'}
          textCenter
        >
          <Card className="mt20 mb20">{'Anh/Chị muốn xóa công việc này?'}</Card>
        </Dialog>
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => {
  const hasShowAll = state.auth?.permission.includes(
    Permission.CONGVANDEN_CHIDAO
  )
  let dataAutoToggleDefault = []
  if (!hasShowAll) {
    dataAutoToggleDefault = [get(state, 'auth.mainDepartmentId')]
  }

  return {
    currentUserId: state.auth?.user?.nhanVienId,
    hasShowAll,
    roleId: state.auth?.roleId,
    mainDepartmentId: state.auth?.mainDepartmentId,
    mainUnitId: state.auth?.mainUnitId,
    dataAutoToggleDefault,
    dataTreeStore: state?.common?.dataTree,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCounterCongViecPhongBanCaNhan,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(TaskCreate))
)
