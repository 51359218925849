import React from 'react'
import { MasterLayout } from '../../components/layout'
import { FormAddUnitCategory } from '../../components/System'
import { HeadingPage } from '../../components/common'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'

const SUA_DON_VI = 'Sửa đơn vị'
const THEM_MOI_DON_VI = 'Thêm mới đơn vị'
class AddUnitCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    donViId: this.props.match.params.id,
    unit: null,
  }

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      this.props.actions.getCommonFetchUnitItem(this.state.donViId)
    }
    this.props.actions.getCategoryUnitMultiLevel()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.unit.item !== this.props.unit.item) {
      this.setState({ unit: nextProps.unit.item })
    }
  }

  handleSubmit(isSuccess = false) {
    if (isSuccess === true) {
      this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    return this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={this.state.unit ? SUA_DON_VI : THEM_MOI_DON_VI}
              iconPage="icon-departments"
            />
            <FormAddUnitCategory
              actions={this.props.actions}
              unit={this.state.unit}
              unitSelect={this.props.unitSelect.items}
              onSubmit={this.handleSubmit}
              match={this.props.match}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  unit: {
    ...state.unitItem,
    item: state.common.unitItem,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUnitCategoryPage)
