import React from 'react'
import PropTypes from 'prop-types'
import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItemProject } from '../../components/SignNumber'
import { GridView } from '../../components/common'
import { connect } from 'react-redux'
import { Intent, Position, Toaster } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import { Redirect } from 'react-router-dom'

import Pagination from 'components/Pagination'

class ListSignNumberProjectsPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      sort: undefined,
      listStatus: [
        {
          name: 'Chưa duyệt',
          value: 0,
        },
        {
          name: 'Không duyệt',
          value: 1,
        },
        {
          name: 'Đang duyệt',
          value: 2,
        },
        {
          name: 'Đã duyệt',
          value: 6,
        },
      ],
      isEmpty: false,
      page: 1,
      isLoading: false,
      isNeedSignNumber: true,
      isRedirect: false,
      redirectTo: null,
      fileId: null,
      soKyHieu: null,
      isError: false,
    }

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handleClickNeddSignNumber = this.handleClickNeddSignNumber.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickDetail = this.handleClickDetail.bind(this)
  }

  static propTypes = {
    signNumberProjects: PropTypes.object.isRequired,
    staffs: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  componentDidMount() {
    this.props.actions.projectsListByMemberIdGet(this.props.auth.roleId)
    this.props.actions.commonFetchDepartmentsLeader()
    this.props.actions.commonFetchStaffByUnitId(this.props.auth.mainUnitId)
    if (this.props.location.state && this.props.location.state.saved) {
      this.toaster.show({
        message: 'Lưu thành công!',
        intent: Intent.SUCCESS,
        autoFocus: true,
        timeout: 2000,
      })
    }

    let preData = Tool.Utils.preData('ListSignNumberProjectsPage')
    let state = this.state

    if (preData) {
      state.filter = preData.filter
      state.page = preData.page
      state.sort = preData.sort
      state.isNeedSignNumber = preData.canKySo
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination(page) {
    this.submitFilter({}, page, this.state.sort, this.state.isNeedSignNumber)
    this.setState({ page })
  }

  handleClickNeddSignNumber = () => {
    this.setState({ isNeedSignNumber: !this.state.isNeedSignNumber }, () => {
      this.submitFilter(
        this.state.filter,
        Types.PAGE,
        null,
        this.state.isNeedSignNumber
      )
    })
  }

  handleSubmit = (filter, type) => {
    let isNeedSignNumber = this.state.isNeedSignNumber
    if (type === 'reset') {
      isNeedSignNumber = true
    }
    this.submitFilter(filter, Types.PAGE, null, isNeedSignNumber)
  }

  submitFilter(e = {}, page = 1, sort, canKySo = true) {
    const filter = Object.assign({}, this.state.filter, e)

    this.setState({ filter, page, isLoading: true }, () => {
      this.props.actions
        .signNumberProjectGet({ filter }, page, sort, canKySo)
        .then(res => {
          Tool.Utils.preData('ListSignNumberProjectsPage', {
            filter,
            page,
            sort,
            canKySo,
          })
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }

          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({ isEmpty, isError })
        })
        .then(() => {
          this.setState({ isLoading: false, isNeedSignNumber: canKySo })
        })
    })
  }

  handleSubmitRemoveItem = id => {
    let total = 0
    let page = this.state.page
    if (
      this.props.signNumberProjects &&
      this.props.signNumberProjects.list &&
      this.props.signNumberProjects.list.total
    ) {
      total = this.props.signNumberProjects.list.total
    }

    this.props.actions
      .signNumberProjectDelete(id)
      .then(res => {
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
        if (total > 0) {
          total -= 1
        }
        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }

        if (page === 0) {
          page = 1
        }
        this.props.actions.getNumberProjectSignNumberPage()
        this.submitFilter(
          this.state.filter,
          page,
          this.state.sort,
          this.state.isNeedSignNumber
        )
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
          intent: Intent.DANGER,
        })
      })
  }

  handleSort(sort) {
    this.submitFilter(
      this.state.filter,
      this.state.page,
      sort,
      this.state.isNeedSignNumber
    )
  }

  handleClickDetail = data => {
    if (!data) {
      return
    }
    this.setState({
      isRedirect: true,
      redirectTo: `/ky-so/du-an/chi-tiet/${data.kySoId}`,
      fileId: data.fileId,
      soKyHieu: data.soKyHieu,
    })
  }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
            state: {
              fileId: this.state.fileId,
              soKyHieu: this.state.soKyHieu,
              from: this.props.location.pathname,
            },
          }}
        />
      )
    }
    return (
      <MasterLayout typeSidebar="signNumber">
        <div>
          {/* Heading */}
          <div className="heading-page">
            <div className="row">
              <div className="col-xs-12">
                <h3 className="text-heading-page">
                  <span className="icon-Kyso_Congvandi icon-heading"></span>
                  <span className="text-heading">Ký số mở rộng</span>
                </h3>
              </div>
            </div>
          </div>

          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <ListFilter
              filter={this.state.filter}
              staffs={this.props.staffs}
              status={this.state.listStatus}
              onSubmit={this.handleSubmit}
              isNeedSignNumber={this.state.isNeedSignNumber}
              onClickNeddSignNumber={this.handleClickNeddSignNumber}
              readOnly={this.props.readOnly}
              type="signNumberProject"
              projects={this.props.projects}
            />

            <div className="table-container">
              <GridView
                isLoading={this.state.isLoading}
                classNames={'pt-table'}
                page={this.state.page}
                headers={headers.listSignNumberProjectPage}
                itemShape={ListItemProject}
                data={this.props.signNumberProjects.list.items}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                onClickDetail={this.handleClickDetail}
                match={this.props.match}
                onFilter={this.handleSort}
                isEmpty={this.state.isEmpty}
                auth={this.props.auth}
                isError={this.state.isError}
              />

              {!this.state.isEmpty &&
                !this.state.isError &&
                !this.state.isLoading && (
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props.signNumberProjects.list.total /
                        Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                )}
            </div>
          </div>
          <Toaster
            className="message-popup-delete"
            position={Position.TOP_RIGHT}
            ref={this.refHandlers.toaster}
          />
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  signNumberProjects: {
    ...state.signNumberProjects,
    list: {
      ...state.signNumberProjects.list,
      items: state.signNumberProjects.list.items.map(
        id => state.entities.signNumberProjects[id]
      ),
    },
  },

  projects: {
    ...state.projects,
    list: {
      ...state.projects.list,
      items: state.projects.list.items.map(id => state.entities.projects[id]),
    },
  },

  auth: state.auth,

  staffs: state.common.staffs,

  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListSignNumberProjectsPage)
