import React, {
  memo,
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import { get, find } from 'lodash'
import csx from 'classnames'
import moment from 'moment'

import { Card, DatePicker } from 'components/newCommon'
import DropdownWrapper from './DropdownWrapper'
import { UNSET, WORK_REMINDER } from 'constants/Enum'

const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'

const selectData = [
  { value: UNSET, label: 'Không nhắc' },
  { value: WORK_REMINDER.TRUOC_MOT_NGAY, label: 'Trước 1 ngày' },
  { value: WORK_REMINDER.TRUOC_HAI_NGAY, label: 'Trước 2 ngày' },
  { value: WORK_REMINDER.TRUOC_BA_NGAY, label: 'Trước 3 ngày' },
]

const typeToNumber = {
  [WORK_REMINDER.TRUOC_MOT_NGAY]: 1,
  [WORK_REMINDER.TRUOC_HAI_NGAY]: 2,
  [WORK_REMINDER.TRUOC_BA_NGAY]: 3,
}

const RemindSelect = ({
  deadline = null,
  onChange = () => {},
  value: selectValue,
  ...props
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const [selected, setSelected] = useState()
  const [dropdownOpen, _setDropdownOpen] = useState(false)

  const _toggleDropdown = useCallback(open => {
    mountedSet(_setDropdownOpen, open)
  }, [])

  const onSelect = value => () => {
    const newSelected = {
      type: value,
      date: null,
    }
    if (value !== UNSET) {
      newSelected.date = moment(deadline)
        .subtract(typeToNumber[value], 'days')
        .set('hour', 7)
        .set('minute', 0)
    }
    mountedSet(setSelected, newSelected)
    onChange(value !== UNSET ? newSelected : undefined)
    _toggleDropdown(false)
  }

  const changeDate = date => {
    const newSelected = {
      type: date ? WORK_REMINDER.TUY_CHINH : UNSET,
      date: date,
    }
    mountedSet(setSelected, newSelected)
    onChange(date ? newSelected : null)
    if (!date) {
      _toggleDropdown(false)
    }
  }

  const selectDisabled = useMemo(
    () => value => {
      if (!deadline) {
        return true
      }

      if (value === UNSET) {
        return false
      }

      if (
        moment(deadline).subtract(typeToNumber[value], 'days') <
        moment().endOf('d')
      ) {
        return true
      }

      return false
    },
    [deadline]
  )

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    mountedSet(setSelected, selectValue)
  }, [selectValue])

  return (
    <DropdownWrapper
      {...props}
      isModal={true}
      open={dropdownOpen}
      onDropdownVisibleChange={_toggleDropdown}
      value={
        !selected
          ? undefined
          : get(selected, 'type') !== WORK_REMINDER.TUY_CHINH
          ? get(find(selectData, { value: get(selected, 'type') }), 'label')
          : moment(get(selected, 'date')).format(DATETIME_FORMAT)
      }
    >
      <Card className="popup">
        <Card className="cpc-list ph5">
          {selectData.map(({ value, label }) => (
            <Card
              className={csx(
                'cpc-list-item',
                'font-size-13',
                'ph5 pv20',
                'has-event',
                {
                  active: value === get(selected, 'type'),
                  disabled: selectDisabled(value),
                }
              )}
              key={value}
              onClick={onSelect(value)}
            >
              {label}
            </Card>
          ))}
        </Card>

        <Card borderTop className="p10">
          <div className={'remind-select-datetime'}>
            <DatePicker
              placeholder="Tùy chỉnh"
              popperPlacement={'top-start'}
              dateFormat="DD/MM/YYYY H:mm"
              timeCaption="Giờ"
              showTimeSelect={true}
              timeIntervals={30}
              timeFormat="HH:mm"
              isClearable={false}
              selected={
                selected?.type === WORK_REMINDER.TUY_CHINH
                  ? selected?.date
                  : undefined
              }
              minDate={moment()}
              maxDate={deadline ? moment(deadline) : undefined}
              disabled={false}
              onChange={changeDate}
            />
          </div>
        </Card>
      </Card>
    </DropdownWrapper>
  )
}

export default memo(RemindSelect)
