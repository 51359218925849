import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { Cookies, withCookies } from 'react-cookie'
import { ContextMenu, Button, Tooltip } from '@blueprintjs/core'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'

import { MasterLayout } from 'components/layout'
import { Icon } from 'components/newCommon'
import { Tabs } from 'components/newCommon2'
import { List, Kanban, Calendar, CalendarTimeline } from './Type'
import {
  TaskForm,
  GiveTaskForm,
  TaskTagsForm,
  ReminderForm,
  GiveTaskFormDOffice,
  Accept,
} from './Form'
import TaskDetail from './Details/Task'
import FlowWorks from './Details/FlowWorks'
import AcceptHistory from './Details/AcceptHistory'
import DeleteTaskConfirm from './Confirm/DeleteTask'
import ApproveTask from './Confirm/ApproveTask'
import SaveDocumentPopup from './DocumentCabinet/SaveDocumentCabinet'
import {
  getThongKeLoaiGiaoViec,
  getDsCongViec,
  getMyFavoriteGroups,
  getDsTag,
  getCayPhongBan,
  getDsNhanVien,
  getDsCongViecKanban,
  getThongKeTrangThaiCongViec,
  postWorkReadAll,
  getPhongBanNhanVien,
  saveTree,
  getWorkTypes,
  getDsCongViecTimeline,
} from 'actions/task'
import requestAction from 'helpers/request'
import {
  RESPONSIBILITY_FILTER,
  WORK_STATUS,
  ASSIGN_TYPE,
  STATISTIC_WORK_RESPONSIBILITY,
} from 'constants/Enum'
import { Permission } from 'components/auth'
import { Action, Toast } from 'constants/MessageForm'

const cookies = new Cookies()
const expiryDate = new Date(Date.now() + 60 * 24 * 60 * 60 * 1000) // 60 day
const tagsNumber = 2 // Số = với length tabsOrigin, dùng để kiểm tra trạng thái cookies menu tags

const requestDateFormat = 'YYYY/MM/DD'

const tabKeys = {
  list: 'LIST',
  kanban: 'KANBAN',
  calendar: 'CALENDAR',
  timeline: 'TIMELINE',
}

const pageTypes = {
  congViecDOffice: 'cong-viec-d-office',
  toiGiao: 'toi-giao',
  duocGiao: 'duoc-giao',
  theoDoi: 'theo-doi',
}

const pageTitles = {
  [pageTypes.congViecDOffice]: 'Công việc D-Office',
  [pageTypes.toiGiao]: 'Tôi giao',
  [pageTypes.duocGiao]: 'Được giao',
  [pageTypes.theoDoi]: 'Theo dõi',
}

const pageActions = {
  chiTiet: 'chi-tiet',
  taoMoi: 'them-moi',
  chinhSua: 'chinh-sua',
  giaoViec: 'giao-viec',
  suaGiaoViec: 'sua-giao-viec',
  nhacViec: 'nhac-viec',
  ganTag: 'tag',
  huyDuyet: 'huy-duyet',
  xoa: 'xoa',
  luuTu: 'luu-tu-tai-lieu',
  luongCongViec: 'luong-cong-viec',
  giaoViecDOffice: 'giao-viec-d-office',
  suaGiaoViecDOffice: 'sua-giao-viec-d-office',
  xacNhan: 'xac-nhan',
  lichSuXacNhan: 'lich-su-xac-nhan',
}

const pageFilter = {
  keys: {
    title: 'Title',
    responibility: 'responsibilityFilter',
    status: 'Status',
    page: 'page',
    workType: 'WorkType',
    workDeadLine: 'WorkDeadLine',
    fromDate: 'FromDate',
    toDate: 'ToDate',
    tag: 'TagIds',
  },
  values: {
    [pageTypes.congViecDOffice]: RESPONSIBILITY_FILTER.CONGVIEC_D_OFFICE,
    [pageTypes.congViecTBKLCH]: RESPONSIBILITY_FILTER.CONGVIEC_TBKLCH,
    [pageTypes.toiGiao]: RESPONSIBILITY_FILTER.TOI_GIAO,
    [pageTypes.duocGiao]: RESPONSIBILITY_FILTER.TOI_DUOC_GIAO,
    [pageTypes.theoDoi]: RESPONSIBILITY_FILTER.TOI_THEO_DOI,
  },
}

export const TaskContext = React.createContext({})

const AllTask = ({
  match,
  history,
  location,
  roleId,
  tokenDO,
  // allCookies = {},
  hasShowAll,
  hasShowDeparmentLeadership,
  mainUnitId,
  mainDepartmentId,
  dataTreeStore,
  dataAutoToggleDefault,
  listWorkTypes,
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const { type, id, action } = match.params
  const status = history?.location?.state?.status
  const workType = history?.location?.state?.workType
  const workDeadLine = history?.location?.state?.workDeadLine

  const buttonTabs = useMemo(
    () => [
      {
        value: WORK_STATUS.CHUA_THUC_HIEN,
        text: 'Chưa thực hiện',
        themeClass: 'cpc-button-orange',
      },
      {
        value: WORK_STATUS.DANG_THUC_HIEN,
        text: 'Đang thực hiện',
        themeClass: '',
      },
      {
        value: WORK_STATUS.HOAN_THANH,
        text: 'Đã thực hiện',
        themeClass: 'cpc-button-green',
      },
    ],
    []
  )

  const tabsOrigin = useMemo(
    () => [
      {
        key: tabKeys.list,
        title: (
          <>
            <Icon classIcon="icon-list" style={{ marginRight: 8 }} />
            Danh sách
          </>
        ),
        content: <List />,
      },
      {
        key: tabKeys.kanban,
        title: (
          <>
            <Icon classIcon="icon-board" style={{ marginRight: 8 }} />
            Kanban
          </>
        ),
        content: <Kanban />,
      },
      {
        key: tabKeys.calendar,
        title: (
          <>
            <Icon classIcon="icon-calendar-edit" style={{ marginRight: 8 }} />
            Lịch làm việc
          </>
        ),
        content: <Calendar />,
      },
      {
        key: tabKeys.timeline,
        title: (
          <>
            <Icon classIcon="icon2-timeline" style={{ marginRight: 8 }} />
            Timeline
          </>
        ),
        content: <CalendarTimeline />,
      },
    ],
    []
  )

  const DEFAULT_FILTER = useMemo(
    () => ({
      page: 1,
      [pageFilter.keys.responibility]: get(pageFilter.values, type),
      [pageFilter.keys.status]:
        type !== pageTypes.toiGiao ? WORK_STATUS.CHUA_THUC_HIEN : undefined,
    }),
    [type]
  )

  const [isLoadingTree, setIsLoadingTree] = useState(false)
  const [activeTab, setActiveTab] = useState()
  const [fetchFail, setfetchFail] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [listData, setListData] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [kanbanData, setKanbanData] = useState([])
  const [calendarData, setCalendarData] = useState([])
  const [timelineData, setTimelineData] = useState([])
  const [tabs, setTabs] = useState(tabsOrigin)
  const [submitDisable, setSubmitDisable] = useState(false)
  const [counterTabsList, setCounterTabsList] = useState({})
  const [departments, setDepartments] = useState([])
  const [departmentFetched, setDepartmentFetched] = useState([])
  const [favoriteGroups, setFavoriteGroups] = useState([])
  const [tags, setTags] = useState([])
  const [tagging, setTagging] = useState(false)
  const [currentTaskTagsChecked, setCurrentTaskTagsChecked] = useState([])
  const [detail, setDetail] = useState({})

  const convertEmployee = useCallback(
    employee => ({
      showAvatar: true,
      value: {
        id: get(employee, 'chucDanhId'),
        type: ASSIGN_TYPE.CA_NHAN,
      },
      label: get(employee, 'tenNhanVien'),
      avatar: get(employee, 'anhDaiDien'),
    }),
    []
  )

  const convertUsers = useCallback(
    values => ({
      ...values,
      id: values?.chucDanhId,
      type: 'PERSONAL',
      name: values?.tenNhanVien,
      tenChucVu: values?.chucVu,
      maChucVu: values?.maChucVu,
      parentId: values?.parentId,
    }),
    []
  )

  const _fetchDepartments = useCallback(() => {
    requestAction({
      showToast: false,
      successCode: 0,
      action: getCayPhongBan,
      afterResponse: (list = []) =>
        _mountedSet(
          setDepartments,
          list.map(phongBan => ({
            value: {
              id: get(phongBan, 'phongBanId'),
              type: ASSIGN_TYPE.PHONG_BAN,
            },
            label: get(phongBan, 'maPhongBan'),
            children: [],
          }))
        ),
    })
  }, [])

  const convertType = type => {
    if (type === 'PB') {
      return 'DEPARTMENT'
    } else if (type === 'DV') {
      return 'ORGANIZATION'
    }

    return 'PERSONAL'
  }

  const fetchPhongBanDonVi = useCallback(async () => {
    let params = {
      donViId: mainUnitId,
      phongBanId: mainDepartmentId,
      limit: 9999,
    }
    await requestAction({
      showToast: false,
      successCode: 200,
      beforeAction: () => {
        setIsLoadingTree(true)
      },
      action: () => getPhongBanNhanVien(params),
      afterResponse: response => {
        let newData = []
        if (response?.items) {
          newData = response?.items?.map(elm => {
            return {
              id: elm?.phongBanId,
              type: convertType('PB'),
              name: elm?.maPhongBan,
              code: elm?.maPhongBan,
              parentId: elm?.donViId,
              children:
                elm?.nhanViens?.map(u =>
                  convertUsers({ ...u, parentId: elm?.phongBanId })
                ) || [],
            }
          })
        }

        requestAction({
          showToast: false,
          successCode: 0,
          action: () => saveTree(newData),
        })
      },
      afterAction: () => {
        setIsLoadingTree(false)
      },
    })
  }, [mainUnitId, mainDepartmentId, convertUsers])

  const _fetchUsers = useCallback(
    async ({ id: departmentId, type }) => {
      if (
        departmentFetched.includes(departmentId) ||
        type !== ASSIGN_TYPE.PHONG_BAN
      )
        return
      let users = []
      await requestAction({
        showToast: false,
        action: () => getDsNhanVien(departmentId),
        afterResponse: ({ items = [] }) => {
          _mountedSet(setDepartmentFetched, oldList => [
            ...oldList,
            departmentId,
          ])
          users = items.map(convertEmployee)
          _mountedSet(setDepartments, oldList =>
            hasShowAll
              ? oldList.map(department =>
                  get(department, 'value.id') === departmentId
                    ? {
                        ...department,
                        children: users,
                      }
                    : department
                )
              : users
          )
        },
      })
      return users
    },
    [departmentFetched, convertEmployee, hasShowAll]
  )

  const fetchWorkTypes = useCallback(() => {
    requestAction({
      action: getWorkTypes,
    })
  }, [])

  const changeTagging = state => {
    _mountedSet(setTagging, state)
  }

  const onTagSucess = ({ id: taskId, newTags }) => {
    _mountedSet(
      setListData,
      listData.map(task =>
        task.id === taskId ? { ...task, workItemTag: newTags } : task
      ) || []
    )

    if (id === taskId) {
      _mountedSet(
        setCurrentTaskTagsChecked,
        newTags.map(taskTag => taskTag.workTagId)
      )
    }
  }

  const tagsChange = newTags => {
    _mountedSet(setTags, newTags)
  }

  const _fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => _mountedSet(setTags, items),
    })
  }, [])

  const _fetchDsCongViec = useCallback(
    async (requestFilter, isNotLocation = true) => {
      let endPoint = ''
      switch (type) {
        case pageTypes.toiGiao:
          endPoint = '/api/v2/works/assign-to-me'
          break
        case pageTypes.duocGiao:
          endPoint = '/api/v2/works/assign-by-me'
          break
        case pageTypes.theoDoi:
          endPoint = '/api/v2/works/watched-by-me'
          break
        case pageTypes.congViecTBKLCH:
          endPoint = '/api/v2/works/meetings'
          break
        case pageTypes.congViecDOffice:
          endPoint = '/api/v2/works/doffices'
          break
        default:
          break
      }

      let filterParams = {
        ...requestFilter,
      }
      if (isNotLocation && (workType || status || workDeadLine)) {
        if (workType) {
          filterParams[pageFilter.keys.workType] = workType
        }
        if (status) {
          filterParams[pageFilter.keys.status] = status
        }
        if (workDeadLine) {
          filterParams[pageFilter.keys.workDeadLine] = workDeadLine
        }

        setFilter(prev => ({
          ...prev,
          ...(workType
            ? {
                [pageFilter.keys.workType]: workType,
              }
            : {}),
          ...(workDeadLine
            ? {
                [pageFilter.keys.workDeadLine]: workDeadLine,
              }
            : {}),
          ...(status
            ? {
                [pageFilter.keys.status]: status,
              }
            : {}),
        }))
      }

      const newRequestFilter = {
        ...filterParams,
        [pageFilter.keys.title]: isString(filterParams?.[pageFilter.keys.title])
          ? filterParams?.[pageFilter.keys.title]?.trim()
          : filterParams?.[pageFilter.keys.title],
        [pageFilter.keys.workType]:
          filterParams?.[pageFilter.keys.workType] &&
          filterParams?.[pageFilter.keys.workType] !== 'ALL'
            ? filterParams?.[pageFilter.keys.workType]
            : null,
      }

      const requestProps = {
        showToast: false,
        beforeAction: () => {
          _mountedSet(setFetching, true)
          _mountedSet(setfetchFail, false)
        },
        afterError: () => _mountedSet(setfetchFail, true),
        afterAction: () => _mountedSet(setFetching, false),
      }

      if (activeTab === tabKeys.list) {
        requestAction({
          ...requestProps,
          action: () => getDsCongViec(newRequestFilter, endPoint),
          afterResponse: ({ items = [], totalCount = 0 }) => {
            _mountedSet(setListData, items)
            _mountedSet(setTotalPage, totalCount)
            _mountedSet(setCounterTabsList, {
              [newRequestFilter?.[pageFilter.keys.status]]: totalCount,
            })
          },
        })
      }

      if (activeTab === tabKeys.kanban) {
        const kanbanRequestFilter = { ...newRequestFilter }
        delete kanbanRequestFilter.Status
        requestAction({
          ...requestProps,
          action: () =>
            getDsCongViecKanban({
              ...kanbanRequestFilter,
              limit: 10,
            }),
          afterResponse: response => {
            _mountedSet(setKanbanData, response)
          },
        })
      }
      if (activeTab === tabKeys.calendar) {
        if (!newRequestFilter['FromDate'] && !newRequestFilter['ToDate']) return
        requestAction({
          ...requestProps,
          action: () => getDsCongViec(newRequestFilter, endPoint),
          afterResponse: response =>
            _mountedSet(setCalendarData, get(response, 'items', [])),
        })
      }

      if (activeTab === tabKeys.timeline) {
        const defaultParam = {
          page: 1,
          Status: undefined,
          limit: -1,
          FromDate: undefined,
          ToDate: undefined,
          timeline: newRequestFilter['ToDate']
            ? moment(newRequestFilter['ToDate']).format('YYYY-MM-01')
            : moment().format('YYYY-MM-01'),
        }
        requestAction({
          ...requestProps,
          action: () =>
            getDsCongViecTimeline(
              {
                ...newRequestFilter,
                ...defaultParam,
              },
              endPoint
            ),
          afterResponse: response => {
            const temp = response?.items || []
            _mountedSet(setTimelineData, temp)
          },
        })
        return
      }
    },
    [activeTab, type, workType, status, workDeadLine]
  )

  const reloadData = useCallback(
    (filterOptions = {}) => {
      const newFilter = { ...filter, ...filterOptions }
      if (!isEmpty(filterOptions)) {
        _mountedSet(setFilter, newFilter)
      }

      _fetchDsCongViec(newFilter)
      funcGetTrangThaiCongViec()
    },
    [filter, _fetchDsCongViec]
  )

  const getDefaultFilterByTab = tab => {
    let newFilter = { ...DEFAULT_FILTER }
    switch (tab) {
      case tabKeys.kanban:
        newFilter.Status = undefined
        newFilter.page = 1
        break
      case tabKeys.calendar:
        newFilter.Status = undefined
        newFilter.page = 1
        newFilter.limit = -1
        break
      case tabKeys.timeline:
        newFilter.Status = undefined
        newFilter.FromDate = moment()
          .subtract(1, 'months')
          .format(requestDateFormat)
        newFilter.ToDate = moment().format(requestDateFormat)
        newFilter.page = 1
        newFilter.limit = -1
        break
      default:
        break
    }

    return newFilter
  }

  const changeFilter =
    ({
      name,
      reFetch = true,
      filterKey,
      multipleKey = false,
      isReloadList = true,
    }) =>
    value => {
      let newFilter = { ...filter, [pageFilter.keys.page]: 1 }

      if (name === 'RESET') {
        newFilter = {
          ...DEFAULT_FILTER,
          [pageFilter.keys.status]:
            type !== pageTypes.toiGiao
              ? filter[pageFilter.keys.status]
              : undefined,
        }
      } else if (multipleKey) {
        newFilter = { ...newFilter, ...value }
      } else {
        newFilter = {
          ...newFilter,
          [name]: value,
        }
      }

      if (filterKey) {
        newFilter = { ...newFilter, ...filterKey }
      }

      _mountedSet(setFilter, newFilter)
      if (reFetch) {
        _fetchDsCongViec(newFilter, false)
      }

      if (name === pageFilter.keys.status && isReloadList) {
        redirectList()
      }
    }

  const redirectList = useCallback(() => {
    const hasGoBack = get(location, 'state.showGoBack', false)
    const status = location?.state?.status ?? false
    hasGoBack
      ? history.goBack()
      : history.push({
          pathname: `/quan-ly-cong-viec/${type}`,
          ...(status
            ? {
                state: {
                  status: status,
                },
              }
            : {}),
        })
  }, [location, history, type])

  const openCreate = useCallback(() => {
    const url = `/quan-ly-cong-viec/${type}/${pageActions.taoMoi}`
    if (!action) return history.push(url)
    if (action) return history.replace(url)
  }, [type, action, history])

  const onReadAll = useCallback(() => {
    const work_item_ids = listData?.filter(f => !f?.isRead)?.map(e => e.id)
    requestAction({
      beforeAction: () => {
        _mountedSet(setSubmitDisable, true)
      },
      showToast: true,
      showToastSucess: true,
      action: () =>
        postWorkReadAll({
          repsonsibility_type: RESPONSIBILITY_FILTER.TOI_THEO_DOI,
          work_item_ids,
        }),
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      afterResponse: () => {
        getThongKeLoaiGiaoViec() // Thống kê tôi giao, được giao, theo dõi
        // Cập nhật lại danh sách với trạng thái đã đọc
        _mountedSet(
          setListData,
          listData?.map(task => {
            return {
              ...task,
              isRead: true,
            }
          }) || []
        )
      },
      afterAction: () => {
        _mountedSet(setSubmitDisable, false)
      },
    })
  }, [listData])

  const changeTab = key => {
    const newFilter = getDefaultFilterByTab(key)
    _mountedSet(setFilter, newFilter)
    _mountedSet(setActiveTab, key)
    redirectList()
  }

  // Cập nhật lại danh sách tags khi kéo thả vị trí
  const onDragDrop = e => {
    let dataTags = [...tabs]
    let oldIndex = get(e, 'source.index')
    let newIndex = get(e, 'destination.index')
    if (oldIndex === newIndex || oldIndex < 0 || newIndex < 0) {
      return
    }

    dataTags.splice(oldIndex, 1)
    dataTags.splice(newIndex, 0, tabs[oldIndex])
    _mountedSet(setTabs, dataTags)
    cookies.set(
      'tags_item_task_manager',
      JSON.stringify(dataTags.map(i => i.key)),
      {
        expires: expiryDate,
      }
    )
  }

  const taskQuickCreation = employee => {
    if (!employee) {
      return
    }

    return {
      showAvatar: true,
      value: {
        id: get(employee, 'chucDanhId'),
        type: ASSIGN_TYPE.CA_NHAN,
      },
      label: get(employee, 'tenNhanVien'),
      avatar: get(employee, 'anhDaiDien'),
    }
  }

  const actionBlock = useMemo(() => {
    const actionOverlays = {
      [pageActions.chiTiet]: TaskDetail,
      [pageActions.taoMoi]: TaskForm,
      [pageActions.ganTag]: TaskTagsForm,
      [pageActions.giaoViec]: GiveTaskForm,
      [pageActions.suaGiaoViec]: GiveTaskForm,
      [pageActions.nhacViec]: ReminderForm,
      [pageActions.chinhSua]: TaskForm,
      [pageActions.huyDuyet]: ApproveTask,
      [pageActions.xoa]: DeleteTaskConfirm,
      [pageActions.luuTu]: SaveDocumentPopup,
      [pageActions.luongCongViec]: FlowWorks,
      [pageActions.giaoViecDOffice]: GiveTaskFormDOffice,
      [pageActions.suaGiaoViecDOffice]: GiveTaskFormDOffice,
      [pageActions.lichSuXacNhan]: AcceptHistory,
      [pageActions.xacNhan]: Accept,
    }
    const ActionOverlay = actionOverlays[action]

    if (!ActionOverlay) return <></>
    return (
      <ActionOverlay
        onClose={() => {
          redirectList()
        }}
        reloadList={reloadData}
      />
    )
  }, [action, reloadData, redirectList])

  const funcGetTrangThaiCongViec = () => {
    getThongKeTrangThaiCongViec({
      responsibilityType: STATISTIC_WORK_RESPONSIBILITY.ASSIGN_TO_ME,
    })
  }

  const onSaveDocumentSuccess = id => {
    _mountedSet(
      setListData,
      listData.map(task =>
        task.id === id
          ? {
              ...task,
              isDaLuuTuPhongBan: true,
            }
          : task
      ) || []
    )
  }

  const changeFilte = data => {
    if (!data) {
      return
    }

    return {
      filterValue: {
        ...data,
        id: get(data, 'id'),
        type: ASSIGN_TYPE.PHONG_BAN,
      },
      label: get(data, 'tenNhanVien'),
      avatar: get(data, 'anhDaiDien'),
    }
  }

  const markAsRead = id => {
    _mountedSet(
      setListData,
      listData.map(task =>
        task.id === id
          ? {
              ...task,
              isRead: true,
            }
          : task
      ) || []
    )
  }

  const kanbanAsRead = (id, status) => {
    _mountedSet(
      setKanbanData,
      kanbanData.map(task => {
        if (task && task.status === status) {
          return {
            ...task,
            items: get(task, 'items').map(elm => {
              if (elm.id === id) {
                return {
                  ...elm,
                  isRead: true,
                }
              }

              return elm
            }),
          }
        }

        return task
      })
    )
  }

  const _fetchFavoriteGroup = useCallback(() => {
    requestAction({
      showToast: false,
      action: () => getMyFavoriteGroups({ is_active: true }),
      afterResponse: ({ items }) => {
        _mountedSet(
          setFavoriteGroups,
          items.map(({ id, name, members }) => ({
            id,
            name,
            children: members.map(
              ({ id, name, avatar, departmentId, ...res }) => ({
                ...res,
                id,
                parentId: departmentId,
                name,
                type: convertType('PERSONAL'),
                avatar,
              })
            ),
          }))
        )
      },
    })
  }, [])

  const checkLayout = keyName => {
    const data = [
      pageActions.chiTiet,
      pageActions.taoMoi,
      pageActions.chinhSua,
      pageActions.giaoViec,
      pageActions.suaGiaoViec,
      pageActions.nhacViec,
      pageActions.xoa,
      pageActions.huyDuyet,
      pageActions.ganTag,
      pageActions.luuTu,
      pageActions.luongCongViec,
      pageActions.giaoViecDOffice,
      pageActions.suaGiaoViecDOffice,
      pageActions.lichSuXacNhan,
      pageActions.xacNhan,
    ]
    if (data.includes(keyName)) {
      return true
    }

    return false
  }

  const contextValue = {
    pageFilter,
    fetchStatus: {
      load: fetching,
      fail: fetchFail,
    },
    data: listData,
    kanbanData,
    calendarData,
    timelineData,
    filter,
    buttonTabs,
    typeParams: type,
    totalPage,
    favoriteGroups,
    tags,
    tagging,
    currentTaskTagsChecked,
    departmentFetched,
    departments,
    isLoadingTree,
    dataTreeStore,
    activeTab,
    detail,
    hasShowAllDeparment: hasShowAll,
    hasShowDeparmentLeadership,
    mainDepartmentId,
    mainUnitId,
    counterTabsList,
    dataAutoToggleDefault,
    roleId,
    listWorkTypes,
    tokenDO,
    changeFilter,
    tagsChange,
    onTagSucess,
    changeTagging,
    reloadKanbanData: reloadData,
    reloadData,
    fetchUsers: _fetchUsers,
    setDetail,
    onSaveDocumentSuccess,
    markAsRead,
    kanbanAsRead,
  }

  const renderTitle = () => {
    return (
      <div className="task-manager-title">
        <h1 className="name">{pageTitles[type]}</h1>
        <div className="d-flex align-items-center gap-10">
          <Tooltip
            content={<span className="font-size-12">Tạo công việc</span>}
          >
            <Button
              className={classNames('cpc-button theme-md white')}
              onClick={openCreate}
            >
              <span>Tạo công việc</span>
              <Icon classIcon="icon-Plus" />
            </Button>
          </Tooltip>
          {type === pageTypes.theoDoi && (
            <Tooltip
              content={
                <span className="font-size-12">Đánh dấu đọc tất cả</span>
              }
            >
              <Button
                className={classNames('cpc-button theme-md blue')}
                onClick={onReadAll}
                disabled={submitDisable}
              >
                <span>Đánh dấu đọc tất cả</span>
                <Icon classIcon="icon-xac-nhan-lai" />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (
      cookies.get('tags_item_task_manager') &&
      cookies.get('tags_item_task_manager').length !== 0 &&
      cookies.get('tags_item_task_manager').length === tagsNumber
    ) {
      let tabsResult = []
      cookies.get('tags_item_task_manager').forEach(elm => {
        tabsOrigin.forEach(i => {
          if (i.key === elm) {
            tabsResult.push(i)
          }
        })
      })
      _mountedSet(setTabs, tabsResult)
    }
  }, [tabsOrigin])

  useEffect(() => {
    _fetchFavoriteGroup()
  }, [_fetchFavoriteGroup])

  useEffect(() => {
    const getData = setTimeout(() => {
      _fetchDsCongViec({
        page: 1,
        [pageFilter.keys.responibility]: pageFilter?.values?.[type],
        [pageFilter.keys.status]:
          type !== pageTypes.toiGiao ? WORK_STATUS.CHUA_THUC_HIEN : undefined,
      })
    }, 0)
    return () => clearTimeout(getData)
  }, [type, _fetchDsCongViec])

  useEffect(() => {
    _mountedSet(
      setActiveTab,
      cookies.get('tags_item_task_manager')
        ? cookies.get('tags_item_task_manager')[0]
        : tabKeys.list
    )
    _mountedSet(setFilter, DEFAULT_FILTER)
  }, [type, DEFAULT_FILTER])

  useEffect(() => {
    if (type === pageTypes.duocGiao) {
      funcGetTrangThaiCongViec()
    }

    if (type === pageTypes.theoDoi) {
      getThongKeLoaiGiaoViec()
    }

    setTimelineData([])
  }, [type])

  useEffect(() => {
    _fetchDsTag()
  }, [_fetchDsTag])

  useEffect(() => {
    fetchPhongBanDonVi()
  }, [fetchPhongBanDonVi])

  useEffect(() => {
    if (hasShowAll) {
      // data filter in list
      _fetchDepartments()
    }
  }, [_fetchDepartments, hasShowAll])

  useEffect(() => {
    if (!hasShowAll) {
      // data filter in list
      _fetchUsers({ id: mainDepartmentId, type: ASSIGN_TYPE.PHONG_BAN })
    }
  }, [_fetchUsers, hasShowAll, mainDepartmentId])

  useEffect(() => {
    fetchWorkTypes()
  }, [fetchWorkTypes])

  useEffect(() => {
    taskQuickCreation(null)
    changeFilte(null)
  }, [])

  useEffect(() => {
    ContextMenu.hide()
  }, [])

  return (
    <MasterLayout typeSidebar="taskManager">
      <TaskContext.Provider value={contextValue}>
        <div className="task-manager-block-all-layout">
          {renderTitle()}
          <div
            style={{
              flex: 1,
              fontFamily: 'Roboto',
              position: 'relative',
            }}
            className="task-manager-block"
          >
            <Tabs
              className={'task-manager-block__tabs'}
              dataSource={tabs || []}
              onChange={changeTab}
              onDragDrop={onDragDrop}
              selectedKey={activeTab}
            />
          </div>
        </div>
        {checkLayout(action) && <div className="mask-layout"></div>}
        {actionBlock}
      </TaskContext.Provider>
    </MasterLayout>
  )
}

AllTask.propTypes = {
  hasShowAll: PropTypes.bool,
  mainUnitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mainDepartmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTreeStore: PropTypes.array,
}

const mapStateToProps = state => {
  const roleId = state?.auth?.roleId
  const mainDepartment = state?.auth?.user?.dsChucDanh?.find(
    elm => elm?.id === roleId
  )
  const hasShowAll = get(state, 'auth.permission', []).includes(
    Permission.CONGVANDEN_CHIDAO
  )
  const hasShowDeparmentLeadership = get(state, 'auth.permission', []).includes(
    Permission.CONGVANDEN_CHIDAO_DVTRUCTHUOC
  )
  let dataAutoToggleDefault = []
  if (!hasShowAll) {
    dataAutoToggleDefault = [get(state, 'auth.mainDepartmentId')]
  }

  return {
    roleId,
    hasShowAll,
    hasShowDeparmentLeadership,
    mainUnitId: get(state, 'auth.mainUnitId'),
    mainDepartmentId: get(state, 'auth.mainDepartmentId'),
    dataTreeStore: get(state, 'common.dataTree'),
    mainDepartment,
    dataAutoToggleDefault,
    listWorkTypes: state?.TaskManager?.listWorkTypes,
    tokenDO: state?.doffice?.toKenDO,
  }
}

export default withCookies(connect(mapStateToProps)(memo(AllTask)))
