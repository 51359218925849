import { useState, useEffect, useCallback, useMemo } from 'react'
import requestAction from 'helpers/request'
import { Intent } from '@blueprintjs/core'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import size from 'lodash/size'
import { Toast } from 'constants/MessageForm'
import { FILE_TYPE } from 'constants/Enum'
import {
  getCongViec,
  showToast,
  getInformationWorkItemAction,
} from 'actions/task'

const useTaskThongBaoKLCHTaskDetail = ({ history, match }) => {
  const id = match.params?.id
  const dateFormat = 'DD/MM/YYYY'
  const [fetching, setFetching] = useState(false)
  const [detail, setDetail] = useState(null)
  const [userMention, setUserMention] = useState([])
  const [menuActive, setMenuActive] = useState(null)
  const [informationWorkItemAction, setInformationWorkItemAction] = useState({
    isAccept: false,
  })

  const onMenu = useCallback(
    (_, data) => {
      if (data.keyName === 'SUA_HUY_KET_LUAN') {
        return history.push(
          `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/ket-luan/chinh-sua/${detail?.parentId}/${id}`
        )
      }

      setMenuActive(data)
    },
    [history, id, detail]
  )

  const showDeadline = useMemo(() => {
    return get(detail, 'deadline') || get(detail, 'originalDeadline')
  }, [detail])

  const renderStatus = values => {
    return values?.status
  }

  const renderApproval = values => {
    let text = ''
    let type = ''
    if (values?.approval === 'APPROVED') {
      text = 'Đã Duyệt'
      type = 'APPROVED'
    } else if (values?.approval === 'WAITING_APPROVAL') {
      text = 'Chờ Duyệt'
      type = 'WAITING_APPROVAL'
    }

    return {
      type: type,
      text: text,
    }
  }

  const attachmentFilesNotFromDocument = useMemo(() => {
    if (!!size(get(detail, 'files'))) {
      return detail.files.filter(elm => elm.application !== 'eOffice')
    }

    return []
  }, [detail])

  const coordinations = useMemo(() => {
    if (detail?.responsibilities) {
      return detail?.responsibilities?.[1]?.workAssignments?.map(
        elm => elm.responsibility?.shortName
      )
    }

    return []
  }, [detail])

  const executor = useMemo(() => {
    return detail?.responsibilities?.[0]?.workAssignments?.[0]
  }, [detail])

  const fetchDetail = useCallback(() => {
    const splitDetailFiles = data => {
      return {
        ...data,
        referenceFile: (data?.files || []).filter(
          file => file.type === FILE_TYPE.FILE_THAM_KHAO
        ),
        files: (data?.files || []).filter(
          file => file.type === FILE_TYPE.FILE_DINH_KEM
        ),
      }
    }

    const getUsersInTaskDetail = data => {
      return uniqBy(
        data?.map(e => ({
          id: e?.id,
          display: e?.name,
        })),
        'id'
      )
    }

    requestAction({
      showToast: false,
      beforeAction: () => setFetching(true),
      action: () => getCongViec(id),
      afterResponse: (result = {}) => {
        setDetail(splitDetailFiles(result))
        setUserMention(getUsersInTaskDetail(result?.relatedStaff))
      },
      afterAction: () => setFetching(false),
      afterError: () => {
        showToast({
          message: Toast.NOT_FOUND('công việc'),
          intent: Intent.WARNING,
        })
        history.goBack()
      },
    })
  }, [id, setDetail, history])

  const handleCheckWorkItemAction = useCallback(() => {
    setInformationWorkItemAction({
      isAccept: false,
    })
    requestAction({
      showToast: false,
      action: () => getInformationWorkItemAction(id),
      afterResponse: res => {
        setInformationWorkItemAction(res)
      },
    })
  }, [id])

  useEffect(() => {
    handleCheckWorkItemAction()
  }, [handleCheckWorkItemAction])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  return {
    dateFormat,
    fetching,
    detail,
    userMention,
    attachmentFilesNotFromDocument,
    coordinations,
    executor,
    menuActive,
    informationWorkItemAction,
    showDeadline,
    renderStatus,
    renderApproval,
    setMenuActive,
    fetchDetail,
    onMenu,
  }
}

export default useTaskThongBaoKLCHTaskDetail
