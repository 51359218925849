import React from 'react'
import { MasterLayout } from '../../components/layout'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Actions from '../../actions/'
import PropTypes from 'prop-types'
import * as Tool from '../../helpers/'
import classnames from 'classnames'
import * as MESSAGE from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'
import { Button } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import {
  HeadingPage,
  PopupConfirm,
  LoadingItem,
} from '../../components/common/'

class DocumentAuthorityPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      tuNgay: undefined,
      denNgay: undefined,
      nguoiDuocUyQuyenId: '',
      dateFromToString: '',
      dateToToString: '',
      isLoadingData: {
        nguoiDuocUyQuyenId: true,
        uyQuyen: true,
      },
      isLoading: {
        isLoadingRemove: false,
        isLoadingSubmit: false,
      },
      isUpdate: false,
      isOpenPopupDelete: false,
      authorityDocument: null,
    }

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickCancle = this.handleClickCancle.bind(this)
    this.handleClickPopupDelete = this.handleClickPopupDelete.bind(this)
    this.handleSubmitRemove = this.handleSubmitRemove.bind(this)
    this.getauthorityDocument = this.getauthorityDocument.bind(this)
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    users: PropTypes.object,
    authorityDocument: PropTypes.object,
  }

  handleClickCancle = () => {
    this.props.history && this.props.history.goBack()
  }

  handleClickPopupDelete = () => {
    this.setState({
      isOpenPopupDelete: !this.state.isOpenPopupDelete,
    })
  }

  getauthorityDocument = () => {
    this.props.actions.documentAuthorityFetch().then(res => {
      let state = this.state
      state.authorityDocument = null
      state.nguoiDuocUyQuyenId = ''
      if (
        this.props.users &&
        this.props.users.leaderCanAssignList &&
        this.props.users.leaderCanAssignList.length > 0
      ) {
        const leaders = this.props.users.leaderCanAssignList.filter(
          s => s.chucDanhId !== this.props.auth.roleId
        )
        state.nguoiDuocUyQuyenId =
          leaders && leaders[0] && leaders[0].chucDanhId
      }
      state.tuNgay = undefined
      state.denNgay = undefined
      state.dateFromToString = ''
      state.dateToToString = ''
      state.isUpdate = false

      if (this.props.authorityDocument && this.props.authorityDocument.item) {
        state.authorityDocument = this.props.authorityDocument.item
        if (this.props.authorityDocument.item.nguoiDuocUyQuyen) {
          const leaders = this.props.users.leaderCanAssignList.filter(
            s => s.chucDanhId !== this.props.auth.roleId
          )
          if (
            leaders.find(
              s =>
                s.chucDanhId ===
                this.props.authorityDocument.item.nguoiDuocUyQuyen.chucDanhId
            )
          ) {
            state.nguoiDuocUyQuyenId =
              this.props.authorityDocument.item.nguoiDuocUyQuyen.chucDanhId
          }
        }
        if (
          this.props.authorityDocument.item.tuNgay &&
          Tool.Utils.isValidDate(
            moment(this.props.authorityDocument.item.tuNgay).format(
              'DD/MM/YYYY'
            )
          )
        ) {
          state.tuNgay = moment(this.props.authorityDocument.item.tuNgay).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          state.dateFromToString = moment(
            this.props.authorityDocument.item.tuNgay
          )
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format('DD/MM/YYYY')
        }
        if (
          this.props.authorityDocument.item.denNgay &&
          Tool.Utils.isValidDate(
            moment(this.props.authorityDocument.item.denNgay).format(
              'DD/MM/YYYY'
            )
          )
        ) {
          state.denNgay = moment(this.props.authorityDocument.item.denNgay).set(
            { hour: 0, minute: 0, second: 0, millisecond: 0 }
          )
          state.dateToToString = moment(
            this.props.authorityDocument.item.denNgay
          )
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format('DD/MM/YYYY')
        }
        state.isUpdate = true
      }
      state.isLoadingData = {
        ...state.isLoadingData,
        uyQuyen: false,
      }
      this.setState(state)
    })
  }

  handleSubmitRemove = async () => {
    this.setState({
      isOpenPopupDelete: false,
      isLoading: {
        ...this.state.isLoading,
        isLoadingRemove: true,
      },
    })
    await this.props.actions.documentAuthorityDelete().then(res => {
      if (res.payload && res.payload.data && res.payload.data.result === true) {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isLoadingRemove: false,
          },
        })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_CANCLE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      }
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          isLoadingRemove: false,
        },
      })
      this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_CANCLE_FAIL,
        intent: Intent.DANGER,
      })
    })
    this.getauthorityDocument()
  }

  handleInputChange = e => {
    let error = this.state.error
    const name = e.target.name
    delete error[name]
    this.setState({
      error,
      [name]: e.target.value,
    })
  }

  handleDateChange = (name, nameToString, e) => {
    const error = this.state.error
    delete error[name]
    let value = e
      ? moment(e).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      : undefined
    let valueToString = ''
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: value,
      [nameToString]: valueToString,
      error,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      name === 'tuNgay' &&
      !this.state.denNgay &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      name === 'tuNgay' &&
      this.state.denNgay &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: this.state.denNgay,
        },
      })
    ) {
      return
    }

    if (
      name === 'denNgay' &&
      !this.state.tuNgay &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      name === 'denNgay' &&
      this.state.tuNgay &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: this.state.tuNgay,
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleSubmit = async e => {
    let state = this.state
    this.setState({
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: false,
      },
    })
    if (
      !state.nguoiDuocUyQuyenId ||
      state.nguoiDuocUyQuyenId.trim().length === 0
    ) {
      state.error.nguoiDuocUyQuyenId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!state.tuNgay) {
      state.error.tuNgay = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!state.denNgay) {
      state.error.denNgay = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (!state.error || Object.keys(state.error).length > 0) {
      state.isLoading = {
        ...state.isLoading,
        isLoadingSubmit: false,
      }
      return this.setState(state, () => {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_WARNING,
          intent: Intent.WARNING,
        })
      })
    }

    this.setState({
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
    })

    if (!this.state.isUpdate) {
      await this.props.actions
        .documentAuthorityCreate({
          nguoi_duoc_uy_quyen_id: state.nguoiDuocUyQuyenId,
          tu_ngay: state.tuNgay ? moment(state.tuNgay).format() : null,
          den_ngay: state.denNgay ? moment(state.denNgay).format() : null,
        })
        .then(res => {
          if (
            !res.payload ||
            !res.payload.data ||
            res.payload.data.result !== true
          ) {
            this.setState({
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: false,
              },
            })
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isLoadingSubmit: false,
            },
          })
          this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_SUCCESS,
            intent: Intent.SUCCESS,
          })
        })
      return this.getauthorityDocument()
    }

    await this.props.actions
      .documentAuthorityUpdate({
        nguoi_duoc_uy_quyen_id: state.nguoiDuocUyQuyenId,
        tu_ngay: state.tuNgay ? moment(state.tuNgay).format() : null,
        den_ngay: state.denNgay ? moment(state.denNgay).format() : null,
      })
      .then(res => {
        if (
          !res.payload ||
          !res.payload.data ||
          res.payload.data.result !== true
        ) {
          this.setState({
            isLoading: {
              ...this.state.isLoading,
              isLoadingSubmit: false,
            },
          })
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isLoadingSubmit: false,
          },
        })
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      })
    this.getauthorityDocument()
  }

  componentDidMount = async () => {
    let state = this.state
    await this.props.actions.getLeaderStaffCanAssign().then(() => {
      if (
        this.props.users &&
        this.props.users.leaderCanAssignList &&
        this.props.users.leaderCanAssignList.length > 0
      ) {
        const leaders = this.props.users.leaderCanAssignList.filter(
          s => s.chucDanhId !== this.props.auth.roleId
        )
        state.nguoiDuocUyQuyenId =
          leaders && leaders[0] && leaders[0].chucDanhId
      }
      state.isLoadingData = {
        ...state.isLoadingData,
        nguoiDuocUyQuyenId: false,
      }
      this.setState(state)
    })
    this.getauthorityDocument()
  }

  render() {
    return (
      <MasterLayout typeSidebar="documentary">
        <div className="authorize-page row center-xs">
          <div className="col-md-7 col-xs-12 col-sm-12 padding-0 form-width-90">
            <HeadingPage
              namePage="Ủy quyền - Chỉ đạo công văn"
              iconPage="icon-Uy_Quyen"
            />
            {this.state.isLoadingData &&
            Object.values(this.state.isLoadingData).find(
              item => item === true
            ) !== undefined ? (
              <div className="loading-container">
                <LoadingItem />
                <LoadingItem />
                <LoadingItem />
              </div>
            ) : (
              <div className="box form-container add-receive-document authorize-addform">
                <div className="form-padding-box">
                  <div className="pt-form-group pt-intent-danger">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <label className="pt-label">
                          Người được ủy quyền
                          <span className="pt-text-muted required-input">
                            *
                          </span>
                        </label>
                        <div className="pt-form-content">
                          <div className="pt-select">
                            <select
                              name="nguoiDuocUyQuyenId"
                              value={this.state.nguoiDuocUyQuyenId}
                              onChange={this.handleInputChange}
                              className={classnames({
                                'loading-input':
                                  this.state.isLoadingData &&
                                  this.state.isLoadingData
                                    .nguoiDuocUyQuyenId === true,
                              })}
                              disabled={
                                (this.state.isLoadingData &&
                                  this.state.isLoadingData
                                    .nguoiDuocUyQuyenId === true) ||
                                (this.state.isLoading &&
                                  Object.values(this.state.isLoading).find(
                                    item => item === true
                                  ) !== undefined)
                              }
                            >
                              {this.props.auth &&
                                this.props.auth.roleId &&
                                this.props.users &&
                                this.props.users.leaderCanAssignList &&
                                this.props.users.leaderCanAssignList
                                  .filter(
                                    s => s.chucDanhId !== this.props.auth.roleId
                                  )
                                  .map((item, i) => (
                                    <option value={item.chucDanhId} key={i}>
                                      {item.tenNhanVien}
                                    </option>
                                  ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-form-group pt-intent-danger">
                    <div className="row">
                      <div className="col-md-6 col-xs-6 col-sm-6">
                        <label className="pt-label">
                          Từ ngày
                          <span className="pt-text-muted required-input">
                            *
                          </span>
                        </label>
                        <div className="pt-form-content">
                          <div className="pt-input-group">
                            <DatePicker
                              isClearable={
                                this.state.dateFromToString ? true : false
                              }
                              readOnly={true}
                              selected={this.state.tuNgay}
                              selectsStart
                              startDate={this.state.tuNgay}
                              endDate={this.state.denNgay}
                              onChange={this.handleDateChange.bind(
                                this,
                                'tuNgay',
                                'dateFromToString'
                              )}
                              value={this.state.dateFromToString}
                              onChangeRaw={e =>
                                this.handleChangeRaw(
                                  'dateFromToString',
                                  e.target.value
                                )
                              }
                              onBlur={e =>
                                this.focousOut(
                                  'tuNgay',
                                  'dateFromToString',
                                  e.target.value
                                )
                              }
                              format="DD/MM/YYYY"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="input-datepicker input-icon-datepicker"
                              placeholderText="Chọn ngày"
                              maxDate={this.state.denNgay}
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: '5px, 10px',
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: 'viewport',
                                },
                              }}
                              disabled={
                                (this.state.isLoadingData &&
                                  this.state.isLoadingData.uyQuyen === true) ||
                                (this.state.isLoading &&
                                  Object.values(this.state.isLoading).find(
                                    item => item === true
                                  ) !== undefined)
                              }
                            />
                          </div>
                        </div>
                        {this.state.error.tuNgay !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.tuNgay}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 col-xs-6 col-sm-6">
                        <label className="pt-label">
                          Đến ngày
                          <span className="pt-text-muted required-input">
                            *
                          </span>
                        </label>
                        <div className="pt-form-content">
                          <div className="pt-input-group pt-intent-danger">
                            <DatePicker
                              isClearable={
                                this.state.dateToToString ? true : false
                              }
                              readOnly={true}
                              selected={this.state.denNgay}
                              selectsEnd
                              startDate={this.state.tuNgay}
                              endDate={this.state.denNgay}
                              onChange={this.handleDateChange.bind(
                                this,
                                'denNgay',
                                'dateToToString'
                              )}
                              value={this.state.dateToToString}
                              onChangeRaw={e =>
                                this.handleChangeRaw(
                                  'dateToToString',
                                  e.target.value
                                )
                              }
                              onBlur={e =>
                                this.focousOut(
                                  'denNgay',
                                  'dateToToString',
                                  e.target.value
                                )
                              }
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="input-datepicker input-icon-datepicker"
                              placeholderText="Chọn ngày"
                              minDate={this.state.tuNgay}
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: '5px, 10px',
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: 'viewport',
                                },
                              }}
                              disabled={
                                (this.state.isLoadingData &&
                                  this.state.isLoadingData.uyQuyen === true) ||
                                (this.state.isLoading &&
                                  Object.values(this.state.isLoading).find(
                                    item => item === true
                                  ) !== undefined)
                              }
                            />
                          </div>
                        </div>
                        {this.state.error.denNgay !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.denNgay}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="buttton-action-footer">
                  {this.state.authorityDocument &&
                    this.state.isLoadingData &&
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) === undefined && (
                      <Button
                        onClick={this.handleClickPopupDelete}
                        className="pull-left btn-cancel btn-cancel-steering"
                        disabled={
                          (this.state.isLoadingData &&
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined) ||
                          (this.state.isLoading &&
                            Object.values(this.state.isLoading).find(
                              item => item === true
                            ) !== undefined)
                        }
                        loading={
                          this.state.isLoading &&
                          this.state.isLoading.isLoadingRemove === true
                        }
                      >
                        <span className="text-content">HỦY ỦY QUYỀN</span>
                        <span className=" pt-icon pt-icon-cross"></span>
                      </Button>
                    )}
                  <Button
                    className="pt-button btn-cancel"
                    disabled={
                      (this.state.isLoadingData &&
                        Object.values(this.state.isLoadingData).find(
                          item => item === true
                        ) !== undefined) ||
                      (this.state.isLoading &&
                        Object.values(this.state.isLoading).find(
                          item => item === true
                        ) !== undefined)
                    }
                    onClick={this.handleClickCancle}
                  >
                    <span className="pt-icon icon-back"></span>
                    <span className="text-content">Quay lại</span>
                  </Button>
                  <Button
                    className="pt-button btn-save"
                    onClick={this.handleSubmit}
                    disabled={
                      (this.state.isLoadingData &&
                        Object.values(this.state.isLoadingData).find(
                          item => item === true
                        ) !== undefined) ||
                      (this.state.isLoading &&
                        Object.values(this.state.isLoading).find(
                          item => item === true
                        ) !== undefined)
                    }
                    loading={
                      this.state.isLoading &&
                      this.state.isLoading.isLoadingSubmit === true
                    }
                  >
                    <span className="text-content">Lưu</span>
                    <span className="pt-icon icon-save"></span>
                  </Button>
                </div>
                {/* Xóa ủy quyền */}
                <PopupConfirm
                  isOpen={this.state.isOpenPopupDelete}
                  onClose={this.handleClickPopupDelete}
                  title={POPUP.HUY_UY_QUYEN}
                  text={POPUP.TEXT_BUTTON_SUBMIT}
                  onSubmit={this.handleSubmitRemove}
                >
                  {POPUP.TEXT_SUBMIT_BODY}
                </PopupConfirm>
              </div>
            )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  users: {
    ...state.users,
    leaderCanAssignList: state.users.leaderCanAssignList.map(
      item => state.entities.users[item]
    ),
  },

  authorityDocument: state.authorityDocument,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentAuthorityPage)
