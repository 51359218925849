import React from 'react'
import { SelectTree } from '../common/'

class ListFilter extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  state = {
    filter: {
      donViId: this.props.defaultValue || '',
    },
    unitSelect: [],
    isLoading: true,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        filter: {
          donViId: nextProps.defaultValue || '',
        },
      })
    }

    if (nextProps.units !== this.props.units) {
      this.setState({ unitSelect: nextProps.units }, () => {
        this.setState({ isLoading: false })
      })
    }
  }

  handleChange(e) {
    if (e === undefined) {
      return
    }

    this.setState(
      {
        filter: {
          ...this.state.filter,
          donViId: e,
        },
      },
      () => {
        this.props.onChange && this.props.onChange(e)
      }
    )
  }

  render() {
    return (
      <div className="search-container search-group-container">
        <div className="row seach-form">
          <div className="col-xs-12 col-sm-12 col-sm-12 end-xs">
            <form className="form-search-basic" onSubmit={this.handleSubmit}>
              <div className="search-header-group form-container search-system-module">
                <div className="search-system-tree">
                  <label className="pt-label pt-label-inline">
                    <span className="text-label">Đơn vị</span>
                  </label>
                  <SelectTree
                    data={this.state.unitSelect}
                    type="Unit"
                    permission={false}
                    value={
                      !this.state.isLoading ? this.state.filter.donViId : ''
                    }
                    handleChange={this.handleChange}
                    isLoading={this.state.isLoading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ListFilter
