import { schema } from 'normalizr'

const PersonalWork = new schema.Entity(
  'personalWorks',
  {},
  {
    idAttribute: personalWork => personalWork.phieuGiaoViecId,
  }
)

export default PersonalWork
