import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get, isEmpty, intersection, size } from 'lodash'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'

import { Permission } from '../../components/auth'
import { HoSoCongViecGroup } from '../../components/tutailieu'
import { STATUS_HSCV } from '../../constants/Api'
import { Utils } from 'helpers'

class HoSoCongViecItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      idActive: -1,
      isActive:
        this.props.filter.isDaLuuTru === true &&
        this.props.filter.isDonVi === true
          ? true
          : false,
      chucDanhId: this.props.auth ? this.props.auth.roleId : null,
      isOpenPopup: false,
    }
  }

  componentDidMount() {
    this.setState(
      {
        idActive: this.props.data.id,
      },
      () => {
        this.setActive()
      }
    )
  }

  setActive = () => {
    const { itemsActive, data } = this.props
    let checkActive = []
    let { isActive } = this.state
    if (!!size(itemsActive)) {
      checkActive = itemsActive.filter(
        elm => elm.id === data.id
        // (elm) => elm.id === data.id && elm.active === false
      )
    }

    if (!!size(checkActive)) {
      isActive = checkActive[0].active
    }

    this.setState({
      idActive: data.id,
      isActive: isActive,
    })
  }

  renderTrangThai = key => {
    switch (key) {
      case 0:
        return (
          <span className="status-job status-black mt0">Chưa nộp hồ sơ</span>
        )
      case 1:
        return <span className="status-job no-process mt0">LĐPB Chờ duyệt</span>
      case 2:
        if (
          this.props.keyTab === 'TTL_DV' &&
          intersection(this.props.auth.permission, [Permission.HSCV_VANTHU])
            .length !== 0
        ) {
          return (
            <span className="status-job no-process mt0">Chờ tiếp nhận</span>
          )
        }
        return <span className="status-job process mt0">LĐPB Đã duyệt</span>
      case 3:
        if (
          this.props.keyTab === 'TTL_DV' &&
          intersection(this.props.auth.permission, [
            Permission.HSCV_VANPHONG_DUYET,
          ]).length !== 0
        ) {
          return (
            <span className="status-job no-process mt0">LĐVP chờ duyệt</span>
          )
        }
        return <span className="status-job done mt0">VT Đã tiếp nhận</span>
      case 4:
        return <span className="status-job approved mt0">Đã lưu hồ sơ</span>
      case 5:
        return <span className="status-job no-approval mt0">Trả lại</span>
      default:
        break
    }
  }

  clickDownUpRow = (id, isActive) => {
    if (isEmpty(id)) {
      return false
    }

    this.props && this.props.hanleKeyActive(id, !isActive)
    this.setState({
      idActive: id,
      isActive: !isActive,
    })
  }

  handleClickPopup = (e, key, data) => {
    e.preventDefault()
    this.props && this.props.handleAction(key, data)
  }

  handleAction = (key, data) => {
    this.props && this.props.handleAction(key, data)
  }

  onViewDetail = data => {
    if (isEmpty(data)) {
      return
    }

    if (data.loai === 0 || data.loai === -1) {
      return
    }

    // Lưu vị trí scroll
    const scrollLocation = get(window, 'pageYOffset', 0)
    Utils.saveData('scroll-hscv-location', scrollLocation)

    const {
      keyTab,
      filter: { isViewAllDonVi },
    } = this.props
    let typeTab = 'don-vi'
    switch (keyTab) {
      case 'TTL_DV':
        typeTab = 'don-vi'
        break
      case 'TTL_PB':
        typeTab = 'phong-ban'
        break
      default:
        break
    }

    return (
      this.props.history &&
      this.props.history.push({
        pathname: `/ho-so-cong-viec/chi-tiet/${typeTab}/${data.id}`,
        state: {
          isHSCVPhongBanDangLap: isViewAllDonVi,
        },
      })
    )
  }

  render() {
    const { auth, data, classLevel, isShowMenuActionThuMuc, filter } =
      this.props
    const { isActive, idActive } = this.state
    const imageIcon = process.env.PUBLIC_URL + '/images/ho-so-cong-viec.svg'
    // Check nếu người chịu trách nhiệm hoặc người phối hơp, hoặc theo trạng thái hscv thì sẽ được thực hiện các action
    let isHienThiSua = false
    if (
      (data.trangThai === 0 || data.trangThai === 5) &&
      (intersection(auth.permission, [Permission.TUTAILIEUPHONGBAN_QUANLY])
        .length !== 0 ||
        (!isEmpty(data) &&
          data.nguoiChiuTrachNhiem &&
          intersection([auth.roleId], [data.nguoiChiuTrachNhiem.chucDanhId])
            .length !== 0))
    ) {
      isHienThiSua = true
    }
    // Check quyền xóa và di chuyển
    let isHienThiDiChuyenXoa = false
    if (
      (data.trangThai === 0 || data.trangThai === 5) &&
      intersection(auth.permission, [Permission.TUTAILIEUPHONGBAN_QUANLY])
        .length !== 0
    ) {
      isHienThiDiChuyenXoa = true
    }
    // Check quyền action thêm mới
    let isHienThiThemMoi = false
    if (
      intersection(auth.permission, [Permission.TUTAILIEUPHONGBAN_QUANLY])
        .length !== 0 &&
      data.loai === 0
    ) {
      isHienThiThemMoi = true
    }
    // Check quyền hiển thị tiếp nhận
    let isHienThiTiepNhan = false
    if (
      this.props.keyTab === 'TTL_DV' &&
      get(data, 'trangThai') === STATUS_HSCV.LDPB_DA_DUYET &&
      intersection(this.props.auth.permission, [Permission.HSCV_VANTHU])
        .length !== 0
    ) {
      isHienThiTiepNhan = true
    }
    // Check quyền người duyệt hồ sơ
    let isHienThiNguoiDuyet = false
    if (
      data.loai !== 0 &&
      ((get(data, 'trangThai') === 1 &&
        intersection(auth.permission, [Permission.HSCV_PHONGBAN_DUYET])
          .length !== 0 &&
        data.nguoiDuyet &&
        data.nguoiDuyet.chucDanhId === auth.roleId) ||
        (get(data, 'trangThai') === 3 &&
          intersection(auth.permission, [Permission.HSCV_VANPHONG_DUYET])
            .length !== 0))
    ) {
      isHienThiNguoiDuyet = true
    }
    // Check hủy nộp
    let isHienThiHuyNop = false
    if (
      !isEmpty(data) &&
      data.trangThai === 1 &&
      data.nguoiChiuTrachNhiem &&
      intersection([auth.roleId], [data.nguoiChiuTrachNhiem.chucDanhId])
        .length !== 0
    ) {
      isHienThiHuyNop = true
    }
    // Hiển thị duyệt lại
    let isHienThiDuyetLai = false
    if (
      data.trangThai === 5 &&
      intersection(auth.permission, [
        Permission.HSCV_PHONGBAN_DUYET,
        Permission.HSCV_VANPHONG_DUYET,
      ]).length !== 0 &&
      data.nguoiDuyet &&
      data.nguoiDuyet.chucDanhId === auth.roleId
    ) {
      isHienThiDuyetLai = true
    }
    let isHienThiTiepNhanLai = false
    if (
      data.trangThai === 5 &&
      intersection(auth.permission, [Permission.HSCV_VANTHU]).length !== 0 &&
      data.nguoiDuyet &&
      data.nguoiDuyet.chucDanhId === auth.roleId
    ) {
      isHienThiTiepNhanLai = true
    }
    // Ẩn luôn menu action
    let showMenuAction = false
    if (
      isHienThiThemMoi === true ||
      isHienThiSua === true ||
      isHienThiNguoiDuyet === true ||
      isHienThiTiepNhan === true ||
      isHienThiHuyNop === true ||
      isHienThiDuyetLai === true ||
      isHienThiTiepNhanLai === true ||
      isHienThiDiChuyenXoa === true
    ) {
      showMenuAction = true
    }
    return (
      <div
        className={classnames('cpc-sortable-body--rows', {
          'cpc-sortable-body--rows-hidden':
            data.id === idActive && isActive === true,
        })}
      >
        <div
          className={classnames('cpc-sortable-body--row', {
            'cpc-sortable-body--row-parent': data.loai === 0,
            'cpc-sortable-body--row-parent-department': data.loai === -1,
            pointer: data.loai === 1,
          })}
          onClick={() => this.onViewDetail(data)}
        >
          <div className="cpc-sortable-body--cell flex-grow-6 pl20">
            <div
              className={classnames(
                `${
                  filter.isChoXuLy === true ||
                  (filter.isTatCa === true && this.props.keyTab === 'TTL_DV')
                    ? ''
                    : classLevel
                }`,
                {
                  'row-bold-multilevel': data.loai === 0,
                }
              )}
            >
              {data.loai !== 1 && (
                <div className="row-arrow-box">
                  {data.dsHoSoCongViecCon.length !== 0 && (
                    <span
                      className={`icon row-arrow-down-up ${
                        isActive ? 'icon-arrow-dropdown' : 'icon-arrow-dropup'
                      }`}
                      onClick={() => this.clickDownUpRow(data.id, isActive)}
                    />
                  )}
                </div>
              )}
              {data.loai === 1 && (
                <i
                  className="hscv-icon mr10"
                  style={{ backgroundImage: `url('${imageIcon}')` }}
                />
              )}
              <span
                className={classnames({
                  'name-department': data.loai === -1,
                  'cursor-default': data.loai !== 1,
                })}
                onClick={() => this.onViewDetail(data)}
              >
                {get(data, 'ten')}
              </span>
            </div>
          </div>
          <div className="cpc-sortable-body--cell flex-grow-2">
            <span>{get(data, 'maKyHieu')}</span>
          </div>
          <div className="cpc-sortable-body--cell flex-grow-2">
            <span>{get(data.nguoiChiuTrachNhiem, 'tenNhanVien')}</span>
          </div>
          {this.props.keyTab === 'TTL_DV' &&
            (filter.isChoXuLy === true ||
              filter.isChoXuLy === null ||
              filter.isTatCa === true) && (
              <div className="cpc-sortable-body--cell flex-grow-2">
                <span>{get(data.phongBanLap, 'maPhongBan')}</span>
              </div>
            )}
          <div className="cpc-sortable-body--cell flex-grow-2">
            <span>{get(data.thoiHanBaoQuan, 'tenThoiHanLuu')}</span>
          </div>
          <div className="cpc-sortable-body--cell flex-grow-2">
            <span>{data.loai === 1 && get(data, 'nam')}</span>
          </div>
          {(this.props.keyTab === 'TTL_PB' ||
            (this.props.keyTab === 'TTL_DV' &&
              (filter.isChoXuLy === true ||
                filter.isChoXuLy === null ||
                filter.isTatCa === true))) && (
            <div className="cpc-sortable-body--cell flex-grow-2">
              {data.loai !== 0 && data.loai !== -1 && (
                <span>{this.renderTrangThai(get(data, 'trangThai'))}</span>
              )}
            </div>
          )}
          {(this.props.keyTab === 'TTL_PB' ||
            (this.props.keyTab === 'TTL_DV' &&
              (filter.isChoXuLy === true ||
                filter.isChoXuLy === null ||
                filter.isTatCa === true))) && (
            <div className="cpc-sortable-body--cell center cursor-default">
              {((isShowMenuActionThuMuc === true &&
                data.loai === 0 &&
                data.loai !== -1 &&
                showMenuAction === true &&
                this.props.keyTab === 'TTL_PB') ||
                (showMenuAction === true &&
                  data.loai !== 0 &&
                  data.loai !== -1)) && (
                <Popover
                  popoverClassName="pt-popover-content-sizing"
                  position={Position.BOTTOM_RIGHT}
                  interactionKind={PopoverInteractionKind.HOVER}
                  openOnTargetFocus={true}
                  content={
                    <div className="menu-popover">
                      {isHienThiSua === true &&
                        !isHienThiDuyetLai &&
                        !isHienThiTiepNhanLai && (
                          <span
                            className="item"
                            onClick={e =>
                              this.handleClickPopup(e, 'CHINH-SUA', data)
                            }
                          >
                            <span className="pt-icon pt-icon-annotation"></span>
                            <span className="name_action">Chỉnh sửa</span>
                          </span>
                        )}
                      {isHienThiDiChuyenXoa === true &&
                        !isHienThiDuyetLai &&
                        !isHienThiTiepNhanLai && (
                          <span
                            className="item"
                            onClick={e => this.handleClickPopup(e, 'XOA', data)}
                          >
                            <span className="pt-icon pt-icon-trash"></span>
                            <span className="name_action">Xóa</span>
                          </span>
                        )}
                      {isHienThiThemMoi === true && (
                        <span
                          className="item"
                          onClick={e =>
                            this.handleClickPopup(
                              e,
                              'THEM-MOI-THU-MUC-CON',
                              data
                            )
                          }
                        >
                          <span className="pt-icon icon-rounded-add-button1"></span>
                          <span className="name_action">
                            Thêm mới thư mục con
                          </span>
                        </span>
                      )}
                      {isHienThiThemMoi === true && (
                        <span
                          className="item"
                          onClick={e =>
                            this.handleClickPopup(e, 'THEM-MOI-HSCV-CON', data)
                          }
                        >
                          <span className="pt-icon icon-rounded-add-button1"></span>
                          <span className="name_action">
                            Thêm mới hồ sơ công việc con
                          </span>
                        </span>
                      )}
                      {isHienThiDiChuyenXoa === true &&
                        !isHienThiDuyetLai &&
                        !isHienThiTiepNhanLai && (
                          <span
                            className="item"
                            onClick={e =>
                              this.handleClickPopup(e, 'DI-CHUYEN', data)
                            }
                          >
                            <span className="pt-icon icon-move-drap"></span>
                            <span className="name_action">Di chuyển</span>
                          </span>
                        )}
                      {isHienThiNguoiDuyet === true && (
                        <span
                          className="item"
                          onClick={e =>
                            this.handleClickPopup(e, 'DUYET-HOSO', data)
                          }
                        >
                          <span className="pt-icon icon-xac-nhan-lai"></span>
                          <span className="name_action">Duyệt</span>
                        </span>
                      )}
                      {isHienThiDuyetLai === true && (
                        <span
                          className="item"
                          onClick={e =>
                            this.handleClickPopup(e, 'DUYET-LAI', data)
                          }
                        >
                          <span className="pt-icon icon-xac-nhan-lai"></span>
                          <span className="name_action">Duyệt lại</span>
                        </span>
                      )}
                      {isHienThiTiepNhanLai === true && (
                        <span
                          className="item"
                          onClick={e =>
                            this.handleClickPopup(e, 'TIEP-NHAN-LAI', data)
                          }
                        >
                          <span className="pt-icon icon-xac-nhan-lai"></span>
                          <span className="name_action">Tiếp nhận lại</span>
                        </span>
                      )}
                      {isHienThiHuyNop === true && (
                        <span
                          className="item"
                          onClick={e =>
                            this.handleClickPopup(e, 'HUY-NOP', data)
                          }
                        >
                          <span className="pt-icon icon-Huy size-icon-12"></span>
                          <span className="name_action">Hủy nộp</span>
                        </span>
                      )}
                      {isHienThiTiepNhan === true && (
                        <span
                          className="item"
                          onClick={e =>
                            this.handleClickPopup(e, 'TIEP-NHAN', data)
                          }
                        >
                          <span className="pt-icon icon-ttl-tiepnhan size-icon-12"></span>
                          <span className="name_action">Tiếp nhận</span>
                        </span>
                      )}
                    </div>
                  }
                >
                  <span className="icon-More"></span>
                </Popover>
              )}
            </div>
          )}
        </div>
        {data.dsHoSoCongViecCon && data.dsHoSoCongViecCon.length !== 0 && (
          <HoSoCongViecGroup
            auth={this.props.auth}
            data={data.dsHoSoCongViecCon}
            handleAction={this.handleAction}
            hanleKeyActive={this.props.hanleKeyActive}
            history={this.props.history}
            itemsActive={this.props.itemsActive}
            keyTab={this.props.keyTab}
            isShowMenuActionThuMuc={this.props.isShowMenuActionThuMuc}
            filter={this.props.filter}
          />
        )}
      </div>
    )
  }
}

HoSoCongViecItem.defaultProps = {
  data: {},
  filter: {},
}

HoSoCongViecItem.propTypes = {
  data: PropTypes.object,
  auth: PropTypes.object.isRequired,
  filter: PropTypes.object,
}

export default HoSoCongViecItem
