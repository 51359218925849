import React, { Component } from 'react'
import { Checkbox, Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  // PopupConfirm,
  DepartmentPerform,
} from '../common/'
import moment from 'moment'
import { Intent } from '@blueprintjs/core'
// import * as POPUP from '../../constants/Popup';
import * as MESSAGE from '../../constants/MessageForm'
import DatePicker from 'react-datepicker'
import * as Tool from '../../helpers'
import { Authorization, Permission } from '../auth'

class ProposeAssignment extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    units: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    // this.handleClickPopup = this.handleClickPopup.bind(this);
    this.handleClickArrow = this.handleClickArrow.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleGetValueGridCheckBox = this.handleGetValueGridCheckBox.bind(this)
    //this.handleScroll = this.handleScroll.bind(this);
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    error: {},
    isOpen: false,
    isOpenPopup: false,
    deXuatChiDaoId: '',
    congVanDenId: '',
    noiDung: '',
    chuTriId: null,
    dsPhoiHop: [],
    dsXemDeBiet: [],
    donViChuTriId: null,
    dsDonViPhoiHop: [],
    dsDonViXemDeBiet: [],
    hanGiaiQuyet: undefined,
    // docHanChe: false,
    luuBanGoc: false,
    departmentSelections: [],
    attachedUnitSelections: [],
    isCheckedAllAttachedUnits: false,
    isLoading: false,
    showGroupbtn: false,
    //isHideButton: false,
    dealineToString: '',
  }

  // handleClickPopup = () => {
  //   this.setState({
  //     isOpenPopup: !this.state.isOpenPopup
  //   });
  // }
  handleClickArrow = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleDateChange = (name, nameToString, e) => {
    let value = e ? e : undefined
    let valueToString = ''
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: value,
      [nameToString]: valueToString,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment().format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    let oldValue = undefined
    let oldValueToString = ''
    if (
      this.props.proposeAssignment &&
      this.props.proposeAssignment.hanGiaiQuyet
    ) {
      oldValue = moment(this.props.proposeAssignment.hanGiaiQuyet)
      oldValueToString = moment(
        this.props.proposeAssignment.hanGiaiQuyet
      ).format('DD/MM/YYYY')
    }

    this.setState({
      [nameToString]: oldValueToString,
      [name]: oldValue,
    })
  }

  handleSubmit = () => {
    if (
      (!this.state.chuTriId || !this.state.chuTriId.length) &&
      (!this.state.donViChuTriId || !this.state.donViChuTriId.length)
    ) {
      return this.props.onSubmit(
        'DeXuatChiDao',
        MESSAGE.TEXT_MESSAGE_PHONGBANCHUTRI,
        Intent.WARNING,
        false,
        null
      )
    }

    this.setState({ isLoading: true })

    let hanGiaiQuyet = null
    if (
      this.state.hanGiaiQuyet &&
      this.state.hanGiaiQuyet.toString().length > 0
    ) {
      hanGiaiQuyet = moment(this.state.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    let dataRequest = {
      cong_van_den_id: this.state.congVanDenId,
      noi_dung: this.state.noiDung,
      chu_tri_id: this.state.chuTriId,
      ds_phoi_hop: this.state.dsPhoiHop,
      ds_xem_de_biet: this.state.dsXemDeBiet,
      don_vi_chu_tri_id: this.state.donViChuTriId,
      ds_don_vi_phoi_hop: this.state.dsDonViPhoiHop,
      ds_don_vi_xem_de_biet: this.state.dsDonViXemDeBiet,
      han_giai_quyet: hanGiaiQuyet,
      // doc_han_che: this.state.docHanChe,
      luu_ban_goc: this.state.luuBanGoc,
    }

    if (this.props.proposeAssignment) {
      dataRequest = {
        ...dataRequest,
        de_xuat_chi_dao_id: this.state.deXuatChiDaoId,
      }
      this.props.actions
        .receiveDocumentUpdateProposeAssigment(
          this.state.congVanDenId,
          dataRequest
        )
        .then(res => {
          if (
            res.payload &&
            res.payload.status === 200 &&
            res.payload.data &&
            res.payload.data.result === true
          ) {
            this.props.onSubmit(
              'DeXuatChiDao',
              MESSAGE.TEXT_PROPOSE_ASSIGN_SUCCESS,
              Intent.SUCCESS,
              true,
              null
            )
          } else {
            this.setState({ isLoading: false })
            this.props.onSubmit(
              'DeXuatChiDao',
              MESSAGE.TOATS_MESSAGE_FAIL,
              Intent.DANGER,
              false,
              null
            )
          }
        })
    } else {
      this.props.actions
        .receiveDocumentSendProposeAssignment(
          this.props.congVanDenId,
          dataRequest
        )
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            this.props.onSubmit(
              'DeXuatChiDao',
              MESSAGE.TEXT_PROPOSE_ASSIGN_SUCCESS,
              Intent.SUCCESS,
              true,
              null
            )
          } else {
            this.setState({ isLoading: false })
            this.props.onSubmit(
              'DeXuatChiDao',
              MESSAGE.TOATS_MESSAGE_FAIL,
              Intent.DANGER,
              false,
              null
            )
          }
        })
    }
  }

  handleInputChange = e => {
    if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  handleGetValueGridCheckBox = (type, data) => {
    if (!data || !Object.keys(data).length) {
      return
    }

    switch (type) {
      case 'units':
        let chuTriId = this.state.chuTriId
        if (!!this.state.donViChuTriId !== !!data.chuTriId) {
          chuTriId = null
        }
        return this.setState({
          chuTriId,
          donViChuTriId: data.chuTriId,
          dsDonViPhoiHop: data.dsPhoiHop,
          dsDonViXemDeBiet: data.dsXemDeBiet,
        })

      default:
        let donViChuTriId = this.state.donViChuTriId
        if (this.state.chuTriId !== data.chuTriId) {
          donViChuTriId = null
        }
        return this.setState({
          donViChuTriId,
          chuTriId: data.chuTriId,
          dsPhoiHop: data.dsPhoiHop,
          dsXemDeBiet: data.dsXemDeBiet,
        })
    }
  }

  componentDidMount() {
    let deXuatChiDaoId = ''
    let noiDung = ''
    let chuTriId = null
    let dsPhoiHop = []
    let dsXemDeBiet = []
    let donViChuTriId = null
    let dsDonViPhoiHop = []
    let dsDonViXemDeBiet = []
    let luuBanGoc = false
    let isOpen = false
    let error = this.state.error
    let currentDate = moment().format('YYYY-MM-DD')

    let hanGiaiQuyet = undefined
    let dealineToString = ''
    if (this.props.proposeAssignment) {
      if (
        this.props.proposeAssignment.hanGiaiQuyet &&
        Tool.Utils.isValidDate(
          moment(this.props.proposeAssignment.hanGiaiQuyet).format('DD/MM/YYYY')
        )
      ) {
        hanGiaiQuyet = moment(this.props.proposeAssignment.hanGiaiQuyet)
        dealineToString = moment(
          this.props.proposeAssignment.hanGiaiQuyet
        ).format('DD/MM/YYYY')
        if (
          moment(this.props.proposeAssignment.hanGiaiQuyet).format(
            'YYYY-MM-DD'
          ) < currentDate
        ) {
          error.hanGiaiQuyet = MESSAGE.TEXT_MESSAGE_HANGIAIQUYET
        }
      }

      if (this.props.proposeAssignment.deXuatChiDaoId) {
        deXuatChiDaoId = this.props.proposeAssignment.deXuatChiDaoId
      }
      if (this.props.proposeAssignment.noiDung) {
        noiDung = this.props.proposeAssignment.noiDung
      }
      if (typeof this.props.proposeAssignment.luuBanGoc === 'boolean') {
        luuBanGoc = this.props.proposeAssignment.luuBanGoc
      }
      if (this.props.proposeAssignment.chuTri) {
        chuTriId = this.props.proposeAssignment.chuTri
      }
      if (this.props.proposeAssignment.phoiHop) {
        dsPhoiHop = this.props.proposeAssignment.phoiHop
      }
      if (this.props.proposeAssignment.xemDeBiet) {
        dsXemDeBiet = this.props.proposeAssignment.xemDeBiet
      }
      if (this.props.proposeAssignment.donViChuTri) {
        donViChuTriId = this.props.proposeAssignment.donViChuTri
        isOpen = true
      }
      if (this.props.proposeAssignment.donViPhoiHop) {
        dsDonViPhoiHop = this.props.proposeAssignment.donViPhoiHop
      }
      if (this.props.proposeAssignment.donViXemDeBiet) {
        dsDonViXemDeBiet = this.props.proposeAssignment.donViXemDeBiet
      }

      if (dsDonViPhoiHop.length > 0 || dsDonViXemDeBiet.length > 0) {
        isOpen = true
      }
    }

    // TODO: Hot fix: show tat ca phong ban
    // const { mainDepartmentId } = this.props;
    // const currentDepartment = this.props.departments.find(item => item.phongBanId === mainDepartmentId);
    // const levelDepartment = currentDepartment && currentDepartment.loai;
    // const departmentSelections = [];
    // levelDepartment && this.props.departments.forEach((item,i) => {
    //   if (item.phongBanId === mainDepartmentId || item.loai > levelDepartment) {
    //     departmentSelections.push({content: item.maPhongBan, value: item.phongBanId});
    //   }
    // });

    const departmentSelections = []
    this.props.departments.forEach((item, i) => {
      departmentSelections.push({
        content: item.maPhongBan,
        value: item.phongBanId,
      })
    })

    const attachedUnitSelections = []
    this.props.units.forEach(item => {
      attachedUnitSelections.push({
        content: item.tenVietTat,
        value: item.donViId,
      })
    })

    this.setState({
      deXuatChiDaoId,
      noiDung,
      dealineToString,
      hanGiaiQuyet,
      // docHanChe: this.props.proposeAssignment.docHanChe,
      luuBanGoc,
      chuTriId,
      dsPhoiHop,
      dsXemDeBiet,
      donViChuTriId,
      dsDonViPhoiHop,
      dsDonViXemDeBiet,
      // dsDonViTrucThuoc,
      error,
      isOpen,
      congVanDenId: this.props.congVanDenId,
      departmentSelections,
      attachedUnitSelections,
    })

    // window.addEventListener('scroll', this.handleScroll);
    // let windowSize = window.innerHeight;
    // let gb = document.getElementById("groupButton");
    // let self = this;
    // setTimeout(function() {
    //   if (windowSize > gb.getBoundingClientRect().top) {
    //     self.setState({
    //       isHideButton: false
    //     });
    //   } else {
    //     self.setState({
    //       isHideButton: true
    //     });
    //   }
    // }, 100);
  }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }
  //
  // handleScroll (event) {
  //   let windowSize = window.innerHeight;
  //   let gb = document.getElementById("groupButton");
  //   if (windowSize >= gb.getBoundingClientRect().top) {
  //     this.setState({
  //       isHideButton: false
  //     });
  //   } else {
  //     this.setState({
  //       isHideButton: true
  //     });
  //   }
  // }

  render() {
    return (
      <div className="container-form-action">
        <div className="heading">
          <span onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>
            {this.state.deXuatChiDaoId
              ? 'Sửa đề xuất chỉ đạo'
              : 'Đề xuất chỉ đạo'}
          </h4>
        </div>

        <div className="form-container container-detail">
          <div className="pt-form-group">
            <div className="row full-row">
              <div className="col-xs-12 col-md-3 col-form-item">
                <label className="pt-label">Nội dung đề xuất chỉ đạo</label>
              </div>
              <div className="col-xs-12 col-md-9 col-form-item">
                <div className="pt-form-content">
                  <textarea
                    name="noiDung"
                    value={this.state.noiDung}
                    onChange={this.handleInputChange}
                    rows="1"
                    className="pt-input pt-fill"
                    dir="auto"
                    autoFocus={true}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-form-group">
            <label className="pt-label pt0">Phòng ban thực hiện</label>
            <DepartmentPerform
              options={this.state.departmentSelections}
              onSubmit={this.handleGetValueGridCheckBox.bind(
                this,
                'departments'
              )}
              dsPhoiHop={this.state.dsPhoiHop}
              dsXemDeBiet={this.state.dsXemDeBiet}
              chuTriId={this.state.chuTriId}
              selectedCompositiveDepartments={[]}
              selectedOnlySeeDepartments={[]}
              titles={['CT', 'PH', 'XĐB']}
              isHasRadio={true}
              type="Phòng ban"
            />
          </div>

          {/* Các đơn vị trực thuộc */}
          {this.state.attachedUnitSelections &&
            this.state.attachedUnitSelections.length > 0 && (
              <Authorization.Element
                permission={[Permission.CONGVANDEN_CHIDAO_DVTRUCTHUOC]}
              >
                <div className="heading-group-select disabled-checkbox">
                  <label className="pt-label pt0">
                    <span
                      className="pull-left name_group name_group_unit"
                      onClick={this.handleClickArrow}
                    >
                      Các đơn vị trực thuộc
                    </span>
                  </label>
                  <span
                    onClick={this.handleClickArrow}
                    className={classnames('name_group_unit', {
                      'pt-icon pt-icon-chevron-up': this.state.isOpen,
                      'pt-icon pt-icon-chevron-down': !this.state.isOpen,
                    })}
                  ></span>
                </div>
                <Collapse isOpen={this.state.isOpen}>
                  <div className="pt-form-group">
                    <DepartmentPerform
                      options={this.state.attachedUnitSelections}
                      onSubmit={this.handleGetValueGridCheckBox.bind(
                        this,
                        'units'
                      )}
                      dsPhoiHop={this.state.dsDonViPhoiHop}
                      dsXemDeBiet={this.state.dsDonViXemDeBiet}
                      chuTriId={this.state.donViChuTriId}
                      selectedCompositiveDepartments={[]}
                      selectedOnlySeeDepartments={[]}
                      titles={['CT', 'PH', 'XĐB']}
                      isHasRadio={true}
                      type="Đơn vị"
                    />
                  </div>
                </Collapse>
              </Authorization.Element>
            )}

          {/* Option Box */}
          <div className="pt-form-group pt-inline resolve-date">
            <label className="pt-label resolve-date-padding0">
              Hạn giải quyết
            </label>
            <div className="pt-form-content">
              <div className="pt-input-group pt-large">
                <DatePicker
                  isClearable={this.state.dealineToString ? true : false}
                  readOnly={this.props.readOnly}
                  selected={this.state.hanGiaiQuyet}
                  onChange={this.handleDateChange.bind(
                    this,
                    'hanGiaiQuyet',
                    'dealineToString'
                  )}
                  value={this.state.dealineToString}
                  onChangeRaw={e =>
                    this.handleChangeRaw('dealineToString', e.target.value)
                  }
                  onBlur={e =>
                    this.focousOut(
                      'hanGiaiQuyet',
                      'dealineToString',
                      e.target.value
                    )
                  }
                  format="DD/MM/YYYY"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker"
                  placeholderText="Chọn ngày..."
                  minDate={moment()}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
            <div className="note-deadline">
              <p className="text">
                Nếu không chọn LĐ Phòng ban chủ trì sẽ xác định
              </p>
            </div>
            {this.state.error.hanGiaiQuyet !== undefined &&
              !this.props.workAssignInformations && (
                <div className="col-xs-12 col-md-offset-3 col-md-9 pt-form-helper-content">
                  <p className="pt-form-helper-text">
                    {this.state.error.hanGiaiQuyet}
                  </p>
                </div>
              )}
          </div>
          <div className="selectbox-group">
            <div className="row full-row">
              {/*<div className="col-md-3 col-xs-12">
                <Checkbox
                  className="pt-inline"
                  name="docHanChe"
                  checked={this.state.docHanChe}
                  onChange={this.handleInputChange}
                >
                  <span className="name_checkbox">Đọc hạn chế</span>
                </Checkbox>
              </div>*/}
              <div className="col-md-9 col-xs-12">
                <Checkbox
                  className="pt-inline"
                  name="luuBanGoc"
                  checked={this.state.luuBanGoc}
                  onChange={this.handleInputChange}
                >
                  <span className="name_checkbox">
                    Phòng ban chủ trì lưu bản giấy
                  </span>
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div id="groupButton" className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>

        <div className="buttton-actionfooter-fixtop buttton-action-footer">
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleSubmit}
            loading={this.state.isLoading}
          >
            <span className="pt-icon icon-save"></span>
            <span className="text-content">Lưu</span>
          </Button>
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
            disabled={this.state.isLoading === true ? true : false}
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
        </div>

        {/*<PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={this.state.deXuatChiDaoId ? POPUP.HUY_SUA_DE_XUAT_CHI_DAO : POPUP.HUY_GUI_DE_XUAT_CHI_DAO}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.props.onClickGoBack}
        >
          {POPUP.TEXT_BODY}
        </PopupConfirm>*/}
      </div>
    )
  }
}

export default ProposeAssignment
