import React from 'react'
class LoadingLine extends React.Component {
  render() {
    return (
      <div className="timeline-wrapper">
        <div className="timeline-item">
          <div className="animated-background-item"></div>
          <div className="animated-background-item"></div>
        </div>
      </div>
    )
  }
}

export default LoadingLine
