import { isArray } from 'lodash'

export const required = value => {
  return !value.toString().trim().length
}

export const maxLength = (value, limit) => {
  return value.toString().trim().length > limit
}

export const maxNumber = (value, limit) => {
  return parseInt(value, 10) > limit
}

export const minNumber = (value, limit) => {
  return parseInt(value, 10) < limit
}

export const fileType = (value, type_list = []) => {
  if (!isArray(type_list)) {
    return
  }
  return !type_list.includes(value)
}

export const maxFileSize = (value, limit) => {
  return value > limit * 1024 * 1024
}

export const selectRequired = value => {
  return value.toString() === '-1'
}

export const unique = (value, list) => {
  return (
    list.map(i => i.toLowerCase()).filter(i => i === value.toLowerCase())
      .length > 1
  )
}
