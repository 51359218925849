import React, { Component } from 'react'
import HeaderMenuItem from './HeaderMenuItem'
import PropTypes from 'prop-types'
import { Authorization } from 'components/auth'
import { checkPermissionMenu } from 'helpers/Helper'

class HeaderMenu extends Component {
  static propTypes = {
    menu: PropTypes.array.isRequired,
    quyensMenu: PropTypes.array,
  }

  render() {
    const quyensMenu = this.props?.quyensMenu
    const Permission = (pers = []) => {
      return Authorization.Component(pers)(HeaderMenuItem)
    }

    return (
      <ul className="list-menu">
        {this.props.menu
          ?.filter(
            e =>
              !e?.permissionMenu ||
              (e?.permissionMenu &&
                checkPermissionMenu(quyensMenu, e.permissionMenu))
          )
          ?.map(item => {
            const Auth = Permission(item.permission)
            return (
              <Auth
                key={item.name}
                item={item}
                hideWhenclickMenu={this.props.hideWhenclickMenu}
                orPermission={item.orPermission}
                actions={this.props.actions}
              />
            )
          })}
        <li className="item logout-mobile">
          <a className="menu-link" href="#/" onClick={this.props.handleLogout}>
            <span className="icon-logout top-icon"></span>
            <span className="text-content">ĐĂNG XUẤT</span>
          </a>
        </li>
      </ul>
    )
  }
}

export default HeaderMenu
