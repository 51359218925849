import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import * as Tool from '../../helpers'
import * as Types from '../../constants/Api'
import { get } from 'lodash'

const fileItemStyle = {
  fontSize: '13px',
  color: '#212121',
  marginBottom: '10px',
}

class DetailBox extends Component {
  render() {
    const detail = get(this.props, 'data', {})
    const token = get(this.props, 'auth.token.accessToken', '')
    const fileBaoCaoUrl = detail.fileBaoCao
      ? detail.fileBaoCao.migrated
        ? `${Types.OLD_DOCUMENT_URL}${detail.fileBaoCao.url}&BearerToken=${token}`
        : `${Types.FILE_URL}/api/file/get/${detail.fileBaoCao.fileId}?BearerToken=${token}`
      : null
    return (
      <div className="box-action-wrapper">
        <div className="box-action-block list-action list-action-sign-number">
          <p className="title">Thực Hiện</p>
          {!!detail && (
            <ul className="data-column">
              <li>
                <div className="row item-value">
                  <div className="col-xs-4 left-content data-column-items">
                    <label>Trích yếu:</label>
                  </div>
                  <div className="col-xs-8 right-content">
                    <div className="content">{detail.trichYeu}</div>
                  </div>
                </div>
              </li>

              <li>
                <div className="row item-value">
                  <div className="col-xs-4 left-content data-column-items">
                    <label>Nội dung trình HĐTV:</label>
                  </div>
                  <div className="col-xs-8 right-content">
                    <div className="content">{detail.noiDungTrinh}</div>
                  </div>
                </div>
              </li>

              <li>
                <div className="row item-value">
                  <div className="col-xs-4 left-content data-column-items">
                    <label>
                      File báo cáo của Ban TH ({detail.fileBaoCao ? 1 : 0}):
                    </label>
                  </div>
                  <div className="col-xs-8 right-content">
                    {detail.fileBaoCao && (
                      <span className="detail">
                        <div style={fileItemStyle}>
                          <span className="info">
                            <a
                              style={{
                                backgroundImage: `url(${Tool.Utils.typeFile(
                                  detail.fileBaoCao.kieuFile.substring(1)
                                )})`,
                              }}
                              className="link-file"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={fileBaoCaoUrl}
                            >
                              {detail.fileBaoCao.tenFile} -{' '}
                              {Tool.Utils.getFileSize(
                                detail.fileBaoCao.kichThuoc
                              )}
                            </a>
                          </span>
                        </div>
                      </span>
                    )}
                  </div>
                </div>
              </li>

              <li>
                <div className="row item-value">
                  <div className="col-xs-4 left-content data-column-items">
                    <label>
                      File đính kèm
                      {!!detail.dsFileDinhKem.length &&
                        ` (${detail.dsFileDinhKem.length})`}
                      :
                    </label>
                  </div>
                  <div className="col-xs-8 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      {detail.dsFileDinhKem.map((item, index) => {
                        const url = item.migrated
                          ? `${Types.OLD_DOCUMENT_URL}${item.url}&BearerToken=${token}`
                          : `${Types.FILE_URL}/api/file/get/${item.fileId}?BearerToken=${token}`
                        return (
                          <div style={fileItemStyle} key={index}>
                            <span className="info">
                              <a
                                style={{
                                  backgroundImage: `url(${Tool.Utils.typeFile(
                                    item.kieuFile.substring(1)
                                  )})`,
                                }}
                                className="link-file"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={url}
                              >
                                {item.tenFile} -{' '}
                                {Tool.Utils.getFileSize(item.kichThuoc)}
                              </a>
                            </span>
                          </div>
                        )
                      })}
                    </Scrollbars>
                  </div>
                </div>
              </li>

              <li>
                <div className="row item-value">
                  <div className="col-xs-4 left-content data-column-items">
                    <label>
                      File tham khảo
                      {!!detail.dsFileThamKhao.length &&
                        ` (${detail.dsFileThamKhao.length})`}
                      :
                    </label>
                  </div>
                  <div className="col-xs-8 right-content">
                    <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={200}
                    >
                      {detail.dsFileThamKhao.map((item, index) => {
                        const url = item.migrated
                          ? `${Types.OLD_DOCUMENT_URL}${item.url}&BearerToken=${token}`
                          : `${Types.FILE_URL}/api/file/get/${item.fileId}?BearerToken=${token}`
                        return (
                          <div style={fileItemStyle} key={index}>
                            <span className="info">
                              <a
                                style={{
                                  backgroundImage: `url(${Tool.Utils.typeFile(
                                    item.kieuFile.substring(1)
                                  )})`,
                                }}
                                className="link-file"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={url}
                              >
                                {item.tenFile} -{' '}
                                {Tool.Utils.getFileSize(item.kichThuoc)}
                              </a>
                            </span>
                          </div>
                        )
                      })}
                    </Scrollbars>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    )
  }
}

DetailBox.propTypes = {
  auth: PropTypes.object.isRequired,
  data: PropTypes.object,
}

DetailBox.defaultProps = {
  data: {},
}

export default DetailBox
