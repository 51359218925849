export const variables = {
  DATE_FORMAT: {
    DATE: 'YYYY-MM-DD',
  },
  PAGEMENUDETAIL: {
    CONGVANDITHUHOI: 'CONGVANDITHUHOI',
    CONGVANDI: 'CONGVANDI',
  },
  FUNC_KEY: {
    THU_HOI: 'THU_HOI',
    HUY_DE_XUAT: 'HUY_DE_XUAT',
  },
}

export default variables
