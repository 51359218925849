import merge from 'lodash/merge'
import * as types from '../constants/ActionTypes'

const initialState = {
  receiveDocuments: {},
  executingViews: {},
  assignmentHistory: {},
  assignmentInformation: {},
  reportWork: {},
  digitalDocuments: {},
  internalDocuments: {},
  documentsCabinet: {},
  sendDocuments: {},
  signNumbers: {},
  signNumberFeedbacks: {},
  signNumberProjectMembers: {},
  signNumberProjects: {},
  projects: {},
  personalWorks: {},
  departmentWorks: {},
  documentCabinetCategories: {},
  departmentWorksAssigned: {},
  alertMistakes: {},
  flowDocumentReceiveDocument: {},
  users: {},
  evnDocuments: {},
  notifications: {},
  alertMistakesDocument: {},
  requestComments: {},
}

export default function entities(state = initialState, action) {
  if (action.payload && action.payload.data && action.payload.data.entities) {
    switch (action.type) {
      case types.ALERT_MISTAKE_GET_LIST:
        if (action.payload.data.entities.alertMistakesDocument) {
          Object.keys(
            action.payload.data.entities.alertMistakesDocument
          ).forEach(k => {
            if (state.alertMistakesDocument[k]) {
              delete state.alertMistakesDocument[k]
            }
          })
        }

        if (action.payload.data.entities.alertMistakes) {
          Object.keys(action.payload.data.entities.alertMistakes).forEach(k => {
            if (state.alertMistakes[k]) {
              delete state.alertMistakes[k]
            }
          })
        }
        break

      case types.WORK_GET_REPORT:
        if (action.payload.data.entities.reportWork) {
          Object.keys(action.payload.data.entities.reportWork).forEach(k => {
            if (state.reportWork[k]) {
              delete state.reportWork[k]
            }
          })
        }
        break

      case types.SIGN_NUMBER_SEND:
      case types.SIGN_NUMBER_INTERNAL:
        if (action.payload.data.entities.signNumbers) {
          Object.keys(action.payload.data.entities.signNumbers).forEach(k => {
            if (state.signNumbers && state.signNumbers[k]) {
              delete state.signNumbers[k]
            }
          })
        }
        break

      case types.EXECUTING_VIEW:
        if (action.payload.data.entities.executingViews) {
          Object.keys(action.payload.data.entities.executingViews).forEach(
            k => {
              if (state.executingViews && state.executingViews[k]) {
                delete state.executingViews[k]
              }
            }
          )
        }
        break

      case types.PERSONAL_WORK_ITEM:
      case types.PERSONAL_WORK:
        if (action.payload.data.entities.personalWorks) {
          Object.keys(action.payload.data.entities.personalWorks).forEach(k => {
            if (state.personalWorks && state.personalWorks[k]) {
              delete state.personalWorks[k]
            }
          })
        }
        break

      case types.WORK_ITEM:
      case types.DEPARTMENT_WORK_NOT_ASSIGN:
      case types.DEPARTMENT_WORK_ASSIGNED:
        if (action.payload.data.entities.departmentWorks) {
          Object.keys(action.payload.data.entities.departmentWorks).forEach(
            k => {
              if (state.departmentWorks && state.departmentWorks[k]) {
                delete state.departmentWorks[k]
              }
            }
          )
        }
        break

      case types.WORK_ASSIGNMENT_INFORMATION:
        if (action.payload.data.entities.assignmentInformation) {
          Object.keys(
            action.payload.data.entities.assignmentInformation
          ).forEach(k => {
            if (state.assignmentInformation && state.assignmentInformation[k]) {
              delete state.assignmentInformation[k]
            }
          })
        }
        break

      case types.DOCUMENTS_CABINET_UNIT_CATEGORIES:
        if (action.payload.data.entities.documentCabinetCategories) {
          Object.keys(
            action.payload.data.entities.documentCabinetCategories
          ).forEach(k => {
            if (
              state.documentCabinetCategories &&
              state.documentCabinetCategories[k]
            ) {
              delete state.documentCabinetCategories[k]
            }
          })
        }
        break
      case types.DOCUMENTS_CABINET_PERSONAL_CATEGORIES:
        if (action.payload.data.entities.documentCabinetCategories) {
          Object.keys(
            action.payload.data.entities.documentCabinetCategories
          ).forEach(k => {
            if (
              state.documentCabinetCategories &&
              state.documentCabinetCategories[k]
            ) {
              delete state.documentCabinetCategories[k]
            }
          })
        }
        break
      case types.DOCUMENTS_CABINET_DEPARTMENT_CATEGORIES:
        if (action.payload.data.entities.documentCabinetCategories) {
          Object.keys(
            action.payload.data.entities.documentCabinetCategories
          ).forEach(k => {
            if (
              state.documentCabinetCategories &&
              state.documentCabinetCategories[k]
            ) {
              delete state.documentCabinetCategories[k]
            }
          })
        }
        break
      case types.DOCUMENT_SEND_DETAIL:
        if (action.payload.data.entities.sendDocuments) {
          Object.keys(action.payload.data.entities.sendDocuments).forEach(k => {
            if (state.sendDocuments && state.sendDocuments[k]) {
              delete state.sendDocuments[k]
            }
          })
        }
        break
      case types.DOCUMENT_INTERNAL_GET:
        if (action.payload.data.entities.internalDocuments) {
          Object.keys(action.payload.data.entities.internalDocuments).forEach(
            k => {
              if (state.internalDocuments && state.internalDocuments[k]) {
                delete state.internalDocuments[k]
              }
            }
          )
        }
        break
      case types.SIGN_NUMBER_PROJECTS_GET_DETAIL:
        if (action.payload.data.entities.signNumberProjects) {
          Object.keys(action.payload.data.entities.signNumberProjects).forEach(
            k => {
              if (state.signNumberProjects && state.signNumberProjects[k]) {
                delete state.signNumberProjects[k]
              }
            }
          )
        }
        break
      case types.SIGN_NUMBER_INTERNAL_DETAIL:
        if (action.payload.data.entities.signNumbers) {
          Object.keys(action.payload.data.entities.signNumbers).forEach(k => {
            if (state.signNumbers && state.signNumbers[k]) {
              delete state.signNumbers[k]
            }
          })
        }
        break
      // lay y kien don vi
      case types.REQUEST_COMMENT_DETAIL:
        if (action.payload.data.entities.requestComments) {
          Object.keys(action.payload.data.entities.requestComments).forEach(
            k => {
              if (state.requestComments && state.requestComments[k]) {
                delete state.requestComments[k]
              }
            }
          )
        }
        break
      default:
    }

    return merge({}, state, action.payload.data.entities)
  }

  return state
}
