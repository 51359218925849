import {
  GET_THONGKE_TRANG_CHU,
  GET_THONGKE_QUANLY_CONGVIEC_TRANG_THAI,
  WORKS_STATISTICS_DASHBOARD,
  GET_WORK_TYPES,
  GET_COUNTER_CONGVIEC_PHONGBAN_CANHAN,
} from '../constants/ActionTypes'

const initialState = {
  statisticsHomePage: [],
  statisticsQuanLyCongViecTrangThaiCongViec: {},
  statisticsDashboard: null,
  listWorkTypes: [],
  counterCongViecPhongBanCaNhan: null,
}

export default function TaskManager(state = initialState, action) {
  switch (action.type) {
    case GET_THONGKE_TRANG_CHU:
      return {
        ...state,
        statisticsHomePage: action.payload.data || [],
      }
    case GET_THONGKE_QUANLY_CONGVIEC_TRANG_THAI:
      return {
        ...state,
        statisticsQuanLyCongViecTrangThaiCongViec:
          action.payload.data.result || {},
      }
    case WORKS_STATISTICS_DASHBOARD:
      return {
        ...state,
        statisticsDashboard: action?.payload?.data?.result || null,
      }
    case GET_WORK_TYPES:
      return {
        ...state,
        listWorkTypes: action?.payload?.data?.result || [],
      }
    case GET_COUNTER_CONGVIEC_PHONGBAN_CANHAN:
      return {
        ...state,
        counterCongViecPhongBanCaNhan: action.payload?.data?.result,
      }
    default:
      return state
  }
}
