import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Tool from '../../helpers'
import * as POPUP from '../../constants/Popup'
import { PopupConfirm } from '../common/'

class DigitizingItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClickShowDetail: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.handleClickShowDetail = this.handleClickShowDetail.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  state = {
    vanBanSoHoaId: '',
    fileName: null,
    isOpenPopup: false,
    idDelete: '',
  }

  handleClickShowDetail = () => {
    this.props.onClickShowDetail &&
      this.props.onClickShowDetail(
        this.state.vanBanSoHoaId,
        this.state.fileName
      )
  }

  handleClickPopup = id => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      idDelete: id,
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState(
      {
        isOpenPopup: false,
      },
      () => {
        if (this.state.idDelete) {
          this.props.onHandleSubmitRemoveItem &&
            this.props.onHandleSubmitRemoveItem(this.state.idDelete)
        }
      }
    )
  }

  componentDidMount() {
    this.setState({
      vanBanSoHoaId: this.props.item.vanBanSoHoaId,
      fileName: this.props.item.tenFile,
    })
  }

  render() {
    let date = null
    if (
      this.props.item.ngayCongVan &&
      Tool.Utils.isValidDate(
        moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')
      )
    ) {
      date = moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')
    }
    const { item } = this.props
    return (
      <div className="item">
        <span
          className="pt-icon pt-icon-trash list-digitizing-icon-trash"
          onClick={this.handleClickPopup.bind(this, item.vanBanSoHoaId)}
        ></span>
        {this.props.item.ngayCongVan && (
          <p className="date-update" onClick={this.handleClickShowDetail}>
            <span>Ngày công văn:&nbsp; {date}</span>
          </p>
        )}
        <p className="code" onClick={this.handleClickShowDetail}>
          {item && item.soCongVan}
        </p>
        <p onClick={this.handleClickShowDetail}>
          <span className="label-item">Trích yếu:</span>
          <span className="info textarea-content">{item && item.trichYeu}</span>
        </p>
        <p onClick={this.handleClickShowDetail}>
          <span className="label-item">Cơ quan ban hành:</span>
          <span className="info">{item && item.coQuanBanHanh}</span>
        </p>
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleClickPopup}
          title={POPUP.XOA_VAN_BAN_SO_HOA}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleSubmitRemoveItem}
        >
          {POPUP.TEXT_SUBMIT_BODY}
        </PopupConfirm>
      </div>
    )
  }
}

export default DigitizingItem
