import { Permission } from 'components/auth'
import workManager from './work-manager'

const allPermissions = [
  Permission.CONGVAN,
  Permission.CONGVANDEN,
  Permission.CONGVANDEN_QUANLY,
  Permission.CONGVANDEN_CHUYENCHIDAO,
  Permission.CONGVANDEN_CHIDAO,
  Permission.CONGVANDEN_DEXUATCHIDAO,
  Permission.CONGVANDEN_LICHSUCHIDAO,
  Permission.CONGVANDEN_LICHSUCONGVAN,
  Permission.CONGVANDI,
  Permission.CONGVANDI_QUANLY,
  Permission.CONGVANNOIBO_QUANLY,
  Permission.CONGVIEC,
  Permission.CONGVIEC_PHONGBAN,
  Permission.CONGVIEC_PHONGBAN_HOANTAT,
  Permission.CONGVIEC_CANHAN,
  Permission.CONGVIEC_GIAOVIEC,
  Permission.KYSO_QUANLY,
  Permission.KYSO_KYBANHANH,
  Permission.KYSO_KYDUYET_GOPY,
  Permission.KYSO_CAPSO,
  Permission.DANHBA,
  Permission.TUTAILIEU,
  Permission.TUTAILIEUDONVI_QUANLY,
  Permission.TUTAILIEUPHONGBAN_QUANLY,
  Permission.HETHONG,
  Permission.DOKHAN_QUANLY,
  Permission.DOBAOMAT_QUANLY,
  Permission.DOITUONGCONGVAN_QUANLY,
  Permission.SOLUUTRU_QUANLY,
  Permission.ADMIN,
  Permission.SUPERADMIN,
  Permission.KY_SO_TAP_TRUNG,
  Permission.CONGVAN_XEMTATCA_6_1,
]

const menuSidebar = {
  documentary: [
    {
      nameMenu: 'Công văn cần xử lý / theo dõi',
      permission: [Permission.CONGVANDEN],
      permissionMenu: [Permission.MENU_SIDEBAR.SMENU_CVAN_CANXULY],
      subMenu: [
        {
          nameMenu: 'Chưa xử lý',
          path: '/cong-van/cong-van-den/danh-sach/chua-xu-ly',
          icon: 'icon-Cong_van_chua_xu_ly',
          type: 'CHUA_XU_LY',
          links: [],
          permission: [],
        },
        {
          nameMenu: 'Công văn từ EVN',
          path: '/cong-van/cong-van-evn/danh-sach',
          icon: 'icon-CV_EVN',
          type: 'CONG_VAN_EVN',
          links: [
            '/cong-van/cong-van-evn/danh-sach',
            '/cong-van/cong-van-evn/chi-tiet',
          ],
          permission: [Permission.CONGVANDEN_QUANLY],
        },
        {
          nameMenu: 'Đang xử lý',
          path: '/cong-van/cong-van-den/danh-sach/dang-xu-ly',
          icon: 'icon-Cong_van_dang_xu_ly',
          type: 'DANG_XU_LY',
          links: [],
          permission: [],
        },
        {
          nameMenu: 'Chuyển nhầm',
          path: '/cong-van/chuyen-nham/danh-sach',
          icon: 'icon-attention-warning',
          type: 'CHUYEN_NHAM',
          links: [],
          permission: [
            Permission.CONGVANDEN_CHIDAO,
            Permission.CONGVANDEN_DEXUATCHIDAO,
          ],
          permissionCondition: 'OR',
        },
      ],
    },
    {
      nameMenu: 'Nhập nhanh',
      permission: [
        Permission.CONGVANDEN_QUANLY,
        Permission.CONGVANDI_QUANLY,
        Permission.CONGVANNOIBO_QUANLY,
      ],
      permissionMenu: [Permission.MENU_SIDEBAR.SMENU_CVAN_NHAPCVAN],
      subMenu: [
        {
          nameMenu: 'Nhập công văn đến',
          path: '/cong-van/cong-van-den/them-moi',
          icon: 'icon-Nhap_cong_van_den',
          links: [],
          permission: [Permission.CONGVANDEN_QUANLY],
        },
        {
          nameMenu: 'Nhập công văn đi',
          path: '/cong-van/cong-van-di/them-moi',
          icon: 'icon-Nhap_cong_van_di',
          links: [],
          permission: [Permission.CONGVANDI_QUANLY],
        },
        {
          nameMenu: 'Nhập công văn nội bộ',
          path: '/cong-van/cong-van-noi-bo/them-moi',
          icon: 'icon-Nhap_cong_van_noi_bo',
          links: [],
          permission: [Permission.CONGVANNOIBO_QUANLY],
        },
      ],
    },
    {
      nameMenu: 'Danh sách công văn',
      permission: [
        Permission.CONGVANDEN,
        Permission.CONGVANDI,
        Permission.CONGVANNOIBO,
      ],
      subMenu: [
        {
          nameMenu: 'Công văn đến',
          path: '/cong-van/cong-van-den/danh-sach',
          icon: 'icon-Cong_van_den',
          links: ['/cong-van/cong-van-den/chi-tiet'],
          permission: [Permission.CONGVANDEN],
        },
        {
          nameMenu: 'Công văn đi',
          path: '/cong-van/cong-van-di/danh-sach',
          icon: 'icon-Cong_van_di',
          links: ['/cong-van/cong-van-di/chi-tiet'],
          permission: [Permission.CONGVANDI],
        },
        {
          nameMenu: 'Công văn nội bộ',
          path: '/cong-van/cong-van-noi-bo/danh-sach',
          icon: 'icon-Cong_van_noi_bo',
          links: ['/cong-van/cong-van-noi-bo/chi-tiet'],
          permission: [Permission.CONGVANNOIBO],
        },
      ],
    },
    {
      nameMenu: 'Danh sách công văn thu hồi',
      permission: [
        Permission.DONGY_THUHOI,
        Permission.DEXUAT_THUHOI,
        Permission.CONGVANDEN,
      ],
      permissionMenu: [Permission.MENU_SIDEBAR.SMENU_CVAN_DSTHUHOI],
      subMenu: [
        {
          nameMenu: 'Công văn đến bị thu hồi',
          path: '/cong-van/cong-van-den-thu-hoi/danh-sach',
          icon: 'icon-revoke',
          type: 'CONG_VAN_DEN_THU_HOI',
          links: ['/cong-van/cong-van-den-thu-hoi/danh-sach'],
          permission: [Permission.CONGVANDEN],
        },
        {
          nameMenu: 'Công văn đi thu hồi',
          path: '/cong-van/cong-van-di-thu-hoi/danh-sach',
          icon: 'icon-revoke',
          type: 'CONG_VAN_DI_THU_HOI',
          links: [
            '/cong-van/cong-van-di-thu-hoi/danh-sach',
            '/cong-van/cong-van-di-thu-hoi/chi-tiet',
          ],
          permission: [Permission.DONGY_THUHOI, Permission.DEXUAT_THUHOI],
        },
      ],
    },
    {
      nameMenu: 'Chức năng khác',
      permission: [Permission.CONGVANDEN_CHUYENCHIDAO],
      permissionMenu: [Permission.MENU_SIDEBAR.SMENU_CVAN_UYQUYEN],
      subMenu: [
        {
          nameMenu: 'Ủy quyền',
          path: '/cong-van/uy-quyen',
          icon: 'icon-Uy_Quyen',
          links: [],
          permission: [],
        },
      ],
    },
    {
      nameMenu: 'Tìm kiếm & In sổ',
      permission: allPermissions,
      subMenu: [
        {
          nameMenu: 'Tìm kiếm công văn D-Office',
          path: '/quan-ly-cong-viec-do/tim-kiem-cong-van',
          icon: 'icon-search-data',
          links: ['/quan-ly-cong-viec-do/tim-kiem-cong-van'],
          permission: [],
        },
        {
          nameMenu: 'Tìm kiếm công văn 7.0',
          path: '/tien-ich/tim-kiem-cong-van/danh-sach',
          icon: 'icon-search-data',
          links: ['/tien-ich/tim-kiem-cong-van/chi-tiet'],
          permission: [],
        },
        {
          nameMenu: 'Tìm kiếm công văn 6.1',
          path: '/tien-ich/tim-kiem-cong-van-v61/danh-sach',
          icon: 'icon-search-data',
          links: ['/tien-ich/tim-kiem-cong-van-v61/chi-tiet'],
          permission: [Permission.CONGVAN_XEMTATCA_6_1],
        },
        {
          nameMenu: 'In sổ công văn đi',
          path: '/tien-ich/in-so-cong-van-di',
          icon: 'icon-In_So_Cong_Van',
          links: [],
          permission: [Permission.CONGVANDI_QUANLY],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_CVAN_INSOCVAN],
        },
        {
          nameMenu: 'In sổ công văn đến',
          path: '/tien-ich/in-so-cong-van-den',
          icon: 'icon-In_So_Cong_Van',
          links: [],
          permission: [Permission.CONGVANDEN_QUANLY],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_CVAN_INSOCVAN],
        },
      ],
    },
  ],
  work: [
    {
      nameMenu: 'Công việc phòng ban',
      permission: [Permission.CONGVIEC_PHONGBAN],
      subMenu: [
        {
          nameMenu: 'Chưa giao',
          path: '/cong-viec/phong-ban/danh-sach/chua-giao',
          icon: 'icon-Chua_Giao',
          type: 'CHUA_GIAO',
          links: ['/cong-viec/phong-ban/chi-tiet/chua-giao'],
          permission: [],
        },
        {
          nameMenu: 'Đã giao',
          path: '/cong-viec/phong-ban/danh-sach/da-giao',
          icon: 'icon-Da_Giao',
          type: 'DA_GIAO',
          links: ['/cong-viec/phong-ban/chi-tiet/da-giao'],
          permission: [],
        },
        {
          nameMenu: 'Chuyển nhầm',
          path: '/cong-viec/chuyen-nham/danh-sach',
          icon: 'icon-attention-warning',
          type: 'CHUYEN_NHAM',
          links: [],
          permission: [],
        },
      ],
    },
    {
      nameMenu: 'Công việc cá nhân',
      permission: [Permission.CONGVIEC_CANHAN],
      subMenu: [
        {
          nameMenu: 'Chưa thực hiện',
          path: '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien',
          icon: 'icon-Chua_Thuc_Hien',
          type: 'CHUA_THUC_HIEN',
          links: ['/cong-viec/ca-nhan/chi-tiet/chua-thuc-hien'],
          permission: [],
        },
        {
          nameMenu: 'Đang thực hiện',
          path: '/cong-viec/ca-nhan/danh-sach/dang-thuc-hien',
          icon: 'icon-Dang_Thuc_Hien',
          type: 'DANG_THUC_HIEN',
          links: ['/cong-viec/ca-nhan/chi-tiet/dang-thuc-hien'],
          permission: [],
        },
        {
          nameMenu: 'Đã thực hiện',
          path: '/cong-viec/ca-nhan/danh-sach/da-thuc-hien',
          icon: 'icon-Da_Thuc_Hien',
          type: 'DA_THUC_HIEN',
          links: ['/cong-viec/ca-nhan/chi-tiet/da-thuc-hien'],
          permission: [],
        },
        {
          nameMenu: 'Đã giao',
          path: '/cong-viec/ca-nhan/danh-sach/da-giao',
          icon: 'icon-Da_Giao',
          type: 'DA_GIAO',
          links: ['/cong-viec/ca-nhan/chi-tiet/da-giao'],
          permission: [],
        },
        {
          nameMenu: 'Xem để biết',
          path: '/cong-viec/ca-nhan/danh-sach/xem-de-biet',
          icon: 'icon-Xemdebiet',
          type: 'XEM_DE_BIET',
          links: ['/cong-viec/ca-nhan/xem-de-biet'],
          permission: [],
        },
      ],
    },
    {
      nameMenu: 'Chức năng khác',
      permission: [Permission.CONGVIEC_GIAOVIEC],
      subMenu: [
        {
          nameMenu: 'Giao việc mới',
          path: '/cong-viec/them-moi-giao-viec',
          icon: 'icon-Giao_Viec_Moi',
          type: 'GIAO_VIEC_MOI',
          links: [],
          permission: [Permission.CONGVIEC_GIAOVIEC],
        },
        {
          nameMenu: 'Ủy quyền',
          path: '/cong-viec/uy-quyen',
          icon: 'icon-Uy_Quyen',
          links: [],
          permission: [],
        },
      ],
    },
    {
      nameMenu: 'Tìm kiếm',
      permission: allPermissions,
      subMenu: [
        {
          nameMenu: 'Tìm kiếm công văn D-Office',
          path: '/quan-ly-cong-viec-do/tim-kiem-cong-van',
          icon: 'icon-search-data',
          links: ['/quan-ly-cong-viec-do/tim-kiem-cong-van'],
          permission: [],
        },
        {
          nameMenu: 'Tìm kiếm công văn 7.0',
          path: '/tien-ich/tim-kiem-cong-van/danh-sach',
          icon: 'icon-search-data',
          links: ['/tien-ich/tim-kiem-cong-van/chi-tiet'],
          permission: [],
        },
      ],
    },
  ],
  signNumber: [
    {
      nameMenu: 'Công văn cần ký số',
      permission: [Permission.KYSO_QUANLY],
      subMenu: [
        {
          nameMenu: 'Công văn đi',
          path: '/ky-so/cong-van-di/danh-sach',
          icon: 'icon-Kyso_Congvandi',
          type: 'CONG_VAN_DI_KY_SO',
          links: ['/ky-so/cong-van-di/chi-tiet'],
          permission: [],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_KYSO_DSCVANDI],
        },
        {
          nameMenu: 'Công văn nội bộ',
          path: '/ky-so/cong-van-noi-bo/danh-sach',
          icon: 'icon-CVNBKyso',
          type: 'CONG_VAN_NOI_BO_KY_SO',
          links: ['/ky-so/cong-van-noi-bo/chi-tiet'],
          permission: [],
        },
      ],
    },
    {
      nameMenu: 'Công văn ký số',
      permission: [Permission.KYSO_QUANLY],
      subMenu: [
        {
          nameMenu: 'Tạo công văn đi ký số',
          path: '/ky-so/cong-van-di/them-moi',
          icon: 'icon-TaoCVDiKyso',
          type: '',
          links: [],
          permission: [],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_KYSO_TAOCVANDI],
        },
        {
          nameMenu: 'Tạo công văn nội bộ ký số',
          path: '/ky-so/cong-van-noi-bo/them-moi',
          icon: 'icon-TaoCVNBKyso',
          type: '',
          links: [],
          permission: [],
        },
      ],
    },
    {
      nameMenu: 'Ký số tập trung',
      className: 'kstt-sidebar-block',
      isNewFeature: true,
      permission: [Permission.KY_SO_TAP_TRUNG],
      subMenu: [
        {
          nameMenu: 'Danh sách trình duyệt',
          path: '/ky-so-tap-trung/van-ban-ky-duyet/danh-sach',
          icon: 'icon-danh-sach-tap-trung',
          type: 'KY_SO_TAP_TRUNG',
          links: [
            '/ky-so-tap-trung/van-ban-ky-duyet/chi-tiet',
            '/ky-so-tap-trung/van-ban-ky-duyet/chinh-sua',
          ],
          permission: [Permission.KY_SO_TAP_TRUNG],
        },
        {
          nameMenu: 'Trình ký số theo quy trình',
          path: '/ky-so-tap-trung/van-ban-ky-duyet/them-moi',
          icon: 'icon-trinh-van-ban-ky-duyet',
          type: '',
          links: ['/ky-so-tap-trung/van-ban-ky-duyet/them-moi'],
          permission: [Permission.KY_SO_TAP_TRUNG],
        },
        {
          nameMenu: 'Tìm kiếm văn bản',
          path: '/ky-so-tap-trung/tim-kiem-van-ban',
          icon: 'icon-search',
          links: ['/ky-so-tap-trung/tim-kiem-van-ban/chi-tiet'],
          permission: [Permission.KY_SO_TAP_TRUNG],
        },
      ],
    },
    {
      nameMenu: 'Lấy ý kiến HĐTV',
      permission: [Permission.HDTV_LAYYKIEN],
      permissionMenu: [Permission.MENU_SIDEBAR.SMENU_HDTV_LAYYKIEN],
      subMenu: [
        {
          nameMenu: 'Danh sách ý kiến',
          path: '/hoi-dong-thanh-vien/lay-y-kien/danh-sach',
          icon: 'icon-bubble-cuoc-tro-chuyen',
          type: 'HDTV_Y_KIEN',
          links: [
            '/hoi-dong-thanh-vien/lay-y-kien/danh-sach',
            '/hoi-dong-thanh-vien/lay-y-kien/chi-tiet',
            '/hoi-dong-thanh-vien/lay-y-kien/chinh-sua',
          ],
          permission: [Permission.HDTV_LAYYKIEN],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_HDTV_LAYYKIEN],
        },
      ],
    },
    {
      nameMenu: 'Ký số mở rộng',
      permission: allPermissions,
      subMenu: [
        {
          nameMenu: 'Dự án',
          path: '/ky-so-mo-rong/du-an/danh-sach',
          icon: 'icon-Tao_Du_An',
          type: '',
          links: [
            '/ky-so-mo-rong/du-an/thong-tin',
            '/ky-so-mo-rong/du-an/them-moi',
            '/ky-so-mo-rong/du-an/chinh-sua',
          ],
          permission: [Permission.DUAN_QUANLY],
        },
        {
          nameMenu: 'Ký số mở rộng',
          path: '/ky-so/du-an/danh-sach',
          icon: 'icon-Du_An',
          type: 'KY_SO_DU_AN',
          links: ['/ky-so/du-an/chi-tiet'],
          permission: [],
        },
        {
          nameMenu: 'Tạo ký số mở rộng',
          path: '/ky-so/du-an/them-moi',
          icon: 'icon-TaoCVNBKyso',
          type: '',
          links: [],
          permission: [],
        },
      ],
    },
    {
      nameMenu: 'Tìm kiếm',
      permission: allPermissions,
      subMenu: [
        {
          nameMenu: 'Tìm kiếm công văn D-Office',
          path: '/quan-ly-cong-viec-do/tim-kiem-cong-van',
          icon: 'icon-search-data',
          links: ['/quan-ly-cong-viec-do/tim-kiem-cong-van'],
          permission: [],
        },
        {
          nameMenu: 'Tìm kiếm công văn 7.0',
          path: '/tien-ich/tim-kiem-cong-van/danh-sach',
          icon: 'icon-search-data',
          links: ['/tien-ich/tim-kiem-cong-van/chi-tiet'],
          permission: [],
        },
      ],
    },
  ],
  contact: [],
  documentsCabinet: [
    {
      nameMenu: 'Tìm kiếm',
      permission: allPermissions,
      subMenu: [
        {
          nameMenu: 'Tìm kiếm công văn D-Office',
          path: '/quan-ly-cong-viec-do/tim-kiem-cong-van',
          icon: 'icon-search-data',
          links: ['/quan-ly-cong-viec-do/tim-kiem-cong-van'],
          permission: [],
        },
        {
          nameMenu: 'Tìm kiếm công văn 7.0',
          path: '/tien-ich/tim-kiem-cong-van/danh-sach',
          icon: 'icon-search-data',
          links: ['/tien-ich/tim-kiem-cong-van/chi-tiet'],
          permission: [],
        },
      ],
    },
  ],
  system: [
    {
      nameMenu: 'Người dùng phân quyền',
      permission: [Permission.ADMIN, Permission.SUPERADMIN],
      subMenu: [
        {
          nameMenu: 'Phân quyền người dùng',
          path: '/he-thong/nguoi-dung/phan-quyen',
          icon: 'icon-decentralization-users',
          links: [
            '/he-thong/nguoi-dung/phong-ban',
            '/he-thong/nguoi-dung/them-moi',
            '/he-thong/nguoi-dung/chinh-sua',
          ],
          permission: [Permission.ADMIN],
        },
        {
          nameMenu: 'Phân quyền vai trò',
          path: '/he-thong/phan-quyen-vai-tro',
          icon: 'icon-decentralization',
          links: [],
          permission: [Permission.SUPERADMIN],
        },
        {
          nameMenu: 'Danh mục vai trò',
          path: '/he-thong/vai-tro/danh-muc',
          icon: 'icon-Danh_Muc_Vai_Tro',
          links: ['/he-thong/vai-tro/them-moi', '/he-thong/vai-tro/chinh-sua'],
          permission: [Permission.SUPERADMIN],
        },
      ],
    },
    {
      nameMenu: 'Quản lý phiên bản',
      permission: [Permission.ADMIN, Permission.SUPERADMIN],
      subMenu: [
        {
          nameMenu: 'Phân quyền chức năng',
          path: '/he-thong/phien-ban/phan-quyen-chuc-nang',
          icon: 'icon-danh-muc-quy-trinh',
          links: ['/he-thong/phien-ban/phan-quyen-chuc-nang'],
          permission: [Permission.ADMIN, Permission.SUPERADMIN],
        },
      ],
    },
    {
      nameMenu: 'Ký số tập trung',
      permission: [Permission.KY_SO_TAP_TRUNG],
      subMenu: [
        {
          nameMenu: 'Danh mục quy trình ký số',
          path: '/he-thong/danh-muc-quy-trinh/',
          icon: 'icon-danh-muc-quy-trinh',
          links: [
            '/he-thong/danh-muc-quy-trinh/them-moi',
            '/he-thong/danh-muc-quy-trinh/chinh-sua',
          ],
          permission: [Permission.SUPERADMIN, Permission.KY_SO_TAP_TRUNG],
        },
        {
          nameMenu: 'Quản lý quy trình',
          path: '/he-thong/quy-trinh/',
          icon: 'icon-quan-ly-quy-trinh',
          links: ['/he-thong/quy-trinh/'],
          permission: [Permission.SUPERADMIN, Permission.KY_SO_TAP_TRUNG],
        },
        {
          nameMenu: 'Danh sách quy trình ký số',
          path: '/he-thong/don-vi/quy-trinh/',
          icon: 'icon-danh-muc-quy-trinh',
          links: ['/he-thong/don-vi/'],
          permission: [Permission.ADMIN, Permission.KY_SO_TAP_TRUNG],
        },
        {
          nameMenu: 'Thống kê ký số tập trung',
          path: '/he-thong/thong-ke-ky-so-tap-trung/danh-sach',
          icon: 'icon-Tong_Hop',
          links: ['/he-thong/thong-ke-ky-so-tap-trung/danh-sach'],
          permission: [Permission.SUPERADMIN, Permission.KY_SO_TAP_TRUNG],
        },
      ],
    },
    {
      nameMenu: 'Danh mục',
      permission: [Permission.ADMIN, Permission.SUPERADMIN, Permission.HETHONG],
      subMenu: [
        {
          nameMenu: 'Đơn vị',
          path: '/he-thong/don-vi/danh-muc',
          icon: 'icon-departments',
          links: [
            '/he-thong/don-vi/danh-muc',
            '/he-thong/don-vi/them-moi',
            '/he-thong/don-vi/chinh-sua',
          ],
          permission: [Permission.SUPERADMIN],
        },
        {
          nameMenu: 'Phòng ban',
          path: '/he-thong/phong-ban/danh-muc',
          icon: 'icon-departments',
          links: [
            '/he-thong/phong-ban/danh-muc',
            '/he-thong/phong-ban/them-moi',
            '/he-thong/phong-ban/chinh-sua',
          ],
          permission: [Permission.ADMIN],
        },
        {
          nameMenu: 'Chức vụ',
          path: '/he-thong/chuc-vu/danh-muc',
          icon: 'icon-position-user',
          links: ['/he-thong/chuc-vu/them-moi', '/he-thong/chuc-vu/chinh-sua'],
          permission: [Permission.ADMIN],
        },
        {
          nameMenu: 'Sổ lưu',
          path: '/he-thong/so-luu/danh-muc',
          icon: 'icon-save-note',
          links: ['/he-thong/so-luu/them-moi', '/he-thong/so-luu/chinh-sua'],
          permission: [Permission.SOLUUTRU_QUANLY],
        },
        {
          nameMenu: 'Loại đối tượng',
          path: '/he-thong/doi-tuong/danh-muc',
          icon: 'icon-Van_Ban_Tra_Loi',
          links: [
            '/he-thong/doi-tuong/them-moi',
            '/he-thong/doi-tuong/chinh-sua',
          ],
          permission: [Permission.DOITUONGCONGVAN_QUANLY],
        },
        {
          nameMenu: 'Độ khẩn',
          path: '/he-thong/do-khan/danh-muc',
          icon: 'icon-flag',
          links: ['/he-thong/do-khan/them-moi', '/he-thong/do-khan/chinh-sua'],
          permission: [Permission.SUPERADMIN],
        },
        {
          nameMenu: 'Độ mật',
          path: '/he-thong/do-bao-mat/danh-muc',
          icon: 'icon-flag',
          links: [
            '/he-thong/do-bao-mat/chinh-sua',
            '/he-thong/do-bao-mat/them-moi',
          ],
          permission: [Permission.SUPERADMIN],
        },
        {
          nameMenu: 'Thời hạn bảo quản',
          path: '/he-thong/thoi-gian-luu/danh-sach',
          icon: 'icon-thoigianluu',
          links: [
            '/he-thong/thoi-gian-luu/danh-sach',
            '/he-thong/thoi-gian-luu/them-moi',
            '/he-thong/thoi-gian-luu/chinh-sua',
          ],
          permission: [Permission.SUPERADMIN],
        },
      ],
    },
    {
      nameMenu: 'Quản lý banner / bài viết',
      permission: [Permission.SUPERADMIN],
      type: 'QLCV_BANNER',
      subMenu: [
        {
          nameMenu: 'Quản lý banner / bài viết',
          path: '/he-thong/quan-ly-cong-viec/banner/danh-sach',
          icon: 'icon-files-image',
          links: [
            '/he-thong/quan-ly-cong-viec/banner/danh-sach',
            '/he-thong/quan-ly-cong-viec/banner/them-moi',
            '/he-thong/quan-ly-cong-viec/banner/chinh-sua',
          ],
          permission: [Permission.SUPERADMIN],
        },
      ],
    },
    {
      nameMenu: 'Quản lý công việc',
      permission: [Permission.SUPERADMIN],
      subMenu: [
        {
          nameMenu: 'Độ ưu tiên',
          path: '/he-thong/quan-ly-cong-viec/do-uu-tien/danh-sach',
          icon: 'icon-flag',
          links: ['/he-thong/quan-ly-cong-viec/do-uu-tien/danh-sach'],
          permission: [Permission.SUPERADMIN],
        },
      ],
    },
    {
      nameMenu: 'Quản lý Service thông báo',
      permission: [Permission.SUPERADMIN],
      subMenu: [
        {
          nameMenu: 'Tạo Service',
          path: '/he-thong/service/them-moi',
          icon: 'icon-s-pen',
          links: ['/he-thong/service/them-moi', '/he-thong/service/chinh-sua/'],
          permission: [Permission.SUPERADMIN],
        },
        {
          nameMenu: 'Danh sách Service',
          path: '/he-thong/service/danh-sach',
          icon: 'icon-s-list',
          links: ['/he-thong/service/danh-sach', '/he-thong/service/thong-ke/'],
          permission: [Permission.SUPERADMIN],
        },
        {
          nameMenu: 'Thống kê service',
          path: '/he-thong/service/thong-ke',
          icon: 'icon-Tong_Hop',
          links: ['/he-thong/service/danh-sach', '/he-thong/service/thong-ke/'],
          permission: [Permission.SUPERADMIN],
        },
      ],
    },
    {
      nameMenu: 'Chia sẻ API',
      permission: [Permission.SUPERADMIN],
      subMenu: [
        {
          nameMenu: 'Ứng dụng chia sẻ',
          path: '/he-thong/chia-se-api/ung-dung-chia-se',
          icon: 'icon-menu-o-vuong',
          links: [
            '/he-thong/chia-se-api/ung-dung-chia-se',
            'he-thong/chia-se-api/ung-dung-chia-se/them-moi',
          ],
          permission: [Permission.SUPERADMIN],
        },
        {
          nameMenu: 'Phân quyền chia sẻ API',
          path: '/he-thong/chia-se-api/phan-quyen-chia-se-api',
          icon: 'icon2-share',
          links: [
            '/he-thong/chia-se-api/phan-quyen-chia-se',
            '/he-thong/chia-se-api/phan-quyen-chia-se/them-moi',
          ],
          permission: [Permission.SUPERADMIN],
        },
      ],
    },
  ],
  utilities: [
    {
      nameMenu: 'Lấy ý kiến đơn vị',
      permission: [
        Permission.LAYYKIENDONVI_PHIEUGUI,
        Permission.LAYYKIENDONVI_PHIEUNHAN,
      ],
      subMenu: [
        {
          nameMenu: 'Gửi góp ý chưa xử lý',
          path: '/tien-ich/lay-y-kien-don-vi/danh-sach-goi',
          icon: 'icon-Cong_van_di',
          links: ['/tien-ich/lay-y-kien-don-vi/danh-sach-goi'],
          type: 'YEU_CAU_GOI_GOP_Y_CHUA_XU_LY',
          permission: [Permission.LAYYKIENDONVI_PHIEUGUI],
        },
        {
          nameMenu: 'Nhận góp ý chưa xử lý',
          path: '/tien-ich/lay-y-kien-don-vi/danh-sach-nhan',
          icon: 'icon-Cong_van_den',
          links: ['/tien-ich/lay-y-kien-don-vi/danh-sach-nhan'],
          type: 'YEU_CAU_NHAN_GOP_Y_CHUA_XU_LY',
          permission: [Permission.LAYYKIENDONVI_PHIEUNHAN],
        },
        {
          nameMenu: 'Tạo phiếu',
          path: '/tien-ich/lay-y-kien-don-vi/them-moi',
          icon: 'icon-rounded-add-button1',
          links: ['/tien-ich/lay-y-kien-don-vi/them-moi'],
          permission: [Permission.LAYYKIENDONVI_PHIEUGUI],
        },
      ],
    },
    {
      nameMenu: 'Tiện ích khác',
      permission: allPermissions,
      subMenu: [
        {
          nameMenu: 'Danh Bạ',
          path: '/danh-ba',
          icon: 'icon-Danh_Ba',
          links: ['/danh-ba'],
          permission: [Permission.DANHBA],
        },
        {
          nameMenu: 'Đổi mật khẩu AD',
          path: 'https://cpass.cpc.vn',
          newTab: true,
          icon: 'icon-AD_password',
          links: [],
          permission: [],
        },
        {
          nameMenu: 'Tủ Tài Liệu',
          icon: 'icon-Tu_Tai_Lieu',
          path: '/tu-tai-lieu/danh-sach',
          permission: [Permission.TUTAILIEU],
          type: 'TU_TAI_LIEU',
          links: [
            '/tu-tai-lieu/danh-sach',
            '/tu-tai-lieu/them-file',
            '/tu-tai-lieu/di-chuyen',
            '/tu-tai-lieu/di-chuyen-ngan-tu',
            '/tu-tai-lieu/chi-tiet',
            '/ho-so-cong-viec/chi-tiet',
          ],
        },
      ],
    },
    {
      nameMenu: 'Thống kê',
      permission: [
        Permission.CONGVAN,
        Permission.CONGVIEC,
        Permission.ADMIN,
        Permission.CONGVANDEN_QUANLY,
        Permission.HSCV_VANPHONG_DUYET,
      ],
      permissionMenu: [
        Permission.MENU_SIDEBAR.SMENU_TKE_CVAN,
        Permission.MENU_SIDEBAR.SMENU_TKE_THXLCVAN,
        Permission.MENU_SIDEBAR.SMENU_TKE_CVIEC,
        Permission.MENU_SIDEBAR.SMENU_TKE_HSCV,
        Permission.MENU_SIDEBAR.SMENU_TKE_HSCV,
        Permission.MENU_SIDEBAR.SMENU_TKE_NDVB,
        Permission.MENU_SIDEBAR.SMENU_TKE_THUHOICVAN,
        Permission.MENU_SIDEBAR.SMENU_TKE_BAONHAM,
      ],
      subMenu: [
        {
          nameMenu: 'Thống kê công văn',
          path: '/tien-ich/thong-ke-cong-van/danh-sach',
          icon: 'icon-Tong_Hop',
          links: ['/tien-ich/thong-ke-cong-van/danh-sach'],
          permission: [Permission.ADMIN],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_TKE_CVAN],
        },
        {
          nameMenu: 'Tình hình xử lý công văn',
          path: '/tien-ich/tinh-hinh-xu-ly-cong-van/danh-sach',
          icon: 'icon-Tong_Hop',
          links: [
            '/tien-ich/tinh-hinh-xu-ly-cong-van/chi-tiet/phong-ban',
            '/tien-ich/tinh-hinh-xu-ly-cong-van/danh-sach',
          ],
          permission: [],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_TKE_THXLCVAN],
        },
        {
          nameMenu: 'Thống kê công việc',
          path: '/tien-ich/thong-ke-cong-viec/danh-sach',
          icon: 'icon-Tong_Hop',
          links: [
            '/tien-ich/thong-ke-cong-viec/chi-tiet/phong-ban',
            '/tien-ich/thong-ke-cong-viec/danh-sach/phong-ban',
            '/tien-ich/thong-ke-cong-viec/danh-sach/don-vi',
          ],
          permission: [],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_TKE_CVIEC],
        },
        {
          nameMenu: 'Thống kê lưu HSCV',
          path: '/tien-ich/thong-ke-tu-tai-lieu/danh-sach',
          icon: 'icon-Tong_Hop',
          links: [
            '/tien-ich/thong-ke-tu-tai-lieu/danh-sach/don-vi',
            '/tien-ich/thong-ke-tu-tai-lieu/danh-sach/phong-ban',
            '/tien-ich/thong-ke-tu-tai-lieu/chi-tiet/phong-ban',
            '/tien-ich/thong-ke-tu-tai-lieu/chi-tiet/don-vi',
          ],
          permission: [],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_TKE_HSCV],
        },
        {
          nameMenu: 'Thống kê nộp lưu trữ HSCV',
          path: '/tien-ich/thong-ke-ho-so-cong-viec/danh-sach',
          icon: 'icon-Tong_Hop',
          links: [
            '/tien-ich/thong-ke-ho-so-cong-viec/chi-tiet',
            '/tien-ich/thong-ke-ho-so-cong-viec/cong-van-chua-luu',
          ],
          permission: [],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_TKE_HSCV],
        },
        {
          nameMenu: 'Thống kê ký số',
          path: '/tien-ich/thong-ke-ky-so/danh-sach',
          icon: 'icon-Tong_Hop',
          links: [
            '/tien-ich/thong-ke-ky-so/chi-tiet/phong-ban',
            '/tien-ich/thong-ke-ky-so/chi-tiet/don-vi',
          ],
          permission: [Permission.ADMIN],
        },
        {
          nameMenu: 'Thống kê văn bản nhận dạng',
          path: '/tien-ich/thong-ke-van-ban-nhan-dang/danh-sach',
          icon: 'icon-Tong_Hop',
          links: [],
          permission: [Permission.ADMIN],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_TKE_NDVB],
        },
        {
          nameMenu: 'Thống kê thu hồi công văn',
          path: '/tien-ich/thong-ke-thu-hoi-cong-van/danh-sach',
          icon: 'icon-Tong_Hop',
          links: ['/tien-ich/thong-ke-thu-hoi-cong-van/danh-sach'],
          type: 'THONG_KE_THU_HOI_CONG_VAN',
          permission: [
            Permission.ADMIN,
            Permission.CONGVANDEN_QUANLY,
            Permission.HSCV_VANPHONG_DUYET,
          ],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_TKE_THUHOICVAN],
        },
        {
          nameMenu: 'Thống kê báo nhầm',
          path: '/tien-ich/thong-ke-bao-nham/danh-sach',
          icon: 'icon-Tong_Hop',
          links: [
            '/tien-ich/thong-ke-bao-nham/chi-tiet/don-vi',
            '/tien-ich/thong-ke-bao-nham/chi-tiet/phong-ban',
          ],
          permission: [Permission.ADMIN],
          permissionMenu: [Permission.MENU_SIDEBAR.SMENU_TKE_BAONHAM],
        },
        {
          nameMenu: 'Thống kê nhân viên',
          path: '/tien-ich/thong-ke-nhan-vien/danh-sach',
          icon: 'icon-Tong_Hop',
          links: ['/tien-ich/thong-ke-nhan-vien/chi-tiet/don-vi'],
          permission: [Permission.ADMIN],
        },
        // {
        //   nameMenu: 'Thống kê tin nhắn',
        //   path: '/tien-ich/thong-ke-tin-nhan/danh-sach',
        //   icon: 'icon-Tong_Hop',
        //   links: [
        //     '/tien-ich/thong-ke-tin-nhan/da-gui/chi-tiet/don-vi',
        //     '/tien-ich/thong-ke-tin-nhan/da-gui/chi-tiet/phong-ban',
        //     '/tien-ich/thong-ke-tin-nhan/chua-gui/chi-tiet/don-vi',
        //   ],
        //   permission: [Permission.ADMIN],
        // },
        {
          nameMenu: 'Thống kê thông báo KLCH',
          path: '/tien-ich/thong-bao-ket-luan-cuoc-hop/thong-ke',
          icon: 'icon-Tong_Hop',
          links: ['/tien-ich/thong-bao-ket-luan-cuoc-hop/thong-ke'],
          permission: [],
        },
      ],
    },
  ],

  taskManager: workManager(allPermissions),
}

export default menuSidebar
