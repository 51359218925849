import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { Button, Classes, Intent, Position } from '@blueprintjs/core'
import { get, size } from 'lodash'
import csx from 'classnames'
import moment from 'moment'

import { Card, TaskCheckbox, Col, Row } from 'components/newCommon'
import { Input, FormDatePicker } from 'components/newCommon2'
import DeleteTodo from './deletetodo'

const NEW_ID = 'NEW'

const dateRequest = 'YYYY-MM-DD'

const TodoForm = memo(({ onSubmit, defaultFormValue, loading, onCancel }) => {
  const [formData, setFormData] = useState(defaultFormValue || {})

  const _onSubmit = () => {
    const requestData = {
      ...formData,
      deadline: formData?.deadline
        ? formData.deadline.startOf('day').format(dateRequest)
        : undefined,
    }
    onSubmit(requestData)
  }

  const onChange = name => value => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  return (
    <div className="ph10">
      <Input
        type="textarea"
        maxLength={255}
        wrapperClassName="mb10"
        placeholder="Nhập tiêu đề"
        value={formData?.content || ''}
        onChange={onChange('content')}
      />

      <Row gutterVertical>
        <Col grid="half">
          <FormDatePicker
            inputClearable
            inputIconClassName="icon2-date-frames"
            onChange={onChange('deadline')}
            placeholder="Hạn thực hiện"
            popoverStretch={false}
            popoverPosition={Position.TOP_LEFT}
            selected={formData?.deadline || null}
          />
        </Col>
        <Col grid="half" className="element-flex-end">
          <span>
            <Button
              className={csx(
                'cpc-button',
                'default',
                'no-border',
                'text-uppercase',
                'font-size-13',
                'ph10',
                'mr10'
              )}
              disabled={loading}
              onClick={onCancel}
            >
              Hủy
            </Button>
            <Button
              className={csx(
                'cpc-button',
                'text-uppercase',
                'font-size-13',
                'ph10',
                'mr10'
              )}
              disabled={!formData?.content?.trim()}
              intent={Intent.PRIMARY}
              loading={loading}
              onClick={_onSubmit}
            >
              Lưu
            </Button>
          </span>
        </Col>
      </Row>
    </div>
  )
})

const ToDoList = ({ className, allowAction, onChange = () => {} }) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const [todoList, setTodoList] = useState([])
  const [editId, setEditId] = useState(null)
  const [deleteId, setDeleteId] = useState()

  const toggleEdit = useCallback(
    (id = null) =>
      () => {
        setEditId(id)
      },
    []
  )

  const editProps = useMemo(
    () => ({
      onCancel: toggleEdit(),
    }),
    [toggleEdit]
  )

  const createToDo = useCallback(
    formValues => {
      const count = size(todoList)
      let newTodoList = [
        ...todoList,
        {
          ...formValues,
          status: 0,
          id: count + 1,
        },
      ]
      setTodoList(newTodoList)
      onChange(newTodoList)
      setEditId(null)
    },
    [onChange, todoList]
  )

  const updateToDo = useCallback(
    currentTodo => value => {
      let newTodoList = todoList?.map(elm => {
        if (elm?.id === currentTodo?.id) {
          return {
            ...currentTodo,
            ...value,
          }
        }

        return elm
      })
      setTodoList(newTodoList)
      onChange(newTodoList)
      setEditId(null)
    },
    [todoList, onChange]
  )

  const deleteToDo = useCallback(() => {
    const newTodoList = todoList?.filter(elm => elm?.id !== deleteId)
    setTodoList(newTodoList)
    onChange(newTodoList)
    _mountedSet(setDeleteId)
  }, [todoList, deleteId, onChange])

  const onChecked = useCallback(
    (id, value) => {
      let newTodoList = todoList?.map(elm => {
        if (elm?.id === id) {
          return {
            ...elm,
            status: value ? 1 : 0,
          }
        }

        return elm
      })
      setTodoList(newTodoList)
      onChange(newTodoList)
    },
    [todoList, onChange]
  )

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  if (!allowAction && !size(todoList)) {
    return null
  }

  return (
    <div
      className={csx('cpc-detail-block p10', {
        [`${className}`]: className,
      })}
    >
      <label className={csx('font-size-13', 'font-weight-600', 'uppercase')}>
        Bước thực hiện
      </label>

      <div className="cpc-list">
        {todoList.map((todo, index) =>
          editId === todo.id ? (
            <TodoForm
              {...editProps}
              key={index}
              defaultFormValue={{
                content: todo.content,
                deadline: todo.deadline ? moment(todo.deadline) : null,
              }}
              onSubmit={updateToDo(todo, 'form')}
            />
          ) : (
            <Card
              className={csx('cpc-list-item', 'ph10', 'border-bottom')}
              key={index}
            >
              <TaskCheckbox
                className="mb0"
                checked={todo?.status ?? false}
                disabled={false}
                style={{ alignItem: 'flex-start' }}
                onChange={checked => onChecked(todo.id, checked)}
              >
                <span className="d-inline-block">
                  <p className="d-block font-size-13 font-weight-300 m0">
                    {todo?.content && (
                      <span
                        className="word-break-word"
                        dangerouslySetInnerHTML={{
                          __html: todo?.content?.replace(/\n/g, '<br>'),
                        }}
                      />
                    )}
                  </p>
                  {todo.deadline && (
                    <i
                      className="font-size-11 font-weight-300"
                      style={{ color: '#999' }}
                    >
                      {moment(todo.deadline).format('DD/MM/YYYY')}
                    </i>
                  )}
                </span>
                {allowAction && (
                  <span
                    className="action"
                    style={{ minWidth: '35px' }}
                    onClick={e => e.preventDefault()}
                  >
                    <i
                      className="icon-but size-icon-12 mr10 icon-color-primary"
                      onClick={toggleEdit(todo.id)}
                    />
                    <i
                      className="icon-bin size-icon-12 icon-color-danger"
                      onClick={() => _mountedSet(setDeleteId, todo.id)}
                    />
                  </span>
                )}
              </TaskCheckbox>
            </Card>
          )
        )}

        {editId === NEW_ID && <TodoForm {...editProps} onSubmit={createToDo} />}
      </div>

      {allowAction && (
        <Button
          className={csx(Classes.MINIMAL, 'cpc-button', 'no-hover', 'pv0')}
          intent={Intent.PRIMARY}
          iconName="plus"
          text="Thêm"
          onClick={toggleEdit(NEW_ID)}
        />
      )}

      <DeleteTodo
        isOpen={!!deleteId}
        onClose={() => _mountedSet(setDeleteId)}
        onConfirm={() => deleteToDo()}
      />
    </div>
  )
}

export default ToDoList
