import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Dialog } from 'components/newCommon'

const ConfirmSuaHuy = ({ onClose, onSuccess }) => {
  return (
    <Dialog
      buttonUppercase
      isOpen={true}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      confirmText="Sửa/Hủy"
      loading={false}
      onCancel={onClose}
      onConfirm={onSuccess}
      onClose={onClose}
      title={'Thông báo'}
      textCenter
    >
      {
        'Công việc đang chọn đã hoàn thành. Bạn có chắc chắn muốn sửa/hủy hay không?'
      }
    </Dialog>
  )
}

ConfirmSuaHuy.defaultProps = {
  onClose: () => {},
  onSuccess: () => {},
}

ConfirmSuaHuy.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default memo(ConfirmSuaHuy)
