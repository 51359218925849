// Document
const CONGVAN = 'CONGVAN'
const CONGVAN_XEMTATCA = 'CONGVAN_XEMTATCA'
const CONGVAN_XEMPHONGBANKHAC = 'CONGVAN_XEMPHONGBANKHAC'
const CONGVANDEN = 'CONGVANDEN'
const CONGVANDEN_QUANLY = 'CONGVANDEN_QUANLY'
const CONGVANDEN_CHUYENCHIDAO = 'CONGVANDEN_CHUYENCHIDAO'
const CONGVANDEN_CHIDAO = 'CONGVANDEN_CHIDAO'
const CONGVANDEN_CHIDAO_DVTRUCTHUOC = 'CONGVANDEN_CHIDAO_DVTRUCTHUOC'
const CONGVANDEN_DEXUATCHIDAO = 'CONGVANDEN_DEXUATCHIDAO'
const CONGVANDEN_LICHSUCHIDAO = 'CONGVANDEN_LICHSUCHIDAO'
const CONGVANDEN_LICHSUCONGVAN = 'CONGVANDEN_LICHSUCONGVAN'
const CONGVANDI = 'CONGVANDI'
const CONGVANDI_QUANLY = 'CONGVANDI_QUANLY'
const CONGVANNOIBO = 'CONGVANNOIBO'
const CONGVANNOIBO_QUANLY = 'CONGVANNOIBO_QUANLY'

// Work
const CONGVIEC = 'CONGVIEC'
const CONGVIEC_PHONGBAN = 'CONGVIEC_PHONGBAN'
const CONGVIEC_PHONGBAN_HOANTAT = 'CONGVIEC_PHONGBAN_HOANTAT'
const CONGVIEC_CANHAN = 'CONGVIEC_CANHAN'
const CONGVIEC_GIAOVIEC = 'CONGVIEC_GIAOVIEC'

// Sign number
const KYSO_QUANLY = 'KYSO_QUANLY'
const KYSO_KYBANHANH = 'KYSO_KYBANHANH'
const KYSO_KYDUYET_GOPY = 'KYSO_KYDUYET_GOPY'
const KYSO_CAPSO = 'KYSO_CAPSO'

// Cabinet
const TUTAILIEU = 'TUTAILIEU'
const TUTAILIEUDONVI = 'TUTAILIEUDONVI'
const TUTAILIEUDONVI_QUANLY = 'TUTAILIEUDONVI_QUANLY'
const TUTAILIEUPHONGBAN = 'TUTAILIEUPHONGBAN'
const TUTAILIEUPHONGBAN_QUANLY = 'TUTAILIEUPHONGBAN_QUANLY'

const DANHBA = 'DANHBA'
const HETHONG = 'HETHONG'
const DOKHAN_QUANLY = 'DOKHAN_QUANLY'
const DOBAOMAT_QUANLY = 'DOBAOMAT_QUANLY'
const DOITUONGCONGVAN_QUANLY = 'DOITUONGCONGVAN_QUANLY'
const SOLUUTRU_QUANLY = 'SOLUUTRU_QUANLY'
const ADMIN = 'ADMIN'
const SUPERADMIN = 'SUPERADMIN'
const DUAN_QUANLY = 'DUAN_QUANLY'
const HDTV_LAYYKIEN = 'HDTV_LAYYKIEN'
const HDTV_LAYYKIEN_KYDUYET = 'HDTV_LAYYKIEN_KYDUYET'
const HDTV_LAYYKIEN_THANHVIEN = 'HDTV_LAYYKIEN_THANHVIEN'

//lay y kien don vi
const LAYYKIENDONVI_CHIDAO = 'LAYYKIENDONVI_CHIDAO'
const LAYYKIENDONVI_GIAOVIEC = 'LAYYKIENDONVI_GIAOVIEC'
const LAYYKIENDONVI_PHIEUGUI = 'LAYYKIENDONVI_PHIEUGUI'
const LAYYKIENDONVI_PHIEUNHAN = 'LAYYKIENDONVI_PHIEUNHAN'
const LAYYKIENDONVI_XACNHAN_DONVI = 'LAYYKIENDONVI_XACNHAN_DONVI'
const LAYYKIENDONVI_XACNHAN_PHONGBAN = 'LAYYKIENDONVI_XACNHAN_PHONGBAN'

// Ky so tap trung
const KY_SO_TAP_TRUNG = 'KY_SO_TAP_TRUNG'

// Công văn đi thu hồi
const DEXUAT_THUHOI = 'DEXUAT_THUHOI'
const DONGY_THUHOI = 'DONGY_THUHOI'
// Tìm kiếm công văn 6.1
const CONGVAN_XEMTATCA_6_1 = 'CONGVAN_XEMTATCA_6_1'
const HSCV_PHONGBAN_DUYET = 'HSCV_PHONGBAN_DUYET'
const HSCV_VANPHONG_DUYET = 'HSCV_VANPHONG_DUYET'
const HSCV_VANTHU = 'HSCV_VANTHU'
// Quản lý công việc
const SUDUNG_QLCV = 'SUDUNG_QLCV'
const THONGBAOKETLUANHDTV = 'THONGBAOKETLUANHDTV'

const permissionTree = {
  [CONGVAN]: {
    CONGVAN_XEMTATCA,
    CONGVAN_XEMPHONGBANKHAC,
    [CONGVANDEN]: {
      CONGVANDEN_QUANLY,
      CONGVANDEN_CHUYENCHIDAO,
      CONGVANDEN_CHIDAO,
      CONGVANDEN_CHIDAO_DVTRUCTHUOC,
      CONGVANDEN_DEXUATCHIDAO,
      CONGVANDEN_LICHSUCHIDAO,
      CONGVANDEN_LICHSUCONGVAN,
    },
    [CONGVANDI]: {
      CONGVANDI_QUANLY,
    },
    [CONGVANNOIBO]: {
      CONGVANNOIBO_QUANLY,
    },
  },
  [CONGVIEC]: {
    CONGVIEC_PHONGBAN,
    CONGVIEC_PHONGBAN_HOANTAT,
    CONGVIEC_CANHAN,
    CONGVIEC_GIAOVIEC,
  },
  [KYSO_QUANLY]: {
    KYSO_KYBANHANH,
    KYSO_KYDUYET_GOPY,
    KYSO_CAPSO,
  },
  [TUTAILIEU]: {
    [TUTAILIEUDONVI]: {
      TUTAILIEUDONVI_QUANLY,
    },
    [TUTAILIEUPHONGBAN]: {
      TUTAILIEUPHONGBAN_QUANLY,
    },
  },
  DANHBA,
  HETHONG,
  DOKHAN_QUANLY,
  DOBAOMAT_QUANLY,
  DOITUONGCONGVAN_QUANLY,
  SOLUUTRU_QUANLY,
  ADMIN,
  SUPERADMIN,
  DUAN_QUANLY,
  KY_SO_TAP_TRUNG,
}

const MENU_SIDEBAR = {
  HOME_DASHBOARD: 'HOME_DASHBOARD',
  // Menu công việc
  BMENU_CVAN_CVIEC: 'BMENU-CVAN_CVIEC',
  // Công văn cần xử lý
  SMENU_CVAN_CANXULY: 'SMENU-CVAN_CANXULY',
  // Công văn nhập nhanh
  SMENU_CVAN_NHAPCVAN: 'SMENU-CVAN_NHAPCVAN',
  // Công văn thu hồi
  SMENU_CVAN_DSTHUHOI: 'SMENU-CVAN_DSTHUHOI',
  // Tìm kiếm và in số
  SMENU_CVAN_INSOCVAN: 'SMENU-CVAN_INSOCVAN',
  // Chức năng khác
  SMENU_CVAN_UYQUYEN: 'SMENU-CVAN_UYQUYEN',

  // Menu công văn cần ký số
  SMENU_KYSO_DSCVANDI: 'SMENU-KYSO_DSCVANDI',
  // Tạo công văn ký số
  SMENU_KYSO_TAOCVANDI: 'SMENU-KYSO_TAOCVANDI',
  // Lấy ý kiến HĐTV
  SMENU_HDTV_LAYYKIEN: 'SMENU-HDTV_LAYYKIEN',

  // Thống kê thu hồi công văn
  SMENU_TKE_CVAN: 'SMENU-TKE_CVAN',
  // Thống kê / Tình hình xử lý công văn
  SMENU_TKE_THXLCVAN: 'SMENU-TKE_THXLCVAN',
  // Thống kê / Thống kê công việc
  SMENU_TKE_CVIEC: 'SMENU-TKE_CVIEC',
  // Thống kê / Thống kê HSCV
  SMENU_TKE_HSCV: 'SMENU-TKE_HSCV',
  // Thống kê / Thống kê văn bản nhận dạng
  SMENU_TKE_NDVB: 'SMENU-TKE_NDVB',
  // Thống kê / Thống kê thu hồi công văn
  SMENU_TKE_THUHOICVAN: 'SMENU-TKE_THUHOICVAN',
  // Thống kê / Thống kê báo nhầm
  SMENU_TKE_BAONHAM: 'SMENU-TKE_BAONHAM',

  // Công văn chỉ đạo
  SACTION_CVAN_CHIDAO: 'SACTION-CVAN_CHIDAO',
  // Công văn tạo sửa xóa
  SACTION_CVAN_TAOSUAXOA: 'SACTION-CVAN_TAOSUAXOA',
  // Công văn lưu tủ
  SACTION_CVAN_LUUTU: 'SACTION-CVAN_LUUTU',
  // Công văn xem thực hiện
  SACTION_CVAN_XEMTHUCHIEN: 'SACTION-CVAN_XEMTHUCHIEN',
  // Ký số / Giao việc phiên bản mới
  SACTION_KYSO_GVIECPBMOI: 'SACTION-KYSO_GVIECPBMOI',
  // Tủ tài liệu
  SMENU_TTL: 'SMENU-TTL',
  SACTION_CVAN_GVIECPBMOI: 'SACTION-CVAN_GVIECPBMOI',
  SMENU_TTL_HSCV: 'SMENU-TTL_HSCV',
  SMENU_HETHONG_PQUYENCNANG: 'SMENU-HETHONG_PQUYENCNANG',
  SACTION_CVAN_GVIECNOIBO: 'SACTION-CVAN_GVIECNOIBO',
  // Ẩn menu công việc tổng công ty
  BMENU_CVAN_CVIEC_QCTCT: 'BMENU-CVAN_CVIEC_QCTCT',
}

export default {
  CONGVAN,
  CONGVAN_XEMTATCA,
  CONGVAN_XEMPHONGBANKHAC,
  CONGVANDEN,
  CONGVANDEN_QUANLY,
  CONGVANDEN_CHUYENCHIDAO,
  CONGVANDEN_CHIDAO,
  CONGVANDEN_CHIDAO_DVTRUCTHUOC,
  CONGVANDEN_DEXUATCHIDAO,
  CONGVANDEN_LICHSUCHIDAO,
  CONGVANDEN_LICHSUCONGVAN,
  CONGVANDI,
  CONGVANDI_QUANLY,
  CONGVANNOIBO,
  CONGVANNOIBO_QUANLY,
  CONGVIEC,
  CONGVIEC_PHONGBAN,
  CONGVIEC_PHONGBAN_HOANTAT,
  CONGVIEC_CANHAN,
  CONGVIEC_GIAOVIEC,
  KYSO_QUANLY,
  KYSO_KYBANHANH,
  KYSO_KYDUYET_GOPY,
  KYSO_CAPSO,
  DANHBA,
  TUTAILIEU,
  TUTAILIEUDONVI,
  TUTAILIEUDONVI_QUANLY,
  TUTAILIEUPHONGBAN,
  TUTAILIEUPHONGBAN_QUANLY,
  HETHONG,
  DOKHAN_QUANLY,
  DOBAOMAT_QUANLY,
  DOITUONGCONGVAN_QUANLY,
  SOLUUTRU_QUANLY,
  ADMIN,
  SUPERADMIN,
  permissionTree,
  DUAN_QUANLY,
  KY_SO_TAP_TRUNG,
  HDTV_LAYYKIEN,
  HDTV_LAYYKIEN_KYDUYET,
  HDTV_LAYYKIEN_THANHVIEN,
  LAYYKIENDONVI_CHIDAO,
  LAYYKIENDONVI_GIAOVIEC,
  LAYYKIENDONVI_PHIEUGUI,
  LAYYKIENDONVI_PHIEUNHAN,
  LAYYKIENDONVI_XACNHAN_DONVI,
  LAYYKIENDONVI_XACNHAN_PHONGBAN,
  DEXUAT_THUHOI,
  DONGY_THUHOI,
  CONGVAN_XEMTATCA_6_1,
  HSCV_PHONGBAN_DUYET,
  HSCV_VANPHONG_DUYET,
  HSCV_VANTHU,
  SUDUNG_QLCV,
  MENU_SIDEBAR,
  THONGBAOKETLUANHDTV,
}
