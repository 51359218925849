import React, { useEffect, useState, useCallback } from 'react'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'

import {
  getLichSuPhanPhoi,
  getQuanHeDonViVanBan,
  getQuanHeDonViVanBanReset,
} from 'actions/doffice'
import requestAction from 'helpers/request'
import QuaTrinhXuLyItem from './quatrinhxulyitem'
import QuaTrinhXuLyDonVi from './quatrinhxulydonvi'
import { Empty } from 'components/newCommon'

const QuaTrinhXuLy = ({
  id,
  documentInfos,
  loaiVanBan,
  listDonViVanBanDO = [],
  tokenDO,
}) => {
  const [lichSuPhanPhoi, setLichSuPhanPhoi] = useState(null)
  const [newData, setNewData] = useState([])

  const fetchLichSuPhanPhoi = useCallback(() => {
    if (!isEmpty(tokenDO)) {
      const token = {
        tokenType: 'Bearer',
        accessToken: tokenDO?.token,
      }
      requestAction({
        getResult: false,
        codeCheck: false,
        showToast: false,
        action: () =>
          getLichSuPhanPhoi(
            {
              ID_NV: tokenDO?.nhanVien?.idNv,
              ID_DV: tokenDO?.nhanVien?.idDv,
              ID_VB: id,
            },
            token
          ),
        afterResponse: response => {
          setLichSuPhanPhoi({
            lANHDAO: response?.data?.lANHDAO || [],
            pHONGBAN: response?.data?.pHONGBAN || [],
          })
        },
      })
    }
  }, [id, tokenDO])

  const getFlowData = useCallback(() => {
    const lOAISOVB = loaiVanBan?.lOAISOVB
    const iDVB = documentInfos?.iDVB
    if (iDVB && lOAISOVB !== 'VBNB') {
      requestAction({
        getResult: false,
        codeCheck: false,
        showToast: false,
        getResponse: true,
        action: () => getQuanHeDonViVanBan({ id: iDVB }),
      })
    } else {
      requestAction({
        showToast: false,
        action: () => getQuanHeDonViVanBanReset(),
      })
      fetchLichSuPhanPhoi()
    }
    // eslint-disable-next-line
  }, [loaiVanBan?.lOAISOVB, fetchLichSuPhanPhoi])

  useEffect(() => {
    if (size(listDonViVanBanDO) !== 0) {
      // 251 đối với đơn vị điện lực miền trung
      const newData = listDonViVanBanDO?.reduce((result, item) => {
        if (
          result &&
          result.some(e => item?.idDvCha === e?.idDv && e.idDv !== 251)
        ) {
          return result
        }

        return [...result, item]
      }, [])
      setNewData(newData)
    } else {
      setNewData([])
    }
  }, [listDonViVanBanDO])

  useEffect(() => {
    const res = setTimeout(() => {
      getFlowData()
    }, 0)
    return () => clearTimeout(res)
  }, [getFlowData])

  return (
    <>
      {size(lichSuPhanPhoi?.lANHDAO) === 0 &&
        size(lichSuPhanPhoi?.pHONGBAN) === 0 &&
        size(newData) === 0 && <Empty />}
      {lichSuPhanPhoi?.lANHDAO?.map?.((item, index) => (
        <QuaTrinhXuLyItem
          key={index}
          hasChild={item.isChildren}
          isLeader={item?.isChidao ?? false}
          id={id}
          idDv={tokenDO?.nhanVien?.idDv}
          tokenDO={tokenDO}
          itemData={item}
        />
      ))}
      {lichSuPhanPhoi?.pHONGBAN?.map?.((item, index) => (
        <QuaTrinhXuLyItem
          key={index}
          hasChild={item.isChildren}
          isLeader={item?.isChidao ?? false}
          id={id}
          idDv={tokenDO?.nhanVien?.idDv}
          tokenDO={tokenDO}
          itemData={item}
        />
      ))}
      {newData &&
        newData?.map((item, index) => (
          <QuaTrinhXuLyDonVi
            key={index}
            id={id}
            idDv={item?.idDv}
            hasChild={true}
            tokenDO={tokenDO}
            data={item}
            childData={
              listDonViVanBanDO?.filter(
                e => e.idDvCha === item?.idDv && e.idDvCha !== 251
              ) || []
            }
          />
        ))}
    </>
  )
}

const mapStateToProps = state => ({
  tokenDO: state?.doffice?.toKenDO,
  documentInfos: state?.doffice?.chiTietVanBan,
  listDonViVanBanDO: state?.doffice?.listDonViVanBanDO,
})

export default connect(mapStateToProps, null)(QuaTrinhXuLy)
