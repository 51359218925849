import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { Button, Intent } from '@blueprintjs/core'

import { Card, Icon, SliderBar } from 'components/newCommon'
import { Input } from 'components/newCommon2'
import requestAction from 'helpers/request'
import { Action, Toast } from 'constants/MessageForm'
import { showToast, getCongViec, postAccept } from 'actions/task'

const Accept = ({ onClose = () => {}, match, reloadList = () => {} }) => {
  const { actionId, id } = match.params
  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!mounted?.current && setFunction(state)
  const [fetching, setFetching] = useState(false)
  const [formData, setFormData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const changeField = name => value => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleAccept = useCallback(() => {
    const data = {
      work_item_id: formData?.id,
      description: formData?.description,
      progress: formData?.progress,
    }
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      beforeAction: () => mountedSet(setIsLoading, true),
      action: () => postAccept(data),
      afterResponse: response => {
        if (response?.code === 200 && !response?.result) {
          return showToast({
            message: response?.message,
            intent: Intent.WARNING,
          })
        } else if (response?.code === 201) {
          return showToast({
            message: Toast.SUCCESS(Action.ACCEPT),
            intent: Intent.SUCCESS,
          })
        } else {
          return showToast({
            message: Toast.FAIL(Action.ACCEPT),
            intent: Intent.DANGER,
          })
        }
      },
      afterAction: () => {
        mountedSet(setIsLoading, false)
        reloadList()
        onClose()
      },
    })
  }, [formData, onClose, reloadList])

  const fetchDetail = useCallback(() => {
    let workId = actionId
    if (!actionId) {
      workId = id
    }

    if (workId) {
      requestAction({
        showToast: false,
        beforeAction: () => mountedSet(setFetching, true),
        action: () => getCongViec(workId),
        afterResponse: (result = {}) => {
          setFormData(prev => ({
            ...prev,
            id: result?.id,
            progress: result?.progress,
          }))
        },
        afterAction: () => mountedSet(setFetching, false),
        afterError: () => {
          showToast({
            message: Toast.NOT_FOUND('công việc'),
            intent: Intent.WARNING,
          })
          onClose()
        },
      })
    }
  }, [id, actionId, onClose])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  return (
    <div
      className={classNames(
        'cpc-side-action',
        'open',
        'large-size',
        'cpc-form'
      )}
    >
      <div>
        <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
          <span>Xác nhận công việc</span>
          <Icon
            classIcon="icon-Huy"
            className={'has-event font-size-12 ml10'}
            onClick={onClose}
          />
        </h1>
        <div className="pt10 pr10 pl10">
          <Card className={'cpc-detail-block p10'}>
            <div class="align-center" style={{ height: '30px' }}>
              <label class="">Tiến độ</label>
            </div>
            <div>
              <SliderBar
                onRelease={changeField('progress')}
                value={formData?.progress || 0}
              />
            </div>
            <Input
              disabled={fetching || isLoading}
              label="Mô tả"
              onChange={changeField('description')}
              placeholder="Nhập..."
              rows={4}
              maxLength={500}
              autoResize={true}
              style={{ maxHeight: 'unset' }}
              type="textarea"
              value={formData?.description}
              wrapperClassName="mt10"
            />
          </Card>
        </div>
        <div className="element-center p15">
          <Button
            className={classNames(
              'cpc-button',
              'btn-cancel',
              'uppercase',
              'font-size-13',
              'ph10 mr10',
              'min-width-100'
            )}
            disabled={fetching}
            onClick={onClose}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={classNames(
              'cpc-button',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100'
            )}
            intent={Intent.PRIMARY}
            disabled={isLoading}
            loading={isLoading}
            onClick={handleAccept}
          >
            <span>Lưu</span>
            <Icon classIcon="icon-save" className="ml5" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withRouter(memo(Accept))
