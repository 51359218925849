import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { PopupConfirm } from '../../common/'
import * as POPUP from '../../../constants/Popup'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    disableAction: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickClosePopup = this.handleClickClosePopup.bind(this)
    this.handleSubmitAlertMistake = this.handleSubmitAlertMistake.bind(this)
  }

  state = {
    isRedirect: false,
    isOpenPopup: false,
    isCheck: false,
    title: '',
    isLoading: false,
  }

  handleClick = () => {
    this.setState({
      isRedirect: true,
    })
  }

  handleClickPopup = i => {
    if (i === 1) {
      this.setState({
        isOpenPopup: true,
        error: '',
        isCheck: false,
      })
    }
    if (i === 2) {
      this.setState({
        isOpenPopup: true,
        error: '',
        isCheck: true,
      })
    }
  }

  handleClickClosePopup() {
    if (this.state.isLoading) {
      return
    }
    this.setState({
      isOpenPopup: false,
    })
  }

  handleSubmitAlertMistake(lyDo, e) {
    e.preventDefault()
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const id = this.props.item && this.props.item.congVanCongViecId
        if (id) {
          this.props.onHandleSubmitRemoveItem &&
            this.props.onHandleSubmitRemoveItem(id, 2, lyDo)
        }
      }
    )
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const id = this.props.item && this.props.item.congVanCongViecId
        if (id) {
          this.props.onHandleSubmitRemoveItem &&
            this.props.onHandleSubmitRemoveItem(id, 1, null)
        }
      }
    )
  }

  render() {
    if (this.state.isRedirect) {
      if (!this.props.item.congViecPhongBanId) {
        return (
          <Redirect
            push
            to={{
              pathname: `/cong-viec/ca-nhan/chi-tiet/da-giao/${this.props.item.congVanCongViecId}`,
              state: {
                status: 'chuyen-nham',
              },
            }}
          />
        )
      }
      return (
        <Redirect
          push
          to={{
            pathname: `/cong-viec/phong-ban/chi-tiet/da-giao/${this.props.item.congViecPhongBanId}`,
            state: {
              status: 'chuyen-nham',
            },
          }}
        />
      )
    }
    const { item } = this.props

    return (
      <tr>
        <td onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick}>
          {item &&
            item.dsBaoNham &&
            item.dsBaoNham[0] &&
            item.dsBaoNham[0].ngayBaoNham &&
            moment(item.dsBaoNham[0].ngayBaoNham).format('DD/MM/YYYY')}
        </td>
        <td onClick={this.handleClick}>{item && item.soCongVan}</td>
        <td onClick={this.handleClick} className="textarea-content">
          {item && item.trichYeu}
        </td>
        <td onClick={this.handleClick}>
          {item &&
            item.hanGiaiQuyet &&
            moment(item.hanGiaiQuyet).format('DD/MM/YYYY')}
        </td>
        <td onClick={this.handleClick}>{item && item.nguoiChuTri}</td>
        <td onClick={this.handleClick}>
          {item &&
            item.dsBaoNham.map((value, i) => (
              <p key={i}>
                <span className="info-alertMistake">{value.nguoiBaoNham}</span>{' '}
                - {moment(value.ngayBaoNham).format('DD/MM/YYYY')}:{' '}
                {value.noiDung}
              </p>
            ))}
        </td>
        <td>
          <Popover
            popoverClassName="pt-popover-content-sizing"
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            openOnTargetFocus={true}
            content={
              <div className="menu-popover">
                <div
                  className="item"
                  onClick={this.handleClickPopup.bind(this, 1)}
                >
                  <span className="pt-icon pt-icon-trash"></span>
                  <span className="name_action">Huỷ báo nhầm</span>
                </div>
                {!item.congVanNoiBoId && (
                  <div
                    className="item"
                    onClick={this.handleClickPopup.bind(this, 2)}
                  >
                    <span className="pt-icon icon-attention-warning"></span>
                    <span className="name_action">Báo nhầm cấp trên</span>
                  </div>
                )}
              </div>
            }
          >
            <span className="icon-More"></span>
          </Popover>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            textarea={this.state.isCheck ? true : false}
            onClose={this.handleClickClosePopup}
            isCheck={true}
            title={
              this.state.isCheck
                ? POPUP.BAO_NHAM_CAP_TREN
                : POPUP.HUY_CHUYEN_NHAM
            }
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={
              this.state.isCheck
                ? this.handleSubmitAlertMistake
                : this.handleSubmitRemoveItem
            }
            isLoading={this.state.isLoading}
          >
            {POPUP.HUY_BAO_NHAM_CONFIRM}
          </PopupConfirm>
        </td>
      </tr>
    )
  }
}

export default ListItem
