import { Action, Toast } from '../../../constants/MessageForm'
import {
  ContentHeader,
  EmptyDataTable,
  ErrorLoadingTable,
  LoadingTable,
  HeadingTableSort,
} from '../../../components/common'
import { headers } from '../../../constants/Table'
import React, { PureComponent } from 'react'
import {
  fetchProcedureSignatureUnits,
  addSignatureUnit,
  removeSignatureUnit,
} from '../../../actions/procedure'

import { Intent } from '@blueprintjs/core'
import { MasterLayout } from '../../../components/layout'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { commonAddToasterMessage } from '../../../actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import AddDonViDtxd from './AddDonViDtxd'

const PAGE_NAME = 'Đơn vị bên ngoài'
const PAGE_ICON = 'icon-danh-muc-quy-trinh'

class UsingGroupDetailDTXDPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isError: false,
      isEmptyData: false,
      isDialogOpen: false,
      currentGroupId: null,
      groupList: [],
    }
  }

  toggleDialog = groupId => {
    const { isDialogOpen } = this.state
    this.setState({
      isDialogOpen: !isDialogOpen,
    })
  }

  fetchUnitGroup = async () => {
    const { id } = this.props.match.params
    const { fetchProcedureSignatureUnits } = this.props
    await this.setState({ isLoading: true })
    fetchProcedureSignatureUnits(id)
      .then(res => {
        const status = !!(_.get(res, 'payload.status') === 200)
        this.setState({
          isError: !status,
          groupList: res.payload.data.result.items,
        })
      })
      .finally(() =>
        this.setState({
          isLoading: false,
        })
      )
  }

  removeDonVi = id => {
    debugger
    const { removeMember, showToast } = this.props
    removeMember(id).then(res => {
      const status = !!(_.get(res, 'payload.status') === 200)
      showToast({
        message: status
          ? Toast.SUCCESS(Action.DELETE)
          : Toast.FAIL(Action.DELETE),
        intent: status ? Intent.SUCCESS : Intent.DANGER,
      })
      this.fetchUnitGroup()
    })
  }

  addDonVi = data => {
    debugger
    const { addMember, showToast } = this.props
    const { id } = this.props.match.params
    addMember(id, data).then(res => {
      const status = !!(_.get(res, 'payload.status') === 200)
      !!status && this.toggleDialog()
      showToast({
        message: status ? Toast.SUCCESS(Action.ADD) : Toast.FAIL(Action.ADD),
        intent: status ? Intent.SUCCESS : Intent.DANGER,
      })
      this.fetchUnitGroup()
    })
  }

  componentDidMount() {
    this.fetchUnitGroup()
  }

  render() {
    const { title } = this.props
    const { isLoading, isError, isDataEmpty, isDialogOpen, groupList } =
      this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <ContentHeader
          pageIcon={PAGE_ICON}
          pageName={`${PAGE_NAME} ${!isLoading ? title : ''}`}
          showGoBackButton
        />
        <div className="group-page-container">
          <div className="container-body group-header no-justify">
            <div className="group-header">
              <div className="group-name">Thông tin đơn vị</div>
              <div className="action-button-group">
                <button
                  className="action-button add-btn"
                  onClick={this.toggleDialog}
                >
                  <i className="pt-icon icon-Plus" />
                  THÊM ĐƠN VỊ
                </button>
              </div>
            </div>
            {!!isLoading ? (
              <LoadingTable />
            ) : !!isError ? (
              <ErrorLoadingTable />
            ) : !!isDataEmpty ? (
              <EmptyDataTable />
            ) : (
              !!groupList.length && (
                <div>
                  <HeadingTableSort data={headers.procedureDTXDDetail} />
                  <SortableList
                    helperClass="SortableHelper"
                    list={groupList}
                    removeDonVi={this.removeDonVi}
                  />
                </div>
              )
            )}
          </div>
        </div>
        <AddDonViDtxd
          isDialogOpen={isDialogOpen}
          closeDialog={this.toggleDialog}
          title="Thêm đơn vị"
          onSubmit={this.addDonVi}
        />
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  groupList: state.procedureAuthority.groupList,
  title: state.procedureAuthority.title,
  auth: state.auth,
})

const mapDispathToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
  fetchProcedureSignatureUnits: bindActionCreators(
    fetchProcedureSignatureUnits,
    dispatch
  ),
  removeMember: bindActionCreators(removeSignatureUnit, dispatch),
  addMember: bindActionCreators(addSignatureUnit, dispatch),
})

const SortableList = SortableContainer(({ list, ...props }) => (
  <div className="table-display sortableList">
    {!!list &&
      list.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          i={index + 1}
          index={index}
          item={item}
          disabled
          {...props}
        />
      ))}
  </div>
))

const SortableItem = SortableElement(({ i, item, removeDonVi }) => (
  <div className="table-rows no-event">
    <div className="table-cols-content width-col-5">{i < 10 ? '0' + i : i}</div>
    <div className="table-cols-content width-col-20">{item.ten}</div>
    <div className="table-cols-content width-col-30">{item.email}</div>
    <div className="table-cols-content width-col-30">
      {item.soDienThoaiViettel}
    </div>
    <div className="table-cols-content width-col-30">
      {item.soDienThoaiVnpt}
    </div>
    <div className="table-cols-content width-col-5">
      <button
        className="delete-member-btn"
        onClick={() => removeDonVi(item.donViDtxdId)}
      >
        <span className="pt-icon pt-icon-trash"></span>
      </button>
    </div>
  </div>
))

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withRouter(UsingGroupDetailDTXDPage))
