import React from 'react'
import { MasterLayout } from '../../components/layout'
import { FormAddSecurityLevelCategory } from '../../components/System'
import { HeadingPage } from '../../components/common'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'

const SUA_DO_MAT = 'Sửa độ mật'
const THEM_MOI_DO_MAT = 'Thêm mới độ mật'
class AddSecurityLevelCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    doBaoMatId: this.props.match.params.id,
    securities: null,
  }

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      this.props.actions.getCommonFetchSecurityItem(this.state.doBaoMatId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.securities.item !== this.props.securities.item) {
      this.setState({ securities: nextProps.securities.item })
    }
  }

  handleSubmit(isSuccess = false) {
    if (isSuccess === true) {
      this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
    }

    return this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={this.state.securities ? SUA_DO_MAT : THEM_MOI_DO_MAT}
              iconPage="icon-flag"
            />
            <FormAddSecurityLevelCategory
              actions={this.props.actions}
              securities={this.state.securities}
              auth={this.props.auth}
              onSubmit={this.handleSubmit}
              match={this.props.match}
              history={this.props.history}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  securities: {
    ...state.securityItem,
    item: state.common.securityItem,
  },

  auth: {
    ...state.auth,
    user: state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSecurityLevelCategoryPage)
