import React from 'react'
import TreeSelect, { TreeNode } from 'rc-tree-select'
import classnames from 'classnames'

class SelectTree extends React.Component {
  constructor(props) {
    super(props)
    this.filterTreeNode = this.filterTreeNode.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  static defaultProps = {
    showSearch: false,
  }

  filterTreeNode = (input, child) => {
    let res = input.toLowerCase()
    let titleProps = child.props.title.toLowerCase()
    return String(titleProps).indexOf(res) === 0
  }

  handleSelect(data, type, disabled = null, isGetTenPhongBan = false) {
    if (type === 'Unit') {
      return data.map((item, i) => {
        if (
          this.props.match !== undefined &&
          item.donViId === this.props.match.params.id
        ) {
          return this.handleSelect([], type)
        }

        return (
          <TreeNode
            value={item.donViId}
            title={item.tenVietTat}
            key={item.donViId || i}
          >
            {item.dsDonViCon && this.handleSelect(item.dsDonViCon, type)}
          </TreeNode>
        )
      })
    }

    if (type === 'Department') {
      return data.map((item, i) => {
        if (
          this.props.match !== undefined &&
          item.phongBanId === this.props.match.params.id
        ) {
          return this.handleSelect([], type)
        }

        return (
          <TreeNode
            className={classnames({
              'active-select': disabled && this.props.value === item.phongBanId,
              'disable-select':
                disabled && disabled.indexOf(item.phongBanId) > -1,
            })}
            value={item.phongBanId}
            title={isGetTenPhongBan ? item.tenPhongBan : item.maPhongBan}
            key={item.phongBanId || i}
          >
            {item.dsPhongBanCon &&
              this.handleSelect(item.dsPhongBanCon, type, disabled)}
          </TreeNode>
        )
      })
    }

    if (type === 'selectTreeFilter') {
      return data.map((item, i) => {
        return (
          <TreeNode
            value={item.phongBanId ? item.phongBanId : undefined}
            title={item.maPhongBan ? item.maPhongBan : item.tenVietTat}
            key={`${item.phongBanId}${i}` || i}
          >
            {item.dsPhongBanCon &&
              this.handleSelect(item.dsPhongBanCon, type, disabled)}
          </TreeNode>
        )
      })
    }

    if (type === 'selectCustom') {
      return data.map((item, i) => {
        if (
          this.props.match !== undefined &&
          item.donViId === this.props.match.params.id
        ) {
          return this.handleSelect([], type)
        }

        return (
          <TreeNode
            value={item.donViId}
            title={item.tenVietTat}
            key={item.donViId || i}
          >
            {item.dsDonViCon && this.handleSelect(item.dsDonViCon, type)}
          </TreeNode>
        )
      })
    }
  }

  render() {
    if (this.props.type === 'selectTreeFilter') {
      return (
        <TreeSelect
          name={this.props.name ? this.props.name : ''}
          className={`dropdown-tree dropdown-tree-filter ${this.props.className}`}
          dropdownClassName={this.props.dropdownClassName}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          value={this.props.value}
          treeDefaultExpandAll={false}
          treeNodeFilterProp="title"
          filterTreeNode={this.filterTreeNode}
          showSearch={false}
          onChange={this.props.handleChange.bind(this, this.props.name)}
          notFoundContent="Không có kết quả"
          disabled={
            this.props.permission ||
            this.props.isLoading ||
            this.props.isDisabled
          }
        >
          {this.handleSelect(
            this.props.data,
            this.props.type,
            this.props.disabled,
            this.props.isGetTenPhongBan
          )}
        </TreeSelect>
      )
    } else if (this.props.type === 'selectCustom') {
      return (
        <TreeSelect
          name={this.props.name ? this.props.name : ''}
          className={`dropdown-tree dropdown-tree-custom ${this.props.className}`}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          value={this.props.value || ''}
          treeDefaultExpandAll={false}
          treeNodeFilterProp="title"
          showSearch={true}
          onChange={this.props.handleChange}
          notFoundContent="Không có kết quả"
          disabled={this.props.isLoading || this.props.isDisabled}
        >
          {this.handleSelect(this.props.data, this.props.type, null, false)}
        </TreeSelect>
      )
    } else {
      return (
        <TreeSelect
          name={this.props.name ? this.props.name : ''}
          className={`dropdown-tree dropdown-tree-filter ${this.props.className}`}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          value={this.props.value}
          treeDefaultExpandAll={false}
          treeNodeFilterProp="title"
          filterTreeNode={this.filterTreeNode}
          showSearch={true}
          onChange={this.props.handleChange}
          notFoundContent="Không có kết quả"
          disabled={
            this.props.permission ||
            this.props.isLoading ||
            this.props.isDisabled
          }
        >
          {this.handleSelect(
            this.props.data,
            this.props.type,
            this.props.disabled,
            this.props.isGetTenPhongBan
          )}
        </TreeSelect>
      )
    }
  }
}

export default SelectTree
