import React, { memo, useRef, useState, useEffect, useCallback } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import { Radio } from '@blueprintjs/core'

import DropdownWrapper from './DropdownWrapper'
import { Card, Scrollbar } from 'components/newCommon'

const Select = ({
  heightMax = 350,
  radioName = 'treeItem',
  dataSource = [],
  keySource = ['label', 'code'],
  onChange: selectChange = () => {},
  value: selectValue,
  onClear,
  ...props
}) => {
  const mounted = useRef(false)
  const mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [selected, setSelected] = useState()
  const [dropdownOpen, _setDropdownOpen] = useState(false)

  const toggleDropdown = useCallback(open => {
    mountedSet(_setDropdownOpen, open)
  }, [])

  const onChange = useCallback(
    value => {
      mountedSet(setSelected, value)
      toggleDropdown(false)
      selectChange(value)
    },
    [selectChange, toggleDropdown]
  )

  const onHandleClear = useCallback(() => {
    mountedSet(setSelected)
    toggleDropdown(false)
    onClear()
  }, [onClear, toggleDropdown])

  const renderTree = (values, index, dataCheck) => {
    if (isEmpty(values)) {
      return <li key={index}></li>
    }

    return (
      <li key={index}>
        <div>
          {!values?.noCheck ? (
            <div className="d-flex">
              <Radio
                name={radioName}
                label={values?.[keySource[0]]}
                value={values?.[keySource[1]]}
                checked={dataCheck?.[keySource[1]] === values?.[keySource[1]]}
                onChange={() => onChange(values)}
              />
            </div>
          ) : (
            <div className="text-uppercase font-weight-700">
              {values?.[keySource[0]]}
            </div>
          )}
        </div>
        {size(values?.children) !== 0 && (
          <ul>
            {values?.children?.map((elm, index2) =>
              renderTree(elm, index2, dataCheck)
            )}
          </ul>
        )}
      </li>
    )
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    mountedSet(setSelected, selectValue)
  }, [selectValue])

  return (
    <DropdownWrapper
      {...props}
      value={selected?.[keySource[0]] || ''}
      open={dropdownOpen}
      onDropdownVisibleChange={toggleDropdown}
      onClear={onHandleClear}
    >
      <Scrollbar maxHeight={heightMax}>
        <Card className="popup" mainClassName="select-tree-box">
          <ul>
            {dataSource?.map((elm, index) => renderTree(elm, index, selected))}
          </ul>
        </Card>
      </Scrollbar>
    </DropdownWrapper>
  )
}

export default memo(Select)
