import React, { Component } from 'react'
import { Button, Intent, Checkbox } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../../helpers'
import * as MESSAGER from '../../../constants/MessageForm'
import { Authorization, Permission } from '../../auth'

const CONGVANDEN = 'CONGVANDEN'
const CONG_VIEC = {
  XUAT_PHAT_TU_CV: '1',
  KHONG_XUAT_PHAT_TU_CV: '2',
}
class ListFilter extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
  }

  state = {
    workType: CONG_VIEC.XUAT_PHAT_TU_CV,
    start_date: moment().subtract(1, 'months'),
    end_date: moment(),
    phong_ban_id: this.props.auth.mainDepartmentId,
    qua_han: false,
    dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
    dateToToString: moment().format('DD/MM/YYYY'),
  }

  handleChange(name, e) {
    this.setState({
      [name]: e.target.value,
    })
  }

  handleDateChange(name, nameToString, e) {
    let value = ''
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: e,
      [nameToString]: value,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      name === 'start_date' &&
      !this.state.end_date &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      name === 'start_date' &&
      this.state.end_date &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: moment(moment(this.state.end_date).format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    if (
      name === 'end_date' &&
      !this.state.start_date &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      name === 'end_date' &&
      this.state.start_date &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment(this.state.start_date).format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const start_date = this.state.start_date
      ? moment(this.state.start_date).format('YYYY-MM-DD')
      : null
    const end_date = this.state.end_date
      ? moment(this.state.end_date).format('YYYY-MM-DD')
      : null
    const phong_ban_id = this.state.phong_ban_id
    const qua_han = this.state.qua_han
    const loai_cong_viec = this.state.workType

    if (!start_date || !end_date) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TEXT_MESSAGE_TIME,
        intent: Intent.WARNING,
      })
    }

    this.props.onSubmit &&
      this.props.onSubmit({
        start_date,
        end_date,
        phong_ban_id,
        qua_han,
        loai_cong_viec,
      })
  }

  handleChangeCheckbox(e) {
    this.setState({
      [e.target.name]: e.target.checked,
    })
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.auth.permission.length > 0) {
      const permission =
        nextProps.auth.permission.find(item => item === CONGVANDEN) ===
        undefined
      this.setState({ permission })
    }

    if (nextProps.filter !== this.props.filter) {
      this.setState({
        phong_ban_id:
          nextProps.filter.phong_ban_id || this.props.auth.mainDepartmentId,
        start_date:
          nextProps.filter.start_date &&
          nextProps.filter.start_date.trim().length > 0
            ? moment(nextProps.filter.start_date)
            : undefined,
        end_date:
          nextProps.filter.end_date &&
          nextProps.filter.end_date.trim().length > 0
            ? moment(nextProps.filter.end_date)
            : undefined,
        qua_han: nextProps.filter.qua_han || false,
        dateFromToString:
          nextProps.filter.start_date &&
          nextProps.filter.start_date.trim().length > 0
            ? moment(nextProps.filter.start_date)
            : '',
        dateToToString:
          nextProps.filter.end_date &&
          nextProps.filter.end_date.trim().length > 0
            ? moment(nextProps.filter.end_date)
            : '',
        workType: nextProps.filter.loai_cong_viec || '',
      })
    }
  }

  render() {
    return (
      <div className="search-container search-GeneralWork">
        <form className="search-list-utilities search-utilities-nodropdown">
          <div className="search-advanced seach-form-container">
            <div className="row row-box-container">
              <div className="col-xs-12 search-padding0">
                <div className="group-datetime pull-left">
                  <label className="pt-label label-datepicker">
                    Thời gian (ngày giao việc)
                  </label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      isClearable={this.state.dateFromToString ? true : false}
                      readOnly={this.props.readOnly}
                      selected={this.state.start_date}
                      selectsStart
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleDateChange.bind(
                        this,
                        'start_date',
                        'dateFromToString'
                      )}
                      value={this.state.dateFromToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateFromToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'start_date',
                          'dateFromToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.end_date}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                      disabled={
                        this.props.match &&
                        this.props.match.params &&
                        this.props.match.params.type
                      }
                    />
                    <DatePicker
                      isClearable={this.state.dateToToString ? true : false}
                      readOnly={this.props.readOnly}
                      selected={this.state.end_date}
                      selectsEnd
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleDateChange.bind(
                        this,
                        'end_date',
                        'dateToToString'
                      )}
                      value={this.state.dateToToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateToToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'end_date',
                          'dateToToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.start_date}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                      disabled={
                        this.props.match &&
                        this.props.match.params &&
                        this.props.match.params.type
                      }
                    />
                  </div>
                </div>
                {!Authorization.vaild(this.props.auth.permission, [
                  Permission.ADMIN,
                ]) && (
                  <div className="group-select pull-left">
                    <label className="pt-label">
                      Phòng ban
                      <div className="pt-select">
                        <select
                          className="select-content"
                          name="phong_ban_id"
                          onChange={this.handleChange.bind(
                            this,
                            'phong_ban_id'
                          )}
                          value={this.state.phong_ban_id}
                          disabled={this.state.permission}
                        >
                          {this.props.departments.map((item, i) => (
                            <option key={i} value={item.phongBanId}>
                              {item.maPhongBan}
                            </option>
                          ))}
                        </select>
                      </div>
                    </label>
                  </div>
                )}
                <div className="group-select pull-left">
                  <label className="pt-label">
                    Loại công việc
                    <div className="pt-select">
                      <select
                        className="select-content"
                        name="workType"
                        onChange={this.handleChange.bind(this, 'workType')}
                        value={this.state.workType}
                        disabled={
                          this.props.match &&
                          this.props.match.params &&
                          this.props.match.params.type
                        }
                      >
                        <option value={CONG_VIEC.XUAT_PHAT_TU_CV}>
                          Từ công văn
                        </option>
                        <option value={CONG_VIEC.KHONG_XUAT_PHAT_TU_CV}>
                          Không qua công văn
                        </option>
                        <option value="">Tất cả</option>
                      </select>
                    </div>
                  </label>
                </div>
                {!Authorization.vaild(this.props.auth.permission, [
                  Permission.ADMIN,
                ]) && (
                  <div className="checkbox-dealine-statistics">
                    <Checkbox
                      type="checkbox"
                      name="qua_han"
                      checked={this.state.qua_han}
                      onChange={this.handleChangeCheckbox}
                      label="Quá hạn"
                      className="checkbox-content"
                    />
                  </div>
                )}
                {this.props.match &&
                  this.props.match.params &&
                  !this.props.match.params.type && (
                    <div className="group-btn-search pull-right">
                      <Button
                        rightIconName="pt-icon pt-icon-search"
                        className="btn-item btn-search"
                        text="Thống kê"
                        onClick={() => {
                          this.handleSubmit()
                          this.props.actions.refreshStatistic(true)
                        }}
                        loading={this.props.isLoading}
                      />
                      {/* TODO: Bỏ button refresh */}
                      {/* <Button type="button" className="btn-item btn-reload" onClick={this.clearFilter}>
                      <span className="icon-Loading"></span>
                    </Button> */}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default ListFilter
