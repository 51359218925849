import { Button, Intent } from '@blueprintjs/core'
import classnames from 'classnames'
import { HeadingPage } from 'components/common'
import { MasterLayout } from 'components/layout'
import * as Actions from 'actions'
import {
  TEXT_MESSAGE_DEFAULT,
  TOATS_MESSAGE_FAIL,
  TOATS_MESSAGE_SUCCESS,
} from 'constants/MessageForm'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './style.scss'

class AddApplication extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoadingData: false,
      key: '',
      tenUngDung: '',
      moTa: '',
      error: {},
      unit: true,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) {
      this.props.actions.getDetailApplication(id)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.props.match.params
    if (id && nextProps.detailApplications) {
      this.setState({
        key: nextProps.detailApplications.key,
        tenUngDung: nextProps.detailApplications.name,
        moTa: nextProps.detailApplications.description,
      })
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ error: {} })
    const { id } = this.props.match.params
    const error = {}

    if (this.state.tenUngDung.trim().length === 0) {
      error.tenUngDung = TEXT_MESSAGE_DEFAULT
    }

    const pattern = /^[A-Za-z_]+$/
    if (
      this.state.key.trim().length > 0 &&
      !pattern.test(this.state.key.trim())
    ) {
      // eslint-disable-next-line max-len
      error.key =
        'Chỉ bao gồm chữ cái không dấu, dấu _ và không chứa khoảng trắng.'
    }

    this.setState({ error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return
    }
    this.setState({ isLoading: true })
    if (!id) {
      this.props.actions
        .postApplication({
          name: this.state.tenUngDung,
          key: this.state.key,
          description: this.state.moTa,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            this.props.history.goBack()
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    } else {
      this.props.actions
        .putApplication(id, {
          name: this.state.tenUngDung,
          key: this.state.key,
          description: this.state.moTa,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            this.props.history.goBack()
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }
  }

  render() {
    const { id } = this.props.match.params
    return (
      <MasterLayout typeSidebar="sidebarSystem" collapseSideBar={true}>
        <div className="row center-xs update-DecentralizationUser">
          <div className="col-md-12 col-xs-12 col-sm-12 section-container">
            <HeadingPage
              namePage={
                !id ? 'Thêm mới ứng dụng chia sẻ' : 'Chỉnh sửa ứng dụng chia sẻ'
              }
              iconPage="icon-menu-o-vuong"
            />
            <div className="systerm-main-content">
              <div className="form-container form-inline-container systerm-form-container">
                <form className="main-form systerm-form">
                  <div className="form-block">
                    <div className="form-subblock">
                      <div className="form-row justify-center">
                        <div className="form-cols form-col8">
                          <div className="form-row ">
                            <div className="form-cols form-col5">
                              <div className="form-group-item pt-form-group pt-intent-danger">
                                <label
                                  className="pt-label pt-label-inline"
                                  htmlFor="tenUngDung"
                                >
                                  Tên ứng dụng
                                  <span className="pt-text-muted required-input">
                                    *
                                  </span>
                                </label>
                                <div className="input-form-inline pt-form-content">
                                  <input
                                    type="text"
                                    value={this.state.tenUngDung}
                                    name="tenUngDung"
                                    placeholder="Nhập tên"
                                    className={classnames('pt-input', {
                                      'loading-input':
                                        Object.values(
                                          this.state.isLoadingData
                                        ).find(item => item === true) !==
                                        undefined,
                                    })}
                                    onChange={this.handleChange}
                                    maxLength={250}
                                  />
                                  {this.state.error?.tenUngDung !==
                                    undefined && (
                                    <div className="pt-form-content pt-form-helper-text">
                                      {this.state.error?.tenUngDung}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-cols form-col5">
                              <div className="form-group-item pt-form-group pt-intent-danger">
                                <label
                                  className="pt-label pt-label-inline"
                                  htmlFor="key"
                                >
                                  Key
                                </label>
                                <div className="input-form-inline pt-form-content">
                                  <input
                                    type="text"
                                    value={this.state.key}
                                    className={classnames('pt-input', {
                                      'loading-input':
                                        Object.values(
                                          this.state.isLoadingData
                                        ).find(item => item === true) !==
                                        undefined,
                                    })}
                                    name="key"
                                    placeholder="Nhập tên key"
                                    onChange={this.handleChange}
                                    maxLength={250}
                                  />
                                  {this.state.error?.key !== undefined && (
                                    <div className="pt-form-content pt-form-helper-text">
                                      {this.state.error?.key}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-cols form-col-full">
                              <div className="form-group-item pt-form-group pt-intent-danger">
                                <label
                                  className="pt-label pt-label-inline custom-label-full"
                                  htmlFor="moTa"
                                >
                                  Mô tả
                                </label>
                                <div className="input-form-inline pt-form-content custom-full-width">
                                  <textarea
                                    name="moTa"
                                    rows="4"
                                    className="pt-input pt-fill"
                                    placeholder="Nhập mô tả"
                                    dir="auto"
                                    value={this.state.moTa}
                                    onChange={this.handleChange}
                                    maxLength={255}
                                  />
                                  {this.state.error?.moTa !== undefined && (
                                    <div className="pt-form-content pt-form-helper-text">
                                      {this.state.error?.moTa}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-block bottom-form">
                    <div className="buttton-action-footer">
                      <Button
                        type="button"
                        className="pt-button pt-button button-default"
                        onClick={() => this.props.history.goBack()}
                        disabled={this.state.isLoading === true ? true : false}
                      >
                        <span className="text-content">Hủy</span>
                        <span className="pt-icon icon-Huy"></span>
                      </Button>
                      <Button
                        type="button"
                        className="pt-button pt-button btn-blue-color"
                        loading={this.state.isLoading}
                        onClick={this.handleSubmit}
                        disabled={
                          Object.values(this.state.isLoadingData).find(
                            item => item === true
                          ) !== undefined
                        }
                      >
                        <span className="text-content">Lưu</span>
                        <span className="pt-icon icon-save"></span>
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  detailApplications: state.system.detailApplications,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddApplication)
