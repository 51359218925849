import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import DatePicker from 'react-datepicker'

class ListFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      filter: {},
      maxDate: '2017-11-20',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps(props) {
    if (props.filter) {
      this.setState({
        filter: {
          textSearch: props.filter.textSearch || undefined,
          ngayCongVan: {
            from:
              props.filter.ngayCongVan &&
              props.filter.ngayCongVan.from &&
              props.filter.ngayCongVan.from.trim().length > 0
                ? moment(props.filter.ngayCongVan.from)
                : undefined,
            to:
              props.filter.ngayCongVan &&
              props.filter.ngayCongVan.to &&
              props.filter.ngayCongVan.to.trim().length > 0
                ? moment(props.filter.ngayCongVan.to)
                : undefined,
          },
        },
      })
    }
  }

  clearFilter = e => {
    e.preventDefault()
    this.props && this.props.handleClearFilter()
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }

    this.props.onSubmit && this.props.onSubmit()
  }

  removeTextSearch(name) {
    this.props && this.props.handleFilterRemoveTextSearch(name)
  }

  handleDateChange(name, pos, e) {
    this.props && this.props.handleFilterDateChange(name, pos, e)
  }

  handleInputChange = e => {
    this.props && this.props.handleFilterInputChange(e)
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch pt15 pb15 px-sm-15 mb10"
      >
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <label className="search-label mb5">
              Thời gian (Ngày công văn)
            </label>
            <div className="row-picker-range">
              <div className="col-picker-range">
                <DatePicker
                  readOnly
                  isClearable
                  selected={get(this.state.filter.ngayCongVan, 'from')}
                  selectsStart
                  startDate={get(this.state.filter.ngayCongVan, 'from')}
                  endDate={get(this.state.filter.ngayCongVan, 'to')}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'from'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Từ ngày"
                  maxDate={
                    get(this.state.filter.ngayCongVan, 'to') ||
                    moment(this.state.maxDate)
                  }
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
              <div className="col-picker-range">
                <DatePicker
                  readOnly
                  isClearable
                  selected={get(this.state.filter.ngayCongVan, 'to')}
                  selectsEnd
                  startDate={get(this.state.filter.ngayCongVan, 'from')}
                  endDate={get(this.state.filter.ngayCongVan, 'to')}
                  onChange={this.handleDateChange.bind(
                    this,
                    'ngayCongVan',
                    'to'
                  )}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Đến ngày"
                  minDate={get(this.state.filter.ngayCongVan, 'from')}
                  maxDate={moment(this.state.maxDate)}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-8 mt20 mt-md-0 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                name="textSearch"
                className="input border-transparent pr-40 input-search-filter"
                type="text"
                onChange={this.handleInputChange}
                placeholder={`Tìm kiếm Số CV, Trích yếu, Số hoá (hoặc Số CV:Trích yếu)`}
                value={
                  this.state.filter.textSearch
                    ? this.state.filter.textSearch
                    : ''
                }
                autoFocus={true}
                maxLength={255}
              />
              {this.state.filter.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'textSearch')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

ListFilter.propTypes = {
  filter: PropTypes.object,
  onSubmit: PropTypes.func,
  handleFilterDateChange: PropTypes.func,
  handleFilterInputChange: PropTypes.func,
  handleFilterRemoveTextSearch: PropTypes.func,
  handleClearFilter: PropTypes.func,
}

export default ListFilter
