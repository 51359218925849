import React from 'react'
import classNames from 'classnames'
import { isValidCondition } from 'helpers/Helper'

const HomeCard = ({
  auth,
  className,
  history,
  total,
  descriptions,
  workType,
}) => {
  const goDetail = () => {
    let pathname = '/quan-ly-cong-viec/ca-nhan/can-thuc-hien'
    if (isValidCondition(auth?.permission, ['CONGVIEC_PHONGBAN'])) {
      pathname = '/quan-ly-cong-viec/phong-ban/chua-giao'
    }

    return history.push({
      pathname: pathname,
      state: {
        workType,
      },
    })
  }

  return (
    <div
      className={classNames('home-card cursor-pointer', className)}
      onClick={() => goDetail()}
    >
      <p className="home-card__num font-size-24 font-weight-600">
        {total || 0}
      </p>
      <p className="home-card__des font-size-14">
        <span
          className="font-roboto font-weight-400 word-break-word"
          dangerouslySetInnerHTML={{ __html: descriptions ?? '' }}
        ></span>
      </p>
    </div>
  )
}

export default HomeCard
