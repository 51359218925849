import React, { PureComponent } from 'react'
import { Button, Intent } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import classnames from 'classnames'
import FileDownload from 'file-saver'
import moment from 'moment'

import { MasterLayout } from 'components/layout'
import { GridView } from 'components/common'
import { ListFilter } from 'components/Utilities/hosocongviec'
import { Utils } from 'helpers'
import * as actions from 'actions'
import { DA_XAY_RA_LOI } from 'constants/MessageForm'
import { GUID_EMPTY } from 'constants/Enum'
import HeadingPanel from 'components/common/HeadingPanel'

const TOTAL_NAME = 'Tổng cộng'
const DONVI = '1'
const PHONGBAN = '2'

class JobProject extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      filter: {},
      isLoading: false,
      isEmpty: false,
      isError: false,
      data: [],
      exporting: false,
    }
  }

  async componentDidMount() {
    let state = this.state
    const preData = Utils.preData('thongKeHoSoCongViec')
    if (preData) {
      state = {
        ...state,
        filter: {
          start_date: preData.start_date,
          end_date: preData.end_date,
        },
      }
    }

    if (
      get(this.props.match, 'params.id') &&
      get(this.props.match, 'params.loai')
    ) {
      let loai =
        get(this.props.match, 'params.loai') === 'phong-ban' ? PHONGBAN : DONVI
      if (preData) {
        state = {
          ...state,
          filter: {
            start_date: preData.start_date,
            end_date: preData.end_date,
            loai: loai,
            id: get(this.props.match, 'params.id'),
          },
        }
      }
    }

    this.setState(state, () => {
      this.submitFilter(this.state.filter)
    })
  }

  componentWillReceiveProps(nextProps) {
    if (get(nextProps.match, 'params.id') !== get(this.state.filter, 'id')) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            id: get(nextProps.match, 'params.id'),
            loai:
              get(nextProps.match, 'params.loai') === 'phong-ban'
                ? PHONGBAN
                : DONVI,
          },
        },
        () => {
          this.submitFilter(this.state.filter)
        }
      )
    }
  }

  exportExcel = async () => {
    let { filter } = this.state
    const { getXuatExcelThongKeHSCV, commonAddToasterMessage } =
      this.props.actions
    this.setState({ exporting: true })
    let loai = this.props.match.params.loai === 'phong-ban' ? PHONGBAN : DONVI
    let params = {}
    if (
      get(this.props.match, 'params.id') &&
      get(this.props.match, 'params.loai')
    ) {
      params = {
        id: get(filter, 'id'),
        start_date: filter.start_date,
        end_date: filter.end_date,
        loai,
      }
    } else {
      params = {
        start_date: filter.start_date,
        end_date: filter.end_date,
      }
    }

    try {
      await getXuatExcelThongKeHSCV(params).then(res => {
        if (res.error) {
          return commonAddToasterMessage({
            message: 'Không xuất được file.',
            intent: Intent.DANGER,
          })
        }
        let preData = Utils.getData('thongKeHoSoCongViec')
        FileDownload.saveAs(
          res.payload.data,
          `Thong-ke-HSCV-${moment(preData.start_date).format('L')}-${moment(
            preData.end_date
          ).format('L')}.xls`
        )
      })
    } catch (error) {
      return commonAddToasterMessage({
        message: DA_XAY_RA_LOI,
        intent: Intent.DANGER,
      })
    } finally {
      this.setState({
        exporting: false,
      })
    }
  }

  submitFilter = async (e = {}) => {
    const filter = Object.assign({}, this.state.filter, e)
    if (isEmpty(filter)) {
      return
    }

    const { getDanhSachThongKeHoSoCongViec, commonAddToasterMessage } =
      this.props.actions
    this.setState({ filter, isLoading: true })
    Utils.preData('thongKeHoSoCongViec', filter)
    try {
      await getDanhSachThongKeHoSoCongViec(filter).then(res => {
        if (
          res &&
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          res.payload.data.result.length !== 0
        ) {
          this.setState({
            isEmpty: false,
            data: res.payload.data.result,
          })
        } else {
          this.setState({
            isEmpty: true,
          })
        }
      })
    } catch (error) {
      this.setState(
        {
          isError: true,
        },
        () => {
          commonAddToasterMessage({
            message: DA_XAY_RA_LOI,
            intent: Intent.DANGER,
          })
        }
      )
    } finally {
      this.setState({
        isLoading: false,
      })
    }
  }

  goDSThongKe = data => {
    if (isEmpty(data)) {
      return
    }

    let loai = 'phong-ban'
    if (data.tenThongKe === 'DONVI') {
      loai = 'don-vi'
    } else if (data.tenThongKe === 'CANHAN') {
      return
    }

    this.props.history.push(
      `/tien-ich/thong-ke-ho-so-cong-viec/chi-tiet/${loai}/${data.id}`
    )
  }

  goDSTraLai = data => {
    if (isEmpty(data) || data.id === GUID_EMPTY) {
      return
    }

    let loai = 'ca-nhan'
    if (data.tenThongKe === 'DONVI') {
      loai = 'don-vi'
    } else if (data.tenThongKe === 'PHONGBAN') {
      loai = 'phong-ban'
    }

    this.props.history.push({
      pathname: `/tien-ich/thong-ke-ho-so-cong-viec/tra-lai/${loai}/${data.id}`,
      state: {
        filter: this.state.filter,
      },
    })
  }

  goBack = () => {
    this.props.history.goBack()
  }

  renderRow =
    () =>
    ({ item, number: index }) => {
      const isTotalRow = item.id === GUID_EMPTY && item.ten === TOTAL_NAME
      return (
        <tr
          className={classnames({
            'text-bold': isTotalRow,
          })}
        >
          <td className="cursor-default">
            {!isTotalRow && ('0' + index).slice(-2)}
          </td>
          <td
            className={classnames('cursor-default', {
              'over-time': !isTotalRow && get(item, 'tenThongKe') !== 'CANHAN',
            })}
            onClick={() => this.goDSThongKe(item)}
          >
            <span
              className={classnames({
                'hover-over-time':
                  !isTotalRow && get(item, 'tenThongKe') !== 'CANHAN',
              })}
            >
              {item.ten}
            </span>
          </td>
          <td className="text-center cursor-default">
            {get(item, 'tongSoHscvHoanThanh', '0')}
          </td>
          <td className="cursor-default text-center">
            {get(item, 'tongSoHscvDaLuu', '0')}
          </td>
          <td className="cursor-default text-center">
            <span>{get(item, 'tongSoHscvChuaLuu', '0')}</span>
          </td>
          <td className="cursor-default text-center">
            <span
              className={classnames({
                'over-time': !isTotalRow,
                'cursor-default': isTotalRow,
              })}
              onClick={() => this.goDSTraLai(item)}
            >
              {get(item, 'tongSoHscvTraLai', '0')}
            </span>
          </td>
          <td className="cursor-default text-center">
            {item.tyLeViPham && item.tyLeViPham !== 'NaN' ? item.tyLeViPham : 0}
          </td>
        </tr>
      )
    }

  render() {
    const thongKeHSCV = [
      {
        classNames: 'colum-stt-first',
        value: 'Stt',
      },
      {
        classNames: 'colum-default-width',
        value: 'Đơn vị/Phòng ban',
        valueCustom: 'name',
      },
      {
        classNames: 'colum-count-deparkment white-space-normal min-width-180',
        value: 'Tổng số HSCV được lập và kết thúc',
      },
      {
        classNames: 'colum-count-deparkment white-space-normal min-width-180',
        value: 'Tổng số hồ sơ giao nộp lưu trữ cơ quan',
      },
      {
        classNames: 'colum-count-deparkment white-space-normal min-width-140',
        value: 'Tổng số hồ sơ chưa giao nộp',
      },
      {
        classNames: 'colum-count-deparkment white-space-normal min-width-220',
        value: 'Tổng số hồ sơ lập sai quy định (bị trả lại)',
      },
      {
        classNames: 'colum-count-deparkment',
        value: 'Tỷ lệ % hồ sơ vi phạm',
      },
    ]
    const { match, actions } = this.props
    const { filter, isLoading, isEmpty, isError, data, exporting } = this.state
    let headerTitle = 'Đơn vị/Phòng ban'
    if (match.params.loai === 'phong-ban') {
      headerTitle = 'Cá nhân'
    }

    let isDisableInputDate = false
    if (get(match, 'params.id')) {
      isDisableInputDate = true
    }

    return (
      <MasterLayout typeSidebar="utilities">
        <div className="page-utilities-container">
          <HeadingPanel
            goBack={filter && filter.id ? this.goBack : null}
            leftTitle="Thống kê nộp lưu trữ HSCV"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading mb10"
                text="Xuất excel"
                onClick={this.exportExcel}
                disabled={isLoading || isEmpty}
                loading={exporting}
              />
            }
          />
          <div className="page-list-container page-list-GeneralDocumentary">
            <ListFilter
              match={match}
              actions={actions}
              onSubmit={this.submitFilter}
              filter={filter}
              isForChildUnit={isDisableInputDate}
            />
            <div className="table-container list-GeneralDocumentary">
              <GridView
                classNames="pt-table"
                isLoading={isLoading}
                isShowMessengStatistics={!filter.start_date}
                isEmpty={isEmpty}
                isError={isError}
                headers={thongKeHSCV}
                headerName={{ name: headerTitle }}
                page={1}
                data={data}
                itemShape={this.renderRow()}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(JobProject)
