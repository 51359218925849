import React from 'react'
import { Intent, Checkbox, Button } from '@blueprintjs/core'
import classnames from 'classnames'
import * as MESSAGER from '../../constants/MessageForm'

const PATH = '/he-thong/do-bao-mat/chinh-sua/:id'
class FormAddSecurityLevelCategory extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    doBaoMatId: '',
    tenDoBaoMat: '',
    maDoBaoMat: '',
    isActive: true,
    isLoading: false,
    error: {},
    path: null,
    stt: null,
    isOpenPopup: false,
    securities: null,
    disabledSelect: false,
    isLoadingData: {
      tenDoBaoMat: true,
      maDoBaoMat: true,
      isActive: true,
    },
  }

  handleChange(e) {
    if (e.target.type === 'text') {
      this.setState({ [e.target.name]: e.target.value })
    } else if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked })
    }
  }

  componentDidMount() {
    if (this.props.match.path !== PATH) {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          tenDoBaoMat: false,
          maDoBaoMat: false,
          isActive: false,
        },
      })
    }
  }

  componentWillReceiveProps = async nextProps => {
    if (nextProps.securities !== this.props.securities) {
      if (!nextProps.securities) {
        return
      }
      this.setState(
        {
          doBaoMatId: nextProps.securities.doBaoMatId || '',
          tenDoBaoMat: nextProps.securities.tenDoBaoMat || '',
          isActive: nextProps.securities.isActive ? true : false,
          maDoBaoMat: nextProps.securities.maDoBaoMat || '',
          securities: nextProps.securities,
          stt: nextProps.securities.stt,
        },
        () => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              tenDoBaoMat: false,
              maDoBaoMat: false,
              isActive: false,
            },
          })
        }
      )
    }

    if (
      nextProps.securities === this.props.securities &&
      this.props.match.path !== PATH
    ) {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          tenDoBaoMat: false,
          maDoBaoMat: false,
          isActive: false,
        },
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ error: {} })
    const error = {}

    if (this.state.maDoBaoMat.trim().length === 0) {
      error.maDoBaoMat = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.tenDoBaoMat.trim().length === 0) {
      error.tenDoBaoMat = MESSAGER.TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error, isLoading: true })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGER.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    this.setState({ isLoading: true })
    if (this.props.securities) {
      this.setState({ error: {} })
      this.props.actions
        .updateCommonFetchSecurityItem({
          do_bao_mat_id: this.state.doBaoMatId,
          ten_do_bao_mat: this.state.tenDoBaoMat,
          ma_do_bao_mat: this.state.maDoBaoMat,
          is_active: this.state.isActive,
          stt: this.state.stt,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    } else {
      this.setState({ error: {} })
      this.props.actions
        .postCommonFetchSecurity({
          ma_do_bao_mat: this.state.maDoBaoMat,
          ten_do_bao_mat: this.state.tenDoBaoMat,
          is_active: this.state.isActive,
        })
        .then(res => {
          if (
            res.payload &&
            res.payload.data.result &&
            res.payload.data.result === true
          ) {
            this.setState({ isLoading: false })
            this.props.onSubmit && this.props.onSubmit(true)
          } else {
            this.setState({ isLoading: false })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }
  }

  handleGoBack() {
    this.props.onSubmit && this.props.onSubmit()
  }

  render() {
    return (
      <div className="systerm-main-content">
        <div className="form-container form-inline-container systerm-form-container">
          <form className="main-form systerm-form">
            <div className="form-block">
              <div className="form-subblock">
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="maSo"
                      >
                        Mã độ bảo mật
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          onChange={this.handleChange}
                          value={this.state.maDoBaoMat}
                          name="maDoBaoMat"
                          autoFocus={true}
                          placeholder="Nhập mã độ bảo mật"
                        />
                        {this.state.error.maDoBaoMat !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.maDoBaoMat}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-cols form-col5">
                    <div className="form-group-item pt-form-group pt-intent-danger">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="moTa"
                      >
                        Tên độ bảo mật
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="input-form-inline pt-form-content">
                        <input
                          type="text"
                          className={classnames('pt-input', {
                            'loading-input':
                              Object.values(this.state.isLoadingData).find(
                                item => item === true
                              ) !== undefined,
                          })}
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          onChange={this.handleChange}
                          value={this.state.tenDoBaoMat}
                          name="tenDoBaoMat"
                          placeholder="Nhập tên độ bảo mật"
                        />
                        {this.state.error.tenDoBaoMat !== undefined && (
                          <div className="pt-form-content pt-form-helper-text">
                            {this.state.error.tenDoBaoMat}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-cols form-col5">
                    <div className="form-group-item form-group-inline">
                      <label
                        className="pt-label pt-label-inline"
                        htmlFor="sudung"
                      >
                        Sử dụng
                      </label>
                      <div className="input-form-inline">
                        <Checkbox
                          name="isActive"
                          disabled={
                            Object.values(this.state.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                          onChange={this.handleChange}
                          checked={this.state.isActive}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-block bottom-form">
              <div className="buttton-action-footer">
                <Button
                  type="button"
                  className="pt-button pt-button btn-cancel"
                  onClick={this.handleGoBack}
                  disabled={this.state.isLoading === true ? true : false}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  type="button"
                  className="pt-button pt-button btn-blue-color"
                  loading={this.state.isLoading}
                  disabled={
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined
                  }
                  onClick={this.handleSubmit}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default FormAddSecurityLevelCategory
