import React, { Component } from 'react'
import { STATISTIC_WORK_RESPONSIBILITY } from 'constants/Enum'
import { Badge } from 'components/newCommon'
const typeMenu = {
  QLCV_THONGBAO: 'QLCV_THONGBAO',
  QLCV_DUOCGIAO: 'QLCV_DUOCGIAO',
}

class SidebarStatistics extends Component {
  render() {
    const { item, statistics } = this.props
    switch (item.type) {
      case typeMenu.QLCV_DUOCGIAO:
        if (statistics && statistics.length !== 0) {
          let totalCount = statistics.filter(
            elm =>
              elm.responsibilityType ===
              STATISTIC_WORK_RESPONSIBILITY.ASSIGN_TO_ME
          )[0].totalCount
          return <Badge className="cpc-badge-sidebar" count={totalCount} />
        }

        return false
      default:
        return false
    }
  }
}
export default SidebarStatistics
