import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Radio, RadioGroup } from '@blueprintjs/core'
import { SidebarCabinet } from '../../components/DocumentCabinet'
import { Permission, Authorization } from '../auth'
import * as Tool from '../../helpers'
import { CABINET_DEPARTMENT, CABINET_PERSONAL } from '../../constants/Api'

class AddFileDocumentsCabinet extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    type: PropTypes.any.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFileInput = this.handleFileInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCheckCategory = this.handleCheckCategory.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    form: {
      fileNoiDung: undefined,
      dsNganTu: [],
      stt: '',
      soCongVan: '',
      ngayCongVan: '',
      coQuanBanHanh: '',
      ghiChu: '',
      tenTaiLieu: '',
      loaiNganTu: this.props.type || 1,
    },
    valueRadio: 'one',
    isOpen: false,
    dateToToString: '',
  }

  componentDidMount() {
    if (this.state.form.loaiNganTu) {
      this.props.onChangeType &&
        this.props.onChangeType(this.state.form.loaiNganTu)
    }
  }

  handleFileInput(e) {
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      this.setState({
        form: {
          ...this.state.form,
          fileNoiDung: file,
        },
      })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value,
        },
      },
      () => {
        if (name === 'loaiNganTu') {
          this.props.onChangeType && this.props.onChangeType(value)
        }
      }
    )
  }

  handleDateChange(name, e) {
    let dateToToString = ''
    if (e) {
      dateToToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      form: {
        ...this.state.form,
        [name]: e ? e : undefined,
      },
      dateToToString,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(value) {
    if (Tool.Utils.isValidDate(value)) {
      return
    }

    this.setState({
      dateToToString: '',
      form: {
        ...this.state.form,
        ngayCongVan: undefined,
      },
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    const form = {
      ...this.state.form,
      ngayCongVan: this.state.form.ngayCongVan
        ? moment(this.state.form.ngayCongVan).format('YYYY/MM/DD')
        : '',
    }
    this.props.onSubmit && this.props.onSubmit(form)
  }

  handleCheckCategory(data) {
    this.setState({
      form: {
        ...this.state.form,
        dsNganTu: data,
      },
    })
  }

  render() {
    let { type, typeName } = this.props

    switch (type) {
      case CABINET_PERSONAL:
        typeName = 'TTL CÁ NHÂN'
        break
      case CABINET_DEPARTMENT:
        typeName = 'TTL PHÒNG BAN'
        break
      default:
        typeName = 'TTL ĐƠN VỊ'
    }
    return (
      <div className="wrapper-box-container addDocumentsCabinet">
        <div className="container-box-addfile">
          <div className="box-action-detail">
            <div className="container-form-action">
              <div className="heading">
                <span className="click-go-back" onClick={this.props.goBack}>
                  <span className="pt-icon pt-icon-arrow-left pull-left"></span>
                </span>
                <h4 className="name-heading">Thêm file</h4>
              </div>
              <div className="row section-content">
                <div className="col-left-form form-container">
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Số thứ tự
                        </label>
                        <div className="pt-form-content">
                          <input
                            name="stt"
                            maxLength={5}
                            className="pt-input"
                            placeholder="Nhập số thứ tự"
                            type="text"
                            dir="auto"
                            value={this.state.form.stt}
                            onChange={this.handleInputChange}
                            autoFocus
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Số công văn
                          <span className="required-input">*</span>
                        </label>
                        <div className="pt-form-content">
                          <input
                            name="soCongVan"
                            className="pt-input"
                            placeholder="Nhập số công văn"
                            type="text"
                            dir="auto"
                            value={this.state.form.soCongVan}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Ngày công văn
                          <span className="required-input">*</span>
                        </label>
                        <div className="pt-form-content dateinput-inline">
                          <DatePicker
                            isClearable={
                              this.state.dateToToString ? true : false
                            }
                            readOnly={this.props.readOnly}
                            selected={this.state.form.ngayCongVan}
                            onChange={this.handleDateChange.bind(
                              this,
                              'ngayCongVan'
                            )}
                            value={this.state.dateToToString}
                            onChangeRaw={e =>
                              this.handleChangeRaw(
                                'dateToToString',
                                e.target.value
                              )
                            }
                            onBlur={e => this.focousOut(e.target.value)}
                            format="DD/MM/YYYY"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="input-datepicker input-icon-datepicker"
                            placeholderText="Chọn ngày..."
                            popperPlacement="auto"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: '5px, 10px',
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Cơ quan ban hành
                          <span className="required-input">*</span>
                        </label>
                        {/*<div className="pt-form-content">
                          <div className="pt-select">
                            <select>
                              <option selected>Choose an item...</option>
                              <option value="1">One</option>
                            </select>
                          </div>
                        </div>*/}
                        <div className="pt-form-content">
                          <input
                            name="coQuanBanHanh"
                            className="pt-input"
                            placeholder="Nhập cơ quan ban hành"
                            type="text"
                            dir="auto"
                            value={this.state.form.coQuanBanHanh}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div className="pt-form-group">
                    <div className="row item-row-input end-xs">
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input hide-label">
                          &nbsp;
                        </label>
                        <div className="pt-form-content">
                          <span className="note-input">Nên chọn từ danh sách để thống nhất đơn vị</span>
                          <input
                            name="coQuanBanHanh"
                            className="pt-input"
                            placeholder="Nhập cơ quan ban hành khác"
                            type="text"
                            dir="auto"
                            value={this.state.form.coQuanBanHanh}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>*/}
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-12 col-xs-12 col-sm-12 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Ghi chú
                        </label>
                        <div className="pt-form-content">
                          <textarea
                            name="ghiChu"
                            rows="4"
                            className="pt-textarea pt-fill"
                            placeholder="Nhập nội dung…"
                            dir="auto"
                            onChange={this.handleInputChange}
                            value={this.state.form.ghiChu}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-12 col-xs-12 col-sm-12 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Trích yếu
                          <span className="required-input">*</span>
                        </label>
                        <div className="pt-form-content">
                          <textarea
                            name="tenTaiLieu"
                            rows="3"
                            className="pt-textarea pt-fill text-abridgment"
                            placeholder="Nhập nội dung…"
                            dir="auto"
                            onChange={this.handleInputChange}
                            value={this.state.form.tenTaiLieu}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-form-group">
                    <div className="row item-row-input">
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input">
                          Thêm vào
                        </label>
                        <div className="pt-form-content">
                          <RadioGroup
                            className="radio-group"
                            name="loaiNganTu"
                            onChange={this.handleInputChange}
                            selectedValue={this.state.form.loaiNganTu}
                          >
                            <Radio
                              className="pt-large item-radio"
                              disabled={
                                !Authorization.vaild(
                                  this.props.auth.permission,
                                  [Permission.TUTAILIEUDONVI_QUANLY]
                                )
                              }
                              label="TTL ĐƠN VỊ"
                              value="1"
                            />
                            <Radio
                              className="pt-large item-radio"
                              disabled={
                                !Authorization.vaild(
                                  this.props.auth.permission,
                                  [Permission.TUTAILIEUPHONGBAN]
                                )
                              }
                              label="TTL PHÒNG BAN"
                              value="2"
                            />
                            <Radio
                              className="pt-large item-radio"
                              label="TTL CÁ NHÂN"
                              value="3"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12 col-sm-6 form-inline-group">
                        <label className="pt-label label-inline-input">
                          File nội dung
                          <span className="required-input">*</span>
                        </label>
                        <div className="pt-form-content">
                          <label className="pt-file-upload pt-input">
                            <input
                              type="file"
                              name="fileNoiDung"
                              onChange={this.handleFileInput}
                              accept={this.props.acceptMIMETypes}
                            />
                            <span className="pt-file-upload-input">
                              {this.state.form.fileNoiDung &&
                                this.state.form.fileNoiDung.name}
                              {!this.state.form.fileNoiDung &&
                                'Chọn file đính kèm'}
                            </span>
                          </label>
                          {this.props.uploadFileprogress > 0 && (
                            <div className="progress-upload-content">
                              <span className="percent-content">
                                {this.props.uploadFileprogress}%
                              </span>
                              <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                                <div
                                  className="pt-progress-meter"
                                  style={{
                                    width: this.props.uploadFileprogress + '%',
                                  }}
                                ></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-right-form document-cabinet-content">
                  <SidebarCabinet
                    mutilSelect
                    nameTab={typeName}
                    categories={this.props.categories}
                    onCheckCategory={this.handleCheckCategory}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="buttton-action-footer">
            <button
              className="pt-button btn-cancel"
              disabled={this.props.isSaving}
              onClick={this.props.goBack}
            >
              <span className=" pt-icon icon-back"></span>
              <span className="text-content">Quay lại</span>
            </button>
            <button
              className="pt-button btn-blue-color"
              disabled={this.props.isSaving}
              onClick={this.handleSubmit}
            >
              <span className="text-content">Lưu</span>
              <span className="pt-icon icon-save"></span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default AddFileDocumentsCabinet
