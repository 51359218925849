import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { PopupConfirm } from 'components/common'
import { TEXT_BUTTON_SUBMIT, TEXT_SUBMIT_BODY } from 'constants/Popup'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    disableAction: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isOpenPopup: false,
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })

    if (this.props.item && this.props.item.applicationId) {
      this.props.onHandleSubmitRemoveItem &&
        this.props.onHandleSubmitRemoveItem(this.props.item.applicationId)
    }
  }

  handleClick = () => {
    if (this.props.item && this.props.item.id && this.props.onClickDetail) {
      this.props.onClickDetail(this.props.item.id)
    }
  }

  render() {
    if (!this.props.item) {
      return null
    }
    const { number } = this.props
    return (
      <tr>
        <td style={{ cursor: 'default' }}>
          {number && number > 9 ? number : '0' + number}
        </td>
        <td style={{ cursor: 'default' }}>{this.props.item.name}</td>

        <td>{this.props.item.key}</td>
        <td style={{ cursor: 'default' }}>
          {this.props.item.creationTime
            ? moment(this.props.item.creationTime).format('DD/MM/YYYY')
            : ''}
        </td>
        <td>
          <Popover
            popoverClassName="pt-popover-content-sizing"
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            openOnTargetFocus={true}
            content={
              <div className="menu-popover">
                <Link
                  className="item"
                  to={`/he-thong/chia-se-api/ung-dung-chia-se/chinh-sua/${this.props.item.applicationId}`}
                >
                  <span className="pt-icon pt-icon-annotation"></span>
                  <span className="name_action">Chỉnh sửa</span>
                </Link>
                <span className="item" onClick={this.handleClickPopup}>
                  <span className="pt-icon pt-icon-trash"></span>
                  <span className="name_action">Xóa</span>
                </span>
              </div>
            }
          >
            <span className="icon-More"></span>
          </Popover>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title="Xóa ứng dụng"
            text={TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleSubmitRemoveItem}
          >
            {TEXT_SUBMIT_BODY}
          </PopupConfirm>
        </td>
      </tr>
    )
  }
}

export default ListItem
