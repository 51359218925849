import React, { Component } from 'react'
import DetailStatus from './DetailStatus'
import DetailStatusProject from './DetailStatusProject'
import Feedback from './Feedback'
import PropTypes from 'prop-types'
import * as Tool from '../../helpers'
import moment from 'moment'
import * as Types from '../../constants/Api'
import { LoadingItem } from '../../components/common/'

class UpdateHistoryDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      signNumber: null,
      signNumberProject: null,
      ctKySoTapTrung: null,
      feedbacks: null,
      time: null,
    }
  }

  static propTypes = {
    historyItem: PropTypes.object,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.historyItem) {
      this.setState({
        signNumber: nextProps.historyItem.signNumber,
        signNumberProject: nextProps.historyItem.signNumberProject,
        ctKySoTapTrung: nextProps.historyItem.ctKySoTapTrung,
        feedbacks: nextProps.historyItem.feedbacks,
        time: nextProps.historyItem.time,
      })
    }
  }

  render() {
    const dataDetail =
      this.state.signNumber ||
      this.state.signNumberProject ||
      this.state.ctKySoTapTrung
    const token =
      this.props.auth &&
      this.props.auth.token &&
      this.props.auth.token.accessToken
        ? this.props.auth.token.accessToken
        : ''
    const extension =
      dataDetail &&
      dataDetail.fileNoiDung &&
      dataDetail.fileNoiDung.tenFile &&
      dataDetail.fileNoiDung.tenFile
        .slice(
          ((dataDetail.fileNoiDung.tenFile.lastIndexOf('.') - 1) >>> 0) + 2
        )
        .toLowerCase()
    return this.props.isLoadingData &&
      Object.values(this.props.isLoadingData).find(item => item === true) !==
        undefined ? (
      <div className="box-loading-signnumber">
        <LoadingItem />
        <LoadingItem />
        <LoadingItem />
      </div>
    ) : (
      <div className="container-form-action detail-history-signnumber">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">
            {this.state.time &&
              `Lần chỉnh sửa ${moment(this.state.time).format(
                'DD/MM/YYYY HH:mm'
              )}`}
          </h4>
        </div>
        {dataDetail && dataDetail.fileNoiDung && (
          <div className="pt-form-group">
            <div className="row info-file">
              <label className="pt-label col-md-3 col-xs-12">
                <span className="label-text">File văn bản:</span>
              </label>
              <div className="pt-form-content col-md-9 col-xs-12">
                <div className="pt-form-content">
                  <span className="info">
                    {dataDetail.fileNoiDung.migrated && (
                      <a
                        style={{
                          backgroundImage: `url(${Tool.Utils.typeFile(
                            extension
                          )})`,
                        }}
                        className="link-file"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${Types.OLD_DOCUMENT_URL}${dataDetail.fileNoiDung.url}&BearerToken=${token}`}
                      >
                        {dataDetail.fileNoiDung.tenFile} -{' '}
                        {Tool.Utils.getFileSize(
                          dataDetail.fileNoiDung.kichThuoc
                        )}
                      </a>
                    )}
                    {!dataDetail.fileNoiDung.migrated && (
                      <a
                        style={{
                          backgroundImage: `url(${Tool.Utils.typeFile(
                            extension
                          )})`,
                        }}
                        className="link-file"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${Types.FILE_URL}/api/file/get/${dataDetail.fileNoiDung.fileId}?BearerToken=${token}`}
                      >
                        {dataDetail.fileNoiDung.tenFile} -{' '}
                        {Tool.Utils.getFileSize(
                          dataDetail.fileNoiDung.kichThuoc
                        )}
                      </a>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="form-container">
          {dataDetail && this.props.type !== 'signNumberProject' && (
            <DetailStatus item={dataDetail} auth={this.props.auth} />
          )}
          {dataDetail && this.props.type === 'signNumberProject' && (
            <DetailStatusProject
              item={dataDetail}
              auth={this.props.auth}
              isKstt={this.props.isKstt}
            />
          )}
          {this.state.feedbacks && this.state.feedbacks.length > 0 && (
            <Feedback
              title="Góp ý/Ghi chú"
              list={this.state.feedbacks}
              isCanUpdate={false}
              feedbackId={null}
            />
          )}
        </div>
      </div>
    )
  }
}

export default UpdateHistoryDetail
