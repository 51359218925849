import React, { memo, Fragment, useRef, useState, useEffect } from 'react'
import { InputGroup, Intent, Button, TextArea } from '@blueprintjs/core'
import { get, isEmpty } from 'lodash'
import csx from 'classnames'

const Input = ({
  className,
  clearable = false,
  loading = false,
  onChange: inputChange = () => {},
  onClear: inputClear = () => {},
  onSend: inputSend,
  value: inputValue = '',
  showSendButton = false,
  sendButtonText = 'Lưu',
  sendButtonClassName = '',
  rightElement,
  leftIcon,
  allowEmptyEnter = true,
  type = 'text',
  styleInput = null,
  ...props
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [text, _setText] = useState('')

  const onChange = e => {
    const { value } = e.target
    _mountedSet(_setText, value)
    inputChange(value)
  }

  const onKeyPress = e => {
    const { key } = e
    if (inputSend && key === 'Enter' && (allowEmptyEnter || text)) {
      inputSend(text)
    }
  }

  const onClear = e => {
    e.stopPropagation()
    _mountedSet(_setText, '')
    inputClear('')
  }

  const inputProps = {
    leftIconName: leftIcon
      ? csx(` ${leftIcon}`, 'absolute-left-icon')
      : props.leftIconName || null,
    style: styleInput,
    rightElement: (
      <Fragment>
        {clearable && !props.disabled && !isEmpty(text) ? (
          <i
            className={csx('icon-close', 'has-event', 'size-icon-14', 'mr10')}
            onClick={onClear}
          />
        ) : null}
        {rightElement}
        {showSendButton && (
          <Button
            className={csx(
              'cpc-button',
              'text-uppercase',
              'font-size-13',
              sendButtonClassName
            )}
            intent={Intent.PRIMARY}
            onClick={() => inputSend(text)}
            loading={loading}
            disabled={!text.trim()}
          >
            {sendButtonText}
          </Button>
        )}
      </Fragment>
    ),
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(_setText, inputValue)
  }, [inputValue])

  const Element = {
    text: InputGroup,
    textarea: TextArea,
  }[type]

  return (
    <Element
      {...props}
      {...(type === 'text' ? inputProps : {})}
      className={csx('cpc-input', className, {
        'cpc-input-icon': leftIcon,
      })}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={text}
    />
  )
}

export default memo(Input)
