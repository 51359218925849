import React, { Component } from 'react'
import { Checkbox, Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'

class ListFilter extends Component {
  static propTypes = {
    staffs: PropTypes.array.isRequired,
    status: PropTypes.array.isRequired,
    onSubmit: PropTypes.func,
    onClickNeedOpinion: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  state = {
    dateUpdate: moment(),
    isOpen: false,
    filter: {
      textSearch: '',
      ngayLap: {
        from: undefined,
        to: undefined,
      },
    },
  }

  componentWillReceiveProps(props) {
    if (props.filter) {
      this.setState({
        filter: {
          textSearch: props.filter.textSearch || '',
          ngayLap: {
            from:
              props.filter.ngayLap &&
              props.filter.ngayLap.from &&
              props.filter.ngayLap.from.trim().length > 0
                ? moment(props.filter.ngayLap.from)
                : undefined,
            to:
              props.filter.ngayLap &&
              props.filter.ngayLap.to &&
              props.filter.ngayLap.to.trim().length > 0
                ? moment(props.filter.ngayLap.to)
                : undefined,
          },
        },
      })
    }
  }

  clearFilter(e) {
    e.preventDefault()
    this.setState(
      {
        dateUpdate: moment(),
        filter: {
          textSearch: '',
          ngayLap: {
            from: undefined,
            to: undefined,
          },
        },
      },
      () => {
        const filter = {
          ...this.state.filter,
          ngayLap: {
            from: this.state.filter.ngayLap.from
              ? moment(this.state.filter.ngayLap.from).format('YYYY-MM-DD')
              : '',
            to: this.state.filter.ngayLap.to
              ? moment(this.state.filter.ngayLap.to).format('YYYY-MM-DD')
              : '',
          },
        }
        if (this.props.type === 'signNumberProject') {
          this.props.onSubmit && this.props.onSubmit(filter, 'reset')
        } else {
          this.props.clearFilterColumn &&
            this.props.clearFilterColumn(filter, 'reset')
        }
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? e : undefined,
          },
        },
      },
      () => {
        this.handleSubmit()
      }
    )
  }

  handleInputChange(e) {
    const value = e.target.value
    const name = e.target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayLap: {
        from: this.state.filter.ngayLap.from
          ? moment(this.state.filter.ngayLap.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayLap.to
          ? moment(this.state.filter.ngayLap.to).format('YYYY-MM-DD')
          : '',
      },
    }
    if (this.props.type === 'signNumberProject') {
      this.props.onSubmit && this.props.onSubmit(filter)
    } else {
      this.props.clearFilterColumn && this.props.clearFilterColumn(filter)
    }
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
    this.props.removeTextSearch && this.props.removeTextSearch('textSearch')
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch pt15 pb20 px-sm-15"
      >
        <div className="row">
          <div className="col-xs-12">
            <Checkbox
              className="pt-inline name-radio head-check-content mb15"
              name="canKySo"
              checked={this.props.isNeedOpinion}
              onChange={this.props.onClickNeedOpinion}
            >
              <span className="name_checkbox">Văn bản chờ lấy ý kiến</span>
            </Checkbox>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="row-picker-range">
              <div className="col-picker-range">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayLap.from}
                  selectsStart
                  startDate={this.state.filter.ngayLap.from}
                  endDate={this.state.filter.ngayLap.to}
                  onChange={this.handleDateChange.bind(this, 'ngayLap', 'from')}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Từ ngày"
                  maxDate={this.state.filter.ngayLap.to}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
              <div className="col-picker-range">
                <DatePicker
                  readOnly
                  isClearable
                  selected={this.state.filter.ngayLap.to}
                  selectsEnd
                  startDate={this.state.filter.ngayLap.from}
                  endDate={this.state.filter.ngayLap.to}
                  onChange={this.handleDateChange.bind(this, 'ngayLap', 'to')}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3 "
                  placeholderText="Đến ngày"
                  minDate={this.state.filter.ngayLap.from}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-8 mt20 mt-md-0 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                name="textSearch"
                className="input border-transparent pr-40 input-search-filter"
                type="text"
                onChange={this.handleInputChange}
                placeholder={`Tìm kiếm số ký hiệu, Trích yếu`}
                value={
                  this.state.filter.textSearch
                    ? this.state.filter.textSearch
                    : ''
                }
                maxLength={255}
              />
              {this.state.filter.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'textSearch')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

export default ListFilter
