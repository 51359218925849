import { Checkbox } from '@blueprintjs/core'
import classnames from 'classnames'
import { includes, isEmpty, isEqual } from 'lodash'
import React, { Component } from 'react'

export default class RowTableCollapse extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isShow: false,
      isCheckAll: false,
      listCheckedId: [],
    }

    this.handleCollapse = this.handleCollapse.bind(this)
    this.handlleCheckAll = this.handlleCheckAll.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.listApiId) {
      const listApplicationItemId = nextProps.data?.shareApis?.map(
        item => item?.shareApiId
      )
      const listAppIdRecive = nextProps?.listApiId?.filter(item =>
        includes(listApplicationItemId, item)
      )
      this.setState({
        isCheckAll: isEqual(
          listApplicationItemId.sort(),
          listAppIdRecive.sort()
        ),
      })
    }
  }

  handleCollapse(id) {
    const toggleItem = document.getElementById(id)
    if (toggleItem) {
      if (!this?.state?.isShow) {
        toggleItem.nextSibling.classList.add('show')
        this.setState({
          isShow: true,
        })
      } else {
        toggleItem.nextSibling.classList.remove('show')
        this.setState({
          isShow: false,
        })
      }
    }
  }

  showMethodValue(name) {
    switch (name) {
      case 0:
        return 'GET'
      case 1:
        return 'POST'
      case 2:
        return 'PUT'
      case 3:
        return 'DELETE'
      case 4:
        return 'PATCH'
      default:
        return ''
    }
  }

  handlleCheckAll(e) {
    const listId = this.props?.data?.shareApis?.map(item => item?.shareApiId)
    this.props.handleCheckListAll(e, listId)
    this.setState({
      isCheckAll: e.target.checked,
    })
  }

  render() {
    const id = this?.props?.id
    return (
      <React.Fragment>
        <tr
          className="accordion-toggle"
          id={id}
          onClick={() => this.handleCollapse(id)}
        >
          <td className="text-bold">{this?.props?.number}</td>
          <td colSpan="5" className="text-bold uppercase">
            <div className="d-flex align-center">
              {this?.props?.data?.name || ''}
              <i
                className={classnames('icon icon-arrow-dropdown', {
                  show: this?.state?.isShow,
                })}
              />
            </div>
          </td>
          <td className="center">
            <Checkbox
              className="checkbox-inline"
              checked={this?.state?.isCheckAll}
              onChange={this.handlleCheckAll}
            />
          </td>
        </tr>
        {/* Child Item */}
        <tbody className="custom-collapse">
          {this?.props?.data?.shareApis?.map((item, index) => {
            return (
              <tr key={item?.shareApiId}>
                <td colSpan={2} style={{ textAlign: 'center' }}>
                  {this?.props?.number}.{index + 1}
                </td>
                <td>{item?.description}</td>
                <td>
                  <p className="word-break-word">{item?.secuityKey}</p>
                </td>
                <td>{item?.method}</td>
                <td>
                  <p className="word-break-word">{item?.endpoint}</p>
                </td>
                <td className="center">
                  <Checkbox
                    className="checkbox-inline"
                    checked={
                      !isEmpty(this?.props?.listApiId) &&
                      includes(this?.props?.listApiId, item?.shareApiId)
                    }
                    onChange={e =>
                      this?.props?.onCheckList(e, item?.shareApiId)
                    }
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </React.Fragment>
    )
  }
}
