import React, { useState, useEffect } from 'react'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import ReactDaterangePicker from 'react-daterange-picker'
import classnames from 'classnames'
import { get } from 'lodash'
import originalMoment from 'moment'
import { extendMoment } from 'moment-range'

import { Input } from 'components/newCommon'

const moment = extendMoment(originalMoment)
const dateFormat = 'DD/MM/YYYY'

const getTextDate = date => {
  if (!moment(date).isValid()) {
    return ''
  }
  return moment(date).format(dateFormat)
}

const DateRangePicker = ({
  disabled = false,
  placeholder = 'Chọn thời gian',
  value,
  classNameRangePicker = '',
  inputWrapperClassName = '',
  onChange: dateChange = () => {},
  clearable = true,
}) => {
  const [isOpen, _setIsOpen] = useState(false)
  const [range, setRange] = useState(null)

  const [dateText, setDateText] = useState('')

  const _toggleOpen = newStatus => {
    _setIsOpen(newStatus)
  }

  const onSelect = rangeDate => {
    setRange(rangeDate)
    dateChange(rangeDate)
    _toggleOpen(false)
  }

  useEffect(() => {
    setRange(value)
  }, [value])

  useEffect(() => {
    if (!range) {
      return setDateText('')
    }
    return setDateText(
      `${getTextDate(get(range, 'start'))} - ${getTextDate(get(range, 'end'))}`
    )
  }, [range])

  return (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      isOpen={isOpen}
      onClose={() => _toggleOpen(false)}
      popoverClassName="cpc-popover no-arrow mt5 ph20 pv10"
      position={Position.BOTTOM_LEFT}
      rootElementTag="div"
      content={
        <ReactDaterangePicker
          className={classnames('cpc-datepicker-range', classNameRangePicker)}
          singleDateRange={true}
          value={range}
          onSelect={onSelect}
        />
      }
    >
      <Input
        className={inputWrapperClassName}
        clearable={clearable}
        disabled={disabled}
        onClear={() => onSelect(null)}
        onClick={() => _toggleOpen(!isOpen)}
        readOnly
        placeholder={placeholder}
        value={dateText}
        leftIcon="icon2-date-frames"
      />
    </Popover>
  )
}

export default DateRangePicker
