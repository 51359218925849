import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AssignmentHistoryItem from './AssignmentHistoryItem'
// import { NotFound } from '../common';
import classnames from 'classnames'

class AssignmentHistory extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    work: PropTypes.object.isRequired,
    assignmentHistory: PropTypes.array.isRequired,
  }
  render() {
    return (
      <div className="container-form-action">
        {this.props.assignmentHistory.length > 0 && (
          <div
            className={classnames(
              'history-steering-box content-AssignmentHistory mt0',
              {
                'history-steering-showmobile': this.props.isShowMobile,
              }
            )}
          >
            {!this.props.isShowMobile ? (
              <div className="heading noborder-bottom">
                <h3 className="name-heading">
                  <span className="icon-Thong_Tin_Giao_Viec"></span>Thông tin
                  chỉ đạo
                </h3>
              </div>
            ) : (
              <div className="heading">
                <span
                  className="click-go-back"
                  onClick={this.props.closeModulesDetail}
                >
                  <span className="pt-icon pt-icon-arrow-left pull-left"></span>
                </span>
                <h4 className="name-heading">Thông tin giao việc</h4>
              </div>
            )}
            {this.props.assignmentHistory.map((item, i) => (
              <AssignmentHistoryItem key={i} AssignmentHistoryItem={item} />
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default AssignmentHistory
