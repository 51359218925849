import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
// import _ from 'lodash';
// import * as Types from '../../constants/Api';

class DetailStatusProject extends Component {
  static propTypes = {
    item: PropTypes.object,
  }

  render() {
    if (
      !this.props.item ||
      !this.props.item.dsThanhVien ||
      this.props.item.dsThanhVien.length < 1
    ) {
      return null
    }

    const roleId = this.props.auth && this.props.auth.roleId
    if (!roleId) {
      return null
    }

    let dsThanhVien = []
    dsThanhVien = this.props.item.dsThanhVien
    // tạm thời đóng lại chức năng xem các bước
    // if (
    //   (roleId &&
    //   this.props.item.nguoiLap &&
    //   this.props.item.nguoiLap.chucDanhId === roleId) ||
    //   (this.props.item && this.props.item.tinhTrang === Types.PASSED)
    // ) {
    //   // Nếu là người lập thì được xem tất cả
    //   dsThanhVien = this.props.item.dsThanhVien;
    // } else {
    //   // Người ký chỉ được xem thành viên bước đã ký và trạng thái của mình ở bước đang ký
    //   const { buoc } = this.props.item;
    //   if (_.isNumber(buoc)) {
    //     dsThanhVien = this.props.item.dsThanhVien.filter(
    //       s => s.buoc < buoc ||
    //       (s.buoc === buoc && s.chucDanhId === roleId)
    //     );
    //   }
    // }

    if (dsThanhVien.length < 1) {
      return null
    }

    const { isKstt } = this.props

    return (
      <div className="detail-status-box">
        <div className="table-status table-scroll table-scroll-hidden">
          <div className="heading-table">
            <div className="cell-table colum-stt">
              <p className="name-heading-table">STT</p>
            </div>
            <div className="cell-table column-department">
              <p className="name-heading-table">
                {isKstt ? 'Thành phần ký duyệt' : 'Cá nhân'}
              </p>
            </div>
            <div className="cell-table colum-status">
              <p className="name-heading-table">Trạng Thái</p>
            </div>
            {this.props.item && this.props.item.tinhTrang > 0 && (
              <div className="cell-table colum-status">
                <p className="name-heading-table">Thời gian ký duyệt</p>
              </div>
            )}
          </div>

          {dsThanhVien &&
            dsThanhVien.map((thanhvien, i) => (
              <div className="row-table" key={i}>
                <div className="cell-table">
                  <p className="content">
                    {i + 1 < 10 ? '0' + (i + 1) : i + 1}
                  </p>
                </div>
                <div className="cell-table column-department">
                  {isKstt && !thanhvien.maPhongBan && (
                    <p className="content department-name">
                      {thanhvien.tenDonViDtxd}
                    </p>
                  )}
                  {isKstt && (
                    <p className="content department-name">
                      {thanhvien.maPhongBan}
                    </p>
                  )}
                  <p className="content">{thanhvien.tenNhanVien}</p>
                </div>

                <div className="cell-table">
                  {thanhvien.trangThai === 0 && (
                    <span className="status-job no-process">Chưa đồng ý</span>
                  )}
                  {thanhvien.trangThai === 1 && (
                    <span className="status-job no-approval">Không đồng ý</span>
                  )}
                  {thanhvien.trangThai === 2 && (
                    <span className="status-job approved">Đồng ý</span>
                  )}
                  {thanhvien.trangThai === 3 && (
                    <span className="status-job reversed">Bảo lưu</span>
                  )}
                </div>
                {this.props.item && this.props.item.tinhTrang > 0 && (
                  <div className="cell-table">
                    {thanhvien && thanhvien.thoiGianDuyet ? (
                      <p className="content">
                        {moment(thanhvien.thoiGianDuyet).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </p>
                    ) : (
                      <p className="content">&nbsp;</p>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    )
  }
}

DetailStatusProject.defaultProps = {
  isKstt: false,
}

export default DetailStatusProject
