import React from 'react'
import classNames from 'classnames'
import { Button } from '@blueprintjs/core'

const Index = ({ name = '', icon = '', history, isGoBack = false }) => {
  const onGoBack = () => {
    if (!history) {
      return
    }

    return history.goBack()
  }

  return (
    <div className="cpc-title">
      {isGoBack && (
        <Button className={'cpc-button default-back'} onClick={onGoBack}>
          <span className="icon2-arrow-left mr5 font-size-10"></span>
          <span>Quay lại</span>
        </Button>
      )}
      <div className="title">
        {icon ? <span className={classNames('icon', icon)}></span> : ''}
        {name ? <h1 className="name">{name}</h1> : ''}
      </div>
    </div>
  )
}

export default Index
