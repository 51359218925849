import { useMemo, useState, useEffect, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

import { Utils } from 'helpers'
import requestAction from 'helpers/request'
import { getMeetings, getLanhDaoChuTri } from 'actions/task'

const useTaskThongbaoKLCH = ({ mainUnitId }) => {
  const filterKeys = {
    title: 'title',
    textSearch: 'textSearch',
    fromExecutionDate: 'fromExecutionDate',
    toExecutionDate: 'toExecutionDate',
    fromDate: 'fromDate',
    toDate: 'toDate',
    meetingType: 'meetingType',
    textNumber: 'textNumber',
    mainResponsibilityId: 'mainResponsibilityId',
  }

  const filterKeysMemo = useMemo(() => {
    return {
      title: filterKeys.title,
      textSearch: filterKeys.textSearch,
      fromExecutionDate: filterKeys.fromExecutionDate,
      toExecutionDate: filterKeys.toExecutionDate,
      fromDate: filterKeys.fromDate,
      toDate: filterKeys.toDate,
      meetingType: filterKeys.meetingType,
      textNumber: filterKeys.textNumber,
      mainResponsibilityId: filterKeys.mainResponsibilityId,
    }
  }, [
    filterKeys.title,
    filterKeys.textSearch,
    filterKeys.fromExecutionDate,
    filterKeys.toExecutionDate,
    filterKeys.fromDate,
    filterKeys.toDate,
    filterKeys.meetingType,
    filterKeys.textNumber,
    filterKeys.mainResponsibilityId,
  ])

  const [filter, setFilter] = useState()
  const [isFetching, setIsFetching] = useState(true)
  const [isFetchingFail, setIsFetchingFail] = useState(false)
  const [page, setPage] = useState(1)
  const [thongBaoKLCHData, setThongBaoKLCHData] = useState(null)
  const [deleteData, setDeleteData] = useState(null)

  const fetchList = useCallback((page = 1, params = null) => {
    const filterStoreData = Utils.preData('meetingStore')
    let newFilter = null
    if (!isEmpty(filterStoreData) && !params) {
      newFilter = filterStoreData
    } else {
      newFilter = {
        ...params,
        textSearch: isString(params?.textSearch)
          ? params?.textSearch?.trim()
          : params?.textSearch,
        textNumber: isString(params?.textNumber)
          ? params?.textNumber?.trim()
          : params?.textNumber,
        title: isString(params?.title) ? params?.title?.trim() : params?.title,
        page: page,
      }
    }

    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      beforeAction: () => {
        setIsFetchingFail(false)
        setIsFetching(true)
      },
      action: () =>
        getMeetings({
          ...newFilter,
          limit: 10,
        }),
      afterResponse: response => {
        setPage(newFilter?.page || 1)
        Utils.preData('meetingStore', newFilter)
        setFilter(newFilter)
        setThongBaoKLCHData(response)
      },
      afterAction: () => {
        setIsFetching(false)
      },
      afterError: () => setIsFetchingFail(true),
    })
  }, [])

  const onChangeFilter = useCallback(
    ({ name, isReFetch = true, isMultipleKey = false }) =>
      value => {
        let newFilter = { ...filter }
        if (name === 'RESET') {
          newFilter = {}
        } else if (name === 'PAGE') {
          return fetchList(value, newFilter)
        } else if (isMultipleKey) {
          newFilter = { ...newFilter, ...value }
        } else {
          newFilter = {
            ...newFilter,
            [name]: value,
          }
        }

        if (isReFetch) {
          fetchList(1, newFilter)
        }
      },
    [filter, fetchList]
  )

  useEffect(() => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getLanhDaoChuTri(mainUnitId),
    })
  }, [mainUnitId])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  return {
    filterKeys: filterKeysMemo,
    filter,
    isFetching,
    isFetchingFail,
    thongBaoKLCHData,
    page,
    deleteData,
    setDeleteData,
    onChangeFilter,
  }
}

export default useTaskThongbaoKLCH
