import React from 'react'
import { PopupConfirm } from '../../components/common'
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import { Checkbox } from '@blueprintjs/core'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import { Link } from 'react-router-dom'
import { Intent } from '@blueprintjs/core'
import * as MESSAGER from '../../constants/MessageForm'
import { HeadingTableSort } from '../../components/common'
import { headers } from '../../constants/Table'
import { MESSAGE_NOT_FOUND, MESSAGE_ERROR } from '../../constants/MessageForm'

const LINK = '/he-thong/so-luu/chinh-sua'
const SO_DEN = 1
const SODEN = 'Sổ đến'
const SODI = 'Sổ đi'
class SaveNoteCategory extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
  }

  state = {
    isOpenPopup: false,
    isEnabled: false,
    items: [],
    soLuuId: null,
    isActive: undefined,
    maSoLuu: null,
    loai: null,
    tenSoLuu: null,
    stt: null,
    soCuoi: null,
    isMove: false,
    isEmpty: false,
    donViId: null,
    isError: false,
  }

  handleClickPopup = id => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
      soLuuId: id,
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.setState(
        {
          items: arrayMove(this.state.items, oldIndex, newIndex),
          isMove: true,
        },
        () => {
          const array = []
          this.state.items.map(item => array.push(item.soLuuId))
          if (array.length > 0 && this.state.isMove === true) {
            this.props.actions
              .updateArrayCommonFetchSaveNote(array)
              .then(res => {
                if (
                  res.payload &&
                  res.payload.data.result &&
                  res.payload.data.result === true
                ) {
                  this.props.actions
                    .commonFetchSaveCodes(1, this.props.donViId, true, null)
                    .then(res => {
                      if (
                        res.error ||
                        (res.payload && res.payload.status !== 200)
                      ) {
                        this.setState({ isError: true })
                      }
                    })
                  this.setState({ isMove: false })
                  return this.props.actions.commonAddToasterMessage({
                    message: MESSAGER.TOATS_MESSAGE_SUCCESS,
                    intent: Intent.SUCCESS,
                  })
                } else {
                  this.setState({
                    isMove: false,
                    items: arrayMove(this.state.items, newIndex, oldIndex),
                  })
                  return this.props.actions.commonAddToasterMessage({
                    message: MESSAGER.TOATS_MESSAGE_FAIL,
                    intent: Intent.DANGER,
                  })
                }
              })
          }
        }
      )
    }
  }

  handleEnabledChange = (value, e) => {
    this.setState({ isEnabled: false })
    if (e.target.type === 'checkbox') {
      this.setState({
        isActive: e.target.checked,
        soLuuId: value.soLuuId,
        maSoLuu: value.maSoLuu,
        tenSoLuu: value.tenSoLuu,
        soCuoi: value.soCuoi,
        donViId: value.donViId,
        loai: value.loai,
        isEnabled: true,
        stt: value.stt,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isActive !== this.state.isActive ||
      prevState.soLuuId !== this.state.soLuuId
    ) {
      if (this.state.isEnabled === true) {
        this.props.actions
          .updateCommonFetchSaveCodes({
            so_luu_id: this.state.soLuuId,
            ma_so_luu: this.state.maSoLuu,
            ten_so_luu: this.state.tenSoLuu,
            so_cuoi: this.state.soCuoi,
            don_vi_id: this.state.donViId,
            loai: this.state.loai,
            is_active: this.state.isActive,
            stt: this.state.stt,
          })
          .then(res => {
            if (
              res.payload &&
              res.payload.data.result &&
              res.payload.data.result === true
            ) {
              this.props.actions
                .commonFetchSaveCodes(1, this.props.donViId, true, null)
                .then(res => {
                  if (
                    res.error ||
                    (res.payload && res.payload.status !== 200)
                  ) {
                    this.setState({ isError: true })
                  }
                })
              return this.props.actions.commonAddToasterMessage({
                message: MESSAGER.TOATS_MESSAGE_SUCCESS,
                intent: Intent.SUCCESS,
              })
            } else {
              this.props.actions.commonAddToasterMessage({
                message: MESSAGER.TOATS_MESSAGE_FAIL,
                intent: Intent.DANGER,
              })
            }
          })
      }
    }
  }

  handleSubmitRemoveItem = () => {
    if (this.state.soLuuId) {
      this.props.actions
        .deleteCommonFetchSaveCodeItem(this.state.soLuuId)
        .then(res => {
          if (res.payload && res.payload.data.result === true) {
            this.props.actions
              .commonFetchSaveCodes(1, this.props.donViId, true, null)
              .then(res => {
                if (res.error || (res.payload && res.payload.status !== 200)) {
                  this.setState({ isError: true })
                }
              })
            return this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_DELETE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          } else {
            this.props.actions.commonAddToasterMessage({
              message: MESSAGER.TOATS_MESSAGE_DELETE_FAIL,
              intent: Intent.DANGER,
            })
          }
        })
    }

    this.setState({
      isOpenPopup: false,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setState({
        items: nextProps.items,
      })
      if (nextProps.items.length === 0) {
        this.setState({
          isEmpty: true,
        })
      }
      if (nextProps.items.length > 0) {
        this.setState({
          isEmpty: false,
        })
      }
    }
  }

  render() {
    let ItemLoading = (
      <div className="loading-content">
        <span className="shape1"></span>
        <span className="shape2"></span>
        <span className="shape3"></span>
        <span className="shape4"></span>
        <span className="shape5"></span>
        <span className="shape6"></span>
        <span className="shape7"></span>
        <span className="shape8"></span>
        <span className="shape9"></span>
        <span className="shape10"></span>
        <span className="shape11"></span>
        <span className="shape12"></span>
        <span className="shape13"></span>
      </div>
    )
    //icon move
    const DragHandle = SortableHandle(() => (
      <span className="icon-move-drap"></span>
    ))

    //list foreach
    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className="sortableList">
          {items.map((value, index) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                value={value}
                i={index + 1}
              />
            )
          })}
        </div>
      )
    })

    //items move
    const SortableItem = SortableElement(({ i, value }) => {
      return (
        <div className="table-rows">
          <div className="table-cols-content width-col-5">
            <DragHandle />
          </div>
          <div className="table-cols-content width-col-5">
            <span className="order-content">{i < 10 ? '0' + i : i}</span>
          </div>
          <div className="table-cols-content width-col-20">
            <span className="order-content">{value.maSoLuu}</span>
          </div>
          <div className="table-cols-content width-col-30">
            <span className="text-content">{value.tenSoLuu}</span>
          </div>
          <div className="table-cols-content width-col-20">
            <span className="text-content">
              {value.loai === SO_DEN ? SODEN : SODI}
            </span>
          </div>
          <div className="table-cols-content width-col-15">
            <span className="text-content">
              <Checkbox
                className="checkbox-item"
                onChange={this.handleEnabledChange.bind(this, value)}
                defaultChecked={value.isActive}
              />
            </span>
          </div>
          <div className="table-cols-content width-col-5">
            <Popover
              popoverClassName="pt-popover-content-sizing"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  <Link className="item" to={`${LINK}/${value.soLuuId}`}>
                    <span className="pt-icon pt-icon-annotation"></span>
                    <span className="name_action">Chỉnh sửa</span>
                  </Link>
                  <span
                    className="item"
                    onClick={this.handleClickPopup.bind(this, value.soLuuId)}
                  >
                    <span className="pt-icon pt-icon-trash"></span>
                    <span className="name_action">Xóa</span>
                  </span>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
          </div>
        </div>
      )
    })

    return (
      <div className="page-list-container page-list-role-container">
        <div className="table-container table-subcollap-container">
          <div className="table-scroll">
            <div className="collapse-panel-content">
              <div className="table-display">
                <div className="table-display-container">
                  {this.props.items.length > 0 &&
                    !this.props.isError &&
                    !this.state.isError && (
                      <HeadingTableSort data={headers.regencyCategory} />
                    )}
                  <div className="table-body-container">
                    {this.props.isLoading ? (
                      <div className="table-loading-system">
                        {ItemLoading}
                        {ItemLoading}
                        {ItemLoading}
                      </div>
                    ) : (
                      !this.props.isError &&
                      !this.state.isError && (
                        <SortableList
                          helperClass="SortableHelper"
                          items={this.state.items}
                          onSortEnd={this.onSortEnd}
                          useDragHandle={true}
                          lockAxis="y"
                        />
                      )
                    )}
                    {this.state.isEmpty &&
                      !this.props.isLoading &&
                      (!this.props.isError || !this.state.isError) && (
                        <div className="error-page-container">
                          <div className="error-content">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/images/search-result-not-found.png'
                              }
                              alt="search-result-not-found"
                              className="img-thumb-error"
                            />
                            <p className="description-note">
                              {MESSAGE_NOT_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    {(this.props.isError || this.state.isError) &&
                      !this.props.isLoading && (
                        <div className="error-page-container">
                          <div className="error-content">
                            <img
                              src={
                                process.env.PUBLIC_URL + '/images/Server.png'
                              }
                              alt="error"
                              className="img-thumb-error"
                            />
                            <p className="description-note">{MESSAGE_ERROR}</p>
                          </div>
                        </div>
                      )}
                    {/* <ListFooter /> */}
                    <PopupConfirm
                      isOpen={this.state.isOpenPopup}
                      onClose={this.handleClickPopup}
                      title={POPUP.XOA_DANH_MUC}
                      text={POPUP.TEXT_BUTTON_SUBMIT}
                      onSubmit={this.handleSubmitRemoveItem}
                    >
                      {POPUP.TEXT_SUBMIT_BODY}
                    </PopupConfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SaveNoteCategory
