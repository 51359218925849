import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'

class ListFilter extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  state = {
    filter: {
      textSearch: '',
    },
  }

  componentWillReceiveProps(props) {
    if (props.textSearch) {
      this.setState({
        filter: props.filter.textSearch || '',
      })
    }
  }

  clearFilter(e) {
    this.setState(
      {
        filter: {
          textSearch: '',
        },
      },
      () => {
        this.handleSubmit()
        this.props.actions.getNumberWorkPage()
      }
    )
  }

  handleInputChange(e) {
    this.setState({
      filter: {
        textSearch: e.target.value,
      },
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = this.state.filter
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
    this.props.removeTextSearch && this.props.removeTextSearch('textSearch')
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className="search-container search-DocumentarySearch pt30 pb30 px-sm-15 mg-bt-20"
      >
        <div className="row">
          <div className="col-xs-12 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 position-gridview">
              <input
                autoFocus={false}
                autoComplete="off"
                placeholder="Tìm kiếm Số CV, Trích yếu, Số hóa (hoặc Số CV:Trích yếu)"
                type="text"
                className="input border-transparent pr-40 input-search-filter"
                onChange={this.handleInputChange}
                name="textSearch"
                value={this.state.filter.textSearch}
              />
              {this.state.filter.textSearch && (
                <i
                  className="icon-close pt-close-gridview-search"
                  onClick={this.removeTextSearch.bind(this, 'textSearch')}
                ></i>
              )}
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              type="button"
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

export default ListFilter
