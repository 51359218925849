import React, { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'
import withRouter from 'react-router/withRouter'

import {
  getStatusDotsChucDanh,
  authLogout,
  switchRoleUser,
  userPermission,
  setCabinetType,
  commonSaveTypeSidebar,
} from 'actions'
import { Permission } from 'components/auth'
import { CABINET_DEPARTMENT } from 'constants/Api'
import { checkPermissionMenu } from 'helpers/Helper'

const UserRole = ({ history, auth, actions, quyensMenu }) => {
  const [dotsData, setDotsData] = useState([])

  const handleChoseRole = id => {
    if (id === auth.roleId) {
      return
    }

    actions.switchRoleUser({ roleId: id })
    actions.userPermission(id).then(res => {
      let redirectTo = '/'
      let typeSidebar = 'HomePage'
      const result = res?.payload?.data?.result?.items
      if (res && result) {
        const permission = result?.map(item => item?.maQuyen)
        // Redirect to after login
        if (checkPermissionMenu(permission, [Permission.SUDUNG_QLCV])) {
          redirectTo = '/quan-ly-cong-viec/ca-nhan/can-thuc-hien'
          typeSidebar = 'taskManager'
        }

        if (permission.indexOf(Permission.TUTAILIEUPHONGBAN_QUANLY) !== -1) {
          actions.setCabinetType(CABINET_DEPARTMENT)
        }
      }
      actions.commonSaveTypeSidebar(typeSidebar)
      if (
        history &&
        history?.location &&
        redirectTo === history?.location?.pathname
      ) {
        return window.location.reload()
      }

      history.push(redirectTo)
    })
  }

  const handleLogout = () => {
    let checkin_maxacnhan = localStorage.getItem('checkin_maxacnhan')
    let user_login = localStorage.getItem('user_login')
    localStorage.clear()
    localStorage.setItem('user_login', user_login)
    localStorage.setItem('checkin_maxacnhan', checkin_maxacnhan)
    sessionStorage.clear()
    actions.authLogout()
  }

  useEffect(() => {
    const user = auth?.user
    if (!user) {
      return
    }

    actions
      .getStatusDotsChucDanh({
        nhan_vien_id: user?.nhanVienId,
      })
      .then(res => {
        const result = res?.payload?.data?.result
        if (result) {
          setDotsData(result)
        }
      })
  }, [auth, actions])

  return (
    <div className="user-role cursor-default">
      <div className="p10">
        <h3 className="user-role__name">
          <span className="icon-profile font-size-15 mr5"></span>
          <span className="font-weight-600 color-blue font-size-14">
            {auth?.user?.tenNhanVien}
          </span>
        </h3>
        <div>
          <p className="label-text font-weight-500">Chức danh:</p>
          <ul className="user-role__roles">
            {auth?.user?.dsChucDanh &&
              auth?.user?.dsChucDanh.map((item, i) => (
                <li
                  key={i}
                  className={classNames({
                    'font-weight-600 color-blue': auth.roleId === item.id,
                  })}
                >
                  <span
                    className={classNames({
                      'text-link': auth.roleId !== item.id,
                    })}
                    onClick={() => handleChoseRole(item.id)}
                  >
                    {item.chucVu ? item.chucVu.tenChucVu + ' ' : ' '}
                    {item.phongBan ? item.phongBan.maPhongBan : ''}
                  </span>
                  {dotsData?.findIndex(
                    i => i.chucDanhId === item.id && i.isThongBao === true
                  ) !== -1 && <span className="dots-chuc-danh" />}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <p className="user-role__logout border-top p10" onClick={handleLogout}>
        <span className="icon-logout font-size-15 mr5"></span>
        <span className="name">Đăng xuất</span>
      </p>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    quyensMenu: state.common?.quyensMenu ?? [],
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getStatusDotsChucDanh,
      authLogout,
      switchRoleUser,
      userPermission,
      setCabinetType,
      commonSaveTypeSidebar,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(UserRole))
)
