import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const NOT_UNIT = '00000000-0000-0000-0000-000000000000'
class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  render() {
    const { item, number } = this.props
    return (
      <tr
        className={classnames({
          'text-bold':
            item.donViId === NOT_UNIT && item.tenDonVi === 'Tổng cộng',
        })}
      >
        <td className="gerenal-list-utilities">
          {item.donViId !== NOT_UNIT && item.tenDonVi !== 'Tổng cộng'
            ? number > 9
              ? number
              : '0' + number
            : null}
        </td>
        <td className="gerenal-list-utilities">{item.tenDonVi}</td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongVanDenSoHoa}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongVanDenNhapTay}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongVanDiSoHoa}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongVanDiNhapTay}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tongSoCongVan}
        </td>
        <td className="gerenal-list-utilities text-center">
          {item.tyLeCongVanSoHoa}
        </td>
      </tr>
    )
  }
}

export default ListItem
