import React, { Component } from 'react'
import moment from 'moment'

class Print extends Component {
  render() {
    return (
      <div className="print-container" id="print">
        {this.props.sendDocument && (
          <div className="print-content">
            <p className="print-p">{this.props.sendDocument.title}</p>
            <table className="print-table" border="1">
              <thead className="print-thead">
                <tr className="print-tr">
                  <th className="print-th print-dateDocument">
                    {this.props.sendDocument.header[0]}
                  </th>
                  <th className="print-th print-default">
                    {this.props.sendDocument.header[1]}
                  </th>
                  <th className="print-th print-brief">
                    {this.props.sendDocument.header[2]}
                  </th>
                  <th className="print-th print-receive">
                    {this.props.sendDocument.header[3]}
                  </th>
                  <th className="print-th print-default">
                    {this.props.sendDocument.header[4]}
                  </th>
                </tr>
              </thead>
              <tbody className="print-tbody">
                {this.props.sendDocument.data.map((item, i) => (
                  <tr className="print-tr" key={i}>
                    <td className="print-td print-dateDocument">
                      {item.ngayCongVan
                        ? moment(item.ngayCongVan).format('DD/MM/YYYY')
                        : null}
                    </td>
                    <td className="print-td print-default">{item.soCongVan}</td>
                    <td className="print-td print-brief">{item.trichYeu}</td>
                    <td className="print-td print-receive">{item.noiNhan}</td>
                    <td className="print-td print-default">{item.nguoiKy}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {this.props.receiveDocument && (
          <div className="print-content">
            <p className="print-p">{this.props.receiveDocument.title}</p>
            <table className="print-table">
              <thead className="print-thead">
                <tr className="print-tr">
                  <th className="print-th print-dateDocument">
                    {this.props.receiveDocument.header[0]}
                  </th>
                  <th className="print-th print-default">
                    {this.props.receiveDocument.header[1]}
                  </th>
                  <th className="print-th print-dateDocument">
                    {this.props.receiveDocument.header[2]}
                  </th>
                  <th className="print-th print-default">
                    {this.props.receiveDocument.header[3]}
                  </th>
                  <th className="print-th print-brief">
                    {this.props.receiveDocument.header[4]}
                  </th>
                  <th className="print-th print-default">
                    {this.props.receiveDocument.header[5]}
                  </th>
                </tr>
              </thead>
              <tbody className="print-tbody">
                {this.props.receiveDocument.data.map((item, i) => (
                  <tr className="print-tr" key={i}>
                    <td className="print-td print-dateDocument">
                      {item.ngayCongVan
                        ? moment(item.ngayCongVan).format('DD/MM/YYYY')
                        : null}
                    </td>
                    <td className="print-td print-default">{item.soCongVan}</td>
                    <td className="print-td print-dateDocument">
                      {item.ngayCapNhat
                        ? moment(item.ngayCapNhat).format('DD/MM/YYYY')
                        : null}
                    </td>
                    <td className="print-td print-default">{item.soDen}</td>
                    <td className="print-td print-brief">{item.trichYeu}</td>
                    <td className="print-td print-default">
                      {item.coQuanBanHanh}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {this.props.searchReceiveDocument && (
          <div className="print-content">
            <p className="print-p">{this.props.searchReceiveDocument.title}</p>
            <table className="print-table">
              <thead className="print-thead">
                <tr className="print-tr">
                  <th className="print-th print-dateDocument">
                    {this.props.searchReceiveDocument.header[0]}
                  </th>
                  <th className="print-th print-default">
                    {this.props.searchReceiveDocument.header[1]}
                  </th>
                  <th className="print-th print-dateDocument">
                    {this.props.searchReceiveDocument.header[2]}
                  </th>
                  <th className="print-th print-default">
                    {this.props.searchReceiveDocument.header[3]}
                  </th>
                  <th className="print-th print-brief">
                    {this.props.searchReceiveDocument.header[4]}
                  </th>
                  <th className="print-th print-default">
                    {this.props.searchReceiveDocument.header[5]}
                  </th>
                </tr>
              </thead>
              <tbody className="print-tbody">
                {this.props.searchReceiveDocument.data.map((item, i) => (
                  <tr className="print-tr" key={i}>
                    <td className="print-td print-dateDocument">
                      {item.ngayCongVan
                        ? moment(item.ngayCongVan).format('DD/MM/YYYY')
                        : null}
                    </td>
                    <td className="print-td print-default">{item.soCongVan}</td>
                    <td className="print-td print-dateDocument">
                      {item.ngayDen
                        ? moment(item.ngayDen).format('DD/MM/YYYY')
                        : null}
                    </td>
                    <td className="print-td print-default">{item.soDen}</td>
                    <td className="print-td print-brief">{item.trichYeu}</td>
                    <td className="print-td print-default">{item.noiGui}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}

export default Print
