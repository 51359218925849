import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { PopupConfirm } from '../common/'
import * as POPUP from '../../constants/Popup'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    disableAction: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isOpenPopup: false,
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })

    if (this.props.item && this.props.item.id) {
      this.props.onHandleSubmitRemoveItem &&
        this.props.onHandleSubmitRemoveItem(this.props.item.id)
    }
  }

  handleClick = () => {
    if (this.props.item && this.props.item.id && this.props.onClickDetail) {
      this.props.onClickDetail(this.props.item.id)
    }
  }

  render() {
    if (!this.props.item) {
      return null
    }
    return (
      <tr>
        <td onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick}>{this.props.item.tieuDe}</td>
        <td className="text-center-col">
          {this.props.item.isActive ? (
            <span className="icon-Bao_Cao"></span>
          ) : (
            ''
          )}
        </td>
        <td onClick={this.handleClick}>
          {this.props.item.ngayTao &&
            moment(this.props.item.ngayTao).format('DD/MM/YYYY HH:mm')}
        </td>
        <td onClick={this.handleClick}>{this.props.item.nguoiTao}</td>
        <td onClick={this.handleClick}>
          {this.props.item.tinTongCongTy === false
            ? this.props.item.noiDang
            : 'Tổng công ty'}
        </td>
        <td>
          <Popover
            popoverClassName="pt-popover-content-sizing"
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            openOnTargetFocus={true}
            content={
              <div className="menu-popover">
                <Link
                  className="item"
                  to={`/thong-bao/chinh-sua/${this.props.item.id}`}
                >
                  <span className="pt-icon pt-icon-annotation"></span>
                  <span className="name_action">Chỉnh sửa</span>
                </Link>
                <span className="item" onClick={this.handleClickPopup}>
                  <span className="pt-icon pt-icon-trash"></span>
                  <span className="name_action">Xóa</span>
                </span>
              </div>
            }
          >
            <span className="icon-More"></span>
          </Popover>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={POPUP.XOA_THONG_BAO}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleSubmitRemoveItem}
          >
            {POPUP.TEXT_SUBMIT_BODY}
          </PopupConfirm>
        </td>
      </tr>
    )
  }
}

export default ListItem
