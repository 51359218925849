import React, { memo, useCallback, useState, Fragment } from 'react'
import {
  Button,
  Popover,
  Menu,
  MenuDivider,
  Position,
  MenuItem,
  PopoverInteractionKind,
  Tooltip,
} from '@blueprintjs/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import moment from 'moment'
import { get, size } from 'lodash'

import { MasterLayout } from 'components/layout'
import Title from 'components/title'
import {
  Table,
  Pagination,
  SelectTree,
  DatePicker,
} from 'components/newCommon2'
import {
  Icon,
  Avatar,
  FileGroup,
  TagGroup,
  Dialog,
  Input,
  Select,
} from 'components/newCommon'
import { TruncateTextTooltip, ViewMoreDots } from 'components/common'
import { Permission } from 'components/auth'
import { WORK_APPROVED, WORK_STATUS } from 'constants/Enum'
import DeleteThongBaoKLCHCongViec from './components/confirm/delete-thongbao-klch-congviec'
import useTaskThongbaoKLCHDetail from './hooks/use-task-thongbao-klch-detail'

const TaskThongBaoKLCHDetail = ({
  history,
  match,
  roleId,
  mainUnitId,
  hasShowAllDeparment,
  dataTreeOrganizationDepartmentStore,
}) => {
  const PAGE_SIZE = 10
  const DATA_FORMAT = 'DD/MM/YYYY'
  const pageActions = {
    flowWorks: 'flow-works',
    chinhSua: 'chinh-sua',
    taoMoiCongViec: 'them-moi-cong-viec',
    chinhSuaCongViec: 'chinh-sua-cong-viec',
    huyDuyet: 'huy-duyet',
    xoa: 'xoa',
    chiTiet: 'chi-tiet',
    chiTietCongViec: 'chi-tiet-cong-viec',
    nhacViec: 'nhac-viec',
    tag: 'tag',
    xacNhan: 'xac-nhan',
    acceptHistory: 'lich-su-xac-nhan',
  }
  const dsStatus = [
    {
      label: 'Tất cả',
      value: 'ALL',
    },
    {
      label: 'Chưa thực hiện',
      value: WORK_STATUS.CHUA_THUC_HIEN,
    },
    {
      label: 'Đang thực hiện',
      value: WORK_STATUS.DANG_THUC_HIEN,
    },
    {
      label: 'Hoàn thành',
      value: WORK_STATUS.HOAN_THANH,
    },
    {
      label: 'Hoàn thành chậm',
      value: WORK_STATUS.HOAN_THANH_CHAM,
    },
  ]
  const dsStatusApprobal = [
    {
      label: 'Tất cả',
      value: 'ALL',
    },
    {
      label: 'Chưa duyệt',
      value: WORK_APPROVED.NOT_APPROVED_YET,
    },
    {
      label: 'Đã duyệt',
      value: WORK_APPROVED.APPROVED,
    },
    {
      label: 'Chờ duyệt',
      value: WORK_APPROVED.WAITING_APPROVAL,
    },
  ]

  const [dataDialog, setDataDialog] = useState({
    isOpen: false,
    data: null,
  })

  const {
    filterKeys,
    dsLoaiCuocHop,
    fetchingDetail,
    dataDetailMetting,
    fetching,
    fetchingFail,
    filter,
    page,
    dataWorksMetting,
    deleteData,
    setDeleteData,
    onChangeFilter,
  } = useTaskThongbaoKLCHDetail({
    match,
    history,
    mainUnitId,
    hasShowAllDeparment,
  })

  const renderDetail = useCallback(() => {
    const newData = [
      {
        label: 'Tên cuộc họp:',
        value: dataDetailMetting?.title || '',
      },
      {
        label: 'Số hiệu văn bản:',
        value: dataDetailMetting?.textNumber || '',
      },
      {
        label: 'Lãnh đạo chủ trì:',
        value: dataDetailMetting?.mainResponsibility || null,
        type: 'AVATAR',
        className: 'align-items-center',
      },
      {
        label: 'Ngày ban hành VB:',
        value: dataDetailMetting?.executionDate,
        type: 'DATE',
      },
      {
        label: 'Loại cuộc họp:',
        value:
          dsLoaiCuocHop.find(
            elm => elm?.value === dataDetailMetting?.meetingType
          )?.label || '',
      },
      {
        label: 'Đơn vị:',
        value: dataDetailMetting?.organizationName,
      },
      {
        label: 'File văn bản:',
        value: dataDetailMetting?.files,
        type: 'FILES',
      },
    ]

    return (
      <ul>
        {newData?.map((item, index) => {
          let content = <span>{item?.value}</span>
          if (item?.type === 'DATE') {
            content = (
              <div>
                {moment(item?.value).isValid()
                  ? moment(item?.value).format(DATA_FORMAT)
                  : ''}
              </div>
            )
          } else if (item?.type === 'FILES') {
            content = <FileGroup list={get(item, 'value')} />
          } else if (item?.type === 'AVATAR') {
            content = (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  src={get(item, 'value.avatar')}
                  className="mr10 tiny"
                  needBaseUrl
                />
                <div className="user-info">
                  <p className="font-weight-600 no-wrap">
                    {get(item, 'value.name')}
                  </p>
                  <span className="font-size-10">
                    {get(item, 'value.information.organization.shortName')}
                  </span>
                </div>
              </div>
            )
          }

          return (
            <li key={index} className={classNames('mb15', item?.className)}>
              <label className="label font-size-13 font-weight-600">
                {item?.label}
              </label>
              {fetchingDetail ? (
                <div
                  className="cpc-loading-wrapper"
                  style={{ minWidth: 150, padding: 0 }}
                >
                  <div className="cpc-loading-block">
                    <span className={classNames('cpc-loading-bar', `item-5`)} />
                  </div>
                </div>
              ) : (
                <div className="font-size-13 white-space-break-spaces">
                  {content}
                </div>
              )}
            </li>
          )
        })}
      </ul>
    )
  }, [fetchingDetail, dataDetailMetting, dsLoaiCuocHop])

  const getMenuAction = useCallback(
    ({ id, creatorProfileId, approval, status } = {}) => {
      const allowAction = creatorProfileId === roleId
      const { chinhSuaCongViec } = pageActions
      let isSecretaryApprove = false
      if (approval === WORK_APPROVED.APPROVED) {
        isSecretaryApprove = true
      }

      let isAbleDeleted = false
      if (
        allowAction &&
        !isSecretaryApprove &&
        status === WORK_STATUS.CHUA_THUC_HIEN
      ) {
        isAbleDeleted = true
      }

      return [
        ...(allowAction && !isSecretaryApprove
          ? [
              {
                icon: 'icon-but size-icon-15',
                text: 'Sửa/Hủy',
                onClick:
                  status !== WORK_STATUS.HOAN_THANH
                    ? () =>
                        history.push(
                          `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/ket-luan/chinh-sua/${dataDetailMetting?.id}/${id}`
                        )
                    : () =>
                        setDataDialog({
                          isOpen: true,
                          data: {
                            mettingId: dataDetailMetting?.id,
                            id,
                            key: chinhSuaCongViec,
                          },
                        }),
              },
            ]
          : []),
        ...(isAbleDeleted
          ? [
              {
                icon: 'icon-bin size-icon-15',
                text: 'Xóa',
                onClick: () => setDeleteData({ id: id }),
              },
            ]
          : []),
      ]
    },

    [pageActions, dataDetailMetting, history, roleId, setDeleteData]
  )

  const handleStatus = status => {
    let text = 'Chưa thực hiện'
    let color = 'yellow'
    switch (status) {
      case WORK_STATUS.DANG_THUC_HIEN:
        text = 'Đang thực hiện'
        color = 'blue'
        break
      case WORK_STATUS.HOAN_THANH:
        text = 'Hoàn thành'
        color = 'green'
        break
      case WORK_APPROVED.APPROVED:
        text = 'Đã duyệt'
        color = 'green'
        break
      case WORK_APPROVED.NOT_APPROVED_YET:
        text = '-'
        color = ''
        break
      case WORK_STATUS.HOAN_THANH_CHAM:
        text = 'Hoàn thành chậm'
        color = 'red'
        break
      case WORK_APPROVED.WAITING_APPROVAL:
        text = 'Chờ duyệt'
        color = 'orange'
        break
      default:
        break
    }

    return (
      <span
        className={classNames(
          'status-metting text-uppercase font-size-10 font-weight-600 no-wrap',
          color
        )}
      >
        {text}
      </span>
    )
  }

  const columns = () => {
    return [
      {
        key: 'stt',
        width: 5,
        headClass: 'uppercase',
        className: 'align-center min-width-60 text-center',
        title: 'Stt',
        render: (v, r, index) => {
          const num = (page - 1) * 10 + index + 1
          return `${num}`.padStart(num < 10 ? 2 : 1, '0')
        },
      },
      {
        key: 'title',
        width: 30,
        headClass: 'uppercase',
        className: 'align-center min-width-100',
        title: 'Nội dung kết luận',
        filter: (
          <Input
            clearable
            onChange={onChangeFilter({
              name: filterKeys.title,
              isReFetch: false,
            })}
            onClear={onChangeFilter({ name: filterKeys.title })}
            onSend={onChangeFilter({ name: filterKeys.title })}
            placeholder="Nhập"
            value={filter?.[filterKeys.title] ? filter?.[filterKeys.title] : ''}
          />
        ),
        render: ({
          title,
          status,
          workUserTags = [],
          referenceId,
          reference,
        }) => (
          <div>
            <span
              className={classNames('white-space-break-spaces', {
                'color-red': status === 'DONE_SLOW',
              })}
            >
              {title?.length > 20 ? (
                <TruncateTextTooltip content={title} />
              ) : (
                title
              )}
            </span>
            <TagGroup
              popoverClassName="ml15 mb5"
              popoverPosition={Position.TOP_LEFT}
              className={classNames({ mt5: !!size(workUserTags) })}
              tags={workUserTags.map(
                ({ workTagId, workTagName, backgroundColor }) => ({
                  id: workTagId,
                  name: workTagName,
                  color: backgroundColor,
                })
              )}
              preChildren={
                <>
                  {!!referenceId && (
                    <>
                      {reference?.daLuuTuPhongBan && (
                        <Tooltip
                          content={
                            <span className="font-size-13">
                              Công việc đã lưu tủ tài liệu
                            </span>
                          }
                          position={Position.BOTTOM}
                        >
                          <Icon
                            classIcon="icon-Save-DocumentCabinet"
                            className={classNames(
                              'icon-color-primary',
                              'size-icon-18',
                              'mr10 mb5',
                              'd-block'
                            )}
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        content={
                          <span className="font-size-13">
                            Công việc Ký số, Công văn
                          </span>
                        }
                        position={Position.BOTTOM}
                      >
                        <Icon
                          classIcon="icon2-e-office-label"
                          className={classNames(
                            'icon-color-primary',
                            'size-icon-18',
                            'mr10 mb5',
                            'd-block'
                          )}
                        />
                      </Tooltip>
                    </>
                  )}
                </>
              }
            />
          </div>
        ),
      },
      {
        key: 'presiding_name',
        width: 30,
        headClass: 'uppercase',
        className: 'align-center min-width-100',
        title: 'Đơn vị chủ trì',
        filter: (
          <SelectTree
            inputWrapperClassName={'input-not-ph'}
            dataSource={[
              { name: 'Tất cả', code: 'ALL' },
              ...dataTreeOrganizationDepartmentStore?.map(e => {
                if (e?.type === 'ALL') {
                  return {
                    ...e,
                    noCheck: true,
                  }
                }

                return e
              }),
            ]}
            keySource={['name', 'code']}
            placeholder="Phòng ban/ Đơn vị"
            wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
            inputStyle={{ height: '34px', lineHeight: '34px' }}
            isShowArrowDropdown
            radioName={filterKeys.chuTri}
            value={
              filter?.[filterKeys.chuTri] ? filter?.[filterKeys.chuTri] : ''
            }
            onChange={onChangeFilter({
              name: filterKeys.chuTri,
            })}
          />
        ),
        render: ({ presidingName, status }) => (
          <span className={classNames({ 'color-red': status === 'DONE_SLOW' })}>
            {presidingName || '-'}
          </span>
        ),
      },
      {
        key: 'coordination_name',
        width: 30,
        headClass: 'uppercase',
        className: 'align-center min-width-100',
        title: 'Đơn vị phối Hợp',
        filter: (
          <SelectTree
            inputWrapperClassName={'input-not-ph'}
            dataSource={[
              { name: 'Tất cả', code: 'ALL' },
              ...dataTreeOrganizationDepartmentStore?.map(e => {
                if (e?.type === 'ALL') {
                  return {
                    ...e,
                    noCheck: true,
                  }
                }

                return e
              }),
            ]}
            keySource={['name', 'code']}
            placeholder="Phòng ban/ Đơn vị"
            wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
            inputStyle={{ height: '34px', lineHeight: '34px' }}
            isShowArrowDropdown
            radioName={filterKeys.phoiHop}
            value={
              filter?.[filterKeys.phoiHop] ? filter?.[filterKeys.phoiHop] : ''
            }
            onChange={onChangeFilter({
              name: filterKeys.phoiHop,
            })}
          />
        ),
        render: ({ coordinationName, status }) => {
          const arrayData = coordinationName?.split(', ') || []
          return (
            <span
              className={classNames({ 'color-red': status === 'DONE_SLOW' })}
            >
              {size(arrayData) !== 0 ? (
                <ViewMoreDots data={arrayData} isTotal={true} />
              ) : (
                '-'
              )}
            </span>
          )
        },
      },
      {
        key: 'executionDate',
        width: 15,
        headClass: 'uppercase',
        className: 'min-width-120 align-center',
        title: 'Ngày Bắt đầu',
        filter: (
          <DatePicker
            leftIcon=""
            clearable={true}
            valueDisplay={null}
            onChange={onChangeFilter({
              name: filterKeys.ngayBatDau,
            })}
          />
        ),
        render: ({ executionDate, status }) => (
          <span className={classNames({ 'color-red': status === 'DONE_SLOW' })}>
            {moment(executionDate).isValid()
              ? moment(executionDate).format(DATA_FORMAT)
              : '-'}
          </span>
        ),
      },
      {
        key: 'deadline',
        width: 15,
        headClass: 'uppercase',
        className: 'min-width-120 align-center',
        title: 'Hạn thực hiện',
        filter: (
          <DatePicker
            leftIcon=""
            clearable={true}
            valueDisplay={null}
            onChange={onChangeFilter({
              name: filterKeys.hanThucHien,
            })}
          />
        ),
        render: ({ deadline, status }) => {
          const today = moment().startOf('day')
          const diff = moment(deadline).startOf('day').diff(today, 'days')
          const daTre = diff < 0
          const ganTre = diff >= 0 && diff - 3 <= 0
          const isChuaHoanThanh =
            status === WORK_STATUS.CHUA_THUC_HIEN ||
            status === WORK_STATUS.DANG_THUC_HIEN

          return (
            <div>
              <p
                className={classNames({
                  'color-red': status === 'DONE_SLOW',
                  'font-weight-600': isChuaHoanThanh && (daTre || ganTre),
                  'text-danger': isChuaHoanThanh && daTre,
                  'color-orange-lv1': isChuaHoanThanh && ganTre,
                })}
              >
                {moment(deadline).isValid()
                  ? moment(deadline).format(DATA_FORMAT)
                  : '-'}
              </p>
              {isChuaHoanThanh &&
              (daTre || ganTre) &&
              moment(deadline).isValid() ? (
                <span className={classNames('font-size-11 disabled')}>
                  {daTre
                    ? `Đã trễ hạn ${Math.abs(diff)} ngày`
                    : ganTre && diff !== 0
                    ? `(Còn ${diff} ngày đến hạn)`
                    : `(Đến hạn)`}
                </span>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        key: 'status',
        width: 15,
        headClass: 'uppercase',
        className: 'min-width-120 align-center min-width-100',
        title: 'Trạng thái',
        filter: (
          <Select
            dataSource={dsStatus || []}
            onChange={onChangeFilter({ name: filterKeys.trangThai })}
            placeholder="Chọn"
            value={
              filter?.[filterKeys.trangThai]
                ? filter?.[filterKeys.trangThai]
                : ''
            }
          />
        ),
        render: ({ status }) => {
          return handleStatus(status)
        },
      },
      {
        key: 'approval',
        width: 15,
        headClass: 'uppercase',
        className: 'align-center justify-center min-width-140',
        title: 'Trạng thái duyệt',
        filter: (
          <Select
            dataSource={dsStatusApprobal || []}
            onChange={onChangeFilter({ name: filterKeys.trangThaiDuyet })}
            placeholder="Chọn"
            value={
              filter?.[filterKeys.trangThaiDuyet]
                ? filter?.[filterKeys.trangThaiDuyet]
                : ''
            }
          />
        ),
        render: ({ approval }) => {
          return handleStatus(approval)
        },
      },
      {
        key: 'thaoTac',
        className: 'element-center max-width-60 min-width-60 align-center',
        width: 5,
        render: record => {
          const menu = getMenuAction(record) || []
          if (!size(menu)) return null
          return (
            <Popover
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              content={
                <Menu className="cpc-context-menu border-none">
                  {menu.map(({ icon, text, className, onClick }, index) => (
                    <Fragment key={index}>
                      {!!index && <MenuDivider />}
                      <MenuItem
                        text={
                          <span
                            className={classNames(
                              'cpc-context-menu-item',
                              className
                            )}
                          >
                            <i className={classNames(icon)} /> {text}
                          </span>
                        }
                        onClick={onClick}
                      />
                    </Fragment>
                  ))}
                </Menu>
              }
            >
              <span
                className="icon-More size-icon-24"
                onClick={e => e.stopPropagation()}
              />
            </Popover>
          )
        },
      },
    ]
  }

  const allowAction = dataDetailMetting?.creatorProfileId === roleId

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="d-flex align-items-center justify-content-space-between mt5">
        <Title
          history={history}
          isGoBack={true}
          name={`Số hiệu văn bản: ${
            dataDetailMetting?.textNumber ? dataDetailMetting?.textNumber : ''
          }`}
          icon="icon2-clipboard"
        />
        {allowAction && (
          <div className="d-flex gap-10">
            <Tooltip
              content={
                <span className="font-size-12">Chỉnh sửa thông báo klch</span>
              }
            >
              <Button
                className={'cpc-button cpc-button-green'}
                loading={false}
                disabled={false}
                onClick={() =>
                  history.push(
                    `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/chinh-sua/${dataDetailMetting?.id}`
                  )
                }
              >
                <span className="mr5 color-white">Chỉnh sửa</span>
                <Icon classIcon="icon-but color-white" />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div>
        <div className="panel-meeting-detail mt10">{renderDetail()}</div>
        <div className="task-manager-title mt20">
          <h1 className="font-weight-600">Danh sách kết luận</h1>
          <div>
            {allowAction && (
              <Tooltip
                position={Position.TOP_RIGHT}
                content={<span className="font-size-12">Tạo kết luận</span>}
              >
                <Button
                  className={classNames('cpc-button blue')}
                  onClick={() =>
                    history.push(
                      `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/ket-luan/tao-moi/${dataDetailMetting?.id}`
                    )
                  }
                >
                  <span className="mr5">Tạo kết luận</span>
                  <Icon classIcon="icon-Plus" />
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
        <Dialog
          buttonUppercase
          isOpen={dataDialog?.isOpen}
          cancelClassName="pl20 pr20"
          cancelText="Hủy"
          confirmClassName="pl20 pr20"
          confirmText="Sửa/Hủy"
          loading={false}
          onCancel={() =>
            setDataDialog({
              isOpen: false,
              data: null,
            })
          }
          onConfirm={() => {
            setDataDialog({
              isOpen: false,
              data: null,
            })
            return history.push(
              `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/ket-luan/chinh-sua/${dataDialog?.data?.mettingId}/${dataDialog?.data?.id}`
            )
          }}
          onClose={() =>
            setDataDialog({
              isOpen: false,
              data: null,
            })
          }
          title={'Thông báo'}
          textCenter
        >
          {
            'Công việc đang chọn đã hoàn thành. Bạn có chắc chắn muốn sửa/hủy hay không?'
          }
        </Dialog>
        <div className="border mt10">
          <Table
            className="cpc-table-giaodienmoi"
            autoHide={false}
            columns={columns()}
            data={dataWorksMetting?.items || []}
            loading={fetching}
            error={fetchingFail}
            tableMinWidth={1200}
            onRowClick={({ id }) =>
              history.push(
                `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/ket-luan/chi-tiet/${id}`
              )
            }
          />
          {dataWorksMetting?.totalCount > PAGE_SIZE && (
            <div className="text-center bg-white ph15 pv20 border-top">
              <Pagination
                total={dataWorksMetting?.totalCount}
                current={page}
                pageSize={PAGE_SIZE}
                onChange={onChangeFilter({ name: 'PAGE' })}
              />
            </div>
          )}
        </div>
        {deleteData ? (
          <DeleteThongBaoKLCHCongViec
            dataSource={deleteData}
            onClose={() => setDeleteData(null)}
            onSuccess={() => onChangeFilter({ name: 'RESET' })()}
          />
        ) : (
          ''
        )}
      </div>
    </MasterLayout>
  )
}

TaskThongBaoKLCHDetail.propTypes = {
  mainUnitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasShowAllDeparment: PropTypes.bool,
  dataTreeOrganizationDepartmentStore: PropTypes.array,
}

const mapStateToProps = state => {
  const roleId = state?.auth?.roleId
  const hasShowAllDeparment = get(state, 'auth.permission', []).includes(
    Permission.THONGBAOKETLUANHDTV
  )

  return {
    hasShowAllDeparment,
    roleId,
    mainUnitId: get(state, 'auth.mainUnitId'),
    dataTreeOrganizationDepartmentStore: get(
      state,
      'common.dataTreeOrganizationDepartmentStore'
    ),
  }
}

export default connect(mapStateToProps)(memo(TaskThongBaoKLCHDetail))
