import { Action, Toast } from '../../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'
import React, { Component } from 'react'
import {
  deleteProcedure,
  fetchAllProcedureWithGroup,
  toggleUsingProcedure,
} from '../../../actions/procedure'

import { ContentHeader } from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import ProcedureList from './ProcedureList'
import ProcedureListFilter from './ProcedureListFilter'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { commonAddToasterMessage } from '../../../actions'
import { connect } from 'react-redux'

const ADD_PATH = '/he-thong/quy-trinh/them-moi'
const PAGE_NAME = 'Quản lý quy trình'
const PAGE_ICON = 'icon-quan-ly-quy-trinh'
const ACTIONS_BUTTON = [
  {
    link: ADD_PATH,
    name: 'Thêm Mới',
    icon: 'pt-icon pt-icon-small-plus',
  },
]

class ProcedurePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      isError: false,
      listData: [],
    }
  }

  setFetchStatus = (type, res = null) => {
    let status
    if (type === 'success') {
      status = _.get(res, 'payload.status') === 200
    } else if (type === 'error') {
      status = false
    }
    this.setState({ isError: !status, isLoading: false })
  }

  fetchData = async (filter = null) => {
    const { fetchAll } = this.props
    await this.setState({
      isLoading: true,
    })
    fetchAll(filter)
      .then(res => {
        this.setFetchStatus('success', res)
      })
      .catch(() => this.setFetchStatus('error'))
  }

  onSearch = async (filter, reset = false) => {
    let { tmpFilter } = this.state
    tmpFilter = reset ? {} : { ...tmpFilter, ...filter }
    this.setState({ tmpFilter })
    this.fetchData(tmpFilter)
  }

  toggleUsing = async item => {
    const { showToast, toggleUsing } = this.props
    const { listData } = this.state
    await toggleUsing({
      id: item.quyTrinhId,
      is_active: !item.isActive,
    })
      .then(async res => {
        const status = !!_.get(res, 'payload.data.result')
        !!status &&
          (await this.setState({
            listData: listData.map(i =>
              i.quyTrinhId === item.quyTrinhId
                ? { ...i, isActive: !i.isActive }
                : i
            ),
          }))
        showToast({
          message: status
            ? Toast.SUCCESS(Action.UPDATE)
            : Toast.FAIL(Action.UPDATE),
          intent: status ? Intent.SUCCESS : Intent.DANGER,
        })
        this.fetchData()
      })
      .catch(() =>
        showToast({
          message: Toast.FAIL(Action.UPDATE),
          intent: Intent.DANGER,
        })
      )
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillReceiveProps(nextProps) {
    if (_.difference(this.props.procedure.list, nextProps.procedure.list)) {
      this.setState({
        listData: nextProps.procedure.list,
      })
    }
  }

  render() {
    const { actionDelete, showToast } = this.props
    const { isLoading, isError, listData } = this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <ContentHeader
          pageIcon={PAGE_ICON}
          pageName={PAGE_NAME}
          actionsButton={ACTIONS_BUTTON}
        />
        <ProcedureListFilter onSearch={this.onSearch} />
        <div className="custom-table-container">
          <ProcedureList
            data={listData}
            isLoading={isLoading}
            isError={isError}
            showToast={showToast}
            actionDelete={actionDelete}
            onSearch={this.onSearch}
            toggleUsing={this.toggleUsing}
            fetchData={this.fetchData}
          />
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  procedure: state.procedure,
})

const mapDispathToProps = dispatch => ({
  fetchAll: bindActionCreators(fetchAllProcedureWithGroup, dispatch),
  actionDelete: bindActionCreators(deleteProcedure, dispatch),
  toggleUsing: bindActionCreators(toggleUsingProcedure, dispatch),
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
})

export default connect(mapStateToProps, mapDispathToProps)(ProcedurePage)
