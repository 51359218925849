import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Authorization, Permission } from '../auth'
import { LoadingItem } from '../common/'

class DetailBoxAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBoxAction: false,
    }
  }

  showBoxAction(e) {
    this.setState({
      showBoxAction: !this.state.showBoxAction,
    })
  }

  render() {
    const MenuDetail = [
      {
        onClick: this.props.onClickDongYThuHoi,
        icon: 'icon-Bao_Cao mauXam font-12',
        name: 'Đồng ý thu hồi',
        permission: [Permission.DONGY_THUHOI],
        isShow: true,
        disabled: this.props.checkDongYThuHoi,
      },
      {
        onClick: this.props.onClickHuyDeXuatThuHoi,
        icon: 'icon-trash',
        name: 'Hủy đề xuất thu hồi',
        permission: [],
        isShow: true,
        disabled: this.props.checkHienThiHuyDeXuat,
      },
      {
        onClick: this.props.onClickRestoreArchive,
        icon: 'icon-listing',
        name: 'Danh sách các đơn vị nhận',
        permission: [],
        isShow: true,
        disabled: false,
      },
    ]
    return this.props.isLoadingData &&
      Object.values(this.props.isLoadingData).find(item => item === true) !==
        undefined ? (
      <LoadingItem />
    ) : (
      <div className="list-action">
        <p className="title">Thực Hiện</p>
        <span
          className="icon-More show-th-mobile"
          onClick={this.showBoxAction.bind(this)}
        ></span>
        <div
          className={classnames('box-action', {
            'box-action-open': this.state.showBoxAction,
          })}
        >
          <div className="close-mobile-menu-detail">
            <button
              className="click-item"
              onClick={this.showBoxAction.bind(this)}
            >
              <span className="pt-icon pt-icon-cross"></span>
            </button>
          </div>
          <div className="row">
            {MenuDetail.filter(item => item.isShow).map((item, key) => {
              return (
                <Authorization.Element permission={item.permission} key={key}>
                  <div className="col-xs-4 col-sm-6 col-md-6 item">
                    <button
                      disabled={item.disabled}
                      className="click-item"
                      onClick={item.onClick}
                    >
                      <i className={`icon-cpc ${item.icon}`}></i>
                      <span className="action-name">{item.name}</span>
                    </button>
                  </div>
                </Authorization.Element>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

DetailBoxAction.propTypes = {
  onClickSendAdditional: PropTypes.func,
  onClickExecutingView: PropTypes.func,
  onClickRestoreArchive: PropTypes.func,
  executingViews: PropTypes.object,
  actions: PropTypes.object.isRequired,
  onClickExecutingViewReceiveDocument: PropTypes.func,
}

export default DetailBoxAction
