import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Classes, Radio, Button, Intent, Position } from '@blueprintjs/core'
import classNames from 'classnames'
import { get, size, toPairs, isEqual, isEmpty, isString } from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'

import { downloadFile } from 'helpers/Helper'
import { DO_DOWNLOAD_FILE } from 'constants/Api'
import { Permission } from 'components/auth'
import {
  Row,
  Col,
  Upload,
  Collapse,
  FileGroup,
  Icon,
  Dialog,
  Card,
} from 'components/newCommon'
import {
  Input,
  Select,
  RemindSelect,
  CheckboxSelect,
  FormDatePicker,
  DateAdvanced,
} from 'components/newCommon2'
import { postUpload, getDsTag, postWorkIndividual } from 'actions/task'
import { priorities } from 'constants/task'
import { Toast } from 'constants/MessageForm'
import { documentTypes, allowDocumentFileTypes } from 'constants/fileTypes'
import { UNSET, WORK_TYPE } from 'constants/Enum'
import { randomId } from 'helpers/string'
import { toDecamelizeKeys } from 'helpers/key'
import requestAction from 'helpers/request'
import ToDoList from './todolist'
import '../index.scss'
import CryptoJS from 'crypto-js'

const dateRequest = 'YYYY-MM-DD'
const dateTimeRequest = 'YYYY-MM-DDTHH:mm:ss'
const CONFIRM_TITLE = 'Xóa công việc'
const CONFIRM_CONTENT = 'Anh/Chị muốn xóa công việc này?'

const PerformTheWork = ({
  roleId,
  user,
  match,
  documentInfo,
  fileDO,
  tokenDO,
  onClose = () => {},
}) => {
  const token = {
    tokenType: 'Bearer',
    accessToken: tokenDO?.token,
  }
  //const id = match?.params?.id
  const deCodeId = CryptoJS.AES.decrypt(
    decodeURIComponent(match?.params?.id),
    'EVNCPC'
  )
  const id = deCodeId.toString(CryptoJS.enc.Utf8)

  const fileCongVanDO =
    fileDO
      ?.filter(f => f?.fILECHINH)
      ?.map(e => ({
        id: e?.iDFILE,
        name: e?.tENFILE,
      })) ?? []
  const fileDinhKemDO =
    fileDO
      ?.filter(f => !f?.fILECHINH)
      ?.map(e => ({
        id: e?.iDFILE,
        name: e?.tENFILE,
      })) ?? []
  const DEFAULT_TASK = useMemo(
    () => ({
      showTask: true,
      showExtra: false,
      data: {
        executionDate: moment(),
      },
      validate: {},
    }),
    []
  )

  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const [tasks, setTasks] = useState([{ ...DEFAULT_TASK, id: randomId() }])
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [validateErrors, setValidateErrors] = useState({})
  const [files, setFiles] = useState({})
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [idTempDialog, setIdTempDialog] = useState(null)
  const [tags, setTags] = useState([])

  const onCloseDialog = useCallback(() => {
    setIsShowDialog(false)
    setIdTempDialog(null)
  }, [])

  const onDeleteTask = useCallback(() => {
    const id = idTempDialog
    const newTasks = tasks.filter(task => task.id !== id)
    _mountedSet(
      setTasks,
      size(newTasks) === 1
        ? newTasks.map(t => ({ ...t, showTask: true }))
        : newTasks
    )

    // Xóa file lưu tạm của công việc đó
    _mountedSet(setFiles, prevFiles => {
      delete prevFiles[id]
      return prevFiles
    })

    onCloseDialog()
  }, [idTempDialog, tasks, onCloseDialog])

  const toggleCollapseTask = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showTask: !task.showTask,
              showExtra: false,
            }
          : {
              ...task,
              showTask: false,
            }
      )
    )
  }

  const toggleCollapseExtra = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showExtra: !task.showExtra,
            }
          : task
      )
    )
  }

  const removeTask = id => () => {
    setIdTempDialog(id)
    setIsShowDialog(true)
  }

  const _changeFormValue = ({ id, name, value }) => {
    _mountedSet(setTasks, prevTasks =>
      prevTasks.map(task =>
        task.id === id
          ? {
              ...task,
              data: {
                ...task.data,
                [name]: value,
                ...(name === 'deadline' ? { workReminder: null } : {}),
              },
            }
          : task
      )
    )
  }

  const _changeFormValidate = ({ id, name, value }) => {
    setValidateErrors(prev => ({
      ...prev,
      [id]: {
        ...(prev?.[id] || {}),
        [name]: value,
      },
    }))
  }

  const changeField = (id, name) => value => {
    _changeFormValidate({
      id,
      name,
      value: false,
    })

    _changeFormValue({
      id,
      name,
      value,
    })
  }

  const changeFile = id => fileList => {
    _mountedSet(setFiles, {
      ...files,
      [id]: fileList,
    })
  }

  const removeAttachmentFiles = id => removeFile => {
    if (removeFile.id) {
      // Xóa file đã upload lên server
      _mountedSet(setTasks, prevTasks =>
        prevTasks.map(task =>
          task.id === id
            ? {
                ...task,
                data: {
                  ...get(task, 'data', {}),
                  attachmentFiles: get(task, 'data.attachmentFiles', []).filter(
                    file => !isEqual(file, removeFile)
                  ),
                },
              }
            : task
        )
      )
    } else {
      // Xóa file chọn từ file upload
      _mountedSet(setFiles, {
        ...files,
        [id]: files[id].filter(elm => elm.uid !== removeFile.uid),
      })
    }
  }

  const _validateRequired = (name, key) => {
    let success = true
    const tasksNew = [...tasks]
    tasksNew.forEach(({ id, data }) => {
      let isCheck = isString(data[name]) && !data[name].trim()
      if (key) {
        isCheck = isString(data?.[name]?.[key]) && !data?.[name]?.[key].trim()
      }

      if (isEmpty(data[name]) || isCheck) {
        success = false
        _changeFormValidate({ id, name, value: true })
      } else {
        _changeFormValidate({ id, name, value: false })
      }
    })

    return success
  }

  const _validateTitle = () => {
    return _validateRequired('title')
  }

  const _validate = () => {
    const success = _validateTitle()
    if (!success) {
      throw new Error('warning')
    }
  }

  const _getConvertData = () => {
    return (tasks || []).map(task => {
      const originData = get(task, 'data', {})
      const newData = {
        title: get(originData, 'title', '').trim(),
        description: get(originData, 'description', '').trim(),
        priority:
          get(originData, 'priority') !== UNSET
            ? get(originData, 'priority')
            : undefined,
        workUserTags: get(originData, 'tags', []).map(item => ({
          workTagId: item,
        })),
        isSaveTemplate: get(originData, 'isSaveTemplate'),
        requireReport: originData?.requireReport || false,
        workType: WORK_TYPE.DOFFICE,
        documentId: id,
        workCheckList: originData?.workCheckList?.map(e => ({
          content: e?.content,
          deadline: e?.deadline,
          status: e?.status || 0,
        })),
        isDofficeSend: true,
      }

      const deadline = get(originData, 'deadline')
      if (deadline) {
        newData.deadline = moment(deadline).startOf('day').format(dateRequest)
      }

      const executionDate = get(originData, 'executionDate')
      if (executionDate) {
        newData.executionDate = moment(executionDate).format(dateRequest)
      }

      const workReminder = get(originData, 'workReminder')
      if (workReminder) {
        newData.workReminder = {
          type: workReminder.type,
          reminderTime: workReminder.date.format(dateTimeRequest),
        }
      }

      return {
        ...task,
        data: newData,
      }
    })
  }

  const onChangeTodo = (id, value) => {
    _mountedSet(setTasks, prevTasks =>
      prevTasks?.map(task =>
        task.id === id
          ? {
              ...task,
              data: {
                ...(task?.data || {}),
                workCheckList: value,
              },
            }
          : task
      )
    )
  }

  // Upload file
  const _upload = async () => {
    const responseFilesObject = {}
    await Promise.all(
      Object.entries(files).map(async ([key, files]) => {
        if (isEmpty(files)) {
          responseFilesObject[key] = []
          return
        }

        await requestAction({
          action: () => postUpload(files),
          afterResponse: (result = []) => {
            responseFilesObject[key] = result
          },
          showToastSucess: false,
          codeCheck: false,
        })

        return
      })
    )

    return responseFilesObject
  }

  const _getDataWithUpload = async () => {
    const cloneTasks = _getConvertData()
    const responseFilesObject = await _upload()
    return (cloneTasks || []).map(task =>
      toDecamelizeKeys({
        ...(task?.data || {}),
        attachmentFileIds: [
          ...(task?.data?.attachmentFileIds || []),
          ...(responseFilesObject[task.id] || []).map(file => file.id),
        ],
      })
    )
  }

  const submitCreate = async () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setIsSubmitLoading, true)
        _validate()
      },
      action: async () => {
        const data = await _getDataWithUpload()
        return postWorkIndividual(data?.[0])
      },
      afterResponse: () => {
        return onClose()
      },
      afterAction: () => {
        _mountedSet(setIsSubmitLoading, false)
      },
      successCode: 201 || 200,
      successMessage: Toast.SUCCESS('Thực hiện công việc'),
      errorMessage: Toast.FAIL('Thực hiện công việc'),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  // Tách files đính kèm từ công văn và files từ ngoài công văn
  const attachmentFilesFromDocument = (files, isEoffice) => {
    let filesResult = []
    if (!size(files)) {
      return []
    }

    if (isEoffice) {
      filesResult = files.filter(elm => elm.application === 'eOffice')
    } else {
      filesResult = files.filter(elm => elm.application !== 'eOffice')
    }

    return filesResult
  }

  const _fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => _mountedSet(setTags, items),
    })
  }, [])

  const onChangeFile = file => {
    if (file?.id) {
      const paramsDownLoadFile = `ID_NV=${tokenDO?.nhanVien?.idNv}&ID_DV=${tokenDO?.nhanVien?.idDv}&ID_LOAI_VB=${id}`
      const url = `${DO_DOWNLOAD_FILE}?ID_FILE=${file.id}&${paramsDownLoadFile}`
      downloadFile(url, file?.name, token?.accessToken)
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (!isEmpty(documentInfo)) {
      setTasks(prev =>
        prev?.map(e => ({
          ...e,
          data: {
            ...e?.data,
            title: documentInfo?.trichYeu,
          },
        }))
      )
    }
  }, [documentInfo])

  useEffect(() => {
    _fetchDsTag()
  }, [_fetchDsTag])

  return (
    <>
      <div className="cpc-form p15">
        {tasks.map(({ id, showTask, showExtra, data }, index) => (
          <Fragment key={id}>
            {!!index && (
              <span
                style={{
                  display: 'block',
                  height: 5,
                  background: '#DFE6EA',
                }}
              />
            )}
            <div>
              {size(tasks) > 1 && (
                <header className="d-flex">
                  <Button
                    className={classNames(
                      Classes.MINIMAL,
                      'cpc-button',
                      'cpc-form-button-left',
                      'font-weight-600',
                      'uppercase',
                      'text-left'
                    )}
                    intent={Intent.PRIMARY}
                    onClick={toggleCollapseTask(id)}
                    style={{ lineHeight: 1.3 }}
                  >
                    {`Công việc ${index + 1}`}
                    <Icon
                      classIcon={
                        showTask ? 'icon2-arrow-down' : 'icon2-arrow-up'
                      }
                      className={classNames('ml5', 'size-icon-10', 'd-flex')}
                    />
                  </Button>
                  <Button
                    className={classNames(
                      Classes.MINIMAL,
                      'cpc-button',
                      'cpc-form-button-right'
                    )}
                    intent={Intent.DANGER}
                    onClick={removeTask(id)}
                  >
                    <Icon classIcon="icon-bin" />
                  </Button>
                </header>
              )}

              <Collapse isOpen={showTask}>
                <Input
                  required
                  disabled={false}
                  label="Tiêu đề"
                  onChange={changeField(id, 'title')}
                  placeholder="Nhập tiêu đề/trích yếu"
                  type="textarea"
                  rows={2}
                  maxLength={500}
                  autoResize={true}
                  value={get(data, 'title')}
                  wrapperClassName="mb15"
                  warning={get(validateErrors, `${id}.title`)}
                />
                <table className="table-perform mb10">
                  <tbody>
                    <tr>
                      <td>Thực hiện</td>
                      <td>CT</td>
                    </tr>
                    <tr>
                      <td>{user?.tenNhanVien}</td>
                      <td>
                        <Radio
                          className={classNames(Classes.SMALL, 'p0 m0')}
                          style={{ display: 'inline-block', width: '16px' }}
                          checked={true}
                          onChange={() => {}}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ToDoList
                  allowAction={true}
                  onChange={e => onChangeTodo(id, e)}
                />
                <Row gutterVertical className="mb10">
                  <Col grid="half">
                    <FormDatePicker
                      textLabel="Ngày bắt đầu"
                      disabled={false}
                      inputClearable
                      inputIconClassName="icon2-date-frames"
                      inputWrapperClassName="mb10"
                      onChange={value => {
                        changeField(id, 'executionDate')(value)
                      }}
                      minimumDate={moment().toDate()}
                      maximumDate={
                        get(data, 'deadline')
                          ? moment(get(data, 'deadline')).toDate()
                          : undefined
                      }
                      placeholder="Chọn ngày bắt đầu"
                      popoverStretch={false}
                      popoverPosition={Position.TOP_LEFT}
                      selected={get(data, 'executionDate')}
                    />
                  </Col>
                  <Col grid="half">
                    <DateAdvanced
                      textLabel="Hạn thực hiện"
                      disabled={false}
                      inputClearable
                      inputIconClassName="icon2-date-frames"
                      inputWrapperClassName="mb10"
                      placeholder="Chọn hạn thực hiện"
                      popoverStretch={false}
                      selected={get(data, 'deadline')}
                      inputWarning={get(validateErrors, `${id}.deadline`)}
                      minimumDate={
                        get(data, 'executionDate')
                          ? moment(get(data, 'executionDate')).toDate()
                          : moment().toDate()
                      }
                      popoverPosition={Position.TOP_RIGHT}
                      onChange={value => {
                        changeField(id, 'deadline')(value)
                      }}
                    />
                  </Col>
                </Row>

                {/* Thông tin bổ sung */}
                <Collapse isOpen={showExtra}>
                  <Input
                    disabled={false}
                    label="Mô tả"
                    onChange={changeField(id, 'description')}
                    placeholder="Nhập..."
                    rows={2}
                    maxLength={500}
                    autoResize={true}
                    style={{ maxHeight: 'unset' }}
                    type="textarea"
                    value={get(data, 'description')}
                    wrapperClassName="mb10"
                  />
                  <Row gutterVertical className="mb10">
                    <Col grid="half">
                      <Select
                        textLabel="Mức độ ưu tiên"
                        dataSource={toPairs(priorities).map(
                          ([value, label]) => ({ value, label })
                        )}
                        disabled={false}
                        inputIconClassName="icon-flag"
                        onChange={changeField(id, 'priority')}
                        placeholder="Chọn mức độ ưu tiên"
                        value={get(data, 'priority')}
                      />
                    </Col>
                    <Col grid="half">
                      <RemindSelect
                        textLabel="Thời gian nhắc việc"
                        inputIconClassName="icon2-date-frames"
                        deadline={get(data, 'deadline')}
                        disabled={false}
                        onChange={changeField(id, 'workReminder')}
                        value={get(data, 'workReminder')}
                        placeholder="Thời gian nhắc việc"
                        popoverPosition={Position.BOTTOM_RIGHT}
                      />
                    </Col>
                  </Row>

                  <CheckboxSelect
                    textLabel="Loại công việc"
                    disabled={false}
                    inputClearable
                    inputIconClassName="icon2-tag"
                    inputWrapperClassName="mb10"
                    placeholder="Loại công việc"
                    onChange={changeField(id, 'tags')}
                    dataSource={tags.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    value={get(data, 'tags', [])}
                  />

                  {!!size(fileCongVanDO) && (
                    <>
                      <label className="font-size-13 font-weight-600">
                        File công văn
                      </label>
                      <FileGroup
                        list={fileCongVanDO}
                        showClose={false}
                        isLink={false}
                        onChange={onChangeFile}
                      />
                    </>
                  )}

                  {!!size(fileDinhKemDO) && (
                    <>
                      <label className="font-size-13 font-weight-600">
                        File đính kèm từ công văn
                      </label>
                      <FileGroup
                        list={fileDinhKemDO}
                        showClose={false}
                        isLink={false}
                        onChange={onChangeFile}
                      />
                    </>
                  )}

                  {((!!size(data?.attachmentFiles) &&
                    !!size(
                      attachmentFilesFromDocument(
                        get(data, 'attachmentFiles', []),
                        false
                      )
                    )) ||
                    !!size(files[id])) && (
                    <>
                      <label className="font-size-13 font-weight-600">
                        File đính kèm từ bên ngoài
                      </label>
                      <FileGroup
                        list={attachmentFilesFromDocument(
                          get(data, 'attachmentFiles', []),
                          false
                        )}
                        listUpload={files[id]}
                        onClose={removeAttachmentFiles(id)}
                        showClose
                      />
                    </>
                  )}

                  <Upload
                    textLabel="File đính kèm"
                    accept={documentTypes.toString()}
                    fileList={get(files, id, [])}
                    wrapperClassName="mb10"
                    textSumary={
                      <p className="mt5 font-style-italic font-size-12">
                        Tải file có dung lượng tối đa 100MB, định dạng .pdf,
                        .docs, .doc, .xls, .xlsx, .ppt, .pptx
                      </p>
                    }
                    isShowList={false}
                    disabled={false}
                    allowFileTypes={allowDocumentFileTypes}
                    onChange={changeFile(id)}
                  />
                </Collapse>

                <Button
                  className={classNames(
                    Classes.MINIMAL,
                    'cpc-button',
                    'cpc-form-button-left'
                  )}
                  intent={Intent.PRIMARY}
                  onClick={toggleCollapseExtra(id)}
                >
                  {showExtra ? 'Thu gọn' : 'Mở rộng'}
                  <Icon
                    classIcon={
                      showExtra ? 'icon2-arrow-up' : 'icon2-arrow-down'
                    }
                    className={classNames('ml5', 'size-icon-10', 'd-flex')}
                  />
                </Button>
              </Collapse>
            </div>
          </Fragment>
        ))}
      </div>
      <div className={classNames('element-center', 'pv10 ph15', 'border-top')}>
        <Button
          className={classNames(
            'cpc-button',
            'btn-cancel',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-100',
            'mr15'
          )}
          disabled={false}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={classNames(
            'cpc-button',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-100'
          )}
          intent={Intent.PRIMARY}
          onClick={submitCreate}
          loading={isSubmitLoading}
        >
          Lưu
          <Icon classIcon="icon-save" className="ml5" />
        </Button>
      </div>
      <Dialog
        buttonUppercase
        isOpen={isShowDialog}
        cancelClassName="pl20 pr20"
        cancelText="Hủy"
        confirmClassName="pl20 pr20"
        confirmText="Xóa"
        onCancel={onCloseDialog}
        onConfirm={onDeleteTask}
        onClose={onCloseDialog}
        title={CONFIRM_TITLE}
        textCenter
      >
        <Card className="mt20 mb20">{CONFIRM_CONTENT}</Card>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  const roleId = state?.auth?.roleId
  const mainDepartment = state?.auth?.user?.dsChucDanh?.find(
    elm => elm?.id === roleId
  )
  const hasShowAll = get(state, 'auth.permission', []).includes(
    Permission.CONGVANDEN_CHIDAO
  )

  return {
    roleId,
    tokenDO: state?.doffice?.toKenDO,
    user: state?.auth?.user,
    mainUnitId: state?.auth?.mainUnitId,
    hasShowAll,
    mainDepartmentId: get(state, 'auth.mainDepartmentId'),
    mainDepartment,
    listWorkTypes: state?.TaskManager?.listWorkTypes,
  }
}

export default connect(mapStateToProps)(memo(PerformTheWork))
