import { Action, Toast, inProcess } from '../../../constants/MessageForm'
import { Checkbox, Intent, Collapse } from '@blueprintjs/core'
import {
  EmptyDataTable,
  ErrorLoadingTable,
  HeadingTableSort,
  LoadingTable,
  PopupConfirm,
} from '../../../components/common'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { TEXT_BUTTON_SUBMIT, TEXT_SUBMIT_BODY } from '../../../constants/Popup'

import { Link } from 'react-router-dom'
import React from 'react'
import _ from 'lodash'
import { headers } from '../../../constants/Table'
import { withRouter } from 'react-router-dom'

const EDIT_PATH = '/he-thong/quy-trinh/chinh-sua'
const EDIT_GROUP_PATH = id => `/he-thong/quy-trinh/${id}/don-vi-ap-dung`
const DETAILS_PATH = '/he-thong/quy-trinh'

class ProcedureList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.data || [],
      isDataEmpty: props.data && !props.data.length,
      isOpenPopup: false,
      deleteItem: null,
      listDeleteId: [],
      listUpdateId: [],
    }
  }

  goToDetails = procedureId => {
    this.props.history.push(`${DETAILS_PATH}/${procedureId}`)
  }

  setDeleteItem = (item = null) => {
    this.setState({
      deleteItem: item,
    })
  }

  toggleDeletePopup = (item = null) => {
    const { showToast } = this.props
    const { isOpenPopup, listDeleteId } = this.state
    if (!isOpenPopup && !!item) {
      if (!listDeleteId.includes(item.id)) {
        this.setDeleteItem(item)
        return this.setState({
          isOpenPopup: !isOpenPopup,
        })
      } else {
        return showToast({
          message: inProcess,
          intent: Intent.WARNING,
        })
      }
    }
    this.setState({
      isOpenPopup: !isOpenPopup,
      deleteItem: item,
    })
  }

  handleDelete = async () => {
    const { deleteItem, listDeleteId } = this.state
    const { actionDelete, showToast, fetchData } = this.props
    if (deleteItem && deleteItem.quyTrinhId) {
      await this.setState({
        listDeleteId: [...listDeleteId, deleteItem.id],
      })
      actionDelete(deleteItem.quyTrinhId)
        .then(res => {
          const status = !!(_.get(res, 'payload.status') === 200)
          showToast({
            message: status
              ? Toast.SUCCESS(Action.DELETE)
              : Toast.FAIL(Action.DELETE),
            intent: status ? Intent.SUCCESS : Intent.DANGER,
          })
        })
        .finally(() => {
          const { listDeleteId } = this.state
          this.setState({
            deleteItem: null,
            listDeleteId: listDeleteId.filter(id => id !== deleteItem.id),
          })
          fetchData()
        })
    } else {
      showToast({
        message: Toast.FAIL(Action.DELETE),
        intent: Intent.DANGER,
      })
    }
    this.toggleDeletePopup()
  }

  toggleUsing = async item => {
    const { toggleUsing } = this.props
    const { listUpdateId } = this.state
    const isProcessing = listUpdateId.includes(item.id)
    if (!isProcessing) {
      await this.setState({
        listUpdateId: [...listUpdateId, item.quyTrinhId],
      })
      await toggleUsing(item)
      this.setState({
        listUpdateId: this.state.listUpdateId.filter(
          i => i !== item.quyTrinhId
        ),
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({
        data: nextProps.data,
        isDataEmpty: !nextProps.data.length,
      })
    }
  }

  render() {
    const { isLoading, isError, data } = this.props
    const { listUpdateId, isDataEmpty } = this.state
    return (
      <div className="table-scroll">
        <div className="new-table-style">
          <header>
            <HeadingTableSort data={headers.procedure} />
          </header>
          <main>
            {!!isLoading ? (
              <LoadingTable />
            ) : !!isError ? (
              <ErrorLoadingTable />
            ) : !!isDataEmpty ? (
              <EmptyDataTable />
            ) : (
              data.map((item, index) => (
                <ListItem
                  key={index}
                  i={index + 1}
                  data={item}
                  goToDetails={this.goToDetails}
                  toggleDeletePopup={this.toggleDeletePopup}
                  toggleUsing={this.toggleUsing}
                  listUpdateId={listUpdateId}
                />
              ))
            )}
            <PopupConfirm
              isOpen={this.state.isOpenPopup}
              onClose={() => this.toggleDeletePopup()}
              title={`${Action.DELETE} ${
                this.state.deleteItem ? this.state.deleteItem.tenQuyTrinh : ''
              }`}
              text={TEXT_BUTTON_SUBMIT}
              onSubmit={this.handleDelete}
            >
              {TEXT_SUBMIT_BODY}
            </PopupConfirm>
          </main>
        </div>
      </div>
    )
  }
}

class ListItem extends React.Component {
  constructor() {
    super()
    this.state = {
      isShowCollapse: false,
    }
  }
  toggleCollapse = () =>
    this.setState({ isShowCollapse: !this.state.isShowCollapse })
  render() {
    const { i, data, toggleUsing, toggleDeletePopup, listUpdateId } = this.props
    const { isShowCollapse } = this.state
    const hasList = !!_.get(data, 'dsQuyTrinh', []).length
    return (
      hasList && (
        <section className="row">
          <main className="row parent">
            <span className="cell cell-width-5 center-text">
              {isShowCollapse ? (
                <i
                  className="icon-round-delete-button has-action"
                  onClick={this.toggleCollapse}
                />
              ) : (
                <i
                  className="icon-rounded-add-button has-action"
                  onClick={this.toggleCollapse}
                />
              )}
            </span>
            <span className="cell cell-width-5">{i < 10 ? '0' + i : i}</span>
            <span className="cell cell-width-90">{data.tenDanhMuc}</span>
          </main>
          <Collapse isOpen={isShowCollapse}>
            {data.dsQuyTrinh.map((childItem, index) => (
              <main
                className="row child has-action margin-collapse-10"
                key={index}
              >
                <div
                  className="cell cell-width-25"
                  onClick={() => this.props.goToDetails(childItem.quyTrinhId)}
                >
                  {childItem.maQuyTrinh}
                </div>
                <div
                  className="cell cell-width-50"
                  onClick={() => this.props.goToDetails(childItem.quyTrinhId)}
                >
                  {childItem.tenQuyTrinh}
                </div>
                <div className="cell cell-width-10">
                  <Checkbox
                    className="procedure-table-checkbox"
                    checked={childItem.isActive}
                    onChange={() => toggleUsing(childItem)}
                    disabled={listUpdateId.includes(childItem.quyTrinhId)}
                  />
                </div>
                <div className="cell cell-width-5 center-text">
                  <Popover
                    position={Position.TOP_RIGHT}
                    interactionKind={PopoverInteractionKind.HOVER}
                    openOnTargetFocus
                    content={
                      <div className="menu-popover">
                        <Link
                          className="item"
                          to={`${EDIT_PATH}/${childItem.quyTrinhId}`}
                        >
                          <span className="pt-icon pt-icon-annotation" />
                          <span className="name_action">Chỉnh sửa</span>
                        </Link>
                        <Link
                          className="item"
                          to={EDIT_GROUP_PATH(childItem.quyTrinhId)}
                        >
                          <span className="pt-icon icon-Ket_Qua_Bao_Cao" />
                          <span className="name_action">Đơn vị áp dụng</span>
                        </Link>
                        <span
                          className="item"
                          onClick={() => toggleDeletePopup(childItem)}
                        >
                          <span className="pt-icon pt-icon-trash" />
                          <span className="name_action">Xóa</span>
                        </span>
                      </div>
                    }
                  >
                    <i className="icon-More default-color-icon size-icon-20" />
                  </Popover>
                </div>
              </main>
            ))}
          </Collapse>
        </section>
      )
    )
  }
}

export default withRouter(ProcedureList)
