import React from 'react'
import { Paginate } from '../../components/common'

const TablePagination = ({
  pageCount = 0,
  marginPagesDisplayed = 1,
  pageRangeDisplayed = 3,
  pageChange,
  children,
  page,
}) => {
  const onPageChange = ({ selected }) => {
    if (pageChange && typeof pageChange === 'function') {
      pageChange(selected + 1)
    }
  }
  return (
    <div className="footer-list m0 p0">
      {!!(pageCount > 0) && (
        <div>
          {children}
          <Paginate
            forcePage={page - 1}
            initialPage={0}
            pageCount={pageCount}
            marginPagesDisplayed={marginPagesDisplayed}
            pageRangeDisplayed={pageRangeDisplayed}
            onPageChange={onPageChange}
          />
          {/* <ReactPaginate
            pageCount={pageCount}
            marginPagesDisplayed={marginPagesDisplayed}
            pageRangeDisplayed={pageRangeDisplayed}
            onPageChange={onPageChange}
            forcePage={page - 1}
            previousLabel={<span className={`pt-icon ${iconPrev || 'pt-icon-arrow-left'}`}></span>}
            nextLabel={<span className={`pt-icon ${iconNext || 'pt-icon-arrow-right'}`}></span>}
            breakLabel={
              <button className="footer-three-dot">
                <span className={`pt-icon ${iconBreak || 'icon-Group-8'}`}></span>
              </button>
            }
            containerClassName="pagination"
            activeClassName="active"
          /> */}
        </div>
      )}
    </div>
  )
}

export default TablePagination
