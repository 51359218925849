import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Tooltip } from '@blueprintjs/core'

import variables from 'constants/variables'
import { ResetButton, SearchInput, DatePicker } from 'components/newCommon'
import { Select } from 'components/newCommon2'

const FilterThongbaoKLCH = ({ filterKeys, dataValue, onChangeFilter }) => {
  const dsLoaiCuocHop = [
    {
      label: 'Tất cả',
      value: 'ALL',
    },
    {
      label: 'Giao ban tuần',
      value: 'WEEKLY_DELIVERY',
    },
    {
      label: 'Sơ kết/Tổng kết',
      value: 'SUMMARY',
    },
    {
      label: 'Họp tại đơn vị',
      value: 'MEETING_AT_ORGANIZATION',
    },
    {
      label: 'Khác',
      value: 'OTHER',
    },
  ]

  return (
    <div className="bg-white task-manager-filter-layout">
      <div>
        <label className="label">Ngày ban hành</label>
        <div className="date-block">
          <DatePicker
            placeholder="Từ ngày"
            isClearable={true}
            selectsStart
            startDate={
              dataValue?.[filterKeys.fromExecutionDate]
                ? moment(dataValue?.[filterKeys.fromExecutionDate])
                : undefined
            }
            endDate={
              dataValue?.[filterKeys.toExecutionDate]
                ? moment(dataValue?.[filterKeys.toExecutionDate])
                : undefined
            }
            selected={
              dataValue?.[filterKeys.fromExecutionDate]
                ? moment(dataValue?.[filterKeys.fromExecutionDate])
                : undefined
            }
            maxDate={
              dataValue?.[filterKeys.toExecutionDate]
                ? moment(dataValue?.[filterKeys.toExecutionDate])
                : undefined
            }
            onChange={value => {
              const newValue = value
                ? value.format(variables.DATE_FORMAT.DATE)
                : undefined
              onChangeFilter({
                name: filterKeys.fromExecutionDate,
              })(newValue)
            }}
          />
          <DatePicker
            placeholder="Đến ngày"
            isClearable={true}
            selectsEnd
            startDate={
              dataValue?.[filterKeys.fromExecutionDate]
                ? moment(dataValue?.[filterKeys.fromExecutionDate])
                : undefined
            }
            endDate={
              dataValue?.[filterKeys.toExecutionDate]
                ? moment(dataValue?.[filterKeys.toExecutionDate])
                : undefined
            }
            selected={
              dataValue?.[filterKeys.toExecutionDate]
                ? moment(dataValue?.[filterKeys.toExecutionDate])
                : undefined
            }
            minDate={
              dataValue?.[filterKeys.fromExecutionDate]
                ? moment(dataValue?.[filterKeys.fromExecutionDate])
                : undefined
            }
            onChange={value => {
              const newValue = value
                ? value.format(variables.DATE_FORMAT.DATE)
                : undefined
              onChangeFilter({ name: filterKeys.toExecutionDate })(newValue)
            }}
          />
        </div>
      </div>
      <div>
        <label className="label">Ngày tạo</label>
        <div className="date-block">
          <DatePicker
            placeholder="Từ ngày"
            isClearable={true}
            selectsStart
            startDate={
              dataValue?.[filterKeys.fromDate]
                ? moment(dataValue?.[filterKeys.fromDate])
                : undefined
            }
            endDate={
              dataValue?.[filterKeys.toDate]
                ? moment(dataValue?.[filterKeys.toDate])
                : undefined
            }
            selected={
              dataValue?.[filterKeys.fromDate]
                ? moment(dataValue?.[filterKeys.fromDate])
                : undefined
            }
            maxDate={
              dataValue?.[filterKeys.toDate]
                ? moment(dataValue?.[filterKeys.toDate])
                : undefined
            }
            onChange={value => {
              const newValue = value
                ? value.format(variables.DATE_FORMAT.DATE)
                : undefined
              onChangeFilter({
                name: filterKeys.fromDate,
              })(newValue)
            }}
          />
          <DatePicker
            placeholder="Đến ngày"
            isClearable={true}
            selectsEnd
            startDate={
              dataValue?.[filterKeys.fromDate]
                ? moment(dataValue?.[filterKeys.fromDate])
                : undefined
            }
            endDate={
              dataValue?.[filterKeys.toDate]
                ? moment(dataValue?.[filterKeys.toDate])
                : undefined
            }
            selected={
              dataValue?.[filterKeys.toDate]
                ? moment(dataValue?.[filterKeys.toDate])
                : undefined
            }
            minDate={
              dataValue?.[filterKeys.fromDate]
                ? moment(dataValue?.[filterKeys.fromDate])
                : undefined
            }
            onChange={value => {
              const newValue = value
                ? value.format(variables.DATE_FORMAT.DATE)
                : undefined
              onChangeFilter({ name: filterKeys.toDate })(newValue)
            }}
          />
        </div>
      </div>
      <div>
        <label className="label">Loại cuộc họp</label>
        <div className="height-38">
          <Select
            inputWrapperClassName={'border-radius-3 input-not-ph'}
            dataSource={dsLoaiCuocHop}
            inputIconClassName="icon2-documents"
            placeholder="Tất cả trạng thái"
            wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
            inputStyle={{ height: '35px', lineHeight: '35px' }}
            isShowArrowDropdown
            value={dataValue?.[filterKeys.meetingType]}
            onChange={onChangeFilter({ name: filterKeys.meetingType })}
          />
        </div>
      </div>
      <div className="grow-1 search-submit">
        <SearchInput
          placeholder="Tìm kiếm theo Tên cuộc họp/ số hiệu VB"
          onChange={onChangeFilter({
            name: filterKeys.textSearch,
            isReFetch: false,
          })}
          onSearch={onChangeFilter({ name: filterKeys.textSearch })}
          value={dataValue?.[filterKeys.textSearch]}
          leftIcon="icon2-search"
        />
        <Tooltip content={<span className="font-size-12">Làm mới</span>}>
          <ResetButton onClick={onChangeFilter({ name: 'RESET' })} />
        </Tooltip>
      </div>
    </div>
  )
}

FilterThongbaoKLCH.propTypes = {
  filterKeys: PropTypes.object,
  dataSource: PropTypes.object,
  dataValue: PropTypes.object,
  onChangeFilter: PropTypes.func,
}

FilterThongbaoKLCH.defaultProps = {
  filterKeys: null,
  dataSource: null,
  dataValue: null,
  onChangeFilter: () => {},
}

export default FilterThongbaoKLCH
