import { schema } from 'normalizr'

const SignNumberFeedback = new schema.Entity(
  'signNumberFeedbacks',
  {},
  {
    idAttribute: feedback => feedback.gopYId,
  }
)

export default SignNumberFeedback
