import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { Position, Tooltip } from '@blueprintjs/core'
import { Icon } from 'components/newCommon'

const TieuDeXuLy = ({ item, className }) => {
  const processingStatus = () => {
    let content = ''
    switch (item?.tTXULY) {
      case 'Hoàn thành':
        content = (
          <div className="circle-border">
            <span className="checkmark"></span>
          </div>
        )
        break
      case 'Đã chuyển':
        content = <Icon classIcon="icon2-hand" />
        break
      case 'Đang thực hiện':
        content = <Icon classIcon="icon-s-edit" style={{ color: '#f8d46e' }} />
        break
      default:
        break
    }

    if (content) {
      return (
        <Tooltip
          className="mr10"
          content={<span className="font-size-13">{item?.tTXULY}</span>}
          position={Position.TOP}
        >
          {content}
        </Tooltip>
      )
    }
  }

  return (
    <div className={classNames('do-collapse-title pl20', className)}>
      <p className="chuc_vu mr10">{item?.tENPB || ''}</p>
      <p
        className={classNames('vai_tro mr10', {
          red: item?.mAXULY === 'Chủ trì' && item?.kEYXULY,
        })}
      >
        {item?.mAXULY || ''}
      </p>
      {processingStatus()}
      <p className="thoi_gian">
        {item?.nGAYTAO
          ? moment(item?.nGAYTAO)
              .subtract(7, 'hours')
              .format('DD/MM/YYYY HH:mm')
          : ''}
      </p>
      {item?.nDUNGXULY && (
        <div
          className={'mt5 font-roboto'}
          style={{ minWidth: '100%' }}
          dangerouslySetInnerHTML={{
            __html: item?.nDUNGXULY,
          }}
        />
      )}
    </div>
  )
}

export default TieuDeXuLy
