import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Intent, Button } from '@blueprintjs/core'
import Select from 'react-select'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import size from 'lodash/size'

import { downloadFile } from 'helpers/Helper'
import { DO_DOWNLOAD_FILE } from 'constants/Api'
import { getMail, postMailQLCV } from 'actions'
import { showToast } from 'actions/task'
import { postUploadFromDO } from 'actions/doffice'
import {
  TEXT_MESSAGE_TYPE_EMAIL,
  TEXT_MESSAGE_DEFAULT,
  TOATS_MESSAGE_WARNING,
} from 'constants/MessageForm'
import { FileGroup } from 'components/newCommon'
import 'react-select/dist/react-select.css'

class SendMail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      emailTo: '',
      title: '',
      content: '',
      dataSelect: '',
      isOpenPopup: false,
      isLoading: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getOptions = this.getOptions.bind(this)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleMailTo = val => {
    let data = val.filter(item => item.value.trim() !== '')
    this.setState({ emailTo: data })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.emailTo !== this.state.emailTo) {
      this.setState({ error: this.state.error })
      const error = {}
      const re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      this.state.emailTo.forEach(item => {
        if (
          item.value &&
          item.value.trim().length > 0 &&
          !re.test(item.value)
        ) {
          error.validationEmailTo = TEXT_MESSAGE_TYPE_EMAIL
        }
      })
      this.setState({ error: error })
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ isLoading: true })
    const { idVb } = this.props?.documentInfo
    this.setState({ error: this.state.error })
    const error = {}
    const re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (this.state.emailTo.length === 0) {
      error.emailTo = TEXT_MESSAGE_DEFAULT
    }

    for (let index = 0; index < this.state.emailTo.length; index++) {
      const element = this.state.emailTo[index]
      if (
        element.value &&
        element.value.trim().length > 0 &&
        !re.test(element.value.trim())
      ) {
        error.validationEmailTo = TEXT_MESSAGE_TYPE_EMAIL
        break
      }
    }

    if (this.state.title.trim().length === 0) {
      error.title = TEXT_MESSAGE_DEFAULT
    }

    this.setState({ error: error })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      this.props.actions.showToast({
        message: TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
      return
    }

    const response = await postUploadFromDO({
      idVB: idVb,
    })
    const MailTo = []
    this.state.emailTo.map(item => MailTo.push(item.value))
    this.setState({ isLoading: true })
    await this.props.actions
      .postMailQLCV({
        email_to: MailTo,
        title: this.state.title,
        content: this.state.content,
        file_dinh_kem: response?.payload?.data?.result?.map(elm => ({
          file_id: elm.id,
          ten_file: elm.name,
          url: elm.url,
        })),
      })
      .then(res => {
        let msg = ''
        if (res && res.payload && res.payload.status === 200) {
          msg = 'Gửi thành công!'
          this.setState({ isLoading: false })
          this.props.actions.showToast({
            message: msg,
            intent: Intent.SUCCESS,
          })
          this.props.onClose && this.props.onClose()
        } else {
          msg = 'Gửi không thành công'
          this.setState({ isLoading: false })
          this.props.actions.showToast({
            message: msg,
            intent: Intent.DANGER,
          })
        }
      })
  }

  handleTextCreator(input) {
    return `Kết quả tìm kiếm: "${input}"`
  }

  getOptions = (input, callback) => {
    const checkInput = input.indexOf('.')
    if (input.trim().length > 2 && checkInput < 0) {
      if (this.promiseGetEmail) {
        clearTimeout(this.promiseGetEmail)
      }
      this.promiseGetEmail = setTimeout(() => {
        this.props.actions
          .getMail(`${input}`)
          .then(response => {
            return response.payload.data.result.items
          })
          .then(json => {
            const data = json.map(item => ({
              value: item.email,
              label: ['<', item.email, ' ', item.tenNhanVien, '>'],
            }))
            callback(null, {
              options: data,
              complete: true,
            })
          })
      }, 800)
    } else if (input.length < 3) {
      callback(null, {
        options: [],
        complete: true,
      })
    } else {
      callback(null, {
        options: [],
        complete: true,
      })
    }
  }

  onChangeFile = file => {
    const id = this.props?.match?.params?.id
    const tokenDO = this.props.tokenDO
    const token = {
      tokenType: 'Bearer',
      accessToken: tokenDO?.token,
    }
    if (file?.id) {
      const paramsDownLoadFile = `ID_NV=${tokenDO?.nhanVien?.idNv}&ID_DV=${tokenDO?.nhanVien?.idDv}&ID_LOAI_VB=${id}`
      const url = `${DO_DOWNLOAD_FILE}?ID_FILE=${file.id}&${paramsDownLoadFile}`
      downloadFile(url, file?.name, token?.accessToken)
    }
  }

  render() {
    const fileDO = this.props.documentInfo.fileDO
    const fileCongVanDO =
      fileDO
        ?.filter(f => f?.fILECHINH)
        ?.map(e => ({
          id: e?.iDFILE,
          name: e?.tENFILE,
        })) ?? []
    const fileDinhKemDO =
      fileDO
        ?.filter(f => !f?.fILECHINH)
        ?.map(e => ({
          id: e?.iDFILE,
          name: e?.tENFILE,
        })) ?? []
    return (
      <div className="container-form-action p15">
        <div className="form-container container-detail">
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-4 col-xs-12">
                Gửi đến email
                <span className="pt-text-muted required-input">*</span>
              </label>
              <div className="pt-form-content col-md-8 col-xs-12">
                <div className="pt-form-content">
                  <Select.AsyncCreatable
                    name="form-field-name"
                    value={this.state.emailTo}
                    loadOptions={this.getOptions}
                    onChange={this.handleMailTo}
                    autofocus={true}
                    autoload={false}
                    multi={true}
                    placeholder="Nhập email"
                    searchPromptText="Nhập email...."
                    loadingPlaceholder="Đang tìm kiếm...."
                    promptTextCreator={this.handleTextCreator}
                    noResultsText="Không tìm thấy dữ liệu..."
                    clearValueText="Xóa dữ liệu"
                  />
                </div>
                {this.state.error.emailTo !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.emailTo}
                  </div>
                )}
                {this.state.error.validationEmailTo !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.validationEmailTo}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-4 col-xs-12">
                Tiêu đề
                <span className="pt-text-muted required-input">*</span>
              </label>
              <div className="pt-form-content col-md-8 col-xs-12">
                <div className="pt-form-content">
                  <input
                    className="pt-input"
                    placeholder="Nhập tiêu đề"
                    type="text"
                    dir="auto"
                    name="title"
                    onChange={this.handleChange}
                    value={this.state.title}
                  />
                </div>
                {this.state.error.title !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.title}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-4 col-xs-12">Nội dung</label>
              <div className="pt-form-content col-md-8 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="4"
                    className="pt-input pt-fill"
                    placeholder="Nội dung…"
                    dir="auto"
                    name="content"
                    onChange={this.handleChange}
                    value={this.state.content}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            {!!size(fileCongVanDO) && (
              <>
                <label className="font-size-13 font-weight-600">
                  File văn bản
                </label>
                <FileGroup
                  list={fileCongVanDO}
                  showClose={false}
                  isLink={false}
                  onChange={this.onChangeFile}
                />
              </>
            )}
            {!!size(fileDinhKemDO) && (
              <>
                <label className="font-size-13 font-weight-600">Phụ lục</label>
                <FileGroup
                  list={fileDinhKemDO}
                  showClose={false}
                  isLink={false}
                  onChange={this.onChangeFile}
                />
              </>
            )}
          </div>
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClose}
            className="pt-button btn-cancel"
            disabled={this.state?.isLoading || false}
          >
            <span className="pt-icon icon-back"></span>
            Quay lại
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-blue-color"
            loading={this.state?.isLoading || false}
          >
            <span className="text-content">Gửi email</span>
            <span className="pt-icon icon-Send-Email"></span>
          </Button>
        </div>
      </div>
    )
  }
}

SendMail.propTypes = {
  onClose: PropTypes.func.isRequired,
  documentInfo: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state?.auth,
  tokenDO: state?.doffice?.toKenDO,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getMail,
      postMailQLCV,
      showToast,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendMail)
