import React from 'react'
import { Button } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import {
  ListFilter,
  ListItem,
} from '../../components/Utilities/DocumentDigitize'
import { GridView, HeadingPage } from '../../components/common'
import { connect } from 'react-redux'
import * as Actions from '../../actions'
import { headers } from '../../constants/Table'
import FileDownload from 'file-saver'
import { Intent } from '@blueprintjs/core'
import * as Tool from '../../helpers'
import moment from 'moment'

class ListDocumentDigitize extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: {},
      isEmpty: false,
      page: 1,
      isLoading: false,
      isError: false,
      isShowMessengStatistics: true,
    }

    this.submitFilter = this.submitFilter.bind(this)
    this.handleExport = this.handleExport.bind(this)
  }

  componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData(`ListDocumentDigitize`)

    if (preData) {
      state.filter = preData.filter
      this.setState(state)
    }

    if (this.props.location.isCheck) {
      this.submitFilter(this.state.filter)
    }
  }

  submitFilter(e = {}) {
    const filter = Object.assign({}, this.state.filter, e)
    this.setState(
      { filter, isLoading: true, isShowMessengStatistics: false },
      () => {
        Tool.Utils.preData(`ListDocumentDigitize`, { filter })
        this.props.actions
          .getDocumentDigitizi(filter, this.props.config.refreshStatistic)
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true
            }

            if (res.error || (res.payload && res.payload.status !== 200)) {
              isError = true
            }
            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.props.actions.refreshStatistic(true)
            this.setState({ isLoading: false })
          })
      }
    )
  }

  handleExport() {
    const { filter, page } = this.state

    this.setState({ exporting: true }, () => {
      this.props.actions
        .exportDocumentDigitize(filter, page)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          let preData = Tool.Utils.getData(`ListDocumentDigitize`)
          FileDownload.saveAs(
            res.payload.data,
            `thong-ke-van-ban-nhan-dang-${moment(
              preData.filter.start_date
            ).format('L')}-${moment(preData.filter.end_date).format('L')}.xls`
          )
        })
        .then(() => {
          this.setState({ exporting: false })
        })
    })
  }

  render() {
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          {/* Heading */}
          <HeadingPage
            namePage="Thống kê văn bản nhận dạng"
            iconPage="icon-Tong_Hop"
          >
            <div className="button-heading-right pull-right">
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={this.state.exporting}
                disabled={this.state.isShowMessengStatistics}
              />
            </div>
          </HeadingPage>
          {/* Table List */}
          <div className="page-list-container page-list-GeneralDocumentary">
            <ListFilter
              onSubmit={this.submitFilter}
              actions={this.props.actions}
              filter={this.state.filter}
              readOnly={this.props.readOnly}
              isLoading={this.state.isLoading}
            />

            <div className="table-container list-GeneralDocumentary">
              <GridView
                classNames={'pt-table'}
                page={this.state.page}
                headers={headers.listDocumentDigitize}
                itemShape={ListItem}
                data={this.props.documentDigitize.items}
                isEmpty={this.state.isEmpty}
                isLoading={this.state.isLoading}
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError}
                isShowMessengStatistics={this.state.isShowMessengStatistics}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  documentDigitize: {
    ...state.documentDigitize,
    items: state.common.documentDigitize,
  },

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDocumentDigitize)
