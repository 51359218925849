import React from 'react'
import { Classes, Button, Intent, Spinner } from '@blueprintjs/core'
import classNames from 'classnames'

import { Icon } from 'components/newCommon'

const MenuDetail = ({
  actionKeys,
  isLoadingSendChat = false,
  onChange = () => {},
}) => {
  return (
    <div className="menu-detail">
      <div>
        <ul className="row">
          <li className="col-sm-6 col-sm-6 col-md-6">
            <div className="menu-detail__item">
              <Button
                className={classNames(
                  'cpc-button cpc-button-link',
                  Classes.MINIMAL
                )}
                intent={Intent.NONE}
                onClick={onChange(actionKeys.GIVE_TASK.key)}
              >
                <Icon
                  classIcon="icon-Giao_Viec"
                  style={{ width: '25px' }}
                  className="icon-size-14 mr5"
                />
                <span className="font-weight-300 font-size-14">Giao việc</span>
              </Button>
            </div>
          </li>
          <li className="col-sm-6 col-sm-6 col-md-6">
            <div className="menu-detail__item">
              <Button
                className={classNames(
                  'cpc-button cpc-button-link',
                  Classes.MINIMAL
                )}
                intent={Intent.NONE}
                onClick={onChange(actionKeys.PERFORM_THE_WORK.key)}
              >
                <Icon
                  classIcon="icon2-perform"
                  style={{ width: '25px' }}
                  className="icon-size-18 mr5"
                />
                <span className="font-weight-300 font-size-14">
                  Thực hiện công việc
                </span>
              </Button>
            </div>
          </li>
        </ul>
      </div>
      <div className="border-top">
        <ul className="row">
          <li className="col-sm-6 col-sm-6 col-md-6">
            <div className="menu-detail__item">
              <Button
                className={classNames(
                  'cpc-button cpc-button-link',
                  Classes.MINIMAL
                )}
                intent={Intent.NONE}
                onClick={onChange(actionKeys.FLOW_TASK.key)}
              >
                <Icon
                  classIcon="icon-organization"
                  style={{ width: '25px' }}
                  className="icon-size-20 mr5"
                />
                <span className="font-weight-300 font-size-14">
                  Quá trình phân phối
                </span>
              </Button>
            </div>
          </li>
          <li className="col-sm-6 col-sm-6 col-md-6">
            <div className="menu-detail__item">
              <Button
                className={classNames(
                  'cpc-button cpc-button-link',
                  Classes.MINIMAL
                )}
                intent={Intent.NONE}
                onClick={onChange(actionKeys.SEND_EMAIL.key)}
              >
                <Icon
                  classIcon="icon-Gmail"
                  style={{ width: '25px' }}
                  className="icon-size-12 mr5"
                />
                <span className="font-weight-300 font-size-14">Gửi email</span>
              </Button>
            </div>
          </li>
        </ul>
        <div className="border-top">
          <ul className="row">
            <li className="col-sm-6 col-sm-6 col-md-6">
              <div className="menu-detail__item">
                <Button
                  className={classNames(
                    'cpc-button cpc-button-link',
                    Classes.MINIMAL
                  )}
                  intent={Intent.NONE}
                  disabled={isLoadingSendChat || false}
                  onClick={onChange(actionKeys.SEND_CHAT.key)}
                >
                  {isLoadingSendChat ? (
                    <Spinner className="spinner-loading xs mr5" />
                  ) : (
                    <Icon
                      classIcon="icon-speech-bubble"
                      style={{ width: '25px' }}
                      className="icon-size-18 mr5"
                    />
                  )}
                  <span className="font-weight-300 font-size-14">Gửi Chat</span>
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MenuDetail
