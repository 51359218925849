import React, { PureComponent } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { HeadingTableSort } from '../../../components/common'
import { headers } from '../../../constants/Table'
import { Utils } from '../../../helpers'
import { fileType, maxFileSize } from '../../../helpers/validation'
import { ValidateForm } from '../../../constants/MessageForm'
import _ from 'lodash'
import * as Types from '../../../constants/Api'

class UsingGroupMemberList extends PureComponent {
  constructor() {
    super()
    this.state = {
      uploading: false,
      error: {},
    }
  }

  fileUpload = e => {
    if (!e) {
      return
    }
    const { name, value, files } = e.target
    const { error } = this.state
    delete error[name]
    let errorMsg = {}
    const extension = Utils.getExtensionFile(value)
    const file = !!files && files[0]

    if (file) {
      const { size } = file
      if (fileType(extension, ['pdf', 'doc', 'docx', 'xls', 'xlsx'])) {
        errorMsg[name] = ValidateForm.FILE_TYPE([
          'pdf',
          'doc',
          'docx',
          'xls',
          'xlsx',
        ])
      }
      if (maxFileSize(size, 25)) {
        errorMsg[name] = ValidateForm.MAX_FILE_SIZE(25)
      }
      if (!!_.size(errorMsg)) {
        this.setState({ error: errorMsg })
      } else {
        this.props.addGroupFile(file)
        this.setState({ uploading: true })
      }
    }
  }

  render() {
    const {
      data = [],
      removeMember,
      addMember,
      groupFile,
      index,
      token,
      removeGroupFile,
    } = this.props
    const { uploading, error } = this.state
    let path = ''
    if (groupFile) {
      path = groupFile.migrated
        ? `${Types.OLD_DOCUMENT_URL}${groupFile.url}&BearerToken=${token}`
        : `${Types.FILE_URL}/api/file/get/${groupFile.fileId}?BearerToken=${token}`
    }
    return (
      !!data && (
        <div>
          <div className="group-header no-justify">
            <span className="group-input-button primary-color">
              <input
                type="file"
                name="groupFile"
                className="group-file"
                id={`group-file-${index}`}
                onChange={this.fileUpload}
              />
              <label htmlFor={`group-file-${index}`}>
                <i className="icon-upload1" />
                Đính kèm file
              </label>
            </span>
            <div className="group-file-display">
              {groupFile && (
                <a
                  style={{
                    backgroundImage: `url(${Utils.typeFile(
                      groupFile.kieuFile.slice(1).toLowerCase()
                    )})`,
                  }}
                  className="link-file"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={path}
                >
                  {groupFile.tenFile} - {Utils.getFileSize(groupFile.kichThuoc)}
                </a>
              )}
              {groupFile && (
                <i className="icon-close" onClick={removeGroupFile} />
              )}
              {uploading && (
                <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                  <div className="pt-progress-meter" />
                </div>
              )}
              {error.groupFile && (
                <div className="validate-error-text">{error.groupFile}</div>
              )}
            </div>
          </div>
          <div className="group-header">
            <div className="group-name">Thông tin thành viên</div>
            <div className="action-button-group">
              <button className="action-button add-btn" onClick={addMember}>
                <i className="pt-icon icon-Plus" />
                THÊM THÀNH VIÊN
              </button>
            </div>
          </div>
          {!!data.length && (
            <HeadingTableSort data={headers.procedureUsingGroupDetail} />
          )}
          {!!data.length && (
            <SortableList
              helperClass="SortableHelper"
              list={data}
              removeMember={removeMember}
            />
          )}
        </div>
      )
    )
  }
}

const SortableList = SortableContainer(({ list, ...props }) => (
  <div className="table-display sortableList">
    {!!list &&
      list.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          i={index + 1}
          index={index}
          item={item}
          disabled
          {...props}
        />
      ))}
  </div>
))

const SortableItem = SortableElement(({ i, item, removeMember }) => (
  <div className="table-rows no-event">
    <div className="table-cols-content width-col-5">{i < 10 ? '0' + i : i}</div>
    <div className="table-cols-content width-col-20">{item.tenNhanVien}</div>
    <div className="table-cols-content width-col-30">{item.tenDonVi}</div>
    <div className="table-cols-content width-col-40">{item.tenPhongBan}</div>
    <div className="table-cols-content width-col-5">
      <button
        className="delete-member-btn"
        onClick={() => removeMember(item.id)}
      >
        <span className="pt-icon pt-icon-trash"></span>
      </button>
    </div>
  </div>
))

export default UsingGroupMemberList
