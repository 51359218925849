import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'

const CHUA_XEM = 0
const CONG_VAN_DEN = 1
class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  // refHandlers = {
  //   toaster: (ref: Toaster) => this.toaster = ref
  // };

  handleClick = () => {
    const nguonXemDeBiet =
      this.props.item && this.props.item.nguonXemDeBiet === CONG_VAN_DEN
        ? 'cong-van'
        : 'cong-viec'
    const xemDeBietId =
      this.props.item &&
      this.props.item.trangThai === CHUA_XEM &&
      this.props.item.xemDeBietId
    if (xemDeBietId) {
      this.props.actions.updateSeeToKnowWork(xemDeBietId).then(res => {
        this.props.actions.getNumberWorkPage()
      })
    }
    return this.props.history.push(
      `/cong-viec/ca-nhan/xem-de-biet/${nguonXemDeBiet}/chi-tiet/${this.props.item.id}`
    )
  }

  render() {
    return (
      <tr>
        <td
          onClick={this.handleClick}
          className={classnames({
            tr_tbody: this.props.item.trangThai === CHUA_XEM,
          })}
        >
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td
          onClick={this.handleClick}
          className={classnames({
            tr_tbody: this.props.item.trangThai === CHUA_XEM,
          })}
        >
          {this.props.item.soCongVan}
        </td>
        <td
          onClick={this.handleClick}
          className={classnames('textarea-content', {
            tr_tbody: this.props.item.trangThai === CHUA_XEM,
          })}
        >
          {this.props.item.trichYeu}
        </td>
        <td
          onClick={this.handleClick}
          className={classnames({
            tr_tbody: this.props.item.trangThai === CHUA_XEM,
          })}
        >
          {this.props.item.ngayGiao
            ? moment(this.props.item.ngayGiao).format('DD/MM/YYYY')
            : ''}
        </td>
        <td
          onClick={this.handleClick}
          className={classnames({
            tr_tbody: this.props.item.trangThai === CHUA_XEM,
          })}
        >
          {this.props.item.chuTri}
        </td>
        <td
          onClick={this.handleClick}
          className={classnames({
            tr_tbody: this.props.item.trangThai === CHUA_XEM,
          })}
        >
          <span className="container-text-content">
            {this.props.item.nguoiGiao}
          </span>
        </td>
        <td
          onClick={this.handleClick}
          className={classnames({
            tr_tbody: this.props.item.trangThai === CHUA_XEM,
          })}
        >
          <span className="container-text-content">
            {this.props.item.trangThai === CHUA_XEM ? 'Chưa đọc' : 'Đã đọc'}
          </span>
        </td>
      </tr>
    )
  }
}

export default ListItem
