import React from 'react'

class GroupItem extends React.Component {
  render() {
    return (
      <div className="collapse-block">
        <div className="collapse-heading-content">
          <h4 className="table-title">{this.props.name}</h4>
        </div>
        <div className="collapse-panel-content">
          <div className="table-display">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default GroupItem
