import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import { PopupConfirm } from '../common/'
import * as POPUP from '../../constants/Popup'
import classnames from 'classnames'

const LDPB_CHUA_DUYET = 0
const KHONG_DUYET = 1
const LDDV_CHUA_DUYET = 2
const THU_KY_CHUA_DUYET = 4
const DANG_GOP_Y = 3
const DA_HOAN_THANH = 9

class ListItemPhieuGoiYeuCau extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
  }

  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  }

  state = {
    isRedirect: false,
    isOpenPopup: false,
  }

  handleClick = () => {
    this.setState({
      isRedirect: true,
    })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = (lyDo, e) => {
    e.preventDefault()
    this.props.onHandleSubmitRemoveItem(lyDo, this.props.item.id)
  }

  render() {
    const { item, number } = this.props
    if (!item || !number) {
      return
    }
    const nhanVienIdNguoiTao =
      item.nguoiLap && item.nguoiLap.nhanVienId ? item.nguoiLap.nhanVienId : ''
    const nhanVienId = this.props.auth.user.nhanVienId
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={`/tien-ich/lay-y-kien-don-vi/chi-tiet-goi/${item.id}`}
        />
      )
    }
    return (
      <tr>
        <td onClick={this.handleClick}>{number > 9 ? number : '0' + number}</td>
        <td onClick={this.handleClick}>{item.soKyHieu ? item.soKyHieu : ''}</td>
        <td onClick={this.handleClick}>{item.trichYeu ? item.trichYeu : ''}</td>
        <td onClick={this.handleClick}>
          {item.ngayLap ? moment(item.ngayLap).format('DD/MM/YYYY') : ''}
        </td>
        <td onClick={this.handleClick}>
          {item.thoiHan ? moment(item.thoiHan).format('DD/MM/YYYY') : ''}
        </td>
        <td onClick={this.handleClick}>
          {item.nguoiLap && item.nguoiLap.tenNhanVien}
        </td>
        <td onClick={this.handleClick} className="textarea-content">
          <span
            className={classnames('status-job position-relative', {
              'ldpb-chuaduyet': item.tinhTrang === LDPB_CHUA_DUYET,
              'thu-ky-chuaduyet': item.tinhTrang === THU_KY_CHUA_DUYET,
              'lddv-chuaduyet': item.tinhTrang === LDDV_CHUA_DUYET,
              'khong-duyet': item.tinhTrang === KHONG_DUYET,
              'dang-gop-y': item.tinhTrang === DANG_GOP_Y,
              'da-gop-y': item.tinhTrang === DA_HOAN_THANH,
            })}
          >
            {item.tinhTrang === LDPB_CHUA_DUYET
              ? 'LĐPB Chưa duyệt'
              : item.tinhTrang === THU_KY_CHUA_DUYET
              ? 'TK Chưa duyệt'
              : item.tinhTrang === KHONG_DUYET
              ? 'Không duyệt'
              : item.tinhTrang === LDDV_CHUA_DUYET
              ? 'LĐĐV chưa duyệt'
              : item.tinhTrang === DANG_GOP_Y
              ? 'Đang góp ý'
              : item.tinhTrang === DA_HOAN_THANH
              ? 'Đã hoàn thành'
              : ''}
          </span>
        </td>
        {(item.tinhTrang === LDPB_CHUA_DUYET ||
          item.tinhTrang === THU_KY_CHUA_DUYET ||
          item.tinhTrang === LDDV_CHUA_DUYET ||
          item.tinhTrang === KHONG_DUYET) &&
        nhanVienIdNguoiTao === nhanVienId ? (
          <td>
            <Popover
              popoverClassName="pt-popover-content-sizing"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  <Link
                    className="item"
                    to={`/tien-ich/lay-y-kien-don-vi/chinh-sua/${item.id}`}
                  >
                    <span className="pt-icon pt-icon-annotation"></span>
                    <span className="name_action">Chỉnh sửa</span>
                  </Link>
                  <span className="item" onClick={this.handleClickPopup}>
                    <span className="pt-icon pt-icon-trash"></span>
                    <span className="name_action">Xóa</span>
                  </span>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
            <PopupConfirm
              isOpen={this.state.isOpenPopup}
              textarea={true}
              isCheck={true}
              onClose={this.handleClickPopup}
              title={POPUP.XOA_PHIEU_YEU_CAU_GOP_Y}
              text={POPUP.TEXT_BUTTON_SUBMIT}
              onSubmit={this.handleSubmitRemoveItem}
              isLoading={this.props.isLoadingXoaPhieuYeuCau}
            >
              {POPUP.TEXT_SUBMIT_BODY}
            </PopupConfirm>
          </td>
        ) : (
          <td className="none-status">&nbsp;</td>
        )}
      </tr>
    )
  }
}

export default ListItemPhieuGoiYeuCau
