import React from 'react'
import {
  ResponsiveContainer,
  BarChart as BChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  Cell,
  LabelList,
  Tooltip,
} from 'recharts'

const BarChart = ({ history, width = '100%', height = 200, data = [] }) => {
  const goPath = path => {
    return history.push(path)
  }

  const CustomTooltip = ({ active, payload }) => {
    const customTitle = value => {
      let result = ''
      switch (value) {
        case 'KSD':
          result = 'Đi ký số'
          break
        case 'KSNB':
          result = 'Nội bộ ký số'
          break
        case 'KSMR':
          result = 'Ký số mở rộng'
          break
        case 'KSTT':
          result = 'Ký số tập trung'
          break
        default:
          break
      }

      return result
    }

    if (active && payload && payload.length) {
      return (
        <div className="bg-white border-secondary p10">
          <p className="label no-wrap">{`${customTitle(
            payload?.[0]?.payload?.name
          )} : ${payload?.[0]?.value}`}</p>
        </div>
      )
    }

    return null
  }

  const renderCustomizedLabel = props => {
    const { x, y, width, value, fill } = props
    const radius = 10
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill={fill}
          stroke={fill}
          strokeWidth={0}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    )
  }

  const onClickBarChart = value => {
    const res = value?.activePayload?.[0]?.payload
    return goPath(res?.path)
  }

  const onClickX = (value, index) => {
    const res = data?.find((_, key) => index === key)
    return goPath(res?.path)
  }

  const CustomizedYAxisTick = ({ x, y, stroke, payload }) => {
    const keyValue = payload?.value
    const customTitle = (value, isTop = true) => {
      let title1 = ''
      let title2 = ''
      switch (value) {
        case 'KSD':
          title1 = 'Đi'
          title2 = 'ký số'
          break
        case 'KSNB':
          title1 = 'Nội bộ'
          title2 = 'ký số'
          break
        case 'KSMR':
          title1 = 'Ký số'
          title2 = 'mở rộng'
          break
        case 'KSTT':
          title1 = 'Ký số'
          title2 = 'tập trung'
          break
        default:
          break
      }

      if (isTop) {
        return title1
      } else {
        return title2
      }
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={15}
          width={50}
          textAnchor="middle"
          fill="rgba(0,0,0,0.7)"
          strokeWidth="0"
          stroke="transparent"
        >
          <tspan textAnchor="middle" x="0">
            {customTitle(keyValue)}
          </tspan>
          <tspan textAnchor="middle" x="0" dy="15">
            {customTitle(keyValue, false)}
          </tspan>
        </text>
      </g>
    )
  }

  return (
    <ResponsiveContainer width={width} height={height}>
      <BChart
        cursor="pointer"
        data={data}
        height={height}
        margin={{ top: 0, right: 0, bottom: 30, left: 0 }}
        barCategoryGap={10}
        onClick={onClickBarChart}
      >
        <YAxis
          type="number"
          padding={{ top: 20 }}
          allowDecimals={false}
          tick={{
            fill: 'rgba(0,0,0,0.4)',
            strokeWidth: 0,
            stroke: 'transparent',
          }}
        />
        <XAxis
          dataKey="name"
          axisLine={false}
          tick={<CustomizedYAxisTick />}
          onClick={onClickX}
        />
        <CartesianGrid vertical={false} strokeDasharray="1 1" />
        <Tooltip cursor={false} content={<CustomTooltip />} />
        <Bar dataKey="value" fill="#8884d8" maxBarSize={45}>
          {data.map((elm, index) => (
            <Cell key={index} fill={elm?.backgroundColor} />
          ))}
          {data.map((elm, index) => (
            <LabelList
              key={index}
              dataKey="value"
              content={renderCustomizedLabel}
            />
          ))}
        </Bar>
      </BChart>
    </ResponsiveContainer>
  )
}

export default BarChart
