import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { PopupConfirm } from '../../common/'
import * as POPUP from '../../../constants/Popup'
import { Checkbox } from '@blueprintjs/core'
import * as MESSAGER from '../../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  state = {
    isOpenPopup: false,
    error: '',
    isActive: null,
    duAnId: null,
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })

    const id = this.props.item.duAnId
    if (id && this.props.onHandleSubmitRemoveItem) {
      this.props.onHandleSubmitRemoveItem(id)
    }
  }

  handleEnabledChange = (value, e) => {
    if (e.target.type === 'checkbox') {
      this.setState(
        {
          isActive: e.target.checked,
          duAnId: value.duAnId,
        },
        () => {
          this.props.actions
            .updateProjectStatus(this.state.duAnId, this.state.isActive)
            .then(res => {
              if (
                res.payload &&
                res.payload.data.result &&
                res.payload.data.result === true
              ) {
                this.props.handleCheckBox && this.props.handleCheckBox()
                return this.props.actions.commonAddToasterMessage({
                  message: MESSAGER.TOATS_MESSAGE_SUCCESS,
                  intent: Intent.SUCCESS,
                })
              } else {
                this.props.actions.commonAddToasterMessage({
                  message: MESSAGER.TOATS_MESSAGE_FAIL,
                  intent: Intent.DANGER,
                })
              }
            })
        }
      )
    }
  }

  handleClick = () => {
    if (!this.props.item) {
      return
    }
    this.props.onClickDetail &&
      this.props.onClickDetail({
        duAnId: this.props.item.duAnId,
      })
  }

  render() {
    if (!this.props.item) {
      return
    }
    const { item } = this.props
    return (
      <tr>
        <td onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick}>{item && item.maDuAn}</td>
        <td onClick={this.handleClick}>{item && item.tenDuAn}</td>
        <td className="gerenal-list-utilities">
          <span
            className="text-content"
            style={{ display: '-webkit-inline-box' }}
          >
            <Checkbox
              className="checkbox-item"
              onChange={this.handleEnabledChange.bind(this, item)}
              checked={item.isActive}
            />
          </span>
        </td>
        <td className="icon-list-project">
          <Popover
            popoverClassName="pt-popover-content-sizing"
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            openOnTargetFocus={true}
            content={
              <div className="menu-popover">
                <Link
                  className="item"
                  to={`/ky-so-mo-rong/du-an/chinh-sua/${item.duAnId}`}
                >
                  <span className="pt-icon pt-icon-annotation"></span>
                  <span className="name_action">Chỉnh sửa</span>
                </Link>
                <span className="item" onClick={this.handleClickPopup}>
                  <span className="pt-icon pt-icon-trash"></span>
                  <span className="name_action">Xóa</span>
                </span>
              </div>
            }
          >
            <span className="icon-More"></span>
          </Popover>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClickPopup}
            title={POPUP.XOA_DU_AN}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleSubmitRemoveItem}
          >
            {POPUP.TEXT_SUBMIT_BODY}
          </PopupConfirm>
        </td>
      </tr>
    )
  }
}

export default ListItem
