import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { size, get } from 'lodash'
import { Cookies, withCookies } from 'react-cookie'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'

import { Permission } from 'components/auth'
import { MasterLayout } from '../../components/layout'
import { getDashboard, getWidgetHome } from 'actions'
import {
  getThongKeTrangChu,
  getDanhSachSlider,
  getWorksStatisticsDashboard,
  getDSPhieuLayYKien,
  getDSLayYKienDonVi,
} from 'actions/task'
import { LoadingItem } from 'components/common'
import { FILE_URL, DO_URL, FE_CHAT_URL } from 'constants/Api'
import Background from './Home/Background'
import HomePageOld from './HomeCard/HomePageOld'
import HomePageNew from './HomeCard/HomePageNew'
import HeaderInfo from './HomeCard/HeaderInfo'
import Notification from 'components/layout/Notification'
import { checkPermissionMenu } from 'helpers/Helper'
import { Tooltip, Position } from '@blueprintjs/core'

const cookies = new Cookies()

const dataExample = [
  {
    feature: 'DOCUMENTS',
    data: [
      {
        type: 'REFERENCE_NOT_HANDLED',
        name: 'Chưa xử lý',
        value: 0,
        display: null,
        url: null,
      },
      {
        type: 'REFERENCE_SIGNING',
        name: 'Đi ký số',
        value: 0,
        display: null,
        url: null,
      },
      {
        type: 'INTERNAL_DOCUMENT_SIGNING',
        name: 'Nội bộ ký số',
        value: 0,
        display: null,
        url: null,
      },
    ],
  },
  {
    feature: 'WORKS_MANAGE',
    data: [
      {
        type: 'WORK_MANAGE_NEW',
        name: 'Chưa thực hiện',
        value: 0,
        display: null,
        url: null,
      },
      {
        type: 'WORK_MANAGE_DOING',
        name: 'Đang thực hiên',
        value: 0,
        display: null,
        url: null,
      },
    ],
  },
  {
    feature: 'WORKS',
    data: [
      {
        type: 'WORK_NOT_ASSIGNED',
        name: 'Chưa giao',
        value: 0,
        display: null,
        url: null,
      },
      {
        type: 'WORK_NEW',
        name: 'Chưa thực hiện',
        value: 0,
        display: null,
        url: null,
      },
    ],
  },
  {
    feature: 'SIGNING',
    data: [
      {
        type: 'CONCENTRATE_SIGNING',
        name: 'Ký số tập trung',
        value: 0,
        display: null,
        url: null,
      },
      {
        type: 'EXPAND_SIGNING',
        name: 'Ký số mở rộng',
        value: 0,
        display: null,
        url: null,
      },
    ],
  },
  {
    feature: 'FEEDBACK',
    data: [
      {
        type: 'SENT_FEEDBACK_NOT_HANDLED',
        name: 'Gửi góp ý',
        value: 0,
        display: null,
        url: null,
      },
      {
        type: 'RECEIVE_FEEDBACK_NOT_HANDLED',
        name: 'Nhận góp ý',
        value: 0,
        display: null,
        url: null,
      },
    ],
  },
]

var settings = {
  arrows: false,
  centerPadding: '0px',
  dots: true,
  fade: false,
  speed: 1000,
  autoPlaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  infinite: true,
}
class HomePage extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isLoading: false,
      dataStatistics: size(props.statisticsHomePage)
        ? props.statisticsHomePage
        : [],
      dataSliders: [],
      isNewHomePage: false,
      isLeftNav: false,
    }
    this.handleClickDigital = this.handleClickDigital.bind(this)
    this.handleOpenChat = this.handleOpenChat.bind(this)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const quyensMenu = props.quyensMenu
    if (size(props.statisticsHomePage)) {
      this.setState({
        dataStatistics: props.statisticsHomePage,
      })
    }

    if (cookies.get('theme_menu') && cookies.get('theme_menu') === 'VERTICAL') {
      this.setState({ isLeftNav: true })
    } else {
      this.setState({ isLeftNav: false })
    }

    if (
      quyensMenu &&
      size(quyensMenu) !== 0 &&
      !checkPermissionMenu(quyensMenu, [Permission.MENU_SIDEBAR.HOME_DASHBOARD])
    ) {
      this.setState({ isNewHomePage: true })
    }
  }

  getData = async () => {
    this.setState({ isLoading: true })
    try {
      await getThongKeTrangChu()
    } catch (error) {
      this.setState({ dataStatistics: dataExample })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  getDataSlider = async () => {
    let params = {
      Page: 1,
      Limit: 100,
      Sort: 'id',
      isActive: true,
    }
    const response = await getDanhSachSlider(params)
    this.setState({
      dataSliders: get(response, 'payload.data.items', []),
    })
  }

  goLinkSlider = elm => {
    if (!!get(elm, 'url')) {
      window.open(get(elm, 'url'), 'eoffice-slider-url')
    }

    return
  }

  componentDidMount() {
    if (!this.state.isNewHomePage) {
      this.getData()
    }

    this.getDataSlider()
    getWorksStatisticsDashboard({
      Limit: 10,
    })
    getDSPhieuLayYKien({
      page: 1,
      limit: 10,
      canXuLy: true,
    })
    getDSLayYKienDonVi({
      page: 1,
      limit: 10,
    })
    this.props.actions.getDashboard({
      limit: 10,
    })

    if (this.props.auth?.user?.nhanVienId) {
      this.props.actions.getWidgetHome(this.props.auth?.user?.nhanVienId)
    }
  }

  handleClickDigital() {
    window.open(DO_URL, '_blank')
  }

  handleOpenChat() {
    const { auth } = this.props

    const url =
      FE_CHAT_URL +
      `?accessToken=${auth.token.accessToken}&expiresIn=${auth.token.expiresIn}&tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}`
    window.open(url, 'eoffice-chat')
  }

  render() {
    const bgImage = `${process.env.PUBLIC_URL}/images/home-bg.png`
    const { dataStatistics, dataSliders, isLoading, isNewHomePage, isLeftNav } =
      this.state
    const {
      auth,
      history,
      notifications,
      numberSideBar,
      statisticsDashboard,
      listDashboard,
      listLayYKienDonVi,
      listPhieuLayYKien,
    } = this.props
    const token = get(auth, 'token.accessToken', '')

    return (
      <MasterLayout typeSidebar="HomePage">
        <div
          ref={el => (this.header = el)}
          className="dashboard-wrapper"
          style={{
            backgroundImage: `url(${
              get(cookies.get('home_background_image'), 'url')
                ? `/backgrounds_home/${get(
                    cookies.get('home_background_image'),
                    'url'
                  )}`
                : bgImage
            })`,
          }}
        >
          <div
            className={classNames('dashboard-container', {
              'dashboard-container-new': isNewHomePage,
            })}
          >
            <div className="wrap-header__info mb20">
              <div>
                {(isNewHomePage || (isLeftNav && !isNewHomePage)) && (
                  <HeaderInfo history={history} isLeftNav={isLeftNav} />
                )}
              </div>
              <div>
                {((isNewHomePage && isLeftNav) ||
                  (!isNewHomePage && isLeftNav)) && (
                  <>
                    <Tooltip
                      position={Position.BOTTOM}
                      content={
                        <span className="font-size-12">
                          Truy cập Digital-Office
                        </span>
                      }
                    >
                      <button
                        className="background-home-icon digital ml15"
                        onClick={this.handleClickDigital}
                      >
                        <span className="icon2-digital-vertical"></span>
                      </button>
                    </Tooltip>
                    <Tooltip
                      position={Position.BOTTOM}
                      content={
                        <span className="font-size-12">Truy cập Chat</span>
                      }
                    >
                      <button
                        className="background-home-icon chat ml15"
                        onClick={this.handleOpenChat}
                      >
                        <span className="icon-chat"></span>
                      </button>
                    </Tooltip>
                    <Tooltip
                      position={Position.BOTTOM}
                      content={<span className="font-size-12">Thông báo</span>}
                    >
                      <Notification
                        classNameIcon="noti-home-icon ml15"
                        history={history}
                        width={
                          this.header && this.header.offsetWidth
                            ? this.header.offsetWidth / 2
                            : 400
                        }
                        notifications={notifications}
                        dataCorporations={notifications?.list?.corporations}
                        dataUnit={notifications?.list?.unit}
                      />
                    </Tooltip>
                  </>
                )}
                <Tooltip
                  position={Position.BOTTOM}
                  content={<span className="font-size-12">Cài đặt</span>}
                >
                  <Background
                    classNameIcon={
                      isNewHomePage || (isLeftNav && !isNewHomePage)
                        ? 'background-home-icon setting'
                        : ''
                    }
                    isCloseBg={true}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="home-row">
              <div className="home-col">
                <div className="home-left--block">
                  {isLoading && (
                    <div className="loading-container">
                      <LoadingItem />
                      <LoadingItem />
                      <LoadingItem />
                    </div>
                  )}
                  {!isLoading && dataSliders.length !== 0 && (
                    <div className="slider-block">
                      <Slider {...settings}>
                        {dataSliders.map((elm, index) => {
                          let bgImageSlider = `${FILE_URL}/api/file/get/${get(
                            elm,
                            'file.id'
                          )}?BearerToken=${token}`
                          return (
                            <div key={index} className="pointer">
                              <div className="slider-item">
                                <div
                                  className="slider-item-image"
                                  style={{
                                    backgroundImage: `url('${bgImageSlider}')`,
                                  }}
                                  onClick={() => this.goLinkSlider(elm)}
                                />
                              </div>
                            </div>
                          )
                        })}
                      </Slider>
                    </div>
                  )}
                  {!isLoading && !isNewHomePage && (
                    <HomePageOld
                      auth={auth}
                      history={history}
                      isLoading={isLoading}
                      dataStatistics={dataStatistics}
                      numberSideBar={numberSideBar}
                      quyensMenu={this.props.quyensMenu}
                    />
                  )}
                  {!isLoading && isNewHomePage && (
                    <HomePageNew
                      auth={auth}
                      history={history}
                      statisticsDashboard={statisticsDashboard}
                      listPhieuLayYKien={listPhieuLayYKien}
                      listLayYKienDonVi={listLayYKienDonVi}
                      numberSideBar={numberSideBar}
                      listDashboard={listDashboard}
                      quyensMenu={this.props.quyensMenu}
                      cauHinhDashboard={this.props.cauHinhDashboard}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

HomePage.propTypes = {
  auth: PropTypes.object,
  statisticsHomePage: PropTypes.array,
  history: PropTypes.object.isRequired,
  quyensMenu: PropTypes.array,
  cauHinhDashboard: PropTypes.array,
}

const mapStateToProps = state => ({
  auth: state.auth,
  notifications: state.notifications,
  statisticsHomePage: state.TaskManager.statisticsHomePage,
  numberSideBar: state.common.numberSideBar,
  statisticsDashboard: state?.TaskManager?.statisticsDashboard,
  quyensMenu: state.common.quyensMenu,
  listDashboard: state.dashboard?.listDashboard,
  listPhieuLayYKien: state.dashboard?.listPhieuLayYKien,
  listLayYKienDonVi: state.dashboard?.listLayYKienDonVi,
  cauHinhDashboard: state.dashboard?.cauHinhDashboard,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDashboard,
      getWidgetHome,
    },
    dispatch
  ),
})

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
)
