import React, { memo, useRef, useState, useEffect } from 'react'
import Upload from 'rc-upload'
import classnames from 'classnames'
import { get, isEqual, find } from 'lodash'
import { FileGroup } from '../index'
import { convertFileToUpload } from 'helpers/file'
import { ValidateForm } from 'constants/MessageForm'
import { allowDocumentFileTypes } from 'constants/fileTypes'

const CustomUpload = ({
  isRequired = false,
  maxSize = 100, // MB
  disabled = false,
  multiple = false,
  isOnly = false,
  accept = '',
  allowFileTypes = allowDocumentFileTypes,
  fileList: value,
  wrapperClassName,
  warning = false,
  textInfo,
  textSumary,
  isShowList = true,
  textLabel = '',
  onChange = () => {},
  ...props
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const upload = useRef()
  const [dragState, setDragState] = useState('drop')
  const [fileList, setFileList] = useState([])
  const [error, setError] = useState(null)

  // validate files extension
  const _validateExtension = fileName => {
    const fileExtension = fileName.split('.').pop()
    if (!accept.includes(fileExtension) || !fileExtension) {
      throw new Error(ValidateForm.FILE_TYPE(allowFileTypes))
    }
  }

  // validate size
  const _validateSize = size => {
    if (size > maxSize * 2 ** 20) {
      throw new Error(ValidateForm.MAX_FILE_SIZE(maxSize))
    }
  }

  // validate unique
  const _validateUnique = file => {
    if (find(fileList.map(convertFileToUpload), convertFileToUpload(file))) {
      throw new Error(ValidateForm.UNIQUE('File '))
    }
  }

  const _validate = file => {
    _validateUnique(file)
    // _validateType(get(file, 'type'));
    _validateExtension(get(file, 'name'))
    _validateSize(get(file, 'size', 0))
  }

  // bỏ gọi action - thêm file vào danh sách
  // _beforeUpload được get 2 tham số ví dụ: _beforeUpload(file, files)
  const _beforeUpload = file => {
    _mountedSet(setError, null)
    try {
      _validate(file)
      let newList = []
      if (isOnly) {
        newList = [file]
      } else {
        newList = [...fileList, file]
      }

      _mountedSet(setFileList, newList)
      onChange(newList)
    } catch (err) {
      _mountedSet(setError, get(err, 'message'))
    }
    // Bỏ hành vi mặc định (post)
    return false
  }

  // bỏ file khỏi danh sách
  const _removeFile = removeFile => {
    _mountedSet(setError, null)
    const newList = fileList.filter(
      file => !isEqual(convertFileToUpload(file), removeFile)
    )
    _mountedSet(setFileList, newList)
    onChange(newList)
  }

  // đổi style border
  const _onFileDrop = e => {
    _mountedSet(setDragState, e.type)
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(setFileList, value || [])
  }, [value])

  return (
    <div className={wrapperClassName}>
      {textLabel && (
        <div className="align-center" style={{ minHeight: 30 }}>
          <label className={classnames({ asterisk: isRequired })}>
            {textLabel}
          </label>
        </div>
      )}
      <div
        className={classnames('cpc-upload-wrapper', {
          'drag-hover': dragState === 'dragover',
          disabled: disabled,
          warning: warning,
        })}
        onDrop={_onFileDrop}
        onDragOver={_onFileDrop}
        onDragLeave={_onFileDrop}
      >
        <Upload
          {...props}
          className="cpc-upload"
          multiple={multiple}
          type="drag"
          beforeUpload={_beforeUpload}
          ref={upload}
        >
          <span className="font-size-13 text-center cpc-upload__infos">
            {textInfo}
            {!!textInfo && ': ('}
            Kéo tệp vào đây hoặc{' '}
            <span className="text-primary">Chọn từ thiết bị</span>
            {!!textInfo && ')'}
            {textSumary}
          </span>
        </Upload>
      </div>
      {isShowList && (
        <FileGroup
          list={fileList.map(convertFileToUpload)}
          smallIcon
          readOnly
          showClose
          disabled={disabled}
          onClose={_removeFile}
        />
      )}
      {error && (
        <div className={classnames('font-size-13', 'text-danger')}>{error}</div>
      )}
    </div>
  )
}

export default memo(CustomUpload)
