import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { get } from 'lodash'
import csx from 'classnames'

const wrapperStyles = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#dfe6ea',
  background: '#fff',
  padding: '8px 15px',
}

const disabledStyles = {
  background: 'rgba(206, 217, 224)',
  cursor: 'not-allowed',
  opacity: '.5',
}

const warningStyles = {
  borderColor: '#c02e2e',
}

const Input = ({
  allowEmptyEnter = true,
  arrowDropdown = null,
  autoResize = false,
  clearable,
  disabled,
  iconClassName,
  label,
  labelButton,
  labelClassname,
  disableHeightLabel = false,
  onChange: inputChange = () => {},
  onClear: inputClear = () => {},
  onEnter: inputEnter,
  onWrapperClick,
  required = false,
  rightElement = null,
  type = 'input',
  value = '',
  warning,
  wrapperClassName = '',
  wrapperRef,
  wrapperStyle: propsWrapperStyle,
  isDOInputNumber = false,
  ...props
}) => {
  const textareaRef = useRef(null)
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [text, _setText] = useState('')

  const onChange = useCallback(
    e => {
      const { value } = e.target
      _mountedSet(_setText, value)
      inputChange(value)
    },
    [inputChange]
  )

  const onKeyPress = useCallback(
    e => {
      const { key } = e
      if (inputEnter && key === 'Enter' && (allowEmptyEnter || text)) {
        inputEnter(text)
      }
    },
    [inputEnter, text, allowEmptyEnter]
  )

  const onClear = useCallback(
    e => {
      e.stopPropagation()
      _mountedSet(_setText, '')
      inputClear('')
    },
    [inputClear]
  )

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(_setText, value)
  }, [value])

  useEffect(() => {
    if (textareaRef && textareaRef?.current) {
      const scrollHeight = textareaRef?.current?.scrollHeight
      if (textareaRef?.current?.selectionStart === 0) {
        textareaRef.current.style.height = 'auto'
      } else {
        textareaRef.current.style.height = scrollHeight + 'px'
      }
    }
    // eslint-disable-next-line
  }, [text, textareaRef])

  const elementProps = useMemo(
    () => ({
      ...props,
      style: {
        minHeight: 24,
        ...(!autoResize ? { maxHeight: 72 } : {}),
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontSize: 13,
        border: 0,
        flex: 1,
        padding: 0,
        cursor: 'inherit',
        background: 'inherit',
        textOverflow: 'ellipsis',
        ...props.style,
      },
      value: text,
      onChange,
      onKeyPress,
      disabled,
    }),
    [props, onChange, onKeyPress, text, disabled, autoResize]
  )

  return (
    <div
      style={{
        ...propsWrapperStyle,
      }}
      className={wrapperClassName}
      onClick={onWrapperClick}
      ref={wrapperRef}
    >
      {label && (
        <div
          className="align-center"
          style={{ height: disableHeightLabel ? '' : 30 }}
        >
          <label
            className={csx(`${labelClassname ? labelClassname : ''}`, {
              asterisk: required,
            })}
          >
            {label}
          </label>
          {labelButton}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          ...wrapperStyles,
          ...(warning ? warningStyles : {}),
          ...(disabled ? disabledStyles : {}),
          padding: `${isDOInputNumber ? '5px 15px' : '8px 15px'}`,
        }}
        className="input-comp-v2"
      >
        {iconClassName && (
          <span
            className={csx(
              iconClassName,
              'icon-color-gray',
              'size-icon-12',
              'align-center'
            )}
            style={{ minWidth: 20 }}
          />
        )}
        {type === 'textarea' ? (
          <textarea ref={textareaRef} {...elementProps} />
        ) : type === 'color' ? (
          <div
            style={{
              ...elementProps.style,
              display: 'flex',
              alignItems: 'center',
            }}
            className="box-color-picker"
          >
            <span
              style={{
                backgroundColor: elementProps.value,
                width: '100%',
                minHeight: '18px',
              }}
            />
          </div>
        ) : (
          <input {...elementProps} type={type} />
        )}
        {clearable && !!text && (
          <span
            className={csx(
              'icon-Huy',
              'size-icon-10',
              'align-center',
              'has-action'
            )}
            onClick={onClear}
          />
        )}
        {rightElement}
        {arrowDropdown}
      </div>
    </div>
  )
}

export default memo(Input)
