import {
  GET_STATISTIC_ALERT_MISTAKE_UNIT,
  GET_STATISTIC_ALERT_MISTAKE_DEPARTMENT,
  GET_STATISTIC_ALERT_MISTAKE_PERSONAL,
} from '../constants/ActionTypes'

const initialState = {
  // Thong ke bao nham
  alertMistakes: {
    list: [],
    total: 0,
  },
}

export default function statisticAlertMistakes(state = initialState, action) {
  switch (action.type) {
    case GET_STATISTIC_ALERT_MISTAKE_UNIT:
    case GET_STATISTIC_ALERT_MISTAKE_DEPARTMENT:
    case GET_STATISTIC_ALERT_MISTAKE_PERSONAL:
      return {
        ...state,
        alertMistakes: {
          list: action.payload.data.result || [],
          total: action.payload.data.result
            ? action.payload.data.result.length
            : 0,
        },
      }

    default:
      return state
  }
}
