import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Tooltip } from '@blueprintjs/core'

import variables from 'constants/variables'
import {
  ResetButton,
  SearchInput,
  DatePicker,
  CheckboxSelect,
} from 'components/newCommon'
import { Select } from 'components/newCommon2'

const Filter = ({
  tab,
  hasStatus,
  labelFromTo,
  filterKeys,
  dataSource,
  dataValue,
  onChangeFilter,
}) => {
  return (
    <div className="bg-white task-manager-filter-layout">
      {tab !== 'CALENDAR' && tab !== 'TIMELINE' && (
        <div>
          <label className="label">{labelFromTo}</label>
          <div className="date-block">
            <DatePicker
              placeholder="Từ ngày"
              isClearable={true}
              selectsStart
              startDate={
                dataValue?.[filterKeys.from]
                  ? moment(dataValue?.[filterKeys.from])
                  : undefined
              }
              endDate={
                dataValue?.[filterKeys.to]
                  ? moment(dataValue?.[filterKeys.to])
                  : undefined
              }
              selected={
                dataValue?.[filterKeys.from]
                  ? moment(dataValue?.[filterKeys.from])
                  : undefined
              }
              maxDate={
                dataValue?.[filterKeys.to]
                  ? moment(dataValue?.[filterKeys.to])
                  : undefined
              }
              onChange={value => {
                const newValue = value
                  ? value.format(variables.DATE_FORMAT.DATE)
                  : undefined
                onChangeFilter({ name: filterKeys.from })(newValue)
              }}
            />
            <DatePicker
              placeholder="Đến ngày"
              isClearable={true}
              selectsEnd
              startDate={
                dataValue?.[filterKeys.from]
                  ? moment(dataValue?.[filterKeys.from])
                  : undefined
              }
              endDate={
                dataValue?.[filterKeys.to]
                  ? moment(dataValue?.[filterKeys.to])
                  : undefined
              }
              selected={
                dataValue?.[filterKeys.to]
                  ? moment(dataValue?.[filterKeys.to])
                  : undefined
              }
              minDate={
                dataValue?.[filterKeys.from]
                  ? moment(dataValue?.[filterKeys.from])
                  : undefined
              }
              onChange={value => {
                const newValue = value
                  ? value.format(variables.DATE_FORMAT.DATE)
                  : undefined
                onChangeFilter({ name: filterKeys.to })(newValue)
              }}
            />
          </div>
        </div>
      )}
      <div>
        <label className="label">Loại công việc</label>
        <div className="height-38">
          <CheckboxSelect
            inputLeftIcon="icon2-documents"
            dataSource={dataSource?.tags.map(tag => ({
              value: tag.id,
              label: tag.name,
            }))}
            onClear={onChangeFilter({ name: filterKeys.tags })}
            onChange={onChangeFilter({
              name: filterKeys.tags,
              isReFetch: false,
            })}
            onClose={onChangeFilter({ name: filterKeys.tags })}
            placeholder="Chọn loại công việc"
            selectedAllText="Tất cả loại công việc"
            value={dataValue?.[filterKeys.tags]}
          />
        </div>
      </div>
      {hasStatus && (
        <div>
          <label className="label">Trạng thái</label>
          <div className="height-38">
            <Select
              inputWrapperClassName={'border-radius-3 input-not-ph'}
              dataSource={dataSource.statusSource}
              inputIconClassName="icon2-documents"
              placeholder="Tất cả trạng thái"
              wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
              inputStyle={{ height: '35px', lineHeight: '35px' }}
              isShowArrowDropdown
              value={dataValue?.[filterKeys.status]}
              onChange={onChangeFilter({
                name: filterKeys.status,
                isReFetch: true,
              })}
            />
          </div>
        </div>
      )}
      <div>
        <label className="label">Nguồn công việc</label>
        <div className="height-38">
          <Select
            inputWrapperClassName={'border-radius-3 input-not-ph'}
            placeholder="Chọn nguồn công việc"
            inputIconClassName="icon2-documents"
            wrapperStyle={{ paddingTop: 0, paddingBottom: 0 }}
            inputStyle={{ height: '35px', lineHeight: '35px' }}
            isShowArrowDropdown
            dataSource={[
              { label: 'Tất cả', value: 'ALL' },
              ...(dataSource?.listWorkTypes?.map(e => ({
                label: e?.name,
                value: e?.keyName,
              })) || []),
            ]}
            value={dataValue?.[filterKeys.workType]}
            onChange={onChangeFilter({ name: filterKeys.workType })}
          />
        </div>
      </div>
      <div className="grow-1 search-submit">
        <SearchInput
          placeholder="Nhập trích yếu/Tiêu đề"
          onChange={onChangeFilter({
            name: filterKeys.title,
            isReFetch: false,
          })}
          onSearch={onChangeFilter({ name: filterKeys.title })}
          value={dataValue?.[filterKeys.title]}
          leftIcon="icon2-search"
        />
        <Tooltip content={<span className="font-size-12">Làm mới</span>}>
          <ResetButton onClick={onChangeFilter({ name: 'RESET' })} />
        </Tooltip>
      </div>
    </div>
  )
}

Filter.propTypes = {
  hasStatus: PropTypes.bool,
  labelFromTo: PropTypes.string,
  filterKeys: PropTypes.object,
  dataSource: PropTypes.object,
  dataValue: PropTypes.object,
  onChangeFilter: PropTypes.func,
}

Filter.defaultProps = {
  hasStatus: false,
  labelFromTo: 'Hạn thực hiện',
  filterKeys: null,
  dataSource: null,
  dataValue: null,
  onChangeFilter: () => {},
}

export default Filter
