import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { PopupConfirm } from '../common/'
import moment from 'moment'
import * as POPUP from '../../constants/Popup'
import { get } from 'lodash'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    match: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.state = {
      chucDanhId: this.props.auth ? this.props.auth.roleId : null,
      isOpenPopup: false,
    }
  }
  handleClick = () => {
    if (!this.props.item) {
      return
    }
    this.props.onClickDetail &&
      this.props.onClickDetail({
        phieuLayYKienId: this.props.item.phieuLayYKienId,
        soKyHieu: this.props.item.soKyHieu,
        fileId: this.props.item.fileId,
      })
  }
  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }
  handleSubmitRemoveItem = (lydo, e) => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })
    this.props.onHandleSubmitRemoveItem &&
      this.props.onHandleSubmitRemoveItem(lydo, this.props.item.phieuLayYKienId)
  }
  componentDidMount() {
    let state = this.state
    this.setState(state)
  }
  render() {
    if (!this.props.item) {
      return
    }
    let tmp
    switch (this.props.item.tinhTrang) {
      case 0:
        tmp = <span className="status-job no-process">Chưa duyệt</span>
        break
      case 1:
        tmp = <span className="status-job no-accept">Không duyệt</span>
        break
      case 2:
        tmp = <span className="status-job approved">Đang lấy ý kiến</span>
        break
      case 6:
        tmp = <span className="status-job process">Hoàn tất</span>
        break
      default:
        tmp = <span>&nbsp;</span>
    }
    const tinhTrang = tmp
    const currentEmployeeId = get(this.props, 'auth.user.nhanVienId')
    const createEmployeeId = get(this.props, 'item.nguoiLap.nhanVienId')
    const allowUpdate =
      currentEmployeeId &&
      createEmployeeId &&
      currentEmployeeId === createEmployeeId &&
      this.props.item.tinhTrang !== 6
    return (
      <tr>
        <td onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick}>{this.props.item.soKyHieu}</td>
        <td onClick={this.handleClick} className="textarea-content">
          {this.props.item.trichYeu}
        </td>
        <td onClick={this.handleClick}>
          {this.props.item.ngayLap &&
            moment(this.props.item.ngayLap).format('DD/MM/YYYY')}
        </td>
        <td onClick={this.handleClick}>
          {this.props.item.nguoiLap && this.props.item.nguoiLap.tenNhanVien}
        </td>
        <td onClick={this.handleClick}>{tinhTrang}</td>
        <td>
          <Popover
            popoverClassName="pt-popover-content-sizing"
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            openOnTargetFocus={true}
            content={
              <div className="menu-popover">
                {allowUpdate && (
                  <Link
                    className="item"
                    to={`/hoi-dong-thanh-vien/lay-y-kien/chinh-sua/${this.props.item.phieuLayYKienId}`}
                  >
                    <span className="pt-icon pt-icon-annotation"></span>
                    <span className="name_action">Chỉnh sửa</span>
                  </Link>
                )}
                <Link
                  className="item"
                  to={`/hoi-dong-thanh-vien/lay-y-kien/tong-hop/${this.props.item.phieuLayYKienId}`}
                >
                  <span className="pt-icon icon-menu-o-vuong"></span>
                  <span className="name_action">Tổng hợp</span>
                </Link>
                {this.props.item.nguoiLap.chucDanhId ===
                  this.state.chucDanhId && (
                  <span className="item" onClick={this.handleClickPopup}>
                    <span className="pt-icon pt-icon-trash"></span>
                    <span className="name_action">Xóa</span>
                  </span>
                )}
              </div>
            }
          >
            <span className="icon-More"></span>
          </Popover>
          <PopupConfirm
            actionPage={POPUP.XOA_KY_SO_CONG_VAN_DI}
            isOpen={this.state.isOpenPopup}
            textarea={true}
            onClose={this.handleClickPopup}
            title={POPUP.XOA_PHIEU_Y_KIEN}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            isWarning={
              this.props.item && this.props.item.tinhTrang > 1 ? true : false
            }
            onSubmit={this.handleSubmitRemoveItem}
            isLoading={this.props.isLoadingXoaYKien}
          >
            {POPUP.TEXT_SUBMIT_BODY}
          </PopupConfirm>
        </td>
      </tr>
    )
  }
}

export default ListItem
