import React from 'react'
import { MasterLayout } from '../../components/layout'
import { InputForm } from '../../components/InternalDocument'
import {
  SignNumberList,
  SignNumberItem,
  LoadingItem,
} from '../../components/common'
import { DetailFileContent } from '../../components/common'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from '@blueprintjs/core'
import * as Actions from '../../actions/'
import * as Tool from '../../helpers/'
import { PROGRESSION, PAGE } from '../../constants/Api'

const CANKYSO = false
const SORT = null
const LIMIT_SIGN_NUMBER = 0
const IS_REFRESH = true

class AddInternalDocument extends React.Component {
  static propTypes = {
    departments: PropTypes.array.isRequired,
    personalLeaderSignNumber: PropTypes.object.isRequired,
    signNumbers: PropTypes.object.isRequired,
    childDepartments: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleShowDetail = this.handleShowDetail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickSelect = this.handleClickSelect.bind(this)
    this.handleLoadSubmit = this.handleLoadSubmit.bind(this)
  }

  refHandlers = {
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  state = {
    isShow: false,
    isShowDetail: false,
    signNumberDocuments: [],
    signNumberId: '',
    signNumberFile: null,
    internalDocumentId: '',
    internalDocument: null,
    isUpdate: false,
    isLoadingData: {
      nguoiKyId: true,
      signNumber: false,
      internalDocument: true,
      electricUnits: true,
      departments: true,
      parentDepartments: true,
    },
    isLoadedData: {
      signNumber: false,
    },
    isAllowShowDepartmentsOfOtherUnits: false,
    isSubmitSuccess: false,
    isLoadingSubmit: false,
    isPc: false,
  }

  handleLoadSubmit = () => {
    this.setState({
      isLoadingSubmit: !this.state.isLoadingSubmit,
    })
  }

  handleChange = async e => {
    if (
      this.state.isLoadedData &&
      this.state.isLoadedData.signNumber === false
    ) {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          signNumber: true,
        },
        isShow: !this.state.isShow,
      })
    }

    if (
      this.state.isLoadedData &&
      this.state.isLoadedData.signNumber === false
    ) {
      return this.props.actions
        .signNumberInternalGet(
          { tinh_trang: PROGRESSION },
          PAGE,
          SORT,
          CANKYSO,
          LIMIT_SIGN_NUMBER
        )
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.entities) {
            this.setState({
              signNumberDocuments: res.payload.data.entities.signNumbers,
            })
          }
        })
        .then(() => {
          this.setState({
            isLoadingData: {
              nguoiKyId: false,
              signNumber: false,
            },
            isLoadedData: {
              ...this.state.isLoadedData,
              signNumber: true,
            },
          })
        })
        .catch(e => {
          this.setState({
            isLoadingData: {
              nguoiKyId: false,
              signNumber: false,
            },
          })
        })
    }

    return this.setState({
      isLoadingData: {
        nguoiKyId: false,
        signNumber: false,
      },
      isShow: !this.state.isShow,
    })
  }

  handleClickGoBack(e) {
    this.setState({
      isLoadingData: {
        nguoiKyId: false,
        signNumber: false,
      },
      isShowDetail: false,
    })
  }

  handleShowDetail = async (id, fileId) => {
    let signNumberFile = null
    if (fileId) {
      await this.props.actions.getFileInformation(fileId).then(res => {
        if (res && res.payload && res.payload.data && res.payload.data.result) {
          signNumberFile = res.payload.data.result
        }
      })
    }
    this.setState({
      isLoadingData: {
        ...this.state.isLoadingData,
        nguoiKyId: false,
        signNumber: false,
      },
      isShowDetail: !this.state.isShowDetail,
      signNumberId: id,
      signNumberFile,
    })
  }

  handleSubmit = async (msg, intent, isSuccess) => {
    if (isSuccess && isSuccess === true) {
      if (!this.props.config.refresh) {
        this.props.actions.refreshPage(true)
      }
      if (this.state.isUpdate) {
        await this.setState({ internalDocument: null })
        this.props.actions.commonAddToasterMessage({
          message: msg,
          intent: intent,
        })
        return this.props.history.goBack()
      }

      // CHANGED: Tao thanh cong thi reset form
      let isLoadingData = this.state.isLoadingData

      if (this.state.isShow && this.state.signNumberId) {
        isLoadingData = {
          ...this.state.isLoadingData,
          signNumber: true,
        }
      }

      await this.setState({
        internalDocument: null,
        isSubmitSuccess: true,
        isShowDetail: false,
        isLoadingData,
        isLoadingSubmit: true,
      })

      // CHANGED: show message
      await this.props.actions.commonAddToasterMessage({
        message: msg,
        intent: intent,
      })

      if (this.state.isShow && this.state.signNumberId) {
        // CHANGED: Load lai danh sach ky so
        await this.props.actions
          .signNumberInternalGet(
            { tinh_trang: PROGRESSION },
            PAGE,
            SORT,
            CANKYSO,
            LIMIT_SIGN_NUMBER,
            IS_REFRESH
          )
          .then(res => {
            let signNumberDocuments = []
            if (
              res.payload &&
              res.payload.data &&
              res.payload.data.entities &&
              res.payload.data.entities.signNumbers
            ) {
              signNumberDocuments = res.payload.data.entities.signNumbers
            }
            this.setState({ signNumberDocuments })
          })
          .finally(() => {
            this.setState({
              isLoadingData: {
                ...this.state.isLoadingData,
                signNumber: false,
              },
            })
          })
      }
    } else {
      await this.props.actions.commonAddToasterMessage({
        message: msg,
        intent: intent,
      })
    }
    this.setState({ isLoadingSubmit: false, isSubmitSuccess: false })
  }

  handleClickSelect = id => {
    this.setState({
      isLoadingData: {
        nguoiKyId: false,
        signNumber: false,
      },
      signNumberId: id,
    })
  }

  componentWillMount() {
    if (this.props.match.path === '/cong-van/cong-van-noi-bo/chinh-sua/:id') {
      this.setState({
        internalDocumentId: this.props.match.params.id,
        isUpdate: true,
      })
    } else {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          internalDocument: false,
        },
      })
    }
  }

  async componentDidMount() {
    if (this.props.auth && this.props.auth.mainUnitId) {
      // CHANGED: Kiem tra don vi co duoc gui cong van noi bo lien don vi khong
      let isAllowShowDepartmentsOfOtherUnits = false
      let isPc = false
      await this.props.actions
        .getCommonFetchUnitItem(this.props.auth.mainUnitId)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            isAllowShowDepartmentsOfOtherUnits =
              res.payload.data.result.guiCongVanNoiBoLienDonVi
            isPc = res.payload.data.result.isPc
          }
          this.setState({ isAllowShowDepartmentsOfOtherUnits, isPc })
        })
      await this.props.actions
        .commonPersonalLeaderSignNumber(this.props.auth.mainDepartmentId)
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              nguoiKyId: false,
            },
          })
        })
      this.props.actions
        .commonFetchElectricUnits(this.props.auth.mainUnitId)
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              electricUnits: false,
            },
          })
        })
    }
    // phong ban chuyen den don vi hien tai
    await this.props.actions.commonFetchDepartments().finally(() => {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          departments: false,
        },
      })
    })
    // phong ban chuyen den cua don vi cap tren
    if (this.props.auth && this.props.auth.parentUnitId) {
      // page = 1, donViId = null, isAll = false, phongBanLanhDao = null, isParent = false
      await this.props.actions
        .commonFetchDepartments(
          PAGE,
          this.props.auth.parentUnitId,
          null,
          null,
          true
        )
        .finally(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              parentDepartments: false,
            },
          })
        })
    } else {
      this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          parentDepartments: false,
        },
      })
    }
    // danh sach phong ban con
    if (this.props.auth && this.props.auth.mainDepartmentId) {
      await this.props.actions.commonFetchChildDepartments(
        this.props.auth.mainDepartmentId
      )
    }
    // chi tiet cong van noi bo
    if (this.state.internalDocumentId) {
      await this.props.actions
        .getInternalDocumentItem(this.state.internalDocumentId)
        .then(() => {
          let state = this.state
          if (
            this.props.listInternalDocuments &&
            this.props.listInternalDocuments.item
          ) {
            state.internalDocument = this.props.listInternalDocuments.item
            state.isLoadingData.internalDocument = false
          }
          this.setState(state)
        })
    }
  }

  componentWillUnmount() {
    this.setState({
      signNumberDocuments: [],
      signNumberId: '',
      signNumberFile: null,
      internalDocumentId: '',
      isUpdate: false,
    })
  }

  render() {
    return (
      <MasterLayout typeSidebar="documentary" collapseSideBar={true}>
        <div className="form-input-receive-document">
          <div
            className={classnames({
              'detail-receive-document': this.state.isShowDetail,
            })}
          >
            {this.state.isShow && (
              <div className="heading-page">
                <div className="row">
                  <div className="col-xs-12">
                    <h3 className="text-heading-page">
                      <span className="icon-Nhap_cong_van_noi_bo icon-heading"></span>
                      Nhập công văn nội bộ
                    </h3>
                  </div>
                </div>
              </div>
            )}

            {!this.state.isShowDetail && (
              <div
                className={classnames('row', {
                  'center-xs': !this.state.isShow,
                  'format-center': !this.state.isShow,
                })}
              >
                <div
                  className={classnames({
                    'col-md-6 col-xs-12 col-sm-12 padding-0':
                      this.state.isShow && !this.state.isShowDetail,
                    'col-md-9 col-xs-12 col-sm-12 padding-0':
                      !this.state.isShow && !this.state.isShowDetail,
                  })}
                >
                  {!this.state.isShow && (
                    <div className="heading-page bottom-add-receive-document">
                      <h3 className="pull-left text-heading-page">
                        <span className="icon-Nhap_cong_van_noi_bo icon-heading"></span>
                        <span className="text-heading">
                          {this.state.isUpdate
                            ? 'Sửa công văn nội bộ'
                            : 'Nhập công văn nội bộ'}
                        </span>
                      </h3>
                      <div className="pull-right group-button-head">
                        {!this.state.isUpdate && (
                          <Button
                            type="submit"
                            onClick={this.handleChange}
                            className="btn-digitizing-cvd"
                            disabled={
                              (this.state.isLoadingData &&
                                this.state.isLoadingData.signNumber === true) ||
                              this.state.isLoadingSubmit === true
                            }
                          >
                            Nhập từ ký số
                          </Button>
                        )}
                      </div>
                    </div>
                  )}

                  <InputForm
                    isShow={this.state.isShow}
                    departments={this.props.departments}
                    actions={this.props.actions}
                    onSubmit={this.handleSubmit}
                    onLoadSubmit={this.handleLoadSubmit}
                    signNumberId={this.state.signNumberId}
                    signNumberFile={
                      this.state.signNumberFile &&
                      Tool.Utils.linkObjectKey(this.state.signNumberFile)
                    }
                    personalLeaderSignNumber={
                      this.props.personalLeaderSignNumber
                        ? this.props.personalLeaderSignNumber.items
                        : []
                    }
                    signNumberDocuments={this.state.signNumberDocuments}
                    childDepartments={this.props.childDepartments}
                    mainDepartmentId={
                      this.props.auth ? this.props.auth.mainDepartmentId : null
                    }
                    mainParentDepartmentId={
                      this.props.auth
                        ? this.props.auth.mainParentDepartmentId
                        : null
                    }
                    isUpdate={this.state.isUpdate}
                    internalDocumentId={this.state.internalDocumentId}
                    internalDocument={this.state.internalDocument}
                    isLoadingData={this.state.isLoadingData}
                    electricUnits={this.props.electricUnits}
                    mainUnitId={
                      this.props.auth ? this.props.auth.mainUnitId : null
                    }
                    isAllowShowDepartmentsOfOtherUnits={
                      this.state.isAllowShowDepartmentsOfOtherUnits
                    }
                    isPc={this.state.isPc}
                    isSubmitSuccess={this.state.isSubmitSuccess}
                    parentDepartments={this.props.parentDepartments}
                    auth={this.props.auth}
                  />
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6">
                  {this.state.isLoadingData &&
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) !== undefined &&
                    this.state.isShow &&
                    !this.state.isUpdate && (
                      <div className="loading-container">
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                        <LoadingItem />
                      </div>
                    )}
                  {(!this.state.isLoadingData ||
                    Object.values(this.state.isLoadingData).find(
                      item => item === true
                    ) === undefined) &&
                    this.state.isShow &&
                    !this.state.isUpdate && (
                      <SignNumberList
                        sizeHeight={765}
                        list={this.props.signNumbers.list.items}
                        onClickHidden={this.handleChange}
                        onClickShowDetail={this.handleShowDetail}
                        onClickSelect={this.handleClickSelect}
                      />
                    )}
                </div>
              </div>
            )}

            {this.state.isShowDetail && (
              <div className="detail-container">
                <div className="row-detail-container clearfix">
                  <div className="box-left">
                    <div className="pd-none">
                      <InputForm
                        isShow={this.state.isShow}
                        departments={this.props.departments}
                        actions={this.props.actions}
                        onSubmit={this.handleSubmit}
                        signNumberId={this.state.signNumberId}
                        signNumberFile={
                          this.state.signNumberFile &&
                          Tool.Utils.linkObjectKey(this.state.signNumberFile)
                        }
                        personalLeaderSignNumber={
                          this.props.personalLeaderSignNumber
                            ? this.props.personalLeaderSignNumber.items
                            : []
                        }
                        signNumberDocuments={this.state.signNumberDocuments}
                        childDepartments={this.props.childDepartments}
                        mainDepartmentId={
                          this.props.auth
                            ? this.props.auth.mainDepartmentId
                            : null
                        }
                        mainParentDepartmentId={
                          this.props.auth
                            ? this.props.auth.mainParentDepartmentId
                            : null
                        }
                        isUpdate={this.state.isUpdate}
                        internalDocumentId={this.state.internalDocumentId}
                        internalDocument={this.state.internalDocument}
                        isLoadingData={this.state.isLoadingData}
                        electricUnits={this.props.electricUnits}
                        mainUnitId={
                          this.props.auth ? this.props.auth.mainUnitId : null
                        }
                        isAllowShowDepartmentsOfOtherUnits={
                          this.state.isAllowShowDepartmentsOfOtherUnits
                        }
                        isPc={this.state.isPc}
                        parentDepartments={this.props.parentDepartments}
                        auth={this.props.auth}
                      />
                    </div>
                  </div>
                  <div className="box-right">
                    <div className="container-form-action">
                      <div className="heading-goback">
                        <span
                          className="left-heading-goback click-go-back"
                          onClick={this.handleClickGoBack}
                        >
                          <span className="pt-icon pt-icon-arrow-left"></span>
                          <span className="text-back">Trở lại danh sách</span>
                        </span>
                      </div>
                      {this.state.signNumberFile && this.state.signNumberId && (
                        <DetailFileContent
                          file={this.state.signNumberFile}
                          actions={this.props.actions}
                          ref={this.refHandlers.detailFileContent}
                          auth={this.props.auth}
                        />
                      )}
                      <div className="list-digitizing detail-digitizing">
                        <SignNumberItem
                          item={
                            this.state.signNumberDocuments[
                              this.state.signNumberId
                            ]
                          }
                          onClickShowDetail={this.handleShowDetail}
                          onClickSelect={this.handleClickSelect}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,

  auth: state.auth,

  departments: state.common.departments,

  // signNumberDepartmentLeaders: state.common.signNumberDepartmentLeaders,

  personalLeaderSignNumber: {
    ...state.personalLeaderSignNumber,
    items: state.common.personalLeaderSignNumber,
  },

  signNumbers: {
    ...state.signNumbers,
    list: {
      ...state.signNumbers.list,
      items: state.signNumbers.list.items.map(
        id => state.entities.signNumbers[id]
      ),
    },
  },

  childDepartments: state.common.childDepartments,

  listInternalDocuments: {
    ...state.listInternalDocuments,
    item: state.entities.internalDocuments[
      state.listInternalDocuments.detailId
    ],
  },

  readOnly: state.common.enableReadOnly,

  electricUnits: state.common.electricUnits,

  parentDepartments: state.common.parentDepartments,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddInternalDocument)
