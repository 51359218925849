import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'

class FlowReceiveComment extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func,
    flowReceiveComment: PropTypes.object.isRequired,
    hiddenName: PropTypes.bool,
  }

  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { flowReceiveComment, auth } = this.props
    const token =
      auth.auth && auth.auth.token && auth.auth.token.accessToken
        ? auth.auth.token.accessToken
        : ''
    let extensionTK = null
    if (flowReceiveComment.thucHien && flowReceiveComment.thucHien.fileGopY) {
      const fileDinhKem = flowReceiveComment.thucHien.fileGopY.fileName
      if (fileDinhKem) {
        extensionTK = fileDinhKem
          .slice(
            ((flowReceiveComment.thucHien.fileGopY.fileName.lastIndexOf('.') -
              1) >>>
              0) +
              2
          )
          .toLowerCase()
      }
    }
    let trangThaiGopY = ''
    if (flowReceiveComment.thucHien) {
      if (flowReceiveComment.thucHien.trangThaiGopY === 0) {
        trangThaiGopY = 'Chưa góp ý'
      }
      if (flowReceiveComment.thucHien.trangThaiGopY === 1) {
        trangThaiGopY = 'Không thống nhất'
      }
      if (flowReceiveComment.thucHien.trangThaiGopY === 2) {
        trangThaiGopY = 'Thống nhất'
      }
      if (flowReceiveComment.thucHien.trangThaiGopY === 3) {
        trangThaiGopY = 'Không ý kiến'
      }
    }
    return (
      <div className="container-form-action">
        <div className="view-job-done">
          {/*chỉ đạo góp ý*/}
          {flowReceiveComment.chiDao && (
            <div className="box-level-primary item-box">
              <div className="avatar-user">
                <div className="click-avatar">
                  <span
                    className="avatar"
                    style={{
                      backgroundImage: `url(${
                        flowReceiveComment.chiDao &&
                        flowReceiveComment.chiDao.anhDaiDien
                          ? Types.IMAGE_URL +
                            flowReceiveComment.chiDao.anhDaiDien
                          : '/images/default_avatar.png'
                      })`,
                    }}
                  ></span>
                  <span className="name_user">
                    {flowReceiveComment.chiDao &&
                      flowReceiveComment.chiDao.tenNhanVien}
                  </span>
                </div>
              </div>
              <div className="info-job">
                {flowReceiveComment.chiDao.thoiGian && (
                  <span className="info-job-item">
                    <span className="title">Ngày:</span>
                    <span className="content">
                      {flowReceiveComment.chiDao.thoiGian &&
                        ' ' +
                          moment(flowReceiveComment.chiDao.thoiGian).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                    </span>
                  </span>
                )}
              </div>
              <div className="info-job">
                {flowReceiveComment.phongBan &&
                  flowReceiveComment.phongBan.tenPhongBan && (
                    <span className="info-job-item">
                      <span className="title">Phòng ban chủ trì:</span>
                      <span className="content">
                        {flowReceiveComment.phongBan.tenPhongBan}
                      </span>
                    </span>
                  )}
              </div>
            </div>
          )}
          {/*giao việc góp ý*/}
          {flowReceiveComment.giaoViec && (
            <div className="box-level-sub item-box">
              <div className="avatar-user">
                <div className="click-avatar">
                  <span
                    className="avatar"
                    style={{
                      backgroundImage: `url(${
                        flowReceiveComment.giaoViec &&
                        flowReceiveComment.giaoViec.anhDaiDien
                          ? Types.IMAGE_URL +
                            flowReceiveComment.giaoViec.anhDaiDien
                          : '/images/default_avatar.png'
                      })`,
                    }}
                  ></span>
                  <span className="name_user">
                    {flowReceiveComment.giaoViec &&
                      flowReceiveComment.giaoViec.tenNhanVien}
                  </span>
                </div>
              </div>
              <div className="info-job">
                {flowReceiveComment.giaoViec.thoiGian && (
                  <span className="info-job-item">
                    <span className="title">Ngày:</span>
                    <span className="content">
                      {flowReceiveComment.giaoViec.thoiGian &&
                        ' ' +
                          moment(flowReceiveComment.giaoViec.thoiGian).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                    </span>
                  </span>
                )}
              </div>

              {/*thực hiện góp ý*/}
              {flowReceiveComment.thucHien && (
                <div className="box-level-sub item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            flowReceiveComment.thucHien &&
                            flowReceiveComment.thucHien.anhDaiDien
                              ? Types.IMAGE_URL +
                                flowReceiveComment.thucHien.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">
                        {flowReceiveComment.thucHien &&
                          flowReceiveComment.thucHien.tenNhanVien}
                      </span>
                    </div>
                  </div>
                  {flowReceiveComment.thucHien.thoiGianGopY && (
                    <div className="info-job">
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {flowReceiveComment.thucHien.thoiGianGopY &&
                            ' ' +
                              moment(
                                flowReceiveComment.thucHien.thoiGianGopY
                              ).format('DD/MM/YYYY HH:mm')}
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div className="box-level-sub item-box">
                <div className="info-gop-y">
                  <span className="left-icon style-icon icon-ThongNhat"></span>
                  <span className="info-job-item">
                    <span className="title title-trang-thai-gop-y">
                      {trangThaiGopY}
                    </span>
                  </span>
                </div>
                {/*nội dung góp ý*/}
                {flowReceiveComment.thucHien.noiDungGopY && (
                  <div className="info-gop-y">
                    <span className="left-icon style-icon icon-ThongTinGopY"></span>
                    <span className="info-job-item">
                      <span className="title ">Thông tin góp ý:</span>

                      <span className="content">
                        {flowReceiveComment.thucHien.noiDungGopY
                          ? flowReceiveComment.thucHien.noiDungGopY
                          : ''}
                      </span>
                    </span>
                  </div>
                )}

                {/*file  góp ý*/}
                {flowReceiveComment.thucHien.fileGopY && (
                  <div className="info-gop-y">
                    <span className="info-job-item file-info">
                      <span className="title-file-info">File góp ý:</span>
                      <a
                        className="link-file"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${Types.FILE_URL}/api/file/get/${flowReceiveComment.thucHien.fileGopY.fileId}?BearerToken=${token}`}
                        style={{
                          backgroundImage: `url(${Tool.Utils.typeFile(
                            extensionTK
                          )})`,
                        }}
                      >
                        {flowReceiveComment.thucHien.fileGopY.tenFile} -{' '}
                        {Tool.Utils.getFileSize(
                          flowReceiveComment.thucHien.fileGopY.kichThuoc
                        )}
                      </a>
                    </span>
                  </div>
                )}
                {/*title Xác nhận góp ý*/}
                {(flowReceiveComment.phongBanXacNhan ||
                  flowReceiveComment.donViXacNhan) && (
                  <div className="info-gop-y">
                    <span className="left-icon style-icon icon-XacNhan"></span>
                    <span className="title">Xác nhận góp ý:</span>
                  </div>
                )}
                {/* phòng ban xác nhận  góp ý*/}
                {flowReceiveComment.phongBanXacNhan && (
                  <div className="box-level-sub item-box">
                    <div className="avatar-user">
                      <div className="click-avatar">
                        <span
                          className="avatar"
                          style={{
                            backgroundImage: `url(${
                              flowReceiveComment.phongBanXacNhan &&
                              flowReceiveComment.phongBanXacNhan.anhDaiDien
                                ? Types.IMAGE_URL +
                                  flowReceiveComment.phongBanXacNhan.anhDaiDien
                                : '/images/default_avatar.png'
                            })`,
                          }}
                        ></span>
                        <span className="name_user">
                          {flowReceiveComment.phongBanXacNhan &&
                            flowReceiveComment.phongBanXacNhan.tenNhanVien}
                        </span>
                      </div>
                    </div>
                    <div className="info-job">
                      {flowReceiveComment.phongBanXacNhan.thoiGianDuyet && (
                        <span className="info-job-item">
                          <span className="title">Ngày:</span>
                          <span className="content">
                            {flowReceiveComment.phongBanXacNhan.thoiGianDuyet &&
                              ' ' +
                                moment(
                                  flowReceiveComment.phongBanXacNhan
                                    .thoiGianDuyet
                                ).format('DD/MM/YYYY HH:mm')}
                          </span>
                          <span className="title">-Thực hiện:</span>
                          <span className="content">
                            {flowReceiveComment.phongBanXacNhan.trangThai === 1
                              ? 'Không đồng ý'
                              : flowReceiveComment.phongBanXacNhan.trangThai ===
                                2
                              ? 'Đồng ý'
                              : ''}
                          </span>
                        </span>
                      )}
                    </div>
                    {/*nội dung góp ý*/}
                    {flowReceiveComment.phongBanXacNhan.yKien && (
                      <div className="info-gop-y">
                        <span className="left-icon style-icon icon-ThongTinGopY"></span>
                        <span className="info-job-item">
                          <span className="title ">Thông tin góp ý:</span>
                          <span className="content">
                            {flowReceiveComment.phongBanXacNhan.yKien
                              ? flowReceiveComment.phongBanXacNhan.yKien
                              : ''}
                          </span>
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {/* đơn vị xác nhận  góp ý*/}
                {flowReceiveComment.donViXacNhan && (
                  <div className="box-level-sub item-box">
                    <div className="avatar-user">
                      <div className="click-avatar">
                        <span
                          className="avatar"
                          style={{
                            backgroundImage: `url(${
                              flowReceiveComment.donViXacNhan &&
                              flowReceiveComment.donViXacNhan.anhDaiDien
                                ? Types.IMAGE_URL +
                                  flowReceiveComment.donViXacNhan.anhDaiDien
                                : '/images/default_avatar.png'
                            })`,
                          }}
                        ></span>
                        <span className="name_user">
                          {flowReceiveComment.donViXacNhan &&
                            flowReceiveComment.donViXacNhan.tenNhanVien}
                        </span>
                      </div>
                    </div>
                    <div className="info-job">
                      {flowReceiveComment.donViXacNhan.thoiGianDuyet && (
                        <span className="info-job-item">
                          <span className="title">Ngày:</span>
                          <span className="content">
                            {flowReceiveComment.donViXacNhan.thoiGianDuyet &&
                              ' ' +
                                moment(
                                  flowReceiveComment.donViXacNhan.thoiGianDuyet
                                ).format('DD/MM/YYYY HH:mm')}
                          </span>
                          <span className="title">-Thực hiện:</span>
                          <span className="content">
                            {flowReceiveComment.donViXacNhan.trangThai === 1
                              ? 'Không đồng ý'
                              : flowReceiveComment.donViXacNhan.trangThai === 2
                              ? 'Đồng ý'
                              : ''}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default FlowReceiveComment
