import React, { Component } from 'react'
import { MasterLayout } from '../../components/layout'
import { DonViNhanList, DetailBoxAction } from '../../components/Revoke'
import {
  DetailFileContent,
  LoadingLine,
  LoadingItem,
  ExecutingView,
  PopupConfirm,
  DetailFileContentV2,
} from 'components/common'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from '../../actions'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import {
  TEXT_THUHOICONGVAN_THANHCONG,
  TEXT_THUHOICONGVAN_KHONGTHANHCONG,
  TEXT_DE_XUAT_THUHOI_CONGVAN_KHONGTHANHCONG,
  TEXT_DE_XUAT_THUHOI_CONGVAN_THANHCONG,
} from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import { get, isEmpty } from 'lodash'
import { isValidCondition } from '../../helpers/Helper'
import { Permission } from '../../components/auth'

const typeRequest = {
  HUY_DE_XUAT: 2, // Hủy đề xuất
}

class DetailRevokePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showListAction: true,
      showDetailInfo: false,
      revokeId: this.props.match.params.id,
      idThuHoi: this.props.match.params.idThuHoi,
      isShowMobileRestoreArchive: false,
      executingViews: [],
      executingViewsEvn: [],
      item: {},
      isLoadingData: {
        file: null,
        item: true,
        isError: false,
      },
      isLoadingMigratedFile: true,
      isLoadingSubData: false,
      infoSendAdditional: null,
      isCheckApi: false,
      attachedUnits: null,
      otherUnits: null,
      relativeUnits: null,
      isShowAttachedUnits: true,
      isShowOtherUnits: true,
      boxMenuFixTop: false,
      isOpenPopup: false,
      popupModel: {
        title: POPUP.THU_HOI_VAN_BAN_CONG_VAN,
        sumary: POPUP.TEXT_SUBMIT_BODY,
        textButton: POPUP.TEXT_BUTTON_SUBMIT,
        func: this.handleSubmiDongYThuHoi,
        textarea: true,
      },
      isLoading: false,
      isOpenPopupHuyYeuCau: false,
      isLoadingButton: false,
      checkHienThiHuyDeXuat: true,
      checkDongYThuHoi: true,
    }
    this.closeShowDetailInfo = this.closeShowDetailInfo.bind(this)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.closeModulesDetail = this.closeModulesDetail.bind(this)
    this.handleClickExecutingView = this.handleClickExecutingView.bind(this)
    this.handleClickExecutingViewReceiveDocument =
      this.handleClickExecutingViewReceiveDocument.bind(this)
    this.handleClickExecutingViewEvn =
      this.handleClickExecutingViewEvn.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  refHandlers = {
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  componentDidMount() {
    // Thông tin đơn vị
    let isShowAttachedUnits = true
    let isShowOtherUnits = true
    if (this.props.auth && this.props.auth.mainUnitId) {
      this.props.actions
        .getCommonFetchUnitItem(this.props.auth.mainUnitId)
        .then(res => {
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            isShowAttachedUnits = res.payload.data.result.guiDonViTrucThuoc
            isShowOtherUnits = res.payload.data.result.guiDonViKhac
          }

          this.setState({
            isShowAttachedUnits,
            isShowOtherUnits,
          })
        })
    }

    window.addEventListener('scroll', this.handleScroll)
    this.props.actions.revokeDocumentDetail(this.state.idThuHoi).then(res => {
      if (res.error) {
        this.setState({
          isLoadingData: {
            isError: true,
          },
        })
      }

      this.setState(
        {
          item: res.payload.data && res.payload.data.result,
          file:
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.fileNoiDung,
        },
        () => {
          if (
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.trangThai === 0 &&
            get(res.payload.data.result.nguoiKy, 'nhanVienId') ===
              get(this.props.auth.user, 'nhanVienId')
          ) {
            this.setState({
              checkDongYThuHoi: false,
            })
          }

          if (
            (res.payload.data &&
              res.payload.data.result &&
              this.props.auth.user &&
              this.props.auth.user.dsChucDanh &&
              this.props.auth.user.dsChucDanh.filter(
                item => item.id === res.payload.data.result.nguoiTaoId
              ).length !== 0) ||
            isValidCondition(
              this.props.auth.permission,
              [Permission.DONGY_THUHOI],
              'AND'
            )
          ) {
            this.setState({
              checkHienThiHuyDeXuat: false,
            })
          }

          this.setState({
            isLoadingData: {
              item: false,
              isError: false,
            },
          })
        }
      )
    })
  }

  handleClickGoBack = () => {
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
    })
  }

  handleClickGoBackList = () => {
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'DanhSachDonViNhan',
    })
  }

  handleClickExecutingView = () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'XemThucHien',
    })
    window.scrollTo(0, 0)
  }

  handleClickExecutingViewReceiveDocument = id => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'XemThucHienCVD',
    })
    window.scrollTo(0, 0)
    this.props.actions.getExecutingView(id).then(res => {
      this.setState({
        showResults: true,
        isLoadingSubData: false,
      })
    })
  }

  handleClickExecutingViewEvn = id => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'XemThucHienEvn',
    })
    window.scrollTo(0, 0)
    this.props.actions.getExecutingViewEvn(id).then(res => {
      if (
        res.payload &&
        res.payload.data &&
        res.payload.data.result &&
        res.payload.data.result.items &&
        res.payload.data.result.items.length > 0
      ) {
        this.setState({
          showResults: true,
          isLoadingSubData: false,
          executingViewsEvn: res.payload.data.result.items,
        })
      } else {
        this.setState({
          showResults: true,
          isLoadingSubData: false,
        })
      }
    })
  }

  handleClickRevoke = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
    })
  }

  /**
   * Click close popup
   * @memberof DetailRevokePage
   */
  handleClickClosePopup = () => {
    this.setState({
      isOpenPopup: false,
    })
  }

  /**
   * handle SubmitRevoke
   * @memberof DetailRevokePage
   */
  handleSubmiRevoke = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  showDetailInfo = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeShowDetailInfo = () => {
    this.setState({
      showDetailInfo: false,
    })
  }

  closeModulesDetail = () => {
    this.setState({
      showListAction: true,
      isShowMobileRestoreArchive: !this.state.isShowMobileRestoreArchive,
    })
  }

  handleClickMoreDepartmentExecutingView = id => {
    this.props.actions.getMoreFlowDocumentaryReceiveDocument(id)
  }

  handleClickDepartmentExecutingView = id => {
    this.props.actions.getMoreExecutingView(id)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  onClickDongYThuHoi = e => {
    e.preventDefault()
    this.setState(
      {
        popupModel: {
          title: POPUP.THU_HOI_CONG_VAN,
          sumary: POPUP.COMFIRM_DONG_Y_THU_HOI_CONG_VAN,
          textButton: POPUP.TEXT_BUTTON_SUBMIT,
          func: this.handleSubmiDongYThuHoi,
          textarea: false,
        },
      },
      () => {
        this.setState({
          isOpenPopup: !this.state.isOpenPopup,
        })
      }
    )
  }

  /**
   * handle handleSubmiDongYThuHoi
   * @memberof DetailRevokePage
   */
  handleSubmiDongYThuHoi = async () => {
    try {
      this.setState({
        isLoadingButton: true,
      })
      const data = await this.props.actions.putDongYThuHoiCongVanDi(
        this.state.idThuHoi
      )
      if (
        data &&
        (data.error ||
          (data.payload.status !== 200 && data.payload.status !== 201))
      ) {
        if (data.error.response.status === 400) {
          return this.props.actions.commonAddToasterMessage({
            message: data.error.response.data.message,
            intent: Intent.DANGER,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TEXT_THUHOICONGVAN_KHONGTHANHCONG,
          intent: Intent.DANGER,
        })
      }

      const thongBaoThanhCong = this.props.actions.commonAddToasterMessage({
        message: TEXT_THUHOICONGVAN_THANHCONG,
        intent: Intent.SUCCESS,
      })
      if (thongBaoThanhCong) {
        this.goBack()
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: TEXT_THUHOICONGVAN_KHONGTHANHCONG,
        intent: Intent.DANGER,
      })
    } finally {
      this.props.actions.getTongSoCongVanThuHoi()
      this.setState({
        isOpenPopup: !this.state.isOpenPopup,
        isLoadingButton: false,
      })
    }
  }

  /**
   * Hủy đề xuất thu hồi
   * @memberof DetailRevokePage
   */
  handleHuyDeXuatThuHoi = () => {
    this.setState({
      isOpenPopupHuyYeuCau: !this.state.isOpenPopupHuyYeuCau,
    })
  }

  handleSubmitHuyDeXuatThuHoiCongVan = async () => {
    try {
      this.setState({
        isLoadingButton: true,
      })
      const params = {
        updateType: typeRequest.HUY_DE_XUAT,
      }
      const dataParams = {
        cong_van_di_id: this.props.item.congVanDiId,
        ly_do_thu_hoi: undefined,
      }
      const data = await this.props.actions.putCapNhatDeXuatThuHoi(
        params,
        dataParams
      )
      if (
        data &&
        (data.error ||
          (data.payload.status !== 200 && data.payload.status !== 201))
      ) {
        if (data.error.response.status === 400) {
          return this.props.actions.commonAddToasterMessage({
            message: data.error.response.data.message,
            intent: Intent.DANGER,
          })
        }

        return this.props.actions.commonAddToasterMessage({
          message: TEXT_DE_XUAT_THUHOI_CONGVAN_KHONGTHANHCONG,
          intent: Intent.DANGER,
        })
      }

      const thongBaoThanhCong = this.props.actions.commonAddToasterMessage({
        message: TEXT_DE_XUAT_THUHOI_CONGVAN_THANHCONG,
        intent: Intent.SUCCESS,
      })
      if (thongBaoThanhCong) {
        this.goBack()
      }
    } catch (error) {
      this.props.actions.commonAddToasterMessage({
        message: TEXT_DE_XUAT_THUHOI_CONGVAN_KHONGTHANHCONG,
        intent: Intent.DANGER,
      })
    } finally {
      this.props.actions.getTongSoCongVanThuHoi()
      this.setState({
        isLoadingButton: false,
        isOpenPopupHuyYeuCau: !this.state.isOpenPopupHuyYeuCau,
      })
    }
  }

  handleClickRestoreArchive = async () => {
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    await this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'DanhSachDonViNhan',
    })
    window.scrollTo(0, 0)
    if (!this.state.infoSendAdditional) {
      await this.props.actions
        .getSendDocumentAdditionalInformation(this.props.match.params.id)
        .then(res => {
          let infoSendAdditional = null
          if (res.payload && res.payload.data && res.payload.data.result) {
            infoSendAdditional = res.payload.data.result
          }

          return this.setState({
            infoSendAdditional,
            showResults: true,
            isLoadingSubData: false,
          })
        })
    }

    await this.setState({
      showResults: true,
      isLoadingSubData: false,
    })
  }

  goBack() {
    this.props.history.goBack()
  }

  render() {
    return (
      <MasterLayout typeSidebar="documentary" collapseSideBar={true}>
        <div className="detail-receive-document detail-send-document">
          {this.state.isLoadingData &&
          Object.values(this.state.isLoadingData).find(
            item => item === true
          ) !== undefined ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              <span className="number-code">
                <span className="name">Công văn đi thu hồi:</span>
                <span className="code">
                  {this.state.item && this.state.item.soCongVan}
                </span>
              </span>
            </div>
          )}
          <div className="detail-container">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                {!isEmpty(this.state.file) && (
                  <DetailFileContentV2
                    auth={this.props.auth}
                    actions={this.props.actions}
                    file={this.state.file}
                  />
                )}
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
              >
                <div className="full-box-content">
                  {this.state.showListAction && (
                    <div className="full-box-group">
                      <PopupConfirm
                        isOpen={this.state.isOpenPopup}
                        onClose={this.handleClickClosePopup}
                        title={this.state.popupModel.title}
                        text={this.state.popupModel.textButton}
                        onSubmit={this.state.popupModel.func}
                        textarea={this.state.popupModel.textarea}
                        isLoading={this.state.isLoadingButton}
                      >
                        {this.state.popupModel.sumary}
                      </PopupConfirm>
                      <DetailBoxAction
                        auth={this.props.auth}
                        onClickExecutingView={this.handleClickExecutingView}
                        onClickRestoreArchive={this.handleClickRestoreArchive}
                        isShowMobile={this.state.isShowMobileRestoreArchive}
                        closeModulesDetail={this.closeModulesDetail}
                        onClickGoBack={this.handleClickGoBack}
                        executingViews={this.state.revokeDocument}
                        actions={this.props.actions}
                        onClickExecutingViewReceiveDocument={
                          this.handleClickExecutingViewReceiveDocument
                        }
                        isLoadingData={this.state.isLoadingData}
                        guiEvn={
                          this.state.revokeDocument &&
                          this.state.revokeDocument.guiEvn
                        }
                        onClickRevoke={this.handleClickRevoke}
                        onClickDongYThuHoi={this.onClickDongYThuHoi}
                        onClickHuyDeXuatThuHoi={this.handleHuyDeXuatThuHoi}
                        checkHienThiHuyDeXuat={this.state.checkHienThiHuyDeXuat}
                        checkDongYThuHoi={this.state.checkDongYThuHoi}
                      />
                      <PopupConfirm
                        isOpen={this.state.isOpenPopupHuyYeuCau}
                        onClose={this.handleHuyDeXuatThuHoi}
                        title={POPUP.HUY_DE_XUAT_THU_HOI}
                        text={POPUP.TEXT_BUTTON_SUBMIT}
                        onSubmit={this.handleSubmitHuyDeXuatThuHoiCongVan}
                        isLoading={this.state.isLoadingButton}
                      >
                        {POPUP.TEXT_SUBMIT_BODY}
                      </PopupConfirm>
                    </div>
                  )}
                  {!this.state.showListAction &&
                    this.state.isLoadingSubData && (
                      <div className="loading-container">
                        <LoadingItem />
                      </div>
                    )}
                  {!this.state.isLoadingSubData &&
                    this.state.showResults &&
                    this.state.showSection === 'DanhSachDonViNhan' && (
                      <DonViNhanList
                        onClickGoBack={this.handleClickGoBack}
                        isShowMobile={this.state.isShowMobileRestoreArchive}
                        infoSendAdditional={this.state.infoSendAdditional}
                        onClickExecutingViewEvn={
                          this.handleClickExecutingViewEvn
                        }
                        onClickExecutingViewReceiveDocument={
                          this.handleClickExecutingViewReceiveDocument
                        }
                        actions={this.props.actions}
                        revokeId={this.state.revokeId}
                        auth={this.props.auth}
                      />
                    )}
                  {!this.state.isLoadingSubData &&
                    this.state.showResults &&
                    this.state.showSection === 'XemThucHienCVD' && (
                      <div>
                        <ExecutingView
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBackList}
                          onClickDepartment={
                            this.handleClickDepartmentExecutingView
                          }
                          executingViews={
                            this.props.executingViewsReceiveDocument.items
                          }
                          actions={this.props.actions}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,
  auth: state.auth,
  item: state.revokeDocuments.item,
  securities: state.common.securities,
  priorities: state.common.priorities,
  objects: state.common.objects,
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
  executingViewsReceiveDocument: {
    ...state.executingViews,
    items:
      state.executingViews.items &&
      state.executingViews.items.map(id => {
        let data = state.entities.executingViews[id]
        const getDSDonVi = dsDonViTrucThuocResponse => {
          return dsDonViTrucThuocResponse.map(item => {
            let subItem = state.entities.executingViews[item.chiDaoId]

            return {
              ...item,
              executingViews: [
                subItem
                  ? {
                      ...subItem,
                      dsDonViTrucThuocResponse: getDSDonVi(
                        subItem.dsDonViTrucThuocResponse
                      ),
                    }
                  : undefined,
              ],
            }
          })
        }

        return {
          ...data,
          dsDonViTrucThuocResponse: getDSDonVi(data.dsDonViTrucThuocResponse),
        }
      }),
  },
})

DetailRevokePage.propTypes = {
  item: PropTypes.object,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailRevokePage)
