import React, { Component } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Authorization, Permission } from '../auth'
import * as Tool from '../../helpers'

class ListFilterOld extends Component {
  static propTypes = {
    units: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    filter: {
      ngayCapNhat: {},
    },
  }

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeFilter = this.handleChangeFilter.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    isOpen: false,
    filter: {
      textSearch: '',
      ngayCapNhat: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      noiGuiId: '',
      phongBanTrinhId: this.props.filter.phongBanTrinhId || '',
    },
    disabledSelect: true,
    hiddenOption: false,
    isShowInput: false,
    dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
    dateToToString: moment().format('DD/MM/YYYY'),
  }

  componentDidMount() {
    if (this.props.auth.permission.length <= 0) {
      return
    }

    let state = this.state

    if (
      Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVANDEN_QUANLY,
      ])
    ) {
      state.disabledSelect = false
    }

    if (
      Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVANDEN_CHUYENCHIDAO,
      ])
    ) {
      state.disabledSelect = false
      state.hiddenOption = true
    }

    this.setState(state)
  }

  componentWillReceiveProps(props) {
    if (props.filter) {
      this.setState({
        filter: {
          textSearch: props.filter.textSearch || '',
          ngayCapNhat: {
            from:
              props.filter.ngayCapNhat &&
              props.filter.ngayCapNhat.from &&
              props.filter.ngayCapNhat.from.trim().length > 0
                ? moment(props.filter.ngayCapNhat.from)
                : '',
            to:
              props.filter.ngayCapNhat &&
              props.filter.ngayCapNhat.to &&
              props.filter.ngayCapNhat.to.trim().length > 0
                ? moment(props.filter.ngayCapNhat.to)
                : '',
          },
          noiGuiId: props.filter.noiGuiId || '',
          noiGui: props.filter.noiGui || '',
          phongBanTrinhId: props.filter.phongBanTrinhId || '',
        },
        dateFromToString:
          props.filter.ngayCapNhat &&
          props.filter.ngayCapNhat.from &&
          props.filter.ngayCapNhat.from.trim().length > 0
            ? moment(props.filter.ngayCapNhat.from)
            : '',
        dateToToString:
          props.filter.ngayCapNhat &&
          props.filter.ngayCapNhat.to &&
          props.filter.ngayCapNhat.to.trim().length > 0
            ? moment(props.filter.ngayCapNhat.to)
            : '',
        isShowInput: props.filter.noiGuiId === '#' ? true : false,
      })
    }

    if (!isNaN(props.open)) {
      this.setState({
        isOpen: props.open,
      })
    }
  }

  clearFilter(e) {
    let phongBanTrinhId = ''
    if (
      this.props.auth &&
      this.props.auth.mainDepartmentId &&
      !Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVANDEN_QUANLY,
      ])
    ) {
      phongBanTrinhId = this.props.auth.mainDepartmentId
    }

    if (this.props.match.params.status === 'chua-xu-ly') {
      const filter = {
        textSearch: '',
        ngayCapNhat: {
          from: '',
          to: '',
        },
        noiGuiId: '',
        phongBanTrinhId,
      }

      const date = {
        dateFromToString: '',
        dateToToString: '',
      }

      return this.setState(
        {
          filter,
          date,
        },
        () => {
          this.props.actions.getNumberDocumentPage()
          this.props.actions.getNumberEVNDocument()
          this.handleSubmit()
        }
      )
    }

    const filter = {
      textSearch: '',
      ngayCapNhat: {
        from: moment().subtract(1, 'months'),
        to: moment(),
      },
      noiGuiId: '',
      phongBanTrinhId,
    }

    const date = {
      dateFromToString: moment().subtract(1, 'months').format('DD/MM/YYYY'),
      dateToToString: moment().format('DD/MM/YYYY'),
    }

    this.setState(
      {
        filter,
        date,
      },
      () => {
        this.handleSubmit()
      }
    )
  }

  handleDateChange(name, pos, nameToString, e) {
    let value = ''
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
      [nameToString]: value,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, pos, nameToString, value) {
    if (
      pos === 'from' &&
      !this.state.filter[name].to &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'from' &&
      this.state.filter[name].to &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: this.state.filter[name].to,
        },
      })
    ) {
      return
    }

    if (
      pos === 'to' &&
      !this.state.filter[name].from &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'to' &&
      this.state.filter[name].from &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: this.state.filter[name].from,
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: undefined,
        },
      },
    })
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    let isShowInput = false
    let { noiGuiId, noiGui } = this.state.filter

    if (
      (name === 'noiGuiId' && value === '#') ||
      (noiGuiId === '#' && name !== 'noiGuiId')
    ) {
      isShowInput = true
    }

    if (name === 'noiGuiId' && value !== '#') {
      noiGui = ''
    }

    this.setState({
      isShowInput,
      filter: {
        ...this.state.filter,
        noiGui,
        [name]: value,
      },
    })
  }

  handleChangeFilter(e) {
    const filter = {
      ...this.state.filter,
      ngayCapNhat: {
        from: this.state.filter.ngayCapNhat.from
          ? moment(this.state.filter.ngayCapNhat.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayCapNhat.to
          ? moment(this.state.filter.ngayCapNhat.to).format('YYYY-MM-DD')
          : '',
      },
      [e.target.name]: e.target.value,
    }
    this.setState({ filter })
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayCapNhat: {
        from: this.state.filter.ngayCapNhat.from
          ? moment(this.state.filter.ngayCapNhat.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayCapNhat.to
          ? moment(this.state.filter.ngayCapNhat.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.status !== this.props.match.params.status) {
      this.clearFilter()
    }
  }

  render() {
    return (
      <div
        className={classnames('search-container search-ReceiveDocument', {
          'search-ReceiveDocument-other': this.state.isShowInput,
        })}
      >
        <form className="form-search-basic" onSubmit={this.handleSubmit}>
          <div className="seach-form">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 end-xs search-header-right-content">
                <div
                  className={classnames(
                    'search-header-group pull-right form-container',
                    {
                      'search-processing':
                        this.props.match.params.status !== 'chua-xu-ly',
                      'search-not-processing':
                        this.props.match.params.status !== 'dang-xu-ly',
                    }
                  )}
                >
                  {this.props.match.params.status !== 'chua-xu-ly' && (
                    <div className="search-filter-date">
                      <div className="group-datetime">
                        <label className="pt-label-inline">
                          <span className="text-label"> Từ ngày </span>
                        </label>
                        <div className="daterangepicker-group">
                          <DatePicker
                            readOnly={this.props.readOnly}
                            isClearable={
                              this.state.dateFromToString ? true : false
                            }
                            selected={this.state.filter.ngayCapNhat.from}
                            selectsStart
                            startDate={this.state.filter.ngayCapNhat.from}
                            endDate={this.state.filter.ngayCapNhat.to}
                            onChange={this.handleDateChange.bind(
                              this,
                              'ngayCapNhat',
                              'from',
                              'dateFromToString'
                            )}
                            value={this.state.dateFromToString}
                            onChangeRaw={e =>
                              this.handleChangeRaw(
                                'dateFromToString',
                                e.target.value
                              )
                            }
                            onBlur={e =>
                              this.focousOut(
                                'ngayCapNhat',
                                'from',
                                'dateFromToString',
                                e.target.value
                              )
                            }
                            format="DD/MM/YYYY"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="input-datepicker input-icon-datepicker"
                            placeholderText="Từ ngày"
                            maxDate={this.state.filter.ngayCapNhat.to}
                            popperPlacement="auto"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: '5px, 10px',
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="group-datetime">
                        <label className="pt-label-inline">
                          <span className="text-label"> Đến ngày </span>
                        </label>
                        <div className="daterangepicker-group">
                          <DatePicker
                            readOnly={this.props.readOnly}
                            isClearable={
                              this.state.dateToToString ? true : false
                            }
                            selected={this.state.filter.ngayCapNhat.to}
                            selectsEnd
                            startDate={this.state.filter.ngayCapNhat.from}
                            endDate={this.state.filter.ngayCapNhat.to}
                            onChange={this.handleDateChange.bind(
                              this,
                              'ngayCapNhat',
                              'to',
                              'dateToToString'
                            )}
                            value={this.state.dateToToString}
                            onChangeRaw={e =>
                              this.handleChangeRaw(
                                'dateToToString',
                                e.target.value
                              )
                            }
                            onBlur={e =>
                              this.focousOut(
                                'ngayCapNhat',
                                'to',
                                'dateToToString',
                                e.target.value
                              )
                            }
                            format="DD/MM/YYYY"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="input-datepicker input-icon-datepicker"
                            placeholderText="Đến ngày"
                            minDate={this.state.filter.ngayCapNhat.from}
                            popperPlacement="auto"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: '5px, 10px',
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="search-filter-select">
                    <div className="select-filter">
                      <div className="group-select group-select-inline">
                        <label className="pt-label-inline">
                          <span className="text-label"> Trình lãnh đạo </span>
                        </label>
                        <div className="pt-select pt-select-inline">
                          <select
                            name="phongBanTrinhId"
                            onChange={this.handleChangeFilter}
                            value={this.state.filter.phongBanTrinhId}
                            disabled={this.state.disabledSelect}
                          >
                            <option value="">Tất cả</option>
                            {this.state.hiddenOption === false && (
                              <option value="00000000-0000-0000-0000-000000000000">
                                Chưa
                              </option>
                            )}
                            {this.props.departments.map((item, i) => (
                              <option key={i} value={item.phongBanId}>
                                {item.maPhongBan}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="filter-search-list">
                      <div className="search-keyword">
                        <input
                          autoFocus={false}
                          autoComplete="off"
                          name="textSearch"
                          className="search"
                          type="text"
                          onChange={this.handleInputChange}
                          placeholder="Tìm số cv, trích yếu (hoặc số cv:trích yếu)..."
                          value={this.state.filter.textSearch}
                        />
                      </div>
                      <div className="btn-reload-group">
                        <Button className="btn-search" type="submit">
                          <span className="pt-icon pt-icon-search"></span>
                        </Button>
                        <Button
                          type="button"
                          className="btn-reload"
                          onClick={this.clearFilter}
                        >
                          <span className="icon-Loading"></span>
                        </Button>
                        <Button
                          type="button"
                          className="btn-toogle"
                          onClick={this.handleClick}
                        >
                          <span
                            className={classnames('pt-icon', {
                              'pt-icon-double-chevron-up': this.state.isOpen,
                              'pt-icon-double-chevron-down': !this.state.isOpen,
                            })}
                          ></span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Collapse isOpen={this.state.isOpen}>
            <div className="search-advanced">
              <div className="row end-xs">
                {this.props.match.params.status === 'chua-xu-ly' && (
                  <div className="group-datetime">
                    <label className="pt-label label-datepicker">
                      Ngày cập nhật
                    </label>
                    <div className="daterangepicker-group">
                      <DatePicker
                        readOnly={this.props.readOnly}
                        isClearable={this.state.dateFromToString ? true : false}
                        selected={this.state.filter.ngayCapNhat.from}
                        selectsStart
                        startDate={this.state.filter.ngayCapNhat.from}
                        endDate={this.state.filter.ngayCapNhat.to}
                        onChange={this.handleDateChange.bind(
                          this,
                          'ngayCapNhat',
                          'from',
                          'dateFromToString'
                        )}
                        value={this.state.dateFromToString}
                        onChangeRaw={e =>
                          this.handleChangeRaw(
                            'dateFromToString',
                            e.target.value
                          )
                        }
                        onBlur={e =>
                          this.focousOut(
                            'ngayCapNhat',
                            'from',
                            'dateFromToString',
                            e.target.value
                          )
                        }
                        format="DD/MM/YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="input-datepicker input-icon-datepicker"
                        placeholderText="Từ ngày"
                        maxDate={this.state.filter.ngayCapNhat.to}
                        popperPlacement="auto"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                      <DatePicker
                        readOnly={this.props.readOnly}
                        isClearable={this.state.dateToToString ? true : false}
                        selected={this.state.filter.ngayCapNhat.to}
                        selectsEnd
                        startDate={this.state.filter.ngayCapNhat.from}
                        endDate={this.state.filter.ngayCapNhat.to}
                        onChange={this.handleDateChange.bind(
                          this,
                          'ngayCapNhat',
                          'to',
                          'dateToToString'
                        )}
                        value={this.state.dateToToString}
                        onChangeRaw={e =>
                          this.handleChangeRaw('dateToToString', e.target.value)
                        }
                        onBlur={e =>
                          this.focousOut(
                            'ngayCapNhat',
                            'to',
                            'dateToToString',
                            e.target.value
                          )
                        }
                        format="DD/MM/YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="input-datepicker input-icon-datepicker"
                        placeholderText="Đến ngày"
                        minDate={this.state.filter.ngayCapNhat.from}
                        popperPlacement="auto"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="group-select">
                  <label className="pt-label">
                    Cơ quan ban hành
                    <div className="pt-select">
                      <select
                        name="noiGuiId"
                        onChange={this.handleInputChange}
                        value={this.state.filter.noiGuiId}
                      >
                        <option value="">Tất cả</option>
                        {this.props.units.map((item, i) => (
                          <option key={i} value={item.donViId}>
                            {item.tenVietTat}
                          </option>
                        ))}
                        <option value="#">Khác</option>
                      </select>
                    </div>
                  </label>
                </div>
                {this.state.isShowInput && (
                  <div className="input-element-other">
                    <label className="pt-label">
                      <span className="emty-nbsp">&nbsp;</span>
                      <div className="pt-form-content">
                        <input
                          name="noiGui"
                          className="pt-input"
                          placeholder="Nhập cơ quan ban hành khác..."
                          type="text"
                          dir="auto"
                          value={
                            this.state.filter && this.state.filter.noiGui
                              ? this.state.filter.noiGui
                              : ''
                          }
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </form>
      </div>
    )
  }
}

export default ListFilterOld
