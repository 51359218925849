import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

const DEFAULT_ID = '00000000-0000-0000-0000-000000000000'
const TOTAL_NAME = 'Tổng cộng'
const TYPE_SEND_SIGN_NUMBER = 1
const TYPE_INTERNAL_SIGN_NUMBER = 2

class ListItemDepartment extends Component {
  constructor(props) {
    super(props)

    this.convertNumber = this.convertNumber.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
  }

  convertNumber = number => {
    if (_.isNumber(number)) {
      return number
    }
    return 0
  }

  redirectToDepartmentSignManully = (item, isTotalRaw, type) => {
    if (!item || !item.id || isTotalRaw || !type) {
      return
    }
    const data = this.props.filter
    let typePath = 'phong-ban'
    if (item.isDonVi) {
      typePath = 'don-vi'
    }
    this.props.actions.refreshStatistic(false)
    return (
      this.props.history &&
      this.props.history.push({
        pathname: `/tien-ich/thong-ke-ky-so/chi-tiet/van-ban-ky-tay/${typePath}/${item.id}`,
        state: {
          ngayGiaoViec: {
            from: data.startDate,
            to: data.endDate,
            loaiCongVan: type,
          },
        },
      })
    )
  }

  render() {
    const { item, number } = this.props
    if (!item) {
      return
    }
    const isTotalRaw = item.id === DEFAULT_ID && item.name === TOTAL_NAME
    return (
      <tr
        className={classnames({
          'text-bold': isTotalRaw,
        })}
      >
        <td className="gerenal-list-utilities">
          {isTotalRaw ? '' : number > 9 ? number : '0' + number}
        </td>
        <td className="gerenal-list-utilities">{item.name}</td>
        <td
          className={classnames('gerenal-list-utilities text-center', {
            'over-time': !isTotalRaw,
          })}
          onClick={this.redirectToDepartmentSignManully.bind(
            this,
            item,
            isTotalRaw,
            TYPE_SEND_SIGN_NUMBER
          )}
        >
          <span className="hover-over-time">
            {' '}
            {this.convertNumber(item.tongSoCongVanKyTayDi)}{' '}
          </span>
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanKySoDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tyLeCongVanKySoDi)}
        </td>
        <td
          className={classnames('gerenal-list-utilities text-center', {
            'over-time': !isTotalRaw,
          })}
          onClick={this.redirectToDepartmentSignManully.bind(
            this,
            item,
            isTotalRaw,
            TYPE_INTERNAL_SIGN_NUMBER
          )}
        >
          <span className="hover-over-time">
            {' '}
            {this.convertNumber(item.tongSoCongVanKyTayNoiBo)}{' '}
          </span>
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanKySoNoiBo)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanNoiBo)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tyLeCongVanKySoNoiBo)}
        </td>
      </tr>
    )
  }
}

export default ListItemDepartment
