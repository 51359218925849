import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { ListItem } from '../../components/SignNumber/Project'
import { GridView } from '../../components/common'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import { Utils } from '../../helpers'
import { SelectTree } from '../../components/common'
import { Redirect } from 'react-router-dom'

import Pagination from 'components/Pagination'

class ListProjectPage extends React.Component {
  constructor(props) {
    super(props)

    let state = {
      isActive: 0,
      donViId: this.props.auth.mainUnitId,
      sort: undefined,
      isEmpty: false,
      isLoading: true,
      page: 1,
      project: [],
      unitSelect: [],
      check: undefined,
      isError: false,
      isRedirect: false,
      redirectTo: null,
    }

    this.state = state

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleCheckBox = this.handleCheckBox.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleSelectTree = this.handleSelectTree.bind(this)
  }

  static propTypes = {
    project: PropTypes.object.isRequired,
  }

  componentDidMount() {
    let donViId = Utils.getData('Project-Unit', {})
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined

    if (donViId === undefined) {
      donViId = this.state.donViId
    }

    this.setState({ donViId }, () => {
      if (permission) {
        this.props.actions.getCategoryUnitMultiLevel().then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
        })
      } else {
        this.props.actions
          .getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
          .then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true, isLoading: false })
            }
          })
      }
    })

    let state = this.state
    let preData = Tool.Utils.preData(`ListProjectPage`)

    if (preData) {
      state.donViId = preData.donViId
      state.isActive = preData.isActive
      state.page = preData.page
      state.sort = preData.sort
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.unitSelect.items !== this.props.unitSelect.items) {
      this.setState({ unitSelect: nextProps.unitSelect.items })
    }

    if (
      this.props.project.list &&
      nextProps.project.list &&
      nextProps.project.list.items !== this.props.project.list.items
    ) {
      this.setState(
        {
          project: nextProps.project.list.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    }
  }

  clickPagination(page) {
    this.setState({ page, isLoading: true }, () => {
      this.submitFilter(
        this.state.donViId,
        this.state.isActive,
        this.state.page,
        this.state.sort
      )
    })
  }

  submitFilter(
    donViId = this.state.donViId,
    isActive = this.state.isActive,
    page = 1,
    sort
  ) {
    this.setState({ donViId, isActive, page, sort }, () => {
      Tool.Utils.preData(`ListProjectPage`, { donViId, isActive, page, sort })
      Tool.Utils.preData(`Project-Unit`, donViId)
      this.props.actions
        .getListProjectPage(
          donViId,
          isActive === 0
            ? null
            : isActive === 1
            ? true
            : isActive === 2
            ? false
            : undefined,
          page,
          sort
        )
        .then(res => {
          if (!res.error && res.payload.data.result.total === 0) {
            this.setState({ isEmpty: true })
          } else {
            this.setState({ isEmpty: false })
          }
        })
        .then(() => {
          this.setState({ isLoading: false })
        })
    })
  }

  handleSubmitRemoveItem = id => {
    let total = 0
    let page = this.state.page
    if (
      this.props.project &&
      this.props.project.list &&
      this.props.project.list.total
    ) {
      total = this.props.project.list.total
    }

    this.props.actions
      .deleteProjectItem(id)
      .then(res => {
        if (
          res.error &&
          res.error.response &&
          res.error.response.data &&
          res.error.response.data.message &&
          typeof res.error.response.data.message === 'string' &&
          res.error.response.data.message.trim().length > 0
        ) {
          return this.props.actions.commonAddToasterMessage({
            message: res.error.response.data.message,
            intent: Intent.DANGER,
          })
        }
        if (res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
        if (total > 0) {
          total -= 1
        }
        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }

        if (page === 0) {
          page = 1
        }

        this.submitFilter(
          this.state.donViId,
          this.state.isActive,
          page,
          this.state.sort
        )
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
          intent: Intent.DANGER,
        })
      })
  }

  handleCheckBox = () => {
    this.submitFilter(
      this.state.donViId,
      this.state.isActive,
      this.state.page,
      this.state.sort
    )
  }

  handleSelect(e) {
    if (e.target.name === 'isActive') {
      this.setState(
        { isActive: Number(e.target.value), isLoading: true },
        () => {
          this.submitFilter(
            this.state.donViId,
            this.state.isActive,
            this.state.page,
            this.state.sort
          )
        }
      )
    }
  }

  handleSelectTree(e) {
    if (e !== undefined) {
      this.setState(
        {
          donViId: e,
          isLoading: true,
        },
        () => {
          this.submitFilter(
            this.state.donViId,
            this.state.isActive,
            this.state.page,
            this.state.sort
          )
        }
      )
    }
  }

  handleClickDetail = data => {
    if (!data) {
      return
    }
    this.setState({
      isRedirect: true,
      redirectTo: `/ky-so-mo-rong/du-an/thong-tin/${data.duAnId}`,
    })
  }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
          }}
        />
      )
    }
    return (
      <MasterLayout typeSidebar="signNumber">
        {/* Heading */}
        <div className="heading-page">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="text-heading-page">
                <span className="icon-Tao_Du_An icon-heading"></span>
                <span className="text-heading">Danh sách dự án</span>
              </h3>
              <Link
                to="/ky-so-mo-rong/du-an/them-moi"
                className="btn-action pull-right hidden-btn"
              >
                <span className="name-btn">THÊM MỚI</span>
                <span className="pt-icon pt-icon-small-plus"></span>
              </Link>
            </div>
          </div>
        </div>

        {/* Table List */}
        <div className="page-list-container">
          <div className="search-container search-group-container">
            <div className="row seach-form">
              <div className="col-xs-12 col-sm-12 col-sm-12 end-xs">
                <div className="form-search-basic select-project">
                  <div className="search-header-group form-container search-system-module">
                    <div className="search-filter-select">
                      <div className="select-filter">
                        <div className="group-select group-select-inline">
                          <label className="pt-label pt-label-inline">
                            <span className="text-label">Hiệu lực</span>
                          </label>
                          <div className="pt-select pt-select-inline">
                            <select
                              className="select-project-system"
                              name="isActive"
                              onChange={this.handleSelect}
                              value={this.state.isActive}
                            >
                              <option value={0}>Tất cả</option>
                              <option value={1}>Có sử dụng</option>
                              <option value={2}>Không sử dụng</option>
                            </select>
                          </div>
                        </div>
                        <div className="search-system-tree search-filter-select">
                          <label className="pt-label pt-label-inline">
                            <span className="text-label">Đơn vị</span>
                          </label>
                          <SelectTree
                            data={this.state.unitSelect}
                            type="Unit"
                            value={
                              this.state.unitSelect.length > 0
                                ? this.state.donViId
                                : ''
                            }
                            handleChange={this.handleSelectTree}
                            isLoading={
                              this.state.unitSelect.length > 0 ? false : true
                            }
                            permission={
                              !this.props.auth.permission.find(
                                item =>
                                  item === 'ADMIN' || item === 'SUPERADMIN'
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-container">
            <GridView
              isLoading={this.state.isLoading}
              classNames={'pt-table'}
              page={this.state.page}
              headers={headers.ListProjectPage}
              itemShape={ListItem}
              data={this.state.project}
              onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
              onHandleClickItem={this.HandleClickItem}
              onClickDetail={this.handleClickDetail}
              onFilter={this.handleSort}
              isEmpty={this.state.isEmpty}
              handleCheckBox={this.handleCheckBox}
              actions={this.props.actions}
            />

            {!this.state.isLoading &&
              !this.state.isEmpty &&
              !this.state.isError && (
                <Pagination
                  current={this.state.page}
                  total={Math.ceil(
                    this.props.project.list.total / Types.PAGINATION_LIMIT
                  )}
                  onChange={this.clickPagination}
                />
              )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,

  auth: state.auth,

  project: {
    ...state.system,
    list: {
      ...state.system.list,
      items: state.system.list.items,
    },
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },

  departments: state.common.leaderDepartments,

  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListProjectPage)
