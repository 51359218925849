import {
  CATEGORY_FETCH_DEPARTMENT_CHILD,
  CATEGORY_STAFF_SUBJECT,
  CATEGORY_UNIT,
  CATEGORY_UNIT_CHILD,
  CATEGORY_FETCH_DEPARTMENT_MULTILEVEL,
  CATEGORY_FETCH_UNIT_MULTILEVEL,
  CATEGORY_FETCH_CONNECTED_UNIT,
  CATEGORY_FETCH_CONNECTED_UNIT_EVN,
} from '../constants/ActionTypes'

const initialState = {
  categoryDepartmentChild: [],
  categoryStattUnit: [],
  categoryUnit: [],
  categoryUnitChild: [],
  categoryDepartmentMultiLevel: [],
  categoryUnitMultiLevel: [],
  categoryConnectedUnit: [],
  categoryConnectedUnitEVN: [],
}

export default function contact(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_FETCH_UNIT_MULTILEVEL:
      return {
        ...state,
        categoryUnitMultiLevel: action.payload.data.result,
      }

    case CATEGORY_FETCH_DEPARTMENT_MULTILEVEL:
      return {
        ...state,
        categoryDepartmentMultiLevel: action.payload.data.result,
      }

    case CATEGORY_UNIT_CHILD:
      return {
        ...state,
        categoryUnitChild: action.payload.data.result.items,
      }

    case CATEGORY_UNIT:
      return {
        ...state,
        categoryUnit: action.payload.data.result.items,
      }

    case CATEGORY_FETCH_DEPARTMENT_CHILD:
      return {
        ...state,
        categoryDepartmentChild: action.payload.data.result,
      }

    case CATEGORY_STAFF_SUBJECT:
      return {
        ...state,
        categoryStattUnit: action.payload.data.result,
      }

    case CATEGORY_FETCH_CONNECTED_UNIT:
      return {
        ...state,
        categoryConnectedUnit: action.payload.data.result,
      }

    case CATEGORY_FETCH_CONNECTED_UNIT_EVN:
      return {
        ...state,
        categoryConnectedUnitEVN: action.payload.data.result,
      }
    default:
      return state
  }
}
