import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Menu = ({ dataSource, className, onClick }) => {
  return (
    <ul className={classNames('task-manager-menu', className)}>
      {dataSource?.map((item, index) => (
        <li key={index}>
          <button type="button" onClick={event => onClick(event, item)}>
            <span
              className={classNames('icon', {
                [`${item.icon}`]: item?.icon,
                [`${item.className}`]: item?.className,
              })}
            ></span>
            {item.name ? (
              <span
                className={classNames('name', {
                  [`${item.className}`]: item?.className,
                })}
              >
                {item.name}
              </span>
            ) : null}
          </button>
        </li>
      ))}
    </ul>
  )
}

Menu.defaultProps = {
  dataSource: [],
  className: '',
  onClick: () => {},
}

Menu.propTypes = {
  dataSource: PropTypes.array,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Menu
