import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InformationSteeringItem from './InformationSteeringItem'
import classnames from 'classnames'
import { NotFound } from '../../components/common/'

class InformationSteering extends Component {
  static propTypes = {
    informationSteering: PropTypes.object,
    parentInformationSteering: PropTypes.object,
    title: PropTypes.string,
  }

  render() {
    return (
      <div
        className={classnames(
          'history-steering-box history-steering-Information mt0 container-form-action',
          {
            'history-steering-showmobile': this.props.isShowMobile,
          }
        )}
      >
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">Thông tin chỉ đạo</h4>
        </div>
        <div className="container-form-action-sub container-detail">
          {!this.props.informationSteering &&
            !this.props.informationSteeringLevel && <NotFound />}
          {this.props.informationSteering &&
            this.props.informationSteering.hasOwnProperty('chiDaoId') && (
              <div className="box-item">
                <div className="heading noborder-bottom">
                  <h3 className="heading-title">
                    <span className="icon-Chidao"></span>
                    <span className="heading-title-text">
                      Thông tin chỉ đạo
                    </span>
                  </h3>
                </div>
                <div className="form-container">
                  <InformationSteeringItem
                    informationSteering={this.props.informationSteering}
                  />
                </div>
                <div className="clear-fix"></div>
              </div>
            )}
          {this.props.informationSteeringLevel &&
            this.props.informationSteeringLevel.hasOwnProperty('chiDaoId') && (
              <div className="box-item">
                <div className="heading noborder-bottom">
                  <h3 className="heading-title">
                    <span className="icon-Chidao"></span>
                    <span className="heading-title-text">
                      Thông tin chỉ đạo của đơn vị cấp trên
                    </span>
                  </h3>
                </div>
                <div className="form-container">
                  <InformationSteeringItem
                    informationSteering={this.props.informationSteeringLevel}
                  />
                </div>
                <div className="clear-fix"></div>
              </div>
            )}
        </div>
      </div>
    )
  }
}

export default InformationSteering
