import React from 'react'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts'

class SimpleLineChart extends React.Component {
  render() {
    return (
      <ResponsiveContainer width="100%" height={267}>
        <LineChart
          data={this.props.data}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
        >
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <CartesianGrid strokeDasharray="1 1" />
          <Tooltip />
          <Legend />
          <Line
            type="linear"
            dataKey="Công văn đi"
            stroke="#f6b026"
            strokeWidth={3}
          />
          <Line
            type="linear"
            dataKey="Công văn đến"
            stroke="#0078d6"
            strokeWidth={3}
          />
          <Line
            type="linear"
            dataKey="Công văn nội bộ"
            stroke="#60b83a"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    )
  }
}

export default SimpleLineChart
