export const headers = [
  {
    classNames: 'column-5',
    value: 'Stt',
  },
  {
    classNames: 'column-13 min-width-200',
    value: 'Id chức năng',
  },
  {
    classNames: '',
    value: 'Ghi chú',
  },
  {
    classNames: 'column-13 center head-check-all',
    checkAll: true,
    value: 'Sử dụng',
  },
]

export default headers
