import React from 'react'
import { GridViewMultipleHeaders } from '../../../components/common'
import { Button } from '@blueprintjs/core'
import PropTypes from 'prop-types'

class TableView extends React.Component {
  constructor(props) {
    super(props)

    this.handleExport = this.handleExport.bind(this)
  }

  static propTypes = {
    heading: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    headers: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    isExporting: PropTypes.bool.isRequired,
    itemShape: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    type: PropTypes.number.isRequired,
    onExport: PropTypes.func.isRequired,
  }

  handleExport() {
    if (!this.props.type) {
      return
    }
    this.props.onExport && this.props.onExport(this.props.type)
  }

  render() {
    const {
      heading,
      page,
      headers,
      isLoading,
      isEmpty,
      isError,
      isExporting,
      itemShape,
      data,
      filter,
      history,
      type,
      colSpanLoading,
    } = this.props
    return (
      <div className="table-container table-multiple-header">
        <div className="heading-content">
          <h2 className="heading-icon-17 main-heading">
            <span className="icon-left icon-double-file"></span>
            <span className="heading-text">{heading}</span>
            <div className="button-heading-right">
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={isExporting}
                disabled={isLoading || !data || data.length === 0}
              />
            </div>
          </h2>
        </div>
        <GridViewMultipleHeaders
          classNames="pt-table"
          page={page}
          headers={headers}
          itemShape={itemShape}
          data={data}
          isLoading={isLoading}
          isEmpty={isEmpty}
          filter={filter}
          history={history}
          isError={isError}
          type={type}
          colSpanLoading={colSpanLoading}
          actions={this.props.actions}
        />
      </div>
    )
  }
}

export default TableView
