import React from 'react'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as Actions from '../../actions/'
import { Checkbox, Button } from '@blueprintjs/core'
import moment from 'moment'
import { DepartmentPerform } from '../../components/common/'
import 'moment/locale/vi'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import classnames from 'classnames'
import DatePicker from 'react-datepicker'
import includes from 'lodash/includes'
import { Permission, Authorization } from '../../components/auth'

class AssignPage extends React.Component {
  constructor(props) {
    super(props)
    let phongBanId = null
    if (this.props.auth) {
      phongBanId = this.props.auth.mainDepartmentId
    }
    this.state = {
      phongBanId,
      isOpenPopup: false,
      error: {},
      tieuDe: '',
      noiDung: '',
      chuTriId: null,
      dsPhoiHop: [],
      dsXemDeBiet: [],
      pbChuTriId: null, // Danh cho lanh dao don vi
      pbPhoiHop: [], // Danh cho lanh dao don vi
      pbXemDeBiet: [], // Danh cho lanh dao don vi
      baoCaoLai: false,
      hanGiaiQuyet: moment().add(7, 'days'),
      minDate: moment(),
      fileId: null,
      fileName: '',
      dsCongViec: [],
      staffSelections: [],
      departmentSelections: [],
      isLoading: false,
      sizeFile: null,
      progress: 0,
      dealineToString: moment().add(7, 'days'),
      //isHideButton: false,
      isLeaderUnit: false,
    }

    this.handleClickCancel = this.handleClickCancel.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleGetValueGridCheckBox = this.handleGetValueGridCheckBox.bind(this)
    this.handleEventRemoveFile = this.handleEventRemoveFile.bind(this)
    this.getUploadProgress = this.getUploadProgress.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
    //this.handleScroll = this.handleScroll.bind(this);
  }

  static propTypes = {
    staffs: PropTypes.array.isRequired,
  }

  getUploadProgress = () => progress => {
    this.setState({
      progress: Math.floor((progress.loaded * 100) / progress.total),
    })
  }

  handleClickCancel = () => {
    this.props.history && this.props.history.goBack()
  }

  handleEventRemoveFile = () => {
    let state = this.state
    delete state.error.fileName
    state.fileName = ''
    state.fileId = null
    state.fileUpload = null
    state.sizeFile = null

    this.setState(state)
  }

  handleFileChange = e => {
    const error = this.state.error
    delete error.fileName
    const fileName = e.target.value
    const extension =
      fileName &&
      fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase()
    let sizeFile = e.target.files && e.target.files[0] && e.target.files[0].size
    const typesFile = [
      'pdf',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'ppsx',
      'pps',
    ]
    if (!includes(typesFile, extension)) {
      error.fileName = MESSAGE.ValidateForm.FILE_TYPE(typesFile)
    } else if (sizeFile > 1024 * 1024 * 25) {
      error.fileName = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
    }

    this.setState({ error })
    if (!error.fileName) {
      let reader = new FileReader()
      let file = e.target.files[0]
      reader.onloadend = () => {
        this.setState({
          fileUpload: file,
          fileName: file.name,
          fileId: null,
          sizeFile,
        })
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  handleDateChange = (name, nameToString, e) => {
    const error = this.state.error
    delete error[name]
    let value = e ? e : undefined
    let valueToString = ''
    if (value) {
      valueToString = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      [name]: value,
      [nameToString]: valueToString,
      error,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, nameToString, value) {
    if (
      Tool.Utils.isValidDate(value, {
        after: {
          date: moment(moment().format('YYYY-MM-DD')),
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      [name]: undefined,
    })
  }

  handleInputChange = e => {
    if (e.target.type === 'checkbox') {
      return this.setState({ [e.target.name]: e.target.checked })
    }
    const error = this.state.error
    delete error[e.target.name]
    this.setState({ [e.target.name]: e.target.value, error })
  }

  handleSubmit = async e => {
    e.preventDefault()
    // CHANGED: Loại bỏ trường hợp chủ trì rỗng
    let chuTriId = this.state.chuTriId
    let pbChuTriId = this.state.pbChuTriId
    if (!this.state.chuTriId || !this.state.chuTriId.trim().length) {
      chuTriId = null
    }
    if (!this.state.pbChuTriId || !this.state.pbChuTriId.trim().length) {
      pbChuTriId = null
    }

    if (
      (this.state.isLeaderUnit === false &&
        (!chuTriId || !chuTriId.trim().length)) ||
      (this.state.isLeaderUnit === true &&
        (!pbChuTriId || !pbChuTriId.trim().length) &&
        (!chuTriId || !chuTriId.trim().length))
    ) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_CHUTRI,
        intent: Intent.WARNING,
      })
    }

    const error = {}
    let sizeFile = this.state.sizeFile
    if (!this.state.hanGiaiQuyet || this.state.hanGiaiQuyet.length === 0) {
      error.hanGiaiQuyet = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      !this.state.dsCongViec.length &&
      this.state.tieuDe.trim().length === 0
    ) {
      error.tieuDe = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (this.state.error.fileName) {
      sizeFile = null
    }

    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false, error })
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    this.setState({ isLoading: true })
    if (this.state.fileUpload) {
      await this.props.actions
        .fileUpload(this.state.fileUpload, this.getUploadProgress())
        .then(res => {
          if (res.error || !res.payload.data.result) {
            error.fileName = MESSAGE.TEXT_MESSAGE_ERROR_UPLOAD
            return this.setState({
              error,
              isLoading: false,
              fileUpload: null,
              fileName: '',
              sizeFile: null,
            })
          }

          let fileId = null
          if (
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.length
          ) {
            fileId = res.payload.data.result[0]
          }
          this.setState({
            fileId: {
              file_id: fileId.id,
              ten_file: fileId.originalName,
              url: fileId.path,
              kieu_file: fileId.type,
              kich_thuoc: fileId.length,
            },
          })
        })
    }

    this.setState({ error, sizeFile })
    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false })
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    this.setState({ isLoading: true })
    let hanGiaiQuyet = null
    if (
      this.state.hanGiaiQuyet &&
      this.state.hanGiaiQuyet.toString().length > 0
    ) {
      hanGiaiQuyet = moment(this.state.hanGiaiQuyet)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format()
    }

    let dataRequest = {
      tieu_de: this.state.tieuDe,
      noi_dung: this.state.noiDung,
      bao_cao_lai: this.state.baoCaoLai,
      han_giai_quyet: hanGiaiQuyet,
      file_dinh_kem: this.state.fileId,
      tu_tai_lieu: {},
      ds_cong_viec: this.state.dsCongViec,
      nhan_vien_chu_tri_id: chuTriId,
      nhan_vien_phoi_hop_ids: this.state.dsPhoiHop,
      nhan_vien_xem_de_biet_ids: this.state.dsXemDeBiet,
    }
    if (this.state.isLeaderUnit === true) {
      dataRequest = {
        ...dataRequest,
        pb_chu_tri_id: pbChuTriId,
        pb_phoi_hop: this.state.pbPhoiHop,
        pb_xem_de_biet: this.state.pbXemDeBiet,
      }
    }

    this.props.actions.workAssign(dataRequest).then(res => {
      if (!res.payload || res.payload.status !== 200) {
        this.setState({ isLoading: false })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }

      this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_SUCCESS,
        intent: Intent.SUCCESS,
      })
      this.props.actions.refreshPage(true)
      this.props.history && this.props.history.goBack()
    })
  }

  handleGetValueGridCheckBox = (type, data) => {
    if (!data || !Object.keys(data).length) {
      return
    }

    switch (type) {
      case 'departments':
        let chuTriId = this.state.chuTriId
        if (this.state.pbChuTriId !== data.chuTriId) {
          chuTriId = null
        }
        return this.setState({
          chuTriId,
          pbChuTriId: data.chuTriId,
          pbPhoiHop: data.dsPhoiHop,
          pbXemDeBiet: data.dsXemDeBiet,
        })
      default:
        let pbChuTriId = this.state.pbChuTriId
        if (this.state.chuTriId !== data.chuTriId) {
          pbChuTriId = null
        }
        return this.setState({
          pbChuTriId,
          chuTriId: data.chuTriId,
          dsPhoiHop: data.dsPhoiHop,
          dsXemDeBiet: data.dsXemDeBiet,
        })
    }
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.list
    ) {
      this.setState({ dsCongViec: this.props.location.state.list })
    }

    // TODO: Kiem tra truong hop LD DV hay LD PB
    if (this.props.auth && this.props.auth.permission) {
      let isLeaderUnit = Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVANDEN_CHIDAO,
      ])
      if (isLeaderUnit) {
        this.props.actions.commonFetchDepartments()
        this.props.actions.commonFetchStaffByDepartmentId(
          this.props.auth.mainDepartmentId
        )
      } else {
        this.props.actions.commonFetchStaffByDepartmentId(
          this.props.auth.mainDepartmentId
        )
      }
      this.setState({ isLeaderUnit })
    }
    // window.addEventListener('scroll', this.handleScroll);
    // let windowSize = window.innerHeight;
    // let gb = document.getElementById("groupButton");
    // let self = this;
    // setTimeout(function() {
    //   if (windowSize > gb.getBoundingClientRect().top) {
    //     self.setState({
    //       isHideButton: false
    //     });
    //   } else {
    //     self.setState({
    //       isHideButton: true
    //     });
    //   }
    // }, 100);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.staffs &&
      nextProps.staffs.length &&
      nextProps.staffs !== this.props.staffs
    ) {
      const staffSelections = []
      nextProps.staffs.forEach(item => {
        staffSelections.push({
          content: item.tenNhanVien,
          value: item.chucDanhId,
        })
      })
      this.setState({ staffSelections })
    }

    if (
      nextProps.departments &&
      nextProps.departments.length &&
      nextProps.departments !== this.props.departments
    ) {
      const departmentSelections = []
      nextProps.departments.forEach(item => {
        departmentSelections.push({
          content: item.maPhongBan,
          value: item.phongBanId,
        })
      })
      this.setState({ departmentSelections })
    }
  }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }
  //
  // handleScroll (event) {
  //   let windowSize = window.innerHeight;
  //   let gb = document.getElementById("groupButton");
  //   if (windowSize >= gb.getBoundingClientRect().top) {
  //     this.setState({
  //       isHideButton: false
  //     });
  //   } else{
  //     this.setState({
  //       isHideButton: true
  //     });
  //   }
  // }

  render() {
    return (
      <MasterLayout typeSidebar="work">
        <div className="row center-xs format-center detail-receive-document ">
          <div className="col-md-8 col-xs-12 col-sm-12 padding-0 form-width-90">
            <div className="heading-page bottom-add-receive-document">
              <h3 className="pull-left text-heading-page">
                <span className="icon-Giao_Viec icon-heading"></span>
                <span className="text-heading">Giao việc</span>
              </h3>
            </div>
            <div className="add-receive-document">
              <div className="form-container container-detail form-assign-work">
                <div className="form-padding-box">
                  {!this.state.dsCongViec.length && (
                    <div className="pt-form-group pt-intent-danger">
                      <div className="row full-row">
                        <label className="pt-label tt-label col-md-3 col-xs-12">
                          Tiêu đề
                          <span className="pt-text-muted required-input">
                            *
                          </span>
                        </label>
                        <div className="pt-form-content col-md-9 col-xs-12">
                          <textarea
                            name="tieuDe"
                            value={this.state.tieuDe}
                            onChange={this.handleInputChange}
                            rows="4"
                            className="pt-input pt-fill"
                            placeholder="Nhập tiêu đề"
                            dir="auto"
                          ></textarea>
                        </div>
                        {this.state.error.tieuDe !== undefined && (
                          <div className="pt-form-helper-text">
                            {this.state.error.tieuDe}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="pt-form-group">
                    <div className="row full-row">
                      <div className="col-xs-12 col-md-3 col-form-item">
                        <label className="pt-label">Nội dung</label>
                      </div>
                      <div className="col-xs-12 col-md-9 col-form-item">
                        <div className="pt-form-content">
                          <textarea
                            name="noiDung"
                            value={this.state.noiDung}
                            onChange={this.handleInputChange}
                            rows="1"
                            className="pt-input pt-fill"
                            dir="auto"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pt-form-group">
                    <div className="row full-row">
                      <label className="pt-label tt-label col-md-3 col-xs-5">
                        Báo cáo lại kết quả
                      </label>
                      <div className="pt-form-content col-md-7 col-xs-7">
                        <Checkbox
                          className="pt-large"
                          checked={this.state.isEnabled}
                          onChange={this.handleInputChange}
                          name="baoCaoLai"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-form-group resolve-date pt-intent-danger">
                    <div className="row full-row">
                      <label className="pt-label pt-inline resolve-date-padding0 col-md-3 col-xs-12">
                        Hạn giải quyết
                        <span className="pt-text-muted required-input">*</span>
                      </label>
                      <div className="pt-form-content pt-inline col-md-3 col-xs-4 col-sm-4">
                        <div className="pt-input-group pt-large">
                          <DatePicker
                            isClearable={
                              this.state.dealineToString ? true : false
                            }
                            readOnly
                            selected={this.state.hanGiaiQuyet}
                            onChange={this.handleDateChange.bind(
                              this,
                              'hanGiaiQuyet',
                              'dealineToString'
                            )}
                            value={this.state.dealineToString}
                            onChangeRaw={e =>
                              this.handleChangeRaw(
                                'dealineToString',
                                e.target.value
                              )
                            }
                            onBlur={e =>
                              this.focousOut(
                                'hanGiaiQuyet',
                                'dealineToString',
                                e.target.value
                              )
                            }
                            format="DD/MM/YYYY"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="input-datepicker input-icon-datepicker"
                            placeholderText="Chọn ngày..."
                            minDate={this.state.minDate}
                            popperPlacement="auto"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: '5px, 10px',
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport',
                              },
                            }}
                          />
                        </div>
                      </div>
                      {this.state.error.hanGiaiQuyet !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.error.hanGiaiQuyet}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pt-form-group pt-intent-danger">
                    <div className="row full-row">
                      <label className="pt-label col-md-3 col-xs-12">
                        File đính kèm
                      </label>
                      <div className="pt-form-content col-md-9 col-xs-12">
                        <div
                          className={classnames('pt-form-content', {
                            'upload-have-close': this.state.fileName.length > 0,
                          })}
                        >
                          <label className="pt-file-upload">
                            <input
                              type="file"
                              name="fileName"
                              onChange={this.handleFileChange}
                              disabled={this.state.isLoading}
                              onClick={event => {
                                event.target.value = null
                              }}
                            />
                            <span className="pt-file-upload-input">
                              {!this.state.fileName ||
                              this.state.fileName.length === 0
                                ? 'Chọn file đính kèm'
                                : this.state.fileName}
                            </span>
                          </label>
                          {this.state.fileName.length > 0 &&
                            !this.state.isLoading && (
                              <span
                                onClick={this.handleEventRemoveFile}
                                className="icon-bc icon-close icon-remove-file"
                              ></span>
                            )}
                          {this.state.sizeFile !== null &&
                            !isNaN(this.state.sizeFile) && (
                              <p className="pt-form-helper-text pt-form-helper-infomation">
                                Kích thước file đã upload:
                                <span className="size-file">
                                  {' '}
                                  {Tool.Utils.getFileSize(this.state.sizeFile)}
                                </span>
                              </p>
                            )}
                          {this.state.error.fileName !== undefined && (
                            <div className="pt-form-helper-text">
                              {this.state.error.fileName}
                            </div>
                          )}
                          {this.state.isLoading && this.state.progress > 0 && (
                            <div className="progress-upload-content">
                              <span className="percent-content">
                                {this.state.progress}%
                              </span>
                              <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                                <div
                                  className="pt-progress-meter"
                                  style={{ width: this.state.progress + '%' }}
                                ></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Design Update*/}
                  {/* CHANGED: Tách cá nhân và phòng ban riêng  */}
                  {/* <div className="pt-form-group">
                    <label className="pt-label">
                      Phòng ban thực hiện
                    </label>
                    <DepartmentPerform
                      options={this.state.isLeaderUnit === false ? this.state.staffSelections : this.state.departmentSelections}
                      onSubmit={this.handleGetValueGridCheckBox}
                      dsPhoiHop={this.state.isLeaderUnit === false ? this.state.dsPhoiHop : this.state.pbPhoiHop}
                      dsXemDeBiet={this.state.isLeaderUnit === false ? this.state.dsXemDeBiet : this.state.pbXemDeBiet}
                      chuTriId={this.state.isLeaderUnit === false ? this.state.chuTriId : this.state.pbChuTriId}
                      selectedCompositiveDepartments={[]}
                      selectedOnlySeeDepartments={[]}
                      titles={['CT', 'PH', 'XĐB']}
                      isHasRadio={true}
                      type={this.state.isLeaderUnit === false ? 'Cá nhân' : 'Phòng ban'}
                    />
                  </div> */}
                  {/* Phòng ban */}
                  {this.state.isLeaderUnit === true && (
                    <div className="pt-form-group">
                      <label className="pt-label pt0">
                        Phòng ban thực hiện
                      </label>
                      <DepartmentPerform
                        options={this.state.departmentSelections}
                        onSubmit={this.handleGetValueGridCheckBox.bind(
                          this,
                          'departments'
                        )}
                        dsPhoiHop={this.state.pbPhoiHop}
                        dsXemDeBiet={this.state.pbXemDeBiet}
                        chuTriId={this.state.pbChuTriId}
                        selectedCompositiveDepartments={[]}
                        selectedOnlySeeDepartments={[]}
                        titles={['CT', 'PH', 'XĐB']}
                        isHasRadio={true}
                        type="Phòng ban"
                      />
                    </div>
                  )}
                  {/* Cá nhân */}
                  <div className="pt-form-group">
                    <label className="pt-label pt0">Cá nhân thực hiện</label>
                    <DepartmentPerform
                      options={this.state.staffSelections}
                      onSubmit={this.handleGetValueGridCheckBox.bind(
                        this,
                        'staffs'
                      )}
                      dsPhoiHop={this.state.dsPhoiHop}
                      dsXemDeBiet={this.state.dsXemDeBiet}
                      chuTriId={this.state.chuTriId}
                      selectedCompositiveDepartments={[]}
                      selectedOnlySeeDepartments={[]}
                      titles={['CT', 'PH', 'XĐB']}
                      isHasRadio={true}
                      type="Cá nhân"
                    />
                  </div>
                </div>
              </div>
              <div id="groupButton" className="buttton-action-footer">
                <Button
                  className="pt-button btn-cancel"
                  onClick={this.handleClickCancel}
                  disabled={this.state.isLoading}
                >
                  <span className="pt-icon icon-back"></span>
                  Quay lại
                </Button>
                <Button
                  className="pt-button btn-blue-color"
                  onClick={this.handleSubmit}
                  loading={this.state.isLoading}
                  disabled={this.state.isLoading}
                >
                  <span className="text-content">Lưu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
              <div className="buttton-actionfooter-fixtop buttton-action-footer">
                <Button
                  className="pt-button btn-blue-color"
                  onClick={this.handleSubmit}
                  loading={this.state.isLoading}
                  disabled={this.state.isLoading}
                >
                  <span className="pt-icon icon-save"></span>
                  <span className="text-content">Lưu</span>
                </Button>
                <Button
                  className="pt-button btn-cancel"
                  onClick={this.handleClickCancel}
                  disabled={this.state.isLoading}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  staffs: state.common.staffs,
  departments: state.common.departments,
  readOnly: state.common.enableReadOnly,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignPage)
