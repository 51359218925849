import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@blueprintjs/core'
import { NotFound, LoadingItem } from '../../components/common'

class CheckboxGroup extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedOptions: PropTypes.array,
    disabledList: PropTypes.array,
    isDisabledAll: PropTypes.bool,
    disabledListNotChecked: PropTypes.array,
    isLoadingData: PropTypes.bool,
  }

  state = {
    disabledOptions: [],
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.disabledList !== this.state.disabledOptions &&
      nextProps.disabledList !== undefined
    ) {
      this.setState({ disabledOptions: nextProps.disabledList })
    }
  }

  render() {
    return (
      <div className="row checkboxgroup-container">
        {this.props.isLoadingData && (
          <div className="loading-container col-xs-12 col-sm-12 col-md-12">
            <LoadingItem />
            <LoadingItem />
            <LoadingItem />
          </div>
        )}
        {!this.props.isLoadingData &&
          this.props.options &&
          this.props.options.length > 0 &&
          this.props.options.map((option, i) => (
            <div key={i} className={this.props.colum}>
              <div className="item-checkbox disabled-checkbox">
                <Checkbox
                  className="pt-inline"
                  name={this.props.name}
                  value={option.value}
                  checked={
                    this.props.selectedOptions.indexOf(option.value) > -1 ||
                    (this.props.isNotCheckDisabledItem === undefined &&
                      this.state.disabledOptions.indexOf(option.value) > -1)
                  }
                  disabled={
                    this.props.isDisabledAll === true ||
                    this.state.disabledOptions.indexOf(option.value) > -1 ||
                    (this.props.disabledListNotChecked &&
                      this.props.disabledListNotChecked.indexOf(option.value) >
                        -1)
                  }
                  onChange={this.props.onChange}
                >
                  <span className="name_checkbox">
                    {option.content}
                    {option.codeSignNumber && (
                      <span className="code-sign-number">{` (${option.codeSignNumber})`}</span>
                    )}
                  </span>
                </Checkbox>
              </div>
            </div>
          ))}
        {this.props.isLoadingData === false &&
          (!this.props.options || this.props.options.length === 0) && (
            <NotFound />
          )}
      </div>
    )
  }
}

export default CheckboxGroup
