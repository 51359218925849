import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

import requestAction from 'helpers/request'
import { getLichSuPhanPhoi } from 'actions/doffice'
import QuaTrinhXuLyItem from './quatrinhxulyitem'
import QuaTrinhXuLyDonViCon from './quatrinhxulydonvi'

const QuaTrinhXuLyDonVi = ({
  data,
  hasChild = false,
  tokenDO,
  id,
  idDv,
  childData = [],
}) => {
  const [isOpenGroup, setOpenGroup] = useState(false)
  const [childrenData, setChildrenData] = useState(null)

  const handleToggle = useCallback(
    (hasChildrenData = false, values) => {
      setOpenGroup(prev => !prev)
      if (!hasChildrenData && !isEmpty(tokenDO)) {
        const params = {
          ID_DV: values?.iDDV || values?.idDv,
          ID_NV: tokenDO?.nhanVien?.idNv,
          ID_VB: values?.iDVB || values?.idVb,
        }
        requestAction({
          getResult: false,
          codeCheck: false,
          showToast: false,
          action: () =>
            getLichSuPhanPhoi(params, {
              tokenType: 'Bearer',
              accessToken: tokenDO?.token,
            }),
          afterResponse: response => {
            setChildrenData({
              lANHDAO: response?.data?.lANHDAO || [],
              pHONGBAN: response?.data?.pHONGBAN || [],
            })
          },
        })
      }
    },
    [tokenDO]
  )

  return (
    <div className="font-roboto">
      <div
        className={classNames('do-collapse-title mt10', { pl20: !hasChild })}
      >
        {hasChild && (
          <span
            className={classNames(
              'pt-icon font-size-13 wh-16 mr5 color-blue cursor-pointer',
              {
                'pt-icon-chevron-up': isOpenGroup,
                'pt-icon-chevron-down': !isOpenGroup,
              }
            )}
            onClick={() =>
              hasChild ? handleToggle(!isEmpty(childrenData), data) : null
            }
          />
        )}
        <p className="mr10 font-weight-600 color-text">
          {data?.tENDV || data?.tenDv || ''}
        </p>
      </div>
      {!isEmpty(childrenData) && isOpenGroup && (
        <div className="pl15">
          {childrenData?.lANHDAO?.map?.((item, index) => (
            <QuaTrinhXuLyItem
              key={index}
              hasChild={item.isChildren}
              isLeader={item?.isChidao ?? false}
              id={id}
              idDv={idDv}
              tokenDO={tokenDO}
              itemData={item}
            />
          ))}
          {childrenData?.pHONGBAN?.map?.((item, index) => (
            <QuaTrinhXuLyItem
              key={index}
              hasChild={item.isChildren}
              isLeader={item?.isChidao ?? false}
              id={id}
              idDv={idDv}
              tokenDO={tokenDO}
              itemData={item}
            />
          ))}
        </div>
      )}
      <div className="ml15">
        {size(childData) !== 0 &&
          isOpenGroup &&
          childData?.map((item, index) => (
            <QuaTrinhXuLyDonViCon
              key={index}
              id={id}
              idDv={item?.idDv}
              hasChild={true}
              tokenDO={tokenDO}
              data={item}
            />
          ))}
      </div>
    </div>
  )
}

export default QuaTrinhXuLyDonVi
