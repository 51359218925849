import React, { Component } from 'react'

class NotFound extends Component {
  render() {
    return (
      <div className="not-found-content">
        <div className="thumb-icon-content">
          <span className="icon icon-multifile-disable"></span>
        </div>
        <p className="description">
          {this.props.message
            ? this.props.message
            : 'Hiện tại không có nội dung'}
        </p>
      </div>
    )
  }
}

export default NotFound
