import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { headers } from 'constants/table/QuanLyCongViec'
import { MasterLayout } from 'components/layout'
import { ListItem } from 'components/System/Banner'
import { GridView } from 'components/common'
import * as Types from 'constants/Api'
import * as Tool from 'helpers'
import { get, size } from 'lodash'
import { HeadingPage } from 'components/common'
import { getDanhSachSlider } from 'actions/task'
import { commonAddToasterMessage } from 'actions'

import Pagination from 'components/Pagination'

const LINK = '/he-thong/quan-ly-cong-viec/banner/them-moi'
class ListBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataResult: [],
      dataResultTotal: 0,
      isLoading: false,
      isEmpty: false,
      isError: false,
      page: 1,
    }

    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
  }

  componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData('ListBanner')
    if (preData) {
      state.page = preData.page
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.page)
    })
  }

  submitFilter = async (page = 1) => {
    try {
      this.setState({ page, isLoading: true, isEmpty: false })
      let params = {
        Page: page,
        Limit: 10,
        Sort: 'id DESC',
      }
      const response = await getDanhSachSlider(params)
      if (
        get(response, 'payload.data.items') &&
        get(response, 'payload.data.items').length !== 0
      ) {
        this.setState({
          dataResult: get(response, 'payload.data.items'),
          dataResultTotal: get(response, 'payload.data.totalCount'),
        })
      }

      if (
        get(response, 'payload.data.items') &&
        get(response, 'payload.data.items').length === 0
      ) {
        this.setState({ isEmpty: true })
      }
    } catch (error) {
      this.setState({ isError: true })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleSubmitRemoveItem = async id => {
    let { dataResult } = this.state
    let loadingAPI = false
    let newDataResult = dataResult.filter(elm => elm.id !== id)
    if (size(newDataResult) === 0) {
      loadingAPI = true
    }

    this.setState(
      {
        dataResult: newDataResult,
      },
      () => {
        if (loadingAPI) {
          this.submitFilter(1)
        }
      }
    )
  }

  render() {
    const { dataResult, dataResultTotal, isError, isEmpty, isLoading, page } =
      this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <HeadingPage
          namePage="Quản lý banner / bài viết"
          iconPage="icon-files-image"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <div>
          {/* Table List */}
          <div className="page-list-container list-sign-number">
            <div
              className="table-container table-container-full"
              style={{
                paddingBottom: 0,
                background:
                  Math.ceil(dataResultTotal / Types.PAGINATION_LIMIT) > 1
                    ? '#fff'
                    : '#eff3f5',
              }}
            >
              <GridView
                actions={this.props.actions}
                isLoading={isLoading}
                classNames={'pt-table table-middle'}
                page={page}
                headers={headers.ListBanner}
                itemShape={ListItem}
                data={dataResult || []}
                onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                isEmpty={isEmpty}
                isError={isError}
                auth={this.props.auth}
                onSubmit={this.submitFilter}
                pageCount={Math.ceil(dataResultTotal / Types.PAGINATION_LIMIT)}
                history={this.props.history}
              />
              {!isLoading && !isEmpty && !isError && (
                <Pagination
                  current={page}
                  total={Math.ceil(dataResultTotal / Types.PAGINATION_LIMIT)}
                  onChange={this.clickPagination}
                />
              )}
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonAddToasterMessage,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListBanner)
