import { TaskCreate } from 'components/Work'
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  setTokenWithQuery,
  getMeCreateWork,
  getMessengeDetailCreateWork,
  setTokenWithQueryCreateWork,
  getHoTroCNTTChiTietCreateWork,
  getUserPermissionCreateWork,
  getHoTroCNTTFilesCreateWork,
} from '../../actions'
import { getContent, htmlClear, convertEntities } from 'helpers/Helper'
import { stringMention } from 'helpers/chat'

const TaoCongViec = ({ location, actions, mainRole }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [documentDetails, setDocumentDetail] = useState({})
  const { accessToken, id, type } = queryString.parse(location.search)

  useEffect(() => {
    if (accessToken && id) {
      const callWithType = () => {
        if (id && type === 'CHAT') {
          actions.getMessengeDetailCreateWork(id).then(res => {
            if (res) {
              const { noiDung, fileDinhKem } = res.payload.data.result
              setDocumentDetail({
                messageId: id,
                trichYeu: convertEntities(
                  stringMention(htmlClear(getContent(noiDung)))
                ),
                fileDinhKemKhac: fileDinhKem || [],
              })
            }
          })
        } else if (id && type === 'SERVICE_DESK') {
          actions.getHoTroCNTTChiTietCreateWork(id).then(res => {
            const response = res?.payload?.data?.request
            if (response) {
              setDocumentDetail(prev => ({
                ...prev,
                hoTroCNTTId: id,
                trichYeu: convertEntities(
                  stringMention(htmlClear(getContent(response?.subject)))
                ),
                description: convertEntities(
                  stringMention(htmlClear(getContent(response?.description)))
                ),
              }))
            }
          })
          actions.getHoTroCNTTFilesCreateWork(id).then(res => {
            if (res?.payload?.data) {
              setDocumentDetail(prev => ({
                ...prev,
                fileDinhKemKhac:
                  res?.payload?.data?.attachments?.map(file => ({
                    fileId: file?.id,
                    tenFile: file?.name,
                    kieuFile: file?.contentType,
                    kichThuoc: file?.size?.value,
                    url: file?.contentUrl,
                  })) || [],
              }))
            }
          })
        }
      }

      Promise.all([
        setIsLoading(true),
        actions.setTokenWithQueryCreateWork({
          tokenType: 'Bearer',
          accessToken: accessToken,
        }),
        actions.getMeCreateWork().then(res => {
          const dsChucDanh = res?.payload?.data?.result?.dsChucDanh
          if (dsChucDanh) {
            const role = dsChucDanh?.find(role => role?.phongBanChinh)
            actions.getUserPermissionCreateWork(role.id)
          }
        }),
        callWithType(),
      ]).then(() => {
        setIsLoading(false)
      })
    }
  }, [accessToken, id, actions, type])

  return (
    <div>
      {
        // If `isLoading` is true, render a loading message or a spinner
        isLoading ? (
          <p>Loading...</p>
        ) : (
          // If `token` is truthy, render the `TaskCreate` component
          accessToken &&
          mainRole?.phongBanId &&
          mainRole?.donViId && (
            <TaskCreate
              isCreateWork={true}
              documentDetail={{
                ...documentDetails,
              }}
              phongBanIdCreateWork={mainRole?.phongBanId}
              donViIdCreateWork={mainRole?.donViId}
            />
          )
        )
      }
    </div>
  )
}

const mapStateToProps = state => {
  const meCreateWork = state.createWork?.meCreateWork
  const mainRole = meCreateWork?.dsChucDanh?.find(role => role?.phongBanChinh)

  return {
    mainRole,
    dataLanhDaoDonVi: null,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setTokenWithQuery,
      getMeCreateWork,
      getMessengeDetailCreateWork,
      setTokenWithQueryCreateWork,
      getHoTroCNTTChiTietCreateWork,
      getUserPermissionCreateWork,
      getHoTroCNTTFilesCreateWork,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaoCongViec)
