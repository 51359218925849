import { Authorization, Permission } from 'components/auth'
import { Intent, Position, Tooltip } from '@blueprintjs/core'

import { GroupMenuSideBar } from '../layout'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { get, isNumber } from 'lodash'
import classnames from 'classnames'
import withRouter from 'react-router/withRouter'
import { checkDonVi, checkPermissionMenu } from '../../helpers/Helper'

class Sidebar extends React.Component {
  static propTypes = {
    // default
    isSubmit: PropTypes.bool.isRequired,
    onHandleClick: PropTypes.func.isRequired,
    isShowMenuMobile: PropTypes.bool.isRequired,
    clickSidebarMobile: PropTypes.func.isRequired,
    //CongVanDen
    inProcess: PropTypes.number,
    noProcess: PropTypes.number,
    //CongViec
    notAssign: PropTypes.number,
    seeToKnow: PropTypes.number,
    // ky so
    sendTotal: PropTypes.number,
    internalTotal: PropTypes.number,
    // Y kien
    yKienTotal: PropTypes.number,
  }

  state = {
    isActive: [[]],
  }

  componentDidMount() {
    if (this.props.data) {
      const quyensMenu = this.props?.quyensMenu
      let isActive = []
      const data = this.props.data
        ?.filter(
          e =>
            (!e?.permissionMenu ||
              (e?.permissionMenu &&
                checkPermissionMenu(quyensMenu, e?.permissionMenu))) &&
            e?.subMenu?.some(
              sub =>
                (!sub?.permissionMenu ||
                  (sub?.permissionMenu &&
                    checkPermissionMenu(quyensMenu, sub?.permissionMenu))) &&
                (!sub?.permission ||
                  (sub?.permission &&
                    Authorization.vaild(
                      this.props.auth.permission,
                      sub?.permission,
                      false
                    )))
            )
        )
        ?.map(elm => {
          return {
            ...elm,
            subMenu:
              elm?.subMenu?.filter(
                e =>
                  !e?.permissionMenu ||
                  (e?.permissionMenu &&
                    checkPermissionMenu(quyensMenu, e?.permissionMenu))
              ) ?? [],
          }
        })
      for (let i = 0; i < data.length; i++) {
        isActive[i] = []
        if (data[i].subMenu) {
          for (let j = 0; j < data[i].subMenu.length; j++) {
            if (this.props.location && this.props.location.pathname) {
              let location = this.props.location.pathname
              if (location === data[i].subMenu[j].path) {
                isActive[i][j] = true
                break
              }

              if (
                data[i].subMenu[j].links &&
                data[i].subMenu[j].links.find(
                  link =>
                    (location.lastIndexOf(link) === 0 &&
                      location[location.lastIndexOf(link) + link.length] ===
                        '/') ||
                    (location.lastIndexOf(link) === 0 &&
                      location.lastIndexOf('/he-thong') === 0)
                ) !== undefined
              ) {
                isActive[i][j] = true
                break
              }
            }
            isActive[i][j] = false
          }
        }
      }
      this.setState({ isActive })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      const quyensMenu = nextProps?.quyensMenu
      let isActive = []
      const data = nextProps.data
        ?.filter(
          e =>
            (!e?.permissionMenu ||
              (e?.permissionMenu &&
                checkPermissionMenu(quyensMenu, e?.permissionMenu))) &&
            e?.subMenu?.some(
              sub =>
                (!sub?.permissionMenu ||
                  (sub?.permissionMenu &&
                    checkPermissionMenu(quyensMenu, sub?.permissionMenu))) &&
                (!sub?.permission ||
                  (sub?.permission &&
                    Authorization.vaild(
                      this.props.auth.permission,
                      sub?.permission,
                      false
                    )))
            )
        )
        ?.map(elm => {
          return {
            ...elm,
            subMenu:
              elm?.subMenu?.filter(
                e =>
                  !e?.permissionMenu ||
                  (e?.permissionMenu &&
                    checkPermissionMenu(quyensMenu, e?.permissionMenu))
              ) ?? [],
          }
        })
      for (let i = 0; i < data.length; i++) {
        isActive[i] = []
        if (data[i].subMenu) {
          for (let j = 0; j < data[i].subMenu.length; j++) {
            if (nextProps.location && nextProps.location.pathname) {
              let location = nextProps.location.pathname
              if (location === data[i].subMenu[j].path) {
                isActive[i][j] = true
                break
              }

              if (
                data[i].subMenu[j].links &&
                data[i].subMenu[j].links.find(
                  link =>
                    (location.lastIndexOf(link) === 0 &&
                      location[location.lastIndexOf(link) + link.length] ===
                        '/') ||
                    (location.lastIndexOf(link) === 0 &&
                      location.lastIndexOf('/he-thong') === 0) ||
                    (location.lastIndexOf(link) === 0 &&
                      location.lastIndexOf('/ky-so-mo-rong/du-an/them-moi') ===
                        0)
                ) !== undefined
              ) {
                isActive[i][j] = true
                break
              }
            }
            isActive[i][j] = false
          }
        }
      }
      this.setState({ isActive })
    }
  }

  transformNameMenu = (nameMenu, subMenu) => {
    // 'Tìm kiếm & In sổ' nếu không có quyền in sổ thì hiển thị là 'Tìm kiếm'
    if (
      nameMenu === 'Tìm kiếm & In sổ' &&
      !Authorization.vaild(this.props.auth.permission, [
        Permission.CONGVANDI_QUANLY,
        Permission.CONGVANDEN_QUANLY,
      ])
    ) {
      return 'Tìm kiếm'
    } else {
      return nameMenu
    }
  }

  checkCongVandiThuHoi = () => {
    if (
      // Nếu danh sách quyền có cả 2 quyền thì trả về True OR
      Authorization.vaild(
        this.props.auth.permission,
        ['CONGVANDI', 'CONGVANDEN_CHIDAO'],
        false
      ) ||
      Authorization.vaild(
        this.props.auth.permission,
        ['CONGVANDI_QUANLY'],
        false
      ) ||
      Authorization.vaild(
        this.props.auth.permission,
        ['DONGY_THUHOI'],
        false
      ) ||
      Authorization.vaild(this.props.auth.permission, ['DEXUAT_THUHOI'], false)
    ) {
      return true
    }
    return false
  }

  checkQLCV = () => {
    if (
      Authorization.vaild(
        this.props.auth.permission,
        ['SUPERADMIN', 'SUDUNG_QLCV'],
        false
      )
    ) {
      return false
    }

    return true
  }

  render() {
    const AuthElement = Authorization.Element
    const quyensMenu = this.props?.quyensMenu
    return (
      <div
        className={classnames('sidebar-menu', {
          'sidebar-mobile-open': this.props.isShowMenuMobile,
        })}
      >
        <Scrollbars autoHide autoHideTimeout={100} autoHideDuration={200}>
          <div className="menu">
            <div className="icon-close-menu">
              <span onClick={this.props.clickSidebarMobile}>
                <span className="pt-icon pt-icon-cross"></span>
              </span>
            </div>
            <ul className="tree">
              <li
                className={classnames('arrow-sidebar', {
                  'arrow-sidebar-border': this.props.isSubmit,
                })}
              >
                <span
                  className={classnames('pt-icon-large pt-intent-primary', {
                    'pt-icon-chevron-right': !this.props.isSubmit,
                    'pt-icon-chevron-left': this.props.isSubmit,
                  })}
                  onClick={this.props.onHandleClick}
                />
              </li>
              {this.props.data
                ?.filter(
                  e =>
                    (!e?.permissionMenu ||
                      (e?.permissionMenu &&
                        checkPermissionMenu(quyensMenu, e?.permissionMenu))) &&
                    e?.subMenu?.some(
                      sub =>
                        (!sub?.permissionMenu ||
                          (sub?.permissionMenu &&
                            checkPermissionMenu(
                              quyensMenu,
                              sub?.permissionMenu
                            ))) &&
                        (!sub?.permission ||
                          (sub?.permission &&
                            Authorization.vaild(
                              this.props.auth.permission,
                              sub?.permission,
                              false
                            )))
                    )
                )
                ?.map((item, i) => {
                  if (item.type === 'QLCV_BANNER' && this.checkQLCV()) {
                    return null
                  }

                  return (
                    <AuthElement key={i} orPermission={item.permission || []}>
                      <GroupMenuSideBar
                        name={this.transformNameMenu(item.nameMenu)}
                        styleClass={item.className}
                        isNewFeature={item.isNewFeature}
                      >
                        <ul className="treeview-item">
                          {item?.subMenu
                            ?.filter(
                              e =>
                                !e?.permissionMenu ||
                                (e?.permissionMenu &&
                                  checkPermissionMenu(
                                    quyensMenu,
                                    e?.permissionMenu
                                  ))
                            )
                            ?.map((value, j) => {
                              if (
                                (value.type === 'CONG_VAN_EVN' &&
                                  (this.props.parentUnitId !== null ||
                                    !checkDonVi(
                                      this.props.auth.user.dsChucDanh,
                                      'EVN CPC'
                                    ))) ||
                                (value.type === 'TU_TAI_LIEU' &&
                                  checkPermissionMenu(quyensMenu, [
                                    Permission.MENU_SIDEBAR.SMENU_TTL,
                                  ]))
                              ) {
                                return null
                              }

                              if (
                                (value.type === 'CONG_VAN_DI_THU_HOI' &&
                                  this.checkCongVandiThuHoi()) ||
                                value.type === 'THONG_KE_THU_HOI_CONG_VAN'
                              ) {
                                return (
                                  <AuthElement
                                    orPermission={value.permission}
                                    key={j}
                                  >
                                    <Tooltip
                                      isDisabled={
                                        this.props.isSubmit &&
                                        this.props.isSubmit === true
                                      }
                                      tooltipClassName="tooltip-sidebar"
                                      content={value.nameMenu}
                                      intent={Intent.PRIMARY}
                                      position={Position.RIGHT}
                                    >
                                      <li
                                        className={classnames({
                                          'active item-menu':
                                            this.state.isActive &&
                                            this.state.isActive[i] &&
                                            this.state.isActive[i][j],
                                        })}
                                      >
                                        {value.newTab && (
                                          <a
                                            className="link-url"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={value.path}
                                            title={value.nameMenu}
                                          >
                                            <span
                                              className={`${value.icon} icon-sidebar`}
                                            ></span>
                                            <span className="hidden-element name-menu">
                                              {value.nameMenu}
                                            </span>
                                          </a>
                                        )}
                                        <Link
                                          className={classnames('link-url', {
                                            'hidden-dowload-app': value.newTab,
                                          })}
                                          to={value.path ? value.path : '#'}
                                          onClick={
                                            this.props.clickSidebarMobile
                                          }
                                        >
                                          <span
                                            className={`${value.icon} icon-sidebar`}
                                          ></span>
                                          <span className="hidden-element name-menu">
                                            {value.nameMenu}
                                          </span>
                                          {/*CONG_VAN_DI_THU_HOI*/}
                                          {isNumber(this.props.tongSoThuHoi) &&
                                            this.props.tongSoThuHoi > 0 &&
                                            value.type ===
                                              'CONG_VAN_DI_THU_HOI' && (
                                              <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                                {this.props.tongSoThuHoi}
                                              </span>
                                            )}
                                        </Link>
                                      </li>
                                    </Tooltip>
                                  </AuthElement>
                                )
                              }

                              return (
                                <AuthElement
                                  key={j}
                                  permission={
                                    get(value, 'permissionCondition') === 'OR'
                                      ? null
                                      : get(value, 'permission', [])
                                  }
                                  orPermission={
                                    get(value, 'permissionCondition') === 'OR'
                                      ? get(value, 'permission')
                                      : null
                                  }
                                >
                                  <Tooltip
                                    isDisabled={
                                      this.props.isSubmit &&
                                      this.props.isSubmit === true
                                    }
                                    tooltipClassName="tooltip-sidebar"
                                    content={value.nameMenu}
                                    intent={Intent.PRIMARY}
                                    position={Position.RIGHT}
                                  >
                                    <li
                                      className={classnames('item-menu', {
                                        active:
                                          this.state.isActive &&
                                          this.state.isActive[i] &&
                                          this.state.isActive[i][j],
                                      })}
                                    >
                                      {value.newTab && (
                                        <a
                                          className="link-url"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={value.path}
                                          title={value.nameMenu}
                                        >
                                          <span
                                            className={`${value.icon} icon-sidebar`}
                                          ></span>
                                          <span className="hidden-element name-menu">
                                            {value.nameMenu}
                                          </span>
                                        </a>
                                      )}

                                      <Link
                                        className={classnames('link-url', {
                                          'hidden-dowload-app': value.newTab,
                                        })}
                                        to={value.path ? value.path : '#'}
                                        onClick={this.props.clickSidebarMobile}
                                      >
                                        <span
                                          className={`${value.icon} icon-sidebar`}
                                        ></span>
                                        <span className="hidden-element name-menu">
                                          {value.nameMenu}
                                        </span>
                                        {/*CONG_VAN_CHUA_XU_LY*/}
                                        {isNumber(this.props.noProcess) &&
                                          this.props.noProcess > 0 &&
                                          value.type === 'CHUA_XU_LY' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.noProcess}
                                            </span>
                                          )}
                                        {/*CONG_VAN_EVN*/}
                                        {isNumber(this.props.evnTotal) &&
                                          this.props.evnTotal > 0 &&
                                          value.type === 'CONG_VAN_EVN' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.evnTotal}
                                            </span>
                                          )}
                                        {/*CONG_VAN_DI_THU_HOI*/}
                                        {isNumber(this.props.tongSoThuHoi) &&
                                          this.props.tongSoThuHoi > 0 &&
                                          value.type ===
                                            'CONG_VAN_DI_THU_HOI' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.tongSoThuHoi}
                                            </span>
                                          )}
                                        {/*CONG_VAN_DANG_XU_LY*/}
                                        {/*isNumber(this.props.inProcess)
                                      && this.props.inProcess > 0
                                      && value.type === 'DANG_XU_LY' &&
                                      <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">{this.props.inProcess}</span>
                                    */}
                                        {/*CHUYEN_NHAM*/}
                                        {isNumber(this.props.alertMistake) &&
                                          this.props.alertMistake > 0 &&
                                          value.type === 'CHUYEN_NHAM' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.alertMistake}
                                            </span>
                                          )}
                                        {/*CONG_VIEC_CHUA_GIAO*/}
                                        {isNumber(this.props.notAssign) &&
                                          this.props.notAssign > 0 &&
                                          value.type === 'CHUA_GIAO' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.notAssign}
                                            </span>
                                          )}
                                        {/*CONG_VIEC_CHUA_THUC_HIEN*/}
                                        {isNumber(this.props.noProcess) &&
                                          this.props.noProcess > 0 &&
                                          value.type === 'CHUA_THUC_HIEN' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.noProcess}
                                            </span>
                                          )}
                                        {/*CONG_VIEC_DANG_THUC_HIEN*/}
                                        {/*isNumber(this.props.inProcess)
                                      && this.props.inProcess > 0
                                      && value.type === 'DANG_THUC_HIEN' &&
                                      <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">{this.props.inProcess}</span>
                                    */}
                                        {/*CONG_VIEC_XEM_DE_BIET*/}
                                        {isNumber(this.props.seeToKnow) &&
                                          this.props.seeToKnow > 0 &&
                                          value.type === 'XEM_DE_BIET' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.seeToKnow}
                                            </span>
                                          )}
                                        {/* tạm thời ẩn đi phần ký số */}
                                        {/*CONG_VAN_DI_KY_SO*/}
                                        {isNumber(this.props.sendTotal) &&
                                          this.props.sendTotal > 0 &&
                                          value.type ===
                                            'CONG_VAN_DI_KY_SO' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.sendTotal}
                                            </span>
                                          )}
                                        {/*CONG_VAN_NOI_BO_KY_SO*/}
                                        {isNumber(this.props.internalTotal) &&
                                          this.props.internalTotal > 0 &&
                                          value.type ===
                                            'CONG_VAN_NOI_BO_KY_SO' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.internalTotal}
                                            </span>
                                          )}
                                        {/*Y_KIEN*/}
                                        {isNumber(this.props.yKienTotal) &&
                                          this.props.yKienTotal > 0 &&
                                          value.type === 'HDTV_Y_KIEN' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.yKienTotal}
                                            </span>
                                          )}
                                        {/*KY_SO_DU_AN*/}
                                        {isNumber(this.props.projectTotal) &&
                                          this.props.projectTotal > 0 &&
                                          value.type === 'KY_SO_DU_AN' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.projectTotal}
                                            </span>
                                          )}
                                        {/*KY_SO_TAP_TRUNG*/}
                                        {isNumber(
                                          this.props.kySoTapTrungTotal
                                        ) &&
                                          this.props.kySoTapTrungTotal > 0 &&
                                          value.type === 'KY_SO_TAP_TRUNG' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {this.props.kySoTapTrungTotal}
                                            </span>
                                          )}
                                        {/*LAY_Y_KIEN_DON_VI*/}
                                        {isNumber(
                                          this.props.phieuGuiYeuCauGopYCanXyLy
                                        ) &&
                                          this.props.phieuGuiYeuCauGopYCanXyLy >
                                            0 &&
                                          value.type ===
                                            'YEU_CAU_GOI_GOP_Y_CHUA_XU_LY' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {
                                                this.props
                                                  .phieuGuiYeuCauGopYCanXyLy
                                              }
                                            </span>
                                          )}
                                        {/*LAY_Y_KIEN_DON_VI*/}
                                        {isNumber(
                                          this.props.phieuNhanYeuCauGopYCanXyLy
                                        ) &&
                                          this.props
                                            .phieuNhanYeuCauGopYCanXyLy > 0 &&
                                          value.type ===
                                            'YEU_CAU_NHAN_GOP_Y_CHUA_XU_LY' && (
                                            <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">
                                              {
                                                this.props
                                                  .phieuNhanYeuCauGopYCanXyLy
                                              }
                                            </span>
                                          )}
                                        {/*CONG_VAN_DAU_THAU_KY_SO : tam thoi an di*/}
                                        {/*value.type === 'CONG_VAN_DAU_THAU_KY_SO' &&
                                      <span className="hidden-element pt-tag pt-tag-count pt-round pt-intent-danger">14</span>
                                    */}
                                        {value.type === 'GIAO_VIEC_MOI' && (
                                          <span
                                            className={classnames(
                                              'icon-new-feature pt-tag-count',
                                              {
                                                'pt-tag-new-feature':
                                                  this.props.isSubmit,
                                                'pt-tag-new-feature-collaped':
                                                  !this.props.isSubmit,
                                              }
                                            )}
                                          ></span>
                                        )}
                                      </Link>
                                    </li>
                                  </Tooltip>
                                </AuthElement>
                              )
                            })}
                        </ul>
                      </GroupMenuSideBar>
                    </AuthElement>
                  )
                })}
            </ul>
          </div>
        </Scrollbars>
      </div>
    )
  }
}

export default withRouter(Sidebar)
