import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import moment from 'moment'

class ExecutingViewBoxEvn extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
  }

  state = {
    isOpen: false,
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    let { item } = this.props
    if (item.idCha === 0) {
      return (
        <div className="executing-view-evn pl30">
          <div
            className="executing-view-evn-item cursor-pointer"
            onClick={this.toggleOpen.bind(this)}
          >
            <span
              className={classnames('pt-icon dropdown-icon', {
                'pt-icon-chevron-up':
                  item.dsPhongBanThucHien &&
                  item.dsPhongBanThucHien.length > 0 &&
                  this.state.isOpen,
                'pt-icon-chevron-down':
                  item.dsPhongBanThucHien &&
                  item.dsPhongBanThucHien.length > 0 &&
                  !this.state.isOpen,
              })}
            ></span>
            <p className="font-bold">
              {item.maPhong}
              {item.vaiTro ? (
                <span
                  className={
                    item.vaiTro === 'Chủ trì'
                      ? 'text-blue ml10'
                      : 'text-secondary ml10'
                  }
                >
                  {' '}
                  ({item.vaiTro})
                </span>
              ) : null}
            </p>
            <span>
              <span className="font-bold">Ngày: </span>
              {item.ngayGiao &&
                ' ' + moment(item.ngayGiao).format('DD/MM/YYYY HH:mm')}
            </span>
            <span>&nbsp; - &nbsp;</span>
            <span>
              <span className="font-bold">Chỉ đạo: </span>
              <span dangerouslySetInnerHTML={{ __html: item.noiDung }}></span>
            </span>
          </div>
          {item.dsPhongBanThucHien && item.dsPhongBanThucHien.length > 0 && (
            <Collapse
              isOpen={this.state.isOpen}
              className="ml50 mt10 min-width-400"
            >
              {item.dsPhongBanThucHien.map((pb, i) => (
                <ExecutingViewBoxEvn key={i} item={pb} secondaryClass />
              ))}
            </Collapse>
          )}
        </div>
      )
    } else if (this.props.secondaryClass !== undefined) {
      return (
        <div>
          <div
            onClick={this.toggleOpen.bind(this)}
            className="executing-view-evn-item cursor-pointer mb10"
          >
            <span
              className={classnames('pt-icon dropdown-icon', {
                'pt-icon-chevron-up':
                  item.dsNhanVienThucHien &&
                  item.dsNhanVienThucHien.length > 0 &&
                  this.state.isOpen,
                'pt-icon-chevron-down':
                  item.dsNhanVienThucHien &&
                  item.dsNhanVienThucHien.length > 0 &&
                  !this.state.isOpen,
              })}
            ></span>
            <p className="font-bold">
              {item.maPhong}
              {item.vaiTro ? (
                <span
                  className={
                    item.vaiTro === 'Chủ trì'
                      ? 'text-blue ml10'
                      : 'text-secondary ml10'
                  }
                >
                  ({item.vaiTro})
                </span>
              ) : null}
            </p>
            {item.noiDung && (
              <p>
                <span className="font-bold">Nội dung: </span>
                <span dangerouslySetInnerHTML={{ __html: item.noiDung }}></span>
              </p>
            )}
          </div>
          {item.dsNhanVienThucHien && item.dsNhanVienThucHien.length > 0 && (
            <Collapse isOpen={this.state.isOpen} className="ml30 min-width-400">
              <div className="mt10 mb10">
                {item.dsNhanVienThucHien.map((pb, i) => (
                  <ExecutingViewBoxEvn key={i} item={pb} />
                ))}
              </div>
            </Collapse>
          )}
        </div>
      )
    } else {
      return (
        <div className="executing-view-evn-item">
          <span
            className={classnames('pt-icon dropdown-icon', {
              'icon-man-chutri': item.vaiTro && item.vaiTro === 'Chủ trì',
              'icon-man-phoihop': item.vaiTro && item.vaiTro === 'Phối hợp',
            })}
          ></span>
          <div className="font-bold pl30">
            <span>
              {item.tenNhanVien}
              {item.vaiTro ? (
                <span
                  className={
                    item.vaiTro === 'Chủ trì'
                      ? 'text-blue ml5'
                      : 'text-secondary ml5'
                  }
                >
                  {' '}
                  ({item.vaiTro})
                </span>
              ) : null}
            </span>
            <span
              className={classnames('status-job', {
                'no-process': item.tinhTrang === 'Chưa xử lý',
                process: item.tinhTrang === 'Đang xử lý',
                done: item.tinhTrang === 'Đã xử lý',
              })}
              style={{ float: 'right', marginTop: '7px' }}
            >
              {item.tinhTrang}
            </span>
            {item.noiDung && (
              <p>
                <span className="font-bold">Thực hiện: </span>
                <span className="font-regular">{item.noiDung}</span>
              </p>
            )}
          </div>
        </div>
      )
    }
  }
}

export default ExecutingViewBoxEvn
