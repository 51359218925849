import React, { Component } from 'react'
import ReactPaginate from 'react-paginate'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { commonAddToasterMessage } from '../../actions/'
// import { Intent } from '@blueprintjs/core';

class Paginate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: '',
    }
  }

  handlePageClick = page => {
    if (page) {
      this.props.onPageChange && this.props.onPageChange(page)
    }
  }

  // onChangePage = (e) => {
  //   const re = /^[0-9\b]+$/;
  //   if (e.target.value === '' || re.test(e.target.value)) {
  //     return this.setState({
  //       page: e.target.value,
  //     });
  //   }

  //   return;
  // };

  // onKeyPressEnter = (e) => {
  //   let { key } = e;
  //   let page = Number(this.state.page);
  //   const pageCount = this.props.pageCount;
  //   if (key === 'Enter' && page) {
  //     if (pageCount && page <= pageCount) {
  //       return (
  //         this.props.onPageChange &&
  //         this.props.onPageChange({
  //           selected: page - 1,
  //         })
  //       );
  //     }

  //     return this.props.actions.commonAddToasterMessage({
  //       message: `Giới hạn số trang không quá ${this.props.pageCount}`,
  //       intent: Intent.WARNING,
  //     });
  //   }
  // };

  render() {
    const { forcePage, initialPage, pageCount, marginPage, pageRange } =
      this.props
    // const { page } = this.state;
    return (
      <div className="pagination-change-page">
        <ReactPaginate
          previousLabel={<span className="pt-icon pt-icon-arrow-left"></span>}
          nextLabel={<span className="pt-icon pt-icon-arrow-right"></span>}
          breakLabel={<button className="footer-three-dot">...</button>}
          breakClassName="break-me"
          forcePage={forcePage}
          initialPage={initialPage}
          pageCount={pageCount}
          marginPagesDisplayed={marginPage}
          pageRangeDisplayed={pageRange}
          onPageChange={this.handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
          disableInitialCallback
        />
        {/* {pageCount >= 3 && (
          <div className="pagination-input">
            <span className="pagination-text">Đến</span>
            <input
              type="text"
              value={page}
              onChange={this.onChangePage}
              onKeyPress={this.onKeyPressEnter}
            />
          </div>
        )} */}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonAddToasterMessage,
    },
    dispatch
  ),
})

export default connect(null, mapDispatchToProps)(Paginate)
