import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage, SelectTree } from '../../components/common'
import { SaveNoteCategory } from '../../components/System'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Utils } from '../../helpers'

const LINK = '/he-thong/so-luu/them-moi'
class SaveNoteCategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  state = {
    donViId: this.props.auth.mainUnitId,
    permission: undefined,
    isRedirect: false,
    unitSelect: [],
    saveNote: [],
    isLoading: true,
    isError: false,
  }

  componentDidMount() {
    let donViId = Utils.getData('SaveNote', {})
    let permission =
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
      undefined

    if (donViId === undefined) {
      donViId = this.props.auth.mainUnitId
    }

    this.setState({ donViId }, () => {
      if (permission) {
        this.props.actions.getCategoryUnitMultiLevel().then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
        })
      } else {
        this.props.actions
          .getCategoryUnitMultiLevel(this.props.auth.mainUnitId)
          .then(res => {
            if (res.error || (res.payload && res.payload.status !== 200)) {
              this.setState({ isError: true, isLoading: false })
            }
          })
      }
      this.props.actions
        .commonFetchSaveCodes(1, donViId, true, null)
        .then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
        })
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth !== this.props.auth) {
      if (nextProps.auth.permission.length === 0) {
        return
      }
      const permission =
        nextProps.auth.permission.find(item => item === 'ADMIN') === undefined
      this.setState({ permission })
    }

    if (nextProps.saveCodes.items !== this.props.saveCodes.items) {
      this.setState(
        {
          saveNote: nextProps.saveCodes.items,
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    } else {
      this.setState({ isLoading: false })
    }

    if (nextProps.unitSelect.items !== this.props.unitSelect.items) {
      this.setState({ unitSelect: nextProps.unitSelect.items })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.donViId !== this.state.donViId &&
      prevState.isRedirect !== this.state.isRedirect
    ) {
      this.props.actions
        .commonFetchSaveCodes(1, this.state.donViId, true, null)
        .then(res => {
          if (res.error || (res.payload && res.payload.status !== 200)) {
            this.setState({ isError: true, isLoading: false })
          }
          Utils.saveData('SaveNote', this.state.donViId)
          if (this.state.isRedirect) {
            this.setState({ isRedirect: false })
          }
        })
    }
  }

  handleChange(e) {
    if (e !== undefined) {
      this.setState({
        donViId: e,
        isRedirect: true,
        isLoading: true,
      })
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <HeadingPage
          namePage="Danh mục sổ lưu"
          iconPage="icon-save-note"
          showButton={true}
          linkButton={LINK}
          nameButton="Thêm Mới"
        />
        <div className="listrole-container">
          <div className="search-container search-group-container">
            <div className="row seach-form">
              <div className="col-xs-12 col-sm-12 col-sm-12 end-xs">
                <form
                  className="form-search-basic"
                  onSubmit={this.handleSubmit}
                >
                  <div className="search-header-group form-container search-system-module">
                    <div className="search-system-tree">
                      <label className="pt-label pt-label-inline">
                        <span className="text-label">Đơn vị</span>
                      </label>
                      <SelectTree
                        data={this.state.unitSelect}
                        type="Unit"
                        permission={this.state.permission}
                        value={
                          this.state.unitSelect.length > 0
                            ? this.state.donViId
                            : ''
                        }
                        handleChange={this.handleChange}
                        isLoading={
                          this.state.unitSelect.length > 0 ? false : true
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <SaveNoteCategory
            items={this.state.saveNote}
            actions={this.props.actions}
            auth={this.props.auth}
            donViId={this.state.donViId}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
          ></SaveNoteCategory>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  saveCodes: {
    ...state.saveCodes,
    items: state.common.saveCodes,
  },

  auth: {
    ...state.auth,
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveNoteCategoryPage)
