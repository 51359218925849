import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FileDinhKem } from '../../../components/System/service'
import _ from 'lodash'
import emojione from 'emojione'

export default class TinChuyenTiepItem extends Component {
  getContent = str => {
    if (str.startsWith('[messageForwardJSON]')) {
      return str.split('[messageForwardJSON]')[2]
    } else {
      return str
    }
  }

  urlify(text) {
    const urlRegex = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi
    text = text.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}">${url}</a>`
    })

    return emojione.toImage(text)
  }

  render() {
    let { message } = this.props
    return (
      <div className="statistics-box-chuyentiep">
        <span className="quote-icon icon-right-quote" />
        <div
          dangerouslySetInnerHTML={{
            __html: this.urlify(
              this.getContent(
                _.get(message, 'noiDung').trim().replace(/<\/p>/g, '\n')
              )
            ),
          }}
        />
        <FileDinhKem
          auth={this.props.auth}
          files={_.get(message, 'fileDinhKem')}
        />
      </div>
    )
  }
}

TinChuyenTiepItem.propTypes = {
  message: PropTypes.object.isRequired,
}
