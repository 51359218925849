import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyAE5UYB0C_oNyISQaAdXezJIOzXqHlftp0',
  authDomain: 'cpc-eoffice.firebaseapp.com',
  databaseURL: 'https://cpc-eoffice.firebaseio.com',
  projectId: 'cpc-eoffice',
  storageBucket: 'cpc-eoffice.appspot.com',
  messagingSenderId: '139008978227',
  appId: '1:139008978227:web:8583aafafcd062d121e246',
  measurementId: 'G-WY0WJK1LHP',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

export default firebaseApp
