import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TextTruncate from 'react-text-truncate'
import { LoadingItem } from '../../components/common/'
import { Link } from 'react-router-dom'

class BoxNotification extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickPreviousPage = this.handleClickPreviousPage.bind(this)
    this.handleClickNextPage = this.handleClickNextPage.bind(this)
  }

  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.array,
    pageCount: PropTypes.number,
    page: PropTypes.number,
    isLoading: PropTypes.bool,
    isEmpty: PropTypes.bool,
    onClickPreviousPage: PropTypes.func,
    onClickNextPage: PropTypes.func,
  }

  handleClickPreviousPage = () => {
    this.props.onClickPreviousPage &&
      this.props.onClickPreviousPage(this.props.type)
  }

  handleClickNextPage = () => {
    this.props.onClickNextPage && this.props.onClickNextPage(this.props.type)
  }

  render() {
    return (
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 content-notification-box">
        <h3 className="heading-notification-box">{this.props.title}</h3>
        {this.props.isLoading === true ? (
          <div className="loading-container">
            <LoadingItem />
            <LoadingItem />
            <LoadingItem />
          </div>
        ) : this.props.isEmpty === true ? (
          <div className="error-page-container">
            <div className="error-content">
              <img
                src={
                  process.env.PUBLIC_URL + '/images/search-result-not-found.png'
                }
                alt="search-result-not-found"
                className="img-thumb-error"
              />
              <p className="description-note">
                Không có dữ liệu trong hệ thống
              </p>
            </div>
          </div>
        ) : (
          <div className="block-notification-content">
            <ul className="list-notification">
              {this.props.data &&
                this.props.data.length > 0 &&
                this.props.data.map((item, i) => (
                  <li className="item" key={i}>
                    <Link
                      to={`/thong-bao/chi-tiet/${item.id}`}
                      className="title-notification"
                    >
                      <span className="icon-Bao_Cao icon-title"></span>
                      <span className="content">
                        <TextTruncate
                          line={2}
                          truncateText="…"
                          text={item.tieuDe}
                        />
                      </span>
                    </Link>
                    <span className="date-update">
                      <span className="date">
                        {item.ngayTao &&
                          moment(item.ngayTao).format('DD/MM/YYYY')}
                      </span>
                      <span className="time">
                        {item.ngayTao && moment(item.ngayTao).format('HH:mm')}
                      </span>
                    </span>
                  </li>
                ))}
            </ul>
            {!isNaN(this.props.pageCount) && this.props.pageCount > 1 && (
              <div className="footer-list-notification">
                <ul className="pagination">
                  <li
                    className={classnames('previous', {
                      disabled: this.props.page && this.props.page === 1,
                    })}
                  >
                    <span
                      className="item-arrow"
                      onClick={this.handleClickPreviousPage}
                    >
                      <span className="pt-icon pt-icon-arrow-left"></span>
                    </span>
                  </li>
                  <li
                    className={classnames('next', {
                      disabled:
                        this.props.page &&
                        this.props.pageCount &&
                        this.props.page === this.props.pageCount,
                    })}
                  >
                    <span
                      className="item-arrow"
                      onClick={this.handleClickNextPage}
                    >
                      <span className="pt-icon pt-icon-arrow-right"></span>
                    </span>
                  </li>
                </ul>
              </div>
            )}
            <div className="clearfix"></div>
          </div>
        )}
      </div>
    )
  }
}

export default BoxNotification
