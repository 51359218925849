import { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import moment from 'moment'

import requestAction from 'helpers/request'
import Utils from 'helpers/utils'
import {
  getWorkTypes,
  getCayPhongBan,
  getDsNhanVien,
  getDsTag,
  getCongViecPhongBanChuaGiao,
  getCongViecPhongBanDaGiao,
} from 'actions/task'
import {
  WORK_PRIORITY,
  DEADLINE_STATUS,
  ASSIGN_TYPE,
  WORK_STATUS,
  WORK_APPROVED,
} from 'constants/Enum'

const useTaskDepartment = ({
  actions,
  match,
  history,
  hasShowAll,
  mainDepartmentId,
}) => {
  const calendarRef = useRef()
  const type = match.params?.type
  const deadlineSource = [
    { label: 'Tất cả', value: null },
    { label: 'Gần đến hạn', value: DEADLINE_STATUS.CON_HAN },
    { label: 'Quá hạn', value: DEADLINE_STATUS.QUA_HAN },
  ]
  const prioritizesSource = [
    { label: 'Tất cả', value: null },
    { label: 'Cao', value: WORK_PRIORITY.CAO },
    { label: 'Bình thường', value: WORK_PRIORITY.BINH_THUONG },
    { label: 'Thấp', value: WORK_PRIORITY.THAP },
  ]
  const statusSource = [
    { label: 'Tất cả', value: null },
    { label: 'Chưa thực hiện', value: WORK_STATUS.CHUA_THUC_HIEN },
    { label: 'Đang thực hiện', value: WORK_STATUS.DANG_THUC_HIEN },
    { label: 'Đã thực hiện', value: WORK_STATUS.HOAN_THANH },
  ]
  const reportSource = [
    { label: 'Tất cả', value: null },
    { label: 'Có', value: true },
    { label: 'Không', value: false },
  ]
  const statusApprobalSource = [
    {
      label: 'Tất cả',
      value: 'ALL',
    },
    {
      label: 'Chưa duyệt',
      value: WORK_APPROVED.NOT_APPROVED_YET,
    },
    {
      label: 'Đã duyệt',
      value: WORK_APPROVED.APPROVED,
    },
    {
      label: 'Chờ duyệt',
      value: WORK_APPROVED.WAITING_APPROVAL,
    },
  ]

  const [isFetching, setIsFetching] = useState(false)
  const [departments, setDepartments] = useState([])
  const [departmentFetched, setDepartmentFetched] = useState([])
  const [tab, setTab] = useState('LIST')
  const [tags, setTags] = useState([])
  const [filterData, setFilterData] = useState(null)
  const [listData, setListData] = useState(null)
  const [calendarData, setCalendarData] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  })
  const [viewType, setViewType] = useState('dayGridMonth')
  const [deleteData, setDeleteData] = useState(null)

  const limit = pagination?.limit

  const filterKeys = useMemo(() => {
    return {
      title: 'title',
      from: 'from',
      to: 'to',
      tags: 'tags',
      status: 'status',
      executorStorage: 'executorStorage',
      workDeadLine: 'workDeadLine',
      workPriority: 'workPriority',
      workType: 'WorkType',
      assignerId: 'AssignerId',
      requireReport: 'RequireReport',
      approval: 'Approval',
    }
  }, [])

  const fetchCalendar = useCallback(
    (params = null) => {
      if (
        tab !== 'CALENDAR' ||
        (tab === 'CALENDAR' &&
          !params?.[filterKeys.from] &&
          !params?.[filterKeys.to])
      ) {
        return
      }

      const newParams = {
        FromDate: params?.[filterKeys.from]
          ? params?.[filterKeys.from]
          : undefined,
        ToDate: params?.[filterKeys.to] ? params?.[filterKeys.to] : undefined,
        TagIds:
          params?.[filterKeys.tags] && params?.[filterKeys.tags]?.length !== 0
            ? params?.[filterKeys.tags]
            : undefined,
        Status: params?.[filterKeys.status]
          ? params?.[filterKeys.status]
          : undefined,
        Title: params?.[filterKeys.title]
          ? params?.[filterKeys.title]
          : undefined,
        WorkType:
          params?.[filterKeys.workType] &&
          params?.[filterKeys.workType] !== 'ALL'
            ? params[filterKeys.workType]
            : null,
        Page: 1,
        Limit: -1,
      }

      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () =>
          type === 'da-giao'
            ? getCongViecPhongBanDaGiao(newParams)
            : getCongViecPhongBanChuaGiao(newParams),
        afterResponse: response => {
          setFilterData(params)
          setCalendarData(response?.result?.items || [])
        },
      })
    },
    [type, tab, filterKeys]
  )

  const fetchList = useCallback(
    (page = 1, params = null) => {
      if (tab !== 'LIST') {
        return
      }

      setIsFetching(true)
      let paramsTemp = params
      let pageTemp = page
      const filterStore = Utils.preData('congviec-phongban')
      const pageStore = Utils.preData('congviec-phongban-page')
      if (filterStore) {
        paramsTemp = filterStore
      }

      if (pageStore) {
        pageTemp = pageStore
      }

      if (
        !paramsTemp &&
        (history.location?.state?.workType ||
          history.location?.state?.workDeadLine)
      ) {
        paramsTemp = {
          [filterKeys.workType]: history.location?.state?.workType || null,
          [filterKeys.workDeadLine]:
            history.location?.state?.workDeadLine || null,
        }
        history.replace({
          ...history.location,
          state: undefined,
        })
      }

      const newParams = {
        Title: paramsTemp?.[filterKeys.title]
          ? paramsTemp?.[filterKeys.title]
          : undefined,
        FromDate: paramsTemp?.[filterKeys.from]
          ? paramsTemp?.[filterKeys.from]
          : undefined,
        ToDate: paramsTemp?.[filterKeys.to]
          ? paramsTemp?.[filterKeys.to]
          : undefined,
        TagIds:
          paramsTemp?.[filterKeys.tags] &&
          paramsTemp?.[filterKeys.tags]?.length !== 0
            ? paramsTemp?.[filterKeys.tags]
            : undefined,
        Status: paramsTemp?.[filterKeys.status]
          ? paramsTemp?.[filterKeys.status]
          : undefined,
        MainResponsibilityId: paramsTemp?.[filterKeys.executorStorage]
          ? paramsTemp?.[filterKeys.executorStorage]?.id
          : undefined,
        AssignerId: paramsTemp?.[filterKeys.assignerStorage]
          ? paramsTemp?.[filterKeys.assignerStorage]?.id
          : undefined,
        WorkDeadLine: paramsTemp?.[filterKeys.workDeadLine]
          ? paramsTemp?.[filterKeys.workDeadLine]
          : undefined,
        WorkPriority: paramsTemp?.[filterKeys.workPriority]
          ? paramsTemp?.[filterKeys.workPriority]
          : undefined,
        WorkType:
          paramsTemp?.[filterKeys.workType] &&
          paramsTemp?.[filterKeys.workType] !== 'ALL'
            ? paramsTemp?.[filterKeys.workType]
            : null,
        RequireReport:
          paramsTemp?.[filterKeys.requireReport] !== null
            ? paramsTemp?.[filterKeys.requireReport]
            : undefined,
        Approval:
          paramsTemp?.[filterKeys.approval] &&
          paramsTemp?.[filterKeys.approval] !== 'ALL'
            ? paramsTemp?.[filterKeys.approval]
            : undefined,
        Page: pageTemp,
        Limit: limit,
      }

      requestAction({
        showToast: false,
        codeCheck: false,
        getResult: false,
        action: () =>
          type === 'da-giao'
            ? getCongViecPhongBanDaGiao(newParams)
            : getCongViecPhongBanChuaGiao(newParams),
        afterResponse: response => {
          Utils.preData('congviec-phongban', paramsTemp)
          Utils.preData('congviec-phongban-page', pageTemp)
          setPagination(prev => ({
            ...prev,
            page: pageTemp,
          }))
          setFilterData(paramsTemp)
          setListData(response?.result)
        },
        afterAction: () => {
          setIsFetching(false)
        },
      })
    },
    [type, tab, limit, filterKeys, history]
  )

  const onChangeFilter = useCallback(
    ({ name, isReFetch = true, isMultipleKey = false }) =>
      value => {
        Utils.preData('congviec-phongban')
        Utils.preData('congviec-phongban-page')
        let temp = filterData
        if (name === 'PAGE') {
          return fetchList(value, temp)
        } else if (name === 'RESET') {
          if (tab === 'CALENDAR') {
            calendarRef.current.getApi().changeView('dayGridMonth')
            setViewType('dayGridMonth')
            calendarRef.current.getApi().today()

            const fromDate = calendarRef.current?.getApi().view.activeStart
            const toDate = calendarRef.current?.getApi().view.activeEnd
            temp = {
              [filterKeys.from]: moment(fromDate).format('YYYY-MM-DD'),
              [filterKeys.to]: moment(toDate).format('YYYY-MM-DD'),
            }
          } else {
            temp = {}
          }
        } else if (isMultipleKey) {
          temp = {
            ...temp,
            ...value,
          }
        } else {
          temp = {
            ...temp,
            [name]: value,
          }
        }

        setFilterData(temp)
        if (isReFetch) {
          if (tab === 'CALENDAR') {
            return fetchCalendar(temp)
          }

          return fetchList(1, temp)
        }
      },
    [tab, filterData, filterKeys, setViewType, fetchList, fetchCalendar]
  )

  const onRowClick = data => {
    return history.push(
      `/quan-ly-cong-viec/phong-ban/${type}/chi-tiet/${data?.id}`
    )
  }

  const onChangeTab = data => {
    if (data?.value === tab) {
      return
    }

    setTab(data?.value)
    setFilterData(null)
    if (data?.value === 'LIST') {
      fetchList(1)
    }
  }

  const fetchDepartments = useCallback(() => {
    requestAction({
      showToast: false,
      successCode: 0,
      action: getCayPhongBan,
      afterResponse: (list = []) =>
        setDepartments(
          list.map(phongBan => ({
            value: {
              id: phongBan?.phongBanId,
              type: ASSIGN_TYPE.PHONG_BAN,
            },
            label: phongBan?.maPhongBan,
            children: [],
          }))
        ),
    })
  }, [])

  const convertEmployee = useCallback(
    employee => ({
      showAvatar: true,
      value: {
        id: employee?.chucDanhId,
        type: ASSIGN_TYPE.CA_NHAN,
      },
      label: employee?.tenNhanVien,
      avatar: employee?.anhDaiDien,
    }),
    []
  )

  const fetchUsers = useCallback(
    async ({ id: departmentId, type }) => {
      if (
        departmentFetched.includes(departmentId) ||
        type !== ASSIGN_TYPE.PHONG_BAN
      )
        return
      let users = []
      await requestAction({
        showToast: false,
        action: () => getDsNhanVien(departmentId),
        afterResponse: ({ items = [] }) => {
          setDepartmentFetched(prev => [...prev, departmentId])
          users = items.map(convertEmployee)
          setDepartments(prev =>
            hasShowAll
              ? prev.map(department =>
                  department?.value?.id === departmentId
                    ? {
                        ...department,
                        children: users,
                      }
                    : department
                )
              : users
          )
        },
      })
      return users
    },
    [departmentFetched, convertEmployee, hasShowAll]
  )

  const fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => setTags(items),
    })
  }, [])

  const fetchWorkTypes = useCallback(() => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: getWorkTypes,
    })
  }, [])

  useEffect(() => {
    fetchList()
    return () => {
      setListData(null)
    }
  }, [fetchList])

  useEffect(() => {
    fetchDsTag()
  }, [fetchDsTag])

  useEffect(() => {
    fetchWorkTypes()
  }, [fetchWorkTypes])

  useEffect(() => {
    if (hasShowAll) {
      fetchDepartments()
    }
  }, [fetchDepartments, hasShowAll])

  useEffect(() => {
    if (!hasShowAll && mainDepartmentId) {
      fetchUsers({ id: mainDepartmentId, type: ASSIGN_TYPE.PHONG_BAN })
    }
  }, [fetchUsers, hasShowAll, mainDepartmentId])

  useEffect(() => {
    Utils.removeData('congviec-phongban')
    Utils.removeData('congviec-phongban-page')
    setTab('LIST')
  }, [type])

  useEffect(() => {
    actions.getCounterCongViecPhongBanCaNhan()
  }, [actions])

  return {
    calendarRef,
    viewType,
    tab,
    departments,
    filterKeys,
    tags,
    deadlineSource,
    prioritizesSource,
    statusSource,
    reportSource,
    statusApprobalSource,
    filterData,
    listData,
    pagination,
    isFetching,
    calendarData,
    deleteData,
    setViewType,
    fetchUsers,
    onChangeTab,
    onChangeFilter,
    onRowClick,
    setDeleteData,
  }
}

export default useTaskDepartment
