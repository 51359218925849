export const UNSET = 'UNSET'

export const ASSIGN_TYPE = {
  DON_VI: 'ORGANIZATION',
  PHONG_BAN: 'DEPARTMENT',
  CA_NHAN: 'PERSONAL',
  NHOM_THUONG_GIAO_VIEC: 'FAVORITE_GROUP',
}

export const RESPONSIBILITY_TYPE = {
  CHU_TRI: 'MAIN_RESPONSIBILITY',
  PHOI_HOP: 'COORDINATION',
  THEO_DOI: 'WATCHER',
}

// Trạng thái thực hiện
export const WORK_STATUS = {
  CHUA_THUC_HIEN: 'NEW',
  DANG_THUC_HIEN: 'DOING',
  PENDING: 'PENDING',
  HOAN_THANH: 'DONE',
  HOAN_THANH_CHAM: 'DONE_SLOW',
}

// Mức độ ưu tiên
export const WORK_PRIORITY = {
  NORMAL: 'NORMAL',
  THAP: 'LOW',
  BINH_THUONG: 'MEDIUM',
  CAO: 'HIGH',
  KHAN_CAP: 'URGENT',
}

// Loại file
export const FILE_TYPE = {
  FILE_DINH_KEM: 'ATTACHMENT_FILE',
  FILE_THAM_KHAO: 'REFERENCE_FILE',
}

// Trạng thái công việc checklist
export const WORK_CHECKLIST_STATUS = {
  DANG_LAM: 'DOING',
  HOAN_THANH: 'DONE',
}

// Loại tham chiếu
export const REFERENCE_TYPE = {
  CONG_VIEC: 'DEPARTMENT_WORK',
  CONG_VAN_DEN: 'EOFFICE_CONGVAN_CONGVANDEN',
  CONG_VAN_KY_SO: 'EOFFICE_KYSO_CONGVANDI',
  KY_SO_MO_RONG: 'EOFFICE_KYSO_KYSOMORONG',
  KY_SO_TAP_TRUNG: 'EOFFICE_KYSO_KYSOTAPTRUNG',
  WORK_ASSIGNMENT: 'WORK_ASSIGNMENT',
}

// Trạng thái thực hiện cá nhân
export const RESPONSIBILITY_FILTER = {
  CONGVIEC_D_OFFICE: 'WATCHED_DOFFICE',
  CONGVIEC_TBKLCH: 'WATCHED_DEPARTMENT_ORGANIZATION',
  TOI_GIAO: 'ASSIGNED_BY_ME',
  DUOC_TOI_GIAO: 'ASSIGNED_BY_ME',
  TOI_DUOC_GIAO: 'ASSIGN_TO_ME',
  TOI_THEO_DOI: 'WATCHED_BY_ME',
}

// Trạng thái action của comment
export const WORK_COMMENT_STATUS = {
  TAO_MOI: 'CREATED',
  CHINH_SUA: 'EDITED',
  THU_HOI: 'REVOKED',
}

// Trạng thái deadline
export const DEADLINE_STATUS = {
  CON_HAN: 'ON_TIME',
  QUA_HAN: 'OVERDUE',
}

// Thống kê
export const STATISTIC = {
  CONGVAN_CHUA_XULY: 'REFERENCE_NOT_HANDLED',
  CONGVAN_DI_KYSO: 'REFERENCE_SIGNING',
  CONGVAN_NOIBO_KYSO: 'INTERNAL_DOCUMENT_SIGNING',
  QLCV_DANG_THUCHIEN: 'WORK_MANAGE_DOING',
  QLCV_CHUA_THUCHIEN: 'WORK_MANAGE_NEW',
  CONGVIEC_CHUA_GIAO: 'WORK_NOT_ASSIGNED',
  CONGVIEC_CHUA_THUCHIEN: 'WORK_NEW',
  KYSO_TAPTRUNG: 'CONCENTRATE_SIGNING',
  KYSO_MORONG: 'EXPAND_SIGNING',
  GUI_GOP_Y_CHUA_XULY: 'SENT_FEEDBACK_NOT_HANDLED',
  NHAN_GOP_Y_CHUA_XULY: 'RECEIVE_FEEDBACK_NOT_HANDLED',
}

// Thông kê công việc cá nhân
export const STATISTIC_WORK_RESPONSIBILITY = {
  CREATED_BY_ME: 'CREATED_BY_ME',
  ASSIGN_TO_ME: 'ASSIGN_TO_ME',
  ASSIGNED_BY_ME: 'ASSIGNED_BY_ME',
  WATCHED_BY_ME: 'WATCHED_BY_ME',
}

// Nhắc việc
export const WORK_REMINDER = {
  TUY_CHINH: 'CUSTOM',
  TRUOC_MOT_NGAY: 'ONE_DAY',
  TRUOC_HAI_NGAY: 'TWO_DAYS',
  TRUOC_BA_NGAY: 'THREE_DAYS',
  KHONG_NHAC: 'NO_REMINDER',
}

export const TASK = {
  CANHAN_DAGIAO: 'CANHAN_DAGIAO',
  CANHAN_DUOCGIAO: 'CANHAN_DUOCGIAO',
  CANHAN_THEODOI: 'WATCHED_BY_ME',
  PHONGBAN_DAGIAO: 'PHONGBAN_DAGIAO',
  PHONGBAN_CHUAGIAO: 'PHONGBAN_CHUAGIAO',
}

export const READ_STATUS = {
  DA_DOC: 'READ',
  CHUA_DOC: 'UNREAD',
}

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export const DOCUMENTS_CABINET = {
  DON_VI: '1',
  PHONG_BAN: '2',
  CA_NHAN: '3',
}

export const HO_SO_CONG_VIEC = 4

export const WORK_APPROVED = {
  NOT_APPROVED_YET: 'NOT_APPROVED_YET',
  APPROVED: 'APPROVED',
  WAITING_APPROVAL: 'WAITING_APPROVAL',
}

export const STATUS_NOTIFICATION = {
  XOA_CONG_VIEC: 'XOA_CONG_VIEC',
  XOA_GIAO_VIEC: 'XOA_GIAO_VIEC',
  HUY_CONG_VIEC: 'HUY_CONG_VIEC',
  TAO_CONG_VIEC_MEETING: 'TAO_CONG_VIEC_MEETING',
  TAO_NHIEU_CONG_VIEC_MEETING: 'TAO_NHIEU_CONG_VIEC_MEETING',
  TAO_CONG_VIEC: 'TAO_CONG_VIEC',
  TAO_CONG_VIEC_EOFFICE: 'TAO_CONG_VIEC_EOFFICE',
  GIAO_VIEC: 'GIAO_VIEC',
}

export const WORK_TYPE = {
  NORMAL: 'NORMAL',
  EOFFICE: 'EOFFICE',
  MEETING: 'MEETING',
  DOFFICE: 'DOFFICE',
  CHAT: 'CHAT',
  SERVICE_DESK: 'SERVICE_DESK',
}
