import React, { memo, useRef, useState, useEffect } from 'react'
import { get } from 'lodash'

import { Dialog, Card } from 'components/newCommon/'
import {
  DELETE_TEMPLATE_TITLE,
  DELETE_TEMPLATE,
  TEXT_BUTTON_SUBMIT,
} from 'constants/Popup'

const DeleteTemplate = ({
  onClose = () => {},
  isOpen = false,
  onConfirm = () => {},
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const [loading, setLoading] = useState(false)

  const deleteTask = async () => {
    _mountedSet(setLoading, true)
    await onConfirm()
    _mountedSet(setLoading, false)
    onClose()
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Dialog
      buttonUppercase
      isOpen={isOpen}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      // confirmDanger
      confirmText={TEXT_BUTTON_SUBMIT}
      loading={loading}
      onCancel={onClose}
      onConfirm={deleteTask}
      onClose={onClose}
      title={DELETE_TEMPLATE_TITLE}
      textCenter
    >
      <Card className="mt20 mb20">{DELETE_TEMPLATE}</Card>
    </Dialog>
  )
}

export default memo(DeleteTemplate)
