import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Pagination from 'components/Pagination'

class ListFooterNotAssigned extends Component {
  static propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
    isType: PropTypes.string,
  }

  render() {
    const { current, total, onChange, isType } = this.props
    return (
      <div className="footer-list note-list-workPage pt10">
        <div>
          {isType !== 'CVPB' && (
            <div
              className={classnames(
                'note-issues note-work',
                'note-issues-none'
              )}
            >
              <span className="name-note">Chú Thích</span>
              <ul className="list-note">
                <li className="note-item">
                  <span className="pt-icon pt-icon-symbol-square style-qh" />
                  <span className="text-qh">Công việc quá hạn</span>
                </li>
                <li className="note-item">
                  <span className="pt-icon pt-icon-symbol-square style-gqh" />
                  <span className="text-qh">Công việc gần quá hạn</span>
                </li>
                <li className="note-item">
                  <span className="icon-Save-DocumentCabinet style-icon-DocumentCabinet" />
                  <span className="text-qh">Đã lưu TTL</span>
                </li>
                <li className="note-item">
                  <span className="icon-attention-warning style-icon-DocumentCabinet" />
                  <span className="text-qh">Đã báo nhầm</span>
                </li>
              </ul>
            </div>
          )}
          <Pagination current={current} total={total} onChange={onChange} />
        </div>
      </div>
    )
  }
}

export default ListFooterNotAssigned
