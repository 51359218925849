import React, { Component } from 'react'
import { Button, Checkbox } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import { CheckboxGroup, PopupConfirm } from '../common/'
import withRouter from 'react-router/withRouter'
import classnames from 'classnames'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import DatePicker from 'react-datepicker'
import _ from 'lodash'
import moment from 'moment'
import * as POPUP from '../../constants/Popup'
import * as TRANGTHAI from '../../constants/RequestCommentStatus'

class InputForm extends Component {
  referFile
  attachFile

  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleOpenPopup = this.handleOpenPopup.bind(this)
    this.handleClosePopup = this.handleClosePopup.bind(this)

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.handleClickCancel = this.handleClickCancel.bind(this)

    // check phòng ban
    this.handleCheckCompositiveDepartments =
      this.handleCheckCompositiveDepartments.bind(this)
    this.handleCheckAllCompositiveDepartments =
      this.handleCheckAllCompositiveDepartments.bind(this)

    // check đơn vị trực thuộc
    this.handleCheckAttachedUnits = this.handleCheckAttachedUnits.bind(this)
    this.handleCheckAllAttachedUnits =
      this.handleCheckAllAttachedUnits.bind(this)
  }

  static propTypes = {
    departments: PropTypes.array.isRequired,
    attachedUnits: PropTypes.array.isRequired, // đơn vị trực thuộc
    departmentSigned: PropTypes.array.isRequired,
    unitsLeader: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    mainDepartmentId: PropTypes.string.isRequired,
    signNumber: PropTypes.object,
    requestComments: PropTypes.object,
    isUpdate: PropTypes.bool.isRequired,
    isLoadingData: PropTypes.object.isRequired,
    isSubmitFail: PropTypes.bool.isRequired,
    parentUnitId: PropTypes.string,
    parentDepartments: PropTypes.array,
    parentUnitsLeaders: PropTypes.array,
  }

  state = {
    attachQty: [''],
    fileDinhKemQty: [''],
    form: {
      soKyHieu: '',
      thoiHan: moment().add(7, 'days'),
      trichYeu: '',
      noiDungYeuCau: '',
      fileNoiDung: null,
      fileThamKhao: [],
      dsFileDinhKem: [],
      dsFileThamKhao: [],
      lanhDaoPhongBanLap: {},
      lanhDaoDonVi: {},
      phongBanNhanIds: [],
      donviNhanIds: [],
    },
    dinhKemFiles: [],
    isCheckedParentUnit: false,
    isHasSignParentUnit: false,
    isHasReceivedParentDepartment: false,
    attachFiles: [],
    fileThamKhaoTuCongViecPb: [],
    fileDinhKemTuCongViecPb: [],
    contentFiles: [],
    donviNhanIds: [], // đơn vị nhận
    phoiHopIds: [], // phòng ban nhận
    lanhDaoPhoiHopIds: [],
    phoiHopCapTrenIds: [],
    chuyenDenIds: [],
    // donViCungKyIds: [],
    chuyenDenPhongBanCapTrenIds: [],
    chuyenDenPhongBanDonViKhacIds: [],
    dsDonViCungKy: [],
    dsDonViCungKyIds: [],
    departmentSelections: [], // phòng ban góp ý,
    parentDepartmentSelections: [],
    unitsEqualSelections: [],
    isCheckedAllCompositive: false,
    isCheckedAllUnitsEqual: false,
    isOpenPopup: false,
    isRedirect: false,
    redirectTo: null,
    error: {},
    errorsAttachFiles: [],
    errorsDinhKemFiles: [],
    contentFileName: '',
    attachFileNames: [],
    dinhKemFileNames: [],
    sizeContentFile: null,
    sizeAttachedFiles: [],
    sizeDinhKemFiles: [],
    isLoading: false,
    isCanDelete: true,
    isCanUpdate: true,
    isCanUpdateFile: true,
    isCanUpdateCompositive: true,
    isCanUpdateCompositiveElectric: true,
    isCanUpdateParentCompositive: true,
    isCanUpdateLeaderUnit: true,
    isCanUpdateOtherLeaderUnit: true,
    isCanUpdateParentLeaderUnit: true,
    disabledList: this.props.mainDepartmentId
      ? new Array(this.props.mainDepartmentId)
      : [],
    leaderUnitDisabledList: [],
    parentLeaderUnitDisabledList: [],
    selectedDepartmentOtherUnits: {}, // Luu phong ban don vi khac theo donViId
    isChooseDepartmentOtherUnits: false,
    compositiveCheckedDataTypeList: [],
    receivedCheckedDataTypeList: [],
    attachedUnitSelection: [], // đơn vị trực thuộc nhận
    attachedUnitSelectionIds: [],
    dsDonViTrucThuoc: [],
    isCheckedAllAttachedUnits: false,
    dateToToString: moment().add(7, 'days').format('DD/MM/YYYY'),
  }

  componentDidMount() {
    let state = this.state

    if (
      this.props.departments &&
      this.props.isCanGetLeaderDepartment !== undefined
    ) {
      const departmentSelections = []
      this.props.departments.forEach(item => {
        let data = {
          content: item.maPhongBan,
          value: item.phongBanId,
          phongBanLanhDao: item.phongBanLanhDao,
          isCanGetLeaderDepartment: this.props.isCanGetLeaderDepartment,
          isCanShowCompositive:
            this.props.isCanGetLeaderDepartment ||
            item.phongBanLanhDao === false,
        }
        if (
          (this.props.isCanGetLeaderDepartment === false &&
            item.phongBanLanhDao === false) ||
          this.props.isCanGetLeaderDepartment
        ) {
          departmentSelections.push(data)
        }
      })
      state.departmentSelections = departmentSelections
    }

    // Đơn vị trục thuộc
    if (this.props.attachedUnits && this.props.attachedUnits.length) {
      const attachedUnitSelection = []
      const attachedUnitSelectionIds = []
      this.props.attachedUnits.forEach((item, i) => {
        attachedUnitSelection.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
        attachedUnitSelectionIds.push(item.donViId)
      })
      state.attachedUnitSelection = attachedUnitSelection
      state.attachedUnitSelectionIds = attachedUnitSelectionIds
    }
    if (this.props.isSubmitFail === true) {
      state.isLoading = false
    }

    this.setState(state)
  }

  componentWillReceiveProps(nextProps) {
    let state = this.state
    if (
      nextProps.departments &&
      nextProps.departments !== this.props.departments &&
      nextProps.isCanGetLeaderDepartment !== undefined
    ) {
      const departmentSelections = []
      nextProps.departments.forEach(item => {
        let data = {
          content: item.maPhongBan,
          value: item.phongBanId,
          phongBanLanhDao: item.phongBanLanhDao,
          isCanGetLeaderDepartment: nextProps.isCanGetLeaderDepartment,
          isCanShowCompositive:
            nextProps.isCanGetLeaderDepartment ||
            item.phongBanLanhDao === false,
        }
        if (
          (nextProps.isCanGetLeaderDepartment === false &&
            item.phongBanLanhDao === false) ||
          nextProps.isCanGetLeaderDepartment
        ) {
          departmentSelections.push(data)
        }
      })
      state.departmentSelections = departmentSelections
    }

    // Đơn vị trực thuộc
    if (
      nextProps.attachedUnits !== this.props.attachedUnits &&
      nextProps.attachedUnits.length
    ) {
      const attachedUnitSelection = []
      const attachedUnitSelectionIds = []
      nextProps.attachedUnits.forEach((item, i) => {
        attachedUnitSelection.push({
          content: item.tenVietTat,
          value: item.donViId,
        })
        attachedUnitSelectionIds.push(item.donViId)
      })
      state.attachedUnitSelection = attachedUnitSelection
      state.attachedUnitSelectionIds = attachedUnitSelectionIds
    }

    if (nextProps.isSubmitFail === true) {
      state.isLoading = false
    }

    this.setState(state)
  }

  handleFileInput(i, e) {
    const [name, id] = e.target.name.split('-')
    let state = this.state
    if (name === 'dinhKemFiles') {
      state.errorsDinhKemFiles.splice(i, 1)

      let reader = new FileReader()
      let file = e.target.files[0]

      const attachFiles = e.target.value
      const extension =
        attachFiles &&
        attachFiles
          .slice(((attachFiles.lastIndexOf('.') - 1) >>> 0) + 2)
          .toLowerCase()
      let sizeAttachedFile = file && file.size

      const extensionSupportArray = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'ppsx',
        'pps',
      ]
      if (extensionSupportArray.indexOf(extension) === -1) {
        state.errorsDinhKemFiles[i] =
          "Hãy chọn file có định dạng '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.ppsx', '.pps'."
      } else if (sizeAttachedFile && sizeAttachedFile > 1024 * 1024 * 25) {
        state.errorsDinhKemFiles[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
      }

      this.setState(state)

      if (!state.errorsDinhKemFiles[i]) {
        reader.onloadend = () => {
          state[name][id] = file
          state.sizeDinhKemFiles[i] = file.size
          state.dinhKemFileNames[i] = file.name
          state.form.dsFileDinhKem[i] = null
          state.fileDinhKemTuCongViecPb[i] = null
          this.setState(state)
        }
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    } else {
      state.errorsAttachFiles.splice(i, 1)

      let reader = new FileReader()
      let file = e.target.files[0]

      const attachFiles = e.target.value
      const extension =
        attachFiles &&
        attachFiles
          .slice(((attachFiles.lastIndexOf('.') - 1) >>> 0) + 2)
          .toLowerCase()
      let sizeAttachedFile = file && file.size

      const extensionSupportArray = [
        'pdf',
        'doc',
        'docx',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'ppsx',
        'pps',
      ]
      if (extensionSupportArray.indexOf(extension) === -1) {
        state.errorsAttachFiles[i] =
          "Hãy chọn file có định dạng '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.ppsx', '.pps'."
      } else if (sizeAttachedFile && sizeAttachedFile > 1024 * 1024 * 25) {
        state.errorsAttachFiles[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
      }

      this.setState(state)

      if (!state.errorsAttachFiles[i]) {
        reader.onloadend = () => {
          state[name][id] = file
          state.sizeAttachedFiles[i] = file.size
          state.attachFileNames[i] = file.name
          state.form.fileThamKhao[i] = null
          state.fileThamKhaoTuCongViecPb[i] = null
          this.setState(state)
        }
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }

  handleFileInputContentFiles(e) {
    const [name, id] = e.target.name.split('-')
    let state = this.state
    delete state.error.contentFile

    let reader = new FileReader()
    let file = e.target.files && e.target.files[0]

    const contentFiles = e.target.value
    const extension =
      contentFiles &&
      contentFiles
        .slice(((contentFiles.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    let sizeContentFile = file && file.size

    if (!contentFiles && state.contentFileName.length === 0) {
      state.error.contentFile = MESSAGE.TEXT_MESSAGE_DEFAULT
    } else if (extension && extension !== 'pdf') {
      state.error.contentFile = MESSAGE.TEXT_TYPE_FILE_UPLOAD_SIGNNUMBER
    } else if (sizeContentFile && sizeContentFile > 1024 * 1024 * 25) {
      state.error.contentFile = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
    }

    if (state.error.contentFile && this.props.isUpdate) {
      state.isCanUpdateCompositive = false
      state.isCanUpdateCompositiveElectric = false
      state.isCanUpdateParentCompositive = false
      state.isCanUpdateLeaderUnit = false
      state.isCanUpdateOtherLeaderUnit = false
      state.isCanUpdateParentLeaderUnit = false
    }
    this.setState(state)

    if (!state.error.contentFile) {
      reader.onloadend = () => {
        state[name][id] = file
        state.sizeContentFile = file.size
        state.contentFileName = file.name
        state.isCanUpdateCompositive = true
        state.isCanUpdateCompositiveElectric = true
        state.isCanUpdateParentCompositive = true
        state.isCanUpdateLeaderUnit = true
        state.isCanUpdateOtherLeaderUnit = true
        state.isCanUpdateParentLeaderUnit = true
        delete state.form.fileNoiDung
        this.setState(state)
      }
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name
    let state = this.state
    state.form[name] = value
    delete state.error[name]

    if (name === 'lanhDaoDonViId') {
      const lanhDaoDonVi =
        this.props.unitsLeader &&
        this.props.unitsLeader.find(item => item.chucDanhId === value)
      if (lanhDaoDonVi) {
        state.form.lanhDaoDonVi['chuc_danh_id'] = lanhDaoDonVi.chucDanhId
        state.form.lanhDaoDonVi['nhan_vien_id'] = lanhDaoDonVi.nhanVienId
        state.form.lanhDaoDonVi['phong_ban_id'] =
          lanhDaoDonVi.phongBan.phongBanId
      } else {
        state.form.lanhDaoDonVi = {}
      }
    }
    if (name === 'lanhDaoPhongBanChuTriId') {
      const lanhDaoPhongBanLap =
        this.props.departmentSigned &&
        this.props.departmentSigned.find(item => item.chucDanhId === value)
      if (lanhDaoPhongBanLap) {
        state.form.lanhDaoPhongBanLap['chuc_danh_id'] =
          lanhDaoPhongBanLap.chucDanhId
        state.form.lanhDaoPhongBanLap['nhan_vien_id'] =
          lanhDaoPhongBanLap.nhanVienId
        state.form.lanhDaoPhongBanLap['phong_ban_id'] =
          lanhDaoPhongBanLap.phongBan.phongBanId
      } else {
        state.form.lanhDaoPhongBanLap = {}
      }
    }
    this.setState(state)
  }

  handleDateChange = (name, nameToString, e) => {
    const error = this.state.error
    delete error[name]
    let value = e ? e : undefined

    this.setState({
      form: {
        ...this.state.form,
        thoiHan: value,
      },
      error,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  handleClickCancel = () => {
    let redirectTo = '/tien-ich/lay-y-kien-don-vi/danh-sach-goi'
    this.setState({
      isRedirect: true,
      redirectTo: redirectTo,
    })
  }

  handleCheckAllAttachedUnits = () => {
    this.setState({
      isCheckedAllAttachedUnits: !this.state.isCheckedAllAttachedUnits,
    })
    let donviNhanIds = this.state.donviNhanIds
    if (!this.state.isCheckedAllAttachedUnits) {
      this.state.attachedUnitSelection.forEach(item => {
        if (donviNhanIds.indexOf(item.value) === -1) {
          donviNhanIds.push(item.value)
        }
      })
    } else {
      donviNhanIds = []
    }
    this.setState({ donviNhanIds })
  }

  handleCheckAllCompositiveDepartments = () => {
    this.setState({
      isCheckedAllCompositive: !this.state.isCheckedAllCompositive,
    })
    let phoiHopIds = this.state.phoiHopIds
    if (!this.state.isCheckedAllCompositive) {
      this.state.departmentSelections.forEach(item => {
        phoiHopIds.push(item.value)
      })
    } else {
      phoiHopIds = []
    }
    this.setState({ phoiHopIds })
  }

  handleCheckCompositiveDepartments = e => {
    const newSelection = e.target.value
    let name = 'phoiHopIds'
    if (this.props.departments) {
      const phongBan = this.props.departments.find(
        item => item.phongBanId === e.target.value
      )
      if (phongBan && phongBan.phongBanLanhDao === true) {
        name = 'lanhDaoPhoiHopIds'
      }
    }
    let data = this.state[name]
    let listSelected = []
    if (data && data.length > 0 && data.indexOf(newSelection) > -1) {
      listSelected = data.filter(s => s !== newSelection)
    } else {
      listSelected = [...data, newSelection]
    }
    if (listSelected.length === data.length) {
      this.setState({ isCheckedAllCompositive: true })
    }
    this.setState({ [name]: listSelected })
  }

  handleCheckAttachedUnits = e => {
    const newSelection = e.target.value
    let name = 'donviNhanIds'
    let data = this.state[name]
    let listSelected = []
    if (data && data.length > 0 && data.indexOf(newSelection) > -1) {
      listSelected = data.filter(s => s !== newSelection)
    } else {
      listSelected = [...data, newSelection]
    }
    if (listSelected.length === data.length) {
      this.setState({ isCheckedAllAttachedUnits: true })
    }
    this.setState({ [name]: listSelected })
  }

  handleEventAddFile = (isFileThamKhao = false) => {
    if (isFileThamKhao) {
      this.setState({
        fileDinhKemQty: this.state.fileDinhKemQty.concat(
          this.state.fileDinhKemQty.length + 1
        ),
      })
    } else {
      this.setState({
        attachQty: this.state.attachQty.concat(this.state.attachQty.length + 1),
      })
    }
  }

  handleEventRemoveFile = (i, isFileDinhKem = false, isFileNoiDung = false) => {
    const state = this.state
    if (isFileNoiDung) {
      delete state.form.fileNoiDung
      state.contentFileName = ''
    }
    if (isFileDinhKem) {
      if (i === 0 && state.fileDinhKemQty.length === 1) {
        state.fileDinhKemQty[i] = ''
      } else {
        state.fileDinhKemQty.splice(i, 1)
      }
      state.dinhKemFiles.splice(i, 1)
      state.errorsDinhKemFiles.splice(i, 1)
      state.form.dsFileDinhKem.splice(i, 1)
      state.dinhKemFileNames.splice(i, 1)
      state.sizeDinhKemFiles.splice(i, 1)
      state.fileDinhKemTuCongViecPb.splice(i, 1)
      this.setState(state)
    } else {
      if (i === 0 && state.attachQty.length === 1) {
        state.attachQty[i] = ''
      } else {
        state.attachQty.splice(i, 1)
      }
      state.attachFiles.splice(i, 1)
      state.errorsAttachFiles.splice(i, 1)
      state.form.fileThamKhao.splice(i, 1)
      state.attachFileNames.splice(i, 1)
      state.sizeAttachedFiles.splice(i, 1)
      state.fileThamKhaoTuCongViecPb.splice(i, 1)
      this.setState(state)
    }
  }

  handleOpenPopup = e => {
    e.preventDefault()
    if (
      this.props.isUpdate &&
      this.props.requestComments &&
      (this.props.requestComments.tinhTrang === TRANGTHAI.LDDV_CHUA_DUYET ||
        this.props.requestComments.tinhTrang === TRANGTHAI.THU_KY_CHUA_DUYET)
    ) {
      this.setState({
        isOpenPopup: !this.state.isOpenPopup,
        error: '',
      })
    } else {
      this.handleSubmit()
    }
  }

  handleClosePopup() {
    this.setState({
      isOpenPopup: false,
    })
  }

  handleSubmit = async () => {
    if (this.state.isLoading === true) {
      return
    }
    await this.setState({ isLoading: true })
    const error = {}
    let sizeContentFile = this.state.sizeContentFile
    let sizeAttachedFiles = this.state.sizeAttachedFiles

    if (!this.state.form.trichYeu || this.state.form.trichYeu.length === 0) {
      error.trichYeu = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    if (
      !this.state.form.noiDungYeuCau ||
      this.state.form.noiDungYeuCau.length === 0
    ) {
      error.noiDungYeuCau = MESSAGE.TEXT_MESSAGE_DEFAULT
    }
    if (
      !this.state.form.lanhDaoPhongBanChuTriId ||
      this.state.form.lanhDaoPhongBanChuTriId.length === 0 ||
      _.isEmpty(this.state.form.lanhDaoPhongBanLap)
    ) {
      error.lanhDaoPhongBanChuTriId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    if (
      (!this.state.phoiHopIds || this.state.phoiHopIds.length === 0) &&
      (!this.state.donviNhanIds || this.state.donviNhanIds.length === 0)
    ) {
      error.donViTrucThuoc = MESSAGE.TEXT_MESSAGE_REQUIRE_CHON_DON_VI_GOP_Y
    }
    //neu chon don vi nhan thi phai co lanh dao don vi xac nhan
    if (
      (!this.state.form.lanhDaoDonViId ||
        this.state.form.lanhDaoDonViId.length === 0 ||
        _.isEmpty(this.state.form.lanhDaoDonVi)) &&
      (!this.state.donviNhanIds || this.state.donviNhanIds.length !== 0)
    ) {
      error.lanhDaoDonViId = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({
      error,
      sizeContentFile,
      sizeAttachedFiles,
      isLoading: true,
    })

    if (Object.keys(error).length !== 0) {
      this.setState({ isLoading: false, error })
      return this.props.onSubmit && this.props.onSubmit({}, false)
    }

    this.setState({ isLoading: true, errorsAttachFiles: [] })
    const files = [...this.state.attachFiles]
    let dinhKemFiles = [...this.state.dinhKemFiles]
    this.props.onSubmit &&
      this.props.onSubmit(
        {
          ...this.state.form,
          phongBanNhanIds: this.state.phoiHopIds,
          donviNhanIds: this.state.donviNhanIds,
          files: {
            attachFiles: files,
            dinhKemFiles: dinhKemFiles,
            contentFiles: this.state.contentFiles,
          },
        },
        true,
        null,
        this.props.isUpdate
      )
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isUpdate &&
      this.props.requestComments &&
      this.props.requestComments !== prevProps.requestComments
    ) {
      let state = this.state

      state.form.soKyHieu = this.props.requestComments.soKyHieu
      state.form.trichYeu = this.props.requestComments.trichYeu
      state.form.noiDungYeuCau = this.props.requestComments.noiDungYeuCau
      state.form.phieuGuiYeuCauGocId =
        this.props.requestComments.phieuGuiYeuCauGocId
      state.form.thoiHan = this.props.requestComments.thoiHan
        ? moment(this.props.requestComments.thoiHan)
        : ''
      state.dateToToString = this.props.requestComments.thoiHan
        ? moment(this.props.requestComments.thoiHan).format('DD/MM/YYYY')
        : ''

      if (this.props.requestComments.lanhDaoDonVi) {
        let lanhDaoDonVi = this.props.requestComments.lanhDaoDonVi
        state.form.lanhDaoDonViId = lanhDaoDonVi.chucDanhId
        if (lanhDaoDonVi) {
          state.form.lanhDaoDonVi['chuc_danh_id'] = lanhDaoDonVi.chucDanhId
          state.form.lanhDaoDonVi['nhan_vien_id'] = lanhDaoDonVi.nhanVienId
        }
      }

      if (this.props.requestComments.lanhDaoPhongBanLap) {
        let lanhDaoPhongBanLap = this.props.requestComments.lanhDaoPhongBanLap
        state.form.lanhDaoPhongBanChuTriId = lanhDaoPhongBanLap.chucDanhId
        if (lanhDaoPhongBanLap) {
          state.form.lanhDaoPhongBanLap['chuc_danh_id'] =
            lanhDaoPhongBanLap.chucDanhId
          state.form.lanhDaoPhongBanLap['nhan_vien_id'] =
            lanhDaoPhongBanLap.nhanVienId
        }
      }

      if (this.props.requestComments.phongBanNhanIds) {
        let phoiHopIds = []
        this.props.requestComments.phongBanNhanIds.forEach(item => {
          phoiHopIds.push(item)
        })
        state.phoiHopIds = phoiHopIds
        if (phoiHopIds.length === this.state.departmentSelections.length) {
          state.isCheckedAllCompositive = true
        }
        state.form.phongBanNhanIds = phoiHopIds
      }

      if (this.props.requestComments.donViNhanIds) {
        let donviNhanIds = []
        this.props.requestComments.donViNhanIds.forEach(item => {
          donviNhanIds.push(item)
        })
        state.donviNhanIds = donviNhanIds
        if (donviNhanIds.length === this.props.attachedUnits.length) {
          state.isCheckedAllAttachedUnits = true
        }
        state.form.donviNhanIds = donviNhanIds
      }

      if (this.props.requestComments.fileNoiDung) {
        const fileNoiDung = {}
        fileNoiDung.file_id = this.props.requestComments.fileNoiDung.fileId
        fileNoiDung.ten_file = this.props.requestComments.fileNoiDung.tenFile
        fileNoiDung.url = this.props.requestComments.fileNoiDung.url
        fileNoiDung.kieu_file = this.props.requestComments.fileNoiDung.kieuFile
        fileNoiDung.kich_thuoc =
          this.props.requestComments.fileNoiDung.kichThuoc
        state.form.fileNoiDung = fileNoiDung
        state.contentFileName = this.props.requestComments.fileNoiDung.tenFile
      }

      if (
        this.props.requestComments.fileThamKhao &&
        this.props.requestComments.fileThamKhao.length
      ) {
        const fileThamKhao = []
        const attachQty = []
        const attachFileNames = []
        this.props.requestComments.fileThamKhao.forEach((item, i) => {
          fileThamKhao.push({
            file_id: item.fileId,
            ten_file: item.tenFile,
            url: item.url,
            kieu_file: item.kieuFile,
            kich_thuoc: item.kichThuoc,
          })
          attachFileNames.push(item.tenFile)
          attachQty.push(i + 1)
        })

        state.form.fileThamKhao = fileThamKhao
        state.attachFileNames = attachFileNames
        state.attachQty = attachQty
      }
      if (
        this.props.requestComments.fileDinhKem &&
        this.props.requestComments.fileDinhKem.length
      ) {
        const dsFileDinhKem = []
        const fileDinhKemQty = []
        const dinhKemFileNames = []
        this.props.requestComments.fileDinhKem.forEach((item, i) => {
          dsFileDinhKem.push({
            file_id: item.fileId,
            ten_file: item.tenFile,
            url: item.url,
            kieu_file: item.kieuFile,
            kich_thuoc: item.kichThuoc,
          })
          dinhKemFileNames.push(item.tenFile)
          fileDinhKemQty.push(i + 1)
        })

        state.form.dsFileDinhKem = dsFileDinhKem
        state.dinhKemFileNames = dinhKemFileNames
        state.fileDinhKemQty = fileDinhKemQty
      }

      this.setState(state)
    }
  }

  handleSelect = (val, index, isFileThamKhao) => {
    if (isFileThamKhao) {
      let fileThamKhaoTuCongViecPb = [...this.state.fileThamKhaoTuCongViecPb]
      fileThamKhaoTuCongViecPb[index] = { ...val, label: val.value.tenFile }
      this.setState({ fileThamKhaoTuCongViecPb })
    } else {
      let fileDinhKemTuCongViecPb = [...this.state.fileDinhKemTuCongViecPb]
      fileDinhKemTuCongViecPb[index] = { ...val, label: val.value.tenFile }
      this.setState({ fileDinhKemTuCongViecPb })
    }
  }

  render() {
    if (this.state.isCanUpdate === false) {
      return null
    }
    const leaderUnits = this.props.unitsLeader
    return (
      <div className="addSignNumber">
        <div className="form-container">
          {/* Thời hạn góp ý, số ký hiệu */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              {/* So ky hieu */}
              <div className="col-md-6 col-xs-6 col-sm-6 colcus-mb-50">
                <label className="pt-label">Số ký hiệu</label>
                <div className="pt-form-content">
                  <input
                    name="soKyHieu"
                    className={classnames('pt-input', {
                      'loading-input':
                        this.props.isLoadingData &&
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined,
                    })}
                    placeholder="Nhập số ký hiệu"
                    type="text"
                    dir="auto"
                    value={this.state.form.soKyHieu}
                    onChange={this.handleInputChange}
                    autoFocus={true}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  />
                </div>
              </div>
              {/* Thoi han gop y*/}
              <div className="col-xs-6 col-sm-6 col-md-6 full-input">
                <label className="pt-label">
                  Thời hạn góp ý(tùy chọn)
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <div className="pt-input-group">
                    <DatePicker
                      isClearable={false}
                      readOnly={this.props.readOnly}
                      selected={this.state.form.thoiHan}
                      onChange={this.handleDateChange.bind(
                        this,
                        'dateToToString',
                        'dateToToString'
                      )}
                      value={this.state.form.thoiHan}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateToToString', e.target.value)
                      }
                      format="DD/MM/YYYY"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Chọn ngày..."
                      popperPlacement="auto"
                      minDate={moment()}
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                      disabled={
                        this.props.isLoadingData &&
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined
                      }
                    />
                  </div>
                </div>
                {this.state.error.dateToToString !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.dateToToString}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Trich yeu, tiêu đề */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label">
                  Tiêu đề
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <input
                    name="trichYeu"
                    className={classnames('pt-input pt-fill', {
                      'loading-input':
                        this.props.isLoadingData &&
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined,
                    })}
                    rows="3"
                    placeholder="Góp ý về…"
                    dir="auto"
                    value={this.state.form.trichYeu}
                    onChange={this.handleInputChange}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  ></input>
                  {this.state.error.trichYeu !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.trichYeu}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Noi dung trinh */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label">
                  Nội dung yêu cầu
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-form-content">
                  <textarea
                    name="noiDungYeuCau"
                    className={classnames('pt-input pt-fill', {
                      'loading-input':
                        this.props.isLoadingData &&
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined,
                    })}
                    rows="10"
                    dir="auto"
                    placeholder="Nhập nội dung yêu cầu..."
                    value={this.state.form.noiDungYeuCau}
                    onChange={this.handleInputChange}
                    disabled={
                      this.props.isLoadingData &&
                      Object.values(this.props.isLoadingData).find(
                        item => item === true
                      ) !== undefined
                    }
                  ></textarea>
                  {this.state.error.noiDungYeuCau !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.noiDungYeuCau}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* File noi dung */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label pt20 mt10 border-top-secondary-2px">
                  File nội dung
                </label>
                <div className="pt-form-content">
                  <label className="pt-file-upload">
                    <input
                      type="file"
                      name="contentFiles-0"
                      onChange={this.handleFileInputContentFiles.bind(this)}
                      disabled={
                        !(
                          this.state.isCanUpdate && this.state.isCanUpdateFile
                        ) ||
                        this.state.isLoading ||
                        Object.values(this.props.isLoadingData).find(
                          item => item === true
                        ) !== undefined
                      }
                    />
                    <span className="pt-file-upload-input">
                      {this.state.contentFileName &&
                      this.state.contentFileName.length
                        ? this.state.contentFileName
                        : 'Chọn file pdf'}
                    </span>
                  </label>
                  {this.state.contentFileName &&
                    this.state.contentFileName.length > 0 &&
                    !this.state.isLoading && (
                      <span
                        onClick={() =>
                          this.handleEventRemoveFile(0, false, true)
                        }
                        className="icon-bc icon-close icon-remove-file z-index-1"
                      ></span>
                    )}
                  {this.state.sizeContentFile && (
                    <p className="pt-form-helper-text pt-form-helper-infomation">
                      Kích thước file đã upload:
                      <span className="size-file">
                        {' '}
                        {Tool.Utils.getFileSize(this.state.sizeContentFile)}
                      </span>
                    </p>
                  )}
                  {this.state.error.contentFile !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.contentFile}
                    </div>
                  )}
                  {this.state.isLoading &&
                    this.props.contentFileProgress &&
                    this.props.contentFileProgress[0] > 0 && (
                      <div className="progress-upload-content">
                        <span className="percent-content">
                          {this.props.contentFileProgress[0]}%
                        </span>
                        <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                          <div
                            className="pt-progress-meter"
                            style={{
                              width: this.props.contentFileProgress[0] + '%',
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          {/* File dinh kem */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label">Các file đính kèm</label>
                <div className="pt-form-content pb20 mb10 border-bottom-secondary-2px">
                  {this.state.fileDinhKemQty.map((item, i) => (
                    <div
                      key={i}
                      className={classnames('pt-form-group pt-intent-danger', {
                        'upload-have-close':
                          i > 0 ||
                          this.state.dinhKemFiles[0] ||
                          (this.state.form.dsFileDinhKem &&
                            this.state.form.dsFileDinhKem[0]),
                      })}
                    >
                      <label className="pt-file-upload">
                        <input
                          type="file"
                          name={`dinhKemFiles-${i}`}
                          onChange={this.handleFileInput.bind(this, i)}
                          onClick={event => {
                            event.target.value = null
                          }}
                          disabled={
                            !(
                              this.state.isCanUpdate &&
                              this.state.isCanUpdateFile
                            ) ||
                            this.state.isLoading ||
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                        />
                        <span className="pt-file-upload-input">
                          {this.state.dinhKemFileNames &&
                          this.state.dinhKemFileNames[i]
                            ? this.state.dinhKemFileNames[i]
                            : 'Chọn file đính kèm'}
                        </span>
                      </label>
                      {(i > 0 ||
                        (i === 0 &&
                          (this.state.fileDinhKemTuCongViecPb[i] ||
                            this.state.dinhKemFiles[0] ||
                            (this.state.form.dsFileDinhKem &&
                              this.state.form.dsFileDinhKem[0])))) &&
                        !this.state.isLoading && (
                          <span
                            onClick={() => this.handleEventRemoveFile(i, true)}
                            className="icon-bc icon-close icon-remove-file z-index-1"
                          ></span>
                        )}
                      {this.state.sizeDinhKemFiles[i] !== undefined && (
                        <p className="pt-form-helper-text pt-form-helper-infomation">
                          Kích thước file đã upload:
                          <span className="size-file">
                            {' '}
                            {Tool.Utils.getFileSize(
                              this.state.sizeDinhKemFiles[i]
                            )}
                          </span>
                        </p>
                      )}
                      {this.state.errorsDinhKemFiles[i] !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.errorsDinhKemFiles[i]}
                        </div>
                      )}
                      {this.state.isLoading &&
                        this.props.dinhKemFilesProgress &&
                        this.props.dinhKemFilesProgress[i] > 0 && (
                          <div className="progress-upload-content">
                            <span className="percent-content">
                              {this.props.dinhKemFilesProgress[i]}%
                            </span>
                            <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                              <div
                                className="pt-progress-meter"
                                style={{
                                  width:
                                    this.props.dinhKemFilesProgress[i] + '%',
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
                  <Button
                    className="btn-add-file mt0"
                    onClick={() => this.handleEventAddFile(true)}
                    disabled={
                      !(this.state.isCanUpdate && this.state.isCanUpdateFile) ||
                      this.state.isLoading
                    }
                  >
                    <span>Thêm file</span>
                    <span className="icon-bc icon-Plus"></span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* File tham khao */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-12">
                <label className="pt-label">Các file tham khảo</label>
                <div className="pt-form-content">
                  {this.state.attachQty.map((item, i) => (
                    <div
                      key={i}
                      className={classnames('pt-form-group pt-intent-danger', {
                        'upload-have-close':
                          i > 0 ||
                          this.state.attachFiles[0] ||
                          (this.state.form.fileThamKhao &&
                            this.state.form.fileThamKhao[0]),
                      })}
                    >
                      <label className="pt-file-upload">
                        <input
                          type="file"
                          name={`attachFiles-${i}`}
                          onChange={this.handleFileInput.bind(this, i)}
                          onClick={event => {
                            event.target.value = null
                          }}
                          disabled={
                            !(
                              this.state.isCanUpdate &&
                              this.state.isCanUpdateFile
                            ) ||
                            this.state.isLoading ||
                            Object.values(this.props.isLoadingData).find(
                              item => item === true
                            ) !== undefined
                          }
                        />
                        <span className="pt-file-upload-input">
                          {this.state.attachFileNames &&
                          this.state.attachFileNames[i]
                            ? this.state.attachFileNames[i]
                            : 'Chọn file tham khảo'}
                        </span>
                      </label>
                      {(i > 0 ||
                        (i === 0 &&
                          (this.state.fileThamKhaoTuCongViecPb[i] ||
                            this.state.attachFiles[0] ||
                            (this.state.form.fileThamKhao &&
                              this.state.form.fileThamKhao[0])))) &&
                        !this.state.isLoading && (
                          <span
                            onClick={() => this.handleEventRemoveFile(i, false)}
                            className="icon-bc icon-close icon-remove-file z-index-1"
                          ></span>
                        )}
                      {this.state.sizeAttachedFiles[i] !== undefined && (
                        <p className="pt-form-helper-text pt-form-helper-infomation">
                          Kích thước file đã upload:
                          <span className="size-file">
                            {' '}
                            {Tool.Utils.getFileSize(
                              this.state.sizeAttachedFiles[i]
                            )}
                          </span>
                        </p>
                      )}
                      {this.state.errorsAttachFiles[i] !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.errorsAttachFiles[i]}
                        </div>
                      )}
                      {this.state.isLoading &&
                        this.props.attachFilesProgress &&
                        this.props.attachFilesProgress[i] > 0 && (
                          <div className="progress-upload-content">
                            <span className="percent-content">
                              {this.props.attachFilesProgress[i]}%
                            </span>
                            <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                              <div
                                className="pt-progress-meter"
                                style={{
                                  width:
                                    this.props.attachFilesProgress[i] + '%',
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
                  <Button
                    className="btn-add-file mt0"
                    onClick={() => this.handleEventAddFile(false)}
                    disabled={
                      !(this.state.isCanUpdate && this.state.isCanUpdateFile) ||
                      this.state.isLoading
                    }
                  >
                    <span>Thêm file</span>
                    <span className="icon-bc icon-Plus"></span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* Trinh lanh dao phong ban */}
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              {/* Trinh lanh dao phong ban */}
              <div className="col-md-6 col-xs-6 col-sm-6 colcus-mb-50">
                <label className="pt-label">
                  Trình LĐ phòng ban
                  <span className="pt-text-muted required-input">*</span>
                </label>

                <div className="pt-form-content">
                  <div className="pt-select">
                    <select
                      name="lanhDaoPhongBanChuTriId"
                      onChange={this.handleInputChange}
                      value={this.state.form.lanhDaoPhongBanChuTriId}
                      className={classnames({
                        'loading-input':
                          this.props.isLoadingData &&
                          this.props.isLoadingData.lanhDaoPhongBanChuTriId ===
                            true,
                      })}
                    >
                      <option>--Chọn lãnh đạo phòng ban--</option>
                      {this.props.departmentSigned.map((item, i) => (
                        <option key={i} value={item.chucDanhId}>
                          {item.tenNhanVien}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {this.state.error.lanhDaoPhongBanChuTriId !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.lanhDaoPhongBanChuTriId}
                  </div>
                )}
              </div>
              {/* Trinh lanh dao don vi */}
              <div className="col-md-6 col-xs-6 col-sm-6 colcus-mb-50">
                <label className="pt-label">Trình LĐ đơn vị</label>
                <div className="pt-form-content">
                  <div className="pt-select">
                    <select
                      name="lanhDaoDonViId"
                      onChange={this.handleInputChange}
                      value={this.state.form.lanhDaoDonViId}
                      className={classnames({
                        'loading-input':
                          this.props.isLoadingData &&
                          this.props.isLoadingData.lanhDaoDonViId === true,
                      })}
                    >
                      <option>--Chọn lãnh đạo đơn vị--</option>
                      {leaderUnits.map((item, i) => (
                        <option key={i} value={item.chucDanhId}>
                          {item.tenNhanVien}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {this.state.error.lanhDaoDonViId !== undefined && (
                  <div className="pt-form-helper-text">
                    {this.state.error.lanhDaoDonViId}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Phong ban gop y */}
          <div className="pt-form-group pt-intent-danger">
            <div className="selectbox-group">
              <div className="heading-group-select disabled-checkbox">
                <span className="pull-left name_group">Phòng ban góp ý</span>
                <Checkbox
                  className="pull-right"
                  name="allCompositive"
                  checked={this.state.isCheckedAllCompositive}
                  onChange={this.handleCheckAllCompositiveDepartments}
                >
                  <span className="name_checkbox">Chọn tất cả</span>
                </Checkbox>
              </div>
              <div className="container-checkbox">
                <CheckboxGroup
                  name="phongBanGopY"
                  options={this.state.departmentSelections}
                  selectedOptions={this.state.phoiHopIds}
                  onChange={this.handleCheckCompositiveDepartments}
                  isNotCheckDisabledItem
                  // isDisabledAll={!this.state.isCanUpdateCompositive}
                  isLoadingData={
                    this.props.isLoadingData &&
                    this.props.isLoadingData.departments
                  }
                  colum="col-xs-6 col-sm-4 col-md-4"
                />
              </div>
            </div>
          </div>
          {/* don vi gop y*/}
          <div className="pt-form-group pt-intent-danger">
            <div className="selectbox-group">
              <div className="heading-group-select disabled-checkbox">
                <span className="pull-left name_group">Đơn vị góp ý</span>
                <Checkbox
                  className="pull-right"
                  name="allCompositive"
                  checked={this.state.isCheckedAllAttachedUnits}
                  onChange={this.handleCheckAllAttachedUnits}
                >
                  <span className="name_checkbox">Chọn tất cả</span>
                </Checkbox>
              </div>
              <div className="container-checkbox">
                <CheckboxGroup
                  name="donViTrucThuoc"
                  options={this.state.attachedUnitSelection}
                  selectedOptions={this.state.donviNhanIds}
                  onChange={this.handleCheckAttachedUnits}
                  isNotCheckDisabledItem
                  isLoadingData={
                    this.props.isLoadingData &&
                    this.props.isLoadingData.departments
                  }
                  colum="col-xs-6 col-sm-4 col-md-4"
                />
              </div>
              {this.state.error.donViTrucThuoc !== undefined && (
                <div className="pt-form-helper-text">
                  {this.state.error.donViTrucThuoc}
                </div>
              )}
            </div>
          </div>
        </div>
        <div id="groupButton" className="buttton-action-footer">
          <div className="row">
            <div className="col-xs-12">
              <div className="full-mobile">
                <Button
                  onClick={this.handleClickCancel}
                  className="pt-button full-btn-left btn-cancel"
                  disabled={this.state.isLoading}
                >
                  <span className=" pt-icon icon-back"></span>
                  <span className="text-content">Quay lại</span>
                </Button>
                <Button
                  onClick={this.handleOpenPopup}
                  className="pt-button btn-save full-btn-right"
                  disabled={
                    Object.values(this.props.isLoadingData).find(
                      item => item === true
                    ) !== undefined || this.state.isLoading === true
                  }
                  loading={this.state.isLoading}
                >
                  <span className="text-content">Lưu phiếu</span>
                  <span className="pt-icon icon-save"></span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-actionfooter-fixtop buttton-action-footer">
          <Button
            className="pt-button btn-blue-color"
            onClick={this.handleOpenPopup}
            disabled={
              Object.values(this.props.isLoadingData).find(
                item => item === true
              ) !== undefined || this.state.isLoading === true
            }
            loading={this.state.isLoading}
          >
            <span className="pt-icon icon-save"></span>
            <span className="text-content">Lưu phiếu</span>
          </Button>
          <Button
            className="pt-button btn-cancel"
            onClick={this.handleClickCancel}
            disabled={this.state.isLoading}
          >
            <span className="text-content">Quay lại</span>
            <span className="pt-icon icon-back"></span>
          </Button>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.handleClosePopup}
            title={POPUP.SUA_GOP_Y}
            text={POPUP.TEXT_BUTTON_SUBMIT}
            onSubmit={this.handleSubmit}
            isLoading={this.state.isLoading}
          >
            {POPUP.COMFIRM_SUA_GOP_Y}
          </PopupConfirm>
        </div>
        {this.state.isRedirect && (
          <Redirect
            push
            to={{
              pathname: this.state.redirectTo,
            }}
          />
        )}
      </div>
    )
  }
}

export default withRouter(InputForm)
