import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import Permission from './Permission'
import { checkPermissionMenu } from 'helpers/Helper'
import Utils from 'helpers/utils'

const vaild = (permission, allowedPermission, or = false) => {
  if (
    (!or &&
      _.intersection(permission, allowedPermission).length ===
        allowedPermission.length) ||
    (or && _.intersection(permission, allowedPermission).length > 0)
  ) {
    return true
  }

  return false
}

const checkParent = permission => {
  for (var key in Permission.permissionTree) {
    if (key === permission) {
      return []
    }

    if (_.isString(Permission.permissionTree[key])) {
      continue
    }

    for (var key2 in Permission.permissionTree[key]) {
      if (key2 === permission) {
        return [key]
      }

      if (_.isString(Permission.permissionTree[key][key2])) {
        continue
      }

      for (var key3 in Permission.permissionTree[key][key2]) {
        if (key3 === permission) {
          return [key, key2]
        }
      }
    }
  }

  return []
}

const checkChildren = permission => {
  let children = []

  for (let key in Permission.permissionTree) {
    for (let key2 in Permission.permissionTree[key]) {
      if (key2 !== permission) {
        continue
      }

      if (_.isString(Permission.permissionTree[key][key2])) {
        children.push(key2)
      } else {
        for (let key3 in Permission.permissionTree[key][key2]) {
          children.push(key3)
        }
      }
    }

    if (key !== permission) {
      continue
    }

    if (_.isString(Permission.permissionTree[key])) {
      continue
    }

    for (let key22 in Permission.permissionTree[key]) {
      children.push(key22)
      if (!_.isString(Permission.permissionTree[key][key22])) {
        for (let key33 in Permission.permissionTree[key][key22]) {
          children.push(key33)
        }
      }
    }
  }

  return children
}

const Authorization =
  (
    allowedPermission = [],
    permissionMenu = [],
    isPage = false,
    isTabCongViec = false,
    isOrPermission = false,
    operatorPermissionMenu = 'OR'
  ) =>
  WrappedComponent => {
    class WithAuthorization extends Component {
      constructor(props) {
        super(props)
        this.state = {
          user: {
            permission: props.auth.permission || [],
          },
        }
      }

      render() {
        const { permission } = this.state.user
        const { orPermission, quyensMenu } = this.props
        const parentProps = Object.assign({}, this.props)
        const codeCheckCongViecTongCongTy = permissionMenu?.find(
          p => p === Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC_QCTCT
        )
        let permissionMenuNotCongViecTongCongTy = permissionMenu
        if (codeCheckCongViecTongCongTy) {
          permissionMenuNotCongViecTongCongTy = permissionMenu?.filter(
            m => m !== Permission.MENU_SIDEBAR.BMENU_CVAN_CVIEC_QCTCT
          )
        }
        if (
          _.size(quyensMenu) !== 0 &&
          _.size(permissionMenu) !== 0 &&
          ((codeCheckCongViecTongCongTy &&
            Utils.checkTongCongTy(this.props.auth?.mainUnitId) &&
            (!checkPermissionMenu(
              quyensMenu,
              permissionMenuNotCongViecTongCongTy
            ) ||
              (checkPermissionMenu(
                quyensMenu,
                permissionMenuNotCongViecTongCongTy
              ) &&
                !checkPermissionMenu(quyensMenu, [
                  codeCheckCongViecTongCongTy,
                ])))) ||
            (!codeCheckCongViecTongCongTy &&
              !checkPermissionMenu(
                quyensMenu,
                permissionMenu,
                operatorPermissionMenu
              )))
        ) {
          return <Redirect push to="/" />
        }

        if (this.props.permission) {
          allowedPermission = this.props.permission
        }

        if (
          (!orPermission &&
            _.intersection(permission, allowedPermission).length ===
              allowedPermission.length) ||
          (orPermission &&
            _.intersection(permission, orPermission).length > 0) ||
          (isOrPermission &&
            _.intersection(permission, allowedPermission).length > 0)
        ) {
          if (this.props.children) {
            return this.props.children
          }

          return <WrappedComponent {...parentProps} />
        }

        if (isPage) {
          if (isTabCongViec) {
            // Nếu muốn chuyển hướng đến tab công việc
            let redirectTo = '/'
            if (permission.indexOf(Permission.CONGVIEC_PHONGBAN) !== -1) {
              redirectTo = '/cong-viec/phong-ban/danh-sach/chua-giao'
            } else if (permission.indexOf(Permission.CONGVIEC_CANHAN) !== -1) {
              redirectTo = '/cong-viec/ca-nhan/danh-sach/chua-thuc-hien'
            }

            return <Redirect push to={redirectTo} />
          }

          return <Redirect push to="/" />
        }

        return false
      }
    }

    const mapStateToProps = state => ({
      auth: state.auth,
      quyensMenu: state?.common?.quyensMenu ?? [],
    })

    return connect(mapStateToProps, null)(WithAuthorization)
  }

// export default Authorization;
export default {
  Component: Authorization,
  Element: Authorization()(),
  vaild: vaild,
  checkParent,
  checkChildren,
}
