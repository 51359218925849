import React from 'react'
import { Button } from '@blueprintjs/core'
import classnames from 'classnames'

import Icon from 'components/newCommon/Icon/Icon'

const HeadingPanel = ({
  className = '',
  goBack = null,
  leftTitle = '',
  leftIcon = '',
  leftElement = null,
  rightElement = null,
}) => {
  return (
    <div className={classnames('heading-panel', className)}>
      <div>
        <div className="heading-panel__left">
          {goBack && (
            <Button
              className="cpc-button pointer font-size-12 uppercase go-back mr15"
              onClick={goBack}
            >
              <Icon className="mr5" classIcon="icon-back icon" />
              <span>Quay lại</span>
            </Button>
          )}
          <div className="heading-panel__left__title">
            {leftIcon && (
              <Icon className="title-icon mr10" classIcon={leftIcon} />
            )}
            {leftTitle && <span className="title-name">{leftTitle}</span>}
            {leftElement && leftElement}
          </div>
        </div>
      </div>
      {rightElement && <div>{rightElement}</div>}
    </div>
  )
}

export default HeadingPanel
