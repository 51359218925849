import React, { memo } from 'react'
import classNames from 'classnames'

const Icon = ({
  classIcon,
  className,
  style,
  disabled = false,
  onClick = () => {},
}) => {
  return (
    <span
      className={classNames(classIcon, className, {
        disabled: disabled,
      })}
      style={style}
      onClick={onClick}
    />
  )
}

export default memo(Icon)
