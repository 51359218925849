import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Radio } from '@blueprintjs/core'
import * as TRANGTHAI from '../../constants/RequestCommentStatus'

class ChiDaoGopY extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    departments: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    error: {},
    typeSubmit: 'ChiDaoGopY',
    form: {
      yKien: '',
      phongBanId: '',
      isSuaChiDao: false,
    },
  }
  componentDidMount() {
    let state = this.state
    const ỵKienLanhDao =
      this.props.requestComments &&
      this.props.requestComments.lanhDaoDonViChiDao
        ? this.props.requestComments.lanhDaoDonViChiDao.yKien
        : ''
    if (
      this.props.requestComments &&
      this.props.requestComments.phongBanId &&
      this.props.requestComments.tinhTrang === 1
    ) {
      state.form['yKien'] = ỵKienLanhDao
      state.form['phongBanId'] = this.props.requestComments.phongBanId
      state.form['isSuaChiDao'] = true
    }
    this.setState(state)
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name
    let state = this.state
    state.form[name] = value
    delete state.error[name]

    this.setState(state)
  }

  handleSubmit() {
    this.props.onSubmit &&
      this.props.onSubmit(this.state.typeSubmit, this.state.form)
  }
  render() {
    const dsPhongBanGopY = this.props.departments
      ? this.props.departments.items
      : []
    return (
      <div className="container-form-action">
        <div className="heading">
          <span onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>
            {this.props.requestComments.tinhTrang === TRANGTHAI.LDDV_CHUA_GIAO
              ? 'Chỉ đạo góp ý'
              : 'Sửa chỉ đạo góp ý'}
          </h4>
        </div>
        <div className="form-container form-send-mail">
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-5 col-xs-12">
                Nội dung chỉ đạo
              </label>
            </div>
            <div className="row">
              <div className="pt-form-content col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="4"
                    className="pt-input pt-fill"
                    placeholder="Nhập nội dung…"
                    dir="auto"
                    name="yKien"
                    onChange={this.handleInputChange}
                    value={this.state.form.yKien}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <label className="pt-label col-md-5 col-xs-12">
                Phòng ban thực hiện
              </label>
            </div>
            <div className="row">
              <div className="pt-form-content department-perform-table col-xs-12">
                <table className="pt-table table-container w-100">
                  <tbody>
                    {dsPhongBanGopY.map((item, i) => (
                      <tr key={i}>
                        <td className="cell-content">{item.tenPhongBan}</td>
                        <td className="cell-content"></td>
                        <td className="cell-content">
                          <Radio
                            className="item-radio"
                            name="phongBanId"
                            checked={
                              this.state.form.phongBanId === item.phongBanId
                            }
                            onChange={this.handleInputChange}
                            value={item.phongBanId}
                            id={item.phongBanId}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/*<div className="pt-select">*/}
                {/*  <select*/}
                {/*    name="phongBanId"*/}
                {/*    onChange={this.handleInputChange}*/}
                {/*    value={this.state.form.phongBanId}*/}
                {/*    className={*/}
                {/*      classnames({*/}
                {/*        'loading-input': this.props.isLoadingData && this.props.isLoadingData.phongBanId === true*/}
                {/*      })*/}
                {/*    }*/}
                {/*  >*/}
                {/*    <option>--Chọn phòng ban--</option>*/}
                {/*    {dsPhongBanGopY.map((item, i) => (*/}
                {/*      <option key={i} value={item.phongBanId}>*/}
                {/*        {item.tenPhongBan}*/}
                {/*      </option>*/}
                {/*    ))}*/}
                {/*  </select>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>

        <div id="groupButton" className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-save"
            onClick={this.handleSubmit}
            loading={this.props.isLoading}
            disabled={false}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
      </div>
    )
  }
}

export default ChiDaoGopY
