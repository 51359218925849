import csx from 'classnames'
import { get } from 'lodash'
import 'moment/locale/vi'

export const datePickerConfig = {
  locale: 'vi',
  readOnly: true,
  isClearable: true,
  showMonthDropdown: true,
  showYearDropdown: true,
  dateFormat: 'DD/MM/YYYY',
  // dropdownMode: 'select',
  className: csx('cpc-input', 'input-datepicker'),
  placeholderText: 'Chọn ngày...',
  popperPlacement: 'bottom',
  popperModifiers: {
    offset: {
      enabled: true,
      offset: '5px, 10px',
    },
    flip: {
      enabled: false,
    },
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  },
}

export const calendarConfig = {
  // Set khu vực là 'việt nam' (không hữu dụng cho lắm)
  locale: 'vi',
  // Format ngày tháng trong month view
  titleFormat: dateObj => `${get(dateObj, 'start.day')} tháng ${
    get(dateObj, 'start.month') + 1
  } -
    ${get(dateObj, 'end.day')} tháng ${get(dateObj, 'end.month') + 1}, ${get(
    dateObj,
    'date.year'
  )}`,
  // Ngày đầu trong tuần là thứ 2
  firstDay: 1,
  // Sắp xếp các thành phần header
  headerToolbar: {
    left: 'title',
    right: 'prev,next today dayGridWeek,dayGridMonth',
  },
  // Text hiển thị của button
  buttonText: {
    today: 'Hôm nay',
    week: 'Tuần',
    month: 'Tháng',
  },
  // height: 'auto',
  // Kích thước lịch
  contentHeight: 650,
  // height: 'auto',
  // Số event hiển thị
  dayMaxEvents: 3,
  // Text nút xem thêm công việc
  moreLinkContent: countObj => `+${countObj.num} công việc`,
  slotDuration: '24:00:00',
  // Text title ngày
  dayHeaderFormat: {
    weekday: 'short',
    month: 'numeric',
    day: 'numeric',
    omitCommas: true,
  },
  // Event hiển thị dạng list
  eventDisplay: 'list-item',
  fixedWeekCount: false,
}

export default {
  datePickerConfig,
  calendarConfig,
}
