import React, { memo, useCallback, useEffect, useState } from 'react'
import { Checkbox, Classes, Button, Tooltip } from '@blueprintjs/core'
import { get } from 'lodash'
import csx from 'classnames'
import { withCookies } from 'react-cookie'

import Title from 'components/title'
import { toDecamelizeKeys } from 'helpers/key'
import requestAction from 'helpers/request'
import { MasterLayout } from 'components/layout'
import { Icon } from 'components/newCommon'
import { Table, Pagination } from 'components/newCommon2'
import { Action, Toast } from 'constants/MessageForm'
import { getDsTag, putEditTag } from 'actions/task'
import DeleteTag from './components/confirm/delete-tag'

const TaskTags = ({ history }) => {
  const PAGE_SIZE = 10
  const PAGE_NAME = 'Quản lý loại công việc'
  const [fetching, setFetching] = useState(true)
  const [fetchingFail, setFetchingFail] = useState(false)
  const [tags, setTags] = useState([])
  const [checking, setChecking] = useState(false)
  const [filter, setFilter] = useState()
  const [totalPage, setTotalPage] = useState(1)
  const [deleteData, setDeleteData] = useState(null)

  const changeCheckBox = record => e => {
    const { checked } = e.target
    const newRecord = { ...record, isActive: checked }
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => setChecking(true),
      action: () => putEditTag(toDecamelizeKeys(newRecord)),
      afterResponse: () =>
        setTags((oldTags = []) =>
          oldTags.map(tag => (tag.id === newRecord.id ? newRecord : tag))
        ),
      afterAction: () => setChecking(false),
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }

  const fetchList = useCallback(
    (page = 1, params = null) => {
      requestAction({
        codeCheck: false,
        showToast: false,
        getResult: false,
        beforeAction: () => setFetching(true),
        action: () =>
          getDsTag({
            ...params,
            page: page,
            limit: PAGE_SIZE,
          }),
        afterResponse: ({ items = [], totalCount = 0 }) => {
          setTags(items)
          setTotalPage(totalCount)
        },
        afterAction: () => setFetching(false),
        afterError: () => setFetchingFail(true),
      })
    },
    [PAGE_SIZE]
  )

  const changeFilter =
    (name, isFetch = true) =>
    value => {
      let newFilter = filter
      if (name === 'PAGE') {
        newFilter = { ...newFilter, page: value }
        setFilter(newFilter)
        return fetchList(value, newFilter)
      }

      newFilter = { ...newFilter, [name]: value }
      setFilter(newFilter)
      if (isFetch) {
        fetchList(1, newFilter)
      }
    }

  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Stt',
      render: (v, r, index) => {
        const num = (get(filter, 'page', 1) - 1) * 10 + index + 1
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'name',
      width: 50,
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Loại công việc',
      render: ({ name, backgroundColor = '#f1f1f1' }) => (
        <>
          <span
            style={{
              display: 'inline-block',
              width: 24,
              height: 24,
              background: backgroundColor,
              marginRight: 10,
            }}
          />
          {name}
        </>
      ),
    },
    {
      key: 'suDung',
      width: 10,
      headClass: 'uppercase',
      className: 'element-center align-center',
      title: 'Sử dụng',
      dataIndex: 'isActive',
      render: (value, record) => (
        <Checkbox
          checked={!!value}
          className={csx(Classes.MINIMAL, 'mb0 inline-block')}
          disabled={checking}
          onChange={changeCheckBox(record)}
        />
      ),
    },
    {
      key: 'thaoTac',
      className: 'element-center align-center',
      width: 5,
      dataIndex: 'id',
      render: id => (
        <>
          <Tooltip
            content={
              <span className="font-size-12">Chỉnh sửa loại công việc</span>
            }
          >
            <Icon
              classIcon="icon-but"
              className={csx(
                'size-icon-12',
                'mr10',
                'icon-color-primary',
                'has-event'
              )}
              onClick={() =>
                history.push(`/quan-ly-cong-viec/tags/chinh-sua/${id}`)
              }
            />
          </Tooltip>
          <Tooltip
            content={<span className="font-size-12">Xóa loại công việc</span>}
          >
            <Icon
              classIcon="icon-bin"
              className={csx('size-icon-12', 'icon-color-danger', 'has-event')}
              onClick={() => setDeleteData({ id: id })}
            />
          </Tooltip>
        </>
      ),
    },
  ]

  useEffect(() => {
    fetchList()
  }, [fetchList])

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="d-flex align-items-center justify-content-space-between mt10">
        <Title name={PAGE_NAME} icon="icon icon2-tag" />
        <Tooltip
          content={<span className="font-size-12">Tạo loại công việc</span>}
        >
          <Button
            className={csx('cpc-button blue')}
            onClick={() => history.push('/quan-ly-cong-viec/tags/them-moi')}
          >
            <span className="mr5">Tạo mới</span>
            <Icon classIcon="icon-Plus" />
          </Button>
        </Tooltip>
      </div>
      <div className="task-manager-block">
        <Table
          className="cpc-table-giaodienmoi"
          autoHide={false}
          columns={columns}
          data={tags}
          loading={fetching}
          error={fetchingFail}
          tableMinWidth={400}
        />
        {totalPage > PAGE_SIZE && (
          <div className="text-center bg-white ph15 pv20 border-top">
            <Pagination
              total={totalPage}
              current={get(filter, 'page', 1)}
              pageSize={PAGE_SIZE}
              onChange={changeFilter('PAGE')}
            />
          </div>
        )}
        {deleteData ? (
          <DeleteTag
            dataSource={deleteData}
            onClose={() => setDeleteData(null)}
            onSuccess={() => {
              setDeleteData(null)
              changeFilter('PAGE')(1)
            }}
          />
        ) : (
          ''
        )}
      </div>
    </MasterLayout>
  )
}

export default withCookies(memo(TaskTags))
