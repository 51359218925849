import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classnames from 'classnames'

const DEFAULT_ID = '00000000-0000-0000-0000-000000000000'
const TOTAL_NAME = 'Tổng cộng'

class ListItemUnit extends Component {
  constructor(props) {
    super(props)

    this.convertNumber = this.convertNumber.bind(this)
    this.redirectToStatisticChild = this.redirectToStatisticChild.bind(this)
  }

  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  convertNumber = number => {
    if (_.isNumber(number)) {
      return number
    }
    return 0
  }

  redirectToStatisticChild = item => {
    if (!item || !item.isDonVi || !item.id) {
      return
    }
    this.props.actions.refreshStatistic(false)
    let pathName = `/tien-ich/thong-ke-ky-so/chi-tiet/don-vi/${item.id}`
    return (
      this.props.history &&
      this.props.history.push({
        pathname: pathName,
        state: {
          isCheck: true,
        },
      })
    )
  }

  render() {
    const { item, number } = this.props
    if (!item) {
      return
    }
    const isTotalRaw = item.id === DEFAULT_ID && item.name === TOTAL_NAME
    return (
      <tr
        className={classnames({
          'text-bold': isTotalRaw,
        })}
      >
        <td className="gerenal-list-utilities">
          {isTotalRaw ? '' : number > 9 ? number : '0' + number}
        </td>
        <td
          onClick={this.redirectToStatisticChild.bind(this, item)}
          className={classnames('gerenal-list-utilities', {
            'over-time': item.isDonVi && !isTotalRaw,
          })}
        >
          <span className="hover-over-time">{item.name}</span>
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanKyTayDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanKySoDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tyLeCongVanKySoDi)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanKyTayNoiBo)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanKySoNoiBo)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tongSoCongVanNoiBo)}
        </td>
        <td className="gerenal-list-utilities text-center">
          {this.convertNumber(item.tyLeCongVanKySoNoiBo)}
        </td>
      </tr>
    )
  }
}

export default ListItemUnit
