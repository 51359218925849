import React, { memo, useContext, useState, useEffect, useRef } from 'react'
import {
  Menu,
  MenuDivider,
  Checkbox,
  Button,
  Classes,
  Intent,
} from '@blueprintjs/core'
import { get } from 'lodash'
import csx from 'classnames'

import { TaskContext } from '../AllTask'
import { Tag, Input } from 'components/newCommon'
import { checkSearchNoAccents } from 'helpers/string'
import requestAction from 'helpers/request'
import { Action, Toast } from 'constants/MessageForm'
import { postTagCongViec } from 'actions/task'

const Tags = ({
  goToAction = () => {},
  taskId = '',
  defaultSelected = [],
  hideMenu = () => {},
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const {
    tags = [],
    tagging = false,
    changeTagging = () => {},
    onTagSucess = () => {},
  } = useContext(TaskContext)
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [showList, setShowList] = useState(tags)
  const [selected, setSelected] = useState(defaultSelected)
  const [search, setSearch] = useState('')

  const toggleCheck = value => e => {
    _mountedSet(setIsDisabledButtonDefault, false)
    const { checked } = e.target
    _mountedSet(
      setSelected,
      checked
        ? [...selected, value]
        : selected.filter(select => select !== value)
    )
  }

  const changeSearch = text => {
    _mountedSet(setSearch, text)
    _mountedSet(
      setShowList,
      tags.filter(({ name }) => checkSearchNoAccents(name, text))
    )
  }

  const onSave = () => {
    requestAction({
      codeCheck: false,
      getResult: false,
      beforeAction: () => changeTagging(true),
      action: () =>
        postTagCongViec(
          taskId,
          selected.map(select => ({ work_tag_id: select }))
        ),
      afterResponse: () => {
        onTagSucess({
          id: taskId,
          newTags: tags
            .filter(({ id }) => selected.includes(id))
            .map(tag => ({
              ...tag,
              workTagName: tag.name,
              workTagId: tag.id,
            })),
        })
        hideMenu()
      },
      afterAction: () => {
        changeTagging(false)
        _mountedSet(setIsDisabledButtonDefault, true)
      },
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Menu className="p10">
      <Input
        className="cpc-input-search mb10"
        clearable
        leftIconName="search"
        onChange={changeSearch}
        onClear={changeSearch}
        placeholder="Tìm kiếm"
        value={search}
      />
      {showList.map(({ id, name, backgroundColor }) => (
        <Checkbox
          className={csx('cpc-checkbox', 'pt5 pb5')}
          checked={selected.includes(id)}
          key={id}
          onChange={toggleCheck(id)}
        >
          <Tag name={name} color={backgroundColor} />
        </Checkbox>
      ))}
      <MenuDivider className="mb10" />
      <div className="no-wrap element-space-between">
        <Button
          className={csx(Classes.MINIMAL, 'font-size-13')}
          text="Quản lý"
          onClick={goToAction('tag')}
        />
        <Button
          className={csx('cpc-button', 'uppercase', 'font-size-13', 'ml50')}
          intent={Intent.PRIMARY}
          disabled={isDisabledButtonDefault}
          onClick={onSave}
          loading={tagging}
        >
          Lưu
        </Button>
      </div>
    </Menu>
  )
}

export default memo(Tags)
