import React, {
  memo,
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import isString from 'lodash/isString'
import classNames from 'classnames'
import moment from 'moment'
import { Classes, Button, Intent, Position } from '@blueprintjs/core'

import { searchFileCongVanV2 } from 'actions'
import { Action, Toast } from 'constants/MessageForm'
import {
  documentTypesLimit,
  allowDocumentFileTypesLimit,
} from 'constants/fileTypes'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import {
  Row,
  Col,
  Scrollbar,
  Upload,
  Collapse,
  Icon,
} from 'components/newCommon'
import {
  Input,
  Select,
  FormDatePicker,
  InputSearch,
} from 'components/newCommon2'
import { postUpload, putMeeting } from 'actions/task'
import { MeetingDetailContext } from '../MeetingDetail'

const MeetingFormUpdate = ({
  actions: actionApi,
  history,
  dataLanhDaoDonVi,
  onClose = () => {},
  reloadDetail = () => {},
}) => {
  const { dsLoaiCuocHop, dataThongTinDonVi, dataDetailMetting } =
    useContext(MeetingDetailContext)
  const id = dataDetailMetting?.id
  const dateRequest = 'YYYY-MM-DD'
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const [dataCollapse, setDataCollapse] = useState({
    showMeeting: true,
    showConclusion: true,
  })
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [dataForm, setDataForm] = useState({})
  const [validateErrors, setValidateErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const _upload = async filesData => {
    const responseFilesObject = {}
    await Promise.all(
      Object.entries(filesData).map(async ([key, files]) => {
        if (isEmpty(files)) {
          responseFilesObject[key] = []
          return
        }

        await requestAction({
          action: () => postUpload(files),
          afterResponse: (result = []) => {
            responseFilesObject[key] = result
          },
          showToastSucess: false,
          codeCheck: false,
        })

        return
      })
    )

    return responseFilesObject
  }

  const onChangeFormValidate = ({ name, value }) => {
    setValidateErrors(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const onValidateFormName = name => {
    let success = true
    if (
      isEmpty(dataForm?.[name]) ||
      (isString(dataForm[name]) && !dataForm[name].trim())
    ) {
      success = false
      onChangeFormValidate({ name, value: true })
    }

    return success
  }

  const onValidateFiles = name => {
    let success = true
    if (size(dataForm?.[name]) === 0) {
      success = false
      onChangeFormValidate({ name, value: true })
    }

    return success
  }

  const onValidate = () => {
    const isCheckMeetingTitle = onValidateFormName('title')
    const isCheckMeetingMeetingType = onValidateFormName('meetingType')
    const isCheckMeetingTextNumber = onValidateFormName('textNumber')
    const isCheckMeetingExecutionDate = onValidateFormName('executionDate')
    const isCheckMeetingMainResponsibilityId = onValidateFormName(
      'mainResponsibilityId'
    )
    const isCheckMeetingMeetingFiles = onValidateFiles('meetingFiles')
    const isCheckFullMeeting =
      !isCheckMeetingTitle ||
      !isCheckMeetingMeetingType ||
      !isCheckMeetingTextNumber ||
      !isCheckMeetingExecutionDate ||
      !isCheckMeetingMainResponsibilityId ||
      !isCheckMeetingMeetingFiles
    if (isCheckFullMeeting) {
      throw new Error('warning')
    }
  }

  const handleConvertData = useCallback(values => {
    const data = {
      title: values?.title || null,
      textNumber: values?.textNumber || null,
      meetingType: values?.meetingType || null,
      mainResponsibilityId: values?.mainResponsibilityId,
      organizationId: values?.organizationId,
    }

    const executionDate = get(values, 'executionDate')
    if (executionDate) {
      data.executionDate = moment(executionDate).format(dateRequest)
    }

    if (get(values, 'meetingFiles')) {
      const newFiles = values?.meetingFiles?.map(elm => elm.id) || []
      const oldFiles = values?.files?.map(elm => elm.id) || []
      data.attachmentFileIds = [...oldFiles, ...newFiles]
    }

    return data
  }, [])

  const submitUpdate = async () => {
    await requestAction({
      successCode: 200 || 201,
      beforeAction: () => {
        mountedSet(setIsLoading, true)
        onValidate()
      },
      action: async () => {
        const fileId = dataForm?.meetingFiles?.[0]?.fileId
        let responseFilesObject = {
          meetingFiles: [
            {
              id: fileId,
            },
          ],
        }
        if (!fileId) {
          responseFilesObject = await _upload({
            meetingFiles: dataForm?.meetingFiles,
          })
        }

        const data = await handleConvertData({
          ...dataForm,
          organizationId: dataThongTinDonVi?.donViId,
          ...responseFilesObject,
        })
        const dataConver = toDecamelizeKeys(data)
        const fullData = {
          ...dataConver,
        }
        return putMeeting(id, fullData)
      },
      afterResponse: () => {
        reloadDetail()
        history.replace(`/thong-bao-ket-luan-cuoc-hop/chi-tiet/${id}`)
      },
      afterAction: () => {
        mountedSet(setIsLoading, false)
        mountedSet(setIsDisabledButtonDefault, true)
      },
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const changeField = name => value => {
    onChangeFormValidate({ name, value: false })
    setDataForm(prev => ({
      ...prev,
      [name]: value,
    }))
    setIsDisabledButtonDefault(false)
  }

  const changeFile = name => fileList => {
    mountedSet(setDataForm, prev => ({
      ...prev,
      [name]: fileList,
    }))
    onChangeFormValidate({ name, value: false })
    setIsDisabledButtonDefault(false)
  }

  const toggleCollapse = (name, value) => {
    mountedSet(setDataCollapse, prev => ({
      ...prev,
      [name]: !value,
    }))
  }

  useEffect(() => {
    if (!isEmpty(dataDetailMetting)) {
      setDataForm({
        meetingType: get(dataDetailMetting, 'meetingType'),
        textNumber: get(dataDetailMetting, 'textNumber'),
        title: get(dataDetailMetting, 'title'),
        executionDate: dataDetailMetting?.executionDate
          ? moment(get(dataDetailMetting, 'executionDate'))
          : null,
        mainResponsibilityId: get(dataDetailMetting, 'mainResponsibilityId'),
        meetingFiles: [
          {
            ...dataDetailMetting?.files?.[0],
            uid: dataDetailMetting?.files?.[0]?.id,
            fileId: dataDetailMetting?.files?.[0]?.id,
          },
        ],
      })
    }
  }, [dataDetailMetting])

  const [isOpen, setIsOpen] = useState(false)
  const [dataInputSearch, setDataInputSearch] = useState([])
  const callApiSoCongVan = useCallback(
    messageDelay => {
      setDataInputSearch([])
      setIsOpen(false)
      actionApi.searchFileCongVanV2(messageDelay).then(res => {
        const result = res?.payload?.data?.result
        if (size(result) !== 0) {
          setIsOpen(true)
          setDataInputSearch(
            result?.map(elm => ({
              ...elm,
              value: elm?.congVanDenId || elm?.congVanId || elm?.congVanNoiBoId,
              label: elm?.soCongVan,
            }))
          )
        }
      })
    },
    [actionApi]
  )

  const onCardChange = useCallback(value => {
    setDataForm(prev => ({
      ...prev,
      textNumber: value?.label,
      meetingFiles: value?.fileCongVan
        ? [
            {
              ...value?.fileCongVan,
              uid: value?.fileCongVan?.fileId,
              name: value?.fileCongVan?.tenFile,
              mime: value?.fileCongVan?.kieuFile,
              size: value?.fileCongVan?.kichThuoc,
            },
          ]
        : [],
      title: value?.trichYeu,
      executionDate: value?.ngayCongVan ? moment(value?.ngayCongVan) : null,
    }))
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <div className={classNames('cpc-side-action', 'open', 'cpc-form')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>Sửa thông báo kết luận cuộc họp</span>
        <Icon
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          disabled={isLoading}
          onClick={onClose}
        />
      </h1>
      <Scrollbar maxHeight={window.innerHeight - 170}>
        <main>
          <div className="pt15 pb15 pl10 pr10">
            <Button
              className={classNames(
                Classes.MINIMAL,
                'cpc-button',
                'cpc-button-link',
                'mb10'
              )}
              intent={Intent.PRIMARY}
              onClick={() =>
                toggleCollapse('showMeeting', dataCollapse?.showMeeting)
              }
            >
              <span className="text-uppercase font-size-13 font-weight-600 color-blue">
                {'Thông tin cuộc họp'}
              </span>
              <Icon
                classIcon={
                  dataCollapse?.showMeeting
                    ? 'icon2-arrow-up'
                    : 'icon2-arrow-down'
                }
                className={classNames('ml5', 'size-icon-10', 'd-flex')}
              />
            </Button>
            <Collapse isOpen={dataCollapse?.showMeeting}>
              <div
                className="d-flex align-item-center p10 mb10"
                style={{ backgroundColor: '#E8E8E8' }}
              >
                <Icon
                  classIcon={'icon2-user'}
                  className={classNames('mr5', 'd-flex')}
                />
                <span className="font-size-13">
                  {dataThongTinDonVi?.tenDonVi}
                </span>
              </div>
              <Row gutterVertical>
                <Col grid="half">
                  <Select
                    className="mb10"
                    isRequired={true}
                    textLabel="Loại cuộc họp"
                    isShowArrowDropdown={true}
                    dataSource={dsLoaiCuocHop}
                    inputIconClassName="icon2-tags-dup"
                    placeholder="Loại cuộc họp"
                    value={get(dataForm, 'meetingType')}
                    inputWarning={get(validateErrors, 'meetingType')}
                    onChange={changeField('meetingType')}
                  />
                </Col>
                <Col grid="half">
                  <InputSearch
                    popoverPosition={Position.BOTTOM_RIGHT}
                    value={dataForm?.textNumber}
                    maxLength={500}
                    required
                    label="Số hiệu văn bản"
                    iconClassName="icon2-documents"
                    wrapperClassName="mb10"
                    dataSource={dataInputSearch}
                    warning={get(validateErrors, 'textNumber')}
                    open={isOpen}
                    onCardChange={onCardChange}
                    onLoad={callApiSoCongVan}
                    onChange={e => changeField('textNumber')(e)}
                  />
                </Col>
              </Row>
              <Upload
                isOnly
                isRequired
                textLabel="File văn bản"
                accept={documentTypesLimit.toString()}
                fileList={get(dataForm, 'meetingFiles') || []}
                wrapperClassName="mb10"
                warning={get(validateErrors, 'meetingFiles')}
                textSumary={
                  <p className="mt5 font-style-italic font-size-12">
                    Chỉ tải lên 1 file có dung lượng tối đa 100MB, định dạng
                    .pdf, .docs, .doc, .xls, .xlsx
                  </p>
                }
                allowFileTypes={allowDocumentFileTypesLimit}
                onChange={changeFile('meetingFiles')}
              />
              <Input
                label="Tên cuộc họp"
                required
                placeholder="Nhập tên cuộc họp..."
                rows={2}
                maxLength={1000}
                autoResize={true}
                type="textarea"
                wrapperClassName="mb10"
                value={get(dataForm, 'title')}
                onChange={changeField('title')}
                warning={get(validateErrors, 'title')}
              />
              <Row gutterVertical>
                <Col grid="half">
                  <FormDatePicker
                    isRequired={true}
                    textLabel="Ngày ban hành văn bản"
                    inputClearable
                    inputIconClassName="icon2-date-frames"
                    inputWrapperClassName="mb10"
                    placeholder="Ngày ban hành văn bản"
                    popoverStretch={false}
                    popoverPosition={Position.TOP_LEFT}
                    selected={get(dataForm, 'executionDate')}
                    inputWarning={get(validateErrors, 'executionDate')}
                    onChange={value => {
                      changeField('executionDate')(value)
                    }}
                  />
                </Col>
                <Col grid="half">
                  <Select
                    isRequired={true}
                    textLabel="Lãnh đạo chủ trì"
                    placeholder="Chọn lãnh đạo chủ trì"
                    isShowArrowDropdown={true}
                    dataSource={dataLanhDaoDonVi?.map(elm => ({
                      label: elm?.tenNhanVien,
                      value: elm?.chucDanhId,
                    }))}
                    inputIconClassName="icon2-user"
                    popoverPosition={Position.TOP_RIGHT}
                    value={get(dataForm, 'mainResponsibilityId')}
                    inputWarning={get(validateErrors, 'mainResponsibilityId')}
                    onChange={changeField('mainResponsibilityId')}
                  />
                </Col>
              </Row>
            </Collapse>
          </div>
        </main>
      </Scrollbar>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          backgroundColor: '#f8f9fb',
          boxShadow: '-1px 0px 4px rgba(0,0,0,0.1)',
        }}
      >
        <div className={classNames('element-center', 'pv10 ph10')}>
          <Button
            className={classNames(
              'cpc-button',
              'btn-cancel',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100',
              'mr15'
            )}
            disabled={isLoading}
            onClick={onClose}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={classNames(
              'cpc-button',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100'
            )}
            intent={Intent.PRIMARY}
            onClick={submitUpdate}
            loading={isLoading}
            disabled={isLoading || isDisabledButtonDefault}
          >
            Lưu
            <Icon classIcon="icon-save" className="ml5" />
          </Button>
        </div>
      </div>
    </div>
  )
}

MeetingFormUpdate.propTypes = {
  dataLanhDaoDonVi: PropTypes.array,
}

const mapStateToProps = state => ({
  dataLanhDaoDonVi: get(state, 'common.dataLanhDaoDonVi'),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ searchFileCongVanV2 }, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(MeetingFormUpdate))
)
