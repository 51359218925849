import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { PopupConfirm } from '../common/'
import moment from 'moment'
import * as POPUP from '../../constants/Popup'
import * as Types from '../../constants/Api'
import classnames from 'classnames'

class ListItemProject extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickPopup = this.handleClickPopup.bind(this)
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)

    this.state = {
      chucDanhId: this.props.auth ? this.props.auth.roleId : null,
      isOpenPopup: false,
      isCanUpdate: true,
      isCanManage: false,
    }
  }

  handleClick = () => {
    if (!this.props.item) {
      return
    }
    this.props.onClickDetail &&
      this.props.onClickDetail({
        kySoId: this.props.item.kySoId,
        soKyHieu: this.props.item.soKyHieu,
        fileId: this.props.item.fileId,
      })
  }

  handleClickPopup = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmitRemoveItem = e => {
    e.preventDefault()
    this.setState({
      isOpenPopup: false,
    })
    this.props.onHandleSubmitRemoveItem &&
      this.props.onHandleSubmitRemoveItem(this.props.item.kySoId)
  }

  componentDidMount() {
    let state = this.state
    if (
      this.props.item.nguoiLap &&
      this.props.item.nguoiLap.chucDanhId === state.chucDanhId
    ) {
      state.isCanManage = true
      state.isCanUpdate = true
    }
    if (this.props.item && this.props.item.tinhTrang) {
      let tinhTrang = this.props.item.tinhTrang
      // Đã duyệt thì không được sửa, chỉ được xóa
      if (tinhTrang === Types.PASSED) {
        state.isCanUpdate = false
      }
    }
    this.setState(state)
  }

  render() {
    if (!this.props.item) {
      return
    }
    let tmp
    const step = this.props.item.buoc
    if (
      (this.props.auth && this.props.auth.roleId) ===
      (this.props.item &&
        this.props.item.nguoiLap &&
        this.props.item.nguoiLap.chucDanhId)
    ) {
      switch (this.props.item.tinhTrang) {
        case 0:
          tmp = <span className="status-job no-process">Chưa duyệt</span>
          break

        case 1:
          tmp = <span className="status-job no-approval">Không duyệt</span>
          break

        case 2:
          tmp = (
            <span className="status-job approved">{`Đang duyệt - Bước ${step}`}</span>
          )
          break

        case 6:
          tmp = <span className="status-job approved">Đã duyệt</span>
          break

        default:
          tmp = <span>&nbsp;</span>
      }
    } else {
      switch (this.props.item.tinhTrang) {
        case 0:
          tmp = <span className="status-job no-process">Chưa duyệt</span>
          break

        case 1:
          tmp = <span className="status-job no-approval">Không duyệt</span>
          break

        case 2:
          tmp = <span className="status-job approved">Đang duyệt</span>
          break

        case 6:
          tmp = <span className="status-job approved">Đã duyệt</span>
          break

        default:
          tmp = <span>&nbsp;</span>
      }
    }

    // Đối với người lập: khongDuyet === true => Không đồng ý
    if (this.state.isCanManage && this.props.item.khongDuyet === true) {
      tmp = <span className="status-job no-approval">Không duyệt</span>
    }
    const tinhTrang = tmp

    let soKyHieu = ''
    if (this.props.item.soKyHieu) {
      soKyHieu = this.props.item.soKyHieu
      if (this.props.item.soKyHieu.indexOf('/') === 0) {
        soKyHieu = this.props.item.soKyHieu.substring(1)
      }
    }

    if (
      this.props.item.soVanBan &&
      !isNaN(this.props.item.soVanBan) &&
      Number(this.props.item.soVanBan) > 0
    ) {
      soKyHieu = this.props.item.soVanBan + '/' + soKyHieu
    }

    return (
      <tr
        className={classnames({
          'status-urgency': this.props.item.quaHan === true,
        })}
      >
        <td onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td onClick={this.handleClick}>{this.props.item.tenDuAn}</td>
        <td onClick={this.handleClick}>{soKyHieu}</td>
        <td onClick={this.handleClick}>
          {moment(this.props.item.ngayLap).format('DD/MM/YYYY')}
        </td>
        <td onClick={this.handleClick}>
          {this.props.item.nguoiLap
            ? this.props.item.nguoiLap.tenNhanVien
            : null}
        </td>
        <td onClick={this.handleClick} className="textarea-content">
          {this.props.item.trichYeu}
        </td>
        <td onClick={this.handleClick}>{tinhTrang}</td>
        {this.state.isCanUpdate === true && this.state.isCanManage === true ? (
          <td>
            <Popover
              popoverClassName="pt-popover-content-sizing"
              position={Position.BOTTOM_RIGHT}
              interactionKind={PopoverInteractionKind.HOVER}
              openOnTargetFocus={true}
              content={
                <div className="menu-popover">
                  {this.state.isCanUpdate && (
                    <Link
                      className="item"
                      to={`/ky-so/du-an/chinh-sua/${this.props.item.kySoId}`}
                    >
                      <span className="pt-icon pt-icon-annotation"></span>
                      <span className="name_action">Chỉnh sửa</span>
                    </Link>
                  )}
                  <span className="item" onClick={this.handleClickPopup}>
                    <span className="pt-icon pt-icon-trash"></span>
                    <span className="name_action">Xóa</span>
                  </span>
                </div>
              }
            >
              <span className="icon-More"></span>
            </Popover>
            <PopupConfirm
              isOpen={this.state.isOpenPopup}
              onClose={this.handleClickPopup}
              title={POPUP.XOA_VAN_BAN_KY_SO}
              text={POPUP.TEXT_BUTTON_SUBMIT}
              isWarning={
                this.props.item && this.props.item.tinhTrang > 1 ? true : false
              }
              onSubmit={this.handleSubmitRemoveItem}
            >
              {this.props.item && this.props.item.tinhTrang > 1
                ? POPUP.CANH_BAO_XOA_VAN_BAN_KY_SO
                : POPUP.TEXT_SUBMIT_BODY}
            </PopupConfirm>
          </td>
        ) : (
          <td>&nbsp;</td>
        )}
      </tr>
    )
  }
}

export default ListItemProject
