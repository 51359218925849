import React, { useState, useCallback, useEffect } from 'react'
import { withCookies } from 'react-cookie'
import withRouter from 'react-router/withRouter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import { Popover, Position } from '@blueprintjs/core'
import size from 'lodash/size'

import { NotificationTabs } from 'components/home'
import * as Actions from 'actions'
import { PAGINATION_LIMIT, PAGE } from 'constants/Api'
import { Utils } from 'helpers'

const Notification = ({
  classNameIcon = '',
  actions,
  history,
  width = 400,
  notifications,
  dataCorporations = [],
  dataUnit = [],
}) => {
  const [pageCount, setPageCount] = useState({
    corporations: Math.ceil(
      notifications.list?.totalCorporations / PAGINATION_LIMIT
    ),
    unit: Math.ceil(notifications.list?.totalUnit / PAGINATION_LIMIT),
  })
  const [page, setPage] = useState({
    corporations: PAGE,
    unit: PAGE,
  })
  const [isLoading, setIsLoading] = useState({
    corporations: false,
    unit: false,
    numberSideBar: true,
  })
  const [isEmpty, setIsEmpty] = useState({
    corporations: false,
    unit: false,
  })
  const [isNoMoreItem, setIsNoMoreItem] = useState({
    corporations: false,
    unit: false,
  })

  const submitFilter = useCallback(
    async (pageParam = 1, type = 'corporations') => {
      const newLoading = {
        ...isLoading,
        [type]: true,
      }
      const pageList = {
        ...page,
        [type]: pageParam,
      }
      const isCorporations = type === 'corporations' ? true : false
      await setPage(pageList)
      await setIsLoading(newLoading)
      Utils.preData(`${type}NotificationList`, { pageParam })
      const res = await actions.notificationFetchList(pageParam, isCorporations)
      if (res && (!res?.error || res?.payload?.data?.result)) {
        const result = res?.payload?.data?.result || null
        if (pageParam === 1) {
          await actions.notificationGetLastest()
        }

        if (result && result?.items && size(result?.items) === 0) {
          setIsNoMoreItem(prev => ({
            ...prev,
            [type]: true,
          }))
        }

        if (result?.total && !isNaN(result?.total)) {
          setPageCount(prev => ({
            ...prev,
            [type]: Math.ceil(result?.total / PAGINATION_LIMIT),
          }))
        }

        if (result?.total === 0) {
          setIsEmpty(prev => ({
            ...prev,
            [type]: true,
          }))
        }
      } else {
        setIsEmpty(prev => ({
          ...prev,
          [type]: false,
        }))
      }

      setIsLoading(prev => ({
        ...prev,
        [type]: false,
      }))
    },
    // eslint-disable-next-line
    [page, actions]
  )

  const handleClickPreviousPage = useCallback(
    (type = 'corporations') => {
      if (page[type] <= 1) {
        return
      }

      setPage(prev => ({
        ...prev,
        [type]: page[type] - 1,
      }))
      submitFilter(page[type] - 1, type)
    },
    [page, submitFilter]
  )

  const handleClickNextPage = useCallback(
    (type = 'corporations') => {
      if (page[type] >= pageCount[type] || isNoMoreItem[type]) {
        return
      }

      if (!isNaN(page[type] + 1)) {
        setPage(prev => ({
          ...prev,
          [type]: page[type] + 1,
        }))
        submitFilter(page[type] + 1, type)
      }
    },
    [page, pageCount, isNoMoreItem, submitFilter]
  )

  useEffect(() => {
    setPageCount(prev => ({
      ...prev,
      corporations: Math.ceil(
        notifications.list?.totalCorporations / PAGINATION_LIMIT
      ),
      unit: Math.ceil(notifications.list?.totalUnit / PAGINATION_LIMIT),
    }))
  }, [notifications])

  return (
    <Popover
      content={
        <NotificationTabs
          history={history}
          width={width}
          corporations={dataCorporations}
          unit={dataUnit}
          notifications={notifications}
          pageCount={pageCount}
          page={page}
          isLoading={isLoading}
          isEmpty={isEmpty}
          onClickPreviousPage={handleClickPreviousPage}
          onClickNextPage={handleClickNextPage}
          submitFilter={submitFilter}
          actions={actions}
        />
      }
      onClose={() => {}}
      position={Position.BOTTOM_RIGHT}
      usePortal={false}
    >
      <div
        onClick={() => {}}
        className={classNames('notification-title', classNameIcon)}
      >
        <div className="notification-title__icon-noti-wrapper">
          <span className="icon-notification notification-title__icon-noti size-icon-20"></span>
          {(!notifications?.list?.unreadCorporations.daDoc ||
            !notifications?.list?.unreadUnit.daDoc) && (
            <span className="notification-title__dot-noti"></span>
          )}
        </div>
      </div>
    </Popover>
  )
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...Actions }, dispatch),
})

const mapStateToProps = state => ({
  auth: state.auth,
})

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification))
)
