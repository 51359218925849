import React, {
  Fragment,
  memo,
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import size from 'lodash/size'
import isEmpty from 'lodash/isEmpty'
import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import toPairs from 'lodash/toPairs'
import isString from 'lodash/isString'
import classNames from 'classnames'
import moment from 'moment'
import {
  Classes,
  Button,
  Intent,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'

import { RESPONSIBILITY_TYPE, UNSET } from 'constants/Enum'
import { Action, Toast } from 'constants/MessageForm'
import { priorities } from 'constants/task'
import {
  documentTypesLimit,
  allowDocumentFileTypesLimit,
  documentTypes,
  allowDocumentFileTypes,
} from 'constants/fileTypes'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import { randomId } from 'helpers/string'
import {
  Row,
  Col,
  Scrollbar,
  Upload,
  Collapse,
  FileGroup,
  Icon,
  Dialog,
  Card,
} from 'components/newCommon'
import {
  Input,
  Select,
  RemindSelect,
  CheckboxSelect,
  FormDatePicker,
  DateAdvanced,
  InputSearch,
} from 'components/newCommon2'
import { searchFileCongVanV2 } from 'actions'
import { postUpload, postMeeting } from 'actions/task'
import { MeetingContext } from '../Meeting'
import PhongBanVaDonViThucHien from './_components/PhongBanVaDonViThucHien'

const CONFIRM_TITLE = 'Xóa kết luận'
const CONFIRM_CONTENT = 'Anh/Chị muốn xóa kết luận này?'

const MeetingForm = ({
  actions: actionApi,
  match,
  history,
  dataLanhDaoDonVi,
  onClose = () => {},
  reloadList = () => {},
}) => {
  const {
    tags = [],
    dsLoaiCuocHop,
    dataThongTinDonVi,
    hasShowAllDeparment,
    mainDepartmentId,
    dataTreeOrganizationDepartmentStore,
  } = useContext(MeetingContext)

  const dateTimeRequest = 'YYYY-MM-DDTHH:mm:ss'
  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const THEO_DOI = 'THEO_DOI'
  const dateRequest = 'YYYY-MM-DD'
  const DEFAULT_TASK = useMemo(
    () => ({
      showTask: true,
      showExtra: false,
      data: {
        executionDate: moment(),
      },
      validate: {},
    }),
    []
  )
  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const { action } = match.params
  const isUpdate = action === 'chinh-sua'
  const [dataCollapse, setDataCollapse] = useState({
    showMeeting: true,
    showConclusion: true,
  })
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [dataForm, setDataForm] = useState({})
  const [tasks, setTasks] = useState([{ ...DEFAULT_TASK, id: randomId() }])
  const [validateErrors, setValidateErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [fetching] = useState(false)
  const [files, setFiles] = useState({})
  const [dataAutoToggle] = useState([-1])
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [idTempDialog, setIdTempDialog] = useState(null)

  const onCloseDialog = useCallback(() => {
    setIsShowDialog(false)
    setIdTempDialog(null)
  }, [])

  const onDeleteTask = useCallback(() => {
    const id = idTempDialog
    const newTasks = tasks.filter(task => task.id !== id)
    _mountedSet(
      setTasks,
      size(newTasks) === 1
        ? newTasks.map(t => ({ ...t, showTask: true }))
        : newTasks
    )

    _mountedSet(setFiles, prevFiles => {
      delete prevFiles[id]
      return prevFiles
    })

    onCloseDialog()
  }, [idTempDialog, tasks, onCloseDialog])

  const _upload = async filesData => {
    const responseFilesObject = {}
    await Promise.all(
      Object.entries(filesData).map(async ([key, files]) => {
        if (isEmpty(files)) {
          responseFilesObject[key] = []
          return
        }

        await requestAction({
          action: () => postUpload(files),
          afterResponse: (result = []) => {
            responseFilesObject[key] = result
          },
          showToastSucess: false,
          codeCheck: false,
        })

        return
      })
    )

    return responseFilesObject
  }

  const onChangeFormValidate = ({ type, id, name, value }) => {
    if (type === 'TASK') {
      setValidateErrors(prev => ({
        ...prev,
        [id]: {
          ...(prev[id] || {}),
          [name]: value,
        },
      }))
    } else {
      setValidateErrors(prev => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const validateRequired = name => {
    let success = true
    const tasksClone = [...tasks]
    tasksClone.forEach(({ id, data }) => {
      if (isEmpty(data[name]) || (isString(data[name]) && !data[name].trim())) {
        success = false
        onChangeFormValidate({ type: 'TASK', id, name, value: true })
      } else {
        onChangeFormValidate({ type: 'TASK', id, name, value: false })
      }
    })

    return success
  }

  const onValidateFormName = name => {
    let success = true
    if (
      isEmpty(dataForm?.[name]) ||
      (isString(dataForm[name]) && !dataForm[name].trim())
    ) {
      success = false
      onChangeFormValidate({ type: 'MEETING', id: -1, name, value: true })
    }

    return success
  }

  const onValidate = () => {
    const isCheckMeetingTitle = onValidateFormName('title')
    const isCheckMeetingMeetingType = onValidateFormName('meetingType')
    const isCheckMeetingTextNumber = onValidateFormName('textNumber')
    const isCheckMeetingExecutionDate = onValidateFormName('executionDate')
    const isCheckMeetingMainResponsibilityId = onValidateFormName(
      'mainResponsibilityId'
    )
    const isCheckMeetingMeetingFiles = onValidateFormName('meetingFiles')
    const isCheckFullMeeting =
      !isCheckMeetingTitle ||
      !isCheckMeetingMeetingType ||
      !isCheckMeetingTextNumber ||
      !isCheckMeetingExecutionDate ||
      !isCheckMeetingMainResponsibilityId ||
      !isCheckMeetingMeetingFiles
    const isCheckTaskTitle = validateRequired('title')
    const isCheckTaskChuTri = validateRequired([CHU_TRI])
    const isCheckTask = !isCheckTaskTitle || !isCheckTaskChuTri
    if (isCheckFullMeeting || isCheckTask) {
      throw new Error('warning')
    }
  }

  const handleConvertData = useCallback(values => {
    const data = {
      title: values?.title || null,
      textNumber: values?.textNumber || null,
      meetingType: values?.meetingType || null,
      mainResponsibilityId: values?.mainResponsibilityId,
      organizationId: values?.organizationId,
    }

    const executionDate = get(values, 'executionDate')
    if (executionDate) {
      data.executionDate = moment(executionDate).format(dateRequest)
    }

    if (get(values, 'meetingFiles')) {
      data.attachmentFileIds = values?.meetingFiles?.map(elm => elm.id)
    }

    return data
  }, [])

  const _convertDataType = (values, type) => ({
    responsibility_id: get(values, 'id'),
    assign_type: get(values, 'type'),
    responsibility_type: type,
  })

  const convertType = type => {
    if (type === 'DEPARTMENT') {
      return 'PB'
    } else if (type === 'ORGANIZATION') {
      return 'DV'
    }

    return 'PERSONAL'
  }

  const _getConvertData = () => {
    return (tasks || []).map(task => {
      const originData = get(task, 'data', {})
      const newData = {
        title: get(originData, 'title', '').trim(),
        description: get(originData, 'description', '').trim(),
        priority:
          get(originData, 'priority') !== UNSET
            ? get(originData, 'priority')
            : undefined,
        work_user_tags: get(originData, 'tags', []).map(item => ({
          work_tag_id: item,
        })),
        attachment_file_ids: get(originData, 'attachmentFiles', []).map(
          file => file.id
        ),
        reference_file_ids: get(originData, 'referenceFiles', []).map(
          file => file.id
        ),
        is_save_template: get(originData, 'isSaveTemplate'),
        require_report: originData?.requireReport || false,
      }

      const deadline = get(originData, 'deadline')
      if (deadline) {
        newData.deadline = moment(deadline).startOf('day').format(dateRequest)
      }

      const executionDate = get(originData, 'executionDate')
      if (executionDate) {
        newData.execution_date = moment(executionDate).format(dateRequest)
      }

      const workReminder = get(originData, 'workReminder')
      if (workReminder) {
        newData.work_reminder = {
          type: workReminder.type,
          reminder_time: workReminder.date.format(dateTimeRequest),
        }
      }

      let executor = []
      if (get(originData, CHU_TRI)) {
        executor = [
          {
            responsibility_id: get(originData, `${CHU_TRI}.id`),
            assign_type: get(originData, `${CHU_TRI}.type`),
            responsibility_type: RESPONSIBILITY_TYPE.CHU_TRI,
          },
        ]
        newData.presiding_id = {
          [convertType(get(originData, `${CHU_TRI}.type`))]: get(
            originData,
            `${CHU_TRI}.id`
          ),
        }
      }

      let coordination = []
      if (get(originData, PHOI_HOP)) {
        coordination = get(originData, PHOI_HOP, [])?.map(elm =>
          _convertDataType(elm, RESPONSIBILITY_TYPE.PHOI_HOP)
        )
        newData.coordination_id = get(originData, PHOI_HOP, [])?.map(elm => ({
          [convertType(elm?.type)]: elm?.id,
        }))
      }

      let watcher = []
      if (get(originData, THEO_DOI)) {
        watcher = get(originData, THEO_DOI, [])?.map(elm =>
          _convertDataType(elm, RESPONSIBILITY_TYPE.THEO_DOI)
        )
      }

      newData.work_assignments = [...executor, ...coordination, ...watcher]

      return {
        ...task,
        data: newData,
      }
    })
  }

  const changeFormValue = ({ id, name, value }) => {
    _mountedSet(setTasks, prevTasks =>
      prevTasks.map(task =>
        task.id === id
          ? {
              ...task,
              data: {
                ...task.data,
                [name]: value,
                ...(name === 'deadline' ? { workReminder: null } : {}),
              },
            }
          : task
      )
    )
  }

  const _getDataWithUpload = async () => {
    const cloneTasks = _getConvertData()
    const responseFilesObject = await _upload(files)
    return (cloneTasks || []).map(task => ({
      ...(task?.data || {}),
      work_type: 'MEETING',
      attachment_file_ids: [
        ...(task?.data?.attachment_file_ids || []),
        ...(responseFilesObject[task.id] || []).map(file => file.id),
      ],
    }))
  }

  const submitCreate = async () => {
    await requestAction({
      successCode: 201 || 200,
      logError: true,
      beforeAction: () => {
        _mountedSet(setIsLoading, true)
        onValidate()
      },
      action: async () => {
        const fileId = dataForm?.meetingFiles?.[0]?.fileId
        let responseFilesObject = {
          meetingFiles: [
            {
              id: fileId,
            },
          ],
        }
        if (!fileId) {
          responseFilesObject = await _upload({
            meetingFiles: dataForm?.meetingFiles,
          })
        }

        const data = await handleConvertData({
          ...dataForm,
          organizationId: dataThongTinDonVi?.donViId,
          ...responseFilesObject,
        })
        const dataConver = toDecamelizeKeys(data)
        const dataCongViec = await _getDataWithUpload()
        const fullData = {
          ...dataConver,
          meeting_work_items: dataCongViec,
        }
        return postMeeting(fullData)
      },
      afterResponse: () => {
        reloadList({ page: 1 })
        history.replace('/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop')
      },
      afterAction: () => {
        _mountedSet(setIsLoading, false)
      },
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const submitUpdate = async () => {}

  const changeField = (type, id, name) => value => {
    setIsDisabledButtonDefault(false)
    if (type === 'TASK') {
      onChangeFormValidate({ type, id, name, value: false })
      changeFormValue({
        id,
        name,
        value,
      })
    } else {
      onChangeFormValidate({ type, id, name: id, value: false })
      setDataForm(prev => ({
        ...prev,
        [id]: value,
      }))
    }
  }

  const changeFile = (type, name) => fileList => {
    _mountedSet(setIsDisabledButtonDefault, false)
    if (type === 'TASK') {
      _mountedSet(setFiles, {
        ...files,
        [name]: fileList,
      })
    } else {
      onChangeFormValidate({ type, id: -1, name, value: false })
      _mountedSet(setDataForm, prev => ({
        ...prev,
        [name]: fileList,
      }))
    }
  }

  // FUNCTION CONG VIEC
  const addTask = () => {
    _mountedSet(setDataCollapse, {
      ...dataCollapse,
      showMeeting: false,
    })
    _mountedSet(setTasks, [
      ...tasks?.map(t => ({ ...t, showTask: false, showExtra: false })),
      { ...DEFAULT_TASK, id: randomId() },
    ])
  }

  const toggleCollapseTask = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showTask: !task.showTask,
              showExtra: false,
            }
          : {
              ...task,
              showTask: false,
            }
      )
    )
  }

  const removeTask = id => () => {
    setIdTempDialog(id)
    setIsShowDialog(true)
  }

  const toggleCollapseExtra = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showExtra: !task.showExtra,
            }
          : task
      )
    )
  }

  const removeAttachmentFiles = id => removeFile => {
    if (removeFile.id) {
      _mountedSet(setTasks, prevTasks =>
        prevTasks.map(task =>
          task.id === id
            ? {
                ...task,
                data: {
                  ...get(task, 'data', {}),
                  attachmentFiles: get(task, 'data.attachmentFiles', []).filter(
                    file => !isEqual(file, removeFile)
                  ),
                },
              }
            : task
        )
      )
    } else {
      _mountedSet(setFiles, {
        ...files,
        [id]: files[id].filter(elm => elm.uid !== removeFile.uid),
      })
    }
  }

  const attachmentFilesFromDocument = (files, isEoffice) => {
    let filesResult = []
    if (!size(files)) {
      return []
    }

    if (isEoffice) {
      filesResult = files.filter(elm => elm.application === 'eOffice')
    } else {
      filesResult = files.filter(elm => elm.application !== 'eOffice')
    }

    return filesResult
  }

  const onChangeRadio = useCallback(
    (name, values, taskId) => {
      setIsDisabledButtonDefault(false)
      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]: name === CHU_TRI ? values : null,
                [PHOI_HOP]:
                  name === PHOI_HOP
                    ? elm?.data?.[PHOI_HOP]
                    : elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id),
                [THEO_DOI]:
                  name === THEO_DOI
                    ? elm?.data?.[THEO_DOI]
                    : elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id),
              },
            }
          }

          return elm
        })
      )
      onChangeFormValidate({ type: 'TASK', id: taskId, name, value: false })
    },
    [setTasks, setIsDisabledButtonDefault]
  )

  const handleCheckActive = useCallback(
    (key, tasks, taskId, record) => {
      const parentItem =
        dataTreeOrganizationDepartmentStore?.find(e => e?.id === record?.id)
          ?.children || []
      const taskItem = tasks?.find(elm => elm?.id === taskId)
      let isChecked = false
      if (
        !isEmpty(taskItem) !== 0 &&
        !isEmpty(taskItem?.data) &&
        size(taskItem?.data?.[key]) !== 0
      ) {
        const taskItemDataKey = taskItem?.data?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        if (
          record?.type === 'ALL' &&
          size(parentItem) !== 0 &&
          size(parentItem) === size(taskItemDataKey)
        ) {
          isChecked = true
        } else {
          const dataKey = taskItem?.data?.[key]?.findIndex(
            e => e?.id === record?.id
          )
          if (dataKey === 0 || (dataKey && dataKey !== -1)) {
            isChecked = true
          }
        }
      }

      return isChecked
    },
    [dataTreeOrganizationDepartmentStore]
  )

  const onChangeCheckbox = useCallback(
    async (name, values, taskId, event, isIndeterminate) => {
      setIsDisabledButtonDefault(false)
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      const isAll = values?.type === 'ALL'
      let treeItem = []
      if (isAll) {
        treeItem =
          dataTreeOrganizationDepartmentStore?.find(e => e?.id === values?.id)
            ?.children || []
      }

      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            let newData = []
            const oldData =
              elm?.data?.[name]?.filter(e => e?.id !== values?.id) || []
            newData = [
              ...oldData,
              ...(isChecked && values?.type !== 'ALL' ? [values] : []),
            ]
            if (isAll) {
              if (isChecked) {
                newData = [...newData, ...treeItem]?.filter(
                  e => e.id !== values?.parentId
                )
                const dataChuTri = elm?.data?.[CHU_TRI]
                if (dataChuTri?.id) {
                  newData = newData?.filter(e => e.id !== dataChuTri?.id)
                }
              } else {
                newData = differenceBy(newData || [], treeItem || [], 'id')
              }
            } else {
              newData = newData?.filter(e => e.id !== values?.parentId)
            }

            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]:
                  name !== CHU_TRI && elm?.data?.[CHU_TRI]?.id === values?.id
                    ? null
                    : elm?.data?.[CHU_TRI],
                [PHOI_HOP]:
                  name === PHOI_HOP
                    ? uniqBy(newData, 'id')
                    : elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id) ||
                      [],
                [THEO_DOI]:
                  name === THEO_DOI
                    ? uniqBy(newData, 'id')
                    : elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id) ||
                      [],
              },
            }
          }

          return elm
        })
      )
    },
    [setTasks, dataTreeOrganizationDepartmentStore, setIsDisabledButtonDefault]
  )

  const handleCheckIndeterminate = useCallback(
    (name, tasks, taskId, record) => {
      const taskItem = tasks?.find(e => e.id === taskId)
      let isChecked = false
      const dataKey = size(taskItem?.data?.[name]) !== 0
      const hasChecked = taskItem?.data?.[name]?.some(
        e => e?.parentId === record?.id
      )
      if (dataKey && record?.type === 'ALL' && hasChecked) {
        isChecked = true
      }

      return isChecked
    },
    []
  )

  const column = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: (record, _, __, taskId) => {
        const taskItem = tasks?.find(
          elm => elm.id === taskId && elm?.data?.[CHU_TRI]?.id === record?.id
        )
        let isChecked = false
        if (!isEmpty(taskItem)) {
          isChecked = true
        }

        if (
          (!hasShowAllDeparment && record?.phongBanId === mainDepartmentId) ||
          record?.type === 'ALL'
        ) {
          return ''
        }

        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            onChange={() => onChangeRadio(CHU_TRI, record, taskId)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: (record, _, __, taskId) => {
        const isChecked = handleCheckActive(PHOI_HOP, tasks, taskId, record)
        const isIndeterminate = handleCheckIndeterminate(
          PHOI_HOP,
          tasks,
          taskId,
          record
        )
        if (!hasShowAllDeparment && record?.phongBanId === mainDepartmentId) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            onChange={e =>
              onChangeCheckbox(PHOI_HOP, record, taskId, e, isIndeterminate)
            }
          />
        )
      },
    },
  ]

  const toggleCollapse = (name, value) => {
    _mountedSet(setDataCollapse, {
      showMeeting: false,
      showConclusion: false,
      [name]: !value,
    })
  }

  const [isOpen, setIsOpen] = useState(false)
  const [dataInputSearch, setDataInputSearch] = useState([])
  const callApiSoCongVan = useCallback(
    messageDelay => {
      setDataInputSearch([])
      setIsOpen(false)
      actionApi.searchFileCongVanV2(messageDelay).then(res => {
        const result = res?.payload?.data?.result
        if (size(result) !== 0) {
          setIsOpen(true)
          setDataInputSearch(
            result?.map(elm => ({
              ...elm,
              value: elm?.congVanDenId || elm?.congVanId || elm?.congVanNoiBoId,
              label: elm?.soCongVan,
            }))
          )
        }
      })
    },
    [actionApi]
  )

  const onCardChange = useCallback(value => {
    setDataForm(prev => ({
      ...prev,
      textNumber: value?.label,
      meetingFiles: value?.fileCongVan
        ? [
            {
              ...value?.fileCongVan,
              uid: value?.fileCongVan?.fileId,
              name: value?.fileCongVan?.tenFile,
              mime: value?.fileCongVan?.kieuFile,
              size: value?.fileCongVan?.kichThuoc,
            },
          ]
        : [],
      title: value?.trichYeu,
      executionDate: value?.ngayCongVan ? moment(value?.ngayCongVan) : null,
    }))
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <div className={classNames('cpc-side-action', 'open', 'cpc-form')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>Tạo thông báo kết luận cuộc họp</span>
        <Icon
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          disabled={isLoading}
          onClick={onClose}
        />
      </h1>
      <Scrollbar maxHeight={window.innerHeight - 170}>
        <main>
          <div className="pt15 pb15 pl10 pr10 border-bottom">
            {/* Cuộc họp */}
            <Button
              className={classNames(
                Classes.MINIMAL,
                'cpc-button',
                'cpc-button-link',
                'mb10'
              )}
              intent={Intent.PRIMARY}
              onClick={() =>
                toggleCollapse('showMeeting', dataCollapse?.showMeeting)
              }
            >
              <span className="text-uppercase font-size-13 font-weight-600 color-blue">
                {'Thông tin cuộc họp'}
              </span>
              <Icon
                classIcon={
                  dataCollapse?.showMeeting
                    ? 'icon2-arrow-up'
                    : 'icon2-arrow-down'
                }
                className={classNames('ml5', 'size-icon-10', 'd-flex')}
              />
            </Button>
            <Collapse isOpen={dataCollapse?.showMeeting}>
              <div
                className="d-flex align-item-center p10 mb10"
                style={{ backgroundColor: '#E8E8E8' }}
              >
                <Icon
                  classIcon={'icon2-user'}
                  className={classNames('mr5', 'd-flex')}
                />
                <span className="font-size-13">
                  {dataThongTinDonVi?.tenDonVi}
                </span>
              </div>
              <Row gutterVertical>
                <Col grid="half">
                  <Select
                    className="mb10"
                    isRequired={true}
                    textLabel="Loại cuộc họp"
                    isShowArrowDropdown={true}
                    dataSource={dsLoaiCuocHop}
                    inputIconClassName="icon2-tags-dup"
                    placeholder="Loại cuộc họp"
                    value={get(dataForm, 'meetingType')}
                    inputWarning={get(validateErrors, 'meetingType')}
                    onChange={changeField('MEETING', 'meetingType')}
                  />
                </Col>
                <Col grid="half">
                  <InputSearch
                    popoverPosition={Position.BOTTOM_RIGHT}
                    value={dataForm?.textNumber}
                    maxLength={500}
                    required
                    label="Số hiệu văn bản"
                    iconClassName="icon2-documents"
                    wrapperClassName="mb10"
                    dataSource={dataInputSearch}
                    warning={get(validateErrors, 'textNumber')}
                    open={isOpen}
                    onCardChange={onCardChange}
                    onLoad={callApiSoCongVan}
                    onChange={e => changeField('MEETING', 'textNumber')(e)}
                  />
                </Col>
              </Row>
              <Upload
                isOnly
                isRequired
                textLabel="File văn bản"
                accept={documentTypesLimit.toString()}
                fileList={get(dataForm, 'meetingFiles') || []}
                wrapperClassName="mb10"
                warning={get(validateErrors, 'meetingFiles')}
                textSumary={
                  <p className="mt5 font-style-italic font-size-12">
                    Chỉ tải lên 1 file có dung lượng tối đa 100MB, định dạng
                    .pdf, .docs, .doc, .xls, .xlsx
                  </p>
                }
                allowFileTypes={allowDocumentFileTypesLimit}
                isShowList={true}
                onChange={changeFile('MEETING', 'meetingFiles')}
              />
              <Input
                label="Tên cuộc họp"
                required
                placeholder="Nhập tên cuộc họp..."
                autoResize={true}
                type="textarea"
                rows={2}
                maxLength={2000}
                wrapperClassName="mb10"
                value={get(dataForm, 'title')}
                onChange={changeField('MEETING', 'title')}
                warning={get(validateErrors, 'title')}
              />
              <Row gutterVertical>
                <Col grid="half">
                  <FormDatePicker
                    isRequired={true}
                    textLabel="Ngày ban hành văn bản"
                    inputClearable
                    inputIconClassName="icon2-date-frames"
                    inputWrapperClassName="mb10"
                    placeholder="Ngày ban hành văn bản"
                    popoverStretch={false}
                    selected={get(dataForm, 'executionDate')}
                    inputWarning={get(validateErrors, 'executionDate')}
                    onChange={value => {
                      changeField('MEETING', 'executionDate')(value)
                    }}
                  />
                </Col>
                <Col grid="half">
                  <Select
                    isRequired={true}
                    textLabel="Lãnh đạo chủ trì"
                    placeholder="Chọn lãnh đạo chủ trì"
                    isShowArrowDropdown={true}
                    dataSource={dataLanhDaoDonVi?.map(elm => ({
                      label: elm?.tenNhanVien,
                      value: elm?.chucDanhId,
                    }))}
                    inputIconClassName="icon2-user"
                    value={get(dataForm, 'mainResponsibilityId')}
                    inputWarning={get(validateErrors, 'mainResponsibilityId')}
                    onChange={changeField('MEETING', 'mainResponsibilityId')}
                  />
                </Col>
              </Row>
            </Collapse>
          </div>
          <div className="pt15 pb15 pl10 pr10">
            {/* Kết luận, chỉ đạo */}
            <Button
              className={classNames(
                Classes.MINIMAL,
                'cpc-button',
                'cpc-button-link',
                'mb10'
              )}
              intent={Intent.PRIMARY}
              onClick={() =>
                toggleCollapse('showConclusion', dataCollapse?.showConclusion)
              }
            >
              <span className="text-uppercase font-size-13 font-weight-600 color-blue">
                {'Kết luận, chỉ đạo'}
              </span>
              <Icon
                classIcon={
                  dataCollapse?.showConclusion
                    ? 'icon2-arrow-up'
                    : 'icon2-arrow-down'
                }
                className={classNames('ml5', 'size-icon-10', 'd-flex')}
              />
            </Button>
            <Collapse isOpen={dataCollapse?.showConclusion}>
              {tasks.map(({ id, showTask, showExtra, data }, index) => (
                <Fragment key={id}>
                  {!!index && (
                    <span
                      style={{
                        display: 'block',
                        height: 5,
                        background: '#DFE6EA',
                      }}
                    />
                  )}
                  <div style={{ padding: 10, backgroundColor: '#fff' }}>
                    {size(tasks) > 1 && (
                      <header className="d-flex">
                        <Button
                          className={classNames(
                            Classes.MINIMAL,
                            'cpc-button',
                            'cpc-form-button-left',
                            'font-weight-600',
                            'uppercase',
                            'text-left'
                          )}
                          intent={Intent.PRIMARY}
                          onClick={toggleCollapseTask(id)}
                          style={{ lineHeight: 1.3 }}
                        >
                          {`Kết luận ${index + 1}`}
                          <Icon
                            classIcon={
                              showTask ? 'icon2-arrow-up' : 'icon2-arrow-down'
                            }
                            className={classNames(
                              'ml5',
                              'size-icon-10',
                              'd-flex'
                            )}
                          />
                        </Button>
                        <Button
                          className={classNames(
                            Classes.MINIMAL,
                            'cpc-button',
                            'cpc-form-button-right'
                          )}
                          intent={Intent.DANGER}
                          onClick={removeTask(id)}
                        >
                          <Icon classIcon="icon-bin" />
                        </Button>
                      </header>
                    )}

                    <Collapse isOpen={showTask}>
                      <Input
                        required={true}
                        disabled={fetching}
                        label="Nội dung kết luận"
                        onChange={changeField('TASK', id, 'title')}
                        placeholder="Nhập nội dung kết luận"
                        type="textarea"
                        rows={2}
                        maxLength={2000}
                        autoResize={true}
                        value={get(data, 'title')}
                        wrapperClassName="mb15"
                        labelButton={
                          <div style={{ marginLeft: 'auto' }}>
                            <Checkbox
                              className={classNames(Classes.SMALL, 'mb0')}
                              checked={get(data, 'requireReport', false)}
                              onChange={({ target: { checked } }) =>
                                changeField(
                                  'TASK',
                                  id,
                                  'requireReport'
                                )(checked)
                              }
                            >
                              <span className="font-size-13 font-weight-300">
                                Yêu cầu báo cáo Hoàn thành
                              </span>
                            </Checkbox>
                          </div>
                        }
                        warning={get(validateErrors, `${id}.title`)}
                      />
                      <PhongBanVaDonViThucHien
                        className="mb10"
                        isRequired={true}
                        column={column}
                        data={dataTreeOrganizationDepartmentStore}
                        taskId={id}
                        warning={get(validateErrors, `${id}.${CHU_TRI}`)}
                        dataAutoToggle={dataAutoToggle}
                      />
                      <Row gutterVertical className="mb10">
                        <Col grid="half">
                          <FormDatePicker
                            textLabel="Ngày bắt đầu"
                            disabled={fetching}
                            inputClearable
                            inputIconClassName="icon2-date-frames"
                            inputWrapperClassName="mb10"
                            onChange={value => {
                              changeField('TASK', id, 'executionDate')(value)
                            }}
                            maximumDate={
                              get(data, 'deadline')
                                ? moment(get(data, 'deadline')).toDate()
                                : undefined
                            }
                            placeholder="Chọn ngày bắt đầu"
                            popoverStretch={false}
                            popoverPosition={Position.TOP_LEFT}
                            selected={get(data, 'executionDate')}
                          />
                        </Col>
                        <Col grid="half">
                          <DateAdvanced
                            textLabel="Hạn thực hiện"
                            disabled={fetching}
                            inputClearable
                            inputIconClassName="icon2-date-frames"
                            inputWrapperClassName="mb10"
                            placeholder="Chọn hạn thực hiện"
                            popoverStretch={false}
                            selected={get(data, 'deadline')}
                            inputWarning={get(validateErrors, `${id}.deadline`)}
                            minimumDate={
                              get(data, 'executionDate')
                                ? moment(get(data, 'executionDate')).toDate()
                                : moment().toDate()
                            }
                            popoverPosition={Position.TOP_RIGHT}
                            onChange={value => {
                              changeField('TASK', id, 'deadline')(value)
                            }}
                          />
                        </Col>
                      </Row>

                      {/* Thông tin bổ sung */}
                      <Collapse isOpen={showExtra}>
                        <Input
                          disabled={fetching}
                          label="Mô tả"
                          onChange={changeField('TASK', id, 'description')}
                          placeholder="Nhập..."
                          rows={2}
                          maxLength={500}
                          autoResize={true}
                          style={{ maxHeight: 'unset' }}
                          type="textarea"
                          value={get(data, 'description')}
                          wrapperClassName="mb10"
                        />
                        <Row gutterVertical className="mb10">
                          <Col grid="half">
                            <Select
                              textLabel="Mức độ ưu tiên"
                              isShowArrowDropdown={true}
                              dataSource={toPairs(priorities).map(
                                ([value, label]) => ({ value, label })
                              )}
                              disabled={fetching}
                              inputIconClassName="icon-flag"
                              onChange={changeField('TASK', id, 'priority')}
                              placeholder="Chọn mức độ ưu tiên"
                              value={get(data, 'priority')}
                            />
                          </Col>
                          <Col grid="half">
                            <RemindSelect
                              textLabel="Thời gian nhắc việc"
                              inputIconClassName="icon2-date-frames"
                              deadline={get(data, 'deadline')}
                              disabled={fetching}
                              onChange={changeField('TASK', id, 'workReminder')}
                              value={get(data, 'workReminder')}
                              placeholder="Thời gian nhắc việc"
                              popoverPosition={Position.BOTTOM_RIGHT}
                            />
                          </Col>
                        </Row>

                        <CheckboxSelect
                          textLabel="Loại công việc"
                          disabled={fetching}
                          inputClearable
                          inputIconClassName="icon2-tag"
                          inputWrapperClassName="mb10"
                          placeholder="Loại công việc"
                          popoverPosition={Position.TOP_LEFT}
                          onChange={changeField('TASK', id, 'tags')}
                          dataSource={tags.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))}
                          value={get(data, 'tags', [])}
                        />

                        {!!size(get(data, 'referenceFiles', [])) && (
                          <>
                            <label className="cpc-detail-label">
                              File công văn
                            </label>
                            <FileGroup list={get(data, 'referenceFiles')} />
                          </>
                        )}

                        {!!size(get(data, 'attachmentFiles', [])) &&
                          !!size(
                            attachmentFilesFromDocument(
                              get(data, 'attachmentFiles', []),
                              true
                            )
                          ) && (
                            <>
                              <label className="cpc-detail-label">
                                File đính kèm từ công văn
                              </label>
                              <FileGroup
                                list={attachmentFilesFromDocument(
                                  get(data, 'attachmentFiles', []),
                                  true
                                )}
                                onClose={removeAttachmentFiles(id)}
                              />
                            </>
                          )}

                        {((!!size(get(data, 'attachmentFiles', [])) &&
                          !!size(
                            attachmentFilesFromDocument(
                              get(data, 'attachmentFiles', []),
                              false
                            )
                          )) ||
                          !!size(files[id])) && (
                          <>
                            <label className="cpc-detail-label">
                              File đính kèm
                            </label>
                            <FileGroup
                              list={attachmentFilesFromDocument(
                                get(data, 'attachmentFiles', []),
                                false
                              )}
                              listUpload={files[id]}
                              onClose={removeAttachmentFiles(id)}
                              showClose
                            />
                          </>
                        )}

                        <Upload
                          textLabel="File đính kèm"
                          accept={documentTypes.toString()}
                          fileList={get(files, id, [])}
                          wrapperClassName="mb10"
                          textSumary={
                            <p className="mt5 font-style-italic font-size-12">
                              Tải file có dung lượng tối đa 100MB, định dạng
                              .pdf, .docs, .doc, .xls, .xlsx, .ppt, .pptx
                            </p>
                          }
                          isShowList={false}
                          disabled={fetching}
                          allowFileTypes={allowDocumentFileTypes}
                          onChange={changeFile('TASK', id)}
                        />
                      </Collapse>

                      <Button
                        className={classNames(
                          Classes.MINIMAL,
                          'cpc-button',
                          'cpc-form-button-left'
                        )}
                        intent={Intent.PRIMARY}
                        onClick={toggleCollapseExtra(id)}
                      >
                        {showExtra ? 'Thu gọn' : 'Mở rộng'}
                        <Icon
                          classIcon={
                            showExtra ? 'icon2-arrow-up' : 'icon2-arrow-down'
                          }
                          className={classNames(
                            'ml5',
                            'size-icon-10',
                            'd-flex'
                          )}
                        />
                      </Button>
                    </Collapse>
                  </div>
                </Fragment>
              ))}
              {!isUpdate && (
                <div
                  style={{ backgroundColor: '#fff' }}
                  className={classNames('d-flex', 'pv10 ph10', 'border-top')}
                >
                  <Button
                    className={classNames(
                      Classes.MINIMAL,
                      'cpc-button',
                      'qlcv-green-color',
                      'cpc-form-button-left',
                      'font-size-13'
                    )}
                    onClick={addTask}
                  >
                    <Icon classIcon="icon-Plus" className="size-icon-12 mr5" />
                    <span>Thêm nội dung kết luận</span>
                  </Button>
                </div>
              )}
            </Collapse>
          </div>
        </main>
      </Scrollbar>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          backgroundColor: '#f8f9fb',
          boxShadow: '-1px 0px 4px rgba(0,0,0,0.1)',
        }}
      >
        <div className={classNames('element-center', 'pv10 ph10')}>
          <Button
            className={classNames(
              'cpc-button',
              'btn-cancel',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100',
              'mr15'
            )}
            disabled={isLoading}
            onClick={onClose}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={classNames(
              'cpc-button',
              'uppercase',
              'font-size-13',
              'ph10',
              'min-width-100'
            )}
            intent={Intent.PRIMARY}
            loading={isLoading}
            disabled={isLoading || isDisabledButtonDefault}
            onClick={isUpdate ? submitUpdate : submitCreate}
          >
            Lưu
            <Icon classIcon="icon-save" className="ml5" />
          </Button>
        </div>
      </div>

      <Dialog
        buttonUppercase
        isOpen={isShowDialog}
        cancelClassName="pl20 pr20"
        cancelText="Hủy"
        confirmClassName="pl20 pr20"
        confirmText="Xóa"
        onCancel={onCloseDialog}
        onConfirm={onDeleteTask}
        onClose={onCloseDialog}
        title={CONFIRM_TITLE}
        textCenter
      >
        <Card className="mt20 mb20">{CONFIRM_CONTENT}</Card>
      </Dialog>
    </div>
  )
}

MeetingForm.propTypes = {
  dataLanhDaoDonVi: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    dataLanhDaoDonVi: get(state, 'common.dataLanhDaoDonVi'),
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ searchFileCongVanV2 }, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(MeetingForm))
)
