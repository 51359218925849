import React, { Component } from 'react'
import { Collapse, Radio } from '@blueprintjs/core'
import { PropTypes } from 'prop-types'
import { NotFound, LoadingItem } from '../common/'
import classnames from 'classnames'
import { PAGE } from '../../constants/Api'
import async from 'async'

class ListUnitRadioGroup extends Component {
  constructor(props) {
    super(props)
    let isOpen = {}
    let isLoaded = {}
    let isLoading = {}
    if (props.data) {
      let tmp = []
      props.data.forEach(item => {
        tmp[item.donViId] = false
      })
      isOpen = tmp
      isLoaded = tmp
      isLoading = tmp
    }

    this.state = {
      departments: {},
      isOpen,
      isLoaded,
      isLoading,
      isLoadFirstTime: false,
    }

    this.handleGetDepartment = this.handleGetDepartment.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
  }

  static propTypes = {
    data: PropTypes.array,
    requestApi: PropTypes.func.isRequired,
    onCheckDepartment: PropTypes.func.isRequired,
    selectedList: PropTypes.array,
    selectedListObject: PropTypes.object,
    disabledUnitsList: PropTypes.array,
    mainUnitId: PropTypes.string,
    conditionField: PropTypes.string,
    conditionValue: PropTypes.any,
    disabledAll: PropTypes.bool,
    isShowUnitCode: PropTypes.bool,
  }

  async componentDidMount() {
    if (this.props.data && !this.state.isLoadFirstTime) {
      let state = this.state
      state.isLoadFirstTime = true
      this.props.data.forEach(item => {
        if (state.isOpen && state.isOpen[item.donViId] === undefined) {
          state.isOpen[item.donViId] = false
        }
        if (state.isLoaded && state.isLoaded[item.donViId] === undefined) {
          state.isLoaded[item.donViId] = false
        }
        if (state.isLoading && state.isLoading[item.donViId] === undefined) {
          state.isLoading[item.donViId] = false
        }
      })
      await this.setState(state)
    }

    // Get phòng ban của đơn vị đã được chọn khi chỉnh sửa
    if (this.props.selectedListObject) {
      const selectedListObject = this.props.selectedListObject
      const keyList = Object.keys(selectedListObject)
      async.eachSeries(keyList, async (donViId, callback) => {
        if (donViId && !this.state.isLoaded[donViId]) {
          await this.setState(
            {
              isLoading: {
                ...this.state.isLoading,
                [donViId]: true,
              },
              isOpen: {
                ...this.state.isOpen,
                [donViId]: true,
              },
              isLoaded: {
                ...this.state.isLoaded,
                [donViId]: true,
              },
            },
            async () => {
              await this.props
                .requestApi(PAGE, donViId)
                .then(res => {
                  let isLoading = this.state.isLoading
                  let departments = this.state.departments
                  if (!res.payload || res.error) {
                    isLoading = {
                      ...isLoading,
                      [donViId]: false,
                    }
                  }
                  if (
                    res.payload &&
                    res.payload.data &&
                    res.payload.data.result &&
                    res.payload.data.result.items
                  ) {
                    let dataResult = res.payload.data.result.items
                    if (
                      this.props.conditionField !== undefined &&
                      this.props.conditionValue !== undefined
                    ) {
                      dataResult = dataResult.filter(
                        item =>
                          item[this.props.conditionField] ===
                          this.props.conditionValue
                      )
                    }
                    departments = {
                      ...departments,
                      [donViId]: dataResult,
                    }
                    isLoading = {
                      ...isLoading,
                      [donViId]: false,
                    }
                  }
                  this.setState({ departments, isLoading })
                })
                .then(callback(null))
            }
          )
        }
      })
    }
  }

  componentWillReceiveProps = async nextProps => {
    if (
      nextProps.data &&
      nextProps.data !== this.props.data &&
      !this.state.isLoadFirstTime
    ) {
      let state = this.state
      state.isLoadFirstTime = true
      await nextProps.data.forEach(item => {
        if (state.isOpen && state.isOpen[item.donViId] === undefined) {
          state.isOpen[item.donViId] = false
        }
        if (state.isLoaded && state.isLoaded[item.donViId] === undefined) {
          state.isLoaded[item.donViId] = false
        }
        if (state.isLoading && state.isLoading[item.donViId] === undefined) {
          state.isLoading[item.donViId] = false
        }
      })
      await this.setState(state)
    }

    // Get phòng ban của đơn vị đã được chọn khi chỉnh sửa
    if (
      nextProps.selectedListObject &&
      nextProps.selectedListObject !== this.props.selectedListObject
    ) {
      const selectedListObject = nextProps.selectedListObject
      const keyList = Object.keys(selectedListObject)
      async.eachSeries(keyList, async (donViId, callback) => {
        if (donViId && !this.state.isLoaded[donViId]) {
          await this.setState(
            {
              isLoading: {
                ...this.state.isLoading,
                [donViId]: true,
              },
              isOpen: {
                ...this.state.isOpen,
                [donViId]: true,
              },
              isLoaded: {
                ...this.state.isLoaded,
                [donViId]: true,
              },
            },
            async () => {
              await this.props
                .requestApi(PAGE, donViId)
                .then(res => {
                  let isLoading = this.state.isLoading
                  let departments = this.state.departments
                  if (!res.payload || res.error) {
                    isLoading = {
                      ...isLoading,
                      [donViId]: false,
                    }
                  }
                  if (
                    res.payload &&
                    res.payload.data &&
                    res.payload.data.result &&
                    res.payload.data.result.items
                  ) {
                    let dataResult = res.payload.data.result.items
                    if (
                      nextProps.conditionField !== undefined &&
                      nextProps.conditionValue !== undefined
                    ) {
                      dataResult = dataResult.filter(
                        item =>
                          item[nextProps.conditionField] ===
                          nextProps.conditionValue
                      )
                    }
                    departments = {
                      ...departments,
                      [donViId]: dataResult,
                    }
                    isLoading = {
                      ...isLoading,
                      [donViId]: false,
                    }
                  }
                  this.setState({ departments, isLoading })
                })
                .then(callback(null))
            }
          )
        }
      })
    }
  }

  handleGetDepartment = async donViId => {
    let isLoaded = this.state.isLoaded
    let isLoading = this.state.isLoading
    let departments = this.state.departments
    if (isLoading && Object.values(isLoading).find(item => item === true)) {
      return
    }
    await this.setState({
      isOpen: {
        ...this.state.isOpen,
        [donViId]: !this.state.isOpen[donViId],
      },
    })

    if (
      donViId &&
      isLoaded[donViId] !== true &&
      this.state.isOpen[donViId] === true
    ) {
      isLoading = {
        ...isLoading,
        [donViId]: true,
      }
      await this.setState({ isLoading })
      await this.props
        .requestApi(PAGE, donViId)
        .then(res => {
          if (!res.payload || res.error) {
            isLoading = {
              ...isLoading,
              [donViId]: false,
            }
          }
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.items
          ) {
            let dataResult = res.payload.data.result.items
            if (
              this.props.conditionField !== undefined &&
              this.props.conditionValue !== undefined
            ) {
              dataResult = dataResult.filter(
                item =>
                  item[this.props.conditionField] === this.props.conditionValue
              )
            }
            departments = {
              ...departments,
              [donViId]: dataResult,
            }
            isLoading = {
              ...isLoading,
              [donViId]: false,
            }
            isLoaded = {
              ...isLoaded,
              [donViId]: true,
            }
          }
        })
        .catch(e => {
          isLoading = {
            ...isLoading,
            [donViId]: false,
          }
        })
      this.setState({ isLoading, isLoaded, departments })
    }
  }

  handleCheck = (donViId, phongBanId) => {
    this.props.onCheckDepartment &&
      this.props.onCheckDepartment({
        donViId,
        phongBanId,
      })
  }

  render() {
    const isLoadingAll =
      this.state.isLoading &&
      Object.values(this.state.isLoading).find(item => item === true) !==
        undefined
    return (
      <div className="wrapper-panel-collapse">
        {this.props.title && (
          <div className="heading-group-panel">{this.props.title}</div>
        )}
        {this.props.data &&
          this.props.data.length > 0 &&
          this.props.data.map((item, index) => {
            if (!item) {
              return null
            }
            return (
              <div className="panel panel-item" key={index}>
                <div
                  className="panel-heading"
                  onClick={this.handleGetDepartment.bind(this, item.donViId)}
                  disabled={isLoadingAll}
                >
                  <h3 className="panel-title">
                    <span
                      className={classnames('pull-left icon-dropdown-panel', {
                        'icon-arrow-dropup': this.state.isOpen[item.donViId],
                        'icon-arrow-dropdown': !this.state.isOpen[item.donViId],
                      })}
                    ></span>
                    {item.tenVietTat}
                    {this.props.isShowUnitCode && (
                      <span className="text-normal">
                        {` (${
                          item.maKySo
                            ? item.maKySo
                            : item.maDonVi && item.maDonVi.toLowerCase()
                        })`}
                      </span>
                    )}
                  </h3>
                </div>
                <Collapse isOpen={this.state.isOpen[item.donViId]}>
                  <div className="panel-body">
                    <div className="selectbox-group">
                      <div className="row checkboxgroup-container">
                        {this.state.isLoading &&
                          this.state.isLoading[item.donViId] === true && (
                            <div className="col-xs-12 col-sm-12 col-md-12">
                              <div className="loading-container">
                                <LoadingItem />
                                <LoadingItem />
                              </div>
                            </div>
                          )}
                        {this.state.isLoading &&
                          this.state.isLoading[item.donViId] === false &&
                          this.state.departments &&
                          this.state.departments[item.donViId] &&
                          this.state.departments[item.donViId].map(
                            (subItem, subIndex) => (
                              <div
                                className="col-xs-6 col-sm-6 col-md-3"
                                key={subIndex}
                              >
                                <div className="item-checkbox disabled-checkbox">
                                  <Radio
                                    className="pt-inline"
                                    checked={
                                      this.props.selectedList &&
                                      this.props.selectedList.indexOf(
                                        subItem.phongBanId
                                      ) > -1
                                    }
                                    disabled={
                                      (this.props.disabledUnitsList &&
                                        this.props.disabledUnitsList.indexOf(
                                          item.donViId
                                        ) > -1) ||
                                      this.props.disabledAll
                                    }
                                    onChange={this.handleCheck.bind(
                                      this,
                                      item.donViId,
                                      subItem.phongBanId
                                    )}
                                  >
                                    <span className="name_checkbox">
                                      {subItem.maPhongBan}
                                    </span>
                                  </Radio>
                                </div>
                              </div>
                            )
                          )}
                        {this.state.isLoading &&
                          this.state.isLoading[item.donViId] === false &&
                          this.state.isOpen &&
                          this.state.isOpen[item.donViId] === true &&
                          (!this.state.departments ||
                            !this.state.departments[item.donViId] ||
                            !this.state.departments[item.donViId].length) && (
                            <div className="col-xs-12 col-sm-12 col-md-12">
                              <NotFound />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            )
          })}
        {(!this.props.data || this.props.data.length === 0) &&
          !isLoadingAll && (
            <div className="col-xs-12 col-sm-12 col-md-12">
              <NotFound />
            </div>
          )}
      </div>
    )
  }
}

export default ListUnitRadioGroup
