import React, { memo, useMemo } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import moment from 'moment'
import { Popover, Position, Button } from '@blueprintjs/core'

import { getCounterCongViecPhongBanCaNhan } from 'actions/index'
import {
  ASSIGN_TYPE,
  WORK_STATUS,
  WORK_APPROVED,
  RESPONSIBILITY_TYPE,
  WORK_TYPE,
} from 'constants/Enum'
import Fragment from 'components/common/Fragment'
import { MasterLayout } from 'components/layout'
import GoBackButton from 'components/Button/GoBackButton'
import {
  StatusDate,
  StatusTag,
  PrioritizeFlag,
  UserCard,
  CollapseMore,
  AvatarGroup,
  FileGroup,
  TagGroup,
  Loading,
  Icon as Icon2,
  SliderBar,
} from 'components/newCommon'
import { Progress } from 'components/newCommon2'
import ToDoList from './Details/ToDoList'
import Report from './Details/Report'
import Comment from './Details/Comment'
import TypeIcons from './components/type-icon'
import Menu from './components/menu'
import Approve from './components/confirm/approve'
import DeleteTask from './components/confirm/delete-task'
import Reminder from './components/form/reminder'
import GiveTask from './components/form/give-task'
import AddTags from './components/form/add-tags'
import AcceptTask from './components/form/accept-task'
import AcceptHistory from './components/detail/accept-history'
import FlowTask from './components/detail/flow-task/index'
import useTaskDetail from './hooks/use-task-detail'

const TaskDetail = ({
  actions,
  auth,
  match,
  history,
  roleId,
  mainDepartmentId,
  mainUnitId,
  dataTree,
}) => {
  const dateFormat = 'DD/MM/YYYY'
  const {
    pathAction,
    main,
    isFetching,
    isDisabled,
    isDisabledDefault,
    deadline,
    executor,
    percent,
    followers,
    detailData,
    informationWorkItemAction,
    coordinations,
    mentionData,
    menuActive,
    fetchInformationWorkItemAction,
    fetchDetail,
    setPercent,
    setIsDisabledDefault,
    setMenuActive,
    onChangeFile,
    onProgress,
    onMenu,
  } = useTaskDetail({
    actions,
    history,
    match,
    roleId,
    mainDepartmentId,
    mainUnitId,
  })

  const changeProgress = (percent = 0) => {
    setIsDisabledDefault(false)
    setPercent(percent)
  }

  const displayHtml = (data, type) => {
    return (
      <div className={'border-top pt5 mt10'}>
        <div className="mb10">
          <label className={'cpc-detail-label mb5'}>
            {type === 'assigner' ? 'Người giao' : 'Người tạo'}
          </label>
          <UserCard
            needBaseUrl
            src={data?.user?.avatar}
            name={data?.user?.name}
            department={data?.user?.department}
            unit={data?.user?.unit}
          />
        </div>
        <div className={'d-flex'}>
          {data?.creationTime && type === 'creator' && (
            <span style={{ minWidth: '33.33%' }}>
              <label className="cpc-detail-label">Ngày tạo:</label>
              <span className="cpc-detail-text">{data?.creationTime}</span>
            </span>
          )}
          {data?.assignDate && type === 'assigner' && (
            <span style={{ minWidth: '33.33%' }}>
              <label className="cpc-detail-label">Ngày giao:</label>
              <span className="cpc-detail-text">{data?.assignDate}</span>
            </span>
          )}
          {data?.executionDate && (
            <span style={{ minWidth: '33.33%' }}>
              <label className="cpc-detail-label">Ngày bẳt đầu:</label>
              <span className="cpc-detail-text">{data?.executionDate}</span>
            </span>
          )}
          {data?.deadline && (
            <span style={{ minWidth: '33.33%' }}>
              <label className="cpc-detail-label">Hạn thực hiện:</label>
              <span className="cpc-detail-text">{data?.deadline}</span>
            </span>
          )}
        </div>
        {data?.description && (
          <div className="mb10">
            <label className="cpc-detail-label">Mô tả</label>
            <p className="cpc-detail-norm">{data?.description}</p>
          </div>
        )}
      </div>
    )
  }

  const renderCreator = data => {
    if (!data) {
      return
    }

    const {
      creator,
      assigner,
      creatorProfileId,
      creationTime,
      executionDate,
      deadline,
      description,
    } = data
    if (assigner?.id === creatorProfileId) {
      return ''
    }

    return displayHtml(
      {
        user: {
          avatar: creator?.avatar,
          name: creator?.fullName,
          department: creator?.department?.shortName,
          unit: creator?.organization?.shortName,
        },
        creationTime:
          creationTime && moment(creationTime).isValid()
            ? moment(creationTime).format(dateFormat)
            : null,
        executionDate:
          executionDate && moment(executionDate).isValid()
            ? moment(executionDate).format(dateFormat)
            : null,
        deadline:
          deadline && moment(deadline).isValid()
            ? moment(deadline).format(dateFormat)
            : null,
        description: description,
      },
      'creator'
    )
  }

  const renderAssigner = data => {
    if (!data) {
      return
    }

    let masterData = data
    if (data?.assigner?.id !== data?.creatorProfileId) {
      const dataGet = data?.responsibilities?.find(
        elm =>
          elm?.workAssignments?.[0] && (elm?.workAssignments?.length ?? 0) > 0
      )
      masterData = dataGet?.workAssignments?.[0]
    }

    const assigner = masterData?.assigner
    const executionDate = masterData?.executionDate
    const assignDate = masterData?.assignDate
    const deadline = masterData?.deadline
    const description = masterData?.description
    return displayHtml(
      {
        user: {
          avatar: assigner?.avatar,
          name: assigner?.name,
          department: assigner?.information?.department?.shortName,
          unit: assigner?.information?.organization?.shortName,
        },
        assignDate:
          assignDate && moment(assignDate).isValid()
            ? moment(assignDate).format(dateFormat)
            : null,
        executionDate:
          executionDate && moment(executionDate).isValid()
            ? moment(executionDate).format(dateFormat)
            : null,
        deadline:
          deadline && moment(deadline).isValid()
            ? moment(deadline).format(dateFormat)
            : null,
        description: description,
      },
      'assigner'
    )
  }

  const renderApproval = useMemo(
    () => data => {
      if (data?.approval === WORK_APPROVED.WAITING_APPROVAL) {
        return data?.approval
      }

      return data?.status
    },
    []
  )

  const renderStatus = useMemo(
    () => data => {
      if (
        data?.status === WORK_STATUS.HOAN_THANH &&
        data?.approval === WORK_APPROVED.APPROVED
      ) {
        return data?.approval
      }

      return data?.status
    },
    []
  )

  const renderType = useMemo(() => {
    if (!detailData?.responsibilityType) {
      return false
    }

    switch (detailData?.responsibilityType) {
      case RESPONSIBILITY_TYPE.CHU_TRI:
        return 'Chủ trì'
      case RESPONSIBILITY_TYPE.PHOI_HOP:
        return 'Phối hợp'
      case RESPONSIBILITY_TYPE.THEO_DOI:
        return 'Theo dõi'
      default:
        break
    }
  }, [detailData])

  const isAssignByMe = useMemo(() => {
    return detailData?.assigner?.id === roleId
  }, [detailData, roleId])

  const isShowExecutor = useMemo(() => {
    if (
      main &&
      (main?.length ?? 0) > 0 &&
      detailData?.responsibilityType !== RESPONSIBILITY_TYPE.CHU_TRI &&
      !isAssignByMe
    ) {
      return true
    } else {
      return false
    }
  }, [main, detailData, isAssignByMe])

  const isShowChuTriPhoiHopTheoDoi = useMemo(() => {
    if (
      isShowExecutor ||
      (followers?.length ?? 0) > 0 ||
      (coordinations?.length ?? 0) > 0
    ) {
      return true
    } else {
      return false
    }
  }, [isShowExecutor, followers, coordinations])

  const attachmentFilesFromDocument = useMemo(() => {
    return (detailData?.files ?? []).filter(
      elm => elm.application === 'eOffice'
    )
  }, [detailData])

  const attachmentFilesNotFromDocument = useMemo(() => {
    return (detailData?.files ?? []).filter(
      elm => elm.application !== 'eOffice'
    )
  }, [detailData])

  const isShowAttachmentFiles = useMemo(() => {
    return (detailData?.referenceFile?.length ?? 0) > 0 ||
      (attachmentFilesFromDocument?.length ?? 0) > 0 ||
      (attachmentFilesNotFromDocument?.length ?? 0) > 0
      ? true
      : false
  }, [detailData, attachmentFilesFromDocument, attachmentFilesNotFromDocument])

  const actionDialogBlock = useMemo(() => {
    const actionOverlays = {
      HUY_DUYET: Approve,
      XOA_CONG_VIEC: DeleteTask,
    }

    const ActionOverlay = actionOverlays[menuActive?.keyName]
    if (!ActionOverlay) return <></>
    return (
      <ActionOverlay
        history={history}
        actionKey={menuActive?.keyName}
        dataSource={detailData}
        onSuccess={() => {
          fetchDetail()
          fetchInformationWorkItemAction()
        }}
        onClose={() => setMenuActive(null)}
      />
    )
  }, [
    history,
    detailData,
    menuActive,
    setMenuActive,
    fetchDetail,
    fetchInformationWorkItemAction,
  ])

  const actionBlock = useMemo(() => {
    const actionOverlays = {
      GIAO_VIEC: GiveTask,
      SUA_GIAO_VIEC: GiveTask,
      GIAO_VIEC_DOFFICE: GiveTask, // DO
      SUA_GIAO_VIEC_DOFFICE: GiveTask, // DO
      NHAC_VIEC: Reminder,
      GAN_TAGS: AddTags,
      LICH_SU_XAC_NHAN: AcceptHistory,
      LUONG_CONG_VIEC: FlowTask,
      XAC_NHAN: AcceptTask,
    }

    const ActionOverlay = actionOverlays[menuActive?.keyName]
    if (!ActionOverlay) return <></>
    return (
      <ActionOverlay
        history={history}
        actionKey={menuActive?.keyName}
        auth={auth}
        dataTree={dataTree}
        dataSource={detailData}
        onSuccess={() => {
          fetchDetail()
          fetchInformationWorkItemAction()
        }}
        onClose={() => setMenuActive(null)}
      />
    )
  }, [
    history,
    detailData,
    menuActive,
    auth,
    dataTree,
    setMenuActive,
    fetchDetail,
    fetchInformationWorkItemAction,
  ])

  const rightMenuData = useMemo(() => {
    const isEdit = informationWorkItemAction?.assigned
    const isDoffice = detailData?.workType === WORK_TYPE.DOFFICE
    const allowChange =
      detailData?.status === WORK_STATUS.CHUA_THUC_HIEN &&
      detailData?.creatorProfileId === roleId &&
      detailData?.workType !== WORK_TYPE.MEETING

    return [
      ...(detailData?.status === WORK_STATUS.CHUA_THUC_HIEN &&
      pathAction !== 'theo-doi' &&
      informationWorkItemAction?.assigned !== null
        ? [
            {
              keyName: isEdit
                ? isDoffice
                  ? 'SUA_GIAO_VIEC_DOFFICE'
                  : 'SUA_GIAO_VIEC'
                : isDoffice
                ? 'GIAO_VIEC_DOFFICE'
                : 'GIAO_VIEC',
              name: isEdit ? 'Sửa giao việc' : 'Giao việc',
              icon: 'icon2-give-task font-size-15',
            },
          ]
        : []),
      {
        keyName: 'LUONG_CONG_VIEC',
        name: 'Luồng công việc',
        icon: 'icon2-node font-size-14',
      },
      {
        keyName: 'LICH_SU_XAC_NHAN',
        name: 'Lịch sử xác nhận',
        icon: 'icon-thoigianluu font-size-15',
      },
      {
        keyName: 'NHAC_VIEC',
        name: 'Nhắc việc',
        icon: 'icon2-bell font-size-15',
      },
      {
        keyName: 'GAN_TAGS',
        name: 'Loại công việc (tags)',
        icon: 'icon2-tag font-size-15',
      },
      ...(pathAction === 'da-giao' &&
      informationWorkItemAction?.showCancelApprove &&
      detailData?.approval === WORK_APPROVED.APPROVED
        ? [
            {
              keyName: 'HUY_DUYET',
              name: 'Hủy duyệt',
              icon: 'icon-Huy font-size-12',
            },
          ]
        : []),
      ...(informationWorkItemAction?.isAccept
        ? [
            {
              keyName: 'XAC_NHAN',
              name: 'Xác nhận',
              icon: 'icon-Bao_Cao font-size-12',
            },
          ]
        : []),
      ...(allowChange
        ? [
            {
              keyName: 'SUA_CONG_VIEC',
              name: 'Sửa công việc',
              icon: 'icon-but font-size-13',
            },
          ]
        : []),
      ...(detailData?.status === WORK_STATUS.CHUA_THUC_HIEN &&
      detailData.creatorProfileId === roleId &&
      pathAction !== 'theo-doi' &&
      detailData?.workType !== WORK_TYPE.MEETING
        ? [
            {
              keyName: 'XOA_CONG_VIEC',
              name: 'Xóa công việc',
              icon: 'icon-bin font-size-13',
              className: 'color-red-important',
            },
          ]
        : []),
    ]
  }, [roleId, detailData, pathAction, informationWorkItemAction])

  const isShowMenu = useMemo(() => {
    if (
      !menuActive ||
      (menuActive &&
        ![
          'GIAO_VIEC',
          'SUA_GIAO_VIEC',
          'GIAO_VIEC_DOFFICE',
          'SUA_GIAO_VIEC_DOFFICE',
          'NHAC_VIEC',
          'GAN_TAGS',
          'LICH_SU_XAC_NHAN',
          'LUONG_CONG_VIEC',
          'XAC_NHAN',
          'XOA_CONG_VIEC',
        ].some(s => s === menuActive?.keyName))
    ) {
      return true
    }

    return false
  }, [menuActive])

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="task-manager-detail-layout mt5">
        <GoBackButton />
        <div className="task-manager-detail-block mt15">
          <div className="left p15">
            {isFetching ? (
              <Loading />
            ) : (
              <div>
                <div className="border pt15 pr15 pl15">
                  {detailData?.workType ? (
                    <TypeIcons
                      workType={detailData?.workType}
                      isDaLuuTuPhongBan={detailData?.isDaLuuTuPhongBan}
                      isShowName={true}
                    />
                  ) : null}
                  <h1 className="font-size-14 font-weight-600">
                    {detailData?.title}
                  </h1>
                  {detailData?.workUserTags &&
                  detailData?.workUserTags?.length !== 0 ? (
                    <TagGroup
                      className="gap-5 mt5"
                      tags={detailData.workUserTags.map(
                        ({ workTagName, backgroundColor }) => ({
                          name: workTagName,
                          color: backgroundColor,
                        })
                      )}
                      popoverPosition={Position.BOTTOM_RIGHT}
                    />
                  ) : null}
                  {detailData?.requireReport && (
                    <p className="d-flex align-center gap-5 mt5">
                      <Icon2
                        classIcon="icon-Bao_Cao"
                        className="font-size-10"
                      />
                      <span className="font-size-13">
                        Yêu cầu báo cáo Hoàn thành
                      </span>
                    </p>
                  )}
                  <div className={'d-flex'}>
                    {(deadline ||
                      (detailData?.finishedDate &&
                        detailData?.status === 'DONE')) && (
                      <span className="cpc-detail-left" style={{ flex: 1 }}>
                        {deadline && (
                          <div>
                            <label className="cpc-detail-label">
                              Hạn thực hiện:
                            </label>
                            <StatusDate
                              className="cpc-detail-text"
                              date={deadline}
                              descriptionInline
                              showDescription={false}
                              isCompleted={detailData?.status === 'DONE'}
                            />
                          </div>
                        )}
                        {detailData?.finishedDate &&
                          detailData?.status === 'DONE' && (
                            <div>
                              <label className="cpc-detail-label">
                                Ngày hoàn thành:
                              </label>
                              <StatusDate
                                className="cpc-detail-text"
                                date={detailData?.finishedDate}
                                descriptionInline
                                isCompleted
                              />
                            </div>
                          )}
                      </span>
                    )}
                    <span className="cpc-detail-right">
                      {detailData?.approval ===
                        WORK_APPROVED.WAITING_APPROVAL && (
                        <StatusTag type={renderApproval(detailData)} />
                      )}
                      <StatusTag
                        type={renderStatus(detailData)}
                        className="ml25"
                      />
                      <PrioritizeFlag
                        type={detailData?.priority}
                        className="ml10"
                      />
                    </span>
                  </div>
                  <div className={'d-flex border-top pt5 mt10'}>
                    <div className="cpc-detail-left">
                      <div>
                        {isAssignByMe ? (
                          <span
                            className="text-uppercase font-size-10"
                            style={{ color: '#738a95' }}
                          >
                            Chủ trì
                          </span>
                        ) : (
                          <div>
                            <label className="cpc-detail-label">
                              Thực hiện:
                            </label>
                            <span
                              className={classNames(
                                'text-uppercase font-size-10 font-weight-600',
                                {
                                  'text-blue':
                                    detailData?.responsibilityType ===
                                      'MAIN_RESPONSIBILITY' &&
                                    detailData?.isMainResponsibility,
                                }
                              )}
                            >
                              {renderType}
                            </span>
                          </div>
                        )}
                      </div>
                      {executor && executor?.length !== 0 && (
                        <div className="mt10">
                          {executor?.type === ASSIGN_TYPE.CA_NHAN ? (
                            <UserCard
                              needBaseUrl
                              src={executor?.avatar}
                              name={executor?.name}
                              department={
                                executor?.information?.department?.shortName
                              }
                              unit={
                                executor?.information?.organization?.shortName
                              }
                            />
                          ) : (
                            <span className="uppercase font-weight-500 font-size-12">
                              {executor?.shortName}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    <span
                      className={classNames('cpc-detail-right', {
                        'has-action': true,
                      })}
                    >
                      <label
                        className={classNames(
                          'd-block',
                          'cpc-detail-label',
                          'mb5'
                        )}
                      >
                        Tiến độ
                      </label>
                      <Popover
                        content={
                          <div style={{ width: 355, padding: 10 }}>
                            <SliderBar
                              onRelease={changeProgress}
                              value={percent}
                            />
                          </div>
                        }
                        popoverClassName="cpc-popover no-arrow"
                        position={Position.BOTTOM_RIGHT}
                        isDisabled={informationWorkItemAction?.isLock}
                      >
                        <div style={{ width: 32 }}>
                          <Progress progress={percent} />
                        </div>
                      </Popover>
                    </span>
                  </div>
                  {isShowChuTriPhoiHopTheoDoi ? (
                    <div className={'border-top pt5 mt10'}>
                      <ul className="d-flex">
                        {isShowExecutor && (
                          <li className="pb10 mr30">
                            <div>
                              <label className={'cpc-detail-label mb5'}>
                                Chủ trì
                              </label>
                              <AvatarGroup
                                list={main
                                  ?.filter(e => e?.responsibility)
                                  ?.map(elm => elm?.responsibility)}
                                needBaseUrl
                                small
                              />
                            </div>
                          </li>
                        )}
                        {(coordinations?.length ?? 0) > 0 ? (
                          <li className="pb10 mr30">
                            <div>
                              <label className={'cpc-detail-label mb5'}>
                                Phối hợp
                              </label>
                              <AvatarGroup
                                list={coordinations
                                  ?.filter(e => e?.responsibility)
                                  ?.map(
                                    coordination => coordination?.responsibility
                                  )}
                                needBaseUrl
                                small
                              />
                            </div>
                          </li>
                        ) : null}
                        {(followers?.length ?? 0) > 0 ? (
                          <li className="pb10">
                            <div>
                              <label className="cpc-detail-label">
                                Theo dõi
                              </label>
                              <AvatarGroup
                                list={followers
                                  ?.filter(e => e?.responsibility)
                                  ?.map(follower => follower?.responsibility)}
                                needBaseUrl
                                small
                                position={Position.TOP_RIGHT}
                              />
                            </div>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  ) : null}
                  <CollapseMore
                    textShow="Xem thêm"
                    textHide="Thu gọn"
                    limitDisplay={0}
                    buttonSpace={false}
                    className={'border-top mt10'}
                    // defaultOpen
                  >
                    {renderCreator(detailData)}
                    {renderAssigner(detailData)}
                    {isShowAttachmentFiles && (
                      <div className={'border-top pt5 mt10'}>
                        {(detailData?.referenceFile?.length ?? 0) > 0 && (
                          <div>
                            <label className="cpc-detail-label">
                              File công văn
                            </label>
                            <FileGroup
                              list={detailData.referenceFile}
                              onChange={onChangeFile}
                            />
                          </div>
                        )}
                        {(attachmentFilesFromDocument?.length ?? 0) > 0 && (
                          <div>
                            <label className="cpc-detail-label">
                              File đính kèm từ công văn
                            </label>
                            <FileGroup
                              list={attachmentFilesFromDocument}
                              onChange={onChangeFile}
                            />
                          </div>
                        )}
                        {(attachmentFilesNotFromDocument?.length ?? 0) > 0 && (
                          <div>
                            <label className="cpc-detail-label">
                              File đính kèm
                            </label>
                            <FileGroup list={attachmentFilesNotFromDocument} />
                          </div>
                        )}
                      </div>
                    )}
                  </CollapseMore>
                </div>
                <Fragment>
                  <ToDoList
                    allowAction={!informationWorkItemAction?.isLock}
                    className={'mt10'}
                  />
                  <Report
                    allowAction={!informationWorkItemAction?.isLock}
                    className={'mt10'}
                  />
                  <Comment users={mentionData} className={'mt10'} />
                </Fragment>
              </div>
            )}
          </div>
          <div className="right">
            {isShowMenu && (
              <h2 className="text-uppercase text-13-500 color-gray p15">
                THỰC HIỆN
              </h2>
            )}
            {isShowMenu && (
              <Menu
                history={history}
                dataSource={rightMenuData}
                onClick={onMenu}
              />
            )}
            {actionBlock}
            {isShowMenu && !informationWorkItemAction?.isLock ? (
              <div className="p15 d-flex align-items-center justify-content-center border-top">
                <Button
                  className={'cpc-button blue ph10 min-width-80'}
                  disabled={isDisabled || isDisabledDefault}
                  onClick={onProgress}
                >
                  <Icon2 classIcon="icon-save" className="mr5" />
                  <span className="uppercase font-size-13">Lưu tiến độ</span>
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        {actionDialogBlock}
      </div>
    </MasterLayout>
  )
}

TaskDetail.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  roleId: PropTypes.string,
  mainDepartmentId: PropTypes.string,
  mainUnitId: PropTypes.string,
  dataTree: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    roleId: state.auth?.roleId,
    mainDepartmentId: state.auth?.mainDepartmentId,
    mainUnitId: state.auth?.mainUnitId,
    dataTree: state.common?.dataTree,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCounterCongViecPhongBanCaNhan,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(TaskDetail))
)
