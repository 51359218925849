import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingPage } from '../../components/common'
import {
  ListFilter,
  ListItemUnit,
  ListItemDepartment,
  ListItemPersonal,
  ListItemPersonalInChildUnit,
  TableView,
} from '../../components/Utilities/StatisticalSignNumber/'
import { Intent } from '@blueprintjs/core'
import { headers } from '../../constants/Table'
import { connect } from 'react-redux'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import * as Types from '../../constants/Api'
import * as MESSAGE from '../../constants/MessageForm'
import FileDownload from 'file-saver'
import moment from 'moment'
import * as Tool from '../../helpers'
import { Authorization, Permission } from '../../components/auth'

class ListStatisticalSignNumberPage extends React.Component {
  constructor(props) {
    super(props)

    let isAdmin = Authorization.vaild(this.props.auth.permission, [
      Permission.ADMIN,
    ])

    this.state = {
      filter: {
        startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        loaiCongVan: Types.TYPE_ALL_SIGN_NUMBER,
      },
      page: 1,
      isShowMessengStatistics: true,
      exporting: {
        units: false,
        departments: false,
        personal: false,
        personalInChildUnits: false,
      },
      isLoading: {
        units: false,
        departments: false,
        personal: false,
        personalInChildUnits: false,
      },
      isEmpty: {
        units: false,
        departments: false,
        personal: false,
        personalInChildUnits: false,
      },
      isError: {
        units: false,
        departments: false,
        personal: false,
        personalInChildUnits: false,
      },
      isAdmin,
    }

    this.submitFilter = this.submitFilter.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.convertTypeStatisticToString =
      this.convertTypeStatisticToString.bind(this)
  }

  componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData(
      'ListStagisticalNumber',
      null,
      'thong-ke-ky-so'
    )

    if (preData) {
      state.filter = preData.filter
      this.setState(state)
    }

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isCheck
    ) {
      return this.submitFilter(this.state.filter)
    }

    let isSubmitStatisticSignNumber = Tool.Utils.getData(
      'isSubmitStatisticSignNumber'
    )
    if (isSubmitStatisticSignNumber) {
      Tool.Utils.saveData('isSubmitStatisticSignNumber', false)
      this.props.history.replace({
        state: {
          isCheck: true,
        },
      })
      this.submitFilter(this.state.filter)
    }
  }

  submitFilter(e = {}) {
    let state = this.state
    state.filter = Object.assign({}, state.filter, e)
    state.isShowMessengStatistics = false
    state.isLoading = {
      units: true,
      departments: true,
      personal: true,
      personalInChildUnits: state.isAdmin,
    }

    const apiRequests = [
      {
        request: this.props.actions.getStatisticsSignNumberUnits,
        type: 'units',
        isAllowed: true,
      },
      {
        request: this.props.actions.getStatisticsSignNumberDepartments,
        type: 'departments',
        isAllowed: true,
      },
      {
        request: this.props.actions.getStatisticsSignNumberPersonal,
        type: 'personal',
        isAllowed: true,
      },
      {
        request: this.props.actions.getStatisticsSignNumberPersonalInChildUnits,
        type: 'personalInChildUnits',
        isAllowed: state.isAdmin,
      },
    ]

    this.setState(state, () => {
      const filter = Object.assign({}, this.state.filter, e)
      Tool.Utils.preData('ListStagisticalNumber', { filter }, 'thong-ke-ky-so')

      apiRequests.forEach(async item => {
        if (!item.isAllowed) {
          return
        }
        let request = item.request
        await request(this.state.filter, this.props.config.refreshStatistic)
          .then(res => {
            let isEmpty = state.isEmpty
            let isError = state.isError
            isEmpty[item.type] = false
            isError[item.type] = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty[item.type] = true
            }

            if (res.error || (res.payload && res.payload.status !== 200)) {
              isError[item.type] = true
            }

            this.setState({ isEmpty, isError })
          })
          .then(() => {
            this.props.actions.refreshStatistic(true)
            let isLoading = this.state.isLoading
            isLoading[item.type] = false
            this.setState({ isLoading })
          })
      })
    })
  }

  handleExport(type) {
    if (!type) {
      return
    }

    let { filter, exporting } = this.state
    if (
      !filter.startDate ||
      !filter.endDate ||
      filter.loaiCongVan === undefined ||
      filter.loaiCongVan === null
    ) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_TIME,
        intent: Intent.WARNING,
      })
    }

    exporting[this.convertTypeStatisticToString(type)] = true
    this.setState({ exporting }, () => {
      this.props.actions
        .exportStatisticsSignNumber(filter, type)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          let preData = Tool.Utils.getData('ListStagisticalNumber')
          FileDownload.saveAs(
            res.payload.data,
            `thong-ke-ky-so-${moment(preData.filter.startDate).format(
              'L'
            )}-${moment(preData.filter.endDate).format('L')}.xls`
          )
        })
        .then(() => {
          this.setState({
            exporting: {
              ...this.state.exporting,
              [this.convertTypeStatisticToString(type)]: false,
            },
          })
        })
    })
  }

  convertTypeStatisticToString = type => {
    switch (type) {
      case Types.TYPE_STATISTIC_SIGN_NUMER_UNITS:
        return 'units'

      case Types.TYPE_STATISTIC_SIGN_NUMER_DEPARTMENTS:
        return 'departments'

      case Types.TYPE_STATISTIC_SIGN_NUMER_PERSONAL_IN_CHILD_UNITS:
        return 'personalInChildUnits'

      default:
        return 'personal'
    }
  }

  render() {
    const isLoading =
      Object.values(this.state.isLoading).findIndex(item => item === true) > -1
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container page-statistic-signnumber">
          {/* Heading */}
          <HeadingPage
            namePage="Thống kê ký số"
            iconPage="icon-Tong_Hop"
          ></HeadingPage>
          {/* Table List */}
          <div className="page-list-container">
            <ListFilter
              onSubmit={this.submitFilter}
              readOnly={this.props.readOnly}
              actions={this.props.actions}
              isLoading={isLoading}
              filter={this.state.filter}
            />

            {this.state.isShowMessengStatistics && (
              <div className="table-container">
                <div className="no-border-bt">
                  <div className="error-table-hover">
                    <div className="error-page-container">
                      <div className="error-content">
                        <img
                          src={process.env.PUBLIC_URL + '/images/calendar.png'}
                          alt="search-result-not-found"
                          className="img-thumb-error"
                        />
                        <p className="description-note">
                          {MESSAGE.MESSAGE_REQUIRED_STATISTICS}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!this.state.isShowMessengStatistics && (
              <TableView
                heading="Thống kê ký số theo đơn vị"
                page={this.state.page}
                headers={headers.generalSignNumberUnit}
                itemShape={ListItemUnit}
                data={this.props.statisticSignNumber.units.list || []}
                isLoading={this.state.isLoading.units}
                isEmpty={
                  this.state.isEmpty.units ||
                  this.props.statisticSignNumber.units.total === 0
                }
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError.units}
                type={Types.TYPE_STATISTIC_SIGN_NUMER_UNITS}
                isExporting={this.state.exporting.units}
                onExport={this.handleExport}
                colSpanLoading={10}
                actions={this.props.actions}
              />
            )}

            {!this.state.isShowMessengStatistics && (
              <TableView
                heading="Thống kê ký số theo phòng ban"
                page={this.state.page}
                headers={headers.generalSignNumberDepartment}
                itemShape={ListItemDepartment}
                data={this.props.statisticSignNumber.departments.list || []}
                isLoading={this.state.isLoading.departments}
                isEmpty={
                  this.state.isEmpty.departments ||
                  this.props.statisticSignNumber.departments.total === 0
                }
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError.departments}
                type={Types.TYPE_STATISTIC_SIGN_NUMER_DEPARTMENTS}
                isExporting={this.state.exporting.departments}
                onExport={this.handleExport}
                colSpanLoading={10}
                actions={this.props.actions}
              />
            )}

            {!this.state.isShowMessengStatistics && (
              <TableView
                heading="Thống kê ký số theo cá nhân"
                page={this.state.page}
                headers={headers.generalSignNumberPersonal}
                itemShape={ListItemPersonal}
                data={this.props.statisticSignNumber.personal.list || []}
                isLoading={this.state.isLoading.personal}
                isEmpty={
                  this.state.isEmpty.personal ||
                  this.props.statisticSignNumber.personal.total === 0
                }
                filter={this.state.filter}
                history={this.props.history}
                isError={this.state.isError.personal}
                type={Types.TYPE_STATISTIC_SIGN_NUMER_PERSONAL}
                isExporting={this.state.exporting.personal}
                onExport={this.handleExport}
                colSpanLoading={11}
                actions={this.props.actions}
              />
            )}

            {!this.state.isShowMessengStatistics &&
              this.state.isAdmin &&
              this.props.statisticSignNumber &&
              this.props.statisticSignNumber.personalInChildUnits &&
              this.props.statisticSignNumber.personalInChildUnits.total > 0 && (
                <TableView
                  heading="Thống kê ký số theo cá nhân của Điện lực"
                  page={this.state.page}
                  headers={headers.generalSignNumberPersonal}
                  itemShape={ListItemPersonalInChildUnit}
                  data={
                    this.props.statisticSignNumber.personalInChildUnits.list ||
                    []
                  }
                  isLoading={this.state.isLoading.personalInChildUnits}
                  isEmpty={
                    this.state.isEmpty.personalInChildUnits ||
                    this.props.statisticSignNumber.personalInChildUnits
                      .total === 0
                  }
                  filter={this.state.filter}
                  history={this.props.history}
                  isError={this.state.isError.personalInChildUnits}
                  type={Types.TYPE_STATISTIC_SIGN_NUMER_PERSONAL_IN_CHILD_UNITS}
                  isExporting={this.state.exporting.personalInChildUnits}
                  onExport={this.handleExport}
                  colSpanLoading={11}
                  actions={this.props.actions}
                />
              )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  statisticSignNumber: state.statisticSignNumber,

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.enableReadOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListStatisticalSignNumberPage)
