import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Redirect } from 'react-router-dom'
import { Checkbox } from '@blueprintjs/core'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { Intent } from '@blueprintjs/core'

import { DOCUMENTS_CABINET } from 'constants/Enum'
import { Authorization } from '../auth'

class ListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isRedirect: false,
      path: '',
      isLoading: false,
      isEnabled: this.props.checked,
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
    this.isOpen = this.isOpen.bind(this)
  }

  handleClick = () => {
    this.setState({
      isRedirect: true,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({
        isEnabled: this.props.checked,
      })
    }
  }

  handleEnabledChange = e => {
    this.setState({ isEnabled: !this.state.isEnabled }, () => {
      this.props.onCheck &&
        this.props.onCheck(this.state.isEnabled, this.props.item)
    })
  }

  setDocumentCabinetPath = () => {
    this.state.path &&
      this.props.actions.setDocumentCabinetPath(this.state.path)
  }

  isOpen() {
    if (
      !this.state.path ||
      (this.state.path && this.state.path.trim().length === 0)
    ) {
      this.setState({ isLoading: true }, () => {
        let loaiTuTaiLieu = this.props.type
        let nganTuId = this.props.item.nganTuDonViId
          ? this.props.item.nganTuDonViId
          : this.props.item.nganTuPhongBanId
          ? this.props.item.nganTuPhongBanId
          : this.props.item.nganTuCaNhanId
        this.props.actions
          .getPathDocumentCabinet({ loaiTuTaiLieu, nganTuId })
          .then(res => {
            if (res && res.payload && res.payload.data) {
              this.setState({
                isLoading: false,
                path: res.payload.data.result,
              })
            } else {
              this.setState({ isLoading: false })
              return this.props.actions.commonAddToasterMessage({
                message: 'Không lấy được đường dẫn.',
                intent: Intent.DANGER,
              })
            }
          })
      })
    }
  }

  render() {
    if (this.state.isRedirect) {
      let typeName
      switch (this.props.type) {
        case DOCUMENTS_CABINET.CA_NHAN:
          typeName = 'ca-nhan'
          break
        case DOCUMENTS_CABINET.PHONG_BAN:
          typeName = 'phong-ban'
          break
        default:
          typeName = 'don-vi'
      }
      return (
        <Redirect
          push
          to={{
            pathname: `/tu-tai-lieu/chi-tiet/${typeName}/${this.props.item.id}`,
            state: {
              phongBanId: this.props.phongBanId,
              keyTabTuTaiLieu: this.props.keyTabTuTaiLieu,
              page: this.props.page || 1,
              filter: !isEmpty(this.props.filter) ? this.props.filter : {},
            },
          }}
        />
      )
    }

    return (
      <tr>
        <td>
          <Authorization.Element permission={this.props.actionPermissions}>
            <Checkbox
              checked={this.state.isEnabled}
              disabled={this.props.isCheckAllData}
              onChange={this.handleEnabledChange}
            />
          </Authorization.Element>
        </td>
        <td onClick={this.handleClick}>{this.props.item.soCongVan}</td>
        <td onClick={this.handleClick}>
          {this.props.item.ngayCongVan
            ? moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')
            : null}
        </td>
        <td onClick={this.handleClick}>
          {this.props.item.ngayTao
            ? moment(this.props.item.ngayTao).format('DD/MM/YYYY')
            : null}
        </td>
        <td onClick={this.handleClick}>{this.props.item.tenTaiLieu}</td>
        {this.props.type !== DOCUMENTS_CABINET.CA_NHAN && (
          <td onClick={this.handleClick}>{this.props.item.noiGui}</td>
        )}
        {this.props.type !== DOCUMENTS_CABINET.CA_NHAN && (
          <td onClick={this.handleClick}>
            {this.props.item.nguoiTao
              ? this.props.item.nguoiTao.tenNhanVien
              : null}
          </td>
        )}
        <td className="list-item-center pb15">
          <Popover
            popoverClassName="pt-popover-content-sizing"
            position={Position.LEFT}
            interactionKind={PopoverInteractionKind.CLICK}
            popoverDidOpen={this.isOpen}
            content={
              <div
                className="menu-popover"
                onClick={this.setDocumentCabinetPath}
              >
                <div className="item">
                  <span className="name_action">
                    {!this.state.isLoading && this.state.path}
                  </span>
                </div>
              </div>
            }
          >
            <span className="icon-folder" style={{ fontSize: 14 }}></span>
          </Popover>
        </td>
      </tr>
    )
  }
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default ListItem
