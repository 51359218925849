import React, { Component } from 'react'
import { Checkbox, Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../helpers'

class ListFilter extends Component {
  static propTypes = {
    staffs: PropTypes.array.isRequired,
    status: PropTypes.array.isRequired,
    onSubmit: PropTypes.func,
    onClickNeddSignNumber: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    dateUpdate: moment(),
    isOpen: false,
    filter: {
      textSearch: '',
      ngayLap: {
        from: '',
        to: '',
      },
      nguoiLap: undefined,
      tinhTrang: undefined,
    },
    dateFromToString: '',
    dateToToString: '',
  }

  componentWillReceiveProps(props) {
    if (props.filter) {
      this.setState({
        filter: {
          textSearch: props.filter.textSearch || '',
          ngayLap: {
            from:
              props.filter.ngayLap &&
              props.filter.ngayLap.from &&
              props.filter.ngayLap.from.trim().length > 0
                ? moment(props.filter.ngayLap.from)
                : '',
            to:
              props.filter.ngayLap &&
              props.filter.ngayLap.to &&
              props.filter.ngayLap.to.trim().length > 0
                ? moment(props.filter.ngayLap.to)
                : '',
          },
          nguoiLap: props.filter.nguoiLap || '',
          tinhTrang: props.filter.tinhTrang || '',
        },
        dateFromToString:
          props.filter.ngayLap &&
          props.filter.ngayLap.from &&
          props.filter.ngayLap.from.trim().length > 0
            ? moment(props.filter.ngayLap.from)
            : '',
        dateToToString:
          props.filter.ngayLap &&
          props.filter.ngayLap.to &&
          props.filter.ngayLap.to.trim().length > 0
            ? moment(props.filter.ngayLap.to)
            : '',
      })
    }
  }

  clearFilter(e) {
    e.preventDefault()
    this.setState(
      {
        dateUpdate: moment(),
        filter: {
          textSearch: undefined,
          ngayLap: {
            from: '',
            to: '',
          },
          nguoiLap: undefined,
          tinhTrang: undefined,
        },
        dateFromToString: '',
        dateToToString: '',
      },
      () => {
        this.props.onSubmit && this.props.onSubmit(this.state.filter, 'reset')
      }
    )
  }

  handleDateChange(name, pos, nameToString, e) {
    let value = ''
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
      [nameToString]: value,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, pos, nameToString, value) {
    if (
      pos === 'from' &&
      !this.state.filter[name].to &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'from' &&
      this.state.filter[name].to &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: this.state.filter[name].to,
        },
      })
    ) {
      return
    }

    if (
      pos === 'to' &&
      !this.state.filter[name].from &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'to' &&
      this.state.filter[name].from &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: this.state.filter[name].from,
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: undefined,
        },
      },
    })
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: value ? value : undefined,
      },
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    const filter = {
      ...this.state.filter,
      ngayLap: {
        from: this.state.filter.ngayLap.from
          ? moment(this.state.filter.ngayLap.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayLap.to
          ? moment(this.state.filter.ngayLap.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmit && this.props.onSubmit(filter, 'submit')
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <div className="search-container">
        <form className="form-search-basic" onSubmit={this.handleSubmit}>
          <div className="seach-form">
            <div className="row">
              <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 search-header-left-content start-xs">
                <Checkbox
                  className="pt-large pt-inline name-radio checkbox-sign-number head-check-content"
                  name="canKySo"
                  checked={this.props.isNeedSignNumber}
                  onChange={this.props.onClickNeddSignNumber}
                >
                  <span className="name_checkbox">Văn bản chờ xử lý</span>
                </Checkbox>
              </div>
              <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 search-header-right-content end-xs">
                <div className="search-header-group">
                  <div className="search-keyword">
                    <input
                      autoFocus={false}
                      autoComplete="off"
                      name="textSearch"
                      className="search"
                      type="text"
                      placeholder="Tìm kiếm Số ký hiệu, Trích yếu, Nội dung trình"
                      onChange={this.handleInputChange}
                      value={
                        this.state.filter.textSearch
                          ? this.state.filter.textSearch
                          : ''
                      }
                    />
                  </div>
                  <div className="btn-reload-group">
                    <Button className="btn-search" type="submit">
                      <span className="pt-icon pt-icon-search"></span>
                    </Button>
                    <Button
                      type="submit"
                      className="btn-reload"
                      onClick={this.clearFilter}
                    >
                      <span className="icon-Loading"></span>
                    </Button>
                    <Button
                      type="submit"
                      className="btn-toogle"
                      onClick={this.handleClick}
                    >
                      <span
                        className={classnames('pt-icon', {
                          'pt-icon-double-chevron-up': this.state.isOpen,
                          'pt-icon-double-chevron-down': !this.state.isOpen,
                        })}
                      ></span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Collapse isOpen={this.state.isOpen}>
            <div className="search-advanced">
              <div className="row">
                <div className="group-datetime">
                  <label className="pt-label label-datepicker">Ngày lập</label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      isClearable={this.state.dateFromToString ? true : false}
                      readOnly={this.props.readOnly}
                      selected={this.state.filter.ngayLap.from}
                      name="ngayLap"
                      selectsStart
                      startDate={this.state.filter.ngayLap.from}
                      endDate={this.state.filter.ngayLap.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'ngayLap',
                        'from',
                        'dateFromToString'
                      )}
                      value={this.state.dateFromToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateFromToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'ngayLap',
                          'from',
                          'dateFromToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.filter.ngayLap.to}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                    <DatePicker
                      isClearable={this.state.dateToToString ? true : false}
                      readOnly={this.props.readOnly}
                      selected={this.state.filter.ngayLap.to}
                      name="ngayLap"
                      selectsEnd
                      startDate={this.state.filter.ngayLap.from}
                      endDate={this.state.filter.ngayLap.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'ngayLap',
                        'to',
                        'dateToToString'
                      )}
                      value={this.state.dateToToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateToToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'ngayLap',
                          'to',
                          'dateToToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.filter.ngayLap.from}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="group-select">
                  <label className="pt-label">
                    Người lập
                    <div className="pt-select">
                      <select
                        name="nguoiLap"
                        onChange={this.handleInputChange}
                        value={this.state.filter.nguoiLap}
                      >
                        <option value="">Tất cả</option>
                        {this.props.staffs.map((item, i) => (
                          <option key={i} value={item.chucDanhId}>
                            {item.tenNhanVien}
                          </option>
                        ))}
                      </select>
                    </div>
                  </label>
                </div>
                <div className="group-select">
                  <label className="pt-label">
                    Tình trạng
                    <div className="pt-select">
                      <select
                        name="tinhTrang"
                        onChange={this.handleInputChange}
                        value={this.state.filter.tinhTrang}
                      >
                        <option value="">Tất cả</option>
                        {this.props.status.map((item, i) => (
                          <option key={i} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </Collapse>
        </form>
      </div>
    )
  }
}

export default ListFilter
