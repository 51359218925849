import React from 'react'
import { Authorization, Permission } from '../../components/auth/'
import PropTypes from 'prop-types'
import BoxNotification from './BoxNotification'
import { Link } from 'react-router-dom'

class ListNotification extends React.Component {
  static propTypes = {
    corporations: PropTypes.array,
    unit: PropTypes.array,
    pageCount: PropTypes.object,
    page: PropTypes.object,
    isLoading: PropTypes.object,
    isEmpty: PropTypes.object,
    onClickPreviousPage: PropTypes.func,
    onClickNextPage: PropTypes.func,
  }

  render() {
    const AuthElement = Authorization.Element
    return (
      <div className="section-container notification-container">
        <section className="section-block">
          <div className="heading-content">
            <h2 className="heading-icon-17 main-heading">
              <span className="icon-left icon-notification"></span>
              <span className="heading-text">Thông báo</span>
            </h2>
            <AuthElement
              orPermission={[Permission.SUPERADMIN, Permission.ADMIN]}
            >
              <div className="btn-right-heading pull-right">
                <Link
                  to="/thong-bao/danh-sach"
                  className="pt-button icon-settings btn-management"
                >
                  <span className="text-btn">Quản lý nội dung</span>
                </Link>
              </div>
            </AuthElement>
          </div>
          <div className="section-content">
            <div className="row border-top list-task-notification">
              {/* Tổng công ty */}
              <BoxNotification
                title="Tổng công ty"
                type="corporations"
                data={this.props.corporations}
                pageCount={
                  this.props.pageCount ? this.props.pageCount.corporations : 0
                }
                page={this.props.page ? this.props.page.corporations : 1}
                isLoading={
                  this.props.isLoading
                    ? this.props.isLoading.corporations
                    : false
                }
                isEmpty={
                  this.props.isEmpty ? this.props.isEmpty.corporations : false
                }
                onClickPreviousPage={this.props.onClickPreviousPage}
                onClickNextPage={this.props.onClickNextPage}
              />
              {/* Đơn vị */}
              <BoxNotification
                title="Đơn vị"
                type="unit"
                data={this.props.unit}
                pageCount={this.props.pageCount ? this.props.pageCount.unit : 0}
                page={this.props.page ? this.props.page.unit : 1}
                isLoading={
                  this.props.isLoading ? this.props.isLoading.unit : false
                }
                isEmpty={this.props.isEmpty ? this.props.isEmpty.unit : false}
                onClickPreviousPage={this.props.onClickPreviousPage}
                onClickNextPage={this.props.onClickNextPage}
              />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default ListNotification
