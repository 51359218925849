import React, { Component } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { Creatable } from 'react-select'
import { LoadingTable } from '../../../components/common'
import { signRole } from '../../../constants/Procedure'
import { Checkbox } from '@blueprintjs/core'

export default class ApprovalStepForm extends Component {
  render() {
    const { addStep, moveStep, isLoading } = this.props
    return (
      <div className="form-sub-step form-sub-new-style">
        <div className="form-row form-sub-title">
          <div className="form-cols">
            <label className="pt-label">QUY TRÌNH KÝ DUYỆT</label>
          </div>
        </div>
        {isLoading ? (
          <LoadingTable />
        ) : (
          <div>
            <div className="form-row">
              <SortableList
                helperClass="SortableHelper-step"
                onSortEnd={moveStep}
                lockAxis="y"
                useDragHandle
                lockToContainerEdges
                {...this.props}
              />
            </div>
            <div className="form-row">
              <div className="form-cols">
                <button
                  type="button"
                  className="pt-button btn-add-more"
                  onClick={addStep}
                >
                  <span className="text-content">Thêm bước</span>
                  <span className="icon-bc icon-Plus" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const DragHandle = SortableHandle(() => (
  <span className="icon-move-drap icon-drag" />
))

const SortableItem = SortableElement(
  ({
    i,
    data,
    listSize,
    isLoading,
    removeStep,
    handleChange,
    error,
    defineStepChange,
    defineList,
    thanhPhanKy,
    showCapSo,
  }) => {
    return (
      <div className="form-container procedure-form step-new-style">
        <div className="form-row form-header header-new-style">
          <div className="form-cols drag">
            <DragHandle />
          </div>
          <div className="form-cols name">
            <label className="pt-label">Bước {i + 1}</label>
          </div>
          {listSize > 1 && (
            <div className="form-cols close-cols">
              <i className="icon-close-light" onClick={() => removeStep(i)} />
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="form-cols form-col-full">
            <div className="pt-form-group">
              <label className="pt-label">
                Định nghĩa bước ký duyệt
                <span className="pt-text-muted required-input">*</span>
              </label>
              <div>
                <Creatable
                  name="name"
                  options={defineList}
                  onInputChange={e => defineStepChange(e, i)}
                  onChange={e => defineStepChange(e, i)}
                  isLoading={isLoading}
                  value={data.name !== '' ? data.name : null}
                  placeholder="Nhập tên định nghĩa"
                  promptTextCreator={text => `Tự định nghĩa: "${text}"`}
                  noResultsText={'Không có định nghĩa gợi ý'}
                  className={'define-step-input'}
                />
              </div>
              {!!error.name && (
                <div className="pt-form-helper-text">{error.name}</div>
              )}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-cols form-col5">
            <div className="pt-form-group">
              <label className="pt-label">Đối tượng ký duyệt</label>
              <div className="pt-select pt-form-content">
                <select
                  type="select"
                  name="sign_subject"
                  value={data.sign_subject}
                  onChange={e => handleChange(e, i)}
                >
                  {thanhPhanKy === 0 && (
                    <option value={0}>Theo danh sách thành viên</option>
                  )}
                  {thanhPhanKy === 1 && (
                    <option value={1}>Theo phòng ban</option>
                  )}
                  {thanhPhanKy === 1 && (
                    <option value={2}>Theo vai trò trong đơn vị</option>
                  )}
                  {thanhPhanKy === 1 && (
                    <option value={3}>Theo vai trò trong phòng ban</option>
                  )}
                  {thanhPhanKy === 9 && (
                    <option value={9}>
                      Theo danh sách thành viên hoặc đơn vị bên ngoài
                    </option>
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>
        {thanhPhanKy !== 0 && (
          <div className="form-row">
            <div className="form-cols form-col-full">
              <div className="pt-form-group">
                <label className="pt-label">
                  Vai trò<span className="pt-text-muted required-input">*</span>
                </label>
                <div className="select-group-container">
                  {Object.keys(signRole).map((key, index) => {
                    if (
                      (data.sign_subject === 1 || data.sign_subject === 3) &&
                      !['LDPB', 'CV'].includes(key)
                    ) {
                      return null
                    }
                    return (
                      <div className="role-checkbox" key={index}>
                        <Checkbox
                          name="role"
                          checked={data.role.includes(key)}
                          value={key}
                          onChange={e => handleChange(e, i)}
                        >
                          {signRole[key]}
                        </Checkbox>
                      </div>
                    )
                  })}
                </div>
                {!!error.role && (
                  <div className="pt-form-helper-text">{error.role}</div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="form-row">
          <div className="form-cols form-col5">
            <div className="form-cols form-col5 pl0">
              <div className="pt-form-group">
                <label className="pt-label">
                  Số lượng người ký
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-select pt-form-content">
                  <select
                    type="select"
                    name="quantity_signature"
                    value={data.quantity_signature}
                    onChange={e => handleChange(e, i)}
                  >
                    <option value={1}>Chọn 1 người</option>
                    <option value={2}>Chọn nhiều người</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-cols form-col5 pr0">
              <div className="pt-form-group">
                <label className="pt-label">
                  Loại chữ ký áp dụng
                  <span className="pt-text-muted required-input">*</span>
                </label>
                <div className="pt-select pt-form-content">
                  <select
                    name="signature_type"
                    value={data.signature_type}
                    onChange={e => handleChange(e, i)}
                  >
                    <option value={0}>
                      Xác nhận qua chương trình(không ký số)
                    </option>
                    <option value={1}>Chữ ký không có hình ảnh con dấu</option>
                    <option value={2}>Chữ ký có hình ảnh con dấu</option>
                    <option value={3}>Chữ ký nháy</option>
                    <option value={4}>Không hiển thị hình ảnh chữ ký</option>
                    {showCapSo && <option value={6}>Cấp số</option>}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="form-cols form-col5">
            <div className="pt-form-group">
              <label className="pt-label">
                Cách xác định vùng ký
                <span className="pt-text-muted required-input">*</span>
              </label>
              <div className="form-row sign-location">
                <div className="form-cols form-col5">
                  <div className="pt-form-group">
                    <div className="pt-select pt-form-content">
                      <select
                        type="select"
                        name="signature_location_type"
                        value={data.signature_location_type}
                        onChange={e => handleChange(e, i)}
                      >
                        <option value={0}>Kẻ ô vùng ký</option>
                        {data.signature_type !== 6 && (
                          <option value={1}>Tìm theo vị trí cố định</option>
                        )}
                        {thanhPhanKy !== 1 && data.signature_type !== 6 && (
                          <option value={2}>Tìm theo tên người ký</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-cols form-col5">
                  <div className="pt-form-group">
                    <div className="input-form pt-form-content">
                      <input
                        type="text"
                        name="signature_location_note"
                        onChange={e => handleChange(e, i)}
                        value={data.signature_location_note || ''}
                        className={`pt-input ${
                          isLoading ? 'loading-input' : ''
                        }`}
                        maxLength="255"
                        disabled={
                          isLoading ||
                          data.signature_location_type === 2 ||
                          (data.quantity_signature === 2 &&
                            data.signature_location_type === 0)
                        }
                        placeholder={
                          data.signature_location_type === 0 &&
                          data.quantity_signature !== 2
                            ? 'Nhập tên vùng ký'
                            : data.signature_location_type === 1
                            ? 'Nhập vị trí'
                            : ''
                        }
                      />
                      {!!error.signature_location_note && (
                        <div className="pt-form-helper-text">
                          {error.signature_location_note}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

const SortableList = SortableContainer(props => {
  const { data: list, error, ...newProps } = props
  return (
    <div className="sort-block-step form-row">
      <div className="form-cols">
        {!!list.length &&
          list.map((item, index) => (
            <SortableItem
              key={index}
              data={item}
              i={index}
              index={index}
              {...newProps}
              listSize={list.length}
              error={error[index] || {}}
              showCapSo={list.length === index + 1}
            />
          ))}
      </div>
    </div>
  )
})
