import React, { memo, useRef, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import {
  deleteCongViec,
  getThongKeLoaiGiaoViec,
  getThongKeTrangThaiCongViec,
} from 'actions/task'
import { Dialog, Card } from 'components/newCommon/'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import { STATISTIC_WORK_RESPONSIBILITY } from 'constants/Enum'

const CONFIRM_TITLE = 'Xóa công việc'
const CONFIRM_CONTENT = 'Anh/Chị muốn xóa công việc này?'

const DeleteTask = ({
  history,
  match,
  onClose = () => {},
  reloadList = () => {},
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const { id, type } = match.params
  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const deleteTask = () => {
    requestAction({
      beforeAction: () => _mountedSet(setLoading, true),
      action: () => deleteCongViec(id),
      afterResponse: () => {
        _mountedSet(setShowDialog, false)
        reloadList()
        reloadThongKe()
        history.push(`/quan-ly-cong-viec/${type}`)
      },
      afterAction: () => _mountedSet(setLoading, false),
      successMessage: Toast.SUCCESS(Action.DELETE),
      errorMessage: Toast.FAIL(Action.DELETE),
    })
  }

  const reloadThongKe = () => {
    getThongKeLoaiGiaoViec()
    if (type === 'duoc-giao') {
      getThongKeTrangThaiCongViec({
        responsibilityType: STATISTIC_WORK_RESPONSIBILITY.ASSIGN_TO_ME,
      })
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Dialog
      buttonUppercase
      isOpen={showDialog}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      // confirmDanger
      confirmText="Xóa"
      loading={loading}
      onCancel={onClose}
      onConfirm={deleteTask}
      onClose={onClose}
      title={CONFIRM_TITLE}
      textCenter
    >
      <Card className="mt20 mb20">{CONFIRM_CONTENT}</Card>
    </Dialog>
  )
}

export default memo(withRouter(DeleteTask))
