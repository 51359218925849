import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { Position, Tooltip } from '@blueprintjs/core'
import size from 'lodash/size'
import { WORK_TYPE, WORK_PRIORITY } from 'constants/Enum'
import { PrioritizeFlag, Icon } from 'components/newCommon'
import 'react-circular-progressbar/dist/styles.css'
import HomeTag from './HomeTag'

const JobCard = ({ history, className = '', data }) => {
  const dateFormat = 'DD/MM/YYYY'

  const goDetail = data => {
    return history.push(
      `/quan-ly-cong-viec/ca-nhan/can-thuc-hien/chi-tiet/${data?.id}`
    )
  }

  const renderWorkType = workType => {
    let result = {
      text: '',
      icon: '',
    }

    switch (workType) {
      case WORK_TYPE.DOFFICE:
        result = {
          text: 'Từ D-OFFICE',
          icon: 'icon2-d-office',
          color: 'icon-color-danger',
        }
        break
      case WORK_TYPE.MEETING:
        result = {
          text: 'Công việc Thông báo KLCH',
          icon: 'icon2-clipboard',
          color: 'icon-color-yellow',
        }
        break
      case WORK_TYPE.EOFFICE:
        result = {
          text: 'Công việc Ký số, Công văn',
          icon: 'icon2-e-office-label',
          color: 'icon-color-primary',
        }
        break
      default:
        break
    }

    return result
  }

  const dataWorkType = renderWorkType(data?.workType)

  return (
    <div
      className={classNames('job-card__block cursor-pointer', className)}
      onClick={() => goDetail(data)}
    >
      <div className="job__head">
        <p className="job__head__des font-size-13">
          {data?.workType && dataWorkType && (
            <Tooltip
              content={
                <span className="font-size-12">{dataWorkType?.text}</span>
              }
              position={Position.BOTTOM}
            >
              <Icon
                classIcon={dataWorkType?.icon}
                className={classNames(
                  'size-icon-18',
                  'mr10 mb5',
                  'd-block',
                  dataWorkType?.color
                )}
              />
            </Tooltip>
          )}
          {data?.title}
        </p>
      </div>
      {size(data?.workUserTags) !== 0 && (
        <div className="d-flex mt10">
          {data?.workUserTags?.map((elm, index) => (
            <HomeTag
              key={index}
              className="mr5"
              backgroundColor={elm?.backgroundColor}
              color={elm?.textColor || '#0f3753'}
              text={elm?.workTagName}
            />
          ))}
        </div>
      )}
      {(data?.deadline ||
        (data?.priority && data?.priority !== WORK_PRIORITY.NORMAL)) && (
        <div className="job__footer mt10">
          {data?.deadline && moment(data?.deadline).isValid() && (
            <p
              className="d-flex item-center font-size-12"
              style={{ color: '#8A9DA7' }}
            >
              <span className="icon2-date-frames mr10"></span>
              {moment(data?.deadline).format(dateFormat)}
            </p>
          )}
          {data?.priority && data?.priority !== WORK_PRIORITY.NORMAL && (
            <p className="job__priority d-flex item-center">
              <PrioritizeFlag className="ml10" type={data?.priority} />
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default JobCard
