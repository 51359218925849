import React, { memo, useRef, useState, useEffect } from 'react'
import { Slider } from '@blueprintjs/core'
import { get } from 'lodash'
import csx from 'classnames'

const ProgressBar = ({
  className,
  disabled = false,
  onChange = () => {},
  onRelease = () => {},
  value: sliderValue,
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, stateValue) =>
    !!get(mounted, 'current', false) && setState(stateValue)

  const [value, setValue] = useState(0)

  const changeValue = number => {
    mountedSet(setValue, number)
    onChange(number)
  }

  const releaseValue = number => {
    mountedSet(setValue, number)
    onRelease(number)
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    mountedSet(setValue, sliderValue)
  }, [sliderValue])

  return (
    <span className={csx('cpc-slider-wrapper', className)}>
      <Slider
        className="cpc-slider"
        disabled={disabled}
        min={0}
        max={100}
        onChange={changeValue}
        onRelease={releaseValue}
        renderLabel={false}
        value={value}
      />
      <span className="font-size-13 ml5">{value}%</span>
    </span>
  )
}

export default memo(ProgressBar)
