import React, { memo, useContext } from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { Tooltip } from '@blueprintjs/core'

import { ResetButton, SearchInput, Select } from 'components/newCommon'
import { DateRangePicker } from 'components/newCommon2'
import { MeetingContext } from '../Meeting'

const MeetingFilter = () => {
  const requestDateFormat = 'YYYY/MM/DD'
  const filterKeys = {
    from: 'fromDate',
    to: 'toDate',
    textSearch: 'textSearch',
    meetingType: 'meetingType',
    fromExecutionDate: 'fromExecutionDate',
    toExecutionDate: 'toExecutionDate',
  }

  const {
    dsLoaiCuocHop,
    changeFilter = () => {},
    filter: {
      [filterKeys.from]: from = null,
      [filterKeys.to]: to = null,
      [filterKeys.meetingType]: meetingType = null,
      [filterKeys.textSearch]: textSearch = '',
      [filterKeys.fromExecutionDate]: fromExecutionDate = null,
      [filterKeys.toExecutionDate]: toExecutionDate = null,
    } = {},
  } = useContext(MeetingContext)

  return (
    <div className="layout-filter">
      <div className="flex-basis-20">
        <div className="cpc-input-v2-filter">
          <DateRangePicker
            placeholder="Ngày ban hành (Từ ngày - Đến ngày)"
            onChange={range => {
              let value = {}
              if (range) {
                value = {
                  [filterKeys.fromExecutionDate]: moment(
                    get(range, 'start')
                  ).format(requestDateFormat),
                  [filterKeys.toExecutionDate]: moment(
                    get(range, 'end')
                  ).format(requestDateFormat),
                }
              } else {
                value = {
                  [filterKeys.fromExecutionDate]: null,
                  [filterKeys.toExecutionDate]: null,
                }
              }
              changeFilter({ multipleKey: true, reFetch: true })(value)
            }}
            value={
              fromExecutionDate && toExecutionDate
                ? moment.range(
                    moment(fromExecutionDate),
                    moment(toExecutionDate)
                  )
                : null
            }
          />
        </div>
      </div>
      <div className="flex-basis-20">
        <div className="cpc-input-v2-filter">
          <DateRangePicker
            placeholder="Ngày tạo (Từ ngày - Đến ngày)"
            onChange={range => {
              let value = {}
              if (range) {
                value = {
                  [filterKeys.from]: moment(get(range, 'start')).format(
                    requestDateFormat
                  ),
                  [filterKeys.to]: moment(get(range, 'end')).format(
                    requestDateFormat
                  ),
                }
              } else {
                value = {
                  [filterKeys.from]: null,
                  [filterKeys.to]: null,
                }
              }
              changeFilter({ multipleKey: true, reFetch: true })(value)
            }}
            value={from && to ? moment.range(moment(from), moment(to)) : null}
          />
        </div>
      </div>
      <div className="flex-basis-20">
        <div className="cpc-input-v2-filter">
          <Select
            placeholder="Loại cuộc họp"
            dataSource={[{ label: 'Tất cả', value: 'ALL' }, ...dsLoaiCuocHop]}
            onChange={changeFilter({
              name: filterKeys.meetingType,
              reFetch: true,
            })}
            value={meetingType}
          />
        </div>
      </div>
      <div className="flex-basis-40">
        <div className="cpc-input-v2-filter-button">
          <SearchInput
            placeholder="Nhập tên cuộc họp/Số hiệu VB"
            onChange={changeFilter({
              name: filterKeys.textSearch,
              reFetch: false,
            })}
            onSearch={changeFilter({ name: filterKeys.textSearch })}
            value={textSearch}
            leftIcon="icon2-search"
          />
          <Tooltip content={<span className="font-size-12">Làm mới</span>}>
            <ResetButton onClick={changeFilter({ name: 'RESET' })} />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default memo(MeetingFilter)
