import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Classes,
  Button,
  Intent,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'
import classNames from 'classnames'
import {
  get,
  size,
  toPairs,
  isEqual,
  isEmpty,
  isString,
  uniqBy,
  differenceBy,
} from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'

import { downloadFile } from 'helpers/Helper'
import { DO_DOWNLOAD_FILE } from 'constants/Api'
import { Permission } from 'components/auth'
import {
  Row,
  Col,
  Upload,
  Collapse,
  FileGroup,
  Icon,
  Dialog,
  Card,
} from 'components/newCommon'
import {
  Input,
  Select,
  RemindSelect,
  CheckboxSelect,
  FormDatePicker,
  DateAdvanced,
} from 'components/newCommon2'
import {
  getMyFavoriteGroups,
  postCongViec,
  postUpload,
  getDsTag,
  getPhongBanNhanVien,
} from 'actions/task'
import { priorities } from 'constants/task'
import { Action, Toast } from 'constants/MessageForm'
import { documentTypes, allowDocumentFileTypes } from 'constants/fileTypes'
import { RESPONSIBILITY_TYPE, UNSET, WORK_TYPE } from 'constants/Enum'
import { randomId } from 'helpers/string'
import { toDecamelizeKeys } from 'helpers/key'
import requestAction from 'helpers/request'
import PhongBanVaDonViThucHien from 'components/common/PhongBanVaDonViThucHien'
import CryptoJS from 'crypto-js'

const dateRequest = 'YYYY-MM-DD'
const dateTimeRequest = 'YYYY-MM-DDTHH:mm:ss'
const CONFIRM_TITLE = 'Xóa công việc'
const CONFIRM_CONTENT = 'Anh/Chị muốn xóa công việc này?'

const GiveTask = ({
  match,
  roleId,
  hasShowAll,
  mainDepartmentId,
  mainUnitId,
  documentInfo,
  fileDO,
  tokenDO,
  onClose = () => {},
}) => {
  const token = {
    tokenType: 'Bearer',
    accessToken: tokenDO?.token,
  }
  //const id = match?.params?.id
  const deCodeId = CryptoJS.AES.decrypt(
    decodeURIComponent(match?.params?.id),
    'EVNCPC'
  )
  const id = deCodeId.toString(CryptoJS.enc.Utf8)
  const fileCongVanDO =
    fileDO
      ?.filter(f => f?.fILECHINH)
      ?.map(e => ({
        id: e?.iDFILE,
        name: e?.tENFILE,
      })) ?? []
  const fileDinhKemDO =
    fileDO
      ?.filter(f => !f?.fILECHINH)
      ?.map(e => ({
        id: e?.iDFILE,
        name: e?.tENFILE,
      })) ?? []
  const DEFAULT_TASK = useMemo(
    () => ({
      showTask: true,
      showExtra: false,
      data: {
        executionDate: moment(),
      },
      validate: {},
    }),
    []
  )

  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)
  const PHONG_BAN = 'DEPARTMENT'
  const DON_VI = 'ORGANIZATION'
  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const THEO_DOI = 'THEO_DOI'
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [tasks, setTasks] = useState([{ ...DEFAULT_TASK, id: randomId() }])
  const [isLoadingTree, setIsLoadingTree] = useState(false)
  const [dataAutoToggle, setDataAutoToggle] = useState([])
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [validateErrors, setValidateErrors] = useState({})
  const [files, setFiles] = useState({})
  const [dataTree, setDataTree] = useState([])
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [idTempDialog, setIdTempDialog] = useState(null)
  const [, setFavoriteGroups] = useState([])
  const [tags, setTags] = useState([])

  const onCloseDialog = useCallback(() => {
    setIsShowDialog(false)
    setIdTempDialog(null)
  }, [])

  const onDeleteTask = useCallback(() => {
    const id = idTempDialog
    const newTasks = tasks.filter(task => task.id !== id)
    _mountedSet(
      setTasks,
      size(newTasks) === 1
        ? // hiện lại form nếu chỉ còn 1 task đang đóng
          newTasks.map(t => ({ ...t, showTask: true }))
        : newTasks
    )

    // Xóa file lưu tạm của công việc đó
    _mountedSet(setFiles, prevFiles => {
      delete prevFiles[id]
      return prevFiles
    })

    onCloseDialog()
  }, [idTempDialog, tasks, onCloseDialog])

  const onChangeRadio = useCallback(
    (values, taskId) => {
      setIsDisabledButtonDefault(false)
      const isParent = values?.type === PHONG_BAN || values?.type === DON_VI
      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]: values,
                [PHOI_HOP]: !isParent
                  ? elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id)
                  : isParent
                  ? elm?.data?.[PHOI_HOP]?.filter(
                      e =>
                        (e.parentId !== values?.id && e.id !== values?.id) ||
                        (e.parentId === values?.id && !e.isLanhDaoPhongBan)
                    )
                  : elm?.data?.[PHOI_HOP],
                [THEO_DOI]: !isParent
                  ? elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id)
                  : isParent
                  ? elm?.data?.[THEO_DOI]?.filter(
                      e =>
                        (e.parentId !== values?.id && e.id !== values?.id) ||
                        (e.parentId === values?.id && !e.isLanhDaoPhongBan)
                    )
                  : elm?.data?.[THEO_DOI],
              },
            }
          }

          return elm
        })
      )
    },
    [setTasks]
  )

  const onChangeCheckbox = useCallback(
    async (name, values, taskId, event, isIndeterminate) => {
      setIsDisabledButtonDefault(false)
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      const isAll =
        values?.type === 'ALL' ||
        values?.type === DON_VI ||
        values?.type === PHONG_BAN
      let treeItem = []
      if (isAll) {
        treeItem = dataTree?.find(e => e?.id === values?.id)?.children || []
        setDataAutoToggle([values?.id])
      }

      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            let newData = []
            const oldData = elm?.data?.[name] || []
            newData = [...oldData, values]
            if (isAll) {
              if (!isChecked) {
                newData = differenceBy(
                  newData || [],
                  [...treeItem, values],
                  'id'
                )
              } else {
                if (!hasShowAll) {
                  newData = [...newData, ...(treeItem || [])]
                  newData = differenceBy(newData || [], [values], 'id')
                }
              }

              const dataChuTri = elm?.data?.[CHU_TRI]
                ? [elm?.data?.[CHU_TRI]]
                : []
              const dataPhoiHop = elm?.data?.[PHOI_HOP] || []
              const dataTheoDoi = elm?.data?.[THEO_DOI] || []
              if (name === PHOI_HOP && !hasShowAll) {
                newData = differenceBy(
                  newData,
                  [...dataChuTri, ...dataTheoDoi],
                  'id'
                )
              } else if (name === THEO_DOI && !hasShowAll) {
                newData = differenceBy(
                  newData,
                  [...dataChuTri, ...dataPhoiHop],
                  'id'
                )
              }

              if (
                (name === PHOI_HOP || name === THEO_DOI) &&
                size(dataChuTri) !== 0 &&
                dataChuTri?.some(
                  e =>
                    e?.id === values?.id &&
                    (e?.type === PHONG_BAN || e?.type === DON_VI)
                )
              ) {
                newData = newData?.filter(
                  e =>
                    e.parentId !== values?.id ||
                    (e.parentId === values?.id && !e?.isLanhDaoPhongBan)
                )
              }
            } else {
              if (!isChecked) {
                newData = differenceBy(newData || [], [values], 'id')
              }
            }

            newData = differenceBy(newData, [{ id: roleId }], 'id')
            const chuTri =
              name !== CHU_TRI &&
              elm?.data?.[CHU_TRI]?.id === values?.id &&
              (elm?.data?.[CHU_TRI]?.type !== PHONG_BAN ||
                elm?.data?.[CHU_TRI]?.type !== DON_VI)
                ? null
                : elm?.data?.[CHU_TRI]
            const phoiHop =
              name === PHOI_HOP
                ? uniqBy(newData, 'id')
                : elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id) || []
            const theoDoi =
              name === THEO_DOI
                ? uniqBy(newData, 'id')
                : elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id) || []

            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]: chuTri,
                [PHOI_HOP]: phoiHop,
                [THEO_DOI]: theoDoi,
              },
            }
          }

          return elm
        })
      )
    },
    [setTasks, setDataAutoToggle, hasShowAll, dataTree, roleId]
  )

  const handleCheckActive = useCallback(
    (key, tasks, taskId, record) => {
      const parentItem =
        dataTree?.find(e => e?.id === record?.id)?.children || []
      const taskItem = tasks?.find(elm => elm?.id === taskId)
      let isChecked = false
      if (
        !isEmpty(taskItem) !== 0 &&
        !isEmpty(taskItem?.data) &&
        size(taskItem?.data?.[key]) !== 0
      ) {
        const taskItemDataKey = taskItem?.data?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        const dataKey = taskItem?.data?.[key]?.findIndex(
          e => e?.id === record?.id
        )
        if (
          (size(parentItem) !== 0 &&
            size(parentItem) === size(taskItemDataKey)) ||
          dataKey === 0 ||
          (dataKey && dataKey !== -1)
        ) {
          isChecked = true
        }
      }

      return isChecked
    },
    [dataTree]
  )

  const handleCheckIndeterminate = useCallback(
    (name, tasks, taskId, record) => {
      const taskItem = tasks?.find(e => e.id === taskId)
      let isChecked = false
      const dataKey = size(taskItem?.data?.[name]) !== 0
      const hasChecked = taskItem?.data?.[name]?.some(
        e => e?.parentId === record?.id
      )
      if (
        dataKey &&
        (record?.type === PHONG_BAN ||
          record?.type === DON_VI ||
          record?.type === 'ALL') &&
        hasChecked
      ) {
        isChecked = true
      }

      return isChecked
    },
    []
  )

  const handleCheckDisable = useCallback(
    (key, taskId, record) => {
      let isDisable = false
      if ((key === PHOI_HOP || key === THEO_DOI) && roleId === record?.id) {
        isDisable = true
      }

      const dataChuTri =
        tasks?.find(e => e?.id === taskId)?.data?.[CHU_TRI] || null
      if (
        (key === PHOI_HOP || key === THEO_DOI) &&
        !isEmpty(dataChuTri) &&
        record?.parentId === dataChuTri?.id &&
        record?.isLanhDaoPhongBan
      ) {
        isDisable = true
      }

      return isDisable
    },
    [tasks, roleId]
  )

  const column = [
    {
      title: 'Phòng ban/đơn vị',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: (record, _, __, taskId) => {
        const taskItem = tasks?.find(
          elm => elm.id === taskId && elm?.data?.[CHU_TRI]?.id === record?.id
        )
        let isChecked = false
        if (!isEmpty(taskItem)) {
          isChecked = true
        }

        if (
          (!hasShowAll && record?.id === mainDepartmentId) ||
          !record.type ||
          record.type === 'ALL'
        ) {
          return ''
        }

        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            onChange={() => onChangeRadio(record, taskId)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: (record, _, __, taskId) => {
        const isDisable = handleCheckDisable(PHOI_HOP, taskId, record)
        const isChecked = handleCheckActive(PHOI_HOP, tasks, taskId, record)
        const isIndeterminate = handleCheckIndeterminate(
          PHOI_HOP,
          tasks,
          taskId,
          record
        )
        if (record?.phongBanId === mainDepartmentId) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable || false}
            onChange={e =>
              onChangeCheckbox(PHOI_HOP, record, taskId, e, isIndeterminate)
            }
          />
        )
      },
    },
    {
      title: 'TD',
      render: (record, _, __, taskId) => {
        const isDisable = handleCheckDisable(THEO_DOI, taskId, record)
        const isChecked = handleCheckActive(THEO_DOI, tasks, taskId, record)
        const isIndeterminate = handleCheckIndeterminate(
          THEO_DOI,
          tasks,
          taskId,
          record
        )
        if (!record.type) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            disabled={isDisable || false}
            onChange={e =>
              onChangeCheckbox(THEO_DOI, record, taskId, e, isIndeterminate)
            }
          />
        )
      },
    },
  ]

  // Ẩn/hiện toàn bộ công việc
  const toggleCollapseTask = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showTask: !task.showTask,
              showExtra: false,
            }
          : {
              ...task,
              showTask: false,
            }
      )
    )
  }

  // Ẩn/hiện thông tin thêm của công việc
  const toggleCollapseExtra = id => () => {
    _mountedSet(
      setTasks,
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showExtra: !task.showExtra,
            }
          : task
      )
    )
  }

  const removeTask = id => () => {
    setIdTempDialog(id)
    setIsShowDialog(true)
  }

  // Thay đổi giá trị của một thành phần trong form
  const _changeFormValue = ({ id, name, value }) => {
    _mountedSet(setTasks, prevTasks =>
      prevTasks.map(task =>
        task.id === id
          ? {
              ...task,
              data: {
                ...task.data,
                [name]: value,
                ...(name === 'deadline' ? { workReminder: null } : {}),
              },
            }
          : task
      )
    )
  }

  const _changeFormValidate = ({ id, name, value }) => {
    setValidateErrors(prev => ({
      ...prev,
      [id]: {
        ...(prev?.[id] || {}),
        [name]: value,
      },
    }))
  }

  const changeField = (id, name) => value => {
    setIsDisabledButtonDefault(false)
    _changeFormValidate({
      id,
      name,
      value: false,
    })

    _changeFormValue({
      id,
      name,
      value,
    })
  }

  const changeFile = id => fileList => {
    setIsDisabledButtonDefault(false)
    _mountedSet(setFiles, {
      ...files,
      [id]: fileList,
    })
  }

  const removeAttachmentFiles = id => removeFile => {
    if (removeFile.id) {
      // Xóa file đã upload lên server
      _mountedSet(setTasks, prevTasks =>
        prevTasks.map(task =>
          task.id === id
            ? {
                ...task,
                data: {
                  ...get(task, 'data', {}),
                  attachmentFiles: get(task, 'data.attachmentFiles', []).filter(
                    file => !isEqual(file, removeFile)
                  ),
                },
              }
            : task
        )
      )
    } else {
      // Xóa file chọn từ file upload
      _mountedSet(setFiles, {
        ...files,
        [id]: files[id].filter(elm => elm.uid !== removeFile.uid),
      })
    }
  }

  const _validateRequired = (name, key) => {
    let success = true
    const tasksNew = [...tasks]
    tasksNew.forEach(({ id, data }) => {
      let isCheck = isString(data[name]) && !data[name].trim()
      if (key) {
        isCheck = isString(data?.[name]?.[key]) && !data?.[name]?.[key].trim()
      }

      if (isEmpty(data[name]) || isCheck) {
        success = false
        _changeFormValidate({ id, name, value: true })
      } else {
        _changeFormValidate({ id, name, value: false })
      }
    })

    return success
  }

  const _validateTitle = () => {
    return _validateRequired('title')
  }

  const _validate = () => {
    const success = _validateTitle()
    const isHasChuTri = _validateRequired(CHU_TRI, 'id')
    if (!success || !isHasChuTri) {
      throw new Error('warning')
    }
  }

  const _convertDataType = (values, type) => ({
    responsibilityId: get(values, 'id'),
    assignType: get(values, 'type'),
    responsibilityType: type,
  })

  const _getConvertData = () => {
    return (tasks || []).map(task => {
      const originData = get(task, 'data', {})
      const newData = {
        title: get(originData, 'title', '').trim(),
        description: get(originData, 'description', '').trim(),
        priority:
          get(originData, 'priority') !== UNSET
            ? get(originData, 'priority')
            : undefined,
        workUserTags: get(originData, 'tags', []).map(item => ({
          workTagId: item,
        })),
        isSaveTemplate: get(originData, 'isSaveTemplate'),
        requireReport: originData?.requireReport || false,
        workType: WORK_TYPE.DOFFICE,
        documentId: id,
      }

      const deadline = get(originData, 'deadline')
      if (deadline) {
        newData.deadline = moment(deadline).startOf('day').format(dateRequest)
      }

      const executionDate = get(originData, 'executionDate')
      if (executionDate) {
        newData.executionDate = moment(executionDate).format(dateRequest)
      }

      const workReminder = get(originData, 'workReminder')
      if (workReminder) {
        newData.workReminder = {
          type: workReminder.type,
          reminderTime: workReminder.date.format(dateTimeRequest),
        }
      }

      const executor = get(originData, CHU_TRI)
        ? [
            {
              responsibilityId: get(originData, `${CHU_TRI}.id`),
              assignType: get(originData, `${CHU_TRI}.type`),
              responsibilityType: RESPONSIBILITY_TYPE.CHU_TRI,
            },
          ]
        : []
      const coordination = get(originData, PHOI_HOP, [])?.map(elm =>
        _convertDataType(elm, RESPONSIBILITY_TYPE.PHOI_HOP)
      )

      const watcher = get(originData, THEO_DOI, [])?.map(elm =>
        _convertDataType(elm, RESPONSIBILITY_TYPE.THEO_DOI)
      )
      newData.workAssignments = [...executor, ...coordination, ...watcher]

      return {
        ...task,
        data: newData,
      }
    })
  }

  // Upload file
  const _upload = async () => {
    const responseFilesObject = {}
    await Promise.all(
      Object.entries(files).map(async ([key, files]) => {
        if (isEmpty(files)) {
          responseFilesObject[key] = []
          return
        }

        await requestAction({
          action: () => postUpload(files),
          afterResponse: (result = []) => {
            responseFilesObject[key] = result
          },
          showToastSucess: false,
          codeCheck: false,
        })

        return
      })
    )

    return responseFilesObject
  }

  // Thêm dữ liệu file vào data
  const _getDataWithUpload = async () => {
    const cloneTasks = _getConvertData()
    const responseFilesObject = await _upload()
    return (cloneTasks || []).map(task =>
      toDecamelizeKeys({
        ...(task?.data || {}),
        attachmentFileIds: [
          ...(task?.data?.attachmentFileIds || []),
          ...(responseFilesObject[task.id] || []).map(file => file.id),
        ],
      })
    )
  }

  // Thực hiện tạo công việc
  const submitCreate = async () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setIsSubmitLoading, true)
        _validate()
      },
      action: async () => {
        const data = await _getDataWithUpload()
        return postCongViec(data)
      },
      afterResponse: () => {
        return onClose()
      },
      afterAction: () => {
        _mountedSet(setIsSubmitLoading, false)
      },
      successCode: 201 || 200,
      successMessage: Toast.SUCCESS(Action.ASSIGN),
      errorMessage: Toast.FAIL(Action.ASSIGN),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  // Tách files đính kèm từ công văn và files từ ngoài công văn
  const attachmentFilesFromDocument = (files, isEoffice) => {
    let filesResult = []
    if (!size(files)) {
      return []
    }

    if (isEoffice) {
      filesResult = files.filter(elm => elm.application === 'eOffice')
    } else {
      filesResult = files.filter(elm => elm.application !== 'eOffice')
    }

    return filesResult
  }

  const _fetchFavoriteGroup = useCallback(() => {
    requestAction({
      showToast: false,
      action: () => getMyFavoriteGroups({ is_active: true }),
      afterResponse: ({ items }) => {
        _mountedSet(
          setFavoriteGroups,
          items.map(({ id, name, members }) => ({
            id,
            name,
            children: members.map(({ id, name, avatar, departmentId }) => ({
              id,
              parentId: departmentId,
              name,
              type: 'PERSONAL',
              avatar,
            })),
          }))
        )
      },
    })
  }, [])

  const _fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => _mountedSet(setTags, items),
    })
  }, [])

  const fetchPhongBanDonVi = useCallback(async () => {
    let params = {
      donViId: mainUnitId,
      phongBanId: mainDepartmentId,
      limit: 9999,
    }
    await requestAction({
      showToast: false,
      successCode: 200,
      beforeAction: () => {
        setIsLoadingTree(true)
      },
      action: () => getPhongBanNhanVien(params),
      afterResponse: response => {
        let newData = []
        if (response?.items) {
          newData = response?.items?.map(elm => {
            return {
              id: elm?.phongBanId,
              type: 'DEPARTMENT',
              name: elm?.maPhongBan,
              code: elm?.maPhongBan,
              parentId: elm?.donViId,
              children:
                elm?.nhanViens?.map(u => ({
                  ...u,
                  id: u?.chucDanhId,
                  type: 'PERSONAL',
                  name: u?.tenNhanVien,
                  tenChucVu: u?.chucVu,
                  maChucVu: u?.maChucVu,
                  parentId: elm?.phongBanId,
                })) || [],
            }
          })
        }
        setDataAutoToggle([mainDepartmentId])
        setDataTree(newData)
      },
      afterAction: () => {
        setIsLoadingTree(false)
      },
    })
  }, [mainUnitId, mainDepartmentId])

  const onChangeFile = file => {
    if (file?.id) {
      const paramsDownLoadFile = `ID_NV=${tokenDO?.nhanVien?.idNv}&ID_DV=${tokenDO?.nhanVien?.idDv}&ID_LOAI_VB=${id}`
      const url = `${DO_DOWNLOAD_FILE}?ID_FILE=${file.id}&${paramsDownLoadFile}`
      downloadFile(url, file?.name, token?.accessToken)
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (!isEmpty(documentInfo)) {
      setTasks(prev =>
        prev?.map(e => ({
          ...e,
          data: {
            ...e?.data,
            title: documentInfo?.trichYeu,
          },
        }))
      )
    }
  }, [documentInfo])

  useEffect(() => {
    fetchPhongBanDonVi()
  }, [fetchPhongBanDonVi])

  useEffect(() => {
    _fetchDsTag()
  }, [_fetchDsTag])

  useEffect(() => {
    _fetchFavoriteGroup()
  }, [_fetchFavoriteGroup])

  return (
    <>
      <div className="cpc-form p15">
        {tasks.map(({ id, showTask, showExtra, data }, index) => (
          <Fragment key={id}>
            {!!index && (
              <span
                style={{
                  display: 'block',
                  height: 5,
                  background: '#DFE6EA',
                }}
              />
            )}
            <div>
              {size(tasks) > 1 && (
                <header className="d-flex">
                  <Button
                    className={classNames(
                      Classes.MINIMAL,
                      'cpc-button',
                      'cpc-form-button-left',
                      'font-weight-600',
                      'uppercase',
                      'text-left'
                    )}
                    intent={Intent.PRIMARY}
                    onClick={toggleCollapseTask(id)}
                    style={{ lineHeight: 1.3 }}
                  >
                    {`Công việc ${index + 1}`}
                    <Icon
                      classIcon={
                        showTask ? 'icon2-arrow-down' : 'icon2-arrow-up'
                      }
                      className={classNames('ml5', 'size-icon-10', 'd-flex')}
                    />
                  </Button>
                  <Button
                    className={classNames(
                      Classes.MINIMAL,
                      'cpc-button',
                      'cpc-form-button-right'
                    )}
                    intent={Intent.DANGER}
                    onClick={removeTask(id)}
                  >
                    <Icon classIcon="icon-bin" />
                  </Button>
                </header>
              )}

              <Collapse isOpen={showTask}>
                <div className="mb10" style={{ display: 'inline-block' }}>
                  <Checkbox
                    className={classNames(Classes.SMALL, 'mb0')}
                    checked={get(data, 'requireReport', false)}
                    onChange={({ target: { checked } }) =>
                      changeField(id, 'requireReport')(checked)
                    }
                  >
                    <span className="font-size-13 font-weight-300">
                      Yêu cầu báo cáo Hoàn thành
                    </span>
                  </Checkbox>
                </div>
                <Input
                  required
                  disabled={false}
                  label="Tiêu đề"
                  onChange={changeField(id, 'title')}
                  placeholder="Nhập tiêu đề/trích yếu"
                  type="textarea"
                  rows={2}
                  maxLength={500}
                  autoResize={true}
                  value={get(data, 'title')}
                  wrapperClassName="mb15"
                  warning={get(validateErrors, `${id}.title`)}
                />
                {/* Phòng ban và đơn vị thực hiện */}
                <PhongBanVaDonViThucHien
                  className="mb10"
                  isRequired={true}
                  isLoading={isLoadingTree}
                  column={column}
                  data={dataTree}
                  taskId={id}
                  dataAutoToggle={dataAutoToggle || []}
                  isDisplayFavoriteGroup={false}
                  warning={get(validateErrors, `${id}.${CHU_TRI}`)}
                  isHideChild={hasShowAll}
                />

                <Row gutterVertical className="mb10">
                  <Col grid="half">
                    <FormDatePicker
                      textLabel="Ngày bắt đầu"
                      disabled={false}
                      inputClearable
                      inputIconClassName="icon2-date-frames"
                      inputWrapperClassName="mb10"
                      onChange={value => {
                        changeField(id, 'executionDate')(value)
                      }}
                      minimumDate={moment().toDate()}
                      maximumDate={
                        get(data, 'deadline')
                          ? moment(get(data, 'deadline')).toDate()
                          : undefined
                      }
                      placeholder="Chọn ngày bắt đầu"
                      popoverStretch={false}
                      popoverPosition={Position.TOP_LEFT}
                      selected={get(data, 'executionDate')}
                    />
                  </Col>
                  <Col grid="half">
                    <DateAdvanced
                      textLabel="Hạn thực hiện"
                      disabled={false}
                      inputClearable
                      inputIconClassName="icon2-date-frames"
                      inputWrapperClassName="mb10"
                      placeholder="Chọn hạn thực hiện"
                      popoverStretch={false}
                      selected={get(data, 'deadline')}
                      inputWarning={get(validateErrors, `${id}.deadline`)}
                      minimumDate={
                        get(data, 'executionDate')
                          ? moment(get(data, 'executionDate')).toDate()
                          : moment().toDate()
                      }
                      popoverPosition={Position.TOP_RIGHT}
                      onChange={value => {
                        changeField(id, 'deadline')(value)
                      }}
                    />
                  </Col>
                </Row>

                {/* Thông tin bổ sung */}
                <Collapse isOpen={showExtra}>
                  <Input
                    disabled={false}
                    label="Mô tả"
                    onChange={changeField(id, 'description')}
                    placeholder="Nhập..."
                    rows={2}
                    maxLength={500}
                    autoResize={true}
                    style={{ maxHeight: 'unset' }}
                    type="textarea"
                    value={get(data, 'description')}
                    wrapperClassName="mb10"
                  />
                  <Row gutterVertical className="mb10">
                    <Col grid="half">
                      <Select
                        textLabel="Mức độ ưu tiên"
                        dataSource={toPairs(priorities).map(
                          ([value, label]) => ({ value, label })
                        )}
                        disabled={false}
                        inputIconClassName="icon-flag"
                        onChange={changeField(id, 'priority')}
                        placeholder="Chọn mức độ ưu tiên"
                        value={get(data, 'priority')}
                      />
                    </Col>
                    <Col grid="half">
                      <RemindSelect
                        textLabel="Thời gian nhắc việc"
                        inputIconClassName="icon2-date-frames"
                        deadline={get(data, 'deadline')}
                        disabled={false}
                        onChange={changeField(id, 'workReminder')}
                        value={get(data, 'workReminder')}
                        placeholder="Thời gian nhắc việc"
                        popoverPosition={Position.BOTTOM_RIGHT}
                      />
                    </Col>
                  </Row>

                  <CheckboxSelect
                    textLabel="Loại công việc"
                    disabled={false}
                    inputClearable
                    inputIconClassName="icon2-tag"
                    inputWrapperClassName="mb10"
                    placeholder="Loại công việc"
                    onChange={changeField(id, 'tags')}
                    dataSource={tags.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    value={get(data, 'tags', [])}
                  />

                  {!!size(fileCongVanDO) && (
                    <>
                      <label className="font-size-13 font-weight-600">
                        File công văn
                      </label>
                      <FileGroup
                        list={fileCongVanDO}
                        showClose={false}
                        isLink={false}
                        onChange={onChangeFile}
                      />
                    </>
                  )}

                  {!!size(fileDinhKemDO) && (
                    <>
                      <label className="font-size-13 font-weight-600">
                        File đính kèm từ công văn
                      </label>
                      <FileGroup
                        list={fileDinhKemDO}
                        showClose={false}
                        isLink={false}
                        onChange={onChangeFile}
                      />
                    </>
                  )}

                  {((!!size(data?.attachmentFiles) &&
                    !!size(
                      attachmentFilesFromDocument(
                        get(data, 'attachmentFiles', []),
                        false
                      )
                    )) ||
                    !!size(files[id])) && (
                    <>
                      <label className="font-size-13 font-weight-600">
                        File đính kèm
                      </label>
                      <FileGroup
                        list={attachmentFilesFromDocument(
                          get(data, 'attachmentFiles', []),
                          false
                        )}
                        listUpload={files[id]}
                        onClose={removeAttachmentFiles(id)}
                        showClose
                      />
                    </>
                  )}

                  <Upload
                    textLabel="File đính kèm"
                    accept={documentTypes.toString()}
                    fileList={get(files, id, [])}
                    wrapperClassName="mb10"
                    textSumary={
                      <p className="mt5 font-style-italic font-size-12">
                        Tải file có dung lượng tối đa 100MB, định dạng .pdf,
                        .docs, .doc, .xls, .xlsx, .ppt, .pptx
                      </p>
                    }
                    isShowList={false}
                    disabled={false}
                    allowFileTypes={allowDocumentFileTypes}
                    onChange={changeFile(id)}
                  />
                </Collapse>

                <Button
                  className={classNames(
                    Classes.MINIMAL,
                    'cpc-button',
                    'cpc-form-button-left'
                  )}
                  intent={Intent.PRIMARY}
                  onClick={toggleCollapseExtra(id)}
                >
                  {showExtra ? 'Thu gọn' : 'Mở rộng'}
                  <Icon
                    classIcon={
                      showExtra ? 'icon2-arrow-up' : 'icon2-arrow-down'
                    }
                    className={classNames('ml5', 'size-icon-10', 'd-flex')}
                  />
                </Button>
              </Collapse>
            </div>
          </Fragment>
        ))}
      </div>
      <div className={classNames('element-center', 'pv10 ph15', 'border-top')}>
        <Button
          className={classNames(
            'cpc-button',
            'btn-cancel',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-100',
            'mr15'
          )}
          disabled={false}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={classNames(
            'cpc-button',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-100'
          )}
          disabled={isDisabledButtonDefault}
          intent={Intent.PRIMARY}
          onClick={submitCreate}
          loading={isSubmitLoading}
        >
          Lưu
          <Icon classIcon="icon-save" className="ml5" />
        </Button>
      </div>
      <Dialog
        buttonUppercase
        isOpen={isShowDialog}
        cancelClassName="pl20 pr20"
        cancelText="Hủy"
        confirmClassName="pl20 pr20"
        confirmText="Xóa"
        onCancel={onCloseDialog}
        onConfirm={onDeleteTask}
        onClose={onCloseDialog}
        title={CONFIRM_TITLE}
        textCenter
      >
        <Card className="mt20 mb20">{CONFIRM_CONTENT}</Card>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  const roleId = state?.auth?.roleId
  const mainDepartment = state?.auth?.user?.dsChucDanh?.find(
    elm => elm?.id === roleId
  )
  const hasShowAll = get(state, 'auth.permission', []).includes(
    Permission.CONGVANDEN_CHIDAO
  )

  return {
    roleId,
    tokenDO: state?.doffice?.toKenDO,
    mainUnitId: state?.auth?.mainUnitId,
    hasShowAll,
    mainDepartmentId: get(state, 'auth.mainDepartmentId'),
    mainDepartment,
    listWorkTypes: state?.TaskManager?.listWorkTypes,
  }
}

export default connect(mapStateToProps)(memo(GiveTask))
