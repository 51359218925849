import React, { memo, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { deleteCongViec } from 'actions/task'
import requestAction from 'helpers/request'
import { Dialog, Card } from 'components/newCommon/'
import { Action, Toast } from 'constants/MessageForm'

const DeleteTask = ({ history, dataSource, onClose, onSuccess }) => {
  const pathname = history?.location?.pathname
  const id = dataSource?.id
  const [urlBackList, setUrlBackList] = useState('/')
  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const onDeleteTask = useCallback(() => {
    requestAction({
      beforeAction: () => setLoading(true),
      action: () => deleteCongViec(id),
      afterResponse: () => {
        setShowDialog(false)
        onSuccess()
        return history.push(urlBackList)
      },
      afterAction: () => setLoading(false),
      successMessage: Toast.SUCCESS(Action.DELETE),
      errorMessage: Toast.FAIL(Action.DELETE),
    })
  }, [id, urlBackList, history, onSuccess])

  useEffect(() => {
    if (pathname) {
      const tempArr = pathname.split('/')
      const pointChiTiet = tempArr.findIndex(f => f === 'chi-tiet')
      const resultArr =
        pointChiTiet !== -1 ? tempArr.slice(0, pointChiTiet) : tempArr
      setUrlBackList(resultArr.join('/'))
    }
  }, [pathname])

  return (
    <Dialog
      buttonUppercase
      isOpen={showDialog}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      confirmText="Xóa"
      loading={loading}
      onCancel={onClose}
      onConfirm={onDeleteTask}
      onClose={onClose}
      title={'Xóa công việc'}
      textCenter
    >
      <Card className="ph10">{'Anh/Chị muốn xóa công việc này?'}</Card>
    </Dialog>
  )
}

DeleteTask.defaultProps = {
  dataSource: null,
  onClose: () => {},
  onSuccess: () => {},
}

DeleteTask.propTypes = {
  dataSource: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default memo(DeleteTask)
