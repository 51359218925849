import React, { Component } from 'react'
import UsingGroupMemberList from './UsingGroupMemberList'
import { Collapse } from '@blueprintjs/core'
import classnames from 'classnames'

export default class UsingGroupDetail extends Component {
  constructor() {
    super()
    this.state = {
      isShowCollapse: true,
    }
  }

  toggleShowCollapse = () =>
    this.setState({
      isShowCollapse: !this.state.isShowCollapse,
    })

  render() {
    const {
      group,
      index,
      deleteGroup,
      removeMember,
      openMemberDialog,
      addGroupFile,
      removeGroupFile,
      token,
    } = this.props
    const { isShowCollapse } = this.state
    return (
      <div>
        <div className="group-header">
          <div className="group-name primary-color">
            NHÓM {index + 1}
            <span
              className={classnames('pt-icon  collapse-btn', {
                'pt-icon-chevron-up': isShowCollapse,
                'pt-icon-chevron-down': !isShowCollapse,
              })}
              onClick={this.toggleShowCollapse}
            />
          </div>
          <div className="action-button-group">
            <button
              className="action-button delete-btn"
              onClick={() => deleteGroup(group.nhomId)}
            >
              XÓA NHÓM
            </button>
          </div>
        </div>
        <Collapse isOpen={isShowCollapse}>
          <div className="group-table">
            <UsingGroupMemberList
              data={group.dsThanhVien || []}
              removeMember={memberId => removeMember(group.nhomId, memberId)}
              addMember={() => openMemberDialog(group.nhomId)}
              addGroupFile={file => addGroupFile(file, group.nhomId)}
              removeGroupFile={() => removeGroupFile(group.nhomId)}
              groupFile={group.fileThanhVien}
              index={index}
              token={token}
            />
          </div>
        </Collapse>
        <hr className="group-end-line" />
      </div>
    )
  }
}
