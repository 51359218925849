import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import { Link, Redirect } from 'react-router-dom';
import moment from 'moment'

class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleEnabledChange = this.handleEnabledChange.bind(this)
  }

  state = {
    isEnabled: false,
    isRedirect: false,
  }

  componentWillReceiveProps(props) {
    if (!isNaN(props.checked)) {
      this.setState({
        isEnabled: props.checked,
      })
    }
  }

  handleEnabledChange = e => {
    this.setState({ isEnabled: !this.state.isEnabled }, () => {
      this.props.onCheck &&
        this.props.onCheck(this.props.item.id, this.state.isEnabled)
    })
  }

  handleClick = () => {
    this.setState({
      isRedirect: true,
    })
  }

  render() {
    return (
      <tr>
        <td className="gerenal-list-utilities" onClick={this.handleClick}>
          {this.props.number > 9 ? this.props.number : '0' + this.props.number}
        </td>
        <td className="gerenal-list-utilities" onClick={this.handleClick}>
          {this.props.item.ngayCongVan
            ? moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')
            : null}
        </td>
        <td className="gerenal-list-utilities" onClick={this.handleClick}>
          {this.props.item.soCongVan}
        </td>
        <td className="gerenal-list-utilities" onClick={this.handleClick}>
          {this.props.item.ngayCapNhat
            ? moment(this.props.item.ngayCapNhat).format('DD/MM/YYYY')
            : null}
        </td>
        <td className="gerenal-list-utilities" onClick={this.handleClick}>
          {this.props.item.soDen}
        </td>
        <td
          className="gerenal-list-utilities textarea-content"
          onClick={this.handleClick}
        >
          {this.props.item.trichYeu}
        </td>
        <td className="gerenal-list-utilities" onClick={this.handleClick}>
          {this.props.item.coQuanBanHanh}
        </td>
      </tr>
    )
  }
}

export default ListItem
