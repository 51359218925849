import React, { memo, useEffect, useRef, useState, useCallback } from 'react'
import { Classes, Checkbox, Button, Intent } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import csx from 'classnames'

import { Icon } from 'components/newCommon'
import { Input, ColorSelect } from 'components/newCommon2'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import { toDecamelizeKeys } from 'helpers/key'
import { postCreateTag, putEditTag, getTag } from 'actions/task'

const Tags = ({ match, onClose = () => {}, reloadList = () => {} }) => {
  const mounted = useRef(false)
  const _mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const { action, id } = match.params
  const isUpdate = action === 'chinh-sua'
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    backgroundColor: '#f1f1f1',
  })
  const [validateErrors, setValidateErrors] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)

  const _changeValidate = (name, value) => {
    _mountedSet(setValidateErrors, {
      ...validateErrors,
      [name]: value,
    })
  }

  const changeFormValue = name => value => {
    _mountedSet(setIsDisabledButtonDefault, false)
    _changeValidate(name)
    _mountedSet(setFormData, { ...formData, [name]: value })
  }

  const changeCheckbox = e => {
    _mountedSet(setIsDisabledButtonDefault, false)
    const { checked } = e.target
    _mountedSet(setFormData, { ...formData, isActive: checked })
  }

  const _validateName = () => {
    if (!get(formData, 'name', '').trim()) {
      _changeValidate('name', true)
      throw new Error('warning')
    }
  }

  const submitCreate = async () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setSubmitLoading, true)
        _validateName()
      },
      action: () => postCreateTag(toDecamelizeKeys(formData)),
      afterResponse: () => {
        reloadList()
        onClose()
      },
      afterAction: () => {
        _mountedSet(setSubmitLoading, false)
        _mountedSet(setIsDisabledButtonDefault, true)
      },
      successCode: 201,
      successMessage: Toast.SUCCESS(Action.CREATE_TAGS),
      errorMessage: Toast.FAIL(Action.CREATE_TAGS),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const submitUpdate = async () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setSubmitLoading, true)
        _validateName()
      },
      action: () => putEditTag(toDecamelizeKeys(formData)),
      afterResponse: () => {
        reloadList()
        onClose()
      },
      afterAction: () => {
        _mountedSet(setSubmitLoading, false)
        _mountedSet(setIsDisabledButtonDefault, true)
      },
      successCode: 200,
      successMessage: Toast.SUCCESS(Action.UPDATE_TAGS),
      errorMessage: Toast.FAIL(Action.UPDATE_TAGS),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const _fetchDetail = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => _mountedSet(setFetching, true),
      action: () => getTag(id),
      afterResponse: result => _mountedSet(setFormData, result),
      afterAction: () => _mountedSet(setFetching, false),
    })
  }, [id])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    if (isUpdate) {
      _fetchDetail()
    }
  }, [_fetchDetail, isUpdate])

  const disableAction = fetching || submitLoading

  return (
    <div className={csx('cpc-side-action', 'open', 'cpc-form')}>
      <h1 className="d-flex justify-space-between align-center text-uppercase font-size-14 font-weight-600 pt15 pb15 pl10 pr10 border-bottom">
        <span>
          {isUpdate ? 'Chỉnh sửa loại công viêc' : 'Tạo loại công việc'}
        </span>
        <Icon
          classIcon="icon-Huy"
          className={'has-event font-size-12 ml10'}
          onClick={onClose}
        />
      </h1>
      <main style={{ padding: 10 }} className={csx('border-bottom', 'mb10')}>
        <Input
          disabled={disableAction}
          label="Tên loại công việc"
          maxLength={500}
          onChange={changeFormValue('name')}
          placeholder="Nhập tên loại công việc"
          required
          value={get(formData, 'name', '')}
          wrapperClassName="mb10"
          warning={get(validateErrors, `name`)}
        />
        <ColorSelect
          inputWrapperClassName="mb10"
          inputIconClassName="icon2-color-blob"
          disabled={disableAction}
          onChange={changeFormValue('backgroundColor')}
          value={get(formData, 'backgroundColor')}
          sampleText={get(formData, 'name')}
        />
        <Checkbox
          className={Classes.SMALL}
          checked={get(formData, 'isActive', false)}
          disabled={disableAction}
          onChange={changeCheckbox}
        >
          <span className="font-size-13">Sử dụng</span>
        </Checkbox>
      </main>
      <div className="element-center">
        <Button
          className={csx(
            'cpc-button',
            'btn-cancel',
            'uppercase',
            'font-size-13',
            'ph10',
            'min-width-100',
            'mr15'
          )}
          disabled={disableAction}
          onClick={onClose}
        >
          <Icon classIcon="icon-back" className="mr5" />
          Quay lại
        </Button>
        <Button
          className={csx('cpc-button', 'uppercase', 'font-size-13', 'ph10')}
          disabled={disableAction || isDisabledButtonDefault}
          intent={Intent.PRIMARY}
          onClick={isUpdate ? submitUpdate : submitCreate}
          loading={submitLoading}
        >
          <Icon classIcon="icon-save" className="mr5" />
          {'Lưu'}
        </Button>
      </div>
    </div>
  )
}

export default memo(withRouter(Tags))
