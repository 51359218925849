import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'
import { Collapse } from '@blueprintjs/core'
import { size, get } from 'lodash'

import * as Types from '../../constants/Api'
import { NotFound, Flowdocumentary } from '../common'
import ExecutingViewBox from './ExecutingViewBox'

class FlowInternalDocumentary extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    flowDocument: PropTypes.object.isRequired,
    hiddenName: PropTypes.bool,
    onClickDepartment: PropTypes.func,
  }

  state = {
    isOpen: {},
    flowDocument: {},
  }

  handleClick(item) {
    if (!item.phongBanTrinhId) {
      return
    }
    this.setState({
      isOpen: {
        ...this.state.isOpen,
        [item.congVanDenId]: !this.state.isOpen[item.congVanDenId],
      },
    })
    if (!this.state.isOpen[item.congVanDenId]) {
      return (
        this.props.onClickDepartment &&
        this.props.onClickDepartment(item.congVanDenId)
      )
    }
  }

  capNhatDanhSachDonViNhan = () => {
    const { flowDocument } = this.props
    this.setState(
      {
        flowDocument: flowDocument,
      },
      () => {
        if (flowDocument && size(flowDocument.dsDonViEvn)) {
          const dsDonViEvnArray = flowDocument.dsDonViEvn
          const dsDonViEvnArray2 = []
          // Nếu có danh sách được gửi cho EVN thì thêm 1 trường để phân biệt trước khi merge vào danh sách đơn vị nhận
          dsDonViEvnArray.map(elm => {
            return dsDonViEvnArray2.push({
              ...elm,
              isDonViEVN: true,
            })
          })
          let newDsDonViNhan = dsDonViEvnArray2.concat(
            get(this.state.flowDocument, 'dsDonViNhan', [])
          )
          this.setState({
            flowDocument: {
              ...this.state.flowDocument,
              dsDonViNhan: newDsDonViNhan,
            },
          })
        }
      }
    )
  }

  componentDidMount() {
    this.capNhatDanhSachDonViNhan()
  }

  checkEVN = data => {
    if (
      data.isDonViEVN ||
      data.donViId === '00000000-0000-0000-0000-000000000000'
    ) {
      return true
    }

    return false
  }

  render() {
    const { flowDocument } = this.state
    return (
      <div className="container-form-action">
        {!this.props.hiddenName && (
          <div className="heading">
            <span className="click-go-back" onClick={this.props.onClickGoBack}>
              <span className="pt-icon pt-icon-arrow-left pull-left"></span>
            </span>
            <h4 className="name-heading">Luồng công văn</h4>
          </div>
        )}
        <div className="form-container container-detail">
          {!this.props.flowDocument.nguoiTaoCongVanNoiBo ? (
            <NotFound />
          ) : (
            <div className="view-job-done">
              {flowDocument.nguoiTaoVanBanKySo && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            flowDocument.nguoiTaoVanBanKySo &&
                            flowDocument.nguoiTaoVanBanKySo.anhDaiDien
                              ? Types.IMAGE_URL +
                                flowDocument.nguoiTaoVanBanKySo.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">
                        {flowDocument.nguoiTaoVanBanKySo &&
                          flowDocument.nguoiTaoVanBanKySo.tenNhanVien}
                      </span>
                    </div>
                  </div>
                  <div className="info-job">
                    {flowDocument.nguoiTaoVanBanKySo.ngayTao && (
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {flowDocument.nguoiTaoVanBanKySo.ngayTao &&
                            ' ' +
                              moment(
                                flowDocument.nguoiTaoVanBanKySo.ngayTao
                              ).format('DD/MM/YYYY HH:mm')}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {flowDocument.phongBanChuTri && flowDocument.nhanVienChuTri && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            flowDocument.nhanVienChuTri &&
                            flowDocument.nhanVienChuTri.anhDaiDien
                              ? Types.IMAGE_URL +
                                flowDocument.nhanVienChuTri.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">
                        {flowDocument.nhanVienChuTri &&
                          flowDocument.nhanVienChuTri.tenNhanVien}
                        &nbsp; / &nbsp;
                        {flowDocument.phongBanChuTri &&
                          flowDocument.phongBanChuTri.maPhongBan}
                      </span>
                    </div>
                  </div>
                  <div className="info-job">
                    {flowDocument.nhanVienChuTri.ngayTao && (
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {flowDocument.nhanVienChuTri.ngayTao &&
                            ' ' +
                              moment(
                                flowDocument.nhanVienChuTri.ngayTao
                              ).format('DD/MM/YYYY HH:mm')}
                        </span>
                        <span className="content">&nbsp; - &nbsp; Đồng ý</span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {flowDocument.dsNhanVienPhongBanPhoiHop &&
                flowDocument.dsNhanVienPhongBanPhoiHop.length > 0 &&
                flowDocument.dsNhanVienPhongBanPhoiHop.map((item, i) => (
                  <div className="box-level-primary item-box" key={i}>
                    <div className="avatar-user">
                      <div className="click-avatar">
                        <span
                          className="avatar"
                          style={{
                            backgroundImage: `url(${
                              item.nhanVienPhoiHop &&
                              item.nhanVienPhoiHop.anhDaiDien
                                ? Types.IMAGE_URL +
                                  item.nhanVienPhoiHop.anhDaiDien
                                : '/images/default_avatar.png'
                            })`,
                          }}
                        ></span>
                        <span className="name_user">
                          {item.nhanVienPhoiHop &&
                            item.nhanVienPhoiHop.tenNhanVien}
                          &nbsp; / &nbsp;
                          {item.phongBanPhoiHop &&
                            item.phongBanPhoiHop.maPhongBan}
                        </span>
                      </div>
                    </div>
                    <div className="info-job">
                      {item.nhanVienPhoiHop && item.nhanVienPhoiHop.ngayTao && (
                        <span className="info-job-item">
                          <span className="title">Ngày:</span>
                          <span className="content">
                            {item.nhanVienPhoiHop &&
                              item.nhanVienPhoiHop.ngayTao &&
                              ' ' +
                                moment(item.nhanVienPhoiHop.ngayTao).format(
                                  'DD/MM/YYYY HH:mm'
                                )}
                          </span>
                          <span className="content">
                            &nbsp; - &nbsp; Đồng ý
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              {flowDocument.lanhDaoDonVi && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            flowDocument.lanhDaoDonVi &&
                            flowDocument.lanhDaoDonVi.anhDaiDien
                              ? Types.IMAGE_URL +
                                flowDocument.lanhDaoDonVi.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">
                        {flowDocument.lanhDaoDonVi &&
                          flowDocument.lanhDaoDonVi.tenNhanVien}
                      </span>
                    </div>
                  </div>
                  <div className="info-job">
                    {flowDocument.lanhDaoDonVi.ngayTao && (
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {flowDocument.lanhDaoDonVi.ngayTao &&
                            ' ' +
                              moment(flowDocument.lanhDaoDonVi.ngayTao).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                        </span>
                        <span className="content">&nbsp; - &nbsp; Đồng ý</span>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {/* {flowDocument.nguoiTaoCongVanNoiBo && (
                <div className="box-level-primary item-box">
                  <div className="avatar-user">
                    <div className="click-avatar">
                      <span
                        className="avatar"
                        style={{
                          backgroundImage: `url(${
                            flowDocument.nguoiTaoCongVanNoiBo &&
                            flowDocument.nguoiTaoCongVanNoiBo.anhDaiDien
                              ? Types.IMAGE_URL +
                                flowDocument.nguoiTaoCongVanNoiBo.anhDaiDien
                              : '/images/default_avatar.png'
                          })`,
                        }}
                      ></span>
                      <span className="name_user">
                        {flowDocument.nguoiTaoCongVanNoiBo &&
                          flowDocument.nguoiTaoCongVanNoiBo.tenNhanVien}
                      </span>
                    </div>
                  </div>
                  <div className="info-job">
                    {flowDocument.nguoiTaoCongVanNoiBo.ngayTao && (
                      <span className="info-job-item">
                        <span className="title">Ngày:</span>
                        <span className="content">
                          {flowDocument.nguoiTaoCongVanNoiBo.ngayTao &&
                            ' ' +
                              moment(
                                flowDocument.nguoiTaoCongVanNoiBo.ngayTao
                              ).format('DD/MM/YYYY HH:mm')}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              )} */}
              {flowDocument.dsCongViecPhongBan && (
                <ExecutingViewBox
                  item={{
                    nguoiChiDao: flowDocument.nguoiTaoCongVanNoiBo,
                    dsCongViecPhongBan: flowDocument.dsCongViecPhongBan,
                  }}
                  {...this.props}
                  auth={this.props.auth}
                />
              )}
              {flowDocument.dsDonViNhan
                ? flowDocument.dsDonViNhan.map((item, i) => {
                    let flowReceiveDocument =
                      this.props.flowReceiveDocument.find(receiveItem => {
                        return receiveItem.congVanDenId === item.congVanDenId
                      })

                    return (
                      <div className="box-level-sub item-box" key={i}>
                        <div
                          className="drop-down-sub"
                          onClick={
                            !this.checkEVN(item)
                              ? this.handleClick.bind(this, item)
                              : null
                          }
                        >
                          <div className="click-avatar">
                            {item.phongBanTrinhId && !this.checkEVN(item) && (
                              <span
                                className={classnames(
                                  'pt-icon click-dropdown',
                                  {
                                    'pt-icon-chevron-up':
                                      this.state.isOpen[item.congVanDenId],
                                    'pt-icon-chevron-down':
                                      !this.state.isOpen[item.congVanDenId],
                                  }
                                )}
                              ></span>
                            )}
                            <span className="name-department">
                              {item.tenVietTat ? item.tenVietTat : ''}
                            </span>
                          </div>
                        </div>
                        {flowReceiveDocument && (
                          <Collapse
                            isOpen={this.state.isOpen[item.congVanDenId]}
                          >
                            <div className="box-level-sub item-box">
                              <Flowdocumentary
                                auth={this.props.auth}
                                onClickDepartment={this.props.onClickDepartment}
                                flowDocument={flowReceiveDocument}
                                daTrinhLanhDao={item.phongBanTrinhId !== null}
                                actions={this.props.actions}
                              />
                            </div>
                          </Collapse>
                        )}
                      </div>
                    )
                  })
                : []}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default FlowInternalDocumentary
