import React, { memo, useContext } from 'react'
import { Tooltip } from '@blueprintjs/core'
import classNames from 'classnames'

import { ResetButton, SearchInput, CheckboxSelect } from 'components/newCommon'
import { TaskContext } from '../AllTask'

const filterKeys = {
  tag: 'TagIds',
  title: 'Title',
  status: 'Status',
  dateType: 'FilterWorkItem.DateType',
}

const Filter = ({ className, onReset }) => {
  const {
    changeFilter = () => {},
    tags = [],
    filter: {
      [filterKeys.tag]: selectedTags = [],
      [filterKeys.title]: title = '',
    } = {},
  } = useContext(TaskContext)

  return (
    <div className={classNames('layout-filter', { [className]: className })}>
      <div className="flex-basis-30">
        <div className="cpc-input-v2-filter">
          <CheckboxSelect
            inputLeftIcon="icon2-documents"
            dataSource={tags.map(tag => ({
              value: tag.id,
              label: tag.name,
            }))}
            onClear={changeFilter({ name: filterKeys.tag })}
            onChange={changeFilter({ name: filterKeys.tag, reFetch: false })}
            onClose={changeFilter({ name: filterKeys.tag })}
            placeholder="Chọn loại công việc"
            selectedAllText="Tất cả loại công việc"
            value={selectedTags}
          />
        </div>
      </div>
      <div className="flex-basis-70">
        <div className="cpc-input-v2-filter-button">
          <SearchInput
            placeholder="Nhập trích yếu/Tiêu đề"
            onChange={changeFilter({ name: filterKeys.title, reFetch: false })}
            onSearch={changeFilter({
              name: filterKeys.title,
            })}
            value={title}
            leftIcon="icon2-search"
          />
          <Tooltip content={<span className="font-size-12">Làm mới</span>}>
            <ResetButton
              onClick={() => {
                onReset()
              }}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default memo(Filter)
