import React, { PureComponent } from 'react'
import TextTruncate from 'react-text-truncate'

class ContentToggle extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      line: 2,
    }
  }

  onClickReadMore = () => {
    this.setState({ line: 10000 })
  }

  render() {
    return (
      <TextTruncate
        containerClassName="content"
        line={this.state.line}
        truncateText="..."
        text={this.props.data}
        textTruncateChild={
          <span className="read-on" onClick={this.onClickReadMore}>
            {' '}
            Xem thêm{' '}
          </span>
        }
      />
    )
  }
}

ContentToggle.defaultProps = {
  data: '',
}

export default ContentToggle
