import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import _ from 'lodash'
import * as Types from '../../constants/Api'
import { LoadingItem, NotFound } from '../../components/common/'
import { Scrollbars } from 'react-custom-scrollbars'

class PhieuYKienChon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataChoose: {},
      isLoading: false,
      chooseItem: '',
      modelData: {
        filter: {
          tinh_trang: 6,
          textSearch: '',
        },
        page: 1,
        sort: undefined,
        canXuLy: false,
      },
      listData: [],
      loadingData: true,
      isEmpty: false,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    let { modelData } = this.state
    let filter = modelData.filter
    await this.setState({ loadingData: true })
    this.props.actions &&
      this.props.actions
        .opinionSendGet(
          { filter },
          modelData.page,
          modelData.sort,
          modelData.canXuLy,
          Types.PAGINATION_LIMIT,
          modelData.filterColumn
        )
        .then(res => {
          if (res.error) {
            this.setState({ loadingData: false, isEmpty: true })
          }
          if (
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.items
          ) {
            this.setState(
              {
                listData: res.payload.data.result.items,
              },
              () => {
                this.setState({
                  loadingData: false,
                })
              }
            )
          }
        })
  }

  /**
   * handle Submit luu data da chon
   * @memberof PhieuYKienChon
   */
  handleSubmit = () => {
    let { dataChoose } = this.state
    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.props.handleSubmit &&
          this.props.handleSubmit(dataChoose, this.props.dataState)
        this.toggleDialog()
      }
    )
  }

  /**
   * toggle Dialog close popup
   * @memberof PhieuYKienChon
   */
  toggleDialog = () => {
    this.props.toggleDialog && this.props.toggleDialog()
  }

  /**
   * Handle input change filter
   * @memberof PhieuYKienChon
   */
  handleInputChange = e => {
    e.preventDefault()
    const target = e.target
    const value = target.value
    this.setState({
      modelData: {
        ...this.state.modelData,
        filter: {
          ...this.state.modelData.filter,
          textSearch: value,
        },
      },
    })
  }

  /**
   * Chọn phiếu yêu cầu item
   * @memberof PhieuYKienChon
   */
  chooseItem(param) {
    if (!_.isEmpty(param)) {
      this.setState({
        dataChoose: param,
      })
    }
    this.setState({
      chooseItem: param,
    })
  }

  render() {
    const { listData } = this.state
    return (
      <div className="dialog-chonphieu-body">
        <div className="dialog-chonphieu-search">
          <input
            type="text"
            name="filter"
            value={this.state.modelData.filter.textSearch}
            onChange={this.handleInputChange}
            className="pt-input"
            placeholder="Tìm kiếm số ký hiệu hoặc trích yếu"
            maxLength={500}
            onKeyDown={e => e.keyCode === 13 && this.getData()}
          />
          <Button
            type="button"
            className="pt-intent-btn-green-shadow btn-icon"
            onClick={this.getData}
          >
            <span className="pt-icon pt-icon-search mr0" />
          </Button>
        </div>
        {!this.state.loadingData ? (
          <div>
            {listData && listData.length !== 0 ? (
              <Scrollbars
                autoHideTimeout={100}
                autoHeight
                autoHeightMin={0}
                autoHeightMax={462}
                autoHideDuration={200}
              >
                <ul>
                  {listData.slice(0, 10).map((item, key) => {
                    return (
                      <li
                        key={key}
                        onClick={() => this.chooseItem(item.phieuLayYKienId)}
                        className={
                          item.phieuLayYKienId === this.state.chooseItem
                            ? 'active'
                            : ''
                        }
                      >
                        <h4>{item.soKyHieu}</h4>
                        <p>{item.trichYeu}</p>
                      </li>
                    )
                  })}
                </ul>
              </Scrollbars>
            ) : (
              <NotFound />
            )}
          </div>
        ) : (
          <div className="loading-container">
            <LoadingItem />
          </div>
        )}
        <div className="buttton-action-footer pb15 pt15">
          <Button className="pt-button btn-cancel" onClick={this.toggleDialog}>
            <span className="pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-blue-color button-icon-left"
            onClick={this.handleSubmit}
            disabled={false}
            loading={this.state.isLoading}
          >
            <span className="pt-icon icon-save"></span>
            <span className="text-content">Lưu</span>
          </Button>
        </div>
      </div>
    )
  }
}

export default PhieuYKienChon
