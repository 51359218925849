import * as types from '../constants/ActionTypes'

const initialState = {
  list: {
    page: 1,
    total: 0,
    items: [],
  },
  detailId: '',
  item: {},
  parentInformationSteering: null,
  information: {},
}

export default function receiveDocuments(state = initialState, action) {
  switch (action.type) {
    case types.API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND:
      return {
        ...state,
        list: initialState.list,
      }

    case types.DOCUMENT_RECEIVE:
      return {
        ...state,
        list: {
          page: action.payload.data.result.result.page,
          total: action.payload.data.result.result.total,
          items:
            action.payload.data.result &&
            action.payload.data.result.result &&
            action.payload.data.result.result.items
              ? action.payload.data.result.result.items
              : [],
        },
      }

    case types.RECEIVE_DOCUMENT_ITEM:
    case types.RECEIVE_DOCUMENT_ITEM_SHORTEN:
      return {
        ...state,
        detailId: action.payload.data.result.result,
      }

    case types.INFOMATION_STEERING:
      let parentInformationSteering = state.parentInformationSteering
      let item = state.item
      if (
        action.meta &&
        action.meta.previousAction &&
        action.meta.previousAction.typeAssign === 'parent'
      ) {
        parentInformationSteering = action.payload.data.result
      } else {
        item = action.payload.data.result
      }
      return {
        ...state,
        item: item,
        parentInformationSteering: parentInformationSteering,
      }

    case types.RECEIVE_DOCUMENT_ITEM_INFORMATION:
      return {
        ...state,
        information: action.payload.data.result,
      }

    default:
      return state
  }
}
