import React from 'react'
import PropTypes from 'prop-types'
import { MasterLayout } from '../../components/layout'
import { Link } from 'react-router-dom'
import { ListItem, ListFilter } from '../../components/AdminNotification'
import { bindActionCreators } from 'redux'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import * as Types from '../../constants/Api'
import { connect } from 'react-redux'
import { GridView } from '../../components/common'
import * as Tool from '../../helpers/'
import * as MESSAGE from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'

import Pagination from 'components/Pagination'

class ListAdminNotificationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tinTongCongTy: false,
      donViId: this.props.auth.mainUnitId,
      isEmpty: false,
      isError: {
        units: false,
        notifications: false,
      },
      isLoadingData: {
        units: true,
        notifications: true,
      },
      page: 1,
      pageCount: 1,
      unitSelect: [],
    }
    this.handleSubmitRemoveItem = this.handleSubmitRemoveItem.bind(this)
    this.handleClickDetail = this.handleClickDetail.bind(this)
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleChangeUnit = this.handleChangeUnit.bind(this)
    this.handleClickCorporation = this.handleClickCorporation.bind(this)
  }

  static propTypes = {
    notifications: PropTypes.object,
  }

  handleChangeUnit(e) {
    if (e !== undefined) {
      this.setState(
        {
          donViId: e,
        },
        () => {
          this.submitFilter(
            Types.PAGE,
            this.state.donViId,
            this.state.tinTongCongTy
          )
        }
      )
    }
  }

  handleClickCorporation = () => {
    this.setState(
      {
        tinTongCongTy: !this.state.tinTongCongTy,
      },
      () => {
        this.submitFilter(
          Types.PAGE,
          this.state.donViId,
          this.state.tinTongCongTy
        )
      }
    )
  }

  handleSubmitRemoveItem = id => {
    let total = 0
    let page = this.state.page
    if (
      this.props.notifications &&
      this.props.notifications.list &&
      this.props.notifications.list.totalAdmin
    ) {
      total = this.props.notifications.list.totalAdmin
    }

    this.props.actions
      .notificationDelete(id)
      .then(res => {
        if (
          res.error ||
          !res.payload ||
          !res.payload.data ||
          res.payload.data.result === false
        ) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
            intent: Intent.DANGER,
          })
        }
        if (total > 0) {
          total -= 1
        }
        if (page > Math.ceil(total / Types.PAGINATION_LIMIT)) {
          page = Math.ceil(total / Types.PAGINATION_LIMIT)
        }

        if (page === 0) {
          page = 1
        }

        this.submitFilter(page, this.state.donViId, this.state.tinTongCongTy)
        Promise.all([
          this.props.actions.notificationFetchList(1, false),
          this.props.actions.notificationFetchList(1, true),
        ]).then(() => this.props.actions.notificationGetLastest())
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_SUCCESS,
          intent: Intent.SUCCESS,
        })
      })
      .catch(err => {
        this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_DELETE_FAIL,
          intent: Intent.DANGER,
        })
      })
  }

  handleClickDetail = id => {
    if (id && this.props.history) {
      this.props.history.push(`/thong-bao/chi-tiet/${id}`)
    }
  }

  clickPagination(page) {
    this.setState({ page }, () => {
      this.submitFilter(
        this.state.page,
        this.state.donViId,
        this.state.tinTongCongTy
      )
    })
  }

  submitFilter(page = 1, donViId = '', tinTongCongTy = false) {
    let donViIdRequest = donViId
    if (tinTongCongTy) {
      donViIdRequest = null
    }
    let isLoadingData = {
      ...this.state.isLoadingData,
      notifications: true,
    }

    this.setState({ page, donViId, tinTongCongTy, isLoadingData }, () => {
      Tool.Utils.preData('adminNotificationList', {
        page,
        donViId,
        tinTongCongTy,
      })
      this.props.actions
        .notificationFetchListAdmin(page, donViIdRequest, tinTongCongTy)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result
          ) {
            let state = this.state
            if (
              res.payload.data.result.result.total &&
              !isNaN(res.payload.data.result.result.total)
            ) {
              state.pageCount = Math.ceil(
                res.payload.data.result.result.total / Types.PAGINATION_LIMIT
              )
            }
            if (res.payload.data.result.result.total === 0) {
              isEmpty = true
            }
          }
          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({
            isEmpty,
            isError: {
              ...this.state.isError,
              notifications: isError,
            },
            isLoadingData: {
              ...this.state.isLoadingData,
              notifications: false,
            },
          })
        })
        .then(() => {
          this.setState({
            isLoadingData: {
              ...isLoadingData,
              notifications: false,
            },
          })
        })
    })
  }

  componentDidMount() {
    // Get api don vi
    let permission =
      this.props.auth &&
      this.props.auth.permission &&
      this.props.auth.permission.find(item => item === 'SUPERADMIN') !==
        undefined
    let request = this.props.actions.getCategoryUnitMultiLevel(
      this.props.auth.mainUnitId
    )
    if (permission) {
      request = this.props.actions.getCategoryUnitMultiLevel()
    }
    request
      .then(res => {
        let state = this.state
        if (
          res.payload &&
          res.payload.status === 200 &&
          this.props.unitSelect &&
          this.props.unitSelect.items
        ) {
          state.unitSelect = this.props.unitSelect.items
        }

        if (res.error || !res.payload || res.payload.status !== 200) {
          state.isError.units = true
        }
        state.isLoadingData.units = false
        this.setState(state)
      })
      .catch(err => {
        this.setState({
          isError: {
            ...this.state.isError,
            units: true,
          },
          isLoadingData: {
            ...this.state.isLoadingData,
            units: false,
          },
        })
      })

    let state = this.state
    let preData = Tool.Utils.preData('adminNotificationList')

    if (preData) {
      state.page = preData.page
      state.donViId = preData.donViId
      state.tinTongCongTy = preData.tinTongCongTy
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  render() {
    const isLoadingData =
      this.state.isLoadingData &&
      Object.values(this.state.isLoadingData).find(item => item === true) !==
        undefined
    const isError =
      this.state.isError &&
      Object.values(this.state.isError).find(item => item === true) !==
        undefined

    const { page, pageCount, isEmpty } = this.state

    return (
      <MasterLayout typeSidebar="HomePage">
        <div className="row center-xs">
          <div className="col-md-8 col-xs-12 col-sm-12 size-width-ListAdminNotification page-ListAdminNotification listDecentralizationUser-container">
            {/* Heading */}
            <div className="heading-page">
              <div className="row">
                <div className="col-xs-12">
                  <h3 className="text-heading-page">
                    <span className="icon-settings icon-heading"></span>
                    <span className="text-heading">Quản lý nội dung</span>
                  </h3>
                  <Link
                    to="/thong-bao/them-moi"
                    className="btn-action pull-right hidden-btn"
                  >
                    <span className="name-btn">Tạo bài viết</span>
                    <span className="pt-icon pt-icon-annotation"></span>
                  </Link>
                </div>
              </div>
            </div>

            {/* Table List */}
            <div className="page-list-container">
              <div className="table-container">
                {isError === false && (
                  <ListFilter
                    data={this.state.unitSelect}
                    type="Unit"
                    permission={false}
                    donViId={
                      this.state.unitSelect.length > 0 ? this.state.donViId : ''
                    }
                    onHandleChange={this.handleChangeUnit}
                    isLoadingUnit={
                      this.state.unitSelect.length > 0 ? false : true
                    }
                    isLoadingData={isLoadingData}
                    isCorporation={this.state.tinTongCongTy || false}
                    onClickCoporation={this.handleClickCorporation}
                    auth={this.props.auth}
                  />
                )}
                <GridView
                  isLoading={isLoadingData}
                  classNames={'pt-table'}
                  page={this.state.page}
                  headers={headers.listAdminNotification}
                  itemShape={ListItem}
                  data={
                    this.props.notifications &&
                    this.props.notifications.list &&
                    this.props.notifications.list.admin
                      ? this.props.notifications.list.admin
                      : []
                  }
                  onHandleSubmitRemoveItem={this.handleSubmitRemoveItem}
                  onClickDetail={this.handleClickDetail}
                  isEmpty={this.state.isEmpty}
                  isError={isError}
                />

                {!isLoadingData && !isError && !isEmpty && (
                  <Pagination
                    current={page}
                    total={pageCount}
                    onChange={this.clickPagination}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },

  notifications: {
    ...state.notifications,
    list: {
      ...state.notifications.list,
      admin:
        state.notifications.list.admin &&
        state.notifications.list.admin.map(
          id => state.entities.notifications[id]
        ),
    },
  },

  unitSelect: {
    ...state.categoryUnitMultiLevel,
    items: state.category.categoryUnitMultiLevel,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListAdminNotificationPage)
