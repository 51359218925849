import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactDOM from 'react-dom'
import { Scrollbars } from 'react-custom-scrollbars'
import { join, size, difference } from 'lodash'

class SelectCheckBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowDropDown: false,
      selectedValue: [],
      checkedValue: [],
      dataSelect: [],
      disabledList: [],
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      dataSelect: props.data,
    })
    if (props.selectedValue && props.selectedValue.length !== 0) {
      this.setState({
        checkedValue: props.selectedValue,
      })
    }

    if (props.disabledList && props.disabledList.length !== 0) {
      this.setState({
        disabledList: props.disabledList,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.isShowDropDown &&
      this.state.isShowDropDown !== prevState.isShowDropDown
    ) {
      document.addEventListener('mousedown', this.handleClick, true)
    }

    if (prevState.checkedValue !== this.state.checkedValue) {
      let labelChecked = []
      this.state.checkedValue.length !== 0 &&
        this.state.checkedValue.forEach(elm => {
          this.state.dataSelect.forEach(element => {
            if (elm === element.value) {
              labelChecked.push(element.label)
            }
          })
        })
      this.setState({
        selectedValue: labelChecked,
      })
    }
  }

  handleClick = event => {
    const domNode = ReactDOM.findDOMNode(this)
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isShowDropDown: false,
      })
      document.removeEventListener('mousedown', this.handleClick, true)
    }
  }

  handleShowSelect = () => {
    this.setState({
      isShowDropDown: !this.state.isShowDropDown,
    })
  }

  handleChangeCheckBox = async e => {
    const { checked = false, value } = e.target
    const { checkedValue } = this.state

    const result = checked
      ? [...checkedValue, value]
      : checkedValue.filter(elm => elm !== value)

    await this.setState({ checkedValue: result })
    this.props && this.props.onValue(result)
  }

  changeAll = async e => {
    const { checked = false } = e.target
    const { dataSelect = [], disabledList = [] } = this.state

    const result = checked
      ? difference(
          dataSelect.map(i => i.value),
          disabledList
        )
      : []

    await this.setState({ checkedValue: result })
    this.props && this.props.onValue(result)
  }

  renderItem = ({ disabled = false, label = '', ...props }) => (
    // props bao gồm: value, onChange, checked
    <li key={props.value}>
      <input {...props} type="checkbox" disabled={disabled} id={props.value} />
      <label className={classnames('name', { disabled })} htmlFor={props.value}>
        {label}
      </label>
    </li>
  )

  renderList = () => {
    const { dataSelect = [], checkedValue = [], disabledList = [] } = this.state

    if (!size(dataSelect)) {
      return (
        <li>
          <span className="not-data">{this.props.textNotData}</span>
        </li>
      )
    }

    return dataSelect.map(elm =>
      this.renderItem({
        disabled: disabledList.includes(elm.value),
        onChange: this.handleChangeCheckBox,
        value: elm.value,
        checked: checkedValue.includes(elm.value),
        label: elm.label,
      })
    )
  }

  render() {
    const { placeholder } = this.props
    const {
      isShowDropDown,
      selectedValue,
      disabledList = [],
      dataSelect = [],
      checkedValue = [],
    } = this.state

    return (
      <div className="select-box-checkbox">
        <div
          className="select-box-input"
          onClick={() => this.handleShowSelect()}
        >
          <input
            readOnly
            type="text"
            placeholder={placeholder}
            onClick={() => this.handleShowSelect()}
            value={join(selectedValue, ', ')}
          />
        </div>
        <ul
          className={classnames('select-box-dropdown', {
            show: isShowDropDown,
          })}
        >
          {/* Checkbox tất cả */}
          {this.renderItem({
            disabled: size(disabledList) === size(dataSelect),
            value: 'all',
            onChange: this.changeAll,
            checked:
              size(dataSelect) - size(disabledList) === size(checkedValue),
            label: 'Tất cả',
          })}
          <Scrollbars
            autoHideTimeout={100}
            autoHeight
            autoHeightMin={0}
            autoHeightMax={200}
            autoHideDuration={100}
          >
            {this.renderList()}
          </Scrollbars>
        </ul>
      </div>
    )
  }
}

SelectCheckBox.defaultProps = {
  data: [],
  disabledList: [],
  placeholder: 'Chọn dữ liệu',
  textNotData: 'Không có dữ liệu...',
  selectedValue: [],
}

SelectCheckBox.propTypes = {
  data: PropTypes.array,
  disabledList: PropTypes.array,
  placeholder: PropTypes.string,
  textNotData: PropTypes.string,
  selectedValue: PropTypes.array,
  onValue: PropTypes.func.isRequired,
}

export default SelectCheckBox
