import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Tool from '../../helpers'

class EvnDocumentItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClickShowDetail: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.handleClickShowDetail = this.handleClickShowDetail.bind(this)
  }

  state = {
    id: '',
    fileId: '',
    fileName: null,
  }

  handleClickShowDetail = () => {
    this.props.onClickShowDetail &&
      this.props.onClickShowDetail({
        id: this.state.id,
        fileId: this.state.fileId,
        fileName: this.state.fileName,
      })
  }

  componentDidMount() {
    this.setState({
      id: this.props.item ? this.props.item.id : '',
      fileId: this.props.item ? this.props.item.fileId : '',
      fileName:
        this.props.item && this.props.item.tenFile
          ? this.props.item.tenFile
          : 'FILE_NAME',
    })
  }

  render() {
    if (!this.props.item) {
      return null
    }

    let date = null
    if (
      this.props.item.ngayCongVan &&
      Tool.Utils.isValidDate(
        moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')
      )
    ) {
      date = moment(this.props.item.ngayCongVan).format('DD/MM/YYYY')
    }

    return (
      <div className="item">
        {date && (
          <p className="date-update" onClick={this.handleClickShowDetail}>
            <span>Ngày công văn:&nbsp;</span>
            {date}
          </p>
        )}
        <p className="code" onClick={this.handleClickShowDetail}>
          {this.props.item.soCongVan}
        </p>
        <p onClick={this.handleClickShowDetail}>
          <span className="label-item">Trích yếu:</span>
          <span className="info textarea-content">
            {this.props.item.trichYeu}
          </span>
        </p>
        <p onClick={this.handleClickShowDetail}>
          <span className="label-item">Người ký:</span>
          <span className="info">{this.props.item.nguoiKy}</span>
        </p>
      </div>
    )
  }
}

export default EvnDocumentItem
