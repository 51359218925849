import React, { useState, memo, useMemo, useCallback, Fragment } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import {
  Popover,
  Position,
  PopoverInteractionKind,
  Menu,
  MenuItem,
  MenuDivider,
  Tooltip,
} from '@blueprintjs/core'

import { MasterLayout } from 'components/layout'
import { Permission } from 'components/auth'
import Title from 'components/title'
import { ViewMoreDots, TruncateTextTooltip } from 'components/common'
import {
  StatusDate,
  PrioritizeFlag,
  Input,
  Select,
  UserTree,
  Avatar,
  TagGroup,
  CheckboxButton,
} from 'components/newCommon'
import { Table, Pagination, Progress } from 'components/newCommon2'
import {
  WORK_STATUS,
  ASSIGN_TYPE,
  WORK_TYPE,
  WORK_APPROVED,
} from 'constants/Enum'

import { getCounterCongViecPhongBanCaNhan } from 'actions/index'
import useTaskPersonal from './hooks/use-task-personal'
import Kanban from './components/kanban'
import Calendar from './components/calendar'
import Timeline from './components/timeline'
import Filter from './components/filter'
import Tabs from './components/tabs'
import TypeIcons from './components/type-icon'
import DeleteTask from './components/confirm/delete-task'

const Index = ({
  actions,
  history,
  match,
  roleId,
  hasShowAll,
  mainDepartmentId,
  currentUserId,
  listWorkTypes,
}) => {
  const type = match.params?.type
  const [dataAfterSortOrUpdate, setDataAfterSortOrUpdate] = useState(null)

  const onUpdateDataAfterSortOrUpdate = () => {
    setDataAfterSortOrUpdate(null)
  }

  const onKanbanUpdateAfterSort = workItem => {
    setDataAfterSortOrUpdate(workItem)
  }

  const {
    calendarRef,
    viewType,
    tab,
    departments,
    filterKeys,
    filterData,
    tags,
    deadlineSource,
    prioritizesSource,
    statusSource,
    reportSource,
    statusApprobalSource,
    readStatusSource,
    listData,
    pagination,
    kanBanData,
    calendarData,
    timelineData,
    rowDisable,
    isFetching,
    isTimelineDefault,
    deleteData,
    setViewType,
    onKanbanUpdateData,
    onKanbanSortWotkItem,
    onKanbanLoadMore,
    onKanbanItemClick,
    fetchUsers,
    onChangeTab,
    onTaskFinish,
    onChangeFilter,
    onRowClick,
    onSetIsTimelineDefault,
    setDeleteData,
  } = useTaskPersonal({
    actions,
    match,
    history,
    type,
    hasShowAll,
    mainDepartmentId,
    onKanbanUpdateAfterSort,
  })

  const getMenuAction = useCallback(
    ({
      id,
      status = WORK_STATUS.CHUA_THUC_HIEN,
      creatorProfileId = null,
      workType,
    } = {}) => {
      const allowAction =
        status === WORK_STATUS.CHUA_THUC_HIEN &&
        creatorProfileId === roleId &&
        workType !== WORK_TYPE.MEETING
      return [
        ...(allowAction
          ? [
              {
                icon: 'icon-but',
                text: 'Sửa công việc',
                onClick: () =>
                  history.push(
                    `/quan-ly-cong-viec/sua-cong-viec/ca-nhan/${id}`
                  ),
              },
            ]
          : []),
        ...(allowAction
          ? [
              {
                icon: 'icon-bin',
                text: 'Xóa công việc',
                onClick: () => setDeleteData({ id: id }),
              },
            ]
          : []),
      ]
    },

    [roleId, history, setDeleteData]
  )

  const tabsOrigin = useMemo(
    () => [
      {
        value: 'LIST',
        name: 'Danh sách',
        icon: 'icon-list',
      },
      ...(type === 'can-thuc-hien'
        ? [
            {
              value: 'KANBAN',
              name: 'Kanban',
              icon: 'icon-board',
            },
          ]
        : []),
      {
        value: 'CALENDAR',
        name: 'Lịch làm việc',
        icon: 'icon-calendar-edit',
      },
      ...(type === 'can-thuc-hien'
        ? [
            {
              value: 'TIMELINE',
              name: 'Timeline',
              icon: 'icon2-timeline',
            },
          ]
        : []),
    ],
    [type]
  )

  const handleStatusDisplay = useMemo(
    () => status => {
      let text = 'Chưa thực hiện'
      let color = 'yellow'
      switch (status) {
        case WORK_STATUS.DANG_THUC_HIEN:
          text = 'Đang thực hiện'
          color = 'blue'
          break
        case WORK_STATUS.HOAN_THANH:
          text = 'Hoàn thành'
          color = 'green'
          break
        case WORK_APPROVED.APPROVED:
          text = 'Đã duyệt'
          color = 'green'
          break
        case WORK_APPROVED.NOT_APPROVED_YET:
          text = '-'
          color = ''
          break
        case WORK_STATUS.HOAN_THANH_CHAM:
          text = 'Hoàn thành chậm'
          color = 'red'
          break
        case WORK_APPROVED.WAITING_APPROVAL:
          text = 'Chờ duyệt'
          color = 'orange'
          break
        default:
          break
      }

      return (
        <span
          className={classNames(
            'status-metting text-uppercase font-size-10 font-weight-600 no-wrap',
            color
          )}
        >
          {text}
        </span>
      )
    },
    []
  )

  const columns = [
    ...(type === 'can-thuc-hien'
      ? [
          {
            key: 'hoanThanh',
            width: 5,
            className: 'align-center element-center max-width-60',
            dataIndex: '',
            render: ({ mainResponsibility, id, status }) => (
              <span onClick={e => e.stopPropagation()}>
                <Tooltip
                  isDisabled={
                    mainResponsibility?.id !== roleId ||
                    status === WORK_STATUS.HOAN_THANH ||
                    rowDisable === id
                  }
                  content={
                    <span className="font-size-13">Hoàn thành nhanh</span>
                  }
                  position={Position.TOP}
                >
                  <CheckboxButton
                    disabled={
                      mainResponsibility?.id !== roleId ||
                      status === WORK_STATUS.HOAN_THANH ||
                      rowDisable === id
                    }
                    styleIcon={{
                      fontSize: '18px',
                    }}
                    onClick={() => onTaskFinish(id)}
                  />
                </Tooltip>
              </span>
            ),
          },
        ]
      : []),
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase element-center',
      className: 'align-center element-center min-width-60',
      title: 'Stt',
      render: (v, r, index) => {
        const num = (pagination?.page - 1) * 10 + index + 1
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'trichYeu',
      width: 40,
      headClass: 'uppercase',
      className: 'align-center min-width-200 max-width-400',
      title: 'Trích yếu / Tiêu đề',
      filter: (
        <Input
          clearable
          onChange={onChangeFilter({
            name: filterKeys.title,
            isReFetch: false,
          })}
          onClear={onChangeFilter({ name: filterKeys.title })}
          onSend={onChangeFilter({ name: filterKeys.title })}
          placeholder="Nhập"
          value={filterData?.[filterKeys.title]}
        />
      ),
      render: ({
        title,
        workItemTag = [],
        workType,
        isDaLuuTuPhongBan,
        isRead,
      }) => {
        return (
          <div>
            <div
              className={classNames({
                'color-blue': !isRead,
              })}
            >
              {title?.length > 20 ? (
                <TruncateTextTooltip content={title} />
              ) : (
                title
              )}
            </div>
            {workItemTag?.length > 0 || workType ? (
              <TagGroup
                className="gap-5 mt5"
                popoverPosition={Position.TOP_LEFT}
                tags={workItemTag.map(({ id, name, backgroundColor }) => ({
                  id: id,
                  name: name,
                  color: backgroundColor,
                }))}
                preChildren={
                  <TypeIcons
                    workType={workType}
                    isDaLuuTuPhongBan={isDaLuuTuPhongBan}
                  />
                }
                activeTags={filterData?.[filterKeys.tags]}
                onClick={({ id }) => {
                  let tagsTemp = [id]
                  if (filterData && filterData?.[filterKeys.tags]) {
                    tagsTemp = filterData?.[filterKeys.tags].includes(id)
                      ? filterData?.[filterKeys.tags].filter(
                          selectedId => selectedId !== id
                        )
                      : [...filterData?.[filterKeys.tags], id]
                  }

                  onChangeFilter({
                    name: [filterKeys.tags],
                  })(tagsTemp)
                }}
              />
            ) : (
              ''
            )}
          </div>
        )
      },
    },
    ...(type === 'can-thuc-hien'
      ? [
          {
            key: 'nguoiGiao',
            width: 5,
            headClass: 'uppercase',
            className: 'min-width-180 align-center',
            title: 'Người giao',
            dataIndex: 'assigner',
            // filter: (),
            render: value => {
              if (!value) {
                return ''
              }

              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    src={value?.avatar}
                    className="mr10 tiny"
                    needBaseUrl
                  />
                  <span>{value?.name}</span>
                </div>
              )
            },
          },
        ]
      : []),
    ...(type !== 'can-thuc-hien'
      ? [
          {
            key: 'chuTri',
            width: 20,
            headClass: 'uppercase',
            className: 'min-width-240 align-center',
            title: 'Chủ trì',
            dataIndex: 'mainResponsibility',
            filter: (
              <UserTree
                dataSource={departments}
                onChange={value =>
                  onChangeFilter({
                    name: filterKeys.executorStorage,
                  })(value)
                }
                onShowCollapse={fetchUsers}
                mainOnly
                placeholder="Chọn"
                value={filterData?.[filterKeys.executorStorage]}
              />
            ),
            render: value => {
              if (value?.type === ASSIGN_TYPE.CA_NHAN) {
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={value?.avatar}
                      className="mr10 tiny"
                      needBaseUrl
                    />
                    <span>{value?.name}</span>
                  </div>
                )
              }

              return value?.shortName
            },
          },
        ]
      : []),
    ...(type !== 'can-thuc-hien'
      ? [
          {
            key: 'phoiHop',
            width: 20,
            headClass: 'uppercase',
            className: 'min-width-160 align-center',
            title: 'Phối hợp',
            dataIndex: 'coordination',
            render: record => {
              const coordinations = record?.filter(e => e?.id)
              if (coordinations?.length === 0) {
                return '-'
              }

              const arrayData = coordinations?.map(elm => {
                const type = elm?.type
                if (
                  type === ASSIGN_TYPE.DON_VI ||
                  type === ASSIGN_TYPE.PHONG_BAN
                ) {
                  return elm?.shortName
                }

                return elm?.name
              })
              return <ViewMoreDots data={arrayData} isTotal={true} />
            },
          },
        ]
      : []),
    {
      key: 'hanThucHien',
      width: 15,
      headClass: 'uppercase',
      className: 'min-width-120 align-center',
      title: 'Hạn thực hiện',
      filter: (
        <Select
          placeholder="Chọn"
          dataSource={deadlineSource}
          onChange={onChangeFilter({ name: filterKeys.workDeadLine })}
          value={filterData?.[filterKeys.workDeadLine]}
        />
      ),
      render: ({ status }, record) => (
        <StatusDate
          date={record?.deadline}
          isCompleted={status === 'DONE'}
          descriptionSize={11}
        />
      ),
    },
    ...(type === 'can-thuc-hien'
      ? [
          {
            key: 'vaiTro',
            width: 5,
            align: 'center',
            headClass: 'uppercase',
            className: 'min-width-80 align-center',
            title: 'Vai trò',
            render: ({ isMainResponsibility, responsibilityType }) => {
              if (!responsibilityType) {
                return ''
              }

              let name = 'Phối hợp'
              let className = ''
              if (responsibilityType === 'MAIN_RESPONSIBILITY') {
                name = 'Chủ trì'
                if (isMainResponsibility) {
                  className = 'text-blue'
                }
              }

              return <span className={className}>{name}</span>
            },
          },
        ]
      : []),
    {
      key: 'uuTien',
      width: 5,
      align: 'center',
      headClass: 'uppercase',
      className: 'min-width-100 align-center',
      title: 'Ưu tiên',
      filter: (
        <Select
          placeholder="Chọn"
          dataSource={prioritizesSource}
          onChange={onChangeFilter({ name: filterKeys.workPriority })}
          value={filterData?.[filterKeys.workPriority]}
        />
      ),
      dataIndex: 'priority',
      render: value => (
        <div>
          <PrioritizeFlag type={value} />
        </div>
      ),
    },
    ...(type !== 'theo-doi'
      ? [
          {
            key: 'canBaoCao',
            width: 5,
            align: 'center',
            headClass: 'uppercase',
            className: 'min-width-80 element-center align-center',
            title: 'Cần BC',
            dataIndex: 'requireReport',
            filter: (
              <Select
                placeholder="Chọn"
                dataSource={reportSource}
                onChange={onChangeFilter({ name: filterKeys.requireReport })}
                value={filterData?.[filterKeys.requireReport]}
              />
            ),
            render: value =>
              value ? <span className="icon2-check color-blue"></span> : '',
          },
        ]
      : []),
    {
      key: 'tienDo',
      className: 'min-width-80 element-center align-center',
      headClass: 'uppercase',
      width: 5,
      title: 'Tiến độ',
      dataIndex: 'progress',
      render: (value = 0) => (
        <div style={{ width: 32 }}>
          <Progress progress={value} status={{ read: true }} />
        </div>
      ),
    },
    ...(type === 'da-giao'
      ? [
          {
            key: 'duyet',
            width: 5,
            headClass: 'uppercase',
            className: 'align-center justify-center min-width-140',
            title: 'Duyệt',
            filter: (
              <Select
                dataSource={statusApprobalSource}
                onChange={onChangeFilter({ name: filterKeys.approval })}
                placeholder="Chọn"
                value={
                  filterData?.[filterKeys.approval]
                    ? filterData?.[filterKeys.approval]
                    : ''
                }
              />
            ),
            render: ({ approval }) => {
              return handleStatusDisplay(approval)
            },
          },
        ]
      : []),
    ...(type === 'theo-doi'
      ? [
          {
            key: 'trangThaiDoc',
            width: 5,
            align: 'center',
            headClass: 'uppercase',
            className: 'min-width-100 align-center justify-center',
            title: 'Trạng thái',
            filter: (
              <Select
                placeholder="Chọn"
                dataSource={readStatusSource}
                onChange={onChangeFilter({ name: filterKeys.isRead })}
                value={
                  filterData?.[filterKeys.isRead]
                    ? filterData?.[filterKeys.isRead]
                    : ''
                }
              />
            ),
            dataIndex: 'isRead',
            render: value =>
              value ? (
                <span>Đã đọc</span>
              ) : (
                <span className="color-blue">Chưa đọc</span>
              ),
          },
        ]
      : []),
    {
      key: 'thaoTac',
      className: 'element-center max-width-60 min-width-60 align-center',
      width: 5,
      render: record => {
        const menu = getMenuAction(record) || []
        if (menu?.length === 0) return null
        return (
          <Popover
            position={Position.BOTTOM_RIGHT}
            interactionKind={PopoverInteractionKind.HOVER}
            content={
              <Menu className="cpc-context-menu border-none">
                {menu.map(({ icon, text, onClick = () => {} }, index) => (
                  <Fragment key={index}>
                    {!!index && <MenuDivider />}
                    <MenuItem
                      text={
                        <span className="cpc-context-menu-item">
                          <i className={classNames('size-icon-15', icon)} />{' '}
                          {text}
                        </span>
                      }
                      onClick={onClick}
                    />
                  </Fragment>
                ))}
              </Menu>
            }
          >
            <span
              className="icon-More size-icon-24"
              onClick={e => e.stopPropagation()}
            />
          </Popover>
        )
      },
    },
  ]

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="mt10">
        <Title
          name={
            type === 'can-thuc-hien'
              ? 'Công việc cá nhân chưa thực hiện'
              : type === 'da-giao'
              ? 'Công việc cá nhân đã giao'
              : 'Công việc cá nhân theo dõi'
          }
          icon={
            type === 'can-thuc-hien'
              ? 'icon-Chua_Thuc_Hien'
              : type === 'da-giao'
              ? 'icon-Da_Giao'
              : 'icon-Xemdebiet'
          }
        />
        <div className="mt15">
          <Tabs
            className={'border task-tabs'}
            dataSource={tabsOrigin || []}
            onChange={onChangeTab}
            selected={tab}
          />
        </div>
        <div className="border">
          <Filter
            tab={tab}
            hasStatus={tab !== 'TIMELINE' && tab !== 'KANBAN'}
            labelFromTo={'Ngày giao'}
            filterKeys={filterKeys}
            dataSource={{ tags, statusSource, listWorkTypes }}
            dataValue={filterData}
            onChangeFilter={onChangeFilter}
          />
          {tab === 'LIST' ? (
            <div>
              <Table
                loading={isFetching}
                error={false}
                className="cpc-table-giaodienmoi"
                autoHide={false}
                columns={columns}
                data={listData?.items}
                onRowClick={onRowClick}
                onContextMenu={() => {}}
                tableMinWidth={1400}
              />
              {listData?.totalCount > pagination?.limit && (
                <div className="text-center bg-white ph15 pv20 border-top">
                  <Pagination
                    total={listData?.totalCount}
                    current={pagination?.page}
                    pageSize={pagination?.limit}
                    onChange={onChangeFilter({ name: 'PAGE' })}
                  />
                </div>
              )}
            </div>
          ) : null}
          {tab === 'KANBAN' ? (
            <Kanban
              currentUserId={currentUserId}
              dataSource={kanBanData}
              dataAfterSortOrUpdate={dataAfterSortOrUpdate}
              onUpdateData={onKanbanUpdateData}
              onSortWotkItem={onKanbanSortWotkItem}
              onKanbanLoadMore={onKanbanLoadMore}
              onUpdateDataAfterSortOrUpdate={onUpdateDataAfterSortOrUpdate}
              onItemClick={onKanbanItemClick}
            />
          ) : null}
          {tab === 'CALENDAR' ? (
            <Calendar
              calendarRef={calendarRef}
              viewType={viewType}
              filterKeys={filterKeys}
              match={match}
              history={history}
              dataSource={calendarData}
              filterData={filterData}
              setViewType={setViewType}
              onChange={onChangeFilter}
              onEventItemClick={onRowClick}
            />
          ) : null}
          {tab === 'TIMELINE' ? (
            <Timeline
              filterKeys={filterKeys}
              filterData={filterData}
              isFetching={isFetching}
              dataSource={timelineData}
              isDefault={isTimelineDefault}
              onSetIsDefault={onSetIsTimelineDefault}
              onItemClick={onRowClick}
              onChange={onChangeFilter}
            />
          ) : null}
        </div>
        {deleteData ? (
          <DeleteTask
            history={history}
            dataSource={deleteData}
            onClose={() => setDeleteData(null)}
            onSuccess={() => onChangeFilter({ name: 'RESET' })()}
          />
        ) : (
          ''
        )}
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => {
  const hasShowAll = state.auth?.permission.includes(
    Permission.CONGVANDEN_CHIDAO
  )

  return {
    currentUserId: state.auth?.user?.nhanVienId,
    hasShowAll,
    roleId: state.auth?.roleId,
    mainDepartmentId: state.auth?.mainDepartmentId,
    listWorkTypes: state.TaskManager?.listWorkTypes,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCounterCongViecPhongBanCaNhan,
    },
    dispatch
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(Index))
)
