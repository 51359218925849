import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Tabs = ({ dataSource, className, selected, onChange }) => {
  return (
    <ul className={classNames(className)}>
      {dataSource?.map((elm, index) => (
        <li
          key={index}
          className={classNames({
            selected: selected === elm?.value,
          })}
          onClick={() => onChange(elm)}
        >
          <div className="item">
            {elm?.icon ? (
              <span className={classNames('icon', elm?.icon)}></span>
            ) : (
              ''
            )}
            <span className="name">{elm?.name}</span>
          </div>
        </li>
      ))}
    </ul>
  )
}

Tabs.propTypes = {
  dataSource: PropTypes.array.isRequired,
  className: PropTypes.string,
  selected: PropTypes.string || PropTypes.number,
  onChange: PropTypes.func,
}

Tabs.defaultProps = {
  dataSource: [],
  className: '',
  selected: '',
  onChange: () => {},
}

export default Tabs
