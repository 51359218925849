import React, { memo, useMemo, useState, useCallback, useContext } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from 'react-calendar-timeline'
import classNames from 'classnames'
import { Button } from '@blueprintjs/core'

import { WORK_STATUS, WORK_TYPE } from 'constants/Enum'
import { MESSAGE_NOT_FOUND } from 'constants/MessageForm'
import { Avatar, Empty, Loading } from 'components/newCommon'
import { CalendarTimelineFilter } from '../Filter'
import { TaskContext } from '../AllTask'
const requestDateFormat = 'YYYY/MM/DD'
const imageWorkEmpty = `${process.env.PUBLIC_URL}/images/empty.svg`

const CalendarTimeline = ({ match, history }) => {
  let scrollRefs
  const { type: taskType } = match.params
  const mainPathname = useMemo(
    () => `/quan-ly-cong-viec/${taskType}`,
    [taskType]
  )

  const {
    filter,
    fetchStatus,
    pageFilter,
    timelineData = [],
    changeFilter = () => {},
  } = useContext(TaskContext)

  const [isVisible, setIsVisible] = useState(true)

  const [defaultTimeStart, setDefaultTimeStart] = useState(
    moment().startOf('month').toDate()
  )
  const [defaultTimeEnd, setDefaultTimeEnd] = useState(
    moment().startOf('month').add(1, 'month').toDate()
  )
  const [visibleTimeStart, setVisibleTimeStart] = useState(
    moment().startOf('month').valueOf()
  )
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    moment().startOf('month').add(1, 'month').valueOf()
  )

  const onReset = useCallback(() => {
    const values = {
      [pageFilter.keys.fromDate]: moment()
        .subtract(1, 'months')
        .format(requestDateFormat),
      [pageFilter.keys.toDate]: moment().format(requestDateFormat),
      [pageFilter.keys.title]: '',
      [pageFilter.keys.tag]: [],
    }

    changeFilter({ multipleKey: true })(values)

    if (!isEmpty(scrollRefs)) {
      scrollRefs.changeZoom(1)
    }
    setDefaultTimeStart(moment().startOf('month').toDate())
    setDefaultTimeEnd(moment().startOf('month').add(1, 'month').toDate())
    setVisibleTimeStart(moment().startOf('month').valueOf())
    setVisibleTimeEnd(moment().startOf('month').add(1, 'month').valueOf())
  }, [pageFilter, changeFilter, scrollRefs])

  const groupConvert = value => {
    const isPersonal = value?.mainResponsibility?.type === 'PERSONAL'
    return {
      ...value,
      key: value?.id,
      id: value?.mainResponsibilityId,
      title: (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {isPersonal ? (
            <Avatar
              src={value?.mainResponsibility?.avatar}
              className="mr10"
              tinytan
              needBaseUrl
            />
          ) : (
            ''
          )}
          <span
            style={{ whiteSpace: 'normal', lineHeight: '18px' }}
            className={classNames('text-color font-size-12')}
          >
            {isPersonal
              ? value?.mainResponsibility?.name
              : value?.mainResponsibility?.shortName}
          </span>
        </div>
      ),
      bgColor: '#000',
    }
  }

  const itemConvert = value => {
    let bg = 'rgba(229, 232, 237, 1)'
    let start = moment(value?.executionDate)
    let end = moment(value?.deadline)
    if (value?.status === WORK_STATUS.CHUA_THUC_HIEN) {
      bg = 'rgba(252, 126, 0, 1)'
    } else if (value?.status === WORK_STATUS.DANG_THUC_HIEN) {
      bg = 'rgba(25, 95, 255, 1)'
    } else if (value?.status === WORK_STATUS.HOAN_THANH) {
      bg = 'rgba(8, 180, 36, 1)'
    }

    if (!value?.executionDate && value?.deadline) {
      let stringDate = moment(value?.deadline).format('YYYY/MM/DD 00:00:00')
      if (value?.creationTime) {
        stringDate = moment(value.creationTime).format('YYYY/MM/DD 00:00:00')
      }

      start = moment(stringDate)
    }

    if (!value?.deadline && value?.executionDate) {
      const stringDate = moment(value?.executionDate)
        .add(20, 'years')
        .format('YYYY/MM/DD 23:59:59')
      end = moment(stringDate)
    }

    return {
      ...value,
      key: value?.id,
      id: value?.id,
      group: value?.mainResponsibilityId,
      title: value?.title,
      start: start,
      end: end,
      bgColor: bg,
      selectedBgColor: bg,
      color: '#ffffff',
    }
  }

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
    const backgroundColor = item?.bgColor
    // const borderColor = item?.color

    return (
      <div
        {...getItemProps({
          className: 'item-active',
          style: {
            backgroundColor,
            background: backgroundColor,
            color: item?.color,
            // borderColor,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext?.selected ? 3 : 1,
            borderRightWidth: itemContext?.selected ? 3 : 1,
          },
          onMouseDown: () => {
            const url = `${mainPathname}/chi-tiet/${item?.id}`
            return history.push(url)
          },
        })}
      >
        {itemContext?.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          className="timeline-item"
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
          }}
        >
          {item?.workType === WORK_TYPE.MEETING ? (
            <span className="icon2-clipboard"></span>
          ) : (
            ''
          )}
          {item?.workType === WORK_TYPE.EOFFICE ? (
            <span className="icon2-e-office-label"></span>
          ) : (
            ''
          )}
          {item?.workType === WORK_TYPE.DOFFICE ? (
            <span className="icon2-d-office"></span>
          ) : (
            ''
          )}
          {item?.workType === WORK_TYPE.CHAT ? (
            <span className="icon2-chat-type"></span>
          ) : (
            ''
          )}
          {item?.workType === WORK_TYPE.SERVICE_DESK ? (
            <span className="icon2-call-type"></span>
          ) : (
            ''
          )}
          <span className="name">{itemContext?.title}</span>
        </div>
        {itemContext?.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    )
  }

  const onTimeChange = (
    visibleTimeStart,
    visibleTimeEnd,
    updateScrollCanvas
  ) => {
    setIsVisible(false)
    const minTime = moment().add(-6, 'months').valueOf()
    const maxTime = moment().add(6, 'months').valueOf()
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime)
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
    }
  }

  const handleItemMove = () => {}

  const handleItemResize = () => {}

  const onPrevClick = () => {
    const values = {
      [pageFilter.keys.fromDate]: moment(filter?.FromDate)
        .subtract(1, 'months')
        .format(requestDateFormat),
      [pageFilter.keys.toDate]: filter?.FromDate,
    }

    changeFilter({ multipleKey: true })(values)

    setDefaultTimeStart(moment(filter?.FromDate).startOf('month').toDate())
    setDefaultTimeEnd(
      moment(filter?.FromDate).startOf('month').add(1, 'month').toDate()
    )
    setVisibleTimeStart(moment(filter?.FromDate).startOf('month').valueOf())
    setVisibleTimeEnd(moment(filter?.FromDate).endOf('month').valueOf())

    // const zoom = visibleTimeEnd - visibleTimeStart
    // setVisibleTimeStart(visibleTimeStart - zoom)
    // setVisibleTimeEnd(visibleTimeEnd - zoom)
  }

  const onNextClick = () => {
    const values = {
      [pageFilter.keys.fromDate]: filter?.ToDate,
      [pageFilter.keys.toDate]: moment(filter?.ToDate)
        .subtract(-1, 'months')
        .format(requestDateFormat),
    }

    changeFilter({ multipleKey: true })(values)

    setDefaultTimeStart(moment(filter?.ToDate).endOf('month').toDate())
    setDefaultTimeEnd(
      moment(filter?.ToDate).endOf('month').add(1, 'month').toDate()
    )
    setVisibleTimeStart(
      moment(filter?.ToDate).subtract(-1, 'months').startOf('month').valueOf()
    )
    setVisibleTimeEnd(
      moment(filter?.ToDate).subtract(-1, 'months').endOf('month').valueOf()
    )

    // const zoom = visibleTimeEnd - visibleTimeStart
    // setVisibleTimeStart(visibleTimeStart + zoom)
    // setVisibleTimeEnd(visibleTimeEnd + zoom)
  }

  const onZoomOutClick = () => {
    if (!isEmpty(scrollRefs)) {
      setIsVisible(false)
      scrollRefs.changeZoom(2)
    }
  }

  const onZoomInClick = () => {
    if (!isEmpty(scrollRefs)) {
      setIsVisible(false)
      scrollRefs.changeZoom(0.5)
    }
  }

  const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start',
    itemTimeEndKey: 'end',
  }

  return (
    <div>
      <CalendarTimelineFilter className={'mb15'} onReset={onReset} />
      <div className="timeline-calendar">
        <div className="timeline-calendar-header-right">
          <div className="timeline-calendar-arrow">
            <Button
              className="timeline-button"
              disabled={false}
              onClick={onPrevClick}
            >
              <span className="icon2-arrow-left font-size-10 icon-size-10 mr5"></span>
              <span className="font-size-12">Tháng trước</span>
            </Button>
            <Button
              className="timeline-button"
              disabled={false}
              onClick={onNextClick}
            >
              <span className="font-size-12">Tháng sau</span>
              <span className="icon2-arrow-right font-size-10 icon-size-10 ml5"></span>
            </Button>
          </div>
          <div className="timeline-calendar-arrow ml10">
            <Button
              className="timeline-button"
              disabled={false}
              onClick={onZoomOutClick}
            >
              <span className="icon-round-delete-button1 font-size-14 icon-size-14"></span>
            </Button>
            <Button
              className="timeline-button"
              disabled={false}
              onClick={onZoomInClick}
            >
              <span className="icon-rounded-add-button1 font-size-14 icon-size-14"></span>
            </Button>
          </div>
        </div>
        {!fetchStatus?.load ? (
          <Timeline
            keys={keys}
            ref={el => (scrollRefs = el)}
            sidebarWidth={180}
            lineHeight={40}
            stackItems
            itemHeightRatio={0.75}
            traditionalZoom={true}
            buffer={1}
            minZoom={7 * 24 * 60 * 60 * 1000}
            maxZoom={5 * 365.24 * 86400 * 1000} // Thời gian lớn nhất lịch có thể zoom (5 năm)
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            visibleTimeStart={isVisible ? visibleTimeStart : null}
            visibleTimeEnd={isVisible ? visibleTimeEnd : null}
            canMove={false}
            canResize={false}
            groups={
              timelineData
                ? uniqBy(timelineData, 'mainResponsibilityId')?.map(
                    groupConvert
                  )
                : []
            }
            items={timelineData?.map(itemConvert) ?? []}
            onTimeChange={onTimeChange}
            itemRenderer={itemRenderer}
            onItemMove={handleItemMove}
            onItemResize={handleItemResize}
            // onZoom={(timelineContext, unit) => {
            //   console.log(timelineContext, unit)
            // }}
          >
            <TimelineHeaders className="sticky">
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <div {...getRootProps()}>
                      <span>Chủ trì</span>
                    </div>
                  )
                }}
              </SidebarHeader>
              <DateHeader unit="primaryHeader" height={50} />
              <DateHeader />
            </TimelineHeaders>
          </Timeline>
        ) : (
          ''
        )}
        {size(timelineData) === 0 && !fetchStatus?.load ? (
          <div
            className="d-flex item-center justify-center"
            style={{ minHeight: 279, backgroundColor: '#fff' }}
          >
            <Empty
              image={imageWorkEmpty}
              size="SMALL"
              message={MESSAGE_NOT_FOUND}
            />
          </div>
        ) : (
          ''
        )}
        {fetchStatus?.load && (
          <div style={{ backgroundColor: '#fff' }}>
            <Loading />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({})

export default withRouter(connect(mapStateToProps)(memo(CalendarTimeline)))
