import React, { Component } from 'react'
import { Button } from '@blueprintjs/core'
import { PAGE, STATUS_EVN_DOC } from '../../constants/Api'

class ListFilterEvn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: props.status || 0,
      textSearch: props.textSearch || '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeInput = this.handleChangeInput.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.textSearch !== this.props.textSearch) {
      this.setState({ textSearch: nextProps.textSearch })
    }
    if (nextProps.status !== this.props.status) {
      this.setState({ status: nextProps.status })
    }
  }

  handleChange(e) {
    let value = e.target.value
    this.setState(
      {
        status: value,
      },
      () => this.handleSubmit()
    )
  }

  handleChangeInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    this.props.submitFilter &&
      this.props.submitFilter(PAGE, this.state.status, this.state.textSearch)
  }

  clearFilter() {
    this.setState(
      {
        status: 0,
        textSearch: '',
      },
      () => {
        this.handleSubmit()
        this.props.actions.getNumberEVNDocument()
      }
    )
  }

  render() {
    return (
      <div className="search-container search-DocumentarySearch pt15 pb30 px-sm-15">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-xs-6 col-sm-3 col-md-2 mt10 mt-sm-0">
              <div>
                <label className="search-label">Trạng thái</label>
                <div className="mt5 form-input-filter select-arrow border-radius-3">
                  <select
                    className="input border-transparent appearance-none"
                    value={this.state.status}
                    onChange={this.handleChange}
                  >
                    {STATUS_EVN_DOC.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-9 col-md-10 mt20 mt-sm-0 align-items-end">
              <div className="form-input-filter flex-auto border-radius-3">
                <input
                  autoFocus={false}
                  autoComplete="off"
                  name="textSearch"
                  value={this.state.textSearch}
                  placeholder="Tìm kiếm Số CV, Trích yếu (hoặc Số CV:Trích yếu)"
                  type="text"
                  className="input border-transparent pr-40"
                  onChange={this.handleChangeInput}
                  maxLength="255"
                />
                <Button
                  type="submit"
                  className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
                >
                  <span className="pt-icon pt-icon-search mr0"></span>
                </Button>
              </div>
              <Button
                onClick={this.clearFilter}
                className="btn-icon flex-solid ml10 pt-intent-btn-white"
              >
                <span className="pt-icon icon-Loading mr0"></span>
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default ListFilterEvn
