import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExecutingViewBox from './ExecutingViewBox'
import NotFound from './NotFound'
import ExecutingViewBoxItems from './ExecutingViewBoxItems'

class ExecutingView extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func,
    executingViews: PropTypes.array.isRequired,
    hiddenName: PropTypes.bool,
  }
  render() {
    return (
      <div className="container-form-action">
        {!this.props.hiddenName && (
          <div className="heading">
            <span className="click-go-back" onClick={this.props.onClickGoBack}>
              <span className="pt-icon pt-icon-arrow-left pull-left"></span>
            </span>
            <h4 className="name-heading">Xem thực hiện</h4>
          </div>
        )}
        <div className="form-container container-detail view-job-done">
          {this.props.executingViews &&
            this.props.executingViews.length === 0 && <NotFound />}
          {!this.props.isNewAssignDoc &&
            this.props.executingViews &&
            this.props.executingViews.map((item, i) => {
              return (
                item && <ExecutingViewBox item={item} key={i} {...this.props} />
              )
            })}
          {this.props.isNewAssignDoc &&
            this.props.executingViews &&
            this.props.executingViews.map((item, i) => {
              return (
                item && (
                  <ExecutingViewBoxItems item={item} key={i} {...this.props} />
                )
              )
            })}
        </div>
      </div>
    )
  }
}

export default ExecutingView
