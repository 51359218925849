import React, { memo } from 'react'
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { get, size, isEmpty } from 'lodash'
import classNames from 'classnames'

import { ASSIGN_TYPE } from 'constants/Enum'
import Avatar from './Avatar'
import { UserCard } from '../index'

const AvatarGroup = ({
  list = [],
  small = false,
  needBaseUrl = false,
  limit = 3,
  position = Position.TOP,
}) => {
  const showList = list.slice(0, limit)
  const hideList = list.slice(limit)
  return (
    <div className="cpc-avatar-group">
      {showList.map(({ avatar, information, name, shortName, type }, index) => {
        let isShowAvatar = true
        if (
          type &&
          (type === ASSIGN_TYPE.DON_VI || type === ASSIGN_TYPE.PHONG_BAN)
        ) {
          isShowAvatar = false
        }

        const handleShowName = () => {
          return (
            <div className="avatar-name">
              <span>{shortName}</span>
            </div>
          )
        }

        return (
          <Popover
            key={index}
            content={
              <div className="pt5 pr10 pb5 pl10">
                <span className={classNames('font-size-13', 'font-weight-600')}>
                  {name}
                </span>
                {!isEmpty(information) && (
                  <p className={classNames('font-size-11')}>
                    {get(information, 'department.shortName') || ''}
                    {get(information, 'department.shortName') &&
                    get(information, 'organization.shortName')
                      ? ' - '
                      : ''}
                    {get(information, 'organization.shortName') || ''}
                  </p>
                )}
              </div>
            }
            position={position}
            interactionKind={PopoverInteractionKind.HOVER}
          >
            {isShowAvatar ? (
              <Avatar needBaseUrl={needBaseUrl} src={avatar} small={small} />
            ) : (
              handleShowName()
            )}
          </Popover>
        )
      })}

      {!!size(hideList) && (
        <div className="ml5">
          <Popover
            content={
              <div
                style={{
                  padding: 12,
                  maxHeight: 200,
                  overflowY: 'auto',
                }}
              >
                {hideList.map(({ avatar, information, name, type }, index) => {
                  let isShowAvatar = true
                  if (
                    type &&
                    (type === ASSIGN_TYPE.DON_VI ||
                      type === ASSIGN_TYPE.PHONG_BAN)
                  ) {
                    isShowAvatar = false
                  }

                  return (
                    <UserCard
                      key={index}
                      isShowAvatar={isShowAvatar}
                      needBaseUrl={needBaseUrl}
                      src={avatar}
                      name={name}
                      className={classNames(!!index && 'mt10')}
                      department={get(information, 'department.shortName')}
                      unit={get(information, 'organization.shortName')}
                    />
                  )
                })}
              </div>
            }
            interactionKind={PopoverInteractionKind.HOVER}
            popoverClassName={classNames('cpc-popover', 'label-group-popover')}
            position={position}
          >
            <span className="label-counter">+ {size(hideList)}</span>
          </Popover>
        </div>
      )}
    </div>
  )
}

export default memo(AvatarGroup)
