import React, { memo, useRef, useState, useEffect } from 'react'
import { TextArea } from '@blueprintjs/core'
import { get } from 'lodash'
import csx from 'classnames'

const CustomTextArea = ({
  className,
  onChange: areaChange = () => {},
  onSend: areaSend,
  value: areaValue = '',
  ...props
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current') && setFunction(state)

  const [text, _setText] = useState('')

  const onChange = e => {
    const { value } = e.target
    _mountedSet(_setText, value)
    areaChange(value)
  }

  const onKeyPress = e => {
    const { key } = e
    if (areaSend && key === 'Enter') {
      areaSend(text)
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(_setText, areaValue)
  }, [areaValue])

  return (
    <TextArea
      {...props}
      className={csx('cpc-textarea', className)}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={text}
    />
  )
}

export default memo(CustomTextArea)
