import React from 'react'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { InputForm } from '../../components/SignNumber'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import * as Actions from '../../actions/'
import withRouter from 'react-router/withRouter'
import * as MESSAGE from '../../constants/MessageForm'
import * as Types from '../../constants/Api'
import { get } from 'lodash'
const UNIT_TYPE = 'parent'

const signNumberType = {
  KYSODI: 'kySoDi',
  KYSONOIBO: 'kySoNoiBo',
}
class InputDocumentSignNumberPage extends React.Component {
  static propTypes = {
    departments: PropTypes.object.isRequired,
    leaderCombinations: PropTypes.object.isRequired,
    unitsEqual: PropTypes.object.isRequired,
    signNumberDepartmentLeaders: PropTypes.object.isRequired,
    personalLeaderSignNumber: PropTypes.object.isRequired,
    signNumber: PropTypes.object,
    auth: PropTypes.object.isRequired,
    unitItem: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getUploadProgress = this.getUploadProgress.bind(this)

    this.state = {
      mainDepartmentId: this.props.auth
        ? this.props.auth.mainDepartmentId
        : null,
      parentUnitId: this.props.auth ? this.props.auth.parentUnitId : null,
      mainUnitId: this.props.auth ? this.props.auth.mainUnitId : null,
      signNumberId: '',
      isUpdate: false,
      isLoadingData: {
        departments: true,
        parentDepartments: true,
        lanhDaoDonViId: true,
        lanhDaoPhongBanChuTriId: true,
        tenThanhPho: true,
        signNumber: true,
        lanhDaoDonViCapTrenId:
          this.props.auth && this.props.auth.parentUnitId ? true : false,
        electricUnits: true,
      },
      isCanUpdate: true,
      isSubmitFail: false,
      signNumber: null,
      type: signNumberType.KYSODI,
      contentFileProgress: [0],
      attachFilesProgress: [0],
      dinhKemFilesProgress: [0],
      isCanChoseSignNumberRelativeUnit: false,
      isCanGetLeaderDepartment: false,
      isAllowShowDepartmentsOfOtherUnits: false,
      isPc: false,
      fileTrinhKy: null,
    }
  }

  componentWillMount() {
    let state = this.state
    if (
      this.props.match &&
      this.props.match.path &&
      this.props.match.path.indexOf('/ky-so/cong-van-noi-bo') > -1
    ) {
      state.type = signNumberType.KYSONOIBO
    } else {
      state.isLoadingData = {
        ...state.isLoadingData,
        lanhDaoDonViCapTrenId: false,
      }
    }
    if (
      this.props.match.path === '/ky-so/cong-van-di/chinh-sua/:id' ||
      this.props.match.path === '/ky-so/cong-van-noi-bo/chinh-sua/:id'
    ) {
      state.signNumberId = this.props.match.params.id
      state.isUpdate = true
      state.isLoadingData = {
        ...state.isLoadingData,
        tenThanhPho: false,
      }
    } else {
      state.isLoadingData = {
        ...state.isLoadingData,
        signNumber: false,
      }
    }
    this.setState(state)
  }

  getUploadProgress = (name, i) => progress => {
    let state = this.state
    if (!isNaN(i) && name) {
      state[name][i] = Math.floor((progress.loaded * 100) / progress.total)
    }
    this.setState(state)
  }

  async componentDidMount() {
    // phong ban phoi hop cua don vi cap tren
    if (
      this.state.parentUnitId &&
      this.state.type === signNumberType.KYSONOIBO
    ) {
      // page = 1, donViId = null, isAll = false, phongBanLanhDao = null, isParent = false
      await this.props.actions
        .commonFetchDepartments(
          Types.PAGE,
          this.state.parentUnitId,
          null,
          null,
          true
        )
        .finally(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              parentDepartments: false,
            },
          })
        })
    } else {
      await this.setState({
        isLoadingData: {
          ...this.state.isLoadingData,
          parentDepartments: false,
        },
      })
    }
    if (this.state.mainUnitId) {
      await this.props.actions
        .commonSignNumberDepartmentLeader(this.state.mainUnitId)
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              lanhDaoDonViId: false,
            },
          })
        })
      // Kiem tra don vi co duoc gui cong van noi bo lien don vi khong
      let isAllowShowDepartmentsOfOtherUnits = false
      let isPc = false
      await this.props.actions
        .getCommonFetchUnitItem(this.state.mainUnitId)
        .then(async res => {
          let isCanChoseSignNumberRelativeUnit =
            this.state.isCanChoseSignNumberRelativeUnit
          let isCanGetLeaderDepartment = Types.TYPE_DEPARTMENT
          if (
            res &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result
          ) {
            isCanChoseSignNumberRelativeUnit =
              res.payload.data.result.kySoLienDonVi
            if (this.state.type === signNumberType.KYSONOIBO) {
              isCanGetLeaderDepartment = res.payload.data.result
                .lanhDaoKyPhoiHop
                ? Types.TYPE_LEADER_DEPARTMENT
                : Types.TYPE_COMMON_DEPARTMENT
            }
            isAllowShowDepartmentsOfOtherUnits =
              res.payload.data.result.guiCongVanNoiBoLienDonVi
            isPc = res.payload.data.result.isPc
            if (isAllowShowDepartmentsOfOtherUnits || isPc) {
              // phong ban chuyen den don vi khac
              await this.props.actions.commonFetchElectricUnits(
                this.state.mainUnitId
              )
            }
          }
          await this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              tenThanhPho: false,
              electricUnits: false,
            },
            isCanChoseSignNumberRelativeUnit,
            isCanGetLeaderDepartment,
            isAllowShowDepartmentsOfOtherUnits,
            isPc,
          })
          // phong ban phoi hop cua don vi hien tai
          if (this.state.type === signNumberType.KYSODI) {
            await this.props.actions
              .commonFetchDepartments(
                Types.PAGE,
                null,
                false,
                Types.TYPE_COMMON_DEPARTMENT
              )
              .finally(async () => {
                await this.setState({
                  isLoadingData: {
                    ...this.state.isLoadingData,
                    departments: false,
                  },
                })
              })
          } else {
            await this.props.actions
              .commonFetchDepartments()
              .finally(async () => {
                await this.setState({
                  isLoadingData: {
                    ...this.state.isLoadingData,
                    departments: false,
                  },
                })
              })
          }

          // get ds lanh dao ky phoi hop
          if (this.state.type === signNumberType.KYSODI) {
            await this.props.actions
              .commonFetchLeaderCombination(this.props.auth.mainUnitId)
              .finally(async () => {
                await this.setState({
                  isLoadingData: {
                    ...this.state.isLoadingData,
                    departments: false,
                  },
                })
              })
          }
        })
    }
    if (
      this.state.parentUnitId &&
      this.state.type === signNumberType.KYSONOIBO
    ) {
      await this.props.actions
        .commonSignNumberDepartmentLeader(this.state.parentUnitId, UNIT_TYPE)
        .then(async () => {
          await this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              lanhDaoDonViCapTrenId: false,
            },
          })
        })
    }
    if (this.state.mainDepartmentId) {
      await this.props.actions
        .commonPersonalLeaderSignNumber(this.state.mainDepartmentId)
        .then(async () => {
          await this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              lanhDaoPhongBanChuTriId: false,
            },
          })
        })
      await this.props.actions.contactFetchUnitsEqual(
        this.state.mainDepartmentId
      )
    }
    if (
      this.state.signNumberId &&
      this.props.match.path === '/ky-so/cong-van-di/chinh-sua/:id'
    ) {
      return this.props.actions
        .signNumberDetail(this.state.signNumberId)
        .then(() => {
          let state = this.state
          if (this.props.signNumber && this.props.signNumber.item) {
            if (
              this.props.signNumber.item.tinhTrang === Types.PROGRESSION ||
              this.props.signNumber.item.tinhTrang === Types.RELEASED ||
              this.props.signNumber.item.tinhTrang === Types.LEADER_UNIT
            ) {
              state.isCanUpdate = false
            }
            state.signNumber = this.props.signNumber.item
            state.isLoadingData.signNumber = false
          }
          this.setState(state)
        })
    }
    if (
      this.state.signNumberId &&
      this.props.match.path === '/ky-so/cong-van-noi-bo/chinh-sua/:id'
    ) {
      return this.props.actions
        .signNumberInternalDetail(this.state.signNumberId)
        .then(() => {
          let state = this.state
          if (this.props.signNumber && this.props.signNumber.item) {
            if (
              this.props.signNumber.item.tinhTrang === Types.PROGRESSION ||
              this.props.signNumber.item.tinhTrang === Types.RELEASED
            ) {
              state.isCanUpdate = false
            }
            if (
              this.props.signNumber.item.tinhTrang >= Types.SECRETARY &&
              this.props.signNumber.item.tinhTrang <= Types.RELEASED &&
              this.props.signNumber.item.lanhDaoDonViPhoiHop
            ) {
              state.isCanUpdate = false
            }
            state.signNumber = this.props.signNumber.item
            state.isLoadingData.signNumber = false
          }
          this.setState(state)
        })
    }
  }

  handleSubmit = async (data, isValidData, message, isUpdate) => {
    if (!isValidData) {
      if (message) {
        return this.props.actions.commonAddToasterMessage({
          message: message,
          intent: Intent.WARNING,
        })
      }
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TOATS_MESSAGE_WARNING,
        intent: Intent.WARNING,
      })
    }

    await this.setState({ isSubmitFail: false })
    let contentFile = data.fileNoiDung
    let attachFiles = data.fileThamKhao.filter(s => !!s)
    let dinhKemFiles = data.dsFileDinhKem.filter(s => !!s)
    let attachFilesProgress = []
    let dinhKemFilesProgress = []
    if (data.files.contentFiles.length) {
      try {
        await this.props.actions
          .fileUpload(
            data.files.contentFiles,
            this.getUploadProgress('contentFileProgress', 0)
          )
          .then(res => {
            contentFile = {
              file_id: res.payload.data.result[0].id,
              kieu_file: res.payload.data.result[0].extension,
              ten_file: res.payload.data.result[0].originalName,
              url: res.payload.data.result[0].path,
              kich_thuoc: res.payload.data.result[0].length,
            }
          })
      } catch (e) {
        this.setState({ isSubmitFail: true })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    }

    delete data.files.contentFile

    if (data.files.attachFiles.length) {
      attachFilesProgress = Array(data.files.attachFiles.length).fill(0)
      await this.setState({ attachFilesProgress })
      try {
        await Promise.all(
          data.files.attachFiles.map(async (item, i) => {
            if (!!item) {
              const data = await this.props.actions.fileUpload(
                item,
                this.getUploadProgress('attachFilesProgress', i)
              )
              return attachFiles.push({
                file_id: data.payload.data.result[0].id,
                kieu_file: data.payload.data.result[0].extension,
                ten_file: data.payload.data.result[0].originalName,
                url: data.payload.data.result[0].path,
                kich_thuoc: data.payload.data.result[0].length,
                is_existed: false,
              })
            }
          })
        )
      } catch (e) {
        this.setState({ isSubmitFail: true })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    }

    delete data.files.attachFiles
    attachFiles = [...attachFiles, ...data.files.fileThamKhaoTuCongViecPb]
    delete data.files.fileThamKhaoTuCongViecPb
    if (data.files.dinhKemFiles.length) {
      dinhKemFilesProgress = Array(data.files.dinhKemFiles.length).fill(0)
      await this.setState({ dinhKemFilesProgress })
      try {
        await Promise.all(
          data.files.dinhKemFiles.map(async (item, i) => {
            if (!!item) {
              const data = await this.props.actions.fileUpload(
                item,
                this.getUploadProgress('dinhKemFilesProgress', i)
              )
              return dinhKemFiles.push({
                file_id: data.payload.data.result[0].id,
                kieu_file: data.payload.data.result[0].extension,
                ten_file: data.payload.data.result[0].originalName,
                url: data.payload.data.result[0].path,
                kich_thuoc: data.payload.data.result[0].length,
                is_existed: false,
              })
            }
          })
        )
      } catch (e) {
        this.setState({ isSubmitFail: true })
        return this.props.actions.commonAddToasterMessage({
          message: MESSAGE.TOATS_MESSAGE_FAIL,
          intent: Intent.DANGER,
        })
      }
    }
    delete data.files.dinhKemFiles

    dinhKemFiles = [...dinhKemFiles, ...data.files.fileDinhKemTuCongViecPb]
    delete data.files.fileDinhKemTuCongViecPb
    let dataRequest = {
      so_ky_hieu: data.soKyHieu,
      ten_thanh_pho: data.tenThanhPho,
      trich_yeu: data.trichYeu,
      lanh_dao_phong_ban_chu_tri_id: data.lanhDaoPhongBanChuTriId,
      lanh_dao_don_vi_id: data.lanhDaoDonViId,
      phoi_hop_ids: data.phoiHopIds,
      don_vi_cung_ky_ids: data.donViCungKyIds,
      file_tham_khao: attachFiles,
      file_noi_dung: contentFile,
      ds_file_dinh_kem: dinhKemFiles,
      noi_dung_trinh: data.noiDungTrinh,
      gui_ban_giay: data.guiBanGiay,
      van_ban_goc_id: data.vanBanGocId,
    }

    if (this.state.type === signNumberType.KYSONOIBO) {
      dataRequest = {
        ...dataRequest,
        chuyen_den_ids: data.chuyenDenIds,
        phoi_hop_cap_tren_ids: data.phoiHopCapTrenIds,
        lanh_dao_don_vi_cap_tren_id: data.lanhDaoDonViCapTrenId,
        lanh_dao_phoi_hop_ids: data.lanhDaoPhoiHopIds,
        chuyen_den_phong_ban_don_vi_khac_ids:
          data.chuyenDenPhongBanDonViKhacIds,
        ds_don_vi_cung_ky: data.dsDonViCungKy,
        chuyen_den_phong_ban_cap_tren_ids: data.chuyenDenPhongBanCapTrenIds,
      }
    } else {
      dataRequest = {
        ...dataRequest,
        lanh_dao_don_vi_phoi_hop_id: data.lanhDaoDonViPhoiHopId,
        lanh_dao_phoi_hop_ids: data.lanhDaoKyPhoiHopIds,
      }
    }

    if (!isUpdate) {
      if (this.props.match.path === '/ky-so/cong-van-di/them-moi') {
        this.props.actions
          .signNumberCreate(dataRequest)
          .then(res => {
            if (res.error) {
              this.setState({ isSubmitFail: true })
              let error = MESSAGE.TOATS_MESSAGE_FAIL
              if (
                res.error &&
                res.error.response &&
                res.error.response.data &&
                res.error.response.data.message
              ) {
                error = res.error.response.data.message
              }
              return this.props.actions.commonAddToasterMessage({
                message: error,
                intent: Intent.DANGER,
              })
            }
            this.setState({ signNumber: null })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            // Refresh danh sach ky so di
            this.props.actions.refreshSendSignNumber(true)
            this.props.history.push('/ky-so/cong-van-di/danh-sach')
          })
          .catch(err => {
            this.setState({ isSubmitFail: true })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          })
      } else if (this.props.match.path === '/ky-so/cong-van-noi-bo/them-moi') {
        this.props.actions
          .signNumberInternalCreate(dataRequest)
          .then(res => {
            if (res.error) {
              this.setState({ isSubmitFail: true })
              let error = MESSAGE.TOATS_MESSAGE_FAIL
              if (
                res.error &&
                res.error.response &&
                res.error.response.data &&
                res.error.response.data.message
              ) {
                error = res.error.response.data.message
              }
              return this.props.actions.commonAddToasterMessage({
                message: error,
                intent: Intent.DANGER,
              })
            }
            this.setState({ signNumber: null })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            // Refresh danh sach ky so noi bo
            this.props.actions.refreshInternalSignNumber(true)
            this.props.history.push('/ky-so/cong-van-noi-bo/danh-sach')
          })
          .catch(err => {
            this.setState({ isSubmitFail: true })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          })
      }
    } else {
      if (
        this.props.match.path.indexOf('/ky-so/cong-van-di/chinh-sua/') === 0
      ) {
        this.props.actions
          .sendSignNumberUpdate(this.state.signNumberId, dataRequest)
          .then(res => {
            if (res.error) {
              this.setState({ isSubmitFail: true })
              let error = MESSAGE.TOATS_MESSAGE_FAIL
              if (
                res.error &&
                res.error.response &&
                res.error.response.data &&
                res.error.response.data.message
              ) {
                error = res.error.response.data.message
              }
              return this.props.actions.commonAddToasterMessage({
                message: error,
                intent: Intent.DANGER,
              })
            }
            this.setState({ signNumber: null })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            // Refresh danh sach ky so di
            this.props.actions.refreshSendSignNumber(true)
            this.props.history.goBack()
          })
          .catch(err => {
            this.setState({ isSubmitFail: true })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          })
      } else if (
        this.props.match.path.indexOf('/ky-so/cong-van-noi-bo/chinh-sua/') === 0
      ) {
        this.props.actions
          .internalSignNumberUpdate(this.state.signNumberId, dataRequest)
          .then(res => {
            if (res.error) {
              this.setState({ isSubmitFail: true })
              let error = MESSAGE.TOATS_MESSAGE_FAIL
              if (
                res.error &&
                res.error.response &&
                res.error.response.data &&
                res.error.response.data.message
              ) {
                error = res.error.response.data.message
              }
              return this.props.actions.commonAddToasterMessage({
                message: error,
                intent: Intent.DANGER,
              })
            }
            this.setState({ signNumber: null })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
            // Refresh danh sach ky so noi bo
            this.props.actions.refreshInternalSignNumber(true)
            this.props.history.goBack()
          })
          .catch(err => {
            this.setState({ isSubmitFail: true })
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_FAIL,
              intent: Intent.DANGER,
            })
          })
      }
    }
  }

  convertFormData = data => {
    let contentFile = data.fileNoiDung
    let attachFiles = data.fileThamKhao.filter(s => !!s)
    let dinhKemFiles = data.dsFileDinhKem.filter(s => !!s)
    let dataRequest = {
      so_ky_hieu: data.soKyHieu,
      ten_thanh_pho: data.tenThanhPho,
      trich_yeu: data.trichYeu,
      lanh_dao_phong_ban_chu_tri_id: data.lanhDaoPhongBanChuTriId,
      lanh_dao_don_vi_id: data.lanhDaoDonViId,
      phoi_hop_ids: data.phoiHopIds,
      don_vi_cung_ky_ids: data.donViCungKyIds,
      file_tham_khao: attachFiles,
      file_noi_dung: contentFile,
      ds_file_dinh_kem: dinhKemFiles,
      noi_dung_trinh: data.noiDungTrinh,
      gui_ban_giay: data.guiBanGiay,
      van_ban_goc_id: data.vanBanGocId,
    }

    if (this.state.type === signNumberType.KYSONOIBO) {
      dataRequest = {
        ...dataRequest,
        chuyen_den_ids: data.chuyenDenIds,
        phoi_hop_cap_tren_ids: data.phoiHopCapTrenIds,
        lanh_dao_don_vi_cap_tren_id: data.lanhDaoDonViCapTrenId,
        lanh_dao_phoi_hop_ids: data.lanhDaoPhoiHopIds,
        chuyen_den_phong_ban_don_vi_khac_ids:
          data.chuyenDenPhongBanDonViKhacIds,
        ds_don_vi_cung_ky: data.dsDonViCungKy,
        chuyen_den_phong_ban_cap_tren_ids: data.chuyenDenPhongBanCapTrenIds,
      }
    } else {
      dataRequest = {
        ...dataRequest,
        lanh_dao_don_vi_phoi_hop_id: data.lanhDaoDonViPhoiHopId,
        lanh_dao_phoi_hop_ids: data.lanhDaoKyPhoiHopIds,
      }
    }

    return dataRequest
  }

  handlePreview = async data => {
    const { postFileTrinhKy, postFileTrinhKyNoiBo } = this.props.actions
    const { type } = this.state
    const dataRequest = this.convertFormData(data)
    let response = null
    let fileTrinhKy = null
    if (type === signNumberType.KYSODI) {
      response = await postFileTrinhKy({
        data: JSON.stringify(dataRequest),
        file: data.files.contentFiles[0],
      })
    } else {
      response = await postFileTrinhKyNoiBo({
        data: JSON.stringify(dataRequest),
        file: data.files.contentFiles[0],
      })
    }

    if (get(response, 'payload.data')) {
      fileTrinhKy = get(response, 'payload.data')
    }

    this.setState({
      fileTrinhKy,
    })

    return true
  }

  render() {
    const location = this.props.location.pathname
    const { isCanUpdate, isUpdate, fileTrinhKy } = this.state

    if (isCanUpdate === false && isUpdate === true) {
      return null
    }

    return (
      <MasterLayout typeSidebar="signNumber" collapseSideBar={true}>
        <div className="row center-xs format-center">
          <div className="col-md-8 col-xs-12 col-sm-12 padding-0 form-width-90">
            <div className="row">
              <div className="col-xs-12">
                <div className="heading-page bottom-add-receive-document">
                  <h3 className="pull-left text-heading-page">
                    <span className="icon-TaoCVDiKyso icon-heading"></span>
                    <span className="text-heading">
                      {location === '/ky-so/cong-van-di/them-moi' &&
                        'Tạo công văn đi ký số'}
                      {location === '/ky-so/cong-van-noi-bo/them-moi' &&
                        'Tạo công văn nội bộ ký số'}
                      {location.indexOf('/ky-so/cong-van-di/chinh-sua/') ===
                        0 && 'Sửa công văn đi ký số'}
                      {location.indexOf('/ky-so/cong-van-noi-bo/chinh-sua/') ===
                        0 && 'Sửa công văn nội bộ ký số'}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <InputForm
              departments={this.props.departments.items}
              leaderCombinations={this.props.leaderCombinations.items}
              departmentSigned={this.props.personalLeaderSignNumber.items}
              unitsEqual={this.props.unitsEqual.items}
              unitsLeader={this.props.signNumberDepartmentLeaders.items}
              cityName={this.props.unitItem && this.props.unitItem.thanhPho}
              onSubmit={this.handleSubmit}
              mainDepartmentId={this.state.mainDepartmentId}
              mainUnitId={this.state.mainUnitId}
              signNumber={this.state.signNumber}
              isUpdate={this.state.isUpdate}
              isLoadingData={this.state.isLoadingData}
              isSubmitFail={this.state.isSubmitFail}
              type={this.state.type}
              parentUnitId={this.state.parentUnitId}
              parentDepartments={this.props.parentDepartments.items}
              parentUnitsLeaders={
                this.props.parentSignNumberDepartmentLeaders.items
              }
              contentFileProgress={this.state.contentFileProgress}
              attachFilesProgress={this.state.attachFilesProgress}
              dinhKemFilesProgress={this.state.dinhKemFilesProgress}
              isCanChoseSignNumberRelativeUnit={
                this.state.isCanChoseSignNumberRelativeUnit
              }
              isCanGetLeaderDepartment={
                this.state.isCanGetLeaderDepartment !==
                Types.TYPE_COMMON_DEPARTMENT
              }
              isAllowShowDepartmentsOfOtherUnits={
                this.state.isAllowShowDepartmentsOfOtherUnits
              }
              isPc={this.state.isPc}
              electricUnits={this.props.electricUnits}
              actions={this.props.actions}
              auth={this.props.auth}
              handlePreview={this.handlePreview}
              fileTrinhKy={fileTrinhKy}
            />
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
  },

  departments: {
    items: state.common.departments,
  },
  leaderCombinations: {
    items: state.common.leaderCombinations,
  },
  parentDepartments: {
    items: state.common.parentDepartments,
  },

  unitsEqual: {
    items: state.common.unitsEqual,
  },

  signNumberDepartmentLeaders: {
    items: state.common.signNumberDepartmentLeaders,
  },

  parentSignNumberDepartmentLeaders: {
    items: state.common.differentSignNumberDepartmentLeaders,
  },

  personalLeaderSignNumber: {
    ...state.personalLeaderSignNumber,
    items: state.common.personalLeaderSignNumber,
  },

  signNumber: {
    ...state.signNumbers,
    item: state.signNumbers.detailId
      ? state.entities.signNumbers[state.signNumbers.detailId]
      : {},
    listFeedbacks: {
      ...state.signNumbers.listFeedbacks,
      items: state.signNumbers.listFeedbacks.items.map(
        item => state.entities.signNumberFeedbacks[item]
      ),
    },
  },

  unitItem: state.common.unitItem,

  electricUnits: state.common.electricUnits,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InputDocumentSignNumberPage)
)
