import React, { Component } from 'react'
import RowTableCollapse from './RowTableCollapse'
import { LoadingItem } from 'components/common'

class TableCollapse extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isLoadingData: false,
    }
  }

  render() {
    return (
      <table className="table-collapse pt-table border">
        <thead>
          <tr>
            <th style={{ width: 50 }}>
              <span className="text-sort"> STT</span>
            </th>
            <th style={{ width: 100 }}>
              <span className="text-sort"> Chức năng</span>
            </th>
            <th width={'25%'}>
              <span className="text-sort"> Danh sách api</span>
            </th>
            <th width={'25%'}>
              <span className="text-sort"> SecuityKey</span>
            </th>
            <th style={{ width: 75 }}>
              <span className="text-sort"> method</span>
            </th>
            <th style={{ width: 162 }}>
              <span className="text-sort"> Endpoint</span>
            </th>
            <th style={{ width: 75, textAlign: 'center' }}>
              <span className="text-sort"> SỬ DỤNG</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {this.props.isLoading && (
            <tr className="loading-container">
              <td colSpan={7}>
                <LoadingItem />
                <LoadingItem />
              </td>
            </tr>
          )}
          {!this?.props?.isLoading &&
            this?.props?.dataSource?.map((item, index) => {
              return (
                <RowTableCollapse
                  onCheckList={this?.props?.onCheckList}
                  listApiId={this?.props?.listApiId}
                  handleCheckListAll={this?.props?.handleCheckListAll}
                  key={item?.categoryId}
                  number={index + 1}
                  data={item}
                  id={`row${index + 1}`}
                />
              )
            })}
          {/* Item */}
        </tbody>
      </table>
    )
  }
}

export default TableCollapse
