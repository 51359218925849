import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import PropTypes from 'prop-types'
import Tree, { TreeNode } from 'rc-tree'
import classname from 'classnames'

class CheckBoxTree extends React.Component {
  constructor() {
    super()
    this.onCheck = this.onCheck.bind(this)
    this.onExpand = this.onExpand.bind(this)
    this.renderChildren = this.renderChildren.bind(this)
  }

  static propTypes = {
    data: PropTypes.array,
    onChange: PropTypes.func,
    checkedList: PropTypes.array,
  }

  state = {
    expandedKeys: [],
    autoExpandParent: true,
    checkedKeys: [],
    selectedKeys: [],
    treeData: [],
  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    })
  }

  onCheck = checkedKeys => {
    this.setState(
      {
        checkedKeys: this.props.checkStrictly
          ? checkedKeys.checked
          : checkedKeys,
      },
      () => {
        this.props.onChange && this.props.onChange(this.state.checkedKeys)
      }
    )
  }

  renderChildren = data => {
    const disabledList = this.props.disabledList || []
    return data.map(item => {
      let value =
        !!this.props.isDonViLienThong || !!this.props.isDonViLienThongEVN
          ? `${item.donViId},${item.maDonVi},${item.tenDonVi}`
          : item.donViId

      if (item.dsDonViCon) {
        return (
          <TreeNode
            key={value}
            title={item.tenVietTat || item.tenDonVi}
            disableCheckbox={
              disabledList.indexOf(value) > -1 || this.props.isDisabledAll
            }
            className={classname({
              [this.props.disabledClass || 'disabled-tree-node']:
                disabledList.indexOf(value) > -1 || this.props.isDisabledAll,
            })}
          >
            {this.renderChildren(item.dsDonViCon)}
          </TreeNode>
        )
      }
      return (
        <TreeNode
          key={value}
          title={item.tenVietTat || item.tenDonVi}
          disableCheckbox={
            disabledList.indexOf(value) > -1 || this.props.isDisabledAll
          }
          className={classname({
            [this.props.disabledClass || 'disabled-tree-node']:
              disabledList.indexOf(value) > -1 || this.props.isDisabledAll,
          })}
        />
      )
    })
  }

  componentDidMount() {
    if (this.props.checkedList !== undefined) {
      this.setState({
        checkedKeys: this.props.checkedList,
        expandedKeys: this.props.checkedList,
      })
    }
    this.props.checkStrictly &&
      this.onExpand(['06914739-d280-e711-80cd-40167e7b9c6a'])
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.checkedList !== this.props.checkedList &&
      this.props.checkedList !== undefined
    ) {
      this.setState({
        checkedKeys: this.props.checkedList,
      })
    }
  }

  render() {
    return (
      <div className="checkbox-tree-content">
        <Scrollbars
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={242}
          autoHide
        >
          <Tree
            checkStrictly={this.props.checkStrictly || false}
            checkable
            onExpand={this.onExpand}
            expandedKeys={this.state.expandedKeys}
            autoExpandParent={this.state.autoExpandParent}
            onCheck={this.onCheck}
            checkedKeys={this.state.checkedKeys}
            showIcon={false}
            selectable={false}
          >
            {this.renderChildren(this.props.data)}
          </Tree>
        </Scrollbars>
      </div>
    )
  }
}

export default CheckBoxTree
