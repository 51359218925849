import React, { Component } from 'react'

import DatePicker from 'react-datepicker'
import classnames from 'classnames'
import {
  EmptyDataTable,
  ErrorLoadingTable,
  LoadingTable,
} from '../../../components/common'
import { get } from 'lodash'

const datePickerConfig = {
  readOnly: true,
  dateFormat: 'DD/MM/YYYY',
  peekNextMonth: true,
  showMonthDropdown: true,
  showYearDropdown: true,
  dropdownMode: 'select',
  className:
    'input-datepicker input-icon-datepicker gridview-filter custom-grid-filter',
  popperPlacement: 'auto',
  popperModifiers: {
    offset: {
      enabled: true,
      offset: '30px, 5px',
    },
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  },
  placeholderText: 'Nhập',
}

const getObjectFilter = (array = [], defaultValue = '') =>
  array.reduce((obj, cur) => {
    obj[cur.nameGridView] = defaultValue
    return obj
  }, {})

const getDefaultFilter = header => ({
  text: getObjectFilter(header.filter(i => i.typeGridView === 'input')),
  date: getObjectFilter(
    header.filter(i => i.typeGridView === 'date'),
    null
  ),
  sort: {},
})

class SearchDispatchGridViewFilter extends Component {
  constructor(props) {
    super(props)
    this.defaultFilter = getDefaultFilter(props.header)
    this.state = {
      filter: this.defaultFilter,
    }
  }

  getHeaderFilter = ({
    type,
    name,
    classNames,
    value,
    onChange,
    onSubmit,
    onClearInput,
    key,
  }) => {
    switch (type) {
      case 'date':
        return (
          <th
            className={classnames(
              'daterangepicker-gridview-filter',
              classNames
            )}
            key={key}
          >
            <div className="daterangepicker-group daterangepicker-gridview">
              <DatePicker
                {...datePickerConfig}
                isClearable={window.innerWidth >= 530}
                selected={value}
                onChange={date => onChange(name, date)}
              />
            </div>
          </th>
        )
      case 'input':
        return (
          <th className={classnames('position-gridview', classNames)} key={key}>
            <input
              name={name}
              maxLength={255}
              style={{ width: '100%' }}
              className="pt-input custom-grid-filter padding-right-input-grid-filter"
              onKeyPress={e => e.key === 'Enter' && onSubmit()}
              onChange={onChange}
              value={value || ''}
              placeholder="Nhập"
            />
            {value && (
              <i
                className="icon-close pt-close-gridview"
                onClick={() => {
                  onClearInput([name])
                }}
              />
            )}
          </th>
        )
      default:
        return <th className={classNames} key={key}></th>
    }
  }

  filterChange = async (type, name, value, replace = false) => {
    await this.setState(prev => ({
      filter: {
        ...prev.filter,
        [type]: {
          ...(!replace && prev.filter[type]),
          [name]: value,
        },
      },
    }))
    if (type === 'date' || type === 'sort') {
      this.submitFilter()
    } else if (type === 'text' && this.props.textFilterChange) {
      this.props.textFilterChange(this.state.filter)
    }
  }

  textFilterChange = e => {
    const { name, value } = e.target
    this.filterChange('text', name, value)
  }

  clearTextFilter = name => {
    this.filterChange('text', name, '')
    this.submitFilter()
  }

  dateFilterChange = (name, date) => {
    this.filterChange('date', name, date)
  }

  sortFilterChange = (name, type) => {
    this.filterChange('sort', name, type === 'DESC' ? 'ASC' : 'DESC', true)
  }

  submitFilter = () => {
    if (this.props.submitFilter) {
      this.props.submitFilter(this.state.filter)
    }
  }

  resetFilter = () => {
    this.setState({ filter: this.defaultFilter })
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.resetFilter &&
      nextProps.resetFilter !== this.props.resetFilter
    ) {
      this.resetFilter()
    }
  }

  render() {
    const {
      data = [],
      loading,
      error,
      classNames,
      header,
      currentPage,
      goToDetails,
    } = this.props
    const {
      filter: { text: textFilter, date: dateFilter, sort: sortFilter },
    } = this.state
    const empty = !data.length
    const tableCol = header.length

    return (
      <div
        className={classnames('table-scroll item-gridview-filter', {
          'table-scroll-hidden': loading || error || empty,
        })}
        style={{ minHeight: '450px' }}
      >
        <table
          className={classnames(classNames, 'fix-table-before')}
          style={{ position: 'relative' }}
        >
          {!!tableCol && !error && (
            <thead>
              <tr>
                {header.map((item, index) => {
                  const { sort: sortName } = item
                  const sortType = get(sortFilter, `${item.sort}`, 'DESC')

                  return (
                    <th className={item.classNames} key={index}>
                      <span className="sort-colum">
                        <span className="text-sort">{item.value}</span>
                        {item.sort && (
                          <span
                            className={classnames(
                              'pt-icon',
                              `${
                                sortType === 'DESC'
                                  ? 'pt-icon-chevron-down'
                                  : 'pt-icon-chevron-up'
                              }`
                            )}
                            onClick={() =>
                              this.sortFilterChange(sortName, sortType)
                            }
                          />
                        )}
                      </span>
                    </th>
                  )
                })}
              </tr>
              <tr>
                {header.map((item, index) => {
                  let value, onChange, onClearInput
                  if (item.typeGridView === 'input') {
                    value = textFilter[item.nameGridView]
                    onChange = this.textFilterChange
                    onClearInput = this.clearTextFilter
                  } else if (item.typeGridView === 'date') {
                    value = dateFilter[item.nameGridView]
                    onChange = this.dateFilterChange
                  }

                  return this.getHeaderFilter({
                    type: item.typeGridView,
                    name: item.nameGridView,
                    classNames: item.classNames,
                    value,
                    onChange,
                    onClearInput,
                    onSubmit: this.submitFilter,
                    key: index,
                  })
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {loading || error || empty ? (
              <tr className="no-pointer">
                <td colSpan={tableCol}>
                  {loading ? (
                    <LoadingTable />
                  ) : error ? (
                    <ErrorLoadingTable />
                  ) : (
                    empty && <EmptyDataTable />
                  )}
                  {false}
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <this.props.itemShape
                  key={index}
                  data={item}
                  index={(currentPage - 1) * 10 + index + 1}
                  handleClick={goToDetails}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default SearchDispatchGridViewFilter
