import React from 'react'
import { MasterLayout } from '../../components/layout'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GridView, DetailFileDinhKem } from '../../components/common'
import { ListItemSynthesis } from '../../components/Opinion'
import { headers } from '../../constants/Table'
import * as Tool from '../../helpers'
import _ from 'lodash'
import { opinionDetail } from '../../actions'
import { LoadingItem } from '../../components/common/'
import * as Types from '../../constants/Api'

class OpinionSynthesis extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opinions: {
        dataDetail: this.props.dataDetail || {},
        id: this.props.match.params.id || '',
      },
      dataTable: {
        isLoading: true,
        page: 1,
      },
      isEmpty: false,
    }
  }

  componentDidMount = async () => {
    this.getDetail()
  }

  /**
   * Gọi Api chi tiết
   * @param {number} id ý kiến id
   * @returns {void} get chi tiết ý kiến
   * @memberof OpinionSynthesis
   */
  getDetail = (id = this.state.opinions.id) => {
    if (_.isEmpty(id)) {
      return
    }
    this.props.actions.opinionDetail(id).then(res => {
      if (res.error) {
        this.setState({ isError: true, isEmpty: true })
      }
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          isLoading: false,
        },
      })
    })
  }

  /**
   * Table heading sort
   * @param {number} sort sort
   * @memberof OpinionSynthesis
   */
  handleSort = sort => {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  /**
   * Filter data
   * @param {object} filter filter model
   * @param {number} page page number value
   * @param {string} sort sort params
   * @memberof OpinionSynthesis
   */
  submitFilter = (filter, page, sort) => {}

  /**
   * Go back
   * @memberof OpinionSynthesis
   */
  goBack() {
    this.props.history.goBack()
  }

  render() {
    const token =
      this.props.auth &&
      this.props.auth.auth.token &&
      this.props.auth.auth.token.accessToken
        ? this.props.auth.auth.token.accessToken
        : ''
    const { dataDetail } = this.props
    const extension =
      dataDetail &&
      dataDetail.fileTongHop &&
      dataDetail.fileTongHop.tenFile &&
      dataDetail.fileTongHop.tenFile
        .slice(
          ((dataDetail.fileTongHop.tenFile.lastIndexOf('.') - 1) >>> 0) + 2
        )
        .toLowerCase()
    return (
      <MasterLayout typeSidebar="signNumber" collapseSideBar={true}>
        <div className="detail-receive-document" ref="historyNode">
          <div className="dispatch-code">
            <button
              onClick={this.goBack.bind(this)}
              type="button"
              className="pt-button pt-button-back"
            >
              <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
              <span className="text-content">Quay lại</span>
            </button>
            <span className="number-code">
              <span className="name">Số ký hiệu:</span>
              <span className="code">{_.get(dataDetail, 'soKyHieu', '')}</span>
            </span>
          </div>
          <div className="detail-container">
            <div className="row-detail-container">
              <div className="box-left">
                <div className="table-container pb0 mb20">
                  <GridView
                    isLoading={this.state.dataTable.isLoading}
                    classNames={'pt-table'}
                    page={this.state.dataTable.page}
                    headers={headers.OpinionSynthesis}
                    itemShape={ListItemSynthesis}
                    data={dataDetail && _.get(dataDetail, 'dsThanhVien', [])}
                    onFilter={this.handleSort}
                    isEmpty={this.state.isEmpty}
                    actions={this.props.actions}
                    auth={this.props.auth}
                  />
                </div>
                {this.state.dataTable.isLoading ? (
                  <div className="loading-container">
                    <LoadingItem />
                    <LoadingItem />
                  </div>
                ) : (
                  dataDetail &&
                  !_.isEmpty(dataDetail.fileTongHop) && (
                    <div className="file-synthesis">
                      <span>File tổng hợp</span>
                      <div className="file-item">
                        <span
                          className="file"
                          style={{
                            backgroundImage: `url(${Tool.Utils.typeFile(
                              extension
                            )})`,
                          }}
                        ></span>
                        <span className="file-name">
                          {dataDetail && dataDetail.fileTongHop && (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                dataDetail &&
                                !_.isEmpty(dataDetail.fileTongHop) &&
                                `${Types.FILE_URL}/api/file/get/${dataDetail.fileTongHop.fileId}?BearerToken=${token}`
                              }
                            >
                              {dataDetail &&
                                !_.isEmpty(dataDetail.fileTongHop) &&
                                dataDetail.fileTongHop.tenFile}
                            </a>
                          )}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="box-right">
                <div className="box-action-wrapper">
                  <div className="box-action-block">
                    <h3 className="title">Thực hiện</h3>
                    {this.state.dataTable.isLoading ? (
                      <div className="loading-container">
                        <LoadingItem />
                        <LoadingItem />
                      </div>
                    ) : (
                      <ul className="data-column">
                        <li>
                          <div className="data-column-items">
                            <label>Trích yếu</label>
                            <div className="content">
                              {_.get(dataDetail, 'trichYeu', '')}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="data-column-items">
                            <label>Nội dung trình HĐTV</label>
                            <div className="content">
                              {_.get(dataDetail, 'noiDungTrinh', '')}
                            </div>
                          </div>
                        </li>
                        {dataDetail && dataDetail.fileBaoCao && (
                          <li>
                            <DetailFileDinhKem
                              className="data-column-file"
                              title="File báo cáo của Ban TH"
                              receiveDocument={{
                                fileDinhKem: [{ ...dataDetail.fileBaoCao }],
                              }}
                              auth={this.props.auth && this.props.auth.auth}
                            />
                          </li>
                        )}
                        {dataDetail &&
                          _.get(dataDetail, 'dsFileDinhKem', []).length !==
                            0 && (
                            <li>
                              <DetailFileDinhKem
                                className="data-column-file"
                                title="File đính kèm"
                                receiveDocument={{
                                  fileDinhKem: dataDetail.dsFileDinhKem,
                                }}
                                auth={this.props.auth && this.props.auth.auth}
                              />
                            </li>
                          )}
                        {dataDetail &&
                          _.get(dataDetail, 'dsFileThamKhao', []).length !==
                            0 && (
                            <li>
                              <DetailFileDinhKem
                                className="data-column-file"
                                title="File tham khảo"
                                receiveDocument={{
                                  fileDinhKem: dataDetail.dsFileThamKhao,
                                }}
                                auth={this.props.auth && this.props.auth.auth}
                                maxHeightScroll={100}
                              />
                            </li>
                          )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

OpinionSynthesis.propTypes = {
  dataDetail: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = state => ({
  dataDetail: state.opinions.dataDetail,
  auth: {
    ...state,
    user: state.auth,
  },
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      opinionDetail,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(OpinionSynthesis)
