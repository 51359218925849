export const convertFileToUpload = file => ({
  uid: file?.uid,
  name: file?.name,
  mime: file?.type,
  size: file?.size,
})

export const imagesType = () => {
  return [
    'image/jpeg',
    'image/jpg',
    'application/jpg',
    'image/pjpeg',
    'image/pipeg',
    'image/gif',
    'image/bmp',
    'image/png',
    'image/x-windows-bmp',
  ]
}

export const ducumentsType = () => {
  return [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.xlsx',
    'text/comma-separated-values',
    'text/csv',
    'application/csv',
    'application/excel',
    'application/vnd.msexcel',
    'application/pdf',
    'application/x-pdf',
    '.pdf',
    'application/zip',
    'application/mspowerpoint',
    'application/powerpoint',
    '.pps',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'ppt',
    'pptx',
    '.ppt',
    '.pptx',
    'application/msword',
    'application/doc',
    'doc',
    '.doc',
    'docx',
    '.docx',
  ]
}

export const videosType = () => {
  return ['video/mp4', 'video/quicktime']
}

export const compressedsType = () => {
  return ['application/x-zip-compressed', 'application/octet-stream']
}

export const audioType = () => {
  return ['audio/mpeg', 'audio/mp4']
}

export const settupType = () => {
  return ['application/x-msdownload', '.exe']
}

export default {
  convertFileToUpload,
  imagesType,
  ducumentsType,
  videosType,
  compressedsType,
  audioType,
  settupType,
}
