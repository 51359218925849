import React from 'react'
import { get } from 'lodash'

class StatisticItem extends React.Component {
  goPages = url => {
    const { data } = this.props
    let status = null
    if (data.type === 'WORK_MANAGE_NEW') {
      status = 'NEW'
    } else if (data.type === 'WORK_MANAGE_DOING') {
      status = 'DOING'
    }

    if (url) {
      return this.props.history.push({
        pathname: url,
        state: {
          status: status,
        },
      })
    }

    return
  }

  render() {
    const { data, modelStyle, url } = this.props
    return (
      <div className="home-col--box pointer" onClick={() => this.goPages(url)}>
        <i
          className={get(modelStyle, 'icon')}
          style={{ backgroundColor: get(modelStyle, 'background') }}
        />
        <div className="info">
          <span style={{ color: get(modelStyle, 'background') }}>
            {get(data, 'value')}
          </span>
          <p>{get(data, 'name')}</p>
        </div>
      </div>
    )
  }
}

export default StatisticItem
