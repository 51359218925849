export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_DISCARD_TOKEN = 'AUTH_DISCARD_TOKEN'
export const AUTH_DOCUMENT_DISCARD_TOKEN = 'AUTH_DOCUMENT_DISCARD_TOKEN'
export const AUTH_SET_USER = 'AUTH_SET_USER'
export const AUTH_GET_PERMISSION = 'AUTH_GET_PERMISSION'
export const AUTH_GET_PERMISSION_DOCUMENT = 'AUTH_GET_PERMISSION_DOCUMENT'
export const CONTACT_FETCH_DEPARTMENTS = 'CONTACT_FETCH_DEPARTMENTS'
export const CONTACT_FETCH_UNITS = 'CONTACT_FETCH_UNITS'
export const CONTACT_FETCH_UNITS_EQUAL = 'CONTACT_FETCH_UNITS_EQUAL'
export const AUTH_SWITCH_ROLE = 'AUTH_SWITCH_ROLE'

// Phân quyền menu
export const API_REQUEST_PERMISSIONS_MENU = 'API_REQUEST_PERMISSIONS_MENU'

export const CONFIG_CABINET_TYPE = 'CONFIG_CABINET_TYPE'
export const CONFIG_PAGE_REFRESH = 'CONFIG_PAGE_REFRESH'
export const CONFIG_REFRESH_SEND_SIGN_NUMBER = 'CONFIG_REFRESH_SEND_SIGN_NUMBER'
export const CONFIG_REFRESH_INTERNAL_SIGN_NUMBER =
  'CONFIG_REFRESH_INTERNAL_SIGN_NUMBER'
export const CONFIG_REFRESH_SEND_DOCUMENT = 'CONFIG_REFRESH_SEND_DOCUMENT'
export const CONFIG_REFRESH_DOCUMENT_CABINET = 'CONFIG_REFRESH_DOCUMENT_CABINET'
export const CONFIG_REFRESH_REVOKE_DOCUMENT = 'CONFIG_REFRESH_REVOKE_DOCUMENT'

export const API_REQUEST_SEND = 'API_REQUEST_SEND'
export const API_REQUEST_SEND_SIGNNUMBER = 'API_REQUEST_SEND_SIGNNUMBER'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_ERROR = 'API_REQUEST_ERROR'
export const API_RESET_SIGNNUMBER_TOKEN = 'API_RESET_SIGNNUMBER_TOKEN'
export const API_RESET_PERSONAL_WORK_ITEM = 'API_RESET_PERSONAL_WORK_ITEM'

export const PERSONAL_WORK_ASSIGN = 'PERSONAL_WORK_ASSIGN'
export const GET_EXECUTING_VIEW_ASSIGN = 'GET_EXECUTING_VIEW_ASSIGN'
export const EXECUTING_VIEW = 'EXECUTING_VIEW'
export const EXECUTING_VIEW_EVN = 'EXECUTING_VIEW_EVN'
export const EXECUTING_VIEW_MORE = 'EXECUTING_VIEW_MORE'
export const DOCUMENT_SEND = 'DOCUMENT_SEND'
export const DOCUMENT_SEND_EXPORT = 'DOCUMENT_SEND_EXPORT'
export const DOCUMENT_SEND_DELETE = 'DOCUMENT_SEND_DELETE'
export const DOCUMENT_SEND_DETAIL = 'DOCUMENT_SEND_DETAIL'
export const DOCUMENT_SEND_ADD = 'DOCUMENT_SEND_ADD'
export const DOCUMENT_SEND_UPDATE = 'DOCUMENT_SEND_UPDATE'
export const DOCUMENT_SEND_ADDITIONAL = 'DOCUMENT_SEND_ADDITIONAL'
export const DOCUMENT_SEND_RETRIEVE = 'DOCUMENT_SEND_RETRIEVE'
export const DOCUMENT_SEND_VALIDATE_DOCUMENT_NUMBER =
  'DOCUMENT_SEND_VALIDATE_DOCUMENT_NUMBER'
export const DOCUMENT_SEND_RESEND_EVN = 'DOCUMENT_SEND_RESEND_EVN'

export const RECEIVE_DOCUMENT = 'RECEIVE_DOCUMENT'
export const RECEIVE_DOCUMENT_ITEM = 'RECEIVE_DOCUMENT_ITEM'
// CHANGED: Api chi tiết công văn rút gọn gọi khi load lần đầu
export const RECEIVE_DOCUMENT_ITEM_SHORTEN = 'RECEIVE_DOCUMENT_ITEM_SHORTEN'
// CHANGED: Api show thông tin công văn
export const RECEIVE_DOCUMENT_ITEM_INFORMATION =
  'RECEIVE_DOCUMENT_ITEM_INFORMATION'
export const API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND =
  'API_REQUEST_INIT_DOCUMENT_RECEIVE_SEND'
export const DOCUMENT_RECEIVE = 'DOCUMENT_RECEIVE'
export const DOCUMENT_RECEIVE_EXPORT = 'DOCUMENT_RECEIVE_EXPORT'
export const DOCUMENT_RECEIVE_ADD = 'DOCUMENT_RECEIVE_ADD'
export const DOCUMENT_RECEIVE_FETCH_DIGITAL_DOCUMENTS =
  'DOCUMENT_RECEIVE_FETCH_DIGITAL_DOCUMENTS'
export const DOCUMENT_RECEIVE_SEND_ASSIGNMENT =
  'DOCUMENT_RECEIVE_SEND_ASSIGNMENT'
export const DOCUMENT_RECEIVE_FETCH_PROPOSE_ASSIGNMENT =
  'DOCUMENT_RECEIVE_FETCH_PROPOSE_ASSIGNMENT'
export const DOCUMENT_RECEIVE_UPDATE = 'DOCUMENT_RECEIVE_UPDATE'
export const DOCUMENT_RECEIVE_ASSIGNMENT = 'DOCUMENT_RECEIVE_ASSIGNMENT'
export const DOCUMENT_RECEIVE_SEND_PROPOSE_ASSIGNMENT =
  'DOCUMENT_RECEIVE_SEND_PROPOSE_ASSIGNMENT'
export const DOCUMENT_RECEIVE_UPDATE_PROPOSE_ASSIGNMENT =
  'DOCUMENT_RECEIVE_UPDATE_PROPOSE_ASSIGNMENT'
export const DOCUMENT_RECEIVE_SEND_ADDITIONAL_ASSIGNMENT =
  'DOCUMENT_RECEIVE_SEND_ADDITIONAL_ASSIGNMENT'
export const DOCUMENT_RECEIVE_ASSIGNMENT_HISTORY =
  'DOCUMENT_RECEIVE_ASSIGNMENT_HISTORY'
export const DOCUMENT_RECEIVE_DELETE_ASSIGNMENT_HISTORY =
  'DOCUMENT_RECEIVE_DELETE_ASSIGNMENT_HISTORY'
export const DOCUMENT_RECEIVE_UPDATE_ASSIGNMENT_HISTORY =
  'DOCUMENT_RECEIVE_UPDATE_ASSIGNMENT_HISTORY'
export const DOCUMENT_RECEIVE_ASSIGNMENT_HISTORY_ITEM =
  'DOCUMENT_RECEIVE_ASSIGNMENT_HISTORY_ITEM'
export const DOCUMENT_RECEIVE_UPDATE_ADDITIONAL_ASSIGNMENT =
  'DOCUMENT_RECEIVE_UPDATE_ADDITIONAL_ASSIGNMENT'
export const DOCUMENT_RECEIVE_FILE_INFO = 'DOCUMENT_RECEIVE_FILE_INFO'
export const DOCUMENT_AUTHORITY_CREATE = 'DOCUMENT_AUTHORITY_CREATE'
export const DOCUMENT_AUTHORITY_UPDATE = 'DOCUMENT_AUTHORITY_UPDATE'
export const DOCUMENT_AUTHORITY_FETCH = 'DOCUMENT_AUTHORITY_FETCH'
export const DOCUMENT_AUTHORITY_DELETE = 'DOCUMENT_AUTHORITY_DELETE'
export const DOCUMENT_DELETE_REWORKING = 'DOCUMENT_DELETE_REWORKING'

export const DOCUMENT_INTERNAL_GET = 'DOCUMENT_INTERNAL_GET'
export const DOCUMENT_INTERNAL_DELETE = 'DOCUMENT_INTERNAL_DELETE'
export const DOCUMENT_INTERNAL_ADD = 'DOCUMENT_INTERNAL_ADD'
export const GET_DOCUMENT_INTERNAL_ITEM = 'GET_DOCUMENT_INTERNAL_ITEM'
export const DOCUMENT_INTERNAL_EXECUTINGVIEW = 'DOCUMENT_INTERNAL_EXECUTINGVIEW'
export const DOCUMENT_INTERNAL_UPDATE = 'DOCUMENT_INTERNAL_UPDATE'
export const SEND_DOCUMENT_EXECUTING_VIEW = 'SEND_DOCUMENT_EXECUTING_VIEW'

export const GET_PROJECT_DUPLICATE = 'GET_PROJECT_DUPLICATE'
export const GET_PROJECT_STATUS = 'GET_PROJECT_STATUS'
export const GET_PROJECT_ITEM = 'GET_PROJECT_ITEM'
export const GET_PROJECT_INFO = 'GET_PROJECT_INFO'
export const UPDATE_PROJECT_ITEM = 'UPDATE_PROJECT_ITEM'
export const DELETE_PROJECT_ITEM = 'DELETE_PROJECT_ITEM'
export const GET_LIST_PROJECT = 'GET_LIST_PROJECT'
export const POST_CREATE_PROJECT = 'POST_CREATE_PROJECT'
export const FORWARD_ASSIGNMENT = 'FORWARD_ASSIGNMENT'
export const SEARCH_DOCUMENT_SEND = 'SEARCH_DOCUMENT_SEND'
export const GET_LIST_STAFFS_IN_DEPARTMENT = 'GET_LIST_STAFFS_IN_DEPARTMENT'
export const FETCH_DEPARTMENTS = 'FETCH_DEPARTMENTS'
export const COMMON_FETCH_OBJECTS = 'COMMON_FETCH_OBJECTS'
export const COMMON_FETCH_SECURITIES = 'COMMON_FETCH_SECURITIES'
export const COMMON_FETCH_PRIORITIES = 'COMMON_FETCH_PRIORITIES'
export const COMMON_FETCH_SAVECODES = 'COMMON_FETCH_SAVECODES'
export const COMMON_FETCH_RELATIVE_UNITS = 'COMMON_FETCH_RELATIVE_UNITS'
export const COMMON_FETCH_LEADER_DERPATMENTS = 'COMMON_FETCH_LEADER_DERPATMENTS'
export const COMMON_FETCH_DERPATMENTS = 'COMMON_FETCH_DERPATMENTS'
export const COMMON_FETCH_LEADERCOMBINATION = 'COMMON_FETCH_LEADERCOMBINATION'
export const FETCH_INTERNAL_DERPATMENTS = 'FETCH_INTERNAL_DERPATMENTS'
export const COMMON_FETCH_ATTACHED_UNITS = 'COMMON_FETCH_ATTACHED_UNITS'
export const COMMON_FETCH_ELECTRIC_UNITS = 'COMMON_FETCH_ELECTRIC_UNITS'
export const COMMON_ADD_TOASTER_MESSAGE = 'COMMON_ADD_TOASTER_MESSAGE'
export const COMMON_REMOVE_TOASTER_MESSAGE = 'COMMON_REMOVE_TOASTER_MESSAGE'
export const COMMON_FETCH_SIGN_NUMBER_DEPARTMENT_LEADER =
  'COMMON_FETCH_SIGN_NUMBER_DEPARTMENT_LEADER'
export const COMMON_FETCH_RECEIVEDOCUMENT_TOTAL =
  'COMMON_FETCH_RECEIVEDOCUMENT_TOTAL'
export const COMMON_FETCH_STAFFS_BY_DEPARTMENT_ID =
  'COMMON_FETCH_STAFFS_BY_DEPARTMENT_ID'
export const FETCH_STAFFS_BY_DEPARTMENT_ID = 'FETCH_STAFFS_BY_DEPARTMENT_ID'
export const COMMON_FETCH_STAFFS_BY_UNIT_ID = 'COMMON_FETCH_STAFFS_BY_UNIT_ID'
export const COMMON_FETCH_DERPATMENT_WORK_TOTAL =
  'COMMON_FETCH_DERPATMENT_WORK_TOTAL'
export const COMMON_FETCH_PERSONAL_WORK_TOTAL =
  'COMMON_FETCH_PERSONAL_WORK_TOTAL'
export const COMMON_FETCH_PERSONAL_LEADER_SIGN_NUMBER =
  'COMMON_FETCH_PERSONAL_LEADER_SIGN_NUMBER'
export const COMMON_FETCH_CHILD_DERPATMENTS = 'COMMON_FETCH_CHILD_DERPATMENTS'
export const COMMON_FETCH_STATISTICS = 'COMMON_FETCH_STATISTICS'
export const COMMON_FETCH_UPDATE_SAVECODES = 'COMMON_FETCH_UPDATE_SAVECODES'
export const COMMON_FETCH_POST_SAVECODES = 'COMMON_FETCH_POST_SAVECODES'
export const COMMON_FETCH_SAVECODES_ITEM = 'COMMON_FETCH_SAVECODES_ITEM'
export const COMMON_FETCH_DELETE_SAVECODES = 'COMMON_FETCH_DELETE_SAVECODES'
export const COMMON_FETCH_UPDATE_SECURITIES = 'COMMON_FETCH_UPDATE_SECURITIES'
export const COMMON_FETCH_GET_SECURITIES_ITEM =
  'COMMON_FETCH_GET_SECURITIES_ITEM'
export const COMMON_FETCH_POST_SECURITIES = 'COMMON_FETCH_POST_SECURITIES'
export const COMMON_FETCH_DELETE_SECURITIES = 'COMMON_FETCH_DELETE_SECURITIES'
export const COMMON_FETCH_UPDATE_OBJECT = 'COMMON_FETCH_UPDATE_OBJECT'
export const COMMON_FETCH_POST_OBJECT = 'COMMON_FETCH_POST_OBJECT'
export const COMMON_FETCH_DELETE_OBJECT = 'COMMON_FETCH_DELETE_OBJECT'
export const COMMON_FETCH_GET_OBEJCT_ITEM = 'COMMON_FETCH_GET_OBEJCT_ITEM'
export const COMMON_FETCH_UPDATE_PRIORITIES = 'COMMON_FETCH_UPDATE_PRIORITIES'
export const COMMON_FETCH_POST_PRIORITIES = 'COMMON_FETCH_POST_PRIORITIES'
export const COMMON_FETCH_DELETE_PRIORITIES = 'COMMON_FETCH_DELETE_PRIORITIES'
export const COMMON_FETCH_GET_PRIORITIES_ITEM =
  'COMMON_FETCH_GET_PRIORITIES_ITEM'
export const COMMON_FETCH_POSITION = 'COMMON_FETCH_POSITION'
export const COMMON_FETCH_UPDATE_POSITION = 'COMMON_FETCH_UPDATE_POSITION'
export const COMMON_FETCH_DELETE_POSITION = 'COMMON_FETCH_DELETE_POSITION'
export const COMMON_FETCH_POST_POSITION = 'COMMON_FETCH_POST_POSITION'
export const COMMON_FETCH_GET_POSITION_ITEM = 'COMMON_FETCH_GET_POSITION_ITEM'
export const COMMON_FETCH_SIGN_NUMBER_TOTALS = 'COMMON_FETCH_GET_POSITION_ITEM'
export const COMMON_FETCH_UPDATE_UNIT = 'COMMON_FETCH_UPDATE_UNIT'
export const COMMON_FETCH_POST_UNIT = 'COMMON_FETCH_POST_UNIT'
export const COMMON_FETCH_DELETE_UNIT = 'COMMON_FETCH_DELETE_UNIT'
export const COMMON_FETCH_GET_UNIT_ITEM = 'COMMON_FETCH_GET_UNIT_ITEM'
export const COMMON_FETCH_UNIT = 'COMMON_FETCH_UNIT'
export const COMMON_FETCH_UPDATE_DEPARTMENTS = 'COMMON_FETCH_UPDATE_DEPARTMENTS'
export const COMMON_FETCH_DELETE_DEPARTMENTS = 'COMMON_FETCH_DELETE_DEPARTMENTS'
export const COMMON_FETCH_POST_DEPARTMENTS = 'COMMON_FETCH_POST_DEPARTMENTS'
export const COMMON_FETCH_GET_DEPARTMENTS_ITEM =
  'COMMON_FETCH_GET_DEPARTMENTS_ITEM'
export const COMMON_FETCH_UPDATE_ARRAY_SECURITY =
  'COMMON_FETCH_UPDATE_ARRAY_SECURITY'
export const COMMON_FETCH_UPDATE_ARRAY_SAVENOTE =
  'COMMON_FETCH_UPDATE_ARRAY_SAVENOTE'
export const COMMON_FETCH_UPDATE_ARRAY_POSITION =
  'COMMON_FETCH_UPDATE_ARRAY_POSITION'
export const COMMON_FETCH_UPDATE_ARRAY_URGENCY =
  'COMMON_FETCH_UPDATE_ARRAY_URGENCY'
export const COMMON_FETCH_UPDATE_ARRAY_SUBJECT =
  'COMMON_FETCH_UPDATE_ARRAY_SUBJECT'
export const COMMON_FETCH_UPDATE_ARRAY_UNIT = 'COMMON_FETCH_UPDATE_ARRAY_UNIT'
export const COMMON_FETCH_UPDATE_ARRAY_DEPARTMENT =
  'COMMON_FETCH_UPDATE_ARRAY_DEPARTMENT'
export const COMMON_FETCH_CABINET_OF_DOCUMENT_CLEAR =
  'COMMON_FETCH_CABINET_OF_DOCUMENT_CLEAR'
export const COMMON_FETCH_CABINET_OF_DOCUMENT =
  'COMMON_FETCH_CABINET_OF_DOCUMENT'
export const COMMON_FETCH_ALERT_MISTAKE_TOTAL =
  'COMMON_FETCH_ALERT_MISTAKE_TOTAL'
export const COMMON_FETCH_SIGN_USER_LIST = 'COMMON_FETCH_SIGN_USER_LIST'
export const COMMON_FETCH_INTERNAL_UNITS = 'COMMON_FETCH_INTERNAL_UNITS'
export const COMMON_FETCH_RECEIVE_UNITS = 'COMMON_FETCH_RECEIVE_UNITS'

export const CONFIG_STATISTIC_REFRESH = 'CONFIG_STATISTIC_REFRESH'
export const COMMON_FETCH_GET_ROLE = 'COMMON_FETCH_GET_ROLE'
export const COMMON_FETCH_UPDATE_ARRAY_ROLE = 'COMMON_FETCH_UPDATE_ARRAY_ROLE'
export const COMMON_FETCH_DELETE_ROLE = 'COMMON_FETCH_DELETE_ROLE'
export const COMMON_FETCH_UPDATE_ROLE = 'COMMON_FETCH_UPDATE_ROLE'
export const COMMON_FETCH_GET_ROLE_ITEM = 'COMMON_FETCH_GET_ROLE_ITEM'
export const COMMON_FETCH_POST_ROLE_ITEM = 'COMMON_FETCH_POST_ROLE_ITEM'
export const COMMON_FETCH_CATEGORY_ROLE = 'COMMON_FETCH_CATEGORY_ROLE'
export const COMMON_FETCH_DELETE_CATEGORY_ROLE =
  'COMMON_FETCH_DELETE_CATEGORY_ROLE'
export const COMMON_FETCH_POST_CATEGORY_ROLE = 'COMMON_FETCH_POST_CATEGORY_ROLE'
export const COMMON_FETCH_GET_CATEGORY_PERMISSION =
  'COMMON_FETCH_GET_CATEGORY_PERMISSION'
export const COMMON_SAVE_TYPE_SIDEBAR = 'COMMON_SAVE_TYPE_SIDEBAR'
export const CLEAR_USERS = 'CLEAR_USERS'
export const GET_STAFFS_IN_DEPARTMENT = 'GET_STAFFS_IN_DEPARTMENT'

export const CATEGORY_FETCH_DEPARTMENT_CHILD = 'CATEGORY_FETCH_DEPARTMENT_CHILD'
export const CATEGORY_STAFF_SUBJECT = 'CATEGORY_STAFF_SUBJECT'
export const CATEGORY_UNIT = 'CATEGORY_UNIT'
export const CATEGORY_UNIT_CHILD = 'CATEGORY_UNIT_CHILD'
export const CATEGORY_FETCH_DEPARTMENT_MULTILEVEL =
  'CATEGORY_FETCH_DEPARTMENT_MULTILEVEL'
export const CATEGORY_FETCH_UNIT_MULTILEVEL = 'CATEGORY_FETCH_UNIT_MULTILEVEL'
export const CATEGORY_FETCH_CONNECTED_UNIT = 'CATEGORY_FETCH_CONNECTED_UNIT'
export const CATEGORY_FETCH_CONNECTED_UNIT_EVN =
  'CATEGORY_FETCH_CONNECTED_UNIT_EVN'

export const UPDATE_DEPARTMENT_PERMISSION = 'UPDATE_DEPARTMENT_PERMISSION'
export const POST_RE_SEND = 'POST_RE_SEND'
export const UPDATE_STAFF_ITEM = 'UPDATE_STAFF_ITEM'
export const GET_STAFF_ITEM = 'GET_STAFF_ITEM'
export const DELETE_STAFF = 'DELETE_STAFF'
export const POST_ADD_STAFF = 'POST_ADD_STAFF'
export const PERSONAL_WORK_REQUEST_SEND = 'PERSONAL_WORK_REQUEST_SEND'
export const PERSONAL_WORK = 'PERSONAL_WORK'
export const WORK_UPDATE_ASSIGN = 'WORK_UPDATE_ASSIGN'
export const WORK_DELETE_REPORT = 'WORK_DELETE_REPORT'
export const WORK_DELETE_ASSIGN = 'WORK_DELETE_ASSIGN'
export const WORK_UPDATE_ADDITIONAL_ASSIGN = 'WORK_UPDATE_ADDITIONAL_ASSIGN'
export const WORK_GET_REPORT = 'WORK_GET_REPORT'
export const PERSONAL_WORK_ITEM = 'PERSONAL_WORK_ITEM'
export const WORK_POST_REPORT = 'WORK_POST_REPORT'
export const WORK_ASSIGNMENT_INFORMATION = 'WORK_ASSIGNMENT_INFORMATION'
export const DEPARTMENT_WORK_ASSIGNED = 'DEPARTMENT_WORK_ASSIGNED'
export const DEPARTMENT_WORK_NOT_ASSIGN = 'DEPARTMENT_WORK_NOT_ASSIGN'
export const WORK_ITEM = 'WORK_ITEM'
export const WORK_ASSIGN = 'WORK_ASSIGN'
export const ADD_WORK_ASSIGN = 'ADD_WORK_ASSIGN'
export const WORK_ADDITIONAL_ASSIGN = 'WORK_ADDITIONAL_ASSIGN'
export const WORK_COMPLETE_DEPARTMENT = 'WORK_COMPLETE_DEPARTMENT'
export const WORK_COMPLETE_PERSONAL = 'WORK_COMPLETE_PERSONAL'
export const WORK_GET_REPORT_PERSONAL = 'WORK_GET_REPORT_PERSONAL'
export const WORK_UPDATE_REPORT_PERSONAL = 'WORK_UPDATE_REPORT_PERSONAL'
export const WORK_DEPARTMENT_ASSIGN_INFORMATIONS =
  'WORK_DEPARTMENT_ASSIGN_INFORMATIONS'
export const WORK_PERSONAL_ASSIGN_INFORMATIONS =
  'WORK_PERSONAL_ASSIGN_INFORMATIONS'
export const WORK_SEE_TO_KNOW = 'WORK_SEE_TO_KNOW'
export const WORK_UPDATE_SEE_TO_KNOW = 'WORK_UPDATE_SEE_TO_KNOW'
export const GET_RECEIVE_DOCUMENT_ITEM = 'GET_RECEIVE_DOCUMENT_ITEM'
export const GET_PERSONAL_WORK_ITEM = 'GET_PERSONAL_WORK_ITEM'
export const UPDATE_WORK_SEE_TO_KNOW = 'UPDATE_WORK_SEE_TO_KNOW'
export const TOTAL_SEE_TO_KNOW = 'TOTAL_SEE_TO_KNOW'
export const GET_LIST_PERSONAL_ASSIGNMENT = 'GET_LIST_PERSONAL_ASSIGNMENT'
export const WORK_AUTHORITY_CREATE = 'WORK_AUTHORITY_CREATE'
export const WORK_AUTHORITY_UPDATE = 'WORK_AUTHORITY_UPDATE'
export const WORK_AUTHORITY_FETCH = 'WORK_AUTHORITY_FETCH'
export const WORK_AUTHORITY_DELETE = 'WORK_AUTHORITY_DELETE'
export const ALERT_MISTAKE_WORK = 'ALERT_MISTAKE_WORK'
export const CANCEL_ALERT_MISTAKE = 'CANCEL_ALERT_MISTAKE'
export const WORK_DELETE_REWORKING = 'WORK_DELETE_REWORKING'

export const SET_DEFAULT_NUMBER_WORK = 'SET_DEFAULT_NUMBER_WORK'
export const SET_DEFAULT_NUMBER_DEPARTMENT = 'SET_DEFAULT_NUMBER_DEPARTMENT'
export const SIGN_NUMBER_INTERNAL = 'SIGN_NUMBER_INTERNAL'
export const SIGN_NUMBER_INTERNAL_CREATE = 'SIGN_NUMBER_INTERNAL_CREATE'
export const SIGN_NUMBER_INTERNAL_UPDATE = 'SIGN_NUMBER_INTERNAL_UPDATE'
export const SIGN_NUMBER_INTERNAL_DETAIL = 'SIGN_NUMBER_INTERNAL_DETAIL'
export const SIGN_NUMBER_INTERNAL_DELETE = 'SIGN_NUMBER_INTERNAL_DELETE'
export const SIGN_NUMBER_INTERNAL_AGREE = 'SIGN_NUMBER_INTERNAL_AGREE'
export const SIGN_NUMBER_EVNCA_INTERNAL_AGREE =
  'SIGN_NUMBER_EVNCA_INTERNAL_AGREE'
export const SIGN_NUMBER_INTERNAL_AGREE_BY_SIM =
  'SIGN_NUMBER_INTERNAL_AGREE_BY_SIM'
export const SIGN_NUMBER_INTERNAL_DISAGREE = 'SIGN_NUMBER_INTERNAL_DISAGREE'
export const SIGN_NUMBER_INTERNAL_DETAIL_FEEDBACK_POST =
  'SIGN_NUMBER_INTERNAL_DETAIL_FEEDBACK_POST'
export const SIGN_NUMBER_INTERNAL_DETAIL_FEEDBACK =
  'SIGN_NUMBER_DETAIL_FEEDBACK'
export const SIGN_NUMBER_INTERNAL_UPDATE_FEEDBACK =
  'SIGN_NUMBER_INTERNAL_UPDATE_FEEDBACK'
export const SIGN_NUMBER_INTERNAL_FETCH_HISTORY =
  'SIGN_NUMBER_INTERNAL_FETCH_HISTORY'

export const SIGN_NUMBER_SEND = 'SIGN_NUMBER_SEND'
export const SIGN_NUMBER_SEND_CREATE = 'SIGN_NUMBER_SEND_CREATE'
export const SIGN_NUMBER_SEND_UPDATE = 'SIGN_NUMBER_SEND_UPDATE'
export const SIGN_NUMBER_SEND_AGREE = 'SIGN_NUMBER_SEND_AGREE'
export const SIGN_NUMBER_EVNCA_SEND_AGREE = 'SIGN_NUMBER_EVNCA_SEND_AGREE'
export const SIGN_NUMBER_SEND_AGREE_BY_SIM = 'SIGN_NUMBER_SEND_AGREE_BY_SIM'
export const SIGN_NUMBER_SEND_DISAGREE = 'SIGN_NUMBER_SEND_DISAGREE'
export const SIGN_NUMBER_SEND_UPDATE_FEEDBACK =
  'SIGN_NUMBER_SEND_UPDATE_FEEDBACK'
export const SIGN_NUMBER_SEND_FETCH_HISTORY = 'SIGN_NUMBER_SEND_FETCH_HISTORY'
export const SIGN_NUMBER_RELEASED_SEND = 'SIGN_NUMBER_RELEASED_SEND'

export const SIGN_NUMBER_DETAIL = 'SIGN_NUMBER_DETAIL'
export const SIGN_NUMBER_DETAIL_FEEDBACK = 'SIGN_NUMBER_DETAIL_FEEDBACK'
export const SIGN_NUMBER_DETAIL_FEEDBACK_POST =
  'SIGN_NUMBER_DETAIL_FEEDBACK_POST'
export const SIGN_NUMBER_DELETE = 'SIGN_NUMBER_DELETE'
export const SIGN_NUMBER_CHECK_PROGRESSION = 'SIGN_NUMBER_CHECK_PROGRESSION'
export const PROGRESSION_BY_SIM = 'PROGRESSION_BY_SIM'

export const SIGN_NUMBER_PROJECT_GET_MEMBER_BY_ID =
  'SIGN_NUMBER_PROJECT_GET_MEMBER_BY_ID'
export const SIGN_NUMBER_PROJECT_CREATE = 'SIGN_NUMBER_PROJECT_CREATE'
export const SIGN_NUMBER_PROJECT_UPDATE = 'SIGN_NUMBER_PROJECT_UPDATE'
export const SIGN_NUMBER_PROJECTS_GET = 'SIGN_NUMBER_PROJECTS_GET'
export const SIGN_NUMBER_PROJECTS_DELETE = 'SIGN_NUMBER_PROJECTS_DELETE'
export const SIGN_NUMBER_PROJECTS_GET_DETAIL = 'SIGN_NUMBER_PROJECTS_GET_DETAIL'
export const SIGN_NUMBER_PROJECTS_GET_DETAIL_UPDATE =
  'SIGN_NUMBER_PROJECTS_GET_DETAIL_UPDATE'
export const SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_GET =
  'SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_GET'
export const SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_CREATE =
  'SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_CREATE'
export const SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_UPDATE =
  'SIGN_NUMBER_PROJECTS_DETAIL_FEEDBACK_UPDATE'
export const SIGN_NUMBER_PROJECTS_AGREE_BY_SIM =
  'SIGN_NUMBER_PROJECTS_AGREE_BY_SIM'
export const SIGN_NUMBER_PROJECTS_AGREE_BY_EVNCA =
  'SIGN_NUMBER_PROJECTS_AGREE_BY_EVNCA'
export const SIGN_NUMBER_PROJECTS_DISAGREE = 'SIGN_NUMBER_PROJECTS_DISAGREE'
export const SIGN_NUMBER_PROJECTS_REVERSE = 'SIGN_NUMBER_PROJECTS_REVERSE'
export const SIGN_NUMBER_PROJECTS_UPDATE_HISTORY_GET =
  'SIGN_NUMBER_PROJECTS_UPDATE_HISTORY_GET'

export const PROJECTS_GET_LIST_BY_MEMBER_ID = 'PROJECTS_GET_LIST_BY_MEMBER_ID'
export const INFOMATION_STEERING = 'INFOMATION_STEERING'
export const FILE_UPLOAD = 'FILE_UPLOAD'
export const PROFILE_FILE_UPLOAD = 'PROFILE_FILE_UPLOAD'
export const AVATAR_UPLOAD = 'AVATAR_UPLOAD'
export const CLEAR_CACHE_AVATAR = 'CLEAR_CACHE_AVATAR'
export const GET_MAIL = 'GET_MAIL'
export const POST_MAIL = 'POST_MAIL'

export const PRINT_SEARCH_DOCUMENT_RECEIVE = 'PRINT_SEARCH_DOCUMENT_RECEIVE'
export const GET_ROLE = 'GET_ROLE'
export const PRINT_DOCUMENT_SEND = 'PRINT_DOCUMENT_SEND'
export const PRINT_DOCUMENT_RECEIVE = 'PRINT_DOCUMENT_RECEIVE'
export const GET_HANDLE_DOCUMENT = 'GET_HANDLE_DOCUMENT'
export const EXPORT_HANDLE_DOCUMENT = 'EXPORT_HANDLE_DOCUMENT'
export const EXPORT_GENERAL_WORK = 'EXPORT_GENERAL_WORK'
export const EXPORT_STATISTICS_SIGN_NUMBER = 'EXPORT_STATISTICS_SIGN_NUMBER'
export const EXPORT_STATISTIC_USER = 'EXPORT_STATISTIC_USER'
export const GET_GENERAL_WORK = 'GET_GENERAL_WORK'
export const EXPORT_STATISTIC_CABINET_DOCUMENT =
  'EXPORT_STATISTIC_CABINET_DOCUMENT'
export const EXPORT_STATISTIC_ALERT_MISTAKE = 'EXPORT_STATISTIC_ALERT_MISTAKE'
export const EXPORT_STATISTIC_EXPIRE_WORK = 'EXPORT_STATISTIC_EXPIRE_WORK'
export const GET_STATISTIC_CABINET_DOCUMENT = 'GET_STATISTIC_CABINET_DOCUMENT'
export const GET_STATISTIC_SIGN_NUMBER = 'GET_STATISTIC_SIGN_NUMBER'
export const GET_STATISTIC_SIGN_NUMBER_UNITS = 'GET_STATISTIC_SIGN_NUMBER_UNITS'
export const GET_STATISTIC_SIGN_NUMBER_DEPARTMENTS =
  'GET_STATISTIC_SIGN_NUMBER_DEPARTMENTS'
export const GET_STATISTIC_SIGN_NUMBER_PERSONAL =
  'GET_STATISTIC_SIGN_NUMBER_PERSONAL'
export const GET_STATISTIC_SIGN_NUMBER_PERSONAL_IN_CHILD_UNITS =
  'GET_STATISTIC_SIGN_NUMBER_PERSONAL_IN_CHILD_UNITS'
export const GET_STATISTIC_USER = 'GET_STATISTIC_USER'
export const GET_STATISTIC_ALERT_MISTAKE_UNIT =
  'GET_STATISTIC_ALERT_MISTAKE_UNIT'
export const GET_STATISTIC_ALERT_MISTAKE_DEPARTMENT =
  'GET_STATISTIC_ALERT_MISTAKE_DEPARTMENT'
export const GET_STATISTIC_ALERT_MISTAKE_PERSONAL =
  'GET_STATISTIC_ALERT_MISTAKE_PERSONAL'
export const GET_STATISTIC_MESSENGER = 'GET_STATISTIC_MESSENGER'
export const GET_STATISTIC_NOT_MESSENGER = 'GET_STATISTIC_NOT_MESSENGER'
export const EXPORT_STATISTIC_MESSENGER = 'EXPORT_STATISTIC_MESSENGER'
export const EXPORT_STATISTIC_NOT_MESSENGER = 'EXPORT_STATISTIC_NOT_MESSENGER'
export const GET_LIST_CONTACT = 'GET_LIST_CONTACT'
export const DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL =
  'DOCUMENTS_CABINET_CATEGORIES_SET_INITIAL'
export const DOCUMENTS_CABINET_UNIT_CATEGORIES =
  'DOCUMENTS_CABINET_UNIT_CATEGORIES'
export const DOCUMENTS_CABINET_PERSONAL_CATEGORIES =
  'DOCUMENTS_CABINET_PERSONAL_CATEGORIES'
export const DOCUMENTS_CABINET_DEPARTMENT_CATEGORIES =
  'DOCUMENTS_CABINET_DEPARTMENT_CATEGORIES'
export const DOCUMENTS_CABINET_PROFILE_CATEGORIES =
  'DOCUMENTS_CABINET_PROFILE_CATEGORIES'
export const DOCUMENTS_CABINET_ITEM_SET_INITIAL =
  'DOCUMENTS_CABINET_ITEM_SET_INITIAL'
export const DOCUMENTS_CABINET_RECEIVE_DOCUMENT_POST =
  'DOCUMENTS_CABINET_RECEIVE_DOCUMENT_POST'
export const DOCUMENTS_CABINET_WORK_POST = 'DOCUMENTS_CABINET_WORK_POST'
export const FLOWDOCUMENTARY_RECEIVEDOCUMENT = 'FLOWDOCUMENTARY_RECEIVEDOCUMENT'
export const FLOWDOCUMENTARY_MORE_RECEIVEDOCUMENT =
  'FLOWDOCUMENTARY_MORE_RECEIVEDOCUMENT'
export const FLOWDOCUMENTART_SENDDOCUMENT = 'FLOWDOCUMENTART_SENDDOCUMENT'
export const FLOWDOCUMENTART_MORE_SENDDOCUMENT =
  'FLOWDOCUMENTART_MORE_SENDDOCUMENT'

export const DOCUMENTS_CABINET_BY_UNIT = 'DOCUMENTS_CABINET_BY_UNIT'
export const DOCUMENTS_CABINET_BY_PERSONAL = 'DOCUMENTS_CABINET_BY_PERSONAL'
export const DOCUMENTS_CABINET_BY_DEPARTMENT = 'DOCUMENTS_CABINET_BY_DEPARTMENT'
export const DOCUMENTS_CABINET_OVERTIME = 'DOCUMENTS_CABINET_OVERTIME'
export const STAGISTICAL_SIGNNUMBER_OVERTIME = 'STAGISTICAL_SIGNNUMBER_OVERTIME'

export const DOCUMENTS_CABINET_UNIT_DELETE = 'DOCUMENTS_CABINET_UNIT_DELETE'
export const DOCUMENTS_CABINET_PERSONAL_DELETE =
  'DOCUMENTS_CABINET_PERSONAL_DELETE'
export const DOCUMENTS_CABINET_DEPARTMENT_DELETE =
  'DOCUMENTS_CABINET_DEPARTMENT_DELETE'

export const DOCUMENTS_CABINET_SAVE_UNIT_CATEGORY =
  'DOCUMENTS_CABINET_SAVE_UNIT_CATEGORY'
export const DOCUMENTS_CABINET_SAVE_PERSONAL_CATEGORY =
  'DOCUMENTS_CABINET_SAVE_PERSONAL_CATEGORY'
export const DOCUMENTS_CABINET_SAVE_DEPARTMENT_CATEGORY =
  'DOCUMENTS_CABINET_SAVE_DEPARTMENT_CATEGORY'
export const DOCUMENTS_CABINET_UPDATE_UNIT_CATEGORY =
  'DOCUMENTS_CABINET_UPDATE_UNIT_CATEGORY'
export const DOCUMENTS_CABINET_UPDATE_PERSONAL_CATEGORY =
  'DOCUMENTS_CABINET_UPDATE_PERSONAL_CATEGORY'
export const DOCUMENTS_CABINET_UPDATE_DEPARTMENT_CATEGORY =
  'DOCUMENTS_CABINET_UPDATE_DEPARTMENT_CATEGORY'

export const DOCUMENTS_CABINET_NEW_ITEM_UNIT = 'DOCUMENTS_CABINET_NEW_ITEM_UNIT'
export const DOCUMENTS_CABINET_NEW_ITEM_DEPARTMENT =
  'DOCUMENTS_CABINET_NEW_ITEM_DEPARTMENT'
export const DOCUMENTS_CABINET_NEW_ITEM_PERSONAL =
  'DOCUMENTS_CABINET_NEW_ITEM_PERSONAL'
export const DOCUMENTS_CABINET_GET_ITEM_UNIT = 'DOCUMENTS_CABINET_GET_ITEM_UNIT'
export const DOCUMENTS_CABINET_GET_ITEM_DEPARTMENT =
  'DOCUMENTS_CABINET_GET_ITEM_DEPARTMENT'
export const DOCUMENTS_CABINET_GET_ITEM_PERSONAL =
  'DOCUMENTS_CABINET_GET_ITEM_PERSONAL'
export const DOCUMENTS_CABINET_EDIT_ITEM_UNIT =
  'DOCUMENTS_CABINET_EDIT_ITEM_UNIT'
export const DOCUMENTS_CABINET_EDIT_ITEM_DEPARTMENT =
  'DOCUMENTS_CABINET_EDIT_ITEM_DEPARTMENT'
export const DOCUMENTS_CABINET_EDIT_ITEM_PERSONAL =
  'DOCUMENTS_CABINET_EDIT_ITEM_PERSONAL'
export const DOCUMENTS_CABINET_MOVE_ITEM_UNIT =
  'DOCUMENTS_CABINET_MOVE_ITEM_UNIT'
export const DOCUMENTS_CABINET_MOVE_ITEM_DEPARTMENT =
  'DOCUMENTS_CABINET_MOVE_ITEM_DEPARTMENT'
export const DOCUMENTS_CABINET_MOVE_ITEM_PERSONAL =
  'DOCUMENTS_CABINET_MOVE_ITEM_PERSONAL'
export const DOCUMENTS_CABINET_GET_ITEMS_UNIT =
  'DOCUMENTS_CABINET_GET_ITEMS_UNIT'
export const DOCUMENTS_CABINET_GET_ITEMS_DEPARTMENT =
  'DOCUMENTS_CABINET_GET_ITEMS_DEPARTMENT'
export const DOCUMENTS_CABINET_GET_ITEMS_PERSONAL =
  'DOCUMENTS_CABINET_GET_ITEMS_PERSONAL'
export const CABINET_UNIT_ITEM = 'CABINET_UNIT_ITEM'
export const CABINET_DEPARTMENT_ITEM = 'CABINET_DEPARTMENT_ITEM'
export const CABINET_PERSONAL_ITEM = 'CABINET_PERSONAL_ITEM'
export const MOVE_DRAWER = 'MOVE_DRAWER'
export const MOVE_USER = 'MOVE_USER'

export const GET_DEPARTMENT_UNIT = 'GET_DEPARTMENT_UNIT'
export const EXPORT_DOCUMENT_DIGITIZE = 'EXPORT_DOCUMENT_DIGITIZE'
export const EXPORT_DOCUMENT_RECOVERY = 'EXPORT_DOCUMENT_RECOVERY'
export const GET_DOCUMENT_DIGITIZE = 'GET_DOCUMENT_DIGITIZE'
export const EXPORT_SENDDOCUMENT_EXCEL = 'EXPORT_SENDDOCUMENT_EXCEL'
export const DELETE_DIGITIZING_ITEM = 'DELETE_DIGITIZING_ITEM'
export const DELETE_ALERT_MISTAKE = 'DELETE_ALERT_MISTAKE'
export const GET_WORK_DOCUMENT_OVER_TIME = 'GET_WORK_DOCUMENT_OVER_TIME'
export const GET_GERENAL_DOCUMENT_OVER_TIME = 'GET_GERENAL_DOCUMENT_OVER_TIME'
export const DOWNLOADFILE = 'DOWNLOADFILE'
export const STORAGE_CAPACITY = 'STORAGE_CAPACITY'
export const DIGITAL_DOCUMENTS_FETCH_ITEM = 'DIGITAL_DOCUMENTS_FETCH_ITEM'
export const DOCUMENTS_EVN_FETCH_LIST = 'DOCUMENTS_EVN_FETCH_LIST'
export const DOCUMENTS_EVN_FETCH_LIST_NO_SCHEMA =
  'DOCUMENTS_EVN_FETCH_LIST_NO_SCHEMA'
export const DOCUMENTS_EVN_FETCH_FILE_ITEM = 'DOCUMENTS_EVN_FETCH_FILE_ITEM'
export const DOCUMENTS_EVN_FETCH_FILE_LIST = 'DOCUMENTS_EVN_FETCH_FILE_LIST'
export const DOCUMENTS_FETCH_MIGRATED_FILE = 'DOCUMENTS_FETCH_MIGRATED_FILE'
export const GET_SENDDOCUMENT_ADDITIONAL_INFORMATION =
  'GET_SENDDOCUMENT_ADDITIONAL_INFORMATION'
export const GET_SENDDOCUMENT_ITEM = 'GET_SENDDOCUMENT_ITEM'
export const ALERT_MISTAKE_GET_LIST = 'ALERT_MISTAKE_GET_LIST'
export const GET_GENERAL_DOCUMENT = 'GET_GENERAL_DOCUMENT'
export const EXPORT_GENERAL_DOCUMENT = 'EXPORT_GENERAL_DOCUMENT'
export const GET_GENERAL_WORK_UNIT = 'GET_GENERAL_WORK_UNIT'
export const GET_DOCUMENT_RECOVERY = 'GET_DOCUMENT_RECOVERY'
// Notification
export const NOTIFICATION_FETCH_LIST_ADMIN = 'NOTIFICATION_FETCH_LIST_ADMIN'
export const NOTIFICATION_FETCH_LIST = 'NOTIFICATION_FETCH_LIST'
export const NOTIFICATION_FETCH_ITEM = 'NOTIFICATION_FETCH_ITEM'
export const NOTIFICATION_GET_LASTEST = 'NOTIFICATION_GET_LASTEST'
export const NOTIFICATION_POST_READED = 'NOTIFICATION_POST_READED'
export const NOTIFICATION_CREATE = 'NOTIFICATION_CREATE'
export const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE'
export const NOTIFICATION_DELETE = 'NOTIFICATION_DELETE'
//Change readonly datepicker 1024px screen
export const READONLY_ENABLE = 'READONLY_ENABLE'
export const SCROLL_FIXTOP_MENU = 'SCROLL_FIXTOP_MENU'

export const GET_LEADER_STAFF_CAN_ASSIGN = 'GET_LEADER_STAFF_CAN_ASSIGN'
export const GET_FILE_INFORMATION = 'GET_FILE_INFORMATION'
//Get Number Sidebar
export const GET_NUMBER_HOME_PAGE = 'GET_NUMBER_HOME_PAGE'
export const GET_NUMBER_DOCUMENT_PAGE = 'GET_NUMBER_DOCUMENT_PAGE'
export const GET_NUMBER_SIGNNUMBER_PAGE = 'GET_NUMBER_SIGNNUMBER_PAGE'
export const GET_NUMBER_WORK_PAGE = 'GET_NUMBER_WORK_PAGE'
export const GET_PATH_DOCUMENTCABINET = 'GET_PATH_DOCUMENTCABINET'
export const SEARCH_FILE_CONG_VAN = 'SEARCH_FILE_CONG_VAN'
export const GET_NUMBER_EVN_DOCUMENT = 'GET_NUMBER_EVN_DOCUMENT'
export const GET_NUMBER_PROJECT_SIGNNUMBER_PAGE =
  'GET_NUMBER_PROJECT_SIGNNUMBER_PAGE'
export const GET_ATTACH_FILE_FROM_SIGN_NUMBER =
  'GET_ATTACH_FILE_FROM_SIGN_NUMBER'
export const SET_DOCUMENT_CABINET_PATH = 'SET_DOCUMENT_CABINET_PATH'
export const GET_NUMBER_KSTT_PAGE = 'GET_NUMBER_KSTT_PAGE'

// Procedure - Quy trình
export const ProcedureCategory = {
  COLLECTION: 'SHOW_PROCEDURE_CATEGORY_COLLECTION',
  ITEM: 'SHOW_PROCEDURE_CATEGORY_ITEM',
  CREATE: 'CREATE_PROCEDURE_CATEGORY',
  UPDATE: 'UPDATE_PROCEDURE_CATEGORY',
  DELETE: 'DELETE_PROCEDURE_CATEGORY',
  SORT: 'SORT_PROCEDURE_CATEGORY',
}

export const Procedure = {
  COLLECTION: 'SHOW_PROCEDURE_COLLECTION',
  ITEM: 'SHOW_PROCEDURE_ITEM',
  CREATE: 'CREATE_PROCEDURE',
  UPDATE: 'UPDATE_PROCEDURE',
  DELETE: 'DELETE_PROCEDURE',
  COLLECTION_GROUP: 'SHOW_PROCEDURE_COLLECTION_GROUP',
}

export const AppliedUnit = {
  COLLECTION: 'SHOW_APPLIED_UNIT_COLLECTION',
  CREATE: 'CREATE_APPLIED_UNIT',
  DELETE: 'DELETE_APPLIED_UNIT',
}

export const Authority = {
  COLLECTION: 'SHOW_AUTHORITY_GROUP_COLLECTION',
  ITEM: 'SHOW_AUTHORITY_GROUP_ITEM',
  CREATE_GROUP: 'CREATE_AUTHORITY_GROUP',
  DELETE_GROUP: 'DELETE_AUTHORITY_GROUP',
  ADD_MEMBER: 'ADD_AUTHORITY_GROUP_MEMBER',
  REMOVE_MEMBER: 'REMOVE_AUTHORITY_GROUP_MEMBER',
}
//Nhân viên
export const Employee = {
  COLLECTION: 'SHOW_PROCEDURE_COLLECTION',
}

export const SignatureDocument = {
  ITEM: 'SHOW_SIGNATURE_DOCUMENT_ITEM',
}

export const REVOKE_DOCUMENTS = 'REVOKE_DOCUMENTS'
export const REVOKE_DOCUMENTS_DETAIL = 'REVOKE_DOCUMENTS_DETAIL'
export const GET_NUMBER_CONGVANTHUHOI = 'GET_NUMBER_CONGVANTHUHOI'
export const SERVICE_DOCUMENTS = 'SERVICE_DOCUMENTS'
export const STATISTICAL_SERVICE_DOCUMENTS = 'STATISTICAL_SERVICE_DOCUMENTS'
export const SERVICE_DOCUMENTS_DETAIL = 'SERVICE_DOCUMENTS_DETAIL'
export const CONFIG_REFRESH_SERVICE_DOCUMENT = 'CONFIG_REFRESH_SERVICE_DOCUMENT'
// Get dots
export const GET_DOTS_WITH_CHUCDANH = 'GET_DOTS_WITH_CHUCDANH'

//phiếu yêu cầu góp ý
export const REQUEST_COMMENT_CREATE = 'REQUEST_COMMENT_CREATE'
export const REQUEST_COMMENTS = 'REQUEST_COMMENTS'
export const REQUEST_COMMENT_DETAIL = 'REQUEST_COMMENT_DETAIL'
export const REQUEST_COMMENT_DELETE = 'REQUEST_COMMENT_DELETE'
export const REQUEST_COMMENT_DUYET = 'REQUEST_COMMENT_DUYET'
export const REQUEST_COMMENT_HOAN_TAT = 'REQUEST_COMMENT_HOAN_TAT'
export const REQUEST_COMMENT_STATISTICS = 'REQUEST_COMMENT_STATISTICS' //thong ke
export const REQUEST_COMMENT_SUMMARY = 'REQUEST_COMMENT_SUMMARY' //tổng hợp
export const EXPORT_REQUEST_COMMENT_SUMMARY = 'EXPORT_REQUEST_COMMENT_SUMMARY' // xuất excel tổng hợp góp ý
export const EXPORT_REQUEST_COMMENTS = 'EXPORT_REQUEST_COMMENTS'
export const FLOW_REQUEST_COMMENTS = 'FLOW_REQUEST_COMMENTS'
export const FLOW_RECEIVE_COMMENT = 'FLOW_RECEIVE_COMMENT'

export const RECEIVE_COMMENT_CHIDAO = 'RECEIVE_COMMENT_CHIDAO'
export const RECEIVE_COMMENT_GIAOVIEC = 'RECEIVE_COMMENT_GIAOVIEC'
export const THUC_HIEN_GOP_Y = 'THUC_HIEN_GOP_Y'
export const CANCEL_REQUEST_COMMENT_APPROVE = 'CANCEL_REQUEST_COMMENT_APPROVE'
// Hoi dong thanh vien
export const API_REQUEST_OPINION = 'API_REQUEST_OPINION'
export const OPINION_SEND = 'OPINION_SEND'
export const OPINION_DETAIL = 'OPINION_DETAIL'
export const CONFIG_REFRESH_OPINION = 'CONFIG_REFRESH_OPINION'
export const GET_NUMBER_PHIEU_Y_KIEN = 'GET_NUMBER_PHIEU_Y_KIEN'
// công văn đến bị thu hồi
export const CONGVANDENTHUHOI_GET = 'CONGVANDENTHUHOI_GET'
export const CONGVANDENTHUHOI_XUAT_EXCEL = 'CONGVANDENTHUHOI_XUAT_EXCEL'

// Chat API
export const CHAT_GET = 'CHAT_GET'

export const GET_PHUONGPHAPLUU_DS = 'GET_PHUONGPHAPLUU_DS'
export const GET_PHUONGPHAPLUU_CHITIET = 'GET_PHUONGPHAPLUU_CHITIET'
export const API_REQUEST_NHANVIEN_PHONGBAN_QUYEN =
  'API_REQUEST_NHANVIEN_PHONGBAN_QUYEN'
export const GET_THOIGIANLUU_DANHSACH = 'GET_THOIGIANLUU_DANHSACH'
// Dannh sách công văn 6.1
export const DANH_SACH_CONG_VAN_61 = 'DANH_SACH_CONG_VAN_61'
export const GET_NUMBER_HSCV = 'GET_NUMBER_HSCV'

// QUẢN LÝ CÔNG VIÊC
export const GET_THONGKE_TRANG_CHU = 'GET_THONGKE_TRANG_CHU'
export const GET_THONGKE_QUANLY_CONGVIEC_LOAI_GIAOVIEC =
  'GET_THONGKE_QUANLY_CONGVIEC_LOAI_GIAOVIEC'
export const GET_THONGKE_QUANLY_CONGVIEC_TRANG_THAI =
  'GET_THONGKE_QUANLY_CONGVIEC_TRANG_THAI'
export const GET_NOTIFICATION_COUNTER = 'GET_NOTIFICATION_COUNTER'
export const GET_COUNTER_CONGVIEC_PHONGBAN_CANHAN =
  'GET_COUNTER_CONGVIEC_PHONGBAN_CANHAN'
// Công văn nội bộ
export const LUONG_CONG_VAN_NOI_BO = 'LUONG_CONG_VAN_NOI_BO'

export const WORKS_STATISTICS_DASHBOARD = 'WORKS_STATISTICS_DASHBOARD'
export const GET_REQUEST_DASHBOARD = 'GET_REQUEST_DASHBOARD'
export const COMMON_ADD_TREE_PHONGBAN_DONVI_NHANVIEN =
  'COMMON_ADD_TREE_PHONGBAN_DONVI_NHANVIEN'
export const COMMON_ADD_TREE_DEPARTMENT_ORGANIZATION =
  'COMMON_ADD_TREE_DEPARTMENT_ORGANIZATION'
export const GET_LANHDAO_DONVI = 'GET_LANHDAO_DONVI'
export const GET_THONGTIN_DONVI = 'GET_THONGTIN_DONVI'
export const SET_DO_TOKEN = 'SET_DO_TOKEN'
export const SET_DO_PHONGBAN_NOIBO = 'SET_DO_PHONGBAN_NOIBO'
export const GET_WORK_TYPES = 'GET_WORK_TYPES'
export const GET_WIDGET_HOME = 'GET_WIDGET_HOME'
export const UPDATE_WIDGET_HOME = 'UPDATE_WIDGET_HOME'

// office-DO
export const GET_DOCS_LIST_DO = 'GET_DOCS_LIST_DO'
export const GET_PHONGBAN_LIST_DO = 'GET_PHONGBAN_LIST_DO'
export const GET_DONVI_LIST_DO = 'GET_DONVI_LIST_DO'
export const GET_SOVB_DO = 'GET_SOVB_DO'
export const GET_PHONGBANXULY_DO = 'GET_PHONGBANXULY_DO'
export const GET_LANHDAOCHIDAO_DO = 'GET_LANHDAOCHIDAO_DO'
export const GET_CHUYENVIENXULY_DO = 'GET_CHUYENVIENXULY_DO'
export const GET_DANHSACHCONGVAN_DO = 'GET_DANHSACHCONGVAN_DO'

// chi tiết do
export const GET_CHITIETVANBANSOHOA_DO = 'GET_CHITIETVANBANSOHOA_DO'
export const GET_CHITIETVANBANSOHOA_DO_RESET = 'GET_CHITIETVANBANSOHOA_DO_RESET'
export const GET_LISTVANBAN_DO = 'GET_LISTVANBAN_DO'
export const GET_LIST_DONVI_VANBAN_DO = 'GET_LIST_DONVI_VANBAN_DO'
export const RESET_DO = 'RESET_DO'
export const GET_LIST_QUAN_HE_DONVI_VANBAN_DO =
  'GET_LIST_QUAN_HE_DONVI_VANBAN_DO'
export const GET_LIST_QUAN_HE_DONVI_VANBAN_DO_RESET =
  'GET_LIST_QUAN_HE_DONVI_VANBAN_DO_RESET'
export const GET_PHIEU_LAY_Y_KIEN = 'GET_PHIEU_LAY_Y_KIEN'
export const GET_LAY_Y_KIEN_DON_VI = 'GET_LAY_Y_KIEN_DON_VI'

// Tạo việc từ bên ngoài
export const SET_ME_INFOS = 'SET_ME_INFOS'
export const AUTH_SET_TOKEN_CREATE_WORK = 'AUTH_SET_TOKEN_CREATE_WORK'
export const USER_PERMISSION_CREATE_WORK = 'USER_PERMISSION_CREATE_WORK'

export const API_REQUEST_SHARED_API = 'API_REQUEST_SHARED_API'
export const API_REQUEST_DETAIL_APPLICATION = 'API_REQUEST_DETAIL_APPLICATION'
export const API_REQUEST_AUTHENTOKEN = 'API_REQUEST_AUTHENTOKEN'
export const API_REQUEST_APICATEGORY = 'API_REQUEST_APICATEGORY'
export const API_REQUEST_AUTHENTOKEN_DETAILS = 'API_REQUEST_AUTHENTOKEN_DETAILS'
