import React, { memo } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  Classes,
  Button,
  Intent,
  Checkbox,
  Position,
  Radio,
} from '@blueprintjs/core'
import moment from 'moment'
import size from 'lodash/size'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import toPairs from 'lodash/toPairs'

import { MasterLayout } from 'components/layout'
import Title from 'components/title'
import { Permission } from 'components/auth'
import {
  Row,
  Col,
  Upload,
  Collapse,
  FileGroup,
  Icon,
  Dialog,
  Card,
} from 'components/newCommon'
import {
  Input,
  Select,
  RemindSelect,
  CheckboxSelect,
  FormDatePicker,
  DateAdvanced,
} from 'components/newCommon2'
import ActionFixed from 'components/action-fixed'
import { priorities } from 'constants/task'
import { documentTypes, allowDocumentFileTypes } from 'constants/fileTypes'
import PhongBanVaDonViThucHien from './components/phong-ban-don-vi-thuc-hien'
import useTaskThongbaoKLCHTaskCreate from './hooks/use-task-thongbao-klch-task-create'

const TaskThongBaoKLCHTaskCreate = ({
  history,
  match,
  hasShowAllDeparment,
  mainDepartmentId,
  mainUnitId,
  dataTreeOrganizationDepartmentStore,
}) => {
  const action = match.params?.action
  const {
    treeKeys,
    tags,
    fetching,
    tasks,
    validateErrors,
    dataAutoToggle,
    isLoading,
    isDisableButton,
    files,
    isShowDialog,
    onCloseDialog,
    onDeleteTask,
    toggleCollapseExtra,
    addMeeting,
    removeTask,
    changeField,
    toggleCollapseTask,
    onChangeRadio,
    onChangeCheckbox,
    handleCheckActive,
    handleCheckIndeterminate,
    attachmentFilesFromDocument,
    changeFile,
    removeAttachmentFiles,
    submitUpdate,
    submitCreate,
  } = useTaskThongbaoKLCHTaskCreate({
    history,
    match,
    hasShowAllDeparment,
    mainDepartmentId,
    mainUnitId,
    dataTreeOrganizationDepartmentStore,
  })

  const column = [
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: 'CT',
      isRequired: true,
      render: (record, _, __, taskId) => {
        const taskItem = tasks?.find(
          elm =>
            elm.id === taskId &&
            elm?.data?.[treeKeys.CHU_TRI]?.id === record?.id
        )
        let isChecked = false
        if (!isEmpty(taskItem)) {
          isChecked = true
        }

        if (
          (!hasShowAllDeparment && record?.phongBanId === mainDepartmentId) ||
          record?.type === 'ALL'
        ) {
          return ''
        }

        return (
          <Radio
            className={Classes.SMALL}
            checked={isChecked}
            onChange={() => onChangeRadio(treeKeys.CHU_TRI, record, taskId)}
          />
        )
      },
    },
    {
      title: 'PH',
      render: (record, _, __, taskId) => {
        const isChecked = handleCheckActive(
          treeKeys.PHOI_HOP,
          tasks,
          taskId,
          record
        )
        const isIndeterminate = handleCheckIndeterminate(
          treeKeys.PHOI_HOP,
          tasks,
          taskId,
          record
        )
        if (!hasShowAllDeparment && record?.phongBanId === mainDepartmentId) {
          return ''
        }

        return (
          <Checkbox
            className={Classes.SMALL}
            checked={isChecked || false}
            indeterminate={isIndeterminate && !isChecked}
            onChange={e =>
              onChangeCheckbox(
                treeKeys.PHOI_HOP,
                record,
                taskId,
                e,
                isIndeterminate
              )
            }
          />
        )
      },
    },
  ]

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="mt10">
        <Title
          name={action === 'chinh-sua' ? 'Sửa / hủy kết luận' : 'Tạo kết luận'}
          icon="icon2-clipboard"
        />
        <div className="cpc-form bg-white pt30 pl30 pr30 border-radius-4 mt15">
          {tasks.map(({ id, showTask, showExtra, data }, index) => (
            <div key={index}>
              {size(tasks) > 1 && (
                <header className="d-flex">
                  <Button
                    className={
                      'cpc-button cpc-button-link no-border transparent blue-text ph5'
                    }
                    onClick={toggleCollapseTask(id)}
                  >
                    <span className="mr5">{`Kết luận ${index + 1}`}</span>
                    <span
                      className={classNames(
                        'icon2-left size-icon-9 d-flex align-items-center justify-content-center',
                        {
                          'rotate-270': !showTask,
                          'rotate-90': showTask,
                        }
                      )}
                    ></span>
                  </Button>
                  <Button
                    className={classNames(
                      Classes.MINIMAL,
                      'cpc-button',
                      'cpc-form-button-right'
                    )}
                    intent={Intent.DANGER}
                    onClick={removeTask(id)}
                  >
                    <Icon classIcon="icon-bin" />
                  </Button>
                </header>
              )}
              <Collapse isOpen={showTask}>
                <Input
                  required={true}
                  disabled={fetching}
                  label="Nội dung kết luận"
                  onChange={changeField(id, 'title')}
                  placeholder="Nhập nội dung kết luận"
                  type="textarea"
                  rows={2}
                  maxLength={2000}
                  autoResize={true}
                  value={get(data, 'title')}
                  wrapperClassName="mb15"
                  labelButton={
                    <div style={{ marginLeft: 'auto' }}>
                      <Checkbox
                        className={classNames(Classes.SMALL, 'mb0')}
                        checked={get(data, 'requireReport', false)}
                        onChange={({ target: { checked } }) =>
                          changeField(id, 'requireReport')(checked)
                        }
                      >
                        <span className="font-size-13 font-weight-300">
                          Yêu cầu báo cáo Hoàn thành
                        </span>
                      </Checkbox>
                    </div>
                  }
                  warning={get(validateErrors, `${id}.title`)}
                />
                <PhongBanVaDonViThucHien
                  className="mb10"
                  isRequired={true}
                  column={column}
                  data={dataTreeOrganizationDepartmentStore}
                  taskId={id}
                  warning={get(validateErrors, `${id}.${treeKeys.CHU_TRI}`)}
                  dataAutoToggle={dataAutoToggle}
                />
                <Row gutterVertical className="mb10">
                  <Col grid="half">
                    <FormDatePicker
                      textLabel="Ngày bắt đầu"
                      disabled={fetching}
                      inputClearable
                      inputIconClassName="icon2-date-frames"
                      inputWrapperClassName="mb10"
                      onChange={value => {
                        changeField(id, 'executionDate')(value)
                      }}
                      maximumDate={
                        get(data, 'deadline')
                          ? moment(get(data, 'deadline')).toDate()
                          : undefined
                      }
                      placeholder="Chọn ngày bắt đầu"
                      popoverStretch={false}
                      popoverPosition={Position.TOP_LEFT}
                      selected={get(data, 'executionDate')}
                    />
                  </Col>
                  <Col grid="half">
                    <DateAdvanced
                      textLabel="Hạn thực hiện"
                      disabled={fetching}
                      inputClearable
                      inputIconClassName="icon2-date-frames"
                      inputWrapperClassName="mb10"
                      placeholder="Chọn hạn thực hiện"
                      popoverStretch={false}
                      selected={get(data, 'deadline')}
                      inputWarning={get(validateErrors, `${id}.deadline`)}
                      minimumDate={
                        get(data, 'executionDate')
                          ? moment(get(data, 'executionDate')).toDate()
                          : moment().toDate()
                      }
                      popoverPosition={Position.TOP_LEFT}
                      onChange={value => {
                        changeField(id, 'deadline')(value)
                      }}
                    />
                  </Col>
                </Row>
                <Collapse isOpen={showExtra}>
                  <Input
                    disabled={fetching}
                    label="Mô tả"
                    onChange={changeField(id, 'description')}
                    placeholder="Nhập..."
                    rows={2}
                    maxLength={500}
                    autoResize={true}
                    style={{ maxHeight: 'unset' }}
                    type="textarea"
                    value={get(data, 'description')}
                    wrapperClassName="mb10"
                  />
                  <Row gutterVertical className="mb10">
                    <Col grid="half">
                      <Select
                        textLabel="Mức độ ưu tiên"
                        dataSource={toPairs(priorities).map(
                          ([value, label]) => ({ value, label })
                        )}
                        disabled={fetching}
                        inputIconClassName="icon-flag"
                        onChange={changeField(id, 'priority')}
                        placeholder="Chọn mức độ ưu tiên"
                        value={get(data, 'priority')}
                      />
                    </Col>
                    <Col grid="half">
                      <RemindSelect
                        textLabel="Thời gian nhắc việc"
                        inputIconClassName="icon2-date-frames"
                        deadline={get(data, 'deadline')}
                        disabled={fetching}
                        onChange={changeField(id, 'workReminder')}
                        value={data?.workReminder || null}
                        placeholder="Thời gian nhắc việc"
                        popoverPosition={Position.BOTTOM_RIGHT}
                      />
                    </Col>
                  </Row>
                  <CheckboxSelect
                    textLabel="Loại công việc"
                    disabled={fetching}
                    inputClearable
                    inputIconClassName="icon2-tag"
                    inputWrapperClassName="mb10"
                    placeholder="Loại công việc"
                    popoverPosition={Position.TOP_LEFT}
                    onChange={changeField(id, 'tags')}
                    dataSource={tags.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    value={get(data, 'tags', [])}
                  />
                  {!!size(get(data, 'referenceFiles', [])) && (
                    <>
                      <label className="cpc-detail-label">File công văn</label>
                      <FileGroup list={get(data, 'referenceFiles')} />
                    </>
                  )}
                  {!!size(get(data, 'attachmentFiles', [])) &&
                    !!size(
                      attachmentFilesFromDocument(
                        get(data, 'attachmentFiles', []),
                        true
                      )
                    ) && (
                      <>
                        <label className="cpc-detail-label">
                          File đính kèm từ công văn
                        </label>
                        <FileGroup
                          list={attachmentFilesFromDocument(
                            get(data, 'attachmentFiles', []),
                            true
                          )}
                          onClose={removeAttachmentFiles(id)}
                        />
                      </>
                    )}
                  {((!!size(get(data, 'attachmentFiles', [])) &&
                    !!size(
                      attachmentFilesFromDocument(
                        get(data, 'attachmentFiles', []),
                        false
                      )
                    )) ||
                    !!size(files[id])) && (
                    <>
                      <label className="cpc-detail-label">File đính kèm</label>
                      <FileGroup
                        list={attachmentFilesFromDocument(
                          get(data, 'attachmentFiles', []),
                          false
                        )}
                        listUpload={files[id]}
                        onClose={removeAttachmentFiles(id)}
                        showClose
                      />
                    </>
                  )}
                  <Upload
                    textLabel="File đính kèm"
                    disabled={fetching}
                    accept={documentTypes.toString()}
                    fileList={get(files, id, [])}
                    textSumary={
                      <p className="mt5 font-style-italic font-size-12">
                        Tải file có dung lượng tối đa 100MB, định dạng .pdf,
                        .docs, .doc, .xls, .xlsx, .ppt, .pptx
                      </p>
                    }
                    wrapperClassName="mb10"
                    isShowList={false}
                    allowFileTypes={allowDocumentFileTypes}
                    onChange={changeFile(id)}
                  />
                </Collapse>
                <div className="d-flex align-items-center justify-content-center pt5 pb15">
                  <Button
                    className={
                      'cpc-button cpc-button-link no-border transparent blue-text ph5'
                    }
                    onClick={toggleCollapseExtra(id)}
                  >
                    <span className="mr5">
                      {showExtra ? 'Thu gọn' : 'Mở rộng'}
                    </span>
                    <span
                      className={classNames(
                        'icon2-left size-icon-9 d-flex align-items-center justify-content-center',
                        {
                          'rotate-270': !showExtra,
                          'rotate-90': showExtra,
                        }
                      )}
                    ></span>
                  </Button>
                </div>
              </Collapse>
            </div>
          ))}
        </div>
        {action !== 'chinh-sua' && (
          <div className={'d-flex align-items-center bg-white border-top pv30'}>
            <div className="ph15">
              <Button className={'cpc-button green-no-bg'} onClick={addMeeting}>
                <Icon classIcon="icon-Plus" className="size-icon-12 mr5" />
                <span>Thêm nội dung kết luận</span>
              </Button>
            </div>
          </div>
        )}
        <div
          className={
            'd-flex align-items-center justify-content-center gap-15 bg-white border-radius-4 border-top ph15'
          }
        >
          <Button
            className={
              'cpc-button white-border-gray uppercase font-size-13 ph10 min-width-100'
            }
            disabled={isLoading}
            onClick={() => history.goBack()}
          >
            <Icon classIcon="icon-back" className="mr5" />
            Quay lại
          </Button>
          <Button
            className={
              'cpc-button blue uppercase font-size-13 ph10 min-width-80'
            }
            disabled={isLoading || isDisableButton}
            loading={isLoading}
            onClick={action === 'chinh-sua' ? submitUpdate : submitCreate}
          >
            Lưu
            <Icon classIcon="icon-save" className="ml5" />
          </Button>
        </div>
        <ActionFixed
          isLoading={isLoading}
          isDisabled={isLoading || isDisableButton}
          onClose={() => history.goBack()}
          onSubmit={action === 'chinh-sua' ? submitUpdate : submitCreate}
        />
        <Dialog
          buttonUppercase
          isOpen={isShowDialog}
          cancelClassName="pl20 pr20"
          cancelText="Hủy"
          confirmClassName="pl20 pr20"
          confirmText="Xóa"
          onCancel={onCloseDialog}
          onConfirm={onDeleteTask}
          onClose={onCloseDialog}
          title={'Xóa kết luận'}
          textCenter
        >
          <Card className="mt20 mb20">{'Anh/Chị muốn xóa kết luận này?'}</Card>
        </Dialog>
      </div>
    </MasterLayout>
  )
}

TaskThongBaoKLCHTaskCreate.propTypes = {
  mainUnitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasShowAllDeparment: PropTypes.bool,
  mainDepartmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTreeOrganizationDepartmentStore: PropTypes.array,
}

const mapStateToProps = state => {
  const hasShowAllDeparment = get(state, 'auth.permission', []).includes(
    Permission.THONGBAOKETLUANHDTV
  )

  return {
    mainUnitId: state?.auth.mainUnitId,
    hasShowAllDeparment,
    mainDepartmentId: get(state, 'auth.mainDepartmentId'),
    dataTreeOrganizationDepartmentStore:
      state?.common?.dataTreeOrganizationDepartmentStore,
  }
}

export default connect(mapStateToProps)(memo(TaskThongBaoKLCHTaskCreate))
