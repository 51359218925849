import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio, RadioGroup, Button, Intent } from '@blueprintjs/core'
import 'react-select/dist/react-select.css'
import includes from 'lodash/includes'
import * as MESSAGE from '../../constants/MessageForm'
import * as Tool from '../../helpers'
import classnames from 'classnames'
import { bindActionCreators } from 'redux'
import * as Actions from '../../actions'
import { connect } from 'react-redux'
import * as TRANGTHAI from '../../constants/RequestCommentStatus'

class ThucHienGopY extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getUploadProgress = this.getUploadProgress.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  state = {
    mainDepartmentId:
      this.props.auth && this.props.auth.user
        ? this.props.auth.user.mainDepartmentId
        : null,
    mainUnitId:
      this.props.auth && this.props.auth.user
        ? this.props.auth.user.mainUnitId
        : null,
    idPhieuNhan: this.props.requestComments.id,
    isAdd: [''],
    attachFiles: [],
    errors: [],
    error: {},
    isOpenPopup: false,
    dataSelect: '',
    fileEdit: [],
    attachFileName: [],
    isOpenPopupDelete: false,
    sizeFile: [],
    isLoading: {
      isLoadingSubmit: false,
    },
    attachFilesProgress: [0],
    form: {
      noi_dung: '',
      trang_thai_gop_y: '2',
      file_gop_y: {},
      lanh_dao_phong_ban: {},
      lanh_dao_don_vi: {},
      lanhDaoPhongBanId: '',
      lanhDaoDonViId: '',
    },
    isSuaGopY: false,
  }

  getUploadProgress = (name, i) => progress => {
    let state = this.state
    if (!isNaN(i) && name) {
      state[name][i] = Math.floor((progress.loaded * 100) / progress.total)
    }
    this.setState(state)
  }

  handleSubmit = async () => {
    this.setState({ error: {} })
    const error = {}

    if (!this.state.form.noi_dung || this.state.form.noi_dung.length === 0) {
      error.noi_dung = MESSAGE.TEXT_MESSAGE_DEFAULT
    }

    this.setState({
      error: error,
      isLoading: {
        ...this.state.isLoading,
        isLoadingSubmit: true,
      },
    })

    if (Object.keys(error).length !== 0) {
      this.setState({
        isLoading: {
          ...this.state.isLoading,
          isLoadingSubmit: false,
        },
      })
      this.props.onSubmit(
        'GopY',
        {},
        MESSAGE.TOATS_MESSAGE_WARNING,
        Intent.WARNING,
        false
      )
      return
    }

    const objectFile = []
    let files = []
    let attachFilesProgress = []

    if (this.state.attachFiles && this.state.attachFiles.length) {
      attachFilesProgress = Array(this.state.attachFiles.length).fill(0)
      await this.setState({ attachFilesProgress })
      try {
        await Promise.all(
          this.state.attachFiles.map(async (item, i) => {
            const data = await this.props.actions.fileUpload(
              item,
              this.getUploadProgress('attachFilesProgress', i)
            )
            return files.push({
              file_id: data.payload.data.result[0].id,
              kieu_file: data.payload.data.result[0].extension,
              ten_file: data.payload.data.result[0].originalName,
              url: data.payload.data.result[0].path,
              kich_thuoc: data.payload.data.result[0].length,
            })
          })
        )
      } catch (e) {
        this.setState({
          isLoading: {
            ...this.state.isLoading,
            isLoadingSubmit: false,
          },
        })
        return this.props.onSubmit(
          'GopY',
          {},
          MESSAGE.TOATS_MESSAGE_FAIL,
          Intent.DANGER,
          false
        )
      }
    }

    files.map(item => objectFile.push(item))
    if (this.state.fileEdit) {
      this.state.fileEdit.map(item => objectFile.push(item))
    }
    if (objectFile.length > 0) {
      let state = this.state
      state.form['file_gop_y'] = objectFile[0]
      this.setState(state)
    }
    delete this.state.attachFiles
    delete this.state.fileEdit

    if (this.state.isSuaGopY === true) {
      this.props.actions
        .chinhSuaGopY(this.state.idPhieuNhan, this.state.form)
        .then(
          res => {
            if (res.error) {
              this.setState({
                isLoading: {
                  ...this.state.isLoading,
                  isLoadingSubmit: false,
                },
              })
              return this.props.onSubmit(
                'GopY',
                {},
                MESSAGE.TEXT_MESSAGE_SUA_GOP_Y_KHONGTHANHCONG,
                Intent.DANGER,
                false
              )
            } else if (res.payload && res.payload.status === 200) {
              this.props.onSubmit(
                'GopY',
                {},
                MESSAGE.TEXT_MESSAGE_SUA_GOP_Y_THANHCONG,
                Intent.SUCCESS,
                true
              )
            }
          },
          () => {
            return this.setState({
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: false,
              },
            })
          }
        )
    } else {
      this.props.actions
        .thucHienGopY(this.state.idPhieuNhan, this.state.form)
        .then(
          res => {
            if (res.error) {
              this.setState({
                isLoading: {
                  ...this.state.isLoading,
                  isLoadingSubmit: false,
                },
              })
              return this.props.onSubmit(
                'GopY',
                {},
                MESSAGE.TEXT_MESSAGE_THUC_HIEN_GOP_Y_KHONGTHANHCONG,
                Intent.DANGER,
                false
              )
            } else if (res.payload && res.payload.status === 200) {
              this.props.onSubmit(
                'GopY',
                {},
                MESSAGE.TEXT_MESSAGE_THUC_HIEN_GOP_Y_THANHCONG,
                Intent.SUCCESS,
                true
              )
            }
          },
          () => {
            return this.setState({
              isLoading: {
                ...this.state.isLoading,
                isLoadingSubmit: false,
              },
            })
          }
        )
    }
  }

  handleEventRemoveFile = i => {
    const state = this.state
    if (i !== 0) {
      state.isAdd.splice(i, 1)
    } else {
      if (state.isAdd.length > 1) {
        state.isAdd.splice(i, 1)
      } else {
        state.isAdd[i] = ''
      }
    }
    if (state.isSuaGopY === true) {
      state.form['file_gop_y'] = {}
    }
    state.attachFiles.splice(i, 1)
    state.errors.splice(i, 1)
    state.fileEdit.splice(i, 1)
    state.attachFileName.splice(i, 1)
    state.sizeFile.splice(i, 1)

    this.setState(state)
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name
    let state = this.state
    state.form[name] = value
    delete state.error[name]
    if (name === 'lanhDaoDonViId') {
      delete state.error['lanhDaoDonViId']
      const lanhDaoDonVi =
        this.props.lanhDaoDonVi &&
        this.props.lanhDaoDonVi.items.find(item => item.chucDanhId === value)
      if (lanhDaoDonVi) {
        state.form.lanh_dao_don_vi['chuc_danh_id'] = lanhDaoDonVi.chucDanhId
        state.form.lanh_dao_don_vi['nhan_vien_id'] = lanhDaoDonVi.nhanVienId
        state.form.lanh_dao_don_vi['phong_ban_id'] =
          lanhDaoDonVi.phongBan.phongBanId
        state.form.lanhDaoDonViId = lanhDaoDonVi.chucDanhId
      }
    }
    if (name === 'lanhDaoPhongBanChuTriId') {
      delete state.error['lanhDaoPhongBanId']
      const lanhDaoPhongBanLap =
        this.props.lanhDaoPhongBan &&
        this.props.lanhDaoPhongBan.items.find(item => item.chucDanhId === value)
      if (lanhDaoPhongBanLap) {
        state.form.lanh_dao_phong_ban['chuc_danh_id'] =
          lanhDaoPhongBanLap.chucDanhId
        state.form.lanh_dao_phong_ban['nhan_vien_id'] =
          lanhDaoPhongBanLap.nhanVienId
        state.form.lanh_dao_phong_ban['phong_ban_id'] =
          lanhDaoPhongBanLap.phongBan.phongBanId
        state.form.lanhDaoPhongBanId = lanhDaoPhongBanLap.chucDanhId
      }
    }
    this.setState(state)
  }

  handleFile = (i, e) => {
    const [name, id] = e.target.name.split('-')
    let state = this.state
    let reader = new FileReader()
    let file = e.target.files[0]

    const errors = this.state.errors
    const attachFiles = e.target.value
    const extension =
      attachFiles &&
      attachFiles
        .slice(((attachFiles.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
    const fileEdit = this.state.fileEdit
    const sizeFile = file && file.size
    const typesFile = [
      'pdf',
      'doc',
      'docx',
      'xls',
      'xlsx',
      'ppt',
      'pptx',
      'ppsx',
      'pps',
    ]
    if (!includes(typesFile, extension)) {
      errors[i] = MESSAGE.ValidateForm.FILE_TYPE(typesFile)
    } else if (sizeFile && sizeFile > 1024 * 1024 * 25) {
      errors[i] = MESSAGE.TEXT_MAXIMUM_SIZE_FILE
    } else {
      delete errors[i]
      fileEdit.splice(i, 1)
    }

    this.setState({ errors, fileEdit })

    if (!errors[i]) {
      reader.onloadend = () => {
        state[name][id] = file
        state.attachFileName[i] = file.name
        state.sizeFile[i] = file.size
        this.setState(state)
      }
    } else {
      state.attachFiles.splice(i, 1)
      state.sizeFile.splice(i, 1)
      this.setState({ state })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  componentDidMount = async () => {
    //get list lanh dao phong ban
    if (this.state.mainDepartmentId) {
      await this.props.actions
        .commonPersonalLeaderSignNumber(this.state.mainDepartmentId)
        .then(async () => {
          await this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              lanhDaoPhongBanChuTriId: false,
            },
          })
        })
    }
    //get list lanh dao don vi
    if (this.state.mainUnitId) {
      await this.props.actions
        .commonSignNumberDepartmentLeader(this.state.mainUnitId)
        .then(() => {
          this.setState({
            isLoadingData: {
              ...this.state.isLoadingData,
              lanhDaoDonViId: false,
            },
          })
        })
    }
    let state = this.state
    let lanhDaoDonViChiDao =
      this.props.requestComments &&
      this.props.requestComments.lanhDaoDonViChiDao &&
      this.props.requestComments.lanhDaoDonViChiDao.chucDanhId
    let lanhDaoPhongBanGiaoViec =
      this.props.requestComments &&
      this.props.requestComments.lanhDaoPhongBanGiaoViec &&
      this.props.requestComments.lanhDaoPhongBanGiaoViec.chucDanhId
    if (this.props.lanhDaoDonVi && this.props.lanhDaoDonVi.items.length) {
      let lanhDaoDonVi = this.props.lanhDaoDonVi.items.find(
        item => item.chucDanhId === lanhDaoDonViChiDao
      )
      if (lanhDaoDonVi) {
        state.form.lanh_dao_don_vi['chuc_danh_id'] = lanhDaoDonVi.chucDanhId
        state.form.lanh_dao_don_vi['nhan_vien_id'] = lanhDaoDonVi.nhanVienId
        state.form.lanh_dao_don_vi['phong_ban_id'] =
          lanhDaoDonVi.phongBan.phongBanId
        state.form.lanhDaoDonViId = lanhDaoDonVi.chucDanhId
      } else {
        let lanhDaoDonViDefault = this.props.lanhDaoDonVi.items[0]
        state.form.lanh_dao_don_vi['chuc_danh_id'] =
          lanhDaoDonViDefault.chucDanhId
        state.form.lanh_dao_don_vi['nhan_vien_id'] =
          lanhDaoDonViDefault.nhanVienId
        state.form.lanh_dao_don_vi['phong_ban_id'] =
          lanhDaoDonViDefault.phongBan.phongBanId
        state.form.lanhDaoDonViId = lanhDaoDonViDefault.chucDanhId
      }
    }
    if (this.props.lanhDaoPhongBan && this.props.lanhDaoPhongBan.items.length) {
      let lanhDaoPhongBanLap = this.props.lanhDaoPhongBan.items.find(
        item => item.chucDanhId === lanhDaoPhongBanGiaoViec
      )
      if (lanhDaoPhongBanLap) {
        state.form.lanh_dao_phong_ban['chuc_danh_id'] =
          lanhDaoPhongBanLap.chucDanhId
        state.form.lanh_dao_phong_ban['nhan_vien_id'] =
          lanhDaoPhongBanLap.nhanVienId
        state.form.lanh_dao_phong_ban['phong_ban_id'] =
          lanhDaoPhongBanLap.phongBan.phongBanId
        state.form.lanhDaoPhongBanId = lanhDaoPhongBanLap.chucDanhId
      } else {
        let lanhDaoPhongBanDefault = this.props.lanhDaoPhongBan.items[0]
        state.form.lanh_dao_phong_ban['chuc_danh_id'] =
          lanhDaoPhongBanDefault.chucDanhId
        state.form.lanh_dao_phong_ban['nhan_vien_id'] =
          lanhDaoPhongBanDefault.nhanVienId
        state.form.lanh_dao_phong_ban['phong_ban_id'] =
          lanhDaoPhongBanDefault.phongBan.phongBanId
        state.form.lanhDaoPhongBanId = lanhDaoPhongBanDefault.chucDanhId
      }
    }

    // mode chỉnh sửa góp ý
    if (
      this.props.requestComments &&
      this.props.requestComments.tinhTrang &&
      [
        TRANGTHAI.LDPB_CHUA_XAC_NHAN,
        TRANGTHAI.LDDV_CHUA_XAC_NHAN,
        TRANGTHAI.TU_CHOI_XAC_NHAN,
      ].includes(this.props.requestComments.tinhTrang)
    ) {
      state.form['noi_dung'] = this.props.requestComments.noiDungGopY
      state.form[
        'trang_thai_gop_y'
      ] = `${this.props.requestComments.trangThaiGopY}`
      if (this.props.requestComments.fileGopY) {
        state.form.file_gop_y['file_id'] =
          this.props.requestComments.fileGopY.fileId
        state.form.file_gop_y['ten_file'] =
          this.props.requestComments.fileGopY.tenFile
        state.form.file_gop_y['url'] = this.props.requestComments.fileGopY.url
        state.form.file_gop_y['kieu_file'] =
          this.props.requestComments.fileGopY.kieuFile
        state.form.file_gop_y['kich_thuoc'] =
          this.props.requestComments.fileGopY.kichThuoc

        state.sizeFile[0] = this.props.requestComments.fileGopY.kichThuoc
        state.attachFileName[0] = this.props.requestComments.fileGopY.tenFile

        state.isAdd[0] = state.fileEdit
      }
      let lanhDaoDonViXacNhan =
        this.props.requestComments &&
        this.props.requestComments.lanhDaoDonViXacNhan &&
        this.props.requestComments.lanhDaoDonViXacNhan.chucDanhId
      let lanhDaoPhongBanXacNhan =
        this.props.requestComments &&
        this.props.requestComments.lanhDaoPhongBanXacNhan &&
        this.props.requestComments.lanhDaoPhongBanXacNhan.chucDanhId
      if (
        this.props.lanhDaoPhongBan &&
        this.props.lanhDaoPhongBan.items.length
      ) {
        let lanhDaoPhongBanLap = this.props.lanhDaoPhongBan.items.find(
          item => item.chucDanhId === lanhDaoPhongBanXacNhan
        )
        if (lanhDaoPhongBanLap) {
          state.form.lanh_dao_phong_ban['chuc_danh_id'] =
            lanhDaoPhongBanLap.chucDanhId
          state.form.lanh_dao_phong_ban['nhan_vien_id'] =
            lanhDaoPhongBanLap.nhanVienId
          state.form.lanh_dao_phong_ban['phong_ban_id'] =
            lanhDaoPhongBanLap.phongBan.phongBanId
          state.form.lanhDaoPhongBanId = lanhDaoPhongBanLap.chucDanhId
        }
      }
      if (this.props.lanhDaoDonVi && this.props.lanhDaoDonVi.items.length) {
        let lanhDaoDonVi = this.props.lanhDaoDonVi.items.find(
          item => item.chucDanhId === lanhDaoDonViXacNhan
        )
        if (lanhDaoDonVi) {
          state.form.lanh_dao_don_vi['chuc_danh_id'] = lanhDaoDonVi.chucDanhId
          state.form.lanh_dao_don_vi['nhan_vien_id'] = lanhDaoDonVi.nhanVienId
          state.form.lanh_dao_don_vi['phong_ban_id'] =
            lanhDaoDonVi.phongBan.phongBanId
          state.form.lanhDaoDonViId = lanhDaoDonVi.chucDanhId
        }
      }
      state.isSuaGopY = true
    }
    this.setState(state)
  }

  render() {
    const lanhDaoChiDao =
      this.props.requestComments &&
      this.props.requestComments.lanhDaoDonViChiDao
        ? this.props.requestComments.lanhDaoDonViChiDao.yKien
        : ''
    const lanhDaoPhongBanGiaoViec =
      this.props.requestComments &&
      this.props.requestComments.lanhDaoPhongBanGiaoViec
        ? this.props.requestComments.lanhDaoPhongBanGiaoViec.yKien
        : ''
    const isGopYLai =
      this.props.requestComments &&
      this.props.requestComments.tinhTrang === TRANGTHAI.TU_CHOI_XAC_NHAN
    const isSuaGopY =
      this.props.requestComments &&
      [TRANGTHAI.LDPB_CHUA_XAC_NHAN, TRANGTHAI.LDDV_CHUA_XAC_NHAN].includes(
        this.props.requestComments.tinhTrang
      )
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="name-heading" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4 className="name-heading">
            {isSuaGopY
              ? 'Sửa góp ý'
              : isGopYLai
              ? 'Thực hiện góp ý lại'
              : 'Thực hiện góp ý'}
          </h4>
          {/*<h4 className="name-heading">Thực hiện góp ý</h4>*/}
        </div>
        <div className="form-container container-detail form-report-work">
          <div className="pt-form-group">
            {this.props.requestComments &&
              this.props.requestComments.lanhDaoDonViChiDao && (
                <div className="row full-row">
                  <label className="pt-label col-md-12 col-xs-12">
                    Lãnh đạo chỉ đạo:
                    <span className="text-label"> {lanhDaoChiDao}</span>
                  </label>
                </div>
              )}
            {this.props.requestComments &&
              this.props.requestComments.lanhDaoPhongBanGiaoViec && (
                <div className="row full-row">
                  <label className="pt-label col-md-12 col-xs-12">
                    Lãnh đạo phòng ban giao việc:
                    <span className="text-label">
                      {' '}
                      {lanhDaoPhongBanGiaoViec}
                    </span>
                  </label>
                </div>
              )}
            <div className="row full-row">
              <div className="col-md-12 col-xs-12">
                <label className="pt-label">Trạng thái góp ý</label>
              </div>
            </div>
            <div className="row full-row">
              <div className="col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <RadioGroup
                    selectedValue={this.state.form.trang_thai_gop_y}
                    onChange={this.handleInputChange}
                    name="trang_thai_gop_y"
                  >
                    <Radio
                      label="Thống nhất"
                      value="2"
                      className="pt-large pt-inline name-radio"
                    />
                    <Radio
                      label="Không thống nhất"
                      value="1"
                      className="pt-large pt-inline name-radio"
                    />
                    <Radio
                      label="Không ý kiến"
                      value="3"
                      className="pt-large pt-inline name-radio"
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <div className="row full-row">
              <label className="pt-label col-md-12 col-xs-12">
                Nội dung góp ý
                <span className="pt-text-muted required-input">*</span>
              </label>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <div className="row full-row">
              <div className="pt-form-content col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    name="noi_dung"
                    onChange={this.handleInputChange}
                    rows="15"
                    className="pt-input pt-fill"
                    placeholder="Nhập nội dung…"
                    dir="auto"
                    autoFocus={true}
                    value={this.state.form.noi_dung}
                  ></textarea>
                  {this.state.error.noi_dung !== undefined && (
                    <div className="pt-form-helper-text">
                      {this.state.error.noi_dung}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="pt-form-group pt-intent-danger">
            <div className="row full-row">
              <label className="pt-label col-md-12 col-xs-12">
                File đính kèm
              </label>
            </div>
          </div>
          {this.state.isAdd.map((item, i) => (
            <div className="pt-form-group pt-intent-danger" key={i}>
              <div className="row full-row">
                <div className="pt-form-content col-md-12 col-xs-12">
                  <div
                    className={classnames('pt-form-content', {
                      'upload-have-close':
                        i > 0 ||
                        (this.state.attachFiles && this.state.attachFiles[0]),
                    })}
                  >
                    <label className="pt-file-upload">
                      <input
                        type="file"
                        name={`attachFiles-${i}`}
                        onChange={this.handleFile.bind(this, i)}
                        disabled={
                          this.state.isLoading &&
                          Object.values(this.state.isLoading).find(
                            item => item === true
                          ) !== undefined
                        }
                        onClick={event => {
                          event.target.value = null
                        }}
                      />
                      <span className="pt-file-upload-input">
                        {this.state.attachFileName[i]
                          ? this.state.attachFileName[i]
                          : 'Chọn file đính kèm'}
                      </span>
                    </label>
                    {this.state.attachFileName &&
                      this.state.attachFileName[0] &&
                      this.state.attachFileName[0].length > 0 && (
                        <span
                          onClick={this.handleEventRemoveFile.bind(this, i)}
                          className="icon-bc icon-close icon-remove-file"
                        ></span>
                      )}
                    {this.state.sizeFile[i] !== undefined &&
                      this.state.errors[i] === undefined && (
                        <div className="pt-form-helper-text pt-form-helper-infomation">
                          Kích thước file đã upload:
                          <span className="size-file">
                            {' '}
                            {Tool.Utils.getFileSize(this.state.sizeFile[i])}
                          </span>
                        </div>
                      )}
                    {Object.values(this.state.isLoading).find(
                      item => item === true
                    ) !== undefined &&
                      this.state.attachFilesProgress &&
                      this.state.attachFilesProgress[i] > 0 && (
                        <div className="progress-upload-content">
                          <span className="percent-content">
                            {this.state.attachFilesProgress[i]}%
                          </span>
                          <div className="pt-progress-bar pt-intent-primary progress-bar-upload">
                            <div
                              className="pt-progress-meter"
                              style={{
                                width: this.state.attachFilesProgress[i] + '%',
                              }}
                            ></div>
                          </div>
                        </div>
                      )}
                    <div className="pt-form-helper-text">
                      {this.state.errors[i] !== undefined && (
                        <div className="pt-form-helper-text">
                          {this.state.errors[i]}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="pt-form-group pt-intent-danger">
            <div className="row full-row">
              <label className="pt-label col-md-12 col-xs-12">
                Trình lãnh đạo phòng ban
              </label>
            </div>
          </div>
          <div className="row pt-form-group pt-intent-danger">
            <div className="pt-form-content col-md-6 col-xs-12 pt-intent-danger">
              <div className="pt-select">
                <select
                  name="lanhDaoPhongBanChuTriId"
                  onChange={this.handleInputChange}
                  value={this.state.form.lanh_dao_phong_ban.chuc_danh_id}
                  className={classnames({
                    'loading-input':
                      this.props.isLoadingData &&
                      this.props.isLoadingData.phongBanId === true,
                  })}
                >
                  {this.props.lanhDaoPhongBan.items.map((item, i) => (
                    <option key={i} value={item.chucDanhId}>
                      {item.tenNhanVien}
                    </option>
                  ))}
                </select>
              </div>
              {this.state.error.lanhDaoPhongBanId !== undefined && (
                <div className="pt-form-helper-text">
                  {this.state.error.lanhDaoPhongBanId}
                </div>
              )}
            </div>
          </div>
          {this.props.requestComments &&
            this.props.requestComments.loai === 2 && (
              <div>
                <div className="pt-form-group pt-intent-danger">
                  <div className="row full-row">
                    <label className="pt-label col-md-12 col-xs-12">
                      Trình lãnh đạo đơn vị
                    </label>
                  </div>
                </div>
                <div className="row pt-form-group pt-intent-danger">
                  <div className="pt-form-content col-md-6 col-xs-12 pt-intent-danger">
                    <div className="pt-select">
                      <select
                        name="lanhDaoDonViId"
                        onChange={this.handleInputChange}
                        value={this.state.form.lanh_dao_don_vi.chuc_danh_id}
                        className={classnames({
                          'loading-input':
                            this.props.isLoadingData &&
                            this.props.isLoadingData.phongBanId === true,
                        })}
                      >
                        {this.props.lanhDaoDonVi.items.map((item, i) => (
                          <option key={i} value={item.chucDanhId}>
                            {item.tenNhanVien}
                          </option>
                        ))}
                      </select>
                    </div>
                    {this.state.error.lanhDaoDonViId !== undefined && (
                      <div className="pt-form-helper-text">
                        {this.state.error.lanhDaoDonViId}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            onClick={this.handleSubmit}
            className="pt-button btn-blue-color"
            loading={
              this.state.isLoading &&
              this.state.isLoading.isLoadingSubmit === true
            }
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  lanhDaoPhongBan: {
    ...state.personalLeaderSignNumber,
    items: state.common.personalLeaderSignNumber,
  },
  lanhDaoDonVi: {
    items: state.common.signNumberDepartmentLeaders,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThucHienGopY)
