import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { MasterLayout } from '../../components/layout'
import { ListFilter, ListItem } from '../../components/Revoke'
import { GridViewFilter, HeadingPage } from '../../components/common'
import { connect } from 'react-redux'
import { headers } from '../../constants/Table'
import {
  commonFetchDepartments,
  commonFetchSignUserList,
  commonAddToasterMessage,
  revokeDocumentGet,
  refreshRevokeDocument,
  putCapNhatDeXuatThuHoi,
  putDongYThuHoiCongVanDi,
  getCongVanDiThuHoiXuatExcel,
  getTongSoCongVanThuHoi,
  sendDocumentGet,
} from '../../actions'
import * as Types from '../../constants/Api'
import * as Tool from '../../helpers'
import { Button, Intent } from '@blueprintjs/core'
import FileDownload from 'file-saver'
import moment from 'moment'
import { isBoolean } from 'lodash'

import Pagination from 'components/Pagination'

const REFRESH_FALSE = false
class ListRevokePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {},
      filterColumn: {},
      sort: undefined,
      isLoading: false,
      isLoadingButton: false,
      isEmpty: false,
      page: 1,
      isError: false,
      isShow: false,
      choXuLy: true,
      revokeDocuments: {
        list: {},
      },
    }
    this.clickPagination = this.clickPagination.bind(this)
    this.submitFilter = this.submitFilter.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.onChangeGridView = this.onChangeGridView.bind(this)
    this.onChangeDateGridViewFilter = this.onChangeDateGridViewFilter.bind(this)
    this.clearFilterColumn = this.clearFilterColumn.bind(this)
    this.onRemoveText = this.onRemoveText.bind(this)
    this.removeTextSearch = this.removeTextSearch.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidMount() {
    this.props.actions.commonFetchDepartments()
    this.props.actions.commonFetchSignUserList()
    let state = this.state
    let preData = Tool.Utils.preData('ListRevokePage')
    if (preData) {
      state.choXuLy = true
      if (preData.filter.trangThai === null) {
        state.choXuLy = false
      }

      state.filter = preData.filter
      state.page =
        (preData?.page?.selected || preData?.page) > 0
          ? preData?.page?.selected || preData?.page
          : 1
      state.sort = preData.sort
      state.filterColumn = {
        ...preData.filterColumn,
        ngayCongVan:
          preData.filterColumn && preData.filterColumn.ngayCongVan
            ? moment(preData.filterColumn.ngayCongVan)
            : null,
        soCongVan:
          preData.filterColumn && preData.filterColumn.soCongVan
            ? preData.filterColumn.soCongVan
            : '',
        trichYeu:
          preData.filterColumn && preData.filterColumn.trichYeu
            ? preData.filterColumn.trichYeu
            : '',
        phongBanLapId:
          preData.filterColumn && preData.filterColumn.phongBanLapId
            ? preData.filterColumn.phongBanLapId
            : '',
        nguoiKyId:
          preData.filterColumn && preData.filterColumn.nguoiKyId
            ? preData.filterColumn.nguoiKyId
            : '',
      }
    } else {
      state.filter = {
        ngayCongVan: {
          from: undefined,
          to: undefined,
        },
      }
    }

    this.setState(state, () => {
      this.clickPagination(this.state.page)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props &&
      this.props.list &&
      this.props.list.total &&
      this.state.page >
        Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT)
    ) {
      this.setState(
        { page: Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT) },
        () => {
          this.clickPagination(this.state.page)
        }
      )
    }
  }

  clickPagination(page = 1) {
    this.setState({ page }, () => {
      this.submitFilter(this.state.filter, this.state.page, this.state.sort)
    })
  }

  submitFilter(e = {}, page = 1, sort) {
    const filter = Object.assign({}, this.state.filter, e)
    if (isBoolean(this.state.choXuLy)) {
      filter.trangThai = this.state.choXuLy ? 0 : null
    }

    const filterColumn = {
      ...this.state.filterColumn,
      ngayCongVan: this.state.filterColumn.ngayCongVan
        ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
        : undefined,
      soCongVan:
        this.state.filterColumn.soCongVan &&
        this.state.filterColumn.soCongVan !== ''
          ? this.state.filterColumn.soCongVan
          : null,
      trichYeu:
        this.state.filterColumn.trichYeu &&
        this.state.filterColumn.trichYeu !== ''
          ? this.state.filterColumn.trichYeu
          : null,
      phongBanLapId:
        this.state.filterColumn.phongBanLapId &&
        this.state.filterColumn.phongBanLapId !== '#'
          ? this.state.filterColumn.phongBanLapId
          : null,
      nguoiKyId:
        this.state.filterColumn.nguoiKyId &&
        this.state.filterColumn.nguoiKyId !== '#'
          ? this.state.filterColumn.nguoiKyId
          : null,
    }
    this.setState({ filter, page, isLoading: true, sort }, () => {
      Tool.Utils.preData('ListRevokePage', { filter, page, sort, filterColumn })
      // tạm thời để refresh = false
      this.props.actions
        .revokeDocumentGet({ filter }, page, sort, REFRESH_FALSE, filterColumn)
        .then(res => {
          let isEmpty = false
          let isError = false
          if (
            !res.error &&
            res.payload &&
            res.payload.data &&
            res.payload.data.result &&
            res.payload.data.result.result &&
            res.payload.data.result.result.total === 0
          ) {
            isEmpty = true
          }
          if (res.error || (res.payload && res.payload.status !== 200)) {
            isError = true
          }
          this.setState({
            revokeDocuments: {
              list: {
                page: res.payload.data.result.page,
                total: res.payload.data.result.total,
                items: res.payload.data.result && res.payload.data.result.items,
              },
            },
            isEmpty,
            isError,
          })
        })
        .then(() => {
          let object = false
          if (
            Object.values(filterColumn).find(item => item !== null) !==
            undefined
          ) {
            object = true
          }
          this.setState({ isLoading: false, isShow: object })
          this.props.actions.refreshRevokeDocument(false)
        })
    })
  }

  handleExport = () => {
    const { filter, page, sort } = this.state
    try {
      this.setState({ exporting: true })
      const filterColumn = {
        ...this.state.filterColumn,
        ngayCongVan: this.state.filterColumn.ngayCongVan
          ? moment(this.state.filterColumn.ngayCongVan).format('YYYY-MM-DD')
          : undefined,
        soCongVan:
          this.state.filterColumn.soCongVan &&
          this.state.filterColumn.soCongVan !== ''
            ? this.state.filterColumn.soCongVan
            : null,
        trichYeu:
          this.state.filterColumn.trichYeu &&
          this.state.filterColumn.trichYeu !== ''
            ? this.state.filterColumn.trichYeu
            : null,
        phongBanLapId:
          this.state.filterColumn.phongBanLapId &&
          this.state.filterColumn.phongBanLapId !== '#'
            ? this.state.filterColumn.phongBanLapId
            : null,
        nguoiKyId:
          this.state.filterColumn.nguoiKyId &&
          this.state.filterColumn.nguoiKyId !== '#'
            ? this.state.filterColumn.nguoiKyId
            : null,
      }
      this.props.actions
        .getCongVanDiThuHoiXuatExcel({ filter }, page, sort, filterColumn)
        .then(res => {
          if (res.error) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }

          FileDownload.saveAs(res.payload.data, 'cong-van-di-thu-hoi.xls')
        })
        .then(() => {
          this.setState({ exporting: false })
        })
    } catch (error) {
      return this.props.actions.commonAddToasterMessage({
        message: 'Không xuất được file.',
        intent: Intent.DANGER,
      })
    }
  }

  handleSort(sort) {
    this.submitFilter(this.state.filter, this.state.page, sort)
  }

  onChangeGridView(e) {
    const value = e.target.value
    const name = e.target.name
    if (name === 'soCongVan' || name === 'trichYeu') {
      return this.setState({
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: value,
        },
      })
    }
    if (name !== 'soCongVan' || name !== 'trichYeu') {
      this.setState(
        {
          isShow: true,
          filterColumn: {
            ...this.state.filterColumn,
            [name]: value,
          },
        },
        () => {
          if (name === 'phongBanLapId' || name === 'nguoiKyId') {
            this.submitFilter()
          }
        }
      )
    }
  }

  onChangeDateGridViewFilter(name, e) {
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: e,
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  async clearFilterColumn(filter, check) {
    if (check) {
      await this.setState({
        filterColumn: {},
        filter: {},
        choXuLy: true,
      })
    }
    await this.submitFilter(filter)
  }

  onRemoveText(name) {
    this.setState(
      {
        isShow: true,
        filterColumn: {
          ...this.state.filterColumn,
          [name]: '',
        },
      },
      () => {
        this.submitFilter()
      }
    )
  }

  removeTextSearch(name) {
    this.setState({
      filter: {
        ...this.state.filter,
        [name]: '',
      },
    })
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? moment(e).format('YYYY-MM-DD') : undefined,
          },
        },
      },
      () => {
        this.submitFilter(this.state.filter, this.state.page, this.state.sort)
      }
    )
  }

  handleCheckBoxStatus = e => {
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    if (value === false) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            ngayCongVan: {
              from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
              to: moment().format('YYYY-MM-DD'),
            },
          },
          [name]: value,
        },
        () => {
          this.submitFilter(this.state.filter, 1, this.state.sort)
        }
      )
    } else {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            ngayCongVan: {
              from: undefined,
              to: undefined,
            },
          },
          [name]: value,
        },
        () => {
          this.submitFilter(this.state.filter, 1, this.state.sort)
        }
      )
    }
  }

  reloadData = () => {
    this.props.actions.sendDocumentGet()
    this.submitFilter()
  }

  render() {
    const { isLoadingButton } = this.state
    return (
      <MasterLayout typeSidebar="documentary">
        <HeadingPage namePage="Công văn đi thu hồi" iconPage="icon-revoke">
          <div className="button-heading-right pull-right">
            <Button
              rightIconName="pt-icon icon-In_So_Cong_Van"
              className="btn-action btn-top-heading color-white btn-min-width-130px"
              text="Xuất excel"
              onClick={this.handleExport}
              loading={isLoadingButton}
              disabled={isLoadingButton}
            />
          </div>
        </HeadingPage>
        <div className="page-list-container list-sign-number">
          <ListFilter
            filter={this.state.filter}
            onSubmit={this.submitFilter}
            readOnly={this.props.readOnly}
            actions={this.props.actions}
            clearFilterColumn={this.clearFilterColumn}
            removeTextSearch={this.removeTextSearch}
            handleDateChange={this.handleDateChange}
            handleCheckBoxStatus={this.handleCheckBoxStatus}
            choXuLy={this.state.choXuLy}
          />
          <div
            className="table-container table-container-full"
            style={{
              paddingBottom: 0,
              border: 0,
              background:
                Math.ceil(this.props.list.total / Types.PAGINATION_LIMIT) > 1
                  ? '#fff'
                  : '#eff3f5',
            }}
          >
            <GridViewFilter
              actions={this.props.actions}
              isLoading={this.state.isLoading}
              classNames={'pt-table fix-table-before'}
              page={this.state.page}
              headers={headers.ListRevokePage}
              itemShape={ListItem}
              data={this.props.list.items || []}
              onFilter={this.handleSort}
              isEmpty={this.state.isEmpty}
              isError={this.state.isError}
              readOnly={this.props.readOnly}
              onChange={this.onChangeGridView}
              onChangeSelect={this.onChangeSelectGridViewFilter}
              onChangeDate={this.onChangeDateGridViewFilter}
              filterColumn={this.state.filterColumn}
              onRemoveText={this.onRemoveText}
              phongBanLapId={this.props.departments}
              nguoiKyId={this.props.signUserList}
              auth={this.props.auth}
              onSubmit={this.submitFilter}
              pageCount={Math.ceil(
                this.props.list.total / Types.PAGINATION_LIMIT
              )}
              isType={false}
              reloadData={this.reloadData}
            />

            {!this.state.isEmpty &&
              !this.state.isError &&
              !this.state.isLoading && (
                <div className="pt10 pb10">
                  <Pagination
                    current={this.state.page}
                    total={Math.ceil(
                      this.props?.list?.total / Types.PAGINATION_LIMIT
                    )}
                    onChange={this.clickPagination}
                  />
                </div>
              )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

ListRevokePage.propTypes = {
  auth: PropTypes.object.isRequired,
  list: PropTypes.object,
  departments: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
  config: state.common.config,
  list: state.revokeDocuments.list,
  readOnly: state.common.enableReadOnly,
  departments: state.common.departments,
  signUserList: state.common.signUserList,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonFetchDepartments,
      commonFetchSignUserList,
      commonAddToasterMessage,
      revokeDocumentGet,
      refreshRevokeDocument,
      putCapNhatDeXuatThuHoi,
      putDongYThuHoiCongVanDi,
      getCongVanDiThuHoiXuatExcel,
      getTongSoCongVanThuHoi,
      sendDocumentGet,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListRevokePage)
