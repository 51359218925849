import React, { memo, useState, useRef, useEffect, useCallback } from 'react'
import { Checkbox } from '@blueprintjs/core'
import { size } from 'lodash'

import { MasterLayout } from 'components/layout'
import { HeadingPage, GridView } from 'components/common'

import { headers } from 'constants/table/QuanLyCongViec'
import requestAction from 'helpers/request'

const prioritizes = [
  { name: 'Cao', id: 1, used: true },
  { name: 'Bình thường', id: 2, used: true },
  { name: 'Thấp', id: 3, used: true },
]

const index = memo(() => {
  const mounted = useRef(false)
  const _mountedSet = (setState, state) => mounted.current && setState(state)

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  // const [error, setError] = useState(false);

  const Row = ({ number, item: data }) =>
    !(number && data) ? null : (
      <tr>
        <td>{`0${number}`}</td>
        <td>{data.name}</td>
        <td className="center">
          <Checkbox className="checkbox-inline" checked={data.used} disabled />
        </td>
      </tr>
    )

  const _fetchPriority = useCallback(() => {
    requestAction({
      showToast: false,
      beforeAction: () => _mountedSet(setLoading, true),
      action: () => _mountedSet(setList, prioritizes),
      afterAction: () => _mountedSet(setLoading, false),
    })
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fetchPriority()
  }, [_fetchPriority])

  return (
    <MasterLayout typeSidebar="sidebarSystem">
      <HeadingPage namePage="Độ ưu tiên" iconPage="icon-flag" />
      <div className="table-container table-container-full pb0">
        <GridView
          classNames="pt-table table-middle"
          isLoading={loading}
          isEmpty={!size(list)}
          headers={headers.priority}
          itemShape={Row}
          data={list}
          page={1}
        />
      </div>
    </MasterLayout>
  )
})

export default index
