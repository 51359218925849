import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@blueprintjs/core'
import * as POPUP from '../../constants/Popup'
import { PopupConfirm } from '../common'
import * as TRANGTHAI from '../../constants/RequestCommentStatus'
import * as MESSAGE from '../../constants/MessageForm'
import { Intent } from '@blueprintjs/core'

class DuyetPhieuYeuCauGopY extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleAgree = this.handleAgree.bind(this)
    this.handleNotAgree = this.handleNotAgree.bind(this)
    this.handleSubmitXacNhan = this.handleSubmitXacNhan.bind(this)
  }

  state = {
    error: {},
    typeSubmit: 'DuyetPhieuYeuCauGopY',
    yKien: '',
    trangThai: '',
    isOpenPopup: false,
    popup_title: '',
    popup_content: '',
  }

  handleSubmitXacNhan() {
    let content = {
      y_kien: this.state.yKien,
      trang_thai: this.state.trangThai,
    }
    this.handleSubmit(content)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAgree = e => {
    e.preventDefault()
    this.setState({
      popup_content: POPUP.COMFIRM_DONG_Y_XAC_NHAN,
      trangThai: TRANGTHAI.LAYYKIEN_DONGY,
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleNotAgree = e => {
    e.preventDefault()
    if (this.state.yKien.trim().length === 0) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_ALERT_DISSAGREE_REQUIRE_WARNING,
        intent: Intent.WARNING,
      })
    }
    this.setState({
      popup_content: POPUP.COMFIRM_DONG_KHONG_DONG_Y_XAC_NHAN,
      trangThai: TRANGTHAI.LAYYKIEN_KHONG_DONGY,
      isOpenPopup: !this.state.isOpenPopup,
      error: '',
    })
  }

  handleSubmit = content => {
    this.props.onSubmit && this.props.onSubmit(this.state.typeSubmit, content)
  }

  render() {
    const isXacnhanLai =
      this.props.requestComments && this.props.requestComments.tinhTrang === 1
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>
            {isXacnhanLai ? 'Xác nhận lại' : 'Xác nhận phiếu gửi yêu cầu góp ý'}
          </h4>
        </div>
        <div className="form-container form-send-mail">
          <div className="pt-form-group pt-intent-danger">
            <div className="row">
              <label className="pt-label col-md-12 col-xs-12">Góp ý</label>
            </div>
            <div className="row">
              <div className="pt-form-content col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="4"
                    className="pt-input pt-fill"
                    placeholder="Nhập nội dung…"
                    dir="auto"
                    name="yKien"
                    onChange={this.handleChange}
                    value={this.state.yKien}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttton-action-footer button-content">
          <Button
            onClick={this.handleAgree}
            name="btn-dongY"
            className="pt-button btn-action-blue btn-confirm-comment"
            loading={this.props.isLoading.isLoadingDuyetGopYDongY}
          >
            Đồng ý<span className="pt-icon pt-icon-large icon-Bao_Cao"></span>
          </Button>
          <Button
            onClick={this.handleNotAgree}
            name="btn-khongDongY"
            className="pt-button  btn-action-red btn-cancel-steering"
            loading={this.props.isLoading.isLoadingDuyetGopYKhongDongY}
            disabled={isXacnhanLai}
          >
            Không đồng ý
            <span className="pt-icon pt-icon-large pt-icon-cross"></span>
          </Button>
        </div>
        <PopupConfirm
          isOpen={this.state.isOpenPopup}
          onClose={this.handleAgree}
          title={POPUP.XAC_NHAN_PHIEU_GOI_YEU_CAU_GOP_Y}
          text={POPUP.TEXT_BUTTON_SUBMIT}
          onSubmit={this.handleSubmitXacNhan}
          isLoading={
            this.state.isLoadingDuyetGopYKhongDongY ||
            this.props.isLoading.isLoadingDuyetGopYDongY
          }
        >
          {this.state.popup_content}
        </PopupConfirm>
      </div>
    )
  }
}

export default DuyetPhieuYeuCauGopY
