import {
  COMMON_FETCH_CABINET_OF_DOCUMENT_CLEAR,
  COMMON_FETCH_CABINET_OF_DOCUMENT,
  COMMON_FETCH_OBJECTS,
  COMMON_FETCH_SECURITIES,
  COMMON_FETCH_PRIORITIES,
  COMMON_FETCH_SAVECODES,
  COMMON_FETCH_RELATIVE_UNITS,
  COMMON_FETCH_LEADER_DERPATMENTS,
  COMMON_FETCH_DERPATMENTS,
  CONTACT_FETCH_UNITS_EQUAL,
  COMMON_FETCH_ATTACHED_UNITS,
  COMMON_FETCH_ELECTRIC_UNITS,
  COMMON_ADD_TOASTER_MESSAGE,
  COMMON_REMOVE_TOASTER_MESSAGE,
  COMMON_FETCH_SIGN_NUMBER_DEPARTMENT_LEADER,
  COMMON_FETCH_RECEIVEDOCUMENT_TOTAL,
  COMMON_FETCH_STAFFS_BY_DEPARTMENT_ID,
  COMMON_FETCH_DERPATMENT_WORK_TOTAL,
  COMMON_FETCH_PERSONAL_WORK_TOTAL,
  COMMON_FETCH_PERSONAL_LEADER_SIGN_NUMBER,
  COMMON_FETCH_CHILD_DERPATMENTS,
  COMMON_FETCH_STATISTICS,
  COMMON_FETCH_STAFFS_BY_UNIT_ID,
  COMMON_FETCH_SAVECODES_ITEM,
  COMMON_FETCH_GET_SECURITIES_ITEM,
  COMMON_FETCH_GET_OBEJCT_ITEM,
  COMMON_FETCH_GET_PRIORITIES_ITEM,
  COMMON_FETCH_GET_POSITION_ITEM,
  COMMON_FETCH_POSITION,
  COMMON_FETCH_GET_UNIT_ITEM,
  COMMON_FETCH_UNIT,
  COMMON_FETCH_GET_DEPARTMENTS_ITEM,
  COMMON_FETCH_GET_ROLE,
  COMMON_FETCH_GET_ROLE_ITEM,
  COMMON_FETCH_CATEGORY_ROLE,
  COMMON_FETCH_GET_CATEGORY_PERMISSION,
  GET_STAFF_ITEM,
  GET_STATISTIC_CABINET_DOCUMENT,
  GET_GENERAL_WORK,
  GET_HANDLE_DOCUMENT,
  GET_GENERAL_DOCUMENT,
  PRINT_DOCUMENT_RECEIVE,
  PRINT_DOCUMENT_SEND,
  GET_ROLE,
  PRINT_SEARCH_DOCUMENT_RECEIVE,
  COMMON_SAVE_TYPE_SIDEBAR,
  COMMON_FETCH_ALERT_MISTAKE_TOTAL,
  READONLY_ENABLE,
  SET_DEFAULT_NUMBER_DEPARTMENT,
  SET_DEFAULT_NUMBER_WORK,
  COMMON_FETCH_SIGN_USER_LIST,
  COMMON_FETCH_INTERNAL_UNITS,
  CONFIG_PAGE_REFRESH,
  COMMON_FETCH_RECEIVE_UNITS,
  //Get number sidebar
  GET_NUMBER_HOME_PAGE,
  GET_NUMBER_DOCUMENT_PAGE,
  GET_NUMBER_SIGNNUMBER_PAGE,
  GET_NUMBER_WORK_PAGE,
  SCROLL_FIXTOP_MENU,
  CONFIG_REFRESH_SEND_SIGN_NUMBER,
  CONFIG_REFRESH_INTERNAL_SIGN_NUMBER,
  CONFIG_REFRESH_SEND_DOCUMENT,
  CONFIG_REFRESH_DOCUMENT_CABINET,
  GET_GENERAL_WORK_UNIT,
  GET_DOCUMENT_DIGITIZE,
  GET_DOCUMENT_RECOVERY,
  CONFIG_STATISTIC_REFRESH,
  FETCH_INTERNAL_DERPATMENTS,
  GET_NUMBER_EVN_DOCUMENT,
  GET_NUMBER_PROJECT_SIGNNUMBER_PAGE,
  CONFIG_REFRESH_SERVICE_DOCUMENT,
  GET_NUMBER_KSTT_PAGE,
  CONFIG_REFRESH_OPINION,
  GET_NUMBER_PHIEU_Y_KIEN,
  CONFIG_REFRESH_REVOKE_DOCUMENT,
  GET_NUMBER_CONGVANTHUHOI,
  COMMON_FETCH_LEADERCOMBINATION,
  GET_PHUONGPHAPLUU_CHITIET,
  GET_THOIGIANLUU_DANHSACH,
  API_REQUEST_NHANVIEN_PHONGBAN_QUYEN,
  GET_NUMBER_HSCV,
  API_REQUEST_PERMISSIONS_MENU,
  COMMON_ADD_TREE_PHONGBAN_DONVI_NHANVIEN,
  COMMON_ADD_TREE_DEPARTMENT_ORGANIZATION,
  GET_LANHDAO_DONVI,
  GET_THONGTIN_DONVI,
} from '../constants/ActionTypes'
import * as Types from '../constants/Api'
import { get } from 'lodash'

const initialState = {
  objects: [],
  securities: [],
  priorities: [],
  saveCodes: [],
  relativeUnits: [],
  departments: [],
  leaderCombinations: [],
  differentDepartments: [],
  parentDepartments: [],
  unitsEqual: [],
  leaderDepartments: [],
  phongBanLapCVNoiBo: [],
  phongBanNhanCVNoiBo: [],
  attachedUnits: [],
  electricUnits: [],
  toaster: null,
  signNumberDepartmentLeaders: [],
  differentSignNumberDepartmentLeaders: [],
  receiveDocumentTotals: {},
  staffs: [],
  departmentWorkTotals: {},
  personalWorkTotals: {},
  personalLeaderSignNumber: [],
  childDepartments: [],
  statistics: null,
  saveCodeItem: null,
  securityItem: null,
  objectItem: null,
  priorityItem: null,
  position: [],
  positionItem: null,
  units: [],
  unitItem: null,
  departmentItem: null,
  role: [],
  roleItem: null,
  categoryRole: [],
  categoryPermission: [],
  totalCategoryPermission: null,
  staffItem: null,
  statisticsCabinetDocument: [],
  totalStatisticCabinetDocument: null,
  generalWork: [],
  totalGerenalWork: null,
  generalDocument: [],
  handleDocument: [],
  totalHandleDocument: null,
  printReceiveDocument: [],
  printSendDocument: [],
  getRole: [],
  listCabinetOfDocument: [],
  printSearchReceiveDocument: [],
  typeSidebar: null,
  enableReadOnly: false,
  enableScrollFixedMenu: false,
  alertMistakeTotals: {},
  signUserList: [],
  internalUnits: [],
  receiveUnits: [],
  config: {
    refresh: false,
    refreshSendSignNumber: false, // refresh ky so di
    refreshInternalSignNumber: false, // refresh ky so noi bo
    refreshSendDocument: false, // refresh cong van di
    refreshDocumentCabinet: false, // refresh danh sach tai lieu trong TTL
    refreshStatistic: true, // refresh danh sach thong ke
    refreshServiceDocument: false, // refresh service
  },
  numberSideBar: {
    congVanChuaXuLy: 0,
    congVanDangXuLy: 0,
    congVanEvn: 0,
    congVanBaoNham: 0,
    congVanDiCanKySo: 0,
    congVanNoiBoCanKySo: 0,
    congViecChuaGiao: 0,
    congViecBaoNham: 0,
    congViecChuaThucHien: 0,
    congViecDangThucHien: 0,
    xemDeBietChuaDoc: 0,
    kySoMoRong: 0,
    tongSoThuHoi: 0,
    phieuGuiYeuCauGopYCanXyLy: 0,
    phieuNhanYeuCauGopYCanXyLy: 0,
    kySoTapTrung: 0,
    countYKien: 0,
    hoSoCongViec: {},
  },
  documentDigitize: [],
  documentRecovery: [],
  phuongPhapLuuChiTiet: {},
  thoiHanBaoQuanDanhSach: [],
  nhanVienPhongBanPhanTheoQuyen: [],
  quyensMenu: [],
  dataTreeOrganizationDepartmentStore: [],
  dataTree: [],
  dataLanhDaoDonVi: [],
  dataThongTinDonVi: {},
}

export default function common(state = initialState, action) {
  switch (action.type) {
    case GET_NUMBER_HOME_PAGE:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          congVanChuaXuLy: action.payload.data.result.congVanChuaXuLy,
          congVanDiCanKySo: action.payload.data.result.congVanDiCanKySo,
          congVanNoiBoCanKySo: action.payload.data.result.congVanNoiBoCanKySo,
          congViecChuaGiao: action.payload.data.result.congViecChuaGiao,
          congViecChuaThucHien: action.payload.data.result.congViecChuaThucHien,
          congViecDangThucHien: action.payload.data.result.congViecDangThucHien,
          phieuGuiYeuCauGopYCanXyLy:
            action.payload.data.result.phieuGuiYeuCauGopYCanXyLy,
          phieuNhanYeuCauGopYCanXyLy:
            action.payload.data.result.phieuNhanYeuCauGopYCanXyLy,
        },
      }

    case GET_NUMBER_DOCUMENT_PAGE:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          congVanChuaXuLy: action.payload.data.result.congVanChuaXuLy,
          congVanDangXuLy: action.payload.data.result.congVanDangXuLy,
          congVanBaoNham: action.payload.data.result.congVanBaoNham,
        },
      }

    case GET_NUMBER_EVN_DOCUMENT:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          congVanEvn: action.payload.data.result.congVanEvn,
        },
      }

    case GET_NUMBER_SIGNNUMBER_PAGE:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          congVanDiCanKySo: action.payload.data.result.congVanDiCanKySo,
          congVanNoiBoCanKySo: action.payload.data.result.congVanNoiBoCanKySo,
        },
      }

    case GET_NUMBER_PROJECT_SIGNNUMBER_PAGE:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          kySoMoRong: action.payload.data.result,
        },
      }

    case GET_NUMBER_KSTT_PAGE:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          kySoTapTrung: action.payload.data.result,
        },
      }

    case GET_NUMBER_PHIEU_Y_KIEN:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          countYKien: action.payload.data.result,
        },
      }

    case GET_NUMBER_CONGVANTHUHOI:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          tongSoThuHoi: action.payload.data.result,
        },
      }

    case GET_NUMBER_WORK_PAGE:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          congViecChuaGiao: action.payload.data.result.congViecChuaGiao,
          congViecBaoNham: action.payload.data.result.congViecBaoNham,
          congViecChuaThucHien: action.payload.data.result.congViecChuaThucHien,
          congViecDangThucHien: action.payload.data.result.congViecDangThucHien,
          xemDeBietChuaDoc: action.payload.data.result.xemDeBietChuaDoc,
        },
      }

    case GET_NUMBER_HSCV:
      return {
        ...state,
        numberSideBar: {
          ...state.numberSideBar,
          hoSoCongViec: {
            donVi: get(action, 'payload.data.result.items', [])
              .filter(i => i.tenTab === 'DONVI')
              .reduce(
                (result, cur) => ({
                  ...result,
                  [cur.trangThai]: cur.soHoSoCongViec,
                }),
                {}
              ),
            phongBan: get(action, 'payload.data.result.items', [])
              .filter(i => i.tenTab === 'PHONGBAN')
              .reduce(
                (result, cur) => ({
                  ...result,
                  [cur.trangThai]: cur.soHoSoCongViec,
                }),
                {}
              ),
          },
        },
      }

    case CONFIG_PAGE_REFRESH:
      return {
        ...state,
        config: {
          ...state.config,
          refresh: action.status,
        },
      }

    case CONFIG_REFRESH_SEND_SIGN_NUMBER:
      return {
        ...state,
        config: {
          ...state.config,
          refreshSendSignNumber: action.status,
        },
      }

    case CONFIG_REFRESH_INTERNAL_SIGN_NUMBER:
      return {
        ...state,
        config: {
          ...state.config,
          refreshInternalSignNumber: action.status,
        },
      }

    case CONFIG_REFRESH_SEND_DOCUMENT:
      return {
        ...state,
        config: {
          ...state.config,
          refreshSendDocument: action.status,
        },
      }

    case CONFIG_REFRESH_DOCUMENT_CABINET:
      return {
        ...state,
        config: {
          ...state.config,
          refreshDocumentCabinet: action.status,
        },
      }

    case CONFIG_STATISTIC_REFRESH:
      return {
        ...state,
        config: {
          ...state.config,
          refreshStatistic: action.status,
        },
      }

    case SET_DEFAULT_NUMBER_WORK:
      return {
        ...state,
        personalWorkTotals: {},
      }
    case SET_DEFAULT_NUMBER_DEPARTMENT:
      return {
        ...state,
        departmentWorkTotals: {},
      }

    case PRINT_SEARCH_DOCUMENT_RECEIVE:
      return {
        ...state,
        printSearchReceiveDocument: action.payload.data.result.items,
      }

    case GET_ROLE:
      return {
        ...state,
        getRole: action.payload.data.result.items,
      }

    case PRINT_DOCUMENT_SEND:
      return {
        ...state,
        printSendDocument: action.payload.data.result.items,
      }

    case PRINT_DOCUMENT_RECEIVE:
      return {
        ...state,
        printReceiveDocument: action.payload.data.result.items,
      }

    case GET_GENERAL_DOCUMENT:
      return {
        ...state,
        generalDocument: action.payload.data.result,
      }

    case GET_HANDLE_DOCUMENT:
      return {
        ...state,
        handleDocument: action.payload.data.result.items,
        totalHandleDocument: action.payload.data.result.total,
      }

    case GET_GENERAL_WORK:
      return {
        ...state,
        generalWork: action.payload.data.result.items,
        totalGerenalWork: action.payload.data.result.total,
      }

    case GET_GENERAL_WORK_UNIT:
      return {
        ...state,
        generalWork: action.payload.data.result,
      }

    case GET_STATISTIC_CABINET_DOCUMENT:
      return {
        ...state,
        statisticsCabinetDocument: action.payload.data.result,
        totalStatisticCabinetDocument: action.payload.data.result.total,
      }

    case GET_STAFF_ITEM:
      return {
        ...state,
        staffItem: action.payload.data.result,
      }

    case COMMON_FETCH_CATEGORY_ROLE:
      return {
        ...state,
        categoryRole: action.payload.data.result.items,
      }

    case COMMON_FETCH_GET_CATEGORY_PERMISSION:
      return {
        ...state,
        categoryPermission: action.payload.data.result.items,
        totalCategoryPermission: action.payload.data.result.total,
      }

    case COMMON_FETCH_GET_ROLE:
      return {
        ...state,
        role: action.payload.data.result.items,
      }

    case COMMON_FETCH_GET_ROLE_ITEM:
      return {
        ...state,
        roleItem: action.payload.data.result,
      }

    case COMMON_FETCH_UNIT:
      return {
        ...state,
        units: action.payload.data.result.items,
      }

    case COMMON_FETCH_GET_UNIT_ITEM:
      return {
        ...state,
        unitItem: action.payload.data.result,
      }

    case COMMON_FETCH_GET_POSITION_ITEM:
      return {
        ...state,
        positionItem: action.payload.data.result,
      }

    case COMMON_FETCH_POSITION:
      return {
        ...state,
        position: action.payload.data.result.items,
      }

    case COMMON_FETCH_OBJECTS:
      return {
        ...state,
        objects: action.payload.data.result.items,
      }

    case COMMON_FETCH_GET_OBEJCT_ITEM:
      return {
        ...state,
        objectItem: action.payload.data.result,
      }

    case COMMON_FETCH_SECURITIES:
      return {
        ...state,
        securities: action.payload.data.result.items,
      }

    case COMMON_FETCH_GET_SECURITIES_ITEM:
      return {
        ...state,
        securityItem: action.payload.data.result,
      }

    case COMMON_FETCH_PRIORITIES:
      return {
        ...state,
        priorities: action.payload.data.result.items,
      }

    case COMMON_FETCH_GET_PRIORITIES_ITEM:
      return {
        ...state,
        priorityItem: action.payload.data.result,
      }

    case COMMON_FETCH_SAVECODES:
      return {
        ...state,
        saveCodes: action.payload.data.result.items,
      }

    case COMMON_FETCH_SAVECODES_ITEM:
      return {
        ...state,
        saveCodeItem: action.payload.data.result,
      }

    case COMMON_FETCH_RELATIVE_UNITS:
      return {
        ...state,
        relativeUnits: action.payload.data.result.items,
      }

    case CONTACT_FETCH_UNITS_EQUAL:
      return {
        ...state,
        unitsEqual: action.payload.data.result.items,
      }

    case COMMON_FETCH_LEADER_DERPATMENTS:
      return {
        ...state,
        leaderDepartments: action.payload.data.result.items,
      }

    case COMMON_FETCH_DERPATMENTS:
      let departments = state.departments
      let differentDepartments = state.differentDepartments
      let parentDepartments = state.parentDepartments
      if (
        action.meta.previousAction &&
        action.meta.previousAction.donViId === null &&
        !action.meta.previousAction.isParent
      ) {
        departments = action.payload.data.result.items
      }
      if (
        action.meta.previousAction &&
        action.meta.previousAction.donViId !== null &&
        !action.meta.previousAction.isParent
      ) {
        differentDepartments = action.payload.data.result.items
      }
      if (
        action.meta.previousAction &&
        action.meta.previousAction.donViId !== null &&
        action.meta.previousAction.isParent
      ) {
        parentDepartments = action.payload.data.result.items
      }
      return {
        ...state,
        departments: departments,
        differentDepartments: differentDepartments,
        parentDepartments: parentDepartments,
      }

    case FETCH_INTERNAL_DERPATMENTS:
      if (action.meta.previousAction.payload.request.params.isPhongBanLap) {
        return {
          ...state,
          phongBanLapCVNoiBo: action.payload.data.result,
        }
      } else {
        return {
          ...state,
          phongBanNhanCVNoiBo: action.payload.data.result,
        }
      }
    case COMMON_FETCH_GET_DEPARTMENTS_ITEM:
      return {
        ...state,
        departmentItem: action.payload.data.result,
      }

    case COMMON_FETCH_ATTACHED_UNITS:
      return {
        ...state,
        attachedUnits: action.payload.data.result.items,
      }

    case COMMON_FETCH_ELECTRIC_UNITS:
      return {
        ...state,
        electricUnits: action.payload.data.result.items,
      }

    case COMMON_ADD_TOASTER_MESSAGE:
      return {
        ...state,
        toaster: action.data,
      }

    case COMMON_REMOVE_TOASTER_MESSAGE:
      return {
        ...state,
        toaster: null,
      }

    case COMMON_FETCH_SIGN_NUMBER_DEPARTMENT_LEADER:
      let signNumberDepartmentLeaders = state.signNumberDepartmentLeaders
      let differentSignNumberDepartmentLeaders =
        state.differentSignNumberDepartmentLeaders
      if (
        action.meta &&
        action.meta.previousAction &&
        action.meta.previousAction.typeLeader === null
      ) {
        signNumberDepartmentLeaders = action.payload.data.result.items
      } else {
        differentSignNumberDepartmentLeaders = action.payload.data.result.items
      }
      return {
        ...state,
        signNumberDepartmentLeaders: signNumberDepartmentLeaders,
        differentSignNumberDepartmentLeaders:
          differentSignNumberDepartmentLeaders,
      }

    case COMMON_FETCH_RECEIVEDOCUMENT_TOTAL:
      let receiveDocumentTotals = state.receiveDocumentTotals
      if (
        action.meta.previousAction.status === Types.PERSONAL_WORK_NO_PROCESS
      ) {
        receiveDocumentTotals.noProcess = action.payload.data.result
      } else if (
        action.meta.previousAction.status === Types.DOCUMENT_RECEIVE_PROCESSING
      ) {
        receiveDocumentTotals.inProcess = action.payload.data.result
      }
      return {
        ...state,
        receiveDocumentTotals: receiveDocumentTotals,
      }

    case COMMON_FETCH_STAFFS_BY_DEPARTMENT_ID:
      return {
        ...state,
        staffs: action.payload.data.result.items || [],
      }

    case COMMON_FETCH_STAFFS_BY_UNIT_ID:
      return {
        ...state,
        staffs: action.payload.data.result.items || [],
      }

    case COMMON_FETCH_DERPATMENT_WORK_TOTAL:
      let departmentWorkTotals = state.departmentWorkTotals
      if (
        action.meta.previousAction.filter.status ===
          Types.DEPARTMENT_WORK_NOT_ASSIGN &&
        !action.meta.previousAction.filter.workExpired &&
        !action.meta.previousAction.filter.workExpiringSoon
      ) {
        departmentWorkTotals.notAssign = action.payload.data.result
      }
      if (action.meta.previousAction.filter.workExpired) {
        departmentWorkTotals.workExpired = action.payload.data.result
      }
      if (action.meta.previousAction.filter.workExpiringSoon) {
        departmentWorkTotals.workExpiringSoon = action.payload.data.result
      }
      return {
        ...state,
        departmentWorkTotals: departmentWorkTotals,
      }

    case COMMON_FETCH_PERSONAL_WORK_TOTAL:
      let personalWorkTotals = state.personalWorkTotals
      if (
        action.meta.previousAction.filter.status ===
          Types.PERSONAL_WORK_NO_PROCESS &&
        !action.meta.previousAction.filter.workExpired &&
        !action.meta.previousAction.filter.workExpiringSoon
      ) {
        personalWorkTotals.noProcess = action.payload.data.result
      }
      if (
        action.meta.previousAction.filter.status ===
          Types.DOCUMENT_RECEIVE_PROCESSING &&
        !action.meta.previousAction.filter.workExpired &&
        !action.meta.previousAction.filter.workExpiringSoon
      ) {
        personalWorkTotals.inProcess = action.payload.data.result
      }
      if (action.meta.previousAction.filter.workExpired) {
        personalWorkTotals.workExpired = action.payload.data.result
      }
      if (action.meta.previousAction.filter.workExpiringSoon) {
        personalWorkTotals.workExpiringSoon = action.payload.data.result
      }
      return {
        ...state,
        personalWorkTotals: personalWorkTotals,
      }

    case COMMON_FETCH_PERSONAL_LEADER_SIGN_NUMBER:
      return {
        ...state,
        personalLeaderSignNumber: action.payload.data.result.items,
      }

    case COMMON_FETCH_CHILD_DERPATMENTS:
      return {
        ...state,
        childDepartments: action.payload.data.result.items,
      }

    case COMMON_FETCH_STATISTICS:
      return {
        ...state,
        statistics: action.payload.data.result,
      }

    case COMMON_FETCH_CABINET_OF_DOCUMENT_CLEAR:
      return {
        ...state,
        listCabinetOfDocument: [],
      }

    case COMMON_FETCH_CABINET_OF_DOCUMENT:
      return {
        ...state,
        listCabinetOfDocument: action.payload.data.result,
      }

    case COMMON_SAVE_TYPE_SIDEBAR:
      return {
        ...state,
        typeSidebar: action.data,
      }

    case COMMON_FETCH_ALERT_MISTAKE_TOTAL:
      let alertMistakeTotals = state.alertMistakeTotals
      if (
        action.meta.previousAction.filter.typeAlertMistake ===
        Types.ALERT_MISTAKE_DOCUMENT
      ) {
        alertMistakeTotals.document = action.payload.data.result
      }
      if (
        action.meta.previousAction.filter.typeAlertMistake ===
        Types.ALERT_MISTAKE_WORK
      ) {
        alertMistakeTotals.work = action.payload.data.result
      }
      return {
        ...state,
        alertMistakeTotals: alertMistakeTotals,
      }

    case READONLY_ENABLE:
      return {
        ...state,
        enableReadOnly: action.data,
      }

    case SCROLL_FIXTOP_MENU:
      return {
        ...state,
        enableScrollFixedMenu: action.data,
      }

    case COMMON_FETCH_SIGN_USER_LIST:
      return {
        ...state,
        signUserList: action.payload.data.result.items,
      }

    case COMMON_FETCH_INTERNAL_UNITS:
      return {
        ...state,
        internalUnits: action.payload.data.result.items,
      }

    case COMMON_FETCH_RECEIVE_UNITS:
      return {
        ...state,
        receiveUnits: action.payload.data.result.items,
      }

    case COMMON_FETCH_LEADERCOMBINATION:
      return {
        ...state,
        leaderCombinations: action.payload.data.result.items,
      }

    case GET_DOCUMENT_DIGITIZE:
      return {
        ...state,
        documentDigitize: action.payload.data.result,
      }

    case GET_DOCUMENT_RECOVERY:
      return {
        ...state,
        documentRecovery: action.payload.data.result,
      }

    case CONFIG_REFRESH_REVOKE_DOCUMENT:
      return {
        ...state,
        config: {
          ...state.config,
          refreshRevokeDocument: action.status,
        },
      }

    case CONFIG_REFRESH_OPINION:
      return {
        ...state,
        config: {
          ...state.config,
          refreshOpinion: action.status,
        },
      }

    case CONFIG_REFRESH_SERVICE_DOCUMENT:
      return {
        ...state,
        config: {
          ...state.config,
          refreshServiceDocument: action.status,
        },
      }

    case GET_PHUONGPHAPLUU_CHITIET:
      return {
        ...state,
        phuongPhapLuuChiTiet: action.payload.data.result || {},
      }
    case GET_THOIGIANLUU_DANHSACH:
      return {
        ...state,
        thoiHanBaoQuanDanhSach: action.payload.data.result.items || [],
      }
    case API_REQUEST_NHANVIEN_PHONGBAN_QUYEN:
      return {
        ...state,
        nhanVienPhongBanPhanTheoQuyen: action.payload.data.result.items || [],
      }
    case API_REQUEST_PERMISSIONS_MENU:
      const listQuyen =
        action?.payload?.data?.result?.items
          ?.filter(item => item.isActive)
          .map?.(item => item?.maChucNang) || []
      return {
        ...state,
        quyensMenu: listQuyen || [],
      }
    case COMMON_ADD_TREE_DEPARTMENT_ORGANIZATION:
      return {
        ...state,
        dataTreeOrganizationDepartmentStore: action.data || [],
      }
    case COMMON_ADD_TREE_PHONGBAN_DONVI_NHANVIEN:
      return {
        ...state,
        dataTree: action.data || [],
      }
    case GET_LANHDAO_DONVI:
      return {
        ...state,
        dataLanhDaoDonVi: action?.payload?.data?.result || [],
      }
    case GET_THONGTIN_DONVI:
      return {
        ...state,
        dataThongTinDonVi: action?.payload?.data?.result?.[0] || [],
      }
    default:
      return state
  }
}
