import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Radio } from '@blueprintjs/core'

class GiaoGopY extends Component {
  static propTypes = {
    onClickGoBack: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    staffs: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
    error: {},
    typeSubmit: 'GiaoGopY',
    form: {
      yKien: '',
      chucDanhId: '',
      isSuaGiaoGopY: false,
    },
  }

  componentDidMount() {
    let state = this.state
    const ỵKienGiaoViec =
      this.props.requestComments &&
      this.props.requestComments.lanhDaoPhongBanGiaoViec
        ? this.props.requestComments.lanhDaoPhongBanGiaoViec.yKien
        : ''
    const chucDanhId =
      this.props.requestComments && this.props.requestComments.caNhanChuTri
        ? this.props.requestComments.caNhanChuTri.chucDanhId
        : ''
    if (
      this.props.requestComments &&
      this.props.requestComments.lanhDaoPhongBanGiaoViec &&
      this.props.requestComments.tinhTrang === 2
    ) {
      state.form['yKien'] = ỵKienGiaoViec
      state.form['chucDanhId'] = chucDanhId
      state.form['isSuaGiaoGopY'] = true
    }
    this.setState(state)
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name
    let state = this.state
    state.form[name] = value
    delete state.error[name]

    this.setState(state)
  }

  handleSubmit() {
    this.props.onSubmit &&
      this.props.onSubmit(this.state.typeSubmit, this.state.form)
  }
  render() {
    const staffs = this.props.staffs ? this.props.staffs.items : []
    const lanhDaoChiDao =
      this.props.requestComments &&
      this.props.requestComments.lanhDaoDonViChiDao
        ? this.props.requestComments.lanhDaoDonViChiDao.yKien
        : ''
    return (
      <div className="container-form-action">
        <div className="heading">
          <span className="click-go-back" onClick={this.props.onClickGoBack}>
            <span className="pt-icon pt-icon-arrow-left pull-left"></span>
          </span>
          <h4>
            {this.props.requestComments.tinhTrang === 1
              ? 'Giao góp ý'
              : 'Sửa giao góp ý'}
          </h4>
        </div>
        <div className="form-container form-send-mail">
          <div className="pt-form-group pt-intent-danger">
            {this.props.requestComments &&
              this.props.requestComments.lanhDaoDonViChiDao && (
                <div className="row">
                  <label className="pt-label col-md-12 col-xs-12">
                    Lãnh đạo chỉ đạo:
                    <span className="text-label"> {lanhDaoChiDao}</span>
                  </label>
                </div>
              )}
            <div className="row">
              <label className="pt-label col-md-5 col-xs-12">
                Nội dung giao góp ý
              </label>
            </div>
            <div className="row">
              <div className="pt-form-content col-md-12 col-xs-12">
                <div className="pt-form-content">
                  <textarea
                    rows="4"
                    className="pt-input pt-fill"
                    placeholder="Nhập nội dung…"
                    dir="auto"
                    name="yKien"
                    onChange={this.handleInputChange}
                    value={this.state.form.yKien}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <label className="pt-label col-md-5 col-xs-12">
                Cá nhân thực hiện
              </label>
            </div>
            <div className="row">
              <div className="pt-form-content department-perform-table  col-xs-12">
                <table className="pt-table table-container w-100">
                  <tbody>
                    {staffs.map((item, i) => (
                      <tr key={i}>
                        <td className="cell-content">{item.tenNhanVien}</td>
                        <td className="cell-content"></td>
                        <td className="cell-content">
                          <Radio
                            className="item-radio"
                            name="chucDanhId"
                            checked={
                              this.state.form.chucDanhId === item.chucDanhId
                            }
                            onChange={this.handleInputChange}
                            value={item.chucDanhId}
                            id={item.chucDanhId}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/*<div className="pt-select">*/}
                {/*  <select*/}
                {/*    name="chucDanhId"*/}
                {/*    onChange={this.handleInputChange}*/}
                {/*    value={this.state.form.chucDanhId}*/}
                {/*  >*/}
                {/*    <option>--Chọn cá nhân thực hiện--</option>*/}
                {/*    {staffs.map((item, i) => (*/}
                {/*      <option key={i} value={item.chucDanhId}>*/}
                {/*        {item.tenNhanVien}*/}
                {/*      </option>*/}
                {/*    ))}*/}
                {/*  </select>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>

        <div id="groupButton" className="buttton-action-footer">
          <Button
            onClick={this.props.onClickGoBack}
            className="pt-button btn-cancel"
          >
            <span className=" pt-icon icon-back"></span>
            <span className="text-content">Quay lại</span>
          </Button>
          <Button
            className="pt-button btn-save"
            onClick={this.handleSubmit}
            loading={this.props.isLoading}
            disabled={false}
          >
            <span className="text-content">Lưu</span>
            <span className="pt-icon icon-save"></span>
          </Button>
        </div>
      </div>
    )
  }
}

export default GiaoGopY
