import React from 'react'

const NotFoundPage = () => {
  return (
    <div className="error-page-container">
      <div className="error-content">
        <img
          src={process.env.PUBLIC_URL + '/images/404.png'}
          alt="404"
          className="img-thumb-error"
        />
        <h2 className="title-error">404</h2>
        <p className="description">
          Không tìm thấy trang này, vui lòng quay lại{' '}
          <a href="/" className="text-link">
            trang chủ
          </a>
        </p>
      </div>
    </div>
  )
}

export default NotFoundPage
