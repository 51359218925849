import React, { memo } from 'react'

import { Dialog, Card } from 'components/newCommon/'
import {
  DELETE_TODO_TITLE,
  DELETE_TODO,
  TEXT_BUTTON_SUBMIT,
} from 'constants/Popup'

const DeleteTodo = ({
  onClose = () => {},
  isOpen = false,
  onConfirm = () => {},
}) => {
  const deleteTask = async () => {
    await onConfirm()
    onClose()
  }

  return (
    <Dialog
      buttonUppercase
      isOpen={isOpen}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      confirmText={TEXT_BUTTON_SUBMIT}
      loading={false}
      onCancel={onClose}
      onConfirm={deleteTask}
      onClose={onClose}
      title={DELETE_TODO_TITLE}
      textCenter
    >
      <Card className="mt20 mb20">{DELETE_TODO}</Card>
    </Dialog>
  )
}

export default memo(DeleteTodo)
