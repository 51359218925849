import React from 'react'
import { MasterLayout } from '../../components/layout'
import { HeadingTableSort } from '../../components/common'
import { ListPermissionRole } from '../../components/System'
import { headers } from '../../constants/Table'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class ListPermissionRolePage extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitRemove = this.handleSubmitRemove.bind(this)
  }

  state = {
    Staffs: [],
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.props.actions.getCategoryStaffSubject(this.props.match.params.id)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.staffs.items !== this.props.staffs.items) {
      if (this.props.staffs.items.length === 0) {
        return
      }
      this.setState({ Staffs: this.props.staffs.items })
    }
  }

  handleSubmitRemove() {
    this.props.history.goBack()
  }

  handleSubmit(id) {
    if (id) {
      this.props.actions.getCategoryStaffSubject(id)
    }
  }

  render() {
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <div className="">
          <button
            type="button"
            className="dispatch-code pt-button pt-button-back"
            onClick={this.handleSubmitRemove}
          >
            <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
            <span className="text-content">Quay lại</span>
          </button>
        </div>

        <div className="listrole-container">
          <ListPermissionRole
            items={this.state.Staffs}
            actions={this.props.actions}
            onSubmit={this.handleSubmit}
            onRemove={this.handleSubmitRemove}
            match={this.props.match}
          >
            {this.state.Staffs.length > 0 && (
              <HeadingTableSort data={headers.ListPermissionRole} />
            )}
          </ListPermissionRole>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  staffs: {
    ...state.categoryStattUnit,
    items: state.category.categoryStattUnit,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPermissionRolePage)
