import React, { useMemo } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Tag from 'components/common/Tag'

const InfoCard = ({
  history,
  className,
  labels = ['Ký hiệu', 'Ngày lập'],
  types = [],
  data: { id, type, kyHieu, ngay, noiDung },
}) => {
  const dateFormat = 'DD/MM/YYYY'

  const goDetail = useMemo(
    () => () => {
      const result = types?.find(e => e.type === type)
      if (isEmpty(result)) {
        return
      }

      const newPath = result?.path?.replace(':id', id)
      return history.push(newPath)
    },
    [id, type, types, history]
  )

  const renderType = useMemo(
    () => typeEnum => {
      const type = types?.find(e => e.type === typeEnum)
      if (isEmpty(type)) {
        return
      }

      return <Tag name={type?.name} className={type?.className} />
    },
    [types]
  )

  return (
    <div className={classNames('cursor-pointer', className)} onClick={goDetail}>
      <div className="d-flex d-flex-wrap align-items-center justify-content-space-between">
        <div className="d-flex d-flex-wrap align-items-center">
          {type && type >= 0 ? (
            <div className="mr5">{renderType(type)}</div>
          ) : (
            ''
          )}
          {kyHieu && (
            <p className="font-size-13">
              <span className="mr5" style={{ color: '#959595' }}>
                {labels?.[0]}:
              </span>
              {kyHieu}
            </p>
          )}
        </div>
        {ngay && (
          <p className="font-size-13">
            <span className="mr5" style={{ color: '#959595' }}>
              {labels?.[1]}:
            </span>
            {ngay ? moment(ngay).format(dateFormat) : '-'}
          </p>
        )}
      </div>
      {noiDung && (
        <div className="font-size-13 mt5 line-clamp-2 font-weight-600 font-roboto">
          {noiDung}
        </div>
      )}
    </div>
  )
}

export default InfoCard
