import { Action, Toast, inProcess } from '../../../constants/MessageForm'
import {
  ContentHeader,
  EmployeeSelectDialogV2,
  EmptyDataTable,
  ErrorLoadingTable,
  LoadingTable,
} from '../../../components/common'
import React, { PureComponent } from 'react'
import {
  addAuthorityGroup,
  deleteAuthorityGroup,
  fetchAuthorityGroup,
  addAuthorityMember,
  removeAuthorityMember,
  addGroupFile,
  removeGroupFile,
} from '../../../actions/procedure'

import GoBackButton from '../../../components/Button/GoBackButton'
import { Intent } from '@blueprintjs/core'
import { MasterLayout } from '../../../components/layout'
import UsingGroupDetail from './UsingGroupDetail'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { commonAddToasterMessage, fileUpload } from '../../../actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const PAGE_NAME = 'Quy Trình'
const PAGE_ICON = 'icon-danh-muc-quy-trinh'

class UsingGroupDetailPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isError: false,
      isEmptyData: false,
      listDeleteGroupId: [],
      isDialogOpen: false,
      currentGroupId: null,
      currentGroupList: [],
    }
  }

  addGroup = () => {
    const { id } = this.props.match.params
    const { addGroup, showToast } = this.props
    addGroup(id).then(res => {
      const status = !!(_.get(res, 'payload.status') === 200)
      showToast({
        message: status
          ? Toast.SUCCESS(Action.CREATE)
          : Toast.FAIL(Action.CREATE),
        intent: status ? Intent.SUCCESS : Intent.DANGER,
      })
    })
  }

  deleteGroup = async groupId => {
    const { id } = this.props.match.params
    const { deleteGroup, showToast } = this.props
    const { listDeleteGroupId } = this.state
    if (!listDeleteGroupId.includes(groupId)) {
      if (groupId) {
        await this.setState({
          listDeleteGroupId: [...listDeleteGroupId, groupId],
        })
        deleteGroup(id, groupId)
          .then(res => {
            const status = !!(_.get(res, 'payload.status') === 200)
            showToast({
              message: status
                ? Toast.SUCCESS(Action.DELETE)
                : Toast.FAIL(Action.DELETE),
              intent: status ? Intent.SUCCESS : Intent.DANGER,
            })
          })
          .finally(() => {
            const { listDeleteGroupId } = this.state
            this.setState({
              listDeleteGroupId: listDeleteGroupId.filter(id => id !== groupId),
            })
          })
      } else {
        showToast({
          message: Toast.FAIL(Action.DELETE),
          intent: Intent.DANGER,
        })
      }
    } else {
      return showToast({
        message: inProcess,
        intent: Intent.WARNING,
      })
    }
  }

  toggleDialog = groupId => {
    const { groupList } = this.props
    const { isDialogOpen } = this.state
    this.setState({
      currentGroupId: !isDialogOpen ? groupId : null,
      currentMemberList: !isDialogOpen
        ? _.get(_.find(groupList, { nhomId: groupId }), 'dsThanhVien', [])
        : [],
      isDialogOpen: !isDialogOpen,
    })
  }

  addMember = memberList => {
    const { addMember, showToast } = this.props
    const { currentGroupId } = this.state
    addMember(currentGroupId, memberList).then(res => {
      const status = !!(_.get(res, 'payload.status') === 200)
      !!status && this.toggleDialog()
      showToast({
        message: status ? Toast.SUCCESS(Action.ADD) : Toast.FAIL(Action.ADD),
        intent: status ? Intent.SUCCESS : Intent.DANGER,
      })
      this.fetchUnitGroup()
    })
  }

  removeMember = (groupId, memberId) => {
    const { removeMember, showToast } = this.props
    removeMember(groupId, memberId).then(res => {
      const status = !!(_.get(res, 'payload.status') === 200)
      showToast({
        message: status
          ? Toast.SUCCESS(Action.DELETE)
          : Toast.FAIL(Action.DELETE),
        intent: status ? Intent.SUCCESS : Intent.DANGER,
      })
    })
  }

  fetchUnitGroup = async () => {
    const { id } = this.props.match.params
    const { fetchGroup } = this.props
    await this.setState({ isLoading: true })
    fetchGroup(id)
      .then(res => {
        const status = !!(_.get(res, 'payload.status') === 200)
        this.setState({
          isError: !status,
        })
      })
      .finally(() =>
        this.setState({
          isLoading: false,
        })
      )
  }

  addGroupFile = async (file, groupId) => {
    const { uploadFile, addGroupFile, showToast } = this.props
    let fileInfo = {}
    await uploadFile(file).then(res => {
      fileInfo = _.get(res, 'payload.data.result[0]')
    })
    await addGroupFile(groupId, this.convertFile(fileInfo)).then(res => {
      const status = !!(_.get(res, 'payload.status') === 200)
      showToast({
        message: status ? Toast.SUCCESS(Action.ADD) : Toast.FAIL(Action.ADD),
        intent: status ? Intent.SUCCESS : Intent.DANGER,
      })
    })
    this.fetchUnitGroup()
  }

  removeGroupFile = async groupId => {
    const { removeGroupFile, showToast } = this.props
    await removeGroupFile(groupId).then(res => {
      const status = !!(_.get(res, 'payload.status') === 200)
      showToast({
        message: status
          ? Toast.SUCCESS(Action.DELETE)
          : Toast.FAIL(Action.DELETE),
        intent: status ? Intent.SUCCESS : Intent.DANGER,
      })
    })
    this.fetchUnitGroup()
  }

  convertFile = file => {
    return {
      file_id: file.id,
      ten_file: file.originalName,
      url: file.path,
      kieu_file: file.extension,
      kich_thuoc: file.length,
      is_existed: true,
    }
  }

  componentDidMount() {
    this.fetchUnitGroup()
  }

  render() {
    const { groupList, title } = this.props
    const { isLoading, isError, isDataEmpty, isDialogOpen, currentMemberList } =
      this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <ContentHeader
          pageIcon={PAGE_ICON}
          pageName={`${PAGE_NAME} ${!isLoading ? title : ''}`}
          showGoBackButton
        />
        <div className="group-page-container">
          <div className="container-title">NHÓM THÀNH VIÊN</div>
          <div className="container-body">
            {!!isLoading ? (
              <LoadingTable />
            ) : !!isError ? (
              <ErrorLoadingTable />
            ) : !!isDataEmpty ? (
              <EmptyDataTable />
            ) : (
              !!groupList.length &&
              groupList.map((group, index) => (
                <UsingGroupDetail
                  key={index}
                  group={group}
                  index={index}
                  deleteGroup={this.deleteGroup}
                  removeMember={this.removeMember}
                  openMemberDialog={this.toggleDialog}
                  addGroupFile={this.addGroupFile}
                  removeGroupFile={this.removeGroupFile}
                  token={_.get(this.props, 'auth.token.accessToken', '')}
                />
              ))
            )}
            {!isLoading && !isError && (
              <button
                className="pt-button add-group-btn"
                onClick={this.addGroup}
              >
                Thêm nhóm
                <i className="pt-icon icon-Plus" />
              </button>
            )}
          </div>
          <div className="container-footer">
            <GoBackButton />
          </div>
        </div>
        <EmployeeSelectDialogV2
          isDialogOpen={isDialogOpen}
          closeDialog={this.toggleDialog}
          title="Chọn thành viên"
          onSubmit={this.addMember}
          defaultSelectList={currentMemberList}
        />
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  groupList: state.procedureAuthority.groupList,
  title: state.procedureAuthority.title,
  auth: state.auth,
})

const mapDispathToProps = dispatch => ({
  showToast: bindActionCreators(commonAddToasterMessage, dispatch),
  fetchGroup: bindActionCreators(fetchAuthorityGroup, dispatch),
  addGroup: bindActionCreators(addAuthorityGroup, dispatch),
  deleteGroup: bindActionCreators(deleteAuthorityGroup, dispatch),
  addMember: bindActionCreators(addAuthorityMember, dispatch),
  removeMember: bindActionCreators(removeAuthorityMember, dispatch),
  uploadFile: bindActionCreators(fileUpload, dispatch),
  addGroupFile: bindActionCreators(addGroupFile, dispatch),
  removeGroupFile: bindActionCreators(removeGroupFile, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispathToProps
)(withRouter(UsingGroupDetailPage))
