import React from 'react'
import { MasterLayout } from '../../components/layout/'
import {
  DetailBoxAction,
  DetailInfo,
} from '../../components/Utilities/DocumentarySearch/'
import {
  InformationSteering,
  ExecutingView,
  DetailFileContent,
  LoadingLine,
  LoadingItem,
  DetailFileContentV2,
} from 'components/common'
import { Flowdocumentary } from '../../components/ReceiveDocument'
import { FlowSendDocumentary } from '../../components/SendDocument'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import * as Types from '../../constants/Api'
import { SendMail } from '../../components/ReceiveDocument'

const TYPE_INFORMATION_STEERING = 'parent'

class DetailSearchDocumentPage extends React.Component {
  static propTypes = {
    receiveDocument: PropTypes.object.isRequired,
    executingViews: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  }

  refHandlers = {
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  constructor(props) {
    super(props)
    this.handleClickShowInfoMobile = this.handleClickShowInfoMobile.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)
    //this.closeShowDetailInfo = this.closeShowDetailInfo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickDetailInfo = this.handleClickDetailInfo.bind(this)
    this.handleClickInformationSteering =
      this.handleClickInformationSteering.bind(this)
    this.handleFinishLoadingMigratedFile =
      this.handleFinishLoadingMigratedFile.bind(this)
    this.handleClickFlowdocumentary = this.handleClickFlowdocumentary.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  state = {
    showResults: false,
    showListAction: true,
    showSection: 'noContent',
    showDetailInfo: false,
    file: null,
    receiveDocumentId: this.props.match.params.id,
    receiveDocument: null,
    isLoadingData: {
      receiveDocument: true,
      // CHANGED: Chuyển sang gọi api khi click action "Thông tin chỉ đạo"
      // informationSteering: true
    },
    informationSteering: null,
    parentInformationSteering: null,
    isLoadingMigratedFile: true,
    isLoadingSubData: false,
    isLoadedApi: {
      flowDocument: false, // Luong cong van
    },
  }

  handleFinishLoadingMigratedFile = () => {
    this.setState({ isLoadingMigratedFile: !this.state.isLoadingMigratedFile })
  }

  componentWillUnmount() {
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
      showDetailInfo: false,
      isRedirect: false,
      redirectTo: null,
      file: null,
    })
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleClickGoBack = e => {
    e.preventDefault()
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: 'noContent',
    })
  }

  handleClickExecutingView = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'XemThucHien',
    })

    this.props.actions
      .getExecutingView(this.state.receiveDocumentId)
      .then(res => {
        this.setState({
          showResults: true,
          isLoadingSubData: false,
        })
      })
  }

  handleClickInformationSteering = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinChiDao',
    })

    if (
      this.props.receiveDocument &&
      this.props.receiveDocument.item &&
      this.props.receiveDocument.item.congVanCapTrenChiDaoId
    ) {
      await this.props.actions
        .InformationSteering(
          this.props.receiveDocument.item.congVanCapTrenChiDaoId,
          TYPE_INFORMATION_STEERING
        )
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            this.setState({
              parentInformationSteering: res.payload.data.result,
            })
          }
        })
    }

    if (
      this.props.receiveDocument &&
      this.props.receiveDocument.item &&
      this.props.receiveDocument.item.trangThai !== 0
    ) {
      await this.props.actions
        .InformationSteering(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.data && res.payload.data.result) {
            this.setState({
              informationSteering: res.payload.data.result,
            })
          }
        })
    }
    await this.setState({
      showResults: true,
      isLoadingSubData: false,
    })
  }

  handleClickFlowdocumentary = async e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'LuongCongVan',
    })

    let { isCheck, isLoadedApi } = this.state
    if (
      this.props.receiveDocument &&
      this.props.receiveDocument.item &&
      this.props.receiveDocument.item.congVanDiId &&
      isLoadedApi &&
      isLoadedApi.flowDocument === false
    ) {
      await this.props.actions
        .getFlowDocumentarySendDocument(
          this.props.receiveDocument.item.congVanDiId
        )
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              flowDocument: true,
            }
          }
          isCheck = true
        })
    }

    if (
      this.props.receiveDocument &&
      this.props.receiveDocument.item &&
      !this.props.receiveDocument.item.congVanDiId &&
      this.state.receiveDocumentId &&
      isLoadedApi &&
      isLoadedApi.flowDocument === false
    ) {
      await this.props.actions
        .getFlowDocumentaryReceiveDocument(this.state.receiveDocumentId)
        .then(res => {
          if (res.payload && res.payload.status === 200) {
            isLoadedApi = {
              ...isLoadedApi,
              flowDocument: true,
            }
          }
        })
    }

    await this.setState({
      isCheck,
      showResults: true,
      isLoadingSubData: false,
      isLoadedApi,
    })
  }

  handleClickDetailInfo = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: false,
      showListAction: false,
      isLoadingSubData: true,
      showSection: 'ThongTinCongVan',
    })

    Promise.all([
      this.props.actions.getReceiveDocumentItemInformation(
        this.state.receiveDocumentId
      ),
    ]).then(() => {
      this.setState({
        showResults: true,
        isLoadingSubData: false,
      })
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
    })
  }

  handleClickShowInfoMobile = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  showDetailInfo = () => {
    this.setState({
      showDetailInfo: !this.state.showDetailInfo,
    })
  }

  closeShowDetailInfo = () => {
    this.setState({
      showDetailInfo: false,
    })
  }

  handleSubmit = (title, msg, intent, isSuccess, id = 0) => {
    this.props.actions.commonAddToasterMessage({ message: msg, intent: intent })
    if (isSuccess) {
      this.props.history.goBack()
    }
  }

  componentDidMount() {
    // CHANGED: Đổi api get chi tiết công văn lúc đầu tiên
    if (this.state.receiveDocumentId) {
      this.props.actions
        .getReceiveDocumentItemShorten(this.state.receiveDocumentId)
        .then(() => {
          if (this.props.receiveDocument && this.props.receiveDocument.item) {
            this.setState({
              file: this.props.receiveDocument.item.fileNoiDung,
              receiveDocument: this.props.receiveDocument.item,
              isLoadingData: {
                ...this.state.isLoadingData,
                receiveDocument: false,
              },
            })
          }
        })
    }

    if (!this.props.objects) {
      this.props.actions.commonFetchObjects()
    }

    if (!this.props.securities) {
      this.props.actions.commonFetchSecurities()
    }

    if (!this.props.priorities) {
      this.props.actions.commonFetchPriorities()
    }

    // CHANGED: Chuyển sang gọi api khi click action "Thông tin chỉ đạo"
    // this.props.actions.getExecutingView(this.state.receiveDocumentId);
    // this.props.actions.InformationSteering(this.state.receiveDocumentId).then(() => {
    //   if (this.props.receiveDocument && this.props.receiveDocument.informationSteering) {
    //     this.setState({informationSteering: this.props.receiveDocument.informationSteering});
    //   }
    //   this.setState({
    //     isLoadingData: {
    //       ...this.state.isLoadingData,
    //       informationSteering: false
    //     }
    //   });
    // });
    window.addEventListener('scroll', this.handleScroll)
  }

  // CHANGED: Chuyển sang gọi api khi click action "Thông tin chỉ đạo"
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.receiveDocument.informationSteering !== this.props.receiveDocument.informationSteering && nextProps.receiveDocument.informationSteering) {
  //     this.setState({informationSteering: nextProps.receiveDocument.informationSteering});
  //   }
  // }

  goBack() {
    this.props.history.goBack()
  }

  handleClickDepartmentExecutingView = id => {
    this.props.actions.getMoreExecutingView(id)
  }

  handleClickMoreDepartmentExecutingView = id => {
    this.props.actions.getMoreFlowDocumentaryReceiveDocument(id)
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 62) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  sendToChat = e => {
    e.preventDefault()
    let { receiveDocument } = this.props
    if (receiveDocument && receiveDocument.item) {
      const { auth } = this.props
      let fileNoiDung = receiveDocument.item.fileNoiDung
        ? receiveDocument.item.fileNoiDung.fileId
        : ''
      // Truyền param id của công văn, công việc hoặc ký số. Có thêm kiểu của loại tài liệu
      let infoGetFileDinhKem = receiveDocument.item.congVanId
      let infoGetFileDinhKemType = 'CONG_VAN'
      if (!infoGetFileDinhKem) {
        infoGetFileDinhKem = receiveDocument.item.congVanNoiBoId
        infoGetFileDinhKemType = 'CONG_VAN_NOI_BO'
      }
      const url =
        Types.FE_CHAT_URL +
        `?accessToken=${auth.token.accessToken}&expiresIn=${
          auth.token.expiresIn
        }
      &tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}
      &fileId=${fileNoiDung.toUpperCase()}&infoGetFileDinhKem=${infoGetFileDinhKem.toUpperCase()}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      window.open(url, '_blank')
    }
  }

  render() {
    let fileNoiDung =
      this.props.receiveDocument && this.props.receiveDocument.item
        ? this.props.receiveDocument.item.fileNoiDung
        : {}
    if (fileNoiDung) {
      fileNoiDung.kichThuocStr = Tool.Utils.getFileSize(fileNoiDung.kichThuoc)
    }
    return (
      <MasterLayout collapseSideBar={true} typeSidebar={this.props.typeSidebar}>
        <div className="detail-receive-document">
          {this.state.isLoadingData &&
          Object.values(this.state.isLoadingData).find(
            item => item === true
          ) !== undefined ? (
            <div className="row-detail-container clearfix loading-line-detail">
              <div className="col-md-4 col-xs-12">
                <LoadingLine />
              </div>
            </div>
          ) : (
            <div className="dispatch-code">
              <button
                onClick={this.goBack.bind(this)}
                type="button"
                className={classnames(
                  'pt-button pt-button-back button-back-fixed',
                  {
                    'fixed-button-back':
                      this.state.boxMenuFixTop &&
                      !this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                    'fixed-button-hasSidebar':
                      this.state.boxMenuFixTop &&
                      this.props.enableScrollFixedMenu &&
                      window.innerWidth >= 1024,
                  }
                )}
              >
                <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
                <span className="text-content">Quay lại</span>
              </button>
              {this.state.receiveDocument &&
                this.state.receiveDocument.soCongVan && (
                  <span className="number-code">
                    <span className="name">Công văn đến:</span>
                    <span className="code">
                      {this.state.receiveDocument &&
                        this.state.receiveDocument.soCongVan}
                    </span>
                  </span>
                )}
            </div>
          )}
          <div className="detail-container">
            <div className="row-detail-container clearfix">
              <div className="box-left">
                <DetailFileContentV2
                  auth={this.props.auth}
                  actions={this.props.actions}
                  file={this.state.file}
                />
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
              >
                <div className="full-box-content" ref="boxAction">
                  {this.state.showListAction && (
                    <div className="full-box-group">
                      <DetailBoxAction
                        onClickExecutingView={this.handleClickExecutingView}
                        onClickDetailInfo={this.handleClickDetailInfo}
                        onClickInformationSteering={
                          this.handleClickInformationSteering
                        }
                        receiveDocument={
                          this.state.receiveDocument
                            ? this.state.receiveDocument
                            : {}
                        }
                        auth={this.props.auth}
                        actions={this.props.actions}
                        informationSteering={this.state.informationSteering}
                        isLoadingData={this.state.isLoadingData}
                        onClickFlowdocumentary={this.handleClickFlowdocumentary}
                        sendToChat={this.sendToChat}
                        onClickSendMail={this.handleClickSendMail}
                      />
                    </div>
                  )}

                  {!this.state.showListAction &&
                    this.state.isLoadingSubData && (
                      <div className="loading-container">
                        <LoadingItem />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'XemThucHien' && (
                      <div className="box-action-item">
                        <ExecutingView
                          onClickGoBack={this.handleClickGoBack}
                          onClickDepartment={
                            this.handleClickDepartmentExecutingView
                          }
                          executingViews={this.props.executingViews.items}
                          auth={this.props.auth}
                          actions={this.props.actions}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'ThongTinCongVan' && (
                      <div className="box-action-item">
                        <DetailInfo
                          onClickGoBack={this.handleClickGoBack}
                          receiveDocument={
                            this.props.receiveDocument
                              ? this.props.receiveDocument.information
                              : {}
                          }
                          securities={this.props.securities}
                          priorities={this.props.priorities}
                          objects={this.props.objects}
                        />
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'LuongCongVan' && (
                      <div className="box-action-item">
                        {!this.state.isCheck && (
                          <Flowdocumentary
                            auth={this.props.auth}
                            onClickGoBack={this.handleClickGoBack}
                            flowDocument={
                              this.props.executingViews
                                .flowDocument_ReceiveDocument
                            }
                            onClickDepartment={
                              this.handleClickMoreDepartmentExecutingView
                            }
                            actions={this.props.actions}
                          />
                        )}
                        {this.state.isCheck && (
                          <FlowSendDocumentary
                            auth={this.props.auth}
                            onClickGoBack={this.handleClickGoBack}
                            flowDocument={
                              this.props.executingViews.flowSendDocument
                            }
                            onClickDepartment={
                              this.handleClickMoreDepartmentExecutingView
                            }
                            flowReceiveDocument={
                              this.props.executingViews.flowDocuments
                            }
                            actions={this.props.actions}
                          />
                        )}
                      </div>
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'ThongTinChiDao' && (
                      <InformationSteering
                        onClickGoBack={this.handleClickGoBack}
                        informationSteering={this.state.informationSteering}
                        informationSteeringLevel={
                          this.state.parentInformationSteering
                        }
                        isShowMobile={true}
                        isShowMobileLevel={true}
                      />
                    )}

                  {this.state.showResults &&
                    this.state.showSection === 'GuiEmail' && (
                      <div className="box-action-item">
                        <SendMail
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSubmit}
                          action={this.props.actions}
                          receiveDocument={
                            this.props.receiveDocument &&
                            this.props.receiveDocument.item
                              ? this.props.receiveDocument.item
                              : {}
                          }
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  receiveDocument: {
    ...state.receiveDocuments,
    item: state.entities.receiveDocuments[state.receiveDocuments.detailId],
    informationSteering: state.receiveDocuments.item,
    parentInformationSteering: state.receiveDocuments.parentInformationSteering,
    information: state.receiveDocuments.information,
  },

  executingViews: {
    ...state.executingViews,
    items:
      state.executingViews.items &&
      state.executingViews.items.map(id => {
        let data = state.entities.executingViews[id]

        const getDSDonVi = dsDonViTrucThuocResponse => {
          return dsDonViTrucThuocResponse.map(item => {
            let subItem = state.entities.executingViews[item.chiDaoId]

            return {
              ...item,
              executingViews: [
                subItem
                  ? {
                      ...subItem,
                      dsDonViTrucThuocResponse: getDSDonVi(
                        subItem.dsDonViTrucThuocResponse
                      ),
                    }
                  : undefined,
              ],
            }
          })
        }

        return {
          ...data,
          dsDonViTrucThuocResponse: getDSDonVi(data.dsDonViTrucThuocResponse),
        }
      }),

    flowDocuments: Object.keys(state.executingViews.flowDocuments).map(key => {
      const item = state.executingViews.flowDocuments[key]
      return {
        ...item,
        nguoiTaoCongVan: state.entities.users[item.nguoiTaoCongVan],
        chiDao: {
          ...state.entities.executingViews[item.chiDao],
          dsDonViTrucThuocResponse: (() => {
            if (!state.entities.executingViews[item.chiDao]) {
              return []
            }
            const getDSDonVi = dsDonViTrucThuocResponse => {
              return dsDonViTrucThuocResponse.map(item => {
                let subItem = state.entities.executingViews[item.chiDaoId]
                let flowDocument =
                  state.executingViews.flowDocuments[item.chiDaoId]
                return {
                  ...item,
                  executingViews: [
                    subItem
                      ? {
                          ...subItem,
                          dsDonViTrucThuocResponse: getDSDonVi(
                            subItem.dsDonViTrucThuocResponse
                          ),
                        }
                      : undefined,
                  ],
                  flowDocument: {
                    ...flowDocument,
                    nguoiTaoCongVan: flowDocument
                      ? state.entities.users[flowDocument.nguoiTaoCongVan]
                      : undefined,
                  },
                }
              })
            }

            return getDSDonVi(
              state.entities.executingViews[item.chiDao]
                .dsDonViTrucThuocResponse
            )
          })(),
        },
      }
    }),

    flowDocument_ReceiveDocument: {
      ...state.executingViews.flowDocument_ReceiveDocument,
      nguoiTaoCongVan:
        state.entities.users[
          state.executingViews.flowDocument_ReceiveDocument.nguoiTaoCongVan
        ],
      chiDao: {
        ...state.entities.executingViews[
          state.executingViews.flowDocument_ReceiveDocument.chiDao
        ],
        dsDonViTrucThuocResponse: (() => {
          if (
            !state.entities.executingViews[
              state.executingViews.flowDocument_ReceiveDocument.chiDao
            ]
          ) {
            return []
          }
          const getDSDonVi = dsDonViTrucThuocResponse => {
            return dsDonViTrucThuocResponse.map(item => {
              let subItem = state.entities.executingViews[item.chiDaoId]
              let flowDocument =
                state.executingViews.flowDocuments[item.chiDaoId]
              return {
                ...item,
                executingViews: [
                  subItem
                    ? {
                        ...subItem,
                        dsDonViTrucThuocResponse: getDSDonVi(
                          subItem.dsDonViTrucThuocResponse
                        ),
                      }
                    : undefined,
                ],
                flowDocument: {
                  ...flowDocument,
                  nguoiTaoCongVan: flowDocument
                    ? state.entities.users[flowDocument.nguoiTaoCongVan]
                    : undefined,
                },
              }
            })
          }

          return getDSDonVi(
            state.entities.executingViews[
              state.executingViews.flowDocument_ReceiveDocument.chiDao
            ].dsDonViTrucThuocResponse
          )
        })(),
      },
    },
  },

  typeSidebar: state.common.typeSidebar,

  securities: state.common.securities,

  priorities: state.common.priorities,

  objects: state.common.objects,

  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailSearchDocumentPage)
