import React from 'react'
import { withCookies } from 'react-cookie'
import withRouter from 'react-router/withRouter'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as Actions from '../../actions'
import { Authorization } from 'components/auth'
import HeaderMenuItem from './HeaderMenuItem'
import { checkPermissionMenu } from 'helpers/Helper'

const Navigation = ({ auth, menu, actions, ...props }) => {
  const Permission = (pers = []) => {
    return Authorization.Component(pers)(HeaderMenuItem)
  }

  return (
    <div className="navigation">
      <div className="navigation__logo">
        {/* eslint-disable-next-line */}
        <a
          href="/"
          style={{ backgroundImage: `url('/images/logo-nav.png')` }}
        ></a>
      </div>
      <ul className="navigation__menu">
        {menu
          ?.filter(
            e =>
              !e?.permissionMenu ||
              (e?.permissionMenu &&
                checkPermissionMenu(props?.quyensMenu, e.permissionMenu))
          )
          ?.map(item => {
            const Auth = Permission(item.permission)
            return (
              <Auth
                key={item.name}
                auth={auth}
                item={item}
                orPermission={item.orPermission}
                actions={actions}
              />
            )
          })}
      </ul>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...Actions }, dispatch),
})

const mapStateToProps = state => ({
  auth: state.auth,
})

export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation))
)
