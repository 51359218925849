import React, { memo, useEffect, useRef, useState } from 'react'
import { get, isArray, isFunction } from 'lodash'
import csx from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { withCookies } from 'react-cookie'

const Tabs = ({
  className,
  classContent = '',
  dataSource,
  selectedKey,
  onChange,
  onDragDrop = () => {},
  leftHeader,
  rightHeader,
}) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const [selected, setSelected] = useState()

  const changeTab = key => () => {
    _mountedSet(setSelected, key)
    isFunction(onChange) && onChange(key)
  }

  const onDragEnd = e => {
    onDragDrop(e)
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _mountedSet(setSelected, selectedKey)
  }, [selectedKey])

  return (
    <div className={className}>
      <header className={csx('element-center', 'position-relative')}>
        <span
          style={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {leftHeader}
        </span>
        <span>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {provided => (
                <div
                  className="tabs-dragdrop"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {isArray(dataSource) &&
                    dataSource.map(({ key, title }, index) => (
                      <Draggable draggableId={key} key={key} index={index}>
                        {(provided, snapshot) => (
                          <div
                            className="tabs-dragdrop-box"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              key={key}
                              className={csx('pt-button cpc-button', {
                                transparent: selected !== key,
                                'no-border': selected !== key,
                                'pt-intent-primary': selected === key,
                              })}
                              onClick={changeTab(key)}
                              {...provided.dragHandleProps}
                            >
                              {title}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </span>
        <span
          style={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {rightHeader}
        </span>
      </header>
      <main className={csx(classContent)}>
        {isArray(dataSource) &&
          dataSource.map(({ key, content }) =>
            selected === key ? <div key={key}>{content}</div> : null
          )}
      </main>
    </div>
  )
}

export default withCookies(memo(Tabs))
