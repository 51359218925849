import React from 'react'
import { Link } from 'react-router-dom'

class HeadingPage extends React.Component {
  render() {
    return (
      <div className="heading-page">
        <div className="row">
          <div className="col-xs-12">
            <h3 className="text-heading-page">
              <span className={`${this.props.iconPage} icon-heading`}></span>
              <span className="text-heading">{this.props.namePage}</span>
            </h3>
            {this.props.children}
            {this.props.showButton ? (
              <Link
                to={this.props.linkButton}
                className="btn-action pull-right hidden-btn btn-addnew"
              >
                <span className="name-btn">{this.props.nameButton}</span>
                <span className="pt-icon pt-icon-small-plus"></span>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

export default HeadingPage
