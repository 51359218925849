import React from 'react'
import Slider from 'react-slick'

class SlickSlider extends React.Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    }
    return (
      <div className="slider">
        <Slider {...settings}>
          <div className="item">
            <img
              src="http://images.tapchianhdep.net/15-10bo-hinh-anh-em-be-de-thuong-dep-me-hon10.jpg"
              alt=""
            />
          </div>
          <div className="item">
            <img
              src="http://images.tapchianhdep.net/15-10bo-hinh-anh-em-be-de-thuong-dep-me-hon10.jpg"
              alt=""
            />
          </div>
          <div className="item">
            <img
              src="http://images.tapchianhdep.net/15-10bo-hinh-anh-em-be-de-thuong-dep-me-hon10.jpg"
              alt=""
            />
          </div>
        </Slider>
      </div>
    )
  }
}

export default SlickSlider
