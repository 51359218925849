import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { PopupConfirm } from '../common/'
import {
  THONG_BAO,
  TEXT_BUTTON_SUBMIT,
  THONG_BAO_CONG_VAN_DEN_THU_HOI,
} from '../../constants/Popup'

class ListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenPopup: false,
    }
  }

  clickPopupConfirm = () => {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup,
    })
  }

  render() {
    const { item, number } = this.props
    if (!item || !number) {
      return
    }
    return (
      <tr className="table-row-middle" onClick={this.clickPopupConfirm}>
        <td>{number > 9 ? number : '0' + number}</td>
        <td>{item.soDen}</td>
        <td>
          {moment(item.ngayCongVan).isValid()
            ? moment(item.ngayCongVan).format('DD/MM/YYYY')
            : ''}
        </td>
        <td>{item.soCongVan ? item.soCongVan : ''}</td>
        <td className="textarea-content">
          {item.trichYeu ? item.trichYeu : ''}
        </td>
        <td>{item.coQuanBanHanh ? item.coQuanBanHanh : ''}</td>
        <td>
          <div className="thong-tin-thu-hoi">
            {item.nguoiDeXuat && (
              <div className="user-box">
                <span className="name">{item.nguoiDeXuat.tenNhanVien}</span>
                <span className="line">-</span>
              </div>
            )}
            {item.ngayTao && moment(item.ngayTao).isValid() && (
              <span className="date">
                {moment(item.ngayTao).format('DD/MM/YYYY')}
              </span>
            )}
            {item.lyDoThuHoi && <p className="content">{item.lyDoThuHoi}</p>}
          </div>
        </td>
        <td>
          <PopupConfirm
            isOpen={this.state.isOpenPopup}
            onClose={this.clickPopupConfirm}
            title={THONG_BAO}
            text={TEXT_BUTTON_SUBMIT}
            isLoading={this.state.isLoadingButton}
            isNotification={true}
            onSubmit={() => {}}
          >
            {THONG_BAO_CONG_VAN_DEN_THU_HOI}
          </PopupConfirm>
        </td>
      </tr>
    )
  }
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
}

export default ListItem
