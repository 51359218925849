import {
  API_REQUEST_ERROR,
  API_REQUEST_SEND,
  AppliedUnit,
  Authority,
  Procedure,
  ProcedureCategory,
  Employee,
  GET_NUMBER_KSTT_PAGE,
  API_REQUEST_SUCCESS,
  SignatureDocument,
} from '../constants/ActionTypes'

import { KSTT_API_URL, CHAT_API_URL } from '../constants/Api'
import _ from 'lodash'
import * as utils from '../constants/Api'
import * as Tool from '../helpers'

export const fetchAllProcedureCategory = filter => (dispatch, getState) => {
  const params = {}
  const textFilter = _.get(filter, 'textFilter', null)
  const activeFilter = _.get(filter, 'activeFilter', null)
  if (!!textFilter && textFilter.trim() !== '') {
    params.textSearch = textFilter.trim()
  }
  if (!!activeFilter) {
    params.isActive = true
  }
  return dispatch({
    types: [API_REQUEST_SEND, ProcedureCategory.COLLECTION, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/danhmucquytrinh',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        params,
      },
    },
  })
}

export const fetchProcedureCategory = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, ProcedureCategory.ITEM, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/danhmucquytrinh/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const createProcedureCategory = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, ProcedureCategory.CREATE, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/danhmucquytrinh',
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data,
      },
    },
  })

export const updateProcedureCategory = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, ProcedureCategory.UPDATE, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/danhmucquytrinh/${data.id}`,
        method: 'PUT',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data,
      },
    },
  })

export const deleteProcedureCategory = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, ProcedureCategory.DELETE, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/danhmucquytrinh/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
    id,
  })

export const sortProcedureCategory = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, ProcedureCategory.SORT, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/danhmucquytrinh/sort',
        method: 'PUT',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data,
      },
    },
  })

export const fetchAllProcedure = filter => (dispatch, getState) => {
  const params = {}
  const textFilter = _.get(filter, 'textFilter', null)
  const selectFilter = _.get(filter, 'selectFilter', null)
  const sortFilter = _.get(filter, 'sortFilter', null)
  const activeFilter = _.get(filter, 'activeFilter', null)
  if (!!textFilter && textFilter.trim() !== '') {
    params.text_search = textFilter.trim()
  }
  if (!!selectFilter && selectFilter !== '-1') {
    params.danh_muc_id = selectFilter
  }
  if (!!sortFilter) {
    params.sort = `${sortFilter.key} ${sortFilter.type}`
  }
  if (!!activeFilter) {
    params.isActive = true
  }
  return dispatch({
    types: [API_REQUEST_SEND, Procedure.COLLECTION, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/quytrinh',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        params,
      },
    },
  })
}

export const deleteProcedure = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, Procedure.DELETE, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/quytrinh/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
    id,
  })

export const createProcedure = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, Procedure.CREATE, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/quytrinh',
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data,
      },
    },
  })

export const fetchAppliedUnits =
  (id, page = 1, getAll = false) =>
  (dispatch, getState) => {
    const params = {}
    if (page) {
      params.page = page
      params.limit = getAll ? 0 : utils.PAGINATION_LIMIT
    }
    return dispatch({
      types: [
        API_REQUEST_SEND,
        getAll ? API_REQUEST_SUCCESS : AppliedUnit.COLLECTION,
        API_REQUEST_ERROR,
      ],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/quytrinh/${id}/donvi`,
          method: 'GET',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
          params,
        },
      },
    })
  }

export const addUnits = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, AppliedUnit.CREATE, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/quytrinh/${id}/donvi`,
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data,
      },
    },
    id,
  })

export const deleteUnit = (id, donViId) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, AppliedUnit.DELETE, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/quytrinh/${id}/donvi/${donViId}`,
        method: 'DELETE',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
    id,
    donViId,
  })

export const fetchProcedure = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, Procedure.ITEM, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/quytrinh/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const fetchProcedureUnit = (id, filter) => (dispatch, getState) => {
  const params = {}
  const textFilter = _.get(filter, 'textFilter', null)
  if (!!textFilter && textFilter.trim() !== '') {
    params.text_search = textFilter.trim()
  }
  return dispatch({
    types: [API_REQUEST_SEND, Authority.COLLECTION, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvi/${id}/quytrinh`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
          'api-version': '2.0',
        },
        params,
      },
    },
  })
}

export const updateProcedure = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, Procedure.UPDATE, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/quytrinh/${data.id}`,
        method: 'PUT',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data,
      },
    },
  })

export const fetchAuthorityGroup = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, Authority.ITEM, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvi/quytrinh/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const addAuthorityGroup = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, Authority.CREATE_GROUP, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvi/quytrinh/${id}/nhom`,
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const addAuthorityMember =
  (groupId, memberList) => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, Authority.ADD_MEMBER, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/nhom/${groupId}/thanhvien`,
          method: 'POST',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
          data: memberList,
        },
      },
    })

export const removeAuthorityMember =
  (groupId, memberId) => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, Authority.REMOVE_MEMBER, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/nhom/${groupId}/thanhvien/${memberId}`,
          method: 'DELETE',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
        },
      },
      groupId,
      memberId,
    })

export const deleteAuthorityGroup =
  (procedureId, groupId) => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, Authority.DELETE_GROUP, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/donvi/quytrinh/${procedureId}/nhom/${groupId}`,
          method: 'DELETE',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
        },
      },
      id: groupId,
    })

export const getDsKyDuyetKSTT =
  (
    filter = {},
    page = 1,
    sort = null,
    canKySo = true,
    limit = utils.PAGINATION_LIMIT,
    refresh = false,
    filterColumn
  ) =>
  (dispatch, getState) => {
    let params = {
      refresh,
      sort,
      page: page,
      limit: limit,
      canKySo: canKySo,
    }
    params = Object.assign(params, Tool.Utils.linkObjectKey(filter))
    params = Object.assign(
      Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
      params
    )

    return dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      page: page,
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: '/api/vanbankyso',
          method: 'GET',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
          params,
        },
      },
    })
  }

export const deleteItemKyDuyetKSTT = (lydo, id) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/vanbankyso/${id}?ly_do_xoa=${lydo}`,
        method: 'DELETE',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const fecthUnitEmployee = unitId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, Employee.COLLECTION, API_REQUEST_ERROR],
    unitId: unitId,
    payload: {
      request: {
        baseURL: CHAT_API_URL,
        url: `/api/donvi/${unitId}/phongban`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const getChiTietKySoTapTrung = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/vanbankyso/${id}`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const kySoTapTrungKhongDongY = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/vanbankyso/${id}/khongdongy`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const kySoTapTrungKyDuyet = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/vanbankyso/${id}/kyduyet`,
        method: 'PATCH',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
        data: data,
      },
    },
  })

export const ctKySoTapTrungFeedbackCreate =
  (id, data) => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/vanbankyso/${id}/gopy`,
          method: 'POST',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const ctKySoTapTrungFeedbackGet = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/vanbankyso/${id}/gopy`,
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const ctKySoTapTrungFeedbackUpdate =
  (id, feedbackId, data) => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/vanbankyso/${id}/gopy/${feedbackId}`,
          method: 'PUT',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const ctKySoTapTrungUpdateHistoryGet =
  (id, vanBanGocId) => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/vanbankyso/${id}/lichsu`,
          method: 'GET',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          params: {
            vanBanGocId: vanBanGocId,
          },
        },
      },
    })

export const getNumberKSTTPage = () => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, GET_NUMBER_KSTT_PAGE, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/vanbankyso/count',
        method: 'GET',
        headers: {
          Authorization:
            getState().auth.token.tokenType +
            ' ' +
            getState().auth.token.accessToken,
        },
      },
    },
  })

export const ctKySoTapTrungAgreeBySIM =
  (id, type = 0, data) =>
  (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/vanbankyso/${id}/kysim?loai=${type}`,
          method: 'PATCH',
          headers: {
            Authorization:
              getState().auth.token.tokenType +
              ' ' +
              getState().auth.token.accessToken,
          },
          data: data,
        },
      },
    })

export const fetchSinatureMember = procedureId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/quytrinh/thanhvien',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        params: { quyTrinhId: procedureId },
      },
    },
  })

export const fetchSignatureMemberGroupByUnit =
  procedureId => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: '/api/quytrinh/thanhvien',
          method: 'GET',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
            'api-version': '2.0',
          },
          params: { quyTrinhId: procedureId },
        },
      },
    })

export const fetchSignatureUnits = procedureId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/quytrinh/donvidtxd',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        params: { quyTrinhId: procedureId },
      },
    },
  })

export const fetchProcedureSignatureUnits = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvi/quytrinh/${id}/donvidtxd`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const addSignatureUnit = (id, signatureUnit) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvi/quytrinh/${id}/donvidtxd`,
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data: signatureUnit,
      },
    },
  })

export const updateSignatureUnit =
  (id, signatureUnit) => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/donvidtxd/${id}`,
          method: 'PUT',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
          data: signatureUnit,
        },
      },
    })

export const removeSignatureUnit = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvidtxd/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const fetchUserProcedure = titleId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, Procedure.COLLECTION, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/nhanvien/${titleId}/quytrinh`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const sendSignatureDocument = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/vanbankyso',
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data,
      },
    },
  })

export const fetchSignatureDocument = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, SignatureDocument.ITEM, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/vanbankyso/${id}/chinhsua`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const updateSignatureDocument = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/vanbankyso/${data.id}`,
        method: 'PUT',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data,
      },
    },
  })

export const fetchProcedureGroup = unitId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvi/${unitId}/quytrinhgroup`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const procedureNameCheck = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/quytrinh/checkma`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        params: data,
      },
    },
  })

export const updateReleaseStatus = documentId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/vanbankyso/${documentId}/phathanh`,
        method: 'PATCH',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const fetchAllProcedureWithGroup = filter => (dispatch, getState) => {
  const params = {}
  const textFilter = _.get(filter, 'textFilter', null)
  const activeFilter = _.get(filter, 'activeFilter', null)
  if (!!textFilter && textFilter.trim() !== '') {
    params.text_search = textFilter.trim()
  }
  if (!!activeFilter) {
    params.isActive = true
  }
  return dispatch({
    types: [API_REQUEST_SEND, Procedure.COLLECTION_GROUP, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: '/api/quytrinh',
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
          'api-version': '2.0',
        },
        params,
      },
    },
  })
}

export const toggleUsingProcedure = data => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/quytrinh/${data.id}/trangthai`,
        method: 'PATCH',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        params: {
          is_active: data.is_active,
        },
      },
    },
  })

export const confirmDispatch = (id, data) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/vanbankyso/${id}/xacnhan`,
        method: 'PATCH',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data,
      },
    },
  })

export const fetchThongKeKstt =
  ({ filter = {} }) =>
  (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/vanbankyso/thongke`,
          method: 'GET',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
          params: filter,
        },
      },
    })

export const timKiemVbks =
  (allFilter = {}) =>
  (dispatch, getState) => {
    const { filter, sort, page, filterColumn } = allFilter

    const params = {
      sort,
      page: page,
      limit: utils.PAGINATION_LIMIT,
      ...Tool.Utils.linkObjectKey({ filter }),
      ...Tool.Utils.linkObjectKey({ filter_column: filterColumn }),
    }

    return dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/vanbankyso/timkiem`,
          method: 'GET',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
          params,
        },
      },
    })
  }

export const fetchEmployeeByRole = (unitId, role) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvi/${unitId}/nhanvienvaitrolist`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        params: {
          dsVaiTro: role,
        },
      },
    },
  })

export const fetchEmployeeByRoleInDepartment =
  (departmentId, role) => (dispatch, getState) =>
    dispatch({
      types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
      payload: {
        request: {
          baseURL: KSTT_API_URL,
          url: `/api/phongban/${departmentId}/nhanvienvaitrolist`,
          method: 'GET',
          headers: {
            Authorization: `${getState().auth.token.tokenType} ${
              getState().auth.token.accessToken
            }`,
          },
          params: {
            dsVaiTro: role,
          },
        },
      },
    })

export const addGroupFile = (groupId, file) => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/nhom/${groupId}/file`,
        method: 'POST',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        data: file,
      },
    },
  })

export const removeGroupFile = groupId => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/nhom/${groupId}/file`,
        method: 'DELETE',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const getDanhSachNhanVienDonVi = id => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        baseURL: KSTT_API_URL,
        url: `/api/donvi/${id}/phongban`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
      },
    },
  })

export const fetchReadDepartment = () => (dispatch, getState) =>
  dispatch({
    types: [API_REQUEST_SEND, API_REQUEST_SUCCESS, API_REQUEST_ERROR],
    payload: {
      request: {
        url: `/api/phongban`,
        method: 'GET',
        headers: {
          Authorization: `${getState().auth.token.tokenType} ${
            getState().auth.token.accessToken
          }`,
        },
        params: {
          page: 1,
          limit: 0,
          isAll: false,
        },
      },
    },
  })
