import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useCallback,
  Fragment,
} from 'react'
import { Button, InputGroup } from '@blueprintjs/core'
import { Icon } from 'components/newCommon'
import { get, isEmpty } from 'lodash'
import csx from 'classnames'

const SearchInput = ({
  value: inputValue = '',
  onChange: inputChange = () => {},
  onSearch: inputSearch = () => {},
  onClear: inputClear = () => {},
  leftIcon = null,
  ...props
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const [text, setText] = useState(inputValue)

  const onChange = useCallback(
    e => {
      const { value } = e.target
      mountedSet(setText, value)
      inputChange(value)
    },
    [inputChange]
  )

  const onSearch = useCallback(
    e => {
      inputSearch(text)
    },
    [text, inputSearch]
  )

  const onKeyPress = useCallback(
    e => {
      const { key } = e
      if (key === 'Enter') {
        onSearch()
      }
    },
    [onSearch]
  )

  const onClear = useCallback(() => {
    mountedSet(setText, '')
    inputClear()
  }, [inputClear])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    mountedSet(setText, inputValue)
  }, [inputValue])

  return (
    <InputGroup
      {...props}
      className={csx('cpc-input-search bg-white', props.className, {
        'cpc-input-icon': leftIcon,
      })}
      maxLength={props.maxLength || 255}
      value={text}
      onChange={onChange}
      onKeyPress={onKeyPress}
      leftIconName={leftIcon ? csx(` ${leftIcon}`, 'absolute-left-icon') : null}
      rightElement={
        <Fragment>
          {!isEmpty(text) && (
            <i
              className={csx('mr10', 'icon-close', 'size-icon-14', 'has-event')}
              onClick={onClear}
            />
          )}
          <Button className={csx('cpc-button', 'green')} onClick={onSearch}>
            <Icon classIcon="icon2 icon2-search" />
          </Button>
        </Fragment>
      }
    />
  )
}

export default memo(SearchInput)
