import React, { Component } from 'react'
import moment from 'moment'
import FileDownload from 'file-saver'
import { Button, Intent } from '@blueprintjs/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { MasterLayout } from '../../components/layout'
import { GridView } from '../../components/common'
import {
  ListFilter,
  ListItem,
} from '../../components/Utilities/StatisticAlertMistake'
import * as Actions from '../../actions'
import * as Tool from '../../helpers'
import { headers } from '../../constants/Table'
import HeadingPanel from 'components/common/HeadingPanel'

class ListStatistiAlertMistakePage extends Component {
  constructor(props) {
    super(props)

    this.submitFilter = this.submitFilter.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleClickGoBack = this.handleClickGoBack.bind(this)

    const id =
      this.props.match && this.props.match.params && this.props.match.params.id
    const type =
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.type
    this.state = {
      isShowMessengStatistics: true,
      isLoading: false,
      isEmpty: false,
      isError: false,
      isExporting: false,
      filter: {
        startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      page: 1,
      id,
      type,
    }
  }

  async componentDidMount() {
    let state = this.state
    let preData = Tool.Utils.preData(
      'ListStatisticAlertMistakes',
      null,
      'thong-ke-bao-nham'
    )

    if (preData) {
      state.filter = preData.filter
      await this.setState(state)
    }

    if (this.props.location && this.props.location.state) {
      let state = this.state
      const { isCheck } = this.props.location.state
      if (isCheck) {
        this.submitFilter(state.filter, state.page)
      }
    }

    let isSubmitStatisticAlertMistakes = Tool.Utils.getData(
      'isSubmitStatisticAlertMistakes'
    )
    if (isSubmitStatisticAlertMistakes) {
      Tool.Utils.saveData('isSubmitStatisticAlertMistakes', false)
      this.props.history.replace({
        state: {
          isCheck: true,
        },
      })
      this.submitFilter(this.state.filter, this.state.page)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match !== this.props.match
    ) {
      this.setState({
        id: nextProps.match.params.id,
        type: nextProps.match.params.type,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id || prevState.type !== this.state.type) {
      this.submitFilter(this.state.filter, this.state.page)
    }
  }

  submitFilter(e = {}) {
    const { type, id } = this.state
    const filter = Object.assign({}, this.state.filter, e)
    let filterSubmit = {
      ...filter,
    }
    if (type === 'don-vi') {
      filterSubmit = {
        ...filterSubmit,
        donViId: this.state.id,
      }
    }
    if (type === 'phong-ban') {
      filterSubmit = {
        ...filterSubmit,
        phongBanId: this.state.id,
      }
    }
    let request
    if (!type && !id) {
      request = this.props.actions.getStatisticAlertMistakesUnit
    }
    if (type === 'don-vi' && id) {
      request = this.props.actions.getStatisticAlertMistakesDepartment
    }
    if (type === 'phong-ban' && id) {
      request = this.props.actions.getStatisticAlertMistakesPersonal
    }

    this.setState(
      { filter, isLoading: true, isShowMessengStatistics: false },
      () => {
        Tool.Utils.preData(
          'ListStatisticAlertMistakes',
          { filter },
          'thong-ke-bao-nham'
        )
        request(filterSubmit, this.props.config.refreshStatistic)
          .then(res => {
            let isEmpty = false
            let isError = false
            if (
              !res.error &&
              res.payload &&
              res.payload.data &&
              res.payload.data.result &&
              res.payload.data.result.total === 0
            ) {
              isEmpty = true
            }
            if (res.error || !res.payload || res.payload.status !== 200) {
              isError = false
            }
            this.setState({ isEmpty, isError })
          })
          .finally(() => {
            this.props.actions.refreshStatistic(true)
            this.setState({ isLoading: false })
          })
      }
    )
  }

  handleExport() {
    let { filter, id, type } = this.state
    if (type === 'don-vi' && id) {
      filter = {
        ...filter,
        donViId: id,
      }
    }
    if (type === 'phong-ban' && id) {
      filter = {
        ...filter,
        phongBanId: id,
      }
    }
    let request
    if (!type && !id) {
      request = this.props.actions.exportStatisticAlertMistakesUnit
    }
    if (type === 'don-vi' && id) {
      request = this.props.actions.exportStatisticAlertMistakesDepartment
    }
    if (type === 'phong-ban' && id) {
      request = this.props.actions.exportStatisticAlertMistakesPersonal
    }
    if (!request) {
      return
    }
    this.setState({ isExporting: true }, () => {
      request(filter)
        .then(res => {
          if (res.error || !res.payload || !res.payload.data) {
            return this.props.actions.commonAddToasterMessage({
              message: 'Không xuất được file.',
              intent: Intent.DANGER,
            })
          }
          let preData = Tool.Utils.getData('ListStatisticAlertMistakes')
          FileDownload.saveAs(
            res.payload.data,
            `thong-ke-bao-nham-${moment(preData.filter.startDate).format(
              'L'
            )}-${moment(preData.filter.endDate).format('L')}.xls`
          )
        })
        .finally(() => {
          this.setState({ isExporting: false })
        })
    })
  }

  handleClickGoBack = async () => {
    await this.props.actions.refreshStatistic(false)
    Tool.Utils.saveData('isSubmitStatisticAlertMistakes', true)
    this.props.history && this.props.history.goBack()
  }

  render() {
    const {
      isShowMessengStatistics,
      isLoading,
      isEmpty,
      isError,
      isExporting,
      filter,
      page,
      type,
      id,
    } = this.state
    let headerAlertMistakes = headers.statisticAlertMistakes
    if (type === 'phong-ban' && id) {
      headerAlertMistakes = headers.statisticAlertMistakesPersonal
    }
    return (
      <MasterLayout typeSidebar={this.props.typeSidebar}>
        <div className="page-utilities-container">
          <HeadingPanel
            goBack={this.state.id ? this.handleClickGoBack : null}
            leftTitle="Thống kê báo nhầm"
            leftIcon="icon-Tong_Hop"
            rightElement={
              <Button
                rightIconName="pt-icon icon-In_So_Cong_Van"
                className="btn-action btn-top-heading mb10"
                text="Xuất excel"
                onClick={this.handleExport}
                loading={isExporting}
                disabled={isShowMessengStatistics || isLoading || isEmpty}
              />
            }
          />
          {/* Filter */}
          <div className="page-list-container">
            <ListFilter
              onSubmit={this.submitFilter}
              filter={filter}
              actions={this.props.actions}
              isLoading={isLoading}
              isForChild={typeof this.state.id === 'string'}
            />
            {/* Table List */}
            <div className="table-container list-GeneralDocumentary">
              <GridView
                classNames={'pt-table'}
                page={page}
                headers={headerAlertMistakes}
                itemShape={ListItem}
                data={this.props.statisticAlertMistakes.alertMistakes.list}
                isEmpty={
                  isEmpty ||
                  this.props.statisticAlertMistakes.alertMistakes.total === 0
                }
                isLoading={isLoading}
                isError={isError}
                isShowMessengStatistics={isShowMessengStatistics}
                auth={this.props.auth}
                filter={filter}
                history={this.props.history}
                actions={this.props.actions}
              />
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

  statisticAlertMistakes: state.statisticAlertMistakes,

  typeSidebar: state.common.typeSidebar,

  readOnly: state.common.readOnly,

  config: state.common.config,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListStatistiAlertMistakePage)
