import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Intent } from '@blueprintjs/core'
import { get, isEmpty } from 'lodash'

import { MasterLayout } from '../../../components/layout'
import {
  ChinhSuaTaiLieu,
  DetailBoxAction,
  SendMail,
  ThongTinTaiLieu,
} from '../../../components/tutailieu'
import * as Actions from '../../../actions'
import * as Tool from '../../../helpers'
import * as MESSAGE from '../../../constants/MessageForm'
import * as Types from '../../../constants/Api'
import { HeadingPage, DetailFileContentV2 } from 'components/common'

class ChiTietTaiLieu extends Component {
  refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
    detailFileContent: (ref: DetailFileContent) =>
      (this.detailFileContent = ref),
  }

  constructor(props) {
    super(props)
    let type = this.switchType()
    let stateLocation = {}
    if (
      props.location &&
      props.location.state &&
      props.location.state.data &&
      props.location.state.data.nguoiChiuTrachNhiem
    ) {
      stateLocation.nguoiChiuTrachNhiem =
        props.location.state.data.nguoiChiuTrachNhiem
    }
    if (
      props.location &&
      props.location.state &&
      props.location.state.data &&
      props.location.state.data.dsNguoiPhoiHop
    ) {
      stateLocation.dsNguoiPhoiHop = props.location.state.data.dsNguoiPhoiHop
    }
    if (
      props.location &&
      props.location.state &&
      props.location.state.data &&
      props.location.state.data.trangThai !== ''
    ) {
      stateLocation.trangThai = props.location.state.data.trangThai
    }

    this.state = {
      id: this.props.match.params.id,
      type,
      data: {},
      showResults: false,
      showListAction: true,
      showSection: '',
      showDetailInfo: false,
      isRedirect: false,
      redirectTo: null,
      isLoadingData: {
        documentsCabinet: true,
      },
      boxMenuFixTop: false,
      path: '',
      isGettingPath: false,
      stateLocation,
    }
    this.handleClickThongTinTaiLieu = this.handleClickThongTinTaiLieu.bind(this)
    this.handleSaveItem = this.handleSaveItem.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.sendToChat = this.sendToChat.bind(this)
  }

  switchType = () => {
    switch (this.props.match.params.type) {
      case 'ca-nhan':
        return Types.CABINET_PERSONAL
      case 'phong-ban':
        return Types.CABINET_DEPARTMENT
      default:
        return Types.CABINET_UNIT
    }
  }

  componentDidMount() {
    this.getDetail()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getDetail = async () => {
    this.setState({
      isLoadingData: true,
    })
    try {
      const id = this.state.id
      await this.props.actions.getChiTietTaiLieuHoSoCongViec(id).then(res => {
        if (res && res.payload && res.payload.data && res.payload.data.result) {
          this.setState({
            data: res.payload.data.result,
          })
        }
      })
    } catch (error) {
      this.goBack()
    } finally {
      this.setState({
        isLoadingData: false,
      })
    }
  }

  handleClickGoBack = e => {
    e.preventDefault()
    this.setState({
      showResults: false,
      showListAction: true,
      showSection: '',
    })
  }

  handleClickSendMail = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'GuiEmail',
    })
  }

  handleClickChinhSuaTaiLieu = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'SuaTuTaiLieu',
    })
  }

  handleClickThongTinTaiLieu = e => {
    e.preventDefault()
    ReactDOM.findDOMNode(this).scrollIntoView({ behavior: 'smooth' })
    this.setState({
      showResults: true,
      showListAction: false,
      showSection: 'ThongTinTaiLieu',
    })
  }

  sendToChat(e) {
    e.preventDefault()
    let { data } = this.state
    if (data) {
      const { auth } = this.props
      let fileNoiDung = data.fileNoiDung ? data.fileNoiDung.fileId : ''
      let infoGetFileDinhKem = data.id
      let infoGetFileDinhKemType = 'TAILIEU_HSCV'
      const url =
        Types.FE_CHAT_URL +
        `?accessToken=${auth.token.accessToken}&expiresIn=${
          auth.token.expiresIn
        }
      &tokenType=${auth.token.tokenType}&refreshToken=${auth.token.refreshToken}
      &fileId=${fileNoiDung.toUpperCase()}&infoGetFileDinhKem=${infoGetFileDinhKem.toUpperCase()}&infoGetFileDinhKemType=${infoGetFileDinhKemType}`
      window.open(url, '_blank')
    }
  }

  handleSaveItem(data) {
    if (!data.tenTaiLieu) {
      return this.props.actions.commonAddToasterMessage({
        message: MESSAGE.TEXT_MESSAGE_DEFAULT,
        intent: Intent.DANGER,
      })
    }
    data = Tool.Utils.linkObjectKey(data)
    const id = data.id
    let request = this.props.actions.putChinhSuaTaiLieuHoSoCongViec(id, data)
    request
      .then(res => {
        if (res && res.error) {
          return this.props.actions.commonAddToasterMessage({
            message: MESSAGE.TOATS_MESSAGE_FAIL,
            intent: Intent.DANGER,
          })
        }
        this.setState(
          {
            showResults: false,
            showListAction: true,
            showSection: '',
          },
          () => {
            this.props.actions.commonAddToasterMessage({
              message: MESSAGE.TOATS_MESSAGE_SUCCESS,
              intent: Intent.SUCCESS,
            })
          }
        )
      })
      .then(this.getDetail)
  }

  goBack() {
    const prevPath = get(this.props, 'location.state.prevPath')
    const store = get(this.props, 'location.state.store')

    if (store) {
      Tool.Utils.saveData(store.key, store.value)
    }

    if (prevPath) {
      return this.props.history.push(prevPath)
    }

    const { data } = this.state
    if (!get(data, 'hoSoCongViecId')) {
      return
    }

    this.props.history.push({
      pathname: `/ho-so-cong-viec/chi-tiet/${
        this.props.match.params.type
      }/${get(data, 'hoSoCongViecId')}`,
      state: {
        type: this.state.type,
      },
    })
  }

  handleScroll(event) {
    let scrollTop = window.pageYOffset
    if (scrollTop > 160) {
      this.setState({
        boxMenuFixTop: true,
      })
    } else {
      this.setState({
        boxMenuFixTop: false,
      })
    }
  }

  handleSubmit = (title, msg, intent, isSuccess, id = 0) => {
    this.props.actions.commonAddToasterMessage({
      message: msg,
      intent: intent,
    })
    if (isSuccess) {
      this.props.actions.refreshPage(false)
      this.goBack()
    }
  }

  render() {
    let { type, data, stateLocation } = this.state
    return (
      <MasterLayout collapseSideBar={true} typeSidebar="documentsCabinet">
        <div className="detail-receive-document page-DetailDocumentsCabinet">
          <div className="dispatch-code">
            <button
              onClick={this.goBack.bind(this)}
              type="button"
              className={classnames(
                'pt-button pt-button-back button-back-fixed',
                {
                  'fixed-button-back':
                    this.state.boxMenuFixTop &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  'fixed-button-hasSidebar':
                    this.state.boxMenuFixTop &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                }
              )}
            >
              <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
              <span className="text-content">Quay lại</span>
            </button>
          </div>
          <HeadingPage
            namePage={get(data, 'tenTaiLieu')}
            iconPage="icon-Tu_Tai_Lieu"
          />
          <div className="detail-container">
            <div className="tabs-detail-page">
              <div className="pt-tabs tab-cus-container pd-none">
                <ul className="pt-tab-list" role="tablist">
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_UNIT,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_UNIT}
                  >
                    TTL ĐƠN VỊ
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_DEPARTMENT,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_DEPARTMENT}
                  >
                    TTL PHÒNG BAN
                  </li>
                  <li
                    className={classnames('pt-tab cursor-default', {
                      'pt-tab-color-cabinet': type !== Types.CABINET_PERSONAL,
                    })}
                    role="tab"
                    aria-selected={type === Types.CABINET_PERSONAL}
                  >
                    TTL CÁ NHÂN
                  </li>
                </ul>
              </div>
            </div>
            <div className="wrapper-box-container row-detail-container clearfix">
              <div className="box-left">
                {!isEmpty(data.fileNoiDung) && (
                  <DetailFileContentV2
                    auth={this.props.auth}
                    actions={this.props.actions}
                    file={data.fileNoiDung}
                    bufferHeight={80}
                  />
                )}
              </div>
              <div
                className={classnames('box-right', {
                  fixtopMenuRight:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    !this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                  fixtopMenuRightHasSideBar:
                    this.state.boxMenuFixTop &&
                    !this.state.showResults &&
                    this.props.enableScrollFixedMenu &&
                    window.innerWidth >= 1024,
                })}
              >
                <div className="box-action-detail full-box-content">
                  <div className="content-action-click" ref="boxAction">
                    {this.state.showListAction && (
                      <DetailBoxAction
                        auth={this.props.auth}
                        action={this.props.actions}
                        data={data || {}}
                        onClickSendMail={this.handleClickSendMail}
                        onHandleSubmit={this.handleSubmit}
                        onClickChinhSuaTaiLieu={this.handleClickChinhSuaTaiLieu}
                        onClickThongTinTaiLieu={this.handleClickThongTinTaiLieu}
                        sendToChat={this.sendToChat}
                        stateLocation={stateLocation}
                        type={this.state.type}
                      />
                    )}
                    {this.state.showResults &&
                      this.state.showSection === 'SuaTuTaiLieu' && (
                        <ChinhSuaTaiLieu
                          actions={this.props.actions}
                          data={data || {}}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSaveItem}
                        />
                      )}
                    {this.state.showResults &&
                      this.state.showSection === 'ThongTinTaiLieu' && (
                        <div className="box-action-item">
                          <ThongTinTaiLieu
                            auth={this.props.auth}
                            data={data || {}}
                            path={this.state.path}
                            onClickGoBack={this.handleClickGoBack}
                          />
                        </div>
                      )}
                    {this.state.showResults &&
                      this.state.showSection === 'GuiEmail' && (
                        <SendMail
                          action={this.props.actions}
                          auth={this.props.auth}
                          onClickGoBack={this.handleClickGoBack}
                          onSubmit={this.handleSubmit}
                          tuTaiLieuData={data}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MasterLayout>
    )
  }
}

ChiTietTaiLieu.propTypes = {
  actions: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
  enableScrollFixedMenu: state.common.enableScrollFixedMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChiTietTaiLieu)
