import React, { Component } from 'react'
import { Button, Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import * as Tool from '../../../helpers'

class ListFilter extends Component {
  static defaultProps = {
    filter: {
      ngay_giao: {
        to: '',
        from: '',
      },
      nguoi_giao_id: '',
      text_search: '',
    },
  }

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChangeRaw = this.handleChangeRaw.bind(this)
    this.focousOut = this.focousOut.bind(this)
  }

  state = {
    isOpen: false,
    filter: {
      text_search: '',
      ngay_giao: {
        from: '',
        to: '',
      },
      nguoi_giao_id: '',
      trang_thai: 2,
    },
    dateFromToString: '',
    dateToToString: '',
  }

  componentWillReceiveProps(props) {
    if (props.filter) {
      this.setState({
        filter: {
          text_search: props.filter.text_search || '',
          ngay_giao: {
            from:
              props.filter.ngay_giao &&
              props.filter.ngay_giao.from &&
              props.filter.ngay_giao.from.trim().length > 0
                ? moment(props.filter.ngay_giao.from)
                : '',
            to:
              props.filter.ngay_giao &&
              props.filter.ngay_giao.to &&
              props.filter.ngay_giao.to.trim().length > 0
                ? moment(props.filter.ngay_giao.to)
                : '',
          },
          nguoi_giao_id: props.filter.nguoi_giao_id || '',
          trang_thai: props.filter.trang_thai || 2,
        },
        dateFromToString:
          props.filter.ngay_giao &&
          props.filter.ngay_giao.from &&
          props.filter.ngay_giao.from.trim().length > 0
            ? moment(props.filter.ngay_giao.from)
            : '',
        dateToToString:
          props.filter.ngay_giao &&
          props.filter.ngay_giao.to &&
          props.filter.ngay_giao.to.trim().length > 0
            ? moment(props.filter.ngay_giao.to)
            : '',
      })
    }
  }

  clearFilter(e) {
    const filter = {
      text_search: '',
      ngay_giao: {
        from: '',
        to: '',
      },
      nguoi_giao_id: '',
      trang_thai: 2,
    }

    this.setState({
      filter,
      dateFromToString: '',
      dateToToString: '',
    })
    this.props.onSubmit && this.props.onSubmit(filter)
    this.props.actions.getNumberWorkPage()
  }

  handleDateChange(name, pos, nameToString, e) {
    let value = ''
    if (e) {
      value = moment(e).format('DD/MM/YYYY')
    }

    this.setState({
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: e ? e : undefined,
        },
      },
      [nameToString]: value,
    })
  }

  handleChangeRaw(name, value) {
    this.setState({ [name]: value })
  }

  focousOut(name, pos, nameToString, value) {
    if (
      pos === 'from' &&
      !this.state.filter[name].to &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'from' &&
      this.state.filter[name].to &&
      Tool.Utils.isValidDate(value, {
        before: {
          date: this.state.filter[name].to,
        },
      })
    ) {
      return
    }

    if (
      pos === 'to' &&
      !this.state.filter[name].from &&
      Tool.Utils.isValidDate(value)
    ) {
      return
    }

    if (
      pos === 'to' &&
      this.state.filter[name].from &&
      Tool.Utils.isValidDate(value, {
        after: {
          date: this.state.filter[name].from,
        },
      })
    ) {
      return
    }

    this.setState({
      [nameToString]: '',
      filter: {
        ...this.state.filter,
        [name]: {
          ...this.state.filter[name],
          [pos]: undefined,
        },
      },
    })
  }

  handleInputChange(e) {
    if (e.target.type === 'text') {
      this.setState({
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      })
    }

    if (e.target.name === 'nguoi_giao_id') {
      this.setState({
        filter: {
          ...this.state.filter,
          [e.target.name]: e.target.value,
        },
      })
    }

    if (e.target.name === 'trang_thai') {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            [e.target.name]: Number(e.target.value),
          },
        },
        () => {
          this.handleSubmit()
        }
      )
    }
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngay_giao: {
        from: this.state.filter.ngay_giao.from
          ? moment(this.state.filter.ngay_giao.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngay_giao.to
          ? moment(this.state.filter.ngay_giao.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmit && this.props.onSubmit(filter)
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <div
        className={classnames('search-container search-work', {
          'search-work-other': this.state.isShowInput,
        })}
      >
        <form className="form-search-basic" onSubmit={this.handleSubmit}>
          <div className="seach-form">
            <div className="row end-xs">
              <div className="col-xs-12 col-md-12 search-header-right-content form-container">
                <div className="search-header-group">
                  <div className="search-filter-select">
                    <div className="select-filter">
                      <div className="group-select group-select-inline">
                        <label className="pt-label-inline">
                          <span className="text-label"> Trạng thái </span>
                        </label>
                        <div className="pt-select pt-select-inline">
                          <select
                            name="trang_thai"
                            onChange={this.handleInputChange}
                            value={this.state.filter.trang_thai}
                          >
                            <option value={2}>Tất cả</option>
                            <option value={0}>Chưa đọc</option>
                            <option value={1}>Đã đọc</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="filter-search-list">
                      <div className="search-keyword">
                        <input
                          autoFocus={false}
                          autoComplete="off"
                          name="text_search"
                          className="search"
                          type="text"
                          placeholder="Tìm số cv, trích yếu (hoặc số cv:trích yếu)..."
                          onChange={this.handleInputChange}
                          value={this.state.filter.text_search}
                        />
                      </div>
                    </div>
                    <div className="btn-reload-group">
                      <Button className="btn-search" type="submit">
                        <span className="pt-icon pt-icon-search"></span>
                      </Button>
                      <Button
                        type="button"
                        className="btn-reload"
                        onClick={this.clearFilter}
                      >
                        <span className="icon-Loading"></span>
                      </Button>
                      <Button
                        type="button"
                        className="btn-toogle"
                        onClick={this.handleClick}
                      >
                        <span
                          className={classnames('pt-icon', {
                            'pt-icon-double-chevron-up': this.state.isOpen,
                            'pt-icon-double-chevron-down': !this.state.isOpen,
                          })}
                        ></span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Collapse isOpen={this.state.isOpen}>
            <div className="search-advanced">
              <div className="row end-xs">
                <div className="group-select">
                  <label className="pt-label">
                    Người giao
                    <div className="pt-select">
                      <select
                        name="nguoi_giao_id"
                        onChange={this.handleInputChange}
                        value={this.state.filter.nguoi_giao_id}
                      >
                        <option value="">Tất cả</option>
                        {this.props.listPersonal.map((item, i) => (
                          <option key={i} value={item.chucDanhId}>
                            {item.tenNhanVien}
                          </option>
                        ))}
                      </select>
                    </div>
                  </label>
                </div>
                <div className="group-datetime">
                  <label className="pt-label label-datepicker">Ngày giao</label>
                  <div className="daterangepicker-group">
                    <DatePicker
                      isClearable={this.state.dateFromToString ? true : false}
                      readOnly={this.props.readOnly}
                      selected={this.state.filter.ngay_giao.from}
                      selectsStart
                      startDate={this.state.filter.ngay_giao.from}
                      endDate={this.state.filter.ngay_giao.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'ngay_giao',
                        'from',
                        'dateFromToString'
                      )}
                      value={this.state.dateFromToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateFromToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'ngay_giao',
                          'from',
                          'dateFromToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Từ ngày"
                      maxDate={this.state.filter.ngay_giao.to}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                    <DatePicker
                      isClearable={this.state.dateToToString ? true : false}
                      readOnly={this.props.readOnly}
                      selected={this.state.filter.ngay_giao.to}
                      selectsEnd
                      startDate={this.state.filter.ngay_giao.from}
                      endDate={this.state.filter.ngay_giao.to}
                      onChange={this.handleDateChange.bind(
                        this,
                        'ngay_giao',
                        'to',
                        'dateToToString'
                      )}
                      value={this.state.dateToToString}
                      onChangeRaw={e =>
                        this.handleChangeRaw('dateToToString', e.target.value)
                      }
                      onBlur={e =>
                        this.focousOut(
                          'ngay_giao',
                          'to',
                          'dateToToString',
                          e.target.value
                        )
                      }
                      format="DD/MM/YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="input-datepicker input-icon-datepicker"
                      placeholderText="Đến ngày"
                      minDate={this.state.filter.ngay_giao.from}
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: '5px, 10px',
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: 'viewport',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </form>
      </div>
    )
  }
}

export default ListFilter
