import React, {
  memo,
  useCallback,
  useRef,
  useState,
  useEffect,
  Fragment,
} from 'react'
import { Text, Button, Tooltip, Intent } from '@blueprintjs/core'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { get, size, groupBy, find, isEmpty as isEmptyLodash } from 'lodash'
import { withCookies } from 'react-cookie'
import csx from 'classnames'
import moment from 'moment'

import { MasterLayout } from 'components/layout'
import {
  Card,
  UserCard,
  Icon,
  Empty,
  Badge,
  InfoCard,
  Loading,
} from 'components/newCommon'
import { Tabs, CircleLoader } from 'components/newCommon2'
import { STATUS_NOTIFICATION, TASK, WORK_TYPE } from 'constants/Enum'

import {
  getDsThongBao,
  markAsRead,
  markAllRead,
  getNotificationCounter,
  getNotificationDetailCounter,
  getNavigationNotificationV2,
  showToast,
} from 'actions/task'
import { getTimeToText } from 'helpers/Helper'
import { Action, Toast, CONG_VIEC_DA_XOA } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import Title from 'components/title'

const dateFormat = 'DD/MM/YYYY'
const tabKeys = {
  general: 'GENERAL',
  reminder: 'REMINDER',
}

const getDateToText = date => {
  const now = moment()
  const days = now.diff(moment(date, dateFormat), 'days')
  if (days === 0) {
    return 'Hôm nay'
  }
  if (days === 1) {
    return 'Hôm qua'
  }

  return date
}

const trimHtmlText = content => {
  let regExr = /<b>([^\\<]*?)<\/b>/g
  return content.replace(regExr, '').trim()
}

const Notification = ({ history, allCookies, auth }) => {
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)

  const [activeTab, setActiveTab] = useState(tabKeys.general)
  const [announcements, setAnnouncements] = useState([])
  const [unreadCounter, setUnreadCounter] = useState({})

  const [fetching, setFetching] = useState(false)
  const [submitDisable, setSubmitDisable] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [isEmpty, setIsEmpty] = useState(false)
  const [firstLoading, setFirstLoading] = useState(true)

  const _groupNotifications = useCallback(list => {
    return groupBy(list, ({ creationTime }) =>
      moment(creationTime).format(dateFormat)
    )
  }, [])

  const _fecthNotifications = useCallback((value, currentPage) => {
    requestAction({
      showToast: false,
      codeCheck: false,
      getResult: false,
      beforeAction: () => {
        _mountedSet(setFetching, true)
        _mountedSet(setIsEmpty, false)
      },
      action: () =>
        getDsThongBao({
          page: currentPage,
          module: value,
        }),
      afterResponse: ({ items = [], totalCount = 0 }) => {
        _mountedSet(setAnnouncements, prev => [...prev, ...items])
        _mountedSet(setHasNextPage, currentPage * 10 < totalCount)
        _mountedSet(setCurrentPage, prev => prev + 1)
        _mountedSet(setFirstLoading, false)
        _mountedSet(setIsEmpty, !totalCount)
      },
      afterAction: () => _mountedSet(setFetching, false),
    })
  }, [])

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: fetching,
    hasNextPage: hasNextPage,
    rootMargin: '0px 0px 200px 0px',
    onLoadMore: () => _fecthNotifications(activeTab, currentPage),
  })

  const goToDetail = async (id, refId, key, senderId) => {
    if (!id || !refId) {
      return
    }

    const hasRead = get(find(announcements, { id }), 'isRead')
    if (!hasRead) {
      requestAction({
        showToast: false,
        getResult: false,
        action: () => markAsRead(id),
        afterResponse: () => {
          getNotificationCounter()
        },
      })
    }

    _mountedSet(setAnnouncements, prev =>
      prev?.map(elm => {
        if (elm?.id === id) {
          return {
            ...elm,
            isRead: true,
          }
        }

        return elm
      })
    )

    if (
      key === STATUS_NOTIFICATION.XOA_CONG_VIEC ||
      key === STATUS_NOTIFICATION.HUY_CONG_VIEC ||
      key === STATUS_NOTIFICATION.XOA_GIAO_VIEC
    ) {
      return showToast({
        message: CONG_VIEC_DA_XOA,
        intent: Intent.SUCCESS,
      })
    }

    await requestAction({
      showToast: false,
      action: () =>
        getNavigationNotificationV2({
          workItemId: refId,
          senderId: senderId,
          keyNotification: key,
        }),
      afterResponse: result => {
        if (
          isEmptyLodash(result) ||
          (result && !result?.responsibilityFilter)
        ) {
          return showToast({
            message: CONG_VIEC_DA_XOA,
            intent: Intent.SUCCESS,
          })
        }

        if (result?.responsibilityFilter === TASK.PHONGBAN_DAGIAO) {
          return history.push(
            `/quan-ly-cong-viec/phong-ban/da-giao/chi-tiet/${result?.workItemId}`
          )
        } else if (result?.responsibilityFilter === TASK.PHONGBAN_CHUAGIAO) {
          return history.push(
            `/quan-ly-cong-viec/phong-ban/chua-giao/chi-tiet/${result?.workItemId}`
          )
        } else if (result?.responsibilityFilter === TASK.CANHAN_DUOCGIAO) {
          return history.push(
            `/quan-ly-cong-viec/ca-nhan/can-thuc-hien/chi-tiet/${result?.workItemId}`
          )
        } else if (result?.responsibilityFilter === TASK.CANHAN_DAGIAO) {
          return history.push(
            `/quan-ly-cong-viec/ca-nhan/da-giao/chi-tiet/${result?.workItemId}`
          )
        } else if (result?.responsibilityFilter === TASK.CANHAN_THEODOI) {
          return history.push(
            `/quan-ly-cong-viec/ca-nhan/theo-doi/chi-tiet/${result?.workItemId}`
          )
        } else if (result?.responsibilityFilter === WORK_TYPE.MEETING) {
          return history.push(
            `/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop/ket-luan/chi-tiet/${result?.workItemId}`
          )
        }
      },
    })
  }

  const tabs = [
    {
      key: tabKeys.general,
      title: (
        <>
          <Icon classIcon="icon-list" style={{ marginRight: 8 }} />
          Công việc chung
          <Badge
            small
            className="ml10"
            count={unreadCounter[tabKeys.general]}
          />
        </>
      ),
    },
    {
      key: tabKeys.reminder,
      title: (
        <>
          <Icon classIcon="icon-bell" style={{ marginRight: 8 }} />
          Nhắc nhở công việc
          <Badge
            small
            className="ml10"
            count={unreadCounter[tabKeys.reminder]}
          />
        </>
      ),
    },
  ]

  const changeTab = key => {
    _mountedSet(setCurrentPage, 1)
    _mountedSet(setAnnouncements, [])
    _mountedSet(setIsEmpty, false)
    _mountedSet(setFirstLoading, true)
    _mountedSet(setHasNextPage, true)
    _mountedSet(setActiveTab, key)
    _fecthNotifications(key, 1)
  }

  const onReadAll = () => {
    requestAction({
      beforeAction: () => {
        _mountedSet(setSubmitDisable, true)
      },
      action: () =>
        markAllRead({
          worksModule: activeTab,
        }),
      afterResponse: () => {
        _mountedSet(setUnreadCounter, prev => ({
          ...prev,
          [activeTab]: 0,
        }))
        getNotificationCounter()
        _mountedSet(setAnnouncements, prevAnnouncements =>
          prevAnnouncements.map(elm => ({
            ...elm,
            isRead: true,
          }))
        )
      },
      successCode: 200,
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      afterAction: () => {
        _mountedSet(setSubmitDisable, false)
      },
    })
  }

  const _fecthNotificationCounter = useCallback(() => {
    requestAction({
      showToast: false,
      action: getNotificationDetailCounter,
      afterResponse: values =>
        _mountedSet(
          setUnreadCounter,
          (values || []).reduce(
            (res, { moduleType, totalCount }) => ({
              ...res,
              [moduleType]: totalCount,
            }),
            {}
          )
        ),
    })
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    _fecthNotificationCounter()
  }, [_fecthNotificationCounter])

  return (
    <MasterLayout typeSidebar="taskManager">
      <div className="mt10">
        <Title name={'Thông báo'} icon="icon2-chuong" />
        <div
          style={{
            flex: 1,
            position: 'relative',
            height: window.innerHeight - 130,
            overflow: 'auto',
          }}
          ref={rootRef}
        >
          <Tabs
            classContent="pt10"
            dataSource={tabs || []}
            onChange={changeTab}
            selectedKey={activeTab}
            rightHeader={
              <div>
                <Tooltip
                  content={
                    <span className="font-size-12">Đánh dấu đọc tất cả</span>
                  }
                >
                  <Button
                    className={csx('cpc-button', 'p0', 'rounded')}
                    intent={Intent.PRIMARY}
                    onClick={onReadAll}
                    disabled={submitDisable}
                  >
                    <Icon classIcon="icon-xac-nhan-lai" />
                  </Button>
                </Tooltip>
              </div>
            }
          />

          {firstLoading && <Loading />}
          {isEmpty && <Empty />}

          {size(announcements) !== 0 &&
            Object.entries(_groupNotifications(announcements)).map(
              ([date, list = []]) => (
                <Fragment key={date}>
                  <Text
                    className={csx(
                      'font-weight-400',
                      'pt10',
                      'text-uppercase',
                      'font-size-12',
                      'mb5'
                    )}
                  >
                    {getDateToText(date)}
                  </Text>

                  {list.map(
                    (
                      {
                        id,
                        isRead = false,
                        sender,
                        senderId,
                        creationTime,
                        content,
                        refId,
                        key,
                      },
                      index
                    ) => (
                      <Card
                        key={index}
                        bordered
                        className={csx('cpc-notification-card', 'has-action', {
                          unread: !isRead,
                        })}
                        onClick={() => goToDetail(id, refId, key, senderId)}
                        rounded
                        style={{ marginBottom: 2 }}
                        padding
                      >
                        {activeTab === tabKeys.general && (
                          <UserCard
                            needBaseUrl
                            src={get(sender, 'avatar')}
                            name={get(sender, 'fullName')}
                            department={get(sender, 'department.shortName')}
                            unit={get(sender, 'organization.shortName')}
                            action={trimHtmlText(content)}
                            className="flex-auto"
                          />
                        )}
                        {activeTab === tabKeys.reminder && (
                          <InfoCard
                            src={`${process.env.PUBLIC_URL}/images/bell-notification.svg`}
                            name="Nhắc nhở:"
                            action={trimHtmlText(content)}
                            className="flex-auto"
                            bgSize="contain"
                            notRadius
                          />
                        )}
                        <span className="time">
                          {getTimeToText(creationTime)}
                        </span>
                      </Card>
                    )
                  )}
                </Fragment>
              )
            )}

          {hasNextPage && <span ref={sentryRef} />}

          {fetching && (
            <span
              style={{
                position: 'fixed',
                bottom: 5,
                right: 5,
                borderRadius: 3,
              }}
            >
              <CircleLoader fill="#ddd" />
            </span>
          )}
        </div>
      </div>
    </MasterLayout>
  )
}

export default withCookies(memo(Notification))
