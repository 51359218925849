import React, { Component } from 'react'
import { Button, Checkbox } from '@blueprintjs/core'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import { DOCUMENTS_CABINET } from 'constants/Enum'
class ListFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: {
        textSearch: '',
        ngayTao: {
          from: undefined,
          to: undefined,
        },
      },
    }

    this.clearFilter = this.clearFilter.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentWillReceiveProps(props) {
    if (props.filter) {
      this.setState({
        filter: {
          textSearch: props.filter.textSearch || '',
          ngayTao: {
            from:
              props.filter.ngayTao &&
              props.filter.ngayTao.from &&
              props.filter.ngayTao.from.trim().length > 0
                ? moment(props.filter.ngayTao.from)
                : undefined,
            to:
              props.filter.ngayTao &&
              props.filter.ngayTao.to &&
              props.filter.ngayTao.to.trim().length > 0
                ? moment(props.filter.ngayTao.to)
                : undefined,
          },
        },
      })
    }
  }

  clearFilter(e) {
    const filter = {
      textSearch: '',
      ngayTao: {
        from: undefined,
        to: undefined,
      },
    }

    this.setState(
      {
        filter,
      },
      () => {
        this.props.clearFilterColumn && this.props.clearFilterColumn(filter)
      }
    )
  }

  handleDateChange(name, pos, e) {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: {
            ...this.state.filter[name],
            [pos]: e ? e : undefined,
          },
        },
      },
      () => {
        const filter = {
          ...this.state.filter,
          ngayTao: {
            from: this.state.filter.ngayTao.from
              ? moment(this.state.filter.ngayTao.from).format('YYYY-MM-DD')
              : '',
            to: this.state.filter.ngayTao.to
              ? moment(this.state.filter.ngayTao.to).format('YYYY-MM-DD')
              : '',
          },
        }
        this.props.setFilter({ filter, isSubmit: true })
      }
    )
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(
      {
        filter: {
          ...this.state.filter,
          [name]: value,
        },
      },
      () => {
        const filter = {
          ...this.state.filter,
          ngayTao: {
            from: this.state.filter.ngayTao.from
              ? moment(this.state.filter.ngayTao.from).format('YYYY-MM-DD')
              : '',
            to: this.state.filter.ngayTao.to
              ? moment(this.state.filter.ngayTao.to).format('YYYY-MM-DD')
              : '',
          },
        }
        this.props.setFilter({ filter, isSubmit: false })
      }
    )
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault()
    }
    const filter = {
      ...this.state.filter,
      ngayTao: {
        from: this.state.filter.ngayTao.from
          ? moment(this.state.filter.ngayTao.from).format('YYYY-MM-DD')
          : '',
        to: this.state.filter.ngayTao.to
          ? moment(this.state.filter.ngayTao.to).format('YYYY-MM-DD')
          : '',
      },
    }
    this.props.onSubmitFilter && this.props.onSubmitFilter(filter)
  }

  render() {
    const { type } = this.props
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`search-container search-DocumentarySearch pt15 pb30 px-sm-15 ${
          this.props.nganTuId ? 'search-documentary-content' : ''
        }`}
      >
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div>
              <label className="search-label">
                Thời gian (Ngày lưu công văn)
              </label>
              <div className="mt5 form-input-filter date-picker-inline border-radius-3 ">
                <DatePicker
                  isClearable
                  readOnly
                  selected={this.state.filter.ngayTao.from}
                  selectsStart
                  startDate={this.state.filter.ngayTao.from}
                  endDate={this.state.filter.ngayTao.to}
                  onChange={this.handleDateChange.bind(this, 'ngayTao', 'from')}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                  placeholderText="Từ ngày"
                  maxDate={this.state.filter.ngayTao.to}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
                <DatePicker
                  isClearable
                  readOnly
                  selected={this.state.filter.ngayTao.to}
                  selectsEnd
                  startDate={this.state.filter.ngayTao.from}
                  endDate={this.state.filter.ngayTao.to}
                  onChange={this.handleDateChange.bind(this, 'ngayTao', 'to')}
                  format="DD/MM/YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="input-datepicker input-icon-datepicker border-transparent border-radius-3"
                  placeholderText="Đến ngày"
                  minDate={this.state.filter.ngayTao.from}
                  popperPlacement="auto"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-8 mt20 mt-md-0 align-items-end">
            <div className="form-input-filter flex-auto border-radius-3 ">
              <input
                autoFocus={false}
                autoComplete="off"
                name="textSearch"
                className="input border-transparent pr-40"
                type="text"
                placeholder="Tìm kiếm Số công văn, Tên tài liệu, Số hóa"
                onChange={this.handleInputChange}
                value={this.state.filter.textSearch}
                maxLength={255}
              />
              <Button
                type="submit"
                className="form-input-btn border-radius-lelf-0 pt-intent-btn-green-shadow btn-icon"
              >
                <span className="pt-icon pt-icon-search mr0"></span>
              </Button>
            </div>
            <Button
              onClick={this.clearFilter}
              className="btn-icon flex-solid ml10 pt-intent-btn-white"
            >
              <span className="pt-icon icon-Loading mr0"></span>
            </Button>
          </div>
        </div>
        {this.props.nganTuId && (
          <div className="row mt20">
            {type !== DOCUMENTS_CABINET.PHONG_BAN && (
              <div className="col-lg-12 d-flex justify-content-end">
                <Checkbox
                  name="all"
                  checked={this.props.isCheckAllData}
                  onChange={this.props.hanldCheckAllData}
                >
                  <span className="name_checkbox">Chọn tất cả tài liệu</span>
                </Checkbox>
              </div>
            )}
          </div>
        )}
      </form>
    )
  }
}

export default ListFilter
