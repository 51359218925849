import get from 'lodash/get'
import * as types from '../constants/ActionTypes'

const initialState = {
  luongCongVanNoiBo: null,
}

export default function detailInternalDocuments(state = initialState, action) {
  switch (action.type) {
    case types.LUONG_CONG_VAN_NOI_BO:
      return {
        ...state,
        luongCongVanNoiBo: get(action, 'payload.data.result.result') || null,
      }
    default:
      return state
  }
}
