import { useMemo, useState, useEffect, useCallback } from 'react'
import get from 'lodash/get'
import size from 'lodash/size'
import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import {
  getMeeting,
  saveTree2,
  getDsTag,
  getPhongBanDonVi,
  getThongTinDonVi,
  getLanhDaoChuTri,
  postUpload,
  postMeeting,
  putMeeting,
} from 'actions/task'
import requestAction from 'helpers/request'
import { RESPONSIBILITY_TYPE, UNSET } from 'constants/Enum'
import { Action, Toast } from 'constants/MessageForm'
import { toDecamelizeKeys } from 'helpers/key'
import { randomId } from 'helpers/string'

const useTaskThongbaoKLCHCreate = ({
  match,
  history,
  actions,
  mainUnitId,
  hasShowAllDeparment,
  dataThongTinDonVi,
  dataTreeOrganizationDepartmentStore,
}) => {
  const dsLoaiCuocHop = [
    {
      label: 'Giao ban tuần',
      value: 'WEEKLY_DELIVERY',
    },
    {
      label: 'Sơ kết/Tổng kết',
      value: 'SUMMARY',
    },
    {
      label: 'Họp tại đơn vị',
      value: 'MEETING_AT_ORGANIZATION',
    },
    {
      label: 'Khác',
      value: 'OTHER',
    },
  ]

  const DEFAULT_TASK = useMemo(
    () => ({
      showTask: true,
      showExtra: false,
      data: {
        executionDate: moment(),
      },
      validate: {},
    }),
    []
  )

  const dateTimeRequest = 'YYYY-MM-DDTHH:mm:ss'
  const CHU_TRI = 'CHU_TRI'
  const PHOI_HOP = 'PHOI_HOP'
  const THEO_DOI = 'THEO_DOI'
  const dateRequest = 'YYYY-MM-DD'

  const id = match.params?.id
  const [tags, setTags] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [dataInputSearch, setDataInputSearch] = useState([])

  const [dataCollapse, setDataCollapse] = useState({
    showMeeting: true,
    showConclusion: true,
  })
  const [isDisabledButtonDefault, setIsDisabledButtonDefault] = useState(true)
  const [dataForm, setDataForm] = useState({})
  const [tasks, setTasks] = useState([{ ...DEFAULT_TASK, id: randomId() }])
  const [validateErrors, setValidateErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [fetching] = useState(false)
  const [files, setFiles] = useState({})
  const [dataAutoToggle] = useState([-1])
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [idTempDialog, setIdTempDialog] = useState(null)

  const onCloseDialog = useCallback(() => {
    setIsShowDialog(false)
    setIdTempDialog(null)
  }, [])

  const onDeleteTask = useCallback(() => {
    const id = idTempDialog
    const newTasks = tasks.filter(task => task.id !== id)
    setTasks(
      size(newTasks) === 1
        ? newTasks.map(t => ({ ...t, showTask: true }))
        : newTasks
    )
    setFiles(prevFiles => {
      delete prevFiles[id]
      return prevFiles
    })

    onCloseDialog()
  }, [idTempDialog, tasks, onCloseDialog])

  const _upload = async filesData => {
    const responseFilesObject = {}
    await Promise.all(
      Object.entries(filesData).map(async ([key, files]) => {
        if (isEmpty(files)) {
          responseFilesObject[key] = []
          return
        }

        await requestAction({
          action: () => postUpload(files),
          afterResponse: (result = []) => {
            responseFilesObject[key] = result
          },
          showToastSucess: false,
          codeCheck: false,
        })

        return
      })
    )

    return responseFilesObject
  }

  const onChangeFormValidate = ({ type, id, name, value }) => {
    if (type === 'TASK') {
      setValidateErrors(prev => ({
        ...prev,
        [id]: {
          ...(prev[id] || {}),
          [name]: value,
        },
      }))
    } else {
      setValidateErrors(prev => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const validateRequired = name => {
    let success = true
    const tasksClone = [...tasks]
    tasksClone.forEach(({ id, data }) => {
      if (isEmpty(data[name]) || (isString(data[name]) && !data[name].trim())) {
        success = false
        onChangeFormValidate({ type: 'TASK', id, name, value: true })
      } else {
        onChangeFormValidate({ type: 'TASK', id, name, value: false })
      }
    })

    return success
  }

  const onValidateFormName = name => {
    let success = true
    if (
      isEmpty(dataForm?.[name]) ||
      (isString(dataForm[name]) && !dataForm[name].trim())
    ) {
      success = false
      onChangeFormValidate({ type: 'MEETING', id: -1, name, value: true })
    }

    return success
  }

  const onValidate = () => {
    const isCheckMeetingTitle = onValidateFormName('title')
    const isCheckMeetingMeetingType = onValidateFormName('meetingType')
    const isCheckMeetingTextNumber = onValidateFormName('textNumber')
    const isCheckMeetingExecutionDate = onValidateFormName('executionDate')
    const isCheckMeetingMainResponsibilityId = onValidateFormName(
      'mainResponsibilityId'
    )
    const isCheckMeetingMeetingFiles = onValidateFormName('meetingFiles')
    const isCheckFullMeeting =
      !isCheckMeetingTitle ||
      !isCheckMeetingMeetingType ||
      !isCheckMeetingTextNumber ||
      !isCheckMeetingExecutionDate ||
      !isCheckMeetingMainResponsibilityId ||
      !isCheckMeetingMeetingFiles
    let isCheckTaskTitle = true
    let isCheckTaskChuTri = true
    if (!id) {
      isCheckTaskTitle = validateRequired('title')
      isCheckTaskChuTri = validateRequired([CHU_TRI])
    }

    const isCheckTask = !isCheckTaskTitle || !isCheckTaskChuTri
    if (isCheckFullMeeting || isCheckTask) {
      throw new Error('warning')
    }
  }

  const handleConvertData = useCallback(values => {
    const data = {
      title: values?.title || null,
      textNumber: values?.textNumber || null,
      meetingType: values?.meetingType || null,
      mainResponsibilityId: values?.mainResponsibilityId,
      organizationId: values?.organizationId,
    }

    const executionDate = get(values, 'executionDate')
    if (executionDate) {
      data.executionDate = moment(executionDate).format(dateRequest)
    }

    if (get(values, 'meetingFiles')) {
      data.attachmentFileIds = values?.meetingFiles?.map(elm => elm.id)
    }

    return data
  }, [])

  const _convertDataType = (values, type) => ({
    responsibility_id: get(values, 'id'),
    assign_type: get(values, 'type'),
    responsibility_type: type,
  })

  const convertType = type => {
    if (type === 'DEPARTMENT') {
      return 'PB'
    } else if (type === 'ORGANIZATION') {
      return 'DV'
    }

    return 'PERSONAL'
  }

  const _getConvertData = () => {
    return (tasks || []).map(task => {
      const originData = get(task, 'data', {})
      const newData = {
        title: get(originData, 'title', '').trim(),
        description: get(originData, 'description', '').trim(),
        priority:
          get(originData, 'priority') !== UNSET
            ? get(originData, 'priority')
            : undefined,
        work_user_tags: get(originData, 'tags', []).map(item => ({
          work_tag_id: item,
        })),
        attachment_file_ids: get(originData, 'attachmentFiles', []).map(
          file => file.id
        ),
        reference_file_ids: get(originData, 'referenceFiles', []).map(
          file => file.id
        ),
        is_save_template: get(originData, 'isSaveTemplate'),
        require_report: originData?.requireReport || false,
      }

      const deadline = get(originData, 'deadline')
      if (deadline) {
        newData.deadline = moment(deadline).startOf('day').format(dateRequest)
      }

      const executionDate = get(originData, 'executionDate')
      if (executionDate) {
        newData.execution_date = moment(executionDate).format(dateRequest)
      }

      const workReminder = get(originData, 'workReminder')
      if (workReminder) {
        newData.work_reminder = {
          type: workReminder.type,
          reminder_time: workReminder.date.format(dateTimeRequest),
        }
      }

      let executor = []
      if (get(originData, CHU_TRI)) {
        executor = [
          {
            responsibility_id: get(originData, `${CHU_TRI}.id`),
            assign_type: get(originData, `${CHU_TRI}.type`),
            responsibility_type: RESPONSIBILITY_TYPE.CHU_TRI,
          },
        ]
        newData.presiding_id = {
          [convertType(get(originData, `${CHU_TRI}.type`))]: get(
            originData,
            `${CHU_TRI}.id`
          ),
        }
      }

      let coordination = []
      if (get(originData, PHOI_HOP)) {
        coordination = get(originData, PHOI_HOP, [])?.map(elm =>
          _convertDataType(elm, RESPONSIBILITY_TYPE.PHOI_HOP)
        )
        newData.coordination_id = get(originData, PHOI_HOP, [])?.map(elm => ({
          [convertType(elm?.type)]: elm?.id,
        }))
      }

      let watcher = []
      if (get(originData, THEO_DOI)) {
        watcher = get(originData, THEO_DOI, [])?.map(elm =>
          _convertDataType(elm, RESPONSIBILITY_TYPE.THEO_DOI)
        )
      }

      newData.work_assignments = [...executor, ...coordination, ...watcher]

      return {
        ...task,
        data: newData,
      }
    })
  }

  const changeFormValue = ({ id, name, value }) => {
    setTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === id
          ? {
              ...task,
              data: {
                ...task.data,
                [name]: value,
                ...(name === 'deadline' ? { workReminder: null } : {}),
              },
            }
          : task
      )
    )
  }

  const _getDataWithUpload = async () => {
    const cloneTasks = _getConvertData()
    const responseFilesObject = await _upload(files)
    return (cloneTasks || []).map(task => ({
      ...(task?.data || {}),
      work_type: 'MEETING',
      attachment_file_ids: [
        ...(task?.data?.attachment_file_ids || []),
        ...(responseFilesObject[task.id] || []).map(file => file.id),
      ],
    }))
  }

  const submitCreate = async () => {
    await requestAction({
      successCode: 201 || 200,
      logError: true,
      beforeAction: () => {
        setIsLoading(true)
        onValidate()
      },
      action: async () => {
        const fileId = dataForm?.meetingFiles?.[0]?.fileId
        let responseFilesObject = {
          meetingFiles: [
            {
              id: fileId,
            },
          ],
        }
        if (!fileId) {
          responseFilesObject = await _upload({
            meetingFiles: dataForm?.meetingFiles,
          })
        }

        const data = await handleConvertData({
          ...dataForm,
          organizationId: dataThongTinDonVi?.donViId,
          ...responseFilesObject,
        })
        const dataConver = toDecamelizeKeys(data)
        const dataCongViec = await _getDataWithUpload()
        const fullData = {
          ...dataConver,
          meeting_work_items: dataCongViec,
        }
        return postMeeting(fullData)
      },
      afterResponse: () => {
        history.replace('/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop')
      },
      afterAction: () => {
        setIsLoading(false)
      },
      successMessage: Toast.SUCCESS(Action.CREATE),
      errorMessage: Toast.FAIL(Action.CREATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const submitUpdate = async () => {
    await requestAction({
      successCode: 200 || 201,
      beforeAction: () => {
        setIsLoading(true)
        onValidate()
      },
      action: async () => {
        const fileId = dataForm?.meetingFiles?.[0]?.fileId
        let responseFilesObject = {
          meetingFiles: [
            {
              id: fileId,
            },
          ],
        }
        if (!fileId) {
          responseFilesObject = await _upload({
            meetingFiles: dataForm?.meetingFiles,
          })
        }

        const data = await handleConvertData({
          ...dataForm,
          organizationId: dataThongTinDonVi?.donViId,
          ...responseFilesObject,
        })
        const dataConver = toDecamelizeKeys(data)
        const fullData = {
          ...dataConver,
        }
        return putMeeting(id, fullData)
      },
      afterResponse: () => {
        history.replace(`/quan-ly-cong-viec/thong-bao-ket-luan-cuoc-hop`)
      },
      afterAction: () => {
        setIsLoading(false)
        setIsDisabledButtonDefault(true)
      },
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
      warningMessage: Toast.INCOMPLETE,
    })
  }

  const changeField = (type, id, name) => value => {
    setIsDisabledButtonDefault(false)
    if (type === 'TASK') {
      onChangeFormValidate({ type, id, name, value: false })
      changeFormValue({
        id,
        name,
        value,
      })
    } else {
      onChangeFormValidate({ type, id, name: id, value: false })
      setDataForm(prev => ({
        ...prev,
        [id]: value,
      }))
    }
  }

  const changeFile = (type, name) => fileList => {
    setIsDisabledButtonDefault(false)
    if (type === 'TASK') {
      setFiles({
        ...files,
        [name]: fileList,
      })
    } else {
      onChangeFormValidate({ type, id: -1, name, value: false })
      setDataForm(prev => ({
        ...prev,
        [name]: fileList,
      }))
    }
  }

  // FUNCTION CONG VIEC
  const addTask = () => {
    setDataCollapse({
      ...dataCollapse,
      showMeeting: false,
    })
    setTasks([
      ...tasks?.map(t => ({ ...t, showTask: false, showExtra: false })),
      { ...DEFAULT_TASK, id: randomId() },
    ])
  }

  const toggleCollapseTask = id => () => {
    setTasks(
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showTask: !task.showTask,
              showExtra: false,
            }
          : {
              ...task,
              showTask: false,
            }
      )
    )
  }

  const removeTask = id => () => {
    setIdTempDialog(id)
    setIsShowDialog(true)
  }

  const toggleCollapseExtra = id => () => {
    setTasks(
      tasks.map(task =>
        task.id === id
          ? {
              ...task,
              showExtra: !task.showExtra,
            }
          : task
      )
    )
  }

  const removeAttachmentFiles = id => removeFile => {
    if (removeFile.id) {
      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === id
            ? {
                ...task,
                data: {
                  ...get(task, 'data', {}),
                  attachmentFiles: get(task, 'data.attachmentFiles', []).filter(
                    file => !isEqual(file, removeFile)
                  ),
                },
              }
            : task
        )
      )
    } else {
      setFiles({
        ...files,
        [id]: files[id].filter(elm => elm.uid !== removeFile.uid),
      })
    }
  }

  const attachmentFilesFromDocument = (files, isEoffice) => {
    let filesResult = []
    if (!size(files)) {
      return []
    }

    if (isEoffice) {
      filesResult = files.filter(elm => elm.application === 'eOffice')
    } else {
      filesResult = files.filter(elm => elm.application !== 'eOffice')
    }

    return filesResult
  }

  const onChangeRadio = useCallback(
    (name, values, taskId) => {
      setIsDisabledButtonDefault(false)
      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]: name === CHU_TRI ? values : null,
                [PHOI_HOP]:
                  name === PHOI_HOP
                    ? elm?.data?.[PHOI_HOP]
                    : elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id),
                [THEO_DOI]:
                  name === THEO_DOI
                    ? elm?.data?.[THEO_DOI]
                    : elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id),
              },
            }
          }

          return elm
        })
      )
      onChangeFormValidate({ type: 'TASK', id: taskId, name, value: false })
    },
    [setTasks, setIsDisabledButtonDefault]
  )

  const handleCheckActive = useCallback(
    (key, tasks, taskId, record) => {
      const parentItem =
        dataTreeOrganizationDepartmentStore?.find(e => e?.id === record?.id)
          ?.children || []
      const taskItem = tasks?.find(elm => elm?.id === taskId)
      let isChecked = false
      if (
        !isEmpty(taskItem) !== 0 &&
        !isEmpty(taskItem?.data) &&
        size(taskItem?.data?.[key]) !== 0
      ) {
        const taskItemDataKey = taskItem?.data?.[key]?.filter(
          e => e?.parentId === record?.id
        )
        if (
          record?.type === 'ALL' &&
          size(parentItem) !== 0 &&
          size(parentItem) === size(taskItemDataKey)
        ) {
          isChecked = true
        } else {
          const dataKey = taskItem?.data?.[key]?.findIndex(
            e => e?.id === record?.id
          )
          if (dataKey === 0 || (dataKey && dataKey !== -1)) {
            isChecked = true
          }
        }
      }

      return isChecked
    },
    [dataTreeOrganizationDepartmentStore]
  )

  const onChangeCheckbox = useCallback(
    async (name, values, taskId, event, isIndeterminate) => {
      setIsDisabledButtonDefault(false)
      let isChecked = event?.target?.checked
      if (isIndeterminate) {
        isChecked = false
      }

      const isAll = values?.type === 'ALL'
      let treeItem = []
      if (isAll) {
        treeItem =
          dataTreeOrganizationDepartmentStore?.find(e => e?.id === values?.id)
            ?.children || []
      }

      setTasks(prev =>
        prev?.map(elm => {
          if (elm.id === taskId) {
            let newData = []
            const oldData =
              elm?.data?.[name]?.filter(e => e?.id !== values?.id) || []
            newData = [
              ...oldData,
              ...(isChecked && values?.type !== 'ALL' ? [values] : []),
            ]
            if (isAll) {
              if (isChecked) {
                newData = [...newData, ...treeItem]?.filter(
                  e => e.id !== values?.parentId
                )
                const dataChuTri = elm?.data?.[CHU_TRI]
                if (dataChuTri?.id) {
                  newData = newData?.filter(e => e.id !== dataChuTri?.id)
                }
              } else {
                newData = differenceBy(newData || [], treeItem || [], 'id')
              }
            } else {
              newData = newData?.filter(e => e.id !== values?.parentId)
            }

            return {
              ...elm,
              data: {
                ...elm?.data,
                [CHU_TRI]:
                  name !== CHU_TRI && elm?.data?.[CHU_TRI]?.id === values?.id
                    ? null
                    : elm?.data?.[CHU_TRI],
                [PHOI_HOP]:
                  name === PHOI_HOP
                    ? uniqBy(newData, 'id')
                    : elm?.data?.[PHOI_HOP]?.filter(e => e.id !== values?.id) ||
                      [],
                [THEO_DOI]:
                  name === THEO_DOI
                    ? uniqBy(newData, 'id')
                    : elm?.data?.[THEO_DOI]?.filter(e => e.id !== values?.id) ||
                      [],
              },
            }
          }

          return elm
        })
      )
    },
    [setTasks, dataTreeOrganizationDepartmentStore, setIsDisabledButtonDefault]
  )

  const handleCheckIndeterminate = useCallback(
    (name, tasks, taskId, record) => {
      const taskItem = tasks?.find(e => e.id === taskId)
      let isChecked = false
      const dataKey = size(taskItem?.data?.[name]) !== 0
      const hasChecked = taskItem?.data?.[name]?.some(
        e => e?.parentId === record?.id
      )
      if (dataKey && record?.type === 'ALL' && hasChecked) {
        isChecked = true
      }

      return isChecked
    },
    []
  )

  const toggleCollapse = (name, value) => {
    setDataCollapse({
      showMeeting: false,
      showConclusion: false,
      [name]: !value,
    })
  }

  const onCardChange = useCallback(value => {
    setDataForm(prev => ({
      ...prev,
      textNumber: value?.label,
      meetingFiles: value?.fileCongVan
        ? [
            {
              ...value?.fileCongVan,
              uid: value?.fileCongVan?.fileId,
              name: value?.fileCongVan?.tenFile,
              mime: value?.fileCongVan?.kieuFile,
              size: value?.fileCongVan?.kichThuoc,
            },
          ]
        : [],
      title: value?.trichYeu,
      executionDate: value?.ngayCongVan ? moment(value?.ngayCongVan) : null,
    }))
  }, [])

  const callApiSoCongVan = useCallback(
    messageDelay => {
      setDataInputSearch([])
      setIsOpen(false)
      actions.searchFileCongVanV2(messageDelay).then(res => {
        const result = res?.payload?.data?.result
        if (size(result) !== 0) {
          setIsOpen(true)
          setDataInputSearch(
            result?.map(elm => ({
              ...elm,
              value: elm?.congVanDenId || elm?.congVanId || elm?.congVanNoiBoId,
              label: elm?.soCongVan,
            }))
          )
        }
      })
    },
    [actions]
  )

  const fetchDepartmentsUnit = useCallback(() => {
    const convertType = type => {
      if (type === 'PB') {
        return 'DEPARTMENT'
      } else if (type === 'DV') {
        return 'ORGANIZATION'
      }

      return 'PERSONAL'
    }

    requestAction({
      showToast: false,
      successCode: 201 || 200,
      action: () =>
        getPhongBanDonVi(mainUnitId, { phongBanLanhDao: false, limit: 9999 }),
      afterResponse: response => {
        const departmentData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'PB')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -1,
              children: [],
            })) || []
        const organizationData =
          response
            ?.filter(f => f?.loaiDonViPhongBan === 'DV')
            ?.map(elm => ({
              id: elm?.donViPhongBanId,
              type: convertType(elm?.loaiDonViPhongBan),
              name: elm?.maDonViPhongBan,
              code: elm?.maDonViPhongBan,
              parentId: -2,
              children: [],
            })) || []
        const newData = [
          ...(size(departmentData) !== 0
            ? [
                {
                  id: -1,
                  type: 'ALL',
                  name: 'Phòng ban',
                  code: 'Phòng ban',
                  parentId: null,
                  children: departmentData,
                },
              ]
            : []),
          ...(size(organizationData) !== 0
            ? [
                {
                  id: -2,
                  type: 'ALL',
                  name: 'PC/ Đơn vị',
                  code: 'PC/ Đơn vị',
                  parentId: null,
                  children: organizationData,
                },
              ]
            : []),
        ]
        requestAction({
          showToast: false,
          successCode: 0,
          action: () => saveTree2(newData),
        })
      },
    })
  }, [mainUnitId])

  const fetchDsTag = useCallback(async () => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getDsTag({ isActive: true, limit: 99 }),
      afterResponse: ({ items = [] }) => setTags(items),
    })
  }, [])

  const fetchDetail = useCallback(() => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: true,
      action: () => getMeeting(id),
      afterResponse: response => {
        setDataForm({
          meetingType: get(response, 'meetingType'),
          textNumber: get(response, 'textNumber'),
          title: get(response, 'title'),
          executionDate: response?.executionDate
            ? moment(get(response, 'executionDate'))
            : null,
          mainResponsibilityId: get(response, 'mainResponsibilityId'),
          meetingFiles: [
            {
              ...response?.files?.[0],
              uid: response?.files?.[0]?.id,
              fileId: response?.files?.[0]?.id,
            },
          ],
        })
      },
    })
  }, [id])

  useEffect(() => {
    fetchDetail()
  }, [fetchDetail])

  useEffect(() => {
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getThongTinDonVi({ donViId: mainUnitId }),
    })
    requestAction({
      codeCheck: false,
      showToast: false,
      getResult: false,
      action: () => getLanhDaoChuTri(mainUnitId),
    })
  }, [mainUnitId])

  useEffect(() => {
    fetchDsTag()
  }, [fetchDsTag])

  useEffect(() => {
    if (hasShowAllDeparment) {
      fetchDepartmentsUnit()
    }
  }, [fetchDepartmentsUnit, hasShowAllDeparment])

  return {
    dsLoaiCuocHop,
    tags,
    dataInputSearch,
    isOpen,
    tasks,
    dataCollapse,
    dataForm,
    validateErrors,
    fetching,
    dataAutoToggle,
    files,
    isLoading,
    isDisabledButtonDefault,
    isShowDialog,
    onCloseDialog,
    onCardChange,
    callApiSoCongVan,
    handleCheckIndeterminate,
    onChangeRadio,
    attachmentFilesFromDocument,
    handleCheckActive,
    onChangeCheckbox,
    removeAttachmentFiles,
    changeField,
    changeFile,
    toggleCollapseTask,
    addTask,
    removeTask,
    onDeleteTask,
    toggleCollapseExtra,
    toggleCollapse,
    submitUpdate,
    submitCreate,
  }
}

export default useTaskThongbaoKLCHCreate
