import React, { PureComponent } from 'react'
import { Dialog } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import moment from 'moment'

import { GridView } from '../common'
import { getLichSuHSCV } from '../../actions'

class LichSuLuuTru extends PureComponent {
  constructor() {
    super()

    this.state = {
      data: [],
      fetching: true,
      empty: false,
      error: false,
    }
  }

  header = () => [
    {
      classNames: 'colum-stt-first',
      value: 'Stt',
    },
    {
      classNames: 'colum-default-width',
      value: 'Cá nhân',
    },
    {
      classNames: 'colum-default-width',
      value: 'Nội dung',
    },
    {
      classNames: '',
      value: 'Thời gian',
    },
  ]

  renderRow =
    () =>
    ({ item, number: index }) => {
      return (
        <tr style={{ pointerEvents: 'none' }}>
          <td>{('0' + index).slice(-2)}</td>
          <td>{get(item, 'nguoiThucHien.tenNhanVien')}</td>
          <td>{get(item, 'noiDung')}</td>
          <td>{moment(get(item, 'ngayTao')).format('HH:mm - DD/MM/YYYY')}</td>
        </tr>
      )
    }

  fetchHistory = async () => {
    const { getLichSuHSCV, information } = this.props
    const response = await getLichSuHSCV(get(information, 'id'))

    if (response.error) {
      return this.setState({
        error: true,
        fetching: false,
      })
    }

    if (get(response, 'payload.data.result.total') === 0) {
      return this.setState({
        empty: true,
        fetching: false,
      })
    }

    this.setState({
      data: get(response, 'payload.data.result.items', []).reverse(),
      fetching: false,
    })
  }

  componentDidMount() {
    this.fetchHistory()
  }

  render() {
    const { onClose } = this.props
    const { fetching, empty, error, data } = this.state

    return (
      <Dialog
        isOpen
        title="Lịch sử HSCV"
        onClose={onClose}
        className="p0"
        style={{ minWidth: 650 }}
      >
        <div style={{ padding: 20 }}>
          <div className="table-container list-GeneralDocumentary pb0">
            <GridView
              classNames="pt-table"
              isLoading={fetching}
              isEmpty={empty}
              isError={error}
              headers={this.header()}
              page={1}
              data={data}
              itemShape={this.renderRow()}
            />
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getLichSuHSCV: bindActionCreators(getLichSuHSCV, dispatch),
})

export default connect(null, mapDispatchToProps)(LichSuLuuTru)
