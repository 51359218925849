import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Pagination from 'components/Pagination'

class CustomPagination extends Component {
  static propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
  }

  render() {
    const { current, total, onChange } = this.props

    return (
      <div className="footer-list">
        <Pagination current={current} total={total} onChange={onChange} />
      </div>
    )
  }
}

export default CustomPagination
