import React, { memo, useCallback, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { putCancelApprovalStatusWork } from 'actions/task'
import { Dialog, Card } from 'components/newCommon'
import { Action, Toast } from 'constants/MessageForm'
import requestAction from 'helpers/request'
import {
  NOT_APPROVE_TITLE,
  NOT_APPROVE_CONTENT,
  TEXT_BUTTON_SUBMIT,
} from 'constants/Popup'

const CancelApproval = ({ dataSource, onClose, onSuccess }) => {
  const id = dataSource?.id
  const mounted = useRef(false)
  const _mountedSet = (setFunction, state) =>
    !!get(mounted, 'current', false) && setFunction(state)
  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const onCancelApproval = useCallback(() => {
    requestAction({
      beforeAction: () => _mountedSet(setLoading, true),
      action: () => putCancelApprovalStatusWork(id),
      afterResponse: () => {
        _mountedSet(setShowDialog, false)
        return onSuccess()
      },
      afterAction: () => {
        _mountedSet(setLoading, false)
        onClose()
      },
      successMessage: Toast.SUCCESS(Action.UPDATE),
      errorMessage: Toast.FAIL(Action.UPDATE),
    })
  }, [id, onClose, onSuccess])

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  return (
    <Dialog
      buttonUppercase
      isOpen={showDialog}
      cancelClassName="pl20 pr20"
      cancelText="Hủy"
      confirmClassName="pl20 pr20"
      confirmText={TEXT_BUTTON_SUBMIT}
      loading={loading}
      onCancel={onClose}
      onConfirm={onCancelApproval}
      onClose={onClose}
      title={NOT_APPROVE_TITLE}
      textCenter
    >
      <Card className="mt20 mb20">{NOT_APPROVE_CONTENT}</Card>
    </Dialog>
  )
}

CancelApproval.defaultProps = {
  dataSource: null,
  onClose: () => {},
  onSuccess: () => {},
}

CancelApproval.propTypes = {
  dataSource: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default memo(CancelApproval)
