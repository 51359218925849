import React, { Component } from 'react'
import { MasterLayout } from '../../components/layout'
import * as Actions from '../../actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  ListFilterStatistic,
  ToMessages,
  FileDinhKem,
  TinChuyenTiep,
} from '../../components/System/service'
import { Scrollbars } from 'react-custom-scrollbars'
import moment from 'moment'
import { LoadingItem } from '../../components/common'
import _ from 'lodash'
import NotFound from '../../components/common/NotFound'
import * as utils from '../../constants/Api'
import emojione from 'emojione'
import { camelizeKeys } from 'humps'

const THONGKE_SERVICE = 'Chi tiết thống kê Service'
class StatisticService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      thongKeTongSoTinNhan: 0,
      isloading: true,
      heightChatBox: 300,
      listData: {
        items: [],
        total: 0,
        page: 1,
      },
      page: 1,
      service_id:
        (this.props.match.params && this.props.match.params.id) || undefined,
      // "2db93df4-378e-4897-9756-60416cb52256",
      filter: {
        search_text: '',
        from_date: undefined,
        to_date: undefined,
      },
    }
  }

  componentDidMount() {
    this.getChatSearch()
    let windowHeight = window && window.innerHeight
    this.setState({
      heightChatBox: windowHeight - 355,
    })
  }

  handleSearchSubmit = filter => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          search_text: filter.searchText ? filter.searchText : undefined,
          from_date: filter.tuNgay
            ? filter.tuNgay.format('YYYY-MM-DD 00:00:00')
            : undefined,
          to_date: filter.denNgay
            ? filter.denNgay.format('YYYY-MM-DD 23:59:59')
            : undefined,
        },
        listData: {
          items: [],
          total: 0,
          page: 1,
        },
        page: 1,
      },
      () => {
        this.getChatSearch(this.state.filter)
      }
    )
  }

  getChatSearch = async (data = {}) => {
    let { filter, listData, service_id, page } = this.state
    filter = Object.assign(filter, data)
    await this.props.actions
      .chatSearchMessages2(service_id, { filter }, page, utils.PAGINATION_LIMIT)
      .then(res => {
        if (
          res &&
          !res.error &&
          res.payload &&
          res.payload.data &&
          res.payload.data.result &&
          res.payload.data.result.length !== 0
        ) {
          let dataNew = listData.items.concat(res.payload.data.result.items)
          this.setState({
            listData: {
              page: res.payload.data.result.page,
              total: res.payload.data.result.total,
              items: dataNew,
            },
            filter: {
              ...this.state.filter,
            },
            isloading: false,
            thongKeTongSoTinNhan: res.payload.data.result.total,
          })
        } else {
          this.setState({
            isloading: false,
            thongKeTongSoTinNhan: 0,
            filter: {},
            listData: {},
          })
        }
      })
  }

  handleScroll = values => {
    if (this.state.listData.items.length >= this.state.listData.total) {
      return
    }

    if (values.top >= 1) {
      this.setState(
        {
          listData: {
            ...this.state.listData,
          },
          filter: {
            ...this.state.filter,
          },
          page: this.state.page + 1,
        },
        () => {
          this.getChatSearch()
        }
      )
    }
  }

  getContent = str => {
    if (str.startsWith('[messageForwardJSON]')) {
      return str.split('[messageForwardJSON]')[2]
    } else {
      return str
    }
  }

  getContentChuyenTiep = str => {
    if (str.startsWith('[messageForwardJSON]')) {
      return str.split('[messageForwardJSON]')[1]
    }

    return false
  }

  urlify(text) {
    const urlRegex = /((https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/gi
    text = text.replace(urlRegex, function (url) {
      return `<a target="_blank" href="${url}">${url}</a>`
    })

    return emojione.toImage(text)
  }

  goBack() {
    this.props.history.goBack()
  }

  render() {
    let { thongKeTongSoTinNhan, isloading, listData, heightChatBox } =
      this.state
    return (
      <MasterLayout typeSidebar="sidebarSystem">
        <div className="heading-block">
          <button
            onClick={this.goBack.bind(this)}
            type="button"
            className="pt-button pt-button-back button-back-fixed mr15"
          >
            <span className="pt-icon pt-icon-chevron-left pt-align-left"></span>
            <span className="text-content">Quay lại</span>
          </button>
          <span className="headding-inline">
            <span className="icon-headding icon-s-list"></span>
            <span className="text-headding">{THONGKE_SERVICE}</span>
          </span>
        </div>
        <div className="statistics-block">
          <div className="statistics-box">
            <span
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/tongsotinnhan.svg)`,
              }}
              className="icon asdas"
            />
            <div className="info">
              <span>Tổng số tin nhắn</span>
              <i>{thongKeTongSoTinNhan}</i>
            </div>
          </div>
        </div>
        <div className="statistics-list--wrapper">
          <ListFilterStatistic
            filter={this.state.filter}
            onSubmit={this.submitFilter}
            readOnly={this.props.readOnly}
            actions={this.props.actions}
            searchSubmit={this.handleSearchSubmit}
          />
          <div className="statistics-list--block" id="statistics-list--block">
            {!isloading ? (
              <Scrollbars
                autoHideTimeout={100}
                autoHeight
                autoHeightMin={0}
                autoHeightMax={heightChatBox}
                autoHideDuration={200}
                onScrollFrame={this.handleScroll}
              >
                <div className="statistics-list--scroll">
                  <ul className="statistics-list">
                    {listData &&
                      listData.items.length > 0 &&
                      listData.items.map((item, key) => {
                        return (
                          <li key={key}>
                            <div className="statistics-heading-block">
                              <div className="heading">
                                <h3>{_.get(item, 'tenNguoiGui')}</h3>
                                <span className="date">
                                  {moment(_.get(item, 'ngayTao')).isValid()
                                    ? moment(_.get(item, 'ngayTao')).format(
                                        'DD/MM/YYYY HH:mm'
                                      )
                                    : ''}
                                </span>
                              </div>
                              <span className="send">
                                To:
                                <ToMessages
                                  receivers={_.get(item, 'nguoiNhan')}
                                />
                              </span>
                            </div>
                            <div className="content">
                              <TinChuyenTiep
                                data={
                                  this.getContentChuyenTiep(
                                    _.get(item, 'noiDung')
                                  )
                                    ? camelizeKeys(
                                        JSON.parse(
                                          this.getContentChuyenTiep(
                                            _.get(item, 'noiDung')
                                          )
                                        )
                                      )
                                    : []
                                }
                              />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: this.urlify(
                                    this.getContent(
                                      _.get(item, 'noiDung')
                                        .trim()
                                        .replace(/<\/p>/g, '\n')
                                    )
                                  ),
                                }}
                              />
                              <FileDinhKem
                                auth={this.props.auth}
                                files={_.get(item, 'fileDinhKem')}
                              />
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </Scrollbars>
            ) : (
              <div className="loading-container">
                <LoadingItem />
                <LoadingItem />
                <LoadingItem />
              </div>
            )}
            {!isloading && listData && listData.items.length === 0 && (
              <NotFound />
            )}
          </div>
        </div>
      </MasterLayout>
    )
  }
}

const mapStateToProps = state => ({
  auth: {
    ...state.auth,
    user: state.auth,
  },
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(StatisticService)
