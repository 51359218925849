import React, { useRef, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import bytes from 'bytes'
import { Intent } from '@blueprintjs/core'
import { commonAddToasterMessage } from 'actions'
import { ValidateForm } from 'constants/MessageForm'

const EMPTY_FILE = {
  tenFile: '',
  kieuFile: '',
  file: undefined,
}

const UploadFile = ({
  actions,
  className,
  maxSize,
  limit,
  fileTypeAllow,
  fileTypeAllowShortcut,
  fileTypeNotAllow,
  defaultFiles,
  isReset,
  isDisabled,
  hasAddFile,
  onLoadFile,
}) => {
  const size = 1024 * 1024 * maxSize
  const [fileDinhKem, setFileDinhKem] = useState([EMPTY_FILE])
  const onLoadFileRef = useRef(onLoadFile)

  const onAdd = useCallback(() => {
    if (fileDinhKem?.length === limit) {
      return actions.commonAddToasterMessage({
        message: `Vui lòng chọn tối đa ${limit} file đính kèm`,
        intent: Intent.WARNING,
      })
    }

    setFileDinhKem(prev => [...prev, EMPTY_FILE])
  }, [limit, fileDinhKem, actions])

  const onChangeFile = (e, index) => {
    const { files } = e.target
    const originalFile = files[0]
    if (originalFile) {
      const originalType = originalFile.type
      const originalSize = originalFile.size
      if (fileTypeAllow?.length !== 0 || fileTypeNotAllow?.length !== 0) {
        if (
          fileTypeAllow?.length !== 0 &&
          !fileTypeAllow?.some(s => s === originalType)
        ) {
          return actions.commonAddToasterMessage({
            message: ValidateForm.FILE_TYPE_ALLOW(
              fileTypeAllowShortcut?.length !== 0
                ? fileTypeAllowShortcut
                : fileTypeAllow
            ),
            intent: Intent.WARNING,
          })
        } else if (
          fileTypeNotAllow?.length !== 0 &&
          fileTypeNotAllow?.some(s => s === originalType)
        ) {
          return actions.commonAddToasterMessage({
            message: ValidateForm.FILE_TYPE_NOT_ALLOW(fileTypeNotAllow),
            intent: Intent.WARNING,
          })
        }
      }
      if (originalSize > size) {
        return actions.commonAddToasterMessage({
          message: ValidateForm.FILE_SIZE_ALLOW(maxSize),
          intent: Intent.WARNING,
        })
      }

      setFileDinhKem(prev =>
        prev?.map((file, i) => {
          if (i === index) {
            return {
              ...file,
              tenFile: originalFile.name,
              kichThuoc: originalSize,
              file: originalFile,
            }
          }

          return file
        })
      )
    }
  }

  const onRemoveFile = index => {
    setFileDinhKem(prev =>
      prev?.length === 1
        ? [EMPTY_FILE]
        : prev?.length > 1
        ? prev?.filter((_, i) => i !== index)
        : prev
    )
  }

  useEffect(() => {
    onLoadFileRef.current = onLoadFile
  }, [onLoadFile])

  useEffect(() => {
    onLoadFileRef.current(fileDinhKem)
  }, [fileDinhKem])

  useEffect(() => {
    if (defaultFiles?.length > 0 ?? false) {
      setFileDinhKem(defaultFiles)
    }
  }, [defaultFiles])

  useEffect(() => {
    if (isReset) {
      setFileDinhKem([EMPTY_FILE])
    }
  }, [isReset])

  return (
    <div className={className}>
      {fileDinhKem.map((file, index) => {
        const fileName = file.file
          ? `${file.tenFile} - ${bytes(file.kichThuoc)}`
          : file?.name
          ? file?.name
          : 'Chọn file đính kèm'
        return (
          <div
            className="comp-upload-file"
            key={index}
            style={{
              marginBottom: index === fileDinhKem.length - 1 ? 0 : '10px',
            }}
          >
            <input type="text" placeholder={fileName} />
            <input
              type="file"
              title={fileName}
              onChange={e => onChangeFile(e, index)}
              disabled={isDisabled}
            />
            <button type="button">Chọn file</button>
            {(file.file || file?.name || index > 0) && !isDisabled ? (
              <span
                className="icon-Huy close"
                onClick={() => onRemoveFile(index)}
              />
            ) : (
              ''
            )}
          </div>
        )
      })}
      {hasAddFile && (
        <button onClick={onAdd} className="comp-upload-file-add mt10">
          <span className="name">Thêm file</span>
          <span className="icon icon-s-plus"></span>
        </button>
      )}
    </div>
  )
}

UploadFile.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  maxSize: PropTypes.number,
  limit: PropTypes.number,
  fileTypeAllow: PropTypes.array,
  fileTypeAllowShortcut: PropTypes.array,
  fileTypeNotAllow: PropTypes.array,
  defaultFiles: PropTypes.any,
  isReset: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasAddFile: PropTypes.bool,
  onLoadFile: PropTypes.func,
}

UploadFile.defaultProps = {
  actions: null,
  className: '',
  maxSize: 10,
  limit: 10,
  fileTypeAllow: [],
  fileTypeAllowShortcut: [],
  fileTypeNotAllow: [],
  defaultFiles: [],
  isReset: false,
  isDisabled: false,
  hasAddFile: true,
  onLoadFile: () => {},
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      commonAddToasterMessage,
    },
    dispatch
  ),
})

export default connect(null, mapDispatchToProps)(UploadFile)
