import {
  ContentHeader,
  EmptyDataTable,
  ErrorLoadingTable,
  LoadingTable,
  HeadingTableSort,
} from '../../../components/common'
import { MasterLayout } from '../../../components/layout'
import React, { PureComponent } from 'react'
import { headers } from '../../../constants/Table'
import { Collapse } from '@blueprintjs/core'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { fetchProcedureGroup } from '../../../actions/procedure'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'

const PAGE_NAME = 'Trình ký số theo quy trình'
const PAGE_ICON = 'icon-trinh-van-ban-ky-duyet'

class ProcedureSelectPage extends PureComponent {
  constructor() {
    super()
    this.state = {
      list: [],
      isLoading: true,
      isError: false,
      isDataEmpty: false,
    }
  }

  fetchProcedure = async () => {
    const { auth, fetchProcedure } = this.props
    await this.setState({ isLoading: true })
    if (auth) {
      await fetchProcedure(auth.mainUnitId)
        .then(res => {
          const status = get(res, 'payload.status') === 200
          const data = get(res, 'payload.data.result.items', [])
          this.setState({
            isError: !status,
            list: data,
            isDataEmpty: !data.length,
          })
        })
        .catch(() =>
          this.setState({
            isError: true,
          })
        )
    }
    this.setState({ isLoading: false })
  }

  componentDidMount() {
    this.fetchProcedure()
  }

  render() {
    const { isLoading, isError, isDataEmpty } = this.state
    return (
      <MasterLayout typeSidebar="signNumber">
        <ContentHeader pageIcon={PAGE_ICON} pageName={PAGE_NAME} />
        <main>
          <HeadingTableSort data={headers.procedureSelect} />
          <div style={{ background: '#fff' }}>
            {!!isLoading ? (
              <LoadingTable />
            ) : !!isError ? (
              <ErrorLoadingTable />
            ) : !!isDataEmpty ? (
              <EmptyDataTable />
            ) : (
              this.state.list.map((item, index) => (
                <CollapseTableItem data={item} key={index} i={index + 1} />
              ))
            )}
          </div>
        </main>
      </MasterLayout>
    )
  }
}

class CollapseTableItem extends PureComponent {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
  }
  toggleCollapse = () => this.setState({ isOpen: !this.state.isOpen })
  render() {
    const { data, i } = this.props
    const { isOpen } = this.state
    return !!data.dsQuyTrinh && !!data.dsQuyTrinh.length ? (
      <section>
        <div className="table-display procedure-select-item category">
          <div className={`table-cols-content width-col-5`}>
            {isOpen ? (
              <span
                className="icon-round-delete-button"
                onClick={this.toggleCollapse}
              />
            ) : (
              <span
                className="icon-rounded-add-button"
                onClick={this.toggleCollapse}
              />
            )}
          </div>
          <div className={`table-cols-content width-col-5`}>{i}</div>
          <div className={`table-cols-content width-col-90`}>
            {data.tenDanhMuc}
          </div>
        </div>
        <Collapse
          isOpen={isOpen}
          className={classnames('procedure-select-item', 'procedure-group')}
        >
          {data.dsQuyTrinh.map((item, index) => (
            <div className="table-display" key={index}>
              <div className="table-cols-content width-col-10" />
              <div
                className={classnames('procedure', {
                  'has-underline': index !== data.dsQuyTrinh.length - 1,
                })}
              >
                <Link
                  to={`/ky-so-tap-trung/van-ban-ky-duyet/them-moi/${item.quyTrinhId}`}
                  className="table-cols-content width-col-90 default"
                >
                  <Popover
                    position={Position.TOP}
                    interactionKind={PopoverInteractionKind.HOVER}
                    openOnTargetFocus
                    content={
                      <div className="menu-popover-quy-trinh">
                        {item.xacDinhThanhPhanKyDuyet === 0 &&
                          'Quy trình cá nhân'}
                        {item.xacDinhThanhPhanKyDuyet === 1 &&
                          'Quy trình chung'}
                      </div>
                    }
                  >
                    <i
                      className={classnames('icon-trinh-quy-trinh', {
                        'icon-quy-trinh-ca-nhan':
                          item.xacDinhThanhPhanKyDuyet === 0,
                        'icon-quy-trinh-chung':
                          item.xacDinhThanhPhanKyDuyet === 1,
                      })}
                    />
                  </Popover>
                  {item.tenQuyTrinh}
                </Link>
              </div>
            </div>
          ))}
        </Collapse>
      </section>
    ) : null
  }
}

const mapDispatchToProps = dispatch => ({
  fetchProcedure: bindActionCreators(fetchProcedureGroup, dispatch),
})

export default connect(null, mapDispatchToProps)(ProcedureSelectPage)
