export const reorder = (list, startIndex, endIndex) => {
  const arrayClone = [...list]

  const [item] = arrayClone.splice(startIndex, 1)
  arrayClone.splice(endIndex, 0, item)

  return arrayClone
}

export const move = (
  sourceList,
  destinationList,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = [...sourceList]
  const destClone = [...destinationList]

  const [item] = sourceClone.splice(droppableSource.index, 1)
  destClone.splice(droppableDestination.index, 0, item)

  return [sourceClone, destClone]
}

export default {
  reorder,
  move,
}
