import React, { memo, useState, useRef, useEffect, useCallback } from 'react'
import { get, find } from 'lodash'
import csx from 'classnames'
import moment from 'moment'

import { Card, DatePicker } from '../index'
import DropdownWrapper from './DropdownWrapper'
import { UNSET, WORK_REMINDER } from 'constants/Enum'

const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'

const selectData = [
  { value: UNSET, label: 'Không nhắc' },
  { value: WORK_REMINDER.TRUOC_MOT_NGAY, label: 'Trước 1 ngày' },
  { value: WORK_REMINDER.TRUOC_HAI_NGAY, label: 'Trước 2 ngày' },
  { value: WORK_REMINDER.TRUOC_BA_NGAY, label: 'Trước 3 ngày' },
]

const typeToNumber = {
  [WORK_REMINDER.TRUOC_MOT_NGAY]: 1,
  [WORK_REMINDER.TRUOC_HAI_NGAY]: 2,
  [WORK_REMINDER.TRUOC_BA_NGAY]: 3,
}

const RemindSelect = ({
  deadline = null,
  onChange = () => {},
  value: selectValue,
  ...props
}) => {
  const mounted = useRef(false)
  const mountedSet = (setState, value) =>
    !!get(mounted, 'current', false) && setState(value)

  const [selected, setSelected] = useState()
  const [dropdownOpen, _setDropdownOpen] = useState(false)

  const _toggleDropdown = useCallback(open => {
    mountedSet(_setDropdownOpen, open)
  }, [])

  const onSelect = value => () => {
    const newSelected = {
      type: value,
      date: null,
    }
    if (value !== UNSET) {
      newSelected.date = moment(deadline)
        .subtract(typeToNumber[value], 'days')
        .set('hour', 7)
        .set('minute', 0)
    }
    mountedSet(setSelected, newSelected)
    onChange(value !== UNSET ? newSelected : undefined)
    _toggleDropdown(false)
  }

  const changeDate = date => {
    const newSelected = {
      type: date ? WORK_REMINDER.TUY_CHINH : UNSET,
      date: date,
    }

    mountedSet(setSelected, newSelected)
    onChange(newSelected)
    if (!date) {
      _toggleDropdown(false)
    }
  }

  const selectDisabled = value => {
    if (value === UNSET) {
      return false
    }
    if (!deadline) {
      return true
    }
    if (
      moment(deadline).subtract(typeToNumber[value], 'days') <
      moment().endOf('d')
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])

  useEffect(() => {
    mountedSet(setSelected, selectValue)
  }, [selectValue])

  return (
    <DropdownWrapper
      {...props}
      clearable={false}
      open={dropdownOpen}
      onDropdownVisibleChange={_toggleDropdown}
      value={
        !selected
          ? undefined
          : get(selected, 'type') !== WORK_REMINDER.TUY_CHINH
          ? get(find(selectData, { value: get(selected, 'type') }), 'label')
          : get(selected, 'date').format(DATETIME_FORMAT)
      }
    >
      <Card className="popup">
        <Card className="cpc-list">
          {selectData.map(({ value, label }) => (
            <Card
              className={csx(
                'cpc-list-item',
                'font-size-13',
                'pt5 pb5 pl20 pr20',
                'has-event',
                {
                  active: value === get(selected, 'type'),
                  disabled: selectDisabled(value),
                }
              )}
              key={value}
              onClick={onSelect(value)}
            >
              {label}
            </Card>
          ))}
        </Card>
        <Card borderTop padding>
          <DatePicker
            isClearable={false}
            showTimeSelect
            popperClassName="has-time"
            className="has-time-clear"
            timeFormat="HH:mm"
            dateFormat="DD/MM/YYYY HH:mm"
            placeholderText="Tùy chỉnh"
            onChange={changeDate}
            selected={
              get(selected, 'type') === WORK_REMINDER.TUY_CHINH
                ? get(selected, 'date')
                : null
            }
            popperPlacement="top"
            minDate={moment()}
          />
        </Card>
      </Card>
    </DropdownWrapper>
  )
}

export default memo(RemindSelect)
