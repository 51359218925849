export const stringMention = string => {
  // /<mention>\[(.*?)\]\((.*?)\)<\/mention>/
  const updatedHTML = string.replace(
    /\[(.*?)\]\((.*?)\)/g,
    function (match, group) {
      const value = match.match(/\[(.*?)\]\((.*?)\)/)
      return `@${value[1]}`
    }
  )
  return updatedHTML
}
