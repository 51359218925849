import React, { useCallback, useEffect, useState, memo, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import moment from 'moment/moment'
import { connect } from 'react-redux'

import { Utils } from 'helpers'
import { HeadingPage } from 'components/common'
import Pagination from 'components/Pagination'
import { PAGINATION_LIMIT } from 'constants/Api'
import {
  getDanhSachSoHoa,
  getChuyenVienXuLy,
  getLanhDaoChiDao,
  getLoaiVanBan,
  getPhongBanHanh,
  getPhongBanXuLy,
  getSoVanBan,
} from 'actions/doffice'
import { MasterLayout } from 'components/layout'
import { Table } from 'components/newCommon2'
import requestAction from 'helpers/request'
import TimKiemCongViecFilter from './timkiemcongviecfilter'
import CryptoAES from 'crypto-js/aes'

export const CongVanContext = React.createContext({})
const filterKeys = {
  loaiCongVan: 'LoaiVB',
  mainKeyWord: 'MainKeyWord',
  idSoVB: 'ID_SO_VB',
  idLoaiVB: 'ID_LOAI_VB',
  idLanhDaoChiDao: 'IDLanhDaoChiDao',
  idPhongBanBanHanh: 'IDPhongBanBanHanh',
  nguoiKyVB: 'NguoiKyVB',
  noiGui: 'NoiGui',
  noiNhan: 'NoiNhan',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  idChuyenVienXL: 'IDChuyenVienXL',
  idPhongBanChuTri: 'IDPhongBanChuTri',
  soDen: 'SO_DEN',
  kyHieu: 'KY_HIEU',
}

const TimKiemCongVan = ({
  typeSidebar,
  history,
  tokenDO,
  danhSachCongVanDO,
}) => {
  const mainPathname = useMemo(
    () => '/quan-ly-cong-viec-do/tim-kiem-cong-van',
    []
  )

  const DEFAULT_FILTER = useMemo(
    () => ({
      [filterKeys.idChuyenVienXL]: 0,
      [filterKeys.idPhongBanBanHanh]: 0,
      [filterKeys.idPhongBanChuTri]: 0,
      [filterKeys.idLoaiVB]: 0,
      [filterKeys.idSoVB]: 0,
      [filterKeys.loaiCongVan]: 'all',
      [filterKeys.mainKeyWord]: '',
      [filterKeys.dateFrom]: moment().subtract(3, 'months'),
      [filterKeys.dateTo]: moment(),
      [filterKeys.kyHieu]: '',
    }),
    []
  )

  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
  })
  const [dataSource, setDataSource] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [isError, setIsError] = useState(false)

  const goChiTiet = id => {
    const idEncode = encodeURIComponent(
      CryptoAES.encrypt(id.toString(), 'EVNCPC')
    )
    const url = `${mainPathname}/chi-tiet/${idEncode}`
    return history.push({
      pathname: url,
    })
  }

  const columns = [
    {
      key: 'stt',
      width: 5,
      headClass: 'uppercase',
      className: 'text-center max-width-60 element-center align-center',
      title: 'Stt',
      render: (v, r, index) => {
        const num = (pagination?.page - 1) * 10 + index + 1
        return `${num}`.padStart(num < 10 ? 2 : 1, '0')
      },
    },
    {
      key: 'kYHIEU',
      headClass: 'uppercase',
      className: 'align-center max-width-140 min-width-120',
      title: 'Số ký hiệu',
      width: 5,
      render: ({ kYHIEU }) => kYHIEU,
    },
    {
      key: 'tieuDe',
      headClass: 'uppercase',
      className: 'align-center',
      title: 'Trích Yếu / Tiêu đề ',
      width: 50,
      render: ({ tRICHYEU }) => tRICHYEU,
    },
    {
      key: 'noiBanHanh',
      headClass: 'uppercase',
      className: 'align-center max-width-200',
      title: 'Nơi ban hành',
      width: 15,
      render: ({ nOIBANHANH }) => nOIBANHANH,
    },
    {
      key: 'ngayVB',
      headClass: 'uppercase',
      className: 'align-center max-width-120 min-width-120',
      title: 'Ngày VB',
      width: 5,
      render: ({ nGAYVB }) =>
        nGAYVB ? moment(nGAYVB).format('DD/MM/YYYY') : '',
    },
    {
      key: 'thaoTac',
      className: 'align-center max-width-80 min-width-80',
      width: 5,
      render: ({ iDVB }) => (
        <div onClick={e => e.stopPropagation()}>
          <div className="color-blue pointer" onClick={() => goChiTiet(iDVB)}>
            <span>Chi tiết</span>
          </div>
        </div>
      ),
    },
  ]

  const fetchList = useCallback(
    (requestFilter = null) => {
      const token = tokenDO?.token
      if (!token) {
        return
      }

      const nhanVien = tokenDO?.nhanVien
      const tokenParams = {
        tokenType: 'Bearer',
        accessToken: token,
      }
      const params = {
        nvid: nhanVien?.idNv,
        madv: nhanVien?.idDv,
      }

      let newRequestFilter = requestFilter
      if (isEmpty(requestFilter)) {
        const filterParams = Utils.preData('timkiemcongvando')
        if (filterParams) {
          newRequestFilter = filterParams
        } else {
          newRequestFilter = DEFAULT_FILTER
        }
      }

      let data = {
        ...newRequestFilter,
        DateFrom: newRequestFilter?.[filterKeys.dateFrom]
          ? moment(newRequestFilter?.[filterKeys.dateFrom])
              .startOf('day')
              .toISOString()
          : undefined,
        DateTo: newRequestFilter?.[filterKeys.dateTo]
          ? moment(newRequestFilter?.[filterKeys.dateTo])
              .startOf('day')
              .toISOString()
          : undefined,
        NoiNhan:
          newRequestFilter?.[filterKeys.noiNhan]?.[0]?.value &&
          newRequestFilter?.[filterKeys.noiNhan]?.[0]?.value !== 'ALL'
            ? newRequestFilter?.[filterKeys.noiNhan]?.[0]?.value
            : undefined,
      }

      requestAction({
        codeCheck: false,
        showToast: false,
        getResult: false,
        beforeAction: () => {
          setIsError(false)
          setIsFetching(true)
        },
        action: () => getDanhSachSoHoa({ data, params }, tokenParams),
        afterResponse: res => {
          if (res?.data) {
            Utils.preData('timkiemcongvando', newRequestFilter)
            const pagePre = Utils.preData('timkiemcongvando-page')
            const dataTemp = res?.data
            if (pagePre && pagePre > 1) {
              const firstItem = (pagePre - 1) * 10
              const lastItem = pagePre * 10
              setDataSource(dataTemp.slice(firstItem, lastItem))
              setPagination(prev => ({
                ...prev,
                page: pagePre,
              }))
            } else {
              setDataSource(dataTemp.slice(0, 10))
              setPagination(prev => ({
                ...prev,
                page: 1,
              }))
            }
          }
        },
        afterAction: () => {
          setFilter(newRequestFilter)
          setIsFetching(false)
        },
        afterError: () => {
          setIsError(true)
        },
      })
    },
    // eslint-disable-next-line
    [tokenDO?.token, filterKeys, DEFAULT_FILTER]
  )

  const changeFilter =
    ({ name, reFetch = false }) =>
    value => {
      let newFilter = filter
      if (name === 'SUBMIT') {
        Utils.preData('timkiemcongvando-page')
        return fetchList(newFilter)
      }

      if (name === 'RESET') {
        Utils.preData('timkiemcongvando-page')
        return fetchList(DEFAULT_FILTER)
      }

      newFilter = {
        ...newFilter,
        [name]: value,
      }
      setFilter(prev => ({
        ...prev,
        [name]: value,
      }))
      if (reFetch) {
        Utils.preData('timkiemcongvando-page')
        return fetchList(newFilter)
      }
    }

  const handleChangePage = useCallback(
    value => {
      const { size } = pagination
      const firstItem = (value - 1) * size
      const lastItem = value * size
      const newData = danhSachCongVanDO.slice(firstItem, lastItem)
      setDataSource(newData)
      setPagination(prev => ({
        ...prev,
        page: value,
      }))
      if (value > 1) {
        Utils.preData('timkiemcongvando-page', value)
      }
    },
    [pagination, danhSachCongVanDO]
  )

  useEffect(() => {
    const res = setTimeout(() => {
      fetchList()
    }, 0)

    return () => clearTimeout(res)
  }, [fetchList])

  useEffect(() => {
    if (!isEmpty(tokenDO)) {
      const params = {
        ID_DV: tokenDO?.nhanVien?.idDv,
      }
      const token = {
        tokenType: 'Bearer',
        accessToken: tokenDO?.token,
      }
      requestAction({
        showToast: false,
        action: () => getLoaiVanBan(params, token),
      })
      requestAction({
        showToast: false,
        action: () => getPhongBanHanh(params, token),
      })
      requestAction({
        showToast: false,
        action: () => getSoVanBan(params, token),
      })
      requestAction({
        showToast: false,
        action: () => getPhongBanXuLy(params, token),
      })
      requestAction({
        showToast: false,
        action: () => getLanhDaoChiDao(params, token),
      })
      requestAction({
        showToast: false,
        action: () => getChuyenVienXuLy(params, token),
      })
    }
    // eslint-disable-next-line
  }, [tokenDO?.token])

  const totalData = size(danhSachCongVanDO ?? []) ?? 0
  return (
    <MasterLayout typeSidebar={typeSidebar}>
      <HeadingPage
        namePage="Tìm kiếm công văn D-Office"
        iconPage="icon-search-data"
      ></HeadingPage>
      <div>
        <TimKiemCongViecFilter
          filter={filter}
          filterKeys={filterKeys}
          changeFilter={changeFilter}
        />
        <Table
          className="cpc-table"
          autoHide={false}
          loading={isFetching}
          error={isError}
          columns={columns}
          data={dataSource}
          tableMinWidth={600}
          onRowClick={({ iDVB }) => goChiTiet(iDVB)}
        />
        {!isFetching && !isError && totalData > 10 && (
          <div className="bg-white ph10">
            <Pagination
              current={pagination?.page || 1}
              total={Math.ceil(totalData / PAGINATION_LIMIT)}
              onChange={handleChangePage}
            />
          </div>
        )}
      </div>
    </MasterLayout>
  )
}

const mapStateToProps = state => ({
  typeSidebar: state?.common?.typeSidebar,
  tokenDO: state?.doffice?.toKenDO,
  danhSachCongVanDO: state?.doffice?.danhSachCongVan,
})

export default connect(mapStateToProps, null)(memo(TimKiemCongVan))
